import {EmptyResult} from '../types';
import {usePost} from './../useHttpHooks';

export type UpdateAutoJoinEmailAddressesCommand = {
    collectionId: string;
    autoJoinEmailAddresses: string;
};

const useUpdateAutoJoinEmailAddresses = () =>
    usePost<UpdateAutoJoinEmailAddressesCommand, EmptyResult>(
        `/api/groups/update-auto-join-email-addresses`,
        {
            errorMessage:
                'POST api/groups/update-auto-join-email-addresses failed'
        }
    );

export default useUpdateAutoJoinEmailAddresses;
