import React, {createContext, ReactNode, useContext, useState} from 'react';
import {MatchOperator, MixType, Pagination} from '../../api/types.ts';
import {ReactStateSetter} from '../../types.ts';

interface GalleryState {
    search: string;
    mixType: MixType;
    selectedTags: string[];
    matchTags: MatchOperator;
    pagination: Pagination;
}

interface GalleryStateContextType {
    galleryStateMyProjects: GalleryState;
    setGalleryStateMyProjects: ReactStateSetter<GalleryState>;
    galleryStateExplore: GalleryState;
    setGalleryStateExplore: ReactStateSetter<GalleryState>;
}

const GalleryStateContext = createContext<GalleryStateContextType | undefined>(
    undefined
);

export const defaultGalleryState: GalleryState = {
    search: '',
    mixType: MixType.Projects,
    selectedTags: [],
    matchTags: MatchOperator.All,
    pagination: {page: 1, perPage: 10}
};

export const GalleryStateProvider: React.FC<{children: ReactNode}> = ({
    children
}) => {
    const [galleryStateMyProjects, setGalleryStateMyProjects] =
        useState<GalleryState>(defaultGalleryState);

    const [galleryStateExplore, setGalleryStateExplore] =
        useState<GalleryState>(defaultGalleryState);

    return (
        <GalleryStateContext.Provider
            value={{
                galleryStateMyProjects,
                setGalleryStateMyProjects,
                galleryStateExplore,
                setGalleryStateExplore
            }}
        >
            {children}
        </GalleryStateContext.Provider>
    );
};

export const useGalleryState = (
    isMyProjects: boolean
): [GalleryState, React.Dispatch<React.SetStateAction<GalleryState>>] => {
    const galleryStateContext = useContext(GalleryStateContext);

    if (!galleryStateContext) {
        throw new Error(
            'useGalleryState must be used within a GalleryStateProvider'
        );
    }

    const {
        galleryStateMyProjects,
        setGalleryStateMyProjects,
        galleryStateExplore,
        setGalleryStateExplore
    } = galleryStateContext;

    return isMyProjects
        ? [galleryStateMyProjects, setGalleryStateMyProjects]
        : [galleryStateExplore, setGalleryStateExplore];
};
