import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import RoleNames from '../../lib/RoleNames.ts';
import {
    ContentSectionModel,
    ContentSectionType,
    VideoHostingService,
    ViewMode
} from '../../api/types.ts';
import {v4 as uuidv4} from 'uuid';
import SectionIcon from './SectionIcon.tsx';
import {MergeData} from '../../types.ts';

interface ContentSectionPickerProps {
    mergeData: MergeData;
    ordinalPosition: number;
    onAdd?: (section: ContentSectionModel) => void;
}

const ContentSectionPicker: React.FC<ContentSectionPickerProps> = ({
    mergeData,
    ordinalPosition,
    onAdd
}) => {
    const {isInRole} = useAuthStateManager();

    const canEditHtml =
        isInRole(RoleNames.Admin) || isInRole(RoleNames.ContentEditor);

    const addMarkdown = () => {
        const section: ContentSectionModel = {
            id: uuidv4(),
            type: ContentSectionType.Markdown,
            ordinalPosition: ordinalPosition,
            content: ''
        };

        sessionStorage.setItem(
            `ViewMode_${section.id}`,
            JSON.stringify(ViewMode.Edit)
        );

        onAdd ? onAdd(section) : mergeData(section.id!, section);
    };

    const addEmbed = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const section: ContentSectionModel = {
            id: uuidv4(),
            type: ContentSectionType.Embed,
            ordinalPosition: ordinalPosition,
            fileName: ''
        };

        sessionStorage.setItem(
            `ViewMode_${section.id}`,
            JSON.stringify(ViewMode.Edit)
        );

        onAdd ? onAdd(section) : mergeData(section.id!, section);
    };

    const addHtml = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const section: ContentSectionModel = {
            id: uuidv4(),
            type: ContentSectionType.Html,
            ordinalPosition: ordinalPosition,
            content: ''
        };

        sessionStorage.setItem(
            `ViewMode_${section.id}`,
            JSON.stringify(ViewMode.Edit)
        );

        onAdd ? onAdd(section) : mergeData(section.id!, section);
    };

    const addCode = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const section: ContentSectionModel = {
            id: uuidv4(),
            type: ContentSectionType.Code,
            ordinalPosition: ordinalPosition,
            fileName: '',
            content: ''
        };

        sessionStorage.setItem(
            `ViewMode_${section.id}`,
            JSON.stringify(ViewMode.Edit)
        );

        onAdd ? onAdd(section) : mergeData(section.id!, section);
    };

    const addCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const section: ContentSectionModel = {
            id: uuidv4(),
            type: ContentSectionType.Copy,
            ordinalPosition: ordinalPosition,
            content: ''
        };

        sessionStorage.setItem(
            `ViewMode_${section.id}`,
            JSON.stringify(ViewMode.Edit)
        );

        onAdd ? onAdd(section) : mergeData(section.id!, section);
    };

    const addVideo = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const section: ContentSectionModel = {
            id: uuidv4(),
            type: ContentSectionType.Video,
            ordinalPosition: ordinalPosition,
            fileName: '',
            service: VideoHostingService.HostedByMixiply,
            videoId: ''
        };

        sessionStorage.setItem(
            `ViewMode_${section.id}`,
            JSON.stringify(ViewMode.Edit)
        );

        onAdd ? onAdd(section) : mergeData(section.id!, section);
    };

    const addPicture = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const section: ContentSectionModel = {
            id: uuidv4(),
            type: ContentSectionType.Picture,
            ordinalPosition: ordinalPosition,
            fileName: '',
            altText: ''
        };

        sessionStorage.setItem(
            `ViewMode_${section.id}`,
            JSON.stringify(ViewMode.Edit)
        );

        onAdd ? onAdd(section) : mergeData(section.id!, section);
    };

    const addAudio = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const section: ContentSectionModel = {
            id: uuidv4(),
            type: ContentSectionType.Audio,
            ordinalPosition: ordinalPosition,
            fileName: '',
            altText: ''
        };

        sessionStorage.setItem(
            `ViewMode_${section.id}`,
            JSON.stringify(ViewMode.Edit)
        );

        onAdd ? onAdd(section) : mergeData(section.id!, section);
    };

    const addHeader = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const section: ContentSectionModel = {
            id: uuidv4(),
            type: ContentSectionType.Header,
            ordinalPosition: ordinalPosition,
            content: '',
            altText: '1'
        };

        sessionStorage.setItem(
            `ViewMode_${section.id}`,
            JSON.stringify(ViewMode.Edit)
        );

        onAdd ? onAdd(section) : mergeData(section.id!, section);
    };

    const addModel = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const section: ContentSectionModel = {
            id: uuidv4(),
            type: ContentSectionType.Model,
            ordinalPosition: ordinalPosition,
            fileName: '',
            content: '',
            altText: ''
        };

        sessionStorage.setItem(
            `ViewMode_${section.id}`,
            JSON.stringify(ViewMode.Edit)
        );

        onAdd ? onAdd(section) : mergeData(section.id!, section);
    };

    const addRichText = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const section: ContentSectionModel = {
            id: uuidv4(),
            type: ContentSectionType.RichText,
            ordinalPosition: ordinalPosition,
            content: ''
        };

        sessionStorage.setItem(
            `ViewMode_${section.id}`,
            JSON.stringify(ViewMode.Edit)
        );

        onAdd ? onAdd(section) : mergeData(section.id!, section);
    };

    const addDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const section: ContentSectionModel = {
            id: uuidv4(),
            type: ContentSectionType.Download,
            ordinalPosition: ordinalPosition,
            fileName: '',
            content: '',
            altText: ''
        };

        sessionStorage.setItem(
            `ViewMode_${section.id}`,
            JSON.stringify(ViewMode.Edit)
        );

        onAdd ? onAdd(section) : mergeData(section.id!, section);
    };

    const addProjects = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const section: ContentSectionModel = {
            id: uuidv4(),
            type: ContentSectionType.Projects,
            ordinalPosition: ordinalPosition,
            fileName: '',
            content: '[]',
            altText: ''
        };

        sessionStorage.setItem(
            `ViewMode_${section.id}`,
            JSON.stringify(ViewMode.Edit)
        );

        onAdd ? onAdd(section) : mergeData(section.id!, section);
    };

    return (
        <div className="mt-5 text-center">
            <p className="mb-2 text-center">
                Click on the controls below to add a new content section.
            </p>
            <div className="content-creator-box">
                <button onClick={addHeader}>
                    <div className="button-icon-container">
                        <SectionIcon sectionType={ContentSectionType.Header} />
                    </div>
                    <div className="button-text">Header</div>
                </button>
                <button onClick={addRichText}>
                    <div className="button-icon-container">
                        <SectionIcon
                            sectionType={ContentSectionType.RichText}
                        />
                    </div>
                    <div className="button-text">Rich text editor</div>
                </button>
                <button onClick={addPicture}>
                    <div className="button-icon-container">
                        <SectionIcon sectionType={ContentSectionType.Picture} />
                    </div>
                    <div className="button-text">Picture</div>
                </button>
                <button onClick={addVideo}>
                    <div className="button-icon-container">
                        <SectionIcon sectionType={ContentSectionType.Video} />
                    </div>
                    <div className="button-text">Video</div>
                </button>
                <button onClick={addModel}>
                    <div className="button-icon-container">
                        <SectionIcon sectionType={ContentSectionType.Model} />
                    </div>
                    <div className="button-text">Model</div>
                </button>
                <button onClick={addAudio}>
                    <div className="button-icon-container">
                        <SectionIcon sectionType={ContentSectionType.Audio} />
                    </div>
                    <div className="button-text">Audio</div>
                </button>
                <button onClick={addDownload}>
                    <div className="button-icon-container">
                        <SectionIcon
                            sectionType={ContentSectionType.Download}
                        />
                    </div>
                    <div className="button-text">Download</div>
                </button>
                <button onClick={addMarkdown}>
                    <div className="button-icon-container">
                        <SectionIcon
                            sectionType={ContentSectionType.Markdown}
                        />
                    </div>
                    <div className="button-text">Markdown</div>
                </button>
                <button onClick={addEmbed}>
                    <div className="button-icon-container">
                        <SectionIcon sectionType={ContentSectionType.Embed} />
                    </div>
                    <div className="button-text">Embed</div>
                </button>
                {canEditHtml && (
                    <button onClick={addHtml}>
                        <div className="button-icon-container">
                            <SectionIcon
                                sectionType={ContentSectionType.Html}
                            />
                        </div>
                        <div className="button-text">HTML</div>
                    </button>
                )}
                <button onClick={addCode}>
                    <div className="button-icon-container font-weight-bold">
                        <SectionIcon sectionType={ContentSectionType.Code} />
                    </div>
                    <div className="button-text">Code</div>
                </button>
                <button onClick={addCopy}>
                    <div className="button-icon-container font-weight-bold">
                        <SectionIcon sectionType={ContentSectionType.Copy} />
                    </div>
                    <div className="button-text">Copy</div>
                </button>
                <button onClick={addProjects}>
                    <div className="button-icon-container font-weight-bold">
                        <SectionIcon
                            sectionType={ContentSectionType.Projects}
                        />
                    </div>
                    <div className="button-text">Link to other projects</div>
                </button>
            </div>
        </div>
    );
};

export default ContentSectionPicker;
