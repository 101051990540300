import React from 'react';
import emojis from './emojis.ts';
import {
    ActiveTabState,
    NavTabsState,
    TabState
} from '../components/shared/tabs/types.ts';
import EmojiImage from './EmojiImage.tsx';
import {ReactStateSetter} from '../types.ts';

interface EmojiPickerNavTabsLinksProps {
    navTabsState: NavTabsState;
    setActiveTabState: ReactStateSetter<ActiveTabState>;
}

const EmojiPickerNavTabsLinks: React.FC<EmojiPickerNavTabsLinksProps> = ({
    navTabsState,
    setActiveTabState
}) => {
    const getLabelClass = (tab: TabState) => {
        return navTabsState.activeTabName === tab.name ? 'active' : '';
    };

    return (
        <ul className="nav nav-tabs">
            {navTabsState.tabs.map(tab => {
                const group = emojis.groups.find(g => g.name === tab.text);
                const emojiCode = group?.subGroups[0].emojis[0].emojiCode;

                return (
                    <li className="nav-item" key={tab.name}>
                        <a
                            className={`nav-link ${getLabelClass(tab)}`}
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                setActiveTabState(prevState => ({
                                    ...prevState,
                                    activeTabName: tab.name
                                }));
                            }}
                        >
                            {!emojiCode ? (
                                tab.text
                            ) : (
                                <EmojiImage
                                    emojiCode={emojiCode}
                                    title={tab.text}
                                />
                            )}
                        </a>
                    </li>
                );
            })}
        </ul>
    );
};

export default EmojiPickerNavTabsLinks;
