import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faPaintBrush,
    faAt,
    faPaperPlane,
    faReply,
    faUsers,
    faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import {NotificationModel, NotificationType} from '../../api/types.ts';
import projectUrl from '../../lib/projectUrl.ts';
import {GroupPageTabNames} from '../groups/GroupPage.tsx';
import EmojiImage from '../../emojis/EmojiImage.tsx';
import displayName from '../../lib/displayName.ts';

interface MxNotificationCardProps {
    notification: NotificationModel;
}

const MxNotificationCard: React.FC<MxNotificationCardProps> = ({
    notification
}) => {
    const notificationImageSrc = () => {
        if (notification.notificationType === NotificationType.ProjectAdded) {
            return notification.mix.thumbnail;
        }

        if (notification.notificationType === NotificationType.Invite) {
            return notification.collectionThumbnail;
        }

        return notification.initiatedBy.thumbnail;
    };

    const notificationImageAlt = () => {
        if (notification.notificationType === NotificationType.ProjectAdded) {
            return 'Mix thumbnail';
        }

        if (notification.notificationType === NotificationType.Invite) {
            return 'Group thumbnail';
        }

        return `@${notification.initiatedBy.nickname} profile image`;
    };

    const notificationUrl = () => {
        if (notification.notificationType === NotificationType.ProjectAdded) {
            return projectUrl(notification.mix);
        }

        if (notification.notificationType === NotificationType.Invite) {
            return '/explore/groups';
        }

        if (notification.notificationType === NotificationType.RequestToJoin) {
            return `/explore/groups/${notification.collectionContainerName}?tab=${GroupPageTabNames.Members}`;
        }

        const channelName = encodeURIComponent(notification.channelName);
        const postId = notification.postId ?? '';
        const parentPostId = notification.parentPostId ?? '';

        return `/explore/groups/${notification.collectionContainerName}?tab=${GroupPageTabNames.Posts}&ch=${channelName}&pid=${postId}&ppid=${parentPostId}`;
    };

    return (
        <a
            className={`notification-card${notification.viewed ? '' : ' new'}`}
            href={notificationUrl()}
        >
            <div className="notification-img-holder">
                <img
                    src={notificationImageSrc()}
                    crossOrigin="anonymous"
                    alt={notificationImageAlt()}
                />
                <span className="notification-type">
                    {notification.notificationType ===
                        NotificationType.ProjectAdded && (
                        <FontAwesomeIcon icon={faPaintBrush} />
                    )}
                    {notification.notificationType ===
                        NotificationType.Mention && (
                        <FontAwesomeIcon icon={faAt} />
                    )}
                    {notification.notificationType ===
                        NotificationType.NewPost && (
                        <FontAwesomeIcon icon={faPaperPlane} />
                    )}
                    {notification.notificationType ===
                        NotificationType.PostReaction && (
                        <EmojiImage emojiCode={notification.data} />
                    )}
                    {notification.notificationType ===
                        NotificationType.PostReply && (
                        <FontAwesomeIcon icon={faReply} />
                    )}
                    {notification.notificationType ===
                        NotificationType.Invite && (
                        <FontAwesomeIcon icon={faUsers} />
                    )}
                    {notification.notificationType ===
                        NotificationType.RequestToJoin && (
                        <FontAwesomeIcon icon={faUserPlus} />
                    )}
                </span>
            </div>
            <div className="notification-info">
                {notification.notificationType ===
                    NotificationType.ProjectAdded && (
                    <span>
                        <span className="fw-bold">
                            {displayName(notification.initiatedBy)}
                        </span>{' '}
                        added a new project{' '}
                        <span className="fw-bold">{notification.mix.name}</span>
                    </span>
                )}
                {notification.notificationType === NotificationType.Mention && (
                    <span>
                        <span className="fw-bold">
                            {displayName(notification.initiatedBy)}
                        </span>{' '}
                        mentioned you in a post in{' '}
                        <span className="fw-bold">
                            {notification.collectionName} #
                            {notification.channelName}
                        </span>
                    </span>
                )}
                {notification.notificationType === NotificationType.NewPost && (
                    <span>
                        <span className="fw-bold">
                            {displayName(notification.initiatedBy)}
                        </span>{' '}
                        added a new post in{' '}
                        <span className="fw-bold">
                            {notification.collectionName} #
                            {notification.channelName}
                        </span>
                    </span>
                )}
                {notification.notificationType ===
                    NotificationType.PostReaction && (
                    <span>
                        <span className="fw-bold">
                            {displayName(notification.initiatedBy)}
                        </span>{' '}
                        reacted to your post in{' '}
                        <span className="fw-bold">
                            {notification.collectionName} #
                            {notification.channelName}
                        </span>
                    </span>
                )}
                {notification.notificationType ===
                    NotificationType.PostReply && (
                    <span>
                        <span className="fw-bold">
                            {displayName(notification.initiatedBy)}
                        </span>{' '}
                        replied to your post in{' '}
                        <span className="fw-bold">
                            {notification.collectionName} #
                            {notification.channelName}
                        </span>
                    </span>
                )}
                {notification.notificationType === NotificationType.Invite && (
                    <span>
                        <span className="fw-bold">
                            {displayName(notification.initiatedBy)}
                        </span>{' '}
                        invited you to join{' '}
                        <span className="fw-bold">
                            {notification.collectionName}
                        </span>
                    </span>
                )}
                {notification.notificationType ===
                    NotificationType.RequestToJoin && (
                    <span>
                        <span className="fw-bold">
                            {displayName(notification.initiatedBy)}
                        </span>{' '}
                        requested to join{' '}
                        <span className="fw-bold">
                            {notification.collectionName}
                        </span>
                    </span>
                )}
            </div>
        </a>
    );
};

export default MxNotificationCard;
