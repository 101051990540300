import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import OverlayWithSpinner from '../shared/OverlayWithSpinner.tsx';

interface FormConfirmEditProps {
    value: string;
    onApply: (newValue: string) => Promise<void>;
    onCancel: () => void;
}

const FormConfirmEdit: React.FC<FormConfirmEditProps> = ({
    value,
    onApply,
    onCancel
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleOnApply = async (newValue: string) => {
        const result = onApply(newValue);
        if (result instanceof Promise) {
            setIsSubmitting(true);
            try {
                await result;
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const [editedValue, setEditedValue] = useState(value);

    // Update state when the value prop changes
    useEffect(() => {
        setEditedValue(value);
    }, [value]);

    const filterInvalidChars = (input: string): string => {
        const invalidCharsRegex = // eslint-disable-next-line no-control-regex
            /[\u0000-\u001F\u0022\u002A\u002F\u003A\u003C\u003E\u003F\u005C\u007C]/g;
        return input.replace(invalidCharsRegex, '');
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filteredValue = filterInvalidChars(event.target.value);
        setEditedValue(filteredValue);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleOnApply(editedValue);
        } else if (event.key === 'Escape') {
            handleOnCancel();
        }
    };

    const handleOnCancel = () => {
        setEditedValue(value);
        onCancel();
    };

    return (
        <div className="d-flex align-items-center">
            <input
                type="text"
                className="form-control me-2"
                value={editedValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                autoFocus
            />
            <button
                className="action-link"
                onClick={() => handleOnApply(editedValue)}
            >
                <FontAwesomeIcon icon={faCheck} />
            </button>
            <button className="action-link" onClick={handleOnCancel}>
                <FontAwesomeIcon icon={faTimes} />
            </button>
            {isSubmitting && <OverlayWithSpinner />}
        </div>
    );
};

export default FormConfirmEdit;
