export class GroupPostsScroller {
    lastScroll = 0;
    nextMessage: () => Promise<void>;
    elem: HTMLElement | null;

    constructor(nextMessage: () => Promise<void>, id: string) {
        this.nextMessage = nextMessage;
        this.elem = document.getElementById(id);
        this.removeListeners();
        this.addEventListeners();
    }

    private async onScroll() {
        if (this.elem === null) {
            return;
        }

        const scrollTop = this.elem.scrollTop;
        const scrollHeight = this.elem.scrollHeight;
        const clientHeight = this.elem.clientHeight;

        // Check if the user has scrolled to the top and not beyond
        if (scrollTop < 100 && scrollTop + clientHeight >= scrollHeight) {
            await this.nextMessage();
        }

        this.lastScroll = +new Date();
    }

    private removeListeners() {
        if (this.elem === null) {
            return;
        }

        this.elem.removeEventListener('scroll', () => this.onScroll());
    }

    private addEventListeners() {
        if (this.elem === null) {
            return;
        }

        this.elem.addEventListener('scroll', () => this.onScroll());
    }

    onPostAdded(groupPost: HTMLElement) {
        if (this.elem === null) {
            return;
        }

        const scrollTop = this.elem.scrollTop;
        this.elem.scrollTop = scrollTop + groupPost.offsetHeight;
    }

    dispose() {
        this.removeListeners();
    }
}
