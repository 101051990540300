import React, {useCallback, useState} from 'react';
import {useQuery} from 'react-query';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Emoji, EmojiType} from './types.ts';
import useGetUserProfileEmojis from '../api/users/useGetUserProfileEmojis.ts';
import emojis from './emojis.ts';
import emojisByEmojiCode, {emojiIsAllowed} from './emojisByEmojiCode.ts';
import NavTabs from '../components/shared/tabs/NavTabs.tsx';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {isNotNullOrWhiteSpace} from '../lib/isNullOrWhiteSpace.ts';
import EmojiPickerNavTabsLinks from './EmojiPickerNavTabsLinks.tsx';
import Tab from '../components/shared/tabs/Tab.tsx';
import EmojiPickerEmoji from './EmojiPickerEmoji.tsx';
import SimilarEmojiComparer from './SimilarEmojiComparer.ts';
import {getNavTabsState} from '../components/shared/tabs/tabs.ts';
import emojiTabs from './emojiTabs.ts';
import {ActiveTabState} from '../components/shared/tabs/types.ts';
import debounce from '../lib/debounce.ts';
import {useAuthStateManager} from '../hooks/useAuthStateManager.tsx';

interface EmojiPickerModalProps {
    onClose: () => void;
    onSelect: (emoji: Emoji) => void;
}

const EmojiPickerModal: React.FC<EmojiPickerModalProps> = ({
    onClose,
    onSelect
}) => {
    const {loggedInUserId} = useAuthStateManager();
    const [matchingEmojis, setMatchingEmojis] = useState<Emoji[]>([]);
    const [mostSimilarEmojis, setMostSimilarEmojis] = useState<Emoji[]>([]);

    const getUserProfileEmojis = useGetUserProfileEmojis({});
    const {data: userProfileEmojis} = useQuery(
        ['userProfileEmojis', loggedInUserId],
        getUserProfileEmojis
    );

    const [activeTabState, setActiveTabState] = useState<ActiveTabState>({
        activeTabName: 'Emojis'
    });
    const navTabsState = getNavTabsState(emojiTabs, activeTabState);

    const [text, setText] = useState('');
    const debouncedSearch = useCallback(
        debounce((searchText: string) => {
            setMatchingEmojis(getMatchingEmojis(searchText));
            setMostSimilarEmojis(getMostSimilarEmojis());
        }, 60),
        []
    );

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newText = event.target.value;
        setText(newText);
        debouncedSearch(newText);
    };

    const getMatchingEmojis = (text: string): Emoji[] => {
        if (!isNotNullOrWhiteSpace(text)) {
            return [];
        }

        const matchText = text.replace(/^[:\s]+|[:\s]+$/g, '').toLowerCase();
        const emojis = Object.values(emojisByEmojiCode);

        return emojis.filter(
            emoji =>
                emoji.emojiCode.toLowerCase().includes(matchText) ||
                emoji.name.toLowerCase().includes(matchText)
        );
    };

    const getMostSimilarEmojis = () => {
        const comparer = new SimilarEmojiComparer(text);

        return matchingEmojis
            .sort((a, b) => comparer.compare(a, b))
            .slice(0, 4);
    };

    const getEmojiCode = (emoji: Emoji) => {
        if (emoji.type === EmojiType.Ascii) {
            return emoji.emojiCode;
        }

        return `:${emoji.emojiCode}:`;
    };

    const subGroupTitle = (name: string) => {
        if (!name.trim()) {
            return name;
        }

        return name.charAt(0).toUpperCase() + name.slice(1).replace('-', ' ');
    };

    const clearSearch = () => setText('');

    return (
        <Modal toggle={onClose} isOpen fullscreen>
            <ModalHeader>
                <EmojiPickerNavTabsLinks
                    navTabsState={navTabsState}
                    setActiveTabState={setActiveTabState}
                />
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={onClose}
                ></button>
            </ModalHeader>
            <ModalBody>
                <NavTabs navTabsState={navTabsState}>
                    <Tab name="Emojis" navTabsState={navTabsState}>
                        <div className="search-holder docs-search emoji-search mb-4">
                            <div className="basic-search-holder">
                                <input
                                    type="text"
                                    className="form-control b-neutral"
                                    placeholder="Search"
                                    aria-label="Search"
                                    value={text}
                                    onChange={handleTextChange}
                                />
                                <button
                                    className="btn action-link search-clear"
                                    type="button"
                                    id="ClearSearch"
                                    onClick={clearSearch}
                                >
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        aria-hidden="true"
                                    />
                                    <span className="visually-hidden">
                                        Clear
                                    </span>
                                </button>
                                <FontAwesomeIcon
                                    icon={faSearch}
                                    className="search-icon-static"
                                />
                            </div>
                        </div>
                        {mostSimilarEmojis.map(emoji => (
                            <div
                                className="emoji-group mb-3"
                                key={emoji.emojiCode}
                            >
                                <EmojiPickerEmoji
                                    emoji={emoji}
                                    onSelect={onSelect}
                                    defaultSkinTone={
                                        userProfileEmojis?.skinTone
                                    }
                                />{' '}
                                {getEmojiCode(emoji)}
                            </div>
                        ))}
                        <div className="emoji-group mb-5">
                            {matchingEmojis.map(emoji => (
                                <EmojiPickerEmoji
                                    emoji={emoji}
                                    onSelect={onSelect}
                                    defaultSkinTone={
                                        userProfileEmojis?.skinTone
                                    }
                                    key={emoji.emojiCode}
                                />
                            ))}
                        </div>
                        {userProfileEmojis &&
                            userProfileEmojis.recentlyUsed.length > 0 && (
                                <div className="mb-5 emoji-group">
                                    <h6 className="op-08">Recently used</h6>
                                    {userProfileEmojis.recentlyUsed.map(
                                        emoji => (
                                            <EmojiPickerEmoji
                                                emoji={emoji}
                                                onSelect={onSelect}
                                                defaultSkinTone={
                                                    userProfileEmojis.skinTone
                                                }
                                                key={emoji.emojiCode}
                                            />
                                        )
                                    )}
                                </div>
                            )}
                    </Tab>
                    {emojis.groups
                        .filter(g => g.name !== 'Component')
                        .map(group => (
                            <Tab
                                name={group.name}
                                navTabsState={navTabsState}
                                key={group.name}
                            >
                                {group.subGroups.map(subGroup => (
                                    <div
                                        className="mb-5 emoji-group"
                                        key={subGroup.name}
                                    >
                                        {!subGroup.name.trim() && (
                                            <h6 className="op-08">
                                                {subGroupTitle(subGroup.name)}
                                            </h6>
                                        )}
                                        {subGroup.emojis
                                            .filter(emojiIsAllowed)
                                            .map(emoji => (
                                                <EmojiPickerEmoji
                                                    emoji={emoji}
                                                    onSelect={onSelect}
                                                    defaultSkinTone={
                                                        userProfileEmojis?.skinTone
                                                    }
                                                    key={emoji.emojiCode}
                                                />
                                            ))}
                                    </div>
                                ))}
                            </Tab>
                        ))}
                </NavTabs>
            </ModalBody>
        </Modal>
    );
};

export default EmojiPickerModal;
