import {EmptyResult} from '../types';
import {usePost} from '../useHttpHooks.ts';
import {EntityDataPayload} from '../../types.ts';

const useMergeLargeObject = () =>
    usePost<EntityDataPayload, EmptyResult>(
        `/api/project-data/merge-large-object`,
        {
            errorMessage: 'Failed to merge large object'
        }
    );

export default useMergeLargeObject;
