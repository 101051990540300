import {ProjectDataIndex} from '../types';
import {EntityDataModel} from '../../types.ts';
import {useGet} from '../useHttpHooks.ts';

const useFetchProjectData = (projectId: string, dataIndex: ProjectDataIndex) =>
    useGet<EntityDataModel>(`/api/project-data/${projectId}/${dataIndex}`, {
        errorMessage: 'Failed to fetch project data'
    });

export default useFetchProjectData;
