import Split from 'split.js';

function addInstance(id, splitInstance) {
    if (window.__splitInstances) {
        window.__splitInstances[id] = splitInstance;
    } else {
        window.__splitInstances = {
            [id]: splitInstance
        };
    }
}

export function applySplit(id, elements, options) {
    try {
        const splitInstance = new Split(elements, options);

        addInstance(id, splitInstance);
    } catch (e) {
        console.error(e);
    }
}

export function disposeSplit(id) {
    try {
        if (window.__splitInstances && window.__splitInstances[id]) {
            window.__splitInstances[id].destroy();
        }
    } catch (e) {
        console.error(e);
    }
}
