import {MixModel, ModerationStatus, ProjectFormat} from '../../api/types.ts';

const emptyMix: MixModel = {
    id: null,
    name: '',
    description: '',
    tags: [],
    customUrl: '',
    projectFormat: ProjectFormat.Web,
    rank: null,
    rankOverride: null,
    revision: 0,
    userId: null,
    nickname: '',
    userName: '',
    userThumbnail: '',
    userContainerName: '',
    includes: '',
    thumbnail: '',
    thumbnailHasChanged: false,
    mixScript: '',
    content: {
        id: null,
        sections: []
    },
    moderationStatus: ModerationStatus.Accepted,
    moderationMessage: '',
    blocked: false,
    archived: false,
    templateId: null,
    isTemplate: false,
    createdFromTemplateId: null,
    projectEntries: [],
    collections: [],
    editors: [],
    tabs: null
};

export default emptyMix;
