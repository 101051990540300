import {MixModel, ProjectDataIndex, ProjectTab} from '../../../api/types.ts';
import isValidURL from '../../../lib/isValidURL.ts';
import EmbeddedWebPageCheck from '../../shared/EmbeddedWebPageCheck.tsx';
import React from 'react';
import EditWebsiteTabSrc from '../EditWebsiteTabSrc.tsx';

interface ProjectTabWebsiteProps {
    mix: MixModel;
    saveProject?: (project: MixModel) => Promise<void>;
    tab: ProjectTab;
    dataIndex: ProjectDataIndex;
    readOnly: boolean;
}

const ProjectTabWebsite: React.FC<ProjectTabWebsiteProps> = ({
    mix,
    saveProject,
    tab,
    dataIndex,
    readOnly
}) => {
    if (!tab.src || !isValidURL(tab.src)) {
        if (!readOnly && saveProject) {
            return (
                <EditWebsiteTabSrc
                    project={mix}
                    saveProject={saveProject}
                    tab={tab}
                    dataIndex={dataIndex}
                />
            );
        }

        return null;
    }

    return (
        <EmbeddedWebPageCheck url={tab.src}>
            <iframe
                src={tab.src}
                style={{width: '100%', height: '100vh', border: 'none'}}
                sandbox="allow-scripts allow-same-origin"
                referrerPolicy="no-referrer"
            />
        </EmbeddedWebPageCheck>
    );
};

export default ProjectTabWebsite;
