import {NotificationModel, Period} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetNotificationsQuery = {
    dateTimeFromUtc: string | null;
    period: Period;
    take: number;
};

const useGetNotifications = (request: GetNotificationsQuery) =>
    useGet<NotificationModel[]>(
        `/api/notifications/get-notifications?${toQuery(request)}`,
        {errorMessage: 'GET api/notifications/get-notifications failed'}
    );

export default useGetNotifications;
