import React, {useState} from 'react';
import OverlayWithSpinner from './OverlayWithSpinner.tsx';

interface ConfirmActionButtonProps {
    id: string;
    cssClass: string;
    buttonText: string;
    confirmationMessage: string;
    onClickAction: (
        event: React.MouseEvent<HTMLButtonElement>
    ) => Promise<void> | void;
}

const ConfirmActionButton: React.FC<ConfirmActionButtonProps> = ({
    id,
    cssClass,
    buttonText,
    confirmationMessage,
    onClickAction
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const confirmAction = async (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        const confirmed = window.confirm(confirmationMessage);
        if (confirmed) {
            const result = onClickAction(event);

            if (result instanceof Promise) {
                setIsSubmitting(true);
                try {
                    await result;
                } finally {
                    setIsSubmitting(false);
                }
            }
        }
    };

    return (
        <>
            <button
                type="button"
                id={id}
                className={cssClass}
                title={buttonText}
                aria-label={buttonText}
                onClick={confirmAction}
            >
                {buttonText}
            </button>
            {isSubmitting && <OverlayWithSpinner />}
        </>
    );
};

export default ConfirmActionButton;
