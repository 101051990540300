import {CollectionMixesResult} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetCollectionMixesQuery = {
    collectionId: string | null;
    containerName: string | null;
};

const useGetCollectionMixes = (request: GetCollectionMixesQuery) =>
    useGet<CollectionMixesResult>(
        `/api/collections/get-collection-mixes?${toQuery(request)}`,
        {errorMessage: 'GET api/collections/get-collection-mixes failed'}
    );

export default useGetCollectionMixes;
