import React, {useEffect, useRef} from 'react';
import {loadCodeEditor, unloadCodeEditor} from './MxCodeLoader.js';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {vscDarkPlus} from 'react-syntax-highlighter/dist/esm/styles/prism';

interface MxCodeProps {
    id: string;
    code: string;
    onChange: (code: string) => void;
    mode?: string;
    readOnly: boolean;
}

const MxCode: React.FC<MxCodeProps> = ({
    id,
    code,
    onChange,
    mode = 'javascript',
    readOnly
}) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (readOnly || !textareaRef.current) {
            return;
        }

        loadCodeEditor(id, onChange, textareaRef.current, mode, readOnly, code);

        return () => {
            unloadCodeEditor(id);
        };
    }, [id, textareaRef.current, mode, readOnly]);

    if (readOnly) {
        return (
            <SyntaxHighlighter language={mode} style={vscDarkPlus}>
                {code}
            </SyntaxHighlighter>
        );
    }

    return <textarea ref={textareaRef} />;
};

export default MxCode;
