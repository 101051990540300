import {PostReactionSummaryModel} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetPostReactionsSummaryQuery = {
    postId: string;
};

const useGetPostReactionsSummary = (request: GetPostReactionsSummaryQuery) =>
    useGet<PostReactionSummaryModel>(`/api/posts/get-post-reactions-summary?${toQuery(request)}`, {errorMessage: 'GET api/posts/get-post-reactions-summary failed'
});

export default useGetPostReactionsSummary;
