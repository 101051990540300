import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useRef,
    useState
} from 'react';
import {AuthData, Authorization, AuthTokens} from '../types';
import {decodeJwt} from '../lib/jwt';
import isLocalHost from '../lib/isLocalHost';
import CustomClaimTypes from '../lib/CustomClaimTypes';
import unknownErrorToString from '../lib/unknownErrorToString.ts';
import {toast} from 'react-toastify';
import tryParseInt from '../lib/tryParseInt.ts';
import {UserStatus} from '../api/types.ts';
import {authRequest} from '../api/auth/authRequest.ts';

const tenSecondsInMilliseconds = 10 * 1000;

function tokenHasExpired(access_token: string) {
    const decodedToken = decodeJwt(access_token);
    const payload = decodedToken.payload;
    const expires = Number(payload.exp); // Seconds since Unix epoch
    const expiryDate = new Date(expires * 1000);
    const now = new Date();
    const diff = expiryDate.getTime() - now.getTime();

    return diff <= tenSecondsInMilliseconds;
}

function getAuthData(access_token: string | null | undefined): AuthData {
    if (!access_token || access_token == '') {
        return {
            isAuthenticated: false,
            roles: []
        };
    }

    const authData: AuthData = {
        isAuthenticated: true,
        roles: []
    };

    const decodedToken = decodeJwt(access_token);
    const payload = decodedToken.payload;

    const keys = [
        'unique_name',
        'email',
        'nameid',
        'aud',
        'iss',
        'exp',
        'iat',
        'nbf',
        'role',
        CustomClaimTypes.nickname,
        CustomClaimTypes.container_name,
        CustomClaimTypes.user_status,
        CustomClaimTypes.upload_limit,
        CustomClaimTypes.has_collections,
        CustomClaimTypes.signalr_token
    ];

    Object.keys(payload).forEach(key => {
        if (!keys.includes(key)) {
            authData[key] = payload[key];
        }
    });

    authData.name = payload.unique_name;
    authData.nickname = payload[CustomClaimTypes.nickname];
    authData.email = payload.email;
    authData.id = payload.nameid;
    authData.signalr_token = payload[CustomClaimTypes.signalr_token];
    authData.container_name = payload[CustomClaimTypes.container_name];
    authData.user_status = tryParseInt(
        payload[CustomClaimTypes.user_status],
        UserStatus.NotRegistered
    );
    authData.has_collections =
        payload[CustomClaimTypes.has_collections] == 'true';
    const parsedUploadLimit = parseInt(CustomClaimTypes.upload_limit);
    authData.upload_limit = isNaN(parsedUploadLimit)
        ? undefined
        : parsedUploadLimit;

    let roles = payload.role || [];

    if (typeof roles === 'string') {
        roles = [roles];
    }

    authData.roles = roles;

    return authData;
}

function getItem(key: 'access_token' | 'refresh_token' | 'redir') {
    if (isLocalHost) {
        return window.localStorage.getItem(key) ?? null;
    }

    return window.sessionStorage.getItem(key) ?? null;
}

function setItem(
    key: 'access_token' | 'refresh_token' | 'redir',
    value: string | null | undefined
) {
    if (isLocalHost) {
        window.localStorage.setItem(key, value ?? '');
    } else {
        window.sessionStorage.setItem(key, value ?? '');
    }
}

function removeItem(key: 'access_token' | 'refresh_token' | 'redir') {
    if (isLocalHost) {
        window.localStorage.removeItem(key);
    } else {
        window.sessionStorage.removeItem(key);
    }
}

export type AuthStateManager = {
    baseUri: string;
    access_token: string;
    refresh_token: string;
    authData: AuthData;

    loggedInUserId: string | null;
    isAuthenticated: boolean;
    isInRole(role: string): boolean;
    isSysAdmin(): boolean;
    isAuthorizedForResource(resource: Authorization): boolean;
    logout(): void;
    setSignInRedirect(redirect: string): void;
    removeSignInRedirect(): string | null;
    getAccessToken(): Promise<string>;
    onAuthenticated(authResponse: AuthTokens): AuthData;
    setAuthorizationHeader(headers: HeadersInit): Promise<void>;
};

const emptyAuthStateManager: AuthStateManager = {
    baseUri: '',
    access_token: '',
    refresh_token: '',
    authData: {
        isAuthenticated: false,
        roles: []
    },

    loggedInUserId: null,
    isAuthenticated: false,
    isInRole: (role: string) => (console.log(role), false),
    isSysAdmin: () => false,
    isAuthorizedForResource: (resource: Authorization) => (
        console.log(resource), false
    ),
    logout: () => {},
    setSignInRedirect: (redirect: string) => {
        console.log(redirect);
    },
    removeSignInRedirect: () => null,
    getAccessToken: async () => '',
    onAuthenticated: (authResponse: AuthTokens) => (
        console.log(authResponse),
        {
            isAuthenticated: false,
            roles: []
        }
    ),
    setAuthorizationHeader: async (headers: Headers) => {
        console.log(headers);
    }
};

const AuthStateManagerContext = createContext<AuthStateManager>(
    emptyAuthStateManager
);

const AuthStateManagerProvider = ({
    baseUri,
    children
}: {
    baseUri: string;
    children: ReactNode;
}) => {
    const authStateManager = useAuthStateManagerImpl(baseUri);

    return (
        <AuthStateManagerContext.Provider value={authStateManager}>
            {children}
        </AuthStateManagerContext.Provider>
    );
};

function useAuthStateManagerImpl(baseUri: string): AuthStateManager {
    const [access_token, set_access_token] = useState(
        () => getItem('access_token') ?? ''
    );

    const [refresh_token, set_refresh_token] = useState(
        () => getItem('refresh_token') ?? ''
    );

    const refreshingPromiseRef = useRef<Promise<string> | null>(null);

    const [authData, setAuthData] = useState(getAuthData(access_token));

    const loggedInUserId = authData.isAuthenticated
        ? authData.id ?? null
        : null;

    const isAuthenticated = authData.isAuthenticated;

    const isInRole = (role: string) => authData.roles.includes(role);

    const isSysAdmin = () => authData.roles.includes('sysadmin');

    const isAuthorizedForResource = (resource: Authorization): boolean => {
        if (!resource.authorize) {
            return true;
        }

        if (!isAuthenticated) {
            return false;
        }

        if (isSysAdmin()) {
            return true;
        }

        if (resource.roles && resource.roles.length > 0) {
            return resource.roles.some(r => isInRole(r));
        }

        return true;
    };

    const logout = () => {
        set_access_token('');
        set_refresh_token('');

        removeItem('access_token');
        removeItem('refresh_token');

        setAuthData({
            isAuthenticated: false,
            roles: []
        });
    };

    const setSignInRedirect = (redirect: string) => {
        setItem('redir', redirect);
    };

    const removeSignInRedirect = () => {
        const redirect = getItem('redir');
        removeItem('redir');
        return redirect;
    };

    function onAuthenticated(authResponse: AuthTokens) {
        set_access_token(authResponse.accessToken);
        set_refresh_token(authResponse.refreshToken);

        setItem('access_token', authResponse.accessToken);
        setItem('refresh_token', authResponse.refreshToken);

        const authData = getAuthData(authResponse.accessToken);

        setAuthData(authData);

        return authData;
    }

    const refreshTokenRequest = useCallback(async (): Promise<string> => {
        const authResponse = await authRequest({
            grant_type: 'refresh_token',
            refresh_token: authData.refreshToken
        });

        if ('access_token' in authResponse && 'refresh_token' in authResponse) {
            onAuthenticated({
                accessToken: authResponse.access_token,
                refreshToken: authResponse.refresh_token
            });

            return authResponse.access_token;
        } else {
            toast.error(unknownErrorToString(authResponse));
            logout();
            return '';
        }
    }, [authData.refreshToken]);

    const getAccessToken = useCallback(async (): Promise<string> => {
        if (access_token) {
            if (tokenHasExpired(access_token)) {
                if (refreshingPromiseRef.current) {
                    try {
                        return await refreshingPromiseRef.current;
                    } catch {
                        logout();
                    }
                } else {
                    const refreshPromise = refreshTokenRequest();
                    refreshingPromiseRef.current = refreshPromise;

                    try {
                        return await refreshPromise;
                    } catch {
                        toast.error(
                            'Your login session has expired, please login again.'
                        );
                        logout();
                    } finally {
                        refreshingPromiseRef.current = null; // Reset the ref
                    }
                }
            } else {
                return access_token;
            }
        }

        return '';
    }, [access_token, refreshTokenRequest, logout]);

    const setAuthorizationHeader = async (headers: HeadersInit) => {
        const access_token = await getAccessToken();
        if (access_token && access_token !== '') {
            (headers as Record<string, string>)[
                'Authorization'
            ] = `bearer ${access_token}`;
        }
    };

    return {
        baseUri,
        access_token,
        refresh_token,
        authData,
        loggedInUserId,
        isAuthenticated,
        isInRole,
        isSysAdmin,
        isAuthorizedForResource,
        logout,
        setSignInRedirect,
        removeSignInRedirect,
        getAccessToken,
        onAuthenticated,
        setAuthorizationHeader
    };
}

const useAuthStateManager = () => useContext(AuthStateManagerContext);

export {useAuthStateManager, AuthStateManagerProvider};
