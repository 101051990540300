import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup} from 'reactstrap';
import ImageResizer from './ImageResizer.tsx';
import ImageCrop from './ImageCrop.tsx';
import {ReactStateSetter} from '../../../types.ts';
import cloneFile from '../../../lib/cloneFile.ts';

enum ImageEditMode {
    Resize,
    Edit
}

interface ImageEditorProps {
    selectedImage: File;
    onImageEdited: ReactStateSetter<File>;
    editedImage: File | null;
    setEditedImage: ReactStateSetter<File | null>;
}

const ImageEditor: React.FC<ImageEditorProps> = ({
    selectedImage,
    onImageEdited,
    editedImage,
    setEditedImage
}) => {
    const [imageEditMode, setImageEditMode] = useState(ImageEditMode.Resize);

    useEffect(() => {
        setEditedImage(cloneFile(selectedImage));
    }, [selectedImage]);

    const handleApply = () => {
        if (editedImage) {
            setImageEditMode(ImageEditMode.Edit);
            onImageEdited(editedImage);
        }
    };

    return (
        <>
            <div className="mb-3">
                <ButtonGroup>
                    <Button
                        color={
                            imageEditMode == ImageEditMode.Resize
                                ? 'primary'
                                : 'secondary'
                        }
                        onClick={() => setImageEditMode(ImageEditMode.Resize)}
                    >
                        Resize | Rotate | Quality
                    </Button>
                    <Button
                        color={
                            imageEditMode == ImageEditMode.Edit
                                ? 'primary'
                                : 'secondary'
                        }
                        onClick={() => handleApply()}
                    >
                        Crop
                    </Button>
                </ButtonGroup>
            </div>

            {imageEditMode == ImageEditMode.Resize && editedImage && (
                <ImageResizer
                    editImage={selectedImage}
                    editedImage={editedImage}
                    setEditedImage={setEditedImage}
                />
            )}
            {imageEditMode == ImageEditMode.Edit && (
                <ImageCrop
                    editImage={selectedImage}
                    setEditImage={onImageEdited}
                />
            )}
        </>
    );
};

export default ImageEditor;
