import {
    EmptyResult,
    CollectionInfoModel,
    CollectionJoinCodeModel
} from '../types';
import {usePost} from './../useHttpHooks';

export type UpdateCollectionCommand = {
    collection: CollectionInfoModel;
    collectionJoinCode: CollectionJoinCodeModel | null;
};

const useUpdateCollection = () =>
    usePost<UpdateCollectionCommand, EmptyResult>(
        `/api/collections/update-collection`,
        {errorMessage: 'POST api/collections/update-collection failed'}
    );

export default useUpdateCollection;
