import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$createParagraphNode, $getRoot, $isDecoratorNode} from 'lexical';

import {useCallback, useEffect} from 'react';

const AddParagraphAtEditorEndPlugin = () => {
    const [editor] = useLexicalComposerContext();

    const $onClick = useCallback(
        (event: MouseEvent) => {
            editor.update(() => {
                const root = $getRoot();
                const lastChild = root.getLastChild();
                if ($isDecoratorNode(lastChild)) {
                    const lastChildElement = editor.getElementByKey(
                        lastChild.getKey()
                    );
                    if (lastChildElement == null) {
                        return false;
                    }

                    const lastChildElementBottom =
                        lastChildElement.getBoundingClientRect().bottom;

                    const {clientY} = event;

                    if (clientY > lastChildElementBottom) {
                        const newParagraph = $createParagraphNode();
                        lastChild.insertAfter(newParagraph);
                        return true;
                    }
                }
            });
            return false;
        },
        [editor]
    );

    useEffect(() => {
        editor.registerRootListener(rootElement => {
            rootElement?.addEventListener('click', $onClick);
        });
    }, [$onClick, editor]);
    return null;
};
export default AddParagraphAtEditorEndPlugin;
