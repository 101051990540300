import {useDelete} from './../useHttpHooks';

export type DeleteFolderCommand = {
    containerName: string;
    folderPath: string;
};

const useDeleteFolder = () =>
    useDelete<DeleteFolderCommand>(`/api/uploads/delete-folder`, {
        errorMessage: 'DELETE api/uploads/delete-folder failed'
    });

export default useDeleteFolder;
