import React from 'react';
import {CollectionJoinCodeModel} from '../../api/types.ts';
import CopyButton from '../shared/CopyButton.tsx';

interface InviteCodesProps {
    collectionJoinCode: CollectionJoinCodeModel | null;
    setCollectionJoinCode: React.Dispatch<
        React.SetStateAction<CollectionJoinCodeModel | null>
    >;
    membershipUrl: string;
    groupName: string;
}

const InviteCodes: React.FC<InviteCodesProps> = ({
    collectionJoinCode,
    setCollectionJoinCode,
    membershipUrl,
    groupName
}) => {
    const roles: Array<
        keyof Pick<CollectionJoinCodeModel, 'viewer' | 'contributor' | 'admin'>
    > = ['viewer', 'contributor', 'admin'];

    const getMailtoLink = (role: string, inviteCode: string) => {
        const subject = encodeURIComponent(
            `Invitation to join "${groupName}" on Mixiply`
        );
        const body = encodeURIComponent(
            `Hello,

You have been invited to join the group "${groupName}" on Mixiply as a ${role}. Please use the following invite code to join:

Invite Code: ${inviteCode}

Join Link: ${membershipUrl}

Instructions:

1. Visit the join link above.
2. Enter the invite code when prompted.
3. Complete your registration to join the group.

Best regards,
[Your Name]`
        );
        return `mailto:?subject=${subject}&body=${body}`;
    };

    return (
        <>
            {collectionJoinCode && (
                <>
                    <div className="form-text text-muted mb-4">
                        Invite codes allow users to join your group "{groupName}
                        " automatically with the specified role on Mixiply. You
                        can share these codes directly or send them via email
                        using the options below.
                    </div>

                    {roles.map(role => (
                        <div className="mb-4" key={role}>
                            <label htmlFor={role} className="form-label">
                                Invite Code for{' '}
                                {role.charAt(0).toUpperCase() + role.slice(1)}
                            </label>
                            <input
                                type="text"
                                id={role}
                                className="form-control"
                                maxLength={24}
                                value={collectionJoinCode[role] ?? ''}
                                onChange={e =>
                                    setCollectionJoinCode(prev => ({
                                        ...prev!,
                                        [role]: e.target.value
                                    }))
                                }
                            />
                            {collectionJoinCode[role] && (
                                <div className="d-flex justify-content-end">
                                    <a
                                        href={getMailtoLink(
                                            role,
                                            collectionJoinCode[role]!
                                        )}
                                        className="btn btn-primary btn-sm mt-3"
                                    >
                                        Email Invite Code
                                    </a>
                                </div>
                            )}
                        </div>
                    ))}

                    <div className="form-text text-muted mb-3">
                        You can also share the group join link directly. Users
                        will be prompted to enter an invite code when joining.
                    </div>
                    <div className="copy-link-container mb-2 d-flex align-items-center">
                        <input
                            type="text"
                            className="form-control me-2"
                            defaultValue={membershipUrl}
                            readOnly
                        />
                        <CopyButton
                            id="Copy_MembershipLink"
                            cssClass="btn btn-secondary"
                            copyText={membershipUrl}
                            title="Copy Join Link"
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default InviteCodes;
