import {UnknownObject} from '../types.ts';

export const elementDataSorter = (
    [, a]: [string, UnknownObject],
    [, b]: [string, UnknownObject]
) => {
    const ordinalPositionA = a.ordinalPosition as number | undefined;
    const ordinalPositionB = b.ordinalPosition as number | undefined;

    if (ordinalPositionA !== undefined && ordinalPositionB !== undefined) {
        return Number(ordinalPositionA) - Number(ordinalPositionB);
    } else if (ordinalPositionA !== undefined) {
        return -1;
    } else if (ordinalPositionB !== undefined) {
        return 1;
    } else {
        return 0;
    }
};

export default elementDataSorter;
