import {ReactNode} from 'react';

interface SpinnerIfLoadingProps {
    children?: ReactNode;
    loading: boolean;
    message?: string;
}

const SpinnerIfLoading: React.FC<SpinnerIfLoadingProps> = ({
    children,
    loading,
    message
}) => {
    if (loading) {
        return (
            <div className="w-100 d-flex justify-content-center align-items-center p-5">
                <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                {message && <div className="ms-3">{message}</div>}
            </div>
        );
    } else {
        return <>{children}</>;
    }
};

export default SpinnerIfLoading;
