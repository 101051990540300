export default function createObservableClass<T extends object>(
    classInstance: T,
    onUpdate: () => void,
    observedFields: (keyof T)[]
): T {
    return new Proxy(classInstance, {
        set(target, property, value) {
            const success = Reflect.set(target, property, value);
            if (success && observedFields.includes(property as keyof T)) {
                onUpdate();
            }
            return success;
        }
    });
}
