import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLanguage} from '@fortawesome/free-solid-svg-icons';

interface FloatingTranslationButtonProps {
    onClick: () => void;
}

const FloatingTranslationButton: React.FC<FloatingTranslationButtonProps> = ({
    onClick
}) => {
    const buttonStyle: React.CSSProperties = {
        position: 'fixed',
        left: '20px',
        top: '20%',
        transform: 'translateY(-50%)',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        fontSize: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        transition: 'all 0.3s ease',
        zIndex: 1000
    };

    const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.currentTarget.style.backgroundColor = '#0056b3';
        e.currentTarget.style.boxShadow = '0 4px 15px rgba(0, 0, 0, 0.3)';
    };

    const handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.currentTarget.style.backgroundColor = '#007bff';
        e.currentTarget.style.boxShadow = '0 2px 10px rgba(0, 0, 0, 0.2)';
    };

    return (
        <button
            onClick={onClick}
            style={buttonStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            title="Translate with AI"
        >
            <FontAwesomeIcon icon={faLanguage} />
        </button>
    );
};

export default FloatingTranslationButton;
