import {FileDataModel, Selection} from '../../api/types.ts';
import {FileFormat} from '../../lib/FileFormats.ts';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import AssetSelectAndUpload from './AssetSelectAndUpload.tsx';

export interface AssetSelectAndUploadModalOptions {
    selection: Selection;
    explanation?: string | null;
    formats?: FileFormat;
    fileSelectionChanged: (files: FileDataModel[]) => void;
    specificContainerName?: string;
}

interface AssetSelectAndUploadModalProps {
    options: AssetSelectAndUploadModalOptions;
    onClose: () => void;
}

const AssetSelectAndUploadModal: React.FC<AssetSelectAndUploadModalProps> = ({
    options,
    onClose
}) => {
    const {
        selection,
        explanation,
        formats,
        fileSelectionChanged,
        specificContainerName
    } = options;

    return (
        <Modal isOpen={true} toggle={onClose} fullscreen zIndex={999}>
            <ModalHeader>
                {selection == Selection.Single ? 'Select file' : 'Select files'}
            </ModalHeader>
            <ModalBody>
                <AssetSelectAndUpload
                    selection={selection}
                    explanation={explanation}
                    formats={formats}
                    fileSelectionChanged={files => fileSelectionChanged(files)}
                    specificContainerName={specificContainerName}
                />
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClose}
                >
                    Cancel
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default AssetSelectAndUploadModal;
