import paper from 'paper';
import ColorScheme from 'color-scheme';

export default function createThumbnail() {
    const canvas = document.createElement('canvas');
    const paths = [];

    canvas.width = 280;
    canvas.height = 200;

    const scheme = new ColorScheme();

    let paletteInSequence = true;
    let colorIndex = 0;

    paper.setup(canvas);

    const hue = getRandomInt(0, 360) - 1;
    const clSchemes = [
        'monochromatic',
        'contrast',
        'triade',
        'tetrade',
        'analogic'
    ];

    scheme
        .from_hue(hue)
        .scheme(clSchemes[getRandomInt(0, 4)])
        .distance(1)
        .add_complement(true)
        .web_safe(true);

    const colors = scheme.colors();
    let color = '#' + colors[getRandomInt(0, colors.length - 1)];
    let path = new paper.Path.Rectangle(
        new paper.Point(0, 0),
        new paper.Point(2800, 2000)
    );
    path.style = {fillColor: new paper.Color(color)};

    let effectAReplica = false;
    let horizontal = false;
    let inclined = false;
    let oppositeReplicaAngle = false;
    let distanceBetweenOpposites = 0;
    let circle = false;

    for (let i = 0; i < getRandomInt(5, 500); i++) {
        if (paletteInSequence) {
            colorIndex++;
            if (colorIndex >= colors.length) {
                colorIndex = 0;
            }
        } else {
            colorIndex = getRandomInt(0, colors.length - 1);
        }

        let color = '#' + colors[colorIndex];

        if (!effectAReplica) {
            circle = getRandomInt(0, 3) === 0;
        }
        if (circle && !effectAReplica) {
            myPath = new paper.Path.Circle(
                new paper.Point(getRandomInt(0, 280), getRandomInt(0, 200)),
                getRandomInt(0, 200)
            );
        } else {
            myPath = new paper.Path();
        }

        myPath.style = {
            fillColor: new paper.Color(color),
            strokeWidth: 0,
            shadowColor: ' rgba(0,0,0,0.01)',
            shadowBlur: 20,
            shadowOffset: 1
        };

        if (effectAReplica) {
            var myPath = paths[i - 1].clone();
            myPath.style = {
                fillColor: new paper.Color(color),
                strokeWidth: 0,
                shadowColor: ' rgba(0,0,0,0.01)',
                shadowBlur: 20,
                shadowOffset: 1
            };

            distanceBetweenOpposites = getRandomInt(-200, 200); // that does not happen...
            while (Math.abs(distanceBetweenOpposites) < 20) {
                distanceBetweenOpposites = getRandomInt(-200, 200); // that does not happen...
            }

            if (circle) {
                let scale = 1 - getRandomInt(1, 5) / 10;
                myPath.scale(scale);
            } else {
                if (distanceBetweenOpposites < 0) {
                    if (horizontal) {
                        //mover arriba
                        myPath.translate(
                            0,
                            -Math.abs(distanceBetweenOpposites)
                        );
                    } else {
                        //mover izquierda
                        myPath.translate(
                            -Math.abs(distanceBetweenOpposites),
                            0
                        );
                    }
                } else {
                    if (horizontal) {
                        //mover abajo
                        myPath.translate(0, Math.abs(distanceBetweenOpposites));
                    } else {
                        //mover derecha
                        myPath.translate(Math.abs(distanceBetweenOpposites), 0);
                    }
                }
            }
        } else if (!circle) {
            horizontal = getRandomInt(0, 1) === 0;
            inclined = getRandomInt(0, 1) === 0;
            oppositeReplicaAngle = getRandomInt(0, 1) === 0;

            if (horizontal) {
                distanceBetweenOpposites = getRandomInt(-200, 200); // that does not happen...
                while (Math.abs(distanceBetweenOpposites) < 20) {
                    distanceBetweenOpposites = getRandomInt(-200, 200); // that does not happen...
                }

                // create a horizontal line
                let p1 = new paper.Point(0, getRandomInt(0, 200));
                let p2 = new paper.Point(
                    280,
                    inclined ? getRandomInt(0, 200) : p1.y
                );

                let p4 = new paper.Point(
                    0,
                    oppositeReplicaAngle ? p1.y + distanceBetweenOpposites : 200
                );
                let p3 = new paper.Point(
                    280,
                    oppositeReplicaAngle ? p2.y + distanceBetweenOpposites : 200
                );

                myPath.add(p1);
                myPath.add(p2);
                myPath.add(p3);
                myPath.add(p4);
            } else {
                distanceBetweenOpposites = getRandomInt(-200, 200); // that does not happen...
                while (Math.abs(distanceBetweenOpposites) < 20) {
                    distanceBetweenOpposites = getRandomInt(-200, 200); // that does not happen...
                }

                // create a vertical line
                let p1 = new paper.Point(getRandomInt(0, 280), 0);
                let p2 = new paper.Point(
                    inclined ? getRandomInt(0, 280) : p1.x,
                    200
                );

                let p4 = new paper.Point(
                    oppositeReplicaAngle
                        ? p1.x + distanceBetweenOpposites
                        : 280,
                    0
                );
                let p3 = new paper.Point(
                    oppositeReplicaAngle
                        ? p2.x + distanceBetweenOpposites
                        : 280,
                    200
                );

                myPath.add(p1);
                myPath.add(p2);
                myPath.add(p3);
                myPath.add(p4);
            }
        }

        if (getRandomInt(0, 3) === 1 && i > 0) {
            let pathx = paths[i - 1].clone();
            let operation = getRandomInt(0, 10);
            if (operation === 1) {
                myPath.unite(pathx);
            }

            if (operation === 2) {
                myPath.intersect(pathx);
            }

            if (operation >= 3) {
                myPath.divide(pathx);
            }

            if (operation === 0) {
                myPath.subtract(pathx);
            }

            if (operation === 0) {
                myPath.exclude(pathx);
            }

            pathx.visible = false;
            myPath.style = {
                fillColor: new paper.Color(
                    '#' + colors[getRandomInt(0, colors.length - 1)]
                ),
                strokeWidth: 0,
                shadowColor: ' rgba(0,0,0,0.01)',
                shadowBlur: 20,
                shadowOffset: 1
            };
        }

        paths[i] = myPath;

        myPath.closed = true;
        effectAReplica = getRandomInt(0, 1) === 0;
    }

    paper.view.viewSize = new paper.Size(
        canvas.width * 1.6,
        canvas.height * 1.6
    );
    paper.view.draw();

    const dataURL = canvas.toDataURL('image/png');

    return dataURL;
}

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}
