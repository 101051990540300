import React, {useState} from 'react';
import {MixModel, ProjectDataIndex} from '../../api/types.ts';
import useProjectData from '../../project_data/useProjectData.ts';
import {SceneData, SceneEntity, SceneEvents} from './types.ts';
import {v4 as uuidv4} from 'uuid';
import {Button, Collapse} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faChevronUp,
    faPlus,
    faSink
} from '@fortawesome/free-solid-svg-icons';
import SceneEntityControl from './SceneEntityControl.tsx';
import AddModelButton from './AddModelButton.tsx';
import getColorHexValue from './getColorHexValue.ts';

interface SceneBuilderProps {
    project: MixModel;
    dataIndex: ProjectDataIndex;
}

const SceneBuilder: React.FC<SceneBuilderProps> = ({project, dataIndex}) => {
    const {id} = project;
    const projectId = id!;

    const {projectData, manipulation} = useProjectData({projectId, dataIndex});
    const {mergeData} = manipulation;

    const sceneData = projectData as SceneData;

    const [openEntities, setOpenEntities] = useState<string[]>([]);

    const toggleEntity = (elementId: string) => {
        setOpenEntities(prevOpenEntities =>
            prevOpenEntities.includes(elementId)
                ? prevOpenEntities.filter(id => id !== elementId)
                : [...prevOpenEntities, elementId]
        );
    };

    const addEntity = (entity: Omit<SceneEntity, 'elementId'>) => {
        const elementId = uuidv4();
        mergeData(elementId, {...entity, elementId}, SceneEvents.EntityAdd);
        setOpenEntities([elementId]);
    };

    const addModel = (url: string) => {
        addEntity({
            type: 'model',
            url,
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1}
        } as Omit<Extract<SceneEntity, {type: 'model'}>, 'elementId'>);
    };

    const addPointLight = () => {
        addEntity({
            type: 'pointLight',
            position: {x: 0, y: 0, z: 0},
            intensity: 1
        } as Omit<Extract<SceneEntity, {type: 'pointLight'}>, 'elementId'>);
    };

    const addDirectionalLight = () => {
        addEntity({
            type: 'directionalLight',
            position: {x: 0, y: 0, z: 0},
            direction: {x: 0, y: -1, z: 0},
            intensity: 0.5
        } as Omit<
            Extract<SceneEntity, {type: 'directionalLight'}>,
            'elementId'
        >);
    };

    const addSpotLight = () => {
        addEntity({
            type: 'spotLight',
            position: {x: 0, y: 0, z: 0},
            direction: {x: 0, y: -1, z: 0},
            angle: Math.PI / 4,
            exponent: 1,
            intensity: 0.8
        } as Omit<Extract<SceneEntity, {type: 'spotLight'}>, 'elementId'>);
    };

    const addHemisphericLight = () => {
        addEntity({
            type: 'hemisphericLight',
            direction: {x: 0, y: 1, z: 0},
            intensity: 0.5
        } as Omit<
            Extract<SceneEntity, {type: 'hemisphericLight'}>,
            'elementId'
        >);
    };

    const addTorusKnot = () => {
        addEntity({
            type: 'torusKnot',
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1},
            radius: 1,
            tube: 0.4,
            radialSegments: 64,
            tubularSegments: 64,
            p: 2,
            q: 3
        } as Omit<Extract<SceneEntity, {type: 'torusKnot'}>, 'elementId'>);
    };

    const addSphere = () => {
        addEntity({
            type: 'sphere',
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1},
            diameter: 2,
            material: {color: {r: 1, g: 0, b: 0}}
        } as Omit<Extract<SceneEntity, {type: 'sphere'}>, 'elementId'>);
    };

    const addBox = () => {
        addEntity({
            type: 'box',
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1},
            size: 1,
            material: {color: {r: 0, g: 1, b: 0}}
        } as Omit<Extract<SceneEntity, {type: 'box'}>, 'elementId'>);
    };

    const addTorus = () => {
        addEntity({
            type: 'torus',
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1},
            diameter: 1,
            thickness: 0.4,
            material: {color: {r: 0, g: 0, b: 1}}
        } as Omit<Extract<SceneEntity, {type: 'torus'}>, 'elementId'>);
    };

    const addCylinder = () => {
        addEntity({
            type: 'cylinder',
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1},
            height: 2,
            diameter: 1,
            material: {color: {r: 1, g: 1, b: 0}}
        } as Omit<Extract<SceneEntity, {type: 'cylinder'}>, 'elementId'>);
    };

    const addPlane = () => {
        addEntity({
            type: 'plane',
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1},
            width: 10,
            height: 10,
            material: {color: {r: 0.8, g: 0.8, b: 0.8}}
        } as Omit<Extract<SceneEntity, {type: 'plane'}>, 'elementId'>);
    };

    const addDisc = () => {
        addEntity({
            type: 'disc',
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1},
            radius: 1,
            material: {color: {r: 0, g: 1, b: 1}}
        } as Omit<Extract<SceneEntity, {type: 'disc'}>, 'elementId'>);
    };

    const addIcosphere = () => {
        addEntity({
            type: 'icosphere',
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1},
            radius: 1,
            subdivisions: 2,
            material: {color: {r: 1, g: 0, b: 1}}
        } as Omit<Extract<SceneEntity, {type: 'icosphere'}>, 'elementId'>);
    };

    const addLines = () => {
        addEntity({
            type: 'lines',
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1},
            points: [
                {x: 0, y: 0, z: 0},
                {x: 1, y: 1, z: 1},
                {x: 2, y: 0, z: 0}
            ],
            colors: [
                {r: 1, g: 0, b: 0, a: 1},
                {r: 0, g: 1, b: 0, a: 1},
                {r: 0, g: 0, b: 1, a: 1}
            ]
        } as Omit<Extract<SceneEntity, {type: 'lines'}>, 'elementId'>);
    };

    const addDashedLines = () => {
        addEntity({
            type: 'dashedLines',
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1},
            points: [
                {x: 0, y: 0, z: 0},
                {x: 1, y: 1, z: 1},
                {x: 2, y: 0, z: 0}
            ],
            dashSize: 0.2,
            gapSize: 0.1,
            dashNb: 10,
            colors: [
                {r: 1, g: 1, b: 0, a: 1},
                {r: 0, g: 1, b: 1, a: 1},
                {r: 1, g: 0, b: 1, a: 1}
            ]
        } as Omit<Extract<SceneEntity, {type: 'dashedLines'}>, 'elementId'>);
    };

    const addExtrudeShape = () => {
        addEntity({
            type: 'extrudeShape',
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1},
            shape: [
                {x: 0, y: 0, z: 0},
                {x: 1, y: 0, z: 0},
                {x: 1, y: 1, z: 0},
                {x: 0, y: 1, z: 0}
            ],
            path: [
                {x: 0, y: 0, z: 0},
                {x: 0, y: 0, z: 1},
                {x: 0, y: 0, z: 2}
            ],
            scale: 0.5,
            cap: 3,
            material: {color: {r: 1, g: 0.5, b: 0}}
        } as Omit<Extract<SceneEntity, {type: 'extrudeShape'}>, 'elementId'>);
    };

    const addLathe = () => {
        addEntity({
            type: 'lathe',
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1},
            shape: [
                {x: 0, y: 0, z: 0},
                {x: 0.5, y: 0.5, z: 0},
                {x: 0, y: 1, z: 0},
                {x: 0, y: 1.5, z: 0}
            ],
            segments: 32,
            cap: 3,
            material: {color: {r: 0.5, g: 0, b: 1}}
        } as Omit<Extract<SceneEntity, {type: 'lathe'}>, 'elementId'>);
    };

    /*
    const addText = () => {
        addEntity({
            type: 'text',
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1},
            text: 'Hello from Mixiply!',
            color: '#0000FF',
            fontSize: 36
        } as Omit<Extract<SceneEntity, {type: 'text'}>, 'elementId'>);
    };
    */

    const addGround = () => {
        addEntity({
            type: 'ground',
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            scaling: {x: 1, y: 1, z: 1},
            width: 20,
            height: 20,
            subdivisions: 100,
            material: {color: {r: 0.5, g: 0.5, b: 0.5}}
        } as Omit<Extract<SceneEntity, {type: 'ground'}>, 'elementId'>);
    };

    const addKitchenSink = () => {
        const objects = [
            {
                type: 'sphere',
                position: {x: 0, y: 1, z: 0},
                rotation: {x: 0, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1},
                diameter: 2,
                material: {color: {r: 1, g: 0, b: 0}}
            },
            {
                type: 'box',
                position: {x: 2, y: 1, z: 0},
                rotation: {x: 0, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1},
                size: 1,
                material: {color: {r: 0, g: 1, b: 0}}
            },
            {
                type: 'torus',
                position: {x: -2, y: 1, z: 0},
                rotation: {x: 0, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1},
                diameter: 1,
                thickness: 0.4,
                material: {color: {r: 0, g: 0, b: 1}}
            },
            {
                type: 'cylinder',
                position: {x: 4, y: 1, z: 0},
                rotation: {x: 0, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1},
                height: 2,
                diameter: 1,
                material: {color: {r: 1, g: 1, b: 0}}
            },
            {
                type: 'plane',
                position: {x: 0, y: 0, z: 0},
                rotation: {x: Math.PI / 2, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1},
                width: 10,
                height: 10,
                material: {color: {r: 0.8, g: 0.8, b: 0.8}}
            },
            {
                type: 'disc',
                position: {x: -4, y: 1, z: 0},
                rotation: {x: 0, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1},
                radius: 1,
                material: {color: {r: 0, g: 1, b: 1}}
            },
            {
                type: 'icosphere',
                position: {x: 6, y: 1, z: 0},
                rotation: {x: 0, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1},
                radius: 1,
                subdivisions: 2,
                material: {color: {r: 1, g: 0, b: 1}}
            },
            {
                type: 'model',
                url: 'https://mixiplycontent.blob.core.windows.net/usefulstuff/51c392cb-1176-463a-a6f9-08d6bd51ab08/Duck.gltf',
                position: {x: 0, y: 0, z: -2},
                rotation: {x: 0, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1}
            },
            {
                type: 'pointLight',
                position: {x: 0, y: 5, z: 0},
                intensity: 1
            },
            {
                type: 'directionalLight',
                position: {x: 0, y: 5, z: 0},
                direction: {x: 0, y: -1, z: 0},
                intensity: 0.5
            },
            {
                type: 'spotLight',
                position: {x: 0, y: 5, z: 0},
                direction: {x: 0, y: -1, z: 0},
                angle: Math.PI / 4,
                exponent: 1,
                intensity: 0.8
            },
            {
                type: 'hemisphericLight',
                direction: {x: 0, y: 1, z: 0},
                intensity: 0.5
            },
            {
                type: 'torusKnot',
                position: {x: -6, y: 1, z: 0},
                rotation: {x: 0, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1},
                radius: 1,
                tube: 0.4,
                radialSegments: 64,
                tubularSegments: 64,
                p: 2,
                q: 3
            },
            {
                type: 'ground',
                position: {x: 0, y: 0, z: 0},
                rotation: {x: 0, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1},
                width: 20,
                height: 20,
                subdivisions: 100,
                material: {color: {r: 0.5, g: 0.5, b: 0.5}},
                heightMap:
                    'https://mixiplycontent.blob.core.windows.net/c-seriousplayconference/Future%20school/%F0%9D%93%97%F0%9D%93%B8%F0%9D%93%BB%F0%9D%93%BB%F0%9D%93%B8%F0%9D%93%BB%20%F0%9D%93%9C%F0%9D%93%BE%F0%9D%93%BB%F0%9D%93%AD%F0%9D%93%AE%F0%9D%93%BB%20%F0%9D%93%9C%F0%9D%94%82%F0%9D%93%BC%F0%9D%93%BD%F0%9D%93%AE%F0%9D%93%BB%F0%9D%94%82%20%F0%9D%93%A1%F0%9D%93%B8%F0%9D%93%B8%F0%9D%93%B6/Screenshot.png'
            },
            {
                type: 'lines',
                position: {x: 0, y: 2, z: 0},
                rotation: {x: 0, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1},
                points: [
                    {x: 0, y: 0, z: 0},
                    {x: 1, y: 1, z: 1},
                    {x: 2, y: 0, z: 0}
                ],
                colors: [
                    {r: 1, g: 0, b: 0, a: 1},
                    {r: 0, g: 1, b: 0, a: 1},
                    {r: 0, g: 0, b: 1, a: 1}
                ]
            },
            {
                type: 'dashedLines',
                position: {x: 0, y: 3, z: 0},
                rotation: {x: 0, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1},
                points: [
                    {x: 0, y: 0, z: 0},
                    {x: 1, y: 1, z: 1},
                    {x: 2, y: 0, z: 0}
                ],
                dashSize: 0.2,
                gapSize: 0.1,
                dashNb: 10,
                colors: [
                    {r: 1, g: 1, b: 0, a: 1},
                    {r: 0, g: 1, b: 1, a: 1},
                    {r: 1, g: 0, b: 1, a: 1}
                ]
            },
            {
                type: 'extrudeShape',
                position: {x: 4, y: 1, z: 0},
                rotation: {x: 0, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1},
                shape: [
                    {x: 0, y: 0, z: 0},
                    {x: 1, y: 0, z: 0},
                    {x: 1, y: 1, z: 0},
                    {x: 0, y: 1, z: 0}
                ],
                path: [
                    {x: 0, y: 0, z: 0},
                    {x: 0, y: 0, z: 1},
                    {x: 0, y: 0, z: 2}
                ],
                scale: 0.5,
                cap: 3, // BABYLON.Mesh.CAP_ALL
                material: {color: {r: 1, g: 0.5, b: 0}}
            },
            {
                type: 'lathe',
                position: {x: 6, y: 1, z: 0},
                rotation: {x: 0, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1},
                shape: [
                    {x: 0, y: 0, z: 0},
                    {x: 0.5, y: 0.5, z: 0},
                    {x: 0, y: 1, z: 0},
                    {x: 0, y: 1.5, z: 0}
                ],
                segments: 32,
                cap: 3, // BABYLON.Mesh.CAP_ALL
                material: {color: {r: 0.5, g: 0, b: 1}}
            } /*,
            {
                type: 'text',
                position: {x: 0, y: 2, z: 0},
                rotation: {x: 0, y: 0, z: 0},
                scaling: {x: 1, y: 1, z: 1},
                text: 'Hello from Mixiply!',
                color: '#0000FF', // Blue color in hexadecimal format
                fontSize: 36
            }*/
        ];

        objects.forEach(object => {
            addEntity(object as Omit<SceneEntity, 'elementId'>);
        });
    };

    const labels: Record<string, string> = {
        sphere: 'Sphere',
        box: 'Box',
        torus: 'Torus',
        cylinder: 'Cylinder',
        plane: 'Plane',
        disc: 'Disc',
        icosphere: 'Icosphere',
        model: 'Model',
        pointLight: 'Point Light',
        directionalLight: 'Directional Light',
        spotLight: 'Spot Light',
        hemisphericLight: 'Hemispheric Light',
        torusKnot: 'Torus Knot',
        ground: 'Ground',
        lines: 'Lines',
        dashedLines: 'Dashed Lines',
        extrudeShape: 'Extrude Shape',
        lathe: 'Lathe',
        text: 'Text'
    };

    const refreshIframe = () => {
        const iframe = document.getElementById('MixSceneIframe');
        if (iframe && iframe instanceof HTMLIFrameElement) {
            // eslint-disable-next-line no-self-assign
            iframe.src = iframe.src;
        }
    };

    const getLabel = (entity: SceneEntity) => {
        if (entity.type == 'model') {
            const fileName = entity.url.split('/').pop();

            return labels[entity.type] + ' - ' + fileName;
        }

        return labels[entity.type];
    };

    return (
        <>
            {Object.entries(sceneData).map(([elementId, entity]) => (
                <div key={elementId} className="mb-2">
                    <Button
                        color="link"
                        onClick={() => toggleEntity(elementId)}
                        className="d-flex align-items-center w-100 text-start"
                    >
                        <FontAwesomeIcon
                            icon={
                                openEntities.includes(elementId)
                                    ? faChevronUp
                                    : faChevronDown
                            }
                            className="me-2"
                            size="sm"
                        />
                        {getLabel(entity)}
                        {entity.material && entity.material.color && (
                            <div
                                style={{
                                    backgroundColor: getColorHexValue(
                                        entity.material.color
                                    ),
                                    width: '12px',
                                    height: '12px',
                                    borderRadius: '50%',
                                    marginLeft: '8px'
                                }}
                            />
                        )}
                    </Button>
                    <Collapse isOpen={openEntities.includes(elementId)}>
                        <SceneEntityControl
                            entity={entity}
                            elementId={elementId}
                            manipulation={manipulation}
                            refreshIframe={refreshIframe}
                        />
                    </Collapse>
                </div>
            ))}

            <AddModelButton onModelSelected={addModel} />

            <Button
                color="primary"
                onClick={addSphere}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Sphere
            </Button>

            <Button color="primary" onClick={addBox} className="me-2" size="sm">
                <FontAwesomeIcon icon={faPlus} /> Box
            </Button>

            <Button
                color="primary"
                onClick={addTorus}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Torus
            </Button>

            <Button
                color="primary"
                onClick={addCylinder}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Cylinder
            </Button>

            <Button
                color="primary"
                onClick={addPlane}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Plane
            </Button>

            <Button
                color="primary"
                onClick={addDisc}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Disc
            </Button>

            <Button
                color="primary"
                onClick={addIcosphere}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Icosphere
            </Button>

            <Button
                color="primary"
                onClick={addPointLight}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Point Light
            </Button>

            <Button
                color="primary"
                onClick={addDirectionalLight}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Directional Light
            </Button>

            <Button
                color="primary"
                onClick={addSpotLight}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Spot Light
            </Button>

            <Button
                color="primary"
                onClick={addHemisphericLight}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Hemispheric Light
            </Button>

            <Button
                color="primary"
                onClick={addTorusKnot}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Torus Knot
            </Button>

            <Button
                color="primary"
                onClick={addGround}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Ground
            </Button>

            <Button
                color="primary"
                onClick={addLines}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Lines
            </Button>

            <Button
                color="primary"
                onClick={addDashedLines}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Dashed Lines
            </Button>

            <Button
                color="primary"
                onClick={addExtrudeShape}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Extrude Shape
            </Button>

            <Button
                color="primary"
                onClick={addLathe}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Lathe
            </Button>

            {/*<Button
                color="primary"
                onClick={addText}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Text
            </Button>*/}

            <Button
                color="primary"
                onClick={addKitchenSink}
                className="me-2"
                size="sm"
            >
                <FontAwesomeIcon icon={faSink} /> Kitchen Sink (adds multiple
                types)
            </Button>

            <div className="d-flex justify-content-end mt-3">
                <Button
                    onClick={() => {
                        refreshIframe();
                    }}
                    color="primary"
                >
                    Refresh
                </Button>
            </div>
        </>
    );
};

export default SceneBuilder;
