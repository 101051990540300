export function getSessionAccessToken(key = 'access_token') {
    return (
        JSON.parse(
            window.sessionStorage[key] || window.localStorage[key] || '""'
        ) || ''
    );
}

export function decodeJwt(token: string) {
    function base64UrlDecode(a: string) {
        let b = a.replace(/-/g, '+').replace(/_/g, '/');
        switch (b.length % 4) {
            case 0:
                break;
            case 2:
                b += '==';
                break;
            case 3:
                b += '=';
                break;
            default:
                throw 'Illegal base64url string!';
        }

        const c = window.atob(b);
        try {
            return decodeURIComponent(escape(c));
        } catch (d) {
            return c;
        }
    }

    const segments = token.split('.');

    if (segments.length !== 3) {
        throw new Error('Not enough or too many segments');
    }

    // All segment should be base64
    const headerSeg = segments[0];
    const payloadSeg = segments[1];
    const signatureSeg = segments[2];

    // base64 decode and parse JSON
    const header = JSON.parse(base64UrlDecode(headerSeg));
    const payload = JSON.parse(base64UrlDecode(payloadSeg));

    return {
        header: header,
        payload: payload,
        signature: signatureSeg
    };
}
