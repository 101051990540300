import {MixesResult, MixType, MatchOperator, Pagination} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetMixesQuery = {
    collection: string;
    search: string;
    mixType: MixType;
    myMixes: boolean;
    tags: string[];
    matchTags: MatchOperator;
    pagination: Pagination;
};

const useGetMixes = (request: GetMixesQuery) =>
    useGet<MixesResult>(`/api/mixes/get-mixes?${toQuery(request)}`, {
        errorMessage: 'GET api/mixes/get-mixes failed'
    });

export default useGetMixes;
