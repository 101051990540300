import {usePost} from './../useHttpHooks';

export type ReactToPostCommand = {
    postId: string;
    parentPostId: string | null;
    emojiCode: string;
};

const useReactToPost = () =>
    usePost<ReactToPostCommand>(`/api/posts/react-to-post`, {
        errorMessage: 'POST api/posts/react-to-post failed'
    });

export default useReactToPost;
