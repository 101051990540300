import React, {useState} from 'react';
import {Vector3} from './types.ts';
import {FormGroup, Label, Input, Button} from 'reactstrap';
import SpatialCoordinateEditor from './SpatialCoordinateEditor.tsx';

interface ExtrudeShapeEditorProps {
    shape: Vector3[];
    path: Vector3[];
    scale?: number;
    rotation?: number;
    cap?: number;
    onShapeChange: (shape: Vector3[]) => void;
    onPathChange: (path: Vector3[]) => void;
    onScaleChange: (scale: number | undefined) => void;
    onRotationChange: (rotation: number | undefined) => void;
    onCapChange: (cap: number | undefined) => void;
}

const ExtrudeShapeEditor: React.FC<ExtrudeShapeEditorProps> = ({
    shape,
    path,
    scale,
    rotation,
    cap,
    onShapeChange,
    onPathChange,
    onScaleChange,
    onRotationChange,
    onCapChange
}) => {
    const [currentShape, setCurrentShape] = useState<Vector3[]>(shape);
    const [currentPath, setCurrentPath] = useState<Vector3[]>(path);

    const handleScaleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onScaleChange(isNaN(value) ? undefined : value);
    };

    const handleRotationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onRotationChange(isNaN(value) ? undefined : value);
    };

    const handleCapChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        onCapChange(isNaN(value) ? undefined : value);
    };

    const handleShapeChange = (index: number, coordinate: Vector3) => {
        const updatedShape = [...currentShape];
        updatedShape[index] = coordinate;
        setCurrentShape(updatedShape);
    };

    const handlePathChange = (index: number, coordinate: Vector3) => {
        const updatedPath = [...currentPath];
        updatedPath[index] = coordinate;
        setCurrentPath(updatedPath);
    };

    const addShapePoint = () => {
        const newPoint: Vector3 = {x: 0, y: 0, z: 0};
        setCurrentShape([...currentShape, newPoint]);
    };

    const removeShapePoint = (index: number) => {
        const updatedShape = currentShape.filter((_, i) => i !== index);
        setCurrentShape(updatedShape);
    };

    const addPathPoint = () => {
        const newPoint: Vector3 = {x: 0, y: 0, z: 0};
        setCurrentPath([...currentPath, newPoint]);
    };

    const removePathPoint = (index: number) => {
        const updatedPath = currentPath.filter((_, i) => i !== index);
        setCurrentPath(updatedPath);
    };

    const applyShapeChanges = () => {
        onShapeChange(currentShape);
    };

    const applyPathChanges = () => {
        onPathChange(currentPath);
    };

    return (
        <>
            <FormGroup>
                <Label>Scale:</Label>
                <Input
                    type="number"
                    step="0.1"
                    value={scale ?? ''}
                    onChange={handleScaleChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Rotation:</Label>
                <Input
                    type="number"
                    step="0.1"
                    value={rotation ?? ''}
                    onChange={handleRotationChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Cap:</Label>
                <Input
                    type="number"
                    step="1"
                    value={cap ?? ''}
                    onChange={handleCapChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Shape:</Label>
                {currentShape.map((point, index) => (
                    <div key={index}>
                        <SpatialCoordinateEditor
                            label={`Point ${index + 1}`}
                            coordinate={point}
                            onChange={coordinate =>
                                handleShapeChange(index, coordinate)
                            }
                        />
                        <Button
                            color="danger"
                            onClick={() => removeShapePoint(index)}
                        >
                            Remove Point
                        </Button>
                    </div>
                ))}
                <Button color="primary" onClick={addShapePoint}>
                    Add Point
                </Button>
                <Button color="success" onClick={applyShapeChanges}>
                    Apply Shape Changes
                </Button>
            </FormGroup>
            <FormGroup>
                <Label>Path:</Label>
                {currentPath.map((point, index) => (
                    <div key={index}>
                        <SpatialCoordinateEditor
                            label={`Point ${index + 1}`}
                            coordinate={point}
                            onChange={coordinate =>
                                handlePathChange(index, coordinate)
                            }
                        />
                        <Button
                            color="danger"
                            onClick={() => removePathPoint(index)}
                        >
                            Remove Point
                        </Button>
                    </div>
                ))}
                <Button color="primary" onClick={addPathPoint}>
                    Add Point
                </Button>
                <Button color="success" onClick={applyPathChanges}>
                    Apply Path Changes
                </Button>
            </FormGroup>
        </>
    );
};

export default ExtrudeShapeEditor;
