import React, {useState, useEffect} from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap';
import {ChannelInfoModel} from '../../api/types.ts';
import ConfirmActionButton from '../shared/ConfirmActionButton.tsx';

interface ChannelSettingsModalProps {
    channelInfo: ChannelInfoModel;
    onSave: (newChannelInfo: ChannelInfoModel) => void;
    onArchive: (newChannelInfo: ChannelInfoModel) => void;
    onDismiss: () => void;
}

const ChannelSettingsModal: React.FC<ChannelSettingsModalProps> = ({
    channelInfo,
    onSave,
    onArchive,
    onDismiss
}) => {
    const [editedChannelInfo, setEditedChannelInfo] =
        useState<ChannelInfoModel>(channelInfo);
    const [startsAsDefault, setStartsAsDefault] = useState<boolean>(false);

    useEffect(() => {
        setStartsAsDefault(channelInfo.isDefault);
    }, [channelInfo]);

    const handleSave = () => {
        onSave(editedChannelInfo);
    };

    const handleArchive = () => {
        onArchive(editedChannelInfo);
    };

    return (
        <Modal
            isOpen={true}
            toggle={onDismiss}
            className="modal-dialog modal-dialog-scrollable modal-lg"
        >
            <ModalHeader toggle={onDismiss}>Edit channel</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="channelName">Name</Label>
                        <Input
                            type="text"
                            id="channelName"
                            maxLength={64}
                            value={editedChannelInfo.name}
                            onChange={e =>
                                setEditedChannelInfo({
                                    ...editedChannelInfo,
                                    name: e.target.value
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="channelTopic">Topic</Label>
                        <Input
                            type="textarea"
                            id="channelTopic"
                            maxLength={1024}
                            value={editedChannelInfo.topic}
                            onChange={e =>
                                setEditedChannelInfo({
                                    ...editedChannelInfo,
                                    topic: e.target.value
                                })
                            }
                        />
                    </FormGroup>
                    {!startsAsDefault && (
                        <FormGroup>
                            <Label for="channelDefault">Default channel</Label>
                            <Input
                                type="checkbox"
                                id="channelDefault"
                                checked={editedChannelInfo.isDefault}
                                onChange={e =>
                                    setEditedChannelInfo({
                                        ...editedChannelInfo,
                                        isDefault: e.target.checked
                                    })
                                }
                            />
                        </FormGroup>
                    )}
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onDismiss}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={handleSave}
                    className="float-end ms-3"
                >
                    Save
                </Button>
                {!startsAsDefault && editedChannelInfo.id && (
                    <ConfirmActionButton
                        id="channelArchiveButton"
                        cssClass="btn btn-outline-primary float-end"
                        buttonText="Archive"
                        confirmationMessage="Are you sure you want to archive this channel? You can reactivate later."
                        onClickAction={handleArchive}
                    />
                )}
            </ModalFooter>
        </Modal>
    );
};

export default ChannelSettingsModal;
