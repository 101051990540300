import {
    CanvasTransform,
    CustomCanvas,
    CustomFabricObject,
    EventTypes,
    FontOptions,
    FontSettings,
    VideoElement
} from './types.ts';
import {fabric} from 'fabric';
import {v4 as uuidv4} from 'uuid';
import {
    AuthData,
    EntityDataModel,
    EntityManipulationMethods,
    MergeData,
    RemoveElement,
    UnknownObject
} from '../../types.ts';
import getChanges from '../../project_data/getChanges.ts';
import emptyGuid from '../../lib/emptyGuid.ts';
import {Player, PlayerEvent} from '../../project_data/useProjectData.ts';
import elementDataSorter from '../../project_data/elementDataSorter.ts';
import {
    findFabricObject,
    modifyFabricObject,
    restoreFabricObject
} from './lib.ts';
import debounce from '../../lib/debounce.ts';
import {FileFormat} from '../../lib/FileFormats.ts';
import React from 'react';
import {FileDataModel, MixModel} from '../../api/types.ts';
import atify from '../../lib/atify.ts';
import {IGroupOptions} from 'fabric/fabric-impl';
import projectUrl from '../../lib/projectUrl.ts';
import {NavigateFunction} from 'react-router/dist/lib/hooks';
import './sprite.ts';

class CanvasController {
    initialized: boolean;
    public canvas: CustomCanvas | null;
    public pencilWidth: number = 5;
    public shadowWidth: number = 0;
    public shadowOffset: number = 0;
    public drawingMode: string = 'Pencil';
    public showPencilToolbar: boolean = false;

    public videoElements: VideoElement[] = [];
    public currentColor: string = '#000000';
    public fontSettings: FontSettings = {
        bold: false,
        italic: false,
        font: 'Arial'
    };

    public players: Record<string, Player> = {};
    public canvasTransform: CanvasTransform = {
        scale: 1,
        translationX: 0,
        translationY: 1
    };
    public isReadOnly: boolean = false;

    public get activeObjectType(): string | null {
        if (!this.canvas) {
            return null;
        }

        return this.canvas.getActiveObject()?.type ?? null;
    }

    public get activeObjectMeta(): string | null | undefined {
        if (!this.canvas) {
            return null;
        }
        const activeObject = this.canvas.getActiveObject();
        const meta = (activeObject as CustomFabricObject).meta;
        return meta;
    }

    public activeObjectFontSettings(): FontSettings | null {
        if (!this.canvas) {
            return null;
        }

        const activeObject = this.canvas.getActiveObject();

        if (activeObject && activeObject.type == 'i-text') {
            const textObject = activeObject as fabric.IText;
            return {
                bold: textObject.fontWeight === 'bold',
                italic: textObject.fontStyle === 'italic',
                font: textObject.fontFamily,
                fontSize: textObject.fontSize
            };
        }

        return null;
    }

    private _movingCanvas: boolean = false;

    public get movingCanvas(): boolean {
        return this._movingCanvas;
    }

    public get isDrawing(): boolean {
        return (
            this.canvas !== null &&
            !this.isReadOnly &&
            this.canvas.isDrawingMode == true
        );
    }

    public get isSelectActive(): boolean {
        return (
            this.canvas !== null &&
            !this.isReadOnly &&
            !this.canvas.isDrawingMode &&
            !this._movingCanvas
        );
    }

    private editingTextElementId: string | null = null;
    private initialData: EntityDataModel | null;
    private initialDataObjectsRestored: boolean;
    private authData: AuthData;
    private eventHandlers: Record<string, (e: fabric.IEvent) => void>;
    private getCurrentProjectData: () => EntityDataModel;
    private manipulation: EntityManipulationMethods;
    private playerEvent: (player: PlayerEvent) => void;
    private playerTimersRef: React.MutableRefObject<{[p: string]: number}>;
    private storageUri: string;
    private openAssetSelectAndUploadModal: (
        fileFormat: FileFormat,
        assetExplanation: string,
        onFileSelected: (file: FileDataModel | null) => void
    ) => void;
    private stateHasChanged: () => void;
    private navigate: NavigateFunction;

    constructor(
        isReadOnly: boolean,
        authData: AuthData,
        playerTimersRef: React.MutableRefObject<{[p: string]: number}>,
        storageUri: string,
        openAssetSelectAndUploadModal: (
            fileFormat: FileFormat,
            assetExplanation: string,
            onFileSelected: (file: FileDataModel | null) => void
        ) => void,
        stateHasChanged: () => void,
        navigate: NavigateFunction
    ) {
        this.initialized = false;
        this.canvas = null;
        this.initialData = null;
        this.initialDataObjectsRestored = false;
        this.isReadOnly = isReadOnly;
        this.authData = authData;
        this.getCurrentProjectData = () => ({});
        this.manipulation = {
            removeElement: () => {},
            mergeData: () => {}
        };
        this.playerEvent = () => {};
        this.playerTimersRef = playerTimersRef;
        this.storageUri = storageUri;
        this.openAssetSelectAndUploadModal = openAssetSelectAndUploadModal;
        this.stateHasChanged = stateHasChanged;
        this.navigate = navigate;

        this.eventHandlers = {
            [EventTypes.ObjectMoving]: this.handleObjectMoving,
            [EventTypes.ObjectScaling]: this.handleObjectScaling,
            [EventTypes.ObjectRotating]: this.handleObjectRotating,
            [EventTypes.ObjectSkewing]: this.handleObjectSkewing,
            [EventTypes.ObjectAdded]: this.handleObjectAdded,
            [EventTypes.ObjectRemoved]: this.handleObjectRemoved,
            [EventTypes.ObjectModified]: this.handleObjectModified,
            [EventTypes.PathCreated]: this.handlePathCreated,
            [EventTypes.MouseDown]: this.handleMouseDown,
            [EventTypes.MouseMove]: this.handleMouseMove,
            [EventTypes.MouseOut]: this.handleMouseOut,
            [EventTypes.MouseUp]: this.handleMouseUp,
            [EventTypes.SelectionCreated]: this.handleSelectionCreated,
            [EventTypes.SelectionUpdated]: this.handleSelectionUpdated,
            [EventTypes.SelectionCleared]: this.handleSelectionCleared,
            [EventTypes.TextEditingEntered]: this.handleTextEditingEntered,
            [EventTypes.TextEditingExited]: this.handleTextEditingExited
        };
    }

    onDelete() {
        if (!this.canvas) {
            return;
        }

        this.removeSelected();
    }

    getObjects = () => {
        return this.canvas?.getObjects() ?? [];
    };

    getSelections = () => {
        if (!this.canvas) {
            return [];
        }

        const currentSelection = this.canvas.getActiveObject();
        if (currentSelection) {
            return [currentSelection];
        } else {
            const currentSelections = this.canvas.getActiveObjects();
            return currentSelections;
        }
    };

    toggleIsDrawing = () => {
        if (this.isDrawing) {
            this.disableFreehandDrawing();
            this.showPencilToolbar = false;
        } else {
            this.enableFreehandDrawing();
            this.showPencilToolbar = true;
        }

        this.stateHasChanged();
    };

    selectClicked = () => {
        this.disableFreehandDrawing();
        this.setMovingCanvas(false);
        this.stateHasChanged();
    };

    private onIsReadOnlyChange = (isReadOnly: boolean) => {
        this.isReadOnly = isReadOnly;
        if (!this.canvas) {
            return;
        }

        const canvas = this.canvas;
        if (isReadOnly) {
            canvas.selection = false;
            canvas.forEachObject(object => {
                object.selectable = false;
                if (!(object as CustomFabricObject).nav) {
                    object.evented = false;
                } else {
                    object.hoverCursor = 'pointer';
                }
            });
            this.setMovingCanvas(true);
        } else {
            canvas.selection = true;
            canvas.forEachObject(object => {
                object.selectable = true;
                object.evented = true;
                if ((object as CustomFabricObject).nav) {
                    object.hoverCursor = 'move';
                }
            });
        }

        canvas.requestRenderAll();
        this.stateHasChanged();
    };

    setIsReadOnly = (isReadOnly: boolean) => {
        this.onIsReadOnlyChange(isReadOnly);
    };

    toggleReadOnlyMode = () => {
        const isReadOnly = this.isReadOnly;
        this.onIsReadOnlyChange(!isReadOnly);
    };

    toggleMovingCanvas = () => {
        this.setMovingCanvas(!this._movingCanvas);
    };

    private setMovingCanvas(movingCanvas: boolean) {
        const canvas = this.canvas!;

        if (movingCanvas) {
            this.zoomCanvas();
            this.dragCanvas();
            canvas.selection = false;
            canvas.forEachObject(object => {
                object.selectable = false;
                if (!(object as CustomFabricObject).nav) {
                    object.evented = false;
                }
            });
            this.disableFreehandDrawing();
        } else {
            canvas.off('mouse:wheel');
            canvas.off('mouse:down');
            canvas.selection = true;
            canvas.forEachObject(object => {
                object.selectable = true;
                object.evented = true;
            });
            canvas.defaultCursor = 'default';
        }

        this._movingCanvas = movingCanvas;

        this.stateHasChanged();
    }

    private zoomCanvas = () => {
        const canvas = this.canvas!;
        canvas.on('mouse:wheel', opt => {
            const delta = opt.e.deltaY;
            let zoom = canvas.getZoom();
            zoom *= 0.999 ** delta;
            if (zoom > 20) {
                zoom = 20;
            }

            if (zoom < 0.01) {
                zoom = 0.01;
            }

            canvas.setZoom(zoom);
            opt.e.preventDefault();
            opt.e.stopPropagation();
            const vpt = canvas.viewportTransform;
            if (vpt) {
                this.canvasTransform = {
                    scale: vpt[0],
                    translationX: vpt[4],
                    translationY: vpt[5]
                };
            }
        });
    };

    private dragCanvas = () => {
        const canvas = this.canvas!;
        canvas.defaultCursor = 'grab';
        canvas.on('mouse:down', function (opt) {
            const evt = opt.e;
            canvas.isDragging = true;
            canvas.selection = false;
            canvas.lastPosX = evt.clientX;
            canvas.lastPosY = evt.clientY;
        });

        canvas.on('mouse:move', opt => {
            if (canvas.isDragging) {
                const e = opt.e;
                if (
                    canvas.viewportTransform &&
                    canvas.lastPosX &&
                    canvas.lastPosY
                ) {
                    const vpt = canvas.viewportTransform;
                    vpt[4] += e.clientX - canvas.lastPosX;
                    vpt[5] += e.clientY - canvas.lastPosY;

                    canvas.requestRenderAll();
                    canvas.lastPosX = e.clientX;
                    canvas.lastPosY = e.clientY;
                    this.canvasTransform = {
                        scale: vpt[0],
                        translationX: vpt[4],
                        translationY: vpt[5]
                    };
                }
            }
        });

        canvas.on('mouse:up', function () {
            if (canvas.viewportTransform) {
                canvas.setViewportTransform(canvas.viewportTransform);
                canvas.isDragging = false;
                canvas.selection = true;
            }
        });
    };

    private findSettingsElement = (projectData: EntityDataModel) => {
        return (
            Object.entries(projectData).find(([, value]) => {
                return Object.prototype.hasOwnProperty.call(value, 'settings');
            })?.[1] ?? null
        );
    };

    handleBackgroundColorChange = () => {
        if (this.canvas) {
            const projectData: EntityDataModel = this.getCurrentProjectData();
            const settingsElement = this.findSettingsElement(projectData);
            const settingsIsNew = settingsElement == null;
            const settingsElementId = settingsIsNew
                ? uuidv4()
                : (settingsElement.elementId as string);

            const newState = settingsElement
                ? ({
                      ...settingsElement,
                      canvasBackgroundColor: this.currentColor
                  } as UnknownObject)
                : {
                      type: 'settings',
                      version: '',
                      elementId: settingsElementId,
                      canvasBackgroundColor: this.currentColor
                  };

            let changes: UnknownObject;
            if (settingsElement) {
                changes = getChanges(settingsElement, newState);
            } else {
                changes = newState;
            }

            this.mergeData(
                settingsElementId,
                changes,
                settingsIsNew
                    ? EventTypes.ObjectAdded
                    : EventTypes.ObjectModified
            );

            this.canvas.setBackgroundColor(
                this.currentColor,
                this.canvas.renderAll.bind(this.canvas)
            );
        }
    };

    handleLineWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.pencilWidth = parseFloat(e.target.value);
        this.enableFreehandDrawing();
    };

    handleShadowWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.shadowWidth = parseFloat(e.target.value);
        this.enableFreehandDrawing();
    };

    handleShadowOffsetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.shadowOffset = parseFloat(e.target.value);
        this.enableFreehandDrawing();
    };

    onDrawingModeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.drawingMode = e.target.value;
        this.enableFreehandDrawing();
    };

    getDiamondPatternSource = () => {
        const diamondWidth = 10;
        const diamondDistance = 5;
        const patternCanvas = document.createElement('canvas');
        patternCanvas.width = patternCanvas.height =
            diamondWidth + diamondDistance;
        const ctx = patternCanvas.getContext('2d');
        if (ctx) {
            const rect = new fabric.Rect({
                width: diamondWidth,
                height: diamondWidth,
                angle: 45,
                fill: this.currentColor,
                left: diamondWidth / 2,
                top: diamondWidth / 2
            });
            rect.render(ctx);
        }
        return patternCanvas;
    };

    getHLinePatternSource = () => {
        const patternCanvas = document.createElement('canvas');
        patternCanvas.width = patternCanvas.height = 10;
        const ctx = patternCanvas.getContext('2d');

        if (ctx) {
            ctx.lineWidth = 5;
            ctx.beginPath();
            ctx.moveTo(0, 5);
            ctx.lineTo(10, 5);
            ctx.closePath();
            ctx.stroke();
        }

        return patternCanvas;
    };

    getVLinePatternSource = () => {
        const patternCanvas = document.createElement('canvas');
        patternCanvas.width = patternCanvas.height = 10;
        const ctx = patternCanvas.getContext('2d');

        if (ctx) {
            ctx.lineWidth = 5;
            ctx.beginPath();
            ctx.moveTo(5, 0);
            ctx.lineTo(5, 10);
            ctx.closePath();
            ctx.stroke();
        }

        return patternCanvas;
    };

    getSquarePatternSource = () => {
        const squareWidth = 10,
            squareDistance = 2;

        const patternCanvas = document.createElement('canvas');
        patternCanvas.width = patternCanvas.height =
            squareWidth + squareDistance;
        const ctx = patternCanvas.getContext('2d');

        if (ctx) {
            ctx.fillRect(0, 0, squareWidth, squareWidth);
        }

        return patternCanvas;
    };

    handleRecenterCanvas = () => {
        const canvas = this.canvas!;
        const vpt = [1, 0, 0, 1, 0, 0];
        canvas.setViewportTransform(vpt);
        canvas.requestRenderAll();
    };

    enableFreehandDrawing = () => {
        let drawingBrush: fabric.BaseBrush;
        if (this.drawingMode === 'Diamond') {
            drawingBrush = new fabric.PatternBrush(this.canvas!);
            (drawingBrush as fabric.PatternBrush).getPatternSrc =
                this.getDiamondPatternSource;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            drawingBrush.source = drawingBrush.getPatternSrc.call(drawingBrush);
        } else if (this.drawingMode === 'VLine') {
            drawingBrush = new fabric.PatternBrush(this.canvas!);
            (drawingBrush as fabric.PatternBrush).getPatternSrc =
                this.getVLinePatternSource;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            drawingBrush.source = drawingBrush.getPatternSrc.call(drawingBrush);
        } else if (this.drawingMode === 'HLine') {
            drawingBrush = new fabric.PatternBrush(this.canvas!);
            (drawingBrush as fabric.PatternBrush).getPatternSrc =
                this.getHLinePatternSource;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            drawingBrush.source = drawingBrush.getPatternSrc.call(drawingBrush);
        } else if (this.drawingMode === 'Square') {
            drawingBrush = new fabric.PatternBrush(this.canvas!);
            (drawingBrush as fabric.PatternBrush).getPatternSrc =
                this.getSquarePatternSource;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            drawingBrush.source = drawingBrush.getPatternSrc.call(drawingBrush);
        } else if (this.drawingMode === 'Spray') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            drawingBrush = new fabric.SprayBrush(this.canvas!);
        } else if (this.drawingMode === 'Circle') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            drawingBrush = new fabric.CircleBrush(this.canvas!);
        } else {
            drawingBrush = new fabric.PencilBrush(this.canvas!);
        }

        drawingBrush.color = this.currentColor;
        drawingBrush.width = this.pencilWidth;
        drawingBrush.shadow = new fabric.Shadow({
            blur: this.shadowWidth,
            offsetX: this.shadowOffset,
            offsetY: this.shadowOffset,
            affectStroke: true,
            color: '#000'
        });

        this.canvas!.freeDrawingBrush = drawingBrush;

        this.canvas!.isDrawingMode = true;
        this.setMovingCanvas(false);
    };

    disableFreehandDrawing = () => {
        this.canvas!.isDrawingMode = false;
    };

    removeSelected = () => {
        const canvas = this.canvas!;
        let isEditingText = false;
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
            canvas.getActiveObjects().forEach((obj: CustomFabricObject) => {
                if (
                    obj instanceof fabric.Image &&
                    obj.type === 'video' &&
                    obj.videoElement
                ) {
                    obj.videoElement.pause();
                    obj.videoElement.src = '';
                    obj.videoElement.load();
                    canvas.remove(obj);
                } else if (
                    obj.type === 'i-text' &&
                    obj.elementId === this.editingTextElementId
                ) {
                    isEditingText = true;
                } else {
                    canvas.remove(obj);
                }
            });

            if (!isEditingText) {
                canvas.discardActiveObject();
                canvas.requestRenderAll();
                canvas.remove(activeObject);
                this.stateHasChanged();
            }
        }
    };

    addProjectLink = (mix: MixModel) => {
        const canvasTransform = this.canvasTransform;
        const newPositionX = this.canvas!.width
            ? this.canvas!.width / 2 / canvasTransform.scale -
              30 -
              canvasTransform.translationX / canvasTransform.scale
            : 50;
        const newPositionY = this.canvas!.height
            ? this.canvas!.height / 2 / canvasTransform.scale -
              35 -
              canvasTransform.translationY / canvasTransform.scale
            : 50;

        const canvas = this.canvas!;
        const options: IGroupOptions = {
            top: newPositionY,
            left: newPositionX,
            width: 200,
            height: 200
        };

        fabric.Image.fromURL(
            mix.thumbnail,
            img => {
                img.set({
                    left: -97.5,
                    top: -97.5,
                    scaleX: 196 / img.width!,
                    scaleY: 196 / img.height!
                });

                const imageClipPath = new fabric.Rect({
                    left: -img.width! / 2,
                    top: -img.height! / 2,
                    width: img.width! - 12,
                    height: img.height! - 12,
                    rx: img.width! / 20,
                    ry: img.height! / 20
                });

                img.clipPath = imageClipPath;

                fabric.Image.fromURL(mix.userThumbnail, userImg => {
                    userImg.scaleToWidth(25);
                    userImg.set({
                        left: -90,
                        top: -90,
                        scaleX: 25 / userImg.width!,
                        scaleY: 25 / userImg.height!
                    });

                    const clipPath = new fabric.Rect({
                        left: -userImg.width! / 2,
                        top: -userImg.height! / 2,
                        width: userImg.width! - 12,
                        height: userImg.height! - 12,
                        rx: img.width! * 2,
                        ry: img.height! * 2
                    });

                    userImg.clipPath = clipPath;

                    const group = new fabric.Group([], options);

                    const textProjectName = mix.name;
                    const textUsername = mix.userName;
                    const textNickname = `${atify(mix.nickname)}`;

                    const textObjectProjectName = new fabric.Textbox(
                        textProjectName,
                        {
                            left: -90,
                            top: 50,
                            width: 200,
                            height: 37.5,
                            fontFamily: 'Poppins, sans-serif',
                            fill: this.currentColor,
                            fontSize: 13,
                            fontWeight: 'bold'
                        }
                    );
                    //textObjectProjectName.scaleToWidth(190);

                    const textObjectUsername = new fabric.IText(textUsername, {
                        left: -56,
                        top: -95,
                        fontFamily: 'Poppins, sans-serif',
                        fill: this.currentColor,
                        fontSize: 13,
                        fontWeight: 'bold'
                    });

                    const textObjectNickname = new fabric.IText(textNickname, {
                        left: -56,
                        top: -77,
                        fontFamily: 'Poppins, sans-serif',
                        fill: this.currentColor,
                        fontSize: 10
                    });

                    const rectBorder = new fabric.Rect({
                        left: -100,
                        top: -100,
                        fill: 'black',
                        width: 200,
                        height: 200,
                        angle: 0,
                        rx: 10,
                        ry: 10
                    });

                    const rectBackground1 = new fabric.Rect({
                        left: -97.5,
                        top: -97.5,
                        fill: 'white',
                        width: 195,
                        height: 40,
                        angle: 0,
                        rx: 8,
                        ry: 8
                    });

                    const rectBackground2 = new fabric.Rect({
                        left: -97.5,
                        top: 48,
                        fill: 'white',
                        width: 195,
                        height: 49.5,
                        angle: 0,
                        rx: 8,
                        ry: 8
                    });

                    group.add(rectBorder);
                    group.add(img);
                    group.add(rectBackground1);
                    group.add(rectBackground2);
                    group.add(userImg);
                    group.add(textObjectProjectName);
                    group.add(textObjectUsername);
                    group.add(textObjectNickname);

                    const customGroup = group as unknown as CustomFabricObject;
                    customGroup.meta = 'fixed-group';
                    customGroup.nav = projectUrl({id: mix.id!});

                    canvas.add(group);

                    group.on(EventTypes.MouseDown, () => {
                        customGroup.nav && this.navigate(customGroup.nav);
                    });
                });
            },
            {
                crossOrigin: 'anonymous' // Handle CORS if loading external images
            }
        );
    };

    addRectangle = () => {
        this.disableFreehandDrawing();
        const canvasTransform = this.canvasTransform;

        const newRectanglePositionX = this.canvas!.width
            ? this.canvas!.width / 2 / canvasTransform.scale -
              30 -
              canvasTransform.translationX / canvasTransform.scale
            : 50;
        const newRectanglePositionY = this.canvas!.height
            ? this.canvas!.height / 2 / canvasTransform.scale -
              35 -
              canvasTransform.translationY / canvasTransform.scale
            : 50;
        const elementId = uuidv4();
        const rect = new fabric.Rect({
            left: newRectanglePositionX,
            top: newRectanglePositionY,
            fill: this.currentColor,
            width: 60,
            height: 70,
            angle: 0,
            transparentCorners: false
        }) as CustomFabricObject;
        rect.elementId = elementId;

        this.canvas!.add(rect);
        this.canvas!.setActiveObject(rect);

        this.setMovingCanvas(false);
    };

    addCircle = () => {
        this.disableFreehandDrawing();
        const canvasTransform = this.canvasTransform;

        const newCirclePositionX = this.canvas!.width
            ? this.canvas!.width / 2 / canvasTransform.scale -
              30 -
              canvasTransform.translationX / canvasTransform.scale
            : 50;
        const newCirclePositionY = this.canvas!.height
            ? this.canvas!.height / 2 / canvasTransform.scale -
              30 -
              canvasTransform.translationY / canvasTransform.scale
            : 50;
        const elementId = uuidv4();
        const circle = new fabric.Circle({
            left: newCirclePositionX,
            top: newCirclePositionY,
            fill: this.currentColor,
            radius: 30,
            transparentCorners: false
        }) as unknown as CustomFabricObject;
        circle.elementId = elementId;

        this.canvas!.add(circle);
        this.canvas!.setActiveObject(circle);
        this.setMovingCanvas(false);
    };

    addText = (textToInsert?: string) => {
        const canvas = this.canvas!;

        this.disableFreehandDrawing();
        const canvasTransform = this.canvasTransform;

        const newTextPositionX = canvas.width
            ? canvas.width / 2 / canvasTransform.scale -
              50 -
              canvasTransform.translationX / canvasTransform.scale
            : 50;
        const newTextPositionY = canvas.height
            ? canvas.height / 2 / canvasTransform.scale -
              10 -
              canvasTransform.translationY / canvasTransform.scale
            : 50;
        const elementId = uuidv4();
        const text = new fabric.IText(textToInsert ?? 'Insert Text', {
            left: newTextPositionX,
            top: newTextPositionY,
            fontFamily: this.fontSettings.font ?? 'Arial',
            fill: this.currentColor,
            fontSize: this.fontSettings.fontSize ?? 20
        }) as unknown as CustomFabricObject;
        text.elementId = elementId;

        canvas.add(text);
        canvas.setActiveObject(text);
        this.setMovingCanvas(false);
    };

    private addImageFromUrl = (url: string) => {
        const canvas = this.canvas!;
        const canvasTransform = this.canvasTransform;
        fabric.Image.fromURL(
            url,
            img => {
                const newImagePositionX = canvas.width
                    ? canvas.width / 2 / canvasTransform.scale -
                      50 -
                      canvasTransform.translationX / canvasTransform.scale
                    : 50;
                const newImagePositionY = canvas.height
                    ? canvas.height / 2 / canvasTransform.scale -
                      50 -
                      canvasTransform.translationY / canvasTransform.scale
                    : 50;
                img.set({
                    left: newImagePositionX,
                    top: newImagePositionY,
                    angle: 0,
                    cornerSize: 10
                });
                img.scaleToWidth(100);
                img.scaleToHeight(100);

                const elementId = uuidv4();
                (img as unknown as CustomFabricObject).elementId = elementId;
                canvas.add(img);

                // Manually fire the EventTypes.ObjectModified event
                canvas.fire(EventTypes.ObjectModified, {
                    target: img
                });
            },
            {
                crossOrigin: 'anonymous' // Handle CORS if loading external images
            }
        );
    };

    addImage = () => {
        this.disableFreehandDrawing();
        this.openAssetSelectAndUploadModal(
            FileFormat.Image,
            'Please select a picture file',
            (file: FileDataModel | null) => {
                if (file) {
                    this.addImageFromUrl(file.uri);
                }
            }
        );
        this.setMovingCanvas(false);
    };

    addNewVideo = (
        src: string,
        videoToClone?: CustomFabricObject,
        activeObject?: fabric.Object,
        pointOnCanvas?: fabric.Point
    ) => {
        const canvasTransform = this.canvasTransform;
        const videoElement: HTMLVideoElement = document.createElement('video');
        videoElement.style.display = 'none';
        videoElement.src = src;
        videoElement.setAttribute('crossOrigin', 'anonymous');
        videoElement.load();
        videoElement.onloadeddata = () => {
            const elementId = uuidv4();

            // Update the state with the new video element and its associated id
            this.videoElements = [
                ...this.videoElements,
                {id: elementId, element: videoElement}
            ];
            videoElement.width = videoElement.videoWidth;
            videoElement.height = videoElement.videoHeight;
            let newVideoPositionX;
            let newVideoPositionY;
            let scaleX;
            let scaleY;
            let angle;

            if (videoToClone) {
                if (activeObject) {
                    newVideoPositionX = pointOnCanvas!.x + 10;
                    newVideoPositionY = pointOnCanvas!.y + 10;
                    scaleX = activeObject.scaleX! * videoToClone.scaleX!;
                    scaleY = activeObject.scaleY! * videoToClone.scaleY!;
                    angle = activeObject.angle! + videoToClone.angle!;
                } else {
                    newVideoPositionX = videoToClone.left! + 10;
                    newVideoPositionY = videoToClone.top! + 10;
                    scaleX = videoToClone.scaleX;
                    scaleY = videoToClone.scaleY;
                    angle = videoToClone.angle;
                }
            } else {
                scaleX = 1;
                scaleY = 1;
                angle = 0;

                if (videoElement.width > this.canvas!.width! / 2) {
                    scaleX = this.canvas!.width! / 2 / videoElement.width;
                    scaleY = scaleX;
                }

                newVideoPositionX = this.canvas!.width
                    ? this.canvas!.width / 2 / canvasTransform.scale -
                      (videoElement.width * scaleX) / 2 -
                      canvasTransform.translationX / canvasTransform.scale
                    : 50;
                newVideoPositionY = this.canvas!.height
                    ? this.canvas!.height / 2 / canvasTransform.scale -
                      (videoElement.height * scaleY) / 2 -
                      canvasTransform.translationY / canvasTransform.scale
                    : 50;
            }

            const video = new fabric.Image(videoElement, {
                left: newVideoPositionX,
                top: newVideoPositionY,
                scaleX: scaleX,
                scaleY: scaleY,
                angle: angle,
                objectCaching: false,
                type: 'video'
            });

            // Casting to CustomFabricObject and setting properties
            const customVideo: CustomFabricObject =
                video as unknown as CustomFabricObject;
            customVideo.elementId = elementId;
            customVideo.uri = src;
            customVideo.videoElement = videoElement;

            this.canvas!.add(customVideo);
            //this.canvas!.setActiveObject(customVideo);
            this.canvas!.fire(EventTypes.ObjectModified, {
                target: video
            });
            customVideo.videoElement.play();

            // For rendering the video
            const c = this.canvas!;
            fabric.util.requestAnimFrame(function render() {
                c.renderAll();
                fabric.util.requestAnimFrame(render);
            });
        };
    };

    addVideo = () => {
        this.disableFreehandDrawing();

        this.openAssetSelectAndUploadModal(
            FileFormat.Video,
            'Please select a video file (mp4)',
            (file: FileDataModel | null) => {
                if (file) {
                    this.addNewVideo(file.uri);
                }
            }
        );
        this.setMovingCanvas(false);
    };

    playSelectedVideo = () => {
        const activeObject: fabric.Object | null =
            this.canvas!.getActiveObject();
        if (activeObject) {
            const activeElementId: string | undefined = (
                activeObject as CustomFabricObject
            ).elementId;

            this.videoElements.forEach((videoElement: VideoElement): void => {
                if (videoElement.id === activeElementId) {
                    videoElement.element.play();
                }
            });
        }
    };

    pauseSelectedVideo = () => {
        const activeObject: fabric.Object | null =
            this.canvas!.getActiveObject();
        if (activeObject) {
            const activeElementId: string | undefined = (
                activeObject as CustomFabricObject
            ).elementId;
            this.videoElements.forEach((videoElement: VideoElement): void => {
                if (videoElement.id === activeElementId) {
                    videoElement.element.pause();
                }
            });
        }
    };

    private updateCanvasSize = () => {
        /*
        const dimensions = {
            width: window.innerWidth - 50,
            height: window.innerHeight - 160
        };
        */

        const canvasContainer = document.getElementById('CanvasContainer');
        if (canvasContainer) {
            const {width} = canvasContainer.getBoundingClientRect();
            const dimensions = {
                width: width,
                height: window.innerHeight - 160
            };

            this.canvas!.setDimensions(dimensions);
        }
    };

    initialize = (
        canvas: CustomCanvas,
        getCurrentProjectData: () => EntityDataModel,
        manipulation: EntityManipulationMethods,
        _playerEvent: (player: PlayerEvent) => void
    ) => {
        if (this.initialized) {
            return;
        }

        this.canvas = canvas;

        this.getCurrentProjectData = getCurrentProjectData;
        this.manipulation = manipulation;
        // this.playerEvent = playerEvent;

        this.updateCanvasSize();

        window.addEventListener('resize', this.updateCanvasSize);

        Object.keys(this.eventHandlers).forEach(eventType => {
            this.canvas!.on(eventType, this.eventHandlers[eventType]);
        });

        this.canvas.selection = !this.isReadOnly;

        if (this.initialData) {
            this.restoreFabricObjects(this.initialData);
            this.initialDataObjectsRestored = true;
            this.initialData = null;
        }

        if (this.isReadOnly) {
            this.setMovingCanvas(true);
        }

        this.initialized = true;
        this.stateHasChanged();
    };

    dispose = () => {
        this.initialized = false;

        window.removeEventListener('resize', this.updateCanvasSize);

        if (this.canvas && this.eventHandlers) {
            Object.keys(this.eventHandlers).forEach(eventType => {
                this.canvas!.off(eventType, this.eventHandlers[eventType]);
            });
        }

        this.canvas && this.canvas.dispose();
        this.canvas = null;
        this.initialData = null;
        this.initialDataObjectsRestored = false;
    };

    onRemoteMergeData: MergeData = (
        elementId: string,
        data: UnknownObject,
        meta?: string | null
    ) => {
        console.log(`onRemoteMergeData elementId=${elementId}, meta=${meta}`);

        const applyBatchedChanges = () => {
            Object.entries(data).forEach(
                ([entryElementId, entryElementData]) => {
                    this.onRemoteMergeElement(
                        entryElementId,
                        entryElementData as UnknownObject,
                        meta
                    );
                }
            );
        };

        if (elementId === emptyGuid) {
            if (meta === EventTypes.OrdinalPositionsChange) {
                const canvas = this.canvas!;
                const objects = this.getObjects();

                // Create a map of elementId to its new ordinalPosition
                const ordinalPositionMap = new Map<string, number>();
                Object.entries(data).forEach(([elementId, elementData]) => {
                    ordinalPositionMap.set(
                        elementId,
                        (elementData as CustomFabricObject).ordinalPosition ?? 0
                    );
                });

                // Update the ordinalPosition of each object on the canvas
                objects.forEach(obj => {
                    const elementObject = obj as CustomFabricObject;
                    const newOrdinalPosition = ordinalPositionMap.get(
                        elementObject.elementId ?? ''
                    );
                    if (newOrdinalPosition !== undefined) {
                        elementObject.ordinalPosition = newOrdinalPosition;
                    }
                });

                // Sort the objects based on their updated ordinalPosition
                objects.sort((a, b) => {
                    const elementA = a as CustomFabricObject;
                    const elementB = b as CustomFabricObject;
                    return (
                        (elementA.ordinalPosition ?? 0) -
                        (elementB.ordinalPosition ?? 0)
                    );
                });

                // Reorder the objects on the canvas based on the sorted order
                objects.forEach((obj, index) => {
                    const currentIndex = canvas.getObjects().indexOf(obj);
                    const desiredIndex = index;

                    if (currentIndex !== desiredIndex) {
                        const steps = currentIndex - desiredIndex;
                        for (let i = 0; i < steps; i++) {
                            canvas.sendBackwards(obj);
                        }
                    }
                });

                // Refresh the canvas to reflect the changes
                canvas.requestRenderAll();
            }

            applyBatchedChanges();
        } else {
            this.onRemoteMergeElement(elementId, data, meta);
        }
    };

    /*
        onImageEdited={(videoElements) =>
            typeof videoElements === 'function'
                ? this.videoElements=(videoElements(his.videoElements))
                : this.videoElements=(videoElements)
        }
    * */

    private onRemoteMergeElement: MergeData = (
        elementId: string,
        data: UnknownObject,
        meta?: string | null
    ) => {
        if (meta === EventTypes.ObjectAdded) {
            restoreFabricObject(
                this.canvas!,
                elementId,
                data,
                videoElements =>
                    typeof videoElements === 'function'
                        ? (this.videoElements = videoElements(
                              this.videoElements
                          ))
                        : (this.videoElements = videoElements),
                this.isReadOnly
            );
        } else if (meta?.startsWith('object:')) {
            const objectToModify = findFabricObject(this.canvas!, elementId);

            if (objectToModify) {
                modifyFabricObject(
                    this.canvas!,
                    objectToModify,
                    elementId,
                    data
                );
                this.canvas!.requestRenderAll();
            } else {
                console.error(`Object with elementId ${elementId} not found.`);
            }
        }
    };

    onRemoteRemoveElement: RemoveElement = (
        elementId: string,
        meta?: string | null
    ) => {
        console.log(
            `onRemoteRemoveElement elementId=${elementId}, meta=${meta}`
        );

        const objectToRemove = findFabricObject(this.canvas!, elementId);

        if (objectToRemove) {
            this.canvas!.remove(objectToRemove);
            this.canvas!.requestRenderAll();
        } else {
            console.error(`Object with elementId ${elementId} not found.`);
        }
    };

    private debouncedCreateSprite = debounce(
        (playerId: string, x: number, y: number) => {
            const player: Player | undefined = this.players[playerId];

            if (!player) {
                return;
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fabric.Sprite.fromURL(
                `${this.storageUri}${player.containerName}/profile/sprite.png`,
                this.createSprite(playerId, x ?? 0, y ?? 0)
            );
        },
        250
    );

    onRemotePlayerEvent = (playerEvent: PlayerEvent) => {
        const playerId: string = playerEvent.userId;
        const playerSprite = findFabricObject(this.canvas!, playerId);

        // Clear any existing timer for this player using playerTimersRef
        if (this.playerTimersRef.current[playerId]) {
            clearTimeout(this.playerTimersRef.current[playerId]);
        }

        if (playerEvent.eventType === 'move') {
            // Player has moved, reset or create a new timer
            this.playerTimersRef.current[playerId] = setTimeout(() => {
                if (playerSprite) {
                    this.canvas!.remove(playerSprite);
                }
                delete this.playerTimersRef.current[playerId];
            }, 30000); // 30 seconds

            if (playerSprite) {
                playerSprite.set({
                    left: playerEvent.x,
                    top: playerEvent.y
                });
            } else {
                this.debouncedCreateSprite(
                    playerId,
                    playerEvent.x ?? 0,
                    playerEvent.y ?? 0
                );
            }

            if (!(playerId in this.players)) {
                this.players = {...this.players, [playerId]: playerEvent};
            }
        } else if (playerEvent.eventType === 'exit') {
            if (
                playerId in this.players &&
                this.playerTimersRef.current[playerId]
            ) {
                clearTimeout(this.playerTimersRef.current[playerId]);
                delete this.playerTimersRef.current[playerId];
                if (playerSprite) {
                    this.canvas!.remove(playerSprite);
                }

                delete this.players[playerId];
                this.players = {...this.players};
            }
        }

        this.canvas!.renderAll();
    };

    changeColor = (color: string) => {
        const activeObject = this.canvas!.getActiveObject();
        if (activeObject) {
            if (activeObject.type === 'path') {
                activeObject.set('stroke', color);
            } else {
                activeObject.set('fill', color);
            }
            this.canvas!.requestRenderAll();
            // Manually fire the 'object:modified' event
            this.canvas!.fire(EventTypes.ObjectModified, {
                target: activeObject
            });
        }

        // handle color change for all objects in a group
        const activeGroup = this.canvas!.getActiveObjects();
        if (activeGroup) {
            activeGroup.forEach((object: CustomFabricObject) => {
                if (object.type === 'path') {
                    object.set('stroke', color);
                } else {
                    object.set('fill', color);
                }
                this.canvas!.requestRenderAll();
                // Manually fire the 'object:modified' event
                this.canvas!.fire(EventTypes.ObjectModified, {target: object});
            });
        }
    };

    handleColorChange = (color: string) => {
        this.currentColor = color;
        this.changeColor(color);
        this.canvas!.freeDrawingBrush.color = color; // make sure the pencil tool gets the new color
        this.stateHasChanged();
    };

    onFontChange = (font: string | undefined) => {
        const canvas = this.canvas!;
        const activeObject = canvas.getActiveObject();
        if (activeObject instanceof fabric.IText) {
            const thisText: fabric.IText = activeObject;

            const fontSettings = this.fontSettings;
            this.fontSettings = {
                ...fontSettings,
                font: font
            };

            thisText.set('fontFamily', font);

            canvas.requestRenderAll();
            this.stateHasChanged();
        }
    };

    setFontOptions = (setting: string) => {
        const activeObject = this.canvas!.getActiveObject();
        if (!activeObject || activeObject.type !== 'i-text') {
            return;
        }

        const textObject = activeObject as fabric.IText;

        switch (setting) {
            case FontOptions.Italic:
                textObject.fontStyle =
                    textObject.fontStyle === 'italic' ? 'normal' : 'italic';
                this.fontSettings = {
                    ...this.fontSettings,
                    italic: !this.fontSettings.italic
                };
                break;
            case FontOptions.Bold:
                textObject.fontWeight =
                    textObject.fontWeight === FontOptions.Bold
                        ? ''
                        : FontOptions.Bold;
                this.fontSettings = {
                    ...this.fontSettings,
                    bold: !this.fontSettings.bold
                };
                break;
            default:
                break;
        }

        this.canvas!.fire(EventTypes.ObjectModified, {target: textObject});
        this.canvas!.renderAll();
        this.stateHasChanged();
    };

    setFontSize = (fontSize: number | undefined) => {
        const activeObject = this.canvas!.getActiveObject();
        if (!activeObject || activeObject.type !== 'i-text') {
            return;
        }

        const textObject = activeObject as fabric.IText;

        textObject.set('fontSize', fontSize);
        this.fontSettings = {...this.fontSettings, fontSize: fontSize};

        this.canvas!.fire(EventTypes.ObjectModified, {target: textObject});
        this.canvas!.renderAll();
        this.stateHasChanged();
    };

    onLayersChanged = () => {
        const batchedChanges: EntityDataModel = {};

        const canvasObjects = this.canvas!.getObjects();
        const currentProjectData = this.getCurrentProjectData();

        canvasObjects.forEach((obj, index) => {
            const elementId = (obj as CustomFabricObject).elementId;
            if (elementId) {
                const element = currentProjectData[elementId];

                if (element) {
                    const ordinalPosition =
                        'ordinalPosition' in element &&
                        typeof element.ordinalPosition === 'number'
                            ? element.ordinalPosition
                            : undefined;

                    if (ordinalPosition !== index) {
                        batchedChanges[elementId] = {ordinalPosition: index};
                    }
                }
            }
        });

        this.mergeData(
            emptyGuid,
            batchedChanges,
            EventTypes.OrdinalPositionsChange
        );
    };

    bringForward = () => {
        const activeObject = this.canvas!.getActiveObject();
        if (activeObject) {
            this.canvas!.bringForward(activeObject);
            this.canvas!.discardActiveObject();
            this.canvas!.requestRenderAll();
            this.onLayersChanged();
        }
    };

    bringBackwards = () => {
        const activeObject = this.canvas!.getActiveObject();

        if (activeObject) {
            this.canvas!.sendBackwards(activeObject);
            this.canvas!.discardActiveObject();
            this.canvas!.requestRenderAll();
            this.onLayersChanged();
        }
    };

    bringToFront = () => {
        const activeObject = this.canvas!.getActiveObject();
        if (activeObject) {
            this.canvas!.bringToFront(activeObject);
            this.canvas!.discardActiveObject();
            this.canvas!.requestRenderAll();
            this.onLayersChanged();
        }
    };

    sendToBack = () => {
        const activeObject = this.canvas!.getActiveObject();
        if (activeObject) {
            this.canvas!.sendToBack(activeObject);
            this.canvas!.discardActiveObject();
            this.canvas!.requestRenderAll();
            this.onLayersChanged();
        }
    };

    groupItems = () => {
        if (
            this.canvas?.getActiveObject() &&
            this.canvas.getActiveObject()?.type === 'activeSelection'
        ) {
            const activeSelection =
                this.canvas.getActiveObject() as fabric.ActiveSelection;
            activeSelection.toGroup();
            this.canvas.requestRenderAll();
        }
    };

    ungroupItems = () => {
        if (!this.canvas) {
            return;
        }

        const canvas = this.canvas;

        if (
            canvas.getActiveObject() &&
            canvas.getActiveObject()?.type === 'group'
        ) {
            const activeGroup = canvas.getActiveObject() as fabric.Group;

            const groupObjects = activeGroup.getObjects();
            const ungroupedObjects: fabric.Object[] = [];
            const canvasObjects = canvas.getObjects();
            const indexOfActiveGroup = canvasObjects.indexOf(activeGroup);

            groupObjects.forEach(object => {
                const obj = object as CustomFabricObject;
                const matrix = obj.calcTransformMatrix();
                const point = {
                    x: -obj.width! / 2,
                    y: -obj.height! / 2
                };
                const pointOnCanvas = fabric.util.transformPoint(
                    point as fabric.Point,
                    matrix
                );
                obj.set({
                    scaleX: activeGroup.scaleX! * obj.scaleX!,
                    scaleY: activeGroup.scaleY! * obj.scaleY!,
                    angle: activeGroup.angle! + obj.angle!,
                    left: pointOnCanvas.x,
                    top: pointOnCanvas.y
                });

                obj.setCoords();
                ungroupedObjects.push(obj);
                canvas.add(obj);
            });

            groupObjects.forEach((object, index) => {
                canvas.moveTo(object, indexOfActiveGroup + index);
            });

            const newSelection = new fabric.ActiveSelection(ungroupedObjects, {
                canvas: canvas
            });

            canvas.remove(activeGroup);
            canvas.setActiveObject(newSelection);
            canvas.requestRenderAll();

            this.onLayersChanged();
        }
    };

    duplicateObject = () => {
        const activeObject = this.canvas!.getActiveObject();
        if (!activeObject) {
            return;
        }

        if (activeObject.type === 'activeSelection') {
            // Cast activeObject to fabric.ActiveSelection to access its items
            const activeSelection = activeObject as fabric.ActiveSelection;

            const activeObjects = activeSelection.getObjects();

            activeObjects.forEach((obj: fabric.Object) => {
                const object = obj as CustomFabricObject;

                const matrix = obj.calcTransformMatrix();

                const point = {
                    x: -obj.width! / 2,
                    y: -obj.height! / 2
                };
                const pointOnCanvas = fabric.util.transformPoint(
                    point as fabric.Point,
                    matrix
                );

                if (object.videoElement) {
                    this.addNewVideo(
                        object.videoElement.src,
                        object,
                        activeObject,
                        pointOnCanvas
                    );
                    this.canvas!.requestRenderAll();
                } else {
                    object.clone((clone: fabric.Object) => {
                        clone.set({
                            scaleX: activeObject.scaleX! * object.scaleX!,
                            scaleY: activeObject.scaleY! * object.scaleY!,
                            angle: activeObject.angle! + object.angle!,
                            left: pointOnCanvas.x + 10,
                            top: pointOnCanvas.y + 10
                        });
                        this.canvas!.add(clone);
                        this.canvas!.requestRenderAll();
                    });
                }
            });
        } else {
            // Clone individual objects
            // TODO: some bugs with the placement of the new object
            const object = activeObject as CustomFabricObject;

            if (object.videoElement) {
                this.addNewVideo(object.videoElement.src, object);
                this.canvas!.requestRenderAll();
            } else {
                activeObject.clone((clone: fabric.Object) => {
                    const left = activeObject.left || 0;
                    const top = activeObject.top || 0;
                    clone.set({
                        left: left + 10,
                        top: top + 10
                    });
                    this.canvas!.add(clone);
                    this.canvas!.setActiveObject(clone);
                    this.canvas!.requestRenderAll();
                });
            }
        }
    };

    onInitialDataFetched = (initialData: EntityDataModel) => {
        console.log(`onInitialDataFetched initialized=${this.initialized}`);
        if (this.initialized) {
            if (!this.initialDataObjectsRestored) {
                this.restoreFabricObjects(initialData);
                this.initialDataObjectsRestored = true;
            }
        } else {
            // We have the initial data before the canvas
            this.initialData = initialData;
        }
    };

    private mergeData = (
        elementId: string,
        data: UnknownObject,
        meta?: string | null
    ) => {
        this.manipulation.mergeData(elementId, data, meta);
    };

    private removeElement = (elementId: string, meta?: string | null) => {
        this.manipulation.removeElement(elementId, meta);
    };

    private restoreFabricObjects = (initialData: EntityDataModel) => {
        Object.entries(initialData)
            .sort(elementDataSorter)
            .forEach(([elementId, elementData]) => {
                const existingElement = findFabricObject(
                    this.canvas!,
                    elementId
                );
                if (!existingElement) {
                    restoreFabricObject(
                        this.canvas!,
                        elementId,
                        elementData,
                        videoElements =>
                            typeof videoElements === 'function'
                                ? (this.videoElements = videoElements(
                                      this.videoElements
                                  ))
                                : (this.videoElements = videoElements),
                        this.isReadOnly
                    );
                }
            });
    };

    private activeSelectionEvent(eventType: string, selection: fabric.Object) {
        const selectedObjects = selection as fabric.ActiveSelection;

        selectedObjects.forEachObject(obj => {
            const object = obj as CustomFabricObject;
            const matrix = obj.calcTransformMatrix();

            const point = {
                x: -obj.width! / 2,
                y: -obj.height! / 2
            };
            const pointOnCanvas = fabric.util.transformPoint(
                point as fabric.Point,
                matrix
            );

            const elementId = (() => {
                if (object.elementId) {
                    return object.elementId;
                }

                const newElementId = uuidv4();
                const ordinalPosition =
                    this.canvas!.getObjects().indexOf(object);
                object.set({
                    elementId: newElementId,
                    ordinalPosition: ordinalPosition
                });
                return newElementId;
            })();

            const changes: UnknownObject = {
                scaleX: selectedObjects.scaleX! * obj.scaleX!,
                scaleY: selectedObjects.scaleY! * obj.scaleY!,
                angle: selectedObjects.angle! + obj.angle!,
                left: pointOnCanvas.x,
                top: pointOnCanvas.y
            };

            this.mergeData(elementId, changes, eventType);
        });
    }

    private handleObjectEvent = (eventType: string, e: fabric.IEvent) => {
        if (e.target && e.target.type === 'activeSelection') {
            this.activeSelectionEvent(eventType, e.target);
            return;
        }

        const object = e.target as CustomFabricObject;
        if (object) {
            const currentProjectData = this.getCurrentProjectData();

            if (object.ordinalPosition === undefined) {
                const ordinalPosition =
                    this.canvas!.getObjects().indexOf(object);
                object.set({
                    ordinalPosition: ordinalPosition
                });
            }

            const elementId = (() => {
                if (object.elementId) {
                    return object.elementId;
                }

                const newElementId = uuidv4();
                const ordinalPosition =
                    this.canvas!.getObjects().indexOf(object);
                object.set({
                    elementId: newElementId,
                    ordinalPosition: ordinalPosition
                });
                return newElementId;
            })();

            if (eventType === EventTypes.ObjectRemoved) {
                this.removeElement(elementId, eventType);
                return;
            }

            const currentState = {
                ...object.toObject(),
                elementId: object.elementId,
                ordinalPosition: object.ordinalPosition,
                meta: object.meta,
                nav: object.nav
            } as UnknownObject;

            const previousState =
                elementId in currentProjectData
                    ? currentProjectData[elementId]
                    : undefined;

            let changes: UnknownObject;
            if (previousState) {
                changes = getChanges(previousState, currentState);
            } else {
                changes = currentState;
            }

            this.mergeData(elementId, changes, eventType);
        }
    };

    private handleObjectMoving = (e: fabric.IEvent) => {
        this.handleObjectEvent(EventTypes.ObjectMoving, e);
    };

    private handleObjectScaling = (e: fabric.IEvent) => {
        this.handleObjectEvent(EventTypes.ObjectScaling, e);
    };

    private handleObjectRotating = (e: fabric.IEvent) => {
        this.handleObjectEvent(EventTypes.ObjectRotating, e);
    };

    private handleObjectSkewing = (e: fabric.IEvent) => {
        this.handleObjectEvent(EventTypes.ObjectSkewing, e);
    };

    private handlePathCreated = (e: fabric.IEvent) => {
        this.handleObjectEvent(EventTypes.PathCreated, e);
        this.showPencilToolbar = false;
    };

    private handleObjectAdded = (e: fabric.IEvent) => {
        this.handleObjectEvent(EventTypes.ObjectAdded, e);
    };

    private handleObjectRemoved = (e: fabric.IEvent) => {
        this.handleObjectEvent(EventTypes.ObjectRemoved, e);
    };

    private handleObjectModified = (e: fabric.IEvent) => {
        this.handleObjectEvent(EventTypes.ObjectModified, e);
    };

    private handleMouseDown = (e: fabric.IEvent) => {
        if (!e.target || !this.isReadOnly) {
            return;
        }

        const customObject = e.target as CustomFabricObject;
        if (customObject.nav) {
            this.navigate(customObject.nav);
        }
    };

    private handleMouseMove = (e: fabric.IEvent) => {
        if (!e.pointer) {
            return;
        }

        const x = e.pointer.x;
        const y = e.pointer.y;

        this.playerEvent({
            eventType: 'move',
            userId: this.authData.id ?? emptyGuid,
            nickname: this.authData.nickname ?? '@anonymous',
            containerName: this.authData.container_name ?? 'anonymous',
            x,
            y
        });
    };

    private handleMouseOut = () => {
        this.playerEvent({
            eventType: 'exit',
            userId: this.authData.id ?? emptyGuid,
            nickname: this.authData.nickname ?? '@anonymous',
            containerName: this.authData.container_name ?? 'anonymous'
        });
    };

    private handleMouseUp = () => {
        this.stateHasChanged();
    };

    private handleSelectionCreated = (e: fabric.IEvent) => {
        console.log(EventTypes.SelectionCreated);
        this.setSelectedTextFontSettings(e);
        this.stateHasChanged();
    };

    private handleSelectionUpdated = (e: fabric.IEvent) => {
        console.log(EventTypes.SelectionUpdated);
        this.setSelectedTextFontSettings(e);
        this.stateHasChanged();
    };

    private handleSelectionCleared = () => {
        console.log(EventTypes.SelectionCleared);
        this.stateHasChanged();
    };

    private setSelectedTextFontSettings(e: fabric.IEvent) {
        const selectedObject = e.selected;

        if (selectedObject) {
            if (typeof selectedObject[0].fill === 'string') {
                this.currentColor = selectedObject[0].fill;
            }

            // Pick up the current state of the text object and set the font, bold, and italic states
            if (selectedObject[0] instanceof fabric.IText) {
                const activeObject = selectedObject[0] as fabric.IText;
                if (activeObject.fontFamily) {
                    this.fontSettings = {
                        ...this.fontSettings,
                        font: activeObject.fontFamily
                    };
                }

                if (activeObject.fontWeight) {
                    this.fontSettings = {
                        ...this.fontSettings,
                        bold: activeObject.fontWeight === FontOptions.Bold
                    };
                }

                if (activeObject.fontStyle) {
                    this.fontSettings = {
                        ...this.fontSettings,
                        italic: activeObject.fontStyle === FontOptions.Italic
                    };
                }
            }
        }
    }

    private handleTextEditingEntered = (e: fabric.IEvent) => {
        const textObject = e.target as CustomFabricObject;
        if (textObject) {
            this.editingTextElementId = textObject.elementId ?? null;
        }
    };

    private handleTextEditingExited = (e: fabric.IEvent) => {
        const textObject = e.target as CustomFabricObject;
        if (textObject) {
            this.editingTextElementId = null;
        }
    };

    captureCanvasImage = (): string => {
        if (!this.canvas) {
            console.error('Canvas is not initialized');
            return '';
        }

        // Get the current dimensions of the canvas
        const width = this.canvas.getWidth();
        const height = this.canvas.getHeight();

        // Create a temporary canvas with the same dimensions
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = width;
        tempCanvas.height = height;
        const tempCtx = tempCanvas.getContext('2d');

        if (!tempCtx) {
            console.error('Unable to get 2D context');
            return '';
        }

        // Fill the background if needed (e.g., white background)
        tempCtx.fillStyle = 'white';
        tempCtx.fillRect(0, 0, width, height);

        // Render the Fabric canvas onto the temporary canvas
        this.canvas.renderAll();
        const fabricImage = this.canvas.toCanvasElement();
        tempCtx.drawImage(fabricImage, 0, 0);

        // Convert the temporary canvas to a data URI
        return tempCanvas.toDataURL('image/png');
    };

    createSprite = (playerId: string, i: number, j: number) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return (sprite: fabric.Sprite) => {
            sprite.set({
                left: i,
                top: j,
                elementId: playerId,
                hasControls: false,
                hasBorders: false
            });
            this.canvas!.add(sprite);
            sprite.play();

            // not sure do we need the sprite animation play all the time, as
            // this approach will cause the canvas to be continuously redrawn,
            // which may impact performance

            // Animation loop to ensure continuous sprite animation
            const animate = () => {
                this.canvas!.renderAll();
                fabric.util.requestAnimFrame(animate);
            };

            // Start the animation loop
            animate();
        };
    };
}

export default CanvasController;
