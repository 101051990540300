import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import getIconDefinition from '../../lib/getIconDefinition.ts';

interface CopyButtonProps {
    id?: string;
    cssClass: string;
    copyText: string;
    title?: string;
    iconCssClass?: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({
    id,
    cssClass,
    copyText,
    title,
    iconCssClass = 'fas fa-copy'
}) => {
    const [state, setState] = useState({
        text: '',
        iconCssClass,
        isDisabled: false
    });
    const [isMacOS, setIsMacOS] = useState(false);

    useEffect(() => {
        setIsMacOS(navigator.platform.toUpperCase().indexOf('MAC') >= 0);
    }, []);

    const copyToClipboardAsync = async () => {
        const temp = state;
        setState({
            text: 'Copied',
            iconCssClass: 'fas fa-check',
            isDisabled: true
        });
        await navigator.clipboard.writeText(copyText);
        setTimeout(() => {
            setState(temp);
        }, 2000);
    };

    const escapedCopyText = () => {
        return escape(copyText);
    };

    return (
        <a
            className={cssClass}
            type="button"
            id={id}
            onClick={
                isMacOS
                    ? () => navigator.clipboard.writeText(escapedCopyText())
                    : copyToClipboardAsync
            }
            style={{whiteSpace: 'nowrap'}}
        >
            {state.text}
            <FontAwesomeIcon
                icon={getIconDefinition(state.iconCssClass)}
                aria-hidden="true"
                title={title}
            />
            <span className="visually-hidden">Copy</span>
        </a>
    );
};

export default CopyButton;
