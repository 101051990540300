import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type DailyAiImageCreationUsage = {
    dailyAiImageCreationKey: string;
    dailyAiImageCreationCount: number;
    dailyAiImageCreationLimit: number;
    hasReachedLimit: boolean;
    planId: string;
    hasPlan: boolean;
};

export type GetDailyAiImageCreationUsageQuery = {
    timezoneOffset: number;
};

const useGetDailyAiImageCreationUsage = (
    request: GetDailyAiImageCreationUsageQuery
) =>
    useGet<DailyAiImageCreationUsage>(
        `/api/assistant/get-daily-ai-image-creation-usage?${toQuery(request)}`,
        {
            errorMessage:
                'GET api/assistant/get-daily-ai-image-creation-usage failed'
        }
    );

export default useGetDailyAiImageCreationUsage;
