import {fabric} from 'fabric';
import {UnknownObject} from '../../types.ts';

export interface FontSettings {
    bold: boolean;
    italic: boolean;
    font: string | undefined;
    fontSize?: number | undefined;
}

export interface CanvasTransform {
    scale: number;
    translationX: number;
    translationY: number;
}

export interface CustomCanvas extends fabric.Canvas {
    isDragging?: boolean;
    lastPosX?: number;
    lastPosY?: number;
}

export interface CanvasState {
    version: string;
    objects: fabric.Object[];
}

export interface CustomFabricObject extends fabric.Object {
    elementId?: string;
    ordinalPosition?: number;
    uri?: string;
    videoElement?: HTMLVideoElement;
    meta?: string | null;
    nav?: string | null;
}

export const EventTypes = {
    ObjectMoving: 'object:moving',
    ObjectScaling: 'object:scaling',
    ObjectRotating: 'object:rotating',
    ObjectSkewing: 'object:skewing',
    ObjectCreated: 'object:created',
    ObjectAdded: 'object:added',
    ObjectRemoved: 'object:removed',
    ObjectModified: 'object:modified',
    PathCreated: 'path:created',
    SelectionCreated: 'selection:created',
    SelectionUpdated: 'selection:updated',
    SelectionCleared: 'selection:cleared',
    MouseUp: 'mouse:up',
    MouseMove: 'mouse:move',
    MouseDown: 'mouse:down',
    MouseOut: 'mouse:out',
    OrdinalPositionsChange: 'ordinalposition:change',
    TextEditingEntered: 'text:editing:entered',
    TextEditingExited: 'text:editing:exited'
};

export enum Tool {
    None,
    Select,
    Pencil,
    Rect,
    Text,
    Circle,
    Image,
    Video
}

export const ObjectTypes = {
    None: '',
    Rect: 'rect',
    Circle: 'circle',
    Text: 'i-text',
    Image: 'image',
    Video: 'video',
    ActiveSelection: 'activeSelection',
    Group: 'group'
};

export const FontOptions = {
    Bold: 'bold',
    Italic: 'italic'
};

export type ElementData = {
    type: string;
    version: string;
} & UnknownObject;

export interface VideoElement {
    id: string;
    element: HTMLVideoElement;
}
