import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {
    $getNodeByKey,
    $getSelection,
    $isNodeSelection,
    $isRangeSelection,
    $setSelection,
    CLICK_COMMAND,
    COMMAND_PRIORITY_LOW,
    DRAGSTART_COMMAND,
    KEY_BACKSPACE_COMMAND,
    KEY_DELETE_COMMAND,
    KEY_ENTER_COMMAND,
    KEY_ESCAPE_COMMAND,
    LexicalCommand,
    SELECTION_CHANGE_COMMAND,
    createCommand,
    type BaseSelection,
    type LexicalEditor,
    type NodeKey
} from 'lexical';
import {useLexicalNodeSelection} from '@lexical/react/useLexicalNodeSelection';
import {mergeRegister} from '@lexical/utils';

import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {$isImageNode} from './ImageNode';
import MediaResizer from './MediaResizer';
import Fallback, {FallbackType} from './Fallback';
import classNames from 'classnames';
import defendAgainstNaN from '../utils/defendAgainstNaN.ts';

export const RIGHT_CLICK_IMAGE_COMMAND: LexicalCommand<MouseEvent> =
    createCommand('RIGHT_CLICK_IMAGE_COMMAND');

export const KEYBOARD_SELECT_IMAGE_COMMAND: LexicalCommand<HTMLElement> =
    createCommand('KEYBOARD_SELECT_IMAGE_COMMAND');

export function LazyImage({
    altText,
    className,
    imageRef,
    src,
    width,
    height,
    maxWidth,
    fallbackType = FallbackType.image
}: {
    altText: string;
    className: string | null;
    height: 'inherit' | number;
    imageRef: {current: null | HTMLImageElement};
    maxWidth: number;
    src: string;
    width: 'inherit' | number;
    fallbackType?: FallbackType;
}): JSX.Element {
    const [loaded, setLoaded] = useState(false);
    const [fallbackWidth, setFallbackWidth] = useState(
        width == 'inherit' ? 560 : width
    );
    const [fallbackHeight, setFallbackHeight] = useState(
        height == 'inherit' ? 315 : height
    );

    const setFallbackSize = useCallback(
        (image: HTMLImageElement) => {
            const wait = setInterval(function () {
                let w = image.naturalWidth;
                let h = image.naturalHeight;
                if (w && h) {
                    clearInterval(wait);
                    if (w > maxWidth) {
                        const ratio = maxWidth / w;
                        h = ratio * h;
                        w = maxWidth;
                    }

                    setFallbackWidth(w);
                    setFallbackHeight(h);
                }
            }, 30);
        },
        [maxWidth]
    );

    useEffect(() => {
        if (
            imageRef?.current != null &&
            (width == 'inherit' || height == 'inherit')
        ) {
            setFallbackSize(imageRef.current);
        }
        if (imageRef.current?.complete) {
            setLoaded(true);
        }
    }, [height, imageRef, setFallbackSize, width]);

    return (
        <>
            <Fallback
                width={fallbackWidth}
                height={fallbackHeight}
                type={fallbackType}
                isLoaded={loaded}
            />
            <img
                className={classNames(
                    loaded ? 'd-block' : 'd-none',
                    className ?? undefined
                )}
                src={src}
                alt={altText}
                ref={imageRef}
                style={{
                    height,
                    maxWidth,
                    width
                }}
                onLoad={() => setLoaded(true)}
            />
        </>
    );
}

export default function ImageComponent({
    src,
    altText,
    nodeKey,
    width,
    height,
    resizable,
    maxWidth
}: {
    altText: string;
    height: 'inherit' | number;
    maxWidth: number;
    nodeKey: NodeKey;
    resizable: boolean;
    src: string;
    width: 'inherit' | number;
}): JSX.Element {
    const imageRef = useRef<null | HTMLImageElement>(null);
    const [isSelected, setSelected, clearSelection] =
        useLexicalNodeSelection(nodeKey);
    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [editor] = useLexicalComposerContext();
    const [selection, setSelection] = useState<BaseSelection | null>(null);
    const activeEditorRef = useRef<LexicalEditor | null>(null);

    const $onDelete = useCallback(
        (payload: KeyboardEvent) => {
            if (isSelected && $isNodeSelection($getSelection())) {
                const event: KeyboardEvent = payload;
                event.preventDefault();
                const node = $getNodeByKey(nodeKey);
                if ($isImageNode(node)) {
                    node.remove();
                    return true;
                }
            }
            return false;
        },
        [isSelected, nodeKey]
    );

    const $onEnter = useCallback(() => {
        const latestSelection = $getSelection();
        if (isSelected && $isNodeSelection(latestSelection)) {
            //create new paragraph after node
            const nodes = latestSelection.getNodes();
            if (nodes.length == 1) {
                const node = nodes[0];
                node.selectEnd();
                return true;
            }
            return false;
        }
        return false;
    }, [isSelected]);

    const $onEscape = useCallback(() => {
        const latestSelection = $getSelection();
        if (
            isSelected &&
            $isNodeSelection(latestSelection) &&
            latestSelection.getNodes().length === 1
        ) {
            // deselect node
            $setSelection(null);
            return true;
        }
        return false;
    }, [isSelected]);

    const onKeyboardSelect = useCallback(
        (payload: HTMLElement) => {
            const imageToSelect = payload;

            if (imageToSelect === imageRef.current) {
                setSelected(true);
                return true;
            }

            return false;
        },
        [setSelected]
    );

    const onClick = useCallback(
        (payload: MouseEvent) => {
            const event = payload;

            if (isResizing) {
                return true;
            }
            if (event.target === imageRef.current) {
                if (event.shiftKey) {
                    setSelected(!isSelected);
                } else {
                    clearSelection();
                    setSelected(true);
                }
                return true;
            }

            return false;
        },
        [isResizing, isSelected, setSelected, clearSelection]
    );

    const onRightClick = useCallback(
        (event: MouseEvent): void => {
            editor.getEditorState().read(() => {
                const latestSelection = $getSelection();
                const domElement = event.target as HTMLElement;
                if (
                    domElement.tagName === 'IMG' &&
                    $isRangeSelection(latestSelection) &&
                    latestSelection.getNodes().length === 1
                ) {
                    editor.dispatchCommand(RIGHT_CLICK_IMAGE_COMMAND, event);
                }
            });
        },
        [editor]
    );

    React.useEffect(() => {
        let isMounted = true;
        const rootElement = editor.getRootElement();
        const unregister = mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                if (isMounted) {
                    setSelection(editorState.read(() => $getSelection()));
                }
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_, activeEditor) => {
                    activeEditorRef.current = activeEditor;
                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand<MouseEvent>(
                CLICK_COMMAND,
                onClick,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand<MouseEvent>(
                RIGHT_CLICK_IMAGE_COMMAND,
                onClick,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                DRAGSTART_COMMAND,
                event => {
                    if (event.target === imageRef.current) {
                        // Stops firefox drag and drop image behaviour which is different from other browsers
                        event.preventDefault();
                        return true;
                    }
                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_DELETE_COMMAND,
                $onDelete,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_BACKSPACE_COMMAND,
                $onDelete,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_ENTER_COMMAND,
                $onEnter,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_ESCAPE_COMMAND,
                $onEscape,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEYBOARD_SELECT_IMAGE_COMMAND,
                onKeyboardSelect,
                COMMAND_PRIORITY_LOW
            )
        );

        rootElement?.addEventListener('click', onClick);
        rootElement?.addEventListener('pointerdown', onClick);
        rootElement?.addEventListener('contextmenu', onRightClick);

        return () => {
            isMounted = false;
            unregister();
            rootElement?.removeEventListener('contextmenu', onRightClick);
            rootElement?.removeEventListener('click', onClick);
            rootElement?.removeEventListener('pointerdown', onClick);
        };
    }, [
        clearSelection,
        editor,
        isResizing,
        isSelected,
        nodeKey,
        $onDelete,
        $onEnter,
        $onEscape,
        onClick,
        onRightClick,
        setSelected,
        onKeyboardSelect
    ]);

    const onResizeEnd = (
        nextWidth: 'inherit' | number,
        nextHeight: 'inherit' | number
    ) => {
        // Delay hiding the resize bars for click case
        setTimeout(() => {
            setIsResizing(false);
        }, 200);

        editor.update(() => {
            const node = $getNodeByKey(nodeKey);
            if ($isImageNode(node)) {
                node.setWidthAndHeight(nextWidth, nextHeight);
            }
        });
    };

    const onResizeStart = () => {
        setIsResizing(true);
    };

    const draggable = isSelected && $isNodeSelection(selection) && !isResizing;
    const isFocused = isSelected || isResizing;

    return (
        <span className="media-resizer-container">
            <div draggable={draggable}>
                <LazyImage
                    className={
                        isFocused
                            ? `focused ${
                                  $isNodeSelection(selection) ? 'draggable' : ''
                              }`
                            : null
                    }
                    src={src}
                    altText={altText}
                    imageRef={imageRef}
                    width={defendAgainstNaN(width)}
                    height={defendAgainstNaN(height)}
                    maxWidth={maxWidth}
                />
            </div>
            {resizable && $isNodeSelection(selection) && isFocused && (
                <MediaResizer
                    editor={editor}
                    mediaRef={imageRef}
                    maxWidth={maxWidth}
                    onResizeStart={onResizeStart}
                    onResizeEnd={onResizeEnd}
                />
            )}
        </span>
    );
}
