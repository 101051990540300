import React, {useEffect, useState} from 'react';
import {
    ChannelInfoModel,
    CollectionInfoModel,
    PostType
} from '../../api/types.ts';
import useGetChannel from '../../api/posts/useGetChannel.ts';
import {useQuery} from 'react-query';
import SpinnerIfLoading from '../shared/SpinnerIfLoading.tsx';
import GroupPosts from '../posts/GroupPosts.tsx';
import MxAuthorPost from '../posts/MxAuthorPost.tsx';
import {Modal, ModalBody} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

interface GroupPostsTabProps {
    collection: CollectionInfoModel;
    channelName: string;
}

const GroupPostsTab: React.FC<GroupPostsTabProps> = ({
    collection,
    channelName
}) => {
    const [channel, setChannel] = useState<ChannelInfoModel | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const getChannel = useGetChannel({
        collectionId: collection.id!,
        channelId: null,
        channelName
    });
    const {data: channelData} = useQuery(
        ['channel', collection.id, null, channelName],
        getChannel
    );

    const clickListener = (event: MouseEvent) => {
        if (
            event.target instanceof HTMLImageElement &&
            [...event.target.classList].some(c => c === 'img-fluid')
        ) {
            onClickImage(event.target.src);
        }
    };

    useEffect(() => {
        window.addEventListener('click', clickListener);

        return () => {
            window.removeEventListener('click', clickListener);
        };
    }, []);

    useEffect(() => {
        if (channelData) {
            setChannel(channelData);
        }
    }, [channelData]);

    const onClickImage = (url: string) => {
        setImageUrl(url);
    };

    const listener = (event: MouseEvent) => {
        if (
            event.target instanceof HTMLImageElement &&
            [...event.target.classList].some(c => c === 'img-fluid')
        ) {
            onClickImage(event.target.src);
        }
    };

    useEffect(() => {
        window.addEventListener('click', listener);

        return () => {
            window.removeEventListener('click', listener);
        };
    }, []);

    const hideImageModal = () => {
        setImageUrl(null);
    };

    return (
        <SpinnerIfLoading loading={channel === null}>
            <div className="container-fluid custom-container mt-2">
                <div className="row">
                    <div className="col-12 posts-holder">
                        <GroupPosts
                            collection={collection}
                            channel={channel!}
                        />
                        <div className="make-post-holder">
                            <MxAuthorPost
                                id={`GroupPosts_${collection.id}`}
                                collection={collection}
                                channel={channel!}
                                type={PostType.Post}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {imageUrl && (
                <Modal fullscreen toggle={hideImageModal} isOpen>
                    <ModalBody>
                        <div className="d-flex w-100 justify-content-end">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={hideImageModal}
                            >
                                <span>
                                    <FontAwesomeIcon icon={faTimes} />
                                </span>
                            </button>
                        </div>
                        <div className="d-flex justify-content-center">
                            <img src={imageUrl} className="img-fluid mt-3" />
                        </div>
                    </ModalBody>
                </Modal>
            )}
        </SpinnerIfLoading>
    );
};

export default GroupPostsTab;
