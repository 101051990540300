import {
    $applyNodeReplacement,
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    DecoratorNode,
    EditorConfig,
    LexicalNode,
    NodeKey,
    SerializedLexicalNode,
    Spread
} from 'lexical';
import EmojiComponent from './EmojiComponent';
import isNullOrWhiteSpace from '../../../lib/isNullOrWhiteSpace.ts';

export interface EmojiPayload {
    altText: string;
    height?: number;
    key?: NodeKey;
    src: string;
    width?: number;
    title: string;
    code: string;
}

function $convertEmojiElement(domNode: Node): null | DOMConversionOutput {
    if (domNode instanceof HTMLImageElement) {
        const {alt: altText, src, title} = domNode;
        const code = domNode.getAttribute('data-emoji-code') ?? '';
        const widthAttributeValue = domNode.getAttribute('width');
        const heightAttributeValue = domNode.getAttribute('height');

        const width = isNullOrWhiteSpace(widthAttributeValue)
            ? undefined
            : +widthAttributeValue!;
        const height = isNullOrWhiteSpace(heightAttributeValue)
            ? undefined
            : +heightAttributeValue!;

        const node = $createEmojiNode({
            altText,
            height,
            src,
            width,
            title,
            code
        });
        return {node};
    }
    return null;
}

export type SerializedEmojiNode = Spread<
    {
        altText: string;
        height?: number;
        src: string;
        width?: number;
        title: string;
        code: string;
    },
    SerializedLexicalNode
>;

export class EmojiNode extends DecoratorNode<JSX.Element> {
    __src: string;
    __altText: string;
    __width: 'inherit' | number;
    __height: 'inherit' | number;
    __title: string;
    __code: string;

    static getType(): string {
        return 'emoji';
    }

    static clone(node: EmojiNode): EmojiNode {
        return new EmojiNode(
            node.__src,
            node.__altText,
            node.__title,
            node.__code,
            node.__width,
            node.__height,
            node.__key
        );
    }

    static importJSON(serializedNode: SerializedEmojiNode): EmojiNode {
        const {altText, height, width, src, title, code} = serializedNode;
        const node = $createEmojiNode({
            altText,
            height,
            src,
            width,
            title,
            code
        });
        return node;
    }

    static importDOM(): DOMConversionMap | null {
        return {
            img: (domNode: HTMLElement) => {
                if (!domNode.hasAttribute('data-emoji-code')) {
                    return null;
                }
                return {conversion: $convertEmojiElement, priority: 0};
            }
        };
    }

    constructor(
        src: string,
        altText: string,
        title: string,
        code: string,
        width?: 'inherit' | number,
        height?: 'inherit' | number,
        key?: NodeKey
    ) {
        super(key);
        this.__src = src;
        this.__altText = altText;
        this.__code = code;
        this.__width = width || 'inherit';
        this.__height = height || 'inherit';
        this.__title = title;
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement('img');
        element.setAttribute('src', this.__src);
        element.setAttribute('alt', this.__altText);
        element.setAttribute('width', this.__width.toString());
        element.setAttribute('height', this.__height.toString());
        element.setAttribute('title', this.__title);
        element.setAttribute('data-emoji-code', this.__code);
        element.setAttribute('class', 'emoji created-by-lexical');
        return {element};
    }

    exportJSON(): SerializedEmojiNode {
        return {
            altText: this.getAltText(),
            height: this.__height === 'inherit' ? 0 : this.__height,
            src: this.getSrc(),
            type: 'emoji',
            version: 1,
            width: this.__width === 'inherit' ? 0 : this.__width,
            title: this.getTitle(),
            code: this.getCode()
        };
    }

    getSrc(): string {
        return this.__src;
    }

    getAltText(): string {
        return this.__altText;
    }

    getTitle(): string {
        return this.__title;
    }

    getCode(): string {
        return this.__code;
    }

    setWidthAndHeight(
        width: 'inherit' | number,
        height: 'inherit' | number
    ): void {
        const writable = this.getWritable();
        writable.__width = width;
        writable.__height = height;
    }

    // View

    createDOM(config: EditorConfig): HTMLElement {
        const span = document.createElement('span');
        const className = config.theme.emoji;
        if (className !== undefined) {
            span.className = className;
        }
        return span;
    }

    updateDOM(): false {
        return false;
    }

    decorate(): JSX.Element {
        return (
            <EmojiComponent
                src={this.__src}
                altText={this.__altText}
                width={this.__width}
                height={this.__height}
                nodeKey={this.getKey()}
            />
        );
    }

    isInline(): true {
        return true;
    }

    isIsolated(): true {
        return true;
    }
}

export function $createEmojiNode({
    altText,
    height,
    src,
    width,
    key,
    title,
    code
}: EmojiPayload): EmojiNode {
    return $applyNodeReplacement(
        new EmojiNode(src, altText, title, code, width, height, key)
    );
}

export function $isEmojiNode(
    node: LexicalNode | null | undefined
): node is EmojiNode {
    return node instanceof EmojiNode;
}
