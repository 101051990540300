import React from 'react';
import {FormGroup, Label, Input} from 'reactstrap';

interface BoxEditorProps {
    size: number;
    onChange: (size: number) => void;
}

const BoxEditor: React.FC<BoxEditorProps> = ({size, onChange}) => {
    const handleSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onChange(isNaN(value) ? 0 : value);
    };

    return (
        <FormGroup>
            <Label>Size:</Label>
            <Input
                type="number"
                step="0.1"
                value={size}
                onChange={handleSizeChange}
            />
        </FormGroup>
    );
};

export default BoxEditor;
