class RoleNames {
    static readonly Admin: string = 'admin';
    static readonly User: string = 'user';
    static readonly ContentEditor: string = 'contenteditor';
    static readonly TemplateAiEditor: string = 'templateaieditor';

    static get All(): string[] {
        return [
            RoleNames.User,
            RoleNames.Admin,
            RoleNames.ContentEditor,
            RoleNames.TemplateAiEditor
        ];
    }
}

export default RoleNames;
