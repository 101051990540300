import {CollectionInfoModel} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetCollectionInfoQuery = {
    collectionId: string | null;
    containerName: string;
};

const useGetCollectionInfo = (request: GetCollectionInfoQuery) =>
    useGet<CollectionInfoModel>(
        `/api/collections/get-collection-info?${toQuery(request)}`,
        {errorMessage: 'GET api/collections/get-collection-info failed'}
    );

export default useGetCollectionInfo;
