import React from 'react';

interface CustomContainerProps {
    children: React.ReactNode;
    className?: string;
}

const CustomContainer: React.FC<CustomContainerProps> = ({
    children,
    className
}) => {
    return (
        <div className={`container-fluid custom-container ${className}`}>
            {children}
        </div>
    );
};

export default CustomContainer;
