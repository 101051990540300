import {MixModel, ProjectDataIndex, ProjectTab} from '../../../api/types.ts';
import CanvasComponent from '../../canvas/CanvasComponent.tsx';

interface ProjectTabCanvasProps {
    mix: MixModel;
    tab: ProjectTab;
    dataIndex: ProjectDataIndex;
    readOnly: boolean;
    reactionsEnabled: boolean;
}

const ProjectTabCanvas: React.FC<ProjectTabCanvasProps> = ({
    mix,
    dataIndex,
    readOnly,
    reactionsEnabled
}) => {
    return (
        <CanvasComponent
            project={mix}
            dataIndex={dataIndex}
            readOnly={readOnly}
            reactionsEnabled={reactionsEnabled}
        />
    );
};

export default ProjectTabCanvas;
