import React, {useState} from 'react';
import {Button, ButtonGroup, ButtonToolbar} from 'reactstrap';
import {FaPause, FaPlay} from 'react-icons/fa6';

interface VideoToolbarProps {
    controlsDisabled: boolean;
    playSelectedVideo: () => void;
    pauseSelectedVideo: () => void;
}

const VideoToolbar: React.FC<VideoToolbarProps> = ({
    controlsDisabled,
    playSelectedVideo,
    pauseSelectedVideo
}) => {
    const [isVideoPlay, setIsVideoPlay] = useState(true);
    const onPlayClick = () => {
        playSelectedVideo();
        setIsVideoPlay(true);
    };
    const onPauseClick = () => {
        pauseSelectedVideo();
        setIsVideoPlay(false);
    };
    return (
        <>
            <ButtonToolbar>
                <ButtonGroup size="sm">
                    <Button
                        title="Play"
                        className={`buttons-that-select ${
                            isVideoPlay ? 'active' : ''
                        }`}
                        disabled={controlsDisabled}
                        onClick={onPlayClick}
                    >
                        <FaPlay size={17} />
                    </Button>
                    <Button
                        title="Pause"
                        className={`buttons-that-select ${
                            !isVideoPlay ? 'active' : ''
                        }`}
                        disabled={controlsDisabled}
                        onClick={onPauseClick}
                    >
                        <FaPause size={17} />
                    </Button>
                </ButtonGroup>
            </ButtonToolbar>
        </>
    );
};

export default VideoToolbar;
