interface VideoPlayerProps {
    videoSrc: string;
    videoType: string | undefined;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({videoSrc, videoType}) => {
    return (
        <div className="embed-responsive" style={{maxWidth: '560px'}}>
            <video controls className="w-100">
                <source src={videoSrc} typeof={videoType} />
            </video>
        </div>
    );
};

export default VideoPlayer;
