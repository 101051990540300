import {useCallback, useEffect, useState} from 'react';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {
    $createParagraphNode,
    $createTextNode,
    $getRoot,
    $getSelection,
    $isNodeSelection,
    $isRangeSelection,
    $isRootOrShadowRoot,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_CRITICAL,
    COMMAND_PRIORITY_NORMAL,
    FORMAT_TEXT_COMMAND,
    KEY_MODIFIER_COMMAND,
    REDO_COMMAND,
    UNDO_COMMAND
} from 'lexical';
import {
    $isListNode,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode
} from '@lexical/list';
import {$createLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link';
import {$setBlocksType} from '@lexical/selection';
import {
    $createHeadingNode,
    $isHeadingNode,
    HeadingTagType
} from '@lexical/rich-text';
import {
    $findMatchingParent,
    $getNearestNodeOfType,
    mergeRegister
} from '@lexical/utils';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faAt,
    faBold,
    faCode,
    faFaceSmile,
    faFile,
    faImage,
    faItalic,
    faLink,
    faList12,
    faListDots,
    faRedo,
    faStrikethrough,
    faUndo,
    faVideo
} from '@fortawesome/free-solid-svg-icons';
import {faYoutube} from '@fortawesome/free-brands-svg-icons';
import {
    Dropdown,
    Button,
    ButtonGroup,
    ButtonToolbar,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';
import type {InsertImagePayload} from './ImagePlugin';
import {INSERT_IMAGE_COMMAND} from './ImagePlugin';
import {INSERT_VIDEO_COMMAND, InsertVideoPayload} from './VideoPlugin';
import {INSERT_YOUTUBE_COMMAND, InsertYouTubePayload} from './YouTubePlugin';
import getSelectedNode from '../utils/getSelectedNode';
import sanitizeUrl from '../utils/sanitizeUrl';
import YouTubeVideoInsertModal from '../modals/YouTubeVideoInsertModal';
import {INSERT_EMOJI_COMMAND, InsertEmojiPayload} from './EmojiPlugin';
import {FileDataModel, Selection} from '../../../api/types.ts';
import {Emoji} from '../../../emojis/types.ts';
import MentionsModal from '../../shared/MentionsModal.tsx';
import AssetSelectAndUploadModal from '../../assets/AssetSelectAndUploadModal.tsx';
import {FileFormat} from '../../../lib/FileFormats.ts';
import EmojiPickerModal from '../../../emojis/EmojiPickerModal.tsx';

const blockTypeToBlockName = {
    bullet: 'Bulleted List',
    h1: 'Heading 1',
    h2: 'Heading 2',
    h3: 'Heading 3',
    h4: 'Heading 4',
    h5: 'Heading 5',
    h6: 'Heading 6',
    number: 'Numbered List',
    paragraph: 'Normal'
};

type BlockType = keyof typeof blockTypeToBlockName;

const ToolbarPlugin = ({
    imageSelector,
    videoSelector,
    youTubeSelector,
    fileSelector,
    emojiSelector,
    mentions,
    setIsLinkEditMode,
    specificContainerName,
    mentionsCollectionId
}: {
    imageSelector?: boolean;
    videoSelector?: boolean;
    youTubeSelector?: boolean;
    fileSelector?: boolean;
    emojiSelector?: boolean;
    mentions?: boolean;
    setIsLinkEditMode: (isLinkEditMode: boolean) => void;
    specificContainerName?: string | null;
    mentionsCollectionId?: string;
}) => {
    const [editor] = useLexicalComposerContext();
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isCode, setIsCode] = useState(false);
    const [isLink, setIsLink] = useState(false);
    const [blockType, setBlockType] = useState<BlockType>('paragraph');

    const isBullet = blockType == 'bullet';
    const isNumbered = blockType == 'number';
    const [formatDropdownOpen, setFormatDropdownOpen] = useState(false);
    const [isImageSelectModalOpen, setIsImageSelectModalOpen] = useState(false);
    const [isVideoSelectModalOpen, setIsVideoSelectModalOpen] = useState(false);
    const [isYouTubeSelectModalOpen, setIsYouTubeSelectModalOpen] =
        useState(false);
    const [isFileSelectModalOpen, setIsFileSelectModalOpen] = useState(false);
    const [isEmojiSelectModalOpen, setIsEmojiSelectModalOpen] = useState(false);
    const [isMentionModalOpen, setIsMentionModalOpen] = useState(false);

    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);

    const toggle = () => setFormatDropdownOpen(prevState => !prevState);

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            let element =
                anchorNode.getKey() === 'root'
                    ? anchorNode
                    : $findMatchingParent(anchorNode, e => {
                          const parent = e.getParent();
                          return parent !== null && $isRootOrShadowRoot(parent);
                      });

            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }

            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);

            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsStrikethrough(selection.hasFormat('strikethrough'));
            setIsCode(selection.hasFormat('code'));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }

            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType<ListNode>(
                        anchorNode,
                        ListNode
                    );
                    const type = parentList
                        ? parentList.getListType()
                        : element.getListType();
                    setBlockType(type as BlockType);
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    if (type in blockTypeToBlockName) {
                        setBlockType(type as keyof typeof blockTypeToBlockName);
                    }
                }
            }
        } else if ($isNodeSelection(selection)) {
            // Update links
            const nodes = selection.getNodes();
            if (nodes.length == 0) {
                return;
            }
            const node = nodes[0];
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand<boolean>(
                CAN_UNDO_COMMAND,
                payload => {
                    setCanUndo(payload);
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL
            ),
            editor.registerCommand<boolean>(
                CAN_REDO_COMMAND,
                payload => {
                    setCanRedo(payload);
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL
            )
        );
    }, [updateToolbar, editor]);

    useEffect(() => {
        return editor.registerCommand(
            KEY_MODIFIER_COMMAND,
            payload => {
                const event: KeyboardEvent = payload;
                const {code, ctrlKey, metaKey} = event;

                if (code === 'KeyK' && (ctrlKey || metaKey)) {
                    event.preventDefault();
                    let url: string | null;
                    if (!isLink) {
                        setIsLinkEditMode(true);
                        url = sanitizeUrl('https://');
                    } else {
                        setIsLinkEditMode(false);
                        url = null;
                    }
                    return editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
                }
                return false;
            },
            COMMAND_PRIORITY_NORMAL
        );
    }, [editor, isLink, setIsLinkEditMode]);

    const formatParagraph = () => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                $setBlocksType(selection, () => $createParagraphNode());
            }
        });
    };

    const formatHeading = (headingSize: HeadingTagType) => {
        if (blockType !== headingSize) {
            editor.update(() => {
                const selection = $getSelection();
                $setBlocksType(selection, () =>
                    $createHeadingNode(headingSize)
                );
            });
        }
    };

    const formatBulletList = () => {
        if (blockType !== 'bullet') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
            formatParagraph();
        }
    };

    const formatNumberedList = () => {
        if (blockType !== 'number') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
            formatParagraph();
        }
    };

    const formatBold = () => {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
    };

    const formatItalic = () => {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
    };

    const formatStrikethrough = () => {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
    };

    const formatCode = () => {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code');
    };

    const insertLink = useCallback(() => {
        if (!isLink) {
            setIsLinkEditMode(true);
            editor.dispatchCommand(
                TOGGLE_LINK_COMMAND,
                sanitizeUrl('https://')
            );
        } else {
            setIsLinkEditMode(false);
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [editor, isLink, setIsLinkEditMode]);

    const insertImage = (file: FileDataModel) => {
        const payload: InsertImagePayload = {
            altText: 'Image',
            src: file.path
        };
        editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
    };

    const onImageSelectionChanged = (files: FileDataModel[] | undefined) => {
        if (!files) {
            return;
        }

        if (files.length > 0) {
            insertImage(files[0]);
        }

        setIsImageSelectModalOpen(false);
    };

    const insertVideo = (file: FileDataModel) => {
        const payload: InsertVideoPayload = {src: file.path};

        editor.dispatchCommand(INSERT_VIDEO_COMMAND, payload);
    };

    const onVideoSelectionChanged = (files: FileDataModel[] | undefined) => {
        if (!files) {
            return;
        }

        if (files.length > 0) {
            insertVideo(files[0]);
        }

        setIsVideoSelectModalOpen(false);
    };

    const insertYouTube = (videoID: string) => {
        const payload: InsertYouTubePayload = {videoID};

        editor.dispatchCommand(INSERT_YOUTUBE_COMMAND, payload);
    };

    const insertFileLink = (file: FileDataModel) => {
        editor.update(() => {
            const selection = $getSelection();
            const linkNode = $createLinkNode(file.path).append(
                $createTextNode(file.name)
            );
            if (selection) {
                selection.insertNodes([linkNode]);
            } else {
                const root = $getRoot();
                const paragraph = $createParagraphNode();
                paragraph.append(linkNode);
                root.append(paragraph);
            }
        });
    };

    const onFileSelectionChanged = (files: FileDataModel[] | undefined) => {
        if (!files) {
            return;
        }

        if (files.length > 0) {
            insertFileLink(files[0]);
        }

        setIsFileSelectModalOpen(false);
    };

    const insertEmoji = (emoji: Emoji) => {
        const payload: InsertEmojiPayload = {
            altText: emoji.alt,
            src: emoji.source,
            title: emoji.name,
            code: emoji.emojiCode,
            width: 22.4,
            height: 22.4
        };
        // console.log('insert emoji');
        editor.dispatchCommand(INSERT_EMOJI_COMMAND, payload);
        setIsEmojiSelectModalOpen(false);
    };

    const insertMention = useCallback(
        (mention: string) => {
            editor.update(() => {
                const selection = $getSelection();
                const linkNode = $createLinkNode(
                    `/explore/groups/@${mention}`
                ).append($createTextNode(`@${mention}`));
                if (selection) {
                    selection.insertNodes([linkNode]);
                } else {
                    const root = $getRoot();
                    const paragraph = $createParagraphNode();
                    paragraph.append(linkNode);
                    root.append(paragraph);
                }
            });
            setIsMentionModalOpen(false);
        },
        [editor]
    );

    const BlockFormatDropDown = (
        <Dropdown toggle={toggle} isOpen={formatDropdownOpen} direction="down">
            <DropdownToggle caret className="btn-sm" style={{width: '93px'}}>
                {blockType == 'bullet' || blockType == 'number'
                    ? blockTypeToBlockName['paragraph']
                    : blockTypeToBlockName[blockType]}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem
                    active={blockType === 'paragraph'}
                    onClick={formatParagraph}
                >
                    <span className="ms-1 text">Normal</span>
                </DropdownItem>
                <DropdownItem
                    active={blockType === 'h1'}
                    onClick={() => formatHeading('h1')}
                >
                    <span className="ms-1 text">Heading 1</span>
                </DropdownItem>
                <DropdownItem
                    active={blockType === 'h2'}
                    onClick={() => formatHeading('h2')}
                >
                    <span className="ms-1 text">Heading 2</span>
                </DropdownItem>
                <DropdownItem
                    active={blockType === 'h3'}
                    onClick={() => formatHeading('h3')}
                >
                    <span className="ms-1 text">Heading 3</span>
                </DropdownItem>
                <DropdownItem
                    active={blockType === 'h4'}
                    onClick={() => formatHeading('h4')}
                >
                    <span className="ms-1 text">Heading 4</span>
                </DropdownItem>
                <DropdownItem
                    active={blockType === 'h5'}
                    onClick={() => formatHeading('h5')}
                >
                    <span className="ms-1 text">Heading 5</span>
                </DropdownItem>
                <DropdownItem
                    active={blockType === 'h6'}
                    onClick={() => formatHeading('h6')}
                >
                    <span className="ms-1 text">Heading 6</span>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );

    return (
        <div style={{borderBottom: '1px solid #ddd'}} className="toolbar">
            <ButtonToolbar className="m-2">
                <ButtonGroup>
                    <Button
                        className="btn-sm btn-secondary"
                        active={isBold}
                        onClick={formatBold}
                        aria-label="Bold"
                    >
                        <FontAwesomeIcon icon={faBold} />
                    </Button>
                    <Button
                        className="btn-sm btn-secondary"
                        active={isItalic}
                        onClick={formatItalic}
                        aria-label="Italic"
                    >
                        <FontAwesomeIcon icon={faItalic} />
                    </Button>
                    <Button
                        className="btn-sm btn-secondary"
                        active={isStrikethrough}
                        onClick={formatStrikethrough}
                        aria-label="Strike through"
                    >
                        <FontAwesomeIcon icon={faStrikethrough} />
                    </Button>
                    <Button
                        className="btn-sm btn-secondary"
                        active={isCode}
                        onClick={formatCode}
                        aria-label="Code"
                    >
                        <FontAwesomeIcon icon={faCode} />
                    </Button>
                </ButtonGroup>
                <ButtonGroup className="ms-1">
                    <Button
                        className="btn-sm btn-secondary"
                        active={isBullet}
                        onClick={formatBulletList}
                        aria-label="Bullet list"
                    >
                        <FontAwesomeIcon icon={faListDots} />
                    </Button>
                    <Button
                        className="btn-sm btn-secondary"
                        active={isNumbered}
                        onClick={formatNumberedList}
                        aria-label="Numbered list"
                    >
                        <FontAwesomeIcon icon={faList12} />
                    </Button>
                </ButtonGroup>
                <ButtonGroup className="ms-1">
                    {BlockFormatDropDown}
                </ButtonGroup>
                <ButtonGroup className="ms-1">
                    <Button
                        active={isLink}
                        className="btn-sm btn-secondary"
                        onClick={insertLink}
                        aria-label="Insert link"
                        title="Insert link"
                    >
                        <FontAwesomeIcon icon={faLink} />
                    </Button>

                    {mentions && (
                        <Button
                            className="btn-sm btn-secondary"
                            onClick={() => setIsMentionModalOpen(true)}
                            aria-label="Add mention"
                            title="Add mention"
                        >
                            <FontAwesomeIcon icon={faAt} />
                            {isMentionModalOpen && (
                                <MentionsModal
                                    onClose={() => setIsMentionModalOpen(false)}
                                    onSelect={insertMention}
                                    collectionId={mentionsCollectionId}
                                />
                            )}
                        </Button>
                    )}
                </ButtonGroup>
                <ButtonGroup className="ms-1">
                    {imageSelector && (
                        <Button
                            className="btn-sm btn-secondary"
                            onClick={() => setIsImageSelectModalOpen(true)}
                            aria-label="Insert image"
                            title="Insert image"
                        >
                            <FontAwesomeIcon icon={faImage} />
                            {isImageSelectModalOpen && (
                                <AssetSelectAndUploadModal
                                    options={{
                                        selection: Selection.Single,
                                        explanation:
                                            'Please select an image file',
                                        formats: FileFormat.Image,
                                        fileSelectionChanged:
                                            onImageSelectionChanged,
                                        specificContainerName:
                                            specificContainerName ?? undefined
                                    }}
                                    onClose={() =>
                                        setIsImageSelectModalOpen(false)
                                    }
                                />
                            )}
                        </Button>
                    )}

                    {videoSelector && (
                        <Button
                            className="btn-sm btn-secondary"
                            onClick={() => setIsVideoSelectModalOpen(true)}
                            aria-label="Insert video"
                            title="Insert video"
                        >
                            <FontAwesomeIcon icon={faVideo} />
                            {isVideoSelectModalOpen && (
                                <AssetSelectAndUploadModal
                                    options={{
                                        selection: Selection.Single,
                                        explanation:
                                            'Please select an video file',
                                        formats: FileFormat.Video,
                                        fileSelectionChanged:
                                            onVideoSelectionChanged,
                                        specificContainerName:
                                            specificContainerName ?? undefined
                                    }}
                                    onClose={() =>
                                        setIsVideoSelectModalOpen(false)
                                    }
                                />
                            )}
                        </Button>
                    )}

                    {youTubeSelector && (
                        <Button
                            className="btn-sm btn-secondary"
                            onClick={() => setIsYouTubeSelectModalOpen(true)}
                            aria-label="Insert YouTube video"
                            title="Insert YouTube video"
                        >
                            <FontAwesomeIcon icon={faYoutube} />
                            {isYouTubeSelectModalOpen && (
                                <YouTubeVideoInsertModal
                                    onClose={() =>
                                        setIsYouTubeSelectModalOpen(false)
                                    }
                                    onSelectYouTubeVideo={insertYouTube}
                                />
                            )}
                        </Button>
                    )}

                    {fileSelector && (
                        <Button
                            className="btn-sm btn-secondary"
                            onClick={() => setIsFileSelectModalOpen(true)}
                            aria-label="Insert file"
                            title="Insert file"
                        >
                            <FontAwesomeIcon icon={faFile} />
                            {isFileSelectModalOpen && (
                                <AssetSelectAndUploadModal
                                    options={{
                                        selection: Selection.Single,
                                        explanation: 'Please select a file',
                                        formats: FileFormat.Any,
                                        fileSelectionChanged:
                                            onFileSelectionChanged,
                                        specificContainerName:
                                            specificContainerName ?? undefined
                                    }}
                                    onClose={() =>
                                        setIsFileSelectModalOpen(false)
                                    }
                                />
                            )}
                        </Button>
                    )}

                    {emojiSelector && (
                        <Button
                            className="btn-sm btn-secondary"
                            onClick={() => setIsEmojiSelectModalOpen(true)}
                            aria-label="Insert emoji"
                            title="Insert emoji"
                        >
                            <FontAwesomeIcon icon={faFaceSmile} />
                            {isEmojiSelectModalOpen && (
                                <EmojiPickerModal
                                    onClose={() =>
                                        setIsEmojiSelectModalOpen(false)
                                    }
                                    onSelect={insertEmoji}
                                />
                            )}
                        </Button>
                    )}
                </ButtonGroup>
                <ButtonGroup className="ms-1">
                    <Button
                        className="btn-sm btn-secondary"
                        disabled={!canUndo}
                        onClick={() => {
                            editor.dispatchCommand(UNDO_COMMAND, undefined);
                        }}
                        aria-label="Undo"
                    >
                        <FontAwesomeIcon icon={faUndo} />
                    </Button>
                    <Button
                        className="btn-sm btn-secondary"
                        disabled={!canRedo}
                        onClick={() => {
                            editor.dispatchCommand(REDO_COMMAND, undefined);
                        }}
                        aria-label="Redo"
                    >
                        <FontAwesomeIcon icon={faRedo} />
                    </Button>
                </ButtonGroup>
            </ButtonToolbar>
        </div>
    );
};

export default ToolbarPlugin;
