import {Spinner} from 'reactstrap';
import {
    AiChunk,
    AiFeedbackItems,
    ContentPackageData,
    MixModel,
    ProjectDataIndex,
    ProjectTab,
    TemplateContentConfig
} from '../../../api/types.ts';
import useProjectData from '../../../project_data/useProjectData.ts';
import ReconnectionModal from '../../../project_data/ReconnectionModal.tsx';
import MxContentPackage from '../../content/MxContentPackage.tsx';
import {useAuthStateManager} from '../../../hooks/useAuthStateManager.tsx';
import {useOnPasteImage} from '../../content_editor/hooks/useOnPasteImage.ts';
import React, {
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import {isNotNullOrWhiteSpace} from '../../../lib/isNullOrWhiteSpace.ts';
import {v4 as uuidv4} from 'uuid';
import useAiHub from '../../assistant/useAiHub.ts';
import useProvideProjectContentFeedback from '../../../api/assistant/useProvideProjectContentFeedback.ts';
import {useMutation} from 'react-query';
import unknownErrorToString from '../../../lib/unknownErrorToString.ts';
import ButtonAsync from '../../shared/ButtonAsync.tsx';

interface ProjectTabContentProps {
    mix: MixModel;
    tab: ProjectTab;
    dataIndex: ProjectDataIndex;
    readOnly: boolean;
    templateConfig?: TemplateContentConfig | null;
}

const ProjectTabContent: React.FC<ProjectTabContentProps> = ({
    mix,
    dataIndex,
    readOnly,
    templateConfig
}) => {
    const {id} = mix;
    const mixId = id!;

    const {authData} = useAuthStateManager();

    const onPasteImage = useOnPasteImage({
        containerName: authData.container_name!,
        folderPath: ['project_files', mixId]
    });

    const {
        projectData,
        manipulation,
        isLoading,
        isError,
        showReconnectionModal,
        closeReconnectionModal,
        reinitialize
    } = useProjectData({projectId: mix.id!, dataIndex});

    const contentPackageData = projectData as unknown as ContentPackageData;

    /*
     * NOTE: isTemplateContentPackage = true if this content package has some sections with a templateKey
     * and no templateConfig which means the current logged-in user is not editing a template
     * but instead is editing a project created from a template
     * Template content packages are locked down to editable and non-editable sections
     * */
    const isTemplateContentPackage =
        Object.values(contentPackageData).some(section =>
            isNotNullOrWhiteSpace(section.templateKey)
        ) && !templateConfig;

    const [feedbacks, setFeedbacks] = useState<AiFeedbackItems>({});
    const requestIdRef = useRef('');

    const onChunkGenerated = useCallback((aiChunk: AiChunk) => {
        if (aiChunk.requestId === requestIdRef.current) {
            setFeedbacks(prevFeedbacks => {
                return {
                    ...prevFeedbacks,
                    [aiChunk.fieldKey]:
                        (prevFeedbacks[aiChunk.fieldKey] || '') + aiChunk.chunk
                };
            });
        }
    }, []);

    const [groupName, setGroupName] = useState<string | null>(null);
    useEffect(() => {
        if (isTemplateContentPackage) {
            setGroupName(uuidv4());
        }
    }, [isTemplateContentPackage]);

    useAiHub({
        groupName,
        onChunkGenerated
    });

    const provideProjectContentFeedback = useProvideProjectContentFeedback();
    const provideProjectContentFeedbackMutation = useMutation(
        provideProjectContentFeedback,
        {
            onSuccess: () => {},
            onError: (error: unknown) => {
                console.log(unknownErrorToString(error));
            }
        }
    );

    const handleProvideTemplateFeedback = async () => {
        if (!isTemplateContentPackage || !groupName) {
            return;
        }

        setFeedbacks({});

        requestIdRef.current = uuidv4();

        await provideProjectContentFeedbackMutation.mutateAsync({
            groupName: groupName,
            projectId: mix.id!,
            dataIndex: dataIndex,
            requestId: requestIdRef.current
        });
    };

    const controlsBottom: ReactNode = (() => {
        if (!readOnly && isTemplateContentPackage) {
            return (
                <div className="d-flex justify-content-end">
                    <ButtonAsync
                        color="secondary"
                        className="me-2"
                        onClick={handleProvideTemplateFeedback}
                    >
                        Get feedback
                    </ButtonAsync>
                </div>
            );
        }

        return null;
    })();

    if (isLoading) {
        return (
            <div className="spinner-full">
                <Spinner color="primary" />
            </div>
        );
    }

    if (isError) {
        return <>Error loading project data</>;
    }

    if (showReconnectionModal && !readOnly) {
        return (
            <ReconnectionModal
                reinitialize={reinitialize}
                onClose={closeReconnectionModal}
            />
        );
    }

    return (
        <MxContentPackage
            entityData={projectData}
            manipulation={manipulation}
            readOnly={readOnly}
            onPasteImage={onPasteImage}
            templateConfig={templateConfig}
            isTemplateContentPackage={isTemplateContentPackage}
            feedbacks={feedbacks}
            controlsBottom={controlsBottom}
        />
    );
};

export default ProjectTabContent;
