import React, {useState} from 'react';
import {useAuthStateManager} from '../../hooks/useAuthStateManager';
import useAddPendingGroupMember, {
    AddPendingGroupMemberCommand
} from '../../api/groups/useAddPendingGroupMember';
import {toast} from 'react-toastify';
import {
    Modal,
    Button,
    FormGroup,
    Label,
    Input,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {MembershipStatus, UserCollectionType} from '../../api/types.ts';
import {useMutation} from 'react-query';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import ButtonAsync from '../shared/ButtonAsync.tsx';

interface AddGroupMemberCardProps {
    collectionId: string;
    onMemberUpdated: () => void;
}

const AddGroupMemberCard: React.FC<AddGroupMemberCardProps> = ({
    collectionId,
    onMemberUpdated
}) => {
    const {authData} = useAuthStateManager();
    const [showModal, setShowModal] = useState(false);
    const [nicknameOrEmail, setNicknameOrEmail] = useState('');
    const [userCollectionType, setUserCollectionType] = useState(
        UserCollectionType.Contributor
    );

    const addPendingGroupMember = useAddPendingGroupMember();
    const addPendingGroupMemberMutation = useMutation(addPendingGroupMember, {
        onSuccess: () => {
            toast.success('Member invited to group');
            hideModal();
            onMemberUpdated();
        },
        onError: (error: unknown) => {
            toast.error(
                'Failed to accept invite ' + unknownErrorToString(error)
            );
        }
    });

    const canInvite = () => {
        return nicknameOrEmail.trim() !== '';
    };

    const inviteAsync = async () => {
        if (!authData.id) {
            toast.error('Unable to send invite.');
            return;
        }

        const command: AddPendingGroupMemberCommand = {
            collectionId,
            groupAdminId: authData.id,
            nicknameOrEmailOrUserId: nicknameOrEmail,
            userCollectionType,
            membershipStatus: MembershipStatus.Invited
        };

        await addPendingGroupMemberMutation.mutateAsync(command);
    };

    const hideModal = () => {
        setNicknameOrEmail('');
        setUserCollectionType(UserCollectionType.Contributor);
        setShowModal(false);
    };

    const inviteMember = () => {
        setShowModal(true);
    };

    const handleUserCollectionTypeChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const value = parseInt(e.target.value, 10);
        setUserCollectionType(value as UserCollectionType);
    };

    return (
        <>
            <div
                className="member-card mixi-card create-card hover-lift"
                onClick={inviteMember}
            >
                <div className="card-content d-flex flex-row align-items-center">
                    <div className="card-body">
                        <div>
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                        <h5 className="card-title">Invite new member</h5>
                    </div>
                </div>
            </div>

            {showModal && (
                <Modal isOpen={showModal} toggle={hideModal}>
                    <ModalHeader toggle={hideModal}>Add member</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="NicknameOrEmail">
                                Nickname or email
                            </Label>
                            <Input
                                type="text"
                                id="NicknameOrEmail"
                                value={nicknameOrEmail}
                                onChange={e =>
                                    setNicknameOrEmail(e.target.value)
                                }
                                maxLength={2048}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="UserCollectionType">Member type</Label>
                            <Input
                                type="select"
                                id="UserCollectionType"
                                value={userCollectionType.toString()}
                                onChange={handleUserCollectionTypeChange}
                            >
                                {Object.entries(UserCollectionType)
                                    .filter(([key]) => isNaN(Number(key)))
                                    .map(([key, value]) => (
                                        <option key={value} value={value}>
                                            {key}
                                        </option>
                                    ))}
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={hideModal}>
                            Cancel
                        </Button>
                        <ButtonAsync
                            color="primary"
                            disabled={!canInvite()}
                            onClick={inviteAsync}
                        >
                            Invite
                        </ButtonAsync>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default AddGroupMemberCard;
