import React from 'react';
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';
import Gallery from './Gallery.tsx';
import {MixModel} from '../../api/types.ts';

interface SelectProjectModalProps {
    onClose: () => void;
    onProjectSelected: (mix: MixModel) => void;
    myProjects?: boolean;
}

const SelectProjectModal: React.FC<SelectProjectModalProps> = ({
    onClose,
    onProjectSelected,
    myProjects
}) => {
    return (
        <Modal toggle={() => onClose()} isOpen fullscreen>
            <ModalBody>
                <div className="row">
                    <div className="col-12">
                        <Gallery
                            myProjects={myProjects ?? false}
                            onProjectClick={mix =>
                                mix.id && onProjectSelected(mix)
                            }
                            options={{
                                disableSwitchMixType: true,
                                transientState: true
                            }}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => onClose()}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default SelectProjectModal;
