import {
    PagedResult,
    ActivityModel,
    UserActivitiesFor,
    Pagination
} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetUserActivitiesQuery = {
    userActivitiesFor: UserActivitiesFor;
    collectionId: string | null;
    pagination: Pagination;
};

const useGetUserActivities = (request: GetUserActivitiesQuery) =>
    useGet<PagedResult<ActivityModel>>(
        `/api/user-activities/get-user-activities?${toQuery(request)}`,
        {errorMessage: 'GET api/user-activities/get-user-activities failed'}
    );

export default useGetUserActivities;
