import React from 'react';
import {Button, ButtonGroup, ButtonToolbar} from 'reactstrap';
import {BsEraser, BsLayerBackward, BsLayerForward} from 'react-icons/bs';
import {BiLayerMinus, BiLayerPlus} from 'react-icons/bi';
import {GrDuplicate} from 'react-icons/gr';
import {LuGroup} from 'react-icons/lu';
import {LuUngroup} from 'react-icons/lu';
import {ObjectTypes} from './types';

interface EditToolbarProps {
    onRemoveSelected: () => void;
    onDuplicate: () => void;
    onBringForward: () => void;
    onBringBackwards: () => void;
    onToFront: () => void;
    onSendToBack: () => void;
    onGroupItems: () => void;
    onUngroupItems: () => void;
    controlsDisabled: boolean;
    activeSelection: string | null;
    activeObjectMeta: string | null | undefined;
}

const EditToolbar: React.FC<EditToolbarProps> = ({
    onRemoveSelected,
    onDuplicate,
    onBringForward,
    onBringBackwards,
    onToFront,
    onSendToBack,
    onGroupItems,
    onUngroupItems,
    controlsDisabled,
    activeSelection,
    activeObjectMeta
}) => {
    return (
        <>
            <ButtonToolbar>
                <ButtonGroup size="sm">
                    <Button
                        title="Delete"
                        className="toolbar-buttons"
                        onClick={onRemoveSelected}
                        disabled={controlsDisabled}
                    >
                        <BsEraser size={17} />
                    </Button>
                    <Button
                        title="Duplicate"
                        className="toolbar-buttons"
                        onClick={onDuplicate}
                        disabled={controlsDisabled}
                    >
                        <GrDuplicate size={17} />
                    </Button>
                    <Button
                        title="Bring Forward"
                        className="toolbar-buttons"
                        onClick={onBringForward}
                        disabled={controlsDisabled}
                    >
                        <BiLayerPlus size={20} />
                    </Button>
                    <Button
                        title="Send Backward"
                        className="toolbar-buttons"
                        onClick={onBringBackwards}
                        disabled={controlsDisabled}
                    >
                        <BiLayerMinus size={20} />
                    </Button>
                    <Button
                        title="Bring to Front"
                        className="toolbar-buttons"
                        onClick={onToFront}
                        disabled={controlsDisabled}
                    >
                        <BsLayerForward size={17} />
                    </Button>
                    <Button
                        title="Send to Back"
                        className="toolbar-buttons"
                        onClick={onSendToBack}
                        disabled={controlsDisabled}
                    >
                        <BsLayerBackward size={17} />
                    </Button>
                    {activeSelection == ObjectTypes.ActiveSelection && (
                        <Button
                            title="Group Items"
                            className="toolbar-buttons"
                            onClick={onGroupItems}
                            disabled={controlsDisabled}
                        >
                            <LuGroup size={20} />
                        </Button>
                    )}

                    {activeSelection == ObjectTypes.Group &&
                        activeObjectMeta !== 'fixed-group' && (
                            <Button
                                title="Ungroup Items"
                                className="toolbar-buttons"
                                onClick={onUngroupItems}
                                disabled={controlsDisabled}
                            >
                                <LuUngroup size={20} />
                            </Button>
                        )}
                </ButtonGroup>
            </ButtonToolbar>
        </>
    );
};

export default EditToolbar;
