import {
    MixModel,
    ProjectDataIndex,
    ProjectSceneScriptAndHtmlModel
} from '../../api/types.ts';
import React, {useEffect, useState} from 'react';
import useFetchProjectSceneScriptAndHtml from '../../api/scene/useFetchProjectSceneScriptAndHtml.ts';
import {useMutation, useQuery} from 'react-query';
import useUpdateSceneScriptAndHtml from '../../api/scene/useUpdateSceneScriptAndHtml.ts';
import {toast} from 'react-toastify';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import {ReactStateSetter} from '../../types.ts';
import {
    Alert,
    Button,
    Nav,
    NavItem,
    NavLink,
    Spinner,
    TabContent,
    TabPane
} from 'reactstrap';
import SceneBuilder from './SceneBuilder.tsx';
import CodeEditor, {TypeDefinitionsUrls} from '../shared/CodeEditor.tsx';

interface SceneToolsProps {
    project: MixModel;
    dataIndex: ProjectDataIndex;
    setSyncToken: ReactStateSetter<string>;
}

const SceneTools: React.FC<SceneToolsProps> = ({
    project,
    dataIndex,
    setSyncToken
}) => {
    const {id} = project;
    const projectId = id!;

    const [activeTab, setActiveTab] = useState('sceneBuilder');

    const toggle = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const [scene, setScene] = useState<ProjectSceneScriptAndHtmlModel | null>(
        null
    );
    const fetchProjectSceneScriptAndHtml = useFetchProjectSceneScriptAndHtml(
        projectId,
        dataIndex
    );
    const {
        data: initialProjectSceneScriptAndHtml,
        isError: sceneIsError,
        isLoading: sceneIsLoading
    } = useQuery(
        ['projectscenescriptandhtml', projectId, dataIndex],
        fetchProjectSceneScriptAndHtml
    );

    useEffect(() => {
        if (initialProjectSceneScriptAndHtml) {
            setScene(initialProjectSceneScriptAndHtml);
            setSyncToken(initialProjectSceneScriptAndHtml.syncToken);
        }
    }, [initialProjectSceneScriptAndHtml, setScene]);

    const updateSceneScriptAndHtml = useUpdateSceneScriptAndHtml(
        projectId,
        dataIndex
    );
    const mutation = useMutation(updateSceneScriptAndHtml);
    const [backendError, setBackendError] = useState<string | null>(null);

    const applySceneUpdate = () => {
        setBackendError(null);

        mutation.mutate(scene!, {
            onSuccess: idResult => {
                toast.success('Project scene updated!');
                if (idResult) {
                    setSyncToken(idResult.id);
                }
            },
            onError: (error: unknown) => {
                setBackendError(unknownErrorToString(error));
            }
        });
    };

    const getTabClassName = (tab: string) => {
        return activeTab == tab ? 'active cursor-pointer' : 'cursor-pointer';
    };

    if (sceneIsLoading) {
        return (
            <div className="spinner-full">
                <Spinner color="primary" />
            </div>
        );
    }

    if (sceneIsError) {
        return <>Error loading project scene</>;
    }

    return (
        <>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={getTabClassName('sceneBuilder')}
                        onClick={() => {
                            toggle('sceneBuilder');
                        }}
                    >
                        Scene Builder
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={getTabClassName('sceneScript')}
                        onClick={() => {
                            toggle('sceneScript');
                        }}
                    >
                        Script
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={getTabClassName('sceneHtml')}
                        onClick={() => {
                            toggle('sceneHtml');
                        }}
                    >
                        HTML
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="sceneBuilder">
                    <SceneBuilder project={project} dataIndex={dataIndex} />
                </TabPane>
                <TabPane tabId="sceneScript">
                    <CodeEditor
                        value={scene?.sceneScript ?? ''}
                        onChange={code =>
                            setScene(preState => ({
                                ...preState!,
                                sceneScript: code
                            }))
                        }
                        typeDefinitionsUrl={TypeDefinitionsUrls.Scene}
                    />
                    <div className="d-flex justify-content-end mt-3">
                        <Button
                            onClick={() => {
                                applySceneUpdate();
                            }}
                            color="primary"
                        >
                            Apply
                        </Button>
                    </div>
                </TabPane>
                <TabPane tabId="sceneHtml">
                    <CodeEditor
                        value={scene?.sceneHtml ?? ''}
                        onChange={code =>
                            setScene(preState => ({
                                ...preState!,
                                sceneHtml: code
                            }))
                        }
                        language="html"
                    />
                    <div className="d-flex justify-content-end mt-3">
                        <Button
                            onClick={() => {
                                applySceneUpdate();
                            }}
                            color="primary"
                        >
                            Apply
                        </Button>
                    </div>
                </TabPane>
            </TabContent>
            {backendError && <Alert color="danger mt-3">{backendError}</Alert>}
        </>
    );
};

export default SceneTools;
