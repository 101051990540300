import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {useLexicalNodeSelection} from '@lexical/react/useLexicalNodeSelection';
import {
    $getNodeByKey,
    $getSelection,
    $isNodeSelection,
    $isRangeSelection,
    $setSelection,
    BaseSelection,
    CLICK_COMMAND,
    COMMAND_PRIORITY_LOW,
    DRAGSTART_COMMAND,
    KEY_BACKSPACE_COMMAND,
    KEY_DELETE_COMMAND,
    KEY_ENTER_COMMAND,
    KEY_ESCAPE_COMMAND,
    LexicalCommand,
    LexicalEditor,
    NodeKey,
    SELECTION_CHANGE_COMMAND,
    createCommand
} from 'lexical';
import {mergeRegister} from '@lexical/utils';

import React, {useCallback, useRef, useState} from 'react';
import MediaResizer from './MediaResizer';
import {$isYouTubeNode} from './YouTubeNode';
import {LazyImage} from './ImageComponent';
import {FallbackType} from './Fallback';

export const DefaultYouTubeVideoWidth: number = 560;
export const DefaultYouTubeVideoHeight: number = 315;

export const RIGHT_CLICK_YOUTUBE_VIDEO_COMMAND: LexicalCommand<MouseEvent> =
    createCommand('RIGHT_CLICK_YOUTUBE_VIDEO_COMMAND');

export const KEYBOARD_SELECT_YOUTUBE_COMMAND: LexicalCommand<HTMLElement> =
    createCommand('KEYBOARD_SELECT_YOUTUBE_COMMAND');

type YouTubeComponentProps = Readonly<{
    videoID: string;
    width: number;
    height: number;
    nodeKey: NodeKey;
    maxWidth?: number;
    resizable: boolean;
}>;

export default function VideoComponent({
    videoID,
    width,
    height,
    nodeKey,
    maxWidth = 800,
    resizable
}: YouTubeComponentProps) {
    const mediaRef = useRef<null | HTMLImageElement>(null);
    const [isSelected, setSelected, clearSelection] =
        useLexicalNodeSelection(nodeKey);
    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [editor] = useLexicalComposerContext();
    const [selection, setSelection] = useState<BaseSelection | null>(null);
    const activeEditorRef = useRef<LexicalEditor | null>(null);

    const $onDelete = useCallback(
        (payload: KeyboardEvent) => {
            if (isSelected && $isNodeSelection($getSelection())) {
                const event: KeyboardEvent = payload;
                event.preventDefault();
                const node = $getNodeByKey(nodeKey);
                if ($isYouTubeNode(node)) {
                    node.remove();
                    return true;
                }
            }
            return false;
        },
        [isSelected, nodeKey]
    );

    const $onEnter = useCallback(() => {
        const latestSelection = $getSelection();
        if (isSelected && $isNodeSelection(latestSelection)) {
            //create new paragraph after node
            const nodes = latestSelection.getNodes();
            if (nodes.length == 1) {
                const node = nodes[0];
                node.selectEnd();
                return true;
            }
            return false;
        }
        return false;
    }, [isSelected]);

    const $onEscape = useCallback(() => {
        const latestSelection = $getSelection();
        if (
            isSelected &&
            $isNodeSelection(latestSelection) &&
            latestSelection.getNodes().length === 1
        ) {
            // deselect node
            $setSelection(null);
            return true;
        }
        return false;
    }, [isSelected]);

    const onClick = useCallback(
        (payload: MouseEvent) => {
            const event = payload;

            if (isResizing) {
                return true;
            }
            if (event.target === mediaRef.current) {
                if (event.shiftKey) {
                    setSelected(!isSelected);
                } else {
                    clearSelection();
                    setSelected(true);
                }
                return true;
            }

            return false;
        },
        [isResizing, isSelected, setSelected, clearSelection]
    );

    const onKeyboardSelect = useCallback(
        (payload: HTMLElement) => {
            const videoToSelect = payload;

            if (videoToSelect === mediaRef.current) {
                setSelected(true);
                return true;
            }

            return false;
        },
        [setSelected]
    );

    const onRightClick = useCallback(
        (event: MouseEvent): void => {
            editor.getEditorState().read(() => {
                const latestSelection = $getSelection();
                const domElement = event.target as HTMLElement;
                if (
                    domElement.tagName === 'VIDEO' &&
                    $isRangeSelection(latestSelection) &&
                    latestSelection.getNodes().length === 1
                ) {
                    editor.dispatchCommand(
                        RIGHT_CLICK_YOUTUBE_VIDEO_COMMAND,
                        event
                    );
                }
            });
        },
        [editor]
    );

    React.useEffect(() => {
        let isMounted = true;
        const rootElement = editor.getRootElement();
        const unregister = mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                if (isMounted) {
                    setSelection(editorState.read(() => $getSelection()));
                }
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_, activeEditor) => {
                    activeEditorRef.current = activeEditor;
                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand<MouseEvent>(
                CLICK_COMMAND,
                onClick,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand<MouseEvent>(
                RIGHT_CLICK_YOUTUBE_VIDEO_COMMAND,
                onClick,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                DRAGSTART_COMMAND,
                event => {
                    if (event.target === mediaRef.current) {
                        // Stops firefox drag and drop image behaviour which is different from other browsers
                        event.preventDefault();
                        return true;
                    }
                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_DELETE_COMMAND,
                $onDelete,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_BACKSPACE_COMMAND,
                $onDelete,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_ENTER_COMMAND,
                $onEnter,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_ESCAPE_COMMAND,
                $onEscape,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEYBOARD_SELECT_YOUTUBE_COMMAND,
                onKeyboardSelect,
                COMMAND_PRIORITY_LOW
            )
        );

        rootElement?.addEventListener('click', onClick);
        rootElement?.addEventListener('pointerdown', onClick);
        rootElement?.addEventListener('contextmenu', onRightClick);

        return () => {
            isMounted = false;
            unregister();
            rootElement?.removeEventListener('contextmenu', onRightClick);
            rootElement?.removeEventListener('click', onClick);
            rootElement?.removeEventListener('pointerdown', onClick);
        };
    }, [
        clearSelection,
        editor,
        isResizing,
        isSelected,
        nodeKey,
        $onDelete,
        $onEnter,
        $onEscape,
        onClick,
        onRightClick,
        setSelected,
        onKeyboardSelect
    ]);

    const onResizeEnd = (
        nextWidth: 'inherit' | number,
        nextHeight: 'inherit' | number
    ) => {
        // Delay hiding the resize bars for click case
        setTimeout(() => {
            setIsResizing(false);
        }, 200);

        editor.update(() => {
            const node = $getNodeByKey(nodeKey);
            if ($isYouTubeNode(node)) {
                const width =
                    nextWidth == 'inherit'
                        ? DefaultYouTubeVideoWidth
                        : nextWidth;
                const height =
                    nextHeight == 'inherit'
                        ? DefaultYouTubeVideoHeight
                        : nextHeight;
                node.setWidthAndHeight(width, height);
            }
        });
    };

    const onResizeStart = () => {
        setIsResizing(true);
    };

    const draggable = isSelected && $isNodeSelection(selection) && !isResizing;
    const isFocused = isSelected || isResizing;

    return (
        <span className="media-resizer-container">
            <div draggable={draggable}>
                {/* using an image with a youtube thumbnail allows us to resize the iframe in the editor */}
                <LazyImage
                    className={
                        isFocused
                            ? `focused ${
                                  $isNodeSelection(selection) ? 'draggable' : ''
                              }`
                            : null
                    }
                    src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                    imageRef={mediaRef}
                    width={width}
                    height={height}
                    maxWidth={maxWidth}
                    altText="YouTube video"
                    fallbackType={FallbackType.youtube}
                />
            </div>
            {resizable && $isNodeSelection(selection) && isFocused && (
                <MediaResizer
                    editor={editor}
                    mediaRef={mediaRef}
                    maxWidth={maxWidth}
                    onResizeStart={onResizeStart}
                    onResizeEnd={onResizeEnd}
                />
            )}
        </span>
    );
}
