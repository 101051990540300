﻿import ReactGA from 'react-ga';
import isLocalHost from '../lib/isLocalHost.ts';
import {useLocation} from 'react-router-dom';
import {useEffect} from 'react';

export const initializeAnalytics = (trackingCode: string) => {
    if (isLocalHost) {
        return;
    }

    ReactGA.initialize(trackingCode);
};

export const logPageView = (path: string) => {
    if (isLocalHost) {
        return;
    }

    ReactGA.set({page: path});
    ReactGA.pageview(path);
};

export const logEvent = (category: string, action: string, label?: string) => {
    if (isLocalHost) {
        return;
    }

    ReactGA.event({
        category: category,
        action: action,
        label: label
    });
};

export const useAnalytics = () => {
    const location = useLocation();

    useEffect(() => {
        logPageView(location.pathname);
    }, [location]);
};
