export default function tryParseInt(
    str: string | null | undefined,
    defaultValue: number
): number {
    if (str === null || str === undefined) {
        return defaultValue;
    }

    const parsed = parseInt(str);
    return isNaN(parsed) ? defaultValue : parsed;
}
