import {faImage, faSpinner, faVideo} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export enum FallbackType {
    image,
    video,
    youtube
}

interface FallbackProps {
    width: number;
    height: number;
    type: FallbackType;
    isLoaded: boolean;
}

const Fallback = ({width, height, type, isLoaded}: FallbackProps) => {
    const icon = (() => {
        switch (type) {
            case FallbackType.image:
                return faImage;
            case FallbackType.video:
            case FallbackType.youtube:
                return faVideo;
            default:
                return faSpinner;
        }
    })();
    return (
        <div
            className={
                isLoaded
                    ? 'd-none'
                    : 'd-flex justify-content-center align-items-center'
            }
            style={{width, height, backgroundColor: '#dfdfdf'}}
        >
            <FontAwesomeIcon fontSize={48} icon={icon} />
        </div>
    );
};

export default Fallback;
