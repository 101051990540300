import {PostReactionModel} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetPostReactionsQuery = {
    postId: string;
};

const useGetPostReactions = (request: GetPostReactionsQuery) =>
    useGet<PostReactionModel[]>(`/api/posts/get-post-reactions?${toQuery(request)}`, {errorMessage: 'GET api/posts/get-post-reactions failed'
});

export default useGetPostReactions;
