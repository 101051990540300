import {useMutation} from 'react-query';
import {v4 as uuidv4} from 'uuid';
import useUploadFiles from '../../../api/uploads/useUploadFiles.ts';

interface UseOnPasteImageOptions {
    containerName: string;
    folderPath: string | string[];
}

export const useOnPasteImage = ({
    containerName,
    folderPath
}: UseOnPasteImageOptions) => {
    const uploadFile = useUploadFiles(containerName, folderPath);
    const uploadFileMutation = useMutation(uploadFile);

    const onPasteImage = (
        dataURL: string,
        onUploadedCallback: (imageURL: string) => void
    ) => {
        const base64Data = dataURL.split(',')[1];

        const uint8Array = Uint8Array.from(atob(base64Data), c =>
            c.charCodeAt(0)
        );

        const blob = new Blob([uint8Array], {type: 'image/png'});

        const imageFileName = `PastedImage_${uuidv4()}.png`;
        const imageFile = new File([blob], imageFileName, {type: 'image/png'});

        const formData = new FormData();
        formData.append('file', imageFile);

        uploadFileMutation.mutate(formData, {
            onSuccess: response => {
                if (response && response.length > 0) {
                    const imageURL = response[0].uri;
                    onUploadedCallback(imageURL);
                } else {
                    // Fallback to original dataURL
                    onUploadedCallback(dataURL);
                }
            },
            onError: error => {
                console.error('Error uploading image:', error);
                // Fallback to original dataURL
                onUploadedCallback(dataURL);
            }
        });
    };

    return onPasteImage;
};
