import React from 'react';
import LabeledFormField from './LabeledFormField';
import {EnumDictionary, NumericValueOf} from '../../lib/enums.ts';

interface FlagEnumCheckboxProps<TEnum> {
    value: TEnum;
    valueChanged: (value: TEnum) => void;
    id: string;
    enumType: EnumDictionary<TEnum>;
}

function FlagEnumCheckbox<TEnum>({
    valueChanged,
    id,
    value,
    enumType
}: FlagEnumCheckboxProps<TEnum>) {
    const handleChange = (
        args: React.ChangeEvent<HTMLInputElement>,
        enumValue: NumericValueOf<TEnum>
    ) => {
        const newValue = args.target.checked
            ? (value as number) | (enumValue as number)
            : (value as number) & ~(enumValue as number);
        valueChanged(newValue as TEnum);
    };

    return (
        <>
            {Object.entries(enumType)
                .filter(
                    ([, enumValue]) =>
                        typeof enumValue === 'number' && enumValue !== 0
                )
                .map(([enumKey, enumValue]) => (
                    <LabeledFormField
                        key={`${id}_${enumKey}`}
                        htmlFor={`${id}_${enumKey}`}
                        label={enumKey}
                        check
                    >
                        <input
                            id={`${id}_${enumKey}`}
                            type="checkbox"
                            className="form-check-input"
                            checked={
                                ((value as number) & (enumValue as number)) !==
                                0
                            }
                            onChange={ev =>
                                handleChange(
                                    ev,
                                    enumValue as NumericValueOf<TEnum>
                                )
                            }
                        />
                    </LabeledFormField>
                ))}
        </>
    );
}

export default FlagEnumCheckbox;
