import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {
    $getAdjacentNode,
    $getNearestNodeFromDOMNode,
    $getRoot,
    $getSelection,
    $isDecoratorNode,
    $isElementNode,
    $isNodeSelection,
    $isRangeSelection,
    COMMAND_PRIORITY_CRITICAL,
    KEY_ARROW_DOWN_COMMAND,
    KEY_ARROW_UP_COMMAND,
    RangeSelection
} from 'lexical';
import {useCallback, useEffect} from 'react';
import {KEYBOARD_SELECT_IMAGE_COMMAND} from '../nodes/ImageComponent';
import {$isImageNode, ImageNode} from '../nodes/ImageNode';
import {$isYouTubeNode, YouTubeNode} from '../nodes/YouTubeNode';
import {$isVideoNode, VideoNode} from '../nodes/VideoNode';
import {KEYBOARD_SELECT_YOUTUBE_COMMAND} from '../nodes/YouTubeComponent';
import {KEYBOARD_SELECT_VIDEO_COMMAND} from '../nodes/VideoComponent';

// This plugin is necessary to handle up and dow arrow selection of decorator nodes
// e.g. image, video, youtube
// and to handle moving the selection from these nodes to paragraphs etc. correctly
const KeyboardNavigationPlugin = () => {
    const [editor] = useLexicalComposerContext();

    function $isTargetWithinDecorator(target: HTMLElement): boolean {
        const node = $getNearestNodeFromDOMNode(target);
        return $isDecoratorNode(node);
    }

    function $isSelectionAtEndOfRoot(selection: RangeSelection) {
        const focus = selection.focus;
        return (
            focus.key === 'root' &&
            focus.offset === $getRoot().getChildrenSize()
        );
    }

    const $selectImage = useCallback(
        (possibleNode: ImageNode, event: KeyboardEvent) => {
            // console.log('possible node select next');
            possibleNode.selectEnd();
            const imageContainerToSelect = editor.getElementByKey(
                possibleNode.getKey()
            );
            const imageToSelect =
                imageContainerToSelect?.getElementsByTagName('img')[0];
            if (imageToSelect != null) {
                editor.dispatchCommand(
                    KEYBOARD_SELECT_IMAGE_COMMAND,
                    imageToSelect
                );
                event.preventDefault();
                return true;
            }
            return false;
        },
        [editor]
    );

    const $selectYouTube = useCallback(
        (possibleNode: YouTubeNode, event: KeyboardEvent) => {
            // console.log('possible node select next');
            possibleNode.selectEnd();
            // youtube nodes display image tag placeholders so need to look for an image
            const imageContainerToSelect = editor.getElementByKey(
                possibleNode.getKey()
            );
            const imageToSelect =
                imageContainerToSelect?.getElementsByTagName('img')[0];
            if (imageToSelect != null) {
                editor.dispatchCommand(
                    KEYBOARD_SELECT_YOUTUBE_COMMAND,
                    imageToSelect
                );
                event.preventDefault();
                return true;
            }
            return false;
        },
        [editor]
    );

    const $selectVideo = useCallback(
        (possibleNode: VideoNode, event: KeyboardEvent) => {
            possibleNode.selectEnd();
            const videoContainerToSelect = editor.getElementByKey(
                possibleNode.getKey()
            );
            const videoToSelect =
                videoContainerToSelect?.getElementsByTagName('video')[0];
            if (videoToSelect != null) {
                editor.dispatchCommand(
                    KEYBOARD_SELECT_VIDEO_COMMAND,
                    videoToSelect
                );
                event.preventDefault();
                return true;
            }
            return false;
        },
        [editor]
    );

    useEffect(() => {
        const teardownUpKeyCommand = editor.registerCommand<KeyboardEvent>(
            KEY_ARROW_UP_COMMAND,
            event => {
                const selection = $getSelection();
                if (
                    $isNodeSelection(selection) &&
                    !$isTargetWithinDecorator(event.target as HTMLElement)
                ) {
                    // If selection is on a node, let's try and move selection
                    // back to being a range selection.
                    const nodes = selection.getNodes();
                    if (nodes.length > 0) {
                        const firstSelectedNode = nodes[0];
                        let possibleNode;
                        if (
                            ($isDecoratorNode(firstSelectedNode) &&
                                !firstSelectedNode.isIsolated() &&
                                !firstSelectedNode.isInline()) ||
                            $isElementNode(firstSelectedNode)
                        ) {
                            possibleNode =
                                firstSelectedNode?.getPreviousSibling();
                        } else if (!$isElementNode(firstSelectedNode)) {
                            possibleNode = firstSelectedNode
                                .getTopLevelElement()
                                ?.getPreviousSibling();
                        }

                        if (possibleNode == null) {
                            return false;
                        }

                        if ($isImageNode(possibleNode)) {
                            return $selectImage(possibleNode, event);
                        }
                        if ($isYouTubeNode(possibleNode)) {
                            return $selectYouTube(possibleNode, event);
                        }
                        if ($isVideoNode(possibleNode)) {
                            return $selectVideo(possibleNode, event);
                        }

                        // the default implementation skips a paragraph when moving from a decorator node so handle this here
                        possibleNode.selectEnd();
                        event.preventDefault();
                        return true;
                    }
                } else if ($isRangeSelection(selection)) {
                    let possibleNode = $getAdjacentNode(selection.focus, true);
                    if (possibleNode == null) {
                        const nodes = selection.getNodes();
                        const firstSelectedNode = nodes[0];
                        possibleNode =
                            firstSelectedNode
                                .getTopLevelElement()
                                ?.getPreviousSibling() ?? null;
                    }

                    if (
                        $isDecoratorNode(possibleNode) &&
                        !possibleNode.isIsolated() &&
                        !possibleNode.isInline()
                    ) {
                        if ($isImageNode(possibleNode)) {
                            return $selectImage(possibleNode, event);
                        }
                        if ($isYouTubeNode(possibleNode)) {
                            return $selectYouTube(possibleNode, event);
                        }
                        if ($isVideoNode(possibleNode)) {
                            return $selectVideo(possibleNode, event);
                        }
                    }
                }
                return false;
            },
            COMMAND_PRIORITY_CRITICAL
        );
        const teardownDownKeyCommand = editor.registerCommand<KeyboardEvent>(
            KEY_ARROW_DOWN_COMMAND,
            event => {
                const selection = $getSelection();
                if ($isNodeSelection(selection)) {
                    const nodes = selection.getNodes();
                    if (nodes.length > 0) {
                        const lastSelectedNode = nodes[nodes.length - 1];
                        let possibleNode;
                        if (
                            ($isDecoratorNode(lastSelectedNode) &&
                                !lastSelectedNode.isIsolated() &&
                                !lastSelectedNode.isInline()) ||
                            $isElementNode(lastSelectedNode)
                        ) {
                            possibleNode = lastSelectedNode?.getNextSibling();
                        } else if (!$isElementNode(lastSelectedNode)) {
                            possibleNode = lastSelectedNode
                                .getTopLevelElement()
                                ?.getNextSibling();
                        }

                        if (possibleNode == null) {
                            return false;
                        }

                        if ($isImageNode(possibleNode)) {
                            return $selectImage(possibleNode, event);
                        }
                        if ($isYouTubeNode(possibleNode)) {
                            return $selectYouTube(possibleNode, event);
                        }
                        if ($isVideoNode(possibleNode)) {
                            return $selectVideo(possibleNode, event);
                        }

                        // the default implementation skips a paragraph when moving from a decorator node so handle this here
                        possibleNode.selectStart();
                        event.preventDefault();

                        return true;
                    }
                    return false;
                } else if ($isRangeSelection(selection)) {
                    if ($isSelectionAtEndOfRoot(selection)) {
                        event.preventDefault();
                        return true;
                    }

                    let possibleNode = $getAdjacentNode(selection.focus, false);
                    if (possibleNode == null) {
                        const nodes = selection.getNodes();
                        const lastSelectedNode = nodes[nodes.length - 1];
                        possibleNode =
                            lastSelectedNode
                                .getTopLevelElement()
                                ?.getNextSibling() ?? null;
                    }

                    if (
                        $isDecoratorNode(possibleNode) &&
                        !possibleNode.isIsolated() &&
                        !possibleNode.isInline()
                    ) {
                        if ($isImageNode(possibleNode)) {
                            return $selectImage(possibleNode, event);
                        }
                        if ($isYouTubeNode(possibleNode)) {
                            return $selectYouTube(possibleNode, event);
                        }
                        if ($isVideoNode(possibleNode)) {
                            return $selectVideo(possibleNode, event);
                        }
                    }
                }
                return false;
            },
            COMMAND_PRIORITY_CRITICAL
        );
        return () => {
            teardownUpKeyCommand();
            teardownDownKeyCommand();
        };
    }, [$selectImage, $selectVideo, $selectYouTube, editor]);

    return null;
};

export default KeyboardNavigationPlugin;
