import React, {useEffect, useState} from 'react';
import getTimezoneOffset from '../../lib/getTimezoneOffset.ts';
import formatDateTime from '../../lib/formatDateTime.ts';

interface DateTimeLocalDisplayProps {
    dateTimeUtc?: Date | string | null;
    format?: string;
}

const DateTimeLocalDisplay: React.FC<DateTimeLocalDisplayProps> = ({
    dateTimeUtc,
    format
}) => {
    const [dateTimeString, setDateTimeString] = useState<string>('');

    useEffect(() => {
        if (dateTimeUtc) {
            try {
                const dateTime =
                    typeof dateTimeUtc === 'string'
                        ? new Date(dateTimeUtc)
                        : dateTimeUtc;
                const offsetInMinutes = getTimezoneOffset();
                const localTime = new Date(
                    dateTime.getTime() - offsetInMinutes * 60000
                );
                setDateTimeString(formatDateTime(localTime, format));
            } catch (exception) {
                setDateTimeString(
                    `${formatDateTime(new Date(dateTimeUtc), format)} (UTC)`
                );
            }
        }
    }, [dateTimeUtc, format]);

    return <>{dateTimeString}</>;
};

export default DateTimeLocalDisplay;
