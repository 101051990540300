const encoder = new TextEncoder();

export default function isLargeObject(
    value: unknown,
    sizeLimit: number
): boolean {
    let size = 0;

    function calculateSize(val: unknown): boolean {
        if (typeof val === 'string') {
            size += encoder.encode(val).length;
        } else if (typeof val === 'number' || typeof val === 'boolean') {
            size += encoder.encode(String(val)).length;
        } else if (val === null) {
            size += encoder.encode('null').length;
        } else if (Array.isArray(val)) {
            size += 2; // Account for the opening and closing brackets
            for (const item of val) {
                if (calculateSize(item)) {
                    return true;
                }
                size += 1; // Add 1 for the comma or closing bracket
            }
        } else if (typeof val === 'object') {
            size += 2; // Account for the opening and closing braces
            for (const [key, nestedVal] of Object.entries(val)) {
                size += encoder.encode(key).length + 3; // Add 3 for the quotes and colon
                if (calculateSize(nestedVal)) {
                    return true;
                }
                size += 1; // Add 1 for the comma or closing brace
            }
        }

        return size > sizeLimit;
    }

    return calculateSize(value);
}
