import {Emoji, Emojis, EmojisByEmojiCode} from './types.ts';
import emojis from './emojis.ts';

function getEmojisByEmojiCode(emojis: Emojis): Record<string, Emoji> {
    const emojisByEmojiCode: Record<string, Emoji> = {};

    for (const group of emojis.groups) {
        for (const subGroup of group.subGroups) {
            for (const emoji of subGroup.emojis) {
                if (!emojiIsAllowed(emoji)) {
                    continue;
                }

                if (!emojisByEmojiCode[emoji.emojiCode]) {
                    emojisByEmojiCode[emoji.emojiCode] = emoji;

                    for (const variant of emoji.variants) {
                        if (!emojisByEmojiCode[variant.emojiCode]) {
                            emojisByEmojiCode[variant.emojiCode] = variant;
                        }
                    }
                }
            }
        }
    }

    return emojisByEmojiCode;
}

export function emojiIsAllowed(emoji: Emoji): boolean {
    if (emoji.name == null) {
        return true;
    }

    return emoji.name.toLowerCase() !== 'middle finger';
}

const emojisByEmojiCode: EmojisByEmojiCode = getEmojisByEmojiCode(emojis);

export default emojisByEmojiCode;
