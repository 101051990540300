import React from 'react';

interface LabeledFormFieldProps {
    children: React.ReactNode;
    label: string;
    check?: boolean;
    htmlFor?: string;
    formGroupClass?: string;
    labelClass?: string;
}

const LabeledFormField: React.FC<LabeledFormFieldProps> = ({
    children,
    label,
    check,
    htmlFor,
    formGroupClass,
    labelClass
}) => {
    let formGroupClasses = 'form-group';
    if (check) {
        formGroupClasses += ' form-check';
    }
    if (formGroupClass) {
        formGroupClasses += ' ' + formGroupClass;
    }

    return (
        <div className={formGroupClasses}>
            {check && children}
            <label htmlFor={htmlFor} className={labelClass}>
                {label}
            </label>
            {!check && children}
        </div>
    );
};

export default LabeledFormField;
