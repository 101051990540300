import React, {useState} from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FileDataModel, Selection} from '../../api/types.ts';
import AssetSelectAndUploadModal, {
    AssetSelectAndUploadModalOptions
} from '../assets/AssetSelectAndUploadModal.tsx';
import {FileFormat} from '../../lib/FileFormats.ts';

interface AddModelButtonProps {
    onModelSelected: (url: string) => void;
}

const AddModelButton: React.FC<AddModelButtonProps> = ({onModelSelected}) => {
    const [
        assetSelectAndUploadModalOptions,
        setAssetSelectAndUploadModalOptions
    ] = useState<AssetSelectAndUploadModalOptions | null>(null);

    const closeAssetSelectAndUploadModal = () => {
        setAssetSelectAndUploadModalOptions(null);
    };

    const openAssetSelectAndUploadModal = (
        onFileSelected: (file: FileDataModel | null) => void
    ) => {
        const onFileSelectionChanged = (files: FileDataModel[] | undefined) => {
            if (!files) {
                return;
            }

            if (files.length > 0) {
                onFileSelected(files[0]);
            } else {
                onFileSelected(null);
            }

            closeAssetSelectAndUploadModal();
        };

        setAssetSelectAndUploadModalOptions({
            selection: Selection.Single,
            explanation: 'Please select a model file',
            formats: FileFormat.Model,
            fileSelectionChanged: onFileSelectionChanged
        });
    };

    const addModel = () => {
        openAssetSelectAndUploadModal((file: FileDataModel | null) => {
            if (file) {
                onModelSelected(file.uri);
            }
        });
    };

    return (
        <>
            {assetSelectAndUploadModalOptions && (
                <AssetSelectAndUploadModal
                    options={assetSelectAndUploadModalOptions}
                    onClose={closeAssetSelectAndUploadModal}
                />
            )}
            <Button
                color="primary"
                onClick={() => addModel()}
                className="me-2"
                title="Add Model"
                size="sm"
            >
                <FontAwesomeIcon icon={faPlus} /> Model
            </Button>
        </>
    );
};

export default AddModelButton;
