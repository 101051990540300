import {MergeData} from '../../types.ts';
import {ContentSectionModel, MixModel, ViewMode} from '../../api/types.ts';
import useSessionState from '../../lib/useSessionState.ts';
import {Button} from 'reactstrap';
import React, {useState} from 'react';
import SelectProjectModal from '../projects/SelectProjectModal.tsx';
import MixCard from '../projects/MixCard.tsx';

interface ProjectsSectionProps {
    elementId: string;
    section: ContentSectionModel;
    readOnly: boolean;
    mergeData: MergeData;
}

const ProjectsSection: React.FC<ProjectsSectionProps> = ({
    elementId,
    section,
    readOnly,
    mergeData
}) => {
    const [viewMode] = useSessionState(`ViewMode_${elementId}`, ViewMode.View);

    const [showSelectProjectModel, setShowSelectProjectModel] = useState(false);

    const mixIds = (JSON.parse(section.content ?? '[]') ?? []) as string[];

    const addProject = () => setShowSelectProjectModel(true);

    const onProjectSelected = (mix: MixModel) => {
        mergeData(elementId, {
            content: JSON.stringify([...mixIds, mix.id])
        });
        setShowSelectProjectModel(false);
    };

    const onRemove = (mixId: string) =>
        mergeData(elementId, {
            content: JSON.stringify([...mixIds.filter(id => id != mixId)])
        });

    if (!readOnly && viewMode == ViewMode.Edit) {
        return (
            <>
                {showSelectProjectModel && (
                    <SelectProjectModal
                        onClose={() => setShowSelectProjectModel(false)}
                        onProjectSelected={onProjectSelected}
                    />
                )}
                <div className="mixi-card-deck">
                    {mixIds.map(mixId => (
                        <MixCard
                            key={mixId}
                            mixId={mixId}
                            onRemove={() => onRemove(mixId)}
                        />
                    ))}
                </div>
                <div className="d-flex justify-content-end">
                    <Button color="primary" onClick={() => addProject()}>
                        Add project
                    </Button>
                </div>
            </>
        );
    }

    return (
        <div className="mixi-card-deck">
            {mixIds.map(mixId => (
                <MixCard key={mixId} mixId={mixId} />
            ))}
        </div>
    );
};

export default ProjectsSection;
