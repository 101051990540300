import React from 'react';
import DateTimeLocalDisplay from '../shared/DateTimeLocalDisplay.tsx';

interface PostSeparatorProps {
    createdOnUtc: string;
}

const PostSeparator: React.FC<PostSeparatorProps> = ({createdOnUtc}) => {
    return (
        <div className="date-separator-holder">
            <div className="date-separator">
                <h6>
                    <DateTimeLocalDisplay
                        dateTimeUtc={createdOnUtc}
                        format="EEE, d MMMM"
                    />
                </h6>
            </div>
        </div>
    );
};

export default PostSeparator;
