import React, {createContext, useState, useContext, ReactNode} from 'react';
import {ProjectTabType} from '../../api/types.ts';

export type TranslationProjectInfo = {
    mixId: string;
    dataIndex: number;
    projectTabType: ProjectTabType;
};

interface TranslationContextType {
    showTranslationPanel: boolean;
    setShowTranslationPanel: (show: boolean) => void;
    projectInfo: TranslationProjectInfo | null;
    setProjectInfo: (info: TranslationProjectInfo | null) => void;
}

const TranslationContext = createContext<TranslationContextType | undefined>(
    undefined
);

export const TranslationProvider: React.FC<{children: ReactNode}> = ({
    children
}) => {
    const [showTranslationPanel, setShowTranslationPanel] = useState(false);
    const [projectInfo, setProjectInfo] =
        useState<TranslationProjectInfo | null>(null);

    return (
        <TranslationContext.Provider
            value={{
                showTranslationPanel,
                setShowTranslationPanel,
                projectInfo,
                setProjectInfo
            }}
        >
            {children}
        </TranslationContext.Provider>
    );
};

export const useTranslation = () => {
    const context = useContext(TranslationContext);
    if (context === undefined) {
        throw new Error(
            'useTranslation must be used within a TranslationProvider'
        );
    }
    return context;
};
