import {$createEmojiNode, EmojiNode, EmojiPayload} from '../nodes/EmojiNode';
import type {LexicalEditor, LexicalNode} from 'lexical';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {mergeRegister} from '@lexical/utils';
import {$isElementNode, $isLineBreakNode, $isTextNode, TextNode} from 'lexical';
import {useEffect} from 'react';
import {emojis, indexingRegex} from '../utils/emojis';

type ChangeHandler = (url: string | null, prevUrl: string | null) => void;

type EmojiMatcherResult = {
    index: number;
    length: number;
    title: string;
    src: string;
    emojiCode: string;
    altText: string;
};

export type EmojiMatcher = (text: string) => EmojiMatcherResult | null;

export function createEmojiMatcherWithRegExp(regExp: RegExp) {
    return (text: string) => {
        const match = regExp.exec(text);
        if (match === null) {
            return null;
        }

        const emoji = emojis[match[0]];

        if (!emoji) {
            return null;
        }

        return {
            index: match.index,
            length: match[0].length,
            title: emoji.title,
            src: emoji.src,
            emojiCode: match[0],
            altText: emoji.alt
        };
    };
}

function findFirstMatch(
    text: string,
    matchers: Array<EmojiMatcher>
): EmojiMatcherResult | null {
    for (let i = 0; i < matchers.length; i++) {
        const match = matchers[i](text);

        if (match) {
            return match;
        }
    }

    return null;
}

const PUNCTUATION_OR_SPACE = /[.,;\s]/;

function isSeparator(char: string): boolean {
    return PUNCTUATION_OR_SPACE.test(char);
}

function endsWithSeparator(textContent: string): boolean {
    return isSeparator(textContent[textContent.length - 1]);
}

function startsWithSeparator(textContent: string): boolean {
    return isSeparator(textContent[0]);
}

function isPreviousNodeValid(node: LexicalNode): boolean {
    let previousNode = node.getPreviousSibling();
    if ($isElementNode(previousNode)) {
        previousNode = previousNode.getLastDescendant();
    }
    return (
        previousNode === null ||
        $isLineBreakNode(previousNode) ||
        ($isTextNode(previousNode) &&
            endsWithSeparator(previousNode.getTextContent()))
    );
}

function isNextNodeValid(node: LexicalNode): boolean {
    let nextNode = node.getNextSibling();
    if ($isElementNode(nextNode)) {
        nextNode = nextNode.getFirstDescendant();
    }
    return (
        nextNode === null ||
        $isLineBreakNode(nextNode) ||
        ($isTextNode(nextNode) &&
            startsWithSeparator(nextNode.getTextContent()))
    );
}

function isContentAroundIsValid(
    matchStart: number,
    matchEnd: number,
    text: string,
    nodes: TextNode[]
): boolean {
    const contentBeforeIsValid =
        matchStart > 0
            ? isSeparator(text[matchStart - 1])
            : isPreviousNodeValid(nodes[0]);
    if (!contentBeforeIsValid) {
        return false;
    }

    const contentAfterIsValid =
        matchEnd < text.length
            ? isSeparator(text[matchEnd])
            : isNextNodeValid(nodes[nodes.length - 1]);
    return contentAfterIsValid;
}

function extractMatchingNodes(
    nodes: TextNode[],
    startIndex: number,
    endIndex: number
): [
    matchingOffset: number,
    unmodifiedBeforeNodes: TextNode[],
    matchingNodes: TextNode[],
    unmodifiedAfterNodes: TextNode[]
] {
    const unmodifiedBeforeNodes: TextNode[] = [];
    const matchingNodes: TextNode[] = [];
    const unmodifiedAfterNodes: TextNode[] = [];
    let matchingOffset = 0;

    let currentOffset = 0;
    const currentNodes = [...nodes];

    while (currentNodes.length > 0) {
        const currentNode = currentNodes[0];
        const currentNodeText = currentNode.getTextContent();
        const currentNodeLength = currentNodeText.length;
        const currentNodeStart = currentOffset;
        const currentNodeEnd = currentOffset + currentNodeLength;

        if (currentNodeEnd <= startIndex) {
            unmodifiedBeforeNodes.push(currentNode);
            matchingOffset += currentNodeLength;
        } else if (currentNodeStart >= endIndex) {
            unmodifiedAfterNodes.push(currentNode);
        } else {
            matchingNodes.push(currentNode);
        }
        currentOffset += currentNodeLength;
        currentNodes.shift();
    }
    return [
        matchingOffset,
        unmodifiedBeforeNodes,
        matchingNodes,
        unmodifiedAfterNodes
    ];
}

function $createEmojiNode_(
    nodes: TextNode[],
    startIndex: number,
    endIndex: number,
    match: EmojiMatcherResult
): TextNode | undefined {
    const payload: EmojiPayload = {
        altText: match.altText,
        src: match.src,
        code: match.emojiCode,
        title: match.title,
        height: 22.4,
        width: 22.4
    };
    const emojiNode = $createEmojiNode(payload);
    if (nodes.length === 1) {
        let remainingTextNode = nodes[0];
        let emojiTextNode;
        if (startIndex === 0) {
            [emojiTextNode, remainingTextNode] =
                remainingTextNode.splitText(endIndex);
        } else {
            [, emojiTextNode, remainingTextNode] = remainingTextNode.splitText(
                startIndex,
                endIndex
            );
        }
        emojiTextNode.replace(emojiNode);
        return remainingTextNode;
    } else if (nodes.length > 1) {
        const firstTextNode = nodes[0];
        let offset = firstTextNode.getTextContent().length;
        let firstEmojiTextNode;
        if (startIndex === 0) {
            firstEmojiTextNode = firstTextNode;
        } else {
            [, firstEmojiTextNode] = firstTextNode.splitText(startIndex);
        }
        const emojiNodes = [];
        let remainingTextNode;
        for (let i = 1; i < nodes.length; i++) {
            const currentNode = nodes[i];
            const currentNodeText = currentNode.getTextContent();
            const currentNodeLength = currentNodeText.length;
            const currentNodeStart = offset;
            const currentNodeEnd = offset + currentNodeLength;
            if (currentNodeStart < endIndex) {
                if (currentNodeEnd <= endIndex) {
                    emojiNodes.push(currentNode);
                } else {
                    const [emojiTextNode, endNode] = currentNode.splitText(
                        endIndex - currentNodeStart
                    );
                    emojiNodes.push(emojiTextNode);
                    remainingTextNode = endNode;
                }
            }
            offset += currentNodeLength;
        }

        firstEmojiTextNode.replace(emojiNode);
        return remainingTextNode;
    }
    return undefined;
}

function $handleEmojiCreation(
    nodes: TextNode[],
    matchers: Array<EmojiMatcher>,
    onChange: ChangeHandler
): void {
    let currentNodes = [...nodes];
    const initialText = currentNodes
        .map(node => node.getTextContent())
        .join('');
    let text = initialText;
    let match;
    let invalidMatchEnd = 0;

    while ((match = findFirstMatch(text, matchers)) && match !== null) {
        const matchStart = match.index;
        const matchLength = match.length;
        const matchEnd = matchStart + matchLength;
        const isValid = isContentAroundIsValid(
            invalidMatchEnd + matchStart,
            invalidMatchEnd + matchEnd,
            initialText,
            currentNodes
        );

        if (isValid) {
            const [matchingOffset, , matchingNodes, unmodifiedAfterNodes] =
                extractMatchingNodes(
                    currentNodes,
                    invalidMatchEnd + matchStart,
                    invalidMatchEnd + matchEnd
                );

            const actualMatchStart =
                invalidMatchEnd + matchStart - matchingOffset;
            const actualMatchEnd = invalidMatchEnd + matchEnd - matchingOffset;
            const remainingTextNode = $createEmojiNode_(
                matchingNodes,
                actualMatchStart,
                actualMatchEnd,
                match
            );
            currentNodes = remainingTextNode
                ? [remainingTextNode, ...unmodifiedAfterNodes]
                : unmodifiedAfterNodes;
            onChange(match.src, null);
            invalidMatchEnd = 0;
        } else {
            invalidMatchEnd += matchEnd;
        }

        text = text.substring(matchEnd);
    }
}

function getTextNodesToMatch(textNode: TextNode): TextNode[] {
    // check if next siblings are simple text nodes till a node contains a space separator
    const textNodesToMatch = [textNode];
    let nextSibling = textNode.getNextSibling();
    while (
        nextSibling !== null &&
        $isTextNode(nextSibling) &&
        nextSibling.isSimpleText()
    ) {
        textNodesToMatch.push(nextSibling);
        if (/[\s]/.test(nextSibling.getTextContent())) {
            break;
        }
        nextSibling = nextSibling.getNextSibling();
    }
    return textNodesToMatch;
}

function useAutoEmoji(editor: LexicalEditor, onChange?: ChangeHandler): void {
    useEffect(() => {
        if (!editor.hasNodes([EmojiNode])) {
            throw new Error(
                'AutoEmojisPlugin: ImageNode not registered on editor'
            );
        }

        const onChangeWrapped = (
            url: string | null,
            prevUrl: string | null
        ) => {
            if (onChange) {
                onChange(url, prevUrl);
            }
        };

        const matchers: EmojiMatcher[] = [
            createEmojiMatcherWithRegExp(indexingRegex)
        ];

        return mergeRegister(
            editor.registerNodeTransform(TextNode, (textNode: TextNode) => {
                if (textNode.isSimpleText()) {
                    const textNodesToMatch = getTextNodesToMatch(textNode);
                    $handleEmojiCreation(
                        textNodesToMatch,
                        matchers,
                        onChangeWrapped
                    );
                }
            })
        );
    }, [editor, onChange]);
}

function AutoEmojiPlugin({
    onChange
}: {
    onChange?: ChangeHandler;
}): JSX.Element | null {
    const [editor] = useLexicalComposerContext();

    useAutoEmoji(editor, onChange);

    return null;
}

export default AutoEmojiPlugin;
