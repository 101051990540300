﻿import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './scss/site.scss';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ClientConfigProvider} from './hooks/useClientConfig.tsx';

(function () {
    if (window.location.host === 'mixiply.azurewebsites.net') {
        window.location.href = 'https://mixiply.com';
        return;
    }

    if (window.location.protocol !== 'https:') {
        window.location.href = 'https://' + window.location.host;
    }

    if (window.location.hostname === 'www.mixiply.com') {
        window.location.href = window.location.href.replace(
            'www.mixiply.com',
            'mixiply.com'
        );
    }
})();

const queryClient = new QueryClient();

const consoleWarn = console.warn;
const suppressedWarnings = [
    'ArtificialNode__DO_NOT_USE', // For lexical
    'Failed prop type: Invalid prop `nodeRef`'
];

console.warn = (message, ...args) => {
    if (!suppressedWarnings.some(entry => typeof message.includes(entry))) {
        consoleWarn(message, ...args);
    }
};

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ClientConfigProvider>
                <App />
            </ClientConfigProvider>
        </QueryClientProvider>
    </React.StrictMode>
);
