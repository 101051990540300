import {DirectoryModel} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetFilesQuery = {
    containerName?: string | null;
    folderPath?: string | null;
};

const useGetFiles = (request: GetFilesQuery) =>
    useGet<DirectoryModel>(`/api/uploads/get-files?${toQuery(request)}`, {
        errorMessage: 'GET api/uploads/get-files failed'
    });

export default useGetFiles;
