import {ActiveTabState, NavTabsState, TabState} from './types.ts';
import {ReactStateSetter} from '../../../types.ts';

export function getNavTabsState(
    tabs: TabState[],
    activeTabState: ActiveTabState
): NavTabsState {
    const activeTab =
        tabs.find(
            tab =>
                tab.available &&
                tab.name.toLowerCase() ===
                    activeTabState.activeTabName.toLowerCase()
        ) ?? tabs.find(tab => tab.available);

    return {
        tabs,
        activeTabName: activeTab?.name ?? '',
        activeTab: activeTab ?? null
    };
}

export function isActive(navTabsState: NavTabsState, name: string) {
    return (
        navTabsState.activeTab != null &&
        navTabsState.activeTab.available &&
        navTabsState.activeTab.name?.toLowerCase() == name.toLowerCase()
    );
}

export function activateTab(
    navTabsState: NavTabsState,
    setActiveTabState: ReactStateSetter<ActiveTabState>,
    name: string
) {
    const newActiveTab = navTabsState.tabs.find(
        tab => tab.name.toLowerCase() == name.toLowerCase()
    );

    if (newActiveTab) {
        setActiveTabState({activeTabName: newActiveTab.name});

        navTabsState.onTabActivated &&
            navTabsState.onTabActivated(newActiveTab);
    }
}
