import {FileDataModel} from '../../api/types.ts';
import FileFormats, {FileFormat} from '../../lib/FileFormats.ts';
import React, {useCallback} from 'react';
import {Alert, Button, Spinner} from 'reactstrap';
import {ReactStateSetter} from '../../types.ts';
import useUploadFiles from '../../api/uploads/useUploadFiles.ts';
import {useMutation} from 'react-query';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import {useDropzone} from 'react-dropzone';
import getFilesFromUploadResults from './getFilesFromUploadResults.ts';
import OverlayWithSpinner from '../shared/OverlayWithSpinner.tsx';

interface UploadFromComputerProps {
    folderPath?: string | null;
    specificContainerName?: string | null;
    selectedFile: File | null;
    setSelectedFile: ReactStateSetter<File | null>;
    onClose: () => void;
    onFilesUploaded: (files: FileDataModel[]) => void;
    backendError: string | null;
    setBackendError: ReactStateSetter<string | null>;
    selectedFormat: FileFormat | null;
    setShowUploadFromComputer: ReactStateSetter<boolean>;
    getEditedImage: () => File | null;
}

const UploadFromComputer: React.FC<UploadFromComputerProps> = ({
    folderPath,
    specificContainerName,
    selectedFile,
    setSelectedFile,
    onClose,
    onFilesUploaded,
    backendError,
    setBackendError,
    selectedFormat,
    setShowUploadFromComputer,
    getEditedImage
}) => {
    const uploadFiles = useUploadFiles(specificContainerName, folderPath);

    const mutation = useMutation(uploadFiles, {
        onSuccess: files => {
            files &&
                onFilesUploaded(
                    getFilesFromUploadResults(files, selectedFormat)
                );
            onClose();
        },
        onError: (error: unknown) => {
            setBackendError(unknownErrorToString(error));
        }
    });

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles && acceptedFiles[0]) {
            setSelectedFile(acceptedFiles[0]);
        }
    }, []);

    const extensions =
        selectedFormat === null
            ? ['*']
            : FileFormats.getExtensions(selectedFormat, true);

    const validFileTypes =
        selectedFormat !== null
            ? FileFormats.getExtensions(selectedFormat, true)
                  .map(f => `.${f}`)
                  .join(', ')
            : '*.*';

    const acceptedFileTypes =
        extensions[0] === '*' || selectedFormat === null
            ? undefined
            : FileFormats.getAcceptFileTypes(selectedFormat, true);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: acceptedFileTypes
    });

    const dropzoneClass = selectedFile ? 'dropzone file-selected' : 'dropzone';

    const renderDropzoneContent = () => {
        if (selectedFile) {
            return <p>File selected: {selectedFile.name}</p>;
        } else if (isDragActive) {
            return <p>Drop the file here ...</p>;
        } else {
            return <p>Drag 'n' drop the file here, or click to select file</p>;
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const editedImage = getEditedImage();
        if (selectedFile) {
            if (editedImage) {
                const formData = new FormData();
                formData.append('file', editedImage);
                mutation.mutate(formData);
            } else {
                const formData = new FormData();
                formData.append('file', selectedFile);
                mutation.mutate(formData);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div {...getRootProps()} className={dropzoneClass}>
                <input {...getInputProps()} />
                <div className="text-center">{renderDropzoneContent()}</div>
            </div>

            <small className="d-block mt-2 text-center">{validFileTypes}</small>
            {backendError && <Alert color="danger mt-3">{backendError}</Alert>}

            <Button
                color="primary"
                type="submit"
                disabled={mutation.isLoading || !selectedFile}
                block
                className="mt-3"
            >
                {mutation.isLoading ? <Spinner size="sm" /> : 'Upload'}
            </Button>
            {mutation.isLoading && <OverlayWithSpinner />}

            <Button
                color="secondary"
                block
                className="mt-3"
                onClick={() => setShowUploadFromComputer(false)}
            >
                Cancel
            </Button>
        </form>
    );
};

export default UploadFromComputer;
