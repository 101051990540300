import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import useGetMixes, {GetMixesQuery} from '../../api/mixes/useGetMixes.ts';
import SpinnerIfLoading from '../shared/SpinnerIfLoading.tsx';
import Search from '../shared/Search.tsx';
import {ReactStateSetter} from '../../types.ts';
import MixTypeButtons from '../shared/MixTypeButtons.tsx';
import {MatchOperator, MixModel, MixType} from '../../api/types.ts';
import SearchProjectTags from '../shared/SearchProjectTags.tsx';
import Pager from '../shared/Pager.tsx';
import GalleryCard from './GalleryCard.tsx';
import {defaultGalleryState, useGalleryState} from './GalleryStateContext.tsx';

interface GalleryOptions {
    transientState?: boolean;
    disableSwitchMixType: boolean;
}

interface GalleryProps {
    myProjects: boolean;
    tags?: string[];
    onProjectClick?: (mix: MixModel) => void;
    options?: GalleryOptions;
}

const Gallery: React.FC<GalleryProps> = ({
    myProjects,
    tags,
    onProjectClick,
    options
}) => {
    const {loggedInUserId} = useAuthStateManager();

    const transientState = options?.transientState === true;
    const disableSwitchMixType = options?.disableSwitchMixType === true;

    const [persistentGalleryState, setPersistentGalleryState] =
        useGalleryState(myProjects);
    const [transientGalleryState, setTransientGalleryState] =
        useState(defaultGalleryState);

    const [galleryState, setGalleryState] = transientState
        ? [transientGalleryState, setTransientGalleryState]
        : [persistentGalleryState, setPersistentGalleryState];

    const getMixesQuery: GetMixesQuery = {
        collection: '',
        search: galleryState.search,
        mixType: galleryState.mixType,
        myMixes: myProjects,
        tags: galleryState.selectedTags,
        matchTags: galleryState.matchTags,
        pagination: galleryState.pagination
    };

    const getMixes = useGetMixes(getMixesQuery);
    const {data: projectsResult, isLoading} = useQuery(
        [
            'mixes',
            loggedInUserId,
            galleryState.search,
            galleryState.mixType,
            myProjects,
            galleryState.selectedTags,
            galleryState.matchTags,
            galleryState.pagination,
            loggedInUserId
        ],
        getMixes
    );

    useEffect(() => {
        if (tags) {
            setGalleryState(prevState => ({
                ...prevState,
                selectedTags: tags
            }));
        }
    }, [tags]);

    const onPageSelected = async (page: number) => {
        setGalleryState(prevState => ({
            ...prevState,
            pagination: {...prevState.pagination, page}
        }));
    };

    const onTagsSelected = (selectedTags: string[]) => {
        setGalleryState(prevState => {
            return {
                ...prevState,
                selectedTags,
                pagination: {...prevState.pagination, page: 1}
            };
        });
    };

    const onMixTypeSelected = (filter: MixType) => {
        setGalleryState(prevState => ({
            ...prevState,
            mixType: filter,
            pagination: {...prevState.pagination, page: 1}
        }));
    };

    const onMatchOperatorSelected = async (matchType: MatchOperator) => {
        setGalleryState(prevState => ({
            ...prevState,
            matchTags: matchType,
            pagination: {...prevState.pagination, page: 1}
        }));
    };

    const indicateIsTemplate = () =>
        galleryState && galleryState.mixType !== MixType.Projects;

    const setGallerySearch: ReactStateSetter<string> = (
        value: ((prevState: string) => string) | string
    ) => {
        setGalleryState(prevState => ({
            ...prevState,
            search:
                typeof value === 'function' ? value(prevState.search) : value
        }));
    };

    return (
        <SpinnerIfLoading loading={isLoading}>
            <div className="mb-4 d-flex flex-sm-row flex-column">
                <Search
                    searchText={galleryState.search}
                    setSearchText={setGallerySearch}
                    cssClass="w-80 col-lg-10 mw-100 me-0 me-sm-2 flex-fill"
                />
                {!disableSwitchMixType && (
                    <MixTypeButtons
                        filter={galleryState.mixType}
                        onFilterSelected={onMixTypeSelected}
                    />
                )}
            </div>

            <div className="mb-4">
                <SearchProjectTags
                    selectedTags={galleryState.selectedTags}
                    setSelectedTags={onTagsSelected}
                    matchTags={galleryState.matchTags}
                    onMatchOperatorSelected={onMatchOperatorSelected}
                />
            </div>

            {projectsResult?.hasResults ? (
                <>
                    <div className="mixi-card-deck">
                        {projectsResult?.mixes.items.map(project => (
                            <div
                                key={project.id}
                                className={`mixi-card-holder ${
                                    indicateIsTemplate() && project.isTemplate
                                        ? ' template-card-holder'
                                        : ''
                                }`}
                            >
                                <GalleryCard
                                    project={project}
                                    indicateIsTemplate={indicateIsTemplate()}
                                    onClick={onProjectClick}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="page-holder mt-4">
                        <Pager
                            pagedResult={projectsResult.mixes}
                            onPageSelected={onPageSelected}
                        />
                    </div>
                </>
            ) : (
                <h3 className="py-4 text-center op-08">No projects found</h3>
            )}
        </SpinnerIfLoading>
    );
};

export default Gallery;
