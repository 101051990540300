import React, {ReactNode} from 'react';

interface MxTableProps {
    mxThead: ReactNode;
    mxTfoot?: ReactNode;
    cssClass?: string;
    children?: ReactNode;
}

const MxTable: React.FC<MxTableProps> = ({
    mxThead,
    mxTfoot,
    cssClass,
    children
}) => {
    return (
        <table
            className={`table table-striped table-hover table-sm ${cssClass}`}
        >
            <thead className="table-dark">{mxThead}</thead>
            <tbody>{children}</tbody>
            {mxTfoot && <tfoot>{mxTfoot}</tfoot>}
        </table>
    );
};

export default MxTable;
