import React from 'react';

type YouTubeVideoPlayerProps = {
    videoId: string;
};

const YouTubeVideoPlayer: React.FC<YouTubeVideoPlayerProps> = ({videoId}) => {
    const width = 640;
    const height = 390;

    const extractVideoId = (url: string): string => {
        const regExp =
            /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return match && match[2].length === 11 ? match[2] : url;
    };

    const embeddableVideoId = extractVideoId(videoId);

    return (
        <div className="row align-items-center">
            <div className="col-8">
                <iframe
                    width={width}
                    height={height}
                    src={`https://www.youtube-nocookie.com/embed/${embeddableVideoId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube video"
                ></iframe>
            </div>
        </div>
    );
};

export default YouTubeVideoPlayer;
