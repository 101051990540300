import React from 'react';
import classNames from 'classnames';
import {
    GroupMemberInfoFieldType,
    IGroupMemberField,
    IGroupMemberInfoAnswer
} from '../../api/types.ts';
import HtmlComponent from './HtmlComponent.tsx';
import {ReactStateSetter} from '../../types.ts';

interface GroupMemberInfoFieldProps
    extends React.HTMLAttributes<HTMLDivElement> {
    field: IGroupMemberField;
    answer: IGroupMemberInfoAnswer;
    setAnswer: ReactStateSetter<IGroupMemberInfoAnswer>;
    readonly?: boolean;
    index: number;
}

const GroupMemberInfoField: React.FC<GroupMemberInfoFieldProps> = ({
    field,
    setAnswer,
    answer,
    readonly,
    index,
    className,
    ...rest
}) => {
    const handleChange = (
        event: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const {value} = event.target;
        switch (field.fieldType) {
            case GroupMemberInfoFieldType.Text:
            case GroupMemberInfoFieldType.MultiLineText:
            case GroupMemberInfoFieldType.Email:
                setAnswer({...answer, stringAnswer: value});
                break;
            case GroupMemberInfoFieldType.Date:
                {
                    const parsedDate = Date.parse(value);
                    setAnswer({
                        ...answer,
                        dateAnswer: isNaN(parsedDate)
                            ? null
                            : new Date(parsedDate)
                    });
                }
                break;
            case GroupMemberInfoFieldType.Time:
                setAnswer({...answer, timeAnswer: value});
                break;
            case GroupMemberInfoFieldType.Number:
                {
                    const parsedNumber = parseFloat(value);
                    setAnswer({
                        ...answer,
                        numberAnswer: isNaN(parsedNumber) ? null : parsedNumber
                    });
                }
                break;
            case GroupMemberInfoFieldType.CheckBox:
                setAnswer({
                    ...answer,
                    booleanAnswer: (event.target as HTMLInputElement).checked
                });
                break;
            case GroupMemberInfoFieldType.SelectList:
            case GroupMemberInfoFieldType.RadioButtonList:
                setAnswer({...answer, stringAnswer: value});
                break;
            default:
                break;
        }
    };

    return (
        <div
            {...rest}
            className={classNames(index > 0 ? 'mt-3' : '', className)}
        >
            {field.introContent && <HtmlComponent html={field.introContent} />}
            {field.label &&
                field.fieldType !== GroupMemberInfoFieldType.CheckBox && (
                    <label htmlFor={field.id!}>{field.label}</label>
                )}
            {(() => {
                switch (field.fieldType) {
                    case GroupMemberInfoFieldType.Text:
                        return (
                            <input
                                className="form-control"
                                readOnly={readonly}
                                maxLength={field.maxLength ?? undefined}
                                onChange={handleChange}
                                type="text"
                                value={answer.stringAnswer || ''}
                                id={field.id!}
                            />
                        );
                    case GroupMemberInfoFieldType.MultiLineText:
                        return (
                            <textarea
                                className="form-control"
                                readOnly={readonly}
                                maxLength={field.maxLength ?? undefined}
                                onChange={handleChange}
                                rows={3}
                                value={answer.stringAnswer || ''}
                                id={field.id!}
                            />
                        );
                    case GroupMemberInfoFieldType.Date:
                        return (
                            <input
                                className="form-control"
                                readOnly={readonly}
                                onChange={handleChange}
                                type="date"
                                value={
                                    answer.dateAnswer
                                        ? new Date(answer.dateAnswer)
                                              .toISOString()
                                              .slice(0, 10)
                                        : ''
                                }
                                id={field.id!}
                            />
                        );
                    case GroupMemberInfoFieldType.Time:
                        return (
                            <input
                                className="form-control"
                                readOnly={readonly}
                                onChange={handleChange}
                                type="time"
                                value={answer.timeAnswer || ''}
                                id={field.id!}
                            />
                        );
                    case GroupMemberInfoFieldType.Number:
                        return (
                            <input
                                className="form-control"
                                readOnly={readonly}
                                maxLength={field.maxLength ?? undefined}
                                onChange={handleChange}
                                type="number"
                                value={answer.numberAnswer || ''}
                                id={field.id!}
                            />
                        );
                    case GroupMemberInfoFieldType.Email:
                        return (
                            <input
                                className="form-control"
                                readOnly={readonly}
                                maxLength={field.maxLength ?? undefined}
                                onChange={handleChange}
                                type="email"
                                value={answer.stringAnswer || ''}
                                id={field.id!}
                            />
                        );
                    case GroupMemberInfoFieldType.CheckBox:
                        return (
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={handleChange}
                                    checked={answer.booleanAnswer || false}
                                    id={field.id!}
                                    disabled={readonly}
                                />
                                {field.label && (
                                    <label
                                        className="form-check-label"
                                        htmlFor={field.id!}
                                    >
                                        {field.label}
                                    </label>
                                )}
                            </div>
                        );
                    case GroupMemberInfoFieldType.SelectList:
                        return (
                            <select
                                className="form-select"
                                id={field.id!}
                                onChange={handleChange}
                                disabled={readonly}
                            >
                                <option
                                    value=""
                                    selected={answer.stringAnswer === ''}
                                />
                                {field.options.map(option => (
                                    <option
                                        key={option}
                                        value={option}
                                        selected={
                                            answer.stringAnswer === option
                                        }
                                    >
                                        {option}
                                    </option>
                                ))}
                            </select>
                        );
                    case GroupMemberInfoFieldType.RadioButtonList:
                        return field.options.map(option => (
                            <div key={option} className="form-check">
                                <input
                                    type="radio"
                                    disabled={readonly}
                                    className="form-check-input"
                                    value={option}
                                    checked={answer.stringAnswer === option}
                                    onChange={() =>
                                        setAnswer({
                                            ...answer,
                                            stringAnswer: option
                                        })
                                    }
                                    id={`${field.id!}_${option}`}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={`${field.id!}_${option}`}
                                >
                                    {option}
                                </label>
                            </div>
                        ));
                    default:
                        return <div>Unknown field type</div>;
                }
            })()}
        </div>
    );
};

export default GroupMemberInfoField;
