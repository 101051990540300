import {
    CustomFabricObject,
    ElementData,
    EventTypes,
    VideoElement
} from './types.ts';
import {fabric} from 'fabric';
import {ReactStateSetter, UnknownObject} from '../../types.ts';
import {v4 as uuidv4} from 'uuid';

export function isCustomFabricObject(obj: unknown): obj is CustomFabricObject {
    return obj instanceof fabric.Object && 'elementId' in obj;
}

export const findFabricObject = (canvas: fabric.Canvas, elementId: string) => {
    const objects = canvas.getObjects();
    return objects.find(
        object => (object as CustomFabricObject).elementId === elementId
    );
};

// TODO: image filters

export const modifyFabricObject = (
    canvas: fabric.Canvas,
    object: fabric.Object,
    elementId: string,
    elementData: UnknownObject
) => {
    const {type, version, ...properties} = elementData as ElementData;

    console.log(
        `Modifying type: ${type}, version: ${version}, elementId: ${elementId}`
    );

    if (type === 'settings') {
        const {canvasBackgroundColor} = elementData;

        if (typeof canvasBackgroundColor === 'string') {
            canvas.setBackgroundColor(
                canvasBackgroundColor,
                canvas.renderAll.bind(canvas)
            );
        }
    } else {
        object.set(properties);
    }
};

export const restoreFabricObject = (
    canvas: fabric.Canvas,
    elementId: string | undefined,
    elementData: UnknownObject,
    setVideoElements: ReactStateSetter<VideoElement[]>,
    isReadOnly: boolean,
    onRestoreToGroup?: (obj: fabric.Object) => void
) => {
    if (elementId != undefined && findFabricObject(canvas, elementId)) {
        return;
    }

    const {type, version, ...properties} = elementData as ElementData;

    console.log(
        `Creating type: ${type}, version: ${version}, elementId: ${elementId}`
    );

    const onRestoreImpl = (restoredObject: fabric.Object) => {
        restoredObject.selectable = !isReadOnly;

        if (isReadOnly) {
            if (!(restoredObject as CustomFabricObject).nav) {
                restoredObject.evented = false;
            } else {
                restoredObject.evented = true;
                restoredObject.hoverCursor = 'pointer';
            }
        } else {
            restoredObject.evented = true;
        }

        /*
         * The object has been restored - but it may have been added out of order
         * due to it being an image or video callback
         * therefore we need to move it to it's correct position
         * within either the canvas or group
         * */

        if (onRestoreToGroup) {
            onRestoreToGroup(restoredObject);
        } else {
            canvas.add(restoredObject);
            fixObjectIndex(canvas, restoredObject);
        }
    };

    const fixObjectIndex = (
        canvas: fabric.Canvas,
        restoredObject: fabric.Object
    ) => {
        // Check if the object has been restored to the correct index
        // Objects can be loaded in an incorrect order due to being loaded in a callback
        // for loading video and image. This code corrects that after it's loaded.

        const canvasObjects = canvas.getObjects();
        const indexOfRestoredObject = canvasObjects.indexOf(restoredObject);
        const ordinalPosition = elementData.ordinalPosition as
            | number
            | undefined;

        const sortFn = (
            a: {ordinalPosition: number | undefined},
            b: {ordinalPosition: number | undefined}
        ) => {
            const ordinalPositionA = a.ordinalPosition;
            const ordinalPositionB = b.ordinalPosition;

            if (
                ordinalPositionA !== undefined &&
                ordinalPositionB !== undefined
            ) {
                return Number(ordinalPositionA) - Number(ordinalPositionB);
            } else if (ordinalPositionA !== undefined) {
                return -1;
            } else if (ordinalPositionB !== undefined) {
                return 1;
            } else {
                return 0;
            }
        };

        const positionAndIndexArray = canvasObjects
            .map((obj, index) => {
                const ordinalPosition = (obj as CustomFabricObject)
                    .ordinalPosition;
                return {index, ordinalPosition};
            })
            .sort(sortFn);

        /*
          Scenario:
          [B, C, D] -> A is restored -> [B, C, D, A] (incorrect order)
          Move A to before B -> B is the first element with an ordinal position greater than A
        */

        const firstElementWithGreaterOrdinalPosition =
            positionAndIndexArray.find(
                p =>
                    ordinalPosition !== undefined &&
                    p.ordinalPosition !== undefined &&
                    p.ordinalPosition > ordinalPosition
            );

        if (firstElementWithGreaterOrdinalPosition) {
            if (
                indexOfRestoredObject >
                firstElementWithGreaterOrdinalPosition.index
            ) {
                canvas.moveTo(
                    restoredObject,
                    firstElementWithGreaterOrdinalPosition.index
                );
            }
        }
    };

    switch (type) {
        case 'settings':
            {
                const {canvasBackgroundColor} = properties;

                if (typeof canvasBackgroundColor === 'string') {
                    canvas.setBackgroundColor(
                        canvasBackgroundColor,
                        canvas.renderAll.bind(canvas)
                    );
                }
            }
            break;
        case 'image':
            fabric.Image.fromURL(
                properties.src as string,
                img => {
                    const {clipPath, ...imageProperties} = properties;

                    if (clipPath !== undefined) {
                        const clipPathObject = clipPath as UnknownObject;

                        const {
                            type: clipPathObjectType,
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            version: clipPathObjectVersion,
                            ...clipPathObjectProperties
                        } = clipPathObject;

                        switch (clipPathObjectType) {
                            case 'rect':
                                {
                                    imageProperties.clipPath = new fabric.Rect(
                                        clipPathObjectProperties
                                    );
                                }
                                break;
                            case 'circle':
                                {
                                    imageProperties.clipPath =
                                        new fabric.Circle(
                                            clipPathObjectProperties
                                        );
                                }
                                break;
                        }
                    }

                    img.set(imageProperties);
                    (img as unknown as CustomFabricObject).elementId =
                        elementId;
                    onRestoreImpl(img);
                },
                {crossOrigin: 'anonymous'}
            );
            break;

        case 'rect':
            {
                const rect = new fabric.Rect(properties);
                (rect as unknown as CustomFabricObject).elementId = elementId;
                onRestoreImpl(rect);
            }
            break;

        case 'circle':
            {
                const circle = new fabric.Circle(properties);
                (circle as unknown as CustomFabricObject).elementId = elementId;
                onRestoreImpl(circle);
            }
            break;

        case 'i-text':
            {
                const iText = new fabric.IText(
                    properties.text as string,
                    properties
                );
                (iText as unknown as CustomFabricObject).elementId = elementId;
                onRestoreImpl(iText);
            }
            break;

        case 'path':
            {
                const pathString = properties.path as string;
                const path = new fabric.Path(pathString, properties);
                (path as unknown as CustomFabricObject).elementId = elementId;
                onRestoreImpl(path);
            }
            break;

        case 'video':
            // Create a video element
            {
                const videoElement: HTMLVideoElement =
                    document.createElement('video');
                videoElement.src = properties.src as string;
                videoElement.setAttribute('crossOrigin', 'anonymous');
                videoElement.load();
                videoElement.onloadeddata = () => {
                    // Update video element dimensions
                    videoElement.width = videoElement.videoWidth;
                    videoElement.height = videoElement.videoHeight;

                    // Create a Fabric image object from the video element
                    const video = new fabric.Image(videoElement, {
                        ...properties, // spread other properties
                        objectCaching: false,
                        type: 'video'
                    });

                    // Cast to custom Fabric object and add custom properties
                    const customVideo: CustomFabricObject =
                        video as unknown as CustomFabricObject;
                    customVideo.elementId = elementId;
                    customVideo.uri = properties.src as string;
                    customVideo.videoElement = videoElement;

                    videoElement.currentTime = 0;
                    onRestoreImpl(customVideo);

                    // Setup animation frame for rendering the video on the canvas
                    fabric.util.requestAnimFrame(function render() {
                        canvas.renderAll();
                        fabric.util.requestAnimFrame(render);
                    });

                    // Notify CanvasComponent about the new video element
                    setVideoElements(
                        (previousVideoElements: VideoElement[]) => {
                            return [
                                ...previousVideoElements,
                                {id: elementId!, element: videoElement}
                            ];
                        }
                    );

                    // document.dispatchEvent(new CustomEvent('videoElementAdded', { detail: { elementId, videoElement } }));
                    canvas.fire(EventTypes.ObjectModified, {target: video});
                };
            }
            break;

        case 'textbox':
            {
                const {text, ...textboxProperties} = properties;
                const textbox = new fabric.Textbox(
                    text as string,
                    textboxProperties
                );
                (textbox as unknown as CustomFabricObject).elementId =
                    elementId;
                onRestoreImpl(textbox);
            }
            break;

        case 'group':
            {
                const groupObjects: fabric.Object[] = [];

                if (Array.isArray(properties.objects)) {
                    const restorePromises = properties.objects.map(
                        (obj: UnknownObject, index: number) => {
                            return new Promise<void>(resolve => {
                                const groupObjectElementId =
                                    'elementId' in obj &&
                                    typeof obj.elementId == 'string'
                                        ? obj.elementId
                                        : uuidv4();
                                restoreFabricObject(
                                    canvas,
                                    groupObjectElementId,
                                    obj,
                                    setVideoElements,
                                    isReadOnly,
                                    (restoredObj: fabric.Object) => {
                                        groupObjects[index] = restoredObj;
                                        resolve();
                                    }
                                );
                            });
                        }
                    );

                    Promise.all(restorePromises).then(() => {
                        const group = new fabric.Group(
                            groupObjects,
                            properties
                        );
                        (group as unknown as CustomFabricObject).elementId =
                            elementId;
                        onRestoreImpl(group);
                    });
                }
            }
            break;
    }
};
