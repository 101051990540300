import React, {useState} from 'react';
import useRemoveGroupMember, {
    RemoveGroupMemberCommand
} from '../../api/groups/useRemoveGroupMember';
import useUpdateGroupMember, {
    UpdateGroupMemberCommand
} from '../../api/groups/useUpdateGroupMember';
import useUpdateGroupMemberInfo, {
    UpdateGroupMemberInfoCommand
} from '../../api/groups/useUpdateGroupMemberInfo';
import GroupMemberActionIcons from './GroupMemberActionIcons';
import {
    CollectionInfoModel,
    GroupMemberInfoAnswerModel,
    GroupMemberInfoFieldModel,
    GroupMemberModel,
    IGroupMemberInfoAnswer,
    MembershipStatus,
    UserCollectionType
} from '../../api/types.ts';
import {toast} from 'react-toastify';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import EnumSelect from '../shared/EnumSelect.tsx';
import GroupMemberInfoField from '../shared/GroupMemberInfoField.tsx';
import ConfirmButton from '../shared/ConfirmButton.tsx';
import displayName from '../../lib/displayName.ts';
import dateTimeUtcNow from '../../lib/dateTimeUtcNow.ts';
import {useMutation} from 'react-query';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import {isNotNullOrWhiteSpace} from '../../lib/isNullOrWhiteSpace.ts';
import OverlayWithSpinner from '../shared/OverlayWithSpinner.tsx';

interface GroupMemberCardProps {
    collection: CollectionInfoModel;
    member: GroupMemberModel;
    onMemberUpdated: () => void;
    canEdit: boolean;
    fields: GroupMemberInfoFieldModel[];
}

const GroupMemberCard: React.FC<GroupMemberCardProps> = ({
    collection,
    member,
    onMemberUpdated,
    canEdit,
    fields
}) => {
    const [showModal, setShowModal] = useState(false);
    const [localMember, setLocalMember] = useState(member);
    const removeGroupMember = useRemoveGroupMember();
    const updateGroupMember = useUpdateGroupMember();
    const updateGroupMemberInfo = useUpdateGroupMemberInfo();

    const removeGroupMemberMutation = useMutation(removeGroupMember, {
        onSuccess: () => {
            toast.success('Member removed');
            setShowModal(false);
            onMemberUpdated();
        },
        onError: (error: unknown) => {
            toast.error(
                'Failed to remove member ' + unknownErrorToString(error)
            );
        }
    });

    const ignoreRequestMutation = useMutation(updateGroupMember, {
        onSuccess: () => {
            toast.success('Request ignored');
            onMemberUpdated();
        },
        onError: (error: unknown) => {
            toast.error(
                'Failed to ignore request ' + unknownErrorToString(error)
            );
        }
    });

    const acceptRequestMutation = useMutation(updateGroupMember, {
        onSuccess: () => {
            toast.success(
                `Accepted @${localMember.nickname}'s request to join`
            );
            onMemberUpdated();
        },
        onError: (error: unknown) => {
            toast.error(
                'Failed to accept request ' + unknownErrorToString(error)
            );
        }
    });

    const updateGroupMemberMutation = useMutation(updateGroupMember, {
        onSuccess: () => {
            toast.success('Member updated');
            setShowModal(false);
            onMemberUpdated();
        },
        onError: (error: unknown) => {
            toast.error(
                'Failed to update member ' + unknownErrorToString(error)
            );
        }
    });

    const updateGroupMemberInfoMutation = useMutation(updateGroupMemberInfo, {
        onSuccess: () => {
            toast.success('Member updated');
            setShowModal(false);
            onMemberUpdated();
        },
        onError: (error: unknown) => {
            toast.error(
                'Failed to update member info ' + unknownErrorToString(error)
            );
        }
    });

    const handleRemoveMember = async () => {
        const command: RemoveGroupMemberCommand = {
            collectionId: collection.id!,
            userId: localMember.userId
        };

        await removeGroupMemberMutation.mutateAsync(command);
    };

    const handleUpdate = async () => {
        const updateMemberCommand: UpdateGroupMemberCommand = {
            collectionId: collection.id!,
            userId: localMember.userId,
            userCollectionType: localMember.userCollectionType,
            membershipStatus: localMember.membershipStatus
        };
        const updateInfoCommand: UpdateGroupMemberInfoCommand = {
            collectionId: collection.id!,
            userId: localMember.userId,
            answers: localMember.groupMemberInfoAnswers
        };

        await updateGroupMemberMutation.mutateAsync(updateMemberCommand);
        await updateGroupMemberInfoMutation.mutateAsync(updateInfoCommand);
    };

    const handleIgnoreRequest = async () => {
        const command: UpdateGroupMemberCommand = {
            collectionId: collection.id!,
            userId: localMember.userId,
            userCollectionType: localMember.userCollectionType,
            membershipStatus: MembershipStatus.RequestIgnored
        };
        await ignoreRequestMutation.mutateAsync(command);
    };

    const handleRequestAccepted = async () => {
        const command: UpdateGroupMemberCommand = {
            collectionId: collection.id!,
            userId: localMember.userId,
            userCollectionType: localMember.userCollectionType,
            membershipStatus: MembershipStatus.Member
        };

        await acceptRequestMutation.mutateAsync(command);
    };

    const userHasName = () => {
        return isNotNullOrWhiteSpace(localMember.name);
    };

    const handleMemberInfoAnswerChange = (
        answer:
            | ((prevState: IGroupMemberInfoAnswer) => IGroupMemberInfoAnswer)
            | IGroupMemberInfoAnswer
    ) => {
        setLocalMember(prevMember => ({
            ...prevMember,
            groupMemberInfoAnswers: prevMember.groupMemberInfoAnswers.map(a => {
                if (typeof answer === 'function') {
                    const updatedAnswer = answer(
                        a
                    ) as GroupMemberInfoAnswerModel;
                    if (
                        a.groupMemberInfoFieldId ===
                        updatedAnswer.groupMemberInfoFieldId
                    ) {
                        return updatedAnswer as GroupMemberInfoAnswerModel;
                    }
                } else if (
                    a.groupMemberInfoFieldId ===
                    (answer as GroupMemberInfoAnswerModel)
                        .groupMemberInfoFieldId
                ) {
                    return answer as GroupMemberInfoAnswerModel;
                }
                return a;
            })
        }));
    };

    return (
        <>
            {(removeGroupMemberMutation.isLoading ||
                ignoreRequestMutation.isLoading ||
                acceptRequestMutation.isLoading ||
                updateGroupMemberMutation.isLoading ||
                updateGroupMemberInfoMutation.isLoading) && (
                <OverlayWithSpinner />
            )}
            <div
                className="member-card mixi-card hover-lift"
                title={localMember.nickname}
            >
                <div className="card-content d-flex flex-row align-items-center">
                    <div className="member">
                        <img
                            src={localMember.thumbnail}
                            crossOrigin="anonymous"
                            className="member-avatar"
                            alt={localMember.nickname}
                        />
                        <div className="member-info">
                            <h6 className="member-name fw-bold">
                                {userHasName()
                                    ? localMember.name
                                    : localMember.nickname}
                            </h6>
                            <p className="op-08 member-type">
                                {localMember.nickname} |{' '}
                                {
                                    UserCollectionType[
                                        localMember.userCollectionType
                                    ]
                                }
                            </p>
                        </div>
                    </div>
                    {canEdit && (
                        <GroupMemberActionIcons
                            membership={localMember.membershipStatus}
                            isGroupAdmin={canEdit}
                            edit={() => setShowModal(true)}
                            ignore={handleIgnoreRequest}
                            accept={handleRequestAccepted}
                            cancel={handleRemoveMember}
                            cssClass="card-actions ms-auto"
                            showText={false}
                        />
                    )}
                </div>
            </div>
            {showModal && (
                <Modal
                    id="EditGroupMember"
                    toggle={() => setShowModal(false)}
                    isOpen={true}
                >
                    <ModalHeader>Edit member</ModalHeader>
                    <ModalBody>
                        {localMember.thumbnail && (
                            <img
                                src={localMember.thumbnail}
                                crossOrigin="anonymous"
                                alt={localMember.nickname}
                                className="mb-3 w-100"
                            />
                        )}
                        <div className="mb-3">
                            <label htmlFor="Nickname">Nickname</label>
                            <input
                                id="Nickname"
                                className="form-control"
                                value={localMember.nickname}
                                readOnly
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Name">Name</label>
                            <input
                                id="Name"
                                className="form-control"
                                value={localMember.name}
                                readOnly
                            />
                        </div>
                        <div className="mb-3">
                            <label
                                htmlFor="UserCollectionType"
                                className="form-label"
                            >
                                Member type
                            </label>
                            <EnumSelect
                                enumType={UserCollectionType}
                                value={localMember.userCollectionType}
                                onValueChange={userCollectionType =>
                                    setLocalMember(prevMember => ({
                                        ...prevMember,
                                        userCollectionType:
                                            Number(userCollectionType)
                                    }))
                                }
                                id="UserCollectionType"
                            />
                        </div>
                        {fields.map((field, index) => {
                            const answer: GroupMemberInfoAnswerModel =
                                localMember.groupMemberInfoAnswers.find(
                                    a => a.groupMemberInfoFieldId === field.id
                                ) || {
                                    id: null,
                                    userId: localMember.userId,
                                    collectionId: collection.id!,
                                    groupMemberInfoFieldId: field.id!,
                                    createdOnUtc: dateTimeUtcNow(),
                                    updatedOnUtc: dateTimeUtcNow(),
                                    stringAnswer: null,
                                    booleanAnswer: null,
                                    numberAnswer: null,
                                    dateAnswer: null,
                                    timeAnswer: null
                                };

                            return (
                                <GroupMemberInfoField
                                    key={index}
                                    field={field}
                                    answer={answer}
                                    setAnswer={handleMemberInfoAnswerChange}
                                    index={index}
                                    readonly={false}
                                />
                            );
                        })}
                        <ConfirmButton
                            cssClass="btn btn-danger"
                            onClick={handleRemoveMember}
                            message="Are you sure you want to remove this group member?"
                        >
                            Remove {displayName(localMember)} from group.
                        </ConfirmButton>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={handleUpdate}
                        >
                            Update
                        </button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default GroupMemberCard;
