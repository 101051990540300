import React from 'react';
import {FormGroup, Label, Input} from 'reactstrap';

interface GroundEditorProps {
    width: number;
    height: number;
    subdivisions?: number;
    heightMap?: string;
    onWidthChange: (width: number) => void;
    onHeightChange: (height: number) => void;
    onSubdivisionsChange: (subdivisions: number | undefined) => void;
    onHeightMapChange: (heightMap: string | undefined) => void;
}

const GroundEditor: React.FC<GroundEditorProps> = ({
    width,
    height,
    subdivisions,
    heightMap,
    onWidthChange,
    onHeightChange,
    onSubdivisionsChange,
    onHeightMapChange
}) => {
    const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onWidthChange(isNaN(value) ? 0 : value);
    };

    const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onHeightChange(isNaN(value) ? 0 : value);
    };

    const handleSubdivisionsChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const value = parseInt(e.target.value, 10);
        onSubdivisionsChange(isNaN(value) ? undefined : value);
    };

    const handleHeightMapChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onHeightMapChange(e.target.value);
    };

    return (
        <>
            <FormGroup>
                <Label>Width:</Label>
                <Input
                    type="number"
                    step="0.1"
                    value={width}
                    onChange={handleWidthChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Height:</Label>
                <Input
                    type="number"
                    step="0.1"
                    value={height}
                    onChange={handleHeightChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Subdivisions:</Label>
                <Input
                    type="number"
                    step="1"
                    value={subdivisions ?? ''}
                    onChange={handleSubdivisionsChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Height Map:</Label>
                <Input
                    type="text"
                    value={heightMap ?? ''}
                    onChange={handleHeightMapChange}
                />
            </FormGroup>
        </>
    );
};

export default GroundEditor;
