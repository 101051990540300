import {
    DecoratorNode,
    EditorConfig,
    NodeKey,
    type DOMConversionMap,
    type DOMConversionOutput,
    type DOMExportOutput,
    type LexicalNode,
    type SerializedLexicalNode,
    type Spread
} from 'lexical';

import YouTubeComponent, {
    DefaultYouTubeVideoWidth,
    DefaultYouTubeVideoHeight
} from './YouTubeComponent';
import isNullOrWhiteSpace from '../../../lib/isNullOrWhiteSpace.ts';

export interface YouTubePayload {
    videoID: string;
    width?: number;
    height?: number;
    key?: NodeKey;
}

function convertYoutubeElement(
    domNode: HTMLElement
): null | DOMConversionOutput {
    const videoID = domNode.getAttribute('data-lexical-youtube');
    if (videoID && domNode instanceof HTMLIFrameElement) {
        const widthAttributeValue = domNode.getAttribute('width');
        const heightAttributeValue = domNode.getAttribute('height');

        const nodeWidth = isNullOrWhiteSpace(widthAttributeValue)
            ? DefaultYouTubeVideoWidth
            : +widthAttributeValue!;
        const nodeHeight = isNullOrWhiteSpace(heightAttributeValue)
            ? DefaultYouTubeVideoHeight
            : +heightAttributeValue!;
        const node = $createYouTubeNode({
            videoID,
            width: nodeWidth,
            height: nodeHeight
        });
        return {node};
    }
    return null;
}

export type SerializedYouTubeNode = Spread<
    {
        videoID: string;
        width: number;
        height: number;
    },
    SerializedLexicalNode
>;

export class YouTubeNode extends DecoratorNode<JSX.Element> {
    __id: string;
    __width: number;
    __height: number;

    static getType(): string {
        return 'youtube';
    }

    static clone(node: YouTubeNode): YouTubeNode {
        return new YouTubeNode(
            node.__id,
            node.__width,
            node.__height,
            node.__key
        );
    }

    exportJSON(): SerializedYouTubeNode {
        return {
            ...super.exportJSON(),
            type: 'youtube',
            version: 1,
            videoID: this.__id,
            width: this.__width,
            height: this.__height
        };
    }

    static importJSON(serializedNode: SerializedYouTubeNode): YouTubeNode {
        const {videoID, width, height} = serializedNode;

        const node = $createYouTubeNode({videoID, width, height});
        return node;
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement('iframe');
        element.setAttribute('data-lexical-youtube', this.__id);
        element.setAttribute('width', `${this.__width}`);
        element.setAttribute('height', `${this.__height}`);
        element.setAttribute(
            'src',
            `https://www.youtube-nocookie.com/embed/${this.__id}`
        );
        element.setAttribute('frameborder', '0');
        element.setAttribute(
            'allow',
            'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        );
        element.setAttribute('allowfullscreen', 'true');
        element.setAttribute('title', 'YouTube video');
        return {element};
    }

    static importDOM(): DOMConversionMap | null {
        return {
            iframe: (domNode: HTMLElement) => {
                if (!domNode.hasAttribute('data-lexical-youtube')) {
                    return null;
                }
                return {
                    conversion: convertYoutubeElement,
                    priority: 1
                };
            }
        };
    }

    constructor(id: string, width?: number, height?: number, key?: NodeKey) {
        super(key);
        this.__id = id;
        this.__width = width ?? DefaultYouTubeVideoWidth;
        this.__height = height ?? DefaultYouTubeVideoHeight;
    }

    setWidthAndHeight(width: number, height: number): void {
        const writable = this.getWritable();
        writable.__width = width;
        writable.__height = height;
    }

    // View

    createDOM(config: EditorConfig): HTMLElement {
        const span = document.createElement('span');
        const theme = config.theme;
        const className = theme.image;
        if (className !== undefined) {
            span.className = className;
        }
        return span;
    }

    updateDOM(): false {
        return false;
    }

    getId(): string {
        return this.__id;
    }

    getWidth(): number {
        return this.__width;
    }

    getHeight(): number {
        return this.__height;
    }

    decorate(): JSX.Element {
        return (
            <YouTubeComponent
                videoID={this.__id}
                width={this.__width}
                height={this.__height}
                nodeKey={this.getKey()}
                resizable={true}
            />
        );
    }

    isInline(): false {
        return false;
    }
}

export function $createYouTubeNode({
    videoID,
    width,
    height,
    key
}: YouTubePayload): YouTubeNode {
    return new YouTubeNode(videoID, width, height, key);
}

export function $isYouTubeNode(
    node: LexicalNode | null | undefined
): node is YouTubeNode {
    return node instanceof YouTubeNode;
}
