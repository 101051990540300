import React from 'react';
import NavTabLink from './NavTabLink';
import {ActiveTabState, NavTabsState} from './types.ts';
import {ReactStateSetter} from '../../../types.ts';

interface NavTabsLinksProps {
    navTabsState: NavTabsState;
    setActiveTabState: ReactStateSetter<ActiveTabState>;
}

const NavTabsLinks: React.FC<NavTabsLinksProps> = ({
    navTabsState,
    setActiveTabState
}) => {
    return (
        <ul className="nav nav-tabs">
            {navTabsState.tabs
                .filter(tab => tab.available)
                .map(tab => (
                    <NavTabLink
                        navTabsState={navTabsState}
                        setActiveTabState={setActiveTabState}
                        tab={tab}
                        key={tab.name}
                    />
                ))}
        </ul>
    );
};

export default NavTabsLinks;
