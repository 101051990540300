import React from 'react';
import {CollectionInfoModel} from '../../api/types.ts';
import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import useGetCollectionMixes, {
    GetCollectionMixesQuery
} from '../../api/collections/useGetCollectionMixes.ts';
import {useQuery} from 'react-query';
import GalleryCard from '../projects/GalleryCard.tsx';

interface GroupProjectsTabProps {
    collection: CollectionInfoModel;
}

const GroupProjectsTab: React.FC<GroupProjectsTabProps> = ({collection}) => {
    const {loggedInUserId} = useAuthStateManager();

    const getCollectionMixesQuery: GetCollectionMixesQuery = {
        collectionId: collection.id,
        containerName: null
    };

    const getCollectionMixes = useGetCollectionMixes(getCollectionMixesQuery);
    const {data: collectionProjectsResult} = useQuery(
        ['getCollectionMixes', loggedInUserId],
        getCollectionMixes
    );

    return (
        collectionProjectsResult && (
            <div className="container-fluid custom-container mt-5 mb-5">
                <div className="row">
                    <div className="col-12">
                        {collectionProjectsResult.shared.length > 0 && (
                            <>
                                <h3 className="fw-bold mb-3">
                                    Shared projects
                                </h3>
                                <div className="mixi-card-deck mb-4">
                                    {collectionProjectsResult.shared.map(
                                        project => (
                                            <div
                                                className="mixi-card-holder"
                                                key={project.id}
                                            >
                                                <GalleryCard
                                                    project={project}
                                                />
                                            </div>
                                        )
                                    )}
                                </div>
                            </>
                        )}
                        {collectionProjectsResult.private.length > 0 && (
                            <>
                                <h3 className="fw-bold mb-3">
                                    Private projects
                                </h3>
                                <div className="mixi-card-deck">
                                    {collectionProjectsResult.private.map(
                                        project => (
                                            <div
                                                className="mixi-card-holder"
                                                key={project.id}
                                            >
                                                <GalleryCard
                                                    project={project}
                                                />
                                            </div>
                                        )
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    );
};

export default GroupProjectsTab;
