import React from 'react';
import {FaBold, FaItalic} from 'react-icons/fa';
import {Button, ButtonGroup, ButtonToolbar} from 'reactstrap';
import CanvasController from './CanvasController.ts';
import {FontOptions} from './types.ts';

interface TextToolbarProps {
    controlsDisabled: boolean;
    controller: CanvasController;
}

const TextToolbar: React.FC<TextToolbarProps> = ({
    controlsDisabled,
    controller
}) => {
    const fontSizes = [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 28, 32, 36, 48, 64, 72
    ];

    const fontSettings =
        controller.activeObjectFontSettings() ?? controller.fontSettings;

    return (
        <>
            <ButtonToolbar>
                <ButtonGroup size="sm">
                    <Button
                        title="Bold"
                        className="toolbar-buttons"
                        onClick={() =>
                            controller.setFontOptions(FontOptions.Bold)
                        }
                        disabled={controlsDisabled}
                    >
                        <FaBold size={15} />
                    </Button>
                    <Button
                        title="Italic"
                        className="toolbar-buttons"
                        onClick={() =>
                            controller.setFontOptions(FontOptions.Italic)
                        }
                        disabled={controlsDisabled}
                    >
                        <FaItalic size={15} />
                    </Button>
                    <Button
                        style={{
                            padding: '5px',
                            background: '#f0f0f0',
                            border: 'none'
                        }}
                    >
                        <select
                            value={fontSettings.font}
                            style={{
                                background: '#f0f0f0'
                            }}
                            onChange={e => {
                                controller.onFontChange(e.target.value);
                                controller.onFontChange(e.target.value);
                            }}
                        >
                            <option value="Arial">Arial</option>
                            <option value="Times New Roman">
                                Times New Roman
                            </option>
                            <option value="Courier New">Courier New</option>
                            <option value="Quicksand">Quicksand</option>
                        </select>
                    </Button>
                    <Button
                        style={{
                            padding: '5px',
                            background: '#f0f0f0',
                            border: 'none'
                        }}
                    >
                        <select
                            value={fontSettings.fontSize ?? ''}
                            style={{background: '#f0f0f0'}}
                            onChange={e => {
                                const fontSize = e.target.value
                                    ? parseInt(e.target.value)
                                    : undefined;
                                controller.setFontSize(fontSize);
                            }}
                            disabled={controlsDisabled}
                        >
                            <option value="">Select size</option>
                            {fontSizes.map(size => (
                                <option key={size} value={size}>
                                    {size}
                                </option>
                            ))}
                        </select>
                    </Button>
                </ButtonGroup>
            </ButtonToolbar>
        </>
    );
};

export default TextToolbar;
