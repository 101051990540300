import React, {useState, useEffect, useRef} from 'react';
import {Collapse} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faComment} from '@fortawesome/free-regular-svg-icons';
import {useMutation, useQuery} from 'react-query';
import {useAuthStateManager} from '../../hooks/useAuthStateManager';
import {
    CollectionInfoModel,
    DisplayPostModel,
    PostType,
    UserCollectionType
} from '../../api/types.ts';
import {toast} from 'react-toastify';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import {Emoji} from '../../emojis/types.ts';
import displayName from '../../lib/displayName.ts';
import DateTimeLocalDisplay from '../shared/DateTimeLocalDisplay.tsx';
import atify from '../../lib/atify.ts';
import GroupRoleIcon from '../groups/GroupRoleIcon.tsx';
import PostToolbar from './PostToolbar.tsx';
import HtmlComponent from '../shared/HtmlComponent.tsx';
import MxReactionSummary from './MxReactionSummary.tsx';
import GroupPostReply from './GroupPostReply.tsx';
import MxAuthorPost from './MxAuthorPost.tsx';
import EmojiPickerModal from '../../emojis/EmojiPickerModal.tsx';
import useGetPostReplies from '../../api/posts/useGetPostReplies.ts';
import RoleNames from '../../lib/RoleNames.ts';
import useDeletePost from '../../api/posts/useDeletePost.ts';
import useReactToPost from '../../api/posts/useReactToPost.ts';

interface GroupPostProps {
    post: DisplayPostModel;
    setPost: (post: DisplayPostModel) => void;
    collection: CollectionInfoModel;
    onDelete: () => void;
    onPostAdded: (postId: string, element: HTMLDivElement) => void;
}

const GroupPost: React.FC<GroupPostProps> = ({
    post,
    setPost,
    collection,
    onDelete,
    onPostAdded
}) => {
    const groupPostRef = useRef<HTMLDivElement>(null);
    const {loggedInUserId, authData} = useAuthStateManager();
    const [canDelete, setCanDelete] = useState(false);
    const [showReplies, setShowReplies] = useState(false);
    const [showReactions, setShowReactions] = useState(false);

    useEffect(() => {
        if (groupPostRef.current) {
            onPostAdded(post.id, groupPostRef.current);
        }
    }, [groupPostRef]);

    const getPostRepliesCount = useGetPostReplies({
        parentPostId: post.id,
        countOnly: true
    });
    const {data: fetchedCountRepliesResult} = useQuery(
        ['post-replies-count', post.id],
        getPostRepliesCount
    );

    const countRepliesResult = fetchedCountRepliesResult ?? {
        countReplies: post.countReplies,
        replies: null
    };

    useEffect(() => {
        setCanDelete(
            loggedInUserId === post.userId ||
                collection.loggedInUserUserCollectionType ===
                    UserCollectionType.Admin ||
                authData.roles.includes(RoleNames.Admin)
        );
    }, [
        loggedInUserId,
        post.userId,
        collection.loggedInUserUserCollectionType,
        authData.roles
    ]);

    const getPostReplies = useGetPostReplies({
        parentPostId: post.id,
        countOnly: false
    });

    const {data: repliesResult, refetch: refetchReplies} = useQuery(
        ['post-replies', post.id],
        getPostReplies,
        {enabled: false}
    );

    useEffect(() => {
        if (repliesResult) {
            setPost({
                ...post,
                replies: repliesResult?.replies ?? null
            });
        }
    }, [repliesResult]);

    const deletePost = useDeletePost();
    const {mutate: deletePostMutation} = useMutation(deletePost);

    const reactToPost = useReactToPost();
    const {mutate: reactToPostMutation} = useMutation(reactToPost);

    const handleReplyDelete = (reply: DisplayPostModel) => {
        const confirmed = confirm(
            'Are you sure you want to delete this reply? This cannot be undone'
        );

        if (confirmed) {
            deletePostMutation(
                {post: reply},
                {
                    onSuccess: () => {
                        toast.success('Reply deleted.');
                        refetchReplies();
                    },
                    onError: error => {
                        toast.error(
                            'Unable to delete reply ' +
                                unknownErrorToString(error)
                        );
                    }
                }
            );
        }
    };

    const handleReact = (emoji: Emoji) => {
        reactToPostMutation(
            {
                postId: post.id,
                parentPostId: null,
                emojiCode: emoji.emojiCode
            },
            {
                onSuccess: () => {
                    setShowReactions(false);
                }
            }
        );
    };

    const toggleReplies = async () => {
        if (!post.replies) {
            await refetchReplies();
        }
        setShowReplies(prevState => !prevState);
    };

    return (
        <div
            className="mixi-card no-hover post-card"
            id={post.id}
            ref={groupPostRef}
        >
            <div className="card-content">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div className="member">
                        <img
                            src={post.user.thumbnail}
                            crossOrigin="anonymous"
                            className="member-avatar"
                            alt={displayName(post.user)}
                        />
                        <div className="member-info">
                            <div className="d-flex mb-1 flex-column">
                                <div className="d-flex align-items-center mb-1">
                                    <h5 className="member-name mb-0">
                                        {displayName(post.user)}
                                    </h5>
                                    <h6 className="post-time ps-2 mb-0">
                                        <DateTimeLocalDisplay
                                            dateTimeUtc={post.createdOnUtc}
                                            format="hh:mm a"
                                        />
                                    </h6>
                                </div>
                                <div className="d-flex align-items-center">
                                    <small className="pe-2">
                                        {atify(post.user.nickname)}&nbsp;
                                    </small>
                                    <small className="member-type">
                                        <GroupRoleIcon
                                            loggedInUserUserCollectionType={
                                                post.user.userCollectionType
                                            }
                                            showText={true}
                                        />
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PostToolbar
                        canDelete={canDelete}
                        postType={post.postType}
                        onDelete={onDelete}
                        onReply={toggleReplies}
                        onReact={() => setShowReactions(true)}
                        postId={post.id}
                        parentPostId={null}
                    />
                </div>
                <div className="card-body">
                    <HtmlComponent html={post.content} />
                </div>

                {(countRepliesResult.countReplies > 0 ||
                    post.reactionsCount > 0) && (
                    <div className="card-footer justify-content-start align-items-center">
                        {countRepliesResult.countReplies > 0 && (
                            <>
                                {!showReplies ? (
                                    <a
                                        onClick={toggleReplies}
                                        className="action-link hover-underline"
                                    >
                                        <FontAwesomeIcon
                                            icon={faComment}
                                            className="pe-1"
                                        />
                                        Show{' '}
                                        <span>
                                            {countRepliesResult.countReplies}
                                        </span>{' '}
                                        {countRepliesResult.countReplies !== 1
                                            ? 'replies'
                                            : 'reply'}
                                    </a>
                                ) : (
                                    <a
                                        onClick={toggleReplies}
                                        className="action-link"
                                    >
                                        Hide replies
                                    </a>
                                )}
                            </>
                        )}
                        <MxReactionSummary
                            reactionSummary={post.reactionSummary}
                            reactionsCount={post.reactionsCount}
                            postId={post.id}
                        />
                    </div>
                )}

                <Collapse isOpen={showReplies}>
                    <div
                        className="card-body replies-body"
                        id={`${post.id}-replies`}
                    >
                        {post.replies && (
                            <>
                                {post.replies.map(reply => (
                                    <GroupPostReply
                                        key={reply.id}
                                        collection={collection}
                                        reply={reply}
                                        onDelete={() =>
                                            handleReplyDelete(reply)
                                        }
                                    />
                                ))}
                                <div className="make-post-holder">
                                    <MxAuthorPost
                                        id={`ReplyPosts_${post.id}`}
                                        collection={{id: post.collectionId}}
                                        channel={{id: post.channelId}}
                                        type={PostType.Reply}
                                        parentPostId={post.id}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </Collapse>
            </div>

            {showReactions && (
                <EmojiPickerModal
                    onClose={() => setShowReactions(false)}
                    onSelect={handleReact}
                />
            )}
        </div>
    );
};

export default GroupPost;
