import {PreviewFormat} from '../../types.ts';
import {useState} from 'react';
import FormConfirmEdit from './FormConfirmEdit.tsx';
import {toast} from 'react-toastify';
import humanFileSize from '../../lib/humanFileSize.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faEdit,
    faFile,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import CopyButton from '../shared/CopyButton.tsx';
import ConfirmButton from '../shared/ConfirmButton.tsx';
import baseUri from '../../api/baseUri.ts';
import {useMutation} from 'react-query';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import getBlobInfoFromUri from '../../lib/getBlobInfoFromUri.ts';
import {isNotNullOrWhiteSpace} from '../../lib/isNullOrWhiteSpace.ts';
import AssetPreviewModal from './AssetPreviewModal.tsx';
import {DirectoryModel, FileDataModel} from '../../api/types.ts';
import useRenameFile from '../../api/uploads/useRenameFile.ts';
import useDeleteFile from '../../api/uploads/useDeleteFile.ts';
import AssetPermissions from './AssetPermissions.ts';

interface ContainerFileProps {
    directory: DirectoryModel;
    fileData: FileDataModel;
    permissions: AssetPermissions;
    refetch: () => void;
}

const ContainerFile: React.FC<ContainerFileProps> = ({
    directory,
    fileData,
    permissions,
    refetch
}) => {
    const [isEditing, setIsEditing] = useState(false);

    const [showPreview, setShowPreview] = useState(false);

    const {containerName, blobName} = getBlobInfoFromUri(fileData.uri);

    const renameFile = useRenameFile();
    const renameFileMutation = useMutation(renameFile, {
        onSuccess: () => {
            toast.success('File renamed');
            refetch();
        },
        onError: (error: unknown) => {
            toast.error(unknownErrorToString(error));
        }
    });

    const onApplyFileRename = async (newValue: string) => {
        setIsEditing(false);

        if (!isNotNullOrWhiteSpace(newValue)) {
            toast.error('Invalid filename.');
            return;
        }

        if (
            directory.files.some(
                f => f.name.toLowerCase() == newValue.toLowerCase()
            )
        ) {
            toast.error('A file with that name already exists.');
            return;
        }

        const destinationFilePath =
            blobName.substring(0, blobName.lastIndexOf('/')) + '/' + newValue;

        await renameFileMutation.mutateAsync({
            containerName,
            sourceFilePath: blobName,
            destinationFilePath
        });
    };

    const deleteFile = useDeleteFile();
    const deleteFileMutation = useMutation(deleteFile, {
        onSuccess: () => {
            toast.success('File deleted');
            refetch();
        },
        onError: (error: unknown) => {
            toast.error(unknownErrorToString(error));
        }
    });

    const onDeleteFile = async () => {
        await deleteFileMutation.mutateAsync({containerName, blobName});
    };

    const getFileLink = () => {
        if (fileData.canViewInVR) {
            return (
                <a
                    className="character-limit-100 d-flex w-60"
                    title={fileData.fileName}
                    href={`${baseUri}/vr?url=${encodeURIComponent(
                        fileData.uri
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {fileData.fileName}
                </a>
            );
        }

        if (fileData.previewFormat != PreviewFormat.NoPreview) {
            return (
                <a
                    className="character-limit-100 d-flex w-60 overflow-hidden"
                    title={fileData.fileName}
                    href="#"
                    role="button"
                    onClick={event => (
                        event.preventDefault(), setShowPreview(true)
                    )}
                >
                    {fileData.fileName}
                </a>
            );
        }

        return (
            <a
                className="character-limit-100 d-flex w-60 overflow-hidden"
                title={fileData.fileName}
                href={fileData.uri}
                target="_blank"
                rel="noopener noreferrer"
            >
                {fileData.fileName}
            </a>
        );
    };

    return (
        <>
            {showPreview && (
                <AssetPreviewModal
                    asset={fileData}
                    onClose={() => setShowPreview(false)}
                />
            )}
            <div className="uploads-list-row position-relative overflow-hidden">
                <span className="uploads-list-row-name position-relative">
                    <div className="list-row-icon">
                        <FontAwesomeIcon icon={faFile} />
                    </div>

                    {isEditing && (
                        <FormConfirmEdit
                            value={fileData.fileName}
                            onApply={onApplyFileRename}
                            onCancel={() => setIsEditing(false)}
                        />
                    )}
                    {!isEditing && (
                        <>
                            {getFileLink()}
                            <small className="fs-08 op-08 ps-2">
                                &nbsp;{humanFileSize(fileData.length)}
                            </small>
                        </>
                    )}
                </span>
                <span className="file-action-icons">
                    <CopyButton
                        cssClass="action-link"
                        copyText={fileData.uri}
                        title="Copy file URL"
                    />

                    <a
                        className="action-link"
                        href={fileData.uri}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={`Download ${fileData.fileName}`}
                    >
                        <FontAwesomeIcon icon={faDownload} />
                    </a>

                    {permissions.canRenameOrDeleteFile && (
                        <>
                            <button
                                className="action-link"
                                onClick={() => setIsEditing(true)}
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    title="Rename file"
                                />
                            </button>
                            <ConfirmButton
                                cssClass="action-link"
                                onClick={onDeleteFile}
                                message="Deleting this file will mean that it is not available in any content that depends on it."
                            >
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    title="Delete file"
                                />
                            </ConfirmButton>
                        </>
                    )}
                </span>
            </div>
        </>
    );
};

export default ContainerFile;
