import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetChannelNamesQuery = {
    collectionId: string | null;
    containerName: string;
};

const useGetChannelNames = (request: GetChannelNamesQuery) =>
    useGet<string[]>(`/api/collections/get-channel-names?${toQuery(request)}`, {errorMessage: 'GET api/collections/get-channel-names failed'
});

export default useGetChannelNames;
