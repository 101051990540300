import {forwardRef, useImperativeHandle} from 'react';
import {ClearEditorPlugin} from '@lexical/react/LexicalClearEditorPlugin';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {CLEAR_EDITOR_COMMAND, CLEAR_HISTORY_COMMAND} from 'lexical';

interface ClearEditorProps {}

export interface ClearEditorRef {
    callClearEditor: () => void;
}

const LexicalClearEditorPlugin = forwardRef<ClearEditorRef, ClearEditorProps>(
    (_props, ref) => {
        const [editor] = useLexicalComposerContext();

        const clearEditor = () => {
            editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
            editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
        };

        useImperativeHandle(ref, () => ({
            callClearEditor: clearEditor
        }));

        return <ClearEditorPlugin />;
    }
);

export default LexicalClearEditorPlugin;
