import React from 'react';
import {NavTabsState} from './types.ts';
import {isActive} from './tabs.ts';

interface TabProps {
    name: string;
    children: React.ReactNode;
    navTabsState: NavTabsState;
}

const Tab: React.FC<TabProps> = ({name, children, navTabsState}) => {
    const shouldRenderTab = () => {
        return isActive(navTabsState, name);
    };

    if (shouldRenderTab()) {
        return <div className="tab-content">{children}</div>;
    }

    return null;
};

export default Tab;
