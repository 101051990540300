import AssetSelectAndUpload from '../assets/AssetSelectAndUpload.tsx';
import {MergeData} from '../../types.ts';
import {FileFormat} from '../../lib/FileFormats.ts';
import isNullOrWhiteSpace from '../../lib/isNullOrWhiteSpace.ts';
import EmbeddedModel from './EmbeddedModel.tsx';
import {
    ContentSectionModel,
    FileDataModel,
    Selection,
    ViewMode
} from '../../api/types.ts';
import useSessionState from '../../lib/useSessionState.ts';

interface ModelSectionProps {
    elementId: string;
    section: ContentSectionModel;
    readOnly: boolean;
    mergeData: MergeData;
    packageContainerName?: string;
}

const ModelSection: React.FC<ModelSectionProps> = ({
    elementId,
    section,
    readOnly,
    mergeData,
    packageContainerName
}) => {
    const [viewMode] = useSessionState(`ViewMode_${elementId}`, ViewMode.View);

    const onFileSelectionChanged = (files: FileDataModel[]) => {
        if (files.length > 0) {
            mergeData(elementId, {fileName: files[0].uri});
        }
    };

    if (!readOnly && viewMode == ViewMode.Edit) {
        return (
            <>
                <div className="mb-3 form-group">
                    <label>Title</label>
                    <span>&nbsp;</span>
                    <input
                        className="form-control"
                        value={section.altText ?? ''}
                        onChange={event =>
                            mergeData(elementId, {altText: event.target.value})
                        }
                        maxLength={2048}
                    />
                </div>
                <div className="mb-3 form-group">
                    <label>Description</label>
                    <span>&nbsp;</span>
                    <textarea
                        className="form-control"
                        rows={3}
                        value={section.content ?? ''}
                        onChange={event =>
                            mergeData(elementId, {content: event.target.value})
                        }
                    />
                </div>
                <div className="mb-3 form-group">
                    <label>3D model source</label>
                    <span>&nbsp;</span>
                    <input
                        className="form-control"
                        defaultValue={section.fileName ?? ''}
                        readOnly
                        disabled
                    />
                </div>

                <AssetSelectAndUpload
                    selection={Selection.Single}
                    explanation="Please select a model file"
                    formats={FileFormat.Model}
                    fileSelectionChanged={files =>
                        onFileSelectionChanged(files)
                    }
                    specificContainerName={packageContainerName}
                />

                <small className="mt-4 d-block text-primary">
                    For models with additional files e.g. .mtl or textures
                    upload all files in a zip.
                </small>
            </>
        );
    }

    if (!isNullOrWhiteSpace(section.fileName)) {
        return (
            <>
                {!isNullOrWhiteSpace(section.altText) && (
                    <h4>{section.altText}</h4>
                )}
                {!isNullOrWhiteSpace(section.content) && (
                    <p>{section.content}</p>
                )}
                <div className="mb-3">
                    <EmbeddedModel src={section.fileName!} />
                </div>
            </>
        );
    }

    return null;
};

export default ModelSection;
