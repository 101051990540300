import React from 'react';
import {Button} from 'reactstrap';
import {FaGoogleDrive} from 'react-icons/fa';

interface GoogleDriveUploadPickerProps {
    openPicker: () => void;
}

const GoogleDriveUploadPicker: React.FC<GoogleDriveUploadPickerProps> = ({
    openPicker
}) => {
    return (
        <div style={{textAlign: 'center'}}>
            <Button
                color="primary"
                onClick={() => openPicker()}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <FaGoogleDrive size={50} />
                <span
                    style={{
                        marginTop: 10
                    }}
                >
                    Upload from Google Drive
                </span>
            </Button>
        </div>
    );
};

export default GoogleDriveUploadPicker;
