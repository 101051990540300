import {ChangeEvent, FC, useCallback} from 'react';

interface ImageUploadProps {
    onThumbnailChanged?: (thumbnail: string) => void;
}

const ImageUpload: FC<ImageUploadProps> = ({onThumbnailChanged}) => {
    const onInputFileChange = useCallback(
        async (event: ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0];
            if (!file) return;

            const reader = new FileReader();
            reader.onload = async e => {
                const dataURI = e.target?.result;
                if (typeof dataURI === 'string') {
                    if (onThumbnailChanged) {
                        onThumbnailChanged(dataURI);
                    }
                }
            };
            reader.readAsDataURL(file);
        },
        [onThumbnailChanged]
    );

    return (
        <form>
            <input
                type="file"
                accept="image/x-png,image/jpeg"
                onChange={onInputFileChange}
            />
        </form>
    );
};

export default ImageUpload;
