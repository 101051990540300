import React, {useState} from 'react';
import {Modal, ModalHeader, ModalBody, Button} from 'reactstrap';
import {useAuthStateManager} from '../../hooks/useAuthStateManager';
import useGetPotentialMentions, {
    GetPotentialMentionsQuery
} from '../../api/groups/useGetPotentialMentions';
import useNicknameExists from '../../api/groups/useNicknameExists';
import {useQuery} from 'react-query';
import {UserInfo} from '../../api/types';
import {toast} from 'react-toastify';
import SpinnerIfLoading from './SpinnerIfLoading.tsx';
import {isNotNullOrWhiteSpace} from '../../lib/isNullOrWhiteSpace.ts';

interface MentionsModalProps {
    onClose: () => void;
    onSelect: (nickname: string) => void;
    collectionId: string | undefined;
}

export const MentionsModal: React.FC<MentionsModalProps> = ({
    onClose,
    onSelect,
    collectionId
}) => {
    const {loggedInUserId} = useAuthStateManager();
    const [nickname, setNickname] = useState('');
    const getPotentialMentionsQuery: GetPotentialMentionsQuery = {
        collectionId: collectionId ?? null
    };
    const fetchPotentialMentions = useGetPotentialMentions(
        getPotentialMentionsQuery
    );
    const {data: users, isLoading} = useQuery(
        ['potentialMentions', loggedInUserId],
        fetchPotentialMentions
    );

    const trimmedNickname = (nickname ?? '').replace(/^@/, '');

    const nicknameExists = useNicknameExists({
        nickname: trimmedNickname
    });

    const {refetch} = useQuery(
        ['nicknameExists', trimmedNickname],
        nicknameExists,
        {
            enabled: false // Disable automatic query execution
        }
    );

    const verifyAndAdd = async () => {
        try {
            const exists = await refetch(); // Trigger the query manually

            if (exists) {
                onSelect(trimmedNickname);
            } else {
                toast.warn(`Could not find @${trimmedNickname}`);
            }
        } catch (error) {
            console.error('Error checking nickname existence:', error);
            toast.error('An error occurred while checking the nickname.');
        }
    };

    const add = async (selectedNickname: string) => {
        onSelect(selectedNickname);
    };

    const userHasName = (user: UserInfo) => {
        return isNotNullOrWhiteSpace(user.name);
    };

    return (
        <Modal isOpen toggle={onClose} size="lg">
            <ModalHeader toggle={onClose}>Add mention</ModalHeader>
            <ModalBody>
                <div className="form-group d-flex mb-4 mention-input">
                    <label className="fw-bold">@</label>
                    <input
                        className="form-control"
                        value={nickname}
                        onChange={e => setNickname(e.target.value)}
                        placeholder={nickname}
                    />
                    <Button
                        className="btn btn-primary ms-2"
                        disabled={nickname.trim() === ''}
                        onClick={verifyAndAdd}
                    >
                        Add
                    </Button>
                </div>
                <SpinnerIfLoading loading={isLoading}>
                    <div className="mixi-card-deck member-card-deck mb-1">
                        {users?.map(user => (
                            <div
                                className="mixi-card-holder"
                                key={user.nickname}
                            >
                                <div className="member-card mixi-card hover-lift">
                                    <a
                                        href="#"
                                        onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            add(user.nickname);
                                        }}
                                    >
                                        <div className="card-content d-flex flex-row align-items-center">
                                            <div className="member">
                                                <img
                                                    src={user.thumbnail}
                                                    crossOrigin="anonymous"
                                                    className="member-avatar"
                                                    alt={user.nickname}
                                                />
                                                <div className="member-info">
                                                    <h6 className="member-name mb-0 fw-bold">
                                                        {userHasName(user)
                                                            ? user.name
                                                            : user.nickname}
                                                        <br />
                                                        <small className="text-start fw-normal">
                                                            {user.nickname}
                                                        </small>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </SpinnerIfLoading>
            </ModalBody>
        </Modal>
    );
};

export default MentionsModal;
