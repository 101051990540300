import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRss} from '@fortawesome/free-solid-svg-icons';

interface ReconnectionModalProps {
    reinitialize: () => void;
    onClose: () => void;
}

const ReconnectionModal: React.FC<ReconnectionModalProps> = ({
    reinitialize,
    onClose
}) => {
    return (
        <Modal isOpen={true} toggle={() => onClose()}>
            <ModalHeader>Connection lost</ModalHeader>
            <ModalBody>The connection for this project has expired</ModalBody>
            <ModalFooter>
                <button
                    className="btn btn-success btn-icon mb-2"
                    onClick={() => {
                        reinitialize();
                        onClose();
                    }}
                >
                    Tap to reconnect{' '}
                    <FontAwesomeIcon icon={faRss} className="pl-2 pr-0" />
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default ReconnectionModal;
