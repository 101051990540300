import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faCode,
    faPlay,
    faImage,
    faHeadphones,
    faHeading,
    faCube,
    faAlignLeft,
    faDownload,
    faCopy,
    faShareAltSquare,
    faArrowDown,
    faChalkboard
} from '@fortawesome/free-solid-svg-icons';
import {ContentSectionType} from '../../api/types.ts';

interface SectionIconProps {
    sectionType: ContentSectionType;
}

const SectionIcon: React.FC<SectionIconProps> = ({sectionType}) => {
    switch (sectionType) {
        case ContentSectionType.Markdown:
            return (
                <span>
                    <b>M</b> <FontAwesomeIcon icon={faArrowDown} />
                </span>
            );
        case ContentSectionType.Code:
            return <span>{'{}'}</span>;
        case ContentSectionType.Html:
            return <FontAwesomeIcon icon={faCode} />;
        case ContentSectionType.Video:
            return <FontAwesomeIcon icon={faPlay} />;
        case ContentSectionType.Picture:
            return <FontAwesomeIcon icon={faImage} />;
        case ContentSectionType.Audio:
            return <FontAwesomeIcon icon={faHeadphones} />;
        case ContentSectionType.Header:
            return <FontAwesomeIcon icon={faHeading} />;
        case ContentSectionType.Model:
            return <FontAwesomeIcon icon={faCube} />;
        case ContentSectionType.RichText:
            return <FontAwesomeIcon icon={faAlignLeft} />;
        case ContentSectionType.Download:
            return <FontAwesomeIcon icon={faDownload} />;
        case ContentSectionType.Copy:
            return <FontAwesomeIcon icon={faCopy} />;
        case ContentSectionType.Embed:
            return <FontAwesomeIcon icon={faShareAltSquare} />;
        case ContentSectionType.Projects:
            return <FontAwesomeIcon icon={faChalkboard} />;
        default:
            return <FontAwesomeIcon icon={faAlignLeft} />;
    }
};

export default SectionIcon;
