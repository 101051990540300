import {ChannelInfoModel} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetChannelQuery = {
    collectionId: string;
    channelId: string | null;
    channelName: string;
};

const useGetChannel = (request: GetChannelQuery) =>
    useGet<ChannelInfoModel>(`/api/posts/get-channel?${toQuery(request)}`, {errorMessage: 'GET api/posts/get-channel failed'
});

export default useGetChannel;
