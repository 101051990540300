import useDrivePicker from 'react-google-drive-picker';
import {useEffect, useRef} from 'react';
import FileFormats, {FileFormat} from '../../../lib/FileFormats.ts';
import useClientConfig from '../../../hooks/useClientConfig.tsx';
import {toast} from 'react-toastify';

export interface PickedFileInfo {
    id: string;
    name: string;
    type: string;
    accessToken: string | undefined;
}

export default function useGoogleDrivePicker(
    onPicked: (file: PickedFileInfo) => void,
    formats?: FileFormat,
    forUpload?: boolean
): {openPicker: () => void} {
    const [openPicker, authResponse] = useDrivePicker();

    const {googleDriveClientId, googleDriveDeveloperKey} = useClientConfig();
    const accessTokenRef = useRef<string | undefined>(undefined);

    useEffect(() => {
        if (authResponse?.access_token) {
            const token = authResponse?.access_token;
            accessTokenRef.current = token;
        }
    }, [authResponse]);

    const getMimeTypesForFormat = (formats?: FileFormat) => {
        return FileFormats.getViewMimeTypes(formats, forUpload);
    };

    const viewMimeTypes = getMimeTypesForFormat(formats);

    const handleOpenPicker = () => {
        if (!googleDriveClientId || !googleDriveDeveloperKey) {
            toast.error(
                'Client config data is not configured. Set values in secrets.js or Azure | Configuration | Application settings.'
            );
            return;
        }

        openPicker({
            clientId: googleDriveClientId,
            developerKey: googleDriveDeveloperKey,
            viewId: 'DOCS',
            ...(accessTokenRef.current && {token: accessTokenRef.current}),
            supportDrives: true,
            viewMimeTypes,
            callbackFunction: data => {
                if (data.action === 'cancel') {
                    console.log('User clicked cancel/close button');
                } else if (data.action === 'picked') {
                    const doc = data.docs[0];

                    onPicked({
                        id: doc.id,
                        name: doc.name,
                        type: doc.type,
                        accessToken: accessTokenRef.current
                    });
                }
            }
        });
    };

    return {
        openPicker: handleOpenPicker
    };
}
