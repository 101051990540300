type LanguageOption = {
    value: string;
    label: string;
};

const languageOptions: LanguageOption[] = [
    {value: 'English', label: 'English - English'},
    {value: 'Te Reo Māori', label: 'Te Reo Māori - Māori'},
    {value: 'Samoan', label: 'Gagana Sāmoa - Samoan'},
    {value: 'Mandarin', label: '普通话 (Pǔtōnghuà) - Mandarin'},
    {value: 'Hindi', label: 'हिन्दी (Hindī) - Hindi'},
    {value: 'French', label: 'Français - French'},
    {value: 'Yue (Cantonese)', label: '粵語 (Yuèyǔ) - Cantonese'},
    {value: 'Tongan', label: 'Lea faka-Tonga - Tongan'},
    {value: 'Spanish', label: 'Español - Spanish'},
    {value: 'Korean', label: '한국어 (Hangugeo) - Korean'},
    {value: 'Tagalog', label: 'Wikang Tagalog - Tagalog'},
    {value: 'German', label: 'Deutsch - German'},
    {value: 'Punjabi', label: 'ਪੰਜਾਬੀ (Pañjābī) - Punjabi'},
    {value: 'Japanese', label: '日本語 (Nihongo) - Japanese'},
    {value: 'Afrikaans', label: 'Afrikaans - Afrikaans'},
    {value: 'Gujarati', label: 'ગુજરાતી (Gujarātī) - Gujarati'},
    {value: 'Dutch', label: 'Nederlands - Dutch'},
    {value: 'Fijian', label: 'Vosa Vakaviti - Fijian'},
    {value: 'Arabic', label: 'اللغة العربية (al-lughah al-ʿarabīyah) - Arabic'}
];

export default languageOptions;
