import {
    DecoratorNode,
    NodeKey,
    SerializedLexicalNode,
    type DOMConversionMap,
    type DOMConversionOutput,
    type DOMExportOutput,
    type EditorConfig,
    type LexicalNode,
    type Spread
} from 'lexical';

import VideoComponent, {
    DefaultVideoWidth,
    DefaultVideoHeight
} from './VideoComponent';
import isNullOrWhiteSpace from '../../../lib/isNullOrWhiteSpace.ts';

export interface VideoPayload {
    src: string;
    width?: number;
    height?: number;
    key?: NodeKey;
}

function convertVideoElement(domNode: HTMLElement): null | DOMConversionOutput {
    if (domNode instanceof HTMLVideoElement) {
        const {src} = domNode;
        const widthAttributeValue = domNode.getAttribute('width');
        const heightAttributeValue = domNode.getAttribute('height');

        const width = isNullOrWhiteSpace(widthAttributeValue)
            ? undefined
            : +widthAttributeValue!;
        const height = isNullOrWhiteSpace(heightAttributeValue)
            ? undefined
            : +heightAttributeValue!;
        const node = $createVideoNode({src, width, height});
        return {node};
    }
    return null;
}

export type SerializedVideoNode = Spread<
    {
        src: string;
        width: number;
        height: number;
    },
    SerializedLexicalNode
>;

export class VideoNode extends DecoratorNode<JSX.Element> {
    __src: string;
    __width: number;
    __height: number;

    static getType(): string {
        return 'video';
    }

    static clone(node: VideoNode): VideoNode {
        return new VideoNode(
            node.__src,
            node.__width,
            node.__height,
            node.__key
        );
    }

    exportJSON(): SerializedVideoNode {
        return {
            ...super.exportJSON(),
            type: 'video',
            version: 1,
            src: this.__src,
            width: this.__width,
            height: this.__height
        };
    }

    static importJSON(serializedNode: SerializedVideoNode): VideoNode {
        const {src, width, height} = serializedNode;
        const node = $createVideoNode({src, width, height});
        return node;
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement('video');
        element.setAttribute('data-lexical-video', this.__src);
        element.setAttribute('width', `${this.__width}`);
        element.setAttribute('height', `${this.__height}`);
        element.setAttribute('src', this.__src);
        element.setAttribute('title', 'Video');
        element.setAttribute('controls', '');
        return {element};
    }

    static importDOM(): DOMConversionMap | null {
        return {
            video: () => {
                return {
                    conversion: convertVideoElement,
                    priority: 0
                };
            }
        };
    }

    constructor(src: string, width?: number, height?: number, key?: NodeKey) {
        super(key);
        this.__src = src;
        this.__width = width ?? DefaultVideoWidth;
        this.__height = height ?? DefaultVideoHeight;
    }

    setWidthAndHeight(width: number, height: number): void {
        const writable = this.getWritable();
        writable.__width = width;
        writable.__height = height;
    }

    // View

    createDOM(config: EditorConfig): HTMLElement {
        const span = document.createElement('span');
        const theme = config.theme;
        const className = theme.image;
        if (className !== undefined) {
            span.className = className;
        }
        return span;
    }

    updateDOM(): false {
        return false;
    }

    getSrc(): string {
        return this.__src;
    }

    getWidth(): number {
        return this.__width;
    }

    getHeight(): number {
        return this.__height;
    }

    decorate(): JSX.Element {
        return (
            <VideoComponent
                src={this.__src}
                width={this.__width}
                height={this.__height}
                nodeKey={this.getKey()}
                resizable={true}
            />
        );
    }
    isInline(): false {
        return false;
    }
}

export function $createVideoNode({
    src,
    width,
    height,
    key
}: VideoPayload): VideoNode {
    return new VideoNode(src, width, height, key);
}

export function $isVideoNode(
    node: LexicalNode | null | undefined
): node is VideoNode {
    return node instanceof VideoNode;
}
