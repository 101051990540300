import React, {ReactNode} from 'react';
import {Link, NavLink, To, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import MxNotifications from '../../notifications/MxNotifications.tsx';
import joinPath from '../../../lib/joinPath.ts';
import useClientConfig from '../../../hooks/useClientConfig.tsx';
import {useAuthStateManager} from '../../../hooks/useAuthStateManager.tsx';

interface TopBarProps {
    name?: ReactNode;
    actions?: ReactNode;
    to?: To;
}

const TopBar: React.FC<TopBarProps> = ({name, actions, to}) => {
    const {storageUri} = useClientConfig();
    const {authData, isAuthenticated} = useAuthStateManager();
    const navigate = useNavigate();

    return (
        <div className="project-bar d-flex">
            <div
                className="project-bar-back"
                onClick={() => navigate(to ?? '/')}
                style={{
                    cursor: 'pointer'
                }}
            >
                <NavLink className="small-logo" to={to ?? '/'}>
                    <img
                        src="/images/logos/logo-mark.svg"
                        alt="Mixiply Logo"
                        width={40}
                    />
                </NavLink>
                <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                <span className="vertical-separator"></span>
            </div>
            <div className="project-bar-name">
                <h5 className="fw-semibold m-0 me-1 d-flex align-items-center">
                    {name}
                </h5>
            </div>
            <div className="project-bar-actions">
                {actions}
                {isAuthenticated && (
                    <>
                        <span className="vertical-separator"></span>
                        <MxNotifications />
                        <div className="user-image-holder">
                            <Link to={`/profile`}>
                                <img
                                    src={joinPath(
                                        storageUri,
                                        'thumbnails',
                                        `${authData.id}.png`
                                    )}
                                    alt="Profile"
                                />
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default TopBar;
