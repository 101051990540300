import {UserProfileEmojis} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetUserProfileEmojisQuery = NonNullable<unknown>;

const useGetUserProfileEmojis = (request: GetUserProfileEmojisQuery) =>
    useGet<UserProfileEmojis>(
        `/api/users/get-user-profile-emojis?${toQuery(request)}`,
        {errorMessage: 'GET api/users/get-user-profile-emojis failed'}
    );

export default useGetUserProfileEmojis;
