import {EntityDataModel, ReactStateSetter, UnknownObject} from '../types.ts';
import {ContentPackageModel, ContentSectionModel} from '../api/types.ts';
import {useEffect, useMemo, useRef} from 'react';
import createEntityManipulationMethods from './createEntityManipulationMethods.ts';

export function entityDataModelToArray(
    entityData: EntityDataModel
): Array<{id: string; ordinalPosition: number} & UnknownObject> {
    // Convert EntityDataModel to an array of its values with 'id' and 'ordinalPosition' included in each object
    const itemsArray = Object.entries(entityData).map(([id, value]) => ({
        id,
        ...(value as {ordinalPosition: number} & UnknownObject) // Assure TypeScript that 'value' includes 'ordinalPosition'
    }));

    // Sort the array based on 'ordinalPosition'
    itemsArray.sort((a, b) => a.ordinalPosition - b.ordinalPosition);

    return itemsArray;
}

export function entityDataModelToIdentifiable<T extends {id?: string | null}>(
    entityDataModel: EntityDataModel
): Array<T> {
    const result: Array<T> = [];

    for (const id in entityDataModel) {
        if (Object.prototype.hasOwnProperty.call(entityDataModel, id)) {
            const item = entityDataModel[id] as T;
            result.push(item);
        }
    }

    return result;
}

export function identifiableToEntityDataModel(
    items: Array<{id?: string | null} & UnknownObject>
): EntityDataModel {
    const result: EntityDataModel = {};

    items.forEach(item => {
        if (item.id != null) {
            // This checks for both null and undefined
            result[item.id] = item;
        }
    });

    return result;
}

export const useContentEntityData = (
    content: ContentPackageModel,
    setContent: ReactStateSetter<ContentPackageModel>
) => {
    const entityData = useMemo(
        () => identifiableToEntityDataModel(content.sections),
        [content.sections]
    );

    const entityDataRef = useRef(entityData);
    useEffect(() => {
        entityDataRef.current = entityData;
    }, [entityData]);

    const setEntityData = (
        ed: EntityDataModel | ((prevState: EntityDataModel) => EntityDataModel)
    ) => {
        const nextState =
            typeof ed === 'function' ? ed(entityDataRef.current) : ed;
        setContent(prevState => {
            const sections =
                entityDataModelToIdentifiable<ContentSectionModel>(nextState);

            entityDataRef.current = nextState;

            return {
                ...prevState,
                sections
            };
        });
    };

    const manipulation = useMemo(
        () => createEntityManipulationMethods(setEntityData),
        [setEntityData]
    );

    return {entityData, manipulation};
};
