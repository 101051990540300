import {EmptyResult} from '../types';
import {usePost} from './../useHttpHooks';

export type UpdateChannelCommand = {
    id: string;
    collectionId: string;
    name: string;
    topic: string;
    isDefault: boolean;
    isDeleted: boolean;
};

const useUpdateChannel = () =>
    usePost<UpdateChannelCommand, EmptyResult>(`/api/posts/update-channel`, {errorMessage: 'POST api/posts/update-channel failed'
});

export default useUpdateChannel;
