import React from 'react';
import {useEffectOnce} from '../../hooks/useEffectOnce.ts';
import {
    loadGroupPostsScroller,
    unloadGroupPostsScroller
} from './group_posts.ts';

interface GroupPostsScrollerProps {
    children: React.ReactNode;
    nextMessage: () => Promise<void>;
}

const MxGroupPostsScroller: React.FC<GroupPostsScrollerProps> = ({
    children,
    nextMessage
}) => {
    const id = 'Posts';

    useEffectOnce(() => {
        loadGroupPostsScroller(id, nextMessage);

        return () => {
            unloadGroupPostsScroller(id);
        };
    });

    return (
        <div id={id} className="group-posts custom-scrollbar">
            {children}
        </div>
    );
};

export default MxGroupPostsScroller;
