import ApiError from '../api/ApiError';
import {ServiceError, ServiceErrors} from '../types';
import truncate from './truncate.ts';

function serviceErrorToString(serviceError: ServiceError): string {
    const parts: string[] = [];

    if (serviceError.message) {
        parts.push(serviceError.message);
    }

    if (serviceError.detail) {
        parts.push(serviceError.detail);
    }

    if (serviceError.stackTrace) {
        parts.push(serviceError.stackTrace);
    }

    if (serviceError.exceptionType) {
        parts.push(serviceError.exceptionType);
    }

    if (serviceError.innerException) {
        parts.push(serviceErrorToString(serviceError.innerException));
    }

    return parts.join(' - ');
}

function unknownToString(err: unknown): string {
    if (typeof err === 'string') {
        return err;
    }

    if (typeof err === 'object' && err !== null) {
        const maybeApiErrorWithMessage = err as {message?: string};

        if (maybeApiErrorWithMessage.message) {
            return maybeApiErrorWithMessage.message;
        } else if ('errors' in err && 'count' in err) {
            const serviceErrors = err as ServiceErrors;
            return serviceErrors.errors.map(serviceErrorToString).join('\n');
        } else {
            return Object.entries(err)
                .map(([key, value]) => `${key}: ${unknownToString(value)}`)
                .join(', ');
        }
    }

    return 'An unexpected error occurred.';
}

export default function unknownErrorToString(err: unknown) {
    if (typeof err === 'string') {
        return err;
    }

    if (err instanceof ApiError) {
        if (err.status == 429) {
            return err.message + ' Too many requests. Please try again later.';
        }

        if (err.status == 401) {
            return (
                err.message +
                ' Unauthorized. ' +
                truncate(unknownToString(err.data), 150)
            );
        }

        return err.message + ' ' + truncate(unknownToString(err.data), 150);
    }

    if (err instanceof Error) {
        return err.message;
    }

    return unknownToString(err);
}
