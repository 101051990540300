import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import useGetUserActivities from '../../api/user_activities/useGetUserActivities';
import {useAuthStateManager} from '../../hooks/useAuthStateManager';
import {
    ActivityModel,
    UserActivitiesFor,
    Pagination,
    AssetModel,
    ContentPackageModel,
    ContentSectionType,
    UserActivityType
} from '../../api/types';
import GalleryCard from '../projects/GalleryCard';
import MxContentPackage from '../content/MxContentPackage';
import AssetPreviewModal from '../assets/AssetPreviewModal';
import htmlEncode from '../../lib/htmlEncode.ts';
import getPostUrl from '../../lib/getPostUrl.ts';
import displayName from '../../lib/displayName.ts';
import humanFileSize from '../../lib/humanFileSize.ts';
import Pager from '../shared/Pager.tsx';
import MxTable from '../shared/MxTable.tsx';
import DateTimeLocalDisplay from '../shared/DateTimeLocalDisplay.tsx';
import {getNewSection} from '../content/lib.ts';
import {PreviewFormat} from '../../types.ts';
import {emojis} from '../content_editor/utils/emojis.ts';
import {identifiableToEntityDataModel} from '../../project_data/lib.ts';
import {emptyManipulation} from '../../project_data/createEntityManipulationMethods.ts';

interface MxUserActivitiesProps {
    collectionId?: string;
    userActivitiesFor: UserActivitiesFor;
}

const MxUserActivities: React.FC<MxUserActivitiesProps> = ({
    collectionId,
    userActivitiesFor
}) => {
    const {loggedInUserId} = useAuthStateManager();
    const [pagination, setPagination] = useState<Pagination>({
        page: 1,
        perPage: 12
    });
    const [previewAsset, setPreviewAsset] = useState<AssetModel | null>(null);

    const fetchActivities = useGetUserActivities({
        userActivitiesFor,
        collectionId: collectionId ?? null,
        pagination
    });

    const {data: activities, isLoading} = useQuery(
        [
            'userActivities',
            userActivitiesFor,
            collectionId,
            pagination.page,
            pagination.perPage,
            loggedInUserId
        ],
        fetchActivities
    );

    const showPreview = (asset: AssetModel) => {
        setPreviewAsset(asset);
    };

    const hidePreview = () => {
        setPreviewAsset(null);
    };

    const getPostInfo = (
        activity: ActivityModel
    ): ContentPackageModel | null => {
        const channel = `<span class="fw-bold">${htmlEncode(
            activity.post!.collectionName
        )} #${htmlEncode(activity.post!.channelName)}</span>`;
        const url = getPostUrl(activity.post!);

        if (activity.activityType === UserActivityType.Mention) {
            return toPostContent(
                url,
                `Mentioned in a post by ${htmlEncode(
                    displayName(activity.post!.postUser)
                )} in ${channel}`
            );
        }

        if (activity.activityType === UserActivityType.PostReply) {
            return toPostContent(
                url,
                `Replied to a post by ${htmlEncode(
                    displayName(activity.post!.postUser)
                )} in ${channel}`
            );
        }

        if (activity.activityType === UserActivityType.NewPost) {
            return toPostContent(url, `Created a new post in ${channel}`);
        }

        if (activity.activityType === UserActivityType.PostReaction) {
            return toPostContent(
                url,
                `Reacted ${getEmojiImage(
                    activity.post!.data
                )} to a post by ${htmlEncode(
                    displayName(activity.post!.postUser)
                )} in ${channel}`
            );
        }

        return null;
    };

    const getEmojiImage = (emojiCode: string) => {
        const emoji = emojis[emojiCode] ?? emojis[`:${emojiCode}:`];

        if (emoji) {
            return `<img class="emoji" alt="${emoji.alt}" title="${emoji.title}" src="${emoji.src}" />`;
        }

        return '';
    };

    const toPostContent = (
        url: string,
        content: string
    ): ContentPackageModel => {
        return toContent(`<a href="${url}">${content}</a>`);
    };

    const toContent = (content: string): ContentPackageModel => {
        return {
            id: null,
            sections: [getNewSection(ContentSectionType.Html, 0, {content})]
        };
    };

    const getActivityTypeText = (activity: ActivityModel): string => {
        switch (activity.activityType) {
            case UserActivityType.JournalEntry:
                return activity.autoGenerated
                    ? 'Project updated'
                    : 'Journal entry';
            case UserActivityType.AssetUploaded:
                return 'Asset uploaded';
            case UserActivityType.PostReply:
                return 'Post reply';
            case UserActivityType.Mention:
                return 'Mention';
            case UserActivityType.NewPost:
                return 'New post';
            case UserActivityType.PostReaction:
                return 'Post reaction';
            default:
                return '';
        }
    };

    const onPageSelected = (page: number) => {
        setPagination(prevPagination => ({...prevPagination, page}));
    };

    return (
        <div className="row">
            <div className="col-12">
                {isLoading ? (
                    <div className="text-center">
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                ) : (
                    <>
                        <MxTable
                            mxThead={
                                <tr>
                                    <th style={{width: '280px'}}>Created on</th>
                                    <th>Activity</th>
                                </tr>
                            }
                        >
                            {activities?.items.map(activity => (
                                <React.Fragment key={activity.id}>
                                    <tr>
                                        <td>
                                            <DateTimeLocalDisplay
                                                dateTimeUtc={
                                                    activity.createdOnUtc
                                                }
                                                format="EEE, d MMMM HH:mm"
                                            />
                                        </td>
                                        <td>{getActivityTypeText(activity)}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {activity.project && (
                                                <div className="mixi-card-holder">
                                                    <GalleryCard
                                                        project={
                                                            activity.project
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {activity.post && (
                                                <Link
                                                    to={getPostUrl(
                                                        activity.post
                                                    )}
                                                >
                                                    <img
                                                        src={
                                                            activity.post
                                                                .collectionThumbnail
                                                        }
                                                        crossOrigin="anonymous"
                                                        alt="Group thumbnail"
                                                        style={{
                                                            height: '160px'
                                                        }}
                                                    />
                                                </Link>
                                            )}
                                        </td>
                                        <td>
                                            {activity.content && (
                                                <MxContentPackage
                                                    readOnly
                                                    entityData={identifiableToEntityDataModel(
                                                        activity.content
                                                            .sections
                                                    )}
                                                    manipulation={
                                                        emptyManipulation
                                                    }
                                                />
                                            )}
                                            {(activity.activityType ===
                                                UserActivityType.Mention ||
                                                activity.activityType ===
                                                    UserActivityType.PostReply ||
                                                activity.activityType ===
                                                    UserActivityType.NewPost ||
                                                activity.activityType ===
                                                    UserActivityType.PostReaction) && (
                                                <>
                                                    {getPostInfo(activity) && (
                                                        <MxContentPackage
                                                            readOnly
                                                            entityData={identifiableToEntityDataModel(
                                                                getPostInfo(
                                                                    activity
                                                                )!.sections
                                                            )}
                                                            manipulation={
                                                                emptyManipulation
                                                            }
                                                        />
                                                    )}
                                                </>
                                            )}
                                            {activity.asset && (
                                                <>
                                                    {activity.asset
                                                        .canViewInVR ? (
                                                        <a
                                                            href={`/vr?url=${encodeURIComponent(
                                                                activity.asset
                                                                    .uri
                                                            )}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {
                                                                activity.asset
                                                                    .name
                                                            }
                                                        </a>
                                                    ) : activity.asset
                                                          .previewFormat !==
                                                      PreviewFormat.NoPreview ? (
                                                        <a
                                                            className="character-limit-50 d-inline-block"
                                                            title={
                                                                activity.asset
                                                                    .name
                                                            }
                                                            href="#"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                showPreview(
                                                                    activity.asset!
                                                                );
                                                            }}
                                                        >
                                                            {
                                                                activity.asset
                                                                    .name
                                                            }
                                                        </a>
                                                    ) : (
                                                        <a
                                                            className="character-limit-50 d-inline-block"
                                                            title={
                                                                activity.asset
                                                                    .name
                                                            }
                                                            href={
                                                                activity.asset
                                                                    .uri
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {
                                                                activity.asset
                                                                    .name
                                                            }
                                                        </a>
                                                    )}
                                                    <small className="fs-08 op-08 ps-2">
                                                        &nbsp;
                                                        {humanFileSize(
                                                            activity.asset
                                                                .length
                                                        )}
                                                    </small>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </MxTable>
                        {activities && (
                            <Pager
                                pagedResult={activities}
                                onPageSelected={onPageSelected}
                            />
                        )}
                    </>
                )}
            </div>
            {previewAsset && (
                <AssetPreviewModal asset={previewAsset} onClose={hidePreview} />
            )}
        </div>
    );
};

export default MxUserActivities;
