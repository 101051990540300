import React from 'react';
import {FormGroup, Label, Input} from 'reactstrap';

interface IcosphereEditorProps {
    radius: number;
    subdivisions: number;
    onRadiusChange: (radius: number) => void;
    onSubdivisionsChange: (subdivisions: number) => void;
}

const IcosphereEditor: React.FC<IcosphereEditorProps> = ({
    radius,
    subdivisions,
    onRadiusChange,
    onSubdivisionsChange
}) => {
    const handleRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onRadiusChange(isNaN(value) ? 0 : value);
    };

    const handleSubdivisionsChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const value = parseInt(e.target.value, 10);
        onSubdivisionsChange(isNaN(value) ? 0 : value);
    };

    return (
        <>
            <FormGroup>
                <Label>Radius:</Label>
                <Input
                    type="number"
                    step="0.1"
                    value={radius}
                    onChange={handleRadiusChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Subdivisions:</Label>
                <Input
                    type="number"
                    step="1"
                    value={subdivisions}
                    onChange={handleSubdivisionsChange}
                />
            </FormGroup>
        </>
    );
};

export default IcosphereEditor;
