import MxCode from '../shared/MxCode.tsx';
import HtmlComponent from '../shared/HtmlComponent.tsx';
import {MergeData} from '../../types.ts';
import {ContentSectionModel, ViewMode} from '../../api/types.ts';
import useSessionState from '../../lib/useSessionState.ts';

interface HtmlSectionProps {
    elementId: string;
    section: ContentSectionModel;
    readOnly: boolean;
    mergeData: MergeData;
}

const HtmlSection: React.FC<HtmlSectionProps> = ({
    elementId,
    section,
    readOnly,
    mergeData
}) => {
    const [viewMode] = useSessionState(`ViewMode_${elementId}`, ViewMode.View);

    if (!readOnly && viewMode == ViewMode.Edit) {
        return (
            <MxCode
                id={`Section_Edit_${elementId}`}
                code={section.content ?? ''}
                onChange={code => mergeData(elementId, {content: code})}
                readOnly={false}
                mode="htmlmixed"
            />
        );
    }

    return <HtmlComponent html={section.content} />;
};

export default HtmlSection;
