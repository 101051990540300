export type Vector3 = {
    x: number;
    y: number;
    z: number;
};

export type NormalizedColor = {
    r: number;
    g: number;
    b: number;
    a?: number;
};

export type HTMLColor = string;

export type Material = {
    color?: NormalizedColor | HTMLColor;
    texture?: string;
    alpha?: number;
};

export type SceneEntity = {
    elementId: string;
    type: string;
    position: Vector3;
    rotation?: Vector3;
    scaling?: Vector3;
    material?: Material;
} & (
    | {type: 'sphere'; diameter: number}
    | {type: 'box'; size: number}
    | {type: 'torus'; diameter: number; thickness: number}
    | {type: 'cylinder'; height: number; diameter: number}
    | {type: 'plane'; width: number; height: number}
    | {type: 'disc'; radius: number}
    | {type: 'icosphere'; radius: number; subdivisions: number}
    | {type: 'model'; url: string}
    | {type: 'pointLight'; intensity?: number}
    | {type: 'directionalLight'; direction: Vector3; intensity?: number}
    | {
          type: 'spotLight';
          direction: Vector3;
          angle?: number;
          exponent?: number;
          intensity?: number;
      }
    | {type: 'hemisphericLight'; direction: Vector3; intensity?: number}
    | {
          type: 'torusKnot';
          radius: number;
          tube: number;
          radialSegments: number;
          tubularSegments: number;
          p: number;
          q: number;
      }
    | {
          type: 'ground';
          width: number;
          height: number;
          subdivisions?: number;
          heightMap?: string;
      }
    | {
          type: 'lines';
          points: Vector3[];
          colors?: (NormalizedColor | HTMLColor)[];
      }
    | {
          type: 'dashedLines';
          points: Vector3[];
          dashSize: number;
          gapSize: number;
          dashNb?: number;
          colors?: (NormalizedColor | HTMLColor)[];
      }
    | {
          type: 'extrudeShape';
          shape: Vector3[];
          path: Vector3[];
          scale?: number;
          rotation?: number;
          cap?: number;
      }
    | {type: 'lathe'; shape: Vector3[]; segments?: number; cap?: number}
    | {
          type: 'text';
          text: string;
          color?: NormalizedColor | HTMLColor;
          fontSize?: number;
      }
);

export const SceneEvents = {
    EntityAdd: 'entity:add',
    EntityModify: 'entity:modify',
    EntityDelete: 'entity:delete'
};

export type SceneData = Record<string, SceneEntity>;
