import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type IframeCheckResult = {
    status: number;
    blocked: boolean | null;
    reason?: string | null;
};

export type IframeCheckQuery = {
    url: string;
};

const useIframeCheck = (request: IframeCheckQuery) =>
    useGet<IframeCheckResult>(
        `/api/web_content/iframe_check?${toQuery(request)}`,
        {errorMessage: 'GET api/web_content/iframe_check failed'}
    );

export default useIframeCheck;
