import React, {ReactNode} from 'react';
import {NavTabsState} from './types.ts';

interface NavTabsProps {
    navTabsState: NavTabsState;
    children: ReactNode;
}

const NavTabs: React.FC<NavTabsProps> = ({navTabsState, children}) => {
    if (navTabsState) {
        return <>{children}</>;
    }

    return null;
};

export default NavTabs;
