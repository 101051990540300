class CustomClaimTypes {
    static readonly nickname = 'urn:mixiply:nickname';
    static readonly container_name = 'urn:mixiply:containerName';
    static readonly user_status = 'urn:mixiply:userStatus';
    static readonly upload_limit = 'urn:mixiply:uploadLimit';
    static readonly has_collections = 'urn:mixiply:hasCollections';
    static readonly signalr_token = 'urn:mixiply:SignalRToken';
}

export default CustomClaimTypes;
