import {ChangeEvent, FC} from 'react';

interface CollectionModel {
    containerName: string;
    name: string;
}

interface SelectCollectionProps {
    collections: CollectionModel[];
    containerName: string;
    onCollectionSelected: (selectedContainerName: string) => void;
}

const SelectCollection: FC<SelectCollectionProps> = ({
    collections,
    containerName,
    onCollectionSelected
}) => {
    const onCollectionChanged = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedContainerName = e.target.value;
        onCollectionSelected(selectedContainerName);
    };

    return collections ? (
        <div className="form-group d-flex align-items-center w-50 select-collection">
            <label htmlFor="Collection" className="">
                Collection
            </label>
            <select
                className="form-select ms-2"
                id="Collection"
                onChange={onCollectionChanged}
                value={containerName}
            >
                {collections.map(collection => (
                    <option
                        key={collection.containerName}
                        value={collection.containerName}
                    >
                        {collection.name}
                    </option>
                ))}
            </select>
        </div>
    ) : null;
};

export default SelectCollection;
