import React, {useState} from 'react';
import {FileDataModel, Selection} from '../../api/types.ts';
import FileFormats, {FileFormat} from '../../lib/FileFormats.ts';
import {toast} from 'react-toastify';
import UploadModal from './UploadModal.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUpload} from '@fortawesome/free-solid-svg-icons';
import AssetSelectionModal from './AssetSelectionModal.tsx';

interface AssetSelectAndUploadProps {
    selection: Selection;
    explanation?: string | null;
    fileSelectionChanged: (files: FileDataModel[]) => void;
    formats?: FileFormat;
    specificContainerName?: string;
}

const AssetSelectAndUpload: React.FC<AssetSelectAndUploadProps> = ({
    selection,
    explanation,
    fileSelectionChanged,
    formats,
    specificContainerName
}) => {
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showAssetSelector, setShowAssetSelector] = useState(false);

    const validExtensions = FileFormats.getExtensions(
        formats ?? FileFormat.Any,
        true
    );
    const validFileTypes = validExtensions.map(f => `.${f}`).join(', ');

    const getFilesFromUploadResults = (files: FileDataModel[]) => {
        const isMatchingExtension = (file: FileDataModel) => {
            return (
                validExtensions.includes(file.extension ?? '') ||
                validExtensions.includes('*')
            );
        };

        return files.filter(isMatchingExtension);
    };

    const onFilesUploaded = (files: FileDataModel[]) => {
        const uploadedFiles = getFilesFromUploadResults(files);

        if (uploadedFiles.length === 0) {
            if (files.some(f => f.fileName.endsWith('.zip'))) {
                toast.warning(
                    `Zip file did not contain any valid file types. Valid file extensions are ${validFileTypes}.`
                );
            } else {
                toast.warning('No files were uploaded.');
            }
        } else {
            fileSelectionChanged(uploadedFiles);
        }
    };

    const onAssetsSelected = async (files: FileDataModel[]) => {
        setShowAssetSelector(false);
        fileSelectionChanged(files);
    };

    return (
        <>
            {showUploadModal && (
                <UploadModal
                    folderPath={''}
                    specificContainerName={specificContainerName}
                    onClose={() => setShowUploadModal(false)}
                    onFilesUploaded={onFilesUploaded}
                    formats={formats}
                />
            )}

            {showAssetSelector && (
                <AssetSelectionModal
                    specificContainerName={specificContainerName}
                    formats={formats}
                    selection={selection}
                    onClose={() => setShowAssetSelector(false)}
                    onAssetsSelected={onAssetsSelected}
                />
            )}

            <div className="row">
                <div className="text-center mt-3 col-12 col-sm-6 col-md-3">
                    <button
                        type="button"
                        className="white-lg-btn w-100 btn btn-custom"
                        onClick={() => setShowAssetSelector(true)}
                    >
                        Select file{selection == Selection.Multiple ? 's' : ''}
                    </button>
                    <small className="d-block mt-2">{explanation}</small>
                </div>
                <div className="text-center mt-3 col-12 col-sm-6 col-md-3">
                    <button
                        type="button"
                        className="mb-0 white-lg-btn w-100"
                        onClick={() => setShowUploadModal(true)}
                    >
                        <FontAwesomeIcon icon={faUpload} title="Upload" />
                    </button>
                    <small className="d-block mt-2">{validFileTypes}</small>
                </div>
            </div>
        </>
    );
};

export default AssetSelectAndUpload;
