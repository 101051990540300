import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {useLexicalNodeSelection} from '@lexical/react/useLexicalNodeSelection';
import {
    $getNodeByKey,
    $getSelection,
    $isNodeSelection,
    $setSelection,
    BaseSelection,
    CLICK_COMMAND,
    COMMAND_PRIORITY_LOW,
    DRAGSTART_COMMAND,
    KEY_BACKSPACE_COMMAND,
    KEY_DELETE_COMMAND,
    KEY_ENTER_COMMAND,
    KEY_ESCAPE_COMMAND,
    LexicalEditor,
    NodeKey,
    SELECTION_CHANGE_COMMAND
} from 'lexical';
import {useCallback, useEffect, useRef, useState} from 'react';
import {$isEmojiNode} from './EmojiNode';
import {mergeRegister} from '@lexical/utils';

export default function EmojiComponent({
    src,
    altText,
    nodeKey,
    width,
    height
}: {
    altText: string;
    height: 'inherit' | number;
    nodeKey: NodeKey;
    src: string;
    width: 'inherit' | number;
}): JSX.Element {
    const imageRef = useRef<null | HTMLImageElement>(null);
    const [isSelected, setSelected, clearSelection] =
        useLexicalNodeSelection(nodeKey);
    const [editor] = useLexicalComposerContext();
    const [selection, setSelection] = useState<BaseSelection | null>(null);
    const activeEditorRef = useRef<LexicalEditor | null>(null);

    const $onDelete = useCallback(
        (payload: KeyboardEvent) => {
            if (isSelected && $isNodeSelection($getSelection())) {
                const event: KeyboardEvent = payload;
                event.preventDefault();
                const node = $getNodeByKey(nodeKey);
                if ($isEmojiNode(node)) {
                    node.remove();
                    return true;
                }
            }
            return false;
        },
        [isSelected, nodeKey]
    );

    const $onEnter = useCallback(() => {
        const latestSelection = $getSelection();
        if (isSelected && $isNodeSelection(latestSelection)) {
            //create new paragraph after node
            const nodes = latestSelection.getNodes();
            if (nodes.length == 1) {
                const node = nodes[0];
                node.selectEnd();
                return true;
            }
            return false;
        }
        return false;
    }, [isSelected]);

    const $onEscape = useCallback(() => {
        const latestSelection = $getSelection();
        if (
            isSelected &&
            $isNodeSelection(latestSelection) &&
            latestSelection.getNodes().length === 1
        ) {
            // deselect node
            $setSelection(null);
            return true;
        }
        return false;
    }, [isSelected]);

    useEffect(() => {
        let isMounted = true;
        const unregister = mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                if (isMounted) {
                    setSelection(editorState.read(() => $getSelection()));
                }
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_, activeEditor) => {
                    activeEditorRef.current = activeEditor;
                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand<MouseEvent>(
                CLICK_COMMAND,
                payload => {
                    const event = payload;
                    if (event.target === imageRef.current) {
                        if (event.shiftKey) {
                            setSelected(!isSelected);
                        } else {
                            clearSelection();
                            setSelected(true);
                        }
                        return true;
                    }

                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                DRAGSTART_COMMAND,
                event => {
                    if (event.target === imageRef.current) {
                        // Stops firefox drag and drop image behaviour which is different from other browsers
                        event.preventDefault();
                        return true;
                    }
                    return false;
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_DELETE_COMMAND,
                $onDelete,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_BACKSPACE_COMMAND,
                $onDelete,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_ENTER_COMMAND,
                $onEnter,
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                KEY_ESCAPE_COMMAND,
                $onEscape,
                COMMAND_PRIORITY_LOW
            )
        );
        return () => {
            isMounted = false;
            unregister();
        };
    }, [
        clearSelection,
        editor,
        isSelected,
        nodeKey,
        $onDelete,
        $onEnter,
        $onEscape,
        setSelected
    ]);

    const draggable = isSelected && $isNodeSelection(selection);
    const isFocused = isSelected;
    return (
        <div draggable={draggable}>
            <img
                className={
                    isFocused
                        ? `focused ${
                              $isNodeSelection(selection) ? 'draggable' : ''
                          }`
                        : undefined
                }
                src={src}
                alt={altText}
                ref={imageRef}
                style={{
                    height,
                    width
                }}
            />
        </div>
    );
}
