import React, {useState} from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap';
import {
    GroupMemberInfoFieldEditableBy,
    GroupMemberInfoFieldModel,
    GroupMemberInfoFieldType
} from '../../api/types';
import ConfirmActionButton from '../shared/ConfirmActionButton';
import JsonStringCollectionInput from '../shared/JsonStringCollectionInput';
import FlagEnumCheckbox from '../shared/FlagEnumCheckbox';
import EnumSelect from '../shared/EnumSelect';
import {ReactStateSetter} from '../../types';
import RichTextEditor from '../content_editor/RichTextEditor.tsx';

interface EditGroupMemberInfoFieldModalProps {
    field: GroupMemberInfoFieldModel;
    setField: ReactStateSetter<GroupMemberInfoFieldModel>;
    onSave: () => void;
    onArchive: () => void;
    onDismiss: () => void;
}

const EditGroupMemberInfoFieldModal: React.FC<
    EditGroupMemberInfoFieldModalProps
> = ({field, setField, onSave, onArchive, onDismiss}) => {
    const [introContent, setIntroContent] = useState(field.introContent);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await onSave();
    };

    const handleIntroContentChange = (html: string) => {
        setIntroContent(html);
        setField(prevField => ({...prevField, introContent: html}));
    };

    const handleFieldChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const {name, value} = e.target;
        setField(prevField => ({...prevField, [name]: value}));
    };

    const handleFieldTypeChange = (fieldType: GroupMemberInfoFieldType) => {
        const value = parseInt(String(fieldType), 10);
        setField(prevField => ({...prevField, fieldType: value}));
    };

    const handleEditableByChange = (editableBy: number) => {
        setField(prevField => ({...prevField, editableBy}));
    };

    const handleOptionsChange = (options: string[]) => {
        setField(prevField => ({...prevField, options}));
    };

    return (
        <Modal
            isOpen={true}
            toggle={onDismiss}
            className="modal-dialog modal-dialog-scrollable modal-lg"
        >
            <ModalHeader toggle={onDismiss}>
                {field.id === null ? 'Add field' : 'Edit field'}
            </ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="introContent">
                            Introduction text/content
                        </Label>
                        <RichTextEditor
                            id="introContent"
                            html={introContent}
                            htmlChanged={handleIntroContentChange}
                            imageSelector
                            emojiSelector
                            videoSelector
                            fileSelector
                        />
                    </FormGroup>
                    <FormGroup className="mt-3">
                        <Label for="fieldType">Field type</Label>
                        <EnumSelect
                            enumType={GroupMemberInfoFieldType}
                            id="fieldType"
                            value={field.fieldType}
                            onValueChange={handleFieldTypeChange}
                        />
                    </FormGroup>
                    <FormGroup className="mt-3">
                        <Label for="label">Label</Label>
                        <Input
                            type="text"
                            id="label"
                            name="label"
                            maxLength={1024}
                            value={field.label}
                            onChange={handleFieldChange}
                        />
                    </FormGroup>
                    <FormGroup className="mt-3">
                        <Label for="editableBy">Editable by</Label>
                        <FlagEnumCheckbox
                            enumType={GroupMemberInfoFieldEditableBy}
                            id="editableBy"
                            value={field.editableBy}
                            valueChanged={handleEditableByChange}
                        />
                    </FormGroup>
                    {(field.fieldType ===
                        GroupMemberInfoFieldType.RadioButtonList ||
                        field.fieldType ===
                            GroupMemberInfoFieldType.SelectList) && (
                        <FormGroup className="mt-3">
                            <Label for="options">Options</Label>
                            <JsonStringCollectionInput
                                value={field.options}
                                onValueChanged={handleOptionsChange}
                            />
                        </FormGroup>
                    )}
                    {(field.fieldType === GroupMemberInfoFieldType.Text ||
                        field.fieldType ===
                            GroupMemberInfoFieldType.MultiLineText ||
                        field.fieldType === GroupMemberInfoFieldType.Email) && (
                        <FormGroup className="mt-3">
                            <Label for="maxLength">Max length</Label>
                            <Input
                                type="number"
                                id="maxLength"
                                name="maxLength"
                                min={0}
                                step={1}
                                value={field.maxLength ?? undefined}
                                onChange={handleFieldChange}
                            />
                        </FormGroup>
                    )}
                </form>
            </ModalBody>
            <ModalFooter>
                {field.id !== null && (
                    <ConfirmActionButton
                        id="fieldDeleteButton"
                        cssClass="btn btn-outline-primary float-end"
                        buttonText="Delete"
                        confirmationMessage="Are you sure you want to delete this Field?"
                        onClickAction={onArchive}
                    />
                )}
                <Button
                    type="submit"
                    color="primary"
                    className="float-end ms-3"
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EditGroupMemberInfoFieldModal;
