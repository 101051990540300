import {Emojis} from './types.ts';

const emojis: Emojis = {
    groups: [
        {
            name: 'Smileys & Emotion',
            subGroups: [
                {
                    name: 'face-smiling',
                    emojis: [
                        {
                            emojiCode: 'grinning_face',
                            alt: '😀',
                            name: 'grinning face',
                            source: '/images/emojis/unicode/svg/1f600.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'grinning_face_with_big_eyes',
                            alt: '😃',
                            name: 'grinning face with big eyes',
                            source: '/images/emojis/unicode/svg/1f603.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'grinning_face_with_smiling_eyes',
                            alt: '😄',
                            name: 'grinning face with smiling eyes',
                            source: '/images/emojis/unicode/svg/1f604.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'beaming_face_with_smiling_eyes',
                            alt: '😁',
                            name: 'beaming face with smiling eyes',
                            source: '/images/emojis/unicode/svg/1f601.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'grinning_squinting_face',
                            alt: '😆',
                            name: 'grinning squinting face',
                            source: '/images/emojis/unicode/svg/1f606.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'grinning_face_with_sweat',
                            alt: '😅',
                            name: 'grinning face with sweat',
                            source: '/images/emojis/unicode/svg/1f605.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rolling_on_the_floor_laughing',
                            alt: '🤣',
                            name: 'rolling on the floor laughing',
                            source: '/images/emojis/unicode/svg/1f923.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'face_with_tears_of_joy',
                            alt: '😂',
                            name: 'face with tears of joy',
                            source: '/images/emojis/unicode/svg/1f602.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'slightly_smiling_face',
                            alt: '🙂',
                            name: 'slightly smiling face',
                            source: '/images/emojis/unicode/svg/1f642.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'upside_down_face',
                            alt: '🙃',
                            name: 'upside-down face',
                            source: '/images/emojis/unicode/svg/1f643.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'winking_face',
                            alt: '😉',
                            name: 'winking face',
                            source: '/images/emojis/unicode/svg/1f609.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'smiling_face_with_smiling_eyes',
                            alt: '😊',
                            name: 'smiling face with smiling eyes',
                            source: '/images/emojis/unicode/svg/1f60a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'smiling_face_with_halo',
                            alt: '😇',
                            name: 'smiling face with halo',
                            source: '/images/emojis/unicode/svg/1f607.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'face-affection',
                    emojis: [
                        {
                            emojiCode: 'smiling_face_with_hearts',
                            alt: '🥰',
                            name: 'smiling face with hearts',
                            source: '/images/emojis/unicode/svg/1f970.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'smiling_face_with_heart_eyes',
                            alt: '😍',
                            name: 'smiling face with heart-eyes',
                            source: '/images/emojis/unicode/svg/1f60d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'star_struck',
                            alt: '🤩',
                            name: 'star-struck',
                            source: '/images/emojis/unicode/svg/1f929.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'face_blowing_a_kiss',
                            alt: '😘',
                            name: 'face blowing a kiss',
                            source: '/images/emojis/unicode/svg/1f618.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'kissing_face',
                            alt: '😗',
                            name: 'kissing face',
                            source: '/images/emojis/unicode/svg/1f617.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'smiling_face',
                            alt: '☺',
                            name: 'smiling face',
                            source: '/images/emojis/unicode/svg/263a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'kissing_face_with_closed_eyes',
                            alt: '😚',
                            name: 'kissing face with closed eyes',
                            source: '/images/emojis/unicode/svg/1f61a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'kissing_face_with_smiling_eyes',
                            alt: '😙',
                            name: 'kissing face with smiling eyes',
                            source: '/images/emojis/unicode/svg/1f619.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'smiling_face_with_tear',
                            alt: '🥲',
                            name: 'smiling face with tear',
                            source: '/images/emojis/unicode/svg/1f972.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'face-tongue',
                    emojis: [
                        {
                            emojiCode: 'face_savoring_food',
                            alt: '😋',
                            name: 'face savoring food',
                            source: '/images/emojis/unicode/svg/1f60b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'face_with_tongue',
                            alt: '😛',
                            name: 'face with tongue',
                            source: '/images/emojis/unicode/svg/1f61b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'winking_face_with_tongue',
                            alt: '😜',
                            name: 'winking face with tongue',
                            source: '/images/emojis/unicode/svg/1f61c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'zany_face',
                            alt: '🤪',
                            name: 'zany face',
                            source: '/images/emojis/unicode/svg/1f92a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'squinting_face_with_tongue',
                            alt: '😝',
                            name: 'squinting face with tongue',
                            source: '/images/emojis/unicode/svg/1f61d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'money_mouth_face',
                            alt: '🤑',
                            name: 'money-mouth face',
                            source: '/images/emojis/unicode/svg/1f911.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'face-hand',
                    emojis: [
                        {
                            emojiCode: 'hugging_face',
                            alt: '🤗',
                            name: 'hugging face',
                            source: '/images/emojis/unicode/svg/1f917.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'face_with_hand_over_mouth',
                            alt: '🤭',
                            name: 'face with hand over mouth',
                            source: '/images/emojis/unicode/svg/1f92d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'shushing_face',
                            alt: '🤫',
                            name: 'shushing face',
                            source: '/images/emojis/unicode/svg/1f92b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'thinking_face',
                            alt: '🤔',
                            name: 'thinking face',
                            source: '/images/emojis/unicode/svg/1f914.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'face-neutral-skeptical',
                    emojis: [
                        {
                            emojiCode: 'zipper_mouth_face',
                            alt: '🤐',
                            name: 'zipper-mouth face',
                            source: '/images/emojis/unicode/svg/1f910.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'face_with_raised_eyebrow',
                            alt: '🤨',
                            name: 'face with raised eyebrow',
                            source: '/images/emojis/unicode/svg/1f928.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'neutral_face',
                            alt: '😐',
                            name: 'neutral face',
                            source: '/images/emojis/unicode/svg/1f610.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'expressionless_face',
                            alt: '😑',
                            name: 'expressionless face',
                            source: '/images/emojis/unicode/svg/1f611.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'face_without_mouth',
                            alt: '😶',
                            name: 'face without mouth',
                            source: '/images/emojis/unicode/svg/1f636.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'smirking_face',
                            alt: '😏',
                            name: 'smirking face',
                            source: '/images/emojis/unicode/svg/1f60f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'unamused_face',
                            alt: '😒',
                            name: 'unamused face',
                            source: '/images/emojis/unicode/svg/1f612.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'face_with_rolling_eyes',
                            alt: '🙄',
                            name: 'face with rolling eyes',
                            source: '/images/emojis/unicode/svg/1f644.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'grimacing_face',
                            alt: '😬',
                            name: 'grimacing face',
                            source: '/images/emojis/unicode/svg/1f62c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'lying_face',
                            alt: '🤥',
                            name: 'lying face',
                            source: '/images/emojis/unicode/svg/1f925.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'face-sleepy',
                    emojis: [
                        {
                            emojiCode: 'relieved_face',
                            alt: '😌',
                            name: 'relieved face',
                            source: '/images/emojis/unicode/svg/1f60c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pensive_face',
                            alt: '😔',
                            name: 'pensive face',
                            source: '/images/emojis/unicode/svg/1f614.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sleepy_face',
                            alt: '😪',
                            name: 'sleepy face',
                            source: '/images/emojis/unicode/svg/1f62a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'drooling_face',
                            alt: '🤤',
                            name: 'drooling face',
                            source: '/images/emojis/unicode/svg/1f924.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sleeping_face',
                            alt: '😴',
                            name: 'sleeping face',
                            source: '/images/emojis/unicode/svg/1f634.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'face-unwell',
                    emojis: [
                        {
                            emojiCode: 'face_with_medical_mask',
                            alt: '😷',
                            name: 'face with medical mask',
                            source: '/images/emojis/unicode/svg/1f637.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'face_with_thermometer',
                            alt: '🤒',
                            name: 'face with thermometer',
                            source: '/images/emojis/unicode/svg/1f912.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'face_with_head_bandage',
                            alt: '🤕',
                            name: 'face with head-bandage',
                            source: '/images/emojis/unicode/svg/1f915.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'nauseated_face',
                            alt: '🤢',
                            name: 'nauseated face',
                            source: '/images/emojis/unicode/svg/1f922.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'face_vomiting',
                            alt: '🤮',
                            name: 'face vomiting',
                            source: '/images/emojis/unicode/svg/1f92e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sneezing_face',
                            alt: '🤧',
                            name: 'sneezing face',
                            source: '/images/emojis/unicode/svg/1f927.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hot_face',
                            alt: '🥵',
                            name: 'hot face',
                            source: '/images/emojis/unicode/svg/1f975.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cold_face',
                            alt: '🥶',
                            name: 'cold face',
                            source: '/images/emojis/unicode/svg/1f976.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'woozy_face',
                            alt: '🥴',
                            name: 'woozy face',
                            source: '/images/emojis/unicode/svg/1f974.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'knocked_out_face',
                            alt: '😵',
                            name: 'knocked-out face',
                            source: '/images/emojis/unicode/svg/1f635.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'exploding_head',
                            alt: '🤯',
                            name: 'exploding head',
                            source: '/images/emojis/unicode/svg/1f92f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'face-hat',
                    emojis: [
                        {
                            emojiCode: 'cowboy_hat_face',
                            alt: '🤠',
                            name: 'cowboy hat face',
                            source: '/images/emojis/unicode/svg/1f920.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'partying_face',
                            alt: '🥳',
                            name: 'partying face',
                            source: '/images/emojis/unicode/svg/1f973.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'disguised_face',
                            alt: '🥸',
                            name: 'disguised face',
                            source: '/images/emojis/unicode/svg/1f978.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'face-glasses',
                    emojis: [
                        {
                            emojiCode: 'smiling_face_with_sunglasses',
                            alt: '😎',
                            name: 'smiling face with sunglasses',
                            source: '/images/emojis/unicode/svg/1f60e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'nerd_face',
                            alt: '🤓',
                            name: 'nerd face',
                            source: '/images/emojis/unicode/svg/1f913.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'face_with_monocle',
                            alt: '🧐',
                            name: 'face with monocle',
                            source: '/images/emojis/unicode/svg/1f9d0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'face-concerned',
                    emojis: [
                        {
                            emojiCode: 'confused_face',
                            alt: '😕',
                            name: 'confused face',
                            source: '/images/emojis/unicode/svg/1f615.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'worried_face',
                            alt: '😟',
                            name: 'worried face',
                            source: '/images/emojis/unicode/svg/1f61f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'slightly_frowning_face',
                            alt: '🙁',
                            name: 'slightly frowning face',
                            source: '/images/emojis/unicode/svg/1f641.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'frowning_face',
                            alt: '☹',
                            name: 'frowning face',
                            source: '/images/emojis/unicode/svg/2639.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'face_with_open_mouth',
                            alt: '😮',
                            name: 'face with open mouth',
                            source: '/images/emojis/unicode/svg/1f62e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hushed_face',
                            alt: '😯',
                            name: 'hushed face',
                            source: '/images/emojis/unicode/svg/1f62f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'astonished_face',
                            alt: '😲',
                            name: 'astonished face',
                            source: '/images/emojis/unicode/svg/1f632.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'flushed_face',
                            alt: '😳',
                            name: 'flushed face',
                            source: '/images/emojis/unicode/svg/1f633.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pleading_face',
                            alt: '🥺',
                            name: 'pleading face',
                            source: '/images/emojis/unicode/svg/1f97a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'frowning_face_with_open_mouth',
                            alt: '😦',
                            name: 'frowning face with open mouth',
                            source: '/images/emojis/unicode/svg/1f626.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'anguished_face',
                            alt: '😧',
                            name: 'anguished face',
                            source: '/images/emojis/unicode/svg/1f627.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fearful_face',
                            alt: '😨',
                            name: 'fearful face',
                            source: '/images/emojis/unicode/svg/1f628.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'anxious_face_with_sweat',
                            alt: '😰',
                            name: 'anxious face with sweat',
                            source: '/images/emojis/unicode/svg/1f630.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sad_but_relieved_face',
                            alt: '😥',
                            name: 'sad but relieved face',
                            source: '/images/emojis/unicode/svg/1f625.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'crying_face',
                            alt: '😢',
                            name: 'crying face',
                            source: '/images/emojis/unicode/svg/1f622.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'loudly_crying_face',
                            alt: '😭',
                            name: 'loudly crying face',
                            source: '/images/emojis/unicode/svg/1f62d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'face_screaming_in_fear',
                            alt: '😱',
                            name: 'face screaming in fear',
                            source: '/images/emojis/unicode/svg/1f631.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'confounded_face',
                            alt: '😖',
                            name: 'confounded face',
                            source: '/images/emojis/unicode/svg/1f616.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'persevering_face',
                            alt: '😣',
                            name: 'persevering face',
                            source: '/images/emojis/unicode/svg/1f623.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'disappointed_face',
                            alt: '😞',
                            name: 'disappointed face',
                            source: '/images/emojis/unicode/svg/1f61e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'downcast_face_with_sweat',
                            alt: '😓',
                            name: 'downcast face with sweat',
                            source: '/images/emojis/unicode/svg/1f613.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'weary_face',
                            alt: '😩',
                            name: 'weary face',
                            source: '/images/emojis/unicode/svg/1f629.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tired_face',
                            alt: '😫',
                            name: 'tired face',
                            source: '/images/emojis/unicode/svg/1f62b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'yawning_face',
                            alt: '🥱',
                            name: 'yawning face',
                            source: '/images/emojis/unicode/svg/1f971.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'face-negative',
                    emojis: [
                        {
                            emojiCode: 'face_with_steam_from_nose',
                            alt: '😤',
                            name: 'face with steam from nose',
                            source: '/images/emojis/unicode/svg/1f624.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pouting_face',
                            alt: '😡',
                            name: 'pouting face',
                            source: '/images/emojis/unicode/svg/1f621.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'angry_face',
                            alt: '😠',
                            name: 'angry face',
                            source: '/images/emojis/unicode/svg/1f620.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'face_with_symbols_on_mouth',
                            alt: '🤬',
                            name: 'face with symbols on mouth',
                            source: '/images/emojis/unicode/svg/1f92c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'smiling_face_with_horns',
                            alt: '😈',
                            name: 'smiling face with horns',
                            source: '/images/emojis/unicode/svg/1f608.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'angry_face_with_horns',
                            alt: '👿',
                            name: 'angry face with horns',
                            source: '/images/emojis/unicode/svg/1f47f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'skull',
                            alt: '💀',
                            name: 'skull',
                            source: '/images/emojis/unicode/svg/1f480.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'skull_and_crossbones',
                            alt: '☠',
                            name: 'skull and crossbones',
                            source: '/images/emojis/unicode/svg/2620.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'face-costume',
                    emojis: [
                        {
                            emojiCode: 'pile_of_poo',
                            alt: '💩',
                            name: 'pile of poo',
                            source: '/images/emojis/unicode/svg/1f4a9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'clown_face',
                            alt: '🤡',
                            name: 'clown face',
                            source: '/images/emojis/unicode/svg/1f921.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ogre',
                            alt: '👹',
                            name: 'ogre',
                            source: '/images/emojis/unicode/svg/1f479.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'goblin',
                            alt: '👺',
                            name: 'goblin',
                            source: '/images/emojis/unicode/svg/1f47a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ghost',
                            alt: '👻',
                            name: 'ghost',
                            source: '/images/emojis/unicode/svg/1f47b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'alien',
                            alt: '👽',
                            name: 'alien',
                            source: '/images/emojis/unicode/svg/1f47d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'alien_monster',
                            alt: '👾',
                            name: 'alien monster',
                            source: '/images/emojis/unicode/svg/1f47e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'robot',
                            alt: '🤖',
                            name: 'robot',
                            source: '/images/emojis/unicode/svg/1f916.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'cat-face',
                    emojis: [
                        {
                            emojiCode: 'grinning_cat',
                            alt: '😺',
                            name: 'grinning cat',
                            source: '/images/emojis/unicode/svg/1f63a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'grinning_cat_with_smiling_eyes',
                            alt: '😸',
                            name: 'grinning cat with smiling eyes',
                            source: '/images/emojis/unicode/svg/1f638.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cat_with_tears_of_joy',
                            alt: '😹',
                            name: 'cat with tears of joy',
                            source: '/images/emojis/unicode/svg/1f639.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'smiling_cat_with_heart_eyes',
                            alt: '😻',
                            name: 'smiling cat with heart-eyes',
                            source: '/images/emojis/unicode/svg/1f63b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cat_with_wry_smile',
                            alt: '😼',
                            name: 'cat with wry smile',
                            source: '/images/emojis/unicode/svg/1f63c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'kissing_cat',
                            alt: '😽',
                            name: 'kissing cat',
                            source: '/images/emojis/unicode/svg/1f63d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'weary_cat',
                            alt: '🙀',
                            name: 'weary cat',
                            source: '/images/emojis/unicode/svg/1f640.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'crying_cat',
                            alt: '😿',
                            name: 'crying cat',
                            source: '/images/emojis/unicode/svg/1f63f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pouting_cat',
                            alt: '😾',
                            name: 'pouting cat',
                            source: '/images/emojis/unicode/svg/1f63e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'monkey-face',
                    emojis: [
                        {
                            emojiCode: 'see_no_evil_monkey',
                            alt: '🙈',
                            name: 'see-no-evil monkey',
                            source: '/images/emojis/unicode/svg/1f648.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hear_no_evil_monkey',
                            alt: '🙉',
                            name: 'hear-no-evil monkey',
                            source: '/images/emojis/unicode/svg/1f649.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'speak_no_evil_monkey',
                            alt: '🙊',
                            name: 'speak-no-evil monkey',
                            source: '/images/emojis/unicode/svg/1f64a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'emotion',
                    emojis: [
                        {
                            emojiCode: 'kiss_mark',
                            alt: '💋',
                            name: 'kiss mark',
                            source: '/images/emojis/unicode/svg/1f48b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'love_letter',
                            alt: '💌',
                            name: 'love letter',
                            source: '/images/emojis/unicode/svg/1f48c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'heart_with_arrow',
                            alt: '💘',
                            name: 'heart with arrow',
                            source: '/images/emojis/unicode/svg/1f498.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'heart_with_ribbon',
                            alt: '💝',
                            name: 'heart with ribbon',
                            source: '/images/emojis/unicode/svg/1f49d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sparkling_heart',
                            alt: '💖',
                            name: 'sparkling heart',
                            source: '/images/emojis/unicode/svg/1f496.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'growing_heart',
                            alt: '💗',
                            name: 'growing heart',
                            source: '/images/emojis/unicode/svg/1f497.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'beating_heart',
                            alt: '💓',
                            name: 'beating heart',
                            source: '/images/emojis/unicode/svg/1f493.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'revolving_hearts',
                            alt: '💞',
                            name: 'revolving hearts',
                            source: '/images/emojis/unicode/svg/1f49e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'two_hearts',
                            alt: '💕',
                            name: 'two hearts',
                            source: '/images/emojis/unicode/svg/1f495.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'heart_decoration',
                            alt: '💟',
                            name: 'heart decoration',
                            source: '/images/emojis/unicode/svg/1f49f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'heart_exclamation',
                            alt: '❣',
                            name: 'heart exclamation',
                            source: '/images/emojis/unicode/svg/2763.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'broken_heart',
                            alt: '💔',
                            name: 'broken heart',
                            source: '/images/emojis/unicode/svg/1f494.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'red_heart',
                            alt: '❤',
                            name: 'red heart',
                            source: '/images/emojis/unicode/svg/2764.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'orange_heart',
                            alt: '🧡',
                            name: 'orange heart',
                            source: '/images/emojis/unicode/svg/1f9e1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'yellow_heart',
                            alt: '💛',
                            name: 'yellow heart',
                            source: '/images/emojis/unicode/svg/1f49b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'green_heart',
                            alt: '💚',
                            name: 'green heart',
                            source: '/images/emojis/unicode/svg/1f49a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'blue_heart',
                            alt: '💙',
                            name: 'blue heart',
                            source: '/images/emojis/unicode/svg/1f499.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'purple_heart',
                            alt: '💜',
                            name: 'purple heart',
                            source: '/images/emojis/unicode/svg/1f49c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'brown_heart',
                            alt: '🤎',
                            name: 'brown heart',
                            source: '/images/emojis/unicode/svg/1f90e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'black_heart',
                            alt: '🖤',
                            name: 'black heart',
                            source: '/images/emojis/unicode/svg/1f5a4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'white_heart',
                            alt: '🤍',
                            name: 'white heart',
                            source: '/images/emojis/unicode/svg/1f90d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hundred_points',
                            alt: '💯',
                            name: 'hundred points',
                            source: '/images/emojis/unicode/svg/1f4af.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'anger_symbol',
                            alt: '💢',
                            name: 'anger symbol',
                            source: '/images/emojis/unicode/svg/1f4a2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'collision',
                            alt: '💥',
                            name: 'collision',
                            source: '/images/emojis/unicode/svg/1f4a5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dizzy',
                            alt: '💫',
                            name: 'dizzy',
                            source: '/images/emojis/unicode/svg/1f4ab.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sweat_droplets',
                            alt: '💦',
                            name: 'sweat droplets',
                            source: '/images/emojis/unicode/svg/1f4a6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dashing_away',
                            alt: '💨',
                            name: 'dashing away',
                            source: '/images/emojis/unicode/svg/1f4a8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hole',
                            alt: '🕳',
                            name: 'hole',
                            source: '/images/emojis/unicode/svg/1f573.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bomb',
                            alt: '💣',
                            name: 'bomb',
                            source: '/images/emojis/unicode/svg/1f4a3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'speech_balloon',
                            alt: '💬',
                            name: 'speech balloon',
                            source: '/images/emojis/unicode/svg/1f4ac.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'left_speech_bubble',
                            alt: '🗨',
                            name: 'left speech bubble',
                            source: '/images/emojis/unicode/svg/1f5e8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'right_anger_bubble',
                            alt: '🗯',
                            name: 'right anger bubble',
                            source: '/images/emojis/unicode/svg/1f5ef.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'thought_balloon',
                            alt: '💭',
                            name: 'thought balloon',
                            source: '/images/emojis/unicode/svg/1f4ad.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'zzz',
                            alt: '💤',
                            name: 'zzz',
                            source: '/images/emojis/unicode/svg/1f4a4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                }
            ]
        },
        {
            name: 'People & Body',
            subGroups: [
                {
                    name: 'hand-fingers-open',
                    emojis: [
                        {
                            emojiCode: 'waving_hand',
                            alt: '👋',
                            name: 'waving hand',
                            source: '/images/emojis/unicode/svg/1f44b.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'waving_hand_light_skin_tone',
                                    alt: '👋🏻',
                                    name: 'waving hand',
                                    source: '/images/emojis/unicode/svg/1f44b-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'waving_hand_medium_light_skin_tone',
                                    alt: '👋🏼',
                                    name: 'waving hand',
                                    source: '/images/emojis/unicode/svg/1f44b-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'waving_hand_medium_skin_tone',
                                    alt: '👋🏽',
                                    name: 'waving hand',
                                    source: '/images/emojis/unicode/svg/1f44b-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'waving_hand_medium_dark_skin_tone',
                                    alt: '👋🏾',
                                    name: 'waving hand',
                                    source: '/images/emojis/unicode/svg/1f44b-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'waving_hand_dark_skin_tone',
                                    alt: '👋🏿',
                                    name: 'waving hand',
                                    source: '/images/emojis/unicode/svg/1f44b-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'raised_back_of_hand',
                            alt: '🤚',
                            name: 'raised back of hand',
                            source: '/images/emojis/unicode/svg/1f91a.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'raised_back_of_hand_light_skin_tone',
                                    alt: '🤚🏻',
                                    name: 'raised back of hand',
                                    source: '/images/emojis/unicode/svg/1f91a-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'raised_back_of_hand_medium_light_skin_tone',
                                    alt: '🤚🏼',
                                    name: 'raised back of hand',
                                    source: '/images/emojis/unicode/svg/1f91a-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'raised_back_of_hand_medium_skin_tone',
                                    alt: '🤚🏽',
                                    name: 'raised back of hand',
                                    source: '/images/emojis/unicode/svg/1f91a-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'raised_back_of_hand_medium_dark_skin_tone',
                                    alt: '🤚🏾',
                                    name: 'raised back of hand',
                                    source: '/images/emojis/unicode/svg/1f91a-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'raised_back_of_hand_dark_skin_tone',
                                    alt: '🤚🏿',
                                    name: 'raised back of hand',
                                    source: '/images/emojis/unicode/svg/1f91a-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'raised_hand',
                            alt: '✋',
                            name: 'raised hand',
                            source: '/images/emojis/unicode/svg/270b.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'raised_hand_light_skin_tone',
                                    alt: '✋🏻',
                                    name: 'raised hand',
                                    source: '/images/emojis/unicode/svg/270b-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'raised_hand_medium_light_skin_tone',
                                    alt: '✋🏼',
                                    name: 'raised hand',
                                    source: '/images/emojis/unicode/svg/270b-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'raised_hand_medium_skin_tone',
                                    alt: '✋🏽',
                                    name: 'raised hand',
                                    source: '/images/emojis/unicode/svg/270b-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'raised_hand_medium_dark_skin_tone',
                                    alt: '✋🏾',
                                    name: 'raised hand',
                                    source: '/images/emojis/unicode/svg/270b-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'raised_hand_dark_skin_tone',
                                    alt: '✋🏿',
                                    name: 'raised hand',
                                    source: '/images/emojis/unicode/svg/270b-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'vulcan_salute',
                            alt: '🖖',
                            name: 'vulcan salute',
                            source: '/images/emojis/unicode/svg/1f596.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'vulcan_salute_light_skin_tone',
                                    alt: '🖖🏻',
                                    name: 'vulcan salute',
                                    source: '/images/emojis/unicode/svg/1f596-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'vulcan_salute_medium_light_skin_tone',
                                    alt: '🖖🏼',
                                    name: 'vulcan salute',
                                    source: '/images/emojis/unicode/svg/1f596-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'vulcan_salute_medium_skin_tone',
                                    alt: '🖖🏽',
                                    name: 'vulcan salute',
                                    source: '/images/emojis/unicode/svg/1f596-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'vulcan_salute_medium_dark_skin_tone',
                                    alt: '🖖🏾',
                                    name: 'vulcan salute',
                                    source: '/images/emojis/unicode/svg/1f596-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'vulcan_salute_dark_skin_tone',
                                    alt: '🖖🏿',
                                    name: 'vulcan salute',
                                    source: '/images/emojis/unicode/svg/1f596-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'hand-fingers-partial',
                    emojis: [
                        {
                            emojiCode: 'ok_hand',
                            alt: '👌',
                            name: 'OK hand',
                            source: '/images/emojis/unicode/svg/1f44c.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'ok_hand_light_skin_tone',
                                    alt: '👌🏻',
                                    name: 'OK hand',
                                    source: '/images/emojis/unicode/svg/1f44c-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'ok_hand_medium_light_skin_tone',
                                    alt: '👌🏼',
                                    name: 'OK hand',
                                    source: '/images/emojis/unicode/svg/1f44c-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'ok_hand_medium_skin_tone',
                                    alt: '👌🏽',
                                    name: 'OK hand',
                                    source: '/images/emojis/unicode/svg/1f44c-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'ok_hand_medium_dark_skin_tone',
                                    alt: '👌🏾',
                                    name: 'OK hand',
                                    source: '/images/emojis/unicode/svg/1f44c-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'ok_hand_dark_skin_tone',
                                    alt: '👌🏿',
                                    name: 'OK hand',
                                    source: '/images/emojis/unicode/svg/1f44c-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'pinched_fingers',
                            alt: '🤌',
                            name: 'pinched fingers',
                            source: '/images/emojis/unicode/svg/1f90c.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'pinched_fingers_light_skin_tone',
                                    alt: '🤌🏻',
                                    name: 'pinched fingers',
                                    source: '/images/emojis/unicode/svg/1f90c-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'pinched_fingers_medium_light_skin_tone',
                                    alt: '🤌🏼',
                                    name: 'pinched fingers',
                                    source: '/images/emojis/unicode/svg/1f90c-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'pinched_fingers_medium_skin_tone',
                                    alt: '🤌🏽',
                                    name: 'pinched fingers',
                                    source: '/images/emojis/unicode/svg/1f90c-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'pinched_fingers_medium_dark_skin_tone',
                                    alt: '🤌🏾',
                                    name: 'pinched fingers',
                                    source: '/images/emojis/unicode/svg/1f90c-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'pinched_fingers_dark_skin_tone',
                                    alt: '🤌🏿',
                                    name: 'pinched fingers',
                                    source: '/images/emojis/unicode/svg/1f90c-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'pinching_hand',
                            alt: '🤏',
                            name: 'pinching hand',
                            source: '/images/emojis/unicode/svg/1f90f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'pinching_hand_light_skin_tone',
                                    alt: '🤏🏻',
                                    name: 'pinching hand',
                                    source: '/images/emojis/unicode/svg/1f90f-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'pinching_hand_medium_light_skin_tone',
                                    alt: '🤏🏼',
                                    name: 'pinching hand',
                                    source: '/images/emojis/unicode/svg/1f90f-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'pinching_hand_medium_skin_tone',
                                    alt: '🤏🏽',
                                    name: 'pinching hand',
                                    source: '/images/emojis/unicode/svg/1f90f-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'pinching_hand_medium_dark_skin_tone',
                                    alt: '🤏🏾',
                                    name: 'pinching hand',
                                    source: '/images/emojis/unicode/svg/1f90f-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'pinching_hand_dark_skin_tone',
                                    alt: '🤏🏿',
                                    name: 'pinching hand',
                                    source: '/images/emojis/unicode/svg/1f90f-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'crossed_fingers',
                            alt: '🤞',
                            name: 'crossed fingers',
                            source: '/images/emojis/unicode/svg/1f91e.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'crossed_fingers_light_skin_tone',
                                    alt: '🤞🏻',
                                    name: 'crossed fingers',
                                    source: '/images/emojis/unicode/svg/1f91e-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'crossed_fingers_medium_light_skin_tone',
                                    alt: '🤞🏼',
                                    name: 'crossed fingers',
                                    source: '/images/emojis/unicode/svg/1f91e-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'crossed_fingers_medium_skin_tone',
                                    alt: '🤞🏽',
                                    name: 'crossed fingers',
                                    source: '/images/emojis/unicode/svg/1f91e-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'crossed_fingers_medium_dark_skin_tone',
                                    alt: '🤞🏾',
                                    name: 'crossed fingers',
                                    source: '/images/emojis/unicode/svg/1f91e-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'crossed_fingers_dark_skin_tone',
                                    alt: '🤞🏿',
                                    name: 'crossed fingers',
                                    source: '/images/emojis/unicode/svg/1f91e-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'love_you_gesture',
                            alt: '🤟',
                            name: 'love-you gesture',
                            source: '/images/emojis/unicode/svg/1f91f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'love_you_gesture_light_skin_tone',
                                    alt: '🤟🏻',
                                    name: 'love-you gesture',
                                    source: '/images/emojis/unicode/svg/1f91f-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'love_you_gesture_medium_light_skin_tone',
                                    alt: '🤟🏼',
                                    name: 'love-you gesture',
                                    source: '/images/emojis/unicode/svg/1f91f-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'love_you_gesture_medium_skin_tone',
                                    alt: '🤟🏽',
                                    name: 'love-you gesture',
                                    source: '/images/emojis/unicode/svg/1f91f-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'love_you_gesture_medium_dark_skin_tone',
                                    alt: '🤟🏾',
                                    name: 'love-you gesture',
                                    source: '/images/emojis/unicode/svg/1f91f-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'love_you_gesture_dark_skin_tone',
                                    alt: '🤟🏿',
                                    name: 'love-you gesture',
                                    source: '/images/emojis/unicode/svg/1f91f-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'sign_of_the_horns',
                            alt: '🤘',
                            name: 'sign of the horns',
                            source: '/images/emojis/unicode/svg/1f918.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'sign_of_the_horns_light_skin_tone',
                                    alt: '🤘🏻',
                                    name: 'sign of the horns',
                                    source: '/images/emojis/unicode/svg/1f918-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'sign_of_the_horns_medium_light_skin_tone',
                                    alt: '🤘🏼',
                                    name: 'sign of the horns',
                                    source: '/images/emojis/unicode/svg/1f918-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'sign_of_the_horns_medium_skin_tone',
                                    alt: '🤘🏽',
                                    name: 'sign of the horns',
                                    source: '/images/emojis/unicode/svg/1f918-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'sign_of_the_horns_medium_dark_skin_tone',
                                    alt: '🤘🏾',
                                    name: 'sign of the horns',
                                    source: '/images/emojis/unicode/svg/1f918-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'sign_of_the_horns_dark_skin_tone',
                                    alt: '🤘🏿',
                                    name: 'sign of the horns',
                                    source: '/images/emojis/unicode/svg/1f918-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'call_me_hand',
                            alt: '🤙',
                            name: 'call me hand',
                            source: '/images/emojis/unicode/svg/1f919.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'call_me_hand_light_skin_tone',
                                    alt: '🤙🏻',
                                    name: 'call me hand',
                                    source: '/images/emojis/unicode/svg/1f919-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'call_me_hand_medium_light_skin_tone',
                                    alt: '🤙🏼',
                                    name: 'call me hand',
                                    source: '/images/emojis/unicode/svg/1f919-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'call_me_hand_medium_skin_tone',
                                    alt: '🤙🏽',
                                    name: 'call me hand',
                                    source: '/images/emojis/unicode/svg/1f919-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'call_me_hand_medium_dark_skin_tone',
                                    alt: '🤙🏾',
                                    name: 'call me hand',
                                    source: '/images/emojis/unicode/svg/1f919-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'call_me_hand_dark_skin_tone',
                                    alt: '🤙🏿',
                                    name: 'call me hand',
                                    source: '/images/emojis/unicode/svg/1f919-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'hand-single-finger',
                    emojis: [
                        {
                            emojiCode: 'backhand_index_pointing_left',
                            alt: '👈',
                            name: 'backhand index pointing left',
                            source: '/images/emojis/unicode/svg/1f448.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'backhand_index_pointing_left_light_skin_tone',
                                    alt: '👈🏻',
                                    name: 'backhand index pointing left',
                                    source: '/images/emojis/unicode/svg/1f448-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_left_medium_light_skin_tone',
                                    alt: '👈🏼',
                                    name: 'backhand index pointing left',
                                    source: '/images/emojis/unicode/svg/1f448-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_left_medium_skin_tone',
                                    alt: '👈🏽',
                                    name: 'backhand index pointing left',
                                    source: '/images/emojis/unicode/svg/1f448-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_left_medium_dark_skin_tone',
                                    alt: '👈🏾',
                                    name: 'backhand index pointing left',
                                    source: '/images/emojis/unicode/svg/1f448-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_left_dark_skin_tone',
                                    alt: '👈🏿',
                                    name: 'backhand index pointing left',
                                    source: '/images/emojis/unicode/svg/1f448-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'backhand_index_pointing_right',
                            alt: '👉',
                            name: 'backhand index pointing right',
                            source: '/images/emojis/unicode/svg/1f449.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'backhand_index_pointing_right_light_skin_tone',
                                    alt: '👉🏻',
                                    name: 'backhand index pointing right',
                                    source: '/images/emojis/unicode/svg/1f449-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_right_medium_light_skin_tone',
                                    alt: '👉🏼',
                                    name: 'backhand index pointing right',
                                    source: '/images/emojis/unicode/svg/1f449-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_right_medium_skin_tone',
                                    alt: '👉🏽',
                                    name: 'backhand index pointing right',
                                    source: '/images/emojis/unicode/svg/1f449-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_right_medium_dark_skin_tone',
                                    alt: '👉🏾',
                                    name: 'backhand index pointing right',
                                    source: '/images/emojis/unicode/svg/1f449-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_right_dark_skin_tone',
                                    alt: '👉🏿',
                                    name: 'backhand index pointing right',
                                    source: '/images/emojis/unicode/svg/1f449-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'backhand_index_pointing_up',
                            alt: '👆',
                            name: 'backhand index pointing up',
                            source: '/images/emojis/unicode/svg/1f446.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'backhand_index_pointing_up_light_skin_tone',
                                    alt: '👆🏻',
                                    name: 'backhand index pointing up',
                                    source: '/images/emojis/unicode/svg/1f446-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_up_medium_light_skin_tone',
                                    alt: '👆🏼',
                                    name: 'backhand index pointing up',
                                    source: '/images/emojis/unicode/svg/1f446-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_up_medium_skin_tone',
                                    alt: '👆🏽',
                                    name: 'backhand index pointing up',
                                    source: '/images/emojis/unicode/svg/1f446-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_up_medium_dark_skin_tone',
                                    alt: '👆🏾',
                                    name: 'backhand index pointing up',
                                    source: '/images/emojis/unicode/svg/1f446-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_up_dark_skin_tone',
                                    alt: '👆🏿',
                                    name: 'backhand index pointing up',
                                    source: '/images/emojis/unicode/svg/1f446-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'middle_finger',
                            alt: '🖕',
                            name: 'middle finger',
                            source: '/images/emojis/unicode/svg/1f595.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'middle_finger_light_skin_tone',
                                    alt: '🖕🏻',
                                    name: 'middle finger',
                                    source: '/images/emojis/unicode/svg/1f595-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'middle_finger_medium_light_skin_tone',
                                    alt: '🖕🏼',
                                    name: 'middle finger',
                                    source: '/images/emojis/unicode/svg/1f595-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'middle_finger_medium_skin_tone',
                                    alt: '🖕🏽',
                                    name: 'middle finger',
                                    source: '/images/emojis/unicode/svg/1f595-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'middle_finger_medium_dark_skin_tone',
                                    alt: '🖕🏾',
                                    name: 'middle finger',
                                    source: '/images/emojis/unicode/svg/1f595-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'middle_finger_dark_skin_tone',
                                    alt: '🖕🏿',
                                    name: 'middle finger',
                                    source: '/images/emojis/unicode/svg/1f595-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'backhand_index_pointing_down',
                            alt: '👇',
                            name: 'backhand index pointing down',
                            source: '/images/emojis/unicode/svg/1f447.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'backhand_index_pointing_down_light_skin_tone',
                                    alt: '👇🏻',
                                    name: 'backhand index pointing down',
                                    source: '/images/emojis/unicode/svg/1f447-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_down_medium_light_skin_tone',
                                    alt: '👇🏼',
                                    name: 'backhand index pointing down',
                                    source: '/images/emojis/unicode/svg/1f447-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_down_medium_skin_tone',
                                    alt: '👇🏽',
                                    name: 'backhand index pointing down',
                                    source: '/images/emojis/unicode/svg/1f447-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_down_medium_dark_skin_tone',
                                    alt: '👇🏾',
                                    name: 'backhand index pointing down',
                                    source: '/images/emojis/unicode/svg/1f447-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'backhand_index_pointing_down_dark_skin_tone',
                                    alt: '👇🏿',
                                    name: 'backhand index pointing down',
                                    source: '/images/emojis/unicode/svg/1f447-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'hand-fingers-closed',
                    emojis: [
                        {
                            emojiCode: 'thumbs_up',
                            alt: '👍',
                            name: 'thumbs up',
                            source: '/images/emojis/unicode/svg/1f44d.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'thumbs_up_light_skin_tone',
                                    alt: '👍🏻',
                                    name: 'thumbs up',
                                    source: '/images/emojis/unicode/svg/1f44d-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'thumbs_up_medium_light_skin_tone',
                                    alt: '👍🏼',
                                    name: 'thumbs up',
                                    source: '/images/emojis/unicode/svg/1f44d-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'thumbs_up_medium_skin_tone',
                                    alt: '👍🏽',
                                    name: 'thumbs up',
                                    source: '/images/emojis/unicode/svg/1f44d-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'thumbs_up_medium_dark_skin_tone',
                                    alt: '👍🏾',
                                    name: 'thumbs up',
                                    source: '/images/emojis/unicode/svg/1f44d-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'thumbs_up_dark_skin_tone',
                                    alt: '👍🏿',
                                    name: 'thumbs up',
                                    source: '/images/emojis/unicode/svg/1f44d-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'thumbs_down',
                            alt: '👎',
                            name: 'thumbs down',
                            source: '/images/emojis/unicode/svg/1f44e.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'thumbs_down_light_skin_tone',
                                    alt: '👎🏻',
                                    name: 'thumbs down',
                                    source: '/images/emojis/unicode/svg/1f44e-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'thumbs_down_medium_light_skin_tone',
                                    alt: '👎🏼',
                                    name: 'thumbs down',
                                    source: '/images/emojis/unicode/svg/1f44e-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'thumbs_down_medium_skin_tone',
                                    alt: '👎🏽',
                                    name: 'thumbs down',
                                    source: '/images/emojis/unicode/svg/1f44e-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'thumbs_down_medium_dark_skin_tone',
                                    alt: '👎🏾',
                                    name: 'thumbs down',
                                    source: '/images/emojis/unicode/svg/1f44e-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'thumbs_down_dark_skin_tone',
                                    alt: '👎🏿',
                                    name: 'thumbs down',
                                    source: '/images/emojis/unicode/svg/1f44e-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'raised_fist',
                            alt: '✊',
                            name: 'raised fist',
                            source: '/images/emojis/unicode/svg/270a.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'raised_fist_light_skin_tone',
                                    alt: '✊🏻',
                                    name: 'raised fist',
                                    source: '/images/emojis/unicode/svg/270a-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'raised_fist_medium_light_skin_tone',
                                    alt: '✊🏼',
                                    name: 'raised fist',
                                    source: '/images/emojis/unicode/svg/270a-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'raised_fist_medium_skin_tone',
                                    alt: '✊🏽',
                                    name: 'raised fist',
                                    source: '/images/emojis/unicode/svg/270a-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'raised_fist_medium_dark_skin_tone',
                                    alt: '✊🏾',
                                    name: 'raised fist',
                                    source: '/images/emojis/unicode/svg/270a-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'raised_fist_dark_skin_tone',
                                    alt: '✊🏿',
                                    name: 'raised fist',
                                    source: '/images/emojis/unicode/svg/270a-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'oncoming_fist',
                            alt: '👊',
                            name: 'oncoming fist',
                            source: '/images/emojis/unicode/svg/1f44a.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'oncoming_fist_light_skin_tone',
                                    alt: '👊🏻',
                                    name: 'oncoming fist',
                                    source: '/images/emojis/unicode/svg/1f44a-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'oncoming_fist_medium_light_skin_tone',
                                    alt: '👊🏼',
                                    name: 'oncoming fist',
                                    source: '/images/emojis/unicode/svg/1f44a-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'oncoming_fist_medium_skin_tone',
                                    alt: '👊🏽',
                                    name: 'oncoming fist',
                                    source: '/images/emojis/unicode/svg/1f44a-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'oncoming_fist_medium_dark_skin_tone',
                                    alt: '👊🏾',
                                    name: 'oncoming fist',
                                    source: '/images/emojis/unicode/svg/1f44a-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'oncoming_fist_dark_skin_tone',
                                    alt: '👊🏿',
                                    name: 'oncoming fist',
                                    source: '/images/emojis/unicode/svg/1f44a-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'left_facing_fist',
                            alt: '🤛',
                            name: 'left-facing fist',
                            source: '/images/emojis/unicode/svg/1f91b.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'left_facing_fist_light_skin_tone',
                                    alt: '🤛🏻',
                                    name: 'left-facing fist',
                                    source: '/images/emojis/unicode/svg/1f91b-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'left_facing_fist_medium_light_skin_tone',
                                    alt: '🤛🏼',
                                    name: 'left-facing fist',
                                    source: '/images/emojis/unicode/svg/1f91b-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'left_facing_fist_medium_skin_tone',
                                    alt: '🤛🏽',
                                    name: 'left-facing fist',
                                    source: '/images/emojis/unicode/svg/1f91b-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'left_facing_fist_medium_dark_skin_tone',
                                    alt: '🤛🏾',
                                    name: 'left-facing fist',
                                    source: '/images/emojis/unicode/svg/1f91b-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'left_facing_fist_dark_skin_tone',
                                    alt: '🤛🏿',
                                    name: 'left-facing fist',
                                    source: '/images/emojis/unicode/svg/1f91b-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'right_facing_fist',
                            alt: '🤜',
                            name: 'right-facing fist',
                            source: '/images/emojis/unicode/svg/1f91c.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'right_facing_fist_light_skin_tone',
                                    alt: '🤜🏻',
                                    name: 'right-facing fist',
                                    source: '/images/emojis/unicode/svg/1f91c-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'right_facing_fist_medium_light_skin_tone',
                                    alt: '🤜🏼',
                                    name: 'right-facing fist',
                                    source: '/images/emojis/unicode/svg/1f91c-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'right_facing_fist_medium_skin_tone',
                                    alt: '🤜🏽',
                                    name: 'right-facing fist',
                                    source: '/images/emojis/unicode/svg/1f91c-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'right_facing_fist_medium_dark_skin_tone',
                                    alt: '🤜🏾',
                                    name: 'right-facing fist',
                                    source: '/images/emojis/unicode/svg/1f91c-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'right_facing_fist_dark_skin_tone',
                                    alt: '🤜🏿',
                                    name: 'right-facing fist',
                                    source: '/images/emojis/unicode/svg/1f91c-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'hands',
                    emojis: [
                        {
                            emojiCode: 'clapping_hands',
                            alt: '👏',
                            name: 'clapping hands',
                            source: '/images/emojis/unicode/svg/1f44f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'clapping_hands_light_skin_tone',
                                    alt: '👏🏻',
                                    name: 'clapping hands',
                                    source: '/images/emojis/unicode/svg/1f44f-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'clapping_hands_medium_light_skin_tone',
                                    alt: '👏🏼',
                                    name: 'clapping hands',
                                    source: '/images/emojis/unicode/svg/1f44f-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'clapping_hands_medium_skin_tone',
                                    alt: '👏🏽',
                                    name: 'clapping hands',
                                    source: '/images/emojis/unicode/svg/1f44f-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'clapping_hands_medium_dark_skin_tone',
                                    alt: '👏🏾',
                                    name: 'clapping hands',
                                    source: '/images/emojis/unicode/svg/1f44f-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'clapping_hands_dark_skin_tone',
                                    alt: '👏🏿',
                                    name: 'clapping hands',
                                    source: '/images/emojis/unicode/svg/1f44f-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'raising_hands',
                            alt: '🙌',
                            name: 'raising hands',
                            source: '/images/emojis/unicode/svg/1f64c.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'raising_hands_light_skin_tone',
                                    alt: '🙌🏻',
                                    name: 'raising hands',
                                    source: '/images/emojis/unicode/svg/1f64c-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'raising_hands_medium_light_skin_tone',
                                    alt: '🙌🏼',
                                    name: 'raising hands',
                                    source: '/images/emojis/unicode/svg/1f64c-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'raising_hands_medium_skin_tone',
                                    alt: '🙌🏽',
                                    name: 'raising hands',
                                    source: '/images/emojis/unicode/svg/1f64c-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'raising_hands_medium_dark_skin_tone',
                                    alt: '🙌🏾',
                                    name: 'raising hands',
                                    source: '/images/emojis/unicode/svg/1f64c-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'raising_hands_dark_skin_tone',
                                    alt: '🙌🏿',
                                    name: 'raising hands',
                                    source: '/images/emojis/unicode/svg/1f64c-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'open_hands',
                            alt: '👐',
                            name: 'open hands',
                            source: '/images/emojis/unicode/svg/1f450.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'open_hands_light_skin_tone',
                                    alt: '👐🏻',
                                    name: 'open hands',
                                    source: '/images/emojis/unicode/svg/1f450-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'open_hands_medium_light_skin_tone',
                                    alt: '👐🏼',
                                    name: 'open hands',
                                    source: '/images/emojis/unicode/svg/1f450-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'open_hands_medium_skin_tone',
                                    alt: '👐🏽',
                                    name: 'open hands',
                                    source: '/images/emojis/unicode/svg/1f450-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'open_hands_medium_dark_skin_tone',
                                    alt: '👐🏾',
                                    name: 'open hands',
                                    source: '/images/emojis/unicode/svg/1f450-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'open_hands_dark_skin_tone',
                                    alt: '👐🏿',
                                    name: 'open hands',
                                    source: '/images/emojis/unicode/svg/1f450-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'palms_up_together',
                            alt: '🤲',
                            name: 'palms up together',
                            source: '/images/emojis/unicode/svg/1f932.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'palms_up_together_light_skin_tone',
                                    alt: '🤲🏻',
                                    name: 'palms up together',
                                    source: '/images/emojis/unicode/svg/1f932-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'palms_up_together_medium_light_skin_tone',
                                    alt: '🤲🏼',
                                    name: 'palms up together',
                                    source: '/images/emojis/unicode/svg/1f932-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'palms_up_together_medium_skin_tone',
                                    alt: '🤲🏽',
                                    name: 'palms up together',
                                    source: '/images/emojis/unicode/svg/1f932-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'palms_up_together_medium_dark_skin_tone',
                                    alt: '🤲🏾',
                                    name: 'palms up together',
                                    source: '/images/emojis/unicode/svg/1f932-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'palms_up_together_dark_skin_tone',
                                    alt: '🤲🏿',
                                    name: 'palms up together',
                                    source: '/images/emojis/unicode/svg/1f932-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'handshake',
                            alt: '🤝',
                            name: 'handshake',
                            source: '/images/emojis/unicode/svg/1f91d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'folded_hands',
                            alt: '🙏',
                            name: 'folded hands',
                            source: '/images/emojis/unicode/svg/1f64f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'folded_hands_light_skin_tone',
                                    alt: '🙏🏻',
                                    name: 'folded hands',
                                    source: '/images/emojis/unicode/svg/1f64f-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'folded_hands_medium_light_skin_tone',
                                    alt: '🙏🏼',
                                    name: 'folded hands',
                                    source: '/images/emojis/unicode/svg/1f64f-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'folded_hands_medium_skin_tone',
                                    alt: '🙏🏽',
                                    name: 'folded hands',
                                    source: '/images/emojis/unicode/svg/1f64f-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'folded_hands_medium_dark_skin_tone',
                                    alt: '🙏🏾',
                                    name: 'folded hands',
                                    source: '/images/emojis/unicode/svg/1f64f-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'folded_hands_dark_skin_tone',
                                    alt: '🙏🏿',
                                    name: 'folded hands',
                                    source: '/images/emojis/unicode/svg/1f64f-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'hand-prop',
                    emojis: [
                        {
                            emojiCode: 'nail_polish',
                            alt: '💅',
                            name: 'nail polish',
                            source: '/images/emojis/unicode/svg/1f485.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'nail_polish_light_skin_tone',
                                    alt: '💅🏻',
                                    name: 'nail polish',
                                    source: '/images/emojis/unicode/svg/1f485-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'nail_polish_medium_light_skin_tone',
                                    alt: '💅🏼',
                                    name: 'nail polish',
                                    source: '/images/emojis/unicode/svg/1f485-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'nail_polish_medium_skin_tone',
                                    alt: '💅🏽',
                                    name: 'nail polish',
                                    source: '/images/emojis/unicode/svg/1f485-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'nail_polish_medium_dark_skin_tone',
                                    alt: '💅🏾',
                                    name: 'nail polish',
                                    source: '/images/emojis/unicode/svg/1f485-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'nail_polish_dark_skin_tone',
                                    alt: '💅🏿',
                                    name: 'nail polish',
                                    source: '/images/emojis/unicode/svg/1f485-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'selfie',
                            alt: '🤳',
                            name: 'selfie',
                            source: '/images/emojis/unicode/svg/1f933.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'selfie_light_skin_tone',
                                    alt: '🤳🏻',
                                    name: 'selfie',
                                    source: '/images/emojis/unicode/svg/1f933-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'selfie_medium_light_skin_tone',
                                    alt: '🤳🏼',
                                    name: 'selfie',
                                    source: '/images/emojis/unicode/svg/1f933-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'selfie_medium_skin_tone',
                                    alt: '🤳🏽',
                                    name: 'selfie',
                                    source: '/images/emojis/unicode/svg/1f933-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'selfie_medium_dark_skin_tone',
                                    alt: '🤳🏾',
                                    name: 'selfie',
                                    source: '/images/emojis/unicode/svg/1f933-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'selfie_dark_skin_tone',
                                    alt: '🤳🏿',
                                    name: 'selfie',
                                    source: '/images/emojis/unicode/svg/1f933-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'body-parts',
                    emojis: [
                        {
                            emojiCode: 'flexed_biceps',
                            alt: '💪',
                            name: 'flexed biceps',
                            source: '/images/emojis/unicode/svg/1f4aa.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'flexed_biceps_light_skin_tone',
                                    alt: '💪🏻',
                                    name: 'flexed biceps',
                                    source: '/images/emojis/unicode/svg/1f4aa-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'flexed_biceps_medium_light_skin_tone',
                                    alt: '💪🏼',
                                    name: 'flexed biceps',
                                    source: '/images/emojis/unicode/svg/1f4aa-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flexed_biceps_medium_skin_tone',
                                    alt: '💪🏽',
                                    name: 'flexed biceps',
                                    source: '/images/emojis/unicode/svg/1f4aa-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'flexed_biceps_medium_dark_skin_tone',
                                    alt: '💪🏾',
                                    name: 'flexed biceps',
                                    source: '/images/emojis/unicode/svg/1f4aa-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flexed_biceps_dark_skin_tone',
                                    alt: '💪🏿',
                                    name: 'flexed biceps',
                                    source: '/images/emojis/unicode/svg/1f4aa-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'mechanical_arm',
                            alt: '🦾',
                            name: 'mechanical arm',
                            source: '/images/emojis/unicode/svg/1f9be.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mechanical_leg',
                            alt: '🦿',
                            name: 'mechanical leg',
                            source: '/images/emojis/unicode/svg/1f9bf.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'leg',
                            alt: '🦵',
                            name: 'leg',
                            source: '/images/emojis/unicode/svg/1f9b5.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'leg_light_skin_tone',
                                    alt: '🦵🏻',
                                    name: 'leg',
                                    source: '/images/emojis/unicode/svg/1f9b5-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'leg_medium_light_skin_tone',
                                    alt: '🦵🏼',
                                    name: 'leg',
                                    source: '/images/emojis/unicode/svg/1f9b5-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'leg_medium_skin_tone',
                                    alt: '🦵🏽',
                                    name: 'leg',
                                    source: '/images/emojis/unicode/svg/1f9b5-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'leg_medium_dark_skin_tone',
                                    alt: '🦵🏾',
                                    name: 'leg',
                                    source: '/images/emojis/unicode/svg/1f9b5-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'leg_dark_skin_tone',
                                    alt: '🦵🏿',
                                    name: 'leg',
                                    source: '/images/emojis/unicode/svg/1f9b5-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'foot',
                            alt: '🦶',
                            name: 'foot',
                            source: '/images/emojis/unicode/svg/1f9b6.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'foot_light_skin_tone',
                                    alt: '🦶🏻',
                                    name: 'foot',
                                    source: '/images/emojis/unicode/svg/1f9b6-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'foot_medium_light_skin_tone',
                                    alt: '🦶🏼',
                                    name: 'foot',
                                    source: '/images/emojis/unicode/svg/1f9b6-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'foot_medium_skin_tone',
                                    alt: '🦶🏽',
                                    name: 'foot',
                                    source: '/images/emojis/unicode/svg/1f9b6-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'foot_medium_dark_skin_tone',
                                    alt: '🦶🏾',
                                    name: 'foot',
                                    source: '/images/emojis/unicode/svg/1f9b6-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'foot_dark_skin_tone',
                                    alt: '🦶🏿',
                                    name: 'foot',
                                    source: '/images/emojis/unicode/svg/1f9b6-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'ear',
                            alt: '👂',
                            name: 'ear',
                            source: '/images/emojis/unicode/svg/1f442.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'ear_light_skin_tone',
                                    alt: '👂🏻',
                                    name: 'ear',
                                    source: '/images/emojis/unicode/svg/1f442-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'ear_medium_light_skin_tone',
                                    alt: '👂🏼',
                                    name: 'ear',
                                    source: '/images/emojis/unicode/svg/1f442-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'ear_medium_skin_tone',
                                    alt: '👂🏽',
                                    name: 'ear',
                                    source: '/images/emojis/unicode/svg/1f442-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'ear_medium_dark_skin_tone',
                                    alt: '👂🏾',
                                    name: 'ear',
                                    source: '/images/emojis/unicode/svg/1f442-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'ear_dark_skin_tone',
                                    alt: '👂🏿',
                                    name: 'ear',
                                    source: '/images/emojis/unicode/svg/1f442-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'ear_with_hearing_aid',
                            alt: '🦻',
                            name: 'ear with hearing aid',
                            source: '/images/emojis/unicode/svg/1f9bb.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'ear_with_hearing_aid_light_skin_tone',
                                    alt: '🦻🏻',
                                    name: 'ear with hearing aid',
                                    source: '/images/emojis/unicode/svg/1f9bb-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'ear_with_hearing_aid_medium_light_skin_tone',
                                    alt: '🦻🏼',
                                    name: 'ear with hearing aid',
                                    source: '/images/emojis/unicode/svg/1f9bb-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'ear_with_hearing_aid_medium_skin_tone',
                                    alt: '🦻🏽',
                                    name: 'ear with hearing aid',
                                    source: '/images/emojis/unicode/svg/1f9bb-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'ear_with_hearing_aid_medium_dark_skin_tone',
                                    alt: '🦻🏾',
                                    name: 'ear with hearing aid',
                                    source: '/images/emojis/unicode/svg/1f9bb-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'ear_with_hearing_aid_dark_skin_tone',
                                    alt: '🦻🏿',
                                    name: 'ear with hearing aid',
                                    source: '/images/emojis/unicode/svg/1f9bb-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'nose',
                            alt: '👃',
                            name: 'nose',
                            source: '/images/emojis/unicode/svg/1f443.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'nose_light_skin_tone',
                                    alt: '👃🏻',
                                    name: 'nose',
                                    source: '/images/emojis/unicode/svg/1f443-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'nose_medium_light_skin_tone',
                                    alt: '👃🏼',
                                    name: 'nose',
                                    source: '/images/emojis/unicode/svg/1f443-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'nose_medium_skin_tone',
                                    alt: '👃🏽',
                                    name: 'nose',
                                    source: '/images/emojis/unicode/svg/1f443-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'nose_medium_dark_skin_tone',
                                    alt: '👃🏾',
                                    name: 'nose',
                                    source: '/images/emojis/unicode/svg/1f443-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'nose_dark_skin_tone',
                                    alt: '👃🏿',
                                    name: 'nose',
                                    source: '/images/emojis/unicode/svg/1f443-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'brain',
                            alt: '🧠',
                            name: 'brain',
                            source: '/images/emojis/unicode/svg/1f9e0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'anatomical_heart',
                            alt: '🫀',
                            name: 'anatomical heart',
                            source: '/images/emojis/unicode/svg/1fac0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'lungs',
                            alt: '🫁',
                            name: 'lungs',
                            source: '/images/emojis/unicode/svg/1fac1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tooth',
                            alt: '🦷',
                            name: 'tooth',
                            source: '/images/emojis/unicode/svg/1f9b7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bone',
                            alt: '🦴',
                            name: 'bone',
                            source: '/images/emojis/unicode/svg/1f9b4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'eyes',
                            alt: '👀',
                            name: 'eyes',
                            source: '/images/emojis/unicode/svg/1f440.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'eye',
                            alt: '👁',
                            name: 'eye',
                            source: '/images/emojis/unicode/svg/1f441.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tongue',
                            alt: '👅',
                            name: 'tongue',
                            source: '/images/emojis/unicode/svg/1f445.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mouth',
                            alt: '👄',
                            name: 'mouth',
                            source: '/images/emojis/unicode/svg/1f444.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'person',
                    emojis: [
                        {
                            emojiCode: 'baby',
                            alt: '👶',
                            name: 'baby',
                            source: '/images/emojis/unicode/svg/1f476.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'baby_light_skin_tone',
                                    alt: '👶🏻',
                                    name: 'baby',
                                    source: '/images/emojis/unicode/svg/1f476-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'baby_medium_light_skin_tone',
                                    alt: '👶🏼',
                                    name: 'baby',
                                    source: '/images/emojis/unicode/svg/1f476-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'baby_medium_skin_tone',
                                    alt: '👶🏽',
                                    name: 'baby',
                                    source: '/images/emojis/unicode/svg/1f476-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'baby_medium_dark_skin_tone',
                                    alt: '👶🏾',
                                    name: 'baby',
                                    source: '/images/emojis/unicode/svg/1f476-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'baby_dark_skin_tone',
                                    alt: '👶🏿',
                                    name: 'baby',
                                    source: '/images/emojis/unicode/svg/1f476-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'child',
                            alt: '🧒',
                            name: 'child',
                            source: '/images/emojis/unicode/svg/1f9d2.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'child_light_skin_tone',
                                    alt: '🧒🏻',
                                    name: 'child',
                                    source: '/images/emojis/unicode/svg/1f9d2-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'child_medium_light_skin_tone',
                                    alt: '🧒🏼',
                                    name: 'child',
                                    source: '/images/emojis/unicode/svg/1f9d2-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'child_medium_skin_tone',
                                    alt: '🧒🏽',
                                    name: 'child',
                                    source: '/images/emojis/unicode/svg/1f9d2-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'child_medium_dark_skin_tone',
                                    alt: '🧒🏾',
                                    name: 'child',
                                    source: '/images/emojis/unicode/svg/1f9d2-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'child_dark_skin_tone',
                                    alt: '🧒🏿',
                                    name: 'child',
                                    source: '/images/emojis/unicode/svg/1f9d2-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'boy',
                            alt: '👦',
                            name: 'boy',
                            source: '/images/emojis/unicode/svg/1f466.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'boy_light_skin_tone',
                                    alt: '👦🏻',
                                    name: 'boy',
                                    source: '/images/emojis/unicode/svg/1f466-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'boy_medium_light_skin_tone',
                                    alt: '👦🏼',
                                    name: 'boy',
                                    source: '/images/emojis/unicode/svg/1f466-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'boy_medium_skin_tone',
                                    alt: '👦🏽',
                                    name: 'boy',
                                    source: '/images/emojis/unicode/svg/1f466-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'boy_medium_dark_skin_tone',
                                    alt: '👦🏾',
                                    name: 'boy',
                                    source: '/images/emojis/unicode/svg/1f466-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'boy_dark_skin_tone',
                                    alt: '👦🏿',
                                    name: 'boy',
                                    source: '/images/emojis/unicode/svg/1f466-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'girl',
                            alt: '👧',
                            name: 'girl',
                            source: '/images/emojis/unicode/svg/1f467.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'girl_light_skin_tone',
                                    alt: '👧🏻',
                                    name: 'girl',
                                    source: '/images/emojis/unicode/svg/1f467-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'girl_medium_light_skin_tone',
                                    alt: '👧🏼',
                                    name: 'girl',
                                    source: '/images/emojis/unicode/svg/1f467-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'girl_medium_skin_tone',
                                    alt: '👧🏽',
                                    name: 'girl',
                                    source: '/images/emojis/unicode/svg/1f467-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'girl_medium_dark_skin_tone',
                                    alt: '👧🏾',
                                    name: 'girl',
                                    source: '/images/emojis/unicode/svg/1f467-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'girl_dark_skin_tone',
                                    alt: '👧🏿',
                                    name: 'girl',
                                    source: '/images/emojis/unicode/svg/1f467-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person',
                            alt: '🧑',
                            name: 'person',
                            source: '/images/emojis/unicode/svg/1f9d1.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'person_light_skin_tone',
                                    alt: '🧑🏻',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_medium_light_skin_tone',
                                    alt: '🧑🏼',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_medium_skin_tone',
                                    alt: '🧑🏽',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_medium_dark_skin_tone',
                                    alt: '🧑🏾',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_dark_skin_tone',
                                    alt: '🧑🏿',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_blond_hair',
                                    alt: '👱',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f471.svg',
                                    components: ['blond hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_light_skin_tone_blond_hair',
                                    alt: '👱🏻',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f471-1f3fb.svg',
                                    components: [
                                        'light skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_light_skin_tone_blond_hair',
                                    alt: '👱🏼',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f471-1f3fc.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_skin_tone_blond_hair',
                                    alt: '👱🏽',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f471-1f3fd.svg',
                                    components: [
                                        'medium skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_dark_skin_tone_blond_hair',
                                    alt: '👱🏾',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f471-1f3fe.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_dark_skin_tone_blond_hair',
                                    alt: '👱🏿',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f471-1f3ff.svg',
                                    components: [
                                        'dark skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_beard',
                                    alt: '🧔',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d4.svg',
                                    components: ['beard'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_light_skin_tone_beard',
                                    alt: '🧔🏻',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d4-1f3fb.svg',
                                    components: ['light skin tone', 'beard'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_light_skin_tone_beard',
                                    alt: '🧔🏼',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d4-1f3fc.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'beard'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_medium_skin_tone_beard',
                                    alt: '🧔🏽',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d4-1f3fd.svg',
                                    components: ['medium skin tone', 'beard'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_dark_skin_tone_beard',
                                    alt: '🧔🏾',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d4-1f3fe.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'beard'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_dark_skin_tone_beard',
                                    alt: '🧔🏿',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d4-1f3ff.svg',
                                    components: ['dark skin tone', 'beard'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_red_hair',
                                    alt: '🧑‍🦰',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-200d-1f9b0.svg',
                                    components: ['red hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_light_skin_tone_red_hair',
                                    alt: '🧑🏻‍🦰',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f9b0.svg',
                                    components: ['light skin tone', 'red hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_light_skin_tone_red_hair',
                                    alt: '🧑🏼‍🦰',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f9b0.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'red hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_skin_tone_red_hair',
                                    alt: '🧑🏽‍🦰',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f9b0.svg',
                                    components: [
                                        'medium skin tone',
                                        'red hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_dark_skin_tone_red_hair',
                                    alt: '🧑🏾‍🦰',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f9b0.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'red hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_dark_skin_tone_red_hair',
                                    alt: '🧑🏿‍🦰',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f9b0.svg',
                                    components: ['dark skin tone', 'red hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_curly_hair',
                                    alt: '🧑‍🦱',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-200d-1f9b1.svg',
                                    components: ['curly hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_light_skin_tone_curly_hair',
                                    alt: '🧑🏻‍🦱',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f9b1.svg',
                                    components: [
                                        'light skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_light_skin_tone_curly_hair',
                                    alt: '🧑🏼‍🦱',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f9b1.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_skin_tone_curly_hair',
                                    alt: '🧑🏽‍🦱',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f9b1.svg',
                                    components: [
                                        'medium skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_dark_skin_tone_curly_hair',
                                    alt: '🧑🏾‍🦱',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f9b1.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_dark_skin_tone_curly_hair',
                                    alt: '🧑🏿‍🦱',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f9b1.svg',
                                    components: [
                                        'dark skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_white_hair',
                                    alt: '🧑‍🦳',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-200d-1f9b3.svg',
                                    components: ['white hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_light_skin_tone_white_hair',
                                    alt: '🧑🏻‍🦳',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f9b3.svg',
                                    components: [
                                        'light skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_light_skin_tone_white_hair',
                                    alt: '🧑🏼‍🦳',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f9b3.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_skin_tone_white_hair',
                                    alt: '🧑🏽‍🦳',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f9b3.svg',
                                    components: [
                                        'medium skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_dark_skin_tone_white_hair',
                                    alt: '🧑🏾‍🦳',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f9b3.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_dark_skin_tone_white_hair',
                                    alt: '🧑🏿‍🦳',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f9b3.svg',
                                    components: [
                                        'dark skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_bald',
                                    alt: '🧑‍🦲',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-200d-1f9b2.svg',
                                    components: ['bald'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_light_skin_tone_bald',
                                    alt: '🧑🏻‍🦲',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f9b2.svg',
                                    components: ['light skin tone', 'bald'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_light_skin_tone_bald',
                                    alt: '🧑🏼‍🦲',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f9b2.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'bald'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_medium_skin_tone_bald',
                                    alt: '🧑🏽‍🦲',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f9b2.svg',
                                    components: ['medium skin tone', 'bald'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_medium_dark_skin_tone_bald',
                                    alt: '🧑🏾‍🦲',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f9b2.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'bald'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_dark_skin_tone_bald',
                                    alt: '🧑🏿‍🦲',
                                    name: 'person',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f9b2.svg',
                                    components: ['dark skin tone', 'bald'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man',
                            alt: '👨',
                            name: 'man',
                            source: '/images/emojis/unicode/svg/1f468.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_light_skin_tone',
                                    alt: '👨🏻',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_medium_light_skin_tone',
                                    alt: '👨🏼',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_medium_skin_tone',
                                    alt: '👨🏽',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_medium_dark_skin_tone',
                                    alt: '👨🏾',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_dark_skin_tone',
                                    alt: '👨🏿',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_red_hair',
                                    alt: '👨‍🦰',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f9b0.svg',
                                    components: ['red hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_light_skin_tone_red_hair',
                                    alt: '👨🏻‍🦰',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f9b0.svg',
                                    components: ['light skin tone', 'red hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_medium_light_skin_tone_red_hair',
                                    alt: '👨🏼‍🦰',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f9b0.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'red hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_medium_skin_tone_red_hair',
                                    alt: '👨🏽‍🦰',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f9b0.svg',
                                    components: [
                                        'medium skin tone',
                                        'red hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_medium_dark_skin_tone_red_hair',
                                    alt: '👨🏾‍🦰',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f9b0.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'red hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_dark_skin_tone_red_hair',
                                    alt: '👨🏿‍🦰',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f9b0.svg',
                                    components: ['dark skin tone', 'red hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_curly_hair',
                                    alt: '👨‍🦱',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f9b1.svg',
                                    components: ['curly hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_light_skin_tone_curly_hair',
                                    alt: '👨🏻‍🦱',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f9b1.svg',
                                    components: [
                                        'light skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_medium_light_skin_tone_curly_hair',
                                    alt: '👨🏼‍🦱',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f9b1.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_medium_skin_tone_curly_hair',
                                    alt: '👨🏽‍🦱',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f9b1.svg',
                                    components: [
                                        'medium skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_medium_dark_skin_tone_curly_hair',
                                    alt: '👨🏾‍🦱',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f9b1.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_dark_skin_tone_curly_hair',
                                    alt: '👨🏿‍🦱',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f9b1.svg',
                                    components: [
                                        'dark skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_white_hair',
                                    alt: '👨‍🦳',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f9b3.svg',
                                    components: ['white hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_light_skin_tone_white_hair',
                                    alt: '👨🏻‍🦳',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f9b3.svg',
                                    components: [
                                        'light skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_medium_light_skin_tone_white_hair',
                                    alt: '👨🏼‍🦳',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f9b3.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_medium_skin_tone_white_hair',
                                    alt: '👨🏽‍🦳',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f9b3.svg',
                                    components: [
                                        'medium skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_medium_dark_skin_tone_white_hair',
                                    alt: '👨🏾‍🦳',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f9b3.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_dark_skin_tone_white_hair',
                                    alt: '👨🏿‍🦳',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f9b3.svg',
                                    components: [
                                        'dark skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_bald',
                                    alt: '👨‍🦲',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f9b2.svg',
                                    components: ['bald'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_light_skin_tone_bald',
                                    alt: '👨🏻‍🦲',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f9b2.svg',
                                    components: ['light skin tone', 'bald'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_medium_light_skin_tone_bald',
                                    alt: '👨🏼‍🦲',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f9b2.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'bald'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_medium_skin_tone_bald',
                                    alt: '👨🏽‍🦲',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f9b2.svg',
                                    components: ['medium skin tone', 'bald'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_medium_dark_skin_tone_bald',
                                    alt: '👨🏾‍🦲',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f9b2.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'bald'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_dark_skin_tone_bald',
                                    alt: '👨🏿‍🦲',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f9b2.svg',
                                    components: ['dark skin tone', 'bald'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_blond_hair',
                                    alt: '👱‍♂️',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f471-200d-2642-fe0f.svg',
                                    components: ['blond hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_light_skin_tone_blond_hair',
                                    alt: '👱🏻‍♂️',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f471-1f3fb-200d-2642-fe0f.svg',
                                    components: [
                                        'light skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_medium_light_skin_tone_blond_hair',
                                    alt: '👱🏼‍♂️',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f471-1f3fc-200d-2642-fe0f.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_medium_skin_tone_blond_hair',
                                    alt: '👱🏽‍♂️',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f471-1f3fd-200d-2642-fe0f.svg',
                                    components: [
                                        'medium skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_medium_dark_skin_tone_blond_hair',
                                    alt: '👱🏾‍♂️',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f471-1f3fe-200d-2642-fe0f.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_dark_skin_tone_blond_hair',
                                    alt: '👱🏿‍♂️',
                                    name: 'man',
                                    source: '/images/emojis/unicode/svg/1f471-1f3ff-200d-2642-fe0f.svg',
                                    components: [
                                        'dark skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman',
                            alt: '👩',
                            name: 'woman',
                            source: '/images/emojis/unicode/svg/1f469.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_light_skin_tone',
                                    alt: '👩🏻',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_medium_light_skin_tone',
                                    alt: '👩🏼',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_medium_skin_tone',
                                    alt: '👩🏽',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_medium_dark_skin_tone',
                                    alt: '👩🏾',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_dark_skin_tone',
                                    alt: '👩🏿',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_red_hair',
                                    alt: '👩‍🦰',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-200d-1f9b0.svg',
                                    components: ['red hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_light_skin_tone_red_hair',
                                    alt: '👩🏻‍🦰',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f9b0.svg',
                                    components: ['light skin tone', 'red hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_medium_light_skin_tone_red_hair',
                                    alt: '👩🏼‍🦰',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f9b0.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'red hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_medium_skin_tone_red_hair',
                                    alt: '👩🏽‍🦰',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f9b0.svg',
                                    components: [
                                        'medium skin tone',
                                        'red hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_medium_dark_skin_tone_red_hair',
                                    alt: '👩🏾‍🦰',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f9b0.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'red hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_dark_skin_tone_red_hair',
                                    alt: '👩🏿‍🦰',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f9b0.svg',
                                    components: ['dark skin tone', 'red hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_curly_hair',
                                    alt: '👩‍🦱',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-200d-1f9b1.svg',
                                    components: ['curly hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_light_skin_tone_curly_hair',
                                    alt: '👩🏻‍🦱',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f9b1.svg',
                                    components: [
                                        'light skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_medium_light_skin_tone_curly_hair',
                                    alt: '👩🏼‍🦱',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f9b1.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_medium_skin_tone_curly_hair',
                                    alt: '👩🏽‍🦱',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f9b1.svg',
                                    components: [
                                        'medium skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_medium_dark_skin_tone_curly_hair',
                                    alt: '👩🏾‍🦱',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f9b1.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_dark_skin_tone_curly_hair',
                                    alt: '👩🏿‍🦱',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f9b1.svg',
                                    components: [
                                        'dark skin tone',
                                        'curly hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_white_hair',
                                    alt: '👩‍🦳',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-200d-1f9b3.svg',
                                    components: ['white hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_light_skin_tone_white_hair',
                                    alt: '👩🏻‍🦳',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f9b3.svg',
                                    components: [
                                        'light skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_medium_light_skin_tone_white_hair',
                                    alt: '👩🏼‍🦳',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f9b3.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_medium_skin_tone_white_hair',
                                    alt: '👩🏽‍🦳',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f9b3.svg',
                                    components: [
                                        'medium skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_medium_dark_skin_tone_white_hair',
                                    alt: '👩🏾‍🦳',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f9b3.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_dark_skin_tone_white_hair',
                                    alt: '👩🏿‍🦳',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f9b3.svg',
                                    components: [
                                        'dark skin tone',
                                        'white hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_bald',
                                    alt: '👩‍🦲',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-200d-1f9b2.svg',
                                    components: ['bald'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_light_skin_tone_bald',
                                    alt: '👩🏻‍🦲',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f9b2.svg',
                                    components: ['light skin tone', 'bald'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_medium_light_skin_tone_bald',
                                    alt: '👩🏼‍🦲',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f9b2.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'bald'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_medium_skin_tone_bald',
                                    alt: '👩🏽‍🦲',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f9b2.svg',
                                    components: ['medium skin tone', 'bald'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_medium_dark_skin_tone_bald',
                                    alt: '👩🏾‍🦲',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f9b2.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'bald'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_dark_skin_tone_bald',
                                    alt: '👩🏿‍🦲',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f9b2.svg',
                                    components: ['dark skin tone', 'bald'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_blond_hair',
                                    alt: '👱‍♀️',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f471-200d-2640-fe0f.svg',
                                    components: ['blond hair'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_light_skin_tone_blond_hair',
                                    alt: '👱🏻‍♀️',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f471-1f3fb-200d-2640-fe0f.svg',
                                    components: [
                                        'light skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_medium_light_skin_tone_blond_hair',
                                    alt: '👱🏼‍♀️',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f471-1f3fc-200d-2640-fe0f.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_medium_skin_tone_blond_hair',
                                    alt: '👱🏽‍♀️',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f471-1f3fd-200d-2640-fe0f.svg',
                                    components: [
                                        'medium skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_medium_dark_skin_tone_blond_hair',
                                    alt: '👱🏾‍♀️',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f471-1f3fe-200d-2640-fe0f.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_dark_skin_tone_blond_hair',
                                    alt: '👱🏿‍♀️',
                                    name: 'woman',
                                    source: '/images/emojis/unicode/svg/1f471-1f3ff-200d-2640-fe0f.svg',
                                    components: [
                                        'dark skin tone',
                                        'blond hair'
                                    ],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'older_person',
                            alt: '🧓',
                            name: 'older person',
                            source: '/images/emojis/unicode/svg/1f9d3.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'older_person_light_skin_tone',
                                    alt: '🧓🏻',
                                    name: 'older person',
                                    source: '/images/emojis/unicode/svg/1f9d3-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'older_person_medium_light_skin_tone',
                                    alt: '🧓🏼',
                                    name: 'older person',
                                    source: '/images/emojis/unicode/svg/1f9d3-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'older_person_medium_skin_tone',
                                    alt: '🧓🏽',
                                    name: 'older person',
                                    source: '/images/emojis/unicode/svg/1f9d3-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'older_person_medium_dark_skin_tone',
                                    alt: '🧓🏾',
                                    name: 'older person',
                                    source: '/images/emojis/unicode/svg/1f9d3-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'older_person_dark_skin_tone',
                                    alt: '🧓🏿',
                                    name: 'older person',
                                    source: '/images/emojis/unicode/svg/1f9d3-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'old_man',
                            alt: '👴',
                            name: 'old man',
                            source: '/images/emojis/unicode/svg/1f474.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'old_man_light_skin_tone',
                                    alt: '👴🏻',
                                    name: 'old man',
                                    source: '/images/emojis/unicode/svg/1f474-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'old_man_medium_light_skin_tone',
                                    alt: '👴🏼',
                                    name: 'old man',
                                    source: '/images/emojis/unicode/svg/1f474-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'old_man_medium_skin_tone',
                                    alt: '👴🏽',
                                    name: 'old man',
                                    source: '/images/emojis/unicode/svg/1f474-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'old_man_medium_dark_skin_tone',
                                    alt: '👴🏾',
                                    name: 'old man',
                                    source: '/images/emojis/unicode/svg/1f474-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'old_man_dark_skin_tone',
                                    alt: '👴🏿',
                                    name: 'old man',
                                    source: '/images/emojis/unicode/svg/1f474-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'old_woman',
                            alt: '👵',
                            name: 'old woman',
                            source: '/images/emojis/unicode/svg/1f475.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'old_woman_light_skin_tone',
                                    alt: '👵🏻',
                                    name: 'old woman',
                                    source: '/images/emojis/unicode/svg/1f475-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'old_woman_medium_light_skin_tone',
                                    alt: '👵🏼',
                                    name: 'old woman',
                                    source: '/images/emojis/unicode/svg/1f475-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'old_woman_medium_skin_tone',
                                    alt: '👵🏽',
                                    name: 'old woman',
                                    source: '/images/emojis/unicode/svg/1f475-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'old_woman_medium_dark_skin_tone',
                                    alt: '👵🏾',
                                    name: 'old woman',
                                    source: '/images/emojis/unicode/svg/1f475-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'old_woman_dark_skin_tone',
                                    alt: '👵🏿',
                                    name: 'old woman',
                                    source: '/images/emojis/unicode/svg/1f475-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'person-gesture',
                    emojis: [
                        {
                            emojiCode: 'person_frowning',
                            alt: '🙍',
                            name: 'person frowning',
                            source: '/images/emojis/unicode/svg/1f64d.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_frowning_light_skin_tone',
                                    alt: '🙍🏻',
                                    name: 'person frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_frowning_medium_light_skin_tone',
                                    alt: '🙍🏼',
                                    name: 'person frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_frowning_medium_skin_tone',
                                    alt: '🙍🏽',
                                    name: 'person frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_frowning_medium_dark_skin_tone',
                                    alt: '🙍🏾',
                                    name: 'person frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_frowning_dark_skin_tone',
                                    alt: '🙍🏿',
                                    name: 'person frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_frowning',
                            alt: '🙍‍♂️',
                            name: 'man frowning',
                            source: '/images/emojis/unicode/svg/1f64d-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_frowning_light_skin_tone',
                                    alt: '🙍🏻‍♂️',
                                    name: 'man frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_frowning_medium_light_skin_tone',
                                    alt: '🙍🏼‍♂️',
                                    name: 'man frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_frowning_medium_skin_tone',
                                    alt: '🙍🏽‍♂️',
                                    name: 'man frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_frowning_medium_dark_skin_tone',
                                    alt: '🙍🏾‍♂️',
                                    name: 'man frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_frowning_dark_skin_tone',
                                    alt: '🙍🏿‍♂️',
                                    name: 'man frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_frowning',
                            alt: '🙍‍♀️',
                            name: 'woman frowning',
                            source: '/images/emojis/unicode/svg/1f64d-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_frowning_light_skin_tone',
                                    alt: '🙍🏻‍♀️',
                                    name: 'woman frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_frowning_medium_light_skin_tone',
                                    alt: '🙍🏼‍♀️',
                                    name: 'woman frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_frowning_medium_skin_tone',
                                    alt: '🙍🏽‍♀️',
                                    name: 'woman frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_frowning_medium_dark_skin_tone',
                                    alt: '🙍🏾‍♀️',
                                    name: 'woman frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_frowning_dark_skin_tone',
                                    alt: '🙍🏿‍♀️',
                                    name: 'woman frowning',
                                    source: '/images/emojis/unicode/svg/1f64d-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_pouting',
                            alt: '🙎',
                            name: 'person pouting',
                            source: '/images/emojis/unicode/svg/1f64e.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'person_pouting_light_skin_tone',
                                    alt: '🙎🏻',
                                    name: 'person pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_pouting_medium_light_skin_tone',
                                    alt: '🙎🏼',
                                    name: 'person pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_pouting_medium_skin_tone',
                                    alt: '🙎🏽',
                                    name: 'person pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_pouting_medium_dark_skin_tone',
                                    alt: '🙎🏾',
                                    name: 'person pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_pouting_dark_skin_tone',
                                    alt: '🙎🏿',
                                    name: 'person pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_pouting',
                            alt: '🙎‍♂️',
                            name: 'man pouting',
                            source: '/images/emojis/unicode/svg/1f64e-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_pouting_light_skin_tone',
                                    alt: '🙎🏻‍♂️',
                                    name: 'man pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_pouting_medium_light_skin_tone',
                                    alt: '🙎🏼‍♂️',
                                    name: 'man pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_pouting_medium_skin_tone',
                                    alt: '🙎🏽‍♂️',
                                    name: 'man pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_pouting_medium_dark_skin_tone',
                                    alt: '🙎🏾‍♂️',
                                    name: 'man pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_pouting_dark_skin_tone',
                                    alt: '🙎🏿‍♂️',
                                    name: 'man pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_pouting',
                            alt: '🙎‍♀️',
                            name: 'woman pouting',
                            source: '/images/emojis/unicode/svg/1f64e-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_pouting_light_skin_tone',
                                    alt: '🙎🏻‍♀️',
                                    name: 'woman pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_pouting_medium_light_skin_tone',
                                    alt: '🙎🏼‍♀️',
                                    name: 'woman pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_pouting_medium_skin_tone',
                                    alt: '🙎🏽‍♀️',
                                    name: 'woman pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_pouting_medium_dark_skin_tone',
                                    alt: '🙎🏾‍♀️',
                                    name: 'woman pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_pouting_dark_skin_tone',
                                    alt: '🙎🏿‍♀️',
                                    name: 'woman pouting',
                                    source: '/images/emojis/unicode/svg/1f64e-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_gesturing_no',
                            alt: '🙅',
                            name: 'person gesturing NO',
                            source: '/images/emojis/unicode/svg/1f645.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_gesturing_no_light_skin_tone',
                                    alt: '🙅🏻',
                                    name: 'person gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_gesturing_no_medium_light_skin_tone',
                                    alt: '🙅🏼',
                                    name: 'person gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_gesturing_no_medium_skin_tone',
                                    alt: '🙅🏽',
                                    name: 'person gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_gesturing_no_medium_dark_skin_tone',
                                    alt: '🙅🏾',
                                    name: 'person gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_gesturing_no_dark_skin_tone',
                                    alt: '🙅🏿',
                                    name: 'person gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_gesturing_no',
                            alt: '🙅‍♂️',
                            name: 'man gesturing NO',
                            source: '/images/emojis/unicode/svg/1f645-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_gesturing_no_light_skin_tone',
                                    alt: '🙅🏻‍♂️',
                                    name: 'man gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_gesturing_no_medium_light_skin_tone',
                                    alt: '🙅🏼‍♂️',
                                    name: 'man gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_gesturing_no_medium_skin_tone',
                                    alt: '🙅🏽‍♂️',
                                    name: 'man gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_gesturing_no_medium_dark_skin_tone',
                                    alt: '🙅🏾‍♂️',
                                    name: 'man gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_gesturing_no_dark_skin_tone',
                                    alt: '🙅🏿‍♂️',
                                    name: 'man gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_gesturing_no',
                            alt: '🙅‍♀️',
                            name: 'woman gesturing NO',
                            source: '/images/emojis/unicode/svg/1f645-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_gesturing_no_light_skin_tone',
                                    alt: '🙅🏻‍♀️',
                                    name: 'woman gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_gesturing_no_medium_light_skin_tone',
                                    alt: '🙅🏼‍♀️',
                                    name: 'woman gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_gesturing_no_medium_skin_tone',
                                    alt: '🙅🏽‍♀️',
                                    name: 'woman gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_gesturing_no_medium_dark_skin_tone',
                                    alt: '🙅🏾‍♀️',
                                    name: 'woman gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_gesturing_no_dark_skin_tone',
                                    alt: '🙅🏿‍♀️',
                                    name: 'woman gesturing NO',
                                    source: '/images/emojis/unicode/svg/1f645-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_gesturing_ok',
                            alt: '🙆',
                            name: 'person gesturing OK',
                            source: '/images/emojis/unicode/svg/1f646.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_gesturing_ok_light_skin_tone',
                                    alt: '🙆🏻',
                                    name: 'person gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_gesturing_ok_medium_light_skin_tone',
                                    alt: '🙆🏼',
                                    name: 'person gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_gesturing_ok_medium_skin_tone',
                                    alt: '🙆🏽',
                                    name: 'person gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_gesturing_ok_medium_dark_skin_tone',
                                    alt: '🙆🏾',
                                    name: 'person gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_gesturing_ok_dark_skin_tone',
                                    alt: '🙆🏿',
                                    name: 'person gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_gesturing_ok',
                            alt: '🙆‍♂️',
                            name: 'man gesturing OK',
                            source: '/images/emojis/unicode/svg/1f646-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_gesturing_ok_light_skin_tone',
                                    alt: '🙆🏻‍♂️',
                                    name: 'man gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_gesturing_ok_medium_light_skin_tone',
                                    alt: '🙆🏼‍♂️',
                                    name: 'man gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_gesturing_ok_medium_skin_tone',
                                    alt: '🙆🏽‍♂️',
                                    name: 'man gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_gesturing_ok_medium_dark_skin_tone',
                                    alt: '🙆🏾‍♂️',
                                    name: 'man gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_gesturing_ok_dark_skin_tone',
                                    alt: '🙆🏿‍♂️',
                                    name: 'man gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_gesturing_ok',
                            alt: '🙆‍♀️',
                            name: 'woman gesturing OK',
                            source: '/images/emojis/unicode/svg/1f646-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_gesturing_ok_light_skin_tone',
                                    alt: '🙆🏻‍♀️',
                                    name: 'woman gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_gesturing_ok_medium_light_skin_tone',
                                    alt: '🙆🏼‍♀️',
                                    name: 'woman gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_gesturing_ok_medium_skin_tone',
                                    alt: '🙆🏽‍♀️',
                                    name: 'woman gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_gesturing_ok_medium_dark_skin_tone',
                                    alt: '🙆🏾‍♀️',
                                    name: 'woman gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_gesturing_ok_dark_skin_tone',
                                    alt: '🙆🏿‍♀️',
                                    name: 'woman gesturing OK',
                                    source: '/images/emojis/unicode/svg/1f646-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_tipping_hand',
                            alt: '💁',
                            name: 'person tipping hand',
                            source: '/images/emojis/unicode/svg/1f481.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_tipping_hand_light_skin_tone',
                                    alt: '💁🏻',
                                    name: 'person tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_tipping_hand_medium_light_skin_tone',
                                    alt: '💁🏼',
                                    name: 'person tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_tipping_hand_medium_skin_tone',
                                    alt: '💁🏽',
                                    name: 'person tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_tipping_hand_medium_dark_skin_tone',
                                    alt: '💁🏾',
                                    name: 'person tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_tipping_hand_dark_skin_tone',
                                    alt: '💁🏿',
                                    name: 'person tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_tipping_hand',
                            alt: '💁‍♂️',
                            name: 'man tipping hand',
                            source: '/images/emojis/unicode/svg/1f481-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_tipping_hand_light_skin_tone',
                                    alt: '💁🏻‍♂️',
                                    name: 'man tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_tipping_hand_medium_light_skin_tone',
                                    alt: '💁🏼‍♂️',
                                    name: 'man tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_tipping_hand_medium_skin_tone',
                                    alt: '💁🏽‍♂️',
                                    name: 'man tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_tipping_hand_medium_dark_skin_tone',
                                    alt: '💁🏾‍♂️',
                                    name: 'man tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_tipping_hand_dark_skin_tone',
                                    alt: '💁🏿‍♂️',
                                    name: 'man tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_tipping_hand',
                            alt: '💁‍♀️',
                            name: 'woman tipping hand',
                            source: '/images/emojis/unicode/svg/1f481-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_tipping_hand_light_skin_tone',
                                    alt: '💁🏻‍♀️',
                                    name: 'woman tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_tipping_hand_medium_light_skin_tone',
                                    alt: '💁🏼‍♀️',
                                    name: 'woman tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_tipping_hand_medium_skin_tone',
                                    alt: '💁🏽‍♀️',
                                    name: 'woman tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_tipping_hand_medium_dark_skin_tone',
                                    alt: '💁🏾‍♀️',
                                    name: 'woman tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_tipping_hand_dark_skin_tone',
                                    alt: '💁🏿‍♀️',
                                    name: 'woman tipping hand',
                                    source: '/images/emojis/unicode/svg/1f481-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_raising_hand',
                            alt: '🙋',
                            name: 'person raising hand',
                            source: '/images/emojis/unicode/svg/1f64b.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_raising_hand_light_skin_tone',
                                    alt: '🙋🏻',
                                    name: 'person raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_raising_hand_medium_light_skin_tone',
                                    alt: '🙋🏼',
                                    name: 'person raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_raising_hand_medium_skin_tone',
                                    alt: '🙋🏽',
                                    name: 'person raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_raising_hand_medium_dark_skin_tone',
                                    alt: '🙋🏾',
                                    name: 'person raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_raising_hand_dark_skin_tone',
                                    alt: '🙋🏿',
                                    name: 'person raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_raising_hand',
                            alt: '🙋‍♂️',
                            name: 'man raising hand',
                            source: '/images/emojis/unicode/svg/1f64b-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_raising_hand_light_skin_tone',
                                    alt: '🙋🏻‍♂️',
                                    name: 'man raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_raising_hand_medium_light_skin_tone',
                                    alt: '🙋🏼‍♂️',
                                    name: 'man raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_raising_hand_medium_skin_tone',
                                    alt: '🙋🏽‍♂️',
                                    name: 'man raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_raising_hand_medium_dark_skin_tone',
                                    alt: '🙋🏾‍♂️',
                                    name: 'man raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_raising_hand_dark_skin_tone',
                                    alt: '🙋🏿‍♂️',
                                    name: 'man raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_raising_hand',
                            alt: '🙋‍♀️',
                            name: 'woman raising hand',
                            source: '/images/emojis/unicode/svg/1f64b-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_raising_hand_light_skin_tone',
                                    alt: '🙋🏻‍♀️',
                                    name: 'woman raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_raising_hand_medium_light_skin_tone',
                                    alt: '🙋🏼‍♀️',
                                    name: 'woman raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_raising_hand_medium_skin_tone',
                                    alt: '🙋🏽‍♀️',
                                    name: 'woman raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_raising_hand_medium_dark_skin_tone',
                                    alt: '🙋🏾‍♀️',
                                    name: 'woman raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_raising_hand_dark_skin_tone',
                                    alt: '🙋🏿‍♀️',
                                    name: 'woman raising hand',
                                    source: '/images/emojis/unicode/svg/1f64b-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'deaf_person',
                            alt: '🧏',
                            name: 'deaf person',
                            source: '/images/emojis/unicode/svg/1f9cf.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'deaf_person_light_skin_tone',
                                    alt: '🧏🏻',
                                    name: 'deaf person',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'deaf_person_medium_light_skin_tone',
                                    alt: '🧏🏼',
                                    name: 'deaf person',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'deaf_person_medium_skin_tone',
                                    alt: '🧏🏽',
                                    name: 'deaf person',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'deaf_person_medium_dark_skin_tone',
                                    alt: '🧏🏾',
                                    name: 'deaf person',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'deaf_person_dark_skin_tone',
                                    alt: '🧏🏿',
                                    name: 'deaf person',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'deaf_man',
                            alt: '🧏‍♂️',
                            name: 'deaf man',
                            source: '/images/emojis/unicode/svg/1f9cf-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'deaf_man_light_skin_tone',
                                    alt: '🧏🏻‍♂️',
                                    name: 'deaf man',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'deaf_man_medium_light_skin_tone',
                                    alt: '🧏🏼‍♂️',
                                    name: 'deaf man',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'deaf_man_medium_skin_tone',
                                    alt: '🧏🏽‍♂️',
                                    name: 'deaf man',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'deaf_man_medium_dark_skin_tone',
                                    alt: '🧏🏾‍♂️',
                                    name: 'deaf man',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'deaf_man_dark_skin_tone',
                                    alt: '🧏🏿‍♂️',
                                    name: 'deaf man',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'deaf_woman',
                            alt: '🧏‍♀️',
                            name: 'deaf woman',
                            source: '/images/emojis/unicode/svg/1f9cf-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'deaf_woman_light_skin_tone',
                                    alt: '🧏🏻‍♀️',
                                    name: 'deaf woman',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'deaf_woman_medium_light_skin_tone',
                                    alt: '🧏🏼‍♀️',
                                    name: 'deaf woman',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'deaf_woman_medium_skin_tone',
                                    alt: '🧏🏽‍♀️',
                                    name: 'deaf woman',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'deaf_woman_medium_dark_skin_tone',
                                    alt: '🧏🏾‍♀️',
                                    name: 'deaf woman',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'deaf_woman_dark_skin_tone',
                                    alt: '🧏🏿‍♀️',
                                    name: 'deaf woman',
                                    source: '/images/emojis/unicode/svg/1f9cf-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_bowing',
                            alt: '🙇',
                            name: 'person bowing',
                            source: '/images/emojis/unicode/svg/1f647.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'person_bowing_light_skin_tone',
                                    alt: '🙇🏻',
                                    name: 'person bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_bowing_medium_light_skin_tone',
                                    alt: '🙇🏼',
                                    name: 'person bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_bowing_medium_skin_tone',
                                    alt: '🙇🏽',
                                    name: 'person bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_bowing_medium_dark_skin_tone',
                                    alt: '🙇🏾',
                                    name: 'person bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_bowing_dark_skin_tone',
                                    alt: '🙇🏿',
                                    name: 'person bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_bowing',
                            alt: '🙇‍♂️',
                            name: 'man bowing',
                            source: '/images/emojis/unicode/svg/1f647-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_bowing_light_skin_tone',
                                    alt: '🙇🏻‍♂️',
                                    name: 'man bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_bowing_medium_light_skin_tone',
                                    alt: '🙇🏼‍♂️',
                                    name: 'man bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_bowing_medium_skin_tone',
                                    alt: '🙇🏽‍♂️',
                                    name: 'man bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_bowing_medium_dark_skin_tone',
                                    alt: '🙇🏾‍♂️',
                                    name: 'man bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_bowing_dark_skin_tone',
                                    alt: '🙇🏿‍♂️',
                                    name: 'man bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_bowing',
                            alt: '🙇‍♀️',
                            name: 'woman bowing',
                            source: '/images/emojis/unicode/svg/1f647-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_bowing_light_skin_tone',
                                    alt: '🙇🏻‍♀️',
                                    name: 'woman bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_bowing_medium_light_skin_tone',
                                    alt: '🙇🏼‍♀️',
                                    name: 'woman bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_bowing_medium_skin_tone',
                                    alt: '🙇🏽‍♀️',
                                    name: 'woman bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_bowing_medium_dark_skin_tone',
                                    alt: '🙇🏾‍♀️',
                                    name: 'woman bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_bowing_dark_skin_tone',
                                    alt: '🙇🏿‍♀️',
                                    name: 'woman bowing',
                                    source: '/images/emojis/unicode/svg/1f647-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_facepalming',
                            alt: '🤦',
                            name: 'person facepalming',
                            source: '/images/emojis/unicode/svg/1f926.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_facepalming_light_skin_tone',
                                    alt: '🤦🏻',
                                    name: 'person facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_facepalming_medium_light_skin_tone',
                                    alt: '🤦🏼',
                                    name: 'person facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_facepalming_medium_skin_tone',
                                    alt: '🤦🏽',
                                    name: 'person facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_facepalming_medium_dark_skin_tone',
                                    alt: '🤦🏾',
                                    name: 'person facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_facepalming_dark_skin_tone',
                                    alt: '🤦🏿',
                                    name: 'person facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_facepalming',
                            alt: '🤦‍♂️',
                            name: 'man facepalming',
                            source: '/images/emojis/unicode/svg/1f926-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_facepalming_light_skin_tone',
                                    alt: '🤦🏻‍♂️',
                                    name: 'man facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_facepalming_medium_light_skin_tone',
                                    alt: '🤦🏼‍♂️',
                                    name: 'man facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_facepalming_medium_skin_tone',
                                    alt: '🤦🏽‍♂️',
                                    name: 'man facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_facepalming_medium_dark_skin_tone',
                                    alt: '🤦🏾‍♂️',
                                    name: 'man facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_facepalming_dark_skin_tone',
                                    alt: '🤦🏿‍♂️',
                                    name: 'man facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_facepalming',
                            alt: '🤦‍♀️',
                            name: 'woman facepalming',
                            source: '/images/emojis/unicode/svg/1f926-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_facepalming_light_skin_tone',
                                    alt: '🤦🏻‍♀️',
                                    name: 'woman facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_facepalming_medium_light_skin_tone',
                                    alt: '🤦🏼‍♀️',
                                    name: 'woman facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_facepalming_medium_skin_tone',
                                    alt: '🤦🏽‍♀️',
                                    name: 'woman facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_facepalming_medium_dark_skin_tone',
                                    alt: '🤦🏾‍♀️',
                                    name: 'woman facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_facepalming_dark_skin_tone',
                                    alt: '🤦🏿‍♀️',
                                    name: 'woman facepalming',
                                    source: '/images/emojis/unicode/svg/1f926-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_shrugging',
                            alt: '🤷',
                            name: 'person shrugging',
                            source: '/images/emojis/unicode/svg/1f937.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_shrugging_light_skin_tone',
                                    alt: '🤷🏻',
                                    name: 'person shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_shrugging_medium_light_skin_tone',
                                    alt: '🤷🏼',
                                    name: 'person shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_shrugging_medium_skin_tone',
                                    alt: '🤷🏽',
                                    name: 'person shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_shrugging_medium_dark_skin_tone',
                                    alt: '🤷🏾',
                                    name: 'person shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_shrugging_dark_skin_tone',
                                    alt: '🤷🏿',
                                    name: 'person shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_shrugging',
                            alt: '🤷‍♂️',
                            name: 'man shrugging',
                            source: '/images/emojis/unicode/svg/1f937-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_shrugging_light_skin_tone',
                                    alt: '🤷🏻‍♂️',
                                    name: 'man shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_shrugging_medium_light_skin_tone',
                                    alt: '🤷🏼‍♂️',
                                    name: 'man shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_shrugging_medium_skin_tone',
                                    alt: '🤷🏽‍♂️',
                                    name: 'man shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_shrugging_medium_dark_skin_tone',
                                    alt: '🤷🏾‍♂️',
                                    name: 'man shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_shrugging_dark_skin_tone',
                                    alt: '🤷🏿‍♂️',
                                    name: 'man shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_shrugging',
                            alt: '🤷‍♀️',
                            name: 'woman shrugging',
                            source: '/images/emojis/unicode/svg/1f937-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_shrugging_light_skin_tone',
                                    alt: '🤷🏻‍♀️',
                                    name: 'woman shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_shrugging_medium_light_skin_tone',
                                    alt: '🤷🏼‍♀️',
                                    name: 'woman shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_shrugging_medium_skin_tone',
                                    alt: '🤷🏽‍♀️',
                                    name: 'woman shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_shrugging_medium_dark_skin_tone',
                                    alt: '🤷🏾‍♀️',
                                    name: 'woman shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_shrugging_dark_skin_tone',
                                    alt: '🤷🏿‍♀️',
                                    name: 'woman shrugging',
                                    source: '/images/emojis/unicode/svg/1f937-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'person-role',
                    emojis: [
                        {
                            emojiCode: 'health_worker',
                            alt: '🧑‍⚕️',
                            name: 'health worker',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-2695-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'health_worker_light_skin_tone',
                                    alt: '🧑🏻‍⚕️',
                                    name: 'health worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-2695-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'health_worker_medium_light_skin_tone',
                                    alt: '🧑🏼‍⚕️',
                                    name: 'health worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-2695-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'health_worker_medium_skin_tone',
                                    alt: '🧑🏽‍⚕️',
                                    name: 'health worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-2695-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'health_worker_medium_dark_skin_tone',
                                    alt: '🧑🏾‍⚕️',
                                    name: 'health worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-2695-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'health_worker_dark_skin_tone',
                                    alt: '🧑🏿‍⚕️',
                                    name: 'health worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-2695-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_health_worker',
                            alt: '👨‍⚕️',
                            name: 'man health worker',
                            source: '/images/emojis/unicode/svg/1f468-200d-2695-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_health_worker_light_skin_tone',
                                    alt: '👨🏻‍⚕️',
                                    name: 'man health worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-2695-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_health_worker_medium_light_skin_tone',
                                    alt: '👨🏼‍⚕️',
                                    name: 'man health worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-2695-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_health_worker_medium_skin_tone',
                                    alt: '👨🏽‍⚕️',
                                    name: 'man health worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-2695-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_health_worker_medium_dark_skin_tone',
                                    alt: '👨🏾‍⚕️',
                                    name: 'man health worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-2695-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_health_worker_dark_skin_tone',
                                    alt: '👨🏿‍⚕️',
                                    name: 'man health worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-2695-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_health_worker',
                            alt: '👩‍⚕️',
                            name: 'woman health worker',
                            source: '/images/emojis/unicode/svg/1f469-200d-2695-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_health_worker_light_skin_tone',
                                    alt: '👩🏻‍⚕️',
                                    name: 'woman health worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-2695-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_health_worker_medium_light_skin_tone',
                                    alt: '👩🏼‍⚕️',
                                    name: 'woman health worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-2695-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_health_worker_medium_skin_tone',
                                    alt: '👩🏽‍⚕️',
                                    name: 'woman health worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-2695-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_health_worker_medium_dark_skin_tone',
                                    alt: '👩🏾‍⚕️',
                                    name: 'woman health worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-2695-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_health_worker_dark_skin_tone',
                                    alt: '👩🏿‍⚕️',
                                    name: 'woman health worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-2695-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'student',
                            alt: '🧑‍🎓',
                            name: 'student',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f393.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'student_light_skin_tone',
                                    alt: '🧑🏻‍🎓',
                                    name: 'student',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f393.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'student_medium_light_skin_tone',
                                    alt: '🧑🏼‍🎓',
                                    name: 'student',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f393.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'student_medium_skin_tone',
                                    alt: '🧑🏽‍🎓',
                                    name: 'student',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f393.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'student_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🎓',
                                    name: 'student',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f393.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'student_dark_skin_tone',
                                    alt: '🧑🏿‍🎓',
                                    name: 'student',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f393.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_student',
                            alt: '👨‍🎓',
                            name: 'man student',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f393.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_student_light_skin_tone',
                                    alt: '👨🏻‍🎓',
                                    name: 'man student',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f393.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_student_medium_light_skin_tone',
                                    alt: '👨🏼‍🎓',
                                    name: 'man student',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f393.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_student_medium_skin_tone',
                                    alt: '👨🏽‍🎓',
                                    name: 'man student',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f393.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_student_medium_dark_skin_tone',
                                    alt: '👨🏾‍🎓',
                                    name: 'man student',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f393.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_student_dark_skin_tone',
                                    alt: '👨🏿‍🎓',
                                    name: 'man student',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f393.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_student',
                            alt: '👩‍🎓',
                            name: 'woman student',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f393.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_student_light_skin_tone',
                                    alt: '👩🏻‍🎓',
                                    name: 'woman student',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f393.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_student_medium_light_skin_tone',
                                    alt: '👩🏼‍🎓',
                                    name: 'woman student',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f393.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_student_medium_skin_tone',
                                    alt: '👩🏽‍🎓',
                                    name: 'woman student',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f393.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_student_medium_dark_skin_tone',
                                    alt: '👩🏾‍🎓',
                                    name: 'woman student',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f393.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_student_dark_skin_tone',
                                    alt: '👩🏿‍🎓',
                                    name: 'woman student',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f393.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'teacher',
                            alt: '🧑‍🏫',
                            name: 'teacher',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f3eb.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'teacher_light_skin_tone',
                                    alt: '🧑🏻‍🏫',
                                    name: 'teacher',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f3eb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'teacher_medium_light_skin_tone',
                                    alt: '🧑🏼‍🏫',
                                    name: 'teacher',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f3eb.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'teacher_medium_skin_tone',
                                    alt: '🧑🏽‍🏫',
                                    name: 'teacher',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f3eb.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'teacher_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🏫',
                                    name: 'teacher',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f3eb.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'teacher_dark_skin_tone',
                                    alt: '🧑🏿‍🏫',
                                    name: 'teacher',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f3eb.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_teacher',
                            alt: '👨‍🏫',
                            name: 'man teacher',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f3eb.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_teacher_light_skin_tone',
                                    alt: '👨🏻‍🏫',
                                    name: 'man teacher',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f3eb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_teacher_medium_light_skin_tone',
                                    alt: '👨🏼‍🏫',
                                    name: 'man teacher',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f3eb.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_teacher_medium_skin_tone',
                                    alt: '👨🏽‍🏫',
                                    name: 'man teacher',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f3eb.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_teacher_medium_dark_skin_tone',
                                    alt: '👨🏾‍🏫',
                                    name: 'man teacher',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f3eb.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_teacher_dark_skin_tone',
                                    alt: '👨🏿‍🏫',
                                    name: 'man teacher',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f3eb.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_teacher',
                            alt: '👩‍🏫',
                            name: 'woman teacher',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f3eb.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_teacher_light_skin_tone',
                                    alt: '👩🏻‍🏫',
                                    name: 'woman teacher',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f3eb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_teacher_medium_light_skin_tone',
                                    alt: '👩🏼‍🏫',
                                    name: 'woman teacher',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f3eb.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_teacher_medium_skin_tone',
                                    alt: '👩🏽‍🏫',
                                    name: 'woman teacher',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f3eb.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_teacher_medium_dark_skin_tone',
                                    alt: '👩🏾‍🏫',
                                    name: 'woman teacher',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f3eb.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_teacher_dark_skin_tone',
                                    alt: '👩🏿‍🏫',
                                    name: 'woman teacher',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f3eb.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'judge',
                            alt: '🧑‍⚖️',
                            name: 'judge',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-2696-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'judge_light_skin_tone',
                                    alt: '🧑🏻‍⚖️',
                                    name: 'judge',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-2696-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'judge_medium_light_skin_tone',
                                    alt: '🧑🏼‍⚖️',
                                    name: 'judge',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-2696-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'judge_medium_skin_tone',
                                    alt: '🧑🏽‍⚖️',
                                    name: 'judge',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-2696-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'judge_medium_dark_skin_tone',
                                    alt: '🧑🏾‍⚖️',
                                    name: 'judge',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-2696-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'judge_dark_skin_tone',
                                    alt: '🧑🏿‍⚖️',
                                    name: 'judge',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-2696-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_judge',
                            alt: '👨‍⚖️',
                            name: 'man judge',
                            source: '/images/emojis/unicode/svg/1f468-200d-2696-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_judge_light_skin_tone',
                                    alt: '👨🏻‍⚖️',
                                    name: 'man judge',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-2696-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_judge_medium_light_skin_tone',
                                    alt: '👨🏼‍⚖️',
                                    name: 'man judge',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-2696-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_judge_medium_skin_tone',
                                    alt: '👨🏽‍⚖️',
                                    name: 'man judge',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-2696-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_judge_medium_dark_skin_tone',
                                    alt: '👨🏾‍⚖️',
                                    name: 'man judge',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-2696-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_judge_dark_skin_tone',
                                    alt: '👨🏿‍⚖️',
                                    name: 'man judge',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-2696-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_judge',
                            alt: '👩‍⚖️',
                            name: 'woman judge',
                            source: '/images/emojis/unicode/svg/1f469-200d-2696-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_judge_light_skin_tone',
                                    alt: '👩🏻‍⚖️',
                                    name: 'woman judge',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-2696-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_judge_medium_light_skin_tone',
                                    alt: '👩🏼‍⚖️',
                                    name: 'woman judge',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-2696-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_judge_medium_skin_tone',
                                    alt: '👩🏽‍⚖️',
                                    name: 'woman judge',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-2696-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_judge_medium_dark_skin_tone',
                                    alt: '👩🏾‍⚖️',
                                    name: 'woman judge',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-2696-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_judge_dark_skin_tone',
                                    alt: '👩🏿‍⚖️',
                                    name: 'woman judge',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-2696-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'farmer',
                            alt: '🧑‍🌾',
                            name: 'farmer',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f33e.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'farmer_light_skin_tone',
                                    alt: '🧑🏻‍🌾',
                                    name: 'farmer',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f33e.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'farmer_medium_light_skin_tone',
                                    alt: '🧑🏼‍🌾',
                                    name: 'farmer',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f33e.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'farmer_medium_skin_tone',
                                    alt: '🧑🏽‍🌾',
                                    name: 'farmer',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f33e.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'farmer_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🌾',
                                    name: 'farmer',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f33e.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'farmer_dark_skin_tone',
                                    alt: '🧑🏿‍🌾',
                                    name: 'farmer',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f33e.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_farmer',
                            alt: '👨‍🌾',
                            name: 'man farmer',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f33e.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_farmer_light_skin_tone',
                                    alt: '👨🏻‍🌾',
                                    name: 'man farmer',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f33e.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_farmer_medium_light_skin_tone',
                                    alt: '👨🏼‍🌾',
                                    name: 'man farmer',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f33e.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_farmer_medium_skin_tone',
                                    alt: '👨🏽‍🌾',
                                    name: 'man farmer',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f33e.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_farmer_medium_dark_skin_tone',
                                    alt: '👨🏾‍🌾',
                                    name: 'man farmer',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f33e.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_farmer_dark_skin_tone',
                                    alt: '👨🏿‍🌾',
                                    name: 'man farmer',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f33e.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_farmer',
                            alt: '👩‍🌾',
                            name: 'woman farmer',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f33e.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_farmer_light_skin_tone',
                                    alt: '👩🏻‍🌾',
                                    name: 'woman farmer',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f33e.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_farmer_medium_light_skin_tone',
                                    alt: '👩🏼‍🌾',
                                    name: 'woman farmer',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f33e.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_farmer_medium_skin_tone',
                                    alt: '👩🏽‍🌾',
                                    name: 'woman farmer',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f33e.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_farmer_medium_dark_skin_tone',
                                    alt: '👩🏾‍🌾',
                                    name: 'woman farmer',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f33e.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_farmer_dark_skin_tone',
                                    alt: '👩🏿‍🌾',
                                    name: 'woman farmer',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f33e.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'cook',
                            alt: '🧑‍🍳',
                            name: 'cook',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f373.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'cook_light_skin_tone',
                                    alt: '🧑🏻‍🍳',
                                    name: 'cook',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f373.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'cook_medium_light_skin_tone',
                                    alt: '🧑🏼‍🍳',
                                    name: 'cook',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f373.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'cook_medium_skin_tone',
                                    alt: '🧑🏽‍🍳',
                                    name: 'cook',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f373.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'cook_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🍳',
                                    name: 'cook',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f373.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'cook_dark_skin_tone',
                                    alt: '🧑🏿‍🍳',
                                    name: 'cook',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f373.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_cook',
                            alt: '👨‍🍳',
                            name: 'man cook',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f373.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_cook_light_skin_tone',
                                    alt: '👨🏻‍🍳',
                                    name: 'man cook',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f373.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_cook_medium_light_skin_tone',
                                    alt: '👨🏼‍🍳',
                                    name: 'man cook',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f373.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_cook_medium_skin_tone',
                                    alt: '👨🏽‍🍳',
                                    name: 'man cook',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f373.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_cook_medium_dark_skin_tone',
                                    alt: '👨🏾‍🍳',
                                    name: 'man cook',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f373.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_cook_dark_skin_tone',
                                    alt: '👨🏿‍🍳',
                                    name: 'man cook',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f373.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_cook',
                            alt: '👩‍🍳',
                            name: 'woman cook',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f373.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_cook_light_skin_tone',
                                    alt: '👩🏻‍🍳',
                                    name: 'woman cook',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f373.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_cook_medium_light_skin_tone',
                                    alt: '👩🏼‍🍳',
                                    name: 'woman cook',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f373.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_cook_medium_skin_tone',
                                    alt: '👩🏽‍🍳',
                                    name: 'woman cook',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f373.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_cook_medium_dark_skin_tone',
                                    alt: '👩🏾‍🍳',
                                    name: 'woman cook',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f373.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_cook_dark_skin_tone',
                                    alt: '👩🏿‍🍳',
                                    name: 'woman cook',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f373.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'mechanic',
                            alt: '🧑‍🔧',
                            name: 'mechanic',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f527.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'mechanic_light_skin_tone',
                                    alt: '🧑🏻‍🔧',
                                    name: 'mechanic',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f527.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'mechanic_medium_light_skin_tone',
                                    alt: '🧑🏼‍🔧',
                                    name: 'mechanic',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f527.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mechanic_medium_skin_tone',
                                    alt: '🧑🏽‍🔧',
                                    name: 'mechanic',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f527.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mechanic_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🔧',
                                    name: 'mechanic',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f527.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mechanic_dark_skin_tone',
                                    alt: '🧑🏿‍🔧',
                                    name: 'mechanic',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f527.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_mechanic',
                            alt: '👨‍🔧',
                            name: 'man mechanic',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f527.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_mechanic_light_skin_tone',
                                    alt: '👨🏻‍🔧',
                                    name: 'man mechanic',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f527.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_mechanic_medium_light_skin_tone',
                                    alt: '👨🏼‍🔧',
                                    name: 'man mechanic',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f527.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_mechanic_medium_skin_tone',
                                    alt: '👨🏽‍🔧',
                                    name: 'man mechanic',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f527.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_mechanic_medium_dark_skin_tone',
                                    alt: '👨🏾‍🔧',
                                    name: 'man mechanic',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f527.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_mechanic_dark_skin_tone',
                                    alt: '👨🏿‍🔧',
                                    name: 'man mechanic',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f527.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_mechanic',
                            alt: '👩‍🔧',
                            name: 'woman mechanic',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f527.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_mechanic_light_skin_tone',
                                    alt: '👩🏻‍🔧',
                                    name: 'woman mechanic',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f527.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_mechanic_medium_light_skin_tone',
                                    alt: '👩🏼‍🔧',
                                    name: 'woman mechanic',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f527.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_mechanic_medium_skin_tone',
                                    alt: '👩🏽‍🔧',
                                    name: 'woman mechanic',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f527.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_mechanic_medium_dark_skin_tone',
                                    alt: '👩🏾‍🔧',
                                    name: 'woman mechanic',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f527.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_mechanic_dark_skin_tone',
                                    alt: '👩🏿‍🔧',
                                    name: 'woman mechanic',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f527.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'factory_worker',
                            alt: '🧑‍🏭',
                            name: 'factory worker',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f3ed.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'factory_worker_light_skin_tone',
                                    alt: '🧑🏻‍🏭',
                                    name: 'factory worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f3ed.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'factory_worker_medium_light_skin_tone',
                                    alt: '🧑🏼‍🏭',
                                    name: 'factory worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f3ed.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'factory_worker_medium_skin_tone',
                                    alt: '🧑🏽‍🏭',
                                    name: 'factory worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f3ed.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'factory_worker_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🏭',
                                    name: 'factory worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f3ed.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'factory_worker_dark_skin_tone',
                                    alt: '🧑🏿‍🏭',
                                    name: 'factory worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f3ed.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_factory_worker',
                            alt: '👨‍🏭',
                            name: 'man factory worker',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f3ed.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_factory_worker_light_skin_tone',
                                    alt: '👨🏻‍🏭',
                                    name: 'man factory worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f3ed.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_factory_worker_medium_light_skin_tone',
                                    alt: '👨🏼‍🏭',
                                    name: 'man factory worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f3ed.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_factory_worker_medium_skin_tone',
                                    alt: '👨🏽‍🏭',
                                    name: 'man factory worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f3ed.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_factory_worker_medium_dark_skin_tone',
                                    alt: '👨🏾‍🏭',
                                    name: 'man factory worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f3ed.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_factory_worker_dark_skin_tone',
                                    alt: '👨🏿‍🏭',
                                    name: 'man factory worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f3ed.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_factory_worker',
                            alt: '👩‍🏭',
                            name: 'woman factory worker',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f3ed.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_factory_worker_light_skin_tone',
                                    alt: '👩🏻‍🏭',
                                    name: 'woman factory worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f3ed.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_factory_worker_medium_light_skin_tone',
                                    alt: '👩🏼‍🏭',
                                    name: 'woman factory worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f3ed.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_factory_worker_medium_skin_tone',
                                    alt: '👩🏽‍🏭',
                                    name: 'woman factory worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f3ed.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_factory_worker_medium_dark_skin_tone',
                                    alt: '👩🏾‍🏭',
                                    name: 'woman factory worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f3ed.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_factory_worker_dark_skin_tone',
                                    alt: '👩🏿‍🏭',
                                    name: 'woman factory worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f3ed.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'office_worker',
                            alt: '🧑‍💼',
                            name: 'office worker',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f4bc.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'office_worker_light_skin_tone',
                                    alt: '🧑🏻‍💼',
                                    name: 'office worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f4bc.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'office_worker_medium_light_skin_tone',
                                    alt: '🧑🏼‍💼',
                                    name: 'office worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f4bc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'office_worker_medium_skin_tone',
                                    alt: '🧑🏽‍💼',
                                    name: 'office worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f4bc.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'office_worker_medium_dark_skin_tone',
                                    alt: '🧑🏾‍💼',
                                    name: 'office worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f4bc.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'office_worker_dark_skin_tone',
                                    alt: '🧑🏿‍💼',
                                    name: 'office worker',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f4bc.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_office_worker',
                            alt: '👨‍💼',
                            name: 'man office worker',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f4bc.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_office_worker_light_skin_tone',
                                    alt: '👨🏻‍💼',
                                    name: 'man office worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f4bc.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_office_worker_medium_light_skin_tone',
                                    alt: '👨🏼‍💼',
                                    name: 'man office worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f4bc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_office_worker_medium_skin_tone',
                                    alt: '👨🏽‍💼',
                                    name: 'man office worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f4bc.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_office_worker_medium_dark_skin_tone',
                                    alt: '👨🏾‍💼',
                                    name: 'man office worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f4bc.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_office_worker_dark_skin_tone',
                                    alt: '👨🏿‍💼',
                                    name: 'man office worker',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f4bc.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_office_worker',
                            alt: '👩‍💼',
                            name: 'woman office worker',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f4bc.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_office_worker_light_skin_tone',
                                    alt: '👩🏻‍💼',
                                    name: 'woman office worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f4bc.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_office_worker_medium_light_skin_tone',
                                    alt: '👩🏼‍💼',
                                    name: 'woman office worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f4bc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_office_worker_medium_skin_tone',
                                    alt: '👩🏽‍💼',
                                    name: 'woman office worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f4bc.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_office_worker_medium_dark_skin_tone',
                                    alt: '👩🏾‍💼',
                                    name: 'woman office worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f4bc.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_office_worker_dark_skin_tone',
                                    alt: '👩🏿‍💼',
                                    name: 'woman office worker',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f4bc.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'scientist',
                            alt: '🧑‍🔬',
                            name: 'scientist',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f52c.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'scientist_light_skin_tone',
                                    alt: '🧑🏻‍🔬',
                                    name: 'scientist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f52c.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'scientist_medium_light_skin_tone',
                                    alt: '🧑🏼‍🔬',
                                    name: 'scientist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f52c.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'scientist_medium_skin_tone',
                                    alt: '🧑🏽‍🔬',
                                    name: 'scientist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f52c.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'scientist_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🔬',
                                    name: 'scientist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f52c.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'scientist_dark_skin_tone',
                                    alt: '🧑🏿‍🔬',
                                    name: 'scientist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f52c.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_scientist',
                            alt: '👨‍🔬',
                            name: 'man scientist',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f52c.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_scientist_light_skin_tone',
                                    alt: '👨🏻‍🔬',
                                    name: 'man scientist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f52c.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_scientist_medium_light_skin_tone',
                                    alt: '👨🏼‍🔬',
                                    name: 'man scientist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f52c.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_scientist_medium_skin_tone',
                                    alt: '👨🏽‍🔬',
                                    name: 'man scientist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f52c.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_scientist_medium_dark_skin_tone',
                                    alt: '👨🏾‍🔬',
                                    name: 'man scientist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f52c.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_scientist_dark_skin_tone',
                                    alt: '👨🏿‍🔬',
                                    name: 'man scientist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f52c.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_scientist',
                            alt: '👩‍🔬',
                            name: 'woman scientist',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f52c.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_scientist_light_skin_tone',
                                    alt: '👩🏻‍🔬',
                                    name: 'woman scientist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f52c.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_scientist_medium_light_skin_tone',
                                    alt: '👩🏼‍🔬',
                                    name: 'woman scientist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f52c.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_scientist_medium_skin_tone',
                                    alt: '👩🏽‍🔬',
                                    name: 'woman scientist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f52c.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_scientist_medium_dark_skin_tone',
                                    alt: '👩🏾‍🔬',
                                    name: 'woman scientist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f52c.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_scientist_dark_skin_tone',
                                    alt: '👩🏿‍🔬',
                                    name: 'woman scientist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f52c.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'technologist',
                            alt: '🧑‍💻',
                            name: 'technologist',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f4bb.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'technologist_light_skin_tone',
                                    alt: '🧑🏻‍💻',
                                    name: 'technologist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f4bb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'technologist_medium_light_skin_tone',
                                    alt: '🧑🏼‍💻',
                                    name: 'technologist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f4bb.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'technologist_medium_skin_tone',
                                    alt: '🧑🏽‍💻',
                                    name: 'technologist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f4bb.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'technologist_medium_dark_skin_tone',
                                    alt: '🧑🏾‍💻',
                                    name: 'technologist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f4bb.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'technologist_dark_skin_tone',
                                    alt: '🧑🏿‍💻',
                                    name: 'technologist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f4bb.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_technologist',
                            alt: '👨‍💻',
                            name: 'man technologist',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f4bb.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_technologist_light_skin_tone',
                                    alt: '👨🏻‍💻',
                                    name: 'man technologist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f4bb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_technologist_medium_light_skin_tone',
                                    alt: '👨🏼‍💻',
                                    name: 'man technologist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f4bb.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_technologist_medium_skin_tone',
                                    alt: '👨🏽‍💻',
                                    name: 'man technologist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f4bb.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_technologist_medium_dark_skin_tone',
                                    alt: '👨🏾‍💻',
                                    name: 'man technologist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f4bb.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_technologist_dark_skin_tone',
                                    alt: '👨🏿‍💻',
                                    name: 'man technologist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f4bb.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_technologist',
                            alt: '👩‍💻',
                            name: 'woman technologist',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f4bb.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_technologist_light_skin_tone',
                                    alt: '👩🏻‍💻',
                                    name: 'woman technologist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f4bb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_technologist_medium_light_skin_tone',
                                    alt: '👩🏼‍💻',
                                    name: 'woman technologist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f4bb.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_technologist_medium_skin_tone',
                                    alt: '👩🏽‍💻',
                                    name: 'woman technologist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f4bb.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_technologist_medium_dark_skin_tone',
                                    alt: '👩🏾‍💻',
                                    name: 'woman technologist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f4bb.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_technologist_dark_skin_tone',
                                    alt: '👩🏿‍💻',
                                    name: 'woman technologist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f4bb.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'singer',
                            alt: '🧑‍🎤',
                            name: 'singer',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f3a4.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'singer_light_skin_tone',
                                    alt: '🧑🏻‍🎤',
                                    name: 'singer',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f3a4.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'singer_medium_light_skin_tone',
                                    alt: '🧑🏼‍🎤',
                                    name: 'singer',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f3a4.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'singer_medium_skin_tone',
                                    alt: '🧑🏽‍🎤',
                                    name: 'singer',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f3a4.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'singer_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🎤',
                                    name: 'singer',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f3a4.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'singer_dark_skin_tone',
                                    alt: '🧑🏿‍🎤',
                                    name: 'singer',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f3a4.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_singer',
                            alt: '👨‍🎤',
                            name: 'man singer',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f3a4.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_singer_light_skin_tone',
                                    alt: '👨🏻‍🎤',
                                    name: 'man singer',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f3a4.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_singer_medium_light_skin_tone',
                                    alt: '👨🏼‍🎤',
                                    name: 'man singer',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f3a4.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_singer_medium_skin_tone',
                                    alt: '👨🏽‍🎤',
                                    name: 'man singer',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f3a4.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_singer_medium_dark_skin_tone',
                                    alt: '👨🏾‍🎤',
                                    name: 'man singer',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f3a4.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_singer_dark_skin_tone',
                                    alt: '👨🏿‍🎤',
                                    name: 'man singer',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f3a4.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_singer',
                            alt: '👩‍🎤',
                            name: 'woman singer',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f3a4.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_singer_light_skin_tone',
                                    alt: '👩🏻‍🎤',
                                    name: 'woman singer',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f3a4.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_singer_medium_light_skin_tone',
                                    alt: '👩🏼‍🎤',
                                    name: 'woman singer',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f3a4.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_singer_medium_skin_tone',
                                    alt: '👩🏽‍🎤',
                                    name: 'woman singer',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f3a4.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_singer_medium_dark_skin_tone',
                                    alt: '👩🏾‍🎤',
                                    name: 'woman singer',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f3a4.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_singer_dark_skin_tone',
                                    alt: '👩🏿‍🎤',
                                    name: 'woman singer',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f3a4.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'artist',
                            alt: '🧑‍🎨',
                            name: 'artist',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f3a8.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'artist_light_skin_tone',
                                    alt: '🧑🏻‍🎨',
                                    name: 'artist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f3a8.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'artist_medium_light_skin_tone',
                                    alt: '🧑🏼‍🎨',
                                    name: 'artist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f3a8.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'artist_medium_skin_tone',
                                    alt: '🧑🏽‍🎨',
                                    name: 'artist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f3a8.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'artist_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🎨',
                                    name: 'artist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f3a8.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'artist_dark_skin_tone',
                                    alt: '🧑🏿‍🎨',
                                    name: 'artist',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f3a8.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_artist',
                            alt: '👨‍🎨',
                            name: 'man artist',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f3a8.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_artist_light_skin_tone',
                                    alt: '👨🏻‍🎨',
                                    name: 'man artist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f3a8.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_artist_medium_light_skin_tone',
                                    alt: '👨🏼‍🎨',
                                    name: 'man artist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f3a8.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_artist_medium_skin_tone',
                                    alt: '👨🏽‍🎨',
                                    name: 'man artist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f3a8.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_artist_medium_dark_skin_tone',
                                    alt: '👨🏾‍🎨',
                                    name: 'man artist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f3a8.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_artist_dark_skin_tone',
                                    alt: '👨🏿‍🎨',
                                    name: 'man artist',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f3a8.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_artist',
                            alt: '👩‍🎨',
                            name: 'woman artist',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f3a8.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_artist_light_skin_tone',
                                    alt: '👩🏻‍🎨',
                                    name: 'woman artist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f3a8.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_artist_medium_light_skin_tone',
                                    alt: '👩🏼‍🎨',
                                    name: 'woman artist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f3a8.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_artist_medium_skin_tone',
                                    alt: '👩🏽‍🎨',
                                    name: 'woman artist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f3a8.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_artist_medium_dark_skin_tone',
                                    alt: '👩🏾‍🎨',
                                    name: 'woman artist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f3a8.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_artist_dark_skin_tone',
                                    alt: '👩🏿‍🎨',
                                    name: 'woman artist',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f3a8.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'pilot',
                            alt: '🧑‍✈️',
                            name: 'pilot',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-2708-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'pilot_light_skin_tone',
                                    alt: '🧑🏻‍✈️',
                                    name: 'pilot',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-2708-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'pilot_medium_light_skin_tone',
                                    alt: '🧑🏼‍✈️',
                                    name: 'pilot',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-2708-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'pilot_medium_skin_tone',
                                    alt: '🧑🏽‍✈️',
                                    name: 'pilot',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-2708-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'pilot_medium_dark_skin_tone',
                                    alt: '🧑🏾‍✈️',
                                    name: 'pilot',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-2708-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'pilot_dark_skin_tone',
                                    alt: '🧑🏿‍✈️',
                                    name: 'pilot',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-2708-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_pilot',
                            alt: '👨‍✈️',
                            name: 'man pilot',
                            source: '/images/emojis/unicode/svg/1f468-200d-2708-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_pilot_light_skin_tone',
                                    alt: '👨🏻‍✈️',
                                    name: 'man pilot',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-2708-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_pilot_medium_light_skin_tone',
                                    alt: '👨🏼‍✈️',
                                    name: 'man pilot',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-2708-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_pilot_medium_skin_tone',
                                    alt: '👨🏽‍✈️',
                                    name: 'man pilot',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-2708-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_pilot_medium_dark_skin_tone',
                                    alt: '👨🏾‍✈️',
                                    name: 'man pilot',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-2708-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_pilot_dark_skin_tone',
                                    alt: '👨🏿‍✈️',
                                    name: 'man pilot',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-2708-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_pilot',
                            alt: '👩‍✈️',
                            name: 'woman pilot',
                            source: '/images/emojis/unicode/svg/1f469-200d-2708-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_pilot_light_skin_tone',
                                    alt: '👩🏻‍✈️',
                                    name: 'woman pilot',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-2708-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_pilot_medium_light_skin_tone',
                                    alt: '👩🏼‍✈️',
                                    name: 'woman pilot',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-2708-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_pilot_medium_skin_tone',
                                    alt: '👩🏽‍✈️',
                                    name: 'woman pilot',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-2708-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_pilot_medium_dark_skin_tone',
                                    alt: '👩🏾‍✈️',
                                    name: 'woman pilot',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-2708-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_pilot_dark_skin_tone',
                                    alt: '👩🏿‍✈️',
                                    name: 'woman pilot',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-2708-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'astronaut',
                            alt: '🧑‍🚀',
                            name: 'astronaut',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f680.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'astronaut_light_skin_tone',
                                    alt: '🧑🏻‍🚀',
                                    name: 'astronaut',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f680.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'astronaut_medium_light_skin_tone',
                                    alt: '🧑🏼‍🚀',
                                    name: 'astronaut',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f680.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'astronaut_medium_skin_tone',
                                    alt: '🧑🏽‍🚀',
                                    name: 'astronaut',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f680.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'astronaut_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🚀',
                                    name: 'astronaut',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f680.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'astronaut_dark_skin_tone',
                                    alt: '🧑🏿‍🚀',
                                    name: 'astronaut',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f680.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_astronaut',
                            alt: '👨‍🚀',
                            name: 'man astronaut',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f680.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_astronaut_light_skin_tone',
                                    alt: '👨🏻‍🚀',
                                    name: 'man astronaut',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f680.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_astronaut_medium_light_skin_tone',
                                    alt: '👨🏼‍🚀',
                                    name: 'man astronaut',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f680.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_astronaut_medium_skin_tone',
                                    alt: '👨🏽‍🚀',
                                    name: 'man astronaut',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f680.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_astronaut_medium_dark_skin_tone',
                                    alt: '👨🏾‍🚀',
                                    name: 'man astronaut',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f680.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_astronaut_dark_skin_tone',
                                    alt: '👨🏿‍🚀',
                                    name: 'man astronaut',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f680.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_astronaut',
                            alt: '👩‍🚀',
                            name: 'woman astronaut',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f680.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_astronaut_light_skin_tone',
                                    alt: '👩🏻‍🚀',
                                    name: 'woman astronaut',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f680.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_astronaut_medium_light_skin_tone',
                                    alt: '👩🏼‍🚀',
                                    name: 'woman astronaut',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f680.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_astronaut_medium_skin_tone',
                                    alt: '👩🏽‍🚀',
                                    name: 'woman astronaut',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f680.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_astronaut_medium_dark_skin_tone',
                                    alt: '👩🏾‍🚀',
                                    name: 'woman astronaut',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f680.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_astronaut_dark_skin_tone',
                                    alt: '👩🏿‍🚀',
                                    name: 'woman astronaut',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f680.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'firefighter',
                            alt: '🧑‍🚒',
                            name: 'firefighter',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f692.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'firefighter_light_skin_tone',
                                    alt: '🧑🏻‍🚒',
                                    name: 'firefighter',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f692.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'firefighter_medium_light_skin_tone',
                                    alt: '🧑🏼‍🚒',
                                    name: 'firefighter',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f692.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'firefighter_medium_skin_tone',
                                    alt: '🧑🏽‍🚒',
                                    name: 'firefighter',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f692.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'firefighter_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🚒',
                                    name: 'firefighter',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f692.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'firefighter_dark_skin_tone',
                                    alt: '🧑🏿‍🚒',
                                    name: 'firefighter',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f692.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_firefighter',
                            alt: '👨‍🚒',
                            name: 'man firefighter',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f692.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_firefighter_light_skin_tone',
                                    alt: '👨🏻‍🚒',
                                    name: 'man firefighter',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f692.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_firefighter_medium_light_skin_tone',
                                    alt: '👨🏼‍🚒',
                                    name: 'man firefighter',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f692.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_firefighter_medium_skin_tone',
                                    alt: '👨🏽‍🚒',
                                    name: 'man firefighter',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f692.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_firefighter_medium_dark_skin_tone',
                                    alt: '👨🏾‍🚒',
                                    name: 'man firefighter',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f692.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_firefighter_dark_skin_tone',
                                    alt: '👨🏿‍🚒',
                                    name: 'man firefighter',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f692.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_firefighter',
                            alt: '👩‍🚒',
                            name: 'woman firefighter',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f692.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_firefighter_light_skin_tone',
                                    alt: '👩🏻‍🚒',
                                    name: 'woman firefighter',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f692.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_firefighter_medium_light_skin_tone',
                                    alt: '👩🏼‍🚒',
                                    name: 'woman firefighter',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f692.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_firefighter_medium_skin_tone',
                                    alt: '👩🏽‍🚒',
                                    name: 'woman firefighter',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f692.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_firefighter_medium_dark_skin_tone',
                                    alt: '👩🏾‍🚒',
                                    name: 'woman firefighter',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f692.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_firefighter_dark_skin_tone',
                                    alt: '👩🏿‍🚒',
                                    name: 'woman firefighter',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f692.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'police_officer',
                            alt: '👮',
                            name: 'police officer',
                            source: '/images/emojis/unicode/svg/1f46e.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'police_officer_light_skin_tone',
                                    alt: '👮🏻',
                                    name: 'police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'police_officer_medium_light_skin_tone',
                                    alt: '👮🏼',
                                    name: 'police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'police_officer_medium_skin_tone',
                                    alt: '👮🏽',
                                    name: 'police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'police_officer_medium_dark_skin_tone',
                                    alt: '👮🏾',
                                    name: 'police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'police_officer_dark_skin_tone',
                                    alt: '👮🏿',
                                    name: 'police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_police_officer',
                            alt: '👮‍♂️',
                            name: 'man police officer',
                            source: '/images/emojis/unicode/svg/1f46e-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_police_officer_light_skin_tone',
                                    alt: '👮🏻‍♂️',
                                    name: 'man police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_police_officer_medium_light_skin_tone',
                                    alt: '👮🏼‍♂️',
                                    name: 'man police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_police_officer_medium_skin_tone',
                                    alt: '👮🏽‍♂️',
                                    name: 'man police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_police_officer_medium_dark_skin_tone',
                                    alt: '👮🏾‍♂️',
                                    name: 'man police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_police_officer_dark_skin_tone',
                                    alt: '👮🏿‍♂️',
                                    name: 'man police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_police_officer',
                            alt: '👮‍♀️',
                            name: 'woman police officer',
                            source: '/images/emojis/unicode/svg/1f46e-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_police_officer_light_skin_tone',
                                    alt: '👮🏻‍♀️',
                                    name: 'woman police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_police_officer_medium_light_skin_tone',
                                    alt: '👮🏼‍♀️',
                                    name: 'woman police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_police_officer_medium_skin_tone',
                                    alt: '👮🏽‍♀️',
                                    name: 'woman police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_police_officer_medium_dark_skin_tone',
                                    alt: '👮🏾‍♀️',
                                    name: 'woman police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_police_officer_dark_skin_tone',
                                    alt: '👮🏿‍♀️',
                                    name: 'woman police officer',
                                    source: '/images/emojis/unicode/svg/1f46e-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_detective',
                            alt: '🕵️‍♂️',
                            name: 'man detective',
                            source: '/images/emojis/unicode/svg/1f575-fe0f-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_detective_light_skin_tone',
                                    alt: '🕵🏻‍♂️',
                                    name: 'man detective',
                                    source: '/images/emojis/unicode/svg/1f575-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_detective_medium_light_skin_tone',
                                    alt: '🕵🏼‍♂️',
                                    name: 'man detective',
                                    source: '/images/emojis/unicode/svg/1f575-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_detective_medium_skin_tone',
                                    alt: '🕵🏽‍♂️',
                                    name: 'man detective',
                                    source: '/images/emojis/unicode/svg/1f575-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_detective_medium_dark_skin_tone',
                                    alt: '🕵🏾‍♂️',
                                    name: 'man detective',
                                    source: '/images/emojis/unicode/svg/1f575-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_detective_dark_skin_tone',
                                    alt: '🕵🏿‍♂️',
                                    name: 'man detective',
                                    source: '/images/emojis/unicode/svg/1f575-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_detective',
                            alt: '🕵️‍♀️',
                            name: 'woman detective',
                            source: '/images/emojis/unicode/svg/1f575-fe0f-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_detective_light_skin_tone',
                                    alt: '🕵🏻‍♀️',
                                    name: 'woman detective',
                                    source: '/images/emojis/unicode/svg/1f575-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_detective_medium_light_skin_tone',
                                    alt: '🕵🏼‍♀️',
                                    name: 'woman detective',
                                    source: '/images/emojis/unicode/svg/1f575-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_detective_medium_skin_tone',
                                    alt: '🕵🏽‍♀️',
                                    name: 'woman detective',
                                    source: '/images/emojis/unicode/svg/1f575-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_detective_medium_dark_skin_tone',
                                    alt: '🕵🏾‍♀️',
                                    name: 'woman detective',
                                    source: '/images/emojis/unicode/svg/1f575-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_detective_dark_skin_tone',
                                    alt: '🕵🏿‍♀️',
                                    name: 'woman detective',
                                    source: '/images/emojis/unicode/svg/1f575-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'guard',
                            alt: '💂',
                            name: 'guard',
                            source: '/images/emojis/unicode/svg/1f482.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'guard_light_skin_tone',
                                    alt: '💂🏻',
                                    name: 'guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'guard_medium_light_skin_tone',
                                    alt: '💂🏼',
                                    name: 'guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'guard_medium_skin_tone',
                                    alt: '💂🏽',
                                    name: 'guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'guard_medium_dark_skin_tone',
                                    alt: '💂🏾',
                                    name: 'guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'guard_dark_skin_tone',
                                    alt: '💂🏿',
                                    name: 'guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_guard',
                            alt: '💂‍♂️',
                            name: 'man guard',
                            source: '/images/emojis/unicode/svg/1f482-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_guard_light_skin_tone',
                                    alt: '💂🏻‍♂️',
                                    name: 'man guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_guard_medium_light_skin_tone',
                                    alt: '💂🏼‍♂️',
                                    name: 'man guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_guard_medium_skin_tone',
                                    alt: '💂🏽‍♂️',
                                    name: 'man guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_guard_medium_dark_skin_tone',
                                    alt: '💂🏾‍♂️',
                                    name: 'man guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_guard_dark_skin_tone',
                                    alt: '💂🏿‍♂️',
                                    name: 'man guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_guard',
                            alt: '💂‍♀️',
                            name: 'woman guard',
                            source: '/images/emojis/unicode/svg/1f482-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_guard_light_skin_tone',
                                    alt: '💂🏻‍♀️',
                                    name: 'woman guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_guard_medium_light_skin_tone',
                                    alt: '💂🏼‍♀️',
                                    name: 'woman guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_guard_medium_skin_tone',
                                    alt: '💂🏽‍♀️',
                                    name: 'woman guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_guard_medium_dark_skin_tone',
                                    alt: '💂🏾‍♀️',
                                    name: 'woman guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_guard_dark_skin_tone',
                                    alt: '💂🏿‍♀️',
                                    name: 'woman guard',
                                    source: '/images/emojis/unicode/svg/1f482-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'ninja',
                            alt: '🥷',
                            name: 'ninja',
                            source: '/images/emojis/unicode/svg/1f977.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'ninja_light_skin_tone',
                                    alt: '🥷🏻',
                                    name: 'ninja',
                                    source: '/images/emojis/unicode/svg/1f977-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'ninja_medium_light_skin_tone',
                                    alt: '🥷🏼',
                                    name: 'ninja',
                                    source: '/images/emojis/unicode/svg/1f977-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'ninja_medium_skin_tone',
                                    alt: '🥷🏽',
                                    name: 'ninja',
                                    source: '/images/emojis/unicode/svg/1f977-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'ninja_medium_dark_skin_tone',
                                    alt: '🥷🏾',
                                    name: 'ninja',
                                    source: '/images/emojis/unicode/svg/1f977-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'ninja_dark_skin_tone',
                                    alt: '🥷🏿',
                                    name: 'ninja',
                                    source: '/images/emojis/unicode/svg/1f977-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'construction_worker',
                            alt: '👷',
                            name: 'construction worker',
                            source: '/images/emojis/unicode/svg/1f477.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'construction_worker_light_skin_tone',
                                    alt: '👷🏻',
                                    name: 'construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'construction_worker_medium_light_skin_tone',
                                    alt: '👷🏼',
                                    name: 'construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'construction_worker_medium_skin_tone',
                                    alt: '👷🏽',
                                    name: 'construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'construction_worker_medium_dark_skin_tone',
                                    alt: '👷🏾',
                                    name: 'construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'construction_worker_dark_skin_tone',
                                    alt: '👷🏿',
                                    name: 'construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_construction_worker',
                            alt: '👷‍♂️',
                            name: 'man construction worker',
                            source: '/images/emojis/unicode/svg/1f477-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_construction_worker_light_skin_tone',
                                    alt: '👷🏻‍♂️',
                                    name: 'man construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_construction_worker_medium_light_skin_tone',
                                    alt: '👷🏼‍♂️',
                                    name: 'man construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_construction_worker_medium_skin_tone',
                                    alt: '👷🏽‍♂️',
                                    name: 'man construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_construction_worker_medium_dark_skin_tone',
                                    alt: '👷🏾‍♂️',
                                    name: 'man construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_construction_worker_dark_skin_tone',
                                    alt: '👷🏿‍♂️',
                                    name: 'man construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_construction_worker',
                            alt: '👷‍♀️',
                            name: 'woman construction worker',
                            source: '/images/emojis/unicode/svg/1f477-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_construction_worker_light_skin_tone',
                                    alt: '👷🏻‍♀️',
                                    name: 'woman construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_construction_worker_medium_light_skin_tone',
                                    alt: '👷🏼‍♀️',
                                    name: 'woman construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_construction_worker_medium_skin_tone',
                                    alt: '👷🏽‍♀️',
                                    name: 'woman construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_construction_worker_medium_dark_skin_tone',
                                    alt: '👷🏾‍♀️',
                                    name: 'woman construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_construction_worker_dark_skin_tone',
                                    alt: '👷🏿‍♀️',
                                    name: 'woman construction worker',
                                    source: '/images/emojis/unicode/svg/1f477-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'prince',
                            alt: '🤴',
                            name: 'prince',
                            source: '/images/emojis/unicode/svg/1f934.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'prince_light_skin_tone',
                                    alt: '🤴🏻',
                                    name: 'prince',
                                    source: '/images/emojis/unicode/svg/1f934-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'prince_medium_light_skin_tone',
                                    alt: '🤴🏼',
                                    name: 'prince',
                                    source: '/images/emojis/unicode/svg/1f934-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'prince_medium_skin_tone',
                                    alt: '🤴🏽',
                                    name: 'prince',
                                    source: '/images/emojis/unicode/svg/1f934-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'prince_medium_dark_skin_tone',
                                    alt: '🤴🏾',
                                    name: 'prince',
                                    source: '/images/emojis/unicode/svg/1f934-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'prince_dark_skin_tone',
                                    alt: '🤴🏿',
                                    name: 'prince',
                                    source: '/images/emojis/unicode/svg/1f934-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'princess',
                            alt: '👸',
                            name: 'princess',
                            source: '/images/emojis/unicode/svg/1f478.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'princess_light_skin_tone',
                                    alt: '👸🏻',
                                    name: 'princess',
                                    source: '/images/emojis/unicode/svg/1f478-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'princess_medium_light_skin_tone',
                                    alt: '👸🏼',
                                    name: 'princess',
                                    source: '/images/emojis/unicode/svg/1f478-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'princess_medium_skin_tone',
                                    alt: '👸🏽',
                                    name: 'princess',
                                    source: '/images/emojis/unicode/svg/1f478-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'princess_medium_dark_skin_tone',
                                    alt: '👸🏾',
                                    name: 'princess',
                                    source: '/images/emojis/unicode/svg/1f478-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'princess_dark_skin_tone',
                                    alt: '👸🏿',
                                    name: 'princess',
                                    source: '/images/emojis/unicode/svg/1f478-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_wearing_turban',
                            alt: '👳',
                            name: 'person wearing turban',
                            source: '/images/emojis/unicode/svg/1f473.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_wearing_turban_light_skin_tone',
                                    alt: '👳🏻',
                                    name: 'person wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_wearing_turban_medium_light_skin_tone',
                                    alt: '👳🏼',
                                    name: 'person wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_wearing_turban_medium_skin_tone',
                                    alt: '👳🏽',
                                    name: 'person wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_wearing_turban_medium_dark_skin_tone',
                                    alt: '👳🏾',
                                    name: 'person wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_wearing_turban_dark_skin_tone',
                                    alt: '👳🏿',
                                    name: 'person wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_wearing_turban',
                            alt: '👳‍♂️',
                            name: 'man wearing turban',
                            source: '/images/emojis/unicode/svg/1f473-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_wearing_turban_light_skin_tone',
                                    alt: '👳🏻‍♂️',
                                    name: 'man wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_wearing_turban_medium_light_skin_tone',
                                    alt: '👳🏼‍♂️',
                                    name: 'man wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_wearing_turban_medium_skin_tone',
                                    alt: '👳🏽‍♂️',
                                    name: 'man wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_wearing_turban_medium_dark_skin_tone',
                                    alt: '👳🏾‍♂️',
                                    name: 'man wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_wearing_turban_dark_skin_tone',
                                    alt: '👳🏿‍♂️',
                                    name: 'man wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_wearing_turban',
                            alt: '👳‍♀️',
                            name: 'woman wearing turban',
                            source: '/images/emojis/unicode/svg/1f473-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_wearing_turban_light_skin_tone',
                                    alt: '👳🏻‍♀️',
                                    name: 'woman wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_wearing_turban_medium_light_skin_tone',
                                    alt: '👳🏼‍♀️',
                                    name: 'woman wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_wearing_turban_medium_skin_tone',
                                    alt: '👳🏽‍♀️',
                                    name: 'woman wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_wearing_turban_medium_dark_skin_tone',
                                    alt: '👳🏾‍♀️',
                                    name: 'woman wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_wearing_turban_dark_skin_tone',
                                    alt: '👳🏿‍♀️',
                                    name: 'woman wearing turban',
                                    source: '/images/emojis/unicode/svg/1f473-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_with_skullcap',
                            alt: '👲',
                            name: 'person with skullcap',
                            source: '/images/emojis/unicode/svg/1f472.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_with_skullcap_light_skin_tone',
                                    alt: '👲🏻',
                                    name: 'person with skullcap',
                                    source: '/images/emojis/unicode/svg/1f472-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_with_skullcap_medium_light_skin_tone',
                                    alt: '👲🏼',
                                    name: 'person with skullcap',
                                    source: '/images/emojis/unicode/svg/1f472-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_with_skullcap_medium_skin_tone',
                                    alt: '👲🏽',
                                    name: 'person with skullcap',
                                    source: '/images/emojis/unicode/svg/1f472-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_with_skullcap_medium_dark_skin_tone',
                                    alt: '👲🏾',
                                    name: 'person with skullcap',
                                    source: '/images/emojis/unicode/svg/1f472-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_with_skullcap_dark_skin_tone',
                                    alt: '👲🏿',
                                    name: 'person with skullcap',
                                    source: '/images/emojis/unicode/svg/1f472-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_with_headscarf',
                            alt: '🧕',
                            name: 'woman with headscarf',
                            source: '/images/emojis/unicode/svg/1f9d5.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_with_headscarf_light_skin_tone',
                                    alt: '🧕🏻',
                                    name: 'woman with headscarf',
                                    source: '/images/emojis/unicode/svg/1f9d5-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_with_headscarf_medium_light_skin_tone',
                                    alt: '🧕🏼',
                                    name: 'woman with headscarf',
                                    source: '/images/emojis/unicode/svg/1f9d5-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_with_headscarf_medium_skin_tone',
                                    alt: '🧕🏽',
                                    name: 'woman with headscarf',
                                    source: '/images/emojis/unicode/svg/1f9d5-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_with_headscarf_medium_dark_skin_tone',
                                    alt: '🧕🏾',
                                    name: 'woman with headscarf',
                                    source: '/images/emojis/unicode/svg/1f9d5-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_with_headscarf_dark_skin_tone',
                                    alt: '🧕🏿',
                                    name: 'woman with headscarf',
                                    source: '/images/emojis/unicode/svg/1f9d5-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_in_tuxedo',
                            alt: '🤵',
                            name: 'person in tuxedo',
                            source: '/images/emojis/unicode/svg/1f935.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_in_tuxedo_light_skin_tone',
                                    alt: '🤵🏻',
                                    name: 'person in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_tuxedo_medium_light_skin_tone',
                                    alt: '🤵🏼',
                                    name: 'person in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_tuxedo_medium_skin_tone',
                                    alt: '🤵🏽',
                                    name: 'person in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_tuxedo_medium_dark_skin_tone',
                                    alt: '🤵🏾',
                                    name: 'person in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_tuxedo_dark_skin_tone',
                                    alt: '🤵🏿',
                                    name: 'person in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_in_tuxedo',
                            alt: '🤵‍♂️',
                            name: 'man in tuxedo',
                            source: '/images/emojis/unicode/svg/1f935-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_in_tuxedo_light_skin_tone',
                                    alt: '🤵🏻‍♂️',
                                    name: 'man in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_tuxedo_medium_light_skin_tone',
                                    alt: '🤵🏼‍♂️',
                                    name: 'man in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_in_tuxedo_medium_skin_tone',
                                    alt: '🤵🏽‍♂️',
                                    name: 'man in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_tuxedo_medium_dark_skin_tone',
                                    alt: '🤵🏾‍♂️',
                                    name: 'man in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_in_tuxedo_dark_skin_tone',
                                    alt: '🤵🏿‍♂️',
                                    name: 'man in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_in_tuxedo',
                            alt: '🤵‍♀️',
                            name: 'woman in tuxedo',
                            source: '/images/emojis/unicode/svg/1f935-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_in_tuxedo_light_skin_tone',
                                    alt: '🤵🏻‍♀️',
                                    name: 'woman in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_tuxedo_medium_light_skin_tone',
                                    alt: '🤵🏼‍♀️',
                                    name: 'woman in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_tuxedo_medium_skin_tone',
                                    alt: '🤵🏽‍♀️',
                                    name: 'woman in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_tuxedo_medium_dark_skin_tone',
                                    alt: '🤵🏾‍♀️',
                                    name: 'woman in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_in_tuxedo_dark_skin_tone',
                                    alt: '🤵🏿‍♀️',
                                    name: 'woman in tuxedo',
                                    source: '/images/emojis/unicode/svg/1f935-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_with_veil',
                            alt: '👰',
                            name: 'person with veil',
                            source: '/images/emojis/unicode/svg/1f470.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_with_veil_light_skin_tone',
                                    alt: '👰🏻',
                                    name: 'person with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_with_veil_medium_light_skin_tone',
                                    alt: '👰🏼',
                                    name: 'person with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_with_veil_medium_skin_tone',
                                    alt: '👰🏽',
                                    name: 'person with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_with_veil_medium_dark_skin_tone',
                                    alt: '👰🏾',
                                    name: 'person with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_with_veil_dark_skin_tone',
                                    alt: '👰🏿',
                                    name: 'person with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_with_veil',
                            alt: '👰‍♂️',
                            name: 'man with veil',
                            source: '/images/emojis/unicode/svg/1f470-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_with_veil_light_skin_tone',
                                    alt: '👰🏻‍♂️',
                                    name: 'man with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_with_veil_medium_light_skin_tone',
                                    alt: '👰🏼‍♂️',
                                    name: 'man with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_with_veil_medium_skin_tone',
                                    alt: '👰🏽‍♂️',
                                    name: 'man with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_with_veil_medium_dark_skin_tone',
                                    alt: '👰🏾‍♂️',
                                    name: 'man with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_with_veil_dark_skin_tone',
                                    alt: '👰🏿‍♂️',
                                    name: 'man with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_with_veil',
                            alt: '👰‍♀️',
                            name: 'woman with veil',
                            source: '/images/emojis/unicode/svg/1f470-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_with_veil_light_skin_tone',
                                    alt: '👰🏻‍♀️',
                                    name: 'woman with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_with_veil_medium_light_skin_tone',
                                    alt: '👰🏼‍♀️',
                                    name: 'woman with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_with_veil_medium_skin_tone',
                                    alt: '👰🏽‍♀️',
                                    name: 'woman with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_with_veil_medium_dark_skin_tone',
                                    alt: '👰🏾‍♀️',
                                    name: 'woman with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_with_veil_dark_skin_tone',
                                    alt: '👰🏿‍♀️',
                                    name: 'woman with veil',
                                    source: '/images/emojis/unicode/svg/1f470-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'pregnant_woman',
                            alt: '🤰',
                            name: 'pregnant woman',
                            source: '/images/emojis/unicode/svg/1f930.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'pregnant_woman_light_skin_tone',
                                    alt: '🤰🏻',
                                    name: 'pregnant woman',
                                    source: '/images/emojis/unicode/svg/1f930-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'pregnant_woman_medium_light_skin_tone',
                                    alt: '🤰🏼',
                                    name: 'pregnant woman',
                                    source: '/images/emojis/unicode/svg/1f930-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'pregnant_woman_medium_skin_tone',
                                    alt: '🤰🏽',
                                    name: 'pregnant woman',
                                    source: '/images/emojis/unicode/svg/1f930-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'pregnant_woman_medium_dark_skin_tone',
                                    alt: '🤰🏾',
                                    name: 'pregnant woman',
                                    source: '/images/emojis/unicode/svg/1f930-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'pregnant_woman_dark_skin_tone',
                                    alt: '🤰🏿',
                                    name: 'pregnant woman',
                                    source: '/images/emojis/unicode/svg/1f930-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'breast_feeding',
                            alt: '🤱',
                            name: 'breast-feeding',
                            source: '/images/emojis/unicode/svg/1f931.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'breast_feeding_light_skin_tone',
                                    alt: '🤱🏻',
                                    name: 'breast-feeding',
                                    source: '/images/emojis/unicode/svg/1f931-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'breast_feeding_medium_light_skin_tone',
                                    alt: '🤱🏼',
                                    name: 'breast-feeding',
                                    source: '/images/emojis/unicode/svg/1f931-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'breast_feeding_medium_skin_tone',
                                    alt: '🤱🏽',
                                    name: 'breast-feeding',
                                    source: '/images/emojis/unicode/svg/1f931-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'breast_feeding_medium_dark_skin_tone',
                                    alt: '🤱🏾',
                                    name: 'breast-feeding',
                                    source: '/images/emojis/unicode/svg/1f931-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'breast_feeding_dark_skin_tone',
                                    alt: '🤱🏿',
                                    name: 'breast-feeding',
                                    source: '/images/emojis/unicode/svg/1f931-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_feeding_baby',
                            alt: '👩‍🍼',
                            name: 'woman feeding baby',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f37c.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_feeding_baby_light_skin_tone',
                                    alt: '👩🏻‍🍼',
                                    name: 'woman feeding baby',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f37c.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_feeding_baby_medium_light_skin_tone',
                                    alt: '👩🏼‍🍼',
                                    name: 'woman feeding baby',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f37c.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_feeding_baby_medium_skin_tone',
                                    alt: '👩🏽‍🍼',
                                    name: 'woman feeding baby',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f37c.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_feeding_baby_medium_dark_skin_tone',
                                    alt: '👩🏾‍🍼',
                                    name: 'woman feeding baby',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f37c.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_feeding_baby_dark_skin_tone',
                                    alt: '👩🏿‍🍼',
                                    name: 'woman feeding baby',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f37c.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_feeding_baby',
                            alt: '👨‍🍼',
                            name: 'man feeding baby',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f37c.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_feeding_baby_light_skin_tone',
                                    alt: '👨🏻‍🍼',
                                    name: 'man feeding baby',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f37c.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_feeding_baby_medium_light_skin_tone',
                                    alt: '👨🏼‍🍼',
                                    name: 'man feeding baby',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f37c.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_feeding_baby_medium_skin_tone',
                                    alt: '👨🏽‍🍼',
                                    name: 'man feeding baby',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f37c.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_feeding_baby_medium_dark_skin_tone',
                                    alt: '👨🏾‍🍼',
                                    name: 'man feeding baby',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f37c.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_feeding_baby_dark_skin_tone',
                                    alt: '👨🏿‍🍼',
                                    name: 'man feeding baby',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f37c.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_feeding_baby',
                            alt: '🧑‍🍼',
                            name: 'person feeding baby',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f37c.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_feeding_baby_light_skin_tone',
                                    alt: '🧑🏻‍🍼',
                                    name: 'person feeding baby',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f37c.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_feeding_baby_medium_light_skin_tone',
                                    alt: '🧑🏼‍🍼',
                                    name: 'person feeding baby',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f37c.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_feeding_baby_medium_skin_tone',
                                    alt: '🧑🏽‍🍼',
                                    name: 'person feeding baby',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f37c.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_feeding_baby_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🍼',
                                    name: 'person feeding baby',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f37c.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_feeding_baby_dark_skin_tone',
                                    alt: '🧑🏿‍🍼',
                                    name: 'person feeding baby',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f37c.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'person-fantasy',
                    emojis: [
                        {
                            emojiCode: 'baby_angel',
                            alt: '👼',
                            name: 'baby angel',
                            source: '/images/emojis/unicode/svg/1f47c.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'baby_angel_light_skin_tone',
                                    alt: '👼🏻',
                                    name: 'baby angel',
                                    source: '/images/emojis/unicode/svg/1f47c-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'baby_angel_medium_light_skin_tone',
                                    alt: '👼🏼',
                                    name: 'baby angel',
                                    source: '/images/emojis/unicode/svg/1f47c-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'baby_angel_medium_skin_tone',
                                    alt: '👼🏽',
                                    name: 'baby angel',
                                    source: '/images/emojis/unicode/svg/1f47c-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'baby_angel_medium_dark_skin_tone',
                                    alt: '👼🏾',
                                    name: 'baby angel',
                                    source: '/images/emojis/unicode/svg/1f47c-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'baby_angel_dark_skin_tone',
                                    alt: '👼🏿',
                                    name: 'baby angel',
                                    source: '/images/emojis/unicode/svg/1f47c-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'santa_claus',
                            alt: '🎅',
                            name: 'Santa Claus',
                            source: '/images/emojis/unicode/svg/1f385.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'santa_claus_light_skin_tone',
                                    alt: '🎅🏻',
                                    name: 'Santa Claus',
                                    source: '/images/emojis/unicode/svg/1f385-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'santa_claus_medium_light_skin_tone',
                                    alt: '🎅🏼',
                                    name: 'Santa Claus',
                                    source: '/images/emojis/unicode/svg/1f385-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'santa_claus_medium_skin_tone',
                                    alt: '🎅🏽',
                                    name: 'Santa Claus',
                                    source: '/images/emojis/unicode/svg/1f385-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'santa_claus_medium_dark_skin_tone',
                                    alt: '🎅🏾',
                                    name: 'Santa Claus',
                                    source: '/images/emojis/unicode/svg/1f385-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'santa_claus_dark_skin_tone',
                                    alt: '🎅🏿',
                                    name: 'Santa Claus',
                                    source: '/images/emojis/unicode/svg/1f385-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'mrs_claus',
                            alt: '🤶',
                            name: 'Mrs. Claus',
                            source: '/images/emojis/unicode/svg/1f936.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'mrs_claus_light_skin_tone',
                                    alt: '🤶🏻',
                                    name: 'Mrs. Claus',
                                    source: '/images/emojis/unicode/svg/1f936-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'mrs_claus_medium_light_skin_tone',
                                    alt: '🤶🏼',
                                    name: 'Mrs. Claus',
                                    source: '/images/emojis/unicode/svg/1f936-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mrs_claus_medium_skin_tone',
                                    alt: '🤶🏽',
                                    name: 'Mrs. Claus',
                                    source: '/images/emojis/unicode/svg/1f936-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'mrs_claus_medium_dark_skin_tone',
                                    alt: '🤶🏾',
                                    name: 'Mrs. Claus',
                                    source: '/images/emojis/unicode/svg/1f936-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mrs_claus_dark_skin_tone',
                                    alt: '🤶🏿',
                                    name: 'Mrs. Claus',
                                    source: '/images/emojis/unicode/svg/1f936-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'mx_claus',
                            alt: '🧑‍🎄',
                            name: 'mx claus',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f384.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'mx_claus_light_skin_tone',
                                    alt: '🧑🏻‍🎄',
                                    name: 'mx claus',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f384.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'mx_claus_medium_light_skin_tone',
                                    alt: '🧑🏼‍🎄',
                                    name: 'mx claus',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f384.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mx_claus_medium_skin_tone',
                                    alt: '🧑🏽‍🎄',
                                    name: 'mx claus',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f384.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mx_claus_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🎄',
                                    name: 'mx claus',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f384.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mx_claus_dark_skin_tone',
                                    alt: '🧑🏿‍🎄',
                                    name: 'mx claus',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f384.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'superhero',
                            alt: '🦸',
                            name: 'superhero',
                            source: '/images/emojis/unicode/svg/1f9b8.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'superhero_light_skin_tone',
                                    alt: '🦸🏻',
                                    name: 'superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'superhero_medium_light_skin_tone',
                                    alt: '🦸🏼',
                                    name: 'superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'superhero_medium_skin_tone',
                                    alt: '🦸🏽',
                                    name: 'superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'superhero_medium_dark_skin_tone',
                                    alt: '🦸🏾',
                                    name: 'superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'superhero_dark_skin_tone',
                                    alt: '🦸🏿',
                                    name: 'superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_superhero',
                            alt: '🦸‍♂️',
                            name: 'man superhero',
                            source: '/images/emojis/unicode/svg/1f9b8-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_superhero_light_skin_tone',
                                    alt: '🦸🏻‍♂️',
                                    name: 'man superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_superhero_medium_light_skin_tone',
                                    alt: '🦸🏼‍♂️',
                                    name: 'man superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_superhero_medium_skin_tone',
                                    alt: '🦸🏽‍♂️',
                                    name: 'man superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_superhero_medium_dark_skin_tone',
                                    alt: '🦸🏾‍♂️',
                                    name: 'man superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_superhero_dark_skin_tone',
                                    alt: '🦸🏿‍♂️',
                                    name: 'man superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_superhero',
                            alt: '🦸‍♀️',
                            name: 'woman superhero',
                            source: '/images/emojis/unicode/svg/1f9b8-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_superhero_light_skin_tone',
                                    alt: '🦸🏻‍♀️',
                                    name: 'woman superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_superhero_medium_light_skin_tone',
                                    alt: '🦸🏼‍♀️',
                                    name: 'woman superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_superhero_medium_skin_tone',
                                    alt: '🦸🏽‍♀️',
                                    name: 'woman superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_superhero_medium_dark_skin_tone',
                                    alt: '🦸🏾‍♀️',
                                    name: 'woman superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_superhero_dark_skin_tone',
                                    alt: '🦸🏿‍♀️',
                                    name: 'woman superhero',
                                    source: '/images/emojis/unicode/svg/1f9b8-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'supervillain',
                            alt: '🦹',
                            name: 'supervillain',
                            source: '/images/emojis/unicode/svg/1f9b9.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'supervillain_light_skin_tone',
                                    alt: '🦹🏻',
                                    name: 'supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'supervillain_medium_light_skin_tone',
                                    alt: '🦹🏼',
                                    name: 'supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'supervillain_medium_skin_tone',
                                    alt: '🦹🏽',
                                    name: 'supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'supervillain_medium_dark_skin_tone',
                                    alt: '🦹🏾',
                                    name: 'supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'supervillain_dark_skin_tone',
                                    alt: '🦹🏿',
                                    name: 'supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_supervillain',
                            alt: '🦹‍♂️',
                            name: 'man supervillain',
                            source: '/images/emojis/unicode/svg/1f9b9-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_supervillain_light_skin_tone',
                                    alt: '🦹🏻‍♂️',
                                    name: 'man supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_supervillain_medium_light_skin_tone',
                                    alt: '🦹🏼‍♂️',
                                    name: 'man supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_supervillain_medium_skin_tone',
                                    alt: '🦹🏽‍♂️',
                                    name: 'man supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_supervillain_medium_dark_skin_tone',
                                    alt: '🦹🏾‍♂️',
                                    name: 'man supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_supervillain_dark_skin_tone',
                                    alt: '🦹🏿‍♂️',
                                    name: 'man supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_supervillain',
                            alt: '🦹‍♀️',
                            name: 'woman supervillain',
                            source: '/images/emojis/unicode/svg/1f9b9-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_supervillain_light_skin_tone',
                                    alt: '🦹🏻‍♀️',
                                    name: 'woman supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_supervillain_medium_light_skin_tone',
                                    alt: '🦹🏼‍♀️',
                                    name: 'woman supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_supervillain_medium_skin_tone',
                                    alt: '🦹🏽‍♀️',
                                    name: 'woman supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_supervillain_medium_dark_skin_tone',
                                    alt: '🦹🏾‍♀️',
                                    name: 'woman supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_supervillain_dark_skin_tone',
                                    alt: '🦹🏿‍♀️',
                                    name: 'woman supervillain',
                                    source: '/images/emojis/unicode/svg/1f9b9-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'mage',
                            alt: '🧙',
                            name: 'mage',
                            source: '/images/emojis/unicode/svg/1f9d9.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'mage_light_skin_tone',
                                    alt: '🧙🏻',
                                    name: 'mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mage_medium_light_skin_tone',
                                    alt: '🧙🏼',
                                    name: 'mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mage_medium_skin_tone',
                                    alt: '🧙🏽',
                                    name: 'mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mage_medium_dark_skin_tone',
                                    alt: '🧙🏾',
                                    name: 'mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mage_dark_skin_tone',
                                    alt: '🧙🏿',
                                    name: 'mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_mage',
                            alt: '🧙‍♂️',
                            name: 'man mage',
                            source: '/images/emojis/unicode/svg/1f9d9-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_mage_light_skin_tone',
                                    alt: '🧙🏻‍♂️',
                                    name: 'man mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_mage_medium_light_skin_tone',
                                    alt: '🧙🏼‍♂️',
                                    name: 'man mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_mage_medium_skin_tone',
                                    alt: '🧙🏽‍♂️',
                                    name: 'man mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_mage_medium_dark_skin_tone',
                                    alt: '🧙🏾‍♂️',
                                    name: 'man mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_mage_dark_skin_tone',
                                    alt: '🧙🏿‍♂️',
                                    name: 'man mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_mage',
                            alt: '🧙‍♀️',
                            name: 'woman mage',
                            source: '/images/emojis/unicode/svg/1f9d9-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_mage_light_skin_tone',
                                    alt: '🧙🏻‍♀️',
                                    name: 'woman mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_mage_medium_light_skin_tone',
                                    alt: '🧙🏼‍♀️',
                                    name: 'woman mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_mage_medium_skin_tone',
                                    alt: '🧙🏽‍♀️',
                                    name: 'woman mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_mage_medium_dark_skin_tone',
                                    alt: '🧙🏾‍♀️',
                                    name: 'woman mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_mage_dark_skin_tone',
                                    alt: '🧙🏿‍♀️',
                                    name: 'woman mage',
                                    source: '/images/emojis/unicode/svg/1f9d9-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'fairy',
                            alt: '🧚',
                            name: 'fairy',
                            source: '/images/emojis/unicode/svg/1f9da.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'fairy_light_skin_tone',
                                    alt: '🧚🏻',
                                    name: 'fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'fairy_medium_light_skin_tone',
                                    alt: '🧚🏼',
                                    name: 'fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'fairy_medium_skin_tone',
                                    alt: '🧚🏽',
                                    name: 'fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'fairy_medium_dark_skin_tone',
                                    alt: '🧚🏾',
                                    name: 'fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'fairy_dark_skin_tone',
                                    alt: '🧚🏿',
                                    name: 'fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_fairy',
                            alt: '🧚‍♂️',
                            name: 'man fairy',
                            source: '/images/emojis/unicode/svg/1f9da-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_fairy_light_skin_tone',
                                    alt: '🧚🏻‍♂️',
                                    name: 'man fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_fairy_medium_light_skin_tone',
                                    alt: '🧚🏼‍♂️',
                                    name: 'man fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_fairy_medium_skin_tone',
                                    alt: '🧚🏽‍♂️',
                                    name: 'man fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_fairy_medium_dark_skin_tone',
                                    alt: '🧚🏾‍♂️',
                                    name: 'man fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_fairy_dark_skin_tone',
                                    alt: '🧚🏿‍♂️',
                                    name: 'man fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_fairy',
                            alt: '🧚‍♀️',
                            name: 'woman fairy',
                            source: '/images/emojis/unicode/svg/1f9da-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_fairy_light_skin_tone',
                                    alt: '🧚🏻‍♀️',
                                    name: 'woman fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_fairy_medium_light_skin_tone',
                                    alt: '🧚🏼‍♀️',
                                    name: 'woman fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_fairy_medium_skin_tone',
                                    alt: '🧚🏽‍♀️',
                                    name: 'woman fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_fairy_medium_dark_skin_tone',
                                    alt: '🧚🏾‍♀️',
                                    name: 'woman fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_fairy_dark_skin_tone',
                                    alt: '🧚🏿‍♀️',
                                    name: 'woman fairy',
                                    source: '/images/emojis/unicode/svg/1f9da-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'vampire',
                            alt: '🧛',
                            name: 'vampire',
                            source: '/images/emojis/unicode/svg/1f9db.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'vampire_light_skin_tone',
                                    alt: '🧛🏻',
                                    name: 'vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'vampire_medium_light_skin_tone',
                                    alt: '🧛🏼',
                                    name: 'vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'vampire_medium_skin_tone',
                                    alt: '🧛🏽',
                                    name: 'vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'vampire_medium_dark_skin_tone',
                                    alt: '🧛🏾',
                                    name: 'vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'vampire_dark_skin_tone',
                                    alt: '🧛🏿',
                                    name: 'vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_vampire',
                            alt: '🧛‍♂️',
                            name: 'man vampire',
                            source: '/images/emojis/unicode/svg/1f9db-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_vampire_light_skin_tone',
                                    alt: '🧛🏻‍♂️',
                                    name: 'man vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_vampire_medium_light_skin_tone',
                                    alt: '🧛🏼‍♂️',
                                    name: 'man vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_vampire_medium_skin_tone',
                                    alt: '🧛🏽‍♂️',
                                    name: 'man vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_vampire_medium_dark_skin_tone',
                                    alt: '🧛🏾‍♂️',
                                    name: 'man vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_vampire_dark_skin_tone',
                                    alt: '🧛🏿‍♂️',
                                    name: 'man vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_vampire',
                            alt: '🧛‍♀️',
                            name: 'woman vampire',
                            source: '/images/emojis/unicode/svg/1f9db-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_vampire_light_skin_tone',
                                    alt: '🧛🏻‍♀️',
                                    name: 'woman vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_vampire_medium_light_skin_tone',
                                    alt: '🧛🏼‍♀️',
                                    name: 'woman vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_vampire_medium_skin_tone',
                                    alt: '🧛🏽‍♀️',
                                    name: 'woman vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_vampire_medium_dark_skin_tone',
                                    alt: '🧛🏾‍♀️',
                                    name: 'woman vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_vampire_dark_skin_tone',
                                    alt: '🧛🏿‍♀️',
                                    name: 'woman vampire',
                                    source: '/images/emojis/unicode/svg/1f9db-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'merperson',
                            alt: '🧜',
                            name: 'merperson',
                            source: '/images/emojis/unicode/svg/1f9dc.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'merperson_light_skin_tone',
                                    alt: '🧜🏻',
                                    name: 'merperson',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'merperson_medium_light_skin_tone',
                                    alt: '🧜🏼',
                                    name: 'merperson',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'merperson_medium_skin_tone',
                                    alt: '🧜🏽',
                                    name: 'merperson',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'merperson_medium_dark_skin_tone',
                                    alt: '🧜🏾',
                                    name: 'merperson',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'merperson_dark_skin_tone',
                                    alt: '🧜🏿',
                                    name: 'merperson',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'merman',
                            alt: '🧜‍♂️',
                            name: 'merman',
                            source: '/images/emojis/unicode/svg/1f9dc-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'merman_light_skin_tone',
                                    alt: '🧜🏻‍♂️',
                                    name: 'merman',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'merman_medium_light_skin_tone',
                                    alt: '🧜🏼‍♂️',
                                    name: 'merman',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'merman_medium_skin_tone',
                                    alt: '🧜🏽‍♂️',
                                    name: 'merman',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'merman_medium_dark_skin_tone',
                                    alt: '🧜🏾‍♂️',
                                    name: 'merman',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'merman_dark_skin_tone',
                                    alt: '🧜🏿‍♂️',
                                    name: 'merman',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'mermaid',
                            alt: '🧜‍♀️',
                            name: 'mermaid',
                            source: '/images/emojis/unicode/svg/1f9dc-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'mermaid_light_skin_tone',
                                    alt: '🧜🏻‍♀️',
                                    name: 'mermaid',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mermaid_medium_light_skin_tone',
                                    alt: '🧜🏼‍♀️',
                                    name: 'mermaid',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mermaid_medium_skin_tone',
                                    alt: '🧜🏽‍♀️',
                                    name: 'mermaid',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mermaid_medium_dark_skin_tone',
                                    alt: '🧜🏾‍♀️',
                                    name: 'mermaid',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'mermaid_dark_skin_tone',
                                    alt: '🧜🏿‍♀️',
                                    name: 'mermaid',
                                    source: '/images/emojis/unicode/svg/1f9dc-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'elf',
                            alt: '🧝',
                            name: 'elf',
                            source: '/images/emojis/unicode/svg/1f9dd.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'elf_light_skin_tone',
                                    alt: '🧝🏻',
                                    name: 'elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'elf_medium_light_skin_tone',
                                    alt: '🧝🏼',
                                    name: 'elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'elf_medium_skin_tone',
                                    alt: '🧝🏽',
                                    name: 'elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'elf_medium_dark_skin_tone',
                                    alt: '🧝🏾',
                                    name: 'elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'elf_dark_skin_tone',
                                    alt: '🧝🏿',
                                    name: 'elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_elf',
                            alt: '🧝‍♂️',
                            name: 'man elf',
                            source: '/images/emojis/unicode/svg/1f9dd-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_elf_light_skin_tone',
                                    alt: '🧝🏻‍♂️',
                                    name: 'man elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_elf_medium_light_skin_tone',
                                    alt: '🧝🏼‍♂️',
                                    name: 'man elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_elf_medium_skin_tone',
                                    alt: '🧝🏽‍♂️',
                                    name: 'man elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_elf_medium_dark_skin_tone',
                                    alt: '🧝🏾‍♂️',
                                    name: 'man elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_elf_dark_skin_tone',
                                    alt: '🧝🏿‍♂️',
                                    name: 'man elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_elf',
                            alt: '🧝‍♀️',
                            name: 'woman elf',
                            source: '/images/emojis/unicode/svg/1f9dd-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_elf_light_skin_tone',
                                    alt: '🧝🏻‍♀️',
                                    name: 'woman elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_elf_medium_light_skin_tone',
                                    alt: '🧝🏼‍♀️',
                                    name: 'woman elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_elf_medium_skin_tone',
                                    alt: '🧝🏽‍♀️',
                                    name: 'woman elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_elf_medium_dark_skin_tone',
                                    alt: '🧝🏾‍♀️',
                                    name: 'woman elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_elf_dark_skin_tone',
                                    alt: '🧝🏿‍♀️',
                                    name: 'woman elf',
                                    source: '/images/emojis/unicode/svg/1f9dd-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'genie',
                            alt: '🧞',
                            name: 'genie',
                            source: '/images/emojis/unicode/svg/1f9de.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'man_genie',
                            alt: '🧞‍♂️',
                            name: 'man genie',
                            source: '/images/emojis/unicode/svg/1f9de-200d-2642-fe0f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_genie',
                            alt: '🧞‍♀️',
                            name: 'woman genie',
                            source: '/images/emojis/unicode/svg/1f9de-200d-2640-fe0f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'zombie',
                            alt: '🧟',
                            name: 'zombie',
                            source: '/images/emojis/unicode/svg/1f9df.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'man_zombie',
                            alt: '🧟‍♂️',
                            name: 'man zombie',
                            source: '/images/emojis/unicode/svg/1f9df-200d-2642-fe0f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_zombie',
                            alt: '🧟‍♀️',
                            name: 'woman zombie',
                            source: '/images/emojis/unicode/svg/1f9df-200d-2640-fe0f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'person-activity',
                    emojis: [
                        {
                            emojiCode: 'person_getting_massage',
                            alt: '💆',
                            name: 'person getting massage',
                            source: '/images/emojis/unicode/svg/1f486.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_getting_massage_light_skin_tone',
                                    alt: '💆🏻',
                                    name: 'person getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_getting_massage_medium_light_skin_tone',
                                    alt: '💆🏼',
                                    name: 'person getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_getting_massage_medium_skin_tone',
                                    alt: '💆🏽',
                                    name: 'person getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_getting_massage_medium_dark_skin_tone',
                                    alt: '💆🏾',
                                    name: 'person getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_getting_massage_dark_skin_tone',
                                    alt: '💆🏿',
                                    name: 'person getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_getting_massage',
                            alt: '💆‍♂️',
                            name: 'man getting massage',
                            source: '/images/emojis/unicode/svg/1f486-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_getting_massage_light_skin_tone',
                                    alt: '💆🏻‍♂️',
                                    name: 'man getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_getting_massage_medium_light_skin_tone',
                                    alt: '💆🏼‍♂️',
                                    name: 'man getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_getting_massage_medium_skin_tone',
                                    alt: '💆🏽‍♂️',
                                    name: 'man getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_getting_massage_medium_dark_skin_tone',
                                    alt: '💆🏾‍♂️',
                                    name: 'man getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_getting_massage_dark_skin_tone',
                                    alt: '💆🏿‍♂️',
                                    name: 'man getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_getting_massage',
                            alt: '💆‍♀️',
                            name: 'woman getting massage',
                            source: '/images/emojis/unicode/svg/1f486-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_getting_massage_light_skin_tone',
                                    alt: '💆🏻‍♀️',
                                    name: 'woman getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_getting_massage_medium_light_skin_tone',
                                    alt: '💆🏼‍♀️',
                                    name: 'woman getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_getting_massage_medium_skin_tone',
                                    alt: '💆🏽‍♀️',
                                    name: 'woman getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_getting_massage_medium_dark_skin_tone',
                                    alt: '💆🏾‍♀️',
                                    name: 'woman getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_getting_massage_dark_skin_tone',
                                    alt: '💆🏿‍♀️',
                                    name: 'woman getting massage',
                                    source: '/images/emojis/unicode/svg/1f486-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_getting_haircut',
                            alt: '💇',
                            name: 'person getting haircut',
                            source: '/images/emojis/unicode/svg/1f487.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_getting_haircut_light_skin_tone',
                                    alt: '💇🏻',
                                    name: 'person getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_getting_haircut_medium_light_skin_tone',
                                    alt: '💇🏼',
                                    name: 'person getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_getting_haircut_medium_skin_tone',
                                    alt: '💇🏽',
                                    name: 'person getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_getting_haircut_medium_dark_skin_tone',
                                    alt: '💇🏾',
                                    name: 'person getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_getting_haircut_dark_skin_tone',
                                    alt: '💇🏿',
                                    name: 'person getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_getting_haircut',
                            alt: '💇‍♂️',
                            name: 'man getting haircut',
                            source: '/images/emojis/unicode/svg/1f487-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_getting_haircut_light_skin_tone',
                                    alt: '💇🏻‍♂️',
                                    name: 'man getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_getting_haircut_medium_light_skin_tone',
                                    alt: '💇🏼‍♂️',
                                    name: 'man getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_getting_haircut_medium_skin_tone',
                                    alt: '💇🏽‍♂️',
                                    name: 'man getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_getting_haircut_medium_dark_skin_tone',
                                    alt: '💇🏾‍♂️',
                                    name: 'man getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_getting_haircut_dark_skin_tone',
                                    alt: '💇🏿‍♂️',
                                    name: 'man getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_getting_haircut',
                            alt: '💇‍♀️',
                            name: 'woman getting haircut',
                            source: '/images/emojis/unicode/svg/1f487-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_getting_haircut_light_skin_tone',
                                    alt: '💇🏻‍♀️',
                                    name: 'woman getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_getting_haircut_medium_light_skin_tone',
                                    alt: '💇🏼‍♀️',
                                    name: 'woman getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_getting_haircut_medium_skin_tone',
                                    alt: '💇🏽‍♀️',
                                    name: 'woman getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_getting_haircut_medium_dark_skin_tone',
                                    alt: '💇🏾‍♀️',
                                    name: 'woman getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_getting_haircut_dark_skin_tone',
                                    alt: '💇🏿‍♀️',
                                    name: 'woman getting haircut',
                                    source: '/images/emojis/unicode/svg/1f487-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_walking',
                            alt: '🚶',
                            name: 'person walking',
                            source: '/images/emojis/unicode/svg/1f6b6.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'person_walking_light_skin_tone',
                                    alt: '🚶🏻',
                                    name: 'person walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_walking_medium_light_skin_tone',
                                    alt: '🚶🏼',
                                    name: 'person walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_walking_medium_skin_tone',
                                    alt: '🚶🏽',
                                    name: 'person walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_walking_medium_dark_skin_tone',
                                    alt: '🚶🏾',
                                    name: 'person walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_walking_dark_skin_tone',
                                    alt: '🚶🏿',
                                    name: 'person walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_walking',
                            alt: '🚶‍♂️',
                            name: 'man walking',
                            source: '/images/emojis/unicode/svg/1f6b6-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_walking_light_skin_tone',
                                    alt: '🚶🏻‍♂️',
                                    name: 'man walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_walking_medium_light_skin_tone',
                                    alt: '🚶🏼‍♂️',
                                    name: 'man walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_walking_medium_skin_tone',
                                    alt: '🚶🏽‍♂️',
                                    name: 'man walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_walking_medium_dark_skin_tone',
                                    alt: '🚶🏾‍♂️',
                                    name: 'man walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_walking_dark_skin_tone',
                                    alt: '🚶🏿‍♂️',
                                    name: 'man walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_walking',
                            alt: '🚶‍♀️',
                            name: 'woman walking',
                            source: '/images/emojis/unicode/svg/1f6b6-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_walking_light_skin_tone',
                                    alt: '🚶🏻‍♀️',
                                    name: 'woman walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_walking_medium_light_skin_tone',
                                    alt: '🚶🏼‍♀️',
                                    name: 'woman walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_walking_medium_skin_tone',
                                    alt: '🚶🏽‍♀️',
                                    name: 'woman walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_walking_medium_dark_skin_tone',
                                    alt: '🚶🏾‍♀️',
                                    name: 'woman walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_walking_dark_skin_tone',
                                    alt: '🚶🏿‍♀️',
                                    name: 'woman walking',
                                    source: '/images/emojis/unicode/svg/1f6b6-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_standing',
                            alt: '🧍',
                            name: 'person standing',
                            source: '/images/emojis/unicode/svg/1f9cd.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_standing_light_skin_tone',
                                    alt: '🧍🏻',
                                    name: 'person standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_standing_medium_light_skin_tone',
                                    alt: '🧍🏼',
                                    name: 'person standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_standing_medium_skin_tone',
                                    alt: '🧍🏽',
                                    name: 'person standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_standing_medium_dark_skin_tone',
                                    alt: '🧍🏾',
                                    name: 'person standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_standing_dark_skin_tone',
                                    alt: '🧍🏿',
                                    name: 'person standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_standing',
                            alt: '🧍‍♂️',
                            name: 'man standing',
                            source: '/images/emojis/unicode/svg/1f9cd-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_standing_light_skin_tone',
                                    alt: '🧍🏻‍♂️',
                                    name: 'man standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_standing_medium_light_skin_tone',
                                    alt: '🧍🏼‍♂️',
                                    name: 'man standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_standing_medium_skin_tone',
                                    alt: '🧍🏽‍♂️',
                                    name: 'man standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_standing_medium_dark_skin_tone',
                                    alt: '🧍🏾‍♂️',
                                    name: 'man standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_standing_dark_skin_tone',
                                    alt: '🧍🏿‍♂️',
                                    name: 'man standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_standing',
                            alt: '🧍‍♀️',
                            name: 'woman standing',
                            source: '/images/emojis/unicode/svg/1f9cd-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_standing_light_skin_tone',
                                    alt: '🧍🏻‍♀️',
                                    name: 'woman standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_standing_medium_light_skin_tone',
                                    alt: '🧍🏼‍♀️',
                                    name: 'woman standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_standing_medium_skin_tone',
                                    alt: '🧍🏽‍♀️',
                                    name: 'woman standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_standing_medium_dark_skin_tone',
                                    alt: '🧍🏾‍♀️',
                                    name: 'woman standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_standing_dark_skin_tone',
                                    alt: '🧍🏿‍♀️',
                                    name: 'woman standing',
                                    source: '/images/emojis/unicode/svg/1f9cd-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_kneeling',
                            alt: '🧎',
                            name: 'person kneeling',
                            source: '/images/emojis/unicode/svg/1f9ce.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_kneeling_light_skin_tone',
                                    alt: '🧎🏻',
                                    name: 'person kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_kneeling_medium_light_skin_tone',
                                    alt: '🧎🏼',
                                    name: 'person kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_kneeling_medium_skin_tone',
                                    alt: '🧎🏽',
                                    name: 'person kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_kneeling_medium_dark_skin_tone',
                                    alt: '🧎🏾',
                                    name: 'person kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_kneeling_dark_skin_tone',
                                    alt: '🧎🏿',
                                    name: 'person kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_kneeling',
                            alt: '🧎‍♂️',
                            name: 'man kneeling',
                            source: '/images/emojis/unicode/svg/1f9ce-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_kneeling_light_skin_tone',
                                    alt: '🧎🏻‍♂️',
                                    name: 'man kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_kneeling_medium_light_skin_tone',
                                    alt: '🧎🏼‍♂️',
                                    name: 'man kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_kneeling_medium_skin_tone',
                                    alt: '🧎🏽‍♂️',
                                    name: 'man kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_kneeling_medium_dark_skin_tone',
                                    alt: '🧎🏾‍♂️',
                                    name: 'man kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_kneeling_dark_skin_tone',
                                    alt: '🧎🏿‍♂️',
                                    name: 'man kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_kneeling',
                            alt: '🧎‍♀️',
                            name: 'woman kneeling',
                            source: '/images/emojis/unicode/svg/1f9ce-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_kneeling_light_skin_tone',
                                    alt: '🧎🏻‍♀️',
                                    name: 'woman kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_kneeling_medium_light_skin_tone',
                                    alt: '🧎🏼‍♀️',
                                    name: 'woman kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_kneeling_medium_skin_tone',
                                    alt: '🧎🏽‍♀️',
                                    name: 'woman kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_kneeling_medium_dark_skin_tone',
                                    alt: '🧎🏾‍♀️',
                                    name: 'woman kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_kneeling_dark_skin_tone',
                                    alt: '🧎🏿‍♀️',
                                    name: 'woman kneeling',
                                    source: '/images/emojis/unicode/svg/1f9ce-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_with_white_cane',
                            alt: '🧑‍🦯',
                            name: 'person with white cane',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f9af.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_with_white_cane_light_skin_tone',
                                    alt: '🧑🏻‍🦯',
                                    name: 'person with white cane',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f9af.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_with_white_cane_medium_light_skin_tone',
                                    alt: '🧑🏼‍🦯',
                                    name: 'person with white cane',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f9af.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_with_white_cane_medium_skin_tone',
                                    alt: '🧑🏽‍🦯',
                                    name: 'person with white cane',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f9af.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_with_white_cane_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🦯',
                                    name: 'person with white cane',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f9af.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_with_white_cane_dark_skin_tone',
                                    alt: '🧑🏿‍🦯',
                                    name: 'person with white cane',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f9af.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_with_white_cane',
                            alt: '👨‍🦯',
                            name: 'man with white cane',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f9af.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_with_white_cane_light_skin_tone',
                                    alt: '👨🏻‍🦯',
                                    name: 'man with white cane',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f9af.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_with_white_cane_medium_light_skin_tone',
                                    alt: '👨🏼‍🦯',
                                    name: 'man with white cane',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f9af.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_with_white_cane_medium_skin_tone',
                                    alt: '👨🏽‍🦯',
                                    name: 'man with white cane',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f9af.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_with_white_cane_medium_dark_skin_tone',
                                    alt: '👨🏾‍🦯',
                                    name: 'man with white cane',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f9af.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_with_white_cane_dark_skin_tone',
                                    alt: '👨🏿‍🦯',
                                    name: 'man with white cane',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f9af.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_with_white_cane',
                            alt: '👩‍🦯',
                            name: 'woman with white cane',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f9af.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_with_white_cane_light_skin_tone',
                                    alt: '👩🏻‍🦯',
                                    name: 'woman with white cane',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f9af.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_with_white_cane_medium_light_skin_tone',
                                    alt: '👩🏼‍🦯',
                                    name: 'woman with white cane',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f9af.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_with_white_cane_medium_skin_tone',
                                    alt: '👩🏽‍🦯',
                                    name: 'woman with white cane',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f9af.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_with_white_cane_medium_dark_skin_tone',
                                    alt: '👩🏾‍🦯',
                                    name: 'woman with white cane',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f9af.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_with_white_cane_dark_skin_tone',
                                    alt: '👩🏿‍🦯',
                                    name: 'woman with white cane',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f9af.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_in_motorized_wheelchair',
                            alt: '🧑‍🦼',
                            name: 'person in motorized wheelchair',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f9bc.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_in_motorized_wheelchair_light_skin_tone',
                                    alt: '🧑🏻‍🦼',
                                    name: 'person in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f9bc.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_motorized_wheelchair_medium_light_skin_tone',
                                    alt: '🧑🏼‍🦼',
                                    name: 'person in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f9bc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_motorized_wheelchair_medium_skin_tone',
                                    alt: '🧑🏽‍🦼',
                                    name: 'person in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f9bc.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_motorized_wheelchair_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🦼',
                                    name: 'person in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f9bc.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_motorized_wheelchair_dark_skin_tone',
                                    alt: '🧑🏿‍🦼',
                                    name: 'person in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f9bc.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_in_motorized_wheelchair',
                            alt: '👨‍🦼',
                            name: 'man in motorized wheelchair',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f9bc.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_in_motorized_wheelchair_light_skin_tone',
                                    alt: '👨🏻‍🦼',
                                    name: 'man in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f9bc.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_motorized_wheelchair_medium_light_skin_tone',
                                    alt: '👨🏼‍🦼',
                                    name: 'man in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f9bc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_motorized_wheelchair_medium_skin_tone',
                                    alt: '👨🏽‍🦼',
                                    name: 'man in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f9bc.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_motorized_wheelchair_medium_dark_skin_tone',
                                    alt: '👨🏾‍🦼',
                                    name: 'man in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f9bc.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_motorized_wheelchair_dark_skin_tone',
                                    alt: '👨🏿‍🦼',
                                    name: 'man in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f9bc.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_in_motorized_wheelchair',
                            alt: '👩‍🦼',
                            name: 'woman in motorized wheelchair',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f9bc.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_in_motorized_wheelchair_light_skin_tone',
                                    alt: '👩🏻‍🦼',
                                    name: 'woman in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f9bc.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_motorized_wheelchair_medium_light_skin_tone',
                                    alt: '👩🏼‍🦼',
                                    name: 'woman in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f9bc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_motorized_wheelchair_medium_skin_tone',
                                    alt: '👩🏽‍🦼',
                                    name: 'woman in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f9bc.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_motorized_wheelchair_medium_dark_skin_tone',
                                    alt: '👩🏾‍🦼',
                                    name: 'woman in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f9bc.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_motorized_wheelchair_dark_skin_tone',
                                    alt: '👩🏿‍🦼',
                                    name: 'woman in motorized wheelchair',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f9bc.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_in_manual_wheelchair',
                            alt: '🧑‍🦽',
                            name: 'person in manual wheelchair',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f9bd.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_in_manual_wheelchair_light_skin_tone',
                                    alt: '🧑🏻‍🦽',
                                    name: 'person in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f9bd.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_manual_wheelchair_medium_light_skin_tone',
                                    alt: '🧑🏼‍🦽',
                                    name: 'person in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f9bd.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_manual_wheelchair_medium_skin_tone',
                                    alt: '🧑🏽‍🦽',
                                    name: 'person in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f9bd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_manual_wheelchair_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🦽',
                                    name: 'person in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f9bd.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_manual_wheelchair_dark_skin_tone',
                                    alt: '🧑🏿‍🦽',
                                    name: 'person in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f9bd.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_in_manual_wheelchair',
                            alt: '👨‍🦽',
                            name: 'man in manual wheelchair',
                            source: '/images/emojis/unicode/svg/1f468-200d-1f9bd.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_in_manual_wheelchair_light_skin_tone',
                                    alt: '👨🏻‍🦽',
                                    name: 'man in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f9bd.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_manual_wheelchair_medium_light_skin_tone',
                                    alt: '👨🏼‍🦽',
                                    name: 'man in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f9bd.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_manual_wheelchair_medium_skin_tone',
                                    alt: '👨🏽‍🦽',
                                    name: 'man in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f9bd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_manual_wheelchair_medium_dark_skin_tone',
                                    alt: '👨🏾‍🦽',
                                    name: 'man in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f9bd.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_manual_wheelchair_dark_skin_tone',
                                    alt: '👨🏿‍🦽',
                                    name: 'man in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f9bd.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_in_manual_wheelchair',
                            alt: '👩‍🦽',
                            name: 'woman in manual wheelchair',
                            source: '/images/emojis/unicode/svg/1f469-200d-1f9bd.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_in_manual_wheelchair_light_skin_tone',
                                    alt: '👩🏻‍🦽',
                                    name: 'woman in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f9bd.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_manual_wheelchair_medium_light_skin_tone',
                                    alt: '👩🏼‍🦽',
                                    name: 'woman in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f9bd.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_manual_wheelchair_medium_skin_tone',
                                    alt: '👩🏽‍🦽',
                                    name: 'woman in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f9bd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_manual_wheelchair_medium_dark_skin_tone',
                                    alt: '👩🏾‍🦽',
                                    name: 'woman in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f9bd.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_manual_wheelchair_dark_skin_tone',
                                    alt: '👩🏿‍🦽',
                                    name: 'woman in manual wheelchair',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f9bd.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_running',
                            alt: '🏃',
                            name: 'person running',
                            source: '/images/emojis/unicode/svg/1f3c3.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'person_running_light_skin_tone',
                                    alt: '🏃🏻',
                                    name: 'person running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_running_medium_light_skin_tone',
                                    alt: '🏃🏼',
                                    name: 'person running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_running_medium_skin_tone',
                                    alt: '🏃🏽',
                                    name: 'person running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_running_medium_dark_skin_tone',
                                    alt: '🏃🏾',
                                    name: 'person running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_running_dark_skin_tone',
                                    alt: '🏃🏿',
                                    name: 'person running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_running',
                            alt: '🏃‍♂️',
                            name: 'man running',
                            source: '/images/emojis/unicode/svg/1f3c3-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_running_light_skin_tone',
                                    alt: '🏃🏻‍♂️',
                                    name: 'man running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_running_medium_light_skin_tone',
                                    alt: '🏃🏼‍♂️',
                                    name: 'man running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_running_medium_skin_tone',
                                    alt: '🏃🏽‍♂️',
                                    name: 'man running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_running_medium_dark_skin_tone',
                                    alt: '🏃🏾‍♂️',
                                    name: 'man running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_running_dark_skin_tone',
                                    alt: '🏃🏿‍♂️',
                                    name: 'man running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_running',
                            alt: '🏃‍♀️',
                            name: 'woman running',
                            source: '/images/emojis/unicode/svg/1f3c3-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_running_light_skin_tone',
                                    alt: '🏃🏻‍♀️',
                                    name: 'woman running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_running_medium_light_skin_tone',
                                    alt: '🏃🏼‍♀️',
                                    name: 'woman running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_running_medium_skin_tone',
                                    alt: '🏃🏽‍♀️',
                                    name: 'woman running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_running_medium_dark_skin_tone',
                                    alt: '🏃🏾‍♀️',
                                    name: 'woman running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_running_dark_skin_tone',
                                    alt: '🏃🏿‍♀️',
                                    name: 'woman running',
                                    source: '/images/emojis/unicode/svg/1f3c3-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_dancing',
                            alt: '💃',
                            name: 'woman dancing',
                            source: '/images/emojis/unicode/svg/1f483.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_dancing_light_skin_tone',
                                    alt: '💃🏻',
                                    name: 'woman dancing',
                                    source: '/images/emojis/unicode/svg/1f483-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_dancing_medium_light_skin_tone',
                                    alt: '💃🏼',
                                    name: 'woman dancing',
                                    source: '/images/emojis/unicode/svg/1f483-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_dancing_medium_skin_tone',
                                    alt: '💃🏽',
                                    name: 'woman dancing',
                                    source: '/images/emojis/unicode/svg/1f483-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_dancing_medium_dark_skin_tone',
                                    alt: '💃🏾',
                                    name: 'woman dancing',
                                    source: '/images/emojis/unicode/svg/1f483-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_dancing_dark_skin_tone',
                                    alt: '💃🏿',
                                    name: 'woman dancing',
                                    source: '/images/emojis/unicode/svg/1f483-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_dancing',
                            alt: '🕺',
                            name: 'man dancing',
                            source: '/images/emojis/unicode/svg/1f57a.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_dancing_light_skin_tone',
                                    alt: '🕺🏻',
                                    name: 'man dancing',
                                    source: '/images/emojis/unicode/svg/1f57a-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_dancing_medium_light_skin_tone',
                                    alt: '🕺🏼',
                                    name: 'man dancing',
                                    source: '/images/emojis/unicode/svg/1f57a-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_dancing_medium_skin_tone',
                                    alt: '🕺🏽',
                                    name: 'man dancing',
                                    source: '/images/emojis/unicode/svg/1f57a-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_dancing_medium_dark_skin_tone',
                                    alt: '🕺🏾',
                                    name: 'man dancing',
                                    source: '/images/emojis/unicode/svg/1f57a-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_dancing_dark_skin_tone',
                                    alt: '🕺🏿',
                                    name: 'man dancing',
                                    source: '/images/emojis/unicode/svg/1f57a-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'people_with_bunny_ears',
                            alt: '👯',
                            name: 'people with bunny ears',
                            source: '/images/emojis/unicode/svg/1f46f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'men_with_bunny_ears',
                            alt: '👯‍♂️',
                            name: 'men with bunny ears',
                            source: '/images/emojis/unicode/svg/1f46f-200d-2642-fe0f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'women_with_bunny_ears',
                            alt: '👯‍♀️',
                            name: 'women with bunny ears',
                            source: '/images/emojis/unicode/svg/1f46f-200d-2640-fe0f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'person_in_steamy_room',
                            alt: '🧖',
                            name: 'person in steamy room',
                            source: '/images/emojis/unicode/svg/1f9d6.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_in_steamy_room_light_skin_tone',
                                    alt: '🧖🏻',
                                    name: 'person in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_steamy_room_medium_light_skin_tone',
                                    alt: '🧖🏼',
                                    name: 'person in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_steamy_room_medium_skin_tone',
                                    alt: '🧖🏽',
                                    name: 'person in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_steamy_room_medium_dark_skin_tone',
                                    alt: '🧖🏾',
                                    name: 'person in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_steamy_room_dark_skin_tone',
                                    alt: '🧖🏿',
                                    name: 'person in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_in_steamy_room',
                            alt: '🧖‍♂️',
                            name: 'man in steamy room',
                            source: '/images/emojis/unicode/svg/1f9d6-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_in_steamy_room_light_skin_tone',
                                    alt: '🧖🏻‍♂️',
                                    name: 'man in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_steamy_room_medium_light_skin_tone',
                                    alt: '🧖🏼‍♂️',
                                    name: 'man in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_steamy_room_medium_skin_tone',
                                    alt: '🧖🏽‍♂️',
                                    name: 'man in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_steamy_room_medium_dark_skin_tone',
                                    alt: '🧖🏾‍♂️',
                                    name: 'man in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_steamy_room_dark_skin_tone',
                                    alt: '🧖🏿‍♂️',
                                    name: 'man in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_in_steamy_room',
                            alt: '🧖‍♀️',
                            name: 'woman in steamy room',
                            source: '/images/emojis/unicode/svg/1f9d6-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_in_steamy_room_light_skin_tone',
                                    alt: '🧖🏻‍♀️',
                                    name: 'woman in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_steamy_room_medium_light_skin_tone',
                                    alt: '🧖🏼‍♀️',
                                    name: 'woman in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_steamy_room_medium_skin_tone',
                                    alt: '🧖🏽‍♀️',
                                    name: 'woman in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_steamy_room_medium_dark_skin_tone',
                                    alt: '🧖🏾‍♀️',
                                    name: 'woman in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_steamy_room_dark_skin_tone',
                                    alt: '🧖🏿‍♀️',
                                    name: 'woman in steamy room',
                                    source: '/images/emojis/unicode/svg/1f9d6-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_climbing',
                            alt: '🧗',
                            name: 'person climbing',
                            source: '/images/emojis/unicode/svg/1f9d7.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_climbing_light_skin_tone',
                                    alt: '🧗🏻',
                                    name: 'person climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_climbing_medium_light_skin_tone',
                                    alt: '🧗🏼',
                                    name: 'person climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_climbing_medium_skin_tone',
                                    alt: '🧗🏽',
                                    name: 'person climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_climbing_medium_dark_skin_tone',
                                    alt: '🧗🏾',
                                    name: 'person climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_climbing_dark_skin_tone',
                                    alt: '🧗🏿',
                                    name: 'person climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_climbing',
                            alt: '🧗‍♂️',
                            name: 'man climbing',
                            source: '/images/emojis/unicode/svg/1f9d7-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_climbing_light_skin_tone',
                                    alt: '🧗🏻‍♂️',
                                    name: 'man climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_climbing_medium_light_skin_tone',
                                    alt: '🧗🏼‍♂️',
                                    name: 'man climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_climbing_medium_skin_tone',
                                    alt: '🧗🏽‍♂️',
                                    name: 'man climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_climbing_medium_dark_skin_tone',
                                    alt: '🧗🏾‍♂️',
                                    name: 'man climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_climbing_dark_skin_tone',
                                    alt: '🧗🏿‍♂️',
                                    name: 'man climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_climbing',
                            alt: '🧗‍♀️',
                            name: 'woman climbing',
                            source: '/images/emojis/unicode/svg/1f9d7-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_climbing_light_skin_tone',
                                    alt: '🧗🏻‍♀️',
                                    name: 'woman climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_climbing_medium_light_skin_tone',
                                    alt: '🧗🏼‍♀️',
                                    name: 'woman climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_climbing_medium_skin_tone',
                                    alt: '🧗🏽‍♀️',
                                    name: 'woman climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_climbing_medium_dark_skin_tone',
                                    alt: '🧗🏾‍♀️',
                                    name: 'woman climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_climbing_dark_skin_tone',
                                    alt: '🧗🏿‍♀️',
                                    name: 'woman climbing',
                                    source: '/images/emojis/unicode/svg/1f9d7-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'person-sport',
                    emojis: [
                        {
                            emojiCode: 'person_fencing',
                            alt: '🤺',
                            name: 'person fencing',
                            source: '/images/emojis/unicode/svg/1f93a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'horse_racing',
                            alt: '🏇',
                            name: 'horse racing',
                            source: '/images/emojis/unicode/svg/1f3c7.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'horse_racing_light_skin_tone',
                                    alt: '🏇🏻',
                                    name: 'horse racing',
                                    source: '/images/emojis/unicode/svg/1f3c7-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'horse_racing_medium_light_skin_tone',
                                    alt: '🏇🏼',
                                    name: 'horse racing',
                                    source: '/images/emojis/unicode/svg/1f3c7-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'horse_racing_medium_skin_tone',
                                    alt: '🏇🏽',
                                    name: 'horse racing',
                                    source: '/images/emojis/unicode/svg/1f3c7-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'horse_racing_medium_dark_skin_tone',
                                    alt: '🏇🏾',
                                    name: 'horse racing',
                                    source: '/images/emojis/unicode/svg/1f3c7-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'horse_racing_dark_skin_tone',
                                    alt: '🏇🏿',
                                    name: 'horse racing',
                                    source: '/images/emojis/unicode/svg/1f3c7-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'skier',
                            alt: '⛷',
                            name: 'skier',
                            source: '/images/emojis/unicode/svg/26f7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'snowboarder',
                            alt: '🏂',
                            name: 'snowboarder',
                            source: '/images/emojis/unicode/svg/1f3c2.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'snowboarder_light_skin_tone',
                                    alt: '🏂🏻',
                                    name: 'snowboarder',
                                    source: '/images/emojis/unicode/svg/1f3c2-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'snowboarder_medium_light_skin_tone',
                                    alt: '🏂🏼',
                                    name: 'snowboarder',
                                    source: '/images/emojis/unicode/svg/1f3c2-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'snowboarder_medium_skin_tone',
                                    alt: '🏂🏽',
                                    name: 'snowboarder',
                                    source: '/images/emojis/unicode/svg/1f3c2-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'snowboarder_medium_dark_skin_tone',
                                    alt: '🏂🏾',
                                    name: 'snowboarder',
                                    source: '/images/emojis/unicode/svg/1f3c2-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'snowboarder_dark_skin_tone',
                                    alt: '🏂🏿',
                                    name: 'snowboarder',
                                    source: '/images/emojis/unicode/svg/1f3c2-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_golfing',
                            alt: '🏌️‍♂️',
                            name: 'man golfing',
                            source: '/images/emojis/unicode/svg/1f3cc-fe0f-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_golfing_light_skin_tone',
                                    alt: '🏌🏻‍♂️',
                                    name: 'man golfing',
                                    source: '/images/emojis/unicode/svg/1f3cc-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_golfing_medium_light_skin_tone',
                                    alt: '🏌🏼‍♂️',
                                    name: 'man golfing',
                                    source: '/images/emojis/unicode/svg/1f3cc-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_golfing_medium_skin_tone',
                                    alt: '🏌🏽‍♂️',
                                    name: 'man golfing',
                                    source: '/images/emojis/unicode/svg/1f3cc-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_golfing_medium_dark_skin_tone',
                                    alt: '🏌🏾‍♂️',
                                    name: 'man golfing',
                                    source: '/images/emojis/unicode/svg/1f3cc-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_golfing_dark_skin_tone',
                                    alt: '🏌🏿‍♂️',
                                    name: 'man golfing',
                                    source: '/images/emojis/unicode/svg/1f3cc-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_golfing',
                            alt: '🏌️‍♀️',
                            name: 'woman golfing',
                            source: '/images/emojis/unicode/svg/1f3cc-fe0f-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_golfing_light_skin_tone',
                                    alt: '🏌🏻‍♀️',
                                    name: 'woman golfing',
                                    source: '/images/emojis/unicode/svg/1f3cc-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_golfing_medium_light_skin_tone',
                                    alt: '🏌🏼‍♀️',
                                    name: 'woman golfing',
                                    source: '/images/emojis/unicode/svg/1f3cc-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_golfing_medium_skin_tone',
                                    alt: '🏌🏽‍♀️',
                                    name: 'woman golfing',
                                    source: '/images/emojis/unicode/svg/1f3cc-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_golfing_medium_dark_skin_tone',
                                    alt: '🏌🏾‍♀️',
                                    name: 'woman golfing',
                                    source: '/images/emojis/unicode/svg/1f3cc-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_golfing_dark_skin_tone',
                                    alt: '🏌🏿‍♀️',
                                    name: 'woman golfing',
                                    source: '/images/emojis/unicode/svg/1f3cc-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_surfing',
                            alt: '🏄',
                            name: 'person surfing',
                            source: '/images/emojis/unicode/svg/1f3c4.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'person_surfing_light_skin_tone',
                                    alt: '🏄🏻',
                                    name: 'person surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_surfing_medium_light_skin_tone',
                                    alt: '🏄🏼',
                                    name: 'person surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_surfing_medium_skin_tone',
                                    alt: '🏄🏽',
                                    name: 'person surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_surfing_medium_dark_skin_tone',
                                    alt: '🏄🏾',
                                    name: 'person surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_surfing_dark_skin_tone',
                                    alt: '🏄🏿',
                                    name: 'person surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_surfing',
                            alt: '🏄‍♂️',
                            name: 'man surfing',
                            source: '/images/emojis/unicode/svg/1f3c4-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_surfing_light_skin_tone',
                                    alt: '🏄🏻‍♂️',
                                    name: 'man surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_surfing_medium_light_skin_tone',
                                    alt: '🏄🏼‍♂️',
                                    name: 'man surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_surfing_medium_skin_tone',
                                    alt: '🏄🏽‍♂️',
                                    name: 'man surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_surfing_medium_dark_skin_tone',
                                    alt: '🏄🏾‍♂️',
                                    name: 'man surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_surfing_dark_skin_tone',
                                    alt: '🏄🏿‍♂️',
                                    name: 'man surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_surfing',
                            alt: '🏄‍♀️',
                            name: 'woman surfing',
                            source: '/images/emojis/unicode/svg/1f3c4-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_surfing_light_skin_tone',
                                    alt: '🏄🏻‍♀️',
                                    name: 'woman surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_surfing_medium_light_skin_tone',
                                    alt: '🏄🏼‍♀️',
                                    name: 'woman surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_surfing_medium_skin_tone',
                                    alt: '🏄🏽‍♀️',
                                    name: 'woman surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_surfing_medium_dark_skin_tone',
                                    alt: '🏄🏾‍♀️',
                                    name: 'woman surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_surfing_dark_skin_tone',
                                    alt: '🏄🏿‍♀️',
                                    name: 'woman surfing',
                                    source: '/images/emojis/unicode/svg/1f3c4-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_rowing_boat',
                            alt: '🚣',
                            name: 'person rowing boat',
                            source: '/images/emojis/unicode/svg/1f6a3.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_rowing_boat_light_skin_tone',
                                    alt: '🚣🏻',
                                    name: 'person rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_rowing_boat_medium_light_skin_tone',
                                    alt: '🚣🏼',
                                    name: 'person rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_rowing_boat_medium_skin_tone',
                                    alt: '🚣🏽',
                                    name: 'person rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_rowing_boat_medium_dark_skin_tone',
                                    alt: '🚣🏾',
                                    name: 'person rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_rowing_boat_dark_skin_tone',
                                    alt: '🚣🏿',
                                    name: 'person rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_rowing_boat',
                            alt: '🚣‍♂️',
                            name: 'man rowing boat',
                            source: '/images/emojis/unicode/svg/1f6a3-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_rowing_boat_light_skin_tone',
                                    alt: '🚣🏻‍♂️',
                                    name: 'man rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_rowing_boat_medium_light_skin_tone',
                                    alt: '🚣🏼‍♂️',
                                    name: 'man rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_rowing_boat_medium_skin_tone',
                                    alt: '🚣🏽‍♂️',
                                    name: 'man rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_rowing_boat_medium_dark_skin_tone',
                                    alt: '🚣🏾‍♂️',
                                    name: 'man rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_rowing_boat_dark_skin_tone',
                                    alt: '🚣🏿‍♂️',
                                    name: 'man rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_rowing_boat',
                            alt: '🚣‍♀️',
                            name: 'woman rowing boat',
                            source: '/images/emojis/unicode/svg/1f6a3-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_rowing_boat_light_skin_tone',
                                    alt: '🚣🏻‍♀️',
                                    name: 'woman rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_rowing_boat_medium_light_skin_tone',
                                    alt: '🚣🏼‍♀️',
                                    name: 'woman rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_rowing_boat_medium_skin_tone',
                                    alt: '🚣🏽‍♀️',
                                    name: 'woman rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_rowing_boat_medium_dark_skin_tone',
                                    alt: '🚣🏾‍♀️',
                                    name: 'woman rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_rowing_boat_dark_skin_tone',
                                    alt: '🚣🏿‍♀️',
                                    name: 'woman rowing boat',
                                    source: '/images/emojis/unicode/svg/1f6a3-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_swimming',
                            alt: '🏊',
                            name: 'person swimming',
                            source: '/images/emojis/unicode/svg/1f3ca.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_swimming_light_skin_tone',
                                    alt: '🏊🏻',
                                    name: 'person swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_swimming_medium_light_skin_tone',
                                    alt: '🏊🏼',
                                    name: 'person swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_swimming_medium_skin_tone',
                                    alt: '🏊🏽',
                                    name: 'person swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_swimming_medium_dark_skin_tone',
                                    alt: '🏊🏾',
                                    name: 'person swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_swimming_dark_skin_tone',
                                    alt: '🏊🏿',
                                    name: 'person swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_swimming',
                            alt: '🏊‍♂️',
                            name: 'man swimming',
                            source: '/images/emojis/unicode/svg/1f3ca-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_swimming_light_skin_tone',
                                    alt: '🏊🏻‍♂️',
                                    name: 'man swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_swimming_medium_light_skin_tone',
                                    alt: '🏊🏼‍♂️',
                                    name: 'man swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_swimming_medium_skin_tone',
                                    alt: '🏊🏽‍♂️',
                                    name: 'man swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_swimming_medium_dark_skin_tone',
                                    alt: '🏊🏾‍♂️',
                                    name: 'man swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_swimming_dark_skin_tone',
                                    alt: '🏊🏿‍♂️',
                                    name: 'man swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_swimming',
                            alt: '🏊‍♀️',
                            name: 'woman swimming',
                            source: '/images/emojis/unicode/svg/1f3ca-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_swimming_light_skin_tone',
                                    alt: '🏊🏻‍♀️',
                                    name: 'woman swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_swimming_medium_light_skin_tone',
                                    alt: '🏊🏼‍♀️',
                                    name: 'woman swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_swimming_medium_skin_tone',
                                    alt: '🏊🏽‍♀️',
                                    name: 'woman swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_swimming_medium_dark_skin_tone',
                                    alt: '🏊🏾‍♀️',
                                    name: 'woman swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_swimming_dark_skin_tone',
                                    alt: '🏊🏿‍♀️',
                                    name: 'woman swimming',
                                    source: '/images/emojis/unicode/svg/1f3ca-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_bouncing_ball',
                            alt: '⛹️‍♂️',
                            name: 'man bouncing ball',
                            source: '/images/emojis/unicode/svg/26f9-fe0f-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_bouncing_ball_light_skin_tone',
                                    alt: '⛹🏻‍♂️',
                                    name: 'man bouncing ball',
                                    source: '/images/emojis/unicode/svg/26f9-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_bouncing_ball_medium_light_skin_tone',
                                    alt: '⛹🏼‍♂️',
                                    name: 'man bouncing ball',
                                    source: '/images/emojis/unicode/svg/26f9-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_bouncing_ball_medium_skin_tone',
                                    alt: '⛹🏽‍♂️',
                                    name: 'man bouncing ball',
                                    source: '/images/emojis/unicode/svg/26f9-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_bouncing_ball_medium_dark_skin_tone',
                                    alt: '⛹🏾‍♂️',
                                    name: 'man bouncing ball',
                                    source: '/images/emojis/unicode/svg/26f9-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_bouncing_ball_dark_skin_tone',
                                    alt: '⛹🏿‍♂️',
                                    name: 'man bouncing ball',
                                    source: '/images/emojis/unicode/svg/26f9-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_bouncing_ball',
                            alt: '⛹️‍♀️',
                            name: 'woman bouncing ball',
                            source: '/images/emojis/unicode/svg/26f9-fe0f-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_bouncing_ball_light_skin_tone',
                                    alt: '⛹🏻‍♀️',
                                    name: 'woman bouncing ball',
                                    source: '/images/emojis/unicode/svg/26f9-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_bouncing_ball_medium_light_skin_tone',
                                    alt: '⛹🏼‍♀️',
                                    name: 'woman bouncing ball',
                                    source: '/images/emojis/unicode/svg/26f9-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_bouncing_ball_medium_skin_tone',
                                    alt: '⛹🏽‍♀️',
                                    name: 'woman bouncing ball',
                                    source: '/images/emojis/unicode/svg/26f9-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_bouncing_ball_medium_dark_skin_tone',
                                    alt: '⛹🏾‍♀️',
                                    name: 'woman bouncing ball',
                                    source: '/images/emojis/unicode/svg/26f9-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_bouncing_ball_dark_skin_tone',
                                    alt: '⛹🏿‍♀️',
                                    name: 'woman bouncing ball',
                                    source: '/images/emojis/unicode/svg/26f9-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_lifting_weights',
                            alt: '🏋️‍♂️',
                            name: 'man lifting weights',
                            source: '/images/emojis/unicode/svg/1f3cb-fe0f-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_lifting_weights_light_skin_tone',
                                    alt: '🏋🏻‍♂️',
                                    name: 'man lifting weights',
                                    source: '/images/emojis/unicode/svg/1f3cb-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_lifting_weights_medium_light_skin_tone',
                                    alt: '🏋🏼‍♂️',
                                    name: 'man lifting weights',
                                    source: '/images/emojis/unicode/svg/1f3cb-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_lifting_weights_medium_skin_tone',
                                    alt: '🏋🏽‍♂️',
                                    name: 'man lifting weights',
                                    source: '/images/emojis/unicode/svg/1f3cb-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_lifting_weights_medium_dark_skin_tone',
                                    alt: '🏋🏾‍♂️',
                                    name: 'man lifting weights',
                                    source: '/images/emojis/unicode/svg/1f3cb-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_lifting_weights_dark_skin_tone',
                                    alt: '🏋🏿‍♂️',
                                    name: 'man lifting weights',
                                    source: '/images/emojis/unicode/svg/1f3cb-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_lifting_weights',
                            alt: '🏋️‍♀️',
                            name: 'woman lifting weights',
                            source: '/images/emojis/unicode/svg/1f3cb-fe0f-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_lifting_weights_light_skin_tone',
                                    alt: '🏋🏻‍♀️',
                                    name: 'woman lifting weights',
                                    source: '/images/emojis/unicode/svg/1f3cb-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_lifting_weights_medium_light_skin_tone',
                                    alt: '🏋🏼‍♀️',
                                    name: 'woman lifting weights',
                                    source: '/images/emojis/unicode/svg/1f3cb-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_lifting_weights_medium_skin_tone',
                                    alt: '🏋🏽‍♀️',
                                    name: 'woman lifting weights',
                                    source: '/images/emojis/unicode/svg/1f3cb-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_lifting_weights_medium_dark_skin_tone',
                                    alt: '🏋🏾‍♀️',
                                    name: 'woman lifting weights',
                                    source: '/images/emojis/unicode/svg/1f3cb-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_lifting_weights_dark_skin_tone',
                                    alt: '🏋🏿‍♀️',
                                    name: 'woman lifting weights',
                                    source: '/images/emojis/unicode/svg/1f3cb-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_biking',
                            alt: '🚴',
                            name: 'person biking',
                            source: '/images/emojis/unicode/svg/1f6b4.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'person_biking_light_skin_tone',
                                    alt: '🚴🏻',
                                    name: 'person biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_biking_medium_light_skin_tone',
                                    alt: '🚴🏼',
                                    name: 'person biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_biking_medium_skin_tone',
                                    alt: '🚴🏽',
                                    name: 'person biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_biking_medium_dark_skin_tone',
                                    alt: '🚴🏾',
                                    name: 'person biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_biking_dark_skin_tone',
                                    alt: '🚴🏿',
                                    name: 'person biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_biking',
                            alt: '🚴‍♂️',
                            name: 'man biking',
                            source: '/images/emojis/unicode/svg/1f6b4-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_biking_light_skin_tone',
                                    alt: '🚴🏻‍♂️',
                                    name: 'man biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_biking_medium_light_skin_tone',
                                    alt: '🚴🏼‍♂️',
                                    name: 'man biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_biking_medium_skin_tone',
                                    alt: '🚴🏽‍♂️',
                                    name: 'man biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_biking_medium_dark_skin_tone',
                                    alt: '🚴🏾‍♂️',
                                    name: 'man biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_biking_dark_skin_tone',
                                    alt: '🚴🏿‍♂️',
                                    name: 'man biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_biking',
                            alt: '🚴‍♀️',
                            name: 'woman biking',
                            source: '/images/emojis/unicode/svg/1f6b4-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_biking_light_skin_tone',
                                    alt: '🚴🏻‍♀️',
                                    name: 'woman biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_biking_medium_light_skin_tone',
                                    alt: '🚴🏼‍♀️',
                                    name: 'woman biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_biking_medium_skin_tone',
                                    alt: '🚴🏽‍♀️',
                                    name: 'woman biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_biking_medium_dark_skin_tone',
                                    alt: '🚴🏾‍♀️',
                                    name: 'woman biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_biking_dark_skin_tone',
                                    alt: '🚴🏿‍♀️',
                                    name: 'woman biking',
                                    source: '/images/emojis/unicode/svg/1f6b4-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_mountain_biking',
                            alt: '🚵',
                            name: 'person mountain biking',
                            source: '/images/emojis/unicode/svg/1f6b5.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_mountain_biking_light_skin_tone',
                                    alt: '🚵🏻',
                                    name: 'person mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_mountain_biking_medium_light_skin_tone',
                                    alt: '🚵🏼',
                                    name: 'person mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_mountain_biking_medium_skin_tone',
                                    alt: '🚵🏽',
                                    name: 'person mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_mountain_biking_medium_dark_skin_tone',
                                    alt: '🚵🏾',
                                    name: 'person mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_mountain_biking_dark_skin_tone',
                                    alt: '🚵🏿',
                                    name: 'person mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_mountain_biking',
                            alt: '🚵‍♂️',
                            name: 'man mountain biking',
                            source: '/images/emojis/unicode/svg/1f6b5-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_mountain_biking_light_skin_tone',
                                    alt: '🚵🏻‍♂️',
                                    name: 'man mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_mountain_biking_medium_light_skin_tone',
                                    alt: '🚵🏼‍♂️',
                                    name: 'man mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_mountain_biking_medium_skin_tone',
                                    alt: '🚵🏽‍♂️',
                                    name: 'man mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_mountain_biking_medium_dark_skin_tone',
                                    alt: '🚵🏾‍♂️',
                                    name: 'man mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_mountain_biking_dark_skin_tone',
                                    alt: '🚵🏿‍♂️',
                                    name: 'man mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_mountain_biking',
                            alt: '🚵‍♀️',
                            name: 'woman mountain biking',
                            source: '/images/emojis/unicode/svg/1f6b5-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_mountain_biking_light_skin_tone',
                                    alt: '🚵🏻‍♀️',
                                    name: 'woman mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_mountain_biking_medium_light_skin_tone',
                                    alt: '🚵🏼‍♀️',
                                    name: 'woman mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_mountain_biking_medium_skin_tone',
                                    alt: '🚵🏽‍♀️',
                                    name: 'woman mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_mountain_biking_medium_dark_skin_tone',
                                    alt: '🚵🏾‍♀️',
                                    name: 'woman mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_mountain_biking_dark_skin_tone',
                                    alt: '🚵🏿‍♀️',
                                    name: 'woman mountain biking',
                                    source: '/images/emojis/unicode/svg/1f6b5-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_cartwheeling',
                            alt: '🤸',
                            name: 'person cartwheeling',
                            source: '/images/emojis/unicode/svg/1f938.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_cartwheeling_light_skin_tone',
                                    alt: '🤸🏻',
                                    name: 'person cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_cartwheeling_medium_light_skin_tone',
                                    alt: '🤸🏼',
                                    name: 'person cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_cartwheeling_medium_skin_tone',
                                    alt: '🤸🏽',
                                    name: 'person cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_cartwheeling_medium_dark_skin_tone',
                                    alt: '🤸🏾',
                                    name: 'person cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_cartwheeling_dark_skin_tone',
                                    alt: '🤸🏿',
                                    name: 'person cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_cartwheeling',
                            alt: '🤸‍♂️',
                            name: 'man cartwheeling',
                            source: '/images/emojis/unicode/svg/1f938-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_cartwheeling_light_skin_tone',
                                    alt: '🤸🏻‍♂️',
                                    name: 'man cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_cartwheeling_medium_light_skin_tone',
                                    alt: '🤸🏼‍♂️',
                                    name: 'man cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_cartwheeling_medium_skin_tone',
                                    alt: '🤸🏽‍♂️',
                                    name: 'man cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_cartwheeling_medium_dark_skin_tone',
                                    alt: '🤸🏾‍♂️',
                                    name: 'man cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_cartwheeling_dark_skin_tone',
                                    alt: '🤸🏿‍♂️',
                                    name: 'man cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_cartwheeling',
                            alt: '🤸‍♀️',
                            name: 'woman cartwheeling',
                            source: '/images/emojis/unicode/svg/1f938-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_cartwheeling_light_skin_tone',
                                    alt: '🤸🏻‍♀️',
                                    name: 'woman cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_cartwheeling_medium_light_skin_tone',
                                    alt: '🤸🏼‍♀️',
                                    name: 'woman cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_cartwheeling_medium_skin_tone',
                                    alt: '🤸🏽‍♀️',
                                    name: 'woman cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_cartwheeling_medium_dark_skin_tone',
                                    alt: '🤸🏾‍♀️',
                                    name: 'woman cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_cartwheeling_dark_skin_tone',
                                    alt: '🤸🏿‍♀️',
                                    name: 'woman cartwheeling',
                                    source: '/images/emojis/unicode/svg/1f938-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'people_wrestling',
                            alt: '🤼',
                            name: 'people wrestling',
                            source: '/images/emojis/unicode/svg/1f93c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'men_wrestling',
                            alt: '🤼‍♂️',
                            name: 'men wrestling',
                            source: '/images/emojis/unicode/svg/1f93c-200d-2642-fe0f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'women_wrestling',
                            alt: '🤼‍♀️',
                            name: 'women wrestling',
                            source: '/images/emojis/unicode/svg/1f93c-200d-2640-fe0f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'person_playing_water_polo',
                            alt: '🤽',
                            name: 'person playing water polo',
                            source: '/images/emojis/unicode/svg/1f93d.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_playing_water_polo_light_skin_tone',
                                    alt: '🤽🏻',
                                    name: 'person playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_playing_water_polo_medium_light_skin_tone',
                                    alt: '🤽🏼',
                                    name: 'person playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_playing_water_polo_medium_skin_tone',
                                    alt: '🤽🏽',
                                    name: 'person playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_playing_water_polo_medium_dark_skin_tone',
                                    alt: '🤽🏾',
                                    name: 'person playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_playing_water_polo_dark_skin_tone',
                                    alt: '🤽🏿',
                                    name: 'person playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_playing_water_polo',
                            alt: '🤽‍♂️',
                            name: 'man playing water polo',
                            source: '/images/emojis/unicode/svg/1f93d-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_playing_water_polo_light_skin_tone',
                                    alt: '🤽🏻‍♂️',
                                    name: 'man playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_playing_water_polo_medium_light_skin_tone',
                                    alt: '🤽🏼‍♂️',
                                    name: 'man playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_playing_water_polo_medium_skin_tone',
                                    alt: '🤽🏽‍♂️',
                                    name: 'man playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_playing_water_polo_medium_dark_skin_tone',
                                    alt: '🤽🏾‍♂️',
                                    name: 'man playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_playing_water_polo_dark_skin_tone',
                                    alt: '🤽🏿‍♂️',
                                    name: 'man playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_playing_water_polo',
                            alt: '🤽‍♀️',
                            name: 'woman playing water polo',
                            source: '/images/emojis/unicode/svg/1f93d-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_playing_water_polo_light_skin_tone',
                                    alt: '🤽🏻‍♀️',
                                    name: 'woman playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_playing_water_polo_medium_light_skin_tone',
                                    alt: '🤽🏼‍♀️',
                                    name: 'woman playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_playing_water_polo_medium_skin_tone',
                                    alt: '🤽🏽‍♀️',
                                    name: 'woman playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_playing_water_polo_medium_dark_skin_tone',
                                    alt: '🤽🏾‍♀️',
                                    name: 'woman playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_playing_water_polo_dark_skin_tone',
                                    alt: '🤽🏿‍♀️',
                                    name: 'woman playing water polo',
                                    source: '/images/emojis/unicode/svg/1f93d-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_playing_handball',
                            alt: '🤾',
                            name: 'person playing handball',
                            source: '/images/emojis/unicode/svg/1f93e.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_playing_handball_light_skin_tone',
                                    alt: '🤾🏻',
                                    name: 'person playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_playing_handball_medium_light_skin_tone',
                                    alt: '🤾🏼',
                                    name: 'person playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_playing_handball_medium_skin_tone',
                                    alt: '🤾🏽',
                                    name: 'person playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_playing_handball_medium_dark_skin_tone',
                                    alt: '🤾🏾',
                                    name: 'person playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_playing_handball_dark_skin_tone',
                                    alt: '🤾🏿',
                                    name: 'person playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_playing_handball',
                            alt: '🤾‍♂️',
                            name: 'man playing handball',
                            source: '/images/emojis/unicode/svg/1f93e-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_playing_handball_light_skin_tone',
                                    alt: '🤾🏻‍♂️',
                                    name: 'man playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_playing_handball_medium_light_skin_tone',
                                    alt: '🤾🏼‍♂️',
                                    name: 'man playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_playing_handball_medium_skin_tone',
                                    alt: '🤾🏽‍♂️',
                                    name: 'man playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_playing_handball_medium_dark_skin_tone',
                                    alt: '🤾🏾‍♂️',
                                    name: 'man playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_playing_handball_dark_skin_tone',
                                    alt: '🤾🏿‍♂️',
                                    name: 'man playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_playing_handball',
                            alt: '🤾‍♀️',
                            name: 'woman playing handball',
                            source: '/images/emojis/unicode/svg/1f93e-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_playing_handball_light_skin_tone',
                                    alt: '🤾🏻‍♀️',
                                    name: 'woman playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_playing_handball_medium_light_skin_tone',
                                    alt: '🤾🏼‍♀️',
                                    name: 'woman playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_playing_handball_medium_skin_tone',
                                    alt: '🤾🏽‍♀️',
                                    name: 'woman playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_playing_handball_medium_dark_skin_tone',
                                    alt: '🤾🏾‍♀️',
                                    name: 'woman playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_playing_handball_dark_skin_tone',
                                    alt: '🤾🏿‍♀️',
                                    name: 'woman playing handball',
                                    source: '/images/emojis/unicode/svg/1f93e-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_juggling',
                            alt: '🤹',
                            name: 'person juggling',
                            source: '/images/emojis/unicode/svg/1f939.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_juggling_light_skin_tone',
                                    alt: '🤹🏻',
                                    name: 'person juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_juggling_medium_light_skin_tone',
                                    alt: '🤹🏼',
                                    name: 'person juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_juggling_medium_skin_tone',
                                    alt: '🤹🏽',
                                    name: 'person juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_juggling_medium_dark_skin_tone',
                                    alt: '🤹🏾',
                                    name: 'person juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_juggling_dark_skin_tone',
                                    alt: '🤹🏿',
                                    name: 'person juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_juggling',
                            alt: '🤹‍♂️',
                            name: 'man juggling',
                            source: '/images/emojis/unicode/svg/1f939-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'man_juggling_light_skin_tone',
                                    alt: '🤹🏻‍♂️',
                                    name: 'man juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_juggling_medium_light_skin_tone',
                                    alt: '🤹🏼‍♂️',
                                    name: 'man juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_juggling_medium_skin_tone',
                                    alt: '🤹🏽‍♂️',
                                    name: 'man juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_juggling_medium_dark_skin_tone',
                                    alt: '🤹🏾‍♂️',
                                    name: 'man juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'man_juggling_dark_skin_tone',
                                    alt: '🤹🏿‍♂️',
                                    name: 'man juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_juggling',
                            alt: '🤹‍♀️',
                            name: 'woman juggling',
                            source: '/images/emojis/unicode/svg/1f939-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'woman_juggling_light_skin_tone',
                                    alt: '🤹🏻‍♀️',
                                    name: 'woman juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_juggling_medium_light_skin_tone',
                                    alt: '🤹🏼‍♀️',
                                    name: 'woman juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_juggling_medium_skin_tone',
                                    alt: '🤹🏽‍♀️',
                                    name: 'woman juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_juggling_medium_dark_skin_tone',
                                    alt: '🤹🏾‍♀️',
                                    name: 'woman juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'woman_juggling_dark_skin_tone',
                                    alt: '🤹🏿‍♀️',
                                    name: 'woman juggling',
                                    source: '/images/emojis/unicode/svg/1f939-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'person-resting',
                    emojis: [
                        {
                            emojiCode: 'person_in_lotus_position',
                            alt: '🧘',
                            name: 'person in lotus position',
                            source: '/images/emojis/unicode/svg/1f9d8.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_in_lotus_position_light_skin_tone',
                                    alt: '🧘🏻',
                                    name: 'person in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_lotus_position_medium_light_skin_tone',
                                    alt: '🧘🏼',
                                    name: 'person in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_lotus_position_medium_skin_tone',
                                    alt: '🧘🏽',
                                    name: 'person in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_lotus_position_medium_dark_skin_tone',
                                    alt: '🧘🏾',
                                    name: 'person in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_lotus_position_dark_skin_tone',
                                    alt: '🧘🏿',
                                    name: 'person in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'man_in_lotus_position',
                            alt: '🧘‍♂️',
                            name: 'man in lotus position',
                            source: '/images/emojis/unicode/svg/1f9d8-200d-2642-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'man_in_lotus_position_light_skin_tone',
                                    alt: '🧘🏻‍♂️',
                                    name: 'man in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3fb-200d-2642-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_lotus_position_medium_light_skin_tone',
                                    alt: '🧘🏼‍♂️',
                                    name: 'man in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3fc-200d-2642-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_lotus_position_medium_skin_tone',
                                    alt: '🧘🏽‍♂️',
                                    name: 'man in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3fd-200d-2642-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_lotus_position_medium_dark_skin_tone',
                                    alt: '🧘🏾‍♂️',
                                    name: 'man in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3fe-200d-2642-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'man_in_lotus_position_dark_skin_tone',
                                    alt: '🧘🏿‍♂️',
                                    name: 'man in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3ff-200d-2642-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_in_lotus_position',
                            alt: '🧘‍♀️',
                            name: 'woman in lotus position',
                            source: '/images/emojis/unicode/svg/1f9d8-200d-2640-fe0f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_in_lotus_position_light_skin_tone',
                                    alt: '🧘🏻‍♀️',
                                    name: 'woman in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3fb-200d-2640-fe0f.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_lotus_position_medium_light_skin_tone',
                                    alt: '🧘🏼‍♀️',
                                    name: 'woman in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3fc-200d-2640-fe0f.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_lotus_position_medium_skin_tone',
                                    alt: '🧘🏽‍♀️',
                                    name: 'woman in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3fd-200d-2640-fe0f.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_lotus_position_medium_dark_skin_tone',
                                    alt: '🧘🏾‍♀️',
                                    name: 'woman in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3fe-200d-2640-fe0f.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_in_lotus_position_dark_skin_tone',
                                    alt: '🧘🏿‍♀️',
                                    name: 'woman in lotus position',
                                    source: '/images/emojis/unicode/svg/1f9d8-1f3ff-200d-2640-fe0f.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_taking_bath',
                            alt: '🛀',
                            name: 'person taking bath',
                            source: '/images/emojis/unicode/svg/1f6c0.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'person_taking_bath_light_skin_tone',
                                    alt: '🛀🏻',
                                    name: 'person taking bath',
                                    source: '/images/emojis/unicode/svg/1f6c0-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_taking_bath_medium_light_skin_tone',
                                    alt: '🛀🏼',
                                    name: 'person taking bath',
                                    source: '/images/emojis/unicode/svg/1f6c0-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_taking_bath_medium_skin_tone',
                                    alt: '🛀🏽',
                                    name: 'person taking bath',
                                    source: '/images/emojis/unicode/svg/1f6c0-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_taking_bath_medium_dark_skin_tone',
                                    alt: '🛀🏾',
                                    name: 'person taking bath',
                                    source: '/images/emojis/unicode/svg/1f6c0-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_taking_bath_dark_skin_tone',
                                    alt: '🛀🏿',
                                    name: 'person taking bath',
                                    source: '/images/emojis/unicode/svg/1f6c0-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'person_in_bed',
                            alt: '🛌',
                            name: 'person in bed',
                            source: '/images/emojis/unicode/svg/1f6cc.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'person_in_bed_light_skin_tone',
                                    alt: '🛌🏻',
                                    name: 'person in bed',
                                    source: '/images/emojis/unicode/svg/1f6cc-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_bed_medium_light_skin_tone',
                                    alt: '🛌🏼',
                                    name: 'person in bed',
                                    source: '/images/emojis/unicode/svg/1f6cc-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_in_bed_medium_skin_tone',
                                    alt: '🛌🏽',
                                    name: 'person in bed',
                                    source: '/images/emojis/unicode/svg/1f6cc-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'person_in_bed_medium_dark_skin_tone',
                                    alt: '🛌🏾',
                                    name: 'person in bed',
                                    source: '/images/emojis/unicode/svg/1f6cc-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'person_in_bed_dark_skin_tone',
                                    alt: '🛌🏿',
                                    name: 'person in bed',
                                    source: '/images/emojis/unicode/svg/1f6cc-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'family',
                    emojis: [
                        {
                            emojiCode: 'people_holding_hands',
                            alt: '🧑‍🤝‍🧑',
                            name: 'people holding hands',
                            source: '/images/emojis/unicode/svg/1f9d1-200d-1f91d-200d-1f9d1.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'people_holding_hands_light_skin_tone',
                                    alt: '🧑🏻‍🤝‍🧑🏻',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_light_skin_tone_medium_light_skin_tone',
                                    alt: '🧑🏻‍🤝‍🧑🏼',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fc.svg',
                                    components: [
                                        'light skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_light_skin_tone_medium_skin_tone',
                                    alt: '🧑🏻‍🤝‍🧑🏽',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fd.svg',
                                    components: [
                                        'light skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_light_skin_tone_medium_dark_skin_tone',
                                    alt: '🧑🏻‍🤝‍🧑🏾',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fe.svg',
                                    components: [
                                        'light skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_light_skin_tone_dark_skin_tone',
                                    alt: '🧑🏻‍🤝‍🧑🏿',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3ff.svg',
                                    components: [
                                        'light skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_light_skin_tone_light_skin_tone',
                                    alt: '🧑🏼‍🤝‍🧑🏻',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fb.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_light_skin_tone',
                                    alt: '🧑🏼‍🤝‍🧑🏼',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_light_skin_tone_medium_skin_tone',
                                    alt: '🧑🏼‍🤝‍🧑🏽',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fd.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_light_skin_tone_medium_dark_skin_tone',
                                    alt: '🧑🏼‍🤝‍🧑🏾',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fe.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_light_skin_tone_dark_skin_tone',
                                    alt: '🧑🏼‍🤝‍🧑🏿',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3ff.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_skin_tone_light_skin_tone',
                                    alt: '🧑🏽‍🤝‍🧑🏻',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fb.svg',
                                    components: [
                                        'medium skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_skin_tone_medium_light_skin_tone',
                                    alt: '🧑🏽‍🤝‍🧑🏼',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fc.svg',
                                    components: [
                                        'medium skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_skin_tone',
                                    alt: '🧑🏽‍🤝‍🧑🏽',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_skin_tone_medium_dark_skin_tone',
                                    alt: '🧑🏽‍🤝‍🧑🏾',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fe.svg',
                                    components: [
                                        'medium skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_skin_tone_dark_skin_tone',
                                    alt: '🧑🏽‍🤝‍🧑🏿',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3ff.svg',
                                    components: [
                                        'medium skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_dark_skin_tone_light_skin_tone',
                                    alt: '🧑🏾‍🤝‍🧑🏻',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fb.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_dark_skin_tone_medium_light_skin_tone',
                                    alt: '🧑🏾‍🤝‍🧑🏼',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fc.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_dark_skin_tone_medium_skin_tone',
                                    alt: '🧑🏾‍🤝‍🧑🏽',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fd.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_dark_skin_tone',
                                    alt: '🧑🏾‍🤝‍🧑🏾',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_medium_dark_skin_tone_dark_skin_tone',
                                    alt: '🧑🏾‍🤝‍🧑🏿',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3ff.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_dark_skin_tone_light_skin_tone',
                                    alt: '🧑🏿‍🤝‍🧑🏻',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fb.svg',
                                    components: [
                                        'dark skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_dark_skin_tone_medium_light_skin_tone',
                                    alt: '🧑🏿‍🤝‍🧑🏼',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fc.svg',
                                    components: [
                                        'dark skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_dark_skin_tone_medium_skin_tone',
                                    alt: '🧑🏿‍🤝‍🧑🏽',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fd.svg',
                                    components: [
                                        'dark skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_dark_skin_tone_medium_dark_skin_tone',
                                    alt: '🧑🏿‍🤝‍🧑🏾',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fe.svg',
                                    components: [
                                        'dark skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'people_holding_hands_dark_skin_tone',
                                    alt: '🧑🏿‍🤝‍🧑🏿',
                                    name: 'people holding hands',
                                    source: '/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'women_holding_hands',
                            alt: '👭',
                            name: 'women holding hands',
                            source: '/images/emojis/unicode/svg/1f46d.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'women_holding_hands_light_skin_tone',
                                    alt: '👭🏻',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f46d-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_light_skin_tone_medium_light_skin_tone',
                                    alt: '👩🏻‍🤝‍👩🏼',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f469-1f3fc.svg',
                                    components: [
                                        'light skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_light_skin_tone_medium_skin_tone',
                                    alt: '👩🏻‍🤝‍👩🏽',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f469-1f3fd.svg',
                                    components: [
                                        'light skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_light_skin_tone_medium_dark_skin_tone',
                                    alt: '👩🏻‍🤝‍👩🏾',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f469-1f3fe.svg',
                                    components: [
                                        'light skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_light_skin_tone_dark_skin_tone',
                                    alt: '👩🏻‍🤝‍👩🏿',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f469-1f3ff.svg',
                                    components: [
                                        'light skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_light_skin_tone_light_skin_tone',
                                    alt: '👩🏼‍🤝‍👩🏻',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f469-1f3fb.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_light_skin_tone',
                                    alt: '👭🏼',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f46d-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_light_skin_tone_medium_skin_tone',
                                    alt: '👩🏼‍🤝‍👩🏽',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f469-1f3fd.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_light_skin_tone_medium_dark_skin_tone',
                                    alt: '👩🏼‍🤝‍👩🏾',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f469-1f3fe.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_light_skin_tone_dark_skin_tone',
                                    alt: '👩🏼‍🤝‍👩🏿',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f469-1f3ff.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_skin_tone_light_skin_tone',
                                    alt: '👩🏽‍🤝‍👩🏻',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f469-1f3fb.svg',
                                    components: [
                                        'medium skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_skin_tone_medium_light_skin_tone',
                                    alt: '👩🏽‍🤝‍👩🏼',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f469-1f3fc.svg',
                                    components: [
                                        'medium skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_skin_tone',
                                    alt: '👭🏽',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f46d-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_skin_tone_medium_dark_skin_tone',
                                    alt: '👩🏽‍🤝‍👩🏾',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f469-1f3fe.svg',
                                    components: [
                                        'medium skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_skin_tone_dark_skin_tone',
                                    alt: '👩🏽‍🤝‍👩🏿',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f469-1f3ff.svg',
                                    components: [
                                        'medium skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_dark_skin_tone_light_skin_tone',
                                    alt: '👩🏾‍🤝‍👩🏻',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f469-1f3fb.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_dark_skin_tone_medium_light_skin_tone',
                                    alt: '👩🏾‍🤝‍👩🏼',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f469-1f3fc.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_dark_skin_tone_medium_skin_tone',
                                    alt: '👩🏾‍🤝‍👩🏽',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f469-1f3fd.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_dark_skin_tone',
                                    alt: '👭🏾',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f46d-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_medium_dark_skin_tone_dark_skin_tone',
                                    alt: '👩🏾‍🤝‍👩🏿',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f469-1f3ff.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_dark_skin_tone_light_skin_tone',
                                    alt: '👩🏿‍🤝‍👩🏻',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f469-1f3fb.svg',
                                    components: [
                                        'dark skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_dark_skin_tone_medium_light_skin_tone',
                                    alt: '👩🏿‍🤝‍👩🏼',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f469-1f3fc.svg',
                                    components: [
                                        'dark skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_dark_skin_tone_medium_skin_tone',
                                    alt: '👩🏿‍🤝‍👩🏽',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f469-1f3fd.svg',
                                    components: [
                                        'dark skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_dark_skin_tone_medium_dark_skin_tone',
                                    alt: '👩🏿‍🤝‍👩🏾',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f469-1f3fe.svg',
                                    components: [
                                        'dark skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'women_holding_hands_dark_skin_tone',
                                    alt: '👭🏿',
                                    name: 'women holding hands',
                                    source: '/images/emojis/unicode/svg/1f46d-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'woman_and_man_holding_hands',
                            alt: '👫',
                            name: 'woman and man holding hands',
                            source: '/images/emojis/unicode/svg/1f46b.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_light_skin_tone',
                                    alt: '👫🏻',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f46b-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_light_skin_tone_medium_light_skin_tone',
                                    alt: '👩🏻‍🤝‍👨🏼',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f468-1f3fc.svg',
                                    components: [
                                        'light skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_light_skin_tone_medium_skin_tone',
                                    alt: '👩🏻‍🤝‍👨🏽',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f468-1f3fd.svg',
                                    components: [
                                        'light skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_light_skin_tone_medium_dark_skin_tone',
                                    alt: '👩🏻‍🤝‍👨🏾',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f468-1f3fe.svg',
                                    components: [
                                        'light skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_light_skin_tone_dark_skin_tone',
                                    alt: '👩🏻‍🤝‍👨🏿',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f468-1f3ff.svg',
                                    components: [
                                        'light skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_light_skin_tone_light_skin_tone',
                                    alt: '👩🏼‍🤝‍👨🏻',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f468-1f3fb.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_light_skin_tone',
                                    alt: '👫🏼',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f46b-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_light_skin_tone_medium_skin_tone',
                                    alt: '👩🏼‍🤝‍👨🏽',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f468-1f3fd.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_light_skin_tone_medium_dark_skin_tone',
                                    alt: '👩🏼‍🤝‍👨🏾',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f468-1f3fe.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_light_skin_tone_dark_skin_tone',
                                    alt: '👩🏼‍🤝‍👨🏿',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f468-1f3ff.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_skin_tone_light_skin_tone',
                                    alt: '👩🏽‍🤝‍👨🏻',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f468-1f3fb.svg',
                                    components: [
                                        'medium skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_skin_tone_medium_light_skin_tone',
                                    alt: '👩🏽‍🤝‍👨🏼',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f468-1f3fc.svg',
                                    components: [
                                        'medium skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_skin_tone',
                                    alt: '👫🏽',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f46b-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_skin_tone_medium_dark_skin_tone',
                                    alt: '👩🏽‍🤝‍👨🏾',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f468-1f3fe.svg',
                                    components: [
                                        'medium skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_skin_tone_dark_skin_tone',
                                    alt: '👩🏽‍🤝‍👨🏿',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f468-1f3ff.svg',
                                    components: [
                                        'medium skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_dark_skin_tone_light_skin_tone',
                                    alt: '👩🏾‍🤝‍👨🏻',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f468-1f3fb.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_dark_skin_tone_medium_light_skin_tone',
                                    alt: '👩🏾‍🤝‍👨🏼',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f468-1f3fc.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_dark_skin_tone_medium_skin_tone',
                                    alt: '👩🏾‍🤝‍👨🏽',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f468-1f3fd.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_dark_skin_tone',
                                    alt: '👫🏾',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f46b-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_medium_dark_skin_tone_dark_skin_tone',
                                    alt: '👩🏾‍🤝‍👨🏿',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f468-1f3ff.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_dark_skin_tone_light_skin_tone',
                                    alt: '👩🏿‍🤝‍👨🏻',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f468-1f3fb.svg',
                                    components: [
                                        'dark skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_dark_skin_tone_medium_light_skin_tone',
                                    alt: '👩🏿‍🤝‍👨🏼',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f468-1f3fc.svg',
                                    components: [
                                        'dark skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_dark_skin_tone_medium_skin_tone',
                                    alt: '👩🏿‍🤝‍👨🏽',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f468-1f3fd.svg',
                                    components: [
                                        'dark skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_dark_skin_tone_medium_dark_skin_tone',
                                    alt: '👩🏿‍🤝‍👨🏾',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f468-1f3fe.svg',
                                    components: [
                                        'dark skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'woman_and_man_holding_hands_dark_skin_tone',
                                    alt: '👫🏿',
                                    name: 'woman and man holding hands',
                                    source: '/images/emojis/unicode/svg/1f46b-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'men_holding_hands',
                            alt: '👬',
                            name: 'men holding hands',
                            source: '/images/emojis/unicode/svg/1f46c.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode:
                                        'men_holding_hands_light_skin_tone',
                                    alt: '👬🏻',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f46c-1f3fb.svg',
                                    components: ['light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_light_skin_tone_medium_light_skin_tone',
                                    alt: '👨🏻‍🤝‍👨🏼',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f91d-200d-1f468-1f3fc.svg',
                                    components: [
                                        'light skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_light_skin_tone_medium_skin_tone',
                                    alt: '👨🏻‍🤝‍👨🏽',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f91d-200d-1f468-1f3fd.svg',
                                    components: [
                                        'light skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_light_skin_tone_medium_dark_skin_tone',
                                    alt: '👨🏻‍🤝‍👨🏾',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f91d-200d-1f468-1f3fe.svg',
                                    components: [
                                        'light skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_light_skin_tone_dark_skin_tone',
                                    alt: '👨🏻‍🤝‍👨🏿',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fb-200d-1f91d-200d-1f468-1f3ff.svg',
                                    components: [
                                        'light skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_light_skin_tone_light_skin_tone',
                                    alt: '👨🏼‍🤝‍👨🏻',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f91d-200d-1f468-1f3fb.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_light_skin_tone',
                                    alt: '👬🏼',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f46c-1f3fc.svg',
                                    components: ['medium-light skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_light_skin_tone_medium_skin_tone',
                                    alt: '👨🏼‍🤝‍👨🏽',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f91d-200d-1f468-1f3fd.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_light_skin_tone_medium_dark_skin_tone',
                                    alt: '👨🏼‍🤝‍👨🏾',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f91d-200d-1f468-1f3fe.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_light_skin_tone_dark_skin_tone',
                                    alt: '👨🏼‍🤝‍👨🏿',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fc-200d-1f91d-200d-1f468-1f3ff.svg',
                                    components: [
                                        'medium-light skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_skin_tone_light_skin_tone',
                                    alt: '👨🏽‍🤝‍👨🏻',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f91d-200d-1f468-1f3fb.svg',
                                    components: [
                                        'medium skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_skin_tone_medium_light_skin_tone',
                                    alt: '👨🏽‍🤝‍👨🏼',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f91d-200d-1f468-1f3fc.svg',
                                    components: [
                                        'medium skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_skin_tone',
                                    alt: '👬🏽',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f46c-1f3fd.svg',
                                    components: ['medium skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_skin_tone_medium_dark_skin_tone',
                                    alt: '👨🏽‍🤝‍👨🏾',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f91d-200d-1f468-1f3fe.svg',
                                    components: [
                                        'medium skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_skin_tone_dark_skin_tone',
                                    alt: '👨🏽‍🤝‍👨🏿',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fd-200d-1f91d-200d-1f468-1f3ff.svg',
                                    components: [
                                        'medium skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_dark_skin_tone_light_skin_tone',
                                    alt: '👨🏾‍🤝‍👨🏻',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f91d-200d-1f468-1f3fb.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_dark_skin_tone_medium_light_skin_tone',
                                    alt: '👨🏾‍🤝‍👨🏼',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f91d-200d-1f468-1f3fc.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_dark_skin_tone_medium_skin_tone',
                                    alt: '👨🏾‍🤝‍👨🏽',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f91d-200d-1f468-1f3fd.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_dark_skin_tone',
                                    alt: '👬🏾',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f46c-1f3fe.svg',
                                    components: ['medium-dark skin tone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_medium_dark_skin_tone_dark_skin_tone',
                                    alt: '👨🏾‍🤝‍👨🏿',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3fe-200d-1f91d-200d-1f468-1f3ff.svg',
                                    components: [
                                        'medium-dark skin tone',
                                        'dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_dark_skin_tone_light_skin_tone',
                                    alt: '👨🏿‍🤝‍👨🏻',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f91d-200d-1f468-1f3fb.svg',
                                    components: [
                                        'dark skin tone',
                                        'light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_dark_skin_tone_medium_light_skin_tone',
                                    alt: '👨🏿‍🤝‍👨🏼',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f91d-200d-1f468-1f3fc.svg',
                                    components: [
                                        'dark skin tone',
                                        'medium-light skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_dark_skin_tone_medium_skin_tone',
                                    alt: '👨🏿‍🤝‍👨🏽',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f91d-200d-1f468-1f3fd.svg',
                                    components: [
                                        'dark skin tone',
                                        'medium skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_dark_skin_tone_medium_dark_skin_tone',
                                    alt: '👨🏿‍🤝‍👨🏾',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f468-1f3ff-200d-1f91d-200d-1f468-1f3fe.svg',
                                    components: [
                                        'dark skin tone',
                                        'medium-dark skin tone'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'men_holding_hands_dark_skin_tone',
                                    alt: '👬🏿',
                                    name: 'men holding hands',
                                    source: '/images/emojis/unicode/svg/1f46c-1f3ff.svg',
                                    components: ['dark skin tone'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'kiss',
                            alt: '💏',
                            name: 'kiss',
                            source: '/images/emojis/unicode/svg/1f48f.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'kiss_woman_man',
                                    alt: '👩‍❤️‍💋‍👨',
                                    name: 'kiss',
                                    source: '/images/emojis/unicode/svg/1f469-200d-2764-fe0f-200d-1f48b-200d-1f468.svg',
                                    components: ['woman', 'man'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'kiss_man_man',
                                    alt: '👨‍❤️‍💋‍👨',
                                    name: 'kiss',
                                    source: '/images/emojis/unicode/svg/1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.svg',
                                    components: ['man', 'man'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'kiss_woman_woman',
                                    alt: '👩‍❤️‍💋‍👩',
                                    name: 'kiss',
                                    source: '/images/emojis/unicode/svg/1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.svg',
                                    components: ['woman', 'woman'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'couple_with_heart',
                            alt: '💑',
                            name: 'couple with heart',
                            source: '/images/emojis/unicode/svg/1f491.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'couple_with_heart_woman_man',
                                    alt: '👩‍❤️‍👨',
                                    name: 'couple with heart',
                                    source: '/images/emojis/unicode/svg/1f469-200d-2764-fe0f-200d-1f468.svg',
                                    components: ['woman', 'man'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'couple_with_heart_man_man',
                                    alt: '👨‍❤️‍👨',
                                    name: 'couple with heart',
                                    source: '/images/emojis/unicode/svg/1f468-200d-2764-fe0f-200d-1f468.svg',
                                    components: ['man', 'man'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'couple_with_heart_woman_woman',
                                    alt: '👩‍❤️‍👩',
                                    name: 'couple with heart',
                                    source: '/images/emojis/unicode/svg/1f469-200d-2764-fe0f-200d-1f469.svg',
                                    components: ['woman', 'woman'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        },
                        {
                            emojiCode: 'family',
                            alt: '👪',
                            name: 'family',
                            source: '/images/emojis/unicode/svg/1f46a.svg',
                            components: [],
                            variants: [
                                {
                                    emojiCode: 'family_man_woman_boy',
                                    alt: '👨‍👩‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f469-200d-1f466.svg',
                                    components: ['man', 'woman', 'boy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_man_woman_girl',
                                    alt: '👨‍👩‍👧',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f469-200d-1f467.svg',
                                    components: ['man', 'woman', 'girl'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_man_woman_girl_boy',
                                    alt: '👨‍👩‍👧‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f469-200d-1f467-200d-1f466.svg',
                                    components: ['man', 'woman', 'girl', 'boy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_man_woman_boy_boy',
                                    alt: '👨‍👩‍👦‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f469-200d-1f466-200d-1f466.svg',
                                    components: ['man', 'woman', 'boy', 'boy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_man_woman_girl_girl',
                                    alt: '👨‍👩‍👧‍👧',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f469-200d-1f467-200d-1f467.svg',
                                    components: [
                                        'man',
                                        'woman',
                                        'girl',
                                        'girl'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_man_man_boy',
                                    alt: '👨‍👨‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f468-200d-1f466.svg',
                                    components: ['man', 'man', 'boy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_man_man_girl',
                                    alt: '👨‍👨‍👧',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f468-200d-1f467.svg',
                                    components: ['man', 'man', 'girl'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_man_man_girl_boy',
                                    alt: '👨‍👨‍👧‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f468-200d-1f467-200d-1f466.svg',
                                    components: ['man', 'man', 'girl', 'boy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_man_man_boy_boy',
                                    alt: '👨‍👨‍👦‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f468-200d-1f466-200d-1f466.svg',
                                    components: ['man', 'man', 'boy', 'boy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_man_man_girl_girl',
                                    alt: '👨‍👨‍👧‍👧',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f468-200d-1f467-200d-1f467.svg',
                                    components: ['man', 'man', 'girl', 'girl'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_woman_woman_boy',
                                    alt: '👩‍👩‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f469-200d-1f469-200d-1f466.svg',
                                    components: ['woman', 'woman', 'boy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_woman_woman_girl',
                                    alt: '👩‍👩‍👧',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f469-200d-1f469-200d-1f467.svg',
                                    components: ['woman', 'woman', 'girl'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_woman_woman_girl_boy',
                                    alt: '👩‍👩‍👧‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f469-200d-1f469-200d-1f467-200d-1f466.svg',
                                    components: [
                                        'woman',
                                        'woman',
                                        'girl',
                                        'boy'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_woman_woman_boy_boy',
                                    alt: '👩‍👩‍👦‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f469-200d-1f469-200d-1f466-200d-1f466.svg',
                                    components: [
                                        'woman',
                                        'woman',
                                        'boy',
                                        'boy'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_woman_woman_girl_girl',
                                    alt: '👩‍👩‍👧‍👧',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f469-200d-1f469-200d-1f467-200d-1f467.svg',
                                    components: [
                                        'woman',
                                        'woman',
                                        'girl',
                                        'girl'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_man_boy',
                                    alt: '👨‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f466.svg',
                                    components: ['man', 'boy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_man_boy_boy',
                                    alt: '👨‍👦‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f466-200d-1f466.svg',
                                    components: ['man', 'boy', 'boy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_man_girl',
                                    alt: '👨‍👧',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f467.svg',
                                    components: ['man', 'girl'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_man_girl_boy',
                                    alt: '👨‍👧‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f467-200d-1f466.svg',
                                    components: ['man', 'girl', 'boy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_man_girl_girl',
                                    alt: '👨‍👧‍👧',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f468-200d-1f467-200d-1f467.svg',
                                    components: ['man', 'girl', 'girl'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_woman_boy',
                                    alt: '👩‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f469-200d-1f466.svg',
                                    components: ['woman', 'boy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_woman_boy_boy',
                                    alt: '👩‍👦‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f469-200d-1f466-200d-1f466.svg',
                                    components: ['woman', 'boy', 'boy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_woman_girl',
                                    alt: '👩‍👧',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f469-200d-1f467.svg',
                                    components: ['woman', 'girl'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_woman_girl_boy',
                                    alt: '👩‍👧‍👦',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f469-200d-1f467-200d-1f466.svg',
                                    components: ['woman', 'girl', 'boy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'family_woman_girl_girl',
                                    alt: '👩‍👧‍👧',
                                    name: 'family',
                                    source: '/images/emojis/unicode/svg/1f469-200d-1f467-200d-1f467.svg',
                                    components: ['woman', 'girl', 'girl'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'person-symbol',
                    emojis: [
                        {
                            emojiCode: 'speaking_head',
                            alt: '🗣',
                            name: 'speaking head',
                            source: '/images/emojis/unicode/svg/1f5e3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bust_in_silhouette',
                            alt: '👤',
                            name: 'bust in silhouette',
                            source: '/images/emojis/unicode/svg/1f464.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'busts_in_silhouette',
                            alt: '👥',
                            name: 'busts in silhouette',
                            source: '/images/emojis/unicode/svg/1f465.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'people_hugging',
                            alt: '🫂',
                            name: 'people hugging',
                            source: '/images/emojis/unicode/svg/1fac2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'footprints',
                            alt: '👣',
                            name: 'footprints',
                            source: '/images/emojis/unicode/svg/1f463.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                }
            ]
        },
        {
            name: 'Component',
            subGroups: [
                {
                    name: 'skin-tone',
                    emojis: []
                },
                {
                    name: 'hair-style',
                    emojis: []
                }
            ]
        },
        {
            name: 'Animals & Nature',
            subGroups: [
                {
                    name: 'animal-mammal',
                    emojis: [
                        {
                            emojiCode: 'monkey_face',
                            alt: '🐵',
                            name: 'monkey face',
                            source: '/images/emojis/unicode/svg/1f435.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'monkey',
                            alt: '🐒',
                            name: 'monkey',
                            source: '/images/emojis/unicode/svg/1f412.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'gorilla',
                            alt: '🦍',
                            name: 'gorilla',
                            source: '/images/emojis/unicode/svg/1f98d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'orangutan',
                            alt: '🦧',
                            name: 'orangutan',
                            source: '/images/emojis/unicode/svg/1f9a7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dog_face',
                            alt: '🐶',
                            name: 'dog face',
                            source: '/images/emojis/unicode/svg/1f436.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dog',
                            alt: '🐕',
                            name: 'dog',
                            source: '/images/emojis/unicode/svg/1f415.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'guide_dog',
                            alt: '🦮',
                            name: 'guide dog',
                            source: '/images/emojis/unicode/svg/1f9ae.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'service_dog',
                            alt: '🐕‍🦺',
                            name: 'service dog',
                            source: '/images/emojis/unicode/svg/1f415-200d-1f9ba.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'poodle',
                            alt: '🐩',
                            name: 'poodle',
                            source: '/images/emojis/unicode/svg/1f429.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'wolf',
                            alt: '🐺',
                            name: 'wolf',
                            source: '/images/emojis/unicode/svg/1f43a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fox',
                            alt: '🦊',
                            name: 'fox',
                            source: '/images/emojis/unicode/svg/1f98a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'raccoon',
                            alt: '🦝',
                            name: 'raccoon',
                            source: '/images/emojis/unicode/svg/1f99d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cat_face',
                            alt: '🐱',
                            name: 'cat face',
                            source: '/images/emojis/unicode/svg/1f431.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cat',
                            alt: '🐈',
                            name: 'cat',
                            source: '/images/emojis/unicode/svg/1f408.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'black_cat',
                            alt: '🐈‍⬛',
                            name: 'black cat',
                            source: '/images/emojis/unicode/svg/1f408-200d-2b1b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'lion',
                            alt: '🦁',
                            name: 'lion',
                            source: '/images/emojis/unicode/svg/1f981.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tiger_face',
                            alt: '🐯',
                            name: 'tiger face',
                            source: '/images/emojis/unicode/svg/1f42f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tiger',
                            alt: '🐅',
                            name: 'tiger',
                            source: '/images/emojis/unicode/svg/1f405.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'leopard',
                            alt: '🐆',
                            name: 'leopard',
                            source: '/images/emojis/unicode/svg/1f406.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'horse_face',
                            alt: '🐴',
                            name: 'horse face',
                            source: '/images/emojis/unicode/svg/1f434.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'horse',
                            alt: '🐎',
                            name: 'horse',
                            source: '/images/emojis/unicode/svg/1f40e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'unicorn',
                            alt: '🦄',
                            name: 'unicorn',
                            source: '/images/emojis/unicode/svg/1f984.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'zebra',
                            alt: '🦓',
                            name: 'zebra',
                            source: '/images/emojis/unicode/svg/1f993.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'deer',
                            alt: '🦌',
                            name: 'deer',
                            source: '/images/emojis/unicode/svg/1f98c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bison',
                            alt: '🦬',
                            name: 'bison',
                            source: '/images/emojis/unicode/svg/1f9ac.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cow_face',
                            alt: '🐮',
                            name: 'cow face',
                            source: '/images/emojis/unicode/svg/1f42e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ox',
                            alt: '🐂',
                            name: 'ox',
                            source: '/images/emojis/unicode/svg/1f402.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'water_buffalo',
                            alt: '🐃',
                            name: 'water buffalo',
                            source: '/images/emojis/unicode/svg/1f403.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cow',
                            alt: '🐄',
                            name: 'cow',
                            source: '/images/emojis/unicode/svg/1f404.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pig_face',
                            alt: '🐷',
                            name: 'pig face',
                            source: '/images/emojis/unicode/svg/1f437.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pig',
                            alt: '🐖',
                            name: 'pig',
                            source: '/images/emojis/unicode/svg/1f416.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'boar',
                            alt: '🐗',
                            name: 'boar',
                            source: '/images/emojis/unicode/svg/1f417.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pig_nose',
                            alt: '🐽',
                            name: 'pig nose',
                            source: '/images/emojis/unicode/svg/1f43d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ram',
                            alt: '🐏',
                            name: 'ram',
                            source: '/images/emojis/unicode/svg/1f40f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ewe',
                            alt: '🐑',
                            name: 'ewe',
                            source: '/images/emojis/unicode/svg/1f411.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'goat',
                            alt: '🐐',
                            name: 'goat',
                            source: '/images/emojis/unicode/svg/1f410.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'camel',
                            alt: '🐪',
                            name: 'camel',
                            source: '/images/emojis/unicode/svg/1f42a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'two_hump_camel',
                            alt: '🐫',
                            name: 'two-hump camel',
                            source: '/images/emojis/unicode/svg/1f42b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'llama',
                            alt: '🦙',
                            name: 'llama',
                            source: '/images/emojis/unicode/svg/1f999.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'giraffe',
                            alt: '🦒',
                            name: 'giraffe',
                            source: '/images/emojis/unicode/svg/1f992.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'elephant',
                            alt: '🐘',
                            name: 'elephant',
                            source: '/images/emojis/unicode/svg/1f418.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mammoth',
                            alt: '🦣',
                            name: 'mammoth',
                            source: '/images/emojis/unicode/svg/1f9a3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rhinoceros',
                            alt: '🦏',
                            name: 'rhinoceros',
                            source: '/images/emojis/unicode/svg/1f98f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hippopotamus',
                            alt: '🦛',
                            name: 'hippopotamus',
                            source: '/images/emojis/unicode/svg/1f99b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mouse_face',
                            alt: '🐭',
                            name: 'mouse face',
                            source: '/images/emojis/unicode/svg/1f42d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mouse',
                            alt: '🐁',
                            name: 'mouse',
                            source: '/images/emojis/unicode/svg/1f401.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rat',
                            alt: '🐀',
                            name: 'rat',
                            source: '/images/emojis/unicode/svg/1f400.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hamster',
                            alt: '🐹',
                            name: 'hamster',
                            source: '/images/emojis/unicode/svg/1f439.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rabbit_face',
                            alt: '🐰',
                            name: 'rabbit face',
                            source: '/images/emojis/unicode/svg/1f430.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rabbit',
                            alt: '🐇',
                            name: 'rabbit',
                            source: '/images/emojis/unicode/svg/1f407.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'chipmunk',
                            alt: '🐿',
                            name: 'chipmunk',
                            source: '/images/emojis/unicode/svg/1f43f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'beaver',
                            alt: '🦫',
                            name: 'beaver',
                            source: '/images/emojis/unicode/svg/1f9ab.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hedgehog',
                            alt: '🦔',
                            name: 'hedgehog',
                            source: '/images/emojis/unicode/svg/1f994.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bat',
                            alt: '🦇',
                            name: 'bat',
                            source: '/images/emojis/unicode/svg/1f987.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bear',
                            alt: '🐻',
                            name: 'bear',
                            source: '/images/emojis/unicode/svg/1f43b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'polar_bear',
                            alt: '🐻‍❄️',
                            name: 'polar bear',
                            source: '/images/emojis/unicode/svg/1f43b-200d-2744-fe0f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'koala',
                            alt: '🐨',
                            name: 'koala',
                            source: '/images/emojis/unicode/svg/1f428.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'panda',
                            alt: '🐼',
                            name: 'panda',
                            source: '/images/emojis/unicode/svg/1f43c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sloth',
                            alt: '🦥',
                            name: 'sloth',
                            source: '/images/emojis/unicode/svg/1f9a5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'otter',
                            alt: '🦦',
                            name: 'otter',
                            source: '/images/emojis/unicode/svg/1f9a6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'skunk',
                            alt: '🦨',
                            name: 'skunk',
                            source: '/images/emojis/unicode/svg/1f9a8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'kangaroo',
                            alt: '🦘',
                            name: 'kangaroo',
                            source: '/images/emojis/unicode/svg/1f998.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'badger',
                            alt: '🦡',
                            name: 'badger',
                            source: '/images/emojis/unicode/svg/1f9a1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'paw_prints',
                            alt: '🐾',
                            name: 'paw prints',
                            source: '/images/emojis/unicode/svg/1f43e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'animal-bird',
                    emojis: [
                        {
                            emojiCode: 'turkey',
                            alt: '🦃',
                            name: 'turkey',
                            source: '/images/emojis/unicode/svg/1f983.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'chicken',
                            alt: '🐔',
                            name: 'chicken',
                            source: '/images/emojis/unicode/svg/1f414.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rooster',
                            alt: '🐓',
                            name: 'rooster',
                            source: '/images/emojis/unicode/svg/1f413.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hatching_chick',
                            alt: '🐣',
                            name: 'hatching chick',
                            source: '/images/emojis/unicode/svg/1f423.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'baby_chick',
                            alt: '🐤',
                            name: 'baby chick',
                            source: '/images/emojis/unicode/svg/1f424.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'front_facing_baby_chick',
                            alt: '🐥',
                            name: 'front-facing baby chick',
                            source: '/images/emojis/unicode/svg/1f425.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bird',
                            alt: '🐦',
                            name: 'bird',
                            source: '/images/emojis/unicode/svg/1f426.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'penguin',
                            alt: '🐧',
                            name: 'penguin',
                            source: '/images/emojis/unicode/svg/1f427.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dove',
                            alt: '🕊',
                            name: 'dove',
                            source: '/images/emojis/unicode/svg/1f54a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'eagle',
                            alt: '🦅',
                            name: 'eagle',
                            source: '/images/emojis/unicode/svg/1f985.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'duck',
                            alt: '🦆',
                            name: 'duck',
                            source: '/images/emojis/unicode/svg/1f986.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'swan',
                            alt: '🦢',
                            name: 'swan',
                            source: '/images/emojis/unicode/svg/1f9a2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'owl',
                            alt: '🦉',
                            name: 'owl',
                            source: '/images/emojis/unicode/svg/1f989.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dodo',
                            alt: '🦤',
                            name: 'dodo',
                            source: '/images/emojis/unicode/svg/1f9a4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'feather',
                            alt: '🪶',
                            name: 'feather',
                            source: '/images/emojis/unicode/svg/1fab6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'flamingo',
                            alt: '🦩',
                            name: 'flamingo',
                            source: '/images/emojis/unicode/svg/1f9a9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'peacock',
                            alt: '🦚',
                            name: 'peacock',
                            source: '/images/emojis/unicode/svg/1f99a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'parrot',
                            alt: '🦜',
                            name: 'parrot',
                            source: '/images/emojis/unicode/svg/1f99c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'animal-amphibian',
                    emojis: [
                        {
                            emojiCode: 'frog',
                            alt: '🐸',
                            name: 'frog',
                            source: '/images/emojis/unicode/svg/1f438.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'animal-reptile',
                    emojis: [
                        {
                            emojiCode: 'crocodile',
                            alt: '🐊',
                            name: 'crocodile',
                            source: '/images/emojis/unicode/svg/1f40a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'turtle',
                            alt: '🐢',
                            name: 'turtle',
                            source: '/images/emojis/unicode/svg/1f422.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'lizard',
                            alt: '🦎',
                            name: 'lizard',
                            source: '/images/emojis/unicode/svg/1f98e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'snake',
                            alt: '🐍',
                            name: 'snake',
                            source: '/images/emojis/unicode/svg/1f40d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dragon_face',
                            alt: '🐲',
                            name: 'dragon face',
                            source: '/images/emojis/unicode/svg/1f432.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dragon',
                            alt: '🐉',
                            name: 'dragon',
                            source: '/images/emojis/unicode/svg/1f409.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sauropod',
                            alt: '🦕',
                            name: 'sauropod',
                            source: '/images/emojis/unicode/svg/1f995.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 't_rex',
                            alt: '🦖',
                            name: 'T-Rex',
                            source: '/images/emojis/unicode/svg/1f996.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'animal-marine',
                    emojis: [
                        {
                            emojiCode: 'spouting_whale',
                            alt: '🐳',
                            name: 'spouting whale',
                            source: '/images/emojis/unicode/svg/1f433.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'whale',
                            alt: '🐋',
                            name: 'whale',
                            source: '/images/emojis/unicode/svg/1f40b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dolphin',
                            alt: '🐬',
                            name: 'dolphin',
                            source: '/images/emojis/unicode/svg/1f42c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'seal',
                            alt: '🦭',
                            name: 'seal',
                            source: '/images/emojis/unicode/svg/1f9ad.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fish',
                            alt: '🐟',
                            name: 'fish',
                            source: '/images/emojis/unicode/svg/1f41f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tropical_fish',
                            alt: '🐠',
                            name: 'tropical fish',
                            source: '/images/emojis/unicode/svg/1f420.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'blowfish',
                            alt: '🐡',
                            name: 'blowfish',
                            source: '/images/emojis/unicode/svg/1f421.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'shark',
                            alt: '🦈',
                            name: 'shark',
                            source: '/images/emojis/unicode/svg/1f988.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'octopus',
                            alt: '🐙',
                            name: 'octopus',
                            source: '/images/emojis/unicode/svg/1f419.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'spiral_shell',
                            alt: '🐚',
                            name: 'spiral shell',
                            source: '/images/emojis/unicode/svg/1f41a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'animal-bug',
                    emojis: [
                        {
                            emojiCode: 'snail',
                            alt: '🐌',
                            name: 'snail',
                            source: '/images/emojis/unicode/svg/1f40c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'butterfly',
                            alt: '🦋',
                            name: 'butterfly',
                            source: '/images/emojis/unicode/svg/1f98b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bug',
                            alt: '🐛',
                            name: 'bug',
                            source: '/images/emojis/unicode/svg/1f41b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ant',
                            alt: '🐜',
                            name: 'ant',
                            source: '/images/emojis/unicode/svg/1f41c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'honeybee',
                            alt: '🐝',
                            name: 'honeybee',
                            source: '/images/emojis/unicode/svg/1f41d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'beetle',
                            alt: '🪲',
                            name: 'beetle',
                            source: '/images/emojis/unicode/svg/1fab2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'lady_beetle',
                            alt: '🐞',
                            name: 'lady beetle',
                            source: '/images/emojis/unicode/svg/1f41e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cricket',
                            alt: '🦗',
                            name: 'cricket',
                            source: '/images/emojis/unicode/svg/1f997.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cockroach',
                            alt: '🪳',
                            name: 'cockroach',
                            source: '/images/emojis/unicode/svg/1fab3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'spider',
                            alt: '🕷',
                            name: 'spider',
                            source: '/images/emojis/unicode/svg/1f577.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'spider_web',
                            alt: '🕸',
                            name: 'spider web',
                            source: '/images/emojis/unicode/svg/1f578.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'scorpion',
                            alt: '🦂',
                            name: 'scorpion',
                            source: '/images/emojis/unicode/svg/1f982.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mosquito',
                            alt: '🦟',
                            name: 'mosquito',
                            source: '/images/emojis/unicode/svg/1f99f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fly',
                            alt: '🪰',
                            name: 'fly',
                            source: '/images/emojis/unicode/svg/1fab0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'worm',
                            alt: '🪱',
                            name: 'worm',
                            source: '/images/emojis/unicode/svg/1fab1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'microbe',
                            alt: '🦠',
                            name: 'microbe',
                            source: '/images/emojis/unicode/svg/1f9a0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'plant-flower',
                    emojis: [
                        {
                            emojiCode: 'bouquet',
                            alt: '💐',
                            name: 'bouquet',
                            source: '/images/emojis/unicode/svg/1f490.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cherry_blossom',
                            alt: '🌸',
                            name: 'cherry blossom',
                            source: '/images/emojis/unicode/svg/1f338.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'white_flower',
                            alt: '💮',
                            name: 'white flower',
                            source: '/images/emojis/unicode/svg/1f4ae.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rosette',
                            alt: '🏵',
                            name: 'rosette',
                            source: '/images/emojis/unicode/svg/1f3f5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rose',
                            alt: '🌹',
                            name: 'rose',
                            source: '/images/emojis/unicode/svg/1f339.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'wilted_flower',
                            alt: '🥀',
                            name: 'wilted flower',
                            source: '/images/emojis/unicode/svg/1f940.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hibiscus',
                            alt: '🌺',
                            name: 'hibiscus',
                            source: '/images/emojis/unicode/svg/1f33a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sunflower',
                            alt: '🌻',
                            name: 'sunflower',
                            source: '/images/emojis/unicode/svg/1f33b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'blossom',
                            alt: '🌼',
                            name: 'blossom',
                            source: '/images/emojis/unicode/svg/1f33c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tulip',
                            alt: '🌷',
                            name: 'tulip',
                            source: '/images/emojis/unicode/svg/1f337.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'plant-other',
                    emojis: [
                        {
                            emojiCode: 'seedling',
                            alt: '🌱',
                            name: 'seedling',
                            source: '/images/emojis/unicode/svg/1f331.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'potted_plant',
                            alt: '🪴',
                            name: 'potted plant',
                            source: '/images/emojis/unicode/svg/1fab4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'evergreen_tree',
                            alt: '🌲',
                            name: 'evergreen tree',
                            source: '/images/emojis/unicode/svg/1f332.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'deciduous_tree',
                            alt: '🌳',
                            name: 'deciduous tree',
                            source: '/images/emojis/unicode/svg/1f333.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'palm_tree',
                            alt: '🌴',
                            name: 'palm tree',
                            source: '/images/emojis/unicode/svg/1f334.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cactus',
                            alt: '🌵',
                            name: 'cactus',
                            source: '/images/emojis/unicode/svg/1f335.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sheaf_of_rice',
                            alt: '🌾',
                            name: 'sheaf of rice',
                            source: '/images/emojis/unicode/svg/1f33e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'herb',
                            alt: '🌿',
                            name: 'herb',
                            source: '/images/emojis/unicode/svg/1f33f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'shamrock',
                            alt: '☘',
                            name: 'shamrock',
                            source: '/images/emojis/unicode/svg/2618.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'four_leaf_clover',
                            alt: '🍀',
                            name: 'four leaf clover',
                            source: '/images/emojis/unicode/svg/1f340.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'maple_leaf',
                            alt: '🍁',
                            name: 'maple leaf',
                            source: '/images/emojis/unicode/svg/1f341.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fallen_leaf',
                            alt: '🍂',
                            name: 'fallen leaf',
                            source: '/images/emojis/unicode/svg/1f342.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'leaf_fluttering_in_wind',
                            alt: '🍃',
                            name: 'leaf fluttering in wind',
                            source: '/images/emojis/unicode/svg/1f343.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                }
            ]
        },
        {
            name: 'Food & Drink',
            subGroups: [
                {
                    name: 'food-fruit',
                    emojis: [
                        {
                            emojiCode: 'grapes',
                            alt: '🍇',
                            name: 'grapes',
                            source: '/images/emojis/unicode/svg/1f347.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'melon',
                            alt: '🍈',
                            name: 'melon',
                            source: '/images/emojis/unicode/svg/1f348.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'watermelon',
                            alt: '🍉',
                            name: 'watermelon',
                            source: '/images/emojis/unicode/svg/1f349.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tangerine',
                            alt: '🍊',
                            name: 'tangerine',
                            source: '/images/emojis/unicode/svg/1f34a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'lemon',
                            alt: '🍋',
                            name: 'lemon',
                            source: '/images/emojis/unicode/svg/1f34b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'banana',
                            alt: '🍌',
                            name: 'banana',
                            source: '/images/emojis/unicode/svg/1f34c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pineapple',
                            alt: '🍍',
                            name: 'pineapple',
                            source: '/images/emojis/unicode/svg/1f34d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mango',
                            alt: '🥭',
                            name: 'mango',
                            source: '/images/emojis/unicode/svg/1f96d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'red_apple',
                            alt: '🍎',
                            name: 'red apple',
                            source: '/images/emojis/unicode/svg/1f34e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'green_apple',
                            alt: '🍏',
                            name: 'green apple',
                            source: '/images/emojis/unicode/svg/1f34f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pear',
                            alt: '🍐',
                            name: 'pear',
                            source: '/images/emojis/unicode/svg/1f350.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'peach',
                            alt: '🍑',
                            name: 'peach',
                            source: '/images/emojis/unicode/svg/1f351.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cherries',
                            alt: '🍒',
                            name: 'cherries',
                            source: '/images/emojis/unicode/svg/1f352.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'strawberry',
                            alt: '🍓',
                            name: 'strawberry',
                            source: '/images/emojis/unicode/svg/1f353.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'blueberries',
                            alt: '🫐',
                            name: 'blueberries',
                            source: '/images/emojis/unicode/svg/1fad0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'kiwi_fruit',
                            alt: '🥝',
                            name: 'kiwi fruit',
                            source: '/images/emojis/unicode/svg/1f95d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tomato',
                            alt: '🍅',
                            name: 'tomato',
                            source: '/images/emojis/unicode/svg/1f345.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'olive',
                            alt: '🫒',
                            name: 'olive',
                            source: '/images/emojis/unicode/svg/1fad2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'coconut',
                            alt: '🥥',
                            name: 'coconut',
                            source: '/images/emojis/unicode/svg/1f965.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'food-vegetable',
                    emojis: [
                        {
                            emojiCode: 'avocado',
                            alt: '🥑',
                            name: 'avocado',
                            source: '/images/emojis/unicode/svg/1f951.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'eggplant',
                            alt: '🍆',
                            name: 'eggplant',
                            source: '/images/emojis/unicode/svg/1f346.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'potato',
                            alt: '🥔',
                            name: 'potato',
                            source: '/images/emojis/unicode/svg/1f954.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'carrot',
                            alt: '🥕',
                            name: 'carrot',
                            source: '/images/emojis/unicode/svg/1f955.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ear_of_corn',
                            alt: '🌽',
                            name: 'ear of corn',
                            source: '/images/emojis/unicode/svg/1f33d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hot_pepper',
                            alt: '🌶',
                            name: 'hot pepper',
                            source: '/images/emojis/unicode/svg/1f336.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bell_pepper',
                            alt: '🫑',
                            name: 'bell pepper',
                            source: '/images/emojis/unicode/svg/1fad1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cucumber',
                            alt: '🥒',
                            name: 'cucumber',
                            source: '/images/emojis/unicode/svg/1f952.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'leafy_green',
                            alt: '🥬',
                            name: 'leafy green',
                            source: '/images/emojis/unicode/svg/1f96c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'broccoli',
                            alt: '🥦',
                            name: 'broccoli',
                            source: '/images/emojis/unicode/svg/1f966.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'garlic',
                            alt: '🧄',
                            name: 'garlic',
                            source: '/images/emojis/unicode/svg/1f9c4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'onion',
                            alt: '🧅',
                            name: 'onion',
                            source: '/images/emojis/unicode/svg/1f9c5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mushroom',
                            alt: '🍄',
                            name: 'mushroom',
                            source: '/images/emojis/unicode/svg/1f344.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'peanuts',
                            alt: '🥜',
                            name: 'peanuts',
                            source: '/images/emojis/unicode/svg/1f95c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'chestnut',
                            alt: '🌰',
                            name: 'chestnut',
                            source: '/images/emojis/unicode/svg/1f330.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'food-prepared',
                    emojis: [
                        {
                            emojiCode: 'bread',
                            alt: '🍞',
                            name: 'bread',
                            source: '/images/emojis/unicode/svg/1f35e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'croissant',
                            alt: '🥐',
                            name: 'croissant',
                            source: '/images/emojis/unicode/svg/1f950.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'baguette_bread',
                            alt: '🥖',
                            name: 'baguette bread',
                            source: '/images/emojis/unicode/svg/1f956.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'flatbread',
                            alt: '🫓',
                            name: 'flatbread',
                            source: '/images/emojis/unicode/svg/1fad3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pretzel',
                            alt: '🥨',
                            name: 'pretzel',
                            source: '/images/emojis/unicode/svg/1f968.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bagel',
                            alt: '🥯',
                            name: 'bagel',
                            source: '/images/emojis/unicode/svg/1f96f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pancakes',
                            alt: '🥞',
                            name: 'pancakes',
                            source: '/images/emojis/unicode/svg/1f95e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'waffle',
                            alt: '🧇',
                            name: 'waffle',
                            source: '/images/emojis/unicode/svg/1f9c7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cheese_wedge',
                            alt: '🧀',
                            name: 'cheese wedge',
                            source: '/images/emojis/unicode/svg/1f9c0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'meat_on_bone',
                            alt: '🍖',
                            name: 'meat on bone',
                            source: '/images/emojis/unicode/svg/1f356.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'poultry_leg',
                            alt: '🍗',
                            name: 'poultry leg',
                            source: '/images/emojis/unicode/svg/1f357.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cut_of_meat',
                            alt: '🥩',
                            name: 'cut of meat',
                            source: '/images/emojis/unicode/svg/1f969.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bacon',
                            alt: '🥓',
                            name: 'bacon',
                            source: '/images/emojis/unicode/svg/1f953.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hamburger',
                            alt: '🍔',
                            name: 'hamburger',
                            source: '/images/emojis/unicode/svg/1f354.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'french_fries',
                            alt: '🍟',
                            name: 'french fries',
                            source: '/images/emojis/unicode/svg/1f35f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pizza',
                            alt: '🍕',
                            name: 'pizza',
                            source: '/images/emojis/unicode/svg/1f355.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hot_dog',
                            alt: '🌭',
                            name: 'hot dog',
                            source: '/images/emojis/unicode/svg/1f32d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sandwich',
                            alt: '🥪',
                            name: 'sandwich',
                            source: '/images/emojis/unicode/svg/1f96a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'taco',
                            alt: '🌮',
                            name: 'taco',
                            source: '/images/emojis/unicode/svg/1f32e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'burrito',
                            alt: '🌯',
                            name: 'burrito',
                            source: '/images/emojis/unicode/svg/1f32f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tamale',
                            alt: '🫔',
                            name: 'tamale',
                            source: '/images/emojis/unicode/svg/1fad4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'stuffed_flatbread',
                            alt: '🥙',
                            name: 'stuffed flatbread',
                            source: '/images/emojis/unicode/svg/1f959.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'falafel',
                            alt: '🧆',
                            name: 'falafel',
                            source: '/images/emojis/unicode/svg/1f9c6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'egg',
                            alt: '🥚',
                            name: 'egg',
                            source: '/images/emojis/unicode/svg/1f95a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cooking',
                            alt: '🍳',
                            name: 'cooking',
                            source: '/images/emojis/unicode/svg/1f373.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'shallow_pan_of_food',
                            alt: '🥘',
                            name: 'shallow pan of food',
                            source: '/images/emojis/unicode/svg/1f958.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pot_of_food',
                            alt: '🍲',
                            name: 'pot of food',
                            source: '/images/emojis/unicode/svg/1f372.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fondue',
                            alt: '🫕',
                            name: 'fondue',
                            source: '/images/emojis/unicode/svg/1fad5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bowl_with_spoon',
                            alt: '🥣',
                            name: 'bowl with spoon',
                            source: '/images/emojis/unicode/svg/1f963.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'green_salad',
                            alt: '🥗',
                            name: 'green salad',
                            source: '/images/emojis/unicode/svg/1f957.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'popcorn',
                            alt: '🍿',
                            name: 'popcorn',
                            source: '/images/emojis/unicode/svg/1f37f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'butter',
                            alt: '🧈',
                            name: 'butter',
                            source: '/images/emojis/unicode/svg/1f9c8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'salt',
                            alt: '🧂',
                            name: 'salt',
                            source: '/images/emojis/unicode/svg/1f9c2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'canned_food',
                            alt: '🥫',
                            name: 'canned food',
                            source: '/images/emojis/unicode/svg/1f96b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'food-asian',
                    emojis: [
                        {
                            emojiCode: 'bento_box',
                            alt: '🍱',
                            name: 'bento box',
                            source: '/images/emojis/unicode/svg/1f371.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rice_cracker',
                            alt: '🍘',
                            name: 'rice cracker',
                            source: '/images/emojis/unicode/svg/1f358.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rice_ball',
                            alt: '🍙',
                            name: 'rice ball',
                            source: '/images/emojis/unicode/svg/1f359.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cooked_rice',
                            alt: '🍚',
                            name: 'cooked rice',
                            source: '/images/emojis/unicode/svg/1f35a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'curry_rice',
                            alt: '🍛',
                            name: 'curry rice',
                            source: '/images/emojis/unicode/svg/1f35b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'steaming_bowl',
                            alt: '🍜',
                            name: 'steaming bowl',
                            source: '/images/emojis/unicode/svg/1f35c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'spaghetti',
                            alt: '🍝',
                            name: 'spaghetti',
                            source: '/images/emojis/unicode/svg/1f35d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'roasted_sweet_potato',
                            alt: '🍠',
                            name: 'roasted sweet potato',
                            source: '/images/emojis/unicode/svg/1f360.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'oden',
                            alt: '🍢',
                            name: 'oden',
                            source: '/images/emojis/unicode/svg/1f362.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sushi',
                            alt: '🍣',
                            name: 'sushi',
                            source: '/images/emojis/unicode/svg/1f363.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fried_shrimp',
                            alt: '🍤',
                            name: 'fried shrimp',
                            source: '/images/emojis/unicode/svg/1f364.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fish_cake_with_swirl',
                            alt: '🍥',
                            name: 'fish cake with swirl',
                            source: '/images/emojis/unicode/svg/1f365.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'moon_cake',
                            alt: '🥮',
                            name: 'moon cake',
                            source: '/images/emojis/unicode/svg/1f96e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dango',
                            alt: '🍡',
                            name: 'dango',
                            source: '/images/emojis/unicode/svg/1f361.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dumpling',
                            alt: '🥟',
                            name: 'dumpling',
                            source: '/images/emojis/unicode/svg/1f95f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fortune_cookie',
                            alt: '🥠',
                            name: 'fortune cookie',
                            source: '/images/emojis/unicode/svg/1f960.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'takeout_box',
                            alt: '🥡',
                            name: 'takeout box',
                            source: '/images/emojis/unicode/svg/1f961.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'food-marine',
                    emojis: [
                        {
                            emojiCode: 'crab',
                            alt: '🦀',
                            name: 'crab',
                            source: '/images/emojis/unicode/svg/1f980.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'lobster',
                            alt: '🦞',
                            name: 'lobster',
                            source: '/images/emojis/unicode/svg/1f99e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'shrimp',
                            alt: '🦐',
                            name: 'shrimp',
                            source: '/images/emojis/unicode/svg/1f990.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'squid',
                            alt: '🦑',
                            name: 'squid',
                            source: '/images/emojis/unicode/svg/1f991.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'oyster',
                            alt: '🦪',
                            name: 'oyster',
                            source: '/images/emojis/unicode/svg/1f9aa.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'food-sweet',
                    emojis: [
                        {
                            emojiCode: 'soft_ice_cream',
                            alt: '🍦',
                            name: 'soft ice cream',
                            source: '/images/emojis/unicode/svg/1f366.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'shaved_ice',
                            alt: '🍧',
                            name: 'shaved ice',
                            source: '/images/emojis/unicode/svg/1f367.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ice_cream',
                            alt: '🍨',
                            name: 'ice cream',
                            source: '/images/emojis/unicode/svg/1f368.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'doughnut',
                            alt: '🍩',
                            name: 'doughnut',
                            source: '/images/emojis/unicode/svg/1f369.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cookie',
                            alt: '🍪',
                            name: 'cookie',
                            source: '/images/emojis/unicode/svg/1f36a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'birthday_cake',
                            alt: '🎂',
                            name: 'birthday cake',
                            source: '/images/emojis/unicode/svg/1f382.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'shortcake',
                            alt: '🍰',
                            name: 'shortcake',
                            source: '/images/emojis/unicode/svg/1f370.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cupcake',
                            alt: '🧁',
                            name: 'cupcake',
                            source: '/images/emojis/unicode/svg/1f9c1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pie',
                            alt: '🥧',
                            name: 'pie',
                            source: '/images/emojis/unicode/svg/1f967.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'chocolate_bar',
                            alt: '🍫',
                            name: 'chocolate bar',
                            source: '/images/emojis/unicode/svg/1f36b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'candy',
                            alt: '🍬',
                            name: 'candy',
                            source: '/images/emojis/unicode/svg/1f36c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'lollipop',
                            alt: '🍭',
                            name: 'lollipop',
                            source: '/images/emojis/unicode/svg/1f36d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'custard',
                            alt: '🍮',
                            name: 'custard',
                            source: '/images/emojis/unicode/svg/1f36e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'honey_pot',
                            alt: '🍯',
                            name: 'honey pot',
                            source: '/images/emojis/unicode/svg/1f36f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'drink',
                    emojis: [
                        {
                            emojiCode: 'baby_bottle',
                            alt: '🍼',
                            name: 'baby bottle',
                            source: '/images/emojis/unicode/svg/1f37c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'glass_of_milk',
                            alt: '🥛',
                            name: 'glass of milk',
                            source: '/images/emojis/unicode/svg/1f95b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hot_beverage',
                            alt: '☕',
                            name: 'hot beverage',
                            source: '/images/emojis/unicode/svg/2615.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'teapot',
                            alt: '🫖',
                            name: 'teapot',
                            source: '/images/emojis/unicode/svg/1fad6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'teacup_without_handle',
                            alt: '🍵',
                            name: 'teacup without handle',
                            source: '/images/emojis/unicode/svg/1f375.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sake',
                            alt: '🍶',
                            name: 'sake',
                            source: '/images/emojis/unicode/svg/1f376.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bottle_with_popping_cork',
                            alt: '🍾',
                            name: 'bottle with popping cork',
                            source: '/images/emojis/unicode/svg/1f37e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'wine_glass',
                            alt: '🍷',
                            name: 'wine glass',
                            source: '/images/emojis/unicode/svg/1f377.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cocktail_glass',
                            alt: '🍸',
                            name: 'cocktail glass',
                            source: '/images/emojis/unicode/svg/1f378.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tropical_drink',
                            alt: '🍹',
                            name: 'tropical drink',
                            source: '/images/emojis/unicode/svg/1f379.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'beer_mug',
                            alt: '🍺',
                            name: 'beer mug',
                            source: '/images/emojis/unicode/svg/1f37a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'clinking_beer_mugs',
                            alt: '🍻',
                            name: 'clinking beer mugs',
                            source: '/images/emojis/unicode/svg/1f37b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'clinking_glasses',
                            alt: '🥂',
                            name: 'clinking glasses',
                            source: '/images/emojis/unicode/svg/1f942.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tumbler_glass',
                            alt: '🥃',
                            name: 'tumbler glass',
                            source: '/images/emojis/unicode/svg/1f943.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cup_with_straw',
                            alt: '🥤',
                            name: 'cup with straw',
                            source: '/images/emojis/unicode/svg/1f964.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bubble_tea',
                            alt: '🧋',
                            name: 'bubble tea',
                            source: '/images/emojis/unicode/svg/1f9cb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'beverage_box',
                            alt: '🧃',
                            name: 'beverage box',
                            source: '/images/emojis/unicode/svg/1f9c3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mate',
                            alt: '🧉',
                            name: 'mate',
                            source: '/images/emojis/unicode/svg/1f9c9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ice',
                            alt: '🧊',
                            name: 'ice',
                            source: '/images/emojis/unicode/svg/1f9ca.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'dishware',
                    emojis: [
                        {
                            emojiCode: 'chopsticks',
                            alt: '🥢',
                            name: 'chopsticks',
                            source: '/images/emojis/unicode/svg/1f962.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fork_and_knife_with_plate',
                            alt: '🍽',
                            name: 'fork and knife with plate',
                            source: '/images/emojis/unicode/svg/1f37d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fork_and_knife',
                            alt: '🍴',
                            name: 'fork and knife',
                            source: '/images/emojis/unicode/svg/1f374.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'spoon',
                            alt: '🥄',
                            name: 'spoon',
                            source: '/images/emojis/unicode/svg/1f944.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'kitchen_knife',
                            alt: '🔪',
                            name: 'kitchen knife',
                            source: '/images/emojis/unicode/svg/1f52a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'amphora',
                            alt: '🏺',
                            name: 'amphora',
                            source: '/images/emojis/unicode/svg/1f3fa.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                }
            ]
        },
        {
            name: 'Travel & Places',
            subGroups: [
                {
                    name: 'place-map',
                    emojis: [
                        {
                            emojiCode: 'globe_showing_europe_africa',
                            alt: '🌍',
                            name: 'globe showing Europe-Africa',
                            source: '/images/emojis/unicode/svg/1f30d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'globe_showing_americas',
                            alt: '🌎',
                            name: 'globe showing Americas',
                            source: '/images/emojis/unicode/svg/1f30e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'globe_showing_asia_australia',
                            alt: '🌏',
                            name: 'globe showing Asia-Australia',
                            source: '/images/emojis/unicode/svg/1f30f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'globe_with_meridians',
                            alt: '🌐',
                            name: 'globe with meridians',
                            source: '/images/emojis/unicode/svg/1f310.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'world_map',
                            alt: '🗺',
                            name: 'world map',
                            source: '/images/emojis/unicode/svg/1f5fa.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'map_of_japan',
                            alt: '🗾',
                            name: 'map of Japan',
                            source: '/images/emojis/unicode/svg/1f5fe.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'compass',
                            alt: '🧭',
                            name: 'compass',
                            source: '/images/emojis/unicode/svg/1f9ed.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'place-geographic',
                    emojis: [
                        {
                            emojiCode: 'snow_capped_mountain',
                            alt: '🏔',
                            name: 'snow-capped mountain',
                            source: '/images/emojis/unicode/svg/1f3d4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mountain',
                            alt: '⛰',
                            name: 'mountain',
                            source: '/images/emojis/unicode/svg/26f0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'volcano',
                            alt: '🌋',
                            name: 'volcano',
                            source: '/images/emojis/unicode/svg/1f30b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mount_fuji',
                            alt: '🗻',
                            name: 'mount fuji',
                            source: '/images/emojis/unicode/svg/1f5fb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'camping',
                            alt: '🏕',
                            name: 'camping',
                            source: '/images/emojis/unicode/svg/1f3d5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'beach_with_umbrella',
                            alt: '🏖',
                            name: 'beach with umbrella',
                            source: '/images/emojis/unicode/svg/1f3d6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'desert',
                            alt: '🏜',
                            name: 'desert',
                            source: '/images/emojis/unicode/svg/1f3dc.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'desert_island',
                            alt: '🏝',
                            name: 'desert island',
                            source: '/images/emojis/unicode/svg/1f3dd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'national_park',
                            alt: '🏞',
                            name: 'national park',
                            source: '/images/emojis/unicode/svg/1f3de.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'place-building',
                    emojis: [
                        {
                            emojiCode: 'stadium',
                            alt: '🏟',
                            name: 'stadium',
                            source: '/images/emojis/unicode/svg/1f3df.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'classical_building',
                            alt: '🏛',
                            name: 'classical building',
                            source: '/images/emojis/unicode/svg/1f3db.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'building_construction',
                            alt: '🏗',
                            name: 'building construction',
                            source: '/images/emojis/unicode/svg/1f3d7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'brick',
                            alt: '🧱',
                            name: 'brick',
                            source: '/images/emojis/unicode/svg/1f9f1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rock',
                            alt: '🪨',
                            name: 'rock',
                            source: '/images/emojis/unicode/svg/1faa8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'wood',
                            alt: '🪵',
                            name: 'wood',
                            source: '/images/emojis/unicode/svg/1fab5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hut',
                            alt: '🛖',
                            name: 'hut',
                            source: '/images/emojis/unicode/svg/1f6d6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'houses',
                            alt: '🏘',
                            name: 'houses',
                            source: '/images/emojis/unicode/svg/1f3d8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'derelict_house',
                            alt: '🏚',
                            name: 'derelict house',
                            source: '/images/emojis/unicode/svg/1f3da.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'house',
                            alt: '🏠',
                            name: 'house',
                            source: '/images/emojis/unicode/svg/1f3e0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'house_with_garden',
                            alt: '🏡',
                            name: 'house with garden',
                            source: '/images/emojis/unicode/svg/1f3e1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'office_building',
                            alt: '🏢',
                            name: 'office building',
                            source: '/images/emojis/unicode/svg/1f3e2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_post_office',
                            alt: '🏣',
                            name: 'Japanese post office',
                            source: '/images/emojis/unicode/svg/1f3e3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'post_office',
                            alt: '🏤',
                            name: 'post office',
                            source: '/images/emojis/unicode/svg/1f3e4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hospital',
                            alt: '🏥',
                            name: 'hospital',
                            source: '/images/emojis/unicode/svg/1f3e5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bank',
                            alt: '🏦',
                            name: 'bank',
                            source: '/images/emojis/unicode/svg/1f3e6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hotel',
                            alt: '🏨',
                            name: 'hotel',
                            source: '/images/emojis/unicode/svg/1f3e8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'love_hotel',
                            alt: '🏩',
                            name: 'love hotel',
                            source: '/images/emojis/unicode/svg/1f3e9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'convenience_store',
                            alt: '🏪',
                            name: 'convenience store',
                            source: '/images/emojis/unicode/svg/1f3ea.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'school',
                            alt: '🏫',
                            name: 'school',
                            source: '/images/emojis/unicode/svg/1f3eb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'department_store',
                            alt: '🏬',
                            name: 'department store',
                            source: '/images/emojis/unicode/svg/1f3ec.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'factory',
                            alt: '🏭',
                            name: 'factory',
                            source: '/images/emojis/unicode/svg/1f3ed.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_castle',
                            alt: '🏯',
                            name: 'Japanese castle',
                            source: '/images/emojis/unicode/svg/1f3ef.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'castle',
                            alt: '🏰',
                            name: 'castle',
                            source: '/images/emojis/unicode/svg/1f3f0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'wedding',
                            alt: '💒',
                            name: 'wedding',
                            source: '/images/emojis/unicode/svg/1f492.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tokyo_tower',
                            alt: '🗼',
                            name: 'Tokyo tower',
                            source: '/images/emojis/unicode/svg/1f5fc.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'statue_of_liberty',
                            alt: '🗽',
                            name: 'Statue of Liberty',
                            source: '/images/emojis/unicode/svg/1f5fd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'place-religious',
                    emojis: [
                        {
                            emojiCode: 'church',
                            alt: '⛪',
                            name: 'church',
                            source: '/images/emojis/unicode/svg/26ea.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mosque',
                            alt: '🕌',
                            name: 'mosque',
                            source: '/images/emojis/unicode/svg/1f54c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hindu_temple',
                            alt: '🛕',
                            name: 'hindu temple',
                            source: '/images/emojis/unicode/svg/1f6d5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'synagogue',
                            alt: '🕍',
                            name: 'synagogue',
                            source: '/images/emojis/unicode/svg/1f54d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'shinto_shrine',
                            alt: '⛩',
                            name: 'shinto shrine',
                            source: '/images/emojis/unicode/svg/26e9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'kaaba',
                            alt: '🕋',
                            name: 'kaaba',
                            source: '/images/emojis/unicode/svg/1f54b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'place-other',
                    emojis: [
                        {
                            emojiCode: 'fountain',
                            alt: '⛲',
                            name: 'fountain',
                            source: '/images/emojis/unicode/svg/26f2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tent',
                            alt: '⛺',
                            name: 'tent',
                            source: '/images/emojis/unicode/svg/26fa.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'foggy',
                            alt: '🌁',
                            name: 'foggy',
                            source: '/images/emojis/unicode/svg/1f301.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'night_with_stars',
                            alt: '🌃',
                            name: 'night with stars',
                            source: '/images/emojis/unicode/svg/1f303.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cityscape',
                            alt: '🏙',
                            name: 'cityscape',
                            source: '/images/emojis/unicode/svg/1f3d9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sunrise_over_mountains',
                            alt: '🌄',
                            name: 'sunrise over mountains',
                            source: '/images/emojis/unicode/svg/1f304.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sunrise',
                            alt: '🌅',
                            name: 'sunrise',
                            source: '/images/emojis/unicode/svg/1f305.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cityscape_at_dusk',
                            alt: '🌆',
                            name: 'cityscape at dusk',
                            source: '/images/emojis/unicode/svg/1f306.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sunset',
                            alt: '🌇',
                            name: 'sunset',
                            source: '/images/emojis/unicode/svg/1f307.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bridge_at_night',
                            alt: '🌉',
                            name: 'bridge at night',
                            source: '/images/emojis/unicode/svg/1f309.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hot_springs',
                            alt: '♨',
                            name: 'hot springs',
                            source: '/images/emojis/unicode/svg/2668.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'carousel_horse',
                            alt: '🎠',
                            name: 'carousel horse',
                            source: '/images/emojis/unicode/svg/1f3a0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ferris_wheel',
                            alt: '🎡',
                            name: 'ferris wheel',
                            source: '/images/emojis/unicode/svg/1f3a1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'roller_coaster',
                            alt: '🎢',
                            name: 'roller coaster',
                            source: '/images/emojis/unicode/svg/1f3a2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'barber_pole',
                            alt: '💈',
                            name: 'barber pole',
                            source: '/images/emojis/unicode/svg/1f488.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'circus_tent',
                            alt: '🎪',
                            name: 'circus tent',
                            source: '/images/emojis/unicode/svg/1f3aa.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'transport-ground',
                    emojis: [
                        {
                            emojiCode: 'locomotive',
                            alt: '🚂',
                            name: 'locomotive',
                            source: '/images/emojis/unicode/svg/1f682.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'railway_car',
                            alt: '🚃',
                            name: 'railway car',
                            source: '/images/emojis/unicode/svg/1f683.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'high_speed_train',
                            alt: '🚄',
                            name: 'high-speed train',
                            source: '/images/emojis/unicode/svg/1f684.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bullet_train',
                            alt: '🚅',
                            name: 'bullet train',
                            source: '/images/emojis/unicode/svg/1f685.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'train',
                            alt: '🚆',
                            name: 'train',
                            source: '/images/emojis/unicode/svg/1f686.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'metro',
                            alt: '🚇',
                            name: 'metro',
                            source: '/images/emojis/unicode/svg/1f687.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'light_rail',
                            alt: '🚈',
                            name: 'light rail',
                            source: '/images/emojis/unicode/svg/1f688.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'station',
                            alt: '🚉',
                            name: 'station',
                            source: '/images/emojis/unicode/svg/1f689.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tram',
                            alt: '🚊',
                            name: 'tram',
                            source: '/images/emojis/unicode/svg/1f68a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'monorail',
                            alt: '🚝',
                            name: 'monorail',
                            source: '/images/emojis/unicode/svg/1f69d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mountain_railway',
                            alt: '🚞',
                            name: 'mountain railway',
                            source: '/images/emojis/unicode/svg/1f69e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tram_car',
                            alt: '🚋',
                            name: 'tram car',
                            source: '/images/emojis/unicode/svg/1f68b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bus',
                            alt: '🚌',
                            name: 'bus',
                            source: '/images/emojis/unicode/svg/1f68c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'oncoming_bus',
                            alt: '🚍',
                            name: 'oncoming bus',
                            source: '/images/emojis/unicode/svg/1f68d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'trolleybus',
                            alt: '🚎',
                            name: 'trolleybus',
                            source: '/images/emojis/unicode/svg/1f68e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'minibus',
                            alt: '🚐',
                            name: 'minibus',
                            source: '/images/emojis/unicode/svg/1f690.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ambulance',
                            alt: '🚑',
                            name: 'ambulance',
                            source: '/images/emojis/unicode/svg/1f691.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fire_engine',
                            alt: '🚒',
                            name: 'fire engine',
                            source: '/images/emojis/unicode/svg/1f692.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'police_car',
                            alt: '🚓',
                            name: 'police car',
                            source: '/images/emojis/unicode/svg/1f693.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'oncoming_police_car',
                            alt: '🚔',
                            name: 'oncoming police car',
                            source: '/images/emojis/unicode/svg/1f694.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'taxi',
                            alt: '🚕',
                            name: 'taxi',
                            source: '/images/emojis/unicode/svg/1f695.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'oncoming_taxi',
                            alt: '🚖',
                            name: 'oncoming taxi',
                            source: '/images/emojis/unicode/svg/1f696.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'automobile',
                            alt: '🚗',
                            name: 'automobile',
                            source: '/images/emojis/unicode/svg/1f697.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'oncoming_automobile',
                            alt: '🚘',
                            name: 'oncoming automobile',
                            source: '/images/emojis/unicode/svg/1f698.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sport_utility_vehicle',
                            alt: '🚙',
                            name: 'sport utility vehicle',
                            source: '/images/emojis/unicode/svg/1f699.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pickup_truck',
                            alt: '🛻',
                            name: 'pickup truck',
                            source: '/images/emojis/unicode/svg/1f6fb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'delivery_truck',
                            alt: '🚚',
                            name: 'delivery truck',
                            source: '/images/emojis/unicode/svg/1f69a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'articulated_lorry',
                            alt: '🚛',
                            name: 'articulated lorry',
                            source: '/images/emojis/unicode/svg/1f69b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tractor',
                            alt: '🚜',
                            name: 'tractor',
                            source: '/images/emojis/unicode/svg/1f69c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'racing_car',
                            alt: '🏎',
                            name: 'racing car',
                            source: '/images/emojis/unicode/svg/1f3ce.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'motorcycle',
                            alt: '🏍',
                            name: 'motorcycle',
                            source: '/images/emojis/unicode/svg/1f3cd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'motor_scooter',
                            alt: '🛵',
                            name: 'motor scooter',
                            source: '/images/emojis/unicode/svg/1f6f5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'manual_wheelchair',
                            alt: '🦽',
                            name: 'manual wheelchair',
                            source: '/images/emojis/unicode/svg/1f9bd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'motorized_wheelchair',
                            alt: '🦼',
                            name: 'motorized wheelchair',
                            source: '/images/emojis/unicode/svg/1f9bc.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'auto_rickshaw',
                            alt: '🛺',
                            name: 'auto rickshaw',
                            source: '/images/emojis/unicode/svg/1f6fa.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bicycle',
                            alt: '🚲',
                            name: 'bicycle',
                            source: '/images/emojis/unicode/svg/1f6b2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'kick_scooter',
                            alt: '🛴',
                            name: 'kick scooter',
                            source: '/images/emojis/unicode/svg/1f6f4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'skateboard',
                            alt: '🛹',
                            name: 'skateboard',
                            source: '/images/emojis/unicode/svg/1f6f9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'roller_skate',
                            alt: '🛼',
                            name: 'roller skate',
                            source: '/images/emojis/unicode/svg/1f6fc.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bus_stop',
                            alt: '🚏',
                            name: 'bus stop',
                            source: '/images/emojis/unicode/svg/1f68f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'motorway',
                            alt: '🛣',
                            name: 'motorway',
                            source: '/images/emojis/unicode/svg/1f6e3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'railway_track',
                            alt: '🛤',
                            name: 'railway track',
                            source: '/images/emojis/unicode/svg/1f6e4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'oil_drum',
                            alt: '🛢',
                            name: 'oil drum',
                            source: '/images/emojis/unicode/svg/1f6e2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fuel_pump',
                            alt: '⛽',
                            name: 'fuel pump',
                            source: '/images/emojis/unicode/svg/26fd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'police_car_light',
                            alt: '🚨',
                            name: 'police car light',
                            source: '/images/emojis/unicode/svg/1f6a8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'horizontal_traffic_light',
                            alt: '🚥',
                            name: 'horizontal traffic light',
                            source: '/images/emojis/unicode/svg/1f6a5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'vertical_traffic_light',
                            alt: '🚦',
                            name: 'vertical traffic light',
                            source: '/images/emojis/unicode/svg/1f6a6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'stop_sign',
                            alt: '🛑',
                            name: 'stop sign',
                            source: '/images/emojis/unicode/svg/1f6d1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'construction',
                            alt: '🚧',
                            name: 'construction',
                            source: '/images/emojis/unicode/svg/1f6a7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'transport-water',
                    emojis: [
                        {
                            emojiCode: 'anchor',
                            alt: '⚓',
                            name: 'anchor',
                            source: '/images/emojis/unicode/svg/2693.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sailboat',
                            alt: '⛵',
                            name: 'sailboat',
                            source: '/images/emojis/unicode/svg/26f5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'canoe',
                            alt: '🛶',
                            name: 'canoe',
                            source: '/images/emojis/unicode/svg/1f6f6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'speedboat',
                            alt: '🚤',
                            name: 'speedboat',
                            source: '/images/emojis/unicode/svg/1f6a4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'passenger_ship',
                            alt: '🛳',
                            name: 'passenger ship',
                            source: '/images/emojis/unicode/svg/1f6f3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ferry',
                            alt: '⛴',
                            name: 'ferry',
                            source: '/images/emojis/unicode/svg/26f4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'motor_boat',
                            alt: '🛥',
                            name: 'motor boat',
                            source: '/images/emojis/unicode/svg/1f6e5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ship',
                            alt: '🚢',
                            name: 'ship',
                            source: '/images/emojis/unicode/svg/1f6a2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'transport-air',
                    emojis: [
                        {
                            emojiCode: 'airplane',
                            alt: '✈',
                            name: 'airplane',
                            source: '/images/emojis/unicode/svg/2708.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'small_airplane',
                            alt: '🛩',
                            name: 'small airplane',
                            source: '/images/emojis/unicode/svg/1f6e9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'airplane_departure',
                            alt: '🛫',
                            name: 'airplane departure',
                            source: '/images/emojis/unicode/svg/1f6eb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'airplane_arrival',
                            alt: '🛬',
                            name: 'airplane arrival',
                            source: '/images/emojis/unicode/svg/1f6ec.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'parachute',
                            alt: '🪂',
                            name: 'parachute',
                            source: '/images/emojis/unicode/svg/1fa82.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'seat',
                            alt: '💺',
                            name: 'seat',
                            source: '/images/emojis/unicode/svg/1f4ba.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'helicopter',
                            alt: '🚁',
                            name: 'helicopter',
                            source: '/images/emojis/unicode/svg/1f681.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'suspension_railway',
                            alt: '🚟',
                            name: 'suspension railway',
                            source: '/images/emojis/unicode/svg/1f69f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mountain_cableway',
                            alt: '🚠',
                            name: 'mountain cableway',
                            source: '/images/emojis/unicode/svg/1f6a0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'aerial_tramway',
                            alt: '🚡',
                            name: 'aerial tramway',
                            source: '/images/emojis/unicode/svg/1f6a1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'satellite',
                            alt: '🛰',
                            name: 'satellite',
                            source: '/images/emojis/unicode/svg/1f6f0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rocket',
                            alt: '🚀',
                            name: 'rocket',
                            source: '/images/emojis/unicode/svg/1f680.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'flying_saucer',
                            alt: '🛸',
                            name: 'flying saucer',
                            source: '/images/emojis/unicode/svg/1f6f8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'hotel',
                    emojis: [
                        {
                            emojiCode: 'bellhop_bell',
                            alt: '🛎',
                            name: 'bellhop bell',
                            source: '/images/emojis/unicode/svg/1f6ce.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'luggage',
                            alt: '🧳',
                            name: 'luggage',
                            source: '/images/emojis/unicode/svg/1f9f3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'time',
                    emojis: [
                        {
                            emojiCode: 'hourglass_done',
                            alt: '⌛',
                            name: 'hourglass done',
                            source: '/images/emojis/unicode/svg/231b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hourglass_not_done',
                            alt: '⏳',
                            name: 'hourglass not done',
                            source: '/images/emojis/unicode/svg/23f3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'watch',
                            alt: '⌚',
                            name: 'watch',
                            source: '/images/emojis/unicode/svg/231a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'alarm_clock',
                            alt: '⏰',
                            name: 'alarm clock',
                            source: '/images/emojis/unicode/svg/23f0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'stopwatch',
                            alt: '⏱',
                            name: 'stopwatch',
                            source: '/images/emojis/unicode/svg/23f1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'timer_clock',
                            alt: '⏲',
                            name: 'timer clock',
                            source: '/images/emojis/unicode/svg/23f2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mantelpiece_clock',
                            alt: '🕰',
                            name: 'mantelpiece clock',
                            source: '/images/emojis/unicode/svg/1f570.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'twelve_oclock',
                            alt: '🕛',
                            name: 'twelve o’clock',
                            source: '/images/emojis/unicode/svg/1f55b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'twelve_thirty',
                            alt: '🕧',
                            name: 'twelve-thirty',
                            source: '/images/emojis/unicode/svg/1f567.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'one_oclock',
                            alt: '🕐',
                            name: 'one o’clock',
                            source: '/images/emojis/unicode/svg/1f550.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'one_thirty',
                            alt: '🕜',
                            name: 'one-thirty',
                            source: '/images/emojis/unicode/svg/1f55c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'two_oclock',
                            alt: '🕑',
                            name: 'two o’clock',
                            source: '/images/emojis/unicode/svg/1f551.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'two_thirty',
                            alt: '🕝',
                            name: 'two-thirty',
                            source: '/images/emojis/unicode/svg/1f55d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'three_oclock',
                            alt: '🕒',
                            name: 'three o’clock',
                            source: '/images/emojis/unicode/svg/1f552.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'three_thirty',
                            alt: '🕞',
                            name: 'three-thirty',
                            source: '/images/emojis/unicode/svg/1f55e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'four_oclock',
                            alt: '🕓',
                            name: 'four o’clock',
                            source: '/images/emojis/unicode/svg/1f553.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'four_thirty',
                            alt: '🕟',
                            name: 'four-thirty',
                            source: '/images/emojis/unicode/svg/1f55f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'five_oclock',
                            alt: '🕔',
                            name: 'five o’clock',
                            source: '/images/emojis/unicode/svg/1f554.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'five_thirty',
                            alt: '🕠',
                            name: 'five-thirty',
                            source: '/images/emojis/unicode/svg/1f560.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'six_oclock',
                            alt: '🕕',
                            name: 'six o’clock',
                            source: '/images/emojis/unicode/svg/1f555.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'six_thirty',
                            alt: '🕡',
                            name: 'six-thirty',
                            source: '/images/emojis/unicode/svg/1f561.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'seven_oclock',
                            alt: '🕖',
                            name: 'seven o’clock',
                            source: '/images/emojis/unicode/svg/1f556.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'seven_thirty',
                            alt: '🕢',
                            name: 'seven-thirty',
                            source: '/images/emojis/unicode/svg/1f562.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'eight_oclock',
                            alt: '🕗',
                            name: 'eight o’clock',
                            source: '/images/emojis/unicode/svg/1f557.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'eight_thirty',
                            alt: '🕣',
                            name: 'eight-thirty',
                            source: '/images/emojis/unicode/svg/1f563.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'nine_oclock',
                            alt: '🕘',
                            name: 'nine o’clock',
                            source: '/images/emojis/unicode/svg/1f558.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'nine_thirty',
                            alt: '🕤',
                            name: 'nine-thirty',
                            source: '/images/emojis/unicode/svg/1f564.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ten_oclock',
                            alt: '🕙',
                            name: 'ten o’clock',
                            source: '/images/emojis/unicode/svg/1f559.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ten_thirty',
                            alt: '🕥',
                            name: 'ten-thirty',
                            source: '/images/emojis/unicode/svg/1f565.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'eleven_oclock',
                            alt: '🕚',
                            name: 'eleven o’clock',
                            source: '/images/emojis/unicode/svg/1f55a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'eleven_thirty',
                            alt: '🕦',
                            name: 'eleven-thirty',
                            source: '/images/emojis/unicode/svg/1f566.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'sky & weather',
                    emojis: [
                        {
                            emojiCode: 'new_moon',
                            alt: '🌑',
                            name: 'new moon',
                            source: '/images/emojis/unicode/svg/1f311.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'waxing_crescent_moon',
                            alt: '🌒',
                            name: 'waxing crescent moon',
                            source: '/images/emojis/unicode/svg/1f312.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'first_quarter_moon',
                            alt: '🌓',
                            name: 'first quarter moon',
                            source: '/images/emojis/unicode/svg/1f313.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'waxing_gibbous_moon',
                            alt: '🌔',
                            name: 'waxing gibbous moon',
                            source: '/images/emojis/unicode/svg/1f314.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'full_moon',
                            alt: '🌕',
                            name: 'full moon',
                            source: '/images/emojis/unicode/svg/1f315.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'waning_gibbous_moon',
                            alt: '🌖',
                            name: 'waning gibbous moon',
                            source: '/images/emojis/unicode/svg/1f316.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'last_quarter_moon',
                            alt: '🌗',
                            name: 'last quarter moon',
                            source: '/images/emojis/unicode/svg/1f317.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'waning_crescent_moon',
                            alt: '🌘',
                            name: 'waning crescent moon',
                            source: '/images/emojis/unicode/svg/1f318.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'crescent_moon',
                            alt: '🌙',
                            name: 'crescent moon',
                            source: '/images/emojis/unicode/svg/1f319.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'new_moon_face',
                            alt: '🌚',
                            name: 'new moon face',
                            source: '/images/emojis/unicode/svg/1f31a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'first_quarter_moon_face',
                            alt: '🌛',
                            name: 'first quarter moon face',
                            source: '/images/emojis/unicode/svg/1f31b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'last_quarter_moon_face',
                            alt: '🌜',
                            name: 'last quarter moon face',
                            source: '/images/emojis/unicode/svg/1f31c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'thermometer',
                            alt: '🌡',
                            name: 'thermometer',
                            source: '/images/emojis/unicode/svg/1f321.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sun',
                            alt: '☀',
                            name: 'sun',
                            source: '/images/emojis/unicode/svg/2600.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'full_moon_face',
                            alt: '🌝',
                            name: 'full moon face',
                            source: '/images/emojis/unicode/svg/1f31d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sun_with_face',
                            alt: '🌞',
                            name: 'sun with face',
                            source: '/images/emojis/unicode/svg/1f31e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ringed_planet',
                            alt: '🪐',
                            name: 'ringed planet',
                            source: '/images/emojis/unicode/svg/1fa90.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'star',
                            alt: '⭐',
                            name: 'star',
                            source: '/images/emojis/unicode/svg/2b50.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'glowing_star',
                            alt: '🌟',
                            name: 'glowing star',
                            source: '/images/emojis/unicode/svg/1f31f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'shooting_star',
                            alt: '🌠',
                            name: 'shooting star',
                            source: '/images/emojis/unicode/svg/1f320.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'milky_way',
                            alt: '🌌',
                            name: 'milky way',
                            source: '/images/emojis/unicode/svg/1f30c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cloud',
                            alt: '☁',
                            name: 'cloud',
                            source: '/images/emojis/unicode/svg/2601.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sun_behind_cloud',
                            alt: '⛅',
                            name: 'sun behind cloud',
                            source: '/images/emojis/unicode/svg/26c5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cloud_with_lightning_and_rain',
                            alt: '⛈',
                            name: 'cloud with lightning and rain',
                            source: '/images/emojis/unicode/svg/26c8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sun_behind_small_cloud',
                            alt: '🌤',
                            name: 'sun behind small cloud',
                            source: '/images/emojis/unicode/svg/1f324.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sun_behind_large_cloud',
                            alt: '🌥',
                            name: 'sun behind large cloud',
                            source: '/images/emojis/unicode/svg/1f325.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sun_behind_rain_cloud',
                            alt: '🌦',
                            name: 'sun behind rain cloud',
                            source: '/images/emojis/unicode/svg/1f326.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cloud_with_rain',
                            alt: '🌧',
                            name: 'cloud with rain',
                            source: '/images/emojis/unicode/svg/1f327.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cloud_with_snow',
                            alt: '🌨',
                            name: 'cloud with snow',
                            source: '/images/emojis/unicode/svg/1f328.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cloud_with_lightning',
                            alt: '🌩',
                            name: 'cloud with lightning',
                            source: '/images/emojis/unicode/svg/1f329.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tornado',
                            alt: '🌪',
                            name: 'tornado',
                            source: '/images/emojis/unicode/svg/1f32a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fog',
                            alt: '🌫',
                            name: 'fog',
                            source: '/images/emojis/unicode/svg/1f32b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'wind_face',
                            alt: '🌬',
                            name: 'wind face',
                            source: '/images/emojis/unicode/svg/1f32c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cyclone',
                            alt: '🌀',
                            name: 'cyclone',
                            source: '/images/emojis/unicode/svg/1f300.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rainbow',
                            alt: '🌈',
                            name: 'rainbow',
                            source: '/images/emojis/unicode/svg/1f308.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'closed_umbrella',
                            alt: '🌂',
                            name: 'closed umbrella',
                            source: '/images/emojis/unicode/svg/1f302.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'umbrella',
                            alt: '☂',
                            name: 'umbrella',
                            source: '/images/emojis/unicode/svg/2602.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'umbrella_with_rain_drops',
                            alt: '☔',
                            name: 'umbrella with rain drops',
                            source: '/images/emojis/unicode/svg/2614.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'umbrella_on_ground',
                            alt: '⛱',
                            name: 'umbrella on ground',
                            source: '/images/emojis/unicode/svg/26f1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'high_voltage',
                            alt: '⚡',
                            name: 'high voltage',
                            source: '/images/emojis/unicode/svg/26a1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'snowflake',
                            alt: '❄',
                            name: 'snowflake',
                            source: '/images/emojis/unicode/svg/2744.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'snowman',
                            alt: '☃',
                            name: 'snowman',
                            source: '/images/emojis/unicode/svg/2603.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'snowman_without_snow',
                            alt: '⛄',
                            name: 'snowman without snow',
                            source: '/images/emojis/unicode/svg/26c4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'comet',
                            alt: '☄',
                            name: 'comet',
                            source: '/images/emojis/unicode/svg/2604.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fire',
                            alt: '🔥',
                            name: 'fire',
                            source: '/images/emojis/unicode/svg/1f525.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'droplet',
                            alt: '💧',
                            name: 'droplet',
                            source: '/images/emojis/unicode/svg/1f4a7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'water_wave',
                            alt: '🌊',
                            name: 'water wave',
                            source: '/images/emojis/unicode/svg/1f30a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                }
            ]
        },
        {
            name: 'Activities',
            subGroups: [
                {
                    name: 'event',
                    emojis: [
                        {
                            emojiCode: 'jack_o_lantern',
                            alt: '🎃',
                            name: 'jack-o-lantern',
                            source: '/images/emojis/unicode/svg/1f383.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'christmas_tree',
                            alt: '🎄',
                            name: 'Christmas tree',
                            source: '/images/emojis/unicode/svg/1f384.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fireworks',
                            alt: '🎆',
                            name: 'fireworks',
                            source: '/images/emojis/unicode/svg/1f386.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sparkler',
                            alt: '🎇',
                            name: 'sparkler',
                            source: '/images/emojis/unicode/svg/1f387.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'firecracker',
                            alt: '🧨',
                            name: 'firecracker',
                            source: '/images/emojis/unicode/svg/1f9e8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sparkles',
                            alt: '✨',
                            name: 'sparkles',
                            source: '/images/emojis/unicode/svg/2728.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'balloon',
                            alt: '🎈',
                            name: 'balloon',
                            source: '/images/emojis/unicode/svg/1f388.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'party_popper',
                            alt: '🎉',
                            name: 'party popper',
                            source: '/images/emojis/unicode/svg/1f389.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'confetti_ball',
                            alt: '🎊',
                            name: 'confetti ball',
                            source: '/images/emojis/unicode/svg/1f38a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tanabata_tree',
                            alt: '🎋',
                            name: 'tanabata tree',
                            source: '/images/emojis/unicode/svg/1f38b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pine_decoration',
                            alt: '🎍',
                            name: 'pine decoration',
                            source: '/images/emojis/unicode/svg/1f38d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_dolls',
                            alt: '🎎',
                            name: 'Japanese dolls',
                            source: '/images/emojis/unicode/svg/1f38e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'carp_streamer',
                            alt: '🎏',
                            name: 'carp streamer',
                            source: '/images/emojis/unicode/svg/1f38f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'wind_chime',
                            alt: '🎐',
                            name: 'wind chime',
                            source: '/images/emojis/unicode/svg/1f390.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'moon_viewing_ceremony',
                            alt: '🎑',
                            name: 'moon viewing ceremony',
                            source: '/images/emojis/unicode/svg/1f391.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'red_envelope',
                            alt: '🧧',
                            name: 'red envelope',
                            source: '/images/emojis/unicode/svg/1f9e7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ribbon',
                            alt: '🎀',
                            name: 'ribbon',
                            source: '/images/emojis/unicode/svg/1f380.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'wrapped_gift',
                            alt: '🎁',
                            name: 'wrapped gift',
                            source: '/images/emojis/unicode/svg/1f381.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'reminder_ribbon',
                            alt: '🎗',
                            name: 'reminder ribbon',
                            source: '/images/emojis/unicode/svg/1f397.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'admission_tickets',
                            alt: '🎟',
                            name: 'admission tickets',
                            source: '/images/emojis/unicode/svg/1f39f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ticket',
                            alt: '🎫',
                            name: 'ticket',
                            source: '/images/emojis/unicode/svg/1f3ab.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'award-medal',
                    emojis: [
                        {
                            emojiCode: 'military_medal',
                            alt: '🎖',
                            name: 'military medal',
                            source: '/images/emojis/unicode/svg/1f396.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'trophy',
                            alt: '🏆',
                            name: 'trophy',
                            source: '/images/emojis/unicode/svg/1f3c6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sports_medal',
                            alt: '🏅',
                            name: 'sports medal',
                            source: '/images/emojis/unicode/svg/1f3c5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: '1st_place_medal',
                            alt: '🥇',
                            name: '1st place medal',
                            source: '/images/emojis/unicode/svg/1f947.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: '2nd_place_medal',
                            alt: '🥈',
                            name: '2nd place medal',
                            source: '/images/emojis/unicode/svg/1f948.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: '3rd_place_medal',
                            alt: '🥉',
                            name: '3rd place medal',
                            source: '/images/emojis/unicode/svg/1f949.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'sport',
                    emojis: [
                        {
                            emojiCode: 'soccer_ball',
                            alt: '⚽',
                            name: 'soccer ball',
                            source: '/images/emojis/unicode/svg/26bd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'baseball',
                            alt: '⚾',
                            name: 'baseball',
                            source: '/images/emojis/unicode/svg/26be.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'softball',
                            alt: '🥎',
                            name: 'softball',
                            source: '/images/emojis/unicode/svg/1f94e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'basketball',
                            alt: '🏀',
                            name: 'basketball',
                            source: '/images/emojis/unicode/svg/1f3c0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'volleyball',
                            alt: '🏐',
                            name: 'volleyball',
                            source: '/images/emojis/unicode/svg/1f3d0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'american_football',
                            alt: '🏈',
                            name: 'american football',
                            source: '/images/emojis/unicode/svg/1f3c8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rugby_football',
                            alt: '🏉',
                            name: 'rugby football',
                            source: '/images/emojis/unicode/svg/1f3c9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tennis',
                            alt: '🎾',
                            name: 'tennis',
                            source: '/images/emojis/unicode/svg/1f3be.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'flying_disc',
                            alt: '🥏',
                            name: 'flying disc',
                            source: '/images/emojis/unicode/svg/1f94f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bowling',
                            alt: '🎳',
                            name: 'bowling',
                            source: '/images/emojis/unicode/svg/1f3b3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cricket_game',
                            alt: '🏏',
                            name: 'cricket game',
                            source: '/images/emojis/unicode/svg/1f3cf.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'field_hockey',
                            alt: '🏑',
                            name: 'field hockey',
                            source: '/images/emojis/unicode/svg/1f3d1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ice_hockey',
                            alt: '🏒',
                            name: 'ice hockey',
                            source: '/images/emojis/unicode/svg/1f3d2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'lacrosse',
                            alt: '🥍',
                            name: 'lacrosse',
                            source: '/images/emojis/unicode/svg/1f94d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ping_pong',
                            alt: '🏓',
                            name: 'ping pong',
                            source: '/images/emojis/unicode/svg/1f3d3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'badminton',
                            alt: '🏸',
                            name: 'badminton',
                            source: '/images/emojis/unicode/svg/1f3f8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'boxing_glove',
                            alt: '🥊',
                            name: 'boxing glove',
                            source: '/images/emojis/unicode/svg/1f94a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'martial_arts_uniform',
                            alt: '🥋',
                            name: 'martial arts uniform',
                            source: '/images/emojis/unicode/svg/1f94b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'goal_net',
                            alt: '🥅',
                            name: 'goal net',
                            source: '/images/emojis/unicode/svg/1f945.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'flag_in_hole',
                            alt: '⛳',
                            name: 'flag in hole',
                            source: '/images/emojis/unicode/svg/26f3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ice_skate',
                            alt: '⛸',
                            name: 'ice skate',
                            source: '/images/emojis/unicode/svg/26f8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fishing_pole',
                            alt: '🎣',
                            name: 'fishing pole',
                            source: '/images/emojis/unicode/svg/1f3a3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'diving_mask',
                            alt: '🤿',
                            name: 'diving mask',
                            source: '/images/emojis/unicode/svg/1f93f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'running_shirt',
                            alt: '🎽',
                            name: 'running shirt',
                            source: '/images/emojis/unicode/svg/1f3bd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'skis',
                            alt: '🎿',
                            name: 'skis',
                            source: '/images/emojis/unicode/svg/1f3bf.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sled',
                            alt: '🛷',
                            name: 'sled',
                            source: '/images/emojis/unicode/svg/1f6f7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'curling_stone',
                            alt: '🥌',
                            name: 'curling stone',
                            source: '/images/emojis/unicode/svg/1f94c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'game',
                    emojis: [
                        {
                            emojiCode: 'bullseye',
                            alt: '🎯',
                            name: 'bullseye',
                            source: '/images/emojis/unicode/svg/1f3af.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'yo_yo',
                            alt: '🪀',
                            name: 'yo-yo',
                            source: '/images/emojis/unicode/svg/1fa80.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'kite',
                            alt: '🪁',
                            name: 'kite',
                            source: '/images/emojis/unicode/svg/1fa81.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pool_8_ball',
                            alt: '🎱',
                            name: 'pool 8 ball',
                            source: '/images/emojis/unicode/svg/1f3b1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'crystal_ball',
                            alt: '🔮',
                            name: 'crystal ball',
                            source: '/images/emojis/unicode/svg/1f52e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'magic_wand',
                            alt: '🪄',
                            name: 'magic wand',
                            source: '/images/emojis/unicode/svg/1fa84.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'nazar_amulet',
                            alt: '🧿',
                            name: 'nazar amulet',
                            source: '/images/emojis/unicode/svg/1f9ff.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'video_game',
                            alt: '🎮',
                            name: 'video game',
                            source: '/images/emojis/unicode/svg/1f3ae.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'joystick',
                            alt: '🕹',
                            name: 'joystick',
                            source: '/images/emojis/unicode/svg/1f579.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'slot_machine',
                            alt: '🎰',
                            name: 'slot machine',
                            source: '/images/emojis/unicode/svg/1f3b0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'game_die',
                            alt: '🎲',
                            name: 'game die',
                            source: '/images/emojis/unicode/svg/1f3b2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'puzzle_piece',
                            alt: '🧩',
                            name: 'puzzle piece',
                            source: '/images/emojis/unicode/svg/1f9e9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'teddy_bear',
                            alt: '🧸',
                            name: 'teddy bear',
                            source: '/images/emojis/unicode/svg/1f9f8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pinata',
                            alt: '🪅',
                            name: 'piñata',
                            source: '/images/emojis/unicode/svg/1fa85.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'nesting_dolls',
                            alt: '🪆',
                            name: 'nesting dolls',
                            source: '/images/emojis/unicode/svg/1fa86.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'spade_suit',
                            alt: '♠',
                            name: 'spade suit',
                            source: '/images/emojis/unicode/svg/2660.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'heart_suit',
                            alt: '♥',
                            name: 'heart suit',
                            source: '/images/emojis/unicode/svg/2665.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'diamond_suit',
                            alt: '♦',
                            name: 'diamond suit',
                            source: '/images/emojis/unicode/svg/2666.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'club_suit',
                            alt: '♣',
                            name: 'club suit',
                            source: '/images/emojis/unicode/svg/2663.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'chess_pawn',
                            alt: '♟',
                            name: 'chess pawn',
                            source: '/images/emojis/unicode/svg/265f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'joker',
                            alt: '🃏',
                            name: 'joker',
                            source: '/images/emojis/unicode/svg/1f0cf.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mahjong_red_dragon',
                            alt: '🀄',
                            name: 'mahjong red dragon',
                            source: '/images/emojis/unicode/svg/1f004.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'flower_playing_cards',
                            alt: '🎴',
                            name: 'flower playing cards',
                            source: '/images/emojis/unicode/svg/1f3b4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'arts & crafts',
                    emojis: [
                        {
                            emojiCode: 'performing_arts',
                            alt: '🎭',
                            name: 'performing arts',
                            source: '/images/emojis/unicode/svg/1f3ad.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'framed_picture',
                            alt: '🖼',
                            name: 'framed picture',
                            source: '/images/emojis/unicode/svg/1f5bc.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'artist_palette',
                            alt: '🎨',
                            name: 'artist palette',
                            source: '/images/emojis/unicode/svg/1f3a8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'thread',
                            alt: '🧵',
                            name: 'thread',
                            source: '/images/emojis/unicode/svg/1f9f5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sewing_needle',
                            alt: '🪡',
                            name: 'sewing needle',
                            source: '/images/emojis/unicode/svg/1faa1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'yarn',
                            alt: '🧶',
                            name: 'yarn',
                            source: '/images/emojis/unicode/svg/1f9f6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'knot',
                            alt: '🪢',
                            name: 'knot',
                            source: '/images/emojis/unicode/svg/1faa2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                }
            ]
        },
        {
            name: 'Objects',
            subGroups: [
                {
                    name: 'clothing',
                    emojis: [
                        {
                            emojiCode: 'glasses',
                            alt: '👓',
                            name: 'glasses',
                            source: '/images/emojis/unicode/svg/1f453.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sunglasses',
                            alt: '🕶',
                            name: 'sunglasses',
                            source: '/images/emojis/unicode/svg/1f576.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'goggles',
                            alt: '🥽',
                            name: 'goggles',
                            source: '/images/emojis/unicode/svg/1f97d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'lab_coat',
                            alt: '🥼',
                            name: 'lab coat',
                            source: '/images/emojis/unicode/svg/1f97c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'safety_vest',
                            alt: '🦺',
                            name: 'safety vest',
                            source: '/images/emojis/unicode/svg/1f9ba.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'necktie',
                            alt: '👔',
                            name: 'necktie',
                            source: '/images/emojis/unicode/svg/1f454.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 't_shirt',
                            alt: '👕',
                            name: 't-shirt',
                            source: '/images/emojis/unicode/svg/1f455.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'jeans',
                            alt: '👖',
                            name: 'jeans',
                            source: '/images/emojis/unicode/svg/1f456.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'scarf',
                            alt: '🧣',
                            name: 'scarf',
                            source: '/images/emojis/unicode/svg/1f9e3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'gloves',
                            alt: '🧤',
                            name: 'gloves',
                            source: '/images/emojis/unicode/svg/1f9e4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'coat',
                            alt: '🧥',
                            name: 'coat',
                            source: '/images/emojis/unicode/svg/1f9e5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'socks',
                            alt: '🧦',
                            name: 'socks',
                            source: '/images/emojis/unicode/svg/1f9e6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dress',
                            alt: '👗',
                            name: 'dress',
                            source: '/images/emojis/unicode/svg/1f457.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'kimono',
                            alt: '👘',
                            name: 'kimono',
                            source: '/images/emojis/unicode/svg/1f458.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sari',
                            alt: '🥻',
                            name: 'sari',
                            source: '/images/emojis/unicode/svg/1f97b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'one_piece_swimsuit',
                            alt: '🩱',
                            name: 'one-piece swimsuit',
                            source: '/images/emojis/unicode/svg/1fa71.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'briefs',
                            alt: '🩲',
                            name: 'briefs',
                            source: '/images/emojis/unicode/svg/1fa72.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'shorts',
                            alt: '🩳',
                            name: 'shorts',
                            source: '/images/emojis/unicode/svg/1fa73.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bikini',
                            alt: '👙',
                            name: 'bikini',
                            source: '/images/emojis/unicode/svg/1f459.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'womans_clothes',
                            alt: '👚',
                            name: 'woman’s clothes',
                            source: '/images/emojis/unicode/svg/1f45a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'purse',
                            alt: '👛',
                            name: 'purse',
                            source: '/images/emojis/unicode/svg/1f45b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'handbag',
                            alt: '👜',
                            name: 'handbag',
                            source: '/images/emojis/unicode/svg/1f45c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'clutch_bag',
                            alt: '👝',
                            name: 'clutch bag',
                            source: '/images/emojis/unicode/svg/1f45d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'shopping_bags',
                            alt: '🛍',
                            name: 'shopping bags',
                            source: '/images/emojis/unicode/svg/1f6cd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'backpack',
                            alt: '🎒',
                            name: 'backpack',
                            source: '/images/emojis/unicode/svg/1f392.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'thong_sandal',
                            alt: '🩴',
                            name: 'thong sandal',
                            source: '/images/emojis/unicode/svg/1fa74.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mans_shoe',
                            alt: '👞',
                            name: 'man’s shoe',
                            source: '/images/emojis/unicode/svg/1f45e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'running_shoe',
                            alt: '👟',
                            name: 'running shoe',
                            source: '/images/emojis/unicode/svg/1f45f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hiking_boot',
                            alt: '🥾',
                            name: 'hiking boot',
                            source: '/images/emojis/unicode/svg/1f97e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'flat_shoe',
                            alt: '🥿',
                            name: 'flat shoe',
                            source: '/images/emojis/unicode/svg/1f97f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'high_heeled_shoe',
                            alt: '👠',
                            name: 'high-heeled shoe',
                            source: '/images/emojis/unicode/svg/1f460.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'womans_sandal',
                            alt: '👡',
                            name: 'woman’s sandal',
                            source: '/images/emojis/unicode/svg/1f461.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ballet_shoes',
                            alt: '🩰',
                            name: 'ballet shoes',
                            source: '/images/emojis/unicode/svg/1fa70.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'womans_boot',
                            alt: '👢',
                            name: 'woman’s boot',
                            source: '/images/emojis/unicode/svg/1f462.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'crown',
                            alt: '👑',
                            name: 'crown',
                            source: '/images/emojis/unicode/svg/1f451.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'womans_hat',
                            alt: '👒',
                            name: 'woman’s hat',
                            source: '/images/emojis/unicode/svg/1f452.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'top_hat',
                            alt: '🎩',
                            name: 'top hat',
                            source: '/images/emojis/unicode/svg/1f3a9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'graduation_cap',
                            alt: '🎓',
                            name: 'graduation cap',
                            source: '/images/emojis/unicode/svg/1f393.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'billed_cap',
                            alt: '🧢',
                            name: 'billed cap',
                            source: '/images/emojis/unicode/svg/1f9e2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'military_helmet',
                            alt: '🪖',
                            name: 'military helmet',
                            source: '/images/emojis/unicode/svg/1fa96.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rescue_workers_helmet',
                            alt: '⛑',
                            name: 'rescue worker’s helmet',
                            source: '/images/emojis/unicode/svg/26d1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'prayer_beads',
                            alt: '📿',
                            name: 'prayer beads',
                            source: '/images/emojis/unicode/svg/1f4ff.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'lipstick',
                            alt: '💄',
                            name: 'lipstick',
                            source: '/images/emojis/unicode/svg/1f484.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ring',
                            alt: '💍',
                            name: 'ring',
                            source: '/images/emojis/unicode/svg/1f48d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'gem_stone',
                            alt: '💎',
                            name: 'gem stone',
                            source: '/images/emojis/unicode/svg/1f48e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'sound',
                    emojis: [
                        {
                            emojiCode: 'muted_speaker',
                            alt: '🔇',
                            name: 'muted speaker',
                            source: '/images/emojis/unicode/svg/1f507.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'speaker_low_volume',
                            alt: '🔈',
                            name: 'speaker low volume',
                            source: '/images/emojis/unicode/svg/1f508.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'speaker_medium_volume',
                            alt: '🔉',
                            name: 'speaker medium volume',
                            source: '/images/emojis/unicode/svg/1f509.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'speaker_high_volume',
                            alt: '🔊',
                            name: 'speaker high volume',
                            source: '/images/emojis/unicode/svg/1f50a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'loudspeaker',
                            alt: '📢',
                            name: 'loudspeaker',
                            source: '/images/emojis/unicode/svg/1f4e2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'megaphone',
                            alt: '📣',
                            name: 'megaphone',
                            source: '/images/emojis/unicode/svg/1f4e3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'postal_horn',
                            alt: '📯',
                            name: 'postal horn',
                            source: '/images/emojis/unicode/svg/1f4ef.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bell',
                            alt: '🔔',
                            name: 'bell',
                            source: '/images/emojis/unicode/svg/1f514.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bell_with_slash',
                            alt: '🔕',
                            name: 'bell with slash',
                            source: '/images/emojis/unicode/svg/1f515.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'music',
                    emojis: [
                        {
                            emojiCode: 'musical_score',
                            alt: '🎼',
                            name: 'musical score',
                            source: '/images/emojis/unicode/svg/1f3bc.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'musical_note',
                            alt: '🎵',
                            name: 'musical note',
                            source: '/images/emojis/unicode/svg/1f3b5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'musical_notes',
                            alt: '🎶',
                            name: 'musical notes',
                            source: '/images/emojis/unicode/svg/1f3b6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'studio_microphone',
                            alt: '🎙',
                            name: 'studio microphone',
                            source: '/images/emojis/unicode/svg/1f399.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'level_slider',
                            alt: '🎚',
                            name: 'level slider',
                            source: '/images/emojis/unicode/svg/1f39a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'control_knobs',
                            alt: '🎛',
                            name: 'control knobs',
                            source: '/images/emojis/unicode/svg/1f39b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'microphone',
                            alt: '🎤',
                            name: 'microphone',
                            source: '/images/emojis/unicode/svg/1f3a4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'headphone',
                            alt: '🎧',
                            name: 'headphone',
                            source: '/images/emojis/unicode/svg/1f3a7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'radio',
                            alt: '📻',
                            name: 'radio',
                            source: '/images/emojis/unicode/svg/1f4fb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'musical-instrument',
                    emojis: [
                        {
                            emojiCode: 'saxophone',
                            alt: '🎷',
                            name: 'saxophone',
                            source: '/images/emojis/unicode/svg/1f3b7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'accordion',
                            alt: '🪗',
                            name: 'accordion',
                            source: '/images/emojis/unicode/svg/1fa97.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'guitar',
                            alt: '🎸',
                            name: 'guitar',
                            source: '/images/emojis/unicode/svg/1f3b8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'musical_keyboard',
                            alt: '🎹',
                            name: 'musical keyboard',
                            source: '/images/emojis/unicode/svg/1f3b9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'trumpet',
                            alt: '🎺',
                            name: 'trumpet',
                            source: '/images/emojis/unicode/svg/1f3ba.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'violin',
                            alt: '🎻',
                            name: 'violin',
                            source: '/images/emojis/unicode/svg/1f3bb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'banjo',
                            alt: '🪕',
                            name: 'banjo',
                            source: '/images/emojis/unicode/svg/1fa95.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'drum',
                            alt: '🥁',
                            name: 'drum',
                            source: '/images/emojis/unicode/svg/1f941.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'long_drum',
                            alt: '🪘',
                            name: 'long drum',
                            source: '/images/emojis/unicode/svg/1fa98.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'phone',
                    emojis: [
                        {
                            emojiCode: 'mobile_phone',
                            alt: '📱',
                            name: 'mobile phone',
                            source: '/images/emojis/unicode/svg/1f4f1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mobile_phone_with_arrow',
                            alt: '📲',
                            name: 'mobile phone with arrow',
                            source: '/images/emojis/unicode/svg/1f4f2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'telephone',
                            alt: '☎',
                            name: 'telephone',
                            source: '/images/emojis/unicode/svg/260e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'telephone_receiver',
                            alt: '📞',
                            name: 'telephone receiver',
                            source: '/images/emojis/unicode/svg/1f4de.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pager',
                            alt: '📟',
                            name: 'pager',
                            source: '/images/emojis/unicode/svg/1f4df.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fax_machine',
                            alt: '📠',
                            name: 'fax machine',
                            source: '/images/emojis/unicode/svg/1f4e0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'computer',
                    emojis: [
                        {
                            emojiCode: 'battery',
                            alt: '🔋',
                            name: 'battery',
                            source: '/images/emojis/unicode/svg/1f50b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'electric_plug',
                            alt: '🔌',
                            name: 'electric plug',
                            source: '/images/emojis/unicode/svg/1f50c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'laptop',
                            alt: '💻',
                            name: 'laptop',
                            source: '/images/emojis/unicode/svg/1f4bb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'desktop_computer',
                            alt: '🖥',
                            name: 'desktop computer',
                            source: '/images/emojis/unicode/svg/1f5a5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'printer',
                            alt: '🖨',
                            name: 'printer',
                            source: '/images/emojis/unicode/svg/1f5a8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'keyboard',
                            alt: '⌨',
                            name: 'keyboard',
                            source: '/images/emojis/unicode/svg/2328.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'computer_mouse',
                            alt: '🖱',
                            name: 'computer mouse',
                            source: '/images/emojis/unicode/svg/1f5b1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'trackball',
                            alt: '🖲',
                            name: 'trackball',
                            source: '/images/emojis/unicode/svg/1f5b2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'computer_disk',
                            alt: '💽',
                            name: 'computer disk',
                            source: '/images/emojis/unicode/svg/1f4bd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'floppy_disk',
                            alt: '💾',
                            name: 'floppy disk',
                            source: '/images/emojis/unicode/svg/1f4be.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'optical_disk',
                            alt: '💿',
                            name: 'optical disk',
                            source: '/images/emojis/unicode/svg/1f4bf.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dvd',
                            alt: '📀',
                            name: 'dvd',
                            source: '/images/emojis/unicode/svg/1f4c0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'abacus',
                            alt: '🧮',
                            name: 'abacus',
                            source: '/images/emojis/unicode/svg/1f9ee.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'light & video',
                    emojis: [
                        {
                            emojiCode: 'movie_camera',
                            alt: '🎥',
                            name: 'movie camera',
                            source: '/images/emojis/unicode/svg/1f3a5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'film_frames',
                            alt: '🎞',
                            name: 'film frames',
                            source: '/images/emojis/unicode/svg/1f39e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'film_projector',
                            alt: '📽',
                            name: 'film projector',
                            source: '/images/emojis/unicode/svg/1f4fd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'clapper_board',
                            alt: '🎬',
                            name: 'clapper board',
                            source: '/images/emojis/unicode/svg/1f3ac.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'television',
                            alt: '📺',
                            name: 'television',
                            source: '/images/emojis/unicode/svg/1f4fa.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'camera',
                            alt: '📷',
                            name: 'camera',
                            source: '/images/emojis/unicode/svg/1f4f7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'camera_with_flash',
                            alt: '📸',
                            name: 'camera with flash',
                            source: '/images/emojis/unicode/svg/1f4f8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'video_camera',
                            alt: '📹',
                            name: 'video camera',
                            source: '/images/emojis/unicode/svg/1f4f9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'videocassette',
                            alt: '📼',
                            name: 'videocassette',
                            source: '/images/emojis/unicode/svg/1f4fc.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'magnifying_glass_tilted_left',
                            alt: '🔍',
                            name: 'magnifying glass tilted left',
                            source: '/images/emojis/unicode/svg/1f50d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'magnifying_glass_tilted_right',
                            alt: '🔎',
                            name: 'magnifying glass tilted right',
                            source: '/images/emojis/unicode/svg/1f50e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'candle',
                            alt: '🕯',
                            name: 'candle',
                            source: '/images/emojis/unicode/svg/1f56f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'light_bulb',
                            alt: '💡',
                            name: 'light bulb',
                            source: '/images/emojis/unicode/svg/1f4a1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'flashlight',
                            alt: '🔦',
                            name: 'flashlight',
                            source: '/images/emojis/unicode/svg/1f526.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'red_paper_lantern',
                            alt: '🏮',
                            name: 'red paper lantern',
                            source: '/images/emojis/unicode/svg/1f3ee.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'diya_lamp',
                            alt: '🪔',
                            name: 'diya lamp',
                            source: '/images/emojis/unicode/svg/1fa94.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'book-paper',
                    emojis: [
                        {
                            emojiCode: 'notebook_with_decorative_cover',
                            alt: '📔',
                            name: 'notebook with decorative cover',
                            source: '/images/emojis/unicode/svg/1f4d4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'closed_book',
                            alt: '📕',
                            name: 'closed book',
                            source: '/images/emojis/unicode/svg/1f4d5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'open_book',
                            alt: '📖',
                            name: 'open book',
                            source: '/images/emojis/unicode/svg/1f4d6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'green_book',
                            alt: '📗',
                            name: 'green book',
                            source: '/images/emojis/unicode/svg/1f4d7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'blue_book',
                            alt: '📘',
                            name: 'blue book',
                            source: '/images/emojis/unicode/svg/1f4d8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'orange_book',
                            alt: '📙',
                            name: 'orange book',
                            source: '/images/emojis/unicode/svg/1f4d9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'books',
                            alt: '📚',
                            name: 'books',
                            source: '/images/emojis/unicode/svg/1f4da.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'notebook',
                            alt: '📓',
                            name: 'notebook',
                            source: '/images/emojis/unicode/svg/1f4d3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ledger',
                            alt: '📒',
                            name: 'ledger',
                            source: '/images/emojis/unicode/svg/1f4d2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'page_with_curl',
                            alt: '📃',
                            name: 'page with curl',
                            source: '/images/emojis/unicode/svg/1f4c3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'scroll',
                            alt: '📜',
                            name: 'scroll',
                            source: '/images/emojis/unicode/svg/1f4dc.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'page_facing_up',
                            alt: '📄',
                            name: 'page facing up',
                            source: '/images/emojis/unicode/svg/1f4c4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'newspaper',
                            alt: '📰',
                            name: 'newspaper',
                            source: '/images/emojis/unicode/svg/1f4f0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'rolled_up_newspaper',
                            alt: '🗞',
                            name: 'rolled-up newspaper',
                            source: '/images/emojis/unicode/svg/1f5de.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bookmark_tabs',
                            alt: '📑',
                            name: 'bookmark tabs',
                            source: '/images/emojis/unicode/svg/1f4d1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bookmark',
                            alt: '🔖',
                            name: 'bookmark',
                            source: '/images/emojis/unicode/svg/1f516.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'label',
                            alt: '🏷',
                            name: 'label',
                            source: '/images/emojis/unicode/svg/1f3f7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'money',
                    emojis: [
                        {
                            emojiCode: 'money_bag',
                            alt: '💰',
                            name: 'money bag',
                            source: '/images/emojis/unicode/svg/1f4b0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'coin',
                            alt: '🪙',
                            name: 'coin',
                            source: '/images/emojis/unicode/svg/1fa99.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'yen_banknote',
                            alt: '💴',
                            name: 'yen banknote',
                            source: '/images/emojis/unicode/svg/1f4b4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dollar_banknote',
                            alt: '💵',
                            name: 'dollar banknote',
                            source: '/images/emojis/unicode/svg/1f4b5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'euro_banknote',
                            alt: '💶',
                            name: 'euro banknote',
                            source: '/images/emojis/unicode/svg/1f4b6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pound_banknote',
                            alt: '💷',
                            name: 'pound banknote',
                            source: '/images/emojis/unicode/svg/1f4b7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'money_with_wings',
                            alt: '💸',
                            name: 'money with wings',
                            source: '/images/emojis/unicode/svg/1f4b8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'credit_card',
                            alt: '💳',
                            name: 'credit card',
                            source: '/images/emojis/unicode/svg/1f4b3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'receipt',
                            alt: '🧾',
                            name: 'receipt',
                            source: '/images/emojis/unicode/svg/1f9fe.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'chart_increasing_with_yen',
                            alt: '💹',
                            name: 'chart increasing with yen',
                            source: '/images/emojis/unicode/svg/1f4b9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'mail',
                    emojis: [
                        {
                            emojiCode: 'envelope',
                            alt: '✉',
                            name: 'envelope',
                            source: '/images/emojis/unicode/svg/2709.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'e_mail',
                            alt: '📧',
                            name: 'e-mail',
                            source: '/images/emojis/unicode/svg/1f4e7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'incoming_envelope',
                            alt: '📨',
                            name: 'incoming envelope',
                            source: '/images/emojis/unicode/svg/1f4e8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'envelope_with_arrow',
                            alt: '📩',
                            name: 'envelope with arrow',
                            source: '/images/emojis/unicode/svg/1f4e9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'outbox_tray',
                            alt: '📤',
                            name: 'outbox tray',
                            source: '/images/emojis/unicode/svg/1f4e4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'inbox_tray',
                            alt: '📥',
                            name: 'inbox tray',
                            source: '/images/emojis/unicode/svg/1f4e5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'package',
                            alt: '📦',
                            name: 'package',
                            source: '/images/emojis/unicode/svg/1f4e6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'closed_mailbox_with_raised_flag',
                            alt: '📫',
                            name: 'closed mailbox with raised flag',
                            source: '/images/emojis/unicode/svg/1f4eb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'closed_mailbox_with_lowered_flag',
                            alt: '📪',
                            name: 'closed mailbox with lowered flag',
                            source: '/images/emojis/unicode/svg/1f4ea.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'open_mailbox_with_raised_flag',
                            alt: '📬',
                            name: 'open mailbox with raised flag',
                            source: '/images/emojis/unicode/svg/1f4ec.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'open_mailbox_with_lowered_flag',
                            alt: '📭',
                            name: 'open mailbox with lowered flag',
                            source: '/images/emojis/unicode/svg/1f4ed.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'postbox',
                            alt: '📮',
                            name: 'postbox',
                            source: '/images/emojis/unicode/svg/1f4ee.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ballot_box_with_ballot',
                            alt: '🗳',
                            name: 'ballot box with ballot',
                            source: '/images/emojis/unicode/svg/1f5f3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'writing',
                    emojis: [
                        {
                            emojiCode: 'pencil',
                            alt: '✏',
                            name: 'pencil',
                            source: '/images/emojis/unicode/svg/270f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'black_nib',
                            alt: '✒',
                            name: 'black nib',
                            source: '/images/emojis/unicode/svg/2712.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fountain_pen',
                            alt: '🖋',
                            name: 'fountain pen',
                            source: '/images/emojis/unicode/svg/1f58b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pen',
                            alt: '🖊',
                            name: 'pen',
                            source: '/images/emojis/unicode/svg/1f58a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'paintbrush',
                            alt: '🖌',
                            name: 'paintbrush',
                            source: '/images/emojis/unicode/svg/1f58c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'crayon',
                            alt: '🖍',
                            name: 'crayon',
                            source: '/images/emojis/unicode/svg/1f58d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'memo',
                            alt: '📝',
                            name: 'memo',
                            source: '/images/emojis/unicode/svg/1f4dd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'office',
                    emojis: [
                        {
                            emojiCode: 'briefcase',
                            alt: '💼',
                            name: 'briefcase',
                            source: '/images/emojis/unicode/svg/1f4bc.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'file_folder',
                            alt: '📁',
                            name: 'file folder',
                            source: '/images/emojis/unicode/svg/1f4c1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'open_file_folder',
                            alt: '📂',
                            name: 'open file folder',
                            source: '/images/emojis/unicode/svg/1f4c2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'card_index_dividers',
                            alt: '🗂',
                            name: 'card index dividers',
                            source: '/images/emojis/unicode/svg/1f5c2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'calendar',
                            alt: '📅',
                            name: 'calendar',
                            source: '/images/emojis/unicode/svg/1f4c5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'tear_off_calendar',
                            alt: '📆',
                            name: 'tear-off calendar',
                            source: '/images/emojis/unicode/svg/1f4c6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'spiral_notepad',
                            alt: '🗒',
                            name: 'spiral notepad',
                            source: '/images/emojis/unicode/svg/1f5d2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'spiral_calendar',
                            alt: '🗓',
                            name: 'spiral calendar',
                            source: '/images/emojis/unicode/svg/1f5d3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'card_index',
                            alt: '📇',
                            name: 'card index',
                            source: '/images/emojis/unicode/svg/1f4c7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'chart_increasing',
                            alt: '📈',
                            name: 'chart increasing',
                            source: '/images/emojis/unicode/svg/1f4c8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'chart_decreasing',
                            alt: '📉',
                            name: 'chart decreasing',
                            source: '/images/emojis/unicode/svg/1f4c9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bar_chart',
                            alt: '📊',
                            name: 'bar chart',
                            source: '/images/emojis/unicode/svg/1f4ca.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'clipboard',
                            alt: '📋',
                            name: 'clipboard',
                            source: '/images/emojis/unicode/svg/1f4cb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pushpin',
                            alt: '📌',
                            name: 'pushpin',
                            source: '/images/emojis/unicode/svg/1f4cc.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'round_pushpin',
                            alt: '📍',
                            name: 'round pushpin',
                            source: '/images/emojis/unicode/svg/1f4cd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'paperclip',
                            alt: '📎',
                            name: 'paperclip',
                            source: '/images/emojis/unicode/svg/1f4ce.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'linked_paperclips',
                            alt: '🖇',
                            name: 'linked paperclips',
                            source: '/images/emojis/unicode/svg/1f587.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'straight_ruler',
                            alt: '📏',
                            name: 'straight ruler',
                            source: '/images/emojis/unicode/svg/1f4cf.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'triangular_ruler',
                            alt: '📐',
                            name: 'triangular ruler',
                            source: '/images/emojis/unicode/svg/1f4d0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'scissors',
                            alt: '✂',
                            name: 'scissors',
                            source: '/images/emojis/unicode/svg/2702.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'card_file_box',
                            alt: '🗃',
                            name: 'card file box',
                            source: '/images/emojis/unicode/svg/1f5c3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'file_cabinet',
                            alt: '🗄',
                            name: 'file cabinet',
                            source: '/images/emojis/unicode/svg/1f5c4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'wastebasket',
                            alt: '🗑',
                            name: 'wastebasket',
                            source: '/images/emojis/unicode/svg/1f5d1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'lock',
                    emojis: [
                        {
                            emojiCode: 'locked',
                            alt: '🔒',
                            name: 'locked',
                            source: '/images/emojis/unicode/svg/1f512.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'unlocked',
                            alt: '🔓',
                            name: 'unlocked',
                            source: '/images/emojis/unicode/svg/1f513.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'locked_with_pen',
                            alt: '🔏',
                            name: 'locked with pen',
                            source: '/images/emojis/unicode/svg/1f50f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'locked_with_key',
                            alt: '🔐',
                            name: 'locked with key',
                            source: '/images/emojis/unicode/svg/1f510.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'key',
                            alt: '🔑',
                            name: 'key',
                            source: '/images/emojis/unicode/svg/1f511.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'old_key',
                            alt: '🗝',
                            name: 'old key',
                            source: '/images/emojis/unicode/svg/1f5dd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'tool',
                    emojis: [
                        {
                            emojiCode: 'hammer',
                            alt: '🔨',
                            name: 'hammer',
                            source: '/images/emojis/unicode/svg/1f528.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'axe',
                            alt: '🪓',
                            name: 'axe',
                            source: '/images/emojis/unicode/svg/1fa93.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pick',
                            alt: '⛏',
                            name: 'pick',
                            source: '/images/emojis/unicode/svg/26cf.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hammer_and_pick',
                            alt: '⚒',
                            name: 'hammer and pick',
                            source: '/images/emojis/unicode/svg/2692.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hammer_and_wrench',
                            alt: '🛠',
                            name: 'hammer and wrench',
                            source: '/images/emojis/unicode/svg/1f6e0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dagger',
                            alt: '🗡',
                            name: 'dagger',
                            source: '/images/emojis/unicode/svg/1f5e1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'crossed_swords',
                            alt: '⚔',
                            name: 'crossed swords',
                            source: '/images/emojis/unicode/svg/2694.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'water_pistol',
                            alt: '🔫',
                            name: 'water pistol',
                            source: '/images/emojis/unicode/svg/1f52b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'boomerang',
                            alt: '🪃',
                            name: 'boomerang',
                            source: '/images/emojis/unicode/svg/1fa83.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bow_and_arrow',
                            alt: '🏹',
                            name: 'bow and arrow',
                            source: '/images/emojis/unicode/svg/1f3f9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'shield',
                            alt: '🛡',
                            name: 'shield',
                            source: '/images/emojis/unicode/svg/1f6e1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'carpentry_saw',
                            alt: '🪚',
                            name: 'carpentry saw',
                            source: '/images/emojis/unicode/svg/1fa9a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'wrench',
                            alt: '🔧',
                            name: 'wrench',
                            source: '/images/emojis/unicode/svg/1f527.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'screwdriver',
                            alt: '🪛',
                            name: 'screwdriver',
                            source: '/images/emojis/unicode/svg/1fa9b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'nut_and_bolt',
                            alt: '🔩',
                            name: 'nut and bolt',
                            source: '/images/emojis/unicode/svg/1f529.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'gear',
                            alt: '⚙',
                            name: 'gear',
                            source: '/images/emojis/unicode/svg/2699.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'clamp',
                            alt: '🗜',
                            name: 'clamp',
                            source: '/images/emojis/unicode/svg/1f5dc.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'balance_scale',
                            alt: '⚖',
                            name: 'balance scale',
                            source: '/images/emojis/unicode/svg/2696.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'white_cane',
                            alt: '🦯',
                            name: 'white cane',
                            source: '/images/emojis/unicode/svg/1f9af.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'link',
                            alt: '🔗',
                            name: 'link',
                            source: '/images/emojis/unicode/svg/1f517.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'chains',
                            alt: '⛓',
                            name: 'chains',
                            source: '/images/emojis/unicode/svg/26d3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hook',
                            alt: '🪝',
                            name: 'hook',
                            source: '/images/emojis/unicode/svg/1fa9d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'toolbox',
                            alt: '🧰',
                            name: 'toolbox',
                            source: '/images/emojis/unicode/svg/1f9f0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'magnet',
                            alt: '🧲',
                            name: 'magnet',
                            source: '/images/emojis/unicode/svg/1f9f2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ladder',
                            alt: '🪜',
                            name: 'ladder',
                            source: '/images/emojis/unicode/svg/1fa9c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'science',
                    emojis: [
                        {
                            emojiCode: 'alembic',
                            alt: '⚗',
                            name: 'alembic',
                            source: '/images/emojis/unicode/svg/2697.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'test_tube',
                            alt: '🧪',
                            name: 'test tube',
                            source: '/images/emojis/unicode/svg/1f9ea.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'petri_dish',
                            alt: '🧫',
                            name: 'petri dish',
                            source: '/images/emojis/unicode/svg/1f9eb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dna',
                            alt: '🧬',
                            name: 'dna',
                            source: '/images/emojis/unicode/svg/1f9ec.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'microscope',
                            alt: '🔬',
                            name: 'microscope',
                            source: '/images/emojis/unicode/svg/1f52c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'telescope',
                            alt: '🔭',
                            name: 'telescope',
                            source: '/images/emojis/unicode/svg/1f52d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'satellite_antenna',
                            alt: '📡',
                            name: 'satellite antenna',
                            source: '/images/emojis/unicode/svg/1f4e1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'medical',
                    emojis: [
                        {
                            emojiCode: 'syringe',
                            alt: '💉',
                            name: 'syringe',
                            source: '/images/emojis/unicode/svg/1f489.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'drop_of_blood',
                            alt: '🩸',
                            name: 'drop of blood',
                            source: '/images/emojis/unicode/svg/1fa78.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pill',
                            alt: '💊',
                            name: 'pill',
                            source: '/images/emojis/unicode/svg/1f48a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'adhesive_bandage',
                            alt: '🩹',
                            name: 'adhesive bandage',
                            source: '/images/emojis/unicode/svg/1fa79.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'stethoscope',
                            alt: '🩺',
                            name: 'stethoscope',
                            source: '/images/emojis/unicode/svg/1fa7a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'household',
                    emojis: [
                        {
                            emojiCode: 'door',
                            alt: '🚪',
                            name: 'door',
                            source: '/images/emojis/unicode/svg/1f6aa.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'elevator',
                            alt: '🛗',
                            name: 'elevator',
                            source: '/images/emojis/unicode/svg/1f6d7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mirror',
                            alt: '🪞',
                            name: 'mirror',
                            source: '/images/emojis/unicode/svg/1fa9e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'window',
                            alt: '🪟',
                            name: 'window',
                            source: '/images/emojis/unicode/svg/1fa9f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bed',
                            alt: '🛏',
                            name: 'bed',
                            source: '/images/emojis/unicode/svg/1f6cf.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'couch_and_lamp',
                            alt: '🛋',
                            name: 'couch and lamp',
                            source: '/images/emojis/unicode/svg/1f6cb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'chair',
                            alt: '🪑',
                            name: 'chair',
                            source: '/images/emojis/unicode/svg/1fa91.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'toilet',
                            alt: '🚽',
                            name: 'toilet',
                            source: '/images/emojis/unicode/svg/1f6bd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'plunger',
                            alt: '🪠',
                            name: 'plunger',
                            source: '/images/emojis/unicode/svg/1faa0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'shower',
                            alt: '🚿',
                            name: 'shower',
                            source: '/images/emojis/unicode/svg/1f6bf.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bathtub',
                            alt: '🛁',
                            name: 'bathtub',
                            source: '/images/emojis/unicode/svg/1f6c1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mouse_trap',
                            alt: '🪤',
                            name: 'mouse trap',
                            source: '/images/emojis/unicode/svg/1faa4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'razor',
                            alt: '🪒',
                            name: 'razor',
                            source: '/images/emojis/unicode/svg/1fa92.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'lotion_bottle',
                            alt: '🧴',
                            name: 'lotion bottle',
                            source: '/images/emojis/unicode/svg/1f9f4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'safety_pin',
                            alt: '🧷',
                            name: 'safety pin',
                            source: '/images/emojis/unicode/svg/1f9f7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'broom',
                            alt: '🧹',
                            name: 'broom',
                            source: '/images/emojis/unicode/svg/1f9f9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'basket',
                            alt: '🧺',
                            name: 'basket',
                            source: '/images/emojis/unicode/svg/1f9fa.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'roll_of_paper',
                            alt: '🧻',
                            name: 'roll of paper',
                            source: '/images/emojis/unicode/svg/1f9fb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bucket',
                            alt: '🪣',
                            name: 'bucket',
                            source: '/images/emojis/unicode/svg/1faa3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'soap',
                            alt: '🧼',
                            name: 'soap',
                            source: '/images/emojis/unicode/svg/1f9fc.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'toothbrush',
                            alt: '🪥',
                            name: 'toothbrush',
                            source: '/images/emojis/unicode/svg/1faa5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sponge',
                            alt: '🧽',
                            name: 'sponge',
                            source: '/images/emojis/unicode/svg/1f9fd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fire_extinguisher',
                            alt: '🧯',
                            name: 'fire extinguisher',
                            source: '/images/emojis/unicode/svg/1f9ef.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'shopping_cart',
                            alt: '🛒',
                            name: 'shopping cart',
                            source: '/images/emojis/unicode/svg/1f6d2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'other-object',
                    emojis: [
                        {
                            emojiCode: 'cigarette',
                            alt: '🚬',
                            name: 'cigarette',
                            source: '/images/emojis/unicode/svg/1f6ac.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'coffin',
                            alt: '⚰',
                            name: 'coffin',
                            source: '/images/emojis/unicode/svg/26b0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'headstone',
                            alt: '🪦',
                            name: 'headstone',
                            source: '/images/emojis/unicode/svg/1faa6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'funeral_urn',
                            alt: '⚱',
                            name: 'funeral urn',
                            source: '/images/emojis/unicode/svg/26b1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'moai',
                            alt: '🗿',
                            name: 'moai',
                            source: '/images/emojis/unicode/svg/1f5ff.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'placard',
                            alt: '🪧',
                            name: 'placard',
                            source: '/images/emojis/unicode/svg/1faa7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                }
            ]
        },
        {
            name: 'Symbols',
            subGroups: [
                {
                    name: 'transport-sign',
                    emojis: [
                        {
                            emojiCode: 'atm_sign',
                            alt: '🏧',
                            name: 'ATM sign',
                            source: '/images/emojis/unicode/svg/1f3e7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'litter_in_bin_sign',
                            alt: '🚮',
                            name: 'litter in bin sign',
                            source: '/images/emojis/unicode/svg/1f6ae.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'potable_water',
                            alt: '🚰',
                            name: 'potable water',
                            source: '/images/emojis/unicode/svg/1f6b0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'wheelchair_symbol',
                            alt: '♿',
                            name: 'wheelchair symbol',
                            source: '/images/emojis/unicode/svg/267f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mens_room',
                            alt: '🚹',
                            name: 'men’s room',
                            source: '/images/emojis/unicode/svg/1f6b9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'womens_room',
                            alt: '🚺',
                            name: 'women’s room',
                            source: '/images/emojis/unicode/svg/1f6ba.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'restroom',
                            alt: '🚻',
                            name: 'restroom',
                            source: '/images/emojis/unicode/svg/1f6bb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'baby_symbol',
                            alt: '🚼',
                            name: 'baby symbol',
                            source: '/images/emojis/unicode/svg/1f6bc.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'water_closet',
                            alt: '🚾',
                            name: 'water closet',
                            source: '/images/emojis/unicode/svg/1f6be.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'passport_control',
                            alt: '🛂',
                            name: 'passport control',
                            source: '/images/emojis/unicode/svg/1f6c2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'customs',
                            alt: '🛃',
                            name: 'customs',
                            source: '/images/emojis/unicode/svg/1f6c3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'baggage_claim',
                            alt: '🛄',
                            name: 'baggage claim',
                            source: '/images/emojis/unicode/svg/1f6c4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'left_luggage',
                            alt: '🛅',
                            name: 'left luggage',
                            source: '/images/emojis/unicode/svg/1f6c5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'warning',
                    emojis: [
                        {
                            emojiCode: 'warning',
                            alt: '⚠',
                            name: 'warning',
                            source: '/images/emojis/unicode/svg/26a0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'children_crossing',
                            alt: '🚸',
                            name: 'children crossing',
                            source: '/images/emojis/unicode/svg/1f6b8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'no_entry',
                            alt: '⛔',
                            name: 'no entry',
                            source: '/images/emojis/unicode/svg/26d4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'prohibited',
                            alt: '🚫',
                            name: 'prohibited',
                            source: '/images/emojis/unicode/svg/1f6ab.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'no_bicycles',
                            alt: '🚳',
                            name: 'no bicycles',
                            source: '/images/emojis/unicode/svg/1f6b3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'no_smoking',
                            alt: '🚭',
                            name: 'no smoking',
                            source: '/images/emojis/unicode/svg/1f6ad.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'no_littering',
                            alt: '🚯',
                            name: 'no littering',
                            source: '/images/emojis/unicode/svg/1f6af.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'non_potable_water',
                            alt: '🚱',
                            name: 'non-potable water',
                            source: '/images/emojis/unicode/svg/1f6b1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'no_pedestrians',
                            alt: '🚷',
                            name: 'no pedestrians',
                            source: '/images/emojis/unicode/svg/1f6b7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'no_mobile_phones',
                            alt: '📵',
                            name: 'no mobile phones',
                            source: '/images/emojis/unicode/svg/1f4f5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'no_one_under_eighteen',
                            alt: '🔞',
                            name: 'no one under eighteen',
                            source: '/images/emojis/unicode/svg/1f51e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'radioactive',
                            alt: '☢',
                            name: 'radioactive',
                            source: '/images/emojis/unicode/svg/2622.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'biohazard',
                            alt: '☣',
                            name: 'biohazard',
                            source: '/images/emojis/unicode/svg/2623.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'arrow',
                    emojis: [
                        {
                            emojiCode: 'up_arrow',
                            alt: '⬆',
                            name: 'up arrow',
                            source: '/images/emojis/unicode/svg/2b06.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'up_right_arrow',
                            alt: '↗',
                            name: 'up-right arrow',
                            source: '/images/emojis/unicode/svg/2197.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'right_arrow',
                            alt: '➡',
                            name: 'right arrow',
                            source: '/images/emojis/unicode/svg/27a1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'down_right_arrow',
                            alt: '↘',
                            name: 'down-right arrow',
                            source: '/images/emojis/unicode/svg/2198.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'down_arrow',
                            alt: '⬇',
                            name: 'down arrow',
                            source: '/images/emojis/unicode/svg/2b07.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'down_left_arrow',
                            alt: '↙',
                            name: 'down-left arrow',
                            source: '/images/emojis/unicode/svg/2199.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'left_arrow',
                            alt: '⬅',
                            name: 'left arrow',
                            source: '/images/emojis/unicode/svg/2b05.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'up_left_arrow',
                            alt: '↖',
                            name: 'up-left arrow',
                            source: '/images/emojis/unicode/svg/2196.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'up_down_arrow',
                            alt: '↕',
                            name: 'up-down arrow',
                            source: '/images/emojis/unicode/svg/2195.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'left_right_arrow',
                            alt: '↔',
                            name: 'left-right arrow',
                            source: '/images/emojis/unicode/svg/2194.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'right_arrow_curving_left',
                            alt: '↩',
                            name: 'right arrow curving left',
                            source: '/images/emojis/unicode/svg/21a9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'left_arrow_curving_right',
                            alt: '↪',
                            name: 'left arrow curving right',
                            source: '/images/emojis/unicode/svg/21aa.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'right_arrow_curving_up',
                            alt: '⤴',
                            name: 'right arrow curving up',
                            source: '/images/emojis/unicode/svg/2934.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'right_arrow_curving_down',
                            alt: '⤵',
                            name: 'right arrow curving down',
                            source: '/images/emojis/unicode/svg/2935.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'clockwise_vertical_arrows',
                            alt: '🔃',
                            name: 'clockwise vertical arrows',
                            source: '/images/emojis/unicode/svg/1f503.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'counterclockwise_arrows_button',
                            alt: '🔄',
                            name: 'counterclockwise arrows button',
                            source: '/images/emojis/unicode/svg/1f504.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'back_arrow',
                            alt: '🔙',
                            name: 'BACK arrow',
                            source: '/images/emojis/unicode/svg/1f519.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'end_arrow',
                            alt: '🔚',
                            name: 'END arrow',
                            source: '/images/emojis/unicode/svg/1f51a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'on_arrow',
                            alt: '🔛',
                            name: 'ON! arrow',
                            source: '/images/emojis/unicode/svg/1f51b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'soon_arrow',
                            alt: '🔜',
                            name: 'SOON arrow',
                            source: '/images/emojis/unicode/svg/1f51c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'top_arrow',
                            alt: '🔝',
                            name: 'TOP arrow',
                            source: '/images/emojis/unicode/svg/1f51d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'religion',
                    emojis: [
                        {
                            emojiCode: 'place_of_worship',
                            alt: '🛐',
                            name: 'place of worship',
                            source: '/images/emojis/unicode/svg/1f6d0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'atom_symbol',
                            alt: '⚛',
                            name: 'atom symbol',
                            source: '/images/emojis/unicode/svg/269b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'om',
                            alt: '🕉',
                            name: 'om',
                            source: '/images/emojis/unicode/svg/1f549.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'star_of_david',
                            alt: '✡',
                            name: 'star of David',
                            source: '/images/emojis/unicode/svg/2721.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'wheel_of_dharma',
                            alt: '☸',
                            name: 'wheel of dharma',
                            source: '/images/emojis/unicode/svg/2638.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'yin_yang',
                            alt: '☯',
                            name: 'yin yang',
                            source: '/images/emojis/unicode/svg/262f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'latin_cross',
                            alt: '✝',
                            name: 'latin cross',
                            source: '/images/emojis/unicode/svg/271d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'orthodox_cross',
                            alt: '☦',
                            name: 'orthodox cross',
                            source: '/images/emojis/unicode/svg/2626.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'star_and_crescent',
                            alt: '☪',
                            name: 'star and crescent',
                            source: '/images/emojis/unicode/svg/262a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'peace_symbol',
                            alt: '☮',
                            name: 'peace symbol',
                            source: '/images/emojis/unicode/svg/262e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'menorah',
                            alt: '🕎',
                            name: 'menorah',
                            source: '/images/emojis/unicode/svg/1f54e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dotted_six_pointed_star',
                            alt: '🔯',
                            name: 'dotted six-pointed star',
                            source: '/images/emojis/unicode/svg/1f52f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'zodiac',
                    emojis: [
                        {
                            emojiCode: 'aries',
                            alt: '♈',
                            name: 'Aries',
                            source: '/images/emojis/unicode/svg/2648.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'taurus',
                            alt: '♉',
                            name: 'Taurus',
                            source: '/images/emojis/unicode/svg/2649.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'gemini',
                            alt: '♊',
                            name: 'Gemini',
                            source: '/images/emojis/unicode/svg/264a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cancer',
                            alt: '♋',
                            name: 'Cancer',
                            source: '/images/emojis/unicode/svg/264b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'leo',
                            alt: '♌',
                            name: 'Leo',
                            source: '/images/emojis/unicode/svg/264c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'virgo',
                            alt: '♍',
                            name: 'Virgo',
                            source: '/images/emojis/unicode/svg/264d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'libra',
                            alt: '♎',
                            name: 'Libra',
                            source: '/images/emojis/unicode/svg/264e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'scorpio',
                            alt: '♏',
                            name: 'Scorpio',
                            source: '/images/emojis/unicode/svg/264f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sagittarius',
                            alt: '♐',
                            name: 'Sagittarius',
                            source: '/images/emojis/unicode/svg/2650.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'capricorn',
                            alt: '♑',
                            name: 'Capricorn',
                            source: '/images/emojis/unicode/svg/2651.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'aquarius',
                            alt: '♒',
                            name: 'Aquarius',
                            source: '/images/emojis/unicode/svg/2652.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pisces',
                            alt: '♓',
                            name: 'Pisces',
                            source: '/images/emojis/unicode/svg/2653.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ophiuchus',
                            alt: '⛎',
                            name: 'Ophiuchus',
                            source: '/images/emojis/unicode/svg/26ce.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'av-symbol',
                    emojis: [
                        {
                            emojiCode: 'shuffle_tracks_button',
                            alt: '🔀',
                            name: 'shuffle tracks button',
                            source: '/images/emojis/unicode/svg/1f500.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'repeat_button',
                            alt: '🔁',
                            name: 'repeat button',
                            source: '/images/emojis/unicode/svg/1f501.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'repeat_single_button',
                            alt: '🔂',
                            name: 'repeat single button',
                            source: '/images/emojis/unicode/svg/1f502.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'play_button',
                            alt: '▶',
                            name: 'play button',
                            source: '/images/emojis/unicode/svg/25b6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fast_forward_button',
                            alt: '⏩',
                            name: 'fast-forward button',
                            source: '/images/emojis/unicode/svg/23e9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'next_track_button',
                            alt: '⏭',
                            name: 'next track button',
                            source: '/images/emojis/unicode/svg/23ed.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'play_or_pause_button',
                            alt: '⏯',
                            name: 'play or pause button',
                            source: '/images/emojis/unicode/svg/23ef.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'reverse_button',
                            alt: '◀',
                            name: 'reverse button',
                            source: '/images/emojis/unicode/svg/25c0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fast_reverse_button',
                            alt: '⏪',
                            name: 'fast reverse button',
                            source: '/images/emojis/unicode/svg/23ea.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'last_track_button',
                            alt: '⏮',
                            name: 'last track button',
                            source: '/images/emojis/unicode/svg/23ee.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'upwards_button',
                            alt: '🔼',
                            name: 'upwards button',
                            source: '/images/emojis/unicode/svg/1f53c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fast_up_button',
                            alt: '⏫',
                            name: 'fast up button',
                            source: '/images/emojis/unicode/svg/23eb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'downwards_button',
                            alt: '🔽',
                            name: 'downwards button',
                            source: '/images/emojis/unicode/svg/1f53d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fast_down_button',
                            alt: '⏬',
                            name: 'fast down button',
                            source: '/images/emojis/unicode/svg/23ec.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pause_button',
                            alt: '⏸',
                            name: 'pause button',
                            source: '/images/emojis/unicode/svg/23f8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'stop_button',
                            alt: '⏹',
                            name: 'stop button',
                            source: '/images/emojis/unicode/svg/23f9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'record_button',
                            alt: '⏺',
                            name: 'record button',
                            source: '/images/emojis/unicode/svg/23fa.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'eject_button',
                            alt: '⏏',
                            name: 'eject button',
                            source: '/images/emojis/unicode/svg/23cf.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cinema',
                            alt: '🎦',
                            name: 'cinema',
                            source: '/images/emojis/unicode/svg/1f3a6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'dim_button',
                            alt: '🔅',
                            name: 'dim button',
                            source: '/images/emojis/unicode/svg/1f505.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'bright_button',
                            alt: '🔆',
                            name: 'bright button',
                            source: '/images/emojis/unicode/svg/1f506.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'antenna_bars',
                            alt: '📶',
                            name: 'antenna bars',
                            source: '/images/emojis/unicode/svg/1f4f6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'vibration_mode',
                            alt: '📳',
                            name: 'vibration mode',
                            source: '/images/emojis/unicode/svg/1f4f3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'mobile_phone_off',
                            alt: '📴',
                            name: 'mobile phone off',
                            source: '/images/emojis/unicode/svg/1f4f4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'gender',
                    emojis: [
                        {
                            emojiCode: 'female_sign',
                            alt: '♀',
                            name: 'female sign',
                            source: '/images/emojis/unicode/svg/2640.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'male_sign',
                            alt: '♂',
                            name: 'male sign',
                            source: '/images/emojis/unicode/svg/2642.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'transgender_symbol',
                            alt: '⚧',
                            name: 'transgender symbol',
                            source: '/images/emojis/unicode/svg/26a7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'math',
                    emojis: [
                        {
                            emojiCode: 'multiply',
                            alt: '✖',
                            name: 'multiply',
                            source: '/images/emojis/unicode/svg/2716.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'plus',
                            alt: '➕',
                            name: 'plus',
                            source: '/images/emojis/unicode/svg/2795.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'minus',
                            alt: '➖',
                            name: 'minus',
                            source: '/images/emojis/unicode/svg/2796.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'divide',
                            alt: '➗',
                            name: 'divide',
                            source: '/images/emojis/unicode/svg/2797.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'infinity',
                            alt: '♾',
                            name: 'infinity',
                            source: '/images/emojis/unicode/svg/267e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'punctuation',
                    emojis: [
                        {
                            emojiCode: 'double_exclamation_mark',
                            alt: '‼',
                            name: 'double exclamation mark',
                            source: '/images/emojis/unicode/svg/203c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'exclamation_question_mark',
                            alt: '⁉',
                            name: 'exclamation question mark',
                            source: '/images/emojis/unicode/svg/2049.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'red_question_mark',
                            alt: '❓',
                            name: 'red question mark',
                            source: '/images/emojis/unicode/svg/2753.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'white_question_mark',
                            alt: '❔',
                            name: 'white question mark',
                            source: '/images/emojis/unicode/svg/2754.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'white_exclamation_mark',
                            alt: '❕',
                            name: 'white exclamation mark',
                            source: '/images/emojis/unicode/svg/2755.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'red_exclamation_mark',
                            alt: '❗',
                            name: 'red exclamation mark',
                            source: '/images/emojis/unicode/svg/2757.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'wavy_dash',
                            alt: '〰',
                            name: 'wavy dash',
                            source: '/images/emojis/unicode/svg/3030.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'currency',
                    emojis: [
                        {
                            emojiCode: 'currency_exchange',
                            alt: '💱',
                            name: 'currency exchange',
                            source: '/images/emojis/unicode/svg/1f4b1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'heavy_dollar_sign',
                            alt: '💲',
                            name: 'heavy dollar sign',
                            source: '/images/emojis/unicode/svg/1f4b2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'other-symbol',
                    emojis: [
                        {
                            emojiCode: 'medical_symbol',
                            alt: '⚕',
                            name: 'medical symbol',
                            source: '/images/emojis/unicode/svg/2695.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'recycling_symbol',
                            alt: '♻',
                            name: 'recycling symbol',
                            source: '/images/emojis/unicode/svg/267b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'fleur_de_lis',
                            alt: '⚜',
                            name: 'fleur-de-lis',
                            source: '/images/emojis/unicode/svg/269c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'trident_emblem',
                            alt: '🔱',
                            name: 'trident emblem',
                            source: '/images/emojis/unicode/svg/1f531.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'name_badge',
                            alt: '📛',
                            name: 'name badge',
                            source: '/images/emojis/unicode/svg/1f4db.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_symbol_for_beginner',
                            alt: '🔰',
                            name: 'Japanese symbol for beginner',
                            source: '/images/emojis/unicode/svg/1f530.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'hollow_red_circle',
                            alt: '⭕',
                            name: 'hollow red circle',
                            source: '/images/emojis/unicode/svg/2b55.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'check_mark_button',
                            alt: '✅',
                            name: 'check mark button',
                            source: '/images/emojis/unicode/svg/2705.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'check_box_with_check',
                            alt: '☑',
                            name: 'check box with check',
                            source: '/images/emojis/unicode/svg/2611.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'check_mark',
                            alt: '✔',
                            name: 'check mark',
                            source: '/images/emojis/unicode/svg/2714.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cross_mark',
                            alt: '❌',
                            name: 'cross mark',
                            source: '/images/emojis/unicode/svg/274c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cross_mark_button',
                            alt: '❎',
                            name: 'cross mark button',
                            source: '/images/emojis/unicode/svg/274e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'curly_loop',
                            alt: '➰',
                            name: 'curly loop',
                            source: '/images/emojis/unicode/svg/27b0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'double_curly_loop',
                            alt: '➿',
                            name: 'double curly loop',
                            source: '/images/emojis/unicode/svg/27bf.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'part_alternation_mark',
                            alt: '〽',
                            name: 'part alternation mark',
                            source: '/images/emojis/unicode/svg/303d.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'eight_spoked_asterisk',
                            alt: '✳',
                            name: 'eight-spoked asterisk',
                            source: '/images/emojis/unicode/svg/2733.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'eight_pointed_star',
                            alt: '✴',
                            name: 'eight-pointed star',
                            source: '/images/emojis/unicode/svg/2734.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sparkle',
                            alt: '❇',
                            name: 'sparkle',
                            source: '/images/emojis/unicode/svg/2747.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'trade_mark',
                            alt: '™',
                            name: 'trade mark',
                            source: '/images/emojis/unicode/svg/2122.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'keycap',
                    emojis: []
                },
                {
                    name: 'alphanum',
                    emojis: [
                        {
                            emojiCode: 'input_latin_uppercase',
                            alt: '🔠',
                            name: 'input latin uppercase',
                            source: '/images/emojis/unicode/svg/1f520.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'input_latin_lowercase',
                            alt: '🔡',
                            name: 'input latin lowercase',
                            source: '/images/emojis/unicode/svg/1f521.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'input_numbers',
                            alt: '🔢',
                            name: 'input numbers',
                            source: '/images/emojis/unicode/svg/1f522.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'input_symbols',
                            alt: '🔣',
                            name: 'input symbols',
                            source: '/images/emojis/unicode/svg/1f523.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'input_latin_letters',
                            alt: '🔤',
                            name: 'input latin letters',
                            source: '/images/emojis/unicode/svg/1f524.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'a_button_blood_type',
                            alt: '🅰',
                            name: 'A button (blood type)',
                            source: '/images/emojis/unicode/svg/1f170.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ab_button_blood_type',
                            alt: '🆎',
                            name: 'AB button (blood type)',
                            source: '/images/emojis/unicode/svg/1f18e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'b_button_blood_type',
                            alt: '🅱',
                            name: 'B button (blood type)',
                            source: '/images/emojis/unicode/svg/1f171.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cl_button',
                            alt: '🆑',
                            name: 'CL button',
                            source: '/images/emojis/unicode/svg/1f191.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'cool_button',
                            alt: '🆒',
                            name: 'COOL button',
                            source: '/images/emojis/unicode/svg/1f192.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'free_button',
                            alt: '🆓',
                            name: 'FREE button',
                            source: '/images/emojis/unicode/svg/1f193.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'information',
                            alt: 'ℹ',
                            name: 'information',
                            source: '/images/emojis/unicode/svg/2139.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'id_button',
                            alt: '🆔',
                            name: 'ID button',
                            source: '/images/emojis/unicode/svg/1f194.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'circled_m',
                            alt: 'Ⓜ',
                            name: 'circled M',
                            source: '/images/emojis/unicode/svg/24c2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'new_button',
                            alt: '🆕',
                            name: 'NEW button',
                            source: '/images/emojis/unicode/svg/1f195.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ng_button',
                            alt: '🆖',
                            name: 'NG button',
                            source: '/images/emojis/unicode/svg/1f196.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'o_button_blood_type',
                            alt: '🅾',
                            name: 'O button (blood type)',
                            source: '/images/emojis/unicode/svg/1f17e.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'ok_button',
                            alt: '🆗',
                            name: 'OK button',
                            source: '/images/emojis/unicode/svg/1f197.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'p_button',
                            alt: '🅿',
                            name: 'P button',
                            source: '/images/emojis/unicode/svg/1f17f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'sos_button',
                            alt: '🆘',
                            name: 'SOS button',
                            source: '/images/emojis/unicode/svg/1f198.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'up_button',
                            alt: '🆙',
                            name: 'UP! button',
                            source: '/images/emojis/unicode/svg/1f199.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'vs_button',
                            alt: '🆚',
                            name: 'VS button',
                            source: '/images/emojis/unicode/svg/1f19a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_here_button',
                            alt: '🈁',
                            name: 'Japanese “here” button',
                            source: '/images/emojis/unicode/svg/1f201.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_service_charge_button',
                            alt: '🈂',
                            name: 'Japanese “service charge” button',
                            source: '/images/emojis/unicode/svg/1f202.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_monthly_amount_button',
                            alt: '🈷',
                            name: 'Japanese “monthly amount” button',
                            source: '/images/emojis/unicode/svg/1f237.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_not_free_of_charge_button',
                            alt: '🈶',
                            name: 'Japanese “not free of charge” button',
                            source: '/images/emojis/unicode/svg/1f236.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_reserved_button',
                            alt: '🈯',
                            name: 'Japanese “reserved” button',
                            source: '/images/emojis/unicode/svg/1f22f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_bargain_button',
                            alt: '🉐',
                            name: 'Japanese “bargain” button',
                            source: '/images/emojis/unicode/svg/1f250.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_discount_button',
                            alt: '🈹',
                            name: 'Japanese “discount” button',
                            source: '/images/emojis/unicode/svg/1f239.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_free_of_charge_button',
                            alt: '🈚',
                            name: 'Japanese “free of charge” button',
                            source: '/images/emojis/unicode/svg/1f21a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_prohibited_button',
                            alt: '🈲',
                            name: 'Japanese “prohibited” button',
                            source: '/images/emojis/unicode/svg/1f232.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_acceptable_button',
                            alt: '🉑',
                            name: 'Japanese “acceptable” button',
                            source: '/images/emojis/unicode/svg/1f251.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_application_button',
                            alt: '🈸',
                            name: 'Japanese “application” button',
                            source: '/images/emojis/unicode/svg/1f238.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_passing_grade_button',
                            alt: '🈴',
                            name: 'Japanese “passing grade” button',
                            source: '/images/emojis/unicode/svg/1f234.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_vacancy_button',
                            alt: '🈳',
                            name: 'Japanese “vacancy” button',
                            source: '/images/emojis/unicode/svg/1f233.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_congratulations_button',
                            alt: '㊗',
                            name: 'Japanese “congratulations” button',
                            source: '/images/emojis/unicode/svg/3297.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_secret_button',
                            alt: '㊙',
                            name: 'Japanese “secret” button',
                            source: '/images/emojis/unicode/svg/3299.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_open_for_business_button',
                            alt: '🈺',
                            name: 'Japanese “open for business” button',
                            source: '/images/emojis/unicode/svg/1f23a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'japanese_no_vacancy_button',
                            alt: '🈵',
                            name: 'Japanese “no vacancy” button',
                            source: '/images/emojis/unicode/svg/1f235.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'geometric',
                    emojis: [
                        {
                            emojiCode: 'red_circle',
                            alt: '🔴',
                            name: 'red circle',
                            source: '/images/emojis/unicode/svg/1f534.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'orange_circle',
                            alt: '🟠',
                            name: 'orange circle',
                            source: '/images/emojis/unicode/svg/1f7e0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'yellow_circle',
                            alt: '🟡',
                            name: 'yellow circle',
                            source: '/images/emojis/unicode/svg/1f7e1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'green_circle',
                            alt: '🟢',
                            name: 'green circle',
                            source: '/images/emojis/unicode/svg/1f7e2.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'blue_circle',
                            alt: '🔵',
                            name: 'blue circle',
                            source: '/images/emojis/unicode/svg/1f535.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'purple_circle',
                            alt: '🟣',
                            name: 'purple circle',
                            source: '/images/emojis/unicode/svg/1f7e3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'brown_circle',
                            alt: '🟤',
                            name: 'brown circle',
                            source: '/images/emojis/unicode/svg/1f7e4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'black_circle',
                            alt: '⚫',
                            name: 'black circle',
                            source: '/images/emojis/unicode/svg/26ab.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'white_circle',
                            alt: '⚪',
                            name: 'white circle',
                            source: '/images/emojis/unicode/svg/26aa.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'red_square',
                            alt: '🟥',
                            name: 'red square',
                            source: '/images/emojis/unicode/svg/1f7e5.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'orange_square',
                            alt: '🟧',
                            name: 'orange square',
                            source: '/images/emojis/unicode/svg/1f7e7.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'yellow_square',
                            alt: '🟨',
                            name: 'yellow square',
                            source: '/images/emojis/unicode/svg/1f7e8.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'green_square',
                            alt: '🟩',
                            name: 'green square',
                            source: '/images/emojis/unicode/svg/1f7e9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'blue_square',
                            alt: '🟦',
                            name: 'blue square',
                            source: '/images/emojis/unicode/svg/1f7e6.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'purple_square',
                            alt: '🟪',
                            name: 'purple square',
                            source: '/images/emojis/unicode/svg/1f7ea.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'brown_square',
                            alt: '🟫',
                            name: 'brown square',
                            source: '/images/emojis/unicode/svg/1f7eb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'black_large_square',
                            alt: '⬛',
                            name: 'black large square',
                            source: '/images/emojis/unicode/svg/2b1b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'white_large_square',
                            alt: '⬜',
                            name: 'white large square',
                            source: '/images/emojis/unicode/svg/2b1c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'black_medium_square',
                            alt: '◼',
                            name: 'black medium square',
                            source: '/images/emojis/unicode/svg/25fc.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'white_medium_square',
                            alt: '◻',
                            name: 'white medium square',
                            source: '/images/emojis/unicode/svg/25fb.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'black_medium_small_square',
                            alt: '◾',
                            name: 'black medium-small square',
                            source: '/images/emojis/unicode/svg/25fe.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'white_medium_small_square',
                            alt: '◽',
                            name: 'white medium-small square',
                            source: '/images/emojis/unicode/svg/25fd.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'black_small_square',
                            alt: '▪',
                            name: 'black small square',
                            source: '/images/emojis/unicode/svg/25aa.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'white_small_square',
                            alt: '▫',
                            name: 'white small square',
                            source: '/images/emojis/unicode/svg/25ab.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'large_orange_diamond',
                            alt: '🔶',
                            name: 'large orange diamond',
                            source: '/images/emojis/unicode/svg/1f536.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'large_blue_diamond',
                            alt: '🔷',
                            name: 'large blue diamond',
                            source: '/images/emojis/unicode/svg/1f537.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'small_orange_diamond',
                            alt: '🔸',
                            name: 'small orange diamond',
                            source: '/images/emojis/unicode/svg/1f538.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'small_blue_diamond',
                            alt: '🔹',
                            name: 'small blue diamond',
                            source: '/images/emojis/unicode/svg/1f539.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'red_triangle_pointed_up',
                            alt: '🔺',
                            name: 'red triangle pointed up',
                            source: '/images/emojis/unicode/svg/1f53a.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'red_triangle_pointed_down',
                            alt: '🔻',
                            name: 'red triangle pointed down',
                            source: '/images/emojis/unicode/svg/1f53b.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'diamond_with_a_dot',
                            alt: '💠',
                            name: 'diamond with a dot',
                            source: '/images/emojis/unicode/svg/1f4a0.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'radio_button',
                            alt: '🔘',
                            name: 'radio button',
                            source: '/images/emojis/unicode/svg/1f518.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'white_square_button',
                            alt: '🔳',
                            name: 'white square button',
                            source: '/images/emojis/unicode/svg/1f533.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'black_square_button',
                            alt: '🔲',
                            name: 'black square button',
                            source: '/images/emojis/unicode/svg/1f532.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                }
            ]
        },
        {
            name: 'Flags',
            subGroups: [
                {
                    name: 'flag',
                    emojis: [
                        {
                            emojiCode: 'chequered_flag',
                            alt: '🏁',
                            name: 'chequered flag',
                            source: '/images/emojis/unicode/svg/1f3c1.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'triangular_flag',
                            alt: '🚩',
                            name: 'triangular flag',
                            source: '/images/emojis/unicode/svg/1f6a9.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'crossed_flags',
                            alt: '🎌',
                            name: 'crossed flags',
                            source: '/images/emojis/unicode/svg/1f38c.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'black_flag',
                            alt: '🏴',
                            name: 'black flag',
                            source: '/images/emojis/unicode/svg/1f3f4.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'white_flag',
                            alt: '🏳',
                            name: 'white flag',
                            source: '/images/emojis/unicode/svg/1f3f3.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'transgender_flag',
                            alt: '🏳️‍⚧️',
                            name: 'transgender flag',
                            source: '/images/emojis/unicode/svg/1f3f3-fe0f-200d-26a7-fe0f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        },
                        {
                            emojiCode: 'pirate_flag',
                            alt: '🏴‍☠️',
                            name: 'pirate flag',
                            source: '/images/emojis/unicode/svg/1f3f4-200d-2620-fe0f.svg',
                            components: [],
                            variants: [],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'country-flag',
                    emojis: [
                        {
                            emojiCode: 'flag_ascension_island',
                            alt: '🇦🇨',
                            name: 'flag',
                            source: '/images/emojis/unicode/svg/1f1e6-1f1e8.svg',
                            components: ['Ascension Island'],
                            variants: [
                                {
                                    emojiCode: 'flag_andorra',
                                    alt: '🇦🇩',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1e9.svg',
                                    components: ['Andorra'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_united_arab_emirates',
                                    alt: '🇦🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1ea.svg',
                                    components: ['United Arab Emirates'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_afghanistan',
                                    alt: '🇦🇫',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1eb.svg',
                                    components: ['Afghanistan'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_antigua__barbuda',
                                    alt: '🇦🇬',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1ec.svg',
                                    components: ['Antigua & Barbuda'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_anguilla',
                                    alt: '🇦🇮',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1ee.svg',
                                    components: ['Anguilla'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_albania',
                                    alt: '🇦🇱',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1f1.svg',
                                    components: ['Albania'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_armenia',
                                    alt: '🇦🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1f2.svg',
                                    components: ['Armenia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_angola',
                                    alt: '🇦🇴',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1f4.svg',
                                    components: ['Angola'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_antarctica',
                                    alt: '🇦🇶',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1f6.svg',
                                    components: ['Antarctica'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_argentina',
                                    alt: '🇦🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1f7.svg',
                                    components: ['Argentina'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_american_samoa',
                                    alt: '🇦🇸',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1f8.svg',
                                    components: ['American Samoa'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_austria',
                                    alt: '🇦🇹',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1f9.svg',
                                    components: ['Austria'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_australia',
                                    alt: '🇦🇺',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1fa.svg',
                                    components: ['Australia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_aruba',
                                    alt: '🇦🇼',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1fc.svg',
                                    components: ['Aruba'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_aland_islands',
                                    alt: '🇦🇽',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1fd.svg',
                                    components: ['Åland Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_azerbaijan',
                                    alt: '🇦🇿',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e6-1f1ff.svg',
                                    components: ['Azerbaijan'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_bosnia__herzegovina',
                                    alt: '🇧🇦',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1e6.svg',
                                    components: ['Bosnia & Herzegovina'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_barbados',
                                    alt: '🇧🇧',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1e7.svg',
                                    components: ['Barbados'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_bangladesh',
                                    alt: '🇧🇩',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1e9.svg',
                                    components: ['Bangladesh'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_belgium',
                                    alt: '🇧🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1ea.svg',
                                    components: ['Belgium'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_burkina_faso',
                                    alt: '🇧🇫',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1eb.svg',
                                    components: ['Burkina Faso'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_bulgaria',
                                    alt: '🇧🇬',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1ec.svg',
                                    components: ['Bulgaria'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_bahrain',
                                    alt: '🇧🇭',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1ed.svg',
                                    components: ['Bahrain'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_burundi',
                                    alt: '🇧🇮',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1ee.svg',
                                    components: ['Burundi'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_benin',
                                    alt: '🇧🇯',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1ef.svg',
                                    components: ['Benin'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_st_barthelemy',
                                    alt: '🇧🇱',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1f1.svg',
                                    components: ['St. Barthélemy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_bermuda',
                                    alt: '🇧🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1f2.svg',
                                    components: ['Bermuda'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_brunei',
                                    alt: '🇧🇳',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1f3.svg',
                                    components: ['Brunei'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_bolivia',
                                    alt: '🇧🇴',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1f4.svg',
                                    components: ['Bolivia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_caribbean_netherlands',
                                    alt: '🇧🇶',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1f6.svg',
                                    components: ['Caribbean Netherlands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_brazil',
                                    alt: '🇧🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1f7.svg',
                                    components: ['Brazil'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_bahamas',
                                    alt: '🇧🇸',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1f8.svg',
                                    components: ['Bahamas'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_bhutan',
                                    alt: '🇧🇹',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1f9.svg',
                                    components: ['Bhutan'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_bouvet_island',
                                    alt: '🇧🇻',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1fb.svg',
                                    components: ['Bouvet Island'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_botswana',
                                    alt: '🇧🇼',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1fc.svg',
                                    components: ['Botswana'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_belarus',
                                    alt: '🇧🇾',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1fe.svg',
                                    components: ['Belarus'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_belize',
                                    alt: '🇧🇿',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e7-1f1ff.svg',
                                    components: ['Belize'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_canada',
                                    alt: '🇨🇦',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1e6.svg',
                                    components: ['Canada'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_cocos_keeling_islands',
                                    alt: '🇨🇨',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1e8.svg',
                                    components: ['Cocos (Keeling) Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_congo___kinshasa',
                                    alt: '🇨🇩',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1e9.svg',
                                    components: ['Congo - Kinshasa'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_central_african_republic',
                                    alt: '🇨🇫',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1eb.svg',
                                    components: ['Central African Republic'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_congo___brazzaville',
                                    alt: '🇨🇬',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1ec.svg',
                                    components: ['Congo - Brazzaville'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_switzerland',
                                    alt: '🇨🇭',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1ed.svg',
                                    components: ['Switzerland'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_cote_divoire',
                                    alt: '🇨🇮',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1ee.svg',
                                    components: ['Côte d’Ivoire'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_cook_islands',
                                    alt: '🇨🇰',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1f0.svg',
                                    components: ['Cook Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_chile',
                                    alt: '🇨🇱',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1f1.svg',
                                    components: ['Chile'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_cameroon',
                                    alt: '🇨🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1f2.svg',
                                    components: ['Cameroon'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_china',
                                    alt: '🇨🇳',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1f3.svg',
                                    components: ['China'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_colombia',
                                    alt: '🇨🇴',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1f4.svg',
                                    components: ['Colombia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_clipperton_island',
                                    alt: '🇨🇵',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1f5.svg',
                                    components: ['Clipperton Island'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_costa_rica',
                                    alt: '🇨🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1f7.svg',
                                    components: ['Costa Rica'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_cuba',
                                    alt: '🇨🇺',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1fa.svg',
                                    components: ['Cuba'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_cape_verde',
                                    alt: '🇨🇻',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1fb.svg',
                                    components: ['Cape Verde'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_curacao',
                                    alt: '🇨🇼',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1fc.svg',
                                    components: ['Curaçao'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_christmas_island',
                                    alt: '🇨🇽',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1fd.svg',
                                    components: ['Christmas Island'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_cyprus',
                                    alt: '🇨🇾',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1fe.svg',
                                    components: ['Cyprus'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_czechia',
                                    alt: '🇨🇿',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e8-1f1ff.svg',
                                    components: ['Czechia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_germany',
                                    alt: '🇩🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e9-1f1ea.svg',
                                    components: ['Germany'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_diego_garcia',
                                    alt: '🇩🇬',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e9-1f1ec.svg',
                                    components: ['Diego Garcia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_djibouti',
                                    alt: '🇩🇯',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e9-1f1ef.svg',
                                    components: ['Djibouti'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_denmark',
                                    alt: '🇩🇰',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e9-1f1f0.svg',
                                    components: ['Denmark'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_dominica',
                                    alt: '🇩🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e9-1f1f2.svg',
                                    components: ['Dominica'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_dominican_republic',
                                    alt: '🇩🇴',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e9-1f1f4.svg',
                                    components: ['Dominican Republic'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_algeria',
                                    alt: '🇩🇿',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1e9-1f1ff.svg',
                                    components: ['Algeria'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_ceuta__melilla',
                                    alt: '🇪🇦',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ea-1f1e6.svg',
                                    components: ['Ceuta & Melilla'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_ecuador',
                                    alt: '🇪🇨',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ea-1f1e8.svg',
                                    components: ['Ecuador'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_estonia',
                                    alt: '🇪🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ea-1f1ea.svg',
                                    components: ['Estonia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_egypt',
                                    alt: '🇪🇬',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ea-1f1ec.svg',
                                    components: ['Egypt'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_western_sahara',
                                    alt: '🇪🇭',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ea-1f1ed.svg',
                                    components: ['Western Sahara'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_eritrea',
                                    alt: '🇪🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ea-1f1f7.svg',
                                    components: ['Eritrea'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_spain',
                                    alt: '🇪🇸',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ea-1f1f8.svg',
                                    components: ['Spain'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_ethiopia',
                                    alt: '🇪🇹',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ea-1f1f9.svg',
                                    components: ['Ethiopia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_european_union',
                                    alt: '🇪🇺',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ea-1f1fa.svg',
                                    components: ['European Union'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_finland',
                                    alt: '🇫🇮',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1eb-1f1ee.svg',
                                    components: ['Finland'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_fiji',
                                    alt: '🇫🇯',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1eb-1f1ef.svg',
                                    components: ['Fiji'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_falkland_islands',
                                    alt: '🇫🇰',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1eb-1f1f0.svg',
                                    components: ['Falkland Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_micronesia',
                                    alt: '🇫🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1eb-1f1f2.svg',
                                    components: ['Micronesia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_faroe_islands',
                                    alt: '🇫🇴',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1eb-1f1f4.svg',
                                    components: ['Faroe Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_france',
                                    alt: '🇫🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1eb-1f1f7.svg',
                                    components: ['France'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_gabon',
                                    alt: '🇬🇦',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1e6.svg',
                                    components: ['Gabon'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_united_kingdom',
                                    alt: '🇬🇧',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1e7.svg',
                                    components: ['United Kingdom'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_grenada',
                                    alt: '🇬🇩',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1e9.svg',
                                    components: ['Grenada'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_georgia',
                                    alt: '🇬🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1ea.svg',
                                    components: ['Georgia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_french_guiana',
                                    alt: '🇬🇫',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1eb.svg',
                                    components: ['French Guiana'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_guernsey',
                                    alt: '🇬🇬',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1ec.svg',
                                    components: ['Guernsey'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_ghana',
                                    alt: '🇬🇭',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1ed.svg',
                                    components: ['Ghana'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_gibraltar',
                                    alt: '🇬🇮',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1ee.svg',
                                    components: ['Gibraltar'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_greenland',
                                    alt: '🇬🇱',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1f1.svg',
                                    components: ['Greenland'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_gambia',
                                    alt: '🇬🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1f2.svg',
                                    components: ['Gambia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_guinea',
                                    alt: '🇬🇳',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1f3.svg',
                                    components: ['Guinea'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_guadeloupe',
                                    alt: '🇬🇵',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1f5.svg',
                                    components: ['Guadeloupe'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_equatorial_guinea',
                                    alt: '🇬🇶',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1f6.svg',
                                    components: ['Equatorial Guinea'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_greece',
                                    alt: '🇬🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1f7.svg',
                                    components: ['Greece'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'flag_south_georgia__south_sandwich_islands',
                                    alt: '🇬🇸',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1f8.svg',
                                    components: [
                                        'South Georgia & South Sandwich Islands'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_guatemala',
                                    alt: '🇬🇹',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1f9.svg',
                                    components: ['Guatemala'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_guam',
                                    alt: '🇬🇺',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1fa.svg',
                                    components: ['Guam'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_guinea_bissau',
                                    alt: '🇬🇼',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1fc.svg',
                                    components: ['Guinea-Bissau'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_guyana',
                                    alt: '🇬🇾',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ec-1f1fe.svg',
                                    components: ['Guyana'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_hong_kong_sar_china',
                                    alt: '🇭🇰',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ed-1f1f0.svg',
                                    components: ['Hong Kong SAR China'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_heard__mcdonald_islands',
                                    alt: '🇭🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ed-1f1f2.svg',
                                    components: ['Heard & McDonald Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_honduras',
                                    alt: '🇭🇳',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ed-1f1f3.svg',
                                    components: ['Honduras'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_croatia',
                                    alt: '🇭🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ed-1f1f7.svg',
                                    components: ['Croatia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_haiti',
                                    alt: '🇭🇹',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ed-1f1f9.svg',
                                    components: ['Haiti'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_hungary',
                                    alt: '🇭🇺',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ed-1f1fa.svg',
                                    components: ['Hungary'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_canary_islands',
                                    alt: '🇮🇨',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ee-1f1e8.svg',
                                    components: ['Canary Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_indonesia',
                                    alt: '🇮🇩',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ee-1f1e9.svg',
                                    components: ['Indonesia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_ireland',
                                    alt: '🇮🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ee-1f1ea.svg',
                                    components: ['Ireland'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_israel',
                                    alt: '🇮🇱',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ee-1f1f1.svg',
                                    components: ['Israel'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_isle_of_man',
                                    alt: '🇮🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ee-1f1f2.svg',
                                    components: ['Isle of Man'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_india',
                                    alt: '🇮🇳',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ee-1f1f3.svg',
                                    components: ['India'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'flag_british_indian_ocean_territory',
                                    alt: '🇮🇴',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ee-1f1f4.svg',
                                    components: [
                                        'British Indian Ocean Territory'
                                    ],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_iraq',
                                    alt: '🇮🇶',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ee-1f1f6.svg',
                                    components: ['Iraq'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_iran',
                                    alt: '🇮🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ee-1f1f7.svg',
                                    components: ['Iran'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_iceland',
                                    alt: '🇮🇸',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ee-1f1f8.svg',
                                    components: ['Iceland'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_italy',
                                    alt: '🇮🇹',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ee-1f1f9.svg',
                                    components: ['Italy'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_jersey',
                                    alt: '🇯🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ef-1f1ea.svg',
                                    components: ['Jersey'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_jamaica',
                                    alt: '🇯🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ef-1f1f2.svg',
                                    components: ['Jamaica'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_jordan',
                                    alt: '🇯🇴',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ef-1f1f4.svg',
                                    components: ['Jordan'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_japan',
                                    alt: '🇯🇵',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ef-1f1f5.svg',
                                    components: ['Japan'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_kenya',
                                    alt: '🇰🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f0-1f1ea.svg',
                                    components: ['Kenya'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_kyrgyzstan',
                                    alt: '🇰🇬',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f0-1f1ec.svg',
                                    components: ['Kyrgyzstan'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_cambodia',
                                    alt: '🇰🇭',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f0-1f1ed.svg',
                                    components: ['Cambodia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_kiribati',
                                    alt: '🇰🇮',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f0-1f1ee.svg',
                                    components: ['Kiribati'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_comoros',
                                    alt: '🇰🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f0-1f1f2.svg',
                                    components: ['Comoros'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_st_kitts__nevis',
                                    alt: '🇰🇳',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f0-1f1f3.svg',
                                    components: ['St. Kitts & Nevis'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_north_korea',
                                    alt: '🇰🇵',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f0-1f1f5.svg',
                                    components: ['North Korea'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_south_korea',
                                    alt: '🇰🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f0-1f1f7.svg',
                                    components: ['South Korea'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_kuwait',
                                    alt: '🇰🇼',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f0-1f1fc.svg',
                                    components: ['Kuwait'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_cayman_islands',
                                    alt: '🇰🇾',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f0-1f1fe.svg',
                                    components: ['Cayman Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_kazakhstan',
                                    alt: '🇰🇿',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f0-1f1ff.svg',
                                    components: ['Kazakhstan'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_laos',
                                    alt: '🇱🇦',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f1-1f1e6.svg',
                                    components: ['Laos'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_lebanon',
                                    alt: '🇱🇧',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f1-1f1e7.svg',
                                    components: ['Lebanon'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_st_lucia',
                                    alt: '🇱🇨',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f1-1f1e8.svg',
                                    components: ['St. Lucia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_liechtenstein',
                                    alt: '🇱🇮',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f1-1f1ee.svg',
                                    components: ['Liechtenstein'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_sri_lanka',
                                    alt: '🇱🇰',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f1-1f1f0.svg',
                                    components: ['Sri Lanka'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_liberia',
                                    alt: '🇱🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f1-1f1f7.svg',
                                    components: ['Liberia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_lesotho',
                                    alt: '🇱🇸',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f1-1f1f8.svg',
                                    components: ['Lesotho'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_lithuania',
                                    alt: '🇱🇹',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f1-1f1f9.svg',
                                    components: ['Lithuania'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_luxembourg',
                                    alt: '🇱🇺',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f1-1f1fa.svg',
                                    components: ['Luxembourg'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_latvia',
                                    alt: '🇱🇻',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f1-1f1fb.svg',
                                    components: ['Latvia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_libya',
                                    alt: '🇱🇾',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f1-1f1fe.svg',
                                    components: ['Libya'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_morocco',
                                    alt: '🇲🇦',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1e6.svg',
                                    components: ['Morocco'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_monaco',
                                    alt: '🇲🇨',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1e8.svg',
                                    components: ['Monaco'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_moldova',
                                    alt: '🇲🇩',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1e9.svg',
                                    components: ['Moldova'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_montenegro',
                                    alt: '🇲🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1ea.svg',
                                    components: ['Montenegro'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_st_martin',
                                    alt: '🇲🇫',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1eb.svg',
                                    components: ['St. Martin'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_madagascar',
                                    alt: '🇲🇬',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1ec.svg',
                                    components: ['Madagascar'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_marshall_islands',
                                    alt: '🇲🇭',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1ed.svg',
                                    components: ['Marshall Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_north_macedonia',
                                    alt: '🇲🇰',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1f0.svg',
                                    components: ['North Macedonia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_mali',
                                    alt: '🇲🇱',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1f1.svg',
                                    components: ['Mali'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_myanmar_burma',
                                    alt: '🇲🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1f2.svg',
                                    components: ['Myanmar (Burma)'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_mongolia',
                                    alt: '🇲🇳',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1f3.svg',
                                    components: ['Mongolia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_macao_sar_china',
                                    alt: '🇲🇴',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1f4.svg',
                                    components: ['Macao SAR China'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_northern_mariana_islands',
                                    alt: '🇲🇵',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1f5.svg',
                                    components: ['Northern Mariana Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_martinique',
                                    alt: '🇲🇶',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1f6.svg',
                                    components: ['Martinique'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_mauritania',
                                    alt: '🇲🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1f7.svg',
                                    components: ['Mauritania'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_montserrat',
                                    alt: '🇲🇸',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1f8.svg',
                                    components: ['Montserrat'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_malta',
                                    alt: '🇲🇹',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1f9.svg',
                                    components: ['Malta'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_mauritius',
                                    alt: '🇲🇺',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1fa.svg',
                                    components: ['Mauritius'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_maldives',
                                    alt: '🇲🇻',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1fb.svg',
                                    components: ['Maldives'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_malawi',
                                    alt: '🇲🇼',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1fc.svg',
                                    components: ['Malawi'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_mexico',
                                    alt: '🇲🇽',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1fd.svg',
                                    components: ['Mexico'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_malaysia',
                                    alt: '🇲🇾',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1fe.svg',
                                    components: ['Malaysia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_mozambique',
                                    alt: '🇲🇿',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f2-1f1ff.svg',
                                    components: ['Mozambique'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_namibia',
                                    alt: '🇳🇦',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f3-1f1e6.svg',
                                    components: ['Namibia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_new_caledonia',
                                    alt: '🇳🇨',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f3-1f1e8.svg',
                                    components: ['New Caledonia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_niger',
                                    alt: '🇳🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f3-1f1ea.svg',
                                    components: ['Niger'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_norfolk_island',
                                    alt: '🇳🇫',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f3-1f1eb.svg',
                                    components: ['Norfolk Island'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_nigeria',
                                    alt: '🇳🇬',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f3-1f1ec.svg',
                                    components: ['Nigeria'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_nicaragua',
                                    alt: '🇳🇮',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f3-1f1ee.svg',
                                    components: ['Nicaragua'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_netherlands',
                                    alt: '🇳🇱',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f3-1f1f1.svg',
                                    components: ['Netherlands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_norway',
                                    alt: '🇳🇴',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f3-1f1f4.svg',
                                    components: ['Norway'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_nepal',
                                    alt: '🇳🇵',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f3-1f1f5.svg',
                                    components: ['Nepal'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_nauru',
                                    alt: '🇳🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f3-1f1f7.svg',
                                    components: ['Nauru'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_niue',
                                    alt: '🇳🇺',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f3-1f1fa.svg',
                                    components: ['Niue'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_new_zealand',
                                    alt: '🇳🇿',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f3-1f1ff.svg',
                                    components: ['New Zealand'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_oman',
                                    alt: '🇴🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f4-1f1f2.svg',
                                    components: ['Oman'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_panama',
                                    alt: '🇵🇦',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f5-1f1e6.svg',
                                    components: ['Panama'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_peru',
                                    alt: '🇵🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f5-1f1ea.svg',
                                    components: ['Peru'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_french_polynesia',
                                    alt: '🇵🇫',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f5-1f1eb.svg',
                                    components: ['French Polynesia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_papua_new_guinea',
                                    alt: '🇵🇬',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f5-1f1ec.svg',
                                    components: ['Papua New Guinea'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_philippines',
                                    alt: '🇵🇭',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f5-1f1ed.svg',
                                    components: ['Philippines'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_pakistan',
                                    alt: '🇵🇰',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f5-1f1f0.svg',
                                    components: ['Pakistan'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_poland',
                                    alt: '🇵🇱',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f5-1f1f1.svg',
                                    components: ['Poland'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_st_pierre__miquelon',
                                    alt: '🇵🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f5-1f1f2.svg',
                                    components: ['St. Pierre & Miquelon'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_pitcairn_islands',
                                    alt: '🇵🇳',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f5-1f1f3.svg',
                                    components: ['Pitcairn Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_puerto_rico',
                                    alt: '🇵🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f5-1f1f7.svg',
                                    components: ['Puerto Rico'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_palestinian_territories',
                                    alt: '🇵🇸',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f5-1f1f8.svg',
                                    components: ['Palestinian Territories'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_portugal',
                                    alt: '🇵🇹',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f5-1f1f9.svg',
                                    components: ['Portugal'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_palau',
                                    alt: '🇵🇼',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f5-1f1fc.svg',
                                    components: ['Palau'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_paraguay',
                                    alt: '🇵🇾',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f5-1f1fe.svg',
                                    components: ['Paraguay'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_qatar',
                                    alt: '🇶🇦',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f6-1f1e6.svg',
                                    components: ['Qatar'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_reunion',
                                    alt: '🇷🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f7-1f1ea.svg',
                                    components: ['Réunion'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_romania',
                                    alt: '🇷🇴',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f7-1f1f4.svg',
                                    components: ['Romania'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_serbia',
                                    alt: '🇷🇸',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f7-1f1f8.svg',
                                    components: ['Serbia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_russia',
                                    alt: '🇷🇺',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f7-1f1fa.svg',
                                    components: ['Russia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_rwanda',
                                    alt: '🇷🇼',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f7-1f1fc.svg',
                                    components: ['Rwanda'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_saudi_arabia',
                                    alt: '🇸🇦',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1e6.svg',
                                    components: ['Saudi Arabia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_solomon_islands',
                                    alt: '🇸🇧',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1e7.svg',
                                    components: ['Solomon Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_seychelles',
                                    alt: '🇸🇨',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1e8.svg',
                                    components: ['Seychelles'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_sudan',
                                    alt: '🇸🇩',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1e9.svg',
                                    components: ['Sudan'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_sweden',
                                    alt: '🇸🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1ea.svg',
                                    components: ['Sweden'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_singapore',
                                    alt: '🇸🇬',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1ec.svg',
                                    components: ['Singapore'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_st_helena',
                                    alt: '🇸🇭',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1ed.svg',
                                    components: ['St. Helena'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_slovenia',
                                    alt: '🇸🇮',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1ee.svg',
                                    components: ['Slovenia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_svalbard__jan_mayen',
                                    alt: '🇸🇯',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1ef.svg',
                                    components: ['Svalbard & Jan Mayen'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_slovakia',
                                    alt: '🇸🇰',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1f0.svg',
                                    components: ['Slovakia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_sierra_leone',
                                    alt: '🇸🇱',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1f1.svg',
                                    components: ['Sierra Leone'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_san_marino',
                                    alt: '🇸🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1f2.svg',
                                    components: ['San Marino'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_senegal',
                                    alt: '🇸🇳',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1f3.svg',
                                    components: ['Senegal'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_somalia',
                                    alt: '🇸🇴',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1f4.svg',
                                    components: ['Somalia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_suriname',
                                    alt: '🇸🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1f7.svg',
                                    components: ['Suriname'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_south_sudan',
                                    alt: '🇸🇸',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1f8.svg',
                                    components: ['South Sudan'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_sao_tome__principe',
                                    alt: '🇸🇹',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1f9.svg',
                                    components: ['São Tomé & Príncipe'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_el_salvador',
                                    alt: '🇸🇻',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1fb.svg',
                                    components: ['El Salvador'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_sint_maarten',
                                    alt: '🇸🇽',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1fd.svg',
                                    components: ['Sint Maarten'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_syria',
                                    alt: '🇸🇾',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1fe.svg',
                                    components: ['Syria'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_eswatini',
                                    alt: '🇸🇿',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f8-1f1ff.svg',
                                    components: ['Eswatini'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_tristan_da_cunha',
                                    alt: '🇹🇦',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1e6.svg',
                                    components: ['Tristan da Cunha'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_turks__caicos_islands',
                                    alt: '🇹🇨',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1e8.svg',
                                    components: ['Turks & Caicos Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_chad',
                                    alt: '🇹🇩',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1e9.svg',
                                    components: ['Chad'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode:
                                        'flag_french_southern_territories',
                                    alt: '🇹🇫',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1eb.svg',
                                    components: ['French Southern Territories'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_togo',
                                    alt: '🇹🇬',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1ec.svg',
                                    components: ['Togo'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_thailand',
                                    alt: '🇹🇭',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1ed.svg',
                                    components: ['Thailand'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_tajikistan',
                                    alt: '🇹🇯',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1ef.svg',
                                    components: ['Tajikistan'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_tokelau',
                                    alt: '🇹🇰',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1f0.svg',
                                    components: ['Tokelau'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_timor_leste',
                                    alt: '🇹🇱',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1f1.svg',
                                    components: ['Timor-Leste'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_turkmenistan',
                                    alt: '🇹🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1f2.svg',
                                    components: ['Turkmenistan'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_tunisia',
                                    alt: '🇹🇳',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1f3.svg',
                                    components: ['Tunisia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_tonga',
                                    alt: '🇹🇴',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1f4.svg',
                                    components: ['Tonga'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_turkey',
                                    alt: '🇹🇷',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1f7.svg',
                                    components: ['Turkey'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_trinidad__tobago',
                                    alt: '🇹🇹',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1f9.svg',
                                    components: ['Trinidad & Tobago'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_tuvalu',
                                    alt: '🇹🇻',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1fb.svg',
                                    components: ['Tuvalu'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_taiwan',
                                    alt: '🇹🇼',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1fc.svg',
                                    components: ['Taiwan'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_tanzania',
                                    alt: '🇹🇿',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1f9-1f1ff.svg',
                                    components: ['Tanzania'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_ukraine',
                                    alt: '🇺🇦',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fa-1f1e6.svg',
                                    components: ['Ukraine'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_uganda',
                                    alt: '🇺🇬',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fa-1f1ec.svg',
                                    components: ['Uganda'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_us_outlying_islands',
                                    alt: '🇺🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fa-1f1f2.svg',
                                    components: ['U.S. Outlying Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_united_nations',
                                    alt: '🇺🇳',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fa-1f1f3.svg',
                                    components: ['United Nations'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_united_states',
                                    alt: '🇺🇸',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fa-1f1f8.svg',
                                    components: ['United States'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_uruguay',
                                    alt: '🇺🇾',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fa-1f1fe.svg',
                                    components: ['Uruguay'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_uzbekistan',
                                    alt: '🇺🇿',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fa-1f1ff.svg',
                                    components: ['Uzbekistan'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_vatican_city',
                                    alt: '🇻🇦',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fb-1f1e6.svg',
                                    components: ['Vatican City'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_st_vincent__grenadines',
                                    alt: '🇻🇨',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fb-1f1e8.svg',
                                    components: ['St. Vincent & Grenadines'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_venezuela',
                                    alt: '🇻🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fb-1f1ea.svg',
                                    components: ['Venezuela'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_british_virgin_islands',
                                    alt: '🇻🇬',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fb-1f1ec.svg',
                                    components: ['British Virgin Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_us_virgin_islands',
                                    alt: '🇻🇮',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fb-1f1ee.svg',
                                    components: ['U.S. Virgin Islands'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_vietnam',
                                    alt: '🇻🇳',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fb-1f1f3.svg',
                                    components: ['Vietnam'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_vanuatu',
                                    alt: '🇻🇺',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fb-1f1fa.svg',
                                    components: ['Vanuatu'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_wallis__futuna',
                                    alt: '🇼🇫',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fc-1f1eb.svg',
                                    components: ['Wallis & Futuna'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_samoa',
                                    alt: '🇼🇸',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fc-1f1f8.svg',
                                    components: ['Samoa'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_kosovo',
                                    alt: '🇽🇰',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fd-1f1f0.svg',
                                    components: ['Kosovo'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_yemen',
                                    alt: '🇾🇪',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fe-1f1ea.svg',
                                    components: ['Yemen'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_mayotte',
                                    alt: '🇾🇹',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1fe-1f1f9.svg',
                                    components: ['Mayotte'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_south_africa',
                                    alt: '🇿🇦',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ff-1f1e6.svg',
                                    components: ['South Africa'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_zambia',
                                    alt: '🇿🇲',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ff-1f1f2.svg',
                                    components: ['Zambia'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_zimbabwe',
                                    alt: '🇿🇼',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f1ff-1f1fc.svg',
                                    components: ['Zimbabwe'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                },
                {
                    name: 'subdivision-flag',
                    emojis: [
                        {
                            emojiCode: 'flag_england',
                            alt: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
                            name: 'flag',
                            source: '/images/emojis/unicode/svg/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.svg',
                            components: ['England'],
                            variants: [
                                {
                                    emojiCode: 'flag_scotland',
                                    alt: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.svg',
                                    components: ['Scotland'],
                                    variants: [],
                                    type: 2
                                },
                                {
                                    emojiCode: 'flag_wales',
                                    alt: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
                                    name: 'flag',
                                    source: '/images/emojis/unicode/svg/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.svg',
                                    components: ['Wales'],
                                    variants: [],
                                    type: 2
                                }
                            ],
                            type: 2
                        }
                    ]
                }
            ]
        },
        {
            name: 'ASCII',
            subGroups: [
                {
                    name: '',
                    emojis: [
                        {
                            emojiCode: '#)',
                            alt: '😵',
                            name: '#)',
                            source: '/images/emojis/ascii/u0023u0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '#-)',
                            alt: '😵',
                            name: '#-)',
                            source: '/images/emojis/ascii/u0023-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '%)',
                            alt: '😵',
                            name: '%)',
                            source: '/images/emojis/ascii/u0025u0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '%-)',
                            alt: '😵',
                            name: '%-)',
                            source: '/images/emojis/ascii/u0025-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '(y)',
                            alt: '👍',
                            name: '(y)',
                            source: '/images/emojis/ascii/u0028yu0079u0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '*)',
                            alt: '😉',
                            name: '*)',
                            source: '/images/emojis/ascii/u002Au0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '*-)',
                            alt: '😉',
                            name: '*-)',
                            source: '/images/emojis/ascii/u002A-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '*\\0/*',
                            alt: '🙆',
                            name: '*\\0/*',
                            source: '/images/emojis/ascii/u002Au005C0u0030u002Fu002A.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '*\\O/*',
                            alt: '🙆',
                            name: '*\\O/*',
                            source: '/images/emojis/ascii/u002Au005COu004Fu002Fu002A.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':#',
                            alt: '😶',
                            name: ':#',
                            source: '/images/emojis/ascii/u0023.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':-#',
                            alt: '😶',
                            name: ':-#',
                            source: '/images/emojis/ascii/-u002Du0023.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':$',
                            alt: '😳',
                            name: ':$',
                            source: '/images/emojis/ascii/u0024.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':(',
                            alt: '😞',
                            name: ':(',
                            source: '/images/emojis/ascii/u0028.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ":'(",
                            alt: '😢',
                            name: ":'(",
                            source: '/images/emojis/ascii/u0027u0028.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ":'-(",
                            alt: '😢',
                            name: ":'-(",
                            source: '/images/emojis/ascii/u0027-u002Du0028.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':-(',
                            alt: '😞',
                            name: ':-(',
                            source: '/images/emojis/ascii/-u002Du0028.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: "':(",
                            alt: '😓',
                            name: "':(",
                            source: '/images/emojis/ascii/u0027u0028.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: "':-(",
                            alt: '😓',
                            name: "':-(",
                            source: '/images/emojis/ascii/u0027-u002Du0028.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':)',
                            alt: '🙂',
                            name: ':)',
                            source: '/images/emojis/ascii/u0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ":')",
                            alt: '😂',
                            name: ":')",
                            source: '/images/emojis/ascii/u0027u0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ":'-)",
                            alt: '😂',
                            name: ":'-)",
                            source: '/images/emojis/ascii/u0027-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':-)',
                            alt: '🙂',
                            name: ':-)',
                            source: '/images/emojis/ascii/-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: "':)",
                            alt: '😅',
                            name: "':)",
                            source: '/images/emojis/ascii/u0027u0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: "':-)",
                            alt: '😅',
                            name: "':-)",
                            source: '/images/emojis/ascii/u0027-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':*',
                            alt: '😘',
                            name: ':*',
                            source: '/images/emojis/ascii/u002A.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':-*',
                            alt: '😘',
                            name: ':-*',
                            source: '/images/emojis/ascii/-u002Du002A.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':-.',
                            alt: '😕',
                            name: ':-.',
                            source: '/images/emojis/ascii/-u002Du002E.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':/',
                            alt: '😕',
                            name: ':/',
                            source: '/images/emojis/ascii/u002F.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':-/',
                            alt: '😕',
                            name: ':-/',
                            source: '/images/emojis/ascii/-u002Du002F.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':@',
                            alt: '😠',
                            name: ':@',
                            source: '/images/emojis/ascii/u0040.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':[',
                            alt: '😞',
                            name: ':[',
                            source: '/images/emojis/ascii/u005B.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':-[',
                            alt: '😞',
                            name: ':-[',
                            source: '/images/emojis/ascii/-u002Du005B.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':\\',
                            alt: '😕',
                            name: ':\\',
                            source: '/images/emojis/ascii/u005C.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':]',
                            alt: '🙂',
                            name: ':]',
                            source: '/images/emojis/ascii/u005D.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':^*',
                            alt: '😘',
                            name: ':^*',
                            source: '/images/emojis/ascii/u005Eu002A.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':b',
                            alt: '😛',
                            name: ':b',
                            source: '/images/emojis/ascii/bu0062.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':-b',
                            alt: '😛',
                            name: ':-b',
                            source: '/images/emojis/ascii/-u002Dbu0062.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':D',
                            alt: '😃',
                            name: ':D',
                            source: '/images/emojis/ascii/Du0044.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':-D',
                            alt: '😃',
                            name: ':-D',
                            source: '/images/emojis/ascii/-u002DDu0044.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: "':D",
                            alt: '😅',
                            name: "':D",
                            source: '/images/emojis/ascii/u0027Du0044.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: "':-D",
                            alt: '😅',
                            name: "':-D",
                            source: '/images/emojis/ascii/u0027-u002DDu0044.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':L',
                            alt: '😕',
                            name: ':L',
                            source: '/images/emojis/ascii/Lu004C.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':O',
                            alt: '😮',
                            name: ':O',
                            source: '/images/emojis/ascii/Ou004F.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':-O',
                            alt: '😮',
                            name: ':-O',
                            source: '/images/emojis/ascii/-u002DOu004F.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':P',
                            alt: '😛',
                            name: ':P',
                            source: '/images/emojis/ascii/Pu0050.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':-P',
                            alt: '😛',
                            name: ':-P',
                            source: '/images/emojis/ascii/-u002DPu0050.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':Þ',
                            alt: '😛',
                            name: ':Þ',
                            source: '/images/emojis/ascii/Þu00DE.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':-Þ',
                            alt: '😛',
                            name: ':-Þ',
                            source: '/images/emojis/ascii/-u002DÞu00DE.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':X',
                            alt: '😶',
                            name: ':X',
                            source: '/images/emojis/ascii/Xu0058.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ':-X',
                            alt: '😶',
                            name: ':-X',
                            source: '/images/emojis/ascii/-u002DXu0058.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ';(',
                            alt: '😢',
                            name: ';(',
                            source: '/images/emojis/ascii/u003Bu0028.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ';-(',
                            alt: '😢',
                            name: ';-(',
                            source: '/images/emojis/ascii/u003B-u002Du0028.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ';)',
                            alt: '😉',
                            name: ';)',
                            source: '/images/emojis/ascii/u003Bu0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ';-)',
                            alt: '😉',
                            name: ';-)',
                            source: '/images/emojis/ascii/u003B-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ';]',
                            alt: '😉',
                            name: ';]',
                            source: '/images/emojis/ascii/u003Bu005D.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ';-]',
                            alt: '😉',
                            name: ';-]',
                            source: '/images/emojis/ascii/u003B-u002Du005D.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ';^)',
                            alt: '😉',
                            name: ';^)',
                            source: '/images/emojis/ascii/u003Bu005Eu0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: ';D',
                            alt: '😉',
                            name: ';D',
                            source: '/images/emojis/ascii/u003BDu0044.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '\\0/',
                            alt: '🙆',
                            name: '\\0/',
                            source: '/images/emojis/ascii/u005C0u0030u002F.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '\\O/',
                            alt: '🙆',
                            name: '\\O/',
                            source: '/images/emojis/ascii/u005COu004Fu002F.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '-_-',
                            alt: '😑',
                            name: '-_-',
                            source: '/images/emojis/ascii/-u002D_u005F-u002D.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '-__-',
                            alt: '😑',
                            name: '-__-',
                            source: '/images/emojis/ascii/-u002D_u005F_u005F-u002D.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '-___-',
                            alt: '😑',
                            name: '-___-',
                            source: '/images/emojis/ascii/-u002D_u005F_u005F_u005F-u002D.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '</3',
                            alt: '💔',
                            name: '</3',
                            source: '/images/emojis/ascii/u003Cu002F3u0033.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '<3',
                            alt: '❤',
                            name: '<3',
                            source: '/images/emojis/ascii/u003C3u0033.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '=#',
                            alt: '😶',
                            name: '=#',
                            source: '/images/emojis/ascii/u003Du0023.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '=$',
                            alt: '😳',
                            name: '=$',
                            source: '/images/emojis/ascii/u003Du0024.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '=(',
                            alt: '😞',
                            name: '=(',
                            source: '/images/emojis/ascii/u003Du0028.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: "'=(",
                            alt: '😓',
                            name: "'=(",
                            source: '/images/emojis/ascii/u0027u003Du0028.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '=)',
                            alt: '🙂',
                            name: '=)',
                            source: '/images/emojis/ascii/u003Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: "'=)",
                            alt: '😅',
                            name: "'=)",
                            source: '/images/emojis/ascii/u0027u003Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '=*',
                            alt: '😘',
                            name: '=*',
                            source: '/images/emojis/ascii/u003Du002A.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '=/',
                            alt: '😕',
                            name: '=/',
                            source: '/images/emojis/ascii/u003Du002F.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '=\\',
                            alt: '😕',
                            name: '=\\',
                            source: '/images/emojis/ascii/u003Du005C.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '=]',
                            alt: '🙂',
                            name: '=]',
                            source: '/images/emojis/ascii/u003Du005D.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '=D',
                            alt: '😃',
                            name: '=D',
                            source: '/images/emojis/ascii/u003DDu0044.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: "'=D",
                            alt: '😅',
                            name: "'=D",
                            source: '/images/emojis/ascii/u0027u003DDu0044.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '=L',
                            alt: '😕',
                            name: '=L',
                            source: '/images/emojis/ascii/u003DLu004C.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '=P',
                            alt: '😛',
                            name: '=P',
                            source: '/images/emojis/ascii/u003DPu0050.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '=X',
                            alt: '😶',
                            name: '=X',
                            source: '/images/emojis/ascii/u003DXu0058.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '>.<',
                            alt: '😣',
                            name: '>.<',
                            source: '/images/emojis/ascii/u003Eu002Eu003C.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '>:(',
                            alt: '😠',
                            name: '>:(',
                            source: '/images/emojis/ascii/u003Eu0028.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '>:-(',
                            alt: '😠',
                            name: '>:-(',
                            source: '/images/emojis/ascii/u003E-u002Du0028.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '>:)',
                            alt: '😆',
                            name: '>:)',
                            source: '/images/emojis/ascii/u003Eu0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '>:-)',
                            alt: '😆',
                            name: '>:-)',
                            source: '/images/emojis/ascii/u003E-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '>:/',
                            alt: '😕',
                            name: '>:/',
                            source: '/images/emojis/ascii/u003Eu002F.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '>:[',
                            alt: '😞',
                            name: '>:[',
                            source: '/images/emojis/ascii/u003Eu005B.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '>:\\',
                            alt: '😕',
                            name: '>:\\',
                            source: '/images/emojis/ascii/u003Eu005C.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '>:O',
                            alt: '😮',
                            name: '>:O',
                            source: '/images/emojis/ascii/u003EOu004F.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '>:P',
                            alt: '😜',
                            name: '>:P',
                            source: '/images/emojis/ascii/u003EPu0050.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '>;)',
                            alt: '😆',
                            name: '>;)',
                            source: '/images/emojis/ascii/u003Eu003Bu0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '>=)',
                            alt: '😆',
                            name: '>=)',
                            source: '/images/emojis/ascii/u003Eu003Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '0:)',
                            alt: '😇',
                            name: '0:)',
                            source: '/images/emojis/ascii/0u0030u0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '0:-)',
                            alt: '😇',
                            name: '0:-)',
                            source: '/images/emojis/ascii/0u0030-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '0:3',
                            alt: '😇',
                            name: '0:3',
                            source: '/images/emojis/ascii/0u00303u0033.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '0:-3',
                            alt: '😇',
                            name: '0:-3',
                            source: '/images/emojis/ascii/0u0030-u002D3u0033.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '0;-)',
                            alt: '😇',
                            name: '0;-)',
                            source: '/images/emojis/ascii/0u0030u003B-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '0;^)',
                            alt: '😇',
                            name: '0;^)',
                            source: '/images/emojis/ascii/0u0030u003Bu005Eu0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '8)',
                            alt: '😎',
                            name: '8)',
                            source: '/images/emojis/ascii/8u0038u0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '8-)',
                            alt: '😎',
                            name: '8-)',
                            source: '/images/emojis/ascii/8u0038-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: '8-D',
                            alt: '😎',
                            name: '8-D',
                            source: '/images/emojis/ascii/8u0038-u002DDu0044.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: 'B)',
                            alt: '😎',
                            name: 'B)',
                            source: '/images/emojis/ascii/Bu0042u0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: 'B-)',
                            alt: '😎',
                            name: 'B-)',
                            source: '/images/emojis/ascii/Bu0042-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: 'B-D',
                            alt: '😎',
                            name: 'B-D',
                            source: '/images/emojis/ascii/Bu0042-u002DDu0044.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: 'D:',
                            alt: '😨',
                            name: 'D:',
                            source: '/images/emojis/ascii/Du0044.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: 'O:)',
                            alt: '😇',
                            name: 'O:)',
                            source: '/images/emojis/ascii/Ou004Fu0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: 'O:-)',
                            alt: '😇',
                            name: 'O:-)',
                            source: '/images/emojis/ascii/Ou004F-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: 'O:3',
                            alt: '😇',
                            name: 'O:3',
                            source: '/images/emojis/ascii/Ou004F3u0033.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: 'O:-3',
                            alt: '😇',
                            name: 'O:-3',
                            source: '/images/emojis/ascii/Ou004F-u002D3u0033.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: 'O;-)',
                            alt: '😇',
                            name: 'O;-)',
                            source: '/images/emojis/ascii/Ou004Fu003B-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: 'O_O',
                            alt: '😮',
                            name: 'O_O',
                            source: '/images/emojis/ascii/Ou004F_u005FOu004F.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: 'O=)',
                            alt: '😇',
                            name: 'O=)',
                            source: '/images/emojis/ascii/Ou004Fu003Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: 'X)',
                            alt: '😵',
                            name: 'X)',
                            source: '/images/emojis/ascii/Xu0058u0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: 'X-)',
                            alt: '😵',
                            name: 'X-)',
                            source: '/images/emojis/ascii/Xu0058-u002Du0029.png',
                            components: [],
                            variants: [],
                            type: 0
                        },
                        {
                            emojiCode: 'X-P',
                            alt: '😜',
                            name: 'X-P',
                            source: '/images/emojis/ascii/Xu0058-u002DPu0050.png',
                            components: [],
                            variants: [],
                            type: 0
                        }
                    ]
                }
            ]
        },
        {
            name: 'Party Parrot',
            subGroups: [
                {
                    name: '',
                    emojis: [
                        {
                            emojiCode: '1102dailyparrot',
                            alt: '1102 dailyparrot',
                            name: '1102 dailyparrot',
                            source: '/images/emojis/named/1102dailyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: '3dparrot',
                            alt: '3d parrot',
                            name: '3d parrot',
                            source: '/images/emojis/named/3dparrot.png',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: '404errorparrot',
                            alt: '404error parrot',
                            name: '404error parrot',
                            source: '/images/emojis/named/404errorparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: '60fpsparrot',
                            alt: '60fps parrot',
                            name: '60fps parrot',
                            source: '/images/emojis/named/60fpsparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'accessibleparrot',
                            alt: 'Accessible parrot',
                            name: 'Accessible parrot',
                            source: '/images/emojis/named/accessibleparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'akak',
                            alt: 'Akak',
                            name: 'Akak',
                            source: '/images/emojis/named/akak.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'angelparrot',
                            alt: 'Angel parrot',
                            name: 'Angel parrot',
                            source: '/images/emojis/named/angelparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'angryparrot',
                            alt: 'Angry parrot',
                            name: 'Angry parrot',
                            source: '/images/emojis/named/angryparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'antarcticaparrot',
                            alt: 'Antarctica parrot',
                            name: 'Antarctica parrot',
                            source: '/images/emojis/named/antarcticaparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'a_parrot',
                            alt: 'A-parrot',
                            name: 'A-parrot',
                            source: '/images/emojis/named/a_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'asyncparrot',
                            alt: 'Async parrot',
                            name: 'Async parrot',
                            source: '/images/emojis/named/asyncparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'aussiecongaparrot',
                            alt: 'Aussie conga parrot',
                            name: 'Aussie conga parrot',
                            source: '/images/emojis/named/aussiecongaparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'aussieparrot',
                            alt: 'Aussie parrot',
                            name: 'Aussie parrot',
                            source: '/images/emojis/named/aussieparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'aussiereversecongaparrot',
                            alt: 'Aussiereversecongaparrot',
                            name: 'Aussiereversecongaparrot',
                            source: '/images/emojis/named/aussiereversecongaparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'backwardsparrot',
                            alt: 'Backwards parrot',
                            name: 'Backwards parrot',
                            source: '/images/emojis/named/backwardsparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'bananaparrot',
                            alt: 'Banana parrot',
                            name: 'Banana parrot',
                            source: '/images/emojis/named/bananaparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'beerparrot',
                            alt: 'Beer parrot',
                            name: 'Beer parrot',
                            source: '/images/emojis/named/beerparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'beretparrot',
                            alt: 'Beret parrot',
                            name: 'Beret parrot',
                            source: '/images/emojis/named/beretparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'bhutanparrot',
                            alt: 'Bhutan parrot',
                            name: 'Bhutan parrot',
                            source: '/images/emojis/named/bhutanparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'bikeparrot',
                            alt: 'Bikeparrot',
                            name: 'Bikeparrot',
                            source: '/images/emojis/named/bikeparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'bikerparrot',
                            alt: 'Biker parrot',
                            name: 'Biker parrot',
                            source: '/images/emojis/named/bikerparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'birthdaypartyparrot',
                            alt: 'Birthday party parrot',
                            name: 'Birthday party parrot',
                            source: '/images/emojis/named/birthdaypartyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'blochparrot',
                            alt: 'Blochparrot',
                            name: 'Blochparrot',
                            source: '/images/emojis/named/blochparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'blondesassyparrot',
                            alt: 'Blonde sassy parrot',
                            name: 'Blonde sassy parrot',
                            source: '/images/emojis/named/blondesassyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'blueparrot',
                            alt: 'Blue parrot',
                            name: 'Blue parrot',
                            source: '/images/emojis/named/blueparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'bluescluesparrot',
                            alt: 'Blues clues parrot',
                            name: 'Blues clues parrot',
                            source: '/images/emojis/named/bluescluesparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'bluntparrot',
                            alt: 'Blunt parrot',
                            name: 'Blunt parrot',
                            source: '/images/emojis/named/bluntparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'bobrossparrot',
                            alt: 'Bob ross parrot',
                            name: 'Bob ross parrot',
                            source: '/images/emojis/named/bobrossparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'bobrosspartyparrot',
                            alt: 'Bob ross party parrot',
                            name: 'Bob ross party parrot',
                            source: '/images/emojis/named/bobrosspartyparrot.jpg',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'bobaparrot',
                            alt: 'Boba parrot',
                            name: 'Boba parrot',
                            source: '/images/emojis/named/bobaparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'boba_parrot',
                            alt: 'Boba-parrot',
                            name: 'Boba-parrot',
                            source: '/images/emojis/named/boba_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'bootlegparrot',
                            alt: 'Bootleg parrot',
                            name: 'Bootleg parrot',
                            source: '/images/emojis/named/bootlegparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'boredparrot',
                            alt: 'Bored parrot',
                            name: 'Bored parrot',
                            source: '/images/emojis/named/boredparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'borjaparrot',
                            alt: 'Borja parrot',
                            name: 'Borja parrot',
                            source: '/images/emojis/named/borjaparrot.png',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'bouncingparrot',
                            alt: 'Bouncing parrot',
                            name: 'Bouncing parrot',
                            source: '/images/emojis/named/bouncingparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'braveheartparrot',
                            alt: 'Braveheart parrot',
                            name: 'Braveheart parrot',
                            source: '/images/emojis/named/braveheartparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'brazilianfanparrot',
                            alt: 'Brazilian fan parrot',
                            name: 'Brazilian fan parrot',
                            source: '/images/emojis/named/brazilianfanparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'brazilianplayerparrot',
                            alt: 'Brazilian player parrot',
                            name: 'Brazilian player parrot',
                            source: '/images/emojis/named/brazilianplayerparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'bunnyparrot',
                            alt: 'Bunny parrot',
                            name: 'Bunny parrot',
                            source: '/images/emojis/named/bunnyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'businessparrot',
                            alt: 'Businessparrot',
                            name: 'Businessparrot',
                            source: '/images/emojis/named/businessparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'cakeparrot',
                            alt: 'Cake parrot',
                            name: 'Cake parrot',
                            source: '/images/emojis/named/cakeparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'calvinistparrot',
                            alt: 'Calvinist parrot',
                            name: 'Calvinist parrot',
                            source: '/images/emojis/named/calvinistparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'canadaparrot',
                            alt: 'Canada parrot',
                            name: 'Canada parrot',
                            source: '/images/emojis/named/canadaparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'capsparrot',
                            alt: 'Caps parrot',
                            name: 'Caps parrot',
                            source: '/images/emojis/named/capsparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'catparrot',
                            alt: 'Cat parrot',
                            name: 'Cat parrot',
                            source: '/images/emojis/named/catparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'ceilingparrot',
                            alt: 'Ceiling parrot',
                            name: 'Ceiling parrot',
                            source: '/images/emojis/named/ceilingparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'checkeredflagparrot',
                            alt: 'Checkeredflag parrot',
                            name: 'Checkeredflag parrot',
                            source: '/images/emojis/named/checkeredflagparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'chef_parrot',
                            alt: 'Chef-parrot',
                            name: 'Chef-parrot',
                            source: '/images/emojis/named/chef_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'chicoparrot',
                            alt: 'Chico parrot',
                            name: 'Chico parrot',
                            source: '/images/emojis/named/chicoparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'chillparrot',
                            alt: 'Chill parrot',
                            name: 'Chill parrot',
                            source: '/images/emojis/named/chillparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'christmasparrot',
                            alt: 'Christmas parrot',
                            name: 'Christmas parrot',
                            source: '/images/emojis/named/christmasparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'coffeeparrot',
                            alt: 'Coffee parrot',
                            name: 'Coffee parrot',
                            source: '/images/emojis/named/coffeeparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'communistparrot',
                            alt: 'Communist parrot',
                            name: 'Communist parrot',
                            source: '/images/emojis/named/communistparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'confusedparrot',
                            alt: 'Confused parrot',
                            name: 'Confused parrot',
                            source: '/images/emojis/named/confusedparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'congapartyparrot',
                            alt: 'Conga party parrot',
                            name: 'Conga party parrot',
                            source: '/images/emojis/named/congapartyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'congaparrot',
                            alt: 'Congaparrot',
                            name: 'Congaparrot',
                            source: '/images/emojis/named/congaparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'copparrot',
                            alt: 'Cop parrot',
                            name: 'Cop parrot',
                            source: '/images/emojis/named/copparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'corona_parrot',
                            alt: 'Corona-parrot',
                            name: 'Corona-parrot',
                            source: '/images/emojis/named/corona_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'covid19parrot',
                            alt: 'Covid19 parrot',
                            name: 'Covid19 parrot',
                            source: '/images/emojis/named/covid19parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'cowboy_parrot',
                            alt: 'Cowboy-parrot',
                            name: 'Cowboy-parrot',
                            source: '/images/emojis/named/cowboy_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'cozyparrot',
                            alt: 'Cozy parrot',
                            name: 'Cozy parrot',
                            source: '/images/emojis/named/cozyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'crazilyangryparrot',
                            alt: 'Crazilyangryparrot',
                            name: 'Crazilyangryparrot',
                            source: '/images/emojis/named/crazilyangryparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'croissantparrot',
                            alt: 'Croissantparrot',
                            name: 'Croissantparrot',
                            source: '/images/emojis/named/croissantparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'cryptoparrot',
                            alt: 'Crypto parrot',
                            name: 'Crypto parrot',
                            source: '/images/emojis/named/cryptoparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'cursedparrot',
                            alt: 'Cursed parrot',
                            name: 'Cursed parrot',
                            source: '/images/emojis/named/cursedparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'dabparrot',
                            alt: 'Dab parrot',
                            name: 'Dab parrot',
                            source: '/images/emojis/named/dabparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'dadparrot',
                            alt: 'Dad parrot',
                            name: 'Dad parrot',
                            source: '/images/emojis/named/dadparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'daftpunkparrot',
                            alt: 'Daft punk parrot',
                            name: 'Daft punk parrot',
                            source: '/images/emojis/named/daftpunkparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'darkbeerparrot',
                            alt: 'Dark beer parrot',
                            name: 'Dark beer parrot',
                            source: '/images/emojis/named/darkbeerparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'darkmodeparrot',
                            alt: 'Dark mode parrot',
                            name: 'Dark mode parrot',
                            source: '/images/emojis/named/darkmodeparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'databaseparrot',
                            alt: 'Database parrot',
                            name: 'Database parrot',
                            source: '/images/emojis/named/databaseparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'deadparrot',
                            alt: 'Deadparrot',
                            name: 'Deadparrot',
                            source: '/images/emojis/named/deadparrot.jpg',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'dealwithitnowparrot',
                            alt: 'Deal with it now parrot',
                            name: 'Deal with it now parrot',
                            source: '/images/emojis/named/dealwithitnowparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'dealwithitparrot',
                            alt: 'Deal with it parrot',
                            name: 'Deal with it parrot',
                            source: '/images/emojis/named/dealwithitparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'deployparrot',
                            alt: 'Deployparrot',
                            name: 'Deployparrot',
                            source: '/images/emojis/named/deployparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'devilparrot',
                            alt: 'Devil parrot',
                            name: 'Devil parrot',
                            source: '/images/emojis/named/devilparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'discoparrot',
                            alt: 'Disco parrot',
                            name: 'Disco parrot',
                            source: '/images/emojis/named/discoparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'docparrot',
                            alt: 'Doc parrot',
                            name: 'Doc parrot',
                            source: '/images/emojis/named/docparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'donutparrot',
                            alt: 'Donut parrot',
                            name: 'Donut parrot',
                            source: '/images/emojis/named/donutparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'downvotepartyparrot',
                            alt: 'Downvote party parrot',
                            name: 'Downvote party parrot',
                            source: '/images/emojis/named/downvotepartyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'dreidelparrot',
                            alt: 'Dreidel parrot',
                            name: 'Dreidel parrot',
                            source: '/images/emojis/named/dreidelparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'drupalparrot',
                            alt: 'Drupal parrot',
                            name: 'Drupal parrot',
                            source: '/images/emojis/named/drupalparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'euparrot',
                            alt: 'Eu parrot',
                            name: 'Eu parrot',
                            source: '/images/emojis/named/euparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'everythingsfineparrot',
                            alt: 'Everythings fine parrot',
                            name: 'Everythings fine parrot',
                            source: '/images/emojis/named/everythingsfineparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'evilparrot',
                            alt: 'Evil parrot',
                            name: 'Evil parrot',
                            source: '/images/emojis/named/evilparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'exceptionallyfastparrot',
                            alt: 'Exceptionally fast parrot',
                            name: 'Exceptionally fast parrot',
                            source: '/images/emojis/named/exceptionallyfastparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'exceptionally_fast_parrot',
                            alt: 'Exceptionally-fast-parrot',
                            name: 'Exceptionally-fast-parrot',
                            source: '/images/emojis/named/exceptionally_fast_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'explodyparrot',
                            alt: 'Explody parrot',
                            name: 'Explody parrot',
                            source: '/images/emojis/named/explodyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'fadingparrot',
                            alt: 'Fadingparrot',
                            name: 'Fadingparrot',
                            source: '/images/emojis/named/fadingparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'fanoos_parrot',
                            alt: 'Fanoos-parrot',
                            name: 'Fanoos-parrot',
                            source: '/images/emojis/named/fanoos_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'fastparrot',
                            alt: 'Fast parrot',
                            name: 'Fast parrot',
                            source: '/images/emojis/named/fastparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'fedoraparrot',
                            alt: 'Fedora parrot',
                            name: 'Fedora parrot',
                            source: '/images/emojis/named/fedoraparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'feministparrot',
                            alt: 'Feminist parrot',
                            name: 'Feminist parrot',
                            source: '/images/emojis/named/feministparrot.png',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'fidgetparrot',
                            alt: 'Fidget parrot',
                            name: 'Fidget parrot',
                            source: '/images/emojis/named/fidgetparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'fieriparrot',
                            alt: 'Fieri parrot',
                            name: 'Fieri parrot',
                            source: '/images/emojis/named/fieriparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'fiestaparrot',
                            alt: 'Fiesta parrot',
                            name: 'Fiesta parrot',
                            source: '/images/emojis/named/fiestaparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'fireparrot',
                            alt: 'Fireparrot',
                            name: 'Fireparrot',
                            source: '/images/emojis/named/fireparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'fixparrot',
                            alt: 'Fixparrot',
                            name: 'Fixparrot',
                            source: '/images/emojis/named/fixparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'flamingparrot',
                            alt: 'Flamingparrot',
                            name: 'Flamingparrot',
                            source: '/images/emojis/named/flamingparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'flowerparrot',
                            alt: 'Flower parrot',
                            name: 'Flower parrot',
                            source: '/images/emojis/named/flowerparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'flyingmoneyparrot',
                            alt: 'Flying money parrot',
                            name: 'Flying money parrot',
                            source: '/images/emojis/named/flyingmoneyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'footballparrot',
                            alt: 'Football parrot',
                            name: 'Football parrot',
                            source: '/images/emojis/named/footballparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'france_parrot',
                            alt: 'France-parrot',
                            name: 'France-parrot',
                            source: '/images/emojis/named/france_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'frenchparrot',
                            alt: 'French parrot',
                            name: 'French parrot',
                            source: '/images/emojis/named/frenchparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'gandalfparrot',
                            alt: 'Gandalf parrot',
                            name: 'Gandalf parrot',
                            source: '/images/emojis/named/gandalfparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'gentlemanparrot',
                            alt: 'Gentleman parrot',
                            name: 'Gentleman parrot',
                            source: '/images/emojis/named/gentlemanparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'githubparrot',
                            alt: 'Github parrot',
                            name: 'Github parrot',
                            source: '/images/emojis/named/githubparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'glimpseparrot',
                            alt: 'Glimpse parrot',
                            name: 'Glimpse parrot',
                            source: '/images/emojis/named/glimpseparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'gothparrot',
                            alt: 'Goth parrot',
                            name: 'Goth parrot',
                            source: '/images/emojis/named/gothparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'gotta_parrot_fast',
                            alt: 'Gotta-parrot-fast',
                            name: 'Gotta-parrot-fast',
                            source: '/images/emojis/named/gotta_parrot_fast.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'grokeparrot',
                            alt: 'Grokeparrot',
                            name: 'Grokeparrot',
                            source: '/images/emojis/named/grokeparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'grouchoparrot',
                            alt: 'Groucho parrot',
                            name: 'Groucho parrot',
                            source: '/images/emojis/named/grouchoparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'guitarparrot',
                            alt: 'Guitarparrot',
                            name: 'Guitarparrot',
                            source: '/images/emojis/named/guitarparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'halalparrot',
                            alt: 'Halal parrot',
                            name: 'Halal parrot',
                            source: '/images/emojis/named/halalparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'hamburgerparrot',
                            alt: 'Hamburger parrot',
                            name: 'Hamburger parrot',
                            source: '/images/emojis/named/hamburgerparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'hanamiparrot',
                            alt: 'Hanami parrot',
                            name: 'Hanami parrot',
                            source: '/images/emojis/named/hanamiparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'hardhatparrot',
                            alt: 'Hard hat parrot',
                            name: 'Hard hat parrot',
                            source: '/images/emojis/named/hardhatparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'haroldparrot',
                            alt: 'Haroldparrot',
                            name: 'Haroldparrot',
                            source: '/images/emojis/named/haroldparrot.jpeg',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'harpoparrot',
                            alt: 'Harpo parrot',
                            name: 'Harpo parrot',
                            source: '/images/emojis/named/harpoparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'harrypotterparrot',
                            alt: 'Harry potter parrot',
                            name: 'Harry potter parrot',
                            source: '/images/emojis/named/harrypotterparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'harvey_parrot',
                            alt: 'Harvey-parrot',
                            name: 'Harvey-parrot',
                            source: '/images/emojis/named/harvey_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'headbangingparrot',
                            alt: 'Headbanging parrot',
                            name: 'Headbanging parrot',
                            source: '/images/emojis/named/headbangingparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'headingparrot',
                            alt: 'Heading parrot',
                            name: 'Heading parrot',
                            source: '/images/emojis/named/headingparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'headlessparrot',
                            alt: 'Headless parrot',
                            name: 'Headless parrot',
                            source: '/images/emojis/named/headlessparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'headsetparrot',
                            alt: 'Headset parrot',
                            name: 'Headset parrot',
                            source: '/images/emojis/named/headsetparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'hmmparrot',
                            alt: 'Hmm parrot',
                            name: 'Hmm parrot',
                            source: '/images/emojis/named/hmmparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'horizontalparrot',
                            alt: 'Horizontal parrot',
                            name: 'Horizontal parrot',
                            source: '/images/emojis/named/horizontalparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'hued_parrot',
                            alt: 'Hued-parrot',
                            name: 'Hued-parrot',
                            source: '/images/emojis/named/hued_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'hyperfastparrot',
                            alt: 'Hyperfastparrot',
                            name: 'Hyperfastparrot',
                            source: '/images/emojis/named/hyperfastparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'hypnoparrot',
                            alt: 'Hypno parrot',
                            name: 'Hypno parrot',
                            source: '/images/emojis/named/hypnoparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'hypnoparrotdark',
                            alt: 'Hypno parrot dark',
                            name: 'Hypno parrot dark',
                            source: '/images/emojis/named/hypnoparrotdark.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'hypnoparrotlight',
                            alt: 'Hypno parrot light',
                            name: 'Hypno parrot light',
                            source: '/images/emojis/named/hypnoparrotlight.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'icecreamparrot',
                            alt: 'Ice cream parrot',
                            name: 'Ice cream parrot',
                            source: '/images/emojis/named/icecreamparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'illuminatiparrot',
                            alt: 'Illuminati parrot',
                            name: 'Illuminati parrot',
                            source: '/images/emojis/named/illuminatiparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'innersourceparrot',
                            alt: 'Inner source parrot',
                            name: 'Inner source parrot',
                            source: '/images/emojis/named/innersourceparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'inverseparrot',
                            alt: 'Inverse parrot',
                            name: 'Inverse parrot',
                            source: '/images/emojis/named/inverseparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'invisibleparrot',
                            alt: 'Invisible parrot',
                            name: 'Invisible parrot',
                            source: '/images/emojis/named/invisibleparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'japanparrot',
                            alt: 'Japan parrot',
                            name: 'Japan parrot',
                            source: '/images/emojis/named/japanparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'jediparrot',
                            alt: 'Jedi parrot',
                            name: 'Jedi parrot',
                            source: '/images/emojis/named/jediparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'jewishparrot',
                            alt: 'Jewish parrot',
                            name: 'Jewish parrot',
                            source: '/images/emojis/named/jewishparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'johnfrancisparrot',
                            alt: 'John francis parrot',
                            name: 'John francis parrot',
                            source: '/images/emojis/named/johnfrancisparrot.png',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'jumpingparrot',
                            alt: 'Jumping parrot',
                            name: 'Jumping parrot',
                            source: '/images/emojis/named/jumpingparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'jumpingparrotjr',
                            alt: 'Jumping parrot jr',
                            name: 'Jumping parrot jr',
                            source: '/images/emojis/named/jumpingparrotjr.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'kaka',
                            alt: 'Kaka',
                            name: 'Kaka',
                            source: '/images/emojis/named/kaka.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'kindasusparrot',
                            alt: 'Kindasusparrot',
                            name: 'Kindasusparrot',
                            source: '/images/emojis/named/kindasusparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'kiwiparrot',
                            alt: 'Kiwi parrot',
                            name: 'Kiwi parrot',
                            source: '/images/emojis/named/kiwiparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'laptopparrot',
                            alt: 'Laptop parrot',
                            name: 'Laptop parrot',
                            source: '/images/emojis/named/laptopparrot.png',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'lepreparty_parrot',
                            alt: 'Lepreparty-parrot',
                            name: 'Lepreparty-parrot',
                            source: '/images/emojis/named/lepreparty_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'levitationparrot',
                            alt: 'Levitation parrot',
                            name: 'Levitation parrot',
                            source: '/images/emojis/named/levitationparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'loveparrot',
                            alt: 'Love parrot',
                            name: 'Love parrot',
                            source: '/images/emojis/named/loveparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'lovey_parrot',
                            alt: 'Lovey-parrot',
                            name: 'Lovey-parrot',
                            source: '/images/emojis/named/lovey_parrot.png',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'luckyparrot',
                            alt: 'Lucky parrot',
                            name: 'Lucky parrot',
                            source: '/images/emojis/named/luckyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'maidparrot',
                            alt: 'Maid parrot',
                            name: 'Maid parrot',
                            source: '/images/emojis/named/maidparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'maracasparrot',
                            alt: 'Maracas parrot',
                            name: 'Maracas parrot',
                            source: '/images/emojis/named/maracasparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'marc_rebillet_parrot',
                            alt: 'Marc-rebillet-parrot',
                            name: 'Marc-rebillet-parrot',
                            source: '/images/emojis/named/marc_rebillet_parrot.jpg',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'mardigrasparrot',
                            alt: 'Mardigras parrot',
                            name: 'Mardigras parrot',
                            source: '/images/emojis/named/mardigrasparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'margaritaparrot',
                            alt: 'Margarita parrot',
                            name: 'Margarita parrot',
                            source: '/images/emojis/named/margaritaparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'marshmallowparrot',
                            alt: 'Marshmallow parrot',
                            name: 'Marshmallow parrot',
                            source: '/images/emojis/named/marshmallowparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'mask_parrot',
                            alt: 'Mask-parrot',
                            name: 'Mask-parrot',
                            source: '/images/emojis/named/mask_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'matrixparrot',
                            alt: 'Matrix parrot',
                            name: 'Matrix parrot',
                            source: '/images/emojis/named/matrixparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'meldparrot',
                            alt: 'Meld parrot',
                            name: 'Meld parrot',
                            source: '/images/emojis/named/meldparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'mergedparrot',
                            alt: 'Merged parrot',
                            name: 'Merged parrot',
                            source: '/images/emojis/named/mergedparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'metalparrot',
                            alt: 'Metal parrot',
                            name: 'Metal parrot',
                            source: '/images/emojis/named/metalparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'mexaparrot',
                            alt: 'Mexa parrot',
                            name: 'Mexa parrot',
                            source: '/images/emojis/named/mexaparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'michaeljacksonparrot',
                            alt: 'Michael jackson parrot',
                            name: 'Michael jackson parrot',
                            source: '/images/emojis/named/michaeljacksonparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'middleparrot',
                            alt: 'Middle parrot',
                            name: 'Middle parrot',
                            source: '/images/emojis/named/middleparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'minecraft_parrot',
                            alt: 'Minecraft-parrot',
                            name: 'Minecraft-parrot',
                            source: '/images/emojis/named/minecraft_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'moneyparrot',
                            alt: 'Moneyparrot',
                            name: 'Moneyparrot',
                            source: '/images/emojis/named/moneyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'moonparrot',
                            alt: 'Moon parrot',
                            name: 'Moon parrot',
                            source: '/images/emojis/named/moonparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'moonwalkingparrot',
                            alt: 'Moon walking parrot',
                            name: 'Moon walking parrot',
                            source: '/images/emojis/named/moonwalkingparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'musicparrot',
                            alt: 'Music parrot',
                            name: 'Music parrot',
                            source: '/images/emojis/named/musicparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'mustacheparrot',
                            alt: 'Mustache parrot',
                            name: 'Mustache parrot',
                            source: '/images/emojis/named/mustacheparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'nekoparrot',
                            alt: 'Neko parrot',
                            name: 'Neko parrot',
                            source: '/images/emojis/named/nekoparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'nodeparrot',
                            alt: 'Node parrot',
                            name: 'Node parrot',
                            source: '/images/emojis/named/nodeparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'norwegianblueparrot',
                            alt: 'Norwegian blue parrot',
                            name: 'Norwegian blue parrot',
                            source: '/images/emojis/named/norwegianblueparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'nyanparrot',
                            alt: 'Nyan parrot',
                            name: 'Nyan parrot',
                            source: '/images/emojis/named/nyanparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'oh_heyparrot',
                            alt: 'Oh-hey parrot',
                            name: 'Oh-hey parrot',
                            source: '/images/emojis/named/oh_heyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'oldtimeyparrot',
                            alt: 'Old timey parrot',
                            name: 'Old timey parrot',
                            source: '/images/emojis/named/oldtimeyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'opensourceparrot',
                            alt: 'Open source parrot',
                            name: 'Open source parrot',
                            source: '/images/emojis/named/opensourceparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'originalparrot',
                            alt: 'Original parrot',
                            name: 'Original parrot',
                            source: '/images/emojis/named/originalparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'oriolesparrot',
                            alt: 'Orioles parrot',
                            name: 'Orioles parrot',
                            source: '/images/emojis/named/oriolesparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'papalparrot',
                            alt: 'Papal parrot',
                            name: 'Papal parrot',
                            source: '/images/emojis/named/papalparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'paperparrot',
                            alt: 'Paper parrot',
                            name: 'Paper parrot',
                            source: '/images/emojis/named/paperparrot.png',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrot',
                            alt: 'Parrot',
                            name: 'Parrot',
                            source: '/images/emojis/named/parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotarrowup',
                            alt: 'Parrot arrow up',
                            name: 'Parrot arrow up',
                            source: '/images/emojis/named/parrotarrowup.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotbean',
                            alt: 'Parrot bean',
                            name: 'Parrot bean',
                            source: '/images/emojis/named/parrotbean.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotbeer',
                            alt: 'Parrot beer',
                            name: 'Parrot beer',
                            source: '/images/emojis/named/parrotbeer.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotbeers',
                            alt: 'Parrot beers',
                            name: 'Parrot beers',
                            source: '/images/emojis/named/parrotbeers.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotcop',
                            alt: 'Parrot cop',
                            name: 'Parrot cop',
                            source: '/images/emojis/named/parrotcop.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotdad',
                            alt: 'Parrot dad',
                            name: 'Parrot dad',
                            source: '/images/emojis/named/parrotdad.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotdada',
                            alt: 'Parrot dada',
                            name: 'Parrot dada',
                            source: '/images/emojis/named/parrotdada.png',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotdoge',
                            alt: 'Parrot doge',
                            name: 'Parrot doge',
                            source: '/images/emojis/named/parrotdoge.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotfly',
                            alt: 'Parrot fly',
                            name: 'Parrot fly',
                            source: '/images/emojis/named/parrotfly.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotgreen',
                            alt: 'Parrot green',
                            name: 'Parrot green',
                            source: '/images/emojis/named/parrotgreen.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotkorrot',
                            alt: 'Parrot korrot',
                            name: 'Parrot korrot',
                            source: '/images/emojis/named/parrotkorrot.png',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotmargarita',
                            alt: 'Parrot margarita',
                            name: 'Parrot margarita',
                            source: '/images/emojis/named/parrotmargarita.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotmustache',
                            alt: 'Parrot mustache',
                            name: 'Parrot mustache',
                            source: '/images/emojis/named/parrotmustache.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotnotfound',
                            alt: 'Parrot not found',
                            name: 'Parrot not found',
                            source: '/images/emojis/named/parrotnotfound.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotok',
                            alt: 'Parrot ok',
                            name: 'Parrot ok',
                            source: '/images/emojis/named/parrotok.png',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotpanther',
                            alt: 'Parrot panther',
                            name: 'Parrot panther',
                            source: '/images/emojis/named/parrotpanther.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotsleep',
                            alt: 'Parrot sleep',
                            name: 'Parrot sleep',
                            source: '/images/emojis/named/parrotsleep.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotsmiley',
                            alt: 'Parrot smiley',
                            name: 'Parrot smiley',
                            source: '/images/emojis/named/parrotsmiley.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrottrump',
                            alt: 'Parrot trump',
                            name: 'Parrot trump',
                            source: '/images/emojis/named/parrottrump.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotturban',
                            alt: 'Parrot turban',
                            name: 'Parrot turban',
                            source: '/images/emojis/named/parrotturban.png',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotwine',
                            alt: 'Parrot wine',
                            name: 'Parrot wine',
                            source: '/images/emojis/named/parrotwine.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotyankee',
                            alt: 'Parrot yankee',
                            name: 'Parrot yankee',
                            source: '/images/emojis/named/parrotyankee.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotbeer_left',
                            alt: 'Parrotbeer-left',
                            name: 'Parrotbeer-left',
                            source: '/images/emojis/named/parrotbeer_left.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotbeer_right',
                            alt: 'Parrotbeer-right',
                            name: 'Parrotbeer-right',
                            source: '/images/emojis/named/parrotbeer_right.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotception',
                            alt: 'Parrotception',
                            name: 'Parrotception',
                            source: '/images/emojis/named/parrotception.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrothd',
                            alt: 'Parrothd',
                            name: 'Parrothd',
                            source: '/images/emojis/named/parrothd.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotliftoff',
                            alt: 'Parrotliftoff',
                            name: 'Parrotliftoff',
                            source: '/images/emojis/named/parrotliftoff.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrot_newzealand',
                            alt: 'Parrot-newzealand',
                            name: 'Parrot-newzealand',
                            source: '/images/emojis/named/parrot_newzealand.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrot_puertorico',
                            alt: 'Parrot-puertorico',
                            name: 'Parrot-puertorico',
                            source: '/images/emojis/named/parrot_puertorico.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrot_unity',
                            alt: 'Parrot-unity',
                            name: 'Parrot-unity',
                            source: '/images/emojis/named/parrot_unity.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotwave1',
                            alt: 'Parrotwave1',
                            name: 'Parrotwave1',
                            source: '/images/emojis/named/parrotwave1.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotwave2',
                            alt: 'Parrotwave2',
                            name: 'Parrotwave2',
                            source: '/images/emojis/named/parrotwave2.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotwave3',
                            alt: 'Parrotwave3',
                            name: 'Parrotwave3',
                            source: '/images/emojis/named/parrotwave3.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotwave4',
                            alt: 'Parrotwave4',
                            name: 'Parrotwave4',
                            source: '/images/emojis/named/parrotwave4.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotwave5',
                            alt: 'Parrotwave5',
                            name: 'Parrotwave5',
                            source: '/images/emojis/named/parrotwave5.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotwave6',
                            alt: 'Parrotwave6',
                            name: 'Parrotwave6',
                            source: '/images/emojis/named/parrotwave6.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'parrotwave7',
                            alt: 'Parrotwave7',
                            name: 'Parrotwave7',
                            source: '/images/emojis/named/parrotwave7.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'partitionparrot',
                            alt: 'Partitionparrot',
                            name: 'Partitionparrot',
                            source: '/images/emojis/named/partitionparrot.jpg',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'partyparrotwallstreetbets',
                            alt: 'Party parrot wallstreetbets',
                            name: 'Party parrot wallstreetbets',
                            source: '/images/emojis/named/partyparrotwallstreetbets.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'partyparrot',
                            alt: 'Partyparrot',
                            name: 'Partyparrot',
                            source: '/images/emojis/named/partyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'peruparrot',
                            alt: 'Peru parrot',
                            name: 'Peru parrot',
                            source: '/images/emojis/named/peruparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'phparrot',
                            alt: 'Ph parrot',
                            name: 'Ph parrot',
                            source: '/images/emojis/named/phparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'picassoparrot',
                            alt: 'Picasso parrot',
                            name: 'Picasso parrot',
                            source: '/images/emojis/named/picassoparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'pingpongparrot',
                            alt: 'Pingpong parrot',
                            name: 'Pingpong parrot',
                            source: '/images/emojis/named/pingpongparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'pirateparrot',
                            alt: 'Pirate parrot',
                            name: 'Pirate parrot',
                            source: '/images/emojis/named/pirateparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'pizzaparrot',
                            alt: 'Pizza parrot',
                            name: 'Pizza parrot',
                            source: '/images/emojis/named/pizzaparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'plusultrafastparrot',
                            alt: 'Plusultrafastparrot',
                            name: 'Plusultrafastparrot',
                            source: '/images/emojis/named/plusultrafastparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'pokeparrot',
                            alt: 'Poke parrot',
                            name: 'Poke parrot',
                            source: '/images/emojis/named/pokeparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'popcornparrot',
                            alt: 'Popcorn parrot',
                            name: 'Popcorn parrot',
                            source: '/images/emojis/named/popcornparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'portalblueparrot',
                            alt: 'Portal blue parrot',
                            name: 'Portal blue parrot',
                            source: '/images/emojis/named/portalblueparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'portalorangeparrot',
                            alt: 'Portal orange parrot',
                            name: 'Portal orange parrot',
                            source: '/images/emojis/named/portalorangeparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'portalparrot',
                            alt: 'Portal parrot',
                            name: 'Portal parrot',
                            source: '/images/emojis/named/portalparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'pumpkinparrot',
                            alt: 'Pumpkin parrot',
                            name: 'Pumpkin parrot',
                            source: '/images/emojis/named/pumpkinparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'quadparrot',
                            alt: 'Quad parrot',
                            name: 'Quad parrot',
                            source: '/images/emojis/named/quadparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'rainbowparrot',
                            alt: 'Rainbow parrot',
                            name: 'Rainbow parrot',
                            source: '/images/emojis/named/rainbowparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'ramadan_parrot',
                            alt: 'Ramadan-parrot',
                            name: 'Ramadan-parrot',
                            source: '/images/emojis/named/ramadan_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'redenvelopeparrot',
                            alt: 'Red envelope parrot',
                            name: 'Red envelope parrot',
                            source: '/images/emojis/named/redenvelopeparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'redhatparrot',
                            alt: 'Red hat parrot',
                            name: 'Red hat parrot',
                            source: '/images/emojis/named/redhatparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'reversecongaparrot',
                            alt: 'Reverse conga parrot',
                            name: 'Reverse conga parrot',
                            source: '/images/emojis/named/reversecongaparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'reverseparrot',
                            alt: 'Reverse parrot',
                            name: 'Reverse parrot',
                            source: '/images/emojis/named/reverseparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'revertitparrot',
                            alt: 'Revertit parrot',
                            name: 'Revertit parrot',
                            source: '/images/emojis/named/revertitparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'revolutionparrot',
                            alt: 'Revolution parrot',
                            name: 'Revolution parrot',
                            source: '/images/emojis/named/revolutionparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'rightparrot',
                            alt: 'Rightparrot',
                            name: 'Rightparrot',
                            source: '/images/emojis/named/rightparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'ripparrot',
                            alt: 'Rip parrot',
                            name: 'Rip parrot',
                            source: '/images/emojis/named/ripparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'rotatingparrot',
                            alt: 'Rotating parrot',
                            name: 'Rotating parrot',
                            source: '/images/emojis/named/rotatingparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'rubyparrot',
                            alt: 'Ruby parrot',
                            name: 'Ruby parrot',
                            source: '/images/emojis/named/rubyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'ryangoslingparrot',
                            alt: 'Ryan gosling parrot',
                            name: 'Ryan gosling parrot',
                            source: '/images/emojis/named/ryangoslingparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'rythmicalparrot',
                            alt: 'Rythmical parrot',
                            name: 'Rythmical parrot',
                            source: '/images/emojis/named/rythmicalparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sadparrot',
                            alt: 'Sad parrot',
                            name: 'Sad parrot',
                            source: '/images/emojis/named/sadparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sadparrotcanada',
                            alt: 'Sadparrotcanada',
                            name: 'Sadparrotcanada',
                            source: '/images/emojis/named/sadparrotcanada.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sambosa_parrot',
                            alt: 'Sambosa-parrot',
                            name: 'Sambosa-parrot',
                            source: '/images/emojis/named/sambosa_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sassyparrot',
                            alt: 'Sassy parrot',
                            name: 'Sassy parrot',
                            source: '/images/emojis/named/sassyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'satanparrot',
                            alt: 'Satanparrot',
                            name: 'Satanparrot',
                            source: '/images/emojis/named/satanparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'schiapparrot',
                            alt: 'Schiapparrot',
                            name: 'Schiapparrot',
                            source: '/images/emojis/named/schiapparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'schnitzelparrot',
                            alt: 'Schnitzel parrot',
                            name: 'Schnitzel parrot',
                            source: '/images/emojis/named/schnitzelparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'scienceparrot',
                            alt: 'Science parrot',
                            name: 'Science parrot',
                            source: '/images/emojis/named/scienceparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sean_parrot',
                            alt: 'Sean-parrot',
                            name: 'Sean-parrot',
                            source: '/images/emojis/named/sean_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sherlockholmesparrot',
                            alt: 'Sherlock holmes parrot',
                            name: 'Sherlock holmes parrot',
                            source: '/images/emojis/named/sherlockholmesparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'shipitfurtherparrot',
                            alt: 'Ship it further parrot',
                            name: 'Ship it further parrot',
                            source: '/images/emojis/named/shipitfurtherparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'shipitparrot',
                            alt: 'Ship it parrot',
                            name: 'Ship it parrot',
                            source: '/images/emojis/named/shipitparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'shortparrot',
                            alt: 'Short parrot',
                            name: 'Short parrot',
                            source: '/images/emojis/named/shortparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'shufflefurtherparrot',
                            alt: 'Shuffle further parrot',
                            name: 'Shuffle further parrot',
                            source: '/images/emojis/named/shufflefurtherparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'shuffleparrot',
                            alt: 'Shuffle parrot',
                            name: 'Shuffle parrot',
                            source: '/images/emojis/named/shuffleparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'shufflepartyparrot',
                            alt: 'Shuffle party parrot',
                            name: 'Shuffle party parrot',
                            source: '/images/emojis/named/shufflepartyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sintparrot',
                            alt: 'Sintparrot',
                            name: 'Sintparrot',
                            source: '/images/emojis/named/sintparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sithparrot',
                            alt: 'Sith parrot',
                            name: 'Sith parrot',
                            source: '/images/emojis/named/sithparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'skiparrot',
                            alt: 'Ski parrot',
                            name: 'Ski parrot',
                            source: '/images/emojis/named/skiparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sleepingparrot',
                            alt: 'Sleeping parrot',
                            name: 'Sleeping parrot',
                            source: '/images/emojis/named/sleepingparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'slomoparrot',
                            alt: 'Slomo parrot',
                            name: 'Slomo parrot',
                            source: '/images/emojis/named/slomoparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'slowparrot',
                            alt: 'Slow parrot',
                            name: 'Slow parrot',
                            source: '/images/emojis/named/slowparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sneezyparrot',
                            alt: 'Sneezyparrot',
                            name: 'Sneezyparrot',
                            source: '/images/emojis/named/sneezyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sombreroparrot',
                            alt: 'Sombrero parrot',
                            name: 'Sombrero parrot',
                            source: '/images/emojis/named/sombreroparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sovietparrot',
                            alt: 'Soviet parrot',
                            name: 'Soviet parrot',
                            source: '/images/emojis/named/sovietparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sovjetparrot',
                            alt: 'Sovjet parrot',
                            name: 'Sovjet parrot',
                            source: '/images/emojis/named/sovjetparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'spinningparrot',
                            alt: 'Spinning parrot',
                            name: 'Spinning parrot',
                            source: '/images/emojis/named/spinningparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'spookparrot',
                            alt: 'Spookparrot',
                            name: 'Spookparrot',
                            source: '/images/emojis/named/spookparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'spookyparrot',
                            alt: 'Spookyparrot',
                            name: 'Spookyparrot',
                            source: '/images/emojis/named/spookyparrot.png',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'spyparrot',
                            alt: 'Spy parrot',
                            name: 'Spy parrot',
                            source: '/images/emojis/named/spyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'stableparrot',
                            alt: 'Stable parrot',
                            name: 'Stable parrot',
                            source: '/images/emojis/named/stableparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'stalkerparrot',
                            alt: 'Stalker parrot',
                            name: 'Stalker parrot',
                            source: '/images/emojis/named/stalkerparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'staticparrot',
                            alt: 'Static parrot',
                            name: 'Static parrot',
                            source: '/images/emojis/named/staticparrot.png',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'stayhomeparrot',
                            alt: 'Stay home parrot',
                            name: 'Stay home parrot',
                            source: '/images/emojis/named/stayhomeparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'stayhomeparrotcloser',
                            alt: 'Stay home parrot closer',
                            name: 'Stay home parrot closer',
                            source: '/images/emojis/named/stayhomeparrotcloser.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'stayhomeparrotwindow',
                            alt: 'Stay home parrot window',
                            name: 'Stay home parrot window',
                            source: '/images/emojis/named/stayhomeparrotwindow.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'staytfhomeparrot',
                            alt: 'Staytf home parrot',
                            name: 'Staytf home parrot',
                            source: '/images/emojis/named/staytfhomeparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'stubparrot',
                            alt: 'Stub parrot',
                            name: 'Stub parrot',
                            source: '/images/emojis/named/stubparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sunglassesparrot',
                            alt: 'Sunglasses parrot',
                            name: 'Sunglasses parrot',
                            source: '/images/emojis/named/sunglassesparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'susparrot',
                            alt: 'Sus parrot',
                            name: 'Sus parrot',
                            source: '/images/emojis/named/susparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'sushiparrot',
                            alt: 'Sushi parrot',
                            name: 'Sushi parrot',
                            source: '/images/emojis/named/sushiparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'syncparrot',
                            alt: 'Sync parrot',
                            name: 'Sync parrot',
                            source: '/images/emojis/named/syncparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'tacoparrot',
                            alt: 'Taco parrot',
                            name: 'Taco parrot',
                            source: '/images/emojis/named/tacoparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'tea_parrot',
                            alt: 'Tea-parrot',
                            name: 'Tea-parrot',
                            source: '/images/emojis/named/tea_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'tenetparrot',
                            alt: 'Tenetparrot',
                            name: 'Tenetparrot',
                            source: '/images/emojis/named/tenetparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'tennisparrot',
                            alt: 'Tennis parrot',
                            name: 'Tennis parrot',
                            source: '/images/emojis/named/tennisparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'thailandparrot',
                            alt: 'Thailand parrot',
                            name: 'Thailand parrot',
                            source: '/images/emojis/named/thailandparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'thegrokeparrot',
                            alt: 'Thegrokeparrot',
                            name: 'Thegrokeparrot',
                            source: '/images/emojis/named/thegrokeparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'thisisfineparrot',
                            alt: 'Thisisfineparrot',
                            name: 'Thisisfineparrot',
                            source: '/images/emojis/named/thisisfineparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'thumbsdownpartyparrot',
                            alt: 'Thumbs down party parrot',
                            name: 'Thumbs down party parrot',
                            source: '/images/emojis/named/thumbsdownpartyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'thumbsupparrot',
                            alt: 'Thumbs up parrot',
                            name: 'Thumbs up parrot',
                            source: '/images/emojis/named/thumbsupparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'tiedyeparrot',
                            alt: 'Tie dye parrot',
                            name: 'Tie dye parrot',
                            source: '/images/emojis/named/tiedyeparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'tinfoilhatparrot',
                            alt: 'Tinfoilhatparrot',
                            name: 'Tinfoilhatparrot',
                            source: '/images/emojis/named/tinfoilhatparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'tpparrot',
                            alt: 'Tp parrot',
                            name: 'Tp parrot',
                            source: '/images/emojis/named/tpparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'transparront',
                            alt: 'Transparront',
                            name: 'Transparront',
                            source: '/images/emojis/named/transparront.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'trans_parrot',
                            alt: 'Trans-parrot',
                            name: 'Trans-parrot',
                            source: '/images/emojis/named/trans_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'triggeredparrot',
                            alt: 'Triggered parrot',
                            name: 'Triggered parrot',
                            source: '/images/emojis/named/triggeredparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'tripletsparrot',
                            alt: 'Triplets parrot',
                            name: 'Triplets parrot',
                            source: '/images/emojis/named/tripletsparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'troll_parrot',
                            alt: 'Troll-parrot',
                            name: 'Troll-parrot',
                            source: '/images/emojis/named/troll_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'twinsparrot',
                            alt: 'Twins parrot',
                            name: 'Twins parrot',
                            source: '/images/emojis/named/twinsparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'ultrafastparrot',
                            alt: 'Ultra fast parrot',
                            name: 'Ultra fast parrot',
                            source: '/images/emojis/named/ultrafastparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'ultraslowparrot',
                            alt: 'Ultra slow parrot',
                            name: 'Ultra slow parrot',
                            source: '/images/emojis/named/ultraslowparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'unicornparrot',
                            alt: 'Unicorn parrot',
                            name: 'Unicorn parrot',
                            source: '/images/emojis/named/unicornparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'upvoteparrot',
                            alt: 'Upvote parrot',
                            name: 'Upvote parrot',
                            source: '/images/emojis/named/upvoteparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'upvotepartyparrot',
                            alt: 'Upvote party parrot',
                            name: 'Upvote party parrot',
                            source: '/images/emojis/named/upvotepartyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'usaparrot',
                            alt: 'Usa parrot',
                            name: 'Usa parrot',
                            source: '/images/emojis/named/usaparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'vaticanparrot',
                            alt: 'Vatican parrot',
                            name: 'Vatican parrot',
                            source: '/images/emojis/named/vaticanparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'verticalparrot',
                            alt: 'Vertical parrot',
                            name: 'Vertical parrot',
                            source: '/images/emojis/named/verticalparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'vikingparrot',
                            alt: 'Viking parrot',
                            name: 'Viking parrot',
                            source: '/images/emojis/named/vikingparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'vimto_parrot',
                            alt: 'Vimto-parrot',
                            name: 'Vimto-parrot',
                            source: '/images/emojis/named/vimto_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'virusparrot',
                            alt: 'Virusparrot',
                            name: 'Virusparrot',
                            source: '/images/emojis/named/virusparrot.png',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'vote_partyparrot',
                            alt: 'Vote-partyparrot',
                            name: 'Vote-partyparrot',
                            source: '/images/emojis/named/vote_partyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'voting_parrot',
                            alt: 'Voting-parrot',
                            name: 'Voting-parrot',
                            source: '/images/emojis/named/voting_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'wave1parrot',
                            alt: 'Wave 1 parrot',
                            name: 'Wave 1 parrot',
                            source: '/images/emojis/named/wave1parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'wave2parrot',
                            alt: 'Wave 2 parrot',
                            name: 'Wave 2 parrot',
                            source: '/images/emojis/named/wave2parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'wave3parrot',
                            alt: 'Wave 3 parrot',
                            name: 'Wave 3 parrot',
                            source: '/images/emojis/named/wave3parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'wave4parrot',
                            alt: 'Wave 4 parrot',
                            name: 'Wave 4 parrot',
                            source: '/images/emojis/named/wave4parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'wave5parrot',
                            alt: 'Wave 5 parrot',
                            name: 'Wave 5 parrot',
                            source: '/images/emojis/named/wave5parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'wave6parrot',
                            alt: 'Wave 6 parrot',
                            name: 'Wave 6 parrot',
                            source: '/images/emojis/named/wave6parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'wave7parrot',
                            alt: 'Wave 7 parrot',
                            name: 'Wave 7 parrot',
                            source: '/images/emojis/named/wave7parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'wave8parrot',
                            alt: 'Wave 8 parrot',
                            name: 'Wave 8 parrot',
                            source: '/images/emojis/named/wave8parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'wave9parrot',
                            alt: 'Wave 9 parrot',
                            name: 'Wave 9 parrot',
                            source: '/images/emojis/named/wave9parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'wendyparrot',
                            alt: 'Wendy parrot',
                            name: 'Wendy parrot',
                            source: '/images/emojis/named/wendyparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'wfhparrot',
                            alt: 'Wfh parrot',
                            name: 'Wfh parrot',
                            source: '/images/emojis/named/wfhparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'whitewalkerparrot',
                            alt: 'White walker parrot',
                            name: 'White walker parrot',
                            source: '/images/emojis/named/whitewalkerparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'wildparrot',
                            alt: 'Wildparrot',
                            name: 'Wildparrot',
                            source: '/images/emojis/named/wildparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'wineparrot',
                            alt: 'Wine parrot',
                            name: 'Wine parrot',
                            source: '/images/emojis/named/wineparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'witnessprotectionparrot',
                            alt: 'Witness protection parrot',
                            name: 'Witness protection parrot',
                            source: '/images/emojis/named/witnessprotectionparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'yosemitesamparrot',
                            alt: 'Yosemite sam parrot',
                            name: 'Yosemite sam parrot',
                            source: '/images/emojis/named/yosemitesamparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'zakarum_parrot',
                            alt: 'Zakarum-parrot',
                            name: 'Zakarum-parrot',
                            source: '/images/emojis/named/zakarum_parrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        },
                        {
                            emojiCode: 'zoukparrot',
                            alt: 'Zouk parrot',
                            name: 'Zouk parrot',
                            source: '/images/emojis/named/zoukparrot.gif',
                            components: [],
                            variants: [],
                            type: 1
                        }
                    ]
                }
            ]
        }
    ]
};

export default emojis;
