import {DisplayPostModel} from '../types';
import {useDelete} from './../useHttpHooks';

export type DeletePostCommand = {
    post: DisplayPostModel;
};

const useDeletePost = () =>
    useDelete<DeletePostCommand>(`/api/posts/delete-post`, {
        errorMessage: 'DELETE api/posts/delete-post failed'
    });

export default useDeletePost;
