﻿interface Emoji {
    src: string;
    alt: string;
    title: string;
}

export const emojis: { [key: string]: Emoji } = {
    ":grinning_face:": {
        "src": "/images/emojis/unicode/svg/1f600.svg",
        "alt": "😀",
        "title": "grinning face"
    },
    ":grinning_face_with_big_eyes:": {
        "src": "/images/emojis/unicode/svg/1f603.svg",
        "alt": "😃",
        "title": "grinning face with big eyes"
    },
    ":grinning_face_with_smiling_eyes:": {
        "src": "/images/emojis/unicode/svg/1f604.svg",
        "alt": "😄",
        "title": "grinning face with smiling eyes"
    },
    ":beaming_face_with_smiling_eyes:": {
        "src": "/images/emojis/unicode/svg/1f601.svg",
        "alt": "😁",
        "title": "beaming face with smiling eyes"
    },
    ":grinning_squinting_face:": {
        "src": "/images/emojis/unicode/svg/1f606.svg",
        "alt": "😆",
        "title": "grinning squinting face"
    },
    ":grinning_face_with_sweat:": {
        "src": "/images/emojis/unicode/svg/1f605.svg",
        "alt": "😅",
        "title": "grinning face with sweat"
    },
    ":rolling_on_the_floor_laughing:": {
        "src": "/images/emojis/unicode/svg/1f923.svg",
        "alt": "🤣",
        "title": "rolling on the floor laughing"
    },
    ":face_with_tears_of_joy:": {
        "src": "/images/emojis/unicode/svg/1f602.svg",
        "alt": "😂",
        "title": "face with tears of joy"
    },
    ":slightly_smiling_face:": {
        "src": "/images/emojis/unicode/svg/1f642.svg",
        "alt": "🙂",
        "title": "slightly smiling face"
    },
    ":upside_down_face:": {
        "src": "/images/emojis/unicode/svg/1f643.svg",
        "alt": "🙃",
        "title": "upside-down face"
    },
    ":winking_face:": {
        "src": "/images/emojis/unicode/svg/1f609.svg",
        "alt": "😉",
        "title": "winking face"
    },
    ":smiling_face_with_smiling_eyes:": {
        "src": "/images/emojis/unicode/svg/1f60a.svg",
        "alt": "😊",
        "title": "smiling face with smiling eyes"
    },
    ":smiling_face_with_halo:": {
        "src": "/images/emojis/unicode/svg/1f607.svg",
        "alt": "😇",
        "title": "smiling face with halo"
    },
    ":smiling_face_with_hearts:": {
        "src": "/images/emojis/unicode/svg/1f970.svg",
        "alt": "🥰",
        "title": "smiling face with hearts"
    },
    ":smiling_face_with_heart_eyes:": {
        "src": "/images/emojis/unicode/svg/1f60d.svg",
        "alt": "😍",
        "title": "smiling face with heart-eyes"
    },
    ":star_struck:": {
        "src": "/images/emojis/unicode/svg/1f929.svg",
        "alt": "🤩",
        "title": "star-struck"
    },
    ":face_blowing_a_kiss:": {
        "src": "/images/emojis/unicode/svg/1f618.svg",
        "alt": "😘",
        "title": "face blowing a kiss"
    },
    ":kissing_face:": {
        "src": "/images/emojis/unicode/svg/1f617.svg",
        "alt": "😗",
        "title": "kissing face"
    },
    ":smiling_face:": {
        "src": "/images/emojis/unicode/svg/263a.svg",
        "alt": "☺",
        "title": "smiling face"
    },
    ":kissing_face_with_closed_eyes:": {
        "src": "/images/emojis/unicode/svg/1f61a.svg",
        "alt": "😚",
        "title": "kissing face with closed eyes"
    },
    ":kissing_face_with_smiling_eyes:": {
        "src": "/images/emojis/unicode/svg/1f619.svg",
        "alt": "😙",
        "title": "kissing face with smiling eyes"
    },
    ":smiling_face_with_tear:": {
        "src": "/images/emojis/unicode/svg/1f972.svg",
        "alt": "🥲",
        "title": "smiling face with tear"
    },
    ":face_savoring_food:": {
        "src": "/images/emojis/unicode/svg/1f60b.svg",
        "alt": "😋",
        "title": "face savoring food"
    },
    ":face_with_tongue:": {
        "src": "/images/emojis/unicode/svg/1f61b.svg",
        "alt": "😛",
        "title": "face with tongue"
    },
    ":winking_face_with_tongue:": {
        "src": "/images/emojis/unicode/svg/1f61c.svg",
        "alt": "😜",
        "title": "winking face with tongue"
    },
    ":zany_face:": {
        "src": "/images/emojis/unicode/svg/1f92a.svg",
        "alt": "🤪",
        "title": "zany face"
    },
    ":squinting_face_with_tongue:": {
        "src": "/images/emojis/unicode/svg/1f61d.svg",
        "alt": "😝",
        "title": "squinting face with tongue"
    },
    ":money_mouth_face:": {
        "src": "/images/emojis/unicode/svg/1f911.svg",
        "alt": "🤑",
        "title": "money-mouth face"
    },
    ":hugging_face:": {
        "src": "/images/emojis/unicode/svg/1f917.svg",
        "alt": "🤗",
        "title": "hugging face"
    },
    ":face_with_hand_over_mouth:": {
        "src": "/images/emojis/unicode/svg/1f92d.svg",
        "alt": "🤭",
        "title": "face with hand over mouth"
    },
    ":shushing_face:": {
        "src": "/images/emojis/unicode/svg/1f92b.svg",
        "alt": "🤫",
        "title": "shushing face"
    },
    ":thinking_face:": {
        "src": "/images/emojis/unicode/svg/1f914.svg",
        "alt": "🤔",
        "title": "thinking face"
    },
    ":zipper_mouth_face:": {
        "src": "/images/emojis/unicode/svg/1f910.svg",
        "alt": "🤐",
        "title": "zipper-mouth face"
    },
    ":face_with_raised_eyebrow:": {
        "src": "/images/emojis/unicode/svg/1f928.svg",
        "alt": "🤨",
        "title": "face with raised eyebrow"
    },
    ":neutral_face:": {
        "src": "/images/emojis/unicode/svg/1f610.svg",
        "alt": "😐",
        "title": "neutral face"
    },
    ":expressionless_face:": {
        "src": "/images/emojis/unicode/svg/1f611.svg",
        "alt": "😑",
        "title": "expressionless face"
    },
    ":face_without_mouth:": {
        "src": "/images/emojis/unicode/svg/1f636.svg",
        "alt": "😶",
        "title": "face without mouth"
    },
    ":smirking_face:": {
        "src": "/images/emojis/unicode/svg/1f60f.svg",
        "alt": "😏",
        "title": "smirking face"
    },
    ":unamused_face:": {
        "src": "/images/emojis/unicode/svg/1f612.svg",
        "alt": "😒",
        "title": "unamused face"
    },
    ":face_with_rolling_eyes:": {
        "src": "/images/emojis/unicode/svg/1f644.svg",
        "alt": "🙄",
        "title": "face with rolling eyes"
    },
    ":grimacing_face:": {
        "src": "/images/emojis/unicode/svg/1f62c.svg",
        "alt": "😬",
        "title": "grimacing face"
    },
    ":lying_face:": {
        "src": "/images/emojis/unicode/svg/1f925.svg",
        "alt": "🤥",
        "title": "lying face"
    },
    ":relieved_face:": {
        "src": "/images/emojis/unicode/svg/1f60c.svg",
        "alt": "😌",
        "title": "relieved face"
    },
    ":pensive_face:": {
        "src": "/images/emojis/unicode/svg/1f614.svg",
        "alt": "😔",
        "title": "pensive face"
    },
    ":sleepy_face:": {
        "src": "/images/emojis/unicode/svg/1f62a.svg",
        "alt": "😪",
        "title": "sleepy face"
    },
    ":drooling_face:": {
        "src": "/images/emojis/unicode/svg/1f924.svg",
        "alt": "🤤",
        "title": "drooling face"
    },
    ":sleeping_face:": {
        "src": "/images/emojis/unicode/svg/1f634.svg",
        "alt": "😴",
        "title": "sleeping face"
    },
    ":face_with_medical_mask:": {
        "src": "/images/emojis/unicode/svg/1f637.svg",
        "alt": "😷",
        "title": "face with medical mask"
    },
    ":face_with_thermometer:": {
        "src": "/images/emojis/unicode/svg/1f912.svg",
        "alt": "🤒",
        "title": "face with thermometer"
    },
    ":face_with_head_bandage:": {
        "src": "/images/emojis/unicode/svg/1f915.svg",
        "alt": "🤕",
        "title": "face with head-bandage"
    },
    ":nauseated_face:": {
        "src": "/images/emojis/unicode/svg/1f922.svg",
        "alt": "🤢",
        "title": "nauseated face"
    },
    ":face_vomiting:": {
        "src": "/images/emojis/unicode/svg/1f92e.svg",
        "alt": "🤮",
        "title": "face vomiting"
    },
    ":sneezing_face:": {
        "src": "/images/emojis/unicode/svg/1f927.svg",
        "alt": "🤧",
        "title": "sneezing face"
    },
    ":hot_face:": {
        "src": "/images/emojis/unicode/svg/1f975.svg",
        "alt": "🥵",
        "title": "hot face"
    },
    ":cold_face:": {
        "src": "/images/emojis/unicode/svg/1f976.svg",
        "alt": "🥶",
        "title": "cold face"
    },
    ":woozy_face:": {
        "src": "/images/emojis/unicode/svg/1f974.svg",
        "alt": "🥴",
        "title": "woozy face"
    },
    ":knocked_out_face:": {
        "src": "/images/emojis/unicode/svg/1f635.svg",
        "alt": "😵",
        "title": "knocked-out face"
    },
    ":exploding_head:": {
        "src": "/images/emojis/unicode/svg/1f92f.svg",
        "alt": "🤯",
        "title": "exploding head"
    },
    ":cowboy_hat_face:": {
        "src": "/images/emojis/unicode/svg/1f920.svg",
        "alt": "🤠",
        "title": "cowboy hat face"
    },
    ":partying_face:": {
        "src": "/images/emojis/unicode/svg/1f973.svg",
        "alt": "🥳",
        "title": "partying face"
    },
    ":disguised_face:": {
        "src": "/images/emojis/unicode/svg/1f978.svg",
        "alt": "🥸",
        "title": "disguised face"
    },
    ":smiling_face_with_sunglasses:": {
        "src": "/images/emojis/unicode/svg/1f60e.svg",
        "alt": "😎",
        "title": "smiling face with sunglasses"
    },
    ":nerd_face:": {
        "src": "/images/emojis/unicode/svg/1f913.svg",
        "alt": "🤓",
        "title": "nerd face"
    },
    ":face_with_monocle:": {
        "src": "/images/emojis/unicode/svg/1f9d0.svg",
        "alt": "🧐",
        "title": "face with monocle"
    },
    ":confused_face:": {
        "src": "/images/emojis/unicode/svg/1f615.svg",
        "alt": "😕",
        "title": "confused face"
    },
    ":worried_face:": {
        "src": "/images/emojis/unicode/svg/1f61f.svg",
        "alt": "😟",
        "title": "worried face"
    },
    ":slightly_frowning_face:": {
        "src": "/images/emojis/unicode/svg/1f641.svg",
        "alt": "🙁",
        "title": "slightly frowning face"
    },
    ":frowning_face:": {
        "src": "/images/emojis/unicode/svg/2639.svg",
        "alt": "☹",
        "title": "frowning face"
    },
    ":face_with_open_mouth:": {
        "src": "/images/emojis/unicode/svg/1f62e.svg",
        "alt": "😮",
        "title": "face with open mouth"
    },
    ":hushed_face:": {
        "src": "/images/emojis/unicode/svg/1f62f.svg",
        "alt": "😯",
        "title": "hushed face"
    },
    ":astonished_face:": {
        "src": "/images/emojis/unicode/svg/1f632.svg",
        "alt": "😲",
        "title": "astonished face"
    },
    ":flushed_face:": {
        "src": "/images/emojis/unicode/svg/1f633.svg",
        "alt": "😳",
        "title": "flushed face"
    },
    ":pleading_face:": {
        "src": "/images/emojis/unicode/svg/1f97a.svg",
        "alt": "🥺",
        "title": "pleading face"
    },
    ":frowning_face_with_open_mouth:": {
        "src": "/images/emojis/unicode/svg/1f626.svg",
        "alt": "😦",
        "title": "frowning face with open mouth"
    },
    ":anguished_face:": {
        "src": "/images/emojis/unicode/svg/1f627.svg",
        "alt": "😧",
        "title": "anguished face"
    },
    ":fearful_face:": {
        "src": "/images/emojis/unicode/svg/1f628.svg",
        "alt": "😨",
        "title": "fearful face"
    },
    ":anxious_face_with_sweat:": {
        "src": "/images/emojis/unicode/svg/1f630.svg",
        "alt": "😰",
        "title": "anxious face with sweat"
    },
    ":sad_but_relieved_face:": {
        "src": "/images/emojis/unicode/svg/1f625.svg",
        "alt": "😥",
        "title": "sad but relieved face"
    },
    ":crying_face:": {
        "src": "/images/emojis/unicode/svg/1f622.svg",
        "alt": "😢",
        "title": "crying face"
    },
    ":loudly_crying_face:": {
        "src": "/images/emojis/unicode/svg/1f62d.svg",
        "alt": "😭",
        "title": "loudly crying face"
    },
    ":face_screaming_in_fear:": {
        "src": "/images/emojis/unicode/svg/1f631.svg",
        "alt": "😱",
        "title": "face screaming in fear"
    },
    ":confounded_face:": {
        "src": "/images/emojis/unicode/svg/1f616.svg",
        "alt": "😖",
        "title": "confounded face"
    },
    ":persevering_face:": {
        "src": "/images/emojis/unicode/svg/1f623.svg",
        "alt": "😣",
        "title": "persevering face"
    },
    ":disappointed_face:": {
        "src": "/images/emojis/unicode/svg/1f61e.svg",
        "alt": "😞",
        "title": "disappointed face"
    },
    ":downcast_face_with_sweat:": {
        "src": "/images/emojis/unicode/svg/1f613.svg",
        "alt": "😓",
        "title": "downcast face with sweat"
    },
    ":weary_face:": {
        "src": "/images/emojis/unicode/svg/1f629.svg",
        "alt": "😩",
        "title": "weary face"
    },
    ":tired_face:": {
        "src": "/images/emojis/unicode/svg/1f62b.svg",
        "alt": "😫",
        "title": "tired face"
    },
    ":yawning_face:": {
        "src": "/images/emojis/unicode/svg/1f971.svg",
        "alt": "🥱",
        "title": "yawning face"
    },
    ":face_with_steam_from_nose:": {
        "src": "/images/emojis/unicode/svg/1f624.svg",
        "alt": "😤",
        "title": "face with steam from nose"
    },
    ":pouting_face:": {
        "src": "/images/emojis/unicode/svg/1f621.svg",
        "alt": "😡",
        "title": "pouting face"
    },
    ":angry_face:": {
        "src": "/images/emojis/unicode/svg/1f620.svg",
        "alt": "😠",
        "title": "angry face"
    },
    ":face_with_symbols_on_mouth:": {
        "src": "/images/emojis/unicode/svg/1f92c.svg",
        "alt": "🤬",
        "title": "face with symbols on mouth"
    },
    ":smiling_face_with_horns:": {
        "src": "/images/emojis/unicode/svg/1f608.svg",
        "alt": "😈",
        "title": "smiling face with horns"
    },
    ":angry_face_with_horns:": {
        "src": "/images/emojis/unicode/svg/1f47f.svg",
        "alt": "👿",
        "title": "angry face with horns"
    },
    ":skull:": {
        "src": "/images/emojis/unicode/svg/1f480.svg",
        "alt": "💀",
        "title": "skull"
    },
    ":skull_and_crossbones:": {
        "src": "/images/emojis/unicode/svg/2620.svg",
        "alt": "☠",
        "title": "skull and crossbones"
    },
    ":pile_of_poo:": {
        "src": "/images/emojis/unicode/svg/1f4a9.svg",
        "alt": "💩",
        "title": "pile of poo"
    },
    ":clown_face:": {
        "src": "/images/emojis/unicode/svg/1f921.svg",
        "alt": "🤡",
        "title": "clown face"
    },
    ":ogre:": {
        "src": "/images/emojis/unicode/svg/1f479.svg",
        "alt": "👹",
        "title": "ogre"
    },
    ":goblin:": {
        "src": "/images/emojis/unicode/svg/1f47a.svg",
        "alt": "👺",
        "title": "goblin"
    },
    ":ghost:": {
        "src": "/images/emojis/unicode/svg/1f47b.svg",
        "alt": "👻",
        "title": "ghost"
    },
    ":alien:": {
        "src": "/images/emojis/unicode/svg/1f47d.svg",
        "alt": "👽",
        "title": "alien"
    },
    ":alien_monster:": {
        "src": "/images/emojis/unicode/svg/1f47e.svg",
        "alt": "👾",
        "title": "alien monster"
    },
    ":robot:": {
        "src": "/images/emojis/unicode/svg/1f916.svg",
        "alt": "🤖",
        "title": "robot"
    },
    ":grinning_cat:": {
        "src": "/images/emojis/unicode/svg/1f63a.svg",
        "alt": "😺",
        "title": "grinning cat"
    },
    ":grinning_cat_with_smiling_eyes:": {
        "src": "/images/emojis/unicode/svg/1f638.svg",
        "alt": "😸",
        "title": "grinning cat with smiling eyes"
    },
    ":cat_with_tears_of_joy:": {
        "src": "/images/emojis/unicode/svg/1f639.svg",
        "alt": "😹",
        "title": "cat with tears of joy"
    },
    ":smiling_cat_with_heart_eyes:": {
        "src": "/images/emojis/unicode/svg/1f63b.svg",
        "alt": "😻",
        "title": "smiling cat with heart-eyes"
    },
    ":cat_with_wry_smile:": {
        "src": "/images/emojis/unicode/svg/1f63c.svg",
        "alt": "😼",
        "title": "cat with wry smile"
    },
    ":kissing_cat:": {
        "src": "/images/emojis/unicode/svg/1f63d.svg",
        "alt": "😽",
        "title": "kissing cat"
    },
    ":weary_cat:": {
        "src": "/images/emojis/unicode/svg/1f640.svg",
        "alt": "🙀",
        "title": "weary cat"
    },
    ":crying_cat:": {
        "src": "/images/emojis/unicode/svg/1f63f.svg",
        "alt": "😿",
        "title": "crying cat"
    },
    ":pouting_cat:": {
        "src": "/images/emojis/unicode/svg/1f63e.svg",
        "alt": "😾",
        "title": "pouting cat"
    },
    ":see_no_evil_monkey:": {
        "src": "/images/emojis/unicode/svg/1f648.svg",
        "alt": "🙈",
        "title": "see-no-evil monkey"
    },
    ":hear_no_evil_monkey:": {
        "src": "/images/emojis/unicode/svg/1f649.svg",
        "alt": "🙉",
        "title": "hear-no-evil monkey"
    },
    ":speak_no_evil_monkey:": {
        "src": "/images/emojis/unicode/svg/1f64a.svg",
        "alt": "🙊",
        "title": "speak-no-evil monkey"
    },
    ":kiss_mark:": {
        "src": "/images/emojis/unicode/svg/1f48b.svg",
        "alt": "💋",
        "title": "kiss mark"
    },
    ":love_letter:": {
        "src": "/images/emojis/unicode/svg/1f48c.svg",
        "alt": "💌",
        "title": "love letter"
    },
    ":heart_with_arrow:": {
        "src": "/images/emojis/unicode/svg/1f498.svg",
        "alt": "💘",
        "title": "heart with arrow"
    },
    ":heart_with_ribbon:": {
        "src": "/images/emojis/unicode/svg/1f49d.svg",
        "alt": "💝",
        "title": "heart with ribbon"
    },
    ":sparkling_heart:": {
        "src": "/images/emojis/unicode/svg/1f496.svg",
        "alt": "💖",
        "title": "sparkling heart"
    },
    ":growing_heart:": {
        "src": "/images/emojis/unicode/svg/1f497.svg",
        "alt": "💗",
        "title": "growing heart"
    },
    ":beating_heart:": {
        "src": "/images/emojis/unicode/svg/1f493.svg",
        "alt": "💓",
        "title": "beating heart"
    },
    ":revolving_hearts:": {
        "src": "/images/emojis/unicode/svg/1f49e.svg",
        "alt": "💞",
        "title": "revolving hearts"
    },
    ":two_hearts:": {
        "src": "/images/emojis/unicode/svg/1f495.svg",
        "alt": "💕",
        "title": "two hearts"
    },
    ":heart_decoration:": {
        "src": "/images/emojis/unicode/svg/1f49f.svg",
        "alt": "💟",
        "title": "heart decoration"
    },
    ":heart_exclamation:": {
        "src": "/images/emojis/unicode/svg/2763.svg",
        "alt": "❣",
        "title": "heart exclamation"
    },
    ":broken_heart:": {
        "src": "/images/emojis/unicode/svg/1f494.svg",
        "alt": "💔",
        "title": "broken heart"
    },
    ":red_heart:": {
        "src": "/images/emojis/unicode/svg/2764.svg",
        "alt": "❤",
        "title": "red heart"
    },
    ":orange_heart:": {
        "src": "/images/emojis/unicode/svg/1f9e1.svg",
        "alt": "🧡",
        "title": "orange heart"
    },
    ":yellow_heart:": {
        "src": "/images/emojis/unicode/svg/1f49b.svg",
        "alt": "💛",
        "title": "yellow heart"
    },
    ":green_heart:": {
        "src": "/images/emojis/unicode/svg/1f49a.svg",
        "alt": "💚",
        "title": "green heart"
    },
    ":blue_heart:": {
        "src": "/images/emojis/unicode/svg/1f499.svg",
        "alt": "💙",
        "title": "blue heart"
    },
    ":purple_heart:": {
        "src": "/images/emojis/unicode/svg/1f49c.svg",
        "alt": "💜",
        "title": "purple heart"
    },
    ":brown_heart:": {
        "src": "/images/emojis/unicode/svg/1f90e.svg",
        "alt": "🤎",
        "title": "brown heart"
    },
    ":black_heart:": {
        "src": "/images/emojis/unicode/svg/1f5a4.svg",
        "alt": "🖤",
        "title": "black heart"
    },
    ":white_heart:": {
        "src": "/images/emojis/unicode/svg/1f90d.svg",
        "alt": "🤍",
        "title": "white heart"
    },
    ":hundred_points:": {
        "src": "/images/emojis/unicode/svg/1f4af.svg",
        "alt": "💯",
        "title": "hundred points"
    },
    ":anger_symbol:": {
        "src": "/images/emojis/unicode/svg/1f4a2.svg",
        "alt": "💢",
        "title": "anger symbol"
    },
    ":collision:": {
        "src": "/images/emojis/unicode/svg/1f4a5.svg",
        "alt": "💥",
        "title": "collision"
    },
    ":dizzy:": {
        "src": "/images/emojis/unicode/svg/1f4ab.svg",
        "alt": "💫",
        "title": "dizzy"
    },
    ":sweat_droplets:": {
        "src": "/images/emojis/unicode/svg/1f4a6.svg",
        "alt": "💦",
        "title": "sweat droplets"
    },
    ":dashing_away:": {
        "src": "/images/emojis/unicode/svg/1f4a8.svg",
        "alt": "💨",
        "title": "dashing away"
    },
    ":hole:": {
        "src": "/images/emojis/unicode/svg/1f573.svg",
        "alt": "🕳",
        "title": "hole"
    },
    ":bomb:": {
        "src": "/images/emojis/unicode/svg/1f4a3.svg",
        "alt": "💣",
        "title": "bomb"
    },
    ":speech_balloon:": {
        "src": "/images/emojis/unicode/svg/1f4ac.svg",
        "alt": "💬",
        "title": "speech balloon"
    },
    ":left_speech_bubble:": {
        "src": "/images/emojis/unicode/svg/1f5e8.svg",
        "alt": "🗨",
        "title": "left speech bubble"
    },
    ":right_anger_bubble:": {
        "src": "/images/emojis/unicode/svg/1f5ef.svg",
        "alt": "🗯",
        "title": "right anger bubble"
    },
    ":thought_balloon:": {
        "src": "/images/emojis/unicode/svg/1f4ad.svg",
        "alt": "💭",
        "title": "thought balloon"
    },
    ":zzz:": {
        "src": "/images/emojis/unicode/svg/1f4a4.svg",
        "alt": "💤",
        "title": "zzz"
    },
    ":waving_hand:": {
        "src": "/images/emojis/unicode/svg/1f44b.svg",
        "alt": "👋",
        "title": "waving hand"
    },
    ":waving_hand_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44b-1f3fb.svg",
        "alt": "👋🏻",
        "title": "waving hand"
    },
    ":waving_hand_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44b-1f3fc.svg",
        "alt": "👋🏼",
        "title": "waving hand"
    },
    ":waving_hand_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44b-1f3fd.svg",
        "alt": "👋🏽",
        "title": "waving hand"
    },
    ":waving_hand_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44b-1f3fe.svg",
        "alt": "👋🏾",
        "title": "waving hand"
    },
    ":waving_hand_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44b-1f3ff.svg",
        "alt": "👋🏿",
        "title": "waving hand"
    },
    ":raised_back_of_hand:": {
        "src": "/images/emojis/unicode/svg/1f91a.svg",
        "alt": "🤚",
        "title": "raised back of hand"
    },
    ":raised_back_of_hand_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91a-1f3fb.svg",
        "alt": "🤚🏻",
        "title": "raised back of hand"
    },
    ":raised_back_of_hand_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91a-1f3fc.svg",
        "alt": "🤚🏼",
        "title": "raised back of hand"
    },
    ":raised_back_of_hand_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91a-1f3fd.svg",
        "alt": "🤚🏽",
        "title": "raised back of hand"
    },
    ":raised_back_of_hand_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91a-1f3fe.svg",
        "alt": "🤚🏾",
        "title": "raised back of hand"
    },
    ":raised_back_of_hand_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91a-1f3ff.svg",
        "alt": "🤚🏿",
        "title": "raised back of hand"
    },
    ":raised_hand:": {
        "src": "/images/emojis/unicode/svg/270b.svg",
        "alt": "✋",
        "title": "raised hand"
    },
    ":raised_hand_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/270b-1f3fb.svg",
        "alt": "✋🏻",
        "title": "raised hand"
    },
    ":raised_hand_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/270b-1f3fc.svg",
        "alt": "✋🏼",
        "title": "raised hand"
    },
    ":raised_hand_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/270b-1f3fd.svg",
        "alt": "✋🏽",
        "title": "raised hand"
    },
    ":raised_hand_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/270b-1f3fe.svg",
        "alt": "✋🏾",
        "title": "raised hand"
    },
    ":raised_hand_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/270b-1f3ff.svg",
        "alt": "✋🏿",
        "title": "raised hand"
    },
    ":vulcan_salute:": {
        "src": "/images/emojis/unicode/svg/1f596.svg",
        "alt": "🖖",
        "title": "vulcan salute"
    },
    ":vulcan_salute_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f596-1f3fb.svg",
        "alt": "🖖🏻",
        "title": "vulcan salute"
    },
    ":vulcan_salute_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f596-1f3fc.svg",
        "alt": "🖖🏼",
        "title": "vulcan salute"
    },
    ":vulcan_salute_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f596-1f3fd.svg",
        "alt": "🖖🏽",
        "title": "vulcan salute"
    },
    ":vulcan_salute_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f596-1f3fe.svg",
        "alt": "🖖🏾",
        "title": "vulcan salute"
    },
    ":vulcan_salute_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f596-1f3ff.svg",
        "alt": "🖖🏿",
        "title": "vulcan salute"
    },
    ":ok_hand:": {
        "src": "/images/emojis/unicode/svg/1f44c.svg",
        "alt": "👌",
        "title": "OK hand"
    },
    ":ok_hand_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44c-1f3fb.svg",
        "alt": "👌🏻",
        "title": "OK hand"
    },
    ":ok_hand_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44c-1f3fc.svg",
        "alt": "👌🏼",
        "title": "OK hand"
    },
    ":ok_hand_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44c-1f3fd.svg",
        "alt": "👌🏽",
        "title": "OK hand"
    },
    ":ok_hand_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44c-1f3fe.svg",
        "alt": "👌🏾",
        "title": "OK hand"
    },
    ":ok_hand_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44c-1f3ff.svg",
        "alt": "👌🏿",
        "title": "OK hand"
    },
    ":pinched_fingers:": {
        "src": "/images/emojis/unicode/svg/1f90c.svg",
        "alt": "🤌",
        "title": "pinched fingers"
    },
    ":pinched_fingers_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f90c-1f3fb.svg",
        "alt": "🤌🏻",
        "title": "pinched fingers"
    },
    ":pinched_fingers_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f90c-1f3fc.svg",
        "alt": "🤌🏼",
        "title": "pinched fingers"
    },
    ":pinched_fingers_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f90c-1f3fd.svg",
        "alt": "🤌🏽",
        "title": "pinched fingers"
    },
    ":pinched_fingers_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f90c-1f3fe.svg",
        "alt": "🤌🏾",
        "title": "pinched fingers"
    },
    ":pinched_fingers_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f90c-1f3ff.svg",
        "alt": "🤌🏿",
        "title": "pinched fingers"
    },
    ":pinching_hand:": {
        "src": "/images/emojis/unicode/svg/1f90f.svg",
        "alt": "🤏",
        "title": "pinching hand"
    },
    ":pinching_hand_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f90f-1f3fb.svg",
        "alt": "🤏🏻",
        "title": "pinching hand"
    },
    ":pinching_hand_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f90f-1f3fc.svg",
        "alt": "🤏🏼",
        "title": "pinching hand"
    },
    ":pinching_hand_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f90f-1f3fd.svg",
        "alt": "🤏🏽",
        "title": "pinching hand"
    },
    ":pinching_hand_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f90f-1f3fe.svg",
        "alt": "🤏🏾",
        "title": "pinching hand"
    },
    ":pinching_hand_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f90f-1f3ff.svg",
        "alt": "🤏🏿",
        "title": "pinching hand"
    },
    ":crossed_fingers:": {
        "src": "/images/emojis/unicode/svg/1f91e.svg",
        "alt": "🤞",
        "title": "crossed fingers"
    },
    ":crossed_fingers_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91e-1f3fb.svg",
        "alt": "🤞🏻",
        "title": "crossed fingers"
    },
    ":crossed_fingers_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91e-1f3fc.svg",
        "alt": "🤞🏼",
        "title": "crossed fingers"
    },
    ":crossed_fingers_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91e-1f3fd.svg",
        "alt": "🤞🏽",
        "title": "crossed fingers"
    },
    ":crossed_fingers_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91e-1f3fe.svg",
        "alt": "🤞🏾",
        "title": "crossed fingers"
    },
    ":crossed_fingers_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91e-1f3ff.svg",
        "alt": "🤞🏿",
        "title": "crossed fingers"
    },
    ":love_you_gesture:": {
        "src": "/images/emojis/unicode/svg/1f91f.svg",
        "alt": "🤟",
        "title": "love-you gesture"
    },
    ":love_you_gesture_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91f-1f3fb.svg",
        "alt": "🤟🏻",
        "title": "love-you gesture"
    },
    ":love_you_gesture_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91f-1f3fc.svg",
        "alt": "🤟🏼",
        "title": "love-you gesture"
    },
    ":love_you_gesture_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91f-1f3fd.svg",
        "alt": "🤟🏽",
        "title": "love-you gesture"
    },
    ":love_you_gesture_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91f-1f3fe.svg",
        "alt": "🤟🏾",
        "title": "love-you gesture"
    },
    ":love_you_gesture_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91f-1f3ff.svg",
        "alt": "🤟🏿",
        "title": "love-you gesture"
    },
    ":sign_of_the_horns:": {
        "src": "/images/emojis/unicode/svg/1f918.svg",
        "alt": "🤘",
        "title": "sign of the horns"
    },
    ":sign_of_the_horns_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f918-1f3fb.svg",
        "alt": "🤘🏻",
        "title": "sign of the horns"
    },
    ":sign_of_the_horns_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f918-1f3fc.svg",
        "alt": "🤘🏼",
        "title": "sign of the horns"
    },
    ":sign_of_the_horns_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f918-1f3fd.svg",
        "alt": "🤘🏽",
        "title": "sign of the horns"
    },
    ":sign_of_the_horns_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f918-1f3fe.svg",
        "alt": "🤘🏾",
        "title": "sign of the horns"
    },
    ":sign_of_the_horns_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f918-1f3ff.svg",
        "alt": "🤘🏿",
        "title": "sign of the horns"
    },
    ":call_me_hand:": {
        "src": "/images/emojis/unicode/svg/1f919.svg",
        "alt": "🤙",
        "title": "call me hand"
    },
    ":call_me_hand_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f919-1f3fb.svg",
        "alt": "🤙🏻",
        "title": "call me hand"
    },
    ":call_me_hand_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f919-1f3fc.svg",
        "alt": "🤙🏼",
        "title": "call me hand"
    },
    ":call_me_hand_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f919-1f3fd.svg",
        "alt": "🤙🏽",
        "title": "call me hand"
    },
    ":call_me_hand_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f919-1f3fe.svg",
        "alt": "🤙🏾",
        "title": "call me hand"
    },
    ":call_me_hand_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f919-1f3ff.svg",
        "alt": "🤙🏿",
        "title": "call me hand"
    },
    ":backhand_index_pointing_left:": {
        "src": "/images/emojis/unicode/svg/1f448.svg",
        "alt": "👈",
        "title": "backhand index pointing left"
    },
    ":backhand_index_pointing_left_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f448-1f3fb.svg",
        "alt": "👈🏻",
        "title": "backhand index pointing left"
    },
    ":backhand_index_pointing_left_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f448-1f3fc.svg",
        "alt": "👈🏼",
        "title": "backhand index pointing left"
    },
    ":backhand_index_pointing_left_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f448-1f3fd.svg",
        "alt": "👈🏽",
        "title": "backhand index pointing left"
    },
    ":backhand_index_pointing_left_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f448-1f3fe.svg",
        "alt": "👈🏾",
        "title": "backhand index pointing left"
    },
    ":backhand_index_pointing_left_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f448-1f3ff.svg",
        "alt": "👈🏿",
        "title": "backhand index pointing left"
    },
    ":backhand_index_pointing_right:": {
        "src": "/images/emojis/unicode/svg/1f449.svg",
        "alt": "👉",
        "title": "backhand index pointing right"
    },
    ":backhand_index_pointing_right_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f449-1f3fb.svg",
        "alt": "👉🏻",
        "title": "backhand index pointing right"
    },
    ":backhand_index_pointing_right_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f449-1f3fc.svg",
        "alt": "👉🏼",
        "title": "backhand index pointing right"
    },
    ":backhand_index_pointing_right_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f449-1f3fd.svg",
        "alt": "👉🏽",
        "title": "backhand index pointing right"
    },
    ":backhand_index_pointing_right_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f449-1f3fe.svg",
        "alt": "👉🏾",
        "title": "backhand index pointing right"
    },
    ":backhand_index_pointing_right_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f449-1f3ff.svg",
        "alt": "👉🏿",
        "title": "backhand index pointing right"
    },
    ":backhand_index_pointing_up:": {
        "src": "/images/emojis/unicode/svg/1f446.svg",
        "alt": "👆",
        "title": "backhand index pointing up"
    },
    ":backhand_index_pointing_up_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f446-1f3fb.svg",
        "alt": "👆🏻",
        "title": "backhand index pointing up"
    },
    ":backhand_index_pointing_up_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f446-1f3fc.svg",
        "alt": "👆🏼",
        "title": "backhand index pointing up"
    },
    ":backhand_index_pointing_up_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f446-1f3fd.svg",
        "alt": "👆🏽",
        "title": "backhand index pointing up"
    },
    ":backhand_index_pointing_up_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f446-1f3fe.svg",
        "alt": "👆🏾",
        "title": "backhand index pointing up"
    },
    ":backhand_index_pointing_up_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f446-1f3ff.svg",
        "alt": "👆🏿",
        "title": "backhand index pointing up"
    },
    ":middle_finger:": {
        "src": "/images/emojis/unicode/svg/1f595.svg",
        "alt": "🖕",
        "title": "middle finger"
    },
    ":middle_finger_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f595-1f3fb.svg",
        "alt": "🖕🏻",
        "title": "middle finger"
    },
    ":middle_finger_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f595-1f3fc.svg",
        "alt": "🖕🏼",
        "title": "middle finger"
    },
    ":middle_finger_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f595-1f3fd.svg",
        "alt": "🖕🏽",
        "title": "middle finger"
    },
    ":middle_finger_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f595-1f3fe.svg",
        "alt": "🖕🏾",
        "title": "middle finger"
    },
    ":middle_finger_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f595-1f3ff.svg",
        "alt": "🖕🏿",
        "title": "middle finger"
    },
    ":backhand_index_pointing_down:": {
        "src": "/images/emojis/unicode/svg/1f447.svg",
        "alt": "👇",
        "title": "backhand index pointing down"
    },
    ":backhand_index_pointing_down_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f447-1f3fb.svg",
        "alt": "👇🏻",
        "title": "backhand index pointing down"
    },
    ":backhand_index_pointing_down_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f447-1f3fc.svg",
        "alt": "👇🏼",
        "title": "backhand index pointing down"
    },
    ":backhand_index_pointing_down_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f447-1f3fd.svg",
        "alt": "👇🏽",
        "title": "backhand index pointing down"
    },
    ":backhand_index_pointing_down_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f447-1f3fe.svg",
        "alt": "👇🏾",
        "title": "backhand index pointing down"
    },
    ":backhand_index_pointing_down_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f447-1f3ff.svg",
        "alt": "👇🏿",
        "title": "backhand index pointing down"
    },
    ":thumbs_up:": {
        "src": "/images/emojis/unicode/svg/1f44d.svg",
        "alt": "👍",
        "title": "thumbs up"
    },
    ":thumbs_up_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44d-1f3fb.svg",
        "alt": "👍🏻",
        "title": "thumbs up"
    },
    ":thumbs_up_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44d-1f3fc.svg",
        "alt": "👍🏼",
        "title": "thumbs up"
    },
    ":thumbs_up_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44d-1f3fd.svg",
        "alt": "👍🏽",
        "title": "thumbs up"
    },
    ":thumbs_up_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44d-1f3fe.svg",
        "alt": "👍🏾",
        "title": "thumbs up"
    },
    ":thumbs_up_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44d-1f3ff.svg",
        "alt": "👍🏿",
        "title": "thumbs up"
    },
    ":thumbs_down:": {
        "src": "/images/emojis/unicode/svg/1f44e.svg",
        "alt": "👎",
        "title": "thumbs down"
    },
    ":thumbs_down_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44e-1f3fb.svg",
        "alt": "👎🏻",
        "title": "thumbs down"
    },
    ":thumbs_down_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44e-1f3fc.svg",
        "alt": "👎🏼",
        "title": "thumbs down"
    },
    ":thumbs_down_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44e-1f3fd.svg",
        "alt": "👎🏽",
        "title": "thumbs down"
    },
    ":thumbs_down_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44e-1f3fe.svg",
        "alt": "👎🏾",
        "title": "thumbs down"
    },
    ":thumbs_down_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44e-1f3ff.svg",
        "alt": "👎🏿",
        "title": "thumbs down"
    },
    ":raised_fist:": {
        "src": "/images/emojis/unicode/svg/270a.svg",
        "alt": "✊",
        "title": "raised fist"
    },
    ":raised_fist_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/270a-1f3fb.svg",
        "alt": "✊🏻",
        "title": "raised fist"
    },
    ":raised_fist_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/270a-1f3fc.svg",
        "alt": "✊🏼",
        "title": "raised fist"
    },
    ":raised_fist_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/270a-1f3fd.svg",
        "alt": "✊🏽",
        "title": "raised fist"
    },
    ":raised_fist_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/270a-1f3fe.svg",
        "alt": "✊🏾",
        "title": "raised fist"
    },
    ":raised_fist_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/270a-1f3ff.svg",
        "alt": "✊🏿",
        "title": "raised fist"
    },
    ":oncoming_fist:": {
        "src": "/images/emojis/unicode/svg/1f44a.svg",
        "alt": "👊",
        "title": "oncoming fist"
    },
    ":oncoming_fist_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44a-1f3fb.svg",
        "alt": "👊🏻",
        "title": "oncoming fist"
    },
    ":oncoming_fist_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44a-1f3fc.svg",
        "alt": "👊🏼",
        "title": "oncoming fist"
    },
    ":oncoming_fist_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44a-1f3fd.svg",
        "alt": "👊🏽",
        "title": "oncoming fist"
    },
    ":oncoming_fist_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44a-1f3fe.svg",
        "alt": "👊🏾",
        "title": "oncoming fist"
    },
    ":oncoming_fist_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44a-1f3ff.svg",
        "alt": "👊🏿",
        "title": "oncoming fist"
    },
    ":left_facing_fist:": {
        "src": "/images/emojis/unicode/svg/1f91b.svg",
        "alt": "🤛",
        "title": "left-facing fist"
    },
    ":left_facing_fist_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91b-1f3fb.svg",
        "alt": "🤛🏻",
        "title": "left-facing fist"
    },
    ":left_facing_fist_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91b-1f3fc.svg",
        "alt": "🤛🏼",
        "title": "left-facing fist"
    },
    ":left_facing_fist_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91b-1f3fd.svg",
        "alt": "🤛🏽",
        "title": "left-facing fist"
    },
    ":left_facing_fist_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91b-1f3fe.svg",
        "alt": "🤛🏾",
        "title": "left-facing fist"
    },
    ":left_facing_fist_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91b-1f3ff.svg",
        "alt": "🤛🏿",
        "title": "left-facing fist"
    },
    ":right_facing_fist:": {
        "src": "/images/emojis/unicode/svg/1f91c.svg",
        "alt": "🤜",
        "title": "right-facing fist"
    },
    ":right_facing_fist_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91c-1f3fb.svg",
        "alt": "🤜🏻",
        "title": "right-facing fist"
    },
    ":right_facing_fist_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91c-1f3fc.svg",
        "alt": "🤜🏼",
        "title": "right-facing fist"
    },
    ":right_facing_fist_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91c-1f3fd.svg",
        "alt": "🤜🏽",
        "title": "right-facing fist"
    },
    ":right_facing_fist_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91c-1f3fe.svg",
        "alt": "🤜🏾",
        "title": "right-facing fist"
    },
    ":right_facing_fist_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f91c-1f3ff.svg",
        "alt": "🤜🏿",
        "title": "right-facing fist"
    },
    ":clapping_hands:": {
        "src": "/images/emojis/unicode/svg/1f44f.svg",
        "alt": "👏",
        "title": "clapping hands"
    },
    ":clapping_hands_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44f-1f3fb.svg",
        "alt": "👏🏻",
        "title": "clapping hands"
    },
    ":clapping_hands_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44f-1f3fc.svg",
        "alt": "👏🏼",
        "title": "clapping hands"
    },
    ":clapping_hands_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44f-1f3fd.svg",
        "alt": "👏🏽",
        "title": "clapping hands"
    },
    ":clapping_hands_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44f-1f3fe.svg",
        "alt": "👏🏾",
        "title": "clapping hands"
    },
    ":clapping_hands_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f44f-1f3ff.svg",
        "alt": "👏🏿",
        "title": "clapping hands"
    },
    ":raising_hands:": {
        "src": "/images/emojis/unicode/svg/1f64c.svg",
        "alt": "🙌",
        "title": "raising hands"
    },
    ":raising_hands_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64c-1f3fb.svg",
        "alt": "🙌🏻",
        "title": "raising hands"
    },
    ":raising_hands_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64c-1f3fc.svg",
        "alt": "🙌🏼",
        "title": "raising hands"
    },
    ":raising_hands_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64c-1f3fd.svg",
        "alt": "🙌🏽",
        "title": "raising hands"
    },
    ":raising_hands_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64c-1f3fe.svg",
        "alt": "🙌🏾",
        "title": "raising hands"
    },
    ":raising_hands_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64c-1f3ff.svg",
        "alt": "🙌🏿",
        "title": "raising hands"
    },
    ":open_hands:": {
        "src": "/images/emojis/unicode/svg/1f450.svg",
        "alt": "👐",
        "title": "open hands"
    },
    ":open_hands_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f450-1f3fb.svg",
        "alt": "👐🏻",
        "title": "open hands"
    },
    ":open_hands_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f450-1f3fc.svg",
        "alt": "👐🏼",
        "title": "open hands"
    },
    ":open_hands_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f450-1f3fd.svg",
        "alt": "👐🏽",
        "title": "open hands"
    },
    ":open_hands_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f450-1f3fe.svg",
        "alt": "👐🏾",
        "title": "open hands"
    },
    ":open_hands_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f450-1f3ff.svg",
        "alt": "👐🏿",
        "title": "open hands"
    },
    ":palms_up_together:": {
        "src": "/images/emojis/unicode/svg/1f932.svg",
        "alt": "🤲",
        "title": "palms up together"
    },
    ":palms_up_together_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f932-1f3fb.svg",
        "alt": "🤲🏻",
        "title": "palms up together"
    },
    ":palms_up_together_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f932-1f3fc.svg",
        "alt": "🤲🏼",
        "title": "palms up together"
    },
    ":palms_up_together_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f932-1f3fd.svg",
        "alt": "🤲🏽",
        "title": "palms up together"
    },
    ":palms_up_together_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f932-1f3fe.svg",
        "alt": "🤲🏾",
        "title": "palms up together"
    },
    ":palms_up_together_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f932-1f3ff.svg",
        "alt": "🤲🏿",
        "title": "palms up together"
    },
    ":handshake:": {
        "src": "/images/emojis/unicode/svg/1f91d.svg",
        "alt": "🤝",
        "title": "handshake"
    },
    ":folded_hands:": {
        "src": "/images/emojis/unicode/svg/1f64f.svg",
        "alt": "🙏",
        "title": "folded hands"
    },
    ":folded_hands_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64f-1f3fb.svg",
        "alt": "🙏🏻",
        "title": "folded hands"
    },
    ":folded_hands_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64f-1f3fc.svg",
        "alt": "🙏🏼",
        "title": "folded hands"
    },
    ":folded_hands_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64f-1f3fd.svg",
        "alt": "🙏🏽",
        "title": "folded hands"
    },
    ":folded_hands_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64f-1f3fe.svg",
        "alt": "🙏🏾",
        "title": "folded hands"
    },
    ":folded_hands_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64f-1f3ff.svg",
        "alt": "🙏🏿",
        "title": "folded hands"
    },
    ":nail_polish:": {
        "src": "/images/emojis/unicode/svg/1f485.svg",
        "alt": "💅",
        "title": "nail polish"
    },
    ":nail_polish_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f485-1f3fb.svg",
        "alt": "💅🏻",
        "title": "nail polish"
    },
    ":nail_polish_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f485-1f3fc.svg",
        "alt": "💅🏼",
        "title": "nail polish"
    },
    ":nail_polish_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f485-1f3fd.svg",
        "alt": "💅🏽",
        "title": "nail polish"
    },
    ":nail_polish_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f485-1f3fe.svg",
        "alt": "💅🏾",
        "title": "nail polish"
    },
    ":nail_polish_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f485-1f3ff.svg",
        "alt": "💅🏿",
        "title": "nail polish"
    },
    ":selfie:": {
        "src": "/images/emojis/unicode/svg/1f933.svg",
        "alt": "🤳",
        "title": "selfie"
    },
    ":selfie_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f933-1f3fb.svg",
        "alt": "🤳🏻",
        "title": "selfie"
    },
    ":selfie_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f933-1f3fc.svg",
        "alt": "🤳🏼",
        "title": "selfie"
    },
    ":selfie_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f933-1f3fd.svg",
        "alt": "🤳🏽",
        "title": "selfie"
    },
    ":selfie_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f933-1f3fe.svg",
        "alt": "🤳🏾",
        "title": "selfie"
    },
    ":selfie_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f933-1f3ff.svg",
        "alt": "🤳🏿",
        "title": "selfie"
    },
    ":flexed_biceps:": {
        "src": "/images/emojis/unicode/svg/1f4aa.svg",
        "alt": "💪",
        "title": "flexed biceps"
    },
    ":flexed_biceps_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f4aa-1f3fb.svg",
        "alt": "💪🏻",
        "title": "flexed biceps"
    },
    ":flexed_biceps_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f4aa-1f3fc.svg",
        "alt": "💪🏼",
        "title": "flexed biceps"
    },
    ":flexed_biceps_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f4aa-1f3fd.svg",
        "alt": "💪🏽",
        "title": "flexed biceps"
    },
    ":flexed_biceps_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f4aa-1f3fe.svg",
        "alt": "💪🏾",
        "title": "flexed biceps"
    },
    ":flexed_biceps_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f4aa-1f3ff.svg",
        "alt": "💪🏿",
        "title": "flexed biceps"
    },
    ":mechanical_arm:": {
        "src": "/images/emojis/unicode/svg/1f9be.svg",
        "alt": "🦾",
        "title": "mechanical arm"
    },
    ":mechanical_leg:": {
        "src": "/images/emojis/unicode/svg/1f9bf.svg",
        "alt": "🦿",
        "title": "mechanical leg"
    },
    ":leg:": {
        "src": "/images/emojis/unicode/svg/1f9b5.svg",
        "alt": "🦵",
        "title": "leg"
    },
    ":leg_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b5-1f3fb.svg",
        "alt": "🦵🏻",
        "title": "leg"
    },
    ":leg_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b5-1f3fc.svg",
        "alt": "🦵🏼",
        "title": "leg"
    },
    ":leg_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b5-1f3fd.svg",
        "alt": "🦵🏽",
        "title": "leg"
    },
    ":leg_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b5-1f3fe.svg",
        "alt": "🦵🏾",
        "title": "leg"
    },
    ":leg_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b5-1f3ff.svg",
        "alt": "🦵🏿",
        "title": "leg"
    },
    ":foot:": {
        "src": "/images/emojis/unicode/svg/1f9b6.svg",
        "alt": "🦶",
        "title": "foot"
    },
    ":foot_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b6-1f3fb.svg",
        "alt": "🦶🏻",
        "title": "foot"
    },
    ":foot_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b6-1f3fc.svg",
        "alt": "🦶🏼",
        "title": "foot"
    },
    ":foot_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b6-1f3fd.svg",
        "alt": "🦶🏽",
        "title": "foot"
    },
    ":foot_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b6-1f3fe.svg",
        "alt": "🦶🏾",
        "title": "foot"
    },
    ":foot_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b6-1f3ff.svg",
        "alt": "🦶🏿",
        "title": "foot"
    },
    ":ear:": {
        "src": "/images/emojis/unicode/svg/1f442.svg",
        "alt": "👂",
        "title": "ear"
    },
    ":ear_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f442-1f3fb.svg",
        "alt": "👂🏻",
        "title": "ear"
    },
    ":ear_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f442-1f3fc.svg",
        "alt": "👂🏼",
        "title": "ear"
    },
    ":ear_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f442-1f3fd.svg",
        "alt": "👂🏽",
        "title": "ear"
    },
    ":ear_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f442-1f3fe.svg",
        "alt": "👂🏾",
        "title": "ear"
    },
    ":ear_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f442-1f3ff.svg",
        "alt": "👂🏿",
        "title": "ear"
    },
    ":ear_with_hearing_aid:": {
        "src": "/images/emojis/unicode/svg/1f9bb.svg",
        "alt": "🦻",
        "title": "ear with hearing aid"
    },
    ":ear_with_hearing_aid_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9bb-1f3fb.svg",
        "alt": "🦻🏻",
        "title": "ear with hearing aid"
    },
    ":ear_with_hearing_aid_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9bb-1f3fc.svg",
        "alt": "🦻🏼",
        "title": "ear with hearing aid"
    },
    ":ear_with_hearing_aid_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9bb-1f3fd.svg",
        "alt": "🦻🏽",
        "title": "ear with hearing aid"
    },
    ":ear_with_hearing_aid_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9bb-1f3fe.svg",
        "alt": "🦻🏾",
        "title": "ear with hearing aid"
    },
    ":ear_with_hearing_aid_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9bb-1f3ff.svg",
        "alt": "🦻🏿",
        "title": "ear with hearing aid"
    },
    ":nose:": {
        "src": "/images/emojis/unicode/svg/1f443.svg",
        "alt": "👃",
        "title": "nose"
    },
    ":nose_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f443-1f3fb.svg",
        "alt": "👃🏻",
        "title": "nose"
    },
    ":nose_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f443-1f3fc.svg",
        "alt": "👃🏼",
        "title": "nose"
    },
    ":nose_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f443-1f3fd.svg",
        "alt": "👃🏽",
        "title": "nose"
    },
    ":nose_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f443-1f3fe.svg",
        "alt": "👃🏾",
        "title": "nose"
    },
    ":nose_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f443-1f3ff.svg",
        "alt": "👃🏿",
        "title": "nose"
    },
    ":brain:": {
        "src": "/images/emojis/unicode/svg/1f9e0.svg",
        "alt": "🧠",
        "title": "brain"
    },
    ":anatomical_heart:": {
        "src": "/images/emojis/unicode/svg/1fac0.svg",
        "alt": "🫀",
        "title": "anatomical heart"
    },
    ":lungs:": {
        "src": "/images/emojis/unicode/svg/1fac1.svg",
        "alt": "🫁",
        "title": "lungs"
    },
    ":tooth:": {
        "src": "/images/emojis/unicode/svg/1f9b7.svg",
        "alt": "🦷",
        "title": "tooth"
    },
    ":bone:": {
        "src": "/images/emojis/unicode/svg/1f9b4.svg",
        "alt": "🦴",
        "title": "bone"
    },
    ":eyes:": {
        "src": "/images/emojis/unicode/svg/1f440.svg",
        "alt": "👀",
        "title": "eyes"
    },
    ":eye:": {
        "src": "/images/emojis/unicode/svg/1f441.svg",
        "alt": "👁",
        "title": "eye"
    },
    ":tongue:": {
        "src": "/images/emojis/unicode/svg/1f445.svg",
        "alt": "👅",
        "title": "tongue"
    },
    ":mouth:": {
        "src": "/images/emojis/unicode/svg/1f444.svg",
        "alt": "👄",
        "title": "mouth"
    },
    ":baby:": {
        "src": "/images/emojis/unicode/svg/1f476.svg",
        "alt": "👶",
        "title": "baby"
    },
    ":baby_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f476-1f3fb.svg",
        "alt": "👶🏻",
        "title": "baby"
    },
    ":baby_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f476-1f3fc.svg",
        "alt": "👶🏼",
        "title": "baby"
    },
    ":baby_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f476-1f3fd.svg",
        "alt": "👶🏽",
        "title": "baby"
    },
    ":baby_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f476-1f3fe.svg",
        "alt": "👶🏾",
        "title": "baby"
    },
    ":baby_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f476-1f3ff.svg",
        "alt": "👶🏿",
        "title": "baby"
    },
    ":child:": {
        "src": "/images/emojis/unicode/svg/1f9d2.svg",
        "alt": "🧒",
        "title": "child"
    },
    ":child_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d2-1f3fb.svg",
        "alt": "🧒🏻",
        "title": "child"
    },
    ":child_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d2-1f3fc.svg",
        "alt": "🧒🏼",
        "title": "child"
    },
    ":child_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d2-1f3fd.svg",
        "alt": "🧒🏽",
        "title": "child"
    },
    ":child_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d2-1f3fe.svg",
        "alt": "🧒🏾",
        "title": "child"
    },
    ":child_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d2-1f3ff.svg",
        "alt": "🧒🏿",
        "title": "child"
    },
    ":boy:": {
        "src": "/images/emojis/unicode/svg/1f466.svg",
        "alt": "👦",
        "title": "boy"
    },
    ":boy_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f466-1f3fb.svg",
        "alt": "👦🏻",
        "title": "boy"
    },
    ":boy_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f466-1f3fc.svg",
        "alt": "👦🏼",
        "title": "boy"
    },
    ":boy_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f466-1f3fd.svg",
        "alt": "👦🏽",
        "title": "boy"
    },
    ":boy_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f466-1f3fe.svg",
        "alt": "👦🏾",
        "title": "boy"
    },
    ":boy_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f466-1f3ff.svg",
        "alt": "👦🏿",
        "title": "boy"
    },
    ":girl:": {
        "src": "/images/emojis/unicode/svg/1f467.svg",
        "alt": "👧",
        "title": "girl"
    },
    ":girl_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f467-1f3fb.svg",
        "alt": "👧🏻",
        "title": "girl"
    },
    ":girl_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f467-1f3fc.svg",
        "alt": "👧🏼",
        "title": "girl"
    },
    ":girl_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f467-1f3fd.svg",
        "alt": "👧🏽",
        "title": "girl"
    },
    ":girl_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f467-1f3fe.svg",
        "alt": "👧🏾",
        "title": "girl"
    },
    ":girl_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f467-1f3ff.svg",
        "alt": "👧🏿",
        "title": "girl"
    },
    ":person:": {
        "src": "/images/emojis/unicode/svg/1f9d1.svg",
        "alt": "🧑",
        "title": "person"
    },
    ":person_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb.svg",
        "alt": "🧑🏻",
        "title": "person"
    },
    ":person_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc.svg",
        "alt": "🧑🏼",
        "title": "person"
    },
    ":person_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd.svg",
        "alt": "🧑🏽",
        "title": "person"
    },
    ":person_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe.svg",
        "alt": "🧑🏾",
        "title": "person"
    },
    ":person_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff.svg",
        "alt": "🧑🏿",
        "title": "person"
    },
    ":person_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471.svg",
        "alt": "👱",
        "title": "person"
    },
    ":person_light_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3fb.svg",
        "alt": "👱🏻",
        "title": "person"
    },
    ":person_medium_light_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3fc.svg",
        "alt": "👱🏼",
        "title": "person"
    },
    ":person_medium_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3fd.svg",
        "alt": "👱🏽",
        "title": "person"
    },
    ":person_medium_dark_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3fe.svg",
        "alt": "👱🏾",
        "title": "person"
    },
    ":person_dark_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3ff.svg",
        "alt": "👱🏿",
        "title": "person"
    },
    ":person_beard:": {
        "src": "/images/emojis/unicode/svg/1f9d4.svg",
        "alt": "🧔",
        "title": "person"
    },
    ":person_light_skin_tone_beard:": {
        "src": "/images/emojis/unicode/svg/1f9d4-1f3fb.svg",
        "alt": "🧔🏻",
        "title": "person"
    },
    ":person_medium_light_skin_tone_beard:": {
        "src": "/images/emojis/unicode/svg/1f9d4-1f3fc.svg",
        "alt": "🧔🏼",
        "title": "person"
    },
    ":person_medium_skin_tone_beard:": {
        "src": "/images/emojis/unicode/svg/1f9d4-1f3fd.svg",
        "alt": "🧔🏽",
        "title": "person"
    },
    ":person_medium_dark_skin_tone_beard:": {
        "src": "/images/emojis/unicode/svg/1f9d4-1f3fe.svg",
        "alt": "🧔🏾",
        "title": "person"
    },
    ":person_dark_skin_tone_beard:": {
        "src": "/images/emojis/unicode/svg/1f9d4-1f3ff.svg",
        "alt": "🧔🏿",
        "title": "person"
    },
    ":person_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f9b0.svg",
        "alt": "🧑‍🦰",
        "title": "person"
    },
    ":person_light_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f9b0.svg",
        "alt": "🧑🏻‍🦰",
        "title": "person"
    },
    ":person_medium_light_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f9b0.svg",
        "alt": "🧑🏼‍🦰",
        "title": "person"
    },
    ":person_medium_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f9b0.svg",
        "alt": "🧑🏽‍🦰",
        "title": "person"
    },
    ":person_medium_dark_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f9b0.svg",
        "alt": "🧑🏾‍🦰",
        "title": "person"
    },
    ":person_dark_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f9b0.svg",
        "alt": "🧑🏿‍🦰",
        "title": "person"
    },
    ":person_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f9b1.svg",
        "alt": "🧑‍🦱",
        "title": "person"
    },
    ":person_light_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f9b1.svg",
        "alt": "🧑🏻‍🦱",
        "title": "person"
    },
    ":person_medium_light_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f9b1.svg",
        "alt": "🧑🏼‍🦱",
        "title": "person"
    },
    ":person_medium_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f9b1.svg",
        "alt": "🧑🏽‍🦱",
        "title": "person"
    },
    ":person_medium_dark_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f9b1.svg",
        "alt": "🧑🏾‍🦱",
        "title": "person"
    },
    ":person_dark_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f9b1.svg",
        "alt": "🧑🏿‍🦱",
        "title": "person"
    },
    ":person_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f9b3.svg",
        "alt": "🧑‍🦳",
        "title": "person"
    },
    ":person_light_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f9b3.svg",
        "alt": "🧑🏻‍🦳",
        "title": "person"
    },
    ":person_medium_light_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f9b3.svg",
        "alt": "🧑🏼‍🦳",
        "title": "person"
    },
    ":person_medium_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f9b3.svg",
        "alt": "🧑🏽‍🦳",
        "title": "person"
    },
    ":person_medium_dark_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f9b3.svg",
        "alt": "🧑🏾‍🦳",
        "title": "person"
    },
    ":person_dark_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f9b3.svg",
        "alt": "🧑🏿‍🦳",
        "title": "person"
    },
    ":person_bald:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f9b2.svg",
        "alt": "🧑‍🦲",
        "title": "person"
    },
    ":person_light_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f9b2.svg",
        "alt": "🧑🏻‍🦲",
        "title": "person"
    },
    ":person_medium_light_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f9b2.svg",
        "alt": "🧑🏼‍🦲",
        "title": "person"
    },
    ":person_medium_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f9b2.svg",
        "alt": "🧑🏽‍🦲",
        "title": "person"
    },
    ":person_medium_dark_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f9b2.svg",
        "alt": "🧑🏾‍🦲",
        "title": "person"
    },
    ":person_dark_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f9b2.svg",
        "alt": "🧑🏿‍🦲",
        "title": "person"
    },
    ":man:": {
        "src": "/images/emojis/unicode/svg/1f468.svg",
        "alt": "👨",
        "title": "man"
    },
    ":man_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb.svg",
        "alt": "👨🏻",
        "title": "man"
    },
    ":man_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc.svg",
        "alt": "👨🏼",
        "title": "man"
    },
    ":man_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd.svg",
        "alt": "👨🏽",
        "title": "man"
    },
    ":man_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe.svg",
        "alt": "👨🏾",
        "title": "man"
    },
    ":man_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff.svg",
        "alt": "👨🏿",
        "title": "man"
    },
    ":man_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f9b0.svg",
        "alt": "👨‍🦰",
        "title": "man"
    },
    ":man_light_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f9b0.svg",
        "alt": "👨🏻‍🦰",
        "title": "man"
    },
    ":man_medium_light_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f9b0.svg",
        "alt": "👨🏼‍🦰",
        "title": "man"
    },
    ":man_medium_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f9b0.svg",
        "alt": "👨🏽‍🦰",
        "title": "man"
    },
    ":man_medium_dark_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f9b0.svg",
        "alt": "👨🏾‍🦰",
        "title": "man"
    },
    ":man_dark_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f9b0.svg",
        "alt": "👨🏿‍🦰",
        "title": "man"
    },
    ":man_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f9b1.svg",
        "alt": "👨‍🦱",
        "title": "man"
    },
    ":man_light_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f9b1.svg",
        "alt": "👨🏻‍🦱",
        "title": "man"
    },
    ":man_medium_light_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f9b1.svg",
        "alt": "👨🏼‍🦱",
        "title": "man"
    },
    ":man_medium_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f9b1.svg",
        "alt": "👨🏽‍🦱",
        "title": "man"
    },
    ":man_medium_dark_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f9b1.svg",
        "alt": "👨🏾‍🦱",
        "title": "man"
    },
    ":man_dark_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f9b1.svg",
        "alt": "👨🏿‍🦱",
        "title": "man"
    },
    ":man_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f9b3.svg",
        "alt": "👨‍🦳",
        "title": "man"
    },
    ":man_light_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f9b3.svg",
        "alt": "👨🏻‍🦳",
        "title": "man"
    },
    ":man_medium_light_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f9b3.svg",
        "alt": "👨🏼‍🦳",
        "title": "man"
    },
    ":man_medium_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f9b3.svg",
        "alt": "👨🏽‍🦳",
        "title": "man"
    },
    ":man_medium_dark_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f9b3.svg",
        "alt": "👨🏾‍🦳",
        "title": "man"
    },
    ":man_dark_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f9b3.svg",
        "alt": "👨🏿‍🦳",
        "title": "man"
    },
    ":man_bald:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f9b2.svg",
        "alt": "👨‍🦲",
        "title": "man"
    },
    ":man_light_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f9b2.svg",
        "alt": "👨🏻‍🦲",
        "title": "man"
    },
    ":man_medium_light_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f9b2.svg",
        "alt": "👨🏼‍🦲",
        "title": "man"
    },
    ":man_medium_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f9b2.svg",
        "alt": "👨🏽‍🦲",
        "title": "man"
    },
    ":man_medium_dark_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f9b2.svg",
        "alt": "👨🏾‍🦲",
        "title": "man"
    },
    ":man_dark_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f9b2.svg",
        "alt": "👨🏿‍🦲",
        "title": "man"
    },
    ":man_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-200d-2642-fe0f.svg",
        "alt": "👱‍♂️",
        "title": "man"
    },
    ":man_light_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3fb-200d-2642-fe0f.svg",
        "alt": "👱🏻‍♂️",
        "title": "man"
    },
    ":man_medium_light_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3fc-200d-2642-fe0f.svg",
        "alt": "👱🏼‍♂️",
        "title": "man"
    },
    ":man_medium_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3fd-200d-2642-fe0f.svg",
        "alt": "👱🏽‍♂️",
        "title": "man"
    },
    ":man_medium_dark_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3fe-200d-2642-fe0f.svg",
        "alt": "👱🏾‍♂️",
        "title": "man"
    },
    ":man_dark_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3ff-200d-2642-fe0f.svg",
        "alt": "👱🏿‍♂️",
        "title": "man"
    },
    ":woman:": {
        "src": "/images/emojis/unicode/svg/1f469.svg",
        "alt": "👩",
        "title": "woman"
    },
    ":woman_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb.svg",
        "alt": "👩🏻",
        "title": "woman"
    },
    ":woman_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc.svg",
        "alt": "👩🏼",
        "title": "woman"
    },
    ":woman_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd.svg",
        "alt": "👩🏽",
        "title": "woman"
    },
    ":woman_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe.svg",
        "alt": "👩🏾",
        "title": "woman"
    },
    ":woman_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff.svg",
        "alt": "👩🏿",
        "title": "woman"
    },
    ":woman_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f9b0.svg",
        "alt": "👩‍🦰",
        "title": "woman"
    },
    ":woman_light_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f9b0.svg",
        "alt": "👩🏻‍🦰",
        "title": "woman"
    },
    ":woman_medium_light_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f9b0.svg",
        "alt": "👩🏼‍🦰",
        "title": "woman"
    },
    ":woman_medium_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f9b0.svg",
        "alt": "👩🏽‍🦰",
        "title": "woman"
    },
    ":woman_medium_dark_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f9b0.svg",
        "alt": "👩🏾‍🦰",
        "title": "woman"
    },
    ":woman_dark_skin_tone_red_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f9b0.svg",
        "alt": "👩🏿‍🦰",
        "title": "woman"
    },
    ":woman_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f9b1.svg",
        "alt": "👩‍🦱",
        "title": "woman"
    },
    ":woman_light_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f9b1.svg",
        "alt": "👩🏻‍🦱",
        "title": "woman"
    },
    ":woman_medium_light_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f9b1.svg",
        "alt": "👩🏼‍🦱",
        "title": "woman"
    },
    ":woman_medium_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f9b1.svg",
        "alt": "👩🏽‍🦱",
        "title": "woman"
    },
    ":woman_medium_dark_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f9b1.svg",
        "alt": "👩🏾‍🦱",
        "title": "woman"
    },
    ":woman_dark_skin_tone_curly_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f9b1.svg",
        "alt": "👩🏿‍🦱",
        "title": "woman"
    },
    ":woman_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f9b3.svg",
        "alt": "👩‍🦳",
        "title": "woman"
    },
    ":woman_light_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f9b3.svg",
        "alt": "👩🏻‍🦳",
        "title": "woman"
    },
    ":woman_medium_light_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f9b3.svg",
        "alt": "👩🏼‍🦳",
        "title": "woman"
    },
    ":woman_medium_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f9b3.svg",
        "alt": "👩🏽‍🦳",
        "title": "woman"
    },
    ":woman_medium_dark_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f9b3.svg",
        "alt": "👩🏾‍🦳",
        "title": "woman"
    },
    ":woman_dark_skin_tone_white_hair:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f9b3.svg",
        "alt": "👩🏿‍🦳",
        "title": "woman"
    },
    ":woman_bald:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f9b2.svg",
        "alt": "👩‍🦲",
        "title": "woman"
    },
    ":woman_light_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f9b2.svg",
        "alt": "👩🏻‍🦲",
        "title": "woman"
    },
    ":woman_medium_light_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f9b2.svg",
        "alt": "👩🏼‍🦲",
        "title": "woman"
    },
    ":woman_medium_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f9b2.svg",
        "alt": "👩🏽‍🦲",
        "title": "woman"
    },
    ":woman_medium_dark_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f9b2.svg",
        "alt": "👩🏾‍🦲",
        "title": "woman"
    },
    ":woman_dark_skin_tone_bald:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f9b2.svg",
        "alt": "👩🏿‍🦲",
        "title": "woman"
    },
    ":woman_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-200d-2640-fe0f.svg",
        "alt": "👱‍♀️",
        "title": "woman"
    },
    ":woman_light_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3fb-200d-2640-fe0f.svg",
        "alt": "👱🏻‍♀️",
        "title": "woman"
    },
    ":woman_medium_light_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3fc-200d-2640-fe0f.svg",
        "alt": "👱🏼‍♀️",
        "title": "woman"
    },
    ":woman_medium_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3fd-200d-2640-fe0f.svg",
        "alt": "👱🏽‍♀️",
        "title": "woman"
    },
    ":woman_medium_dark_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3fe-200d-2640-fe0f.svg",
        "alt": "👱🏾‍♀️",
        "title": "woman"
    },
    ":woman_dark_skin_tone_blond_hair:": {
        "src": "/images/emojis/unicode/svg/1f471-1f3ff-200d-2640-fe0f.svg",
        "alt": "👱🏿‍♀️",
        "title": "woman"
    },
    ":older_person:": {
        "src": "/images/emojis/unicode/svg/1f9d3.svg",
        "alt": "🧓",
        "title": "older person"
    },
    ":older_person_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d3-1f3fb.svg",
        "alt": "🧓🏻",
        "title": "older person"
    },
    ":older_person_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d3-1f3fc.svg",
        "alt": "🧓🏼",
        "title": "older person"
    },
    ":older_person_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d3-1f3fd.svg",
        "alt": "🧓🏽",
        "title": "older person"
    },
    ":older_person_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d3-1f3fe.svg",
        "alt": "🧓🏾",
        "title": "older person"
    },
    ":older_person_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d3-1f3ff.svg",
        "alt": "🧓🏿",
        "title": "older person"
    },
    ":old_man:": {
        "src": "/images/emojis/unicode/svg/1f474.svg",
        "alt": "👴",
        "title": "old man"
    },
    ":old_man_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f474-1f3fb.svg",
        "alt": "👴🏻",
        "title": "old man"
    },
    ":old_man_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f474-1f3fc.svg",
        "alt": "👴🏼",
        "title": "old man"
    },
    ":old_man_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f474-1f3fd.svg",
        "alt": "👴🏽",
        "title": "old man"
    },
    ":old_man_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f474-1f3fe.svg",
        "alt": "👴🏾",
        "title": "old man"
    },
    ":old_man_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f474-1f3ff.svg",
        "alt": "👴🏿",
        "title": "old man"
    },
    ":old_woman:": {
        "src": "/images/emojis/unicode/svg/1f475.svg",
        "alt": "👵",
        "title": "old woman"
    },
    ":old_woman_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f475-1f3fb.svg",
        "alt": "👵🏻",
        "title": "old woman"
    },
    ":old_woman_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f475-1f3fc.svg",
        "alt": "👵🏼",
        "title": "old woman"
    },
    ":old_woman_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f475-1f3fd.svg",
        "alt": "👵🏽",
        "title": "old woman"
    },
    ":old_woman_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f475-1f3fe.svg",
        "alt": "👵🏾",
        "title": "old woman"
    },
    ":old_woman_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f475-1f3ff.svg",
        "alt": "👵🏿",
        "title": "old woman"
    },
    ":person_frowning:": {
        "src": "/images/emojis/unicode/svg/1f64d.svg",
        "alt": "🙍",
        "title": "person frowning"
    },
    ":person_frowning_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3fb.svg",
        "alt": "🙍🏻",
        "title": "person frowning"
    },
    ":person_frowning_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3fc.svg",
        "alt": "🙍🏼",
        "title": "person frowning"
    },
    ":person_frowning_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3fd.svg",
        "alt": "🙍🏽",
        "title": "person frowning"
    },
    ":person_frowning_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3fe.svg",
        "alt": "🙍🏾",
        "title": "person frowning"
    },
    ":person_frowning_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3ff.svg",
        "alt": "🙍🏿",
        "title": "person frowning"
    },
    ":man_frowning:": {
        "src": "/images/emojis/unicode/svg/1f64d-200d-2642-fe0f.svg",
        "alt": "🙍‍♂️",
        "title": "man frowning"
    },
    ":man_frowning_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3fb-200d-2642-fe0f.svg",
        "alt": "🙍🏻‍♂️",
        "title": "man frowning"
    },
    ":man_frowning_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3fc-200d-2642-fe0f.svg",
        "alt": "🙍🏼‍♂️",
        "title": "man frowning"
    },
    ":man_frowning_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3fd-200d-2642-fe0f.svg",
        "alt": "🙍🏽‍♂️",
        "title": "man frowning"
    },
    ":man_frowning_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3fe-200d-2642-fe0f.svg",
        "alt": "🙍🏾‍♂️",
        "title": "man frowning"
    },
    ":man_frowning_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3ff-200d-2642-fe0f.svg",
        "alt": "🙍🏿‍♂️",
        "title": "man frowning"
    },
    ":woman_frowning:": {
        "src": "/images/emojis/unicode/svg/1f64d-200d-2640-fe0f.svg",
        "alt": "🙍‍♀️",
        "title": "woman frowning"
    },
    ":woman_frowning_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3fb-200d-2640-fe0f.svg",
        "alt": "🙍🏻‍♀️",
        "title": "woman frowning"
    },
    ":woman_frowning_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3fc-200d-2640-fe0f.svg",
        "alt": "🙍🏼‍♀️",
        "title": "woman frowning"
    },
    ":woman_frowning_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3fd-200d-2640-fe0f.svg",
        "alt": "🙍🏽‍♀️",
        "title": "woman frowning"
    },
    ":woman_frowning_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3fe-200d-2640-fe0f.svg",
        "alt": "🙍🏾‍♀️",
        "title": "woman frowning"
    },
    ":woman_frowning_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64d-1f3ff-200d-2640-fe0f.svg",
        "alt": "🙍🏿‍♀️",
        "title": "woman frowning"
    },
    ":person_pouting:": {
        "src": "/images/emojis/unicode/svg/1f64e.svg",
        "alt": "🙎",
        "title": "person pouting"
    },
    ":person_pouting_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3fb.svg",
        "alt": "🙎🏻",
        "title": "person pouting"
    },
    ":person_pouting_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3fc.svg",
        "alt": "🙎🏼",
        "title": "person pouting"
    },
    ":person_pouting_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3fd.svg",
        "alt": "🙎🏽",
        "title": "person pouting"
    },
    ":person_pouting_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3fe.svg",
        "alt": "🙎🏾",
        "title": "person pouting"
    },
    ":person_pouting_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3ff.svg",
        "alt": "🙎🏿",
        "title": "person pouting"
    },
    ":man_pouting:": {
        "src": "/images/emojis/unicode/svg/1f64e-200d-2642-fe0f.svg",
        "alt": "🙎‍♂️",
        "title": "man pouting"
    },
    ":man_pouting_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3fb-200d-2642-fe0f.svg",
        "alt": "🙎🏻‍♂️",
        "title": "man pouting"
    },
    ":man_pouting_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3fc-200d-2642-fe0f.svg",
        "alt": "🙎🏼‍♂️",
        "title": "man pouting"
    },
    ":man_pouting_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3fd-200d-2642-fe0f.svg",
        "alt": "🙎🏽‍♂️",
        "title": "man pouting"
    },
    ":man_pouting_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3fe-200d-2642-fe0f.svg",
        "alt": "🙎🏾‍♂️",
        "title": "man pouting"
    },
    ":man_pouting_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3ff-200d-2642-fe0f.svg",
        "alt": "🙎🏿‍♂️",
        "title": "man pouting"
    },
    ":woman_pouting:": {
        "src": "/images/emojis/unicode/svg/1f64e-200d-2640-fe0f.svg",
        "alt": "🙎‍♀️",
        "title": "woman pouting"
    },
    ":woman_pouting_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3fb-200d-2640-fe0f.svg",
        "alt": "🙎🏻‍♀️",
        "title": "woman pouting"
    },
    ":woman_pouting_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3fc-200d-2640-fe0f.svg",
        "alt": "🙎🏼‍♀️",
        "title": "woman pouting"
    },
    ":woman_pouting_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3fd-200d-2640-fe0f.svg",
        "alt": "🙎🏽‍♀️",
        "title": "woman pouting"
    },
    ":woman_pouting_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3fe-200d-2640-fe0f.svg",
        "alt": "🙎🏾‍♀️",
        "title": "woman pouting"
    },
    ":woman_pouting_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64e-1f3ff-200d-2640-fe0f.svg",
        "alt": "🙎🏿‍♀️",
        "title": "woman pouting"
    },
    ":person_gesturing_no:": {
        "src": "/images/emojis/unicode/svg/1f645.svg",
        "alt": "🙅",
        "title": "person gesturing NO"
    },
    ":person_gesturing_no_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3fb.svg",
        "alt": "🙅🏻",
        "title": "person gesturing NO"
    },
    ":person_gesturing_no_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3fc.svg",
        "alt": "🙅🏼",
        "title": "person gesturing NO"
    },
    ":person_gesturing_no_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3fd.svg",
        "alt": "🙅🏽",
        "title": "person gesturing NO"
    },
    ":person_gesturing_no_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3fe.svg",
        "alt": "🙅🏾",
        "title": "person gesturing NO"
    },
    ":person_gesturing_no_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3ff.svg",
        "alt": "🙅🏿",
        "title": "person gesturing NO"
    },
    ":man_gesturing_no:": {
        "src": "/images/emojis/unicode/svg/1f645-200d-2642-fe0f.svg",
        "alt": "🙅‍♂️",
        "title": "man gesturing NO"
    },
    ":man_gesturing_no_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3fb-200d-2642-fe0f.svg",
        "alt": "🙅🏻‍♂️",
        "title": "man gesturing NO"
    },
    ":man_gesturing_no_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3fc-200d-2642-fe0f.svg",
        "alt": "🙅🏼‍♂️",
        "title": "man gesturing NO"
    },
    ":man_gesturing_no_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3fd-200d-2642-fe0f.svg",
        "alt": "🙅🏽‍♂️",
        "title": "man gesturing NO"
    },
    ":man_gesturing_no_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3fe-200d-2642-fe0f.svg",
        "alt": "🙅🏾‍♂️",
        "title": "man gesturing NO"
    },
    ":man_gesturing_no_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3ff-200d-2642-fe0f.svg",
        "alt": "🙅🏿‍♂️",
        "title": "man gesturing NO"
    },
    ":woman_gesturing_no:": {
        "src": "/images/emojis/unicode/svg/1f645-200d-2640-fe0f.svg",
        "alt": "🙅‍♀️",
        "title": "woman gesturing NO"
    },
    ":woman_gesturing_no_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3fb-200d-2640-fe0f.svg",
        "alt": "🙅🏻‍♀️",
        "title": "woman gesturing NO"
    },
    ":woman_gesturing_no_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3fc-200d-2640-fe0f.svg",
        "alt": "🙅🏼‍♀️",
        "title": "woman gesturing NO"
    },
    ":woman_gesturing_no_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3fd-200d-2640-fe0f.svg",
        "alt": "🙅🏽‍♀️",
        "title": "woman gesturing NO"
    },
    ":woman_gesturing_no_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3fe-200d-2640-fe0f.svg",
        "alt": "🙅🏾‍♀️",
        "title": "woman gesturing NO"
    },
    ":woman_gesturing_no_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f645-1f3ff-200d-2640-fe0f.svg",
        "alt": "🙅🏿‍♀️",
        "title": "woman gesturing NO"
    },
    ":person_gesturing_ok:": {
        "src": "/images/emojis/unicode/svg/1f646.svg",
        "alt": "🙆",
        "title": "person gesturing OK"
    },
    ":person_gesturing_ok_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3fb.svg",
        "alt": "🙆🏻",
        "title": "person gesturing OK"
    },
    ":person_gesturing_ok_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3fc.svg",
        "alt": "🙆🏼",
        "title": "person gesturing OK"
    },
    ":person_gesturing_ok_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3fd.svg",
        "alt": "🙆🏽",
        "title": "person gesturing OK"
    },
    ":person_gesturing_ok_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3fe.svg",
        "alt": "🙆🏾",
        "title": "person gesturing OK"
    },
    ":person_gesturing_ok_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3ff.svg",
        "alt": "🙆🏿",
        "title": "person gesturing OK"
    },
    ":man_gesturing_ok:": {
        "src": "/images/emojis/unicode/svg/1f646-200d-2642-fe0f.svg",
        "alt": "🙆‍♂️",
        "title": "man gesturing OK"
    },
    ":man_gesturing_ok_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3fb-200d-2642-fe0f.svg",
        "alt": "🙆🏻‍♂️",
        "title": "man gesturing OK"
    },
    ":man_gesturing_ok_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3fc-200d-2642-fe0f.svg",
        "alt": "🙆🏼‍♂️",
        "title": "man gesturing OK"
    },
    ":man_gesturing_ok_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3fd-200d-2642-fe0f.svg",
        "alt": "🙆🏽‍♂️",
        "title": "man gesturing OK"
    },
    ":man_gesturing_ok_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3fe-200d-2642-fe0f.svg",
        "alt": "🙆🏾‍♂️",
        "title": "man gesturing OK"
    },
    ":man_gesturing_ok_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3ff-200d-2642-fe0f.svg",
        "alt": "🙆🏿‍♂️",
        "title": "man gesturing OK"
    },
    ":woman_gesturing_ok:": {
        "src": "/images/emojis/unicode/svg/1f646-200d-2640-fe0f.svg",
        "alt": "🙆‍♀️",
        "title": "woman gesturing OK"
    },
    ":woman_gesturing_ok_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3fb-200d-2640-fe0f.svg",
        "alt": "🙆🏻‍♀️",
        "title": "woman gesturing OK"
    },
    ":woman_gesturing_ok_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3fc-200d-2640-fe0f.svg",
        "alt": "🙆🏼‍♀️",
        "title": "woman gesturing OK"
    },
    ":woman_gesturing_ok_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3fd-200d-2640-fe0f.svg",
        "alt": "🙆🏽‍♀️",
        "title": "woman gesturing OK"
    },
    ":woman_gesturing_ok_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3fe-200d-2640-fe0f.svg",
        "alt": "🙆🏾‍♀️",
        "title": "woman gesturing OK"
    },
    ":woman_gesturing_ok_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f646-1f3ff-200d-2640-fe0f.svg",
        "alt": "🙆🏿‍♀️",
        "title": "woman gesturing OK"
    },
    ":person_tipping_hand:": {
        "src": "/images/emojis/unicode/svg/1f481.svg",
        "alt": "💁",
        "title": "person tipping hand"
    },
    ":person_tipping_hand_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3fb.svg",
        "alt": "💁🏻",
        "title": "person tipping hand"
    },
    ":person_tipping_hand_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3fc.svg",
        "alt": "💁🏼",
        "title": "person tipping hand"
    },
    ":person_tipping_hand_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3fd.svg",
        "alt": "💁🏽",
        "title": "person tipping hand"
    },
    ":person_tipping_hand_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3fe.svg",
        "alt": "💁🏾",
        "title": "person tipping hand"
    },
    ":person_tipping_hand_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3ff.svg",
        "alt": "💁🏿",
        "title": "person tipping hand"
    },
    ":man_tipping_hand:": {
        "src": "/images/emojis/unicode/svg/1f481-200d-2642-fe0f.svg",
        "alt": "💁‍♂️",
        "title": "man tipping hand"
    },
    ":man_tipping_hand_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3fb-200d-2642-fe0f.svg",
        "alt": "💁🏻‍♂️",
        "title": "man tipping hand"
    },
    ":man_tipping_hand_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3fc-200d-2642-fe0f.svg",
        "alt": "💁🏼‍♂️",
        "title": "man tipping hand"
    },
    ":man_tipping_hand_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3fd-200d-2642-fe0f.svg",
        "alt": "💁🏽‍♂️",
        "title": "man tipping hand"
    },
    ":man_tipping_hand_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3fe-200d-2642-fe0f.svg",
        "alt": "💁🏾‍♂️",
        "title": "man tipping hand"
    },
    ":man_tipping_hand_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3ff-200d-2642-fe0f.svg",
        "alt": "💁🏿‍♂️",
        "title": "man tipping hand"
    },
    ":woman_tipping_hand:": {
        "src": "/images/emojis/unicode/svg/1f481-200d-2640-fe0f.svg",
        "alt": "💁‍♀️",
        "title": "woman tipping hand"
    },
    ":woman_tipping_hand_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3fb-200d-2640-fe0f.svg",
        "alt": "💁🏻‍♀️",
        "title": "woman tipping hand"
    },
    ":woman_tipping_hand_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3fc-200d-2640-fe0f.svg",
        "alt": "💁🏼‍♀️",
        "title": "woman tipping hand"
    },
    ":woman_tipping_hand_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3fd-200d-2640-fe0f.svg",
        "alt": "💁🏽‍♀️",
        "title": "woman tipping hand"
    },
    ":woman_tipping_hand_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3fe-200d-2640-fe0f.svg",
        "alt": "💁🏾‍♀️",
        "title": "woman tipping hand"
    },
    ":woman_tipping_hand_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f481-1f3ff-200d-2640-fe0f.svg",
        "alt": "💁🏿‍♀️",
        "title": "woman tipping hand"
    },
    ":person_raising_hand:": {
        "src": "/images/emojis/unicode/svg/1f64b.svg",
        "alt": "🙋",
        "title": "person raising hand"
    },
    ":person_raising_hand_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3fb.svg",
        "alt": "🙋🏻",
        "title": "person raising hand"
    },
    ":person_raising_hand_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3fc.svg",
        "alt": "🙋🏼",
        "title": "person raising hand"
    },
    ":person_raising_hand_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3fd.svg",
        "alt": "🙋🏽",
        "title": "person raising hand"
    },
    ":person_raising_hand_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3fe.svg",
        "alt": "🙋🏾",
        "title": "person raising hand"
    },
    ":person_raising_hand_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3ff.svg",
        "alt": "🙋🏿",
        "title": "person raising hand"
    },
    ":man_raising_hand:": {
        "src": "/images/emojis/unicode/svg/1f64b-200d-2642-fe0f.svg",
        "alt": "🙋‍♂️",
        "title": "man raising hand"
    },
    ":man_raising_hand_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3fb-200d-2642-fe0f.svg",
        "alt": "🙋🏻‍♂️",
        "title": "man raising hand"
    },
    ":man_raising_hand_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3fc-200d-2642-fe0f.svg",
        "alt": "🙋🏼‍♂️",
        "title": "man raising hand"
    },
    ":man_raising_hand_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3fd-200d-2642-fe0f.svg",
        "alt": "🙋🏽‍♂️",
        "title": "man raising hand"
    },
    ":man_raising_hand_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3fe-200d-2642-fe0f.svg",
        "alt": "🙋🏾‍♂️",
        "title": "man raising hand"
    },
    ":man_raising_hand_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3ff-200d-2642-fe0f.svg",
        "alt": "🙋🏿‍♂️",
        "title": "man raising hand"
    },
    ":woman_raising_hand:": {
        "src": "/images/emojis/unicode/svg/1f64b-200d-2640-fe0f.svg",
        "alt": "🙋‍♀️",
        "title": "woman raising hand"
    },
    ":woman_raising_hand_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3fb-200d-2640-fe0f.svg",
        "alt": "🙋🏻‍♀️",
        "title": "woman raising hand"
    },
    ":woman_raising_hand_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3fc-200d-2640-fe0f.svg",
        "alt": "🙋🏼‍♀️",
        "title": "woman raising hand"
    },
    ":woman_raising_hand_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3fd-200d-2640-fe0f.svg",
        "alt": "🙋🏽‍♀️",
        "title": "woman raising hand"
    },
    ":woman_raising_hand_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3fe-200d-2640-fe0f.svg",
        "alt": "🙋🏾‍♀️",
        "title": "woman raising hand"
    },
    ":woman_raising_hand_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f64b-1f3ff-200d-2640-fe0f.svg",
        "alt": "🙋🏿‍♀️",
        "title": "woman raising hand"
    },
    ":deaf_person:": {
        "src": "/images/emojis/unicode/svg/1f9cf.svg",
        "alt": "🧏",
        "title": "deaf person"
    },
    ":deaf_person_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3fb.svg",
        "alt": "🧏🏻",
        "title": "deaf person"
    },
    ":deaf_person_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3fc.svg",
        "alt": "🧏🏼",
        "title": "deaf person"
    },
    ":deaf_person_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3fd.svg",
        "alt": "🧏🏽",
        "title": "deaf person"
    },
    ":deaf_person_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3fe.svg",
        "alt": "🧏🏾",
        "title": "deaf person"
    },
    ":deaf_person_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3ff.svg",
        "alt": "🧏🏿",
        "title": "deaf person"
    },
    ":deaf_man:": {
        "src": "/images/emojis/unicode/svg/1f9cf-200d-2642-fe0f.svg",
        "alt": "🧏‍♂️",
        "title": "deaf man"
    },
    ":deaf_man_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3fb-200d-2642-fe0f.svg",
        "alt": "🧏🏻‍♂️",
        "title": "deaf man"
    },
    ":deaf_man_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3fc-200d-2642-fe0f.svg",
        "alt": "🧏🏼‍♂️",
        "title": "deaf man"
    },
    ":deaf_man_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3fd-200d-2642-fe0f.svg",
        "alt": "🧏🏽‍♂️",
        "title": "deaf man"
    },
    ":deaf_man_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3fe-200d-2642-fe0f.svg",
        "alt": "🧏🏾‍♂️",
        "title": "deaf man"
    },
    ":deaf_man_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3ff-200d-2642-fe0f.svg",
        "alt": "🧏🏿‍♂️",
        "title": "deaf man"
    },
    ":deaf_woman:": {
        "src": "/images/emojis/unicode/svg/1f9cf-200d-2640-fe0f.svg",
        "alt": "🧏‍♀️",
        "title": "deaf woman"
    },
    ":deaf_woman_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3fb-200d-2640-fe0f.svg",
        "alt": "🧏🏻‍♀️",
        "title": "deaf woman"
    },
    ":deaf_woman_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3fc-200d-2640-fe0f.svg",
        "alt": "🧏🏼‍♀️",
        "title": "deaf woman"
    },
    ":deaf_woman_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3fd-200d-2640-fe0f.svg",
        "alt": "🧏🏽‍♀️",
        "title": "deaf woman"
    },
    ":deaf_woman_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3fe-200d-2640-fe0f.svg",
        "alt": "🧏🏾‍♀️",
        "title": "deaf woman"
    },
    ":deaf_woman_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cf-1f3ff-200d-2640-fe0f.svg",
        "alt": "🧏🏿‍♀️",
        "title": "deaf woman"
    },
    ":person_bowing:": {
        "src": "/images/emojis/unicode/svg/1f647.svg",
        "alt": "🙇",
        "title": "person bowing"
    },
    ":person_bowing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3fb.svg",
        "alt": "🙇🏻",
        "title": "person bowing"
    },
    ":person_bowing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3fc.svg",
        "alt": "🙇🏼",
        "title": "person bowing"
    },
    ":person_bowing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3fd.svg",
        "alt": "🙇🏽",
        "title": "person bowing"
    },
    ":person_bowing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3fe.svg",
        "alt": "🙇🏾",
        "title": "person bowing"
    },
    ":person_bowing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3ff.svg",
        "alt": "🙇🏿",
        "title": "person bowing"
    },
    ":man_bowing:": {
        "src": "/images/emojis/unicode/svg/1f647-200d-2642-fe0f.svg",
        "alt": "🙇‍♂️",
        "title": "man bowing"
    },
    ":man_bowing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3fb-200d-2642-fe0f.svg",
        "alt": "🙇🏻‍♂️",
        "title": "man bowing"
    },
    ":man_bowing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3fc-200d-2642-fe0f.svg",
        "alt": "🙇🏼‍♂️",
        "title": "man bowing"
    },
    ":man_bowing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3fd-200d-2642-fe0f.svg",
        "alt": "🙇🏽‍♂️",
        "title": "man bowing"
    },
    ":man_bowing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3fe-200d-2642-fe0f.svg",
        "alt": "🙇🏾‍♂️",
        "title": "man bowing"
    },
    ":man_bowing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3ff-200d-2642-fe0f.svg",
        "alt": "🙇🏿‍♂️",
        "title": "man bowing"
    },
    ":woman_bowing:": {
        "src": "/images/emojis/unicode/svg/1f647-200d-2640-fe0f.svg",
        "alt": "🙇‍♀️",
        "title": "woman bowing"
    },
    ":woman_bowing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3fb-200d-2640-fe0f.svg",
        "alt": "🙇🏻‍♀️",
        "title": "woman bowing"
    },
    ":woman_bowing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3fc-200d-2640-fe0f.svg",
        "alt": "🙇🏼‍♀️",
        "title": "woman bowing"
    },
    ":woman_bowing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3fd-200d-2640-fe0f.svg",
        "alt": "🙇🏽‍♀️",
        "title": "woman bowing"
    },
    ":woman_bowing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3fe-200d-2640-fe0f.svg",
        "alt": "🙇🏾‍♀️",
        "title": "woman bowing"
    },
    ":woman_bowing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f647-1f3ff-200d-2640-fe0f.svg",
        "alt": "🙇🏿‍♀️",
        "title": "woman bowing"
    },
    ":person_facepalming:": {
        "src": "/images/emojis/unicode/svg/1f926.svg",
        "alt": "🤦",
        "title": "person facepalming"
    },
    ":person_facepalming_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3fb.svg",
        "alt": "🤦🏻",
        "title": "person facepalming"
    },
    ":person_facepalming_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3fc.svg",
        "alt": "🤦🏼",
        "title": "person facepalming"
    },
    ":person_facepalming_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3fd.svg",
        "alt": "🤦🏽",
        "title": "person facepalming"
    },
    ":person_facepalming_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3fe.svg",
        "alt": "🤦🏾",
        "title": "person facepalming"
    },
    ":person_facepalming_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3ff.svg",
        "alt": "🤦🏿",
        "title": "person facepalming"
    },
    ":man_facepalming:": {
        "src": "/images/emojis/unicode/svg/1f926-200d-2642-fe0f.svg",
        "alt": "🤦‍♂️",
        "title": "man facepalming"
    },
    ":man_facepalming_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3fb-200d-2642-fe0f.svg",
        "alt": "🤦🏻‍♂️",
        "title": "man facepalming"
    },
    ":man_facepalming_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3fc-200d-2642-fe0f.svg",
        "alt": "🤦🏼‍♂️",
        "title": "man facepalming"
    },
    ":man_facepalming_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3fd-200d-2642-fe0f.svg",
        "alt": "🤦🏽‍♂️",
        "title": "man facepalming"
    },
    ":man_facepalming_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3fe-200d-2642-fe0f.svg",
        "alt": "🤦🏾‍♂️",
        "title": "man facepalming"
    },
    ":man_facepalming_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3ff-200d-2642-fe0f.svg",
        "alt": "🤦🏿‍♂️",
        "title": "man facepalming"
    },
    ":woman_facepalming:": {
        "src": "/images/emojis/unicode/svg/1f926-200d-2640-fe0f.svg",
        "alt": "🤦‍♀️",
        "title": "woman facepalming"
    },
    ":woman_facepalming_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3fb-200d-2640-fe0f.svg",
        "alt": "🤦🏻‍♀️",
        "title": "woman facepalming"
    },
    ":woman_facepalming_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3fc-200d-2640-fe0f.svg",
        "alt": "🤦🏼‍♀️",
        "title": "woman facepalming"
    },
    ":woman_facepalming_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3fd-200d-2640-fe0f.svg",
        "alt": "🤦🏽‍♀️",
        "title": "woman facepalming"
    },
    ":woman_facepalming_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3fe-200d-2640-fe0f.svg",
        "alt": "🤦🏾‍♀️",
        "title": "woman facepalming"
    },
    ":woman_facepalming_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f926-1f3ff-200d-2640-fe0f.svg",
        "alt": "🤦🏿‍♀️",
        "title": "woman facepalming"
    },
    ":person_shrugging:": {
        "src": "/images/emojis/unicode/svg/1f937.svg",
        "alt": "🤷",
        "title": "person shrugging"
    },
    ":person_shrugging_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3fb.svg",
        "alt": "🤷🏻",
        "title": "person shrugging"
    },
    ":person_shrugging_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3fc.svg",
        "alt": "🤷🏼",
        "title": "person shrugging"
    },
    ":person_shrugging_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3fd.svg",
        "alt": "🤷🏽",
        "title": "person shrugging"
    },
    ":person_shrugging_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3fe.svg",
        "alt": "🤷🏾",
        "title": "person shrugging"
    },
    ":person_shrugging_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3ff.svg",
        "alt": "🤷🏿",
        "title": "person shrugging"
    },
    ":man_shrugging:": {
        "src": "/images/emojis/unicode/svg/1f937-200d-2642-fe0f.svg",
        "alt": "🤷‍♂️",
        "title": "man shrugging"
    },
    ":man_shrugging_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3fb-200d-2642-fe0f.svg",
        "alt": "🤷🏻‍♂️",
        "title": "man shrugging"
    },
    ":man_shrugging_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3fc-200d-2642-fe0f.svg",
        "alt": "🤷🏼‍♂️",
        "title": "man shrugging"
    },
    ":man_shrugging_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3fd-200d-2642-fe0f.svg",
        "alt": "🤷🏽‍♂️",
        "title": "man shrugging"
    },
    ":man_shrugging_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3fe-200d-2642-fe0f.svg",
        "alt": "🤷🏾‍♂️",
        "title": "man shrugging"
    },
    ":man_shrugging_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3ff-200d-2642-fe0f.svg",
        "alt": "🤷🏿‍♂️",
        "title": "man shrugging"
    },
    ":woman_shrugging:": {
        "src": "/images/emojis/unicode/svg/1f937-200d-2640-fe0f.svg",
        "alt": "🤷‍♀️",
        "title": "woman shrugging"
    },
    ":woman_shrugging_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3fb-200d-2640-fe0f.svg",
        "alt": "🤷🏻‍♀️",
        "title": "woman shrugging"
    },
    ":woman_shrugging_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3fc-200d-2640-fe0f.svg",
        "alt": "🤷🏼‍♀️",
        "title": "woman shrugging"
    },
    ":woman_shrugging_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3fd-200d-2640-fe0f.svg",
        "alt": "🤷🏽‍♀️",
        "title": "woman shrugging"
    },
    ":woman_shrugging_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3fe-200d-2640-fe0f.svg",
        "alt": "🤷🏾‍♀️",
        "title": "woman shrugging"
    },
    ":woman_shrugging_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f937-1f3ff-200d-2640-fe0f.svg",
        "alt": "🤷🏿‍♀️",
        "title": "woman shrugging"
    },
    ":health_worker:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-2695-fe0f.svg",
        "alt": "🧑‍⚕️",
        "title": "health worker"
    },
    ":health_worker_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-2695-fe0f.svg",
        "alt": "🧑🏻‍⚕️",
        "title": "health worker"
    },
    ":health_worker_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-2695-fe0f.svg",
        "alt": "🧑🏼‍⚕️",
        "title": "health worker"
    },
    ":health_worker_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-2695-fe0f.svg",
        "alt": "🧑🏽‍⚕️",
        "title": "health worker"
    },
    ":health_worker_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-2695-fe0f.svg",
        "alt": "🧑🏾‍⚕️",
        "title": "health worker"
    },
    ":health_worker_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-2695-fe0f.svg",
        "alt": "🧑🏿‍⚕️",
        "title": "health worker"
    },
    ":man_health_worker:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-2695-fe0f.svg",
        "alt": "👨‍⚕️",
        "title": "man health worker"
    },
    ":man_health_worker_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-2695-fe0f.svg",
        "alt": "👨🏻‍⚕️",
        "title": "man health worker"
    },
    ":man_health_worker_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-2695-fe0f.svg",
        "alt": "👨🏼‍⚕️",
        "title": "man health worker"
    },
    ":man_health_worker_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-2695-fe0f.svg",
        "alt": "👨🏽‍⚕️",
        "title": "man health worker"
    },
    ":man_health_worker_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-2695-fe0f.svg",
        "alt": "👨🏾‍⚕️",
        "title": "man health worker"
    },
    ":man_health_worker_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-2695-fe0f.svg",
        "alt": "👨🏿‍⚕️",
        "title": "man health worker"
    },
    ":woman_health_worker:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-2695-fe0f.svg",
        "alt": "👩‍⚕️",
        "title": "woman health worker"
    },
    ":woman_health_worker_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-2695-fe0f.svg",
        "alt": "👩🏻‍⚕️",
        "title": "woman health worker"
    },
    ":woman_health_worker_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-2695-fe0f.svg",
        "alt": "👩🏼‍⚕️",
        "title": "woman health worker"
    },
    ":woman_health_worker_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-2695-fe0f.svg",
        "alt": "👩🏽‍⚕️",
        "title": "woman health worker"
    },
    ":woman_health_worker_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-2695-fe0f.svg",
        "alt": "👩🏾‍⚕️",
        "title": "woman health worker"
    },
    ":woman_health_worker_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-2695-fe0f.svg",
        "alt": "👩🏿‍⚕️",
        "title": "woman health worker"
    },
    ":student:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f393.svg",
        "alt": "🧑‍🎓",
        "title": "student"
    },
    ":student_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f393.svg",
        "alt": "🧑🏻‍🎓",
        "title": "student"
    },
    ":student_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f393.svg",
        "alt": "🧑🏼‍🎓",
        "title": "student"
    },
    ":student_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f393.svg",
        "alt": "🧑🏽‍🎓",
        "title": "student"
    },
    ":student_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f393.svg",
        "alt": "🧑🏾‍🎓",
        "title": "student"
    },
    ":student_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f393.svg",
        "alt": "🧑🏿‍🎓",
        "title": "student"
    },
    ":man_student:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f393.svg",
        "alt": "👨‍🎓",
        "title": "man student"
    },
    ":man_student_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f393.svg",
        "alt": "👨🏻‍🎓",
        "title": "man student"
    },
    ":man_student_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f393.svg",
        "alt": "👨🏼‍🎓",
        "title": "man student"
    },
    ":man_student_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f393.svg",
        "alt": "👨🏽‍🎓",
        "title": "man student"
    },
    ":man_student_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f393.svg",
        "alt": "👨🏾‍🎓",
        "title": "man student"
    },
    ":man_student_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f393.svg",
        "alt": "👨🏿‍🎓",
        "title": "man student"
    },
    ":woman_student:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f393.svg",
        "alt": "👩‍🎓",
        "title": "woman student"
    },
    ":woman_student_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f393.svg",
        "alt": "👩🏻‍🎓",
        "title": "woman student"
    },
    ":woman_student_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f393.svg",
        "alt": "👩🏼‍🎓",
        "title": "woman student"
    },
    ":woman_student_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f393.svg",
        "alt": "👩🏽‍🎓",
        "title": "woman student"
    },
    ":woman_student_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f393.svg",
        "alt": "👩🏾‍🎓",
        "title": "woman student"
    },
    ":woman_student_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f393.svg",
        "alt": "👩🏿‍🎓",
        "title": "woman student"
    },
    ":teacher:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f3eb.svg",
        "alt": "🧑‍🏫",
        "title": "teacher"
    },
    ":teacher_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f3eb.svg",
        "alt": "🧑🏻‍🏫",
        "title": "teacher"
    },
    ":teacher_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f3eb.svg",
        "alt": "🧑🏼‍🏫",
        "title": "teacher"
    },
    ":teacher_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f3eb.svg",
        "alt": "🧑🏽‍🏫",
        "title": "teacher"
    },
    ":teacher_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f3eb.svg",
        "alt": "🧑🏾‍🏫",
        "title": "teacher"
    },
    ":teacher_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f3eb.svg",
        "alt": "🧑🏿‍🏫",
        "title": "teacher"
    },
    ":man_teacher:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f3eb.svg",
        "alt": "👨‍🏫",
        "title": "man teacher"
    },
    ":man_teacher_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f3eb.svg",
        "alt": "👨🏻‍🏫",
        "title": "man teacher"
    },
    ":man_teacher_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f3eb.svg",
        "alt": "👨🏼‍🏫",
        "title": "man teacher"
    },
    ":man_teacher_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f3eb.svg",
        "alt": "👨🏽‍🏫",
        "title": "man teacher"
    },
    ":man_teacher_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f3eb.svg",
        "alt": "👨🏾‍🏫",
        "title": "man teacher"
    },
    ":man_teacher_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f3eb.svg",
        "alt": "👨🏿‍🏫",
        "title": "man teacher"
    },
    ":woman_teacher:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f3eb.svg",
        "alt": "👩‍🏫",
        "title": "woman teacher"
    },
    ":woman_teacher_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f3eb.svg",
        "alt": "👩🏻‍🏫",
        "title": "woman teacher"
    },
    ":woman_teacher_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f3eb.svg",
        "alt": "👩🏼‍🏫",
        "title": "woman teacher"
    },
    ":woman_teacher_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f3eb.svg",
        "alt": "👩🏽‍🏫",
        "title": "woman teacher"
    },
    ":woman_teacher_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f3eb.svg",
        "alt": "👩🏾‍🏫",
        "title": "woman teacher"
    },
    ":woman_teacher_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f3eb.svg",
        "alt": "👩🏿‍🏫",
        "title": "woman teacher"
    },
    ":judge:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-2696-fe0f.svg",
        "alt": "🧑‍⚖️",
        "title": "judge"
    },
    ":judge_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-2696-fe0f.svg",
        "alt": "🧑🏻‍⚖️",
        "title": "judge"
    },
    ":judge_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-2696-fe0f.svg",
        "alt": "🧑🏼‍⚖️",
        "title": "judge"
    },
    ":judge_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-2696-fe0f.svg",
        "alt": "🧑🏽‍⚖️",
        "title": "judge"
    },
    ":judge_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-2696-fe0f.svg",
        "alt": "🧑🏾‍⚖️",
        "title": "judge"
    },
    ":judge_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-2696-fe0f.svg",
        "alt": "🧑🏿‍⚖️",
        "title": "judge"
    },
    ":man_judge:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-2696-fe0f.svg",
        "alt": "👨‍⚖️",
        "title": "man judge"
    },
    ":man_judge_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-2696-fe0f.svg",
        "alt": "👨🏻‍⚖️",
        "title": "man judge"
    },
    ":man_judge_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-2696-fe0f.svg",
        "alt": "👨🏼‍⚖️",
        "title": "man judge"
    },
    ":man_judge_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-2696-fe0f.svg",
        "alt": "👨🏽‍⚖️",
        "title": "man judge"
    },
    ":man_judge_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-2696-fe0f.svg",
        "alt": "👨🏾‍⚖️",
        "title": "man judge"
    },
    ":man_judge_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-2696-fe0f.svg",
        "alt": "👨🏿‍⚖️",
        "title": "man judge"
    },
    ":woman_judge:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-2696-fe0f.svg",
        "alt": "👩‍⚖️",
        "title": "woman judge"
    },
    ":woman_judge_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-2696-fe0f.svg",
        "alt": "👩🏻‍⚖️",
        "title": "woman judge"
    },
    ":woman_judge_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-2696-fe0f.svg",
        "alt": "👩🏼‍⚖️",
        "title": "woman judge"
    },
    ":woman_judge_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-2696-fe0f.svg",
        "alt": "👩🏽‍⚖️",
        "title": "woman judge"
    },
    ":woman_judge_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-2696-fe0f.svg",
        "alt": "👩🏾‍⚖️",
        "title": "woman judge"
    },
    ":woman_judge_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-2696-fe0f.svg",
        "alt": "👩🏿‍⚖️",
        "title": "woman judge"
    },
    ":farmer:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f33e.svg",
        "alt": "🧑‍🌾",
        "title": "farmer"
    },
    ":farmer_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f33e.svg",
        "alt": "🧑🏻‍🌾",
        "title": "farmer"
    },
    ":farmer_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f33e.svg",
        "alt": "🧑🏼‍🌾",
        "title": "farmer"
    },
    ":farmer_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f33e.svg",
        "alt": "🧑🏽‍🌾",
        "title": "farmer"
    },
    ":farmer_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f33e.svg",
        "alt": "🧑🏾‍🌾",
        "title": "farmer"
    },
    ":farmer_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f33e.svg",
        "alt": "🧑🏿‍🌾",
        "title": "farmer"
    },
    ":man_farmer:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f33e.svg",
        "alt": "👨‍🌾",
        "title": "man farmer"
    },
    ":man_farmer_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f33e.svg",
        "alt": "👨🏻‍🌾",
        "title": "man farmer"
    },
    ":man_farmer_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f33e.svg",
        "alt": "👨🏼‍🌾",
        "title": "man farmer"
    },
    ":man_farmer_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f33e.svg",
        "alt": "👨🏽‍🌾",
        "title": "man farmer"
    },
    ":man_farmer_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f33e.svg",
        "alt": "👨🏾‍🌾",
        "title": "man farmer"
    },
    ":man_farmer_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f33e.svg",
        "alt": "👨🏿‍🌾",
        "title": "man farmer"
    },
    ":woman_farmer:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f33e.svg",
        "alt": "👩‍🌾",
        "title": "woman farmer"
    },
    ":woman_farmer_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f33e.svg",
        "alt": "👩🏻‍🌾",
        "title": "woman farmer"
    },
    ":woman_farmer_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f33e.svg",
        "alt": "👩🏼‍🌾",
        "title": "woman farmer"
    },
    ":woman_farmer_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f33e.svg",
        "alt": "👩🏽‍🌾",
        "title": "woman farmer"
    },
    ":woman_farmer_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f33e.svg",
        "alt": "👩🏾‍🌾",
        "title": "woman farmer"
    },
    ":woman_farmer_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f33e.svg",
        "alt": "👩🏿‍🌾",
        "title": "woman farmer"
    },
    ":cook:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f373.svg",
        "alt": "🧑‍🍳",
        "title": "cook"
    },
    ":cook_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f373.svg",
        "alt": "🧑🏻‍🍳",
        "title": "cook"
    },
    ":cook_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f373.svg",
        "alt": "🧑🏼‍🍳",
        "title": "cook"
    },
    ":cook_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f373.svg",
        "alt": "🧑🏽‍🍳",
        "title": "cook"
    },
    ":cook_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f373.svg",
        "alt": "🧑🏾‍🍳",
        "title": "cook"
    },
    ":cook_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f373.svg",
        "alt": "🧑🏿‍🍳",
        "title": "cook"
    },
    ":man_cook:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f373.svg",
        "alt": "👨‍🍳",
        "title": "man cook"
    },
    ":man_cook_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f373.svg",
        "alt": "👨🏻‍🍳",
        "title": "man cook"
    },
    ":man_cook_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f373.svg",
        "alt": "👨🏼‍🍳",
        "title": "man cook"
    },
    ":man_cook_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f373.svg",
        "alt": "👨🏽‍🍳",
        "title": "man cook"
    },
    ":man_cook_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f373.svg",
        "alt": "👨🏾‍🍳",
        "title": "man cook"
    },
    ":man_cook_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f373.svg",
        "alt": "👨🏿‍🍳",
        "title": "man cook"
    },
    ":woman_cook:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f373.svg",
        "alt": "👩‍🍳",
        "title": "woman cook"
    },
    ":woman_cook_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f373.svg",
        "alt": "👩🏻‍🍳",
        "title": "woman cook"
    },
    ":woman_cook_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f373.svg",
        "alt": "👩🏼‍🍳",
        "title": "woman cook"
    },
    ":woman_cook_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f373.svg",
        "alt": "👩🏽‍🍳",
        "title": "woman cook"
    },
    ":woman_cook_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f373.svg",
        "alt": "👩🏾‍🍳",
        "title": "woman cook"
    },
    ":woman_cook_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f373.svg",
        "alt": "👩🏿‍🍳",
        "title": "woman cook"
    },
    ":mechanic:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f527.svg",
        "alt": "🧑‍🔧",
        "title": "mechanic"
    },
    ":mechanic_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f527.svg",
        "alt": "🧑🏻‍🔧",
        "title": "mechanic"
    },
    ":mechanic_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f527.svg",
        "alt": "🧑🏼‍🔧",
        "title": "mechanic"
    },
    ":mechanic_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f527.svg",
        "alt": "🧑🏽‍🔧",
        "title": "mechanic"
    },
    ":mechanic_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f527.svg",
        "alt": "🧑🏾‍🔧",
        "title": "mechanic"
    },
    ":mechanic_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f527.svg",
        "alt": "🧑🏿‍🔧",
        "title": "mechanic"
    },
    ":man_mechanic:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f527.svg",
        "alt": "👨‍🔧",
        "title": "man mechanic"
    },
    ":man_mechanic_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f527.svg",
        "alt": "👨🏻‍🔧",
        "title": "man mechanic"
    },
    ":man_mechanic_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f527.svg",
        "alt": "👨🏼‍🔧",
        "title": "man mechanic"
    },
    ":man_mechanic_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f527.svg",
        "alt": "👨🏽‍🔧",
        "title": "man mechanic"
    },
    ":man_mechanic_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f527.svg",
        "alt": "👨🏾‍🔧",
        "title": "man mechanic"
    },
    ":man_mechanic_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f527.svg",
        "alt": "👨🏿‍🔧",
        "title": "man mechanic"
    },
    ":woman_mechanic:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f527.svg",
        "alt": "👩‍🔧",
        "title": "woman mechanic"
    },
    ":woman_mechanic_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f527.svg",
        "alt": "👩🏻‍🔧",
        "title": "woman mechanic"
    },
    ":woman_mechanic_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f527.svg",
        "alt": "👩🏼‍🔧",
        "title": "woman mechanic"
    },
    ":woman_mechanic_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f527.svg",
        "alt": "👩🏽‍🔧",
        "title": "woman mechanic"
    },
    ":woman_mechanic_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f527.svg",
        "alt": "👩🏾‍🔧",
        "title": "woman mechanic"
    },
    ":woman_mechanic_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f527.svg",
        "alt": "👩🏿‍🔧",
        "title": "woman mechanic"
    },
    ":factory_worker:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f3ed.svg",
        "alt": "🧑‍🏭",
        "title": "factory worker"
    },
    ":factory_worker_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f3ed.svg",
        "alt": "🧑🏻‍🏭",
        "title": "factory worker"
    },
    ":factory_worker_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f3ed.svg",
        "alt": "🧑🏼‍🏭",
        "title": "factory worker"
    },
    ":factory_worker_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f3ed.svg",
        "alt": "🧑🏽‍🏭",
        "title": "factory worker"
    },
    ":factory_worker_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f3ed.svg",
        "alt": "🧑🏾‍🏭",
        "title": "factory worker"
    },
    ":factory_worker_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f3ed.svg",
        "alt": "🧑🏿‍🏭",
        "title": "factory worker"
    },
    ":man_factory_worker:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f3ed.svg",
        "alt": "👨‍🏭",
        "title": "man factory worker"
    },
    ":man_factory_worker_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f3ed.svg",
        "alt": "👨🏻‍🏭",
        "title": "man factory worker"
    },
    ":man_factory_worker_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f3ed.svg",
        "alt": "👨🏼‍🏭",
        "title": "man factory worker"
    },
    ":man_factory_worker_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f3ed.svg",
        "alt": "👨🏽‍🏭",
        "title": "man factory worker"
    },
    ":man_factory_worker_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f3ed.svg",
        "alt": "👨🏾‍🏭",
        "title": "man factory worker"
    },
    ":man_factory_worker_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f3ed.svg",
        "alt": "👨🏿‍🏭",
        "title": "man factory worker"
    },
    ":woman_factory_worker:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f3ed.svg",
        "alt": "👩‍🏭",
        "title": "woman factory worker"
    },
    ":woman_factory_worker_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f3ed.svg",
        "alt": "👩🏻‍🏭",
        "title": "woman factory worker"
    },
    ":woman_factory_worker_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f3ed.svg",
        "alt": "👩🏼‍🏭",
        "title": "woman factory worker"
    },
    ":woman_factory_worker_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f3ed.svg",
        "alt": "👩🏽‍🏭",
        "title": "woman factory worker"
    },
    ":woman_factory_worker_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f3ed.svg",
        "alt": "👩🏾‍🏭",
        "title": "woman factory worker"
    },
    ":woman_factory_worker_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f3ed.svg",
        "alt": "👩🏿‍🏭",
        "title": "woman factory worker"
    },
    ":office_worker:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f4bc.svg",
        "alt": "🧑‍💼",
        "title": "office worker"
    },
    ":office_worker_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f4bc.svg",
        "alt": "🧑🏻‍💼",
        "title": "office worker"
    },
    ":office_worker_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f4bc.svg",
        "alt": "🧑🏼‍💼",
        "title": "office worker"
    },
    ":office_worker_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f4bc.svg",
        "alt": "🧑🏽‍💼",
        "title": "office worker"
    },
    ":office_worker_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f4bc.svg",
        "alt": "🧑🏾‍💼",
        "title": "office worker"
    },
    ":office_worker_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f4bc.svg",
        "alt": "🧑🏿‍💼",
        "title": "office worker"
    },
    ":man_office_worker:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f4bc.svg",
        "alt": "👨‍💼",
        "title": "man office worker"
    },
    ":man_office_worker_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f4bc.svg",
        "alt": "👨🏻‍💼",
        "title": "man office worker"
    },
    ":man_office_worker_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f4bc.svg",
        "alt": "👨🏼‍💼",
        "title": "man office worker"
    },
    ":man_office_worker_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f4bc.svg",
        "alt": "👨🏽‍💼",
        "title": "man office worker"
    },
    ":man_office_worker_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f4bc.svg",
        "alt": "👨🏾‍💼",
        "title": "man office worker"
    },
    ":man_office_worker_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f4bc.svg",
        "alt": "👨🏿‍💼",
        "title": "man office worker"
    },
    ":woman_office_worker:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f4bc.svg",
        "alt": "👩‍💼",
        "title": "woman office worker"
    },
    ":woman_office_worker_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f4bc.svg",
        "alt": "👩🏻‍💼",
        "title": "woman office worker"
    },
    ":woman_office_worker_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f4bc.svg",
        "alt": "👩🏼‍💼",
        "title": "woman office worker"
    },
    ":woman_office_worker_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f4bc.svg",
        "alt": "👩🏽‍💼",
        "title": "woman office worker"
    },
    ":woman_office_worker_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f4bc.svg",
        "alt": "👩🏾‍💼",
        "title": "woman office worker"
    },
    ":woman_office_worker_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f4bc.svg",
        "alt": "👩🏿‍💼",
        "title": "woman office worker"
    },
    ":scientist:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f52c.svg",
        "alt": "🧑‍🔬",
        "title": "scientist"
    },
    ":scientist_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f52c.svg",
        "alt": "🧑🏻‍🔬",
        "title": "scientist"
    },
    ":scientist_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f52c.svg",
        "alt": "🧑🏼‍🔬",
        "title": "scientist"
    },
    ":scientist_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f52c.svg",
        "alt": "🧑🏽‍🔬",
        "title": "scientist"
    },
    ":scientist_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f52c.svg",
        "alt": "🧑🏾‍🔬",
        "title": "scientist"
    },
    ":scientist_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f52c.svg",
        "alt": "🧑🏿‍🔬",
        "title": "scientist"
    },
    ":man_scientist:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f52c.svg",
        "alt": "👨‍🔬",
        "title": "man scientist"
    },
    ":man_scientist_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f52c.svg",
        "alt": "👨🏻‍🔬",
        "title": "man scientist"
    },
    ":man_scientist_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f52c.svg",
        "alt": "👨🏼‍🔬",
        "title": "man scientist"
    },
    ":man_scientist_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f52c.svg",
        "alt": "👨🏽‍🔬",
        "title": "man scientist"
    },
    ":man_scientist_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f52c.svg",
        "alt": "👨🏾‍🔬",
        "title": "man scientist"
    },
    ":man_scientist_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f52c.svg",
        "alt": "👨🏿‍🔬",
        "title": "man scientist"
    },
    ":woman_scientist:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f52c.svg",
        "alt": "👩‍🔬",
        "title": "woman scientist"
    },
    ":woman_scientist_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f52c.svg",
        "alt": "👩🏻‍🔬",
        "title": "woman scientist"
    },
    ":woman_scientist_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f52c.svg",
        "alt": "👩🏼‍🔬",
        "title": "woman scientist"
    },
    ":woman_scientist_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f52c.svg",
        "alt": "👩🏽‍🔬",
        "title": "woman scientist"
    },
    ":woman_scientist_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f52c.svg",
        "alt": "👩🏾‍🔬",
        "title": "woman scientist"
    },
    ":woman_scientist_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f52c.svg",
        "alt": "👩🏿‍🔬",
        "title": "woman scientist"
    },
    ":technologist:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f4bb.svg",
        "alt": "🧑‍💻",
        "title": "technologist"
    },
    ":technologist_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f4bb.svg",
        "alt": "🧑🏻‍💻",
        "title": "technologist"
    },
    ":technologist_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f4bb.svg",
        "alt": "🧑🏼‍💻",
        "title": "technologist"
    },
    ":technologist_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f4bb.svg",
        "alt": "🧑🏽‍💻",
        "title": "technologist"
    },
    ":technologist_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f4bb.svg",
        "alt": "🧑🏾‍💻",
        "title": "technologist"
    },
    ":technologist_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f4bb.svg",
        "alt": "🧑🏿‍💻",
        "title": "technologist"
    },
    ":man_technologist:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f4bb.svg",
        "alt": "👨‍💻",
        "title": "man technologist"
    },
    ":man_technologist_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f4bb.svg",
        "alt": "👨🏻‍💻",
        "title": "man technologist"
    },
    ":man_technologist_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f4bb.svg",
        "alt": "👨🏼‍💻",
        "title": "man technologist"
    },
    ":man_technologist_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f4bb.svg",
        "alt": "👨🏽‍💻",
        "title": "man technologist"
    },
    ":man_technologist_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f4bb.svg",
        "alt": "👨🏾‍💻",
        "title": "man technologist"
    },
    ":man_technologist_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f4bb.svg",
        "alt": "👨🏿‍💻",
        "title": "man technologist"
    },
    ":woman_technologist:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f4bb.svg",
        "alt": "👩‍💻",
        "title": "woman technologist"
    },
    ":woman_technologist_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f4bb.svg",
        "alt": "👩🏻‍💻",
        "title": "woman technologist"
    },
    ":woman_technologist_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f4bb.svg",
        "alt": "👩🏼‍💻",
        "title": "woman technologist"
    },
    ":woman_technologist_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f4bb.svg",
        "alt": "👩🏽‍💻",
        "title": "woman technologist"
    },
    ":woman_technologist_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f4bb.svg",
        "alt": "👩🏾‍💻",
        "title": "woman technologist"
    },
    ":woman_technologist_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f4bb.svg",
        "alt": "👩🏿‍💻",
        "title": "woman technologist"
    },
    ":singer:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f3a4.svg",
        "alt": "🧑‍🎤",
        "title": "singer"
    },
    ":singer_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f3a4.svg",
        "alt": "🧑🏻‍🎤",
        "title": "singer"
    },
    ":singer_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f3a4.svg",
        "alt": "🧑🏼‍🎤",
        "title": "singer"
    },
    ":singer_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f3a4.svg",
        "alt": "🧑🏽‍🎤",
        "title": "singer"
    },
    ":singer_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f3a4.svg",
        "alt": "🧑🏾‍🎤",
        "title": "singer"
    },
    ":singer_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f3a4.svg",
        "alt": "🧑🏿‍🎤",
        "title": "singer"
    },
    ":man_singer:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f3a4.svg",
        "alt": "👨‍🎤",
        "title": "man singer"
    },
    ":man_singer_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f3a4.svg",
        "alt": "👨🏻‍🎤",
        "title": "man singer"
    },
    ":man_singer_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f3a4.svg",
        "alt": "👨🏼‍🎤",
        "title": "man singer"
    },
    ":man_singer_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f3a4.svg",
        "alt": "👨🏽‍🎤",
        "title": "man singer"
    },
    ":man_singer_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f3a4.svg",
        "alt": "👨🏾‍🎤",
        "title": "man singer"
    },
    ":man_singer_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f3a4.svg",
        "alt": "👨🏿‍🎤",
        "title": "man singer"
    },
    ":woman_singer:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f3a4.svg",
        "alt": "👩‍🎤",
        "title": "woman singer"
    },
    ":woman_singer_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f3a4.svg",
        "alt": "👩🏻‍🎤",
        "title": "woman singer"
    },
    ":woman_singer_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f3a4.svg",
        "alt": "👩🏼‍🎤",
        "title": "woman singer"
    },
    ":woman_singer_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f3a4.svg",
        "alt": "👩🏽‍🎤",
        "title": "woman singer"
    },
    ":woman_singer_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f3a4.svg",
        "alt": "👩🏾‍🎤",
        "title": "woman singer"
    },
    ":woman_singer_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f3a4.svg",
        "alt": "👩🏿‍🎤",
        "title": "woman singer"
    },
    ":artist:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f3a8.svg",
        "alt": "🧑‍🎨",
        "title": "artist"
    },
    ":artist_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f3a8.svg",
        "alt": "🧑🏻‍🎨",
        "title": "artist"
    },
    ":artist_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f3a8.svg",
        "alt": "🧑🏼‍🎨",
        "title": "artist"
    },
    ":artist_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f3a8.svg",
        "alt": "🧑🏽‍🎨",
        "title": "artist"
    },
    ":artist_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f3a8.svg",
        "alt": "🧑🏾‍🎨",
        "title": "artist"
    },
    ":artist_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f3a8.svg",
        "alt": "🧑🏿‍🎨",
        "title": "artist"
    },
    ":man_artist:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f3a8.svg",
        "alt": "👨‍🎨",
        "title": "man artist"
    },
    ":man_artist_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f3a8.svg",
        "alt": "👨🏻‍🎨",
        "title": "man artist"
    },
    ":man_artist_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f3a8.svg",
        "alt": "👨🏼‍🎨",
        "title": "man artist"
    },
    ":man_artist_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f3a8.svg",
        "alt": "👨🏽‍🎨",
        "title": "man artist"
    },
    ":man_artist_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f3a8.svg",
        "alt": "👨🏾‍🎨",
        "title": "man artist"
    },
    ":man_artist_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f3a8.svg",
        "alt": "👨🏿‍🎨",
        "title": "man artist"
    },
    ":woman_artist:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f3a8.svg",
        "alt": "👩‍🎨",
        "title": "woman artist"
    },
    ":woman_artist_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f3a8.svg",
        "alt": "👩🏻‍🎨",
        "title": "woman artist"
    },
    ":woman_artist_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f3a8.svg",
        "alt": "👩🏼‍🎨",
        "title": "woman artist"
    },
    ":woman_artist_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f3a8.svg",
        "alt": "👩🏽‍🎨",
        "title": "woman artist"
    },
    ":woman_artist_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f3a8.svg",
        "alt": "👩🏾‍🎨",
        "title": "woman artist"
    },
    ":woman_artist_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f3a8.svg",
        "alt": "👩🏿‍🎨",
        "title": "woman artist"
    },
    ":pilot:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-2708-fe0f.svg",
        "alt": "🧑‍✈️",
        "title": "pilot"
    },
    ":pilot_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-2708-fe0f.svg",
        "alt": "🧑🏻‍✈️",
        "title": "pilot"
    },
    ":pilot_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-2708-fe0f.svg",
        "alt": "🧑🏼‍✈️",
        "title": "pilot"
    },
    ":pilot_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-2708-fe0f.svg",
        "alt": "🧑🏽‍✈️",
        "title": "pilot"
    },
    ":pilot_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-2708-fe0f.svg",
        "alt": "🧑🏾‍✈️",
        "title": "pilot"
    },
    ":pilot_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-2708-fe0f.svg",
        "alt": "🧑🏿‍✈️",
        "title": "pilot"
    },
    ":man_pilot:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-2708-fe0f.svg",
        "alt": "👨‍✈️",
        "title": "man pilot"
    },
    ":man_pilot_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-2708-fe0f.svg",
        "alt": "👨🏻‍✈️",
        "title": "man pilot"
    },
    ":man_pilot_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-2708-fe0f.svg",
        "alt": "👨🏼‍✈️",
        "title": "man pilot"
    },
    ":man_pilot_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-2708-fe0f.svg",
        "alt": "👨🏽‍✈️",
        "title": "man pilot"
    },
    ":man_pilot_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-2708-fe0f.svg",
        "alt": "👨🏾‍✈️",
        "title": "man pilot"
    },
    ":man_pilot_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-2708-fe0f.svg",
        "alt": "👨🏿‍✈️",
        "title": "man pilot"
    },
    ":woman_pilot:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-2708-fe0f.svg",
        "alt": "👩‍✈️",
        "title": "woman pilot"
    },
    ":woman_pilot_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-2708-fe0f.svg",
        "alt": "👩🏻‍✈️",
        "title": "woman pilot"
    },
    ":woman_pilot_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-2708-fe0f.svg",
        "alt": "👩🏼‍✈️",
        "title": "woman pilot"
    },
    ":woman_pilot_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-2708-fe0f.svg",
        "alt": "👩🏽‍✈️",
        "title": "woman pilot"
    },
    ":woman_pilot_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-2708-fe0f.svg",
        "alt": "👩🏾‍✈️",
        "title": "woman pilot"
    },
    ":woman_pilot_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-2708-fe0f.svg",
        "alt": "👩🏿‍✈️",
        "title": "woman pilot"
    },
    ":astronaut:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f680.svg",
        "alt": "🧑‍🚀",
        "title": "astronaut"
    },
    ":astronaut_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f680.svg",
        "alt": "🧑🏻‍🚀",
        "title": "astronaut"
    },
    ":astronaut_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f680.svg",
        "alt": "🧑🏼‍🚀",
        "title": "astronaut"
    },
    ":astronaut_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f680.svg",
        "alt": "🧑🏽‍🚀",
        "title": "astronaut"
    },
    ":astronaut_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f680.svg",
        "alt": "🧑🏾‍🚀",
        "title": "astronaut"
    },
    ":astronaut_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f680.svg",
        "alt": "🧑🏿‍🚀",
        "title": "astronaut"
    },
    ":man_astronaut:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f680.svg",
        "alt": "👨‍🚀",
        "title": "man astronaut"
    },
    ":man_astronaut_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f680.svg",
        "alt": "👨🏻‍🚀",
        "title": "man astronaut"
    },
    ":man_astronaut_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f680.svg",
        "alt": "👨🏼‍🚀",
        "title": "man astronaut"
    },
    ":man_astronaut_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f680.svg",
        "alt": "👨🏽‍🚀",
        "title": "man astronaut"
    },
    ":man_astronaut_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f680.svg",
        "alt": "👨🏾‍🚀",
        "title": "man astronaut"
    },
    ":man_astronaut_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f680.svg",
        "alt": "👨🏿‍🚀",
        "title": "man astronaut"
    },
    ":woman_astronaut:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f680.svg",
        "alt": "👩‍🚀",
        "title": "woman astronaut"
    },
    ":woman_astronaut_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f680.svg",
        "alt": "👩🏻‍🚀",
        "title": "woman astronaut"
    },
    ":woman_astronaut_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f680.svg",
        "alt": "👩🏼‍🚀",
        "title": "woman astronaut"
    },
    ":woman_astronaut_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f680.svg",
        "alt": "👩🏽‍🚀",
        "title": "woman astronaut"
    },
    ":woman_astronaut_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f680.svg",
        "alt": "👩🏾‍🚀",
        "title": "woman astronaut"
    },
    ":woman_astronaut_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f680.svg",
        "alt": "👩🏿‍🚀",
        "title": "woman astronaut"
    },
    ":firefighter:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f692.svg",
        "alt": "🧑‍🚒",
        "title": "firefighter"
    },
    ":firefighter_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f692.svg",
        "alt": "🧑🏻‍🚒",
        "title": "firefighter"
    },
    ":firefighter_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f692.svg",
        "alt": "🧑🏼‍🚒",
        "title": "firefighter"
    },
    ":firefighter_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f692.svg",
        "alt": "🧑🏽‍🚒",
        "title": "firefighter"
    },
    ":firefighter_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f692.svg",
        "alt": "🧑🏾‍🚒",
        "title": "firefighter"
    },
    ":firefighter_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f692.svg",
        "alt": "🧑🏿‍🚒",
        "title": "firefighter"
    },
    ":man_firefighter:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f692.svg",
        "alt": "👨‍🚒",
        "title": "man firefighter"
    },
    ":man_firefighter_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f692.svg",
        "alt": "👨🏻‍🚒",
        "title": "man firefighter"
    },
    ":man_firefighter_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f692.svg",
        "alt": "👨🏼‍🚒",
        "title": "man firefighter"
    },
    ":man_firefighter_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f692.svg",
        "alt": "👨🏽‍🚒",
        "title": "man firefighter"
    },
    ":man_firefighter_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f692.svg",
        "alt": "👨🏾‍🚒",
        "title": "man firefighter"
    },
    ":man_firefighter_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f692.svg",
        "alt": "👨🏿‍🚒",
        "title": "man firefighter"
    },
    ":woman_firefighter:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f692.svg",
        "alt": "👩‍🚒",
        "title": "woman firefighter"
    },
    ":woman_firefighter_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f692.svg",
        "alt": "👩🏻‍🚒",
        "title": "woman firefighter"
    },
    ":woman_firefighter_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f692.svg",
        "alt": "👩🏼‍🚒",
        "title": "woman firefighter"
    },
    ":woman_firefighter_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f692.svg",
        "alt": "👩🏽‍🚒",
        "title": "woman firefighter"
    },
    ":woman_firefighter_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f692.svg",
        "alt": "👩🏾‍🚒",
        "title": "woman firefighter"
    },
    ":woman_firefighter_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f692.svg",
        "alt": "👩🏿‍🚒",
        "title": "woman firefighter"
    },
    ":police_officer:": {
        "src": "/images/emojis/unicode/svg/1f46e.svg",
        "alt": "👮",
        "title": "police officer"
    },
    ":police_officer_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3fb.svg",
        "alt": "👮🏻",
        "title": "police officer"
    },
    ":police_officer_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3fc.svg",
        "alt": "👮🏼",
        "title": "police officer"
    },
    ":police_officer_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3fd.svg",
        "alt": "👮🏽",
        "title": "police officer"
    },
    ":police_officer_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3fe.svg",
        "alt": "👮🏾",
        "title": "police officer"
    },
    ":police_officer_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3ff.svg",
        "alt": "👮🏿",
        "title": "police officer"
    },
    ":man_police_officer:": {
        "src": "/images/emojis/unicode/svg/1f46e-200d-2642-fe0f.svg",
        "alt": "👮‍♂️",
        "title": "man police officer"
    },
    ":man_police_officer_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3fb-200d-2642-fe0f.svg",
        "alt": "👮🏻‍♂️",
        "title": "man police officer"
    },
    ":man_police_officer_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3fc-200d-2642-fe0f.svg",
        "alt": "👮🏼‍♂️",
        "title": "man police officer"
    },
    ":man_police_officer_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3fd-200d-2642-fe0f.svg",
        "alt": "👮🏽‍♂️",
        "title": "man police officer"
    },
    ":man_police_officer_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3fe-200d-2642-fe0f.svg",
        "alt": "👮🏾‍♂️",
        "title": "man police officer"
    },
    ":man_police_officer_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3ff-200d-2642-fe0f.svg",
        "alt": "👮🏿‍♂️",
        "title": "man police officer"
    },
    ":woman_police_officer:": {
        "src": "/images/emojis/unicode/svg/1f46e-200d-2640-fe0f.svg",
        "alt": "👮‍♀️",
        "title": "woman police officer"
    },
    ":woman_police_officer_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3fb-200d-2640-fe0f.svg",
        "alt": "👮🏻‍♀️",
        "title": "woman police officer"
    },
    ":woman_police_officer_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3fc-200d-2640-fe0f.svg",
        "alt": "👮🏼‍♀️",
        "title": "woman police officer"
    },
    ":woman_police_officer_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3fd-200d-2640-fe0f.svg",
        "alt": "👮🏽‍♀️",
        "title": "woman police officer"
    },
    ":woman_police_officer_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3fe-200d-2640-fe0f.svg",
        "alt": "👮🏾‍♀️",
        "title": "woman police officer"
    },
    ":woman_police_officer_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46e-1f3ff-200d-2640-fe0f.svg",
        "alt": "👮🏿‍♀️",
        "title": "woman police officer"
    },
    ":man_detective:": {
        "src": "/images/emojis/unicode/svg/1f575-fe0f-200d-2642-fe0f.svg",
        "alt": "🕵️‍♂️",
        "title": "man detective"
    },
    ":man_detective_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f575-1f3fb-200d-2642-fe0f.svg",
        "alt": "🕵🏻‍♂️",
        "title": "man detective"
    },
    ":man_detective_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f575-1f3fc-200d-2642-fe0f.svg",
        "alt": "🕵🏼‍♂️",
        "title": "man detective"
    },
    ":man_detective_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f575-1f3fd-200d-2642-fe0f.svg",
        "alt": "🕵🏽‍♂️",
        "title": "man detective"
    },
    ":man_detective_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f575-1f3fe-200d-2642-fe0f.svg",
        "alt": "🕵🏾‍♂️",
        "title": "man detective"
    },
    ":man_detective_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f575-1f3ff-200d-2642-fe0f.svg",
        "alt": "🕵🏿‍♂️",
        "title": "man detective"
    },
    ":woman_detective:": {
        "src": "/images/emojis/unicode/svg/1f575-fe0f-200d-2640-fe0f.svg",
        "alt": "🕵️‍♀️",
        "title": "woman detective"
    },
    ":woman_detective_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f575-1f3fb-200d-2640-fe0f.svg",
        "alt": "🕵🏻‍♀️",
        "title": "woman detective"
    },
    ":woman_detective_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f575-1f3fc-200d-2640-fe0f.svg",
        "alt": "🕵🏼‍♀️",
        "title": "woman detective"
    },
    ":woman_detective_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f575-1f3fd-200d-2640-fe0f.svg",
        "alt": "🕵🏽‍♀️",
        "title": "woman detective"
    },
    ":woman_detective_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f575-1f3fe-200d-2640-fe0f.svg",
        "alt": "🕵🏾‍♀️",
        "title": "woman detective"
    },
    ":woman_detective_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f575-1f3ff-200d-2640-fe0f.svg",
        "alt": "🕵🏿‍♀️",
        "title": "woman detective"
    },
    ":guard:": {
        "src": "/images/emojis/unicode/svg/1f482.svg",
        "alt": "💂",
        "title": "guard"
    },
    ":guard_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3fb.svg",
        "alt": "💂🏻",
        "title": "guard"
    },
    ":guard_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3fc.svg",
        "alt": "💂🏼",
        "title": "guard"
    },
    ":guard_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3fd.svg",
        "alt": "💂🏽",
        "title": "guard"
    },
    ":guard_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3fe.svg",
        "alt": "💂🏾",
        "title": "guard"
    },
    ":guard_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3ff.svg",
        "alt": "💂🏿",
        "title": "guard"
    },
    ":man_guard:": {
        "src": "/images/emojis/unicode/svg/1f482-200d-2642-fe0f.svg",
        "alt": "💂‍♂️",
        "title": "man guard"
    },
    ":man_guard_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3fb-200d-2642-fe0f.svg",
        "alt": "💂🏻‍♂️",
        "title": "man guard"
    },
    ":man_guard_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3fc-200d-2642-fe0f.svg",
        "alt": "💂🏼‍♂️",
        "title": "man guard"
    },
    ":man_guard_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3fd-200d-2642-fe0f.svg",
        "alt": "💂🏽‍♂️",
        "title": "man guard"
    },
    ":man_guard_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3fe-200d-2642-fe0f.svg",
        "alt": "💂🏾‍♂️",
        "title": "man guard"
    },
    ":man_guard_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3ff-200d-2642-fe0f.svg",
        "alt": "💂🏿‍♂️",
        "title": "man guard"
    },
    ":woman_guard:": {
        "src": "/images/emojis/unicode/svg/1f482-200d-2640-fe0f.svg",
        "alt": "💂‍♀️",
        "title": "woman guard"
    },
    ":woman_guard_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3fb-200d-2640-fe0f.svg",
        "alt": "💂🏻‍♀️",
        "title": "woman guard"
    },
    ":woman_guard_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3fc-200d-2640-fe0f.svg",
        "alt": "💂🏼‍♀️",
        "title": "woman guard"
    },
    ":woman_guard_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3fd-200d-2640-fe0f.svg",
        "alt": "💂🏽‍♀️",
        "title": "woman guard"
    },
    ":woman_guard_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3fe-200d-2640-fe0f.svg",
        "alt": "💂🏾‍♀️",
        "title": "woman guard"
    },
    ":woman_guard_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f482-1f3ff-200d-2640-fe0f.svg",
        "alt": "💂🏿‍♀️",
        "title": "woman guard"
    },
    ":ninja:": {
        "src": "/images/emojis/unicode/svg/1f977.svg",
        "alt": "🥷",
        "title": "ninja"
    },
    ":ninja_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f977-1f3fb.svg",
        "alt": "🥷🏻",
        "title": "ninja"
    },
    ":ninja_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f977-1f3fc.svg",
        "alt": "🥷🏼",
        "title": "ninja"
    },
    ":ninja_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f977-1f3fd.svg",
        "alt": "🥷🏽",
        "title": "ninja"
    },
    ":ninja_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f977-1f3fe.svg",
        "alt": "🥷🏾",
        "title": "ninja"
    },
    ":ninja_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f977-1f3ff.svg",
        "alt": "🥷🏿",
        "title": "ninja"
    },
    ":construction_worker:": {
        "src": "/images/emojis/unicode/svg/1f477.svg",
        "alt": "👷",
        "title": "construction worker"
    },
    ":construction_worker_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3fb.svg",
        "alt": "👷🏻",
        "title": "construction worker"
    },
    ":construction_worker_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3fc.svg",
        "alt": "👷🏼",
        "title": "construction worker"
    },
    ":construction_worker_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3fd.svg",
        "alt": "👷🏽",
        "title": "construction worker"
    },
    ":construction_worker_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3fe.svg",
        "alt": "👷🏾",
        "title": "construction worker"
    },
    ":construction_worker_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3ff.svg",
        "alt": "👷🏿",
        "title": "construction worker"
    },
    ":man_construction_worker:": {
        "src": "/images/emojis/unicode/svg/1f477-200d-2642-fe0f.svg",
        "alt": "👷‍♂️",
        "title": "man construction worker"
    },
    ":man_construction_worker_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3fb-200d-2642-fe0f.svg",
        "alt": "👷🏻‍♂️",
        "title": "man construction worker"
    },
    ":man_construction_worker_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3fc-200d-2642-fe0f.svg",
        "alt": "👷🏼‍♂️",
        "title": "man construction worker"
    },
    ":man_construction_worker_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3fd-200d-2642-fe0f.svg",
        "alt": "👷🏽‍♂️",
        "title": "man construction worker"
    },
    ":man_construction_worker_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3fe-200d-2642-fe0f.svg",
        "alt": "👷🏾‍♂️",
        "title": "man construction worker"
    },
    ":man_construction_worker_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3ff-200d-2642-fe0f.svg",
        "alt": "👷🏿‍♂️",
        "title": "man construction worker"
    },
    ":woman_construction_worker:": {
        "src": "/images/emojis/unicode/svg/1f477-200d-2640-fe0f.svg",
        "alt": "👷‍♀️",
        "title": "woman construction worker"
    },
    ":woman_construction_worker_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3fb-200d-2640-fe0f.svg",
        "alt": "👷🏻‍♀️",
        "title": "woman construction worker"
    },
    ":woman_construction_worker_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3fc-200d-2640-fe0f.svg",
        "alt": "👷🏼‍♀️",
        "title": "woman construction worker"
    },
    ":woman_construction_worker_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3fd-200d-2640-fe0f.svg",
        "alt": "👷🏽‍♀️",
        "title": "woman construction worker"
    },
    ":woman_construction_worker_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3fe-200d-2640-fe0f.svg",
        "alt": "👷🏾‍♀️",
        "title": "woman construction worker"
    },
    ":woman_construction_worker_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f477-1f3ff-200d-2640-fe0f.svg",
        "alt": "👷🏿‍♀️",
        "title": "woman construction worker"
    },
    ":prince:": {
        "src": "/images/emojis/unicode/svg/1f934.svg",
        "alt": "🤴",
        "title": "prince"
    },
    ":prince_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f934-1f3fb.svg",
        "alt": "🤴🏻",
        "title": "prince"
    },
    ":prince_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f934-1f3fc.svg",
        "alt": "🤴🏼",
        "title": "prince"
    },
    ":prince_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f934-1f3fd.svg",
        "alt": "🤴🏽",
        "title": "prince"
    },
    ":prince_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f934-1f3fe.svg",
        "alt": "🤴🏾",
        "title": "prince"
    },
    ":prince_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f934-1f3ff.svg",
        "alt": "🤴🏿",
        "title": "prince"
    },
    ":princess:": {
        "src": "/images/emojis/unicode/svg/1f478.svg",
        "alt": "👸",
        "title": "princess"
    },
    ":princess_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f478-1f3fb.svg",
        "alt": "👸🏻",
        "title": "princess"
    },
    ":princess_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f478-1f3fc.svg",
        "alt": "👸🏼",
        "title": "princess"
    },
    ":princess_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f478-1f3fd.svg",
        "alt": "👸🏽",
        "title": "princess"
    },
    ":princess_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f478-1f3fe.svg",
        "alt": "👸🏾",
        "title": "princess"
    },
    ":princess_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f478-1f3ff.svg",
        "alt": "👸🏿",
        "title": "princess"
    },
    ":person_wearing_turban:": {
        "src": "/images/emojis/unicode/svg/1f473.svg",
        "alt": "👳",
        "title": "person wearing turban"
    },
    ":person_wearing_turban_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3fb.svg",
        "alt": "👳🏻",
        "title": "person wearing turban"
    },
    ":person_wearing_turban_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3fc.svg",
        "alt": "👳🏼",
        "title": "person wearing turban"
    },
    ":person_wearing_turban_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3fd.svg",
        "alt": "👳🏽",
        "title": "person wearing turban"
    },
    ":person_wearing_turban_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3fe.svg",
        "alt": "👳🏾",
        "title": "person wearing turban"
    },
    ":person_wearing_turban_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3ff.svg",
        "alt": "👳🏿",
        "title": "person wearing turban"
    },
    ":man_wearing_turban:": {
        "src": "/images/emojis/unicode/svg/1f473-200d-2642-fe0f.svg",
        "alt": "👳‍♂️",
        "title": "man wearing turban"
    },
    ":man_wearing_turban_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3fb-200d-2642-fe0f.svg",
        "alt": "👳🏻‍♂️",
        "title": "man wearing turban"
    },
    ":man_wearing_turban_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3fc-200d-2642-fe0f.svg",
        "alt": "👳🏼‍♂️",
        "title": "man wearing turban"
    },
    ":man_wearing_turban_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3fd-200d-2642-fe0f.svg",
        "alt": "👳🏽‍♂️",
        "title": "man wearing turban"
    },
    ":man_wearing_turban_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3fe-200d-2642-fe0f.svg",
        "alt": "👳🏾‍♂️",
        "title": "man wearing turban"
    },
    ":man_wearing_turban_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3ff-200d-2642-fe0f.svg",
        "alt": "👳🏿‍♂️",
        "title": "man wearing turban"
    },
    ":woman_wearing_turban:": {
        "src": "/images/emojis/unicode/svg/1f473-200d-2640-fe0f.svg",
        "alt": "👳‍♀️",
        "title": "woman wearing turban"
    },
    ":woman_wearing_turban_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3fb-200d-2640-fe0f.svg",
        "alt": "👳🏻‍♀️",
        "title": "woman wearing turban"
    },
    ":woman_wearing_turban_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3fc-200d-2640-fe0f.svg",
        "alt": "👳🏼‍♀️",
        "title": "woman wearing turban"
    },
    ":woman_wearing_turban_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3fd-200d-2640-fe0f.svg",
        "alt": "👳🏽‍♀️",
        "title": "woman wearing turban"
    },
    ":woman_wearing_turban_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3fe-200d-2640-fe0f.svg",
        "alt": "👳🏾‍♀️",
        "title": "woman wearing turban"
    },
    ":woman_wearing_turban_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f473-1f3ff-200d-2640-fe0f.svg",
        "alt": "👳🏿‍♀️",
        "title": "woman wearing turban"
    },
    ":person_with_skullcap:": {
        "src": "/images/emojis/unicode/svg/1f472.svg",
        "alt": "👲",
        "title": "person with skullcap"
    },
    ":person_with_skullcap_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f472-1f3fb.svg",
        "alt": "👲🏻",
        "title": "person with skullcap"
    },
    ":person_with_skullcap_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f472-1f3fc.svg",
        "alt": "👲🏼",
        "title": "person with skullcap"
    },
    ":person_with_skullcap_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f472-1f3fd.svg",
        "alt": "👲🏽",
        "title": "person with skullcap"
    },
    ":person_with_skullcap_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f472-1f3fe.svg",
        "alt": "👲🏾",
        "title": "person with skullcap"
    },
    ":person_with_skullcap_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f472-1f3ff.svg",
        "alt": "👲🏿",
        "title": "person with skullcap"
    },
    ":woman_with_headscarf:": {
        "src": "/images/emojis/unicode/svg/1f9d5.svg",
        "alt": "🧕",
        "title": "woman with headscarf"
    },
    ":woman_with_headscarf_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d5-1f3fb.svg",
        "alt": "🧕🏻",
        "title": "woman with headscarf"
    },
    ":woman_with_headscarf_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d5-1f3fc.svg",
        "alt": "🧕🏼",
        "title": "woman with headscarf"
    },
    ":woman_with_headscarf_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d5-1f3fd.svg",
        "alt": "🧕🏽",
        "title": "woman with headscarf"
    },
    ":woman_with_headscarf_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d5-1f3fe.svg",
        "alt": "🧕🏾",
        "title": "woman with headscarf"
    },
    ":woman_with_headscarf_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d5-1f3ff.svg",
        "alt": "🧕🏿",
        "title": "woman with headscarf"
    },
    ":person_in_tuxedo:": {
        "src": "/images/emojis/unicode/svg/1f935.svg",
        "alt": "🤵",
        "title": "person in tuxedo"
    },
    ":person_in_tuxedo_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3fb.svg",
        "alt": "🤵🏻",
        "title": "person in tuxedo"
    },
    ":person_in_tuxedo_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3fc.svg",
        "alt": "🤵🏼",
        "title": "person in tuxedo"
    },
    ":person_in_tuxedo_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3fd.svg",
        "alt": "🤵🏽",
        "title": "person in tuxedo"
    },
    ":person_in_tuxedo_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3fe.svg",
        "alt": "🤵🏾",
        "title": "person in tuxedo"
    },
    ":person_in_tuxedo_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3ff.svg",
        "alt": "🤵🏿",
        "title": "person in tuxedo"
    },
    ":man_in_tuxedo:": {
        "src": "/images/emojis/unicode/svg/1f935-200d-2642-fe0f.svg",
        "alt": "🤵‍♂️",
        "title": "man in tuxedo"
    },
    ":man_in_tuxedo_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3fb-200d-2642-fe0f.svg",
        "alt": "🤵🏻‍♂️",
        "title": "man in tuxedo"
    },
    ":man_in_tuxedo_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3fc-200d-2642-fe0f.svg",
        "alt": "🤵🏼‍♂️",
        "title": "man in tuxedo"
    },
    ":man_in_tuxedo_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3fd-200d-2642-fe0f.svg",
        "alt": "🤵🏽‍♂️",
        "title": "man in tuxedo"
    },
    ":man_in_tuxedo_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3fe-200d-2642-fe0f.svg",
        "alt": "🤵🏾‍♂️",
        "title": "man in tuxedo"
    },
    ":man_in_tuxedo_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3ff-200d-2642-fe0f.svg",
        "alt": "🤵🏿‍♂️",
        "title": "man in tuxedo"
    },
    ":woman_in_tuxedo:": {
        "src": "/images/emojis/unicode/svg/1f935-200d-2640-fe0f.svg",
        "alt": "🤵‍♀️",
        "title": "woman in tuxedo"
    },
    ":woman_in_tuxedo_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3fb-200d-2640-fe0f.svg",
        "alt": "🤵🏻‍♀️",
        "title": "woman in tuxedo"
    },
    ":woman_in_tuxedo_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3fc-200d-2640-fe0f.svg",
        "alt": "🤵🏼‍♀️",
        "title": "woman in tuxedo"
    },
    ":woman_in_tuxedo_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3fd-200d-2640-fe0f.svg",
        "alt": "🤵🏽‍♀️",
        "title": "woman in tuxedo"
    },
    ":woman_in_tuxedo_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3fe-200d-2640-fe0f.svg",
        "alt": "🤵🏾‍♀️",
        "title": "woman in tuxedo"
    },
    ":woman_in_tuxedo_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f935-1f3ff-200d-2640-fe0f.svg",
        "alt": "🤵🏿‍♀️",
        "title": "woman in tuxedo"
    },
    ":person_with_veil:": {
        "src": "/images/emojis/unicode/svg/1f470.svg",
        "alt": "👰",
        "title": "person with veil"
    },
    ":person_with_veil_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3fb.svg",
        "alt": "👰🏻",
        "title": "person with veil"
    },
    ":person_with_veil_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3fc.svg",
        "alt": "👰🏼",
        "title": "person with veil"
    },
    ":person_with_veil_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3fd.svg",
        "alt": "👰🏽",
        "title": "person with veil"
    },
    ":person_with_veil_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3fe.svg",
        "alt": "👰🏾",
        "title": "person with veil"
    },
    ":person_with_veil_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3ff.svg",
        "alt": "👰🏿",
        "title": "person with veil"
    },
    ":man_with_veil:": {
        "src": "/images/emojis/unicode/svg/1f470-200d-2642-fe0f.svg",
        "alt": "👰‍♂️",
        "title": "man with veil"
    },
    ":man_with_veil_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3fb-200d-2642-fe0f.svg",
        "alt": "👰🏻‍♂️",
        "title": "man with veil"
    },
    ":man_with_veil_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3fc-200d-2642-fe0f.svg",
        "alt": "👰🏼‍♂️",
        "title": "man with veil"
    },
    ":man_with_veil_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3fd-200d-2642-fe0f.svg",
        "alt": "👰🏽‍♂️",
        "title": "man with veil"
    },
    ":man_with_veil_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3fe-200d-2642-fe0f.svg",
        "alt": "👰🏾‍♂️",
        "title": "man with veil"
    },
    ":man_with_veil_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3ff-200d-2642-fe0f.svg",
        "alt": "👰🏿‍♂️",
        "title": "man with veil"
    },
    ":woman_with_veil:": {
        "src": "/images/emojis/unicode/svg/1f470-200d-2640-fe0f.svg",
        "alt": "👰‍♀️",
        "title": "woman with veil"
    },
    ":woman_with_veil_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3fb-200d-2640-fe0f.svg",
        "alt": "👰🏻‍♀️",
        "title": "woman with veil"
    },
    ":woman_with_veil_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3fc-200d-2640-fe0f.svg",
        "alt": "👰🏼‍♀️",
        "title": "woman with veil"
    },
    ":woman_with_veil_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3fd-200d-2640-fe0f.svg",
        "alt": "👰🏽‍♀️",
        "title": "woman with veil"
    },
    ":woman_with_veil_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3fe-200d-2640-fe0f.svg",
        "alt": "👰🏾‍♀️",
        "title": "woman with veil"
    },
    ":woman_with_veil_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f470-1f3ff-200d-2640-fe0f.svg",
        "alt": "👰🏿‍♀️",
        "title": "woman with veil"
    },
    ":pregnant_woman:": {
        "src": "/images/emojis/unicode/svg/1f930.svg",
        "alt": "🤰",
        "title": "pregnant woman"
    },
    ":pregnant_woman_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f930-1f3fb.svg",
        "alt": "🤰🏻",
        "title": "pregnant woman"
    },
    ":pregnant_woman_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f930-1f3fc.svg",
        "alt": "🤰🏼",
        "title": "pregnant woman"
    },
    ":pregnant_woman_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f930-1f3fd.svg",
        "alt": "🤰🏽",
        "title": "pregnant woman"
    },
    ":pregnant_woman_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f930-1f3fe.svg",
        "alt": "🤰🏾",
        "title": "pregnant woman"
    },
    ":pregnant_woman_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f930-1f3ff.svg",
        "alt": "🤰🏿",
        "title": "pregnant woman"
    },
    ":breast_feeding:": {
        "src": "/images/emojis/unicode/svg/1f931.svg",
        "alt": "🤱",
        "title": "breast-feeding"
    },
    ":breast_feeding_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f931-1f3fb.svg",
        "alt": "🤱🏻",
        "title": "breast-feeding"
    },
    ":breast_feeding_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f931-1f3fc.svg",
        "alt": "🤱🏼",
        "title": "breast-feeding"
    },
    ":breast_feeding_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f931-1f3fd.svg",
        "alt": "🤱🏽",
        "title": "breast-feeding"
    },
    ":breast_feeding_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f931-1f3fe.svg",
        "alt": "🤱🏾",
        "title": "breast-feeding"
    },
    ":breast_feeding_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f931-1f3ff.svg",
        "alt": "🤱🏿",
        "title": "breast-feeding"
    },
    ":woman_feeding_baby:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f37c.svg",
        "alt": "👩‍🍼",
        "title": "woman feeding baby"
    },
    ":woman_feeding_baby_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f37c.svg",
        "alt": "👩🏻‍🍼",
        "title": "woman feeding baby"
    },
    ":woman_feeding_baby_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f37c.svg",
        "alt": "👩🏼‍🍼",
        "title": "woman feeding baby"
    },
    ":woman_feeding_baby_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f37c.svg",
        "alt": "👩🏽‍🍼",
        "title": "woman feeding baby"
    },
    ":woman_feeding_baby_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f37c.svg",
        "alt": "👩🏾‍🍼",
        "title": "woman feeding baby"
    },
    ":woman_feeding_baby_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f37c.svg",
        "alt": "👩🏿‍🍼",
        "title": "woman feeding baby"
    },
    ":man_feeding_baby:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f37c.svg",
        "alt": "👨‍🍼",
        "title": "man feeding baby"
    },
    ":man_feeding_baby_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f37c.svg",
        "alt": "👨🏻‍🍼",
        "title": "man feeding baby"
    },
    ":man_feeding_baby_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f37c.svg",
        "alt": "👨🏼‍🍼",
        "title": "man feeding baby"
    },
    ":man_feeding_baby_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f37c.svg",
        "alt": "👨🏽‍🍼",
        "title": "man feeding baby"
    },
    ":man_feeding_baby_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f37c.svg",
        "alt": "👨🏾‍🍼",
        "title": "man feeding baby"
    },
    ":man_feeding_baby_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f37c.svg",
        "alt": "👨🏿‍🍼",
        "title": "man feeding baby"
    },
    ":person_feeding_baby:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f37c.svg",
        "alt": "🧑‍🍼",
        "title": "person feeding baby"
    },
    ":person_feeding_baby_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f37c.svg",
        "alt": "🧑🏻‍🍼",
        "title": "person feeding baby"
    },
    ":person_feeding_baby_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f37c.svg",
        "alt": "🧑🏼‍🍼",
        "title": "person feeding baby"
    },
    ":person_feeding_baby_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f37c.svg",
        "alt": "🧑🏽‍🍼",
        "title": "person feeding baby"
    },
    ":person_feeding_baby_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f37c.svg",
        "alt": "🧑🏾‍🍼",
        "title": "person feeding baby"
    },
    ":person_feeding_baby_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f37c.svg",
        "alt": "🧑🏿‍🍼",
        "title": "person feeding baby"
    },
    ":baby_angel:": {
        "src": "/images/emojis/unicode/svg/1f47c.svg",
        "alt": "👼",
        "title": "baby angel"
    },
    ":baby_angel_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f47c-1f3fb.svg",
        "alt": "👼🏻",
        "title": "baby angel"
    },
    ":baby_angel_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f47c-1f3fc.svg",
        "alt": "👼🏼",
        "title": "baby angel"
    },
    ":baby_angel_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f47c-1f3fd.svg",
        "alt": "👼🏽",
        "title": "baby angel"
    },
    ":baby_angel_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f47c-1f3fe.svg",
        "alt": "👼🏾",
        "title": "baby angel"
    },
    ":baby_angel_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f47c-1f3ff.svg",
        "alt": "👼🏿",
        "title": "baby angel"
    },
    ":santa_claus:": {
        "src": "/images/emojis/unicode/svg/1f385.svg",
        "alt": "🎅",
        "title": "Santa Claus"
    },
    ":santa_claus_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f385-1f3fb.svg",
        "alt": "🎅🏻",
        "title": "Santa Claus"
    },
    ":santa_claus_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f385-1f3fc.svg",
        "alt": "🎅🏼",
        "title": "Santa Claus"
    },
    ":santa_claus_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f385-1f3fd.svg",
        "alt": "🎅🏽",
        "title": "Santa Claus"
    },
    ":santa_claus_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f385-1f3fe.svg",
        "alt": "🎅🏾",
        "title": "Santa Claus"
    },
    ":santa_claus_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f385-1f3ff.svg",
        "alt": "🎅🏿",
        "title": "Santa Claus"
    },
    ":mrs_claus:": {
        "src": "/images/emojis/unicode/svg/1f936.svg",
        "alt": "🤶",
        "title": "Mrs. Claus"
    },
    ":mrs_claus_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f936-1f3fb.svg",
        "alt": "🤶🏻",
        "title": "Mrs. Claus"
    },
    ":mrs_claus_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f936-1f3fc.svg",
        "alt": "🤶🏼",
        "title": "Mrs. Claus"
    },
    ":mrs_claus_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f936-1f3fd.svg",
        "alt": "🤶🏽",
        "title": "Mrs. Claus"
    },
    ":mrs_claus_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f936-1f3fe.svg",
        "alt": "🤶🏾",
        "title": "Mrs. Claus"
    },
    ":mrs_claus_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f936-1f3ff.svg",
        "alt": "🤶🏿",
        "title": "Mrs. Claus"
    },
    ":mx_claus:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f384.svg",
        "alt": "🧑‍🎄",
        "title": "mx claus"
    },
    ":mx_claus_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f384.svg",
        "alt": "🧑🏻‍🎄",
        "title": "mx claus"
    },
    ":mx_claus_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f384.svg",
        "alt": "🧑🏼‍🎄",
        "title": "mx claus"
    },
    ":mx_claus_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f384.svg",
        "alt": "🧑🏽‍🎄",
        "title": "mx claus"
    },
    ":mx_claus_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f384.svg",
        "alt": "🧑🏾‍🎄",
        "title": "mx claus"
    },
    ":mx_claus_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f384.svg",
        "alt": "🧑🏿‍🎄",
        "title": "mx claus"
    },
    ":superhero:": {
        "src": "/images/emojis/unicode/svg/1f9b8.svg",
        "alt": "🦸",
        "title": "superhero"
    },
    ":superhero_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3fb.svg",
        "alt": "🦸🏻",
        "title": "superhero"
    },
    ":superhero_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3fc.svg",
        "alt": "🦸🏼",
        "title": "superhero"
    },
    ":superhero_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3fd.svg",
        "alt": "🦸🏽",
        "title": "superhero"
    },
    ":superhero_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3fe.svg",
        "alt": "🦸🏾",
        "title": "superhero"
    },
    ":superhero_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3ff.svg",
        "alt": "🦸🏿",
        "title": "superhero"
    },
    ":man_superhero:": {
        "src": "/images/emojis/unicode/svg/1f9b8-200d-2642-fe0f.svg",
        "alt": "🦸‍♂️",
        "title": "man superhero"
    },
    ":man_superhero_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3fb-200d-2642-fe0f.svg",
        "alt": "🦸🏻‍♂️",
        "title": "man superhero"
    },
    ":man_superhero_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3fc-200d-2642-fe0f.svg",
        "alt": "🦸🏼‍♂️",
        "title": "man superhero"
    },
    ":man_superhero_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3fd-200d-2642-fe0f.svg",
        "alt": "🦸🏽‍♂️",
        "title": "man superhero"
    },
    ":man_superhero_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3fe-200d-2642-fe0f.svg",
        "alt": "🦸🏾‍♂️",
        "title": "man superhero"
    },
    ":man_superhero_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3ff-200d-2642-fe0f.svg",
        "alt": "🦸🏿‍♂️",
        "title": "man superhero"
    },
    ":woman_superhero:": {
        "src": "/images/emojis/unicode/svg/1f9b8-200d-2640-fe0f.svg",
        "alt": "🦸‍♀️",
        "title": "woman superhero"
    },
    ":woman_superhero_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3fb-200d-2640-fe0f.svg",
        "alt": "🦸🏻‍♀️",
        "title": "woman superhero"
    },
    ":woman_superhero_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3fc-200d-2640-fe0f.svg",
        "alt": "🦸🏼‍♀️",
        "title": "woman superhero"
    },
    ":woman_superhero_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3fd-200d-2640-fe0f.svg",
        "alt": "🦸🏽‍♀️",
        "title": "woman superhero"
    },
    ":woman_superhero_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3fe-200d-2640-fe0f.svg",
        "alt": "🦸🏾‍♀️",
        "title": "woman superhero"
    },
    ":woman_superhero_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b8-1f3ff-200d-2640-fe0f.svg",
        "alt": "🦸🏿‍♀️",
        "title": "woman superhero"
    },
    ":supervillain:": {
        "src": "/images/emojis/unicode/svg/1f9b9.svg",
        "alt": "🦹",
        "title": "supervillain"
    },
    ":supervillain_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3fb.svg",
        "alt": "🦹🏻",
        "title": "supervillain"
    },
    ":supervillain_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3fc.svg",
        "alt": "🦹🏼",
        "title": "supervillain"
    },
    ":supervillain_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3fd.svg",
        "alt": "🦹🏽",
        "title": "supervillain"
    },
    ":supervillain_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3fe.svg",
        "alt": "🦹🏾",
        "title": "supervillain"
    },
    ":supervillain_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3ff.svg",
        "alt": "🦹🏿",
        "title": "supervillain"
    },
    ":man_supervillain:": {
        "src": "/images/emojis/unicode/svg/1f9b9-200d-2642-fe0f.svg",
        "alt": "🦹‍♂️",
        "title": "man supervillain"
    },
    ":man_supervillain_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3fb-200d-2642-fe0f.svg",
        "alt": "🦹🏻‍♂️",
        "title": "man supervillain"
    },
    ":man_supervillain_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3fc-200d-2642-fe0f.svg",
        "alt": "🦹🏼‍♂️",
        "title": "man supervillain"
    },
    ":man_supervillain_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3fd-200d-2642-fe0f.svg",
        "alt": "🦹🏽‍♂️",
        "title": "man supervillain"
    },
    ":man_supervillain_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3fe-200d-2642-fe0f.svg",
        "alt": "🦹🏾‍♂️",
        "title": "man supervillain"
    },
    ":man_supervillain_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3ff-200d-2642-fe0f.svg",
        "alt": "🦹🏿‍♂️",
        "title": "man supervillain"
    },
    ":woman_supervillain:": {
        "src": "/images/emojis/unicode/svg/1f9b9-200d-2640-fe0f.svg",
        "alt": "🦹‍♀️",
        "title": "woman supervillain"
    },
    ":woman_supervillain_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3fb-200d-2640-fe0f.svg",
        "alt": "🦹🏻‍♀️",
        "title": "woman supervillain"
    },
    ":woman_supervillain_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3fc-200d-2640-fe0f.svg",
        "alt": "🦹🏼‍♀️",
        "title": "woman supervillain"
    },
    ":woman_supervillain_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3fd-200d-2640-fe0f.svg",
        "alt": "🦹🏽‍♀️",
        "title": "woman supervillain"
    },
    ":woman_supervillain_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3fe-200d-2640-fe0f.svg",
        "alt": "🦹🏾‍♀️",
        "title": "woman supervillain"
    },
    ":woman_supervillain_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9b9-1f3ff-200d-2640-fe0f.svg",
        "alt": "🦹🏿‍♀️",
        "title": "woman supervillain"
    },
    ":mage:": {
        "src": "/images/emojis/unicode/svg/1f9d9.svg",
        "alt": "🧙",
        "title": "mage"
    },
    ":mage_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3fb.svg",
        "alt": "🧙🏻",
        "title": "mage"
    },
    ":mage_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3fc.svg",
        "alt": "🧙🏼",
        "title": "mage"
    },
    ":mage_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3fd.svg",
        "alt": "🧙🏽",
        "title": "mage"
    },
    ":mage_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3fe.svg",
        "alt": "🧙🏾",
        "title": "mage"
    },
    ":mage_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3ff.svg",
        "alt": "🧙🏿",
        "title": "mage"
    },
    ":man_mage:": {
        "src": "/images/emojis/unicode/svg/1f9d9-200d-2642-fe0f.svg",
        "alt": "🧙‍♂️",
        "title": "man mage"
    },
    ":man_mage_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3fb-200d-2642-fe0f.svg",
        "alt": "🧙🏻‍♂️",
        "title": "man mage"
    },
    ":man_mage_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3fc-200d-2642-fe0f.svg",
        "alt": "🧙🏼‍♂️",
        "title": "man mage"
    },
    ":man_mage_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3fd-200d-2642-fe0f.svg",
        "alt": "🧙🏽‍♂️",
        "title": "man mage"
    },
    ":man_mage_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3fe-200d-2642-fe0f.svg",
        "alt": "🧙🏾‍♂️",
        "title": "man mage"
    },
    ":man_mage_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3ff-200d-2642-fe0f.svg",
        "alt": "🧙🏿‍♂️",
        "title": "man mage"
    },
    ":woman_mage:": {
        "src": "/images/emojis/unicode/svg/1f9d9-200d-2640-fe0f.svg",
        "alt": "🧙‍♀️",
        "title": "woman mage"
    },
    ":woman_mage_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3fb-200d-2640-fe0f.svg",
        "alt": "🧙🏻‍♀️",
        "title": "woman mage"
    },
    ":woman_mage_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3fc-200d-2640-fe0f.svg",
        "alt": "🧙🏼‍♀️",
        "title": "woman mage"
    },
    ":woman_mage_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3fd-200d-2640-fe0f.svg",
        "alt": "🧙🏽‍♀️",
        "title": "woman mage"
    },
    ":woman_mage_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3fe-200d-2640-fe0f.svg",
        "alt": "🧙🏾‍♀️",
        "title": "woman mage"
    },
    ":woman_mage_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d9-1f3ff-200d-2640-fe0f.svg",
        "alt": "🧙🏿‍♀️",
        "title": "woman mage"
    },
    ":fairy:": {
        "src": "/images/emojis/unicode/svg/1f9da.svg",
        "alt": "🧚",
        "title": "fairy"
    },
    ":fairy_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3fb.svg",
        "alt": "🧚🏻",
        "title": "fairy"
    },
    ":fairy_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3fc.svg",
        "alt": "🧚🏼",
        "title": "fairy"
    },
    ":fairy_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3fd.svg",
        "alt": "🧚🏽",
        "title": "fairy"
    },
    ":fairy_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3fe.svg",
        "alt": "🧚🏾",
        "title": "fairy"
    },
    ":fairy_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3ff.svg",
        "alt": "🧚🏿",
        "title": "fairy"
    },
    ":man_fairy:": {
        "src": "/images/emojis/unicode/svg/1f9da-200d-2642-fe0f.svg",
        "alt": "🧚‍♂️",
        "title": "man fairy"
    },
    ":man_fairy_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3fb-200d-2642-fe0f.svg",
        "alt": "🧚🏻‍♂️",
        "title": "man fairy"
    },
    ":man_fairy_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3fc-200d-2642-fe0f.svg",
        "alt": "🧚🏼‍♂️",
        "title": "man fairy"
    },
    ":man_fairy_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3fd-200d-2642-fe0f.svg",
        "alt": "🧚🏽‍♂️",
        "title": "man fairy"
    },
    ":man_fairy_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3fe-200d-2642-fe0f.svg",
        "alt": "🧚🏾‍♂️",
        "title": "man fairy"
    },
    ":man_fairy_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3ff-200d-2642-fe0f.svg",
        "alt": "🧚🏿‍♂️",
        "title": "man fairy"
    },
    ":woman_fairy:": {
        "src": "/images/emojis/unicode/svg/1f9da-200d-2640-fe0f.svg",
        "alt": "🧚‍♀️",
        "title": "woman fairy"
    },
    ":woman_fairy_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3fb-200d-2640-fe0f.svg",
        "alt": "🧚🏻‍♀️",
        "title": "woman fairy"
    },
    ":woman_fairy_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3fc-200d-2640-fe0f.svg",
        "alt": "🧚🏼‍♀️",
        "title": "woman fairy"
    },
    ":woman_fairy_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3fd-200d-2640-fe0f.svg",
        "alt": "🧚🏽‍♀️",
        "title": "woman fairy"
    },
    ":woman_fairy_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3fe-200d-2640-fe0f.svg",
        "alt": "🧚🏾‍♀️",
        "title": "woman fairy"
    },
    ":woman_fairy_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9da-1f3ff-200d-2640-fe0f.svg",
        "alt": "🧚🏿‍♀️",
        "title": "woman fairy"
    },
    ":vampire:": {
        "src": "/images/emojis/unicode/svg/1f9db.svg",
        "alt": "🧛",
        "title": "vampire"
    },
    ":vampire_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3fb.svg",
        "alt": "🧛🏻",
        "title": "vampire"
    },
    ":vampire_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3fc.svg",
        "alt": "🧛🏼",
        "title": "vampire"
    },
    ":vampire_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3fd.svg",
        "alt": "🧛🏽",
        "title": "vampire"
    },
    ":vampire_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3fe.svg",
        "alt": "🧛🏾",
        "title": "vampire"
    },
    ":vampire_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3ff.svg",
        "alt": "🧛🏿",
        "title": "vampire"
    },
    ":man_vampire:": {
        "src": "/images/emojis/unicode/svg/1f9db-200d-2642-fe0f.svg",
        "alt": "🧛‍♂️",
        "title": "man vampire"
    },
    ":man_vampire_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3fb-200d-2642-fe0f.svg",
        "alt": "🧛🏻‍♂️",
        "title": "man vampire"
    },
    ":man_vampire_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3fc-200d-2642-fe0f.svg",
        "alt": "🧛🏼‍♂️",
        "title": "man vampire"
    },
    ":man_vampire_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3fd-200d-2642-fe0f.svg",
        "alt": "🧛🏽‍♂️",
        "title": "man vampire"
    },
    ":man_vampire_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3fe-200d-2642-fe0f.svg",
        "alt": "🧛🏾‍♂️",
        "title": "man vampire"
    },
    ":man_vampire_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3ff-200d-2642-fe0f.svg",
        "alt": "🧛🏿‍♂️",
        "title": "man vampire"
    },
    ":woman_vampire:": {
        "src": "/images/emojis/unicode/svg/1f9db-200d-2640-fe0f.svg",
        "alt": "🧛‍♀️",
        "title": "woman vampire"
    },
    ":woman_vampire_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3fb-200d-2640-fe0f.svg",
        "alt": "🧛🏻‍♀️",
        "title": "woman vampire"
    },
    ":woman_vampire_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3fc-200d-2640-fe0f.svg",
        "alt": "🧛🏼‍♀️",
        "title": "woman vampire"
    },
    ":woman_vampire_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3fd-200d-2640-fe0f.svg",
        "alt": "🧛🏽‍♀️",
        "title": "woman vampire"
    },
    ":woman_vampire_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3fe-200d-2640-fe0f.svg",
        "alt": "🧛🏾‍♀️",
        "title": "woman vampire"
    },
    ":woman_vampire_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9db-1f3ff-200d-2640-fe0f.svg",
        "alt": "🧛🏿‍♀️",
        "title": "woman vampire"
    },
    ":merperson:": {
        "src": "/images/emojis/unicode/svg/1f9dc.svg",
        "alt": "🧜",
        "title": "merperson"
    },
    ":merperson_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3fb.svg",
        "alt": "🧜🏻",
        "title": "merperson"
    },
    ":merperson_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3fc.svg",
        "alt": "🧜🏼",
        "title": "merperson"
    },
    ":merperson_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3fd.svg",
        "alt": "🧜🏽",
        "title": "merperson"
    },
    ":merperson_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3fe.svg",
        "alt": "🧜🏾",
        "title": "merperson"
    },
    ":merperson_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3ff.svg",
        "alt": "🧜🏿",
        "title": "merperson"
    },
    ":merman:": {
        "src": "/images/emojis/unicode/svg/1f9dc-200d-2642-fe0f.svg",
        "alt": "🧜‍♂️",
        "title": "merman"
    },
    ":merman_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3fb-200d-2642-fe0f.svg",
        "alt": "🧜🏻‍♂️",
        "title": "merman"
    },
    ":merman_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3fc-200d-2642-fe0f.svg",
        "alt": "🧜🏼‍♂️",
        "title": "merman"
    },
    ":merman_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3fd-200d-2642-fe0f.svg",
        "alt": "🧜🏽‍♂️",
        "title": "merman"
    },
    ":merman_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3fe-200d-2642-fe0f.svg",
        "alt": "🧜🏾‍♂️",
        "title": "merman"
    },
    ":merman_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3ff-200d-2642-fe0f.svg",
        "alt": "🧜🏿‍♂️",
        "title": "merman"
    },
    ":mermaid:": {
        "src": "/images/emojis/unicode/svg/1f9dc-200d-2640-fe0f.svg",
        "alt": "🧜‍♀️",
        "title": "mermaid"
    },
    ":mermaid_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3fb-200d-2640-fe0f.svg",
        "alt": "🧜🏻‍♀️",
        "title": "mermaid"
    },
    ":mermaid_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3fc-200d-2640-fe0f.svg",
        "alt": "🧜🏼‍♀️",
        "title": "mermaid"
    },
    ":mermaid_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3fd-200d-2640-fe0f.svg",
        "alt": "🧜🏽‍♀️",
        "title": "mermaid"
    },
    ":mermaid_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3fe-200d-2640-fe0f.svg",
        "alt": "🧜🏾‍♀️",
        "title": "mermaid"
    },
    ":mermaid_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dc-1f3ff-200d-2640-fe0f.svg",
        "alt": "🧜🏿‍♀️",
        "title": "mermaid"
    },
    ":elf:": {
        "src": "/images/emojis/unicode/svg/1f9dd.svg",
        "alt": "🧝",
        "title": "elf"
    },
    ":elf_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3fb.svg",
        "alt": "🧝🏻",
        "title": "elf"
    },
    ":elf_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3fc.svg",
        "alt": "🧝🏼",
        "title": "elf"
    },
    ":elf_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3fd.svg",
        "alt": "🧝🏽",
        "title": "elf"
    },
    ":elf_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3fe.svg",
        "alt": "🧝🏾",
        "title": "elf"
    },
    ":elf_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3ff.svg",
        "alt": "🧝🏿",
        "title": "elf"
    },
    ":man_elf:": {
        "src": "/images/emojis/unicode/svg/1f9dd-200d-2642-fe0f.svg",
        "alt": "🧝‍♂️",
        "title": "man elf"
    },
    ":man_elf_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3fb-200d-2642-fe0f.svg",
        "alt": "🧝🏻‍♂️",
        "title": "man elf"
    },
    ":man_elf_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3fc-200d-2642-fe0f.svg",
        "alt": "🧝🏼‍♂️",
        "title": "man elf"
    },
    ":man_elf_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3fd-200d-2642-fe0f.svg",
        "alt": "🧝🏽‍♂️",
        "title": "man elf"
    },
    ":man_elf_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3fe-200d-2642-fe0f.svg",
        "alt": "🧝🏾‍♂️",
        "title": "man elf"
    },
    ":man_elf_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3ff-200d-2642-fe0f.svg",
        "alt": "🧝🏿‍♂️",
        "title": "man elf"
    },
    ":woman_elf:": {
        "src": "/images/emojis/unicode/svg/1f9dd-200d-2640-fe0f.svg",
        "alt": "🧝‍♀️",
        "title": "woman elf"
    },
    ":woman_elf_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3fb-200d-2640-fe0f.svg",
        "alt": "🧝🏻‍♀️",
        "title": "woman elf"
    },
    ":woman_elf_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3fc-200d-2640-fe0f.svg",
        "alt": "🧝🏼‍♀️",
        "title": "woman elf"
    },
    ":woman_elf_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3fd-200d-2640-fe0f.svg",
        "alt": "🧝🏽‍♀️",
        "title": "woman elf"
    },
    ":woman_elf_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3fe-200d-2640-fe0f.svg",
        "alt": "🧝🏾‍♀️",
        "title": "woman elf"
    },
    ":woman_elf_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9dd-1f3ff-200d-2640-fe0f.svg",
        "alt": "🧝🏿‍♀️",
        "title": "woman elf"
    },
    ":genie:": {
        "src": "/images/emojis/unicode/svg/1f9de.svg",
        "alt": "🧞",
        "title": "genie"
    },
    ":man_genie:": {
        "src": "/images/emojis/unicode/svg/1f9de-200d-2642-fe0f.svg",
        "alt": "🧞‍♂️",
        "title": "man genie"
    },
    ":woman_genie:": {
        "src": "/images/emojis/unicode/svg/1f9de-200d-2640-fe0f.svg",
        "alt": "🧞‍♀️",
        "title": "woman genie"
    },
    ":zombie:": {
        "src": "/images/emojis/unicode/svg/1f9df.svg",
        "alt": "🧟",
        "title": "zombie"
    },
    ":man_zombie:": {
        "src": "/images/emojis/unicode/svg/1f9df-200d-2642-fe0f.svg",
        "alt": "🧟‍♂️",
        "title": "man zombie"
    },
    ":woman_zombie:": {
        "src": "/images/emojis/unicode/svg/1f9df-200d-2640-fe0f.svg",
        "alt": "🧟‍♀️",
        "title": "woman zombie"
    },
    ":person_getting_massage:": {
        "src": "/images/emojis/unicode/svg/1f486.svg",
        "alt": "💆",
        "title": "person getting massage"
    },
    ":person_getting_massage_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3fb.svg",
        "alt": "💆🏻",
        "title": "person getting massage"
    },
    ":person_getting_massage_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3fc.svg",
        "alt": "💆🏼",
        "title": "person getting massage"
    },
    ":person_getting_massage_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3fd.svg",
        "alt": "💆🏽",
        "title": "person getting massage"
    },
    ":person_getting_massage_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3fe.svg",
        "alt": "💆🏾",
        "title": "person getting massage"
    },
    ":person_getting_massage_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3ff.svg",
        "alt": "💆🏿",
        "title": "person getting massage"
    },
    ":man_getting_massage:": {
        "src": "/images/emojis/unicode/svg/1f486-200d-2642-fe0f.svg",
        "alt": "💆‍♂️",
        "title": "man getting massage"
    },
    ":man_getting_massage_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3fb-200d-2642-fe0f.svg",
        "alt": "💆🏻‍♂️",
        "title": "man getting massage"
    },
    ":man_getting_massage_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3fc-200d-2642-fe0f.svg",
        "alt": "💆🏼‍♂️",
        "title": "man getting massage"
    },
    ":man_getting_massage_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3fd-200d-2642-fe0f.svg",
        "alt": "💆🏽‍♂️",
        "title": "man getting massage"
    },
    ":man_getting_massage_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3fe-200d-2642-fe0f.svg",
        "alt": "💆🏾‍♂️",
        "title": "man getting massage"
    },
    ":man_getting_massage_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3ff-200d-2642-fe0f.svg",
        "alt": "💆🏿‍♂️",
        "title": "man getting massage"
    },
    ":woman_getting_massage:": {
        "src": "/images/emojis/unicode/svg/1f486-200d-2640-fe0f.svg",
        "alt": "💆‍♀️",
        "title": "woman getting massage"
    },
    ":woman_getting_massage_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3fb-200d-2640-fe0f.svg",
        "alt": "💆🏻‍♀️",
        "title": "woman getting massage"
    },
    ":woman_getting_massage_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3fc-200d-2640-fe0f.svg",
        "alt": "💆🏼‍♀️",
        "title": "woman getting massage"
    },
    ":woman_getting_massage_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3fd-200d-2640-fe0f.svg",
        "alt": "💆🏽‍♀️",
        "title": "woman getting massage"
    },
    ":woman_getting_massage_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3fe-200d-2640-fe0f.svg",
        "alt": "💆🏾‍♀️",
        "title": "woman getting massage"
    },
    ":woman_getting_massage_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f486-1f3ff-200d-2640-fe0f.svg",
        "alt": "💆🏿‍♀️",
        "title": "woman getting massage"
    },
    ":person_getting_haircut:": {
        "src": "/images/emojis/unicode/svg/1f487.svg",
        "alt": "💇",
        "title": "person getting haircut"
    },
    ":person_getting_haircut_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3fb.svg",
        "alt": "💇🏻",
        "title": "person getting haircut"
    },
    ":person_getting_haircut_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3fc.svg",
        "alt": "💇🏼",
        "title": "person getting haircut"
    },
    ":person_getting_haircut_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3fd.svg",
        "alt": "💇🏽",
        "title": "person getting haircut"
    },
    ":person_getting_haircut_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3fe.svg",
        "alt": "💇🏾",
        "title": "person getting haircut"
    },
    ":person_getting_haircut_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3ff.svg",
        "alt": "💇🏿",
        "title": "person getting haircut"
    },
    ":man_getting_haircut:": {
        "src": "/images/emojis/unicode/svg/1f487-200d-2642-fe0f.svg",
        "alt": "💇‍♂️",
        "title": "man getting haircut"
    },
    ":man_getting_haircut_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3fb-200d-2642-fe0f.svg",
        "alt": "💇🏻‍♂️",
        "title": "man getting haircut"
    },
    ":man_getting_haircut_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3fc-200d-2642-fe0f.svg",
        "alt": "💇🏼‍♂️",
        "title": "man getting haircut"
    },
    ":man_getting_haircut_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3fd-200d-2642-fe0f.svg",
        "alt": "💇🏽‍♂️",
        "title": "man getting haircut"
    },
    ":man_getting_haircut_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3fe-200d-2642-fe0f.svg",
        "alt": "💇🏾‍♂️",
        "title": "man getting haircut"
    },
    ":man_getting_haircut_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3ff-200d-2642-fe0f.svg",
        "alt": "💇🏿‍♂️",
        "title": "man getting haircut"
    },
    ":woman_getting_haircut:": {
        "src": "/images/emojis/unicode/svg/1f487-200d-2640-fe0f.svg",
        "alt": "💇‍♀️",
        "title": "woman getting haircut"
    },
    ":woman_getting_haircut_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3fb-200d-2640-fe0f.svg",
        "alt": "💇🏻‍♀️",
        "title": "woman getting haircut"
    },
    ":woman_getting_haircut_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3fc-200d-2640-fe0f.svg",
        "alt": "💇🏼‍♀️",
        "title": "woman getting haircut"
    },
    ":woman_getting_haircut_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3fd-200d-2640-fe0f.svg",
        "alt": "💇🏽‍♀️",
        "title": "woman getting haircut"
    },
    ":woman_getting_haircut_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3fe-200d-2640-fe0f.svg",
        "alt": "💇🏾‍♀️",
        "title": "woman getting haircut"
    },
    ":woman_getting_haircut_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f487-1f3ff-200d-2640-fe0f.svg",
        "alt": "💇🏿‍♀️",
        "title": "woman getting haircut"
    },
    ":person_walking:": {
        "src": "/images/emojis/unicode/svg/1f6b6.svg",
        "alt": "🚶",
        "title": "person walking"
    },
    ":person_walking_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3fb.svg",
        "alt": "🚶🏻",
        "title": "person walking"
    },
    ":person_walking_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3fc.svg",
        "alt": "🚶🏼",
        "title": "person walking"
    },
    ":person_walking_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3fd.svg",
        "alt": "🚶🏽",
        "title": "person walking"
    },
    ":person_walking_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3fe.svg",
        "alt": "🚶🏾",
        "title": "person walking"
    },
    ":person_walking_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3ff.svg",
        "alt": "🚶🏿",
        "title": "person walking"
    },
    ":man_walking:": {
        "src": "/images/emojis/unicode/svg/1f6b6-200d-2642-fe0f.svg",
        "alt": "🚶‍♂️",
        "title": "man walking"
    },
    ":man_walking_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3fb-200d-2642-fe0f.svg",
        "alt": "🚶🏻‍♂️",
        "title": "man walking"
    },
    ":man_walking_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3fc-200d-2642-fe0f.svg",
        "alt": "🚶🏼‍♂️",
        "title": "man walking"
    },
    ":man_walking_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3fd-200d-2642-fe0f.svg",
        "alt": "🚶🏽‍♂️",
        "title": "man walking"
    },
    ":man_walking_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3fe-200d-2642-fe0f.svg",
        "alt": "🚶🏾‍♂️",
        "title": "man walking"
    },
    ":man_walking_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3ff-200d-2642-fe0f.svg",
        "alt": "🚶🏿‍♂️",
        "title": "man walking"
    },
    ":woman_walking:": {
        "src": "/images/emojis/unicode/svg/1f6b6-200d-2640-fe0f.svg",
        "alt": "🚶‍♀️",
        "title": "woman walking"
    },
    ":woman_walking_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3fb-200d-2640-fe0f.svg",
        "alt": "🚶🏻‍♀️",
        "title": "woman walking"
    },
    ":woman_walking_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3fc-200d-2640-fe0f.svg",
        "alt": "🚶🏼‍♀️",
        "title": "woman walking"
    },
    ":woman_walking_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3fd-200d-2640-fe0f.svg",
        "alt": "🚶🏽‍♀️",
        "title": "woman walking"
    },
    ":woman_walking_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3fe-200d-2640-fe0f.svg",
        "alt": "🚶🏾‍♀️",
        "title": "woman walking"
    },
    ":woman_walking_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b6-1f3ff-200d-2640-fe0f.svg",
        "alt": "🚶🏿‍♀️",
        "title": "woman walking"
    },
    ":person_standing:": {
        "src": "/images/emojis/unicode/svg/1f9cd.svg",
        "alt": "🧍",
        "title": "person standing"
    },
    ":person_standing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3fb.svg",
        "alt": "🧍🏻",
        "title": "person standing"
    },
    ":person_standing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3fc.svg",
        "alt": "🧍🏼",
        "title": "person standing"
    },
    ":person_standing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3fd.svg",
        "alt": "🧍🏽",
        "title": "person standing"
    },
    ":person_standing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3fe.svg",
        "alt": "🧍🏾",
        "title": "person standing"
    },
    ":person_standing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3ff.svg",
        "alt": "🧍🏿",
        "title": "person standing"
    },
    ":man_standing:": {
        "src": "/images/emojis/unicode/svg/1f9cd-200d-2642-fe0f.svg",
        "alt": "🧍‍♂️",
        "title": "man standing"
    },
    ":man_standing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3fb-200d-2642-fe0f.svg",
        "alt": "🧍🏻‍♂️",
        "title": "man standing"
    },
    ":man_standing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3fc-200d-2642-fe0f.svg",
        "alt": "🧍🏼‍♂️",
        "title": "man standing"
    },
    ":man_standing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3fd-200d-2642-fe0f.svg",
        "alt": "🧍🏽‍♂️",
        "title": "man standing"
    },
    ":man_standing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3fe-200d-2642-fe0f.svg",
        "alt": "🧍🏾‍♂️",
        "title": "man standing"
    },
    ":man_standing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3ff-200d-2642-fe0f.svg",
        "alt": "🧍🏿‍♂️",
        "title": "man standing"
    },
    ":woman_standing:": {
        "src": "/images/emojis/unicode/svg/1f9cd-200d-2640-fe0f.svg",
        "alt": "🧍‍♀️",
        "title": "woman standing"
    },
    ":woman_standing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3fb-200d-2640-fe0f.svg",
        "alt": "🧍🏻‍♀️",
        "title": "woman standing"
    },
    ":woman_standing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3fc-200d-2640-fe0f.svg",
        "alt": "🧍🏼‍♀️",
        "title": "woman standing"
    },
    ":woman_standing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3fd-200d-2640-fe0f.svg",
        "alt": "🧍🏽‍♀️",
        "title": "woman standing"
    },
    ":woman_standing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3fe-200d-2640-fe0f.svg",
        "alt": "🧍🏾‍♀️",
        "title": "woman standing"
    },
    ":woman_standing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9cd-1f3ff-200d-2640-fe0f.svg",
        "alt": "🧍🏿‍♀️",
        "title": "woman standing"
    },
    ":person_kneeling:": {
        "src": "/images/emojis/unicode/svg/1f9ce.svg",
        "alt": "🧎",
        "title": "person kneeling"
    },
    ":person_kneeling_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3fb.svg",
        "alt": "🧎🏻",
        "title": "person kneeling"
    },
    ":person_kneeling_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3fc.svg",
        "alt": "🧎🏼",
        "title": "person kneeling"
    },
    ":person_kneeling_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3fd.svg",
        "alt": "🧎🏽",
        "title": "person kneeling"
    },
    ":person_kneeling_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3fe.svg",
        "alt": "🧎🏾",
        "title": "person kneeling"
    },
    ":person_kneeling_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3ff.svg",
        "alt": "🧎🏿",
        "title": "person kneeling"
    },
    ":man_kneeling:": {
        "src": "/images/emojis/unicode/svg/1f9ce-200d-2642-fe0f.svg",
        "alt": "🧎‍♂️",
        "title": "man kneeling"
    },
    ":man_kneeling_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3fb-200d-2642-fe0f.svg",
        "alt": "🧎🏻‍♂️",
        "title": "man kneeling"
    },
    ":man_kneeling_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3fc-200d-2642-fe0f.svg",
        "alt": "🧎🏼‍♂️",
        "title": "man kneeling"
    },
    ":man_kneeling_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3fd-200d-2642-fe0f.svg",
        "alt": "🧎🏽‍♂️",
        "title": "man kneeling"
    },
    ":man_kneeling_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3fe-200d-2642-fe0f.svg",
        "alt": "🧎🏾‍♂️",
        "title": "man kneeling"
    },
    ":man_kneeling_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3ff-200d-2642-fe0f.svg",
        "alt": "🧎🏿‍♂️",
        "title": "man kneeling"
    },
    ":woman_kneeling:": {
        "src": "/images/emojis/unicode/svg/1f9ce-200d-2640-fe0f.svg",
        "alt": "🧎‍♀️",
        "title": "woman kneeling"
    },
    ":woman_kneeling_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3fb-200d-2640-fe0f.svg",
        "alt": "🧎🏻‍♀️",
        "title": "woman kneeling"
    },
    ":woman_kneeling_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3fc-200d-2640-fe0f.svg",
        "alt": "🧎🏼‍♀️",
        "title": "woman kneeling"
    },
    ":woman_kneeling_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3fd-200d-2640-fe0f.svg",
        "alt": "🧎🏽‍♀️",
        "title": "woman kneeling"
    },
    ":woman_kneeling_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3fe-200d-2640-fe0f.svg",
        "alt": "🧎🏾‍♀️",
        "title": "woman kneeling"
    },
    ":woman_kneeling_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9ce-1f3ff-200d-2640-fe0f.svg",
        "alt": "🧎🏿‍♀️",
        "title": "woman kneeling"
    },
    ":person_with_white_cane:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f9af.svg",
        "alt": "🧑‍🦯",
        "title": "person with white cane"
    },
    ":person_with_white_cane_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f9af.svg",
        "alt": "🧑🏻‍🦯",
        "title": "person with white cane"
    },
    ":person_with_white_cane_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f9af.svg",
        "alt": "🧑🏼‍🦯",
        "title": "person with white cane"
    },
    ":person_with_white_cane_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f9af.svg",
        "alt": "🧑🏽‍🦯",
        "title": "person with white cane"
    },
    ":person_with_white_cane_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f9af.svg",
        "alt": "🧑🏾‍🦯",
        "title": "person with white cane"
    },
    ":person_with_white_cane_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f9af.svg",
        "alt": "🧑🏿‍🦯",
        "title": "person with white cane"
    },
    ":man_with_white_cane:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f9af.svg",
        "alt": "👨‍🦯",
        "title": "man with white cane"
    },
    ":man_with_white_cane_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f9af.svg",
        "alt": "👨🏻‍🦯",
        "title": "man with white cane"
    },
    ":man_with_white_cane_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f9af.svg",
        "alt": "👨🏼‍🦯",
        "title": "man with white cane"
    },
    ":man_with_white_cane_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f9af.svg",
        "alt": "👨🏽‍🦯",
        "title": "man with white cane"
    },
    ":man_with_white_cane_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f9af.svg",
        "alt": "👨🏾‍🦯",
        "title": "man with white cane"
    },
    ":man_with_white_cane_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f9af.svg",
        "alt": "👨🏿‍🦯",
        "title": "man with white cane"
    },
    ":woman_with_white_cane:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f9af.svg",
        "alt": "👩‍🦯",
        "title": "woman with white cane"
    },
    ":woman_with_white_cane_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f9af.svg",
        "alt": "👩🏻‍🦯",
        "title": "woman with white cane"
    },
    ":woman_with_white_cane_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f9af.svg",
        "alt": "👩🏼‍🦯",
        "title": "woman with white cane"
    },
    ":woman_with_white_cane_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f9af.svg",
        "alt": "👩🏽‍🦯",
        "title": "woman with white cane"
    },
    ":woman_with_white_cane_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f9af.svg",
        "alt": "👩🏾‍🦯",
        "title": "woman with white cane"
    },
    ":woman_with_white_cane_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f9af.svg",
        "alt": "👩🏿‍🦯",
        "title": "woman with white cane"
    },
    ":person_in_motorized_wheelchair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f9bc.svg",
        "alt": "🧑‍🦼",
        "title": "person in motorized wheelchair"
    },
    ":person_in_motorized_wheelchair_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f9bc.svg",
        "alt": "🧑🏻‍🦼",
        "title": "person in motorized wheelchair"
    },
    ":person_in_motorized_wheelchair_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f9bc.svg",
        "alt": "🧑🏼‍🦼",
        "title": "person in motorized wheelchair"
    },
    ":person_in_motorized_wheelchair_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f9bc.svg",
        "alt": "🧑🏽‍🦼",
        "title": "person in motorized wheelchair"
    },
    ":person_in_motorized_wheelchair_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f9bc.svg",
        "alt": "🧑🏾‍🦼",
        "title": "person in motorized wheelchair"
    },
    ":person_in_motorized_wheelchair_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f9bc.svg",
        "alt": "🧑🏿‍🦼",
        "title": "person in motorized wheelchair"
    },
    ":man_in_motorized_wheelchair:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f9bc.svg",
        "alt": "👨‍🦼",
        "title": "man in motorized wheelchair"
    },
    ":man_in_motorized_wheelchair_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f9bc.svg",
        "alt": "👨🏻‍🦼",
        "title": "man in motorized wheelchair"
    },
    ":man_in_motorized_wheelchair_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f9bc.svg",
        "alt": "👨🏼‍🦼",
        "title": "man in motorized wheelchair"
    },
    ":man_in_motorized_wheelchair_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f9bc.svg",
        "alt": "👨🏽‍🦼",
        "title": "man in motorized wheelchair"
    },
    ":man_in_motorized_wheelchair_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f9bc.svg",
        "alt": "👨🏾‍🦼",
        "title": "man in motorized wheelchair"
    },
    ":man_in_motorized_wheelchair_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f9bc.svg",
        "alt": "👨🏿‍🦼",
        "title": "man in motorized wheelchair"
    },
    ":woman_in_motorized_wheelchair:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f9bc.svg",
        "alt": "👩‍🦼",
        "title": "woman in motorized wheelchair"
    },
    ":woman_in_motorized_wheelchair_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f9bc.svg",
        "alt": "👩🏻‍🦼",
        "title": "woman in motorized wheelchair"
    },
    ":woman_in_motorized_wheelchair_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f9bc.svg",
        "alt": "👩🏼‍🦼",
        "title": "woman in motorized wheelchair"
    },
    ":woman_in_motorized_wheelchair_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f9bc.svg",
        "alt": "👩🏽‍🦼",
        "title": "woman in motorized wheelchair"
    },
    ":woman_in_motorized_wheelchair_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f9bc.svg",
        "alt": "👩🏾‍🦼",
        "title": "woman in motorized wheelchair"
    },
    ":woman_in_motorized_wheelchair_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f9bc.svg",
        "alt": "👩🏿‍🦼",
        "title": "woman in motorized wheelchair"
    },
    ":person_in_manual_wheelchair:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f9bd.svg",
        "alt": "🧑‍🦽",
        "title": "person in manual wheelchair"
    },
    ":person_in_manual_wheelchair_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f9bd.svg",
        "alt": "🧑🏻‍🦽",
        "title": "person in manual wheelchair"
    },
    ":person_in_manual_wheelchair_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f9bd.svg",
        "alt": "🧑🏼‍🦽",
        "title": "person in manual wheelchair"
    },
    ":person_in_manual_wheelchair_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f9bd.svg",
        "alt": "🧑🏽‍🦽",
        "title": "person in manual wheelchair"
    },
    ":person_in_manual_wheelchair_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f9bd.svg",
        "alt": "🧑🏾‍🦽",
        "title": "person in manual wheelchair"
    },
    ":person_in_manual_wheelchair_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f9bd.svg",
        "alt": "🧑🏿‍🦽",
        "title": "person in manual wheelchair"
    },
    ":man_in_manual_wheelchair:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f9bd.svg",
        "alt": "👨‍🦽",
        "title": "man in manual wheelchair"
    },
    ":man_in_manual_wheelchair_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f9bd.svg",
        "alt": "👨🏻‍🦽",
        "title": "man in manual wheelchair"
    },
    ":man_in_manual_wheelchair_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f9bd.svg",
        "alt": "👨🏼‍🦽",
        "title": "man in manual wheelchair"
    },
    ":man_in_manual_wheelchair_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f9bd.svg",
        "alt": "👨🏽‍🦽",
        "title": "man in manual wheelchair"
    },
    ":man_in_manual_wheelchair_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f9bd.svg",
        "alt": "👨🏾‍🦽",
        "title": "man in manual wheelchair"
    },
    ":man_in_manual_wheelchair_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f9bd.svg",
        "alt": "👨🏿‍🦽",
        "title": "man in manual wheelchair"
    },
    ":woman_in_manual_wheelchair:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f9bd.svg",
        "alt": "👩‍🦽",
        "title": "woman in manual wheelchair"
    },
    ":woman_in_manual_wheelchair_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f9bd.svg",
        "alt": "👩🏻‍🦽",
        "title": "woman in manual wheelchair"
    },
    ":woman_in_manual_wheelchair_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f9bd.svg",
        "alt": "👩🏼‍🦽",
        "title": "woman in manual wheelchair"
    },
    ":woman_in_manual_wheelchair_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f9bd.svg",
        "alt": "👩🏽‍🦽",
        "title": "woman in manual wheelchair"
    },
    ":woman_in_manual_wheelchair_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f9bd.svg",
        "alt": "👩🏾‍🦽",
        "title": "woman in manual wheelchair"
    },
    ":woman_in_manual_wheelchair_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f9bd.svg",
        "alt": "👩🏿‍🦽",
        "title": "woman in manual wheelchair"
    },
    ":person_running:": {
        "src": "/images/emojis/unicode/svg/1f3c3.svg",
        "alt": "🏃",
        "title": "person running"
    },
    ":person_running_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3fb.svg",
        "alt": "🏃🏻",
        "title": "person running"
    },
    ":person_running_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3fc.svg",
        "alt": "🏃🏼",
        "title": "person running"
    },
    ":person_running_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3fd.svg",
        "alt": "🏃🏽",
        "title": "person running"
    },
    ":person_running_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3fe.svg",
        "alt": "🏃🏾",
        "title": "person running"
    },
    ":person_running_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3ff.svg",
        "alt": "🏃🏿",
        "title": "person running"
    },
    ":man_running:": {
        "src": "/images/emojis/unicode/svg/1f3c3-200d-2642-fe0f.svg",
        "alt": "🏃‍♂️",
        "title": "man running"
    },
    ":man_running_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3fb-200d-2642-fe0f.svg",
        "alt": "🏃🏻‍♂️",
        "title": "man running"
    },
    ":man_running_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3fc-200d-2642-fe0f.svg",
        "alt": "🏃🏼‍♂️",
        "title": "man running"
    },
    ":man_running_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3fd-200d-2642-fe0f.svg",
        "alt": "🏃🏽‍♂️",
        "title": "man running"
    },
    ":man_running_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3fe-200d-2642-fe0f.svg",
        "alt": "🏃🏾‍♂️",
        "title": "man running"
    },
    ":man_running_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3ff-200d-2642-fe0f.svg",
        "alt": "🏃🏿‍♂️",
        "title": "man running"
    },
    ":woman_running:": {
        "src": "/images/emojis/unicode/svg/1f3c3-200d-2640-fe0f.svg",
        "alt": "🏃‍♀️",
        "title": "woman running"
    },
    ":woman_running_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3fb-200d-2640-fe0f.svg",
        "alt": "🏃🏻‍♀️",
        "title": "woman running"
    },
    ":woman_running_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3fc-200d-2640-fe0f.svg",
        "alt": "🏃🏼‍♀️",
        "title": "woman running"
    },
    ":woman_running_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3fd-200d-2640-fe0f.svg",
        "alt": "🏃🏽‍♀️",
        "title": "woman running"
    },
    ":woman_running_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3fe-200d-2640-fe0f.svg",
        "alt": "🏃🏾‍♀️",
        "title": "woman running"
    },
    ":woman_running_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c3-1f3ff-200d-2640-fe0f.svg",
        "alt": "🏃🏿‍♀️",
        "title": "woman running"
    },
    ":woman_dancing:": {
        "src": "/images/emojis/unicode/svg/1f483.svg",
        "alt": "💃",
        "title": "woman dancing"
    },
    ":woman_dancing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f483-1f3fb.svg",
        "alt": "💃🏻",
        "title": "woman dancing"
    },
    ":woman_dancing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f483-1f3fc.svg",
        "alt": "💃🏼",
        "title": "woman dancing"
    },
    ":woman_dancing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f483-1f3fd.svg",
        "alt": "💃🏽",
        "title": "woman dancing"
    },
    ":woman_dancing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f483-1f3fe.svg",
        "alt": "💃🏾",
        "title": "woman dancing"
    },
    ":woman_dancing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f483-1f3ff.svg",
        "alt": "💃🏿",
        "title": "woman dancing"
    },
    ":man_dancing:": {
        "src": "/images/emojis/unicode/svg/1f57a.svg",
        "alt": "🕺",
        "title": "man dancing"
    },
    ":man_dancing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f57a-1f3fb.svg",
        "alt": "🕺🏻",
        "title": "man dancing"
    },
    ":man_dancing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f57a-1f3fc.svg",
        "alt": "🕺🏼",
        "title": "man dancing"
    },
    ":man_dancing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f57a-1f3fd.svg",
        "alt": "🕺🏽",
        "title": "man dancing"
    },
    ":man_dancing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f57a-1f3fe.svg",
        "alt": "🕺🏾",
        "title": "man dancing"
    },
    ":man_dancing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f57a-1f3ff.svg",
        "alt": "🕺🏿",
        "title": "man dancing"
    },
    ":people_with_bunny_ears:": {
        "src": "/images/emojis/unicode/svg/1f46f.svg",
        "alt": "👯",
        "title": "people with bunny ears"
    },
    ":men_with_bunny_ears:": {
        "src": "/images/emojis/unicode/svg/1f46f-200d-2642-fe0f.svg",
        "alt": "👯‍♂️",
        "title": "men with bunny ears"
    },
    ":women_with_bunny_ears:": {
        "src": "/images/emojis/unicode/svg/1f46f-200d-2640-fe0f.svg",
        "alt": "👯‍♀️",
        "title": "women with bunny ears"
    },
    ":person_in_steamy_room:": {
        "src": "/images/emojis/unicode/svg/1f9d6.svg",
        "alt": "🧖",
        "title": "person in steamy room"
    },
    ":person_in_steamy_room_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3fb.svg",
        "alt": "🧖🏻",
        "title": "person in steamy room"
    },
    ":person_in_steamy_room_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3fc.svg",
        "alt": "🧖🏼",
        "title": "person in steamy room"
    },
    ":person_in_steamy_room_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3fd.svg",
        "alt": "🧖🏽",
        "title": "person in steamy room"
    },
    ":person_in_steamy_room_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3fe.svg",
        "alt": "🧖🏾",
        "title": "person in steamy room"
    },
    ":person_in_steamy_room_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3ff.svg",
        "alt": "🧖🏿",
        "title": "person in steamy room"
    },
    ":man_in_steamy_room:": {
        "src": "/images/emojis/unicode/svg/1f9d6-200d-2642-fe0f.svg",
        "alt": "🧖‍♂️",
        "title": "man in steamy room"
    },
    ":man_in_steamy_room_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3fb-200d-2642-fe0f.svg",
        "alt": "🧖🏻‍♂️",
        "title": "man in steamy room"
    },
    ":man_in_steamy_room_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3fc-200d-2642-fe0f.svg",
        "alt": "🧖🏼‍♂️",
        "title": "man in steamy room"
    },
    ":man_in_steamy_room_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3fd-200d-2642-fe0f.svg",
        "alt": "🧖🏽‍♂️",
        "title": "man in steamy room"
    },
    ":man_in_steamy_room_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3fe-200d-2642-fe0f.svg",
        "alt": "🧖🏾‍♂️",
        "title": "man in steamy room"
    },
    ":man_in_steamy_room_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3ff-200d-2642-fe0f.svg",
        "alt": "🧖🏿‍♂️",
        "title": "man in steamy room"
    },
    ":woman_in_steamy_room:": {
        "src": "/images/emojis/unicode/svg/1f9d6-200d-2640-fe0f.svg",
        "alt": "🧖‍♀️",
        "title": "woman in steamy room"
    },
    ":woman_in_steamy_room_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3fb-200d-2640-fe0f.svg",
        "alt": "🧖🏻‍♀️",
        "title": "woman in steamy room"
    },
    ":woman_in_steamy_room_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3fc-200d-2640-fe0f.svg",
        "alt": "🧖🏼‍♀️",
        "title": "woman in steamy room"
    },
    ":woman_in_steamy_room_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3fd-200d-2640-fe0f.svg",
        "alt": "🧖🏽‍♀️",
        "title": "woman in steamy room"
    },
    ":woman_in_steamy_room_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3fe-200d-2640-fe0f.svg",
        "alt": "🧖🏾‍♀️",
        "title": "woman in steamy room"
    },
    ":woman_in_steamy_room_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d6-1f3ff-200d-2640-fe0f.svg",
        "alt": "🧖🏿‍♀️",
        "title": "woman in steamy room"
    },
    ":person_climbing:": {
        "src": "/images/emojis/unicode/svg/1f9d7.svg",
        "alt": "🧗",
        "title": "person climbing"
    },
    ":person_climbing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3fb.svg",
        "alt": "🧗🏻",
        "title": "person climbing"
    },
    ":person_climbing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3fc.svg",
        "alt": "🧗🏼",
        "title": "person climbing"
    },
    ":person_climbing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3fd.svg",
        "alt": "🧗🏽",
        "title": "person climbing"
    },
    ":person_climbing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3fe.svg",
        "alt": "🧗🏾",
        "title": "person climbing"
    },
    ":person_climbing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3ff.svg",
        "alt": "🧗🏿",
        "title": "person climbing"
    },
    ":man_climbing:": {
        "src": "/images/emojis/unicode/svg/1f9d7-200d-2642-fe0f.svg",
        "alt": "🧗‍♂️",
        "title": "man climbing"
    },
    ":man_climbing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3fb-200d-2642-fe0f.svg",
        "alt": "🧗🏻‍♂️",
        "title": "man climbing"
    },
    ":man_climbing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3fc-200d-2642-fe0f.svg",
        "alt": "🧗🏼‍♂️",
        "title": "man climbing"
    },
    ":man_climbing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3fd-200d-2642-fe0f.svg",
        "alt": "🧗🏽‍♂️",
        "title": "man climbing"
    },
    ":man_climbing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3fe-200d-2642-fe0f.svg",
        "alt": "🧗🏾‍♂️",
        "title": "man climbing"
    },
    ":man_climbing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3ff-200d-2642-fe0f.svg",
        "alt": "🧗🏿‍♂️",
        "title": "man climbing"
    },
    ":woman_climbing:": {
        "src": "/images/emojis/unicode/svg/1f9d7-200d-2640-fe0f.svg",
        "alt": "🧗‍♀️",
        "title": "woman climbing"
    },
    ":woman_climbing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3fb-200d-2640-fe0f.svg",
        "alt": "🧗🏻‍♀️",
        "title": "woman climbing"
    },
    ":woman_climbing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3fc-200d-2640-fe0f.svg",
        "alt": "🧗🏼‍♀️",
        "title": "woman climbing"
    },
    ":woman_climbing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3fd-200d-2640-fe0f.svg",
        "alt": "🧗🏽‍♀️",
        "title": "woman climbing"
    },
    ":woman_climbing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3fe-200d-2640-fe0f.svg",
        "alt": "🧗🏾‍♀️",
        "title": "woman climbing"
    },
    ":woman_climbing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d7-1f3ff-200d-2640-fe0f.svg",
        "alt": "🧗🏿‍♀️",
        "title": "woman climbing"
    },
    ":person_fencing:": {
        "src": "/images/emojis/unicode/svg/1f93a.svg",
        "alt": "🤺",
        "title": "person fencing"
    },
    ":horse_racing:": {
        "src": "/images/emojis/unicode/svg/1f3c7.svg",
        "alt": "🏇",
        "title": "horse racing"
    },
    ":horse_racing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c7-1f3fb.svg",
        "alt": "🏇🏻",
        "title": "horse racing"
    },
    ":horse_racing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c7-1f3fc.svg",
        "alt": "🏇🏼",
        "title": "horse racing"
    },
    ":horse_racing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c7-1f3fd.svg",
        "alt": "🏇🏽",
        "title": "horse racing"
    },
    ":horse_racing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c7-1f3fe.svg",
        "alt": "🏇🏾",
        "title": "horse racing"
    },
    ":horse_racing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c7-1f3ff.svg",
        "alt": "🏇🏿",
        "title": "horse racing"
    },
    ":skier:": {
        "src": "/images/emojis/unicode/svg/26f7.svg",
        "alt": "⛷",
        "title": "skier"
    },
    ":snowboarder:": {
        "src": "/images/emojis/unicode/svg/1f3c2.svg",
        "alt": "🏂",
        "title": "snowboarder"
    },
    ":snowboarder_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c2-1f3fb.svg",
        "alt": "🏂🏻",
        "title": "snowboarder"
    },
    ":snowboarder_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c2-1f3fc.svg",
        "alt": "🏂🏼",
        "title": "snowboarder"
    },
    ":snowboarder_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c2-1f3fd.svg",
        "alt": "🏂🏽",
        "title": "snowboarder"
    },
    ":snowboarder_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c2-1f3fe.svg",
        "alt": "🏂🏾",
        "title": "snowboarder"
    },
    ":snowboarder_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c2-1f3ff.svg",
        "alt": "🏂🏿",
        "title": "snowboarder"
    },
    ":man_golfing:": {
        "src": "/images/emojis/unicode/svg/1f3cc-fe0f-200d-2642-fe0f.svg",
        "alt": "🏌️‍♂️",
        "title": "man golfing"
    },
    ":man_golfing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cc-1f3fb-200d-2642-fe0f.svg",
        "alt": "🏌🏻‍♂️",
        "title": "man golfing"
    },
    ":man_golfing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cc-1f3fc-200d-2642-fe0f.svg",
        "alt": "🏌🏼‍♂️",
        "title": "man golfing"
    },
    ":man_golfing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cc-1f3fd-200d-2642-fe0f.svg",
        "alt": "🏌🏽‍♂️",
        "title": "man golfing"
    },
    ":man_golfing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cc-1f3fe-200d-2642-fe0f.svg",
        "alt": "🏌🏾‍♂️",
        "title": "man golfing"
    },
    ":man_golfing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cc-1f3ff-200d-2642-fe0f.svg",
        "alt": "🏌🏿‍♂️",
        "title": "man golfing"
    },
    ":woman_golfing:": {
        "src": "/images/emojis/unicode/svg/1f3cc-fe0f-200d-2640-fe0f.svg",
        "alt": "🏌️‍♀️",
        "title": "woman golfing"
    },
    ":woman_golfing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cc-1f3fb-200d-2640-fe0f.svg",
        "alt": "🏌🏻‍♀️",
        "title": "woman golfing"
    },
    ":woman_golfing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cc-1f3fc-200d-2640-fe0f.svg",
        "alt": "🏌🏼‍♀️",
        "title": "woman golfing"
    },
    ":woman_golfing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cc-1f3fd-200d-2640-fe0f.svg",
        "alt": "🏌🏽‍♀️",
        "title": "woman golfing"
    },
    ":woman_golfing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cc-1f3fe-200d-2640-fe0f.svg",
        "alt": "🏌🏾‍♀️",
        "title": "woman golfing"
    },
    ":woman_golfing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cc-1f3ff-200d-2640-fe0f.svg",
        "alt": "🏌🏿‍♀️",
        "title": "woman golfing"
    },
    ":person_surfing:": {
        "src": "/images/emojis/unicode/svg/1f3c4.svg",
        "alt": "🏄",
        "title": "person surfing"
    },
    ":person_surfing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3fb.svg",
        "alt": "🏄🏻",
        "title": "person surfing"
    },
    ":person_surfing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3fc.svg",
        "alt": "🏄🏼",
        "title": "person surfing"
    },
    ":person_surfing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3fd.svg",
        "alt": "🏄🏽",
        "title": "person surfing"
    },
    ":person_surfing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3fe.svg",
        "alt": "🏄🏾",
        "title": "person surfing"
    },
    ":person_surfing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3ff.svg",
        "alt": "🏄🏿",
        "title": "person surfing"
    },
    ":man_surfing:": {
        "src": "/images/emojis/unicode/svg/1f3c4-200d-2642-fe0f.svg",
        "alt": "🏄‍♂️",
        "title": "man surfing"
    },
    ":man_surfing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3fb-200d-2642-fe0f.svg",
        "alt": "🏄🏻‍♂️",
        "title": "man surfing"
    },
    ":man_surfing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3fc-200d-2642-fe0f.svg",
        "alt": "🏄🏼‍♂️",
        "title": "man surfing"
    },
    ":man_surfing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3fd-200d-2642-fe0f.svg",
        "alt": "🏄🏽‍♂️",
        "title": "man surfing"
    },
    ":man_surfing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3fe-200d-2642-fe0f.svg",
        "alt": "🏄🏾‍♂️",
        "title": "man surfing"
    },
    ":man_surfing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3ff-200d-2642-fe0f.svg",
        "alt": "🏄🏿‍♂️",
        "title": "man surfing"
    },
    ":woman_surfing:": {
        "src": "/images/emojis/unicode/svg/1f3c4-200d-2640-fe0f.svg",
        "alt": "🏄‍♀️",
        "title": "woman surfing"
    },
    ":woman_surfing_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3fb-200d-2640-fe0f.svg",
        "alt": "🏄🏻‍♀️",
        "title": "woman surfing"
    },
    ":woman_surfing_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3fc-200d-2640-fe0f.svg",
        "alt": "🏄🏼‍♀️",
        "title": "woman surfing"
    },
    ":woman_surfing_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3fd-200d-2640-fe0f.svg",
        "alt": "🏄🏽‍♀️",
        "title": "woman surfing"
    },
    ":woman_surfing_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3fe-200d-2640-fe0f.svg",
        "alt": "🏄🏾‍♀️",
        "title": "woman surfing"
    },
    ":woman_surfing_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3c4-1f3ff-200d-2640-fe0f.svg",
        "alt": "🏄🏿‍♀️",
        "title": "woman surfing"
    },
    ":person_rowing_boat:": {
        "src": "/images/emojis/unicode/svg/1f6a3.svg",
        "alt": "🚣",
        "title": "person rowing boat"
    },
    ":person_rowing_boat_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3fb.svg",
        "alt": "🚣🏻",
        "title": "person rowing boat"
    },
    ":person_rowing_boat_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3fc.svg",
        "alt": "🚣🏼",
        "title": "person rowing boat"
    },
    ":person_rowing_boat_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3fd.svg",
        "alt": "🚣🏽",
        "title": "person rowing boat"
    },
    ":person_rowing_boat_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3fe.svg",
        "alt": "🚣🏾",
        "title": "person rowing boat"
    },
    ":person_rowing_boat_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3ff.svg",
        "alt": "🚣🏿",
        "title": "person rowing boat"
    },
    ":man_rowing_boat:": {
        "src": "/images/emojis/unicode/svg/1f6a3-200d-2642-fe0f.svg",
        "alt": "🚣‍♂️",
        "title": "man rowing boat"
    },
    ":man_rowing_boat_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3fb-200d-2642-fe0f.svg",
        "alt": "🚣🏻‍♂️",
        "title": "man rowing boat"
    },
    ":man_rowing_boat_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3fc-200d-2642-fe0f.svg",
        "alt": "🚣🏼‍♂️",
        "title": "man rowing boat"
    },
    ":man_rowing_boat_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3fd-200d-2642-fe0f.svg",
        "alt": "🚣🏽‍♂️",
        "title": "man rowing boat"
    },
    ":man_rowing_boat_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3fe-200d-2642-fe0f.svg",
        "alt": "🚣🏾‍♂️",
        "title": "man rowing boat"
    },
    ":man_rowing_boat_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3ff-200d-2642-fe0f.svg",
        "alt": "🚣🏿‍♂️",
        "title": "man rowing boat"
    },
    ":woman_rowing_boat:": {
        "src": "/images/emojis/unicode/svg/1f6a3-200d-2640-fe0f.svg",
        "alt": "🚣‍♀️",
        "title": "woman rowing boat"
    },
    ":woman_rowing_boat_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3fb-200d-2640-fe0f.svg",
        "alt": "🚣🏻‍♀️",
        "title": "woman rowing boat"
    },
    ":woman_rowing_boat_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3fc-200d-2640-fe0f.svg",
        "alt": "🚣🏼‍♀️",
        "title": "woman rowing boat"
    },
    ":woman_rowing_boat_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3fd-200d-2640-fe0f.svg",
        "alt": "🚣🏽‍♀️",
        "title": "woman rowing boat"
    },
    ":woman_rowing_boat_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3fe-200d-2640-fe0f.svg",
        "alt": "🚣🏾‍♀️",
        "title": "woman rowing boat"
    },
    ":woman_rowing_boat_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6a3-1f3ff-200d-2640-fe0f.svg",
        "alt": "🚣🏿‍♀️",
        "title": "woman rowing boat"
    },
    ":person_swimming:": {
        "src": "/images/emojis/unicode/svg/1f3ca.svg",
        "alt": "🏊",
        "title": "person swimming"
    },
    ":person_swimming_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3fb.svg",
        "alt": "🏊🏻",
        "title": "person swimming"
    },
    ":person_swimming_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3fc.svg",
        "alt": "🏊🏼",
        "title": "person swimming"
    },
    ":person_swimming_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3fd.svg",
        "alt": "🏊🏽",
        "title": "person swimming"
    },
    ":person_swimming_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3fe.svg",
        "alt": "🏊🏾",
        "title": "person swimming"
    },
    ":person_swimming_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3ff.svg",
        "alt": "🏊🏿",
        "title": "person swimming"
    },
    ":man_swimming:": {
        "src": "/images/emojis/unicode/svg/1f3ca-200d-2642-fe0f.svg",
        "alt": "🏊‍♂️",
        "title": "man swimming"
    },
    ":man_swimming_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3fb-200d-2642-fe0f.svg",
        "alt": "🏊🏻‍♂️",
        "title": "man swimming"
    },
    ":man_swimming_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3fc-200d-2642-fe0f.svg",
        "alt": "🏊🏼‍♂️",
        "title": "man swimming"
    },
    ":man_swimming_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3fd-200d-2642-fe0f.svg",
        "alt": "🏊🏽‍♂️",
        "title": "man swimming"
    },
    ":man_swimming_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3fe-200d-2642-fe0f.svg",
        "alt": "🏊🏾‍♂️",
        "title": "man swimming"
    },
    ":man_swimming_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3ff-200d-2642-fe0f.svg",
        "alt": "🏊🏿‍♂️",
        "title": "man swimming"
    },
    ":woman_swimming:": {
        "src": "/images/emojis/unicode/svg/1f3ca-200d-2640-fe0f.svg",
        "alt": "🏊‍♀️",
        "title": "woman swimming"
    },
    ":woman_swimming_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3fb-200d-2640-fe0f.svg",
        "alt": "🏊🏻‍♀️",
        "title": "woman swimming"
    },
    ":woman_swimming_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3fc-200d-2640-fe0f.svg",
        "alt": "🏊🏼‍♀️",
        "title": "woman swimming"
    },
    ":woman_swimming_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3fd-200d-2640-fe0f.svg",
        "alt": "🏊🏽‍♀️",
        "title": "woman swimming"
    },
    ":woman_swimming_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3fe-200d-2640-fe0f.svg",
        "alt": "🏊🏾‍♀️",
        "title": "woman swimming"
    },
    ":woman_swimming_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3ca-1f3ff-200d-2640-fe0f.svg",
        "alt": "🏊🏿‍♀️",
        "title": "woman swimming"
    },
    ":man_bouncing_ball:": {
        "src": "/images/emojis/unicode/svg/26f9-fe0f-200d-2642-fe0f.svg",
        "alt": "⛹️‍♂️",
        "title": "man bouncing ball"
    },
    ":man_bouncing_ball_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/26f9-1f3fb-200d-2642-fe0f.svg",
        "alt": "⛹🏻‍♂️",
        "title": "man bouncing ball"
    },
    ":man_bouncing_ball_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/26f9-1f3fc-200d-2642-fe0f.svg",
        "alt": "⛹🏼‍♂️",
        "title": "man bouncing ball"
    },
    ":man_bouncing_ball_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/26f9-1f3fd-200d-2642-fe0f.svg",
        "alt": "⛹🏽‍♂️",
        "title": "man bouncing ball"
    },
    ":man_bouncing_ball_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/26f9-1f3fe-200d-2642-fe0f.svg",
        "alt": "⛹🏾‍♂️",
        "title": "man bouncing ball"
    },
    ":man_bouncing_ball_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/26f9-1f3ff-200d-2642-fe0f.svg",
        "alt": "⛹🏿‍♂️",
        "title": "man bouncing ball"
    },
    ":woman_bouncing_ball:": {
        "src": "/images/emojis/unicode/svg/26f9-fe0f-200d-2640-fe0f.svg",
        "alt": "⛹️‍♀️",
        "title": "woman bouncing ball"
    },
    ":woman_bouncing_ball_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/26f9-1f3fb-200d-2640-fe0f.svg",
        "alt": "⛹🏻‍♀️",
        "title": "woman bouncing ball"
    },
    ":woman_bouncing_ball_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/26f9-1f3fc-200d-2640-fe0f.svg",
        "alt": "⛹🏼‍♀️",
        "title": "woman bouncing ball"
    },
    ":woman_bouncing_ball_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/26f9-1f3fd-200d-2640-fe0f.svg",
        "alt": "⛹🏽‍♀️",
        "title": "woman bouncing ball"
    },
    ":woman_bouncing_ball_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/26f9-1f3fe-200d-2640-fe0f.svg",
        "alt": "⛹🏾‍♀️",
        "title": "woman bouncing ball"
    },
    ":woman_bouncing_ball_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/26f9-1f3ff-200d-2640-fe0f.svg",
        "alt": "⛹🏿‍♀️",
        "title": "woman bouncing ball"
    },
    ":man_lifting_weights:": {
        "src": "/images/emojis/unicode/svg/1f3cb-fe0f-200d-2642-fe0f.svg",
        "alt": "🏋️‍♂️",
        "title": "man lifting weights"
    },
    ":man_lifting_weights_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cb-1f3fb-200d-2642-fe0f.svg",
        "alt": "🏋🏻‍♂️",
        "title": "man lifting weights"
    },
    ":man_lifting_weights_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cb-1f3fc-200d-2642-fe0f.svg",
        "alt": "🏋🏼‍♂️",
        "title": "man lifting weights"
    },
    ":man_lifting_weights_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cb-1f3fd-200d-2642-fe0f.svg",
        "alt": "🏋🏽‍♂️",
        "title": "man lifting weights"
    },
    ":man_lifting_weights_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cb-1f3fe-200d-2642-fe0f.svg",
        "alt": "🏋🏾‍♂️",
        "title": "man lifting weights"
    },
    ":man_lifting_weights_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cb-1f3ff-200d-2642-fe0f.svg",
        "alt": "🏋🏿‍♂️",
        "title": "man lifting weights"
    },
    ":woman_lifting_weights:": {
        "src": "/images/emojis/unicode/svg/1f3cb-fe0f-200d-2640-fe0f.svg",
        "alt": "🏋️‍♀️",
        "title": "woman lifting weights"
    },
    ":woman_lifting_weights_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cb-1f3fb-200d-2640-fe0f.svg",
        "alt": "🏋🏻‍♀️",
        "title": "woman lifting weights"
    },
    ":woman_lifting_weights_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cb-1f3fc-200d-2640-fe0f.svg",
        "alt": "🏋🏼‍♀️",
        "title": "woman lifting weights"
    },
    ":woman_lifting_weights_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cb-1f3fd-200d-2640-fe0f.svg",
        "alt": "🏋🏽‍♀️",
        "title": "woman lifting weights"
    },
    ":woman_lifting_weights_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cb-1f3fe-200d-2640-fe0f.svg",
        "alt": "🏋🏾‍♀️",
        "title": "woman lifting weights"
    },
    ":woman_lifting_weights_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f3cb-1f3ff-200d-2640-fe0f.svg",
        "alt": "🏋🏿‍♀️",
        "title": "woman lifting weights"
    },
    ":person_biking:": {
        "src": "/images/emojis/unicode/svg/1f6b4.svg",
        "alt": "🚴",
        "title": "person biking"
    },
    ":person_biking_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3fb.svg",
        "alt": "🚴🏻",
        "title": "person biking"
    },
    ":person_biking_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3fc.svg",
        "alt": "🚴🏼",
        "title": "person biking"
    },
    ":person_biking_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3fd.svg",
        "alt": "🚴🏽",
        "title": "person biking"
    },
    ":person_biking_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3fe.svg",
        "alt": "🚴🏾",
        "title": "person biking"
    },
    ":person_biking_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3ff.svg",
        "alt": "🚴🏿",
        "title": "person biking"
    },
    ":man_biking:": {
        "src": "/images/emojis/unicode/svg/1f6b4-200d-2642-fe0f.svg",
        "alt": "🚴‍♂️",
        "title": "man biking"
    },
    ":man_biking_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3fb-200d-2642-fe0f.svg",
        "alt": "🚴🏻‍♂️",
        "title": "man biking"
    },
    ":man_biking_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3fc-200d-2642-fe0f.svg",
        "alt": "🚴🏼‍♂️",
        "title": "man biking"
    },
    ":man_biking_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3fd-200d-2642-fe0f.svg",
        "alt": "🚴🏽‍♂️",
        "title": "man biking"
    },
    ":man_biking_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3fe-200d-2642-fe0f.svg",
        "alt": "🚴🏾‍♂️",
        "title": "man biking"
    },
    ":man_biking_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3ff-200d-2642-fe0f.svg",
        "alt": "🚴🏿‍♂️",
        "title": "man biking"
    },
    ":woman_biking:": {
        "src": "/images/emojis/unicode/svg/1f6b4-200d-2640-fe0f.svg",
        "alt": "🚴‍♀️",
        "title": "woman biking"
    },
    ":woman_biking_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3fb-200d-2640-fe0f.svg",
        "alt": "🚴🏻‍♀️",
        "title": "woman biking"
    },
    ":woman_biking_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3fc-200d-2640-fe0f.svg",
        "alt": "🚴🏼‍♀️",
        "title": "woman biking"
    },
    ":woman_biking_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3fd-200d-2640-fe0f.svg",
        "alt": "🚴🏽‍♀️",
        "title": "woman biking"
    },
    ":woman_biking_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3fe-200d-2640-fe0f.svg",
        "alt": "🚴🏾‍♀️",
        "title": "woman biking"
    },
    ":woman_biking_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b4-1f3ff-200d-2640-fe0f.svg",
        "alt": "🚴🏿‍♀️",
        "title": "woman biking"
    },
    ":person_mountain_biking:": {
        "src": "/images/emojis/unicode/svg/1f6b5.svg",
        "alt": "🚵",
        "title": "person mountain biking"
    },
    ":person_mountain_biking_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3fb.svg",
        "alt": "🚵🏻",
        "title": "person mountain biking"
    },
    ":person_mountain_biking_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3fc.svg",
        "alt": "🚵🏼",
        "title": "person mountain biking"
    },
    ":person_mountain_biking_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3fd.svg",
        "alt": "🚵🏽",
        "title": "person mountain biking"
    },
    ":person_mountain_biking_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3fe.svg",
        "alt": "🚵🏾",
        "title": "person mountain biking"
    },
    ":person_mountain_biking_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3ff.svg",
        "alt": "🚵🏿",
        "title": "person mountain biking"
    },
    ":man_mountain_biking:": {
        "src": "/images/emojis/unicode/svg/1f6b5-200d-2642-fe0f.svg",
        "alt": "🚵‍♂️",
        "title": "man mountain biking"
    },
    ":man_mountain_biking_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3fb-200d-2642-fe0f.svg",
        "alt": "🚵🏻‍♂️",
        "title": "man mountain biking"
    },
    ":man_mountain_biking_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3fc-200d-2642-fe0f.svg",
        "alt": "🚵🏼‍♂️",
        "title": "man mountain biking"
    },
    ":man_mountain_biking_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3fd-200d-2642-fe0f.svg",
        "alt": "🚵🏽‍♂️",
        "title": "man mountain biking"
    },
    ":man_mountain_biking_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3fe-200d-2642-fe0f.svg",
        "alt": "🚵🏾‍♂️",
        "title": "man mountain biking"
    },
    ":man_mountain_biking_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3ff-200d-2642-fe0f.svg",
        "alt": "🚵🏿‍♂️",
        "title": "man mountain biking"
    },
    ":woman_mountain_biking:": {
        "src": "/images/emojis/unicode/svg/1f6b5-200d-2640-fe0f.svg",
        "alt": "🚵‍♀️",
        "title": "woman mountain biking"
    },
    ":woman_mountain_biking_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3fb-200d-2640-fe0f.svg",
        "alt": "🚵🏻‍♀️",
        "title": "woman mountain biking"
    },
    ":woman_mountain_biking_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3fc-200d-2640-fe0f.svg",
        "alt": "🚵🏼‍♀️",
        "title": "woman mountain biking"
    },
    ":woman_mountain_biking_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3fd-200d-2640-fe0f.svg",
        "alt": "🚵🏽‍♀️",
        "title": "woman mountain biking"
    },
    ":woman_mountain_biking_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3fe-200d-2640-fe0f.svg",
        "alt": "🚵🏾‍♀️",
        "title": "woman mountain biking"
    },
    ":woman_mountain_biking_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6b5-1f3ff-200d-2640-fe0f.svg",
        "alt": "🚵🏿‍♀️",
        "title": "woman mountain biking"
    },
    ":person_cartwheeling:": {
        "src": "/images/emojis/unicode/svg/1f938.svg",
        "alt": "🤸",
        "title": "person cartwheeling"
    },
    ":person_cartwheeling_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3fb.svg",
        "alt": "🤸🏻",
        "title": "person cartwheeling"
    },
    ":person_cartwheeling_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3fc.svg",
        "alt": "🤸🏼",
        "title": "person cartwheeling"
    },
    ":person_cartwheeling_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3fd.svg",
        "alt": "🤸🏽",
        "title": "person cartwheeling"
    },
    ":person_cartwheeling_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3fe.svg",
        "alt": "🤸🏾",
        "title": "person cartwheeling"
    },
    ":person_cartwheeling_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3ff.svg",
        "alt": "🤸🏿",
        "title": "person cartwheeling"
    },
    ":man_cartwheeling:": {
        "src": "/images/emojis/unicode/svg/1f938-200d-2642-fe0f.svg",
        "alt": "🤸‍♂️",
        "title": "man cartwheeling"
    },
    ":man_cartwheeling_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3fb-200d-2642-fe0f.svg",
        "alt": "🤸🏻‍♂️",
        "title": "man cartwheeling"
    },
    ":man_cartwheeling_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3fc-200d-2642-fe0f.svg",
        "alt": "🤸🏼‍♂️",
        "title": "man cartwheeling"
    },
    ":man_cartwheeling_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3fd-200d-2642-fe0f.svg",
        "alt": "🤸🏽‍♂️",
        "title": "man cartwheeling"
    },
    ":man_cartwheeling_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3fe-200d-2642-fe0f.svg",
        "alt": "🤸🏾‍♂️",
        "title": "man cartwheeling"
    },
    ":man_cartwheeling_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3ff-200d-2642-fe0f.svg",
        "alt": "🤸🏿‍♂️",
        "title": "man cartwheeling"
    },
    ":woman_cartwheeling:": {
        "src": "/images/emojis/unicode/svg/1f938-200d-2640-fe0f.svg",
        "alt": "🤸‍♀️",
        "title": "woman cartwheeling"
    },
    ":woman_cartwheeling_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3fb-200d-2640-fe0f.svg",
        "alt": "🤸🏻‍♀️",
        "title": "woman cartwheeling"
    },
    ":woman_cartwheeling_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3fc-200d-2640-fe0f.svg",
        "alt": "🤸🏼‍♀️",
        "title": "woman cartwheeling"
    },
    ":woman_cartwheeling_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3fd-200d-2640-fe0f.svg",
        "alt": "🤸🏽‍♀️",
        "title": "woman cartwheeling"
    },
    ":woman_cartwheeling_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3fe-200d-2640-fe0f.svg",
        "alt": "🤸🏾‍♀️",
        "title": "woman cartwheeling"
    },
    ":woman_cartwheeling_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f938-1f3ff-200d-2640-fe0f.svg",
        "alt": "🤸🏿‍♀️",
        "title": "woman cartwheeling"
    },
    ":people_wrestling:": {
        "src": "/images/emojis/unicode/svg/1f93c.svg",
        "alt": "🤼",
        "title": "people wrestling"
    },
    ":men_wrestling:": {
        "src": "/images/emojis/unicode/svg/1f93c-200d-2642-fe0f.svg",
        "alt": "🤼‍♂️",
        "title": "men wrestling"
    },
    ":women_wrestling:": {
        "src": "/images/emojis/unicode/svg/1f93c-200d-2640-fe0f.svg",
        "alt": "🤼‍♀️",
        "title": "women wrestling"
    },
    ":person_playing_water_polo:": {
        "src": "/images/emojis/unicode/svg/1f93d.svg",
        "alt": "🤽",
        "title": "person playing water polo"
    },
    ":person_playing_water_polo_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3fb.svg",
        "alt": "🤽🏻",
        "title": "person playing water polo"
    },
    ":person_playing_water_polo_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3fc.svg",
        "alt": "🤽🏼",
        "title": "person playing water polo"
    },
    ":person_playing_water_polo_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3fd.svg",
        "alt": "🤽🏽",
        "title": "person playing water polo"
    },
    ":person_playing_water_polo_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3fe.svg",
        "alt": "🤽🏾",
        "title": "person playing water polo"
    },
    ":person_playing_water_polo_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3ff.svg",
        "alt": "🤽🏿",
        "title": "person playing water polo"
    },
    ":man_playing_water_polo:": {
        "src": "/images/emojis/unicode/svg/1f93d-200d-2642-fe0f.svg",
        "alt": "🤽‍♂️",
        "title": "man playing water polo"
    },
    ":man_playing_water_polo_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3fb-200d-2642-fe0f.svg",
        "alt": "🤽🏻‍♂️",
        "title": "man playing water polo"
    },
    ":man_playing_water_polo_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3fc-200d-2642-fe0f.svg",
        "alt": "🤽🏼‍♂️",
        "title": "man playing water polo"
    },
    ":man_playing_water_polo_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3fd-200d-2642-fe0f.svg",
        "alt": "🤽🏽‍♂️",
        "title": "man playing water polo"
    },
    ":man_playing_water_polo_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3fe-200d-2642-fe0f.svg",
        "alt": "🤽🏾‍♂️",
        "title": "man playing water polo"
    },
    ":man_playing_water_polo_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3ff-200d-2642-fe0f.svg",
        "alt": "🤽🏿‍♂️",
        "title": "man playing water polo"
    },
    ":woman_playing_water_polo:": {
        "src": "/images/emojis/unicode/svg/1f93d-200d-2640-fe0f.svg",
        "alt": "🤽‍♀️",
        "title": "woman playing water polo"
    },
    ":woman_playing_water_polo_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3fb-200d-2640-fe0f.svg",
        "alt": "🤽🏻‍♀️",
        "title": "woman playing water polo"
    },
    ":woman_playing_water_polo_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3fc-200d-2640-fe0f.svg",
        "alt": "🤽🏼‍♀️",
        "title": "woman playing water polo"
    },
    ":woman_playing_water_polo_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3fd-200d-2640-fe0f.svg",
        "alt": "🤽🏽‍♀️",
        "title": "woman playing water polo"
    },
    ":woman_playing_water_polo_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3fe-200d-2640-fe0f.svg",
        "alt": "🤽🏾‍♀️",
        "title": "woman playing water polo"
    },
    ":woman_playing_water_polo_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93d-1f3ff-200d-2640-fe0f.svg",
        "alt": "🤽🏿‍♀️",
        "title": "woman playing water polo"
    },
    ":person_playing_handball:": {
        "src": "/images/emojis/unicode/svg/1f93e.svg",
        "alt": "🤾",
        "title": "person playing handball"
    },
    ":person_playing_handball_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3fb.svg",
        "alt": "🤾🏻",
        "title": "person playing handball"
    },
    ":person_playing_handball_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3fc.svg",
        "alt": "🤾🏼",
        "title": "person playing handball"
    },
    ":person_playing_handball_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3fd.svg",
        "alt": "🤾🏽",
        "title": "person playing handball"
    },
    ":person_playing_handball_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3fe.svg",
        "alt": "🤾🏾",
        "title": "person playing handball"
    },
    ":person_playing_handball_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3ff.svg",
        "alt": "🤾🏿",
        "title": "person playing handball"
    },
    ":man_playing_handball:": {
        "src": "/images/emojis/unicode/svg/1f93e-200d-2642-fe0f.svg",
        "alt": "🤾‍♂️",
        "title": "man playing handball"
    },
    ":man_playing_handball_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3fb-200d-2642-fe0f.svg",
        "alt": "🤾🏻‍♂️",
        "title": "man playing handball"
    },
    ":man_playing_handball_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3fc-200d-2642-fe0f.svg",
        "alt": "🤾🏼‍♂️",
        "title": "man playing handball"
    },
    ":man_playing_handball_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3fd-200d-2642-fe0f.svg",
        "alt": "🤾🏽‍♂️",
        "title": "man playing handball"
    },
    ":man_playing_handball_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3fe-200d-2642-fe0f.svg",
        "alt": "🤾🏾‍♂️",
        "title": "man playing handball"
    },
    ":man_playing_handball_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3ff-200d-2642-fe0f.svg",
        "alt": "🤾🏿‍♂️",
        "title": "man playing handball"
    },
    ":woman_playing_handball:": {
        "src": "/images/emojis/unicode/svg/1f93e-200d-2640-fe0f.svg",
        "alt": "🤾‍♀️",
        "title": "woman playing handball"
    },
    ":woman_playing_handball_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3fb-200d-2640-fe0f.svg",
        "alt": "🤾🏻‍♀️",
        "title": "woman playing handball"
    },
    ":woman_playing_handball_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3fc-200d-2640-fe0f.svg",
        "alt": "🤾🏼‍♀️",
        "title": "woman playing handball"
    },
    ":woman_playing_handball_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3fd-200d-2640-fe0f.svg",
        "alt": "🤾🏽‍♀️",
        "title": "woman playing handball"
    },
    ":woman_playing_handball_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3fe-200d-2640-fe0f.svg",
        "alt": "🤾🏾‍♀️",
        "title": "woman playing handball"
    },
    ":woman_playing_handball_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f93e-1f3ff-200d-2640-fe0f.svg",
        "alt": "🤾🏿‍♀️",
        "title": "woman playing handball"
    },
    ":person_juggling:": {
        "src": "/images/emojis/unicode/svg/1f939.svg",
        "alt": "🤹",
        "title": "person juggling"
    },
    ":person_juggling_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3fb.svg",
        "alt": "🤹🏻",
        "title": "person juggling"
    },
    ":person_juggling_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3fc.svg",
        "alt": "🤹🏼",
        "title": "person juggling"
    },
    ":person_juggling_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3fd.svg",
        "alt": "🤹🏽",
        "title": "person juggling"
    },
    ":person_juggling_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3fe.svg",
        "alt": "🤹🏾",
        "title": "person juggling"
    },
    ":person_juggling_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3ff.svg",
        "alt": "🤹🏿",
        "title": "person juggling"
    },
    ":man_juggling:": {
        "src": "/images/emojis/unicode/svg/1f939-200d-2642-fe0f.svg",
        "alt": "🤹‍♂️",
        "title": "man juggling"
    },
    ":man_juggling_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3fb-200d-2642-fe0f.svg",
        "alt": "🤹🏻‍♂️",
        "title": "man juggling"
    },
    ":man_juggling_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3fc-200d-2642-fe0f.svg",
        "alt": "🤹🏼‍♂️",
        "title": "man juggling"
    },
    ":man_juggling_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3fd-200d-2642-fe0f.svg",
        "alt": "🤹🏽‍♂️",
        "title": "man juggling"
    },
    ":man_juggling_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3fe-200d-2642-fe0f.svg",
        "alt": "🤹🏾‍♂️",
        "title": "man juggling"
    },
    ":man_juggling_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3ff-200d-2642-fe0f.svg",
        "alt": "🤹🏿‍♂️",
        "title": "man juggling"
    },
    ":woman_juggling:": {
        "src": "/images/emojis/unicode/svg/1f939-200d-2640-fe0f.svg",
        "alt": "🤹‍♀️",
        "title": "woman juggling"
    },
    ":woman_juggling_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3fb-200d-2640-fe0f.svg",
        "alt": "🤹🏻‍♀️",
        "title": "woman juggling"
    },
    ":woman_juggling_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3fc-200d-2640-fe0f.svg",
        "alt": "🤹🏼‍♀️",
        "title": "woman juggling"
    },
    ":woman_juggling_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3fd-200d-2640-fe0f.svg",
        "alt": "🤹🏽‍♀️",
        "title": "woman juggling"
    },
    ":woman_juggling_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3fe-200d-2640-fe0f.svg",
        "alt": "🤹🏾‍♀️",
        "title": "woman juggling"
    },
    ":woman_juggling_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f939-1f3ff-200d-2640-fe0f.svg",
        "alt": "🤹🏿‍♀️",
        "title": "woman juggling"
    },
    ":person_in_lotus_position:": {
        "src": "/images/emojis/unicode/svg/1f9d8.svg",
        "alt": "🧘",
        "title": "person in lotus position"
    },
    ":person_in_lotus_position_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3fb.svg",
        "alt": "🧘🏻",
        "title": "person in lotus position"
    },
    ":person_in_lotus_position_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3fc.svg",
        "alt": "🧘🏼",
        "title": "person in lotus position"
    },
    ":person_in_lotus_position_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3fd.svg",
        "alt": "🧘🏽",
        "title": "person in lotus position"
    },
    ":person_in_lotus_position_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3fe.svg",
        "alt": "🧘🏾",
        "title": "person in lotus position"
    },
    ":person_in_lotus_position_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3ff.svg",
        "alt": "🧘🏿",
        "title": "person in lotus position"
    },
    ":man_in_lotus_position:": {
        "src": "/images/emojis/unicode/svg/1f9d8-200d-2642-fe0f.svg",
        "alt": "🧘‍♂️",
        "title": "man in lotus position"
    },
    ":man_in_lotus_position_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3fb-200d-2642-fe0f.svg",
        "alt": "🧘🏻‍♂️",
        "title": "man in lotus position"
    },
    ":man_in_lotus_position_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3fc-200d-2642-fe0f.svg",
        "alt": "🧘🏼‍♂️",
        "title": "man in lotus position"
    },
    ":man_in_lotus_position_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3fd-200d-2642-fe0f.svg",
        "alt": "🧘🏽‍♂️",
        "title": "man in lotus position"
    },
    ":man_in_lotus_position_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3fe-200d-2642-fe0f.svg",
        "alt": "🧘🏾‍♂️",
        "title": "man in lotus position"
    },
    ":man_in_lotus_position_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3ff-200d-2642-fe0f.svg",
        "alt": "🧘🏿‍♂️",
        "title": "man in lotus position"
    },
    ":woman_in_lotus_position:": {
        "src": "/images/emojis/unicode/svg/1f9d8-200d-2640-fe0f.svg",
        "alt": "🧘‍♀️",
        "title": "woman in lotus position"
    },
    ":woman_in_lotus_position_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3fb-200d-2640-fe0f.svg",
        "alt": "🧘🏻‍♀️",
        "title": "woman in lotus position"
    },
    ":woman_in_lotus_position_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3fc-200d-2640-fe0f.svg",
        "alt": "🧘🏼‍♀️",
        "title": "woman in lotus position"
    },
    ":woman_in_lotus_position_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3fd-200d-2640-fe0f.svg",
        "alt": "🧘🏽‍♀️",
        "title": "woman in lotus position"
    },
    ":woman_in_lotus_position_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3fe-200d-2640-fe0f.svg",
        "alt": "🧘🏾‍♀️",
        "title": "woman in lotus position"
    },
    ":woman_in_lotus_position_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d8-1f3ff-200d-2640-fe0f.svg",
        "alt": "🧘🏿‍♀️",
        "title": "woman in lotus position"
    },
    ":person_taking_bath:": {
        "src": "/images/emojis/unicode/svg/1f6c0.svg",
        "alt": "🛀",
        "title": "person taking bath"
    },
    ":person_taking_bath_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6c0-1f3fb.svg",
        "alt": "🛀🏻",
        "title": "person taking bath"
    },
    ":person_taking_bath_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6c0-1f3fc.svg",
        "alt": "🛀🏼",
        "title": "person taking bath"
    },
    ":person_taking_bath_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6c0-1f3fd.svg",
        "alt": "🛀🏽",
        "title": "person taking bath"
    },
    ":person_taking_bath_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6c0-1f3fe.svg",
        "alt": "🛀🏾",
        "title": "person taking bath"
    },
    ":person_taking_bath_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6c0-1f3ff.svg",
        "alt": "🛀🏿",
        "title": "person taking bath"
    },
    ":person_in_bed:": {
        "src": "/images/emojis/unicode/svg/1f6cc.svg",
        "alt": "🛌",
        "title": "person in bed"
    },
    ":person_in_bed_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6cc-1f3fb.svg",
        "alt": "🛌🏻",
        "title": "person in bed"
    },
    ":person_in_bed_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6cc-1f3fc.svg",
        "alt": "🛌🏼",
        "title": "person in bed"
    },
    ":person_in_bed_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6cc-1f3fd.svg",
        "alt": "🛌🏽",
        "title": "person in bed"
    },
    ":person_in_bed_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6cc-1f3fe.svg",
        "alt": "🛌🏾",
        "title": "person in bed"
    },
    ":person_in_bed_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f6cc-1f3ff.svg",
        "alt": "🛌🏿",
        "title": "person in bed"
    },
    ":people_holding_hands:": {
        "src": "/images/emojis/unicode/svg/1f9d1-200d-1f91d-200d-1f9d1.svg",
        "alt": "🧑‍🤝‍🧑",
        "title": "people holding hands"
    },
    ":people_holding_hands_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fb.svg",
        "alt": "🧑🏻‍🤝‍🧑🏻",
        "title": "people holding hands"
    },
    ":people_holding_hands_light_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fc.svg",
        "alt": "🧑🏻‍🤝‍🧑🏼",
        "title": "people holding hands"
    },
    ":people_holding_hands_light_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fd.svg",
        "alt": "🧑🏻‍🤝‍🧑🏽",
        "title": "people holding hands"
    },
    ":people_holding_hands_light_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fe.svg",
        "alt": "🧑🏻‍🤝‍🧑🏾",
        "title": "people holding hands"
    },
    ":people_holding_hands_light_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3ff.svg",
        "alt": "🧑🏻‍🤝‍🧑🏿",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_light_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fb.svg",
        "alt": "🧑🏼‍🤝‍🧑🏻",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fc.svg",
        "alt": "🧑🏼‍🤝‍🧑🏼",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_light_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fd.svg",
        "alt": "🧑🏼‍🤝‍🧑🏽",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_light_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fe.svg",
        "alt": "🧑🏼‍🤝‍🧑🏾",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_light_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3ff.svg",
        "alt": "🧑🏼‍🤝‍🧑🏿",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fb.svg",
        "alt": "🧑🏽‍🤝‍🧑🏻",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fc.svg",
        "alt": "🧑🏽‍🤝‍🧑🏼",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fd.svg",
        "alt": "🧑🏽‍🤝‍🧑🏽",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fe.svg",
        "alt": "🧑🏽‍🤝‍🧑🏾",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3ff.svg",
        "alt": "🧑🏽‍🤝‍🧑🏿",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_dark_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fb.svg",
        "alt": "🧑🏾‍🤝‍🧑🏻",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_dark_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fc.svg",
        "alt": "🧑🏾‍🤝‍🧑🏼",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_dark_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fd.svg",
        "alt": "🧑🏾‍🤝‍🧑🏽",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fe.svg",
        "alt": "🧑🏾‍🤝‍🧑🏾",
        "title": "people holding hands"
    },
    ":people_holding_hands_medium_dark_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3ff.svg",
        "alt": "🧑🏾‍🤝‍🧑🏿",
        "title": "people holding hands"
    },
    ":people_holding_hands_dark_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fb.svg",
        "alt": "🧑🏿‍🤝‍🧑🏻",
        "title": "people holding hands"
    },
    ":people_holding_hands_dark_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fc.svg",
        "alt": "🧑🏿‍🤝‍🧑🏼",
        "title": "people holding hands"
    },
    ":people_holding_hands_dark_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fd.svg",
        "alt": "🧑🏿‍🤝‍🧑🏽",
        "title": "people holding hands"
    },
    ":people_holding_hands_dark_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fe.svg",
        "alt": "🧑🏿‍🤝‍🧑🏾",
        "title": "people holding hands"
    },
    ":people_holding_hands_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3ff.svg",
        "alt": "🧑🏿‍🤝‍🧑🏿",
        "title": "people holding hands"
    },
    ":women_holding_hands:": {
        "src": "/images/emojis/unicode/svg/1f46d.svg",
        "alt": "👭",
        "title": "women holding hands"
    },
    ":women_holding_hands_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46d-1f3fb.svg",
        "alt": "👭🏻",
        "title": "women holding hands"
    },
    ":women_holding_hands_light_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f469-1f3fc.svg",
        "alt": "👩🏻‍🤝‍👩🏼",
        "title": "women holding hands"
    },
    ":women_holding_hands_light_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f469-1f3fd.svg",
        "alt": "👩🏻‍🤝‍👩🏽",
        "title": "women holding hands"
    },
    ":women_holding_hands_light_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f469-1f3fe.svg",
        "alt": "👩🏻‍🤝‍👩🏾",
        "title": "women holding hands"
    },
    ":women_holding_hands_light_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f469-1f3ff.svg",
        "alt": "👩🏻‍🤝‍👩🏿",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_light_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f469-1f3fb.svg",
        "alt": "👩🏼‍🤝‍👩🏻",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46d-1f3fc.svg",
        "alt": "👭🏼",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_light_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f469-1f3fd.svg",
        "alt": "👩🏼‍🤝‍👩🏽",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_light_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f469-1f3fe.svg",
        "alt": "👩🏼‍🤝‍👩🏾",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_light_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f469-1f3ff.svg",
        "alt": "👩🏼‍🤝‍👩🏿",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f469-1f3fb.svg",
        "alt": "👩🏽‍🤝‍👩🏻",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f469-1f3fc.svg",
        "alt": "👩🏽‍🤝‍👩🏼",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46d-1f3fd.svg",
        "alt": "👭🏽",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f469-1f3fe.svg",
        "alt": "👩🏽‍🤝‍👩🏾",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f469-1f3ff.svg",
        "alt": "👩🏽‍🤝‍👩🏿",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_dark_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f469-1f3fb.svg",
        "alt": "👩🏾‍🤝‍👩🏻",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_dark_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f469-1f3fc.svg",
        "alt": "👩🏾‍🤝‍👩🏼",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_dark_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f469-1f3fd.svg",
        "alt": "👩🏾‍🤝‍👩🏽",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46d-1f3fe.svg",
        "alt": "👭🏾",
        "title": "women holding hands"
    },
    ":women_holding_hands_medium_dark_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f469-1f3ff.svg",
        "alt": "👩🏾‍🤝‍👩🏿",
        "title": "women holding hands"
    },
    ":women_holding_hands_dark_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f469-1f3fb.svg",
        "alt": "👩🏿‍🤝‍👩🏻",
        "title": "women holding hands"
    },
    ":women_holding_hands_dark_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f469-1f3fc.svg",
        "alt": "👩🏿‍🤝‍👩🏼",
        "title": "women holding hands"
    },
    ":women_holding_hands_dark_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f469-1f3fd.svg",
        "alt": "👩🏿‍🤝‍👩🏽",
        "title": "women holding hands"
    },
    ":women_holding_hands_dark_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f469-1f3fe.svg",
        "alt": "👩🏿‍🤝‍👩🏾",
        "title": "women holding hands"
    },
    ":women_holding_hands_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46d-1f3ff.svg",
        "alt": "👭🏿",
        "title": "women holding hands"
    },
    ":woman_and_man_holding_hands:": {
        "src": "/images/emojis/unicode/svg/1f46b.svg",
        "alt": "👫",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46b-1f3fb.svg",
        "alt": "👫🏻",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_light_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f468-1f3fc.svg",
        "alt": "👩🏻‍🤝‍👨🏼",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_light_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f468-1f3fd.svg",
        "alt": "👩🏻‍🤝‍👨🏽",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_light_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f468-1f3fe.svg",
        "alt": "👩🏻‍🤝‍👨🏾",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_light_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fb-200d-1f91d-200d-1f468-1f3ff.svg",
        "alt": "👩🏻‍🤝‍👨🏿",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_light_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f468-1f3fb.svg",
        "alt": "👩🏼‍🤝‍👨🏻",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46b-1f3fc.svg",
        "alt": "👫🏼",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_light_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f468-1f3fd.svg",
        "alt": "👩🏼‍🤝‍👨🏽",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_light_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f468-1f3fe.svg",
        "alt": "👩🏼‍🤝‍👨🏾",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_light_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fc-200d-1f91d-200d-1f468-1f3ff.svg",
        "alt": "👩🏼‍🤝‍👨🏿",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f468-1f3fb.svg",
        "alt": "👩🏽‍🤝‍👨🏻",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f468-1f3fc.svg",
        "alt": "👩🏽‍🤝‍👨🏼",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46b-1f3fd.svg",
        "alt": "👫🏽",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f468-1f3fe.svg",
        "alt": "👩🏽‍🤝‍👨🏾",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fd-200d-1f91d-200d-1f468-1f3ff.svg",
        "alt": "👩🏽‍🤝‍👨🏿",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_dark_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f468-1f3fb.svg",
        "alt": "👩🏾‍🤝‍👨🏻",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_dark_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f468-1f3fc.svg",
        "alt": "👩🏾‍🤝‍👨🏼",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_dark_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f468-1f3fd.svg",
        "alt": "👩🏾‍🤝‍👨🏽",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46b-1f3fe.svg",
        "alt": "👫🏾",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_medium_dark_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3fe-200d-1f91d-200d-1f468-1f3ff.svg",
        "alt": "👩🏾‍🤝‍👨🏿",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_dark_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f468-1f3fb.svg",
        "alt": "👩🏿‍🤝‍👨🏻",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_dark_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f468-1f3fc.svg",
        "alt": "👩🏿‍🤝‍👨🏼",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_dark_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f468-1f3fd.svg",
        "alt": "👩🏿‍🤝‍👨🏽",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_dark_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f469-1f3ff-200d-1f91d-200d-1f468-1f3fe.svg",
        "alt": "👩🏿‍🤝‍👨🏾",
        "title": "woman and man holding hands"
    },
    ":woman_and_man_holding_hands_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46b-1f3ff.svg",
        "alt": "👫🏿",
        "title": "woman and man holding hands"
    },
    ":men_holding_hands:": {
        "src": "/images/emojis/unicode/svg/1f46c.svg",
        "alt": "👬",
        "title": "men holding hands"
    },
    ":men_holding_hands_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46c-1f3fb.svg",
        "alt": "👬🏻",
        "title": "men holding hands"
    },
    ":men_holding_hands_light_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f91d-200d-1f468-1f3fc.svg",
        "alt": "👨🏻‍🤝‍👨🏼",
        "title": "men holding hands"
    },
    ":men_holding_hands_light_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f91d-200d-1f468-1f3fd.svg",
        "alt": "👨🏻‍🤝‍👨🏽",
        "title": "men holding hands"
    },
    ":men_holding_hands_light_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f91d-200d-1f468-1f3fe.svg",
        "alt": "👨🏻‍🤝‍👨🏾",
        "title": "men holding hands"
    },
    ":men_holding_hands_light_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fb-200d-1f91d-200d-1f468-1f3ff.svg",
        "alt": "👨🏻‍🤝‍👨🏿",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_light_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f91d-200d-1f468-1f3fb.svg",
        "alt": "👨🏼‍🤝‍👨🏻",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46c-1f3fc.svg",
        "alt": "👬🏼",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_light_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f91d-200d-1f468-1f3fd.svg",
        "alt": "👨🏼‍🤝‍👨🏽",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_light_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f91d-200d-1f468-1f3fe.svg",
        "alt": "👨🏼‍🤝‍👨🏾",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_light_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fc-200d-1f91d-200d-1f468-1f3ff.svg",
        "alt": "👨🏼‍🤝‍👨🏿",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f91d-200d-1f468-1f3fb.svg",
        "alt": "👨🏽‍🤝‍👨🏻",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f91d-200d-1f468-1f3fc.svg",
        "alt": "👨🏽‍🤝‍👨🏼",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46c-1f3fd.svg",
        "alt": "👬🏽",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f91d-200d-1f468-1f3fe.svg",
        "alt": "👨🏽‍🤝‍👨🏾",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fd-200d-1f91d-200d-1f468-1f3ff.svg",
        "alt": "👨🏽‍🤝‍👨🏿",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_dark_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f91d-200d-1f468-1f3fb.svg",
        "alt": "👨🏾‍🤝‍👨🏻",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_dark_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f91d-200d-1f468-1f3fc.svg",
        "alt": "👨🏾‍🤝‍👨🏼",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_dark_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f91d-200d-1f468-1f3fd.svg",
        "alt": "👨🏾‍🤝‍👨🏽",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46c-1f3fe.svg",
        "alt": "👬🏾",
        "title": "men holding hands"
    },
    ":men_holding_hands_medium_dark_skin_tone_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3fe-200d-1f91d-200d-1f468-1f3ff.svg",
        "alt": "👨🏾‍🤝‍👨🏿",
        "title": "men holding hands"
    },
    ":men_holding_hands_dark_skin_tone_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f91d-200d-1f468-1f3fb.svg",
        "alt": "👨🏿‍🤝‍👨🏻",
        "title": "men holding hands"
    },
    ":men_holding_hands_dark_skin_tone_medium_light_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f91d-200d-1f468-1f3fc.svg",
        "alt": "👨🏿‍🤝‍👨🏼",
        "title": "men holding hands"
    },
    ":men_holding_hands_dark_skin_tone_medium_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f91d-200d-1f468-1f3fd.svg",
        "alt": "👨🏿‍🤝‍👨🏽",
        "title": "men holding hands"
    },
    ":men_holding_hands_dark_skin_tone_medium_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f468-1f3ff-200d-1f91d-200d-1f468-1f3fe.svg",
        "alt": "👨🏿‍🤝‍👨🏾",
        "title": "men holding hands"
    },
    ":men_holding_hands_dark_skin_tone:": {
        "src": "/images/emojis/unicode/svg/1f46c-1f3ff.svg",
        "alt": "👬🏿",
        "title": "men holding hands"
    },
    ":kiss:": {
        "src": "/images/emojis/unicode/svg/1f48f.svg",
        "alt": "💏",
        "title": "kiss"
    },
    ":kiss_woman_man:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-2764-fe0f-200d-1f48b-200d-1f468.svg",
        "alt": "👩‍❤️‍💋‍👨",
        "title": "kiss"
    },
    ":kiss_man_man:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.svg",
        "alt": "👨‍❤️‍💋‍👨",
        "title": "kiss"
    },
    ":kiss_woman_woman:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.svg",
        "alt": "👩‍❤️‍💋‍👩",
        "title": "kiss"
    },
    ":couple_with_heart:": {
        "src": "/images/emojis/unicode/svg/1f491.svg",
        "alt": "💑",
        "title": "couple with heart"
    },
    ":couple_with_heart_woman_man:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-2764-fe0f-200d-1f468.svg",
        "alt": "👩‍❤️‍👨",
        "title": "couple with heart"
    },
    ":couple_with_heart_man_man:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-2764-fe0f-200d-1f468.svg",
        "alt": "👨‍❤️‍👨",
        "title": "couple with heart"
    },
    ":couple_with_heart_woman_woman:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-2764-fe0f-200d-1f469.svg",
        "alt": "👩‍❤️‍👩",
        "title": "couple with heart"
    },
    ":family:": {
        "src": "/images/emojis/unicode/svg/1f46a.svg",
        "alt": "👪",
        "title": "family"
    },
    ":family_man_woman_boy:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f469-200d-1f466.svg",
        "alt": "👨‍👩‍👦",
        "title": "family"
    },
    ":family_man_woman_girl:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f469-200d-1f467.svg",
        "alt": "👨‍👩‍👧",
        "title": "family"
    },
    ":family_man_woman_girl_boy:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f469-200d-1f467-200d-1f466.svg",
        "alt": "👨‍👩‍👧‍👦",
        "title": "family"
    },
    ":family_man_woman_boy_boy:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f469-200d-1f466-200d-1f466.svg",
        "alt": "👨‍👩‍👦‍👦",
        "title": "family"
    },
    ":family_man_woman_girl_girl:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f469-200d-1f467-200d-1f467.svg",
        "alt": "👨‍👩‍👧‍👧",
        "title": "family"
    },
    ":family_man_man_boy:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f468-200d-1f466.svg",
        "alt": "👨‍👨‍👦",
        "title": "family"
    },
    ":family_man_man_girl:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f468-200d-1f467.svg",
        "alt": "👨‍👨‍👧",
        "title": "family"
    },
    ":family_man_man_girl_boy:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f468-200d-1f467-200d-1f466.svg",
        "alt": "👨‍👨‍👧‍👦",
        "title": "family"
    },
    ":family_man_man_boy_boy:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f468-200d-1f466-200d-1f466.svg",
        "alt": "👨‍👨‍👦‍👦",
        "title": "family"
    },
    ":family_man_man_girl_girl:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f468-200d-1f467-200d-1f467.svg",
        "alt": "👨‍👨‍👧‍👧",
        "title": "family"
    },
    ":family_woman_woman_boy:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f469-200d-1f466.svg",
        "alt": "👩‍👩‍👦",
        "title": "family"
    },
    ":family_woman_woman_girl:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f469-200d-1f467.svg",
        "alt": "👩‍👩‍👧",
        "title": "family"
    },
    ":family_woman_woman_girl_boy:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f469-200d-1f467-200d-1f466.svg",
        "alt": "👩‍👩‍👧‍👦",
        "title": "family"
    },
    ":family_woman_woman_boy_boy:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f469-200d-1f466-200d-1f466.svg",
        "alt": "👩‍👩‍👦‍👦",
        "title": "family"
    },
    ":family_woman_woman_girl_girl:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f469-200d-1f467-200d-1f467.svg",
        "alt": "👩‍👩‍👧‍👧",
        "title": "family"
    },
    ":family_man_boy:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f466.svg",
        "alt": "👨‍👦",
        "title": "family"
    },
    ":family_man_boy_boy:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f466-200d-1f466.svg",
        "alt": "👨‍👦‍👦",
        "title": "family"
    },
    ":family_man_girl:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f467.svg",
        "alt": "👨‍👧",
        "title": "family"
    },
    ":family_man_girl_boy:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f467-200d-1f466.svg",
        "alt": "👨‍👧‍👦",
        "title": "family"
    },
    ":family_man_girl_girl:": {
        "src": "/images/emojis/unicode/svg/1f468-200d-1f467-200d-1f467.svg",
        "alt": "👨‍👧‍👧",
        "title": "family"
    },
    ":family_woman_boy:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f466.svg",
        "alt": "👩‍👦",
        "title": "family"
    },
    ":family_woman_boy_boy:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f466-200d-1f466.svg",
        "alt": "👩‍👦‍👦",
        "title": "family"
    },
    ":family_woman_girl:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f467.svg",
        "alt": "👩‍👧",
        "title": "family"
    },
    ":family_woman_girl_boy:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f467-200d-1f466.svg",
        "alt": "👩‍👧‍👦",
        "title": "family"
    },
    ":family_woman_girl_girl:": {
        "src": "/images/emojis/unicode/svg/1f469-200d-1f467-200d-1f467.svg",
        "alt": "👩‍👧‍👧",
        "title": "family"
    },
    ":speaking_head:": {
        "src": "/images/emojis/unicode/svg/1f5e3.svg",
        "alt": "🗣",
        "title": "speaking head"
    },
    ":bust_in_silhouette:": {
        "src": "/images/emojis/unicode/svg/1f464.svg",
        "alt": "👤",
        "title": "bust in silhouette"
    },
    ":busts_in_silhouette:": {
        "src": "/images/emojis/unicode/svg/1f465.svg",
        "alt": "👥",
        "title": "busts in silhouette"
    },
    ":people_hugging:": {
        "src": "/images/emojis/unicode/svg/1fac2.svg",
        "alt": "🫂",
        "title": "people hugging"
    },
    ":footprints:": {
        "src": "/images/emojis/unicode/svg/1f463.svg",
        "alt": "👣",
        "title": "footprints"
    },
    ":monkey_face:": {
        "src": "/images/emojis/unicode/svg/1f435.svg",
        "alt": "🐵",
        "title": "monkey face"
    },
    ":monkey:": {
        "src": "/images/emojis/unicode/svg/1f412.svg",
        "alt": "🐒",
        "title": "monkey"
    },
    ":gorilla:": {
        "src": "/images/emojis/unicode/svg/1f98d.svg",
        "alt": "🦍",
        "title": "gorilla"
    },
    ":orangutan:": {
        "src": "/images/emojis/unicode/svg/1f9a7.svg",
        "alt": "🦧",
        "title": "orangutan"
    },
    ":dog_face:": {
        "src": "/images/emojis/unicode/svg/1f436.svg",
        "alt": "🐶",
        "title": "dog face"
    },
    ":dog:": {
        "src": "/images/emojis/unicode/svg/1f415.svg",
        "alt": "🐕",
        "title": "dog"
    },
    ":guide_dog:": {
        "src": "/images/emojis/unicode/svg/1f9ae.svg",
        "alt": "🦮",
        "title": "guide dog"
    },
    ":service_dog:": {
        "src": "/images/emojis/unicode/svg/1f415-200d-1f9ba.svg",
        "alt": "🐕‍🦺",
        "title": "service dog"
    },
    ":poodle:": {
        "src": "/images/emojis/unicode/svg/1f429.svg",
        "alt": "🐩",
        "title": "poodle"
    },
    ":wolf:": {
        "src": "/images/emojis/unicode/svg/1f43a.svg",
        "alt": "🐺",
        "title": "wolf"
    },
    ":fox:": {
        "src": "/images/emojis/unicode/svg/1f98a.svg",
        "alt": "🦊",
        "title": "fox"
    },
    ":raccoon:": {
        "src": "/images/emojis/unicode/svg/1f99d.svg",
        "alt": "🦝",
        "title": "raccoon"
    },
    ":cat_face:": {
        "src": "/images/emojis/unicode/svg/1f431.svg",
        "alt": "🐱",
        "title": "cat face"
    },
    ":cat:": {
        "src": "/images/emojis/unicode/svg/1f408.svg",
        "alt": "🐈",
        "title": "cat"
    },
    ":black_cat:": {
        "src": "/images/emojis/unicode/svg/1f408-200d-2b1b.svg",
        "alt": "🐈‍⬛",
        "title": "black cat"
    },
    ":lion:": {
        "src": "/images/emojis/unicode/svg/1f981.svg",
        "alt": "🦁",
        "title": "lion"
    },
    ":tiger_face:": {
        "src": "/images/emojis/unicode/svg/1f42f.svg",
        "alt": "🐯",
        "title": "tiger face"
    },
    ":tiger:": {
        "src": "/images/emojis/unicode/svg/1f405.svg",
        "alt": "🐅",
        "title": "tiger"
    },
    ":leopard:": {
        "src": "/images/emojis/unicode/svg/1f406.svg",
        "alt": "🐆",
        "title": "leopard"
    },
    ":horse_face:": {
        "src": "/images/emojis/unicode/svg/1f434.svg",
        "alt": "🐴",
        "title": "horse face"
    },
    ":horse:": {
        "src": "/images/emojis/unicode/svg/1f40e.svg",
        "alt": "🐎",
        "title": "horse"
    },
    ":unicorn:": {
        "src": "/images/emojis/unicode/svg/1f984.svg",
        "alt": "🦄",
        "title": "unicorn"
    },
    ":zebra:": {
        "src": "/images/emojis/unicode/svg/1f993.svg",
        "alt": "🦓",
        "title": "zebra"
    },
    ":deer:": {
        "src": "/images/emojis/unicode/svg/1f98c.svg",
        "alt": "🦌",
        "title": "deer"
    },
    ":bison:": {
        "src": "/images/emojis/unicode/svg/1f9ac.svg",
        "alt": "🦬",
        "title": "bison"
    },
    ":cow_face:": {
        "src": "/images/emojis/unicode/svg/1f42e.svg",
        "alt": "🐮",
        "title": "cow face"
    },
    ":ox:": {
        "src": "/images/emojis/unicode/svg/1f402.svg",
        "alt": "🐂",
        "title": "ox"
    },
    ":water_buffalo:": {
        "src": "/images/emojis/unicode/svg/1f403.svg",
        "alt": "🐃",
        "title": "water buffalo"
    },
    ":cow:": {
        "src": "/images/emojis/unicode/svg/1f404.svg",
        "alt": "🐄",
        "title": "cow"
    },
    ":pig_face:": {
        "src": "/images/emojis/unicode/svg/1f437.svg",
        "alt": "🐷",
        "title": "pig face"
    },
    ":pig:": {
        "src": "/images/emojis/unicode/svg/1f416.svg",
        "alt": "🐖",
        "title": "pig"
    },
    ":boar:": {
        "src": "/images/emojis/unicode/svg/1f417.svg",
        "alt": "🐗",
        "title": "boar"
    },
    ":pig_nose:": {
        "src": "/images/emojis/unicode/svg/1f43d.svg",
        "alt": "🐽",
        "title": "pig nose"
    },
    ":ram:": {
        "src": "/images/emojis/unicode/svg/1f40f.svg",
        "alt": "🐏",
        "title": "ram"
    },
    ":ewe:": {
        "src": "/images/emojis/unicode/svg/1f411.svg",
        "alt": "🐑",
        "title": "ewe"
    },
    ":goat:": {
        "src": "/images/emojis/unicode/svg/1f410.svg",
        "alt": "🐐",
        "title": "goat"
    },
    ":camel:": {
        "src": "/images/emojis/unicode/svg/1f42a.svg",
        "alt": "🐪",
        "title": "camel"
    },
    ":two_hump_camel:": {
        "src": "/images/emojis/unicode/svg/1f42b.svg",
        "alt": "🐫",
        "title": "two-hump camel"
    },
    ":llama:": {
        "src": "/images/emojis/unicode/svg/1f999.svg",
        "alt": "🦙",
        "title": "llama"
    },
    ":giraffe:": {
        "src": "/images/emojis/unicode/svg/1f992.svg",
        "alt": "🦒",
        "title": "giraffe"
    },
    ":elephant:": {
        "src": "/images/emojis/unicode/svg/1f418.svg",
        "alt": "🐘",
        "title": "elephant"
    },
    ":mammoth:": {
        "src": "/images/emojis/unicode/svg/1f9a3.svg",
        "alt": "🦣",
        "title": "mammoth"
    },
    ":rhinoceros:": {
        "src": "/images/emojis/unicode/svg/1f98f.svg",
        "alt": "🦏",
        "title": "rhinoceros"
    },
    ":hippopotamus:": {
        "src": "/images/emojis/unicode/svg/1f99b.svg",
        "alt": "🦛",
        "title": "hippopotamus"
    },
    ":mouse_face:": {
        "src": "/images/emojis/unicode/svg/1f42d.svg",
        "alt": "🐭",
        "title": "mouse face"
    },
    ":mouse:": {
        "src": "/images/emojis/unicode/svg/1f401.svg",
        "alt": "🐁",
        "title": "mouse"
    },
    ":rat:": {
        "src": "/images/emojis/unicode/svg/1f400.svg",
        "alt": "🐀",
        "title": "rat"
    },
    ":hamster:": {
        "src": "/images/emojis/unicode/svg/1f439.svg",
        "alt": "🐹",
        "title": "hamster"
    },
    ":rabbit_face:": {
        "src": "/images/emojis/unicode/svg/1f430.svg",
        "alt": "🐰",
        "title": "rabbit face"
    },
    ":rabbit:": {
        "src": "/images/emojis/unicode/svg/1f407.svg",
        "alt": "🐇",
        "title": "rabbit"
    },
    ":chipmunk:": {
        "src": "/images/emojis/unicode/svg/1f43f.svg",
        "alt": "🐿",
        "title": "chipmunk"
    },
    ":beaver:": {
        "src": "/images/emojis/unicode/svg/1f9ab.svg",
        "alt": "🦫",
        "title": "beaver"
    },
    ":hedgehog:": {
        "src": "/images/emojis/unicode/svg/1f994.svg",
        "alt": "🦔",
        "title": "hedgehog"
    },
    ":bat:": {
        "src": "/images/emojis/unicode/svg/1f987.svg",
        "alt": "🦇",
        "title": "bat"
    },
    ":bear:": {
        "src": "/images/emojis/unicode/svg/1f43b.svg",
        "alt": "🐻",
        "title": "bear"
    },
    ":polar_bear:": {
        "src": "/images/emojis/unicode/svg/1f43b-200d-2744-fe0f.svg",
        "alt": "🐻‍❄️",
        "title": "polar bear"
    },
    ":koala:": {
        "src": "/images/emojis/unicode/svg/1f428.svg",
        "alt": "🐨",
        "title": "koala"
    },
    ":panda:": {
        "src": "/images/emojis/unicode/svg/1f43c.svg",
        "alt": "🐼",
        "title": "panda"
    },
    ":sloth:": {
        "src": "/images/emojis/unicode/svg/1f9a5.svg",
        "alt": "🦥",
        "title": "sloth"
    },
    ":otter:": {
        "src": "/images/emojis/unicode/svg/1f9a6.svg",
        "alt": "🦦",
        "title": "otter"
    },
    ":skunk:": {
        "src": "/images/emojis/unicode/svg/1f9a8.svg",
        "alt": "🦨",
        "title": "skunk"
    },
    ":kangaroo:": {
        "src": "/images/emojis/unicode/svg/1f998.svg",
        "alt": "🦘",
        "title": "kangaroo"
    },
    ":badger:": {
        "src": "/images/emojis/unicode/svg/1f9a1.svg",
        "alt": "🦡",
        "title": "badger"
    },
    ":paw_prints:": {
        "src": "/images/emojis/unicode/svg/1f43e.svg",
        "alt": "🐾",
        "title": "paw prints"
    },
    ":turkey:": {
        "src": "/images/emojis/unicode/svg/1f983.svg",
        "alt": "🦃",
        "title": "turkey"
    },
    ":chicken:": {
        "src": "/images/emojis/unicode/svg/1f414.svg",
        "alt": "🐔",
        "title": "chicken"
    },
    ":rooster:": {
        "src": "/images/emojis/unicode/svg/1f413.svg",
        "alt": "🐓",
        "title": "rooster"
    },
    ":hatching_chick:": {
        "src": "/images/emojis/unicode/svg/1f423.svg",
        "alt": "🐣",
        "title": "hatching chick"
    },
    ":baby_chick:": {
        "src": "/images/emojis/unicode/svg/1f424.svg",
        "alt": "🐤",
        "title": "baby chick"
    },
    ":front_facing_baby_chick:": {
        "src": "/images/emojis/unicode/svg/1f425.svg",
        "alt": "🐥",
        "title": "front-facing baby chick"
    },
    ":bird:": {
        "src": "/images/emojis/unicode/svg/1f426.svg",
        "alt": "🐦",
        "title": "bird"
    },
    ":penguin:": {
        "src": "/images/emojis/unicode/svg/1f427.svg",
        "alt": "🐧",
        "title": "penguin"
    },
    ":dove:": {
        "src": "/images/emojis/unicode/svg/1f54a.svg",
        "alt": "🕊",
        "title": "dove"
    },
    ":eagle:": {
        "src": "/images/emojis/unicode/svg/1f985.svg",
        "alt": "🦅",
        "title": "eagle"
    },
    ":duck:": {
        "src": "/images/emojis/unicode/svg/1f986.svg",
        "alt": "🦆",
        "title": "duck"
    },
    ":swan:": {
        "src": "/images/emojis/unicode/svg/1f9a2.svg",
        "alt": "🦢",
        "title": "swan"
    },
    ":owl:": {
        "src": "/images/emojis/unicode/svg/1f989.svg",
        "alt": "🦉",
        "title": "owl"
    },
    ":dodo:": {
        "src": "/images/emojis/unicode/svg/1f9a4.svg",
        "alt": "🦤",
        "title": "dodo"
    },
    ":feather:": {
        "src": "/images/emojis/unicode/svg/1fab6.svg",
        "alt": "🪶",
        "title": "feather"
    },
    ":flamingo:": {
        "src": "/images/emojis/unicode/svg/1f9a9.svg",
        "alt": "🦩",
        "title": "flamingo"
    },
    ":peacock:": {
        "src": "/images/emojis/unicode/svg/1f99a.svg",
        "alt": "🦚",
        "title": "peacock"
    },
    ":parrot:": {
        "src": "/images/emojis/unicode/svg/1f99c.svg",
        "alt": "🦜",
        "title": "parrot"
    },
    ":frog:": {
        "src": "/images/emojis/unicode/svg/1f438.svg",
        "alt": "🐸",
        "title": "frog"
    },
    ":crocodile:": {
        "src": "/images/emojis/unicode/svg/1f40a.svg",
        "alt": "🐊",
        "title": "crocodile"
    },
    ":turtle:": {
        "src": "/images/emojis/unicode/svg/1f422.svg",
        "alt": "🐢",
        "title": "turtle"
    },
    ":lizard:": {
        "src": "/images/emojis/unicode/svg/1f98e.svg",
        "alt": "🦎",
        "title": "lizard"
    },
    ":snake:": {
        "src": "/images/emojis/unicode/svg/1f40d.svg",
        "alt": "🐍",
        "title": "snake"
    },
    ":dragon_face:": {
        "src": "/images/emojis/unicode/svg/1f432.svg",
        "alt": "🐲",
        "title": "dragon face"
    },
    ":dragon:": {
        "src": "/images/emojis/unicode/svg/1f409.svg",
        "alt": "🐉",
        "title": "dragon"
    },
    ":sauropod:": {
        "src": "/images/emojis/unicode/svg/1f995.svg",
        "alt": "🦕",
        "title": "sauropod"
    },
    ":t_rex:": {
        "src": "/images/emojis/unicode/svg/1f996.svg",
        "alt": "🦖",
        "title": "T-Rex"
    },
    ":spouting_whale:": {
        "src": "/images/emojis/unicode/svg/1f433.svg",
        "alt": "🐳",
        "title": "spouting whale"
    },
    ":whale:": {
        "src": "/images/emojis/unicode/svg/1f40b.svg",
        "alt": "🐋",
        "title": "whale"
    },
    ":dolphin:": {
        "src": "/images/emojis/unicode/svg/1f42c.svg",
        "alt": "🐬",
        "title": "dolphin"
    },
    ":seal:": {
        "src": "/images/emojis/unicode/svg/1f9ad.svg",
        "alt": "🦭",
        "title": "seal"
    },
    ":fish:": {
        "src": "/images/emojis/unicode/svg/1f41f.svg",
        "alt": "🐟",
        "title": "fish"
    },
    ":tropical_fish:": {
        "src": "/images/emojis/unicode/svg/1f420.svg",
        "alt": "🐠",
        "title": "tropical fish"
    },
    ":blowfish:": {
        "src": "/images/emojis/unicode/svg/1f421.svg",
        "alt": "🐡",
        "title": "blowfish"
    },
    ":shark:": {
        "src": "/images/emojis/unicode/svg/1f988.svg",
        "alt": "🦈",
        "title": "shark"
    },
    ":octopus:": {
        "src": "/images/emojis/unicode/svg/1f419.svg",
        "alt": "🐙",
        "title": "octopus"
    },
    ":spiral_shell:": {
        "src": "/images/emojis/unicode/svg/1f41a.svg",
        "alt": "🐚",
        "title": "spiral shell"
    },
    ":snail:": {
        "src": "/images/emojis/unicode/svg/1f40c.svg",
        "alt": "🐌",
        "title": "snail"
    },
    ":butterfly:": {
        "src": "/images/emojis/unicode/svg/1f98b.svg",
        "alt": "🦋",
        "title": "butterfly"
    },
    ":bug:": {
        "src": "/images/emojis/unicode/svg/1f41b.svg",
        "alt": "🐛",
        "title": "bug"
    },
    ":ant:": {
        "src": "/images/emojis/unicode/svg/1f41c.svg",
        "alt": "🐜",
        "title": "ant"
    },
    ":honeybee:": {
        "src": "/images/emojis/unicode/svg/1f41d.svg",
        "alt": "🐝",
        "title": "honeybee"
    },
    ":beetle:": {
        "src": "/images/emojis/unicode/svg/1fab2.svg",
        "alt": "🪲",
        "title": "beetle"
    },
    ":lady_beetle:": {
        "src": "/images/emojis/unicode/svg/1f41e.svg",
        "alt": "🐞",
        "title": "lady beetle"
    },
    ":cricket:": {
        "src": "/images/emojis/unicode/svg/1f997.svg",
        "alt": "🦗",
        "title": "cricket"
    },
    ":cockroach:": {
        "src": "/images/emojis/unicode/svg/1fab3.svg",
        "alt": "🪳",
        "title": "cockroach"
    },
    ":spider:": {
        "src": "/images/emojis/unicode/svg/1f577.svg",
        "alt": "🕷",
        "title": "spider"
    },
    ":spider_web:": {
        "src": "/images/emojis/unicode/svg/1f578.svg",
        "alt": "🕸",
        "title": "spider web"
    },
    ":scorpion:": {
        "src": "/images/emojis/unicode/svg/1f982.svg",
        "alt": "🦂",
        "title": "scorpion"
    },
    ":mosquito:": {
        "src": "/images/emojis/unicode/svg/1f99f.svg",
        "alt": "🦟",
        "title": "mosquito"
    },
    ":fly:": {
        "src": "/images/emojis/unicode/svg/1fab0.svg",
        "alt": "🪰",
        "title": "fly"
    },
    ":worm:": {
        "src": "/images/emojis/unicode/svg/1fab1.svg",
        "alt": "🪱",
        "title": "worm"
    },
    ":microbe:": {
        "src": "/images/emojis/unicode/svg/1f9a0.svg",
        "alt": "🦠",
        "title": "microbe"
    },
    ":bouquet:": {
        "src": "/images/emojis/unicode/svg/1f490.svg",
        "alt": "💐",
        "title": "bouquet"
    },
    ":cherry_blossom:": {
        "src": "/images/emojis/unicode/svg/1f338.svg",
        "alt": "🌸",
        "title": "cherry blossom"
    },
    ":white_flower:": {
        "src": "/images/emojis/unicode/svg/1f4ae.svg",
        "alt": "💮",
        "title": "white flower"
    },
    ":rosette:": {
        "src": "/images/emojis/unicode/svg/1f3f5.svg",
        "alt": "🏵",
        "title": "rosette"
    },
    ":rose:": {
        "src": "/images/emojis/unicode/svg/1f339.svg",
        "alt": "🌹",
        "title": "rose"
    },
    ":wilted_flower:": {
        "src": "/images/emojis/unicode/svg/1f940.svg",
        "alt": "🥀",
        "title": "wilted flower"
    },
    ":hibiscus:": {
        "src": "/images/emojis/unicode/svg/1f33a.svg",
        "alt": "🌺",
        "title": "hibiscus"
    },
    ":sunflower:": {
        "src": "/images/emojis/unicode/svg/1f33b.svg",
        "alt": "🌻",
        "title": "sunflower"
    },
    ":blossom:": {
        "src": "/images/emojis/unicode/svg/1f33c.svg",
        "alt": "🌼",
        "title": "blossom"
    },
    ":tulip:": {
        "src": "/images/emojis/unicode/svg/1f337.svg",
        "alt": "🌷",
        "title": "tulip"
    },
    ":seedling:": {
        "src": "/images/emojis/unicode/svg/1f331.svg",
        "alt": "🌱",
        "title": "seedling"
    },
    ":potted_plant:": {
        "src": "/images/emojis/unicode/svg/1fab4.svg",
        "alt": "🪴",
        "title": "potted plant"
    },
    ":evergreen_tree:": {
        "src": "/images/emojis/unicode/svg/1f332.svg",
        "alt": "🌲",
        "title": "evergreen tree"
    },
    ":deciduous_tree:": {
        "src": "/images/emojis/unicode/svg/1f333.svg",
        "alt": "🌳",
        "title": "deciduous tree"
    },
    ":palm_tree:": {
        "src": "/images/emojis/unicode/svg/1f334.svg",
        "alt": "🌴",
        "title": "palm tree"
    },
    ":cactus:": {
        "src": "/images/emojis/unicode/svg/1f335.svg",
        "alt": "🌵",
        "title": "cactus"
    },
    ":sheaf_of_rice:": {
        "src": "/images/emojis/unicode/svg/1f33e.svg",
        "alt": "🌾",
        "title": "sheaf of rice"
    },
    ":herb:": {
        "src": "/images/emojis/unicode/svg/1f33f.svg",
        "alt": "🌿",
        "title": "herb"
    },
    ":shamrock:": {
        "src": "/images/emojis/unicode/svg/2618.svg",
        "alt": "☘",
        "title": "shamrock"
    },
    ":four_leaf_clover:": {
        "src": "/images/emojis/unicode/svg/1f340.svg",
        "alt": "🍀",
        "title": "four leaf clover"
    },
    ":maple_leaf:": {
        "src": "/images/emojis/unicode/svg/1f341.svg",
        "alt": "🍁",
        "title": "maple leaf"
    },
    ":fallen_leaf:": {
        "src": "/images/emojis/unicode/svg/1f342.svg",
        "alt": "🍂",
        "title": "fallen leaf"
    },
    ":leaf_fluttering_in_wind:": {
        "src": "/images/emojis/unicode/svg/1f343.svg",
        "alt": "🍃",
        "title": "leaf fluttering in wind"
    },
    ":grapes:": {
        "src": "/images/emojis/unicode/svg/1f347.svg",
        "alt": "🍇",
        "title": "grapes"
    },
    ":melon:": {
        "src": "/images/emojis/unicode/svg/1f348.svg",
        "alt": "🍈",
        "title": "melon"
    },
    ":watermelon:": {
        "src": "/images/emojis/unicode/svg/1f349.svg",
        "alt": "🍉",
        "title": "watermelon"
    },
    ":tangerine:": {
        "src": "/images/emojis/unicode/svg/1f34a.svg",
        "alt": "🍊",
        "title": "tangerine"
    },
    ":lemon:": {
        "src": "/images/emojis/unicode/svg/1f34b.svg",
        "alt": "🍋",
        "title": "lemon"
    },
    ":banana:": {
        "src": "/images/emojis/unicode/svg/1f34c.svg",
        "alt": "🍌",
        "title": "banana"
    },
    ":pineapple:": {
        "src": "/images/emojis/unicode/svg/1f34d.svg",
        "alt": "🍍",
        "title": "pineapple"
    },
    ":mango:": {
        "src": "/images/emojis/unicode/svg/1f96d.svg",
        "alt": "🥭",
        "title": "mango"
    },
    ":red_apple:": {
        "src": "/images/emojis/unicode/svg/1f34e.svg",
        "alt": "🍎",
        "title": "red apple"
    },
    ":green_apple:": {
        "src": "/images/emojis/unicode/svg/1f34f.svg",
        "alt": "🍏",
        "title": "green apple"
    },
    ":pear:": {
        "src": "/images/emojis/unicode/svg/1f350.svg",
        "alt": "🍐",
        "title": "pear"
    },
    ":peach:": {
        "src": "/images/emojis/unicode/svg/1f351.svg",
        "alt": "🍑",
        "title": "peach"
    },
    ":cherries:": {
        "src": "/images/emojis/unicode/svg/1f352.svg",
        "alt": "🍒",
        "title": "cherries"
    },
    ":strawberry:": {
        "src": "/images/emojis/unicode/svg/1f353.svg",
        "alt": "🍓",
        "title": "strawberry"
    },
    ":blueberries:": {
        "src": "/images/emojis/unicode/svg/1fad0.svg",
        "alt": "🫐",
        "title": "blueberries"
    },
    ":kiwi_fruit:": {
        "src": "/images/emojis/unicode/svg/1f95d.svg",
        "alt": "🥝",
        "title": "kiwi fruit"
    },
    ":tomato:": {
        "src": "/images/emojis/unicode/svg/1f345.svg",
        "alt": "🍅",
        "title": "tomato"
    },
    ":olive:": {
        "src": "/images/emojis/unicode/svg/1fad2.svg",
        "alt": "🫒",
        "title": "olive"
    },
    ":coconut:": {
        "src": "/images/emojis/unicode/svg/1f965.svg",
        "alt": "🥥",
        "title": "coconut"
    },
    ":avocado:": {
        "src": "/images/emojis/unicode/svg/1f951.svg",
        "alt": "🥑",
        "title": "avocado"
    },
    ":eggplant:": {
        "src": "/images/emojis/unicode/svg/1f346.svg",
        "alt": "🍆",
        "title": "eggplant"
    },
    ":potato:": {
        "src": "/images/emojis/unicode/svg/1f954.svg",
        "alt": "🥔",
        "title": "potato"
    },
    ":carrot:": {
        "src": "/images/emojis/unicode/svg/1f955.svg",
        "alt": "🥕",
        "title": "carrot"
    },
    ":ear_of_corn:": {
        "src": "/images/emojis/unicode/svg/1f33d.svg",
        "alt": "🌽",
        "title": "ear of corn"
    },
    ":hot_pepper:": {
        "src": "/images/emojis/unicode/svg/1f336.svg",
        "alt": "🌶",
        "title": "hot pepper"
    },
    ":bell_pepper:": {
        "src": "/images/emojis/unicode/svg/1fad1.svg",
        "alt": "🫑",
        "title": "bell pepper"
    },
    ":cucumber:": {
        "src": "/images/emojis/unicode/svg/1f952.svg",
        "alt": "🥒",
        "title": "cucumber"
    },
    ":leafy_green:": {
        "src": "/images/emojis/unicode/svg/1f96c.svg",
        "alt": "🥬",
        "title": "leafy green"
    },
    ":broccoli:": {
        "src": "/images/emojis/unicode/svg/1f966.svg",
        "alt": "🥦",
        "title": "broccoli"
    },
    ":garlic:": {
        "src": "/images/emojis/unicode/svg/1f9c4.svg",
        "alt": "🧄",
        "title": "garlic"
    },
    ":onion:": {
        "src": "/images/emojis/unicode/svg/1f9c5.svg",
        "alt": "🧅",
        "title": "onion"
    },
    ":mushroom:": {
        "src": "/images/emojis/unicode/svg/1f344.svg",
        "alt": "🍄",
        "title": "mushroom"
    },
    ":peanuts:": {
        "src": "/images/emojis/unicode/svg/1f95c.svg",
        "alt": "🥜",
        "title": "peanuts"
    },
    ":chestnut:": {
        "src": "/images/emojis/unicode/svg/1f330.svg",
        "alt": "🌰",
        "title": "chestnut"
    },
    ":bread:": {
        "src": "/images/emojis/unicode/svg/1f35e.svg",
        "alt": "🍞",
        "title": "bread"
    },
    ":croissant:": {
        "src": "/images/emojis/unicode/svg/1f950.svg",
        "alt": "🥐",
        "title": "croissant"
    },
    ":baguette_bread:": {
        "src": "/images/emojis/unicode/svg/1f956.svg",
        "alt": "🥖",
        "title": "baguette bread"
    },
    ":flatbread:": {
        "src": "/images/emojis/unicode/svg/1fad3.svg",
        "alt": "🫓",
        "title": "flatbread"
    },
    ":pretzel:": {
        "src": "/images/emojis/unicode/svg/1f968.svg",
        "alt": "🥨",
        "title": "pretzel"
    },
    ":bagel:": {
        "src": "/images/emojis/unicode/svg/1f96f.svg",
        "alt": "🥯",
        "title": "bagel"
    },
    ":pancakes:": {
        "src": "/images/emojis/unicode/svg/1f95e.svg",
        "alt": "🥞",
        "title": "pancakes"
    },
    ":waffle:": {
        "src": "/images/emojis/unicode/svg/1f9c7.svg",
        "alt": "🧇",
        "title": "waffle"
    },
    ":cheese_wedge:": {
        "src": "/images/emojis/unicode/svg/1f9c0.svg",
        "alt": "🧀",
        "title": "cheese wedge"
    },
    ":meat_on_bone:": {
        "src": "/images/emojis/unicode/svg/1f356.svg",
        "alt": "🍖",
        "title": "meat on bone"
    },
    ":poultry_leg:": {
        "src": "/images/emojis/unicode/svg/1f357.svg",
        "alt": "🍗",
        "title": "poultry leg"
    },
    ":cut_of_meat:": {
        "src": "/images/emojis/unicode/svg/1f969.svg",
        "alt": "🥩",
        "title": "cut of meat"
    },
    ":bacon:": {
        "src": "/images/emojis/unicode/svg/1f953.svg",
        "alt": "🥓",
        "title": "bacon"
    },
    ":hamburger:": {
        "src": "/images/emojis/unicode/svg/1f354.svg",
        "alt": "🍔",
        "title": "hamburger"
    },
    ":french_fries:": {
        "src": "/images/emojis/unicode/svg/1f35f.svg",
        "alt": "🍟",
        "title": "french fries"
    },
    ":pizza:": {
        "src": "/images/emojis/unicode/svg/1f355.svg",
        "alt": "🍕",
        "title": "pizza"
    },
    ":hot_dog:": {
        "src": "/images/emojis/unicode/svg/1f32d.svg",
        "alt": "🌭",
        "title": "hot dog"
    },
    ":sandwich:": {
        "src": "/images/emojis/unicode/svg/1f96a.svg",
        "alt": "🥪",
        "title": "sandwich"
    },
    ":taco:": {
        "src": "/images/emojis/unicode/svg/1f32e.svg",
        "alt": "🌮",
        "title": "taco"
    },
    ":burrito:": {
        "src": "/images/emojis/unicode/svg/1f32f.svg",
        "alt": "🌯",
        "title": "burrito"
    },
    ":tamale:": {
        "src": "/images/emojis/unicode/svg/1fad4.svg",
        "alt": "🫔",
        "title": "tamale"
    },
    ":stuffed_flatbread:": {
        "src": "/images/emojis/unicode/svg/1f959.svg",
        "alt": "🥙",
        "title": "stuffed flatbread"
    },
    ":falafel:": {
        "src": "/images/emojis/unicode/svg/1f9c6.svg",
        "alt": "🧆",
        "title": "falafel"
    },
    ":egg:": {
        "src": "/images/emojis/unicode/svg/1f95a.svg",
        "alt": "🥚",
        "title": "egg"
    },
    ":cooking:": {
        "src": "/images/emojis/unicode/svg/1f373.svg",
        "alt": "🍳",
        "title": "cooking"
    },
    ":shallow_pan_of_food:": {
        "src": "/images/emojis/unicode/svg/1f958.svg",
        "alt": "🥘",
        "title": "shallow pan of food"
    },
    ":pot_of_food:": {
        "src": "/images/emojis/unicode/svg/1f372.svg",
        "alt": "🍲",
        "title": "pot of food"
    },
    ":fondue:": {
        "src": "/images/emojis/unicode/svg/1fad5.svg",
        "alt": "🫕",
        "title": "fondue"
    },
    ":bowl_with_spoon:": {
        "src": "/images/emojis/unicode/svg/1f963.svg",
        "alt": "🥣",
        "title": "bowl with spoon"
    },
    ":green_salad:": {
        "src": "/images/emojis/unicode/svg/1f957.svg",
        "alt": "🥗",
        "title": "green salad"
    },
    ":popcorn:": {
        "src": "/images/emojis/unicode/svg/1f37f.svg",
        "alt": "🍿",
        "title": "popcorn"
    },
    ":butter:": {
        "src": "/images/emojis/unicode/svg/1f9c8.svg",
        "alt": "🧈",
        "title": "butter"
    },
    ":salt:": {
        "src": "/images/emojis/unicode/svg/1f9c2.svg",
        "alt": "🧂",
        "title": "salt"
    },
    ":canned_food:": {
        "src": "/images/emojis/unicode/svg/1f96b.svg",
        "alt": "🥫",
        "title": "canned food"
    },
    ":bento_box:": {
        "src": "/images/emojis/unicode/svg/1f371.svg",
        "alt": "🍱",
        "title": "bento box"
    },
    ":rice_cracker:": {
        "src": "/images/emojis/unicode/svg/1f358.svg",
        "alt": "🍘",
        "title": "rice cracker"
    },
    ":rice_ball:": {
        "src": "/images/emojis/unicode/svg/1f359.svg",
        "alt": "🍙",
        "title": "rice ball"
    },
    ":cooked_rice:": {
        "src": "/images/emojis/unicode/svg/1f35a.svg",
        "alt": "🍚",
        "title": "cooked rice"
    },
    ":curry_rice:": {
        "src": "/images/emojis/unicode/svg/1f35b.svg",
        "alt": "🍛",
        "title": "curry rice"
    },
    ":steaming_bowl:": {
        "src": "/images/emojis/unicode/svg/1f35c.svg",
        "alt": "🍜",
        "title": "steaming bowl"
    },
    ":spaghetti:": {
        "src": "/images/emojis/unicode/svg/1f35d.svg",
        "alt": "🍝",
        "title": "spaghetti"
    },
    ":roasted_sweet_potato:": {
        "src": "/images/emojis/unicode/svg/1f360.svg",
        "alt": "🍠",
        "title": "roasted sweet potato"
    },
    ":oden:": {
        "src": "/images/emojis/unicode/svg/1f362.svg",
        "alt": "🍢",
        "title": "oden"
    },
    ":sushi:": {
        "src": "/images/emojis/unicode/svg/1f363.svg",
        "alt": "🍣",
        "title": "sushi"
    },
    ":fried_shrimp:": {
        "src": "/images/emojis/unicode/svg/1f364.svg",
        "alt": "🍤",
        "title": "fried shrimp"
    },
    ":fish_cake_with_swirl:": {
        "src": "/images/emojis/unicode/svg/1f365.svg",
        "alt": "🍥",
        "title": "fish cake with swirl"
    },
    ":moon_cake:": {
        "src": "/images/emojis/unicode/svg/1f96e.svg",
        "alt": "🥮",
        "title": "moon cake"
    },
    ":dango:": {
        "src": "/images/emojis/unicode/svg/1f361.svg",
        "alt": "🍡",
        "title": "dango"
    },
    ":dumpling:": {
        "src": "/images/emojis/unicode/svg/1f95f.svg",
        "alt": "🥟",
        "title": "dumpling"
    },
    ":fortune_cookie:": {
        "src": "/images/emojis/unicode/svg/1f960.svg",
        "alt": "🥠",
        "title": "fortune cookie"
    },
    ":takeout_box:": {
        "src": "/images/emojis/unicode/svg/1f961.svg",
        "alt": "🥡",
        "title": "takeout box"
    },
    ":crab:": {
        "src": "/images/emojis/unicode/svg/1f980.svg",
        "alt": "🦀",
        "title": "crab"
    },
    ":lobster:": {
        "src": "/images/emojis/unicode/svg/1f99e.svg",
        "alt": "🦞",
        "title": "lobster"
    },
    ":shrimp:": {
        "src": "/images/emojis/unicode/svg/1f990.svg",
        "alt": "🦐",
        "title": "shrimp"
    },
    ":squid:": {
        "src": "/images/emojis/unicode/svg/1f991.svg",
        "alt": "🦑",
        "title": "squid"
    },
    ":oyster:": {
        "src": "/images/emojis/unicode/svg/1f9aa.svg",
        "alt": "🦪",
        "title": "oyster"
    },
    ":soft_ice_cream:": {
        "src": "/images/emojis/unicode/svg/1f366.svg",
        "alt": "🍦",
        "title": "soft ice cream"
    },
    ":shaved_ice:": {
        "src": "/images/emojis/unicode/svg/1f367.svg",
        "alt": "🍧",
        "title": "shaved ice"
    },
    ":ice_cream:": {
        "src": "/images/emojis/unicode/svg/1f368.svg",
        "alt": "🍨",
        "title": "ice cream"
    },
    ":doughnut:": {
        "src": "/images/emojis/unicode/svg/1f369.svg",
        "alt": "🍩",
        "title": "doughnut"
    },
    ":cookie:": {
        "src": "/images/emojis/unicode/svg/1f36a.svg",
        "alt": "🍪",
        "title": "cookie"
    },
    ":birthday_cake:": {
        "src": "/images/emojis/unicode/svg/1f382.svg",
        "alt": "🎂",
        "title": "birthday cake"
    },
    ":shortcake:": {
        "src": "/images/emojis/unicode/svg/1f370.svg",
        "alt": "🍰",
        "title": "shortcake"
    },
    ":cupcake:": {
        "src": "/images/emojis/unicode/svg/1f9c1.svg",
        "alt": "🧁",
        "title": "cupcake"
    },
    ":pie:": {
        "src": "/images/emojis/unicode/svg/1f967.svg",
        "alt": "🥧",
        "title": "pie"
    },
    ":chocolate_bar:": {
        "src": "/images/emojis/unicode/svg/1f36b.svg",
        "alt": "🍫",
        "title": "chocolate bar"
    },
    ":candy:": {
        "src": "/images/emojis/unicode/svg/1f36c.svg",
        "alt": "🍬",
        "title": "candy"
    },
    ":lollipop:": {
        "src": "/images/emojis/unicode/svg/1f36d.svg",
        "alt": "🍭",
        "title": "lollipop"
    },
    ":custard:": {
        "src": "/images/emojis/unicode/svg/1f36e.svg",
        "alt": "🍮",
        "title": "custard"
    },
    ":honey_pot:": {
        "src": "/images/emojis/unicode/svg/1f36f.svg",
        "alt": "🍯",
        "title": "honey pot"
    },
    ":baby_bottle:": {
        "src": "/images/emojis/unicode/svg/1f37c.svg",
        "alt": "🍼",
        "title": "baby bottle"
    },
    ":glass_of_milk:": {
        "src": "/images/emojis/unicode/svg/1f95b.svg",
        "alt": "🥛",
        "title": "glass of milk"
    },
    ":hot_beverage:": {
        "src": "/images/emojis/unicode/svg/2615.svg",
        "alt": "☕",
        "title": "hot beverage"
    },
    ":teapot:": {
        "src": "/images/emojis/unicode/svg/1fad6.svg",
        "alt": "🫖",
        "title": "teapot"
    },
    ":teacup_without_handle:": {
        "src": "/images/emojis/unicode/svg/1f375.svg",
        "alt": "🍵",
        "title": "teacup without handle"
    },
    ":sake:": {
        "src": "/images/emojis/unicode/svg/1f376.svg",
        "alt": "🍶",
        "title": "sake"
    },
    ":bottle_with_popping_cork:": {
        "src": "/images/emojis/unicode/svg/1f37e.svg",
        "alt": "🍾",
        "title": "bottle with popping cork"
    },
    ":wine_glass:": {
        "src": "/images/emojis/unicode/svg/1f377.svg",
        "alt": "🍷",
        "title": "wine glass"
    },
    ":cocktail_glass:": {
        "src": "/images/emojis/unicode/svg/1f378.svg",
        "alt": "🍸",
        "title": "cocktail glass"
    },
    ":tropical_drink:": {
        "src": "/images/emojis/unicode/svg/1f379.svg",
        "alt": "🍹",
        "title": "tropical drink"
    },
    ":beer_mug:": {
        "src": "/images/emojis/unicode/svg/1f37a.svg",
        "alt": "🍺",
        "title": "beer mug"
    },
    ":clinking_beer_mugs:": {
        "src": "/images/emojis/unicode/svg/1f37b.svg",
        "alt": "🍻",
        "title": "clinking beer mugs"
    },
    ":clinking_glasses:": {
        "src": "/images/emojis/unicode/svg/1f942.svg",
        "alt": "🥂",
        "title": "clinking glasses"
    },
    ":tumbler_glass:": {
        "src": "/images/emojis/unicode/svg/1f943.svg",
        "alt": "🥃",
        "title": "tumbler glass"
    },
    ":cup_with_straw:": {
        "src": "/images/emojis/unicode/svg/1f964.svg",
        "alt": "🥤",
        "title": "cup with straw"
    },
    ":bubble_tea:": {
        "src": "/images/emojis/unicode/svg/1f9cb.svg",
        "alt": "🧋",
        "title": "bubble tea"
    },
    ":beverage_box:": {
        "src": "/images/emojis/unicode/svg/1f9c3.svg",
        "alt": "🧃",
        "title": "beverage box"
    },
    ":mate:": {
        "src": "/images/emojis/unicode/svg/1f9c9.svg",
        "alt": "🧉",
        "title": "mate"
    },
    ":ice:": {
        "src": "/images/emojis/unicode/svg/1f9ca.svg",
        "alt": "🧊",
        "title": "ice"
    },
    ":chopsticks:": {
        "src": "/images/emojis/unicode/svg/1f962.svg",
        "alt": "🥢",
        "title": "chopsticks"
    },
    ":fork_and_knife_with_plate:": {
        "src": "/images/emojis/unicode/svg/1f37d.svg",
        "alt": "🍽",
        "title": "fork and knife with plate"
    },
    ":fork_and_knife:": {
        "src": "/images/emojis/unicode/svg/1f374.svg",
        "alt": "🍴",
        "title": "fork and knife"
    },
    ":spoon:": {
        "src": "/images/emojis/unicode/svg/1f944.svg",
        "alt": "🥄",
        "title": "spoon"
    },
    ":kitchen_knife:": {
        "src": "/images/emojis/unicode/svg/1f52a.svg",
        "alt": "🔪",
        "title": "kitchen knife"
    },
    ":amphora:": {
        "src": "/images/emojis/unicode/svg/1f3fa.svg",
        "alt": "🏺",
        "title": "amphora"
    },
    ":globe_showing_europe_africa:": {
        "src": "/images/emojis/unicode/svg/1f30d.svg",
        "alt": "🌍",
        "title": "globe showing Europe-Africa"
    },
    ":globe_showing_americas:": {
        "src": "/images/emojis/unicode/svg/1f30e.svg",
        "alt": "🌎",
        "title": "globe showing Americas"
    },
    ":globe_showing_asia_australia:": {
        "src": "/images/emojis/unicode/svg/1f30f.svg",
        "alt": "🌏",
        "title": "globe showing Asia-Australia"
    },
    ":globe_with_meridians:": {
        "src": "/images/emojis/unicode/svg/1f310.svg",
        "alt": "🌐",
        "title": "globe with meridians"
    },
    ":world_map:": {
        "src": "/images/emojis/unicode/svg/1f5fa.svg",
        "alt": "🗺",
        "title": "world map"
    },
    ":map_of_japan:": {
        "src": "/images/emojis/unicode/svg/1f5fe.svg",
        "alt": "🗾",
        "title": "map of Japan"
    },
    ":compass:": {
        "src": "/images/emojis/unicode/svg/1f9ed.svg",
        "alt": "🧭",
        "title": "compass"
    },
    ":snow_capped_mountain:": {
        "src": "/images/emojis/unicode/svg/1f3d4.svg",
        "alt": "🏔",
        "title": "snow-capped mountain"
    },
    ":mountain:": {
        "src": "/images/emojis/unicode/svg/26f0.svg",
        "alt": "⛰",
        "title": "mountain"
    },
    ":volcano:": {
        "src": "/images/emojis/unicode/svg/1f30b.svg",
        "alt": "🌋",
        "title": "volcano"
    },
    ":mount_fuji:": {
        "src": "/images/emojis/unicode/svg/1f5fb.svg",
        "alt": "🗻",
        "title": "mount fuji"
    },
    ":camping:": {
        "src": "/images/emojis/unicode/svg/1f3d5.svg",
        "alt": "🏕",
        "title": "camping"
    },
    ":beach_with_umbrella:": {
        "src": "/images/emojis/unicode/svg/1f3d6.svg",
        "alt": "🏖",
        "title": "beach with umbrella"
    },
    ":desert:": {
        "src": "/images/emojis/unicode/svg/1f3dc.svg",
        "alt": "🏜",
        "title": "desert"
    },
    ":desert_island:": {
        "src": "/images/emojis/unicode/svg/1f3dd.svg",
        "alt": "🏝",
        "title": "desert island"
    },
    ":national_park:": {
        "src": "/images/emojis/unicode/svg/1f3de.svg",
        "alt": "🏞",
        "title": "national park"
    },
    ":stadium:": {
        "src": "/images/emojis/unicode/svg/1f3df.svg",
        "alt": "🏟",
        "title": "stadium"
    },
    ":classical_building:": {
        "src": "/images/emojis/unicode/svg/1f3db.svg",
        "alt": "🏛",
        "title": "classical building"
    },
    ":building_construction:": {
        "src": "/images/emojis/unicode/svg/1f3d7.svg",
        "alt": "🏗",
        "title": "building construction"
    },
    ":brick:": {
        "src": "/images/emojis/unicode/svg/1f9f1.svg",
        "alt": "🧱",
        "title": "brick"
    },
    ":rock:": {
        "src": "/images/emojis/unicode/svg/1faa8.svg",
        "alt": "🪨",
        "title": "rock"
    },
    ":wood:": {
        "src": "/images/emojis/unicode/svg/1fab5.svg",
        "alt": "🪵",
        "title": "wood"
    },
    ":hut:": {
        "src": "/images/emojis/unicode/svg/1f6d6.svg",
        "alt": "🛖",
        "title": "hut"
    },
    ":houses:": {
        "src": "/images/emojis/unicode/svg/1f3d8.svg",
        "alt": "🏘",
        "title": "houses"
    },
    ":derelict_house:": {
        "src": "/images/emojis/unicode/svg/1f3da.svg",
        "alt": "🏚",
        "title": "derelict house"
    },
    ":house:": {
        "src": "/images/emojis/unicode/svg/1f3e0.svg",
        "alt": "🏠",
        "title": "house"
    },
    ":house_with_garden:": {
        "src": "/images/emojis/unicode/svg/1f3e1.svg",
        "alt": "🏡",
        "title": "house with garden"
    },
    ":office_building:": {
        "src": "/images/emojis/unicode/svg/1f3e2.svg",
        "alt": "🏢",
        "title": "office building"
    },
    ":japanese_post_office:": {
        "src": "/images/emojis/unicode/svg/1f3e3.svg",
        "alt": "🏣",
        "title": "Japanese post office"
    },
    ":post_office:": {
        "src": "/images/emojis/unicode/svg/1f3e4.svg",
        "alt": "🏤",
        "title": "post office"
    },
    ":hospital:": {
        "src": "/images/emojis/unicode/svg/1f3e5.svg",
        "alt": "🏥",
        "title": "hospital"
    },
    ":bank:": {
        "src": "/images/emojis/unicode/svg/1f3e6.svg",
        "alt": "🏦",
        "title": "bank"
    },
    ":hotel:": {
        "src": "/images/emojis/unicode/svg/1f3e8.svg",
        "alt": "🏨",
        "title": "hotel"
    },
    ":love_hotel:": {
        "src": "/images/emojis/unicode/svg/1f3e9.svg",
        "alt": "🏩",
        "title": "love hotel"
    },
    ":convenience_store:": {
        "src": "/images/emojis/unicode/svg/1f3ea.svg",
        "alt": "🏪",
        "title": "convenience store"
    },
    ":school:": {
        "src": "/images/emojis/unicode/svg/1f3eb.svg",
        "alt": "🏫",
        "title": "school"
    },
    ":department_store:": {
        "src": "/images/emojis/unicode/svg/1f3ec.svg",
        "alt": "🏬",
        "title": "department store"
    },
    ":factory:": {
        "src": "/images/emojis/unicode/svg/1f3ed.svg",
        "alt": "🏭",
        "title": "factory"
    },
    ":japanese_castle:": {
        "src": "/images/emojis/unicode/svg/1f3ef.svg",
        "alt": "🏯",
        "title": "Japanese castle"
    },
    ":castle:": {
        "src": "/images/emojis/unicode/svg/1f3f0.svg",
        "alt": "🏰",
        "title": "castle"
    },
    ":wedding:": {
        "src": "/images/emojis/unicode/svg/1f492.svg",
        "alt": "💒",
        "title": "wedding"
    },
    ":tokyo_tower:": {
        "src": "/images/emojis/unicode/svg/1f5fc.svg",
        "alt": "🗼",
        "title": "Tokyo tower"
    },
    ":statue_of_liberty:": {
        "src": "/images/emojis/unicode/svg/1f5fd.svg",
        "alt": "🗽",
        "title": "Statue of Liberty"
    },
    ":church:": {
        "src": "/images/emojis/unicode/svg/26ea.svg",
        "alt": "⛪",
        "title": "church"
    },
    ":mosque:": {
        "src": "/images/emojis/unicode/svg/1f54c.svg",
        "alt": "🕌",
        "title": "mosque"
    },
    ":hindu_temple:": {
        "src": "/images/emojis/unicode/svg/1f6d5.svg",
        "alt": "🛕",
        "title": "hindu temple"
    },
    ":synagogue:": {
        "src": "/images/emojis/unicode/svg/1f54d.svg",
        "alt": "🕍",
        "title": "synagogue"
    },
    ":shinto_shrine:": {
        "src": "/images/emojis/unicode/svg/26e9.svg",
        "alt": "⛩",
        "title": "shinto shrine"
    },
    ":kaaba:": {
        "src": "/images/emojis/unicode/svg/1f54b.svg",
        "alt": "🕋",
        "title": "kaaba"
    },
    ":fountain:": {
        "src": "/images/emojis/unicode/svg/26f2.svg",
        "alt": "⛲",
        "title": "fountain"
    },
    ":tent:": {
        "src": "/images/emojis/unicode/svg/26fa.svg",
        "alt": "⛺",
        "title": "tent"
    },
    ":foggy:": {
        "src": "/images/emojis/unicode/svg/1f301.svg",
        "alt": "🌁",
        "title": "foggy"
    },
    ":night_with_stars:": {
        "src": "/images/emojis/unicode/svg/1f303.svg",
        "alt": "🌃",
        "title": "night with stars"
    },
    ":cityscape:": {
        "src": "/images/emojis/unicode/svg/1f3d9.svg",
        "alt": "🏙",
        "title": "cityscape"
    },
    ":sunrise_over_mountains:": {
        "src": "/images/emojis/unicode/svg/1f304.svg",
        "alt": "🌄",
        "title": "sunrise over mountains"
    },
    ":sunrise:": {
        "src": "/images/emojis/unicode/svg/1f305.svg",
        "alt": "🌅",
        "title": "sunrise"
    },
    ":cityscape_at_dusk:": {
        "src": "/images/emojis/unicode/svg/1f306.svg",
        "alt": "🌆",
        "title": "cityscape at dusk"
    },
    ":sunset:": {
        "src": "/images/emojis/unicode/svg/1f307.svg",
        "alt": "🌇",
        "title": "sunset"
    },
    ":bridge_at_night:": {
        "src": "/images/emojis/unicode/svg/1f309.svg",
        "alt": "🌉",
        "title": "bridge at night"
    },
    ":hot_springs:": {
        "src": "/images/emojis/unicode/svg/2668.svg",
        "alt": "♨",
        "title": "hot springs"
    },
    ":carousel_horse:": {
        "src": "/images/emojis/unicode/svg/1f3a0.svg",
        "alt": "🎠",
        "title": "carousel horse"
    },
    ":ferris_wheel:": {
        "src": "/images/emojis/unicode/svg/1f3a1.svg",
        "alt": "🎡",
        "title": "ferris wheel"
    },
    ":roller_coaster:": {
        "src": "/images/emojis/unicode/svg/1f3a2.svg",
        "alt": "🎢",
        "title": "roller coaster"
    },
    ":barber_pole:": {
        "src": "/images/emojis/unicode/svg/1f488.svg",
        "alt": "💈",
        "title": "barber pole"
    },
    ":circus_tent:": {
        "src": "/images/emojis/unicode/svg/1f3aa.svg",
        "alt": "🎪",
        "title": "circus tent"
    },
    ":locomotive:": {
        "src": "/images/emojis/unicode/svg/1f682.svg",
        "alt": "🚂",
        "title": "locomotive"
    },
    ":railway_car:": {
        "src": "/images/emojis/unicode/svg/1f683.svg",
        "alt": "🚃",
        "title": "railway car"
    },
    ":high_speed_train:": {
        "src": "/images/emojis/unicode/svg/1f684.svg",
        "alt": "🚄",
        "title": "high-speed train"
    },
    ":bullet_train:": {
        "src": "/images/emojis/unicode/svg/1f685.svg",
        "alt": "🚅",
        "title": "bullet train"
    },
    ":train:": {
        "src": "/images/emojis/unicode/svg/1f686.svg",
        "alt": "🚆",
        "title": "train"
    },
    ":metro:": {
        "src": "/images/emojis/unicode/svg/1f687.svg",
        "alt": "🚇",
        "title": "metro"
    },
    ":light_rail:": {
        "src": "/images/emojis/unicode/svg/1f688.svg",
        "alt": "🚈",
        "title": "light rail"
    },
    ":station:": {
        "src": "/images/emojis/unicode/svg/1f689.svg",
        "alt": "🚉",
        "title": "station"
    },
    ":tram:": {
        "src": "/images/emojis/unicode/svg/1f68a.svg",
        "alt": "🚊",
        "title": "tram"
    },
    ":monorail:": {
        "src": "/images/emojis/unicode/svg/1f69d.svg",
        "alt": "🚝",
        "title": "monorail"
    },
    ":mountain_railway:": {
        "src": "/images/emojis/unicode/svg/1f69e.svg",
        "alt": "🚞",
        "title": "mountain railway"
    },
    ":tram_car:": {
        "src": "/images/emojis/unicode/svg/1f68b.svg",
        "alt": "🚋",
        "title": "tram car"
    },
    ":bus:": {
        "src": "/images/emojis/unicode/svg/1f68c.svg",
        "alt": "🚌",
        "title": "bus"
    },
    ":oncoming_bus:": {
        "src": "/images/emojis/unicode/svg/1f68d.svg",
        "alt": "🚍",
        "title": "oncoming bus"
    },
    ":trolleybus:": {
        "src": "/images/emojis/unicode/svg/1f68e.svg",
        "alt": "🚎",
        "title": "trolleybus"
    },
    ":minibus:": {
        "src": "/images/emojis/unicode/svg/1f690.svg",
        "alt": "🚐",
        "title": "minibus"
    },
    ":ambulance:": {
        "src": "/images/emojis/unicode/svg/1f691.svg",
        "alt": "🚑",
        "title": "ambulance"
    },
    ":fire_engine:": {
        "src": "/images/emojis/unicode/svg/1f692.svg",
        "alt": "🚒",
        "title": "fire engine"
    },
    ":police_car:": {
        "src": "/images/emojis/unicode/svg/1f693.svg",
        "alt": "🚓",
        "title": "police car"
    },
    ":oncoming_police_car:": {
        "src": "/images/emojis/unicode/svg/1f694.svg",
        "alt": "🚔",
        "title": "oncoming police car"
    },
    ":taxi:": {
        "src": "/images/emojis/unicode/svg/1f695.svg",
        "alt": "🚕",
        "title": "taxi"
    },
    ":oncoming_taxi:": {
        "src": "/images/emojis/unicode/svg/1f696.svg",
        "alt": "🚖",
        "title": "oncoming taxi"
    },
    ":automobile:": {
        "src": "/images/emojis/unicode/svg/1f697.svg",
        "alt": "🚗",
        "title": "automobile"
    },
    ":oncoming_automobile:": {
        "src": "/images/emojis/unicode/svg/1f698.svg",
        "alt": "🚘",
        "title": "oncoming automobile"
    },
    ":sport_utility_vehicle:": {
        "src": "/images/emojis/unicode/svg/1f699.svg",
        "alt": "🚙",
        "title": "sport utility vehicle"
    },
    ":pickup_truck:": {
        "src": "/images/emojis/unicode/svg/1f6fb.svg",
        "alt": "🛻",
        "title": "pickup truck"
    },
    ":delivery_truck:": {
        "src": "/images/emojis/unicode/svg/1f69a.svg",
        "alt": "🚚",
        "title": "delivery truck"
    },
    ":articulated_lorry:": {
        "src": "/images/emojis/unicode/svg/1f69b.svg",
        "alt": "🚛",
        "title": "articulated lorry"
    },
    ":tractor:": {
        "src": "/images/emojis/unicode/svg/1f69c.svg",
        "alt": "🚜",
        "title": "tractor"
    },
    ":racing_car:": {
        "src": "/images/emojis/unicode/svg/1f3ce.svg",
        "alt": "🏎",
        "title": "racing car"
    },
    ":motorcycle:": {
        "src": "/images/emojis/unicode/svg/1f3cd.svg",
        "alt": "🏍",
        "title": "motorcycle"
    },
    ":motor_scooter:": {
        "src": "/images/emojis/unicode/svg/1f6f5.svg",
        "alt": "🛵",
        "title": "motor scooter"
    },
    ":manual_wheelchair:": {
        "src": "/images/emojis/unicode/svg/1f9bd.svg",
        "alt": "🦽",
        "title": "manual wheelchair"
    },
    ":motorized_wheelchair:": {
        "src": "/images/emojis/unicode/svg/1f9bc.svg",
        "alt": "🦼",
        "title": "motorized wheelchair"
    },
    ":auto_rickshaw:": {
        "src": "/images/emojis/unicode/svg/1f6fa.svg",
        "alt": "🛺",
        "title": "auto rickshaw"
    },
    ":bicycle:": {
        "src": "/images/emojis/unicode/svg/1f6b2.svg",
        "alt": "🚲",
        "title": "bicycle"
    },
    ":kick_scooter:": {
        "src": "/images/emojis/unicode/svg/1f6f4.svg",
        "alt": "🛴",
        "title": "kick scooter"
    },
    ":skateboard:": {
        "src": "/images/emojis/unicode/svg/1f6f9.svg",
        "alt": "🛹",
        "title": "skateboard"
    },
    ":roller_skate:": {
        "src": "/images/emojis/unicode/svg/1f6fc.svg",
        "alt": "🛼",
        "title": "roller skate"
    },
    ":bus_stop:": {
        "src": "/images/emojis/unicode/svg/1f68f.svg",
        "alt": "🚏",
        "title": "bus stop"
    },
    ":motorway:": {
        "src": "/images/emojis/unicode/svg/1f6e3.svg",
        "alt": "🛣",
        "title": "motorway"
    },
    ":railway_track:": {
        "src": "/images/emojis/unicode/svg/1f6e4.svg",
        "alt": "🛤",
        "title": "railway track"
    },
    ":oil_drum:": {
        "src": "/images/emojis/unicode/svg/1f6e2.svg",
        "alt": "🛢",
        "title": "oil drum"
    },
    ":fuel_pump:": {
        "src": "/images/emojis/unicode/svg/26fd.svg",
        "alt": "⛽",
        "title": "fuel pump"
    },
    ":police_car_light:": {
        "src": "/images/emojis/unicode/svg/1f6a8.svg",
        "alt": "🚨",
        "title": "police car light"
    },
    ":horizontal_traffic_light:": {
        "src": "/images/emojis/unicode/svg/1f6a5.svg",
        "alt": "🚥",
        "title": "horizontal traffic light"
    },
    ":vertical_traffic_light:": {
        "src": "/images/emojis/unicode/svg/1f6a6.svg",
        "alt": "🚦",
        "title": "vertical traffic light"
    },
    ":stop_sign:": {
        "src": "/images/emojis/unicode/svg/1f6d1.svg",
        "alt": "🛑",
        "title": "stop sign"
    },
    ":construction:": {
        "src": "/images/emojis/unicode/svg/1f6a7.svg",
        "alt": "🚧",
        "title": "construction"
    },
    ":anchor:": {
        "src": "/images/emojis/unicode/svg/2693.svg",
        "alt": "⚓",
        "title": "anchor"
    },
    ":sailboat:": {
        "src": "/images/emojis/unicode/svg/26f5.svg",
        "alt": "⛵",
        "title": "sailboat"
    },
    ":canoe:": {
        "src": "/images/emojis/unicode/svg/1f6f6.svg",
        "alt": "🛶",
        "title": "canoe"
    },
    ":speedboat:": {
        "src": "/images/emojis/unicode/svg/1f6a4.svg",
        "alt": "🚤",
        "title": "speedboat"
    },
    ":passenger_ship:": {
        "src": "/images/emojis/unicode/svg/1f6f3.svg",
        "alt": "🛳",
        "title": "passenger ship"
    },
    ":ferry:": {
        "src": "/images/emojis/unicode/svg/26f4.svg",
        "alt": "⛴",
        "title": "ferry"
    },
    ":motor_boat:": {
        "src": "/images/emojis/unicode/svg/1f6e5.svg",
        "alt": "🛥",
        "title": "motor boat"
    },
    ":ship:": {
        "src": "/images/emojis/unicode/svg/1f6a2.svg",
        "alt": "🚢",
        "title": "ship"
    },
    ":airplane:": {
        "src": "/images/emojis/unicode/svg/2708.svg",
        "alt": "✈",
        "title": "airplane"
    },
    ":small_airplane:": {
        "src": "/images/emojis/unicode/svg/1f6e9.svg",
        "alt": "🛩",
        "title": "small airplane"
    },
    ":airplane_departure:": {
        "src": "/images/emojis/unicode/svg/1f6eb.svg",
        "alt": "🛫",
        "title": "airplane departure"
    },
    ":airplane_arrival:": {
        "src": "/images/emojis/unicode/svg/1f6ec.svg",
        "alt": "🛬",
        "title": "airplane arrival"
    },
    ":parachute:": {
        "src": "/images/emojis/unicode/svg/1fa82.svg",
        "alt": "🪂",
        "title": "parachute"
    },
    ":seat:": {
        "src": "/images/emojis/unicode/svg/1f4ba.svg",
        "alt": "💺",
        "title": "seat"
    },
    ":helicopter:": {
        "src": "/images/emojis/unicode/svg/1f681.svg",
        "alt": "🚁",
        "title": "helicopter"
    },
    ":suspension_railway:": {
        "src": "/images/emojis/unicode/svg/1f69f.svg",
        "alt": "🚟",
        "title": "suspension railway"
    },
    ":mountain_cableway:": {
        "src": "/images/emojis/unicode/svg/1f6a0.svg",
        "alt": "🚠",
        "title": "mountain cableway"
    },
    ":aerial_tramway:": {
        "src": "/images/emojis/unicode/svg/1f6a1.svg",
        "alt": "🚡",
        "title": "aerial tramway"
    },
    ":satellite:": {
        "src": "/images/emojis/unicode/svg/1f6f0.svg",
        "alt": "🛰",
        "title": "satellite"
    },
    ":rocket:": {
        "src": "/images/emojis/unicode/svg/1f680.svg",
        "alt": "🚀",
        "title": "rocket"
    },
    ":flying_saucer:": {
        "src": "/images/emojis/unicode/svg/1f6f8.svg",
        "alt": "🛸",
        "title": "flying saucer"
    },
    ":bellhop_bell:": {
        "src": "/images/emojis/unicode/svg/1f6ce.svg",
        "alt": "🛎",
        "title": "bellhop bell"
    },
    ":luggage:": {
        "src": "/images/emojis/unicode/svg/1f9f3.svg",
        "alt": "🧳",
        "title": "luggage"
    },
    ":hourglass_done:": {
        "src": "/images/emojis/unicode/svg/231b.svg",
        "alt": "⌛",
        "title": "hourglass done"
    },
    ":hourglass_not_done:": {
        "src": "/images/emojis/unicode/svg/23f3.svg",
        "alt": "⏳",
        "title": "hourglass not done"
    },
    ":watch:": {
        "src": "/images/emojis/unicode/svg/231a.svg",
        "alt": "⌚",
        "title": "watch"
    },
    ":alarm_clock:": {
        "src": "/images/emojis/unicode/svg/23f0.svg",
        "alt": "⏰",
        "title": "alarm clock"
    },
    ":stopwatch:": {
        "src": "/images/emojis/unicode/svg/23f1.svg",
        "alt": "⏱",
        "title": "stopwatch"
    },
    ":timer_clock:": {
        "src": "/images/emojis/unicode/svg/23f2.svg",
        "alt": "⏲",
        "title": "timer clock"
    },
    ":mantelpiece_clock:": {
        "src": "/images/emojis/unicode/svg/1f570.svg",
        "alt": "🕰",
        "title": "mantelpiece clock"
    },
    ":twelve_oclock:": {
        "src": "/images/emojis/unicode/svg/1f55b.svg",
        "alt": "🕛",
        "title": "twelve o’clock"
    },
    ":twelve_thirty:": {
        "src": "/images/emojis/unicode/svg/1f567.svg",
        "alt": "🕧",
        "title": "twelve-thirty"
    },
    ":one_oclock:": {
        "src": "/images/emojis/unicode/svg/1f550.svg",
        "alt": "🕐",
        "title": "one o’clock"
    },
    ":one_thirty:": {
        "src": "/images/emojis/unicode/svg/1f55c.svg",
        "alt": "🕜",
        "title": "one-thirty"
    },
    ":two_oclock:": {
        "src": "/images/emojis/unicode/svg/1f551.svg",
        "alt": "🕑",
        "title": "two o’clock"
    },
    ":two_thirty:": {
        "src": "/images/emojis/unicode/svg/1f55d.svg",
        "alt": "🕝",
        "title": "two-thirty"
    },
    ":three_oclock:": {
        "src": "/images/emojis/unicode/svg/1f552.svg",
        "alt": "🕒",
        "title": "three o’clock"
    },
    ":three_thirty:": {
        "src": "/images/emojis/unicode/svg/1f55e.svg",
        "alt": "🕞",
        "title": "three-thirty"
    },
    ":four_oclock:": {
        "src": "/images/emojis/unicode/svg/1f553.svg",
        "alt": "🕓",
        "title": "four o’clock"
    },
    ":four_thirty:": {
        "src": "/images/emojis/unicode/svg/1f55f.svg",
        "alt": "🕟",
        "title": "four-thirty"
    },
    ":five_oclock:": {
        "src": "/images/emojis/unicode/svg/1f554.svg",
        "alt": "🕔",
        "title": "five o’clock"
    },
    ":five_thirty:": {
        "src": "/images/emojis/unicode/svg/1f560.svg",
        "alt": "🕠",
        "title": "five-thirty"
    },
    ":six_oclock:": {
        "src": "/images/emojis/unicode/svg/1f555.svg",
        "alt": "🕕",
        "title": "six o’clock"
    },
    ":six_thirty:": {
        "src": "/images/emojis/unicode/svg/1f561.svg",
        "alt": "🕡",
        "title": "six-thirty"
    },
    ":seven_oclock:": {
        "src": "/images/emojis/unicode/svg/1f556.svg",
        "alt": "🕖",
        "title": "seven o’clock"
    },
    ":seven_thirty:": {
        "src": "/images/emojis/unicode/svg/1f562.svg",
        "alt": "🕢",
        "title": "seven-thirty"
    },
    ":eight_oclock:": {
        "src": "/images/emojis/unicode/svg/1f557.svg",
        "alt": "🕗",
        "title": "eight o’clock"
    },
    ":eight_thirty:": {
        "src": "/images/emojis/unicode/svg/1f563.svg",
        "alt": "🕣",
        "title": "eight-thirty"
    },
    ":nine_oclock:": {
        "src": "/images/emojis/unicode/svg/1f558.svg",
        "alt": "🕘",
        "title": "nine o’clock"
    },
    ":nine_thirty:": {
        "src": "/images/emojis/unicode/svg/1f564.svg",
        "alt": "🕤",
        "title": "nine-thirty"
    },
    ":ten_oclock:": {
        "src": "/images/emojis/unicode/svg/1f559.svg",
        "alt": "🕙",
        "title": "ten o’clock"
    },
    ":ten_thirty:": {
        "src": "/images/emojis/unicode/svg/1f565.svg",
        "alt": "🕥",
        "title": "ten-thirty"
    },
    ":eleven_oclock:": {
        "src": "/images/emojis/unicode/svg/1f55a.svg",
        "alt": "🕚",
        "title": "eleven o’clock"
    },
    ":eleven_thirty:": {
        "src": "/images/emojis/unicode/svg/1f566.svg",
        "alt": "🕦",
        "title": "eleven-thirty"
    },
    ":new_moon:": {
        "src": "/images/emojis/unicode/svg/1f311.svg",
        "alt": "🌑",
        "title": "new moon"
    },
    ":waxing_crescent_moon:": {
        "src": "/images/emojis/unicode/svg/1f312.svg",
        "alt": "🌒",
        "title": "waxing crescent moon"
    },
    ":first_quarter_moon:": {
        "src": "/images/emojis/unicode/svg/1f313.svg",
        "alt": "🌓",
        "title": "first quarter moon"
    },
    ":waxing_gibbous_moon:": {
        "src": "/images/emojis/unicode/svg/1f314.svg",
        "alt": "🌔",
        "title": "waxing gibbous moon"
    },
    ":full_moon:": {
        "src": "/images/emojis/unicode/svg/1f315.svg",
        "alt": "🌕",
        "title": "full moon"
    },
    ":waning_gibbous_moon:": {
        "src": "/images/emojis/unicode/svg/1f316.svg",
        "alt": "🌖",
        "title": "waning gibbous moon"
    },
    ":last_quarter_moon:": {
        "src": "/images/emojis/unicode/svg/1f317.svg",
        "alt": "🌗",
        "title": "last quarter moon"
    },
    ":waning_crescent_moon:": {
        "src": "/images/emojis/unicode/svg/1f318.svg",
        "alt": "🌘",
        "title": "waning crescent moon"
    },
    ":crescent_moon:": {
        "src": "/images/emojis/unicode/svg/1f319.svg",
        "alt": "🌙",
        "title": "crescent moon"
    },
    ":new_moon_face:": {
        "src": "/images/emojis/unicode/svg/1f31a.svg",
        "alt": "🌚",
        "title": "new moon face"
    },
    ":first_quarter_moon_face:": {
        "src": "/images/emojis/unicode/svg/1f31b.svg",
        "alt": "🌛",
        "title": "first quarter moon face"
    },
    ":last_quarter_moon_face:": {
        "src": "/images/emojis/unicode/svg/1f31c.svg",
        "alt": "🌜",
        "title": "last quarter moon face"
    },
    ":thermometer:": {
        "src": "/images/emojis/unicode/svg/1f321.svg",
        "alt": "🌡",
        "title": "thermometer"
    },
    ":sun:": {
        "src": "/images/emojis/unicode/svg/2600.svg",
        "alt": "☀",
        "title": "sun"
    },
    ":full_moon_face:": {
        "src": "/images/emojis/unicode/svg/1f31d.svg",
        "alt": "🌝",
        "title": "full moon face"
    },
    ":sun_with_face:": {
        "src": "/images/emojis/unicode/svg/1f31e.svg",
        "alt": "🌞",
        "title": "sun with face"
    },
    ":ringed_planet:": {
        "src": "/images/emojis/unicode/svg/1fa90.svg",
        "alt": "🪐",
        "title": "ringed planet"
    },
    ":star:": {
        "src": "/images/emojis/unicode/svg/2b50.svg",
        "alt": "⭐",
        "title": "star"
    },
    ":glowing_star:": {
        "src": "/images/emojis/unicode/svg/1f31f.svg",
        "alt": "🌟",
        "title": "glowing star"
    },
    ":shooting_star:": {
        "src": "/images/emojis/unicode/svg/1f320.svg",
        "alt": "🌠",
        "title": "shooting star"
    },
    ":milky_way:": {
        "src": "/images/emojis/unicode/svg/1f30c.svg",
        "alt": "🌌",
        "title": "milky way"
    },
    ":cloud:": {
        "src": "/images/emojis/unicode/svg/2601.svg",
        "alt": "☁",
        "title": "cloud"
    },
    ":sun_behind_cloud:": {
        "src": "/images/emojis/unicode/svg/26c5.svg",
        "alt": "⛅",
        "title": "sun behind cloud"
    },
    ":cloud_with_lightning_and_rain:": {
        "src": "/images/emojis/unicode/svg/26c8.svg",
        "alt": "⛈",
        "title": "cloud with lightning and rain"
    },
    ":sun_behind_small_cloud:": {
        "src": "/images/emojis/unicode/svg/1f324.svg",
        "alt": "🌤",
        "title": "sun behind small cloud"
    },
    ":sun_behind_large_cloud:": {
        "src": "/images/emojis/unicode/svg/1f325.svg",
        "alt": "🌥",
        "title": "sun behind large cloud"
    },
    ":sun_behind_rain_cloud:": {
        "src": "/images/emojis/unicode/svg/1f326.svg",
        "alt": "🌦",
        "title": "sun behind rain cloud"
    },
    ":cloud_with_rain:": {
        "src": "/images/emojis/unicode/svg/1f327.svg",
        "alt": "🌧",
        "title": "cloud with rain"
    },
    ":cloud_with_snow:": {
        "src": "/images/emojis/unicode/svg/1f328.svg",
        "alt": "🌨",
        "title": "cloud with snow"
    },
    ":cloud_with_lightning:": {
        "src": "/images/emojis/unicode/svg/1f329.svg",
        "alt": "🌩",
        "title": "cloud with lightning"
    },
    ":tornado:": {
        "src": "/images/emojis/unicode/svg/1f32a.svg",
        "alt": "🌪",
        "title": "tornado"
    },
    ":fog:": {
        "src": "/images/emojis/unicode/svg/1f32b.svg",
        "alt": "🌫",
        "title": "fog"
    },
    ":wind_face:": {
        "src": "/images/emojis/unicode/svg/1f32c.svg",
        "alt": "🌬",
        "title": "wind face"
    },
    ":cyclone:": {
        "src": "/images/emojis/unicode/svg/1f300.svg",
        "alt": "🌀",
        "title": "cyclone"
    },
    ":rainbow:": {
        "src": "/images/emojis/unicode/svg/1f308.svg",
        "alt": "🌈",
        "title": "rainbow"
    },
    ":closed_umbrella:": {
        "src": "/images/emojis/unicode/svg/1f302.svg",
        "alt": "🌂",
        "title": "closed umbrella"
    },
    ":umbrella:": {
        "src": "/images/emojis/unicode/svg/2602.svg",
        "alt": "☂",
        "title": "umbrella"
    },
    ":umbrella_with_rain_drops:": {
        "src": "/images/emojis/unicode/svg/2614.svg",
        "alt": "☔",
        "title": "umbrella with rain drops"
    },
    ":umbrella_on_ground:": {
        "src": "/images/emojis/unicode/svg/26f1.svg",
        "alt": "⛱",
        "title": "umbrella on ground"
    },
    ":high_voltage:": {
        "src": "/images/emojis/unicode/svg/26a1.svg",
        "alt": "⚡",
        "title": "high voltage"
    },
    ":snowflake:": {
        "src": "/images/emojis/unicode/svg/2744.svg",
        "alt": "❄",
        "title": "snowflake"
    },
    ":snowman:": {
        "src": "/images/emojis/unicode/svg/2603.svg",
        "alt": "☃",
        "title": "snowman"
    },
    ":snowman_without_snow:": {
        "src": "/images/emojis/unicode/svg/26c4.svg",
        "alt": "⛄",
        "title": "snowman without snow"
    },
    ":comet:": {
        "src": "/images/emojis/unicode/svg/2604.svg",
        "alt": "☄",
        "title": "comet"
    },
    ":fire:": {
        "src": "/images/emojis/unicode/svg/1f525.svg",
        "alt": "🔥",
        "title": "fire"
    },
    ":droplet:": {
        "src": "/images/emojis/unicode/svg/1f4a7.svg",
        "alt": "💧",
        "title": "droplet"
    },
    ":water_wave:": {
        "src": "/images/emojis/unicode/svg/1f30a.svg",
        "alt": "🌊",
        "title": "water wave"
    },
    ":jack_o_lantern:": {
        "src": "/images/emojis/unicode/svg/1f383.svg",
        "alt": "🎃",
        "title": "jack-o-lantern"
    },
    ":christmas_tree:": {
        "src": "/images/emojis/unicode/svg/1f384.svg",
        "alt": "🎄",
        "title": "Christmas tree"
    },
    ":fireworks:": {
        "src": "/images/emojis/unicode/svg/1f386.svg",
        "alt": "🎆",
        "title": "fireworks"
    },
    ":sparkler:": {
        "src": "/images/emojis/unicode/svg/1f387.svg",
        "alt": "🎇",
        "title": "sparkler"
    },
    ":firecracker:": {
        "src": "/images/emojis/unicode/svg/1f9e8.svg",
        "alt": "🧨",
        "title": "firecracker"
    },
    ":sparkles:": {
        "src": "/images/emojis/unicode/svg/2728.svg",
        "alt": "✨",
        "title": "sparkles"
    },
    ":balloon:": {
        "src": "/images/emojis/unicode/svg/1f388.svg",
        "alt": "🎈",
        "title": "balloon"
    },
    ":party_popper:": {
        "src": "/images/emojis/unicode/svg/1f389.svg",
        "alt": "🎉",
        "title": "party popper"
    },
    ":confetti_ball:": {
        "src": "/images/emojis/unicode/svg/1f38a.svg",
        "alt": "🎊",
        "title": "confetti ball"
    },
    ":tanabata_tree:": {
        "src": "/images/emojis/unicode/svg/1f38b.svg",
        "alt": "🎋",
        "title": "tanabata tree"
    },
    ":pine_decoration:": {
        "src": "/images/emojis/unicode/svg/1f38d.svg",
        "alt": "🎍",
        "title": "pine decoration"
    },
    ":japanese_dolls:": {
        "src": "/images/emojis/unicode/svg/1f38e.svg",
        "alt": "🎎",
        "title": "Japanese dolls"
    },
    ":carp_streamer:": {
        "src": "/images/emojis/unicode/svg/1f38f.svg",
        "alt": "🎏",
        "title": "carp streamer"
    },
    ":wind_chime:": {
        "src": "/images/emojis/unicode/svg/1f390.svg",
        "alt": "🎐",
        "title": "wind chime"
    },
    ":moon_viewing_ceremony:": {
        "src": "/images/emojis/unicode/svg/1f391.svg",
        "alt": "🎑",
        "title": "moon viewing ceremony"
    },
    ":red_envelope:": {
        "src": "/images/emojis/unicode/svg/1f9e7.svg",
        "alt": "🧧",
        "title": "red envelope"
    },
    ":ribbon:": {
        "src": "/images/emojis/unicode/svg/1f380.svg",
        "alt": "🎀",
        "title": "ribbon"
    },
    ":wrapped_gift:": {
        "src": "/images/emojis/unicode/svg/1f381.svg",
        "alt": "🎁",
        "title": "wrapped gift"
    },
    ":reminder_ribbon:": {
        "src": "/images/emojis/unicode/svg/1f397.svg",
        "alt": "🎗",
        "title": "reminder ribbon"
    },
    ":admission_tickets:": {
        "src": "/images/emojis/unicode/svg/1f39f.svg",
        "alt": "🎟",
        "title": "admission tickets"
    },
    ":ticket:": {
        "src": "/images/emojis/unicode/svg/1f3ab.svg",
        "alt": "🎫",
        "title": "ticket"
    },
    ":military_medal:": {
        "src": "/images/emojis/unicode/svg/1f396.svg",
        "alt": "🎖",
        "title": "military medal"
    },
    ":trophy:": {
        "src": "/images/emojis/unicode/svg/1f3c6.svg",
        "alt": "🏆",
        "title": "trophy"
    },
    ":sports_medal:": {
        "src": "/images/emojis/unicode/svg/1f3c5.svg",
        "alt": "🏅",
        "title": "sports medal"
    },
    ":1st_place_medal:": {
        "src": "/images/emojis/unicode/svg/1f947.svg",
        "alt": "🥇",
        "title": "1st place medal"
    },
    ":2nd_place_medal:": {
        "src": "/images/emojis/unicode/svg/1f948.svg",
        "alt": "🥈",
        "title": "2nd place medal"
    },
    ":3rd_place_medal:": {
        "src": "/images/emojis/unicode/svg/1f949.svg",
        "alt": "🥉",
        "title": "3rd place medal"
    },
    ":soccer_ball:": {
        "src": "/images/emojis/unicode/svg/26bd.svg",
        "alt": "⚽",
        "title": "soccer ball"
    },
    ":baseball:": {
        "src": "/images/emojis/unicode/svg/26be.svg",
        "alt": "⚾",
        "title": "baseball"
    },
    ":softball:": {
        "src": "/images/emojis/unicode/svg/1f94e.svg",
        "alt": "🥎",
        "title": "softball"
    },
    ":basketball:": {
        "src": "/images/emojis/unicode/svg/1f3c0.svg",
        "alt": "🏀",
        "title": "basketball"
    },
    ":volleyball:": {
        "src": "/images/emojis/unicode/svg/1f3d0.svg",
        "alt": "🏐",
        "title": "volleyball"
    },
    ":american_football:": {
        "src": "/images/emojis/unicode/svg/1f3c8.svg",
        "alt": "🏈",
        "title": "american football"
    },
    ":rugby_football:": {
        "src": "/images/emojis/unicode/svg/1f3c9.svg",
        "alt": "🏉",
        "title": "rugby football"
    },
    ":tennis:": {
        "src": "/images/emojis/unicode/svg/1f3be.svg",
        "alt": "🎾",
        "title": "tennis"
    },
    ":flying_disc:": {
        "src": "/images/emojis/unicode/svg/1f94f.svg",
        "alt": "🥏",
        "title": "flying disc"
    },
    ":bowling:": {
        "src": "/images/emojis/unicode/svg/1f3b3.svg",
        "alt": "🎳",
        "title": "bowling"
    },
    ":cricket_game:": {
        "src": "/images/emojis/unicode/svg/1f3cf.svg",
        "alt": "🏏",
        "title": "cricket game"
    },
    ":field_hockey:": {
        "src": "/images/emojis/unicode/svg/1f3d1.svg",
        "alt": "🏑",
        "title": "field hockey"
    },
    ":ice_hockey:": {
        "src": "/images/emojis/unicode/svg/1f3d2.svg",
        "alt": "🏒",
        "title": "ice hockey"
    },
    ":lacrosse:": {
        "src": "/images/emojis/unicode/svg/1f94d.svg",
        "alt": "🥍",
        "title": "lacrosse"
    },
    ":ping_pong:": {
        "src": "/images/emojis/unicode/svg/1f3d3.svg",
        "alt": "🏓",
        "title": "ping pong"
    },
    ":badminton:": {
        "src": "/images/emojis/unicode/svg/1f3f8.svg",
        "alt": "🏸",
        "title": "badminton"
    },
    ":boxing_glove:": {
        "src": "/images/emojis/unicode/svg/1f94a.svg",
        "alt": "🥊",
        "title": "boxing glove"
    },
    ":martial_arts_uniform:": {
        "src": "/images/emojis/unicode/svg/1f94b.svg",
        "alt": "🥋",
        "title": "martial arts uniform"
    },
    ":goal_net:": {
        "src": "/images/emojis/unicode/svg/1f945.svg",
        "alt": "🥅",
        "title": "goal net"
    },
    ":flag_in_hole:": {
        "src": "/images/emojis/unicode/svg/26f3.svg",
        "alt": "⛳",
        "title": "flag in hole"
    },
    ":ice_skate:": {
        "src": "/images/emojis/unicode/svg/26f8.svg",
        "alt": "⛸",
        "title": "ice skate"
    },
    ":fishing_pole:": {
        "src": "/images/emojis/unicode/svg/1f3a3.svg",
        "alt": "🎣",
        "title": "fishing pole"
    },
    ":diving_mask:": {
        "src": "/images/emojis/unicode/svg/1f93f.svg",
        "alt": "🤿",
        "title": "diving mask"
    },
    ":running_shirt:": {
        "src": "/images/emojis/unicode/svg/1f3bd.svg",
        "alt": "🎽",
        "title": "running shirt"
    },
    ":skis:": {
        "src": "/images/emojis/unicode/svg/1f3bf.svg",
        "alt": "🎿",
        "title": "skis"
    },
    ":sled:": {
        "src": "/images/emojis/unicode/svg/1f6f7.svg",
        "alt": "🛷",
        "title": "sled"
    },
    ":curling_stone:": {
        "src": "/images/emojis/unicode/svg/1f94c.svg",
        "alt": "🥌",
        "title": "curling stone"
    },
    ":bullseye:": {
        "src": "/images/emojis/unicode/svg/1f3af.svg",
        "alt": "🎯",
        "title": "bullseye"
    },
    ":yo_yo:": {
        "src": "/images/emojis/unicode/svg/1fa80.svg",
        "alt": "🪀",
        "title": "yo-yo"
    },
    ":kite:": {
        "src": "/images/emojis/unicode/svg/1fa81.svg",
        "alt": "🪁",
        "title": "kite"
    },
    ":pool_8_ball:": {
        "src": "/images/emojis/unicode/svg/1f3b1.svg",
        "alt": "🎱",
        "title": "pool 8 ball"
    },
    ":crystal_ball:": {
        "src": "/images/emojis/unicode/svg/1f52e.svg",
        "alt": "🔮",
        "title": "crystal ball"
    },
    ":magic_wand:": {
        "src": "/images/emojis/unicode/svg/1fa84.svg",
        "alt": "🪄",
        "title": "magic wand"
    },
    ":nazar_amulet:": {
        "src": "/images/emojis/unicode/svg/1f9ff.svg",
        "alt": "🧿",
        "title": "nazar amulet"
    },
    ":video_game:": {
        "src": "/images/emojis/unicode/svg/1f3ae.svg",
        "alt": "🎮",
        "title": "video game"
    },
    ":joystick:": {
        "src": "/images/emojis/unicode/svg/1f579.svg",
        "alt": "🕹",
        "title": "joystick"
    },
    ":slot_machine:": {
        "src": "/images/emojis/unicode/svg/1f3b0.svg",
        "alt": "🎰",
        "title": "slot machine"
    },
    ":game_die:": {
        "src": "/images/emojis/unicode/svg/1f3b2.svg",
        "alt": "🎲",
        "title": "game die"
    },
    ":puzzle_piece:": {
        "src": "/images/emojis/unicode/svg/1f9e9.svg",
        "alt": "🧩",
        "title": "puzzle piece"
    },
    ":teddy_bear:": {
        "src": "/images/emojis/unicode/svg/1f9f8.svg",
        "alt": "🧸",
        "title": "teddy bear"
    },
    ":pinata:": {
        "src": "/images/emojis/unicode/svg/1fa85.svg",
        "alt": "🪅",
        "title": "piñata"
    },
    ":nesting_dolls:": {
        "src": "/images/emojis/unicode/svg/1fa86.svg",
        "alt": "🪆",
        "title": "nesting dolls"
    },
    ":spade_suit:": {
        "src": "/images/emojis/unicode/svg/2660.svg",
        "alt": "♠",
        "title": "spade suit"
    },
    ":heart_suit:": {
        "src": "/images/emojis/unicode/svg/2665.svg",
        "alt": "♥",
        "title": "heart suit"
    },
    ":diamond_suit:": {
        "src": "/images/emojis/unicode/svg/2666.svg",
        "alt": "♦",
        "title": "diamond suit"
    },
    ":club_suit:": {
        "src": "/images/emojis/unicode/svg/2663.svg",
        "alt": "♣",
        "title": "club suit"
    },
    ":chess_pawn:": {
        "src": "/images/emojis/unicode/svg/265f.svg",
        "alt": "♟",
        "title": "chess pawn"
    },
    ":joker:": {
        "src": "/images/emojis/unicode/svg/1f0cf.svg",
        "alt": "🃏",
        "title": "joker"
    },
    ":mahjong_red_dragon:": {
        "src": "/images/emojis/unicode/svg/1f004.svg",
        "alt": "🀄",
        "title": "mahjong red dragon"
    },
    ":flower_playing_cards:": {
        "src": "/images/emojis/unicode/svg/1f3b4.svg",
        "alt": "🎴",
        "title": "flower playing cards"
    },
    ":performing_arts:": {
        "src": "/images/emojis/unicode/svg/1f3ad.svg",
        "alt": "🎭",
        "title": "performing arts"
    },
    ":framed_picture:": {
        "src": "/images/emojis/unicode/svg/1f5bc.svg",
        "alt": "🖼",
        "title": "framed picture"
    },
    ":artist_palette:": {
        "src": "/images/emojis/unicode/svg/1f3a8.svg",
        "alt": "🎨",
        "title": "artist palette"
    },
    ":thread:": {
        "src": "/images/emojis/unicode/svg/1f9f5.svg",
        "alt": "🧵",
        "title": "thread"
    },
    ":sewing_needle:": {
        "src": "/images/emojis/unicode/svg/1faa1.svg",
        "alt": "🪡",
        "title": "sewing needle"
    },
    ":yarn:": {
        "src": "/images/emojis/unicode/svg/1f9f6.svg",
        "alt": "🧶",
        "title": "yarn"
    },
    ":knot:": {
        "src": "/images/emojis/unicode/svg/1faa2.svg",
        "alt": "🪢",
        "title": "knot"
    },
    ":glasses:": {
        "src": "/images/emojis/unicode/svg/1f453.svg",
        "alt": "👓",
        "title": "glasses"
    },
    ":sunglasses:": {
        "src": "/images/emojis/unicode/svg/1f576.svg",
        "alt": "🕶",
        "title": "sunglasses"
    },
    ":goggles:": {
        "src": "/images/emojis/unicode/svg/1f97d.svg",
        "alt": "🥽",
        "title": "goggles"
    },
    ":lab_coat:": {
        "src": "/images/emojis/unicode/svg/1f97c.svg",
        "alt": "🥼",
        "title": "lab coat"
    },
    ":safety_vest:": {
        "src": "/images/emojis/unicode/svg/1f9ba.svg",
        "alt": "🦺",
        "title": "safety vest"
    },
    ":necktie:": {
        "src": "/images/emojis/unicode/svg/1f454.svg",
        "alt": "👔",
        "title": "necktie"
    },
    ":t_shirt:": {
        "src": "/images/emojis/unicode/svg/1f455.svg",
        "alt": "👕",
        "title": "t-shirt"
    },
    ":jeans:": {
        "src": "/images/emojis/unicode/svg/1f456.svg",
        "alt": "👖",
        "title": "jeans"
    },
    ":scarf:": {
        "src": "/images/emojis/unicode/svg/1f9e3.svg",
        "alt": "🧣",
        "title": "scarf"
    },
    ":gloves:": {
        "src": "/images/emojis/unicode/svg/1f9e4.svg",
        "alt": "🧤",
        "title": "gloves"
    },
    ":coat:": {
        "src": "/images/emojis/unicode/svg/1f9e5.svg",
        "alt": "🧥",
        "title": "coat"
    },
    ":socks:": {
        "src": "/images/emojis/unicode/svg/1f9e6.svg",
        "alt": "🧦",
        "title": "socks"
    },
    ":dress:": {
        "src": "/images/emojis/unicode/svg/1f457.svg",
        "alt": "👗",
        "title": "dress"
    },
    ":kimono:": {
        "src": "/images/emojis/unicode/svg/1f458.svg",
        "alt": "👘",
        "title": "kimono"
    },
    ":sari:": {
        "src": "/images/emojis/unicode/svg/1f97b.svg",
        "alt": "🥻",
        "title": "sari"
    },
    ":one_piece_swimsuit:": {
        "src": "/images/emojis/unicode/svg/1fa71.svg",
        "alt": "🩱",
        "title": "one-piece swimsuit"
    },
    ":briefs:": {
        "src": "/images/emojis/unicode/svg/1fa72.svg",
        "alt": "🩲",
        "title": "briefs"
    },
    ":shorts:": {
        "src": "/images/emojis/unicode/svg/1fa73.svg",
        "alt": "🩳",
        "title": "shorts"
    },
    ":bikini:": {
        "src": "/images/emojis/unicode/svg/1f459.svg",
        "alt": "👙",
        "title": "bikini"
    },
    ":womans_clothes:": {
        "src": "/images/emojis/unicode/svg/1f45a.svg",
        "alt": "👚",
        "title": "woman’s clothes"
    },
    ":purse:": {
        "src": "/images/emojis/unicode/svg/1f45b.svg",
        "alt": "👛",
        "title": "purse"
    },
    ":handbag:": {
        "src": "/images/emojis/unicode/svg/1f45c.svg",
        "alt": "👜",
        "title": "handbag"
    },
    ":clutch_bag:": {
        "src": "/images/emojis/unicode/svg/1f45d.svg",
        "alt": "👝",
        "title": "clutch bag"
    },
    ":shopping_bags:": {
        "src": "/images/emojis/unicode/svg/1f6cd.svg",
        "alt": "🛍",
        "title": "shopping bags"
    },
    ":backpack:": {
        "src": "/images/emojis/unicode/svg/1f392.svg",
        "alt": "🎒",
        "title": "backpack"
    },
    ":thong_sandal:": {
        "src": "/images/emojis/unicode/svg/1fa74.svg",
        "alt": "🩴",
        "title": "thong sandal"
    },
    ":mans_shoe:": {
        "src": "/images/emojis/unicode/svg/1f45e.svg",
        "alt": "👞",
        "title": "man’s shoe"
    },
    ":running_shoe:": {
        "src": "/images/emojis/unicode/svg/1f45f.svg",
        "alt": "👟",
        "title": "running shoe"
    },
    ":hiking_boot:": {
        "src": "/images/emojis/unicode/svg/1f97e.svg",
        "alt": "🥾",
        "title": "hiking boot"
    },
    ":flat_shoe:": {
        "src": "/images/emojis/unicode/svg/1f97f.svg",
        "alt": "🥿",
        "title": "flat shoe"
    },
    ":high_heeled_shoe:": {
        "src": "/images/emojis/unicode/svg/1f460.svg",
        "alt": "👠",
        "title": "high-heeled shoe"
    },
    ":womans_sandal:": {
        "src": "/images/emojis/unicode/svg/1f461.svg",
        "alt": "👡",
        "title": "woman’s sandal"
    },
    ":ballet_shoes:": {
        "src": "/images/emojis/unicode/svg/1fa70.svg",
        "alt": "🩰",
        "title": "ballet shoes"
    },
    ":womans_boot:": {
        "src": "/images/emojis/unicode/svg/1f462.svg",
        "alt": "👢",
        "title": "woman’s boot"
    },
    ":crown:": {
        "src": "/images/emojis/unicode/svg/1f451.svg",
        "alt": "👑",
        "title": "crown"
    },
    ":womans_hat:": {
        "src": "/images/emojis/unicode/svg/1f452.svg",
        "alt": "👒",
        "title": "woman’s hat"
    },
    ":top_hat:": {
        "src": "/images/emojis/unicode/svg/1f3a9.svg",
        "alt": "🎩",
        "title": "top hat"
    },
    ":graduation_cap:": {
        "src": "/images/emojis/unicode/svg/1f393.svg",
        "alt": "🎓",
        "title": "graduation cap"
    },
    ":billed_cap:": {
        "src": "/images/emojis/unicode/svg/1f9e2.svg",
        "alt": "🧢",
        "title": "billed cap"
    },
    ":military_helmet:": {
        "src": "/images/emojis/unicode/svg/1fa96.svg",
        "alt": "🪖",
        "title": "military helmet"
    },
    ":rescue_workers_helmet:": {
        "src": "/images/emojis/unicode/svg/26d1.svg",
        "alt": "⛑",
        "title": "rescue worker’s helmet"
    },
    ":prayer_beads:": {
        "src": "/images/emojis/unicode/svg/1f4ff.svg",
        "alt": "📿",
        "title": "prayer beads"
    },
    ":lipstick:": {
        "src": "/images/emojis/unicode/svg/1f484.svg",
        "alt": "💄",
        "title": "lipstick"
    },
    ":ring:": {
        "src": "/images/emojis/unicode/svg/1f48d.svg",
        "alt": "💍",
        "title": "ring"
    },
    ":gem_stone:": {
        "src": "/images/emojis/unicode/svg/1f48e.svg",
        "alt": "💎",
        "title": "gem stone"
    },
    ":muted_speaker:": {
        "src": "/images/emojis/unicode/svg/1f507.svg",
        "alt": "🔇",
        "title": "muted speaker"
    },
    ":speaker_low_volume:": {
        "src": "/images/emojis/unicode/svg/1f508.svg",
        "alt": "🔈",
        "title": "speaker low volume"
    },
    ":speaker_medium_volume:": {
        "src": "/images/emojis/unicode/svg/1f509.svg",
        "alt": "🔉",
        "title": "speaker medium volume"
    },
    ":speaker_high_volume:": {
        "src": "/images/emojis/unicode/svg/1f50a.svg",
        "alt": "🔊",
        "title": "speaker high volume"
    },
    ":loudspeaker:": {
        "src": "/images/emojis/unicode/svg/1f4e2.svg",
        "alt": "📢",
        "title": "loudspeaker"
    },
    ":megaphone:": {
        "src": "/images/emojis/unicode/svg/1f4e3.svg",
        "alt": "📣",
        "title": "megaphone"
    },
    ":postal_horn:": {
        "src": "/images/emojis/unicode/svg/1f4ef.svg",
        "alt": "📯",
        "title": "postal horn"
    },
    ":bell:": {
        "src": "/images/emojis/unicode/svg/1f514.svg",
        "alt": "🔔",
        "title": "bell"
    },
    ":bell_with_slash:": {
        "src": "/images/emojis/unicode/svg/1f515.svg",
        "alt": "🔕",
        "title": "bell with slash"
    },
    ":musical_score:": {
        "src": "/images/emojis/unicode/svg/1f3bc.svg",
        "alt": "🎼",
        "title": "musical score"
    },
    ":musical_note:": {
        "src": "/images/emojis/unicode/svg/1f3b5.svg",
        "alt": "🎵",
        "title": "musical note"
    },
    ":musical_notes:": {
        "src": "/images/emojis/unicode/svg/1f3b6.svg",
        "alt": "🎶",
        "title": "musical notes"
    },
    ":studio_microphone:": {
        "src": "/images/emojis/unicode/svg/1f399.svg",
        "alt": "🎙",
        "title": "studio microphone"
    },
    ":level_slider:": {
        "src": "/images/emojis/unicode/svg/1f39a.svg",
        "alt": "🎚",
        "title": "level slider"
    },
    ":control_knobs:": {
        "src": "/images/emojis/unicode/svg/1f39b.svg",
        "alt": "🎛",
        "title": "control knobs"
    },
    ":microphone:": {
        "src": "/images/emojis/unicode/svg/1f3a4.svg",
        "alt": "🎤",
        "title": "microphone"
    },
    ":headphone:": {
        "src": "/images/emojis/unicode/svg/1f3a7.svg",
        "alt": "🎧",
        "title": "headphone"
    },
    ":radio:": {
        "src": "/images/emojis/unicode/svg/1f4fb.svg",
        "alt": "📻",
        "title": "radio"
    },
    ":saxophone:": {
        "src": "/images/emojis/unicode/svg/1f3b7.svg",
        "alt": "🎷",
        "title": "saxophone"
    },
    ":accordion:": {
        "src": "/images/emojis/unicode/svg/1fa97.svg",
        "alt": "🪗",
        "title": "accordion"
    },
    ":guitar:": {
        "src": "/images/emojis/unicode/svg/1f3b8.svg",
        "alt": "🎸",
        "title": "guitar"
    },
    ":musical_keyboard:": {
        "src": "/images/emojis/unicode/svg/1f3b9.svg",
        "alt": "🎹",
        "title": "musical keyboard"
    },
    ":trumpet:": {
        "src": "/images/emojis/unicode/svg/1f3ba.svg",
        "alt": "🎺",
        "title": "trumpet"
    },
    ":violin:": {
        "src": "/images/emojis/unicode/svg/1f3bb.svg",
        "alt": "🎻",
        "title": "violin"
    },
    ":banjo:": {
        "src": "/images/emojis/unicode/svg/1fa95.svg",
        "alt": "🪕",
        "title": "banjo"
    },
    ":drum:": {
        "src": "/images/emojis/unicode/svg/1f941.svg",
        "alt": "🥁",
        "title": "drum"
    },
    ":long_drum:": {
        "src": "/images/emojis/unicode/svg/1fa98.svg",
        "alt": "🪘",
        "title": "long drum"
    },
    ":mobile_phone:": {
        "src": "/images/emojis/unicode/svg/1f4f1.svg",
        "alt": "📱",
        "title": "mobile phone"
    },
    ":mobile_phone_with_arrow:": {
        "src": "/images/emojis/unicode/svg/1f4f2.svg",
        "alt": "📲",
        "title": "mobile phone with arrow"
    },
    ":telephone:": {
        "src": "/images/emojis/unicode/svg/260e.svg",
        "alt": "☎",
        "title": "telephone"
    },
    ":telephone_receiver:": {
        "src": "/images/emojis/unicode/svg/1f4de.svg",
        "alt": "📞",
        "title": "telephone receiver"
    },
    ":pager:": {
        "src": "/images/emojis/unicode/svg/1f4df.svg",
        "alt": "📟",
        "title": "pager"
    },
    ":fax_machine:": {
        "src": "/images/emojis/unicode/svg/1f4e0.svg",
        "alt": "📠",
        "title": "fax machine"
    },
    ":battery:": {
        "src": "/images/emojis/unicode/svg/1f50b.svg",
        "alt": "🔋",
        "title": "battery"
    },
    ":electric_plug:": {
        "src": "/images/emojis/unicode/svg/1f50c.svg",
        "alt": "🔌",
        "title": "electric plug"
    },
    ":laptop:": {
        "src": "/images/emojis/unicode/svg/1f4bb.svg",
        "alt": "💻",
        "title": "laptop"
    },
    ":desktop_computer:": {
        "src": "/images/emojis/unicode/svg/1f5a5.svg",
        "alt": "🖥",
        "title": "desktop computer"
    },
    ":printer:": {
        "src": "/images/emojis/unicode/svg/1f5a8.svg",
        "alt": "🖨",
        "title": "printer"
    },
    ":keyboard:": {
        "src": "/images/emojis/unicode/svg/2328.svg",
        "alt": "⌨",
        "title": "keyboard"
    },
    ":computer_mouse:": {
        "src": "/images/emojis/unicode/svg/1f5b1.svg",
        "alt": "🖱",
        "title": "computer mouse"
    },
    ":trackball:": {
        "src": "/images/emojis/unicode/svg/1f5b2.svg",
        "alt": "🖲",
        "title": "trackball"
    },
    ":computer_disk:": {
        "src": "/images/emojis/unicode/svg/1f4bd.svg",
        "alt": "💽",
        "title": "computer disk"
    },
    ":floppy_disk:": {
        "src": "/images/emojis/unicode/svg/1f4be.svg",
        "alt": "💾",
        "title": "floppy disk"
    },
    ":optical_disk:": {
        "src": "/images/emojis/unicode/svg/1f4bf.svg",
        "alt": "💿",
        "title": "optical disk"
    },
    ":dvd:": {
        "src": "/images/emojis/unicode/svg/1f4c0.svg",
        "alt": "📀",
        "title": "dvd"
    },
    ":abacus:": {
        "src": "/images/emojis/unicode/svg/1f9ee.svg",
        "alt": "🧮",
        "title": "abacus"
    },
    ":movie_camera:": {
        "src": "/images/emojis/unicode/svg/1f3a5.svg",
        "alt": "🎥",
        "title": "movie camera"
    },
    ":film_frames:": {
        "src": "/images/emojis/unicode/svg/1f39e.svg",
        "alt": "🎞",
        "title": "film frames"
    },
    ":film_projector:": {
        "src": "/images/emojis/unicode/svg/1f4fd.svg",
        "alt": "📽",
        "title": "film projector"
    },
    ":clapper_board:": {
        "src": "/images/emojis/unicode/svg/1f3ac.svg",
        "alt": "🎬",
        "title": "clapper board"
    },
    ":television:": {
        "src": "/images/emojis/unicode/svg/1f4fa.svg",
        "alt": "📺",
        "title": "television"
    },
    ":camera:": {
        "src": "/images/emojis/unicode/svg/1f4f7.svg",
        "alt": "📷",
        "title": "camera"
    },
    ":camera_with_flash:": {
        "src": "/images/emojis/unicode/svg/1f4f8.svg",
        "alt": "📸",
        "title": "camera with flash"
    },
    ":video_camera:": {
        "src": "/images/emojis/unicode/svg/1f4f9.svg",
        "alt": "📹",
        "title": "video camera"
    },
    ":videocassette:": {
        "src": "/images/emojis/unicode/svg/1f4fc.svg",
        "alt": "📼",
        "title": "videocassette"
    },
    ":magnifying_glass_tilted_left:": {
        "src": "/images/emojis/unicode/svg/1f50d.svg",
        "alt": "🔍",
        "title": "magnifying glass tilted left"
    },
    ":magnifying_glass_tilted_right:": {
        "src": "/images/emojis/unicode/svg/1f50e.svg",
        "alt": "🔎",
        "title": "magnifying glass tilted right"
    },
    ":candle:": {
        "src": "/images/emojis/unicode/svg/1f56f.svg",
        "alt": "🕯",
        "title": "candle"
    },
    ":light_bulb:": {
        "src": "/images/emojis/unicode/svg/1f4a1.svg",
        "alt": "💡",
        "title": "light bulb"
    },
    ":flashlight:": {
        "src": "/images/emojis/unicode/svg/1f526.svg",
        "alt": "🔦",
        "title": "flashlight"
    },
    ":red_paper_lantern:": {
        "src": "/images/emojis/unicode/svg/1f3ee.svg",
        "alt": "🏮",
        "title": "red paper lantern"
    },
    ":diya_lamp:": {
        "src": "/images/emojis/unicode/svg/1fa94.svg",
        "alt": "🪔",
        "title": "diya lamp"
    },
    ":notebook_with_decorative_cover:": {
        "src": "/images/emojis/unicode/svg/1f4d4.svg",
        "alt": "📔",
        "title": "notebook with decorative cover"
    },
    ":closed_book:": {
        "src": "/images/emojis/unicode/svg/1f4d5.svg",
        "alt": "📕",
        "title": "closed book"
    },
    ":open_book:": {
        "src": "/images/emojis/unicode/svg/1f4d6.svg",
        "alt": "📖",
        "title": "open book"
    },
    ":green_book:": {
        "src": "/images/emojis/unicode/svg/1f4d7.svg",
        "alt": "📗",
        "title": "green book"
    },
    ":blue_book:": {
        "src": "/images/emojis/unicode/svg/1f4d8.svg",
        "alt": "📘",
        "title": "blue book"
    },
    ":orange_book:": {
        "src": "/images/emojis/unicode/svg/1f4d9.svg",
        "alt": "📙",
        "title": "orange book"
    },
    ":books:": {
        "src": "/images/emojis/unicode/svg/1f4da.svg",
        "alt": "📚",
        "title": "books"
    },
    ":notebook:": {
        "src": "/images/emojis/unicode/svg/1f4d3.svg",
        "alt": "📓",
        "title": "notebook"
    },
    ":ledger:": {
        "src": "/images/emojis/unicode/svg/1f4d2.svg",
        "alt": "📒",
        "title": "ledger"
    },
    ":page_with_curl:": {
        "src": "/images/emojis/unicode/svg/1f4c3.svg",
        "alt": "📃",
        "title": "page with curl"
    },
    ":scroll:": {
        "src": "/images/emojis/unicode/svg/1f4dc.svg",
        "alt": "📜",
        "title": "scroll"
    },
    ":page_facing_up:": {
        "src": "/images/emojis/unicode/svg/1f4c4.svg",
        "alt": "📄",
        "title": "page facing up"
    },
    ":newspaper:": {
        "src": "/images/emojis/unicode/svg/1f4f0.svg",
        "alt": "📰",
        "title": "newspaper"
    },
    ":rolled_up_newspaper:": {
        "src": "/images/emojis/unicode/svg/1f5de.svg",
        "alt": "🗞",
        "title": "rolled-up newspaper"
    },
    ":bookmark_tabs:": {
        "src": "/images/emojis/unicode/svg/1f4d1.svg",
        "alt": "📑",
        "title": "bookmark tabs"
    },
    ":bookmark:": {
        "src": "/images/emojis/unicode/svg/1f516.svg",
        "alt": "🔖",
        "title": "bookmark"
    },
    ":label:": {
        "src": "/images/emojis/unicode/svg/1f3f7.svg",
        "alt": "🏷",
        "title": "label"
    },
    ":money_bag:": {
        "src": "/images/emojis/unicode/svg/1f4b0.svg",
        "alt": "💰",
        "title": "money bag"
    },
    ":coin:": {
        "src": "/images/emojis/unicode/svg/1fa99.svg",
        "alt": "🪙",
        "title": "coin"
    },
    ":yen_banknote:": {
        "src": "/images/emojis/unicode/svg/1f4b4.svg",
        "alt": "💴",
        "title": "yen banknote"
    },
    ":dollar_banknote:": {
        "src": "/images/emojis/unicode/svg/1f4b5.svg",
        "alt": "💵",
        "title": "dollar banknote"
    },
    ":euro_banknote:": {
        "src": "/images/emojis/unicode/svg/1f4b6.svg",
        "alt": "💶",
        "title": "euro banknote"
    },
    ":pound_banknote:": {
        "src": "/images/emojis/unicode/svg/1f4b7.svg",
        "alt": "💷",
        "title": "pound banknote"
    },
    ":money_with_wings:": {
        "src": "/images/emojis/unicode/svg/1f4b8.svg",
        "alt": "💸",
        "title": "money with wings"
    },
    ":credit_card:": {
        "src": "/images/emojis/unicode/svg/1f4b3.svg",
        "alt": "💳",
        "title": "credit card"
    },
    ":receipt:": {
        "src": "/images/emojis/unicode/svg/1f9fe.svg",
        "alt": "🧾",
        "title": "receipt"
    },
    ":chart_increasing_with_yen:": {
        "src": "/images/emojis/unicode/svg/1f4b9.svg",
        "alt": "💹",
        "title": "chart increasing with yen"
    },
    ":envelope:": {
        "src": "/images/emojis/unicode/svg/2709.svg",
        "alt": "✉",
        "title": "envelope"
    },
    ":e_mail:": {
        "src": "/images/emojis/unicode/svg/1f4e7.svg",
        "alt": "📧",
        "title": "e-mail"
    },
    ":incoming_envelope:": {
        "src": "/images/emojis/unicode/svg/1f4e8.svg",
        "alt": "📨",
        "title": "incoming envelope"
    },
    ":envelope_with_arrow:": {
        "src": "/images/emojis/unicode/svg/1f4e9.svg",
        "alt": "📩",
        "title": "envelope with arrow"
    },
    ":outbox_tray:": {
        "src": "/images/emojis/unicode/svg/1f4e4.svg",
        "alt": "📤",
        "title": "outbox tray"
    },
    ":inbox_tray:": {
        "src": "/images/emojis/unicode/svg/1f4e5.svg",
        "alt": "📥",
        "title": "inbox tray"
    },
    ":package:": {
        "src": "/images/emojis/unicode/svg/1f4e6.svg",
        "alt": "📦",
        "title": "package"
    },
    ":closed_mailbox_with_raised_flag:": {
        "src": "/images/emojis/unicode/svg/1f4eb.svg",
        "alt": "📫",
        "title": "closed mailbox with raised flag"
    },
    ":closed_mailbox_with_lowered_flag:": {
        "src": "/images/emojis/unicode/svg/1f4ea.svg",
        "alt": "📪",
        "title": "closed mailbox with lowered flag"
    },
    ":open_mailbox_with_raised_flag:": {
        "src": "/images/emojis/unicode/svg/1f4ec.svg",
        "alt": "📬",
        "title": "open mailbox with raised flag"
    },
    ":open_mailbox_with_lowered_flag:": {
        "src": "/images/emojis/unicode/svg/1f4ed.svg",
        "alt": "📭",
        "title": "open mailbox with lowered flag"
    },
    ":postbox:": {
        "src": "/images/emojis/unicode/svg/1f4ee.svg",
        "alt": "📮",
        "title": "postbox"
    },
    ":ballot_box_with_ballot:": {
        "src": "/images/emojis/unicode/svg/1f5f3.svg",
        "alt": "🗳",
        "title": "ballot box with ballot"
    },
    ":pencil:": {
        "src": "/images/emojis/unicode/svg/270f.svg",
        "alt": "✏",
        "title": "pencil"
    },
    ":black_nib:": {
        "src": "/images/emojis/unicode/svg/2712.svg",
        "alt": "✒",
        "title": "black nib"
    },
    ":fountain_pen:": {
        "src": "/images/emojis/unicode/svg/1f58b.svg",
        "alt": "🖋",
        "title": "fountain pen"
    },
    ":pen:": {
        "src": "/images/emojis/unicode/svg/1f58a.svg",
        "alt": "🖊",
        "title": "pen"
    },
    ":paintbrush:": {
        "src": "/images/emojis/unicode/svg/1f58c.svg",
        "alt": "🖌",
        "title": "paintbrush"
    },
    ":crayon:": {
        "src": "/images/emojis/unicode/svg/1f58d.svg",
        "alt": "🖍",
        "title": "crayon"
    },
    ":memo:": {
        "src": "/images/emojis/unicode/svg/1f4dd.svg",
        "alt": "📝",
        "title": "memo"
    },
    ":briefcase:": {
        "src": "/images/emojis/unicode/svg/1f4bc.svg",
        "alt": "💼",
        "title": "briefcase"
    },
    ":file_folder:": {
        "src": "/images/emojis/unicode/svg/1f4c1.svg",
        "alt": "📁",
        "title": "file folder"
    },
    ":open_file_folder:": {
        "src": "/images/emojis/unicode/svg/1f4c2.svg",
        "alt": "📂",
        "title": "open file folder"
    },
    ":card_index_dividers:": {
        "src": "/images/emojis/unicode/svg/1f5c2.svg",
        "alt": "🗂",
        "title": "card index dividers"
    },
    ":calendar:": {
        "src": "/images/emojis/unicode/svg/1f4c5.svg",
        "alt": "📅",
        "title": "calendar"
    },
    ":tear_off_calendar:": {
        "src": "/images/emojis/unicode/svg/1f4c6.svg",
        "alt": "📆",
        "title": "tear-off calendar"
    },
    ":spiral_notepad:": {
        "src": "/images/emojis/unicode/svg/1f5d2.svg",
        "alt": "🗒",
        "title": "spiral notepad"
    },
    ":spiral_calendar:": {
        "src": "/images/emojis/unicode/svg/1f5d3.svg",
        "alt": "🗓",
        "title": "spiral calendar"
    },
    ":card_index:": {
        "src": "/images/emojis/unicode/svg/1f4c7.svg",
        "alt": "📇",
        "title": "card index"
    },
    ":chart_increasing:": {
        "src": "/images/emojis/unicode/svg/1f4c8.svg",
        "alt": "📈",
        "title": "chart increasing"
    },
    ":chart_decreasing:": {
        "src": "/images/emojis/unicode/svg/1f4c9.svg",
        "alt": "📉",
        "title": "chart decreasing"
    },
    ":bar_chart:": {
        "src": "/images/emojis/unicode/svg/1f4ca.svg",
        "alt": "📊",
        "title": "bar chart"
    },
    ":clipboard:": {
        "src": "/images/emojis/unicode/svg/1f4cb.svg",
        "alt": "📋",
        "title": "clipboard"
    },
    ":pushpin:": {
        "src": "/images/emojis/unicode/svg/1f4cc.svg",
        "alt": "📌",
        "title": "pushpin"
    },
    ":round_pushpin:": {
        "src": "/images/emojis/unicode/svg/1f4cd.svg",
        "alt": "📍",
        "title": "round pushpin"
    },
    ":paperclip:": {
        "src": "/images/emojis/unicode/svg/1f4ce.svg",
        "alt": "📎",
        "title": "paperclip"
    },
    ":linked_paperclips:": {
        "src": "/images/emojis/unicode/svg/1f587.svg",
        "alt": "🖇",
        "title": "linked paperclips"
    },
    ":straight_ruler:": {
        "src": "/images/emojis/unicode/svg/1f4cf.svg",
        "alt": "📏",
        "title": "straight ruler"
    },
    ":triangular_ruler:": {
        "src": "/images/emojis/unicode/svg/1f4d0.svg",
        "alt": "📐",
        "title": "triangular ruler"
    },
    ":scissors:": {
        "src": "/images/emojis/unicode/svg/2702.svg",
        "alt": "✂",
        "title": "scissors"
    },
    ":card_file_box:": {
        "src": "/images/emojis/unicode/svg/1f5c3.svg",
        "alt": "🗃",
        "title": "card file box"
    },
    ":file_cabinet:": {
        "src": "/images/emojis/unicode/svg/1f5c4.svg",
        "alt": "🗄",
        "title": "file cabinet"
    },
    ":wastebasket:": {
        "src": "/images/emojis/unicode/svg/1f5d1.svg",
        "alt": "🗑",
        "title": "wastebasket"
    },
    ":locked:": {
        "src": "/images/emojis/unicode/svg/1f512.svg",
        "alt": "🔒",
        "title": "locked"
    },
    ":unlocked:": {
        "src": "/images/emojis/unicode/svg/1f513.svg",
        "alt": "🔓",
        "title": "unlocked"
    },
    ":locked_with_pen:": {
        "src": "/images/emojis/unicode/svg/1f50f.svg",
        "alt": "🔏",
        "title": "locked with pen"
    },
    ":locked_with_key:": {
        "src": "/images/emojis/unicode/svg/1f510.svg",
        "alt": "🔐",
        "title": "locked with key"
    },
    ":key:": {
        "src": "/images/emojis/unicode/svg/1f511.svg",
        "alt": "🔑",
        "title": "key"
    },
    ":old_key:": {
        "src": "/images/emojis/unicode/svg/1f5dd.svg",
        "alt": "🗝",
        "title": "old key"
    },
    ":hammer:": {
        "src": "/images/emojis/unicode/svg/1f528.svg",
        "alt": "🔨",
        "title": "hammer"
    },
    ":axe:": {
        "src": "/images/emojis/unicode/svg/1fa93.svg",
        "alt": "🪓",
        "title": "axe"
    },
    ":pick:": {
        "src": "/images/emojis/unicode/svg/26cf.svg",
        "alt": "⛏",
        "title": "pick"
    },
    ":hammer_and_pick:": {
        "src": "/images/emojis/unicode/svg/2692.svg",
        "alt": "⚒",
        "title": "hammer and pick"
    },
    ":hammer_and_wrench:": {
        "src": "/images/emojis/unicode/svg/1f6e0.svg",
        "alt": "🛠",
        "title": "hammer and wrench"
    },
    ":dagger:": {
        "src": "/images/emojis/unicode/svg/1f5e1.svg",
        "alt": "🗡",
        "title": "dagger"
    },
    ":crossed_swords:": {
        "src": "/images/emojis/unicode/svg/2694.svg",
        "alt": "⚔",
        "title": "crossed swords"
    },
    ":water_pistol:": {
        "src": "/images/emojis/unicode/svg/1f52b.svg",
        "alt": "🔫",
        "title": "water pistol"
    },
    ":boomerang:": {
        "src": "/images/emojis/unicode/svg/1fa83.svg",
        "alt": "🪃",
        "title": "boomerang"
    },
    ":bow_and_arrow:": {
        "src": "/images/emojis/unicode/svg/1f3f9.svg",
        "alt": "🏹",
        "title": "bow and arrow"
    },
    ":shield:": {
        "src": "/images/emojis/unicode/svg/1f6e1.svg",
        "alt": "🛡",
        "title": "shield"
    },
    ":carpentry_saw:": {
        "src": "/images/emojis/unicode/svg/1fa9a.svg",
        "alt": "🪚",
        "title": "carpentry saw"
    },
    ":wrench:": {
        "src": "/images/emojis/unicode/svg/1f527.svg",
        "alt": "🔧",
        "title": "wrench"
    },
    ":screwdriver:": {
        "src": "/images/emojis/unicode/svg/1fa9b.svg",
        "alt": "🪛",
        "title": "screwdriver"
    },
    ":nut_and_bolt:": {
        "src": "/images/emojis/unicode/svg/1f529.svg",
        "alt": "🔩",
        "title": "nut and bolt"
    },
    ":gear:": {
        "src": "/images/emojis/unicode/svg/2699.svg",
        "alt": "⚙",
        "title": "gear"
    },
    ":clamp:": {
        "src": "/images/emojis/unicode/svg/1f5dc.svg",
        "alt": "🗜",
        "title": "clamp"
    },
    ":balance_scale:": {
        "src": "/images/emojis/unicode/svg/2696.svg",
        "alt": "⚖",
        "title": "balance scale"
    },
    ":white_cane:": {
        "src": "/images/emojis/unicode/svg/1f9af.svg",
        "alt": "🦯",
        "title": "white cane"
    },
    ":link:": {
        "src": "/images/emojis/unicode/svg/1f517.svg",
        "alt": "🔗",
        "title": "link"
    },
    ":chains:": {
        "src": "/images/emojis/unicode/svg/26d3.svg",
        "alt": "⛓",
        "title": "chains"
    },
    ":hook:": {
        "src": "/images/emojis/unicode/svg/1fa9d.svg",
        "alt": "🪝",
        "title": "hook"
    },
    ":toolbox:": {
        "src": "/images/emojis/unicode/svg/1f9f0.svg",
        "alt": "🧰",
        "title": "toolbox"
    },
    ":magnet:": {
        "src": "/images/emojis/unicode/svg/1f9f2.svg",
        "alt": "🧲",
        "title": "magnet"
    },
    ":ladder:": {
        "src": "/images/emojis/unicode/svg/1fa9c.svg",
        "alt": "🪜",
        "title": "ladder"
    },
    ":alembic:": {
        "src": "/images/emojis/unicode/svg/2697.svg",
        "alt": "⚗",
        "title": "alembic"
    },
    ":test_tube:": {
        "src": "/images/emojis/unicode/svg/1f9ea.svg",
        "alt": "🧪",
        "title": "test tube"
    },
    ":petri_dish:": {
        "src": "/images/emojis/unicode/svg/1f9eb.svg",
        "alt": "🧫",
        "title": "petri dish"
    },
    ":dna:": {
        "src": "/images/emojis/unicode/svg/1f9ec.svg",
        "alt": "🧬",
        "title": "dna"
    },
    ":microscope:": {
        "src": "/images/emojis/unicode/svg/1f52c.svg",
        "alt": "🔬",
        "title": "microscope"
    },
    ":telescope:": {
        "src": "/images/emojis/unicode/svg/1f52d.svg",
        "alt": "🔭",
        "title": "telescope"
    },
    ":satellite_antenna:": {
        "src": "/images/emojis/unicode/svg/1f4e1.svg",
        "alt": "📡",
        "title": "satellite antenna"
    },
    ":syringe:": {
        "src": "/images/emojis/unicode/svg/1f489.svg",
        "alt": "💉",
        "title": "syringe"
    },
    ":drop_of_blood:": {
        "src": "/images/emojis/unicode/svg/1fa78.svg",
        "alt": "🩸",
        "title": "drop of blood"
    },
    ":pill:": {
        "src": "/images/emojis/unicode/svg/1f48a.svg",
        "alt": "💊",
        "title": "pill"
    },
    ":adhesive_bandage:": {
        "src": "/images/emojis/unicode/svg/1fa79.svg",
        "alt": "🩹",
        "title": "adhesive bandage"
    },
    ":stethoscope:": {
        "src": "/images/emojis/unicode/svg/1fa7a.svg",
        "alt": "🩺",
        "title": "stethoscope"
    },
    ":door:": {
        "src": "/images/emojis/unicode/svg/1f6aa.svg",
        "alt": "🚪",
        "title": "door"
    },
    ":elevator:": {
        "src": "/images/emojis/unicode/svg/1f6d7.svg",
        "alt": "🛗",
        "title": "elevator"
    },
    ":mirror:": {
        "src": "/images/emojis/unicode/svg/1fa9e.svg",
        "alt": "🪞",
        "title": "mirror"
    },
    ":window:": {
        "src": "/images/emojis/unicode/svg/1fa9f.svg",
        "alt": "🪟",
        "title": "window"
    },
    ":bed:": {
        "src": "/images/emojis/unicode/svg/1f6cf.svg",
        "alt": "🛏",
        "title": "bed"
    },
    ":couch_and_lamp:": {
        "src": "/images/emojis/unicode/svg/1f6cb.svg",
        "alt": "🛋",
        "title": "couch and lamp"
    },
    ":chair:": {
        "src": "/images/emojis/unicode/svg/1fa91.svg",
        "alt": "🪑",
        "title": "chair"
    },
    ":toilet:": {
        "src": "/images/emojis/unicode/svg/1f6bd.svg",
        "alt": "🚽",
        "title": "toilet"
    },
    ":plunger:": {
        "src": "/images/emojis/unicode/svg/1faa0.svg",
        "alt": "🪠",
        "title": "plunger"
    },
    ":shower:": {
        "src": "/images/emojis/unicode/svg/1f6bf.svg",
        "alt": "🚿",
        "title": "shower"
    },
    ":bathtub:": {
        "src": "/images/emojis/unicode/svg/1f6c1.svg",
        "alt": "🛁",
        "title": "bathtub"
    },
    ":mouse_trap:": {
        "src": "/images/emojis/unicode/svg/1faa4.svg",
        "alt": "🪤",
        "title": "mouse trap"
    },
    ":razor:": {
        "src": "/images/emojis/unicode/svg/1fa92.svg",
        "alt": "🪒",
        "title": "razor"
    },
    ":lotion_bottle:": {
        "src": "/images/emojis/unicode/svg/1f9f4.svg",
        "alt": "🧴",
        "title": "lotion bottle"
    },
    ":safety_pin:": {
        "src": "/images/emojis/unicode/svg/1f9f7.svg",
        "alt": "🧷",
        "title": "safety pin"
    },
    ":broom:": {
        "src": "/images/emojis/unicode/svg/1f9f9.svg",
        "alt": "🧹",
        "title": "broom"
    },
    ":basket:": {
        "src": "/images/emojis/unicode/svg/1f9fa.svg",
        "alt": "🧺",
        "title": "basket"
    },
    ":roll_of_paper:": {
        "src": "/images/emojis/unicode/svg/1f9fb.svg",
        "alt": "🧻",
        "title": "roll of paper"
    },
    ":bucket:": {
        "src": "/images/emojis/unicode/svg/1faa3.svg",
        "alt": "🪣",
        "title": "bucket"
    },
    ":soap:": {
        "src": "/images/emojis/unicode/svg/1f9fc.svg",
        "alt": "🧼",
        "title": "soap"
    },
    ":toothbrush:": {
        "src": "/images/emojis/unicode/svg/1faa5.svg",
        "alt": "🪥",
        "title": "toothbrush"
    },
    ":sponge:": {
        "src": "/images/emojis/unicode/svg/1f9fd.svg",
        "alt": "🧽",
        "title": "sponge"
    },
    ":fire_extinguisher:": {
        "src": "/images/emojis/unicode/svg/1f9ef.svg",
        "alt": "🧯",
        "title": "fire extinguisher"
    },
    ":shopping_cart:": {
        "src": "/images/emojis/unicode/svg/1f6d2.svg",
        "alt": "🛒",
        "title": "shopping cart"
    },
    ":cigarette:": {
        "src": "/images/emojis/unicode/svg/1f6ac.svg",
        "alt": "🚬",
        "title": "cigarette"
    },
    ":coffin:": {
        "src": "/images/emojis/unicode/svg/26b0.svg",
        "alt": "⚰",
        "title": "coffin"
    },
    ":headstone:": {
        "src": "/images/emojis/unicode/svg/1faa6.svg",
        "alt": "🪦",
        "title": "headstone"
    },
    ":funeral_urn:": {
        "src": "/images/emojis/unicode/svg/26b1.svg",
        "alt": "⚱",
        "title": "funeral urn"
    },
    ":moai:": {
        "src": "/images/emojis/unicode/svg/1f5ff.svg",
        "alt": "🗿",
        "title": "moai"
    },
    ":placard:": {
        "src": "/images/emojis/unicode/svg/1faa7.svg",
        "alt": "🪧",
        "title": "placard"
    },
    ":atm_sign:": {
        "src": "/images/emojis/unicode/svg/1f3e7.svg",
        "alt": "🏧",
        "title": "ATM sign"
    },
    ":litter_in_bin_sign:": {
        "src": "/images/emojis/unicode/svg/1f6ae.svg",
        "alt": "🚮",
        "title": "litter in bin sign"
    },
    ":potable_water:": {
        "src": "/images/emojis/unicode/svg/1f6b0.svg",
        "alt": "🚰",
        "title": "potable water"
    },
    ":wheelchair_symbol:": {
        "src": "/images/emojis/unicode/svg/267f.svg",
        "alt": "♿",
        "title": "wheelchair symbol"
    },
    ":mens_room:": {
        "src": "/images/emojis/unicode/svg/1f6b9.svg",
        "alt": "🚹",
        "title": "men’s room"
    },
    ":womens_room:": {
        "src": "/images/emojis/unicode/svg/1f6ba.svg",
        "alt": "🚺",
        "title": "women’s room"
    },
    ":restroom:": {
        "src": "/images/emojis/unicode/svg/1f6bb.svg",
        "alt": "🚻",
        "title": "restroom"
    },
    ":baby_symbol:": {
        "src": "/images/emojis/unicode/svg/1f6bc.svg",
        "alt": "🚼",
        "title": "baby symbol"
    },
    ":water_closet:": {
        "src": "/images/emojis/unicode/svg/1f6be.svg",
        "alt": "🚾",
        "title": "water closet"
    },
    ":passport_control:": {
        "src": "/images/emojis/unicode/svg/1f6c2.svg",
        "alt": "🛂",
        "title": "passport control"
    },
    ":customs:": {
        "src": "/images/emojis/unicode/svg/1f6c3.svg",
        "alt": "🛃",
        "title": "customs"
    },
    ":baggage_claim:": {
        "src": "/images/emojis/unicode/svg/1f6c4.svg",
        "alt": "🛄",
        "title": "baggage claim"
    },
    ":left_luggage:": {
        "src": "/images/emojis/unicode/svg/1f6c5.svg",
        "alt": "🛅",
        "title": "left luggage"
    },
    ":warning:": {
        "src": "/images/emojis/unicode/svg/26a0.svg",
        "alt": "⚠",
        "title": "warning"
    },
    ":children_crossing:": {
        "src": "/images/emojis/unicode/svg/1f6b8.svg",
        "alt": "🚸",
        "title": "children crossing"
    },
    ":no_entry:": {
        "src": "/images/emojis/unicode/svg/26d4.svg",
        "alt": "⛔",
        "title": "no entry"
    },
    ":prohibited:": {
        "src": "/images/emojis/unicode/svg/1f6ab.svg",
        "alt": "🚫",
        "title": "prohibited"
    },
    ":no_bicycles:": {
        "src": "/images/emojis/unicode/svg/1f6b3.svg",
        "alt": "🚳",
        "title": "no bicycles"
    },
    ":no_smoking:": {
        "src": "/images/emojis/unicode/svg/1f6ad.svg",
        "alt": "🚭",
        "title": "no smoking"
    },
    ":no_littering:": {
        "src": "/images/emojis/unicode/svg/1f6af.svg",
        "alt": "🚯",
        "title": "no littering"
    },
    ":non_potable_water:": {
        "src": "/images/emojis/unicode/svg/1f6b1.svg",
        "alt": "🚱",
        "title": "non-potable water"
    },
    ":no_pedestrians:": {
        "src": "/images/emojis/unicode/svg/1f6b7.svg",
        "alt": "🚷",
        "title": "no pedestrians"
    },
    ":no_mobile_phones:": {
        "src": "/images/emojis/unicode/svg/1f4f5.svg",
        "alt": "📵",
        "title": "no mobile phones"
    },
    ":no_one_under_eighteen:": {
        "src": "/images/emojis/unicode/svg/1f51e.svg",
        "alt": "🔞",
        "title": "no one under eighteen"
    },
    ":radioactive:": {
        "src": "/images/emojis/unicode/svg/2622.svg",
        "alt": "☢",
        "title": "radioactive"
    },
    ":biohazard:": {
        "src": "/images/emojis/unicode/svg/2623.svg",
        "alt": "☣",
        "title": "biohazard"
    },
    ":up_arrow:": {
        "src": "/images/emojis/unicode/svg/2b06.svg",
        "alt": "⬆",
        "title": "up arrow"
    },
    ":up_right_arrow:": {
        "src": "/images/emojis/unicode/svg/2197.svg",
        "alt": "↗",
        "title": "up-right arrow"
    },
    ":right_arrow:": {
        "src": "/images/emojis/unicode/svg/27a1.svg",
        "alt": "➡",
        "title": "right arrow"
    },
    ":down_right_arrow:": {
        "src": "/images/emojis/unicode/svg/2198.svg",
        "alt": "↘",
        "title": "down-right arrow"
    },
    ":down_arrow:": {
        "src": "/images/emojis/unicode/svg/2b07.svg",
        "alt": "⬇",
        "title": "down arrow"
    },
    ":down_left_arrow:": {
        "src": "/images/emojis/unicode/svg/2199.svg",
        "alt": "↙",
        "title": "down-left arrow"
    },
    ":left_arrow:": {
        "src": "/images/emojis/unicode/svg/2b05.svg",
        "alt": "⬅",
        "title": "left arrow"
    },
    ":up_left_arrow:": {
        "src": "/images/emojis/unicode/svg/2196.svg",
        "alt": "↖",
        "title": "up-left arrow"
    },
    ":up_down_arrow:": {
        "src": "/images/emojis/unicode/svg/2195.svg",
        "alt": "↕",
        "title": "up-down arrow"
    },
    ":left_right_arrow:": {
        "src": "/images/emojis/unicode/svg/2194.svg",
        "alt": "↔",
        "title": "left-right arrow"
    },
    ":right_arrow_curving_left:": {
        "src": "/images/emojis/unicode/svg/21a9.svg",
        "alt": "↩",
        "title": "right arrow curving left"
    },
    ":left_arrow_curving_right:": {
        "src": "/images/emojis/unicode/svg/21aa.svg",
        "alt": "↪",
        "title": "left arrow curving right"
    },
    ":right_arrow_curving_up:": {
        "src": "/images/emojis/unicode/svg/2934.svg",
        "alt": "⤴",
        "title": "right arrow curving up"
    },
    ":right_arrow_curving_down:": {
        "src": "/images/emojis/unicode/svg/2935.svg",
        "alt": "⤵",
        "title": "right arrow curving down"
    },
    ":clockwise_vertical_arrows:": {
        "src": "/images/emojis/unicode/svg/1f503.svg",
        "alt": "🔃",
        "title": "clockwise vertical arrows"
    },
    ":counterclockwise_arrows_button:": {
        "src": "/images/emojis/unicode/svg/1f504.svg",
        "alt": "🔄",
        "title": "counterclockwise arrows button"
    },
    ":back_arrow:": {
        "src": "/images/emojis/unicode/svg/1f519.svg",
        "alt": "🔙",
        "title": "BACK arrow"
    },
    ":end_arrow:": {
        "src": "/images/emojis/unicode/svg/1f51a.svg",
        "alt": "🔚",
        "title": "END arrow"
    },
    ":on_arrow:": {
        "src": "/images/emojis/unicode/svg/1f51b.svg",
        "alt": "🔛",
        "title": "ON! arrow"
    },
    ":soon_arrow:": {
        "src": "/images/emojis/unicode/svg/1f51c.svg",
        "alt": "🔜",
        "title": "SOON arrow"
    },
    ":top_arrow:": {
        "src": "/images/emojis/unicode/svg/1f51d.svg",
        "alt": "🔝",
        "title": "TOP arrow"
    },
    ":place_of_worship:": {
        "src": "/images/emojis/unicode/svg/1f6d0.svg",
        "alt": "🛐",
        "title": "place of worship"
    },
    ":atom_symbol:": {
        "src": "/images/emojis/unicode/svg/269b.svg",
        "alt": "⚛",
        "title": "atom symbol"
    },
    ":om:": {
        "src": "/images/emojis/unicode/svg/1f549.svg",
        "alt": "🕉",
        "title": "om"
    },
    ":star_of_david:": {
        "src": "/images/emojis/unicode/svg/2721.svg",
        "alt": "✡",
        "title": "star of David"
    },
    ":wheel_of_dharma:": {
        "src": "/images/emojis/unicode/svg/2638.svg",
        "alt": "☸",
        "title": "wheel of dharma"
    },
    ":yin_yang:": {
        "src": "/images/emojis/unicode/svg/262f.svg",
        "alt": "☯",
        "title": "yin yang"
    },
    ":latin_cross:": {
        "src": "/images/emojis/unicode/svg/271d.svg",
        "alt": "✝",
        "title": "latin cross"
    },
    ":orthodox_cross:": {
        "src": "/images/emojis/unicode/svg/2626.svg",
        "alt": "☦",
        "title": "orthodox cross"
    },
    ":star_and_crescent:": {
        "src": "/images/emojis/unicode/svg/262a.svg",
        "alt": "☪",
        "title": "star and crescent"
    },
    ":peace_symbol:": {
        "src": "/images/emojis/unicode/svg/262e.svg",
        "alt": "☮",
        "title": "peace symbol"
    },
    ":menorah:": {
        "src": "/images/emojis/unicode/svg/1f54e.svg",
        "alt": "🕎",
        "title": "menorah"
    },
    ":dotted_six_pointed_star:": {
        "src": "/images/emojis/unicode/svg/1f52f.svg",
        "alt": "🔯",
        "title": "dotted six-pointed star"
    },
    ":aries:": {
        "src": "/images/emojis/unicode/svg/2648.svg",
        "alt": "♈",
        "title": "Aries"
    },
    ":taurus:": {
        "src": "/images/emojis/unicode/svg/2649.svg",
        "alt": "♉",
        "title": "Taurus"
    },
    ":gemini:": {
        "src": "/images/emojis/unicode/svg/264a.svg",
        "alt": "♊",
        "title": "Gemini"
    },
    ":cancer:": {
        "src": "/images/emojis/unicode/svg/264b.svg",
        "alt": "♋",
        "title": "Cancer"
    },
    ":leo:": {
        "src": "/images/emojis/unicode/svg/264c.svg",
        "alt": "♌",
        "title": "Leo"
    },
    ":virgo:": {
        "src": "/images/emojis/unicode/svg/264d.svg",
        "alt": "♍",
        "title": "Virgo"
    },
    ":libra:": {
        "src": "/images/emojis/unicode/svg/264e.svg",
        "alt": "♎",
        "title": "Libra"
    },
    ":scorpio:": {
        "src": "/images/emojis/unicode/svg/264f.svg",
        "alt": "♏",
        "title": "Scorpio"
    },
    ":sagittarius:": {
        "src": "/images/emojis/unicode/svg/2650.svg",
        "alt": "♐",
        "title": "Sagittarius"
    },
    ":capricorn:": {
        "src": "/images/emojis/unicode/svg/2651.svg",
        "alt": "♑",
        "title": "Capricorn"
    },
    ":aquarius:": {
        "src": "/images/emojis/unicode/svg/2652.svg",
        "alt": "♒",
        "title": "Aquarius"
    },
    ":pisces:": {
        "src": "/images/emojis/unicode/svg/2653.svg",
        "alt": "♓",
        "title": "Pisces"
    },
    ":ophiuchus:": {
        "src": "/images/emojis/unicode/svg/26ce.svg",
        "alt": "⛎",
        "title": "Ophiuchus"
    },
    ":shuffle_tracks_button:": {
        "src": "/images/emojis/unicode/svg/1f500.svg",
        "alt": "🔀",
        "title": "shuffle tracks button"
    },
    ":repeat_button:": {
        "src": "/images/emojis/unicode/svg/1f501.svg",
        "alt": "🔁",
        "title": "repeat button"
    },
    ":repeat_single_button:": {
        "src": "/images/emojis/unicode/svg/1f502.svg",
        "alt": "🔂",
        "title": "repeat single button"
    },
    ":play_button:": {
        "src": "/images/emojis/unicode/svg/25b6.svg",
        "alt": "▶",
        "title": "play button"
    },
    ":fast_forward_button:": {
        "src": "/images/emojis/unicode/svg/23e9.svg",
        "alt": "⏩",
        "title": "fast-forward button"
    },
    ":next_track_button:": {
        "src": "/images/emojis/unicode/svg/23ed.svg",
        "alt": "⏭",
        "title": "next track button"
    },
    ":play_or_pause_button:": {
        "src": "/images/emojis/unicode/svg/23ef.svg",
        "alt": "⏯",
        "title": "play or pause button"
    },
    ":reverse_button:": {
        "src": "/images/emojis/unicode/svg/25c0.svg",
        "alt": "◀",
        "title": "reverse button"
    },
    ":fast_reverse_button:": {
        "src": "/images/emojis/unicode/svg/23ea.svg",
        "alt": "⏪",
        "title": "fast reverse button"
    },
    ":last_track_button:": {
        "src": "/images/emojis/unicode/svg/23ee.svg",
        "alt": "⏮",
        "title": "last track button"
    },
    ":upwards_button:": {
        "src": "/images/emojis/unicode/svg/1f53c.svg",
        "alt": "🔼",
        "title": "upwards button"
    },
    ":fast_up_button:": {
        "src": "/images/emojis/unicode/svg/23eb.svg",
        "alt": "⏫",
        "title": "fast up button"
    },
    ":downwards_button:": {
        "src": "/images/emojis/unicode/svg/1f53d.svg",
        "alt": "🔽",
        "title": "downwards button"
    },
    ":fast_down_button:": {
        "src": "/images/emojis/unicode/svg/23ec.svg",
        "alt": "⏬",
        "title": "fast down button"
    },
    ":pause_button:": {
        "src": "/images/emojis/unicode/svg/23f8.svg",
        "alt": "⏸",
        "title": "pause button"
    },
    ":stop_button:": {
        "src": "/images/emojis/unicode/svg/23f9.svg",
        "alt": "⏹",
        "title": "stop button"
    },
    ":record_button:": {
        "src": "/images/emojis/unicode/svg/23fa.svg",
        "alt": "⏺",
        "title": "record button"
    },
    ":eject_button:": {
        "src": "/images/emojis/unicode/svg/23cf.svg",
        "alt": "⏏",
        "title": "eject button"
    },
    ":cinema:": {
        "src": "/images/emojis/unicode/svg/1f3a6.svg",
        "alt": "🎦",
        "title": "cinema"
    },
    ":dim_button:": {
        "src": "/images/emojis/unicode/svg/1f505.svg",
        "alt": "🔅",
        "title": "dim button"
    },
    ":bright_button:": {
        "src": "/images/emojis/unicode/svg/1f506.svg",
        "alt": "🔆",
        "title": "bright button"
    },
    ":antenna_bars:": {
        "src": "/images/emojis/unicode/svg/1f4f6.svg",
        "alt": "📶",
        "title": "antenna bars"
    },
    ":vibration_mode:": {
        "src": "/images/emojis/unicode/svg/1f4f3.svg",
        "alt": "📳",
        "title": "vibration mode"
    },
    ":mobile_phone_off:": {
        "src": "/images/emojis/unicode/svg/1f4f4.svg",
        "alt": "📴",
        "title": "mobile phone off"
    },
    ":female_sign:": {
        "src": "/images/emojis/unicode/svg/2640.svg",
        "alt": "♀",
        "title": "female sign"
    },
    ":male_sign:": {
        "src": "/images/emojis/unicode/svg/2642.svg",
        "alt": "♂",
        "title": "male sign"
    },
    ":transgender_symbol:": {
        "src": "/images/emojis/unicode/svg/26a7.svg",
        "alt": "⚧",
        "title": "transgender symbol"
    },
    ":multiply:": {
        "src": "/images/emojis/unicode/svg/2716.svg",
        "alt": "✖",
        "title": "multiply"
    },
    ":plus:": {
        "src": "/images/emojis/unicode/svg/2795.svg",
        "alt": "➕",
        "title": "plus"
    },
    ":minus:": {
        "src": "/images/emojis/unicode/svg/2796.svg",
        "alt": "➖",
        "title": "minus"
    },
    ":divide:": {
        "src": "/images/emojis/unicode/svg/2797.svg",
        "alt": "➗",
        "title": "divide"
    },
    ":infinity:": {
        "src": "/images/emojis/unicode/svg/267e.svg",
        "alt": "♾",
        "title": "infinity"
    },
    ":double_exclamation_mark:": {
        "src": "/images/emojis/unicode/svg/203c.svg",
        "alt": "‼",
        "title": "double exclamation mark"
    },
    ":exclamation_question_mark:": {
        "src": "/images/emojis/unicode/svg/2049.svg",
        "alt": "⁉",
        "title": "exclamation question mark"
    },
    ":red_question_mark:": {
        "src": "/images/emojis/unicode/svg/2753.svg",
        "alt": "❓",
        "title": "red question mark"
    },
    ":white_question_mark:": {
        "src": "/images/emojis/unicode/svg/2754.svg",
        "alt": "❔",
        "title": "white question mark"
    },
    ":white_exclamation_mark:": {
        "src": "/images/emojis/unicode/svg/2755.svg",
        "alt": "❕",
        "title": "white exclamation mark"
    },
    ":red_exclamation_mark:": {
        "src": "/images/emojis/unicode/svg/2757.svg",
        "alt": "❗",
        "title": "red exclamation mark"
    },
    ":wavy_dash:": {
        "src": "/images/emojis/unicode/svg/3030.svg",
        "alt": "〰",
        "title": "wavy dash"
    },
    ":currency_exchange:": {
        "src": "/images/emojis/unicode/svg/1f4b1.svg",
        "alt": "💱",
        "title": "currency exchange"
    },
    ":heavy_dollar_sign:": {
        "src": "/images/emojis/unicode/svg/1f4b2.svg",
        "alt": "💲",
        "title": "heavy dollar sign"
    },
    ":medical_symbol:": {
        "src": "/images/emojis/unicode/svg/2695.svg",
        "alt": "⚕",
        "title": "medical symbol"
    },
    ":recycling_symbol:": {
        "src": "/images/emojis/unicode/svg/267b.svg",
        "alt": "♻",
        "title": "recycling symbol"
    },
    ":fleur_de_lis:": {
        "src": "/images/emojis/unicode/svg/269c.svg",
        "alt": "⚜",
        "title": "fleur-de-lis"
    },
    ":trident_emblem:": {
        "src": "/images/emojis/unicode/svg/1f531.svg",
        "alt": "🔱",
        "title": "trident emblem"
    },
    ":name_badge:": {
        "src": "/images/emojis/unicode/svg/1f4db.svg",
        "alt": "📛",
        "title": "name badge"
    },
    ":japanese_symbol_for_beginner:": {
        "src": "/images/emojis/unicode/svg/1f530.svg",
        "alt": "🔰",
        "title": "Japanese symbol for beginner"
    },
    ":hollow_red_circle:": {
        "src": "/images/emojis/unicode/svg/2b55.svg",
        "alt": "⭕",
        "title": "hollow red circle"
    },
    ":check_mark_button:": {
        "src": "/images/emojis/unicode/svg/2705.svg",
        "alt": "✅",
        "title": "check mark button"
    },
    ":check_box_with_check:": {
        "src": "/images/emojis/unicode/svg/2611.svg",
        "alt": "☑",
        "title": "check box with check"
    },
    ":check_mark:": {
        "src": "/images/emojis/unicode/svg/2714.svg",
        "alt": "✔",
        "title": "check mark"
    },
    ":cross_mark:": {
        "src": "/images/emojis/unicode/svg/274c.svg",
        "alt": "❌",
        "title": "cross mark"
    },
    ":cross_mark_button:": {
        "src": "/images/emojis/unicode/svg/274e.svg",
        "alt": "❎",
        "title": "cross mark button"
    },
    ":curly_loop:": {
        "src": "/images/emojis/unicode/svg/27b0.svg",
        "alt": "➰",
        "title": "curly loop"
    },
    ":double_curly_loop:": {
        "src": "/images/emojis/unicode/svg/27bf.svg",
        "alt": "➿",
        "title": "double curly loop"
    },
    ":part_alternation_mark:": {
        "src": "/images/emojis/unicode/svg/303d.svg",
        "alt": "〽",
        "title": "part alternation mark"
    },
    ":eight_spoked_asterisk:": {
        "src": "/images/emojis/unicode/svg/2733.svg",
        "alt": "✳",
        "title": "eight-spoked asterisk"
    },
    ":eight_pointed_star:": {
        "src": "/images/emojis/unicode/svg/2734.svg",
        "alt": "✴",
        "title": "eight-pointed star"
    },
    ":sparkle:": {
        "src": "/images/emojis/unicode/svg/2747.svg",
        "alt": "❇",
        "title": "sparkle"
    },
    ":trade_mark:": {
        "src": "/images/emojis/unicode/svg/2122.svg",
        "alt": "™",
        "title": "trade mark"
    },
    ":input_latin_uppercase:": {
        "src": "/images/emojis/unicode/svg/1f520.svg",
        "alt": "🔠",
        "title": "input latin uppercase"
    },
    ":input_latin_lowercase:": {
        "src": "/images/emojis/unicode/svg/1f521.svg",
        "alt": "🔡",
        "title": "input latin lowercase"
    },
    ":input_numbers:": {
        "src": "/images/emojis/unicode/svg/1f522.svg",
        "alt": "🔢",
        "title": "input numbers"
    },
    ":input_symbols:": {
        "src": "/images/emojis/unicode/svg/1f523.svg",
        "alt": "🔣",
        "title": "input symbols"
    },
    ":input_latin_letters:": {
        "src": "/images/emojis/unicode/svg/1f524.svg",
        "alt": "🔤",
        "title": "input latin letters"
    },
    ":a_button_blood_type:": {
        "src": "/images/emojis/unicode/svg/1f170.svg",
        "alt": "🅰",
        "title": "A button (blood type)"
    },
    ":ab_button_blood_type:": {
        "src": "/images/emojis/unicode/svg/1f18e.svg",
        "alt": "🆎",
        "title": "AB button (blood type)"
    },
    ":b_button_blood_type:": {
        "src": "/images/emojis/unicode/svg/1f171.svg",
        "alt": "🅱",
        "title": "B button (blood type)"
    },
    ":cl_button:": {
        "src": "/images/emojis/unicode/svg/1f191.svg",
        "alt": "🆑",
        "title": "CL button"
    },
    ":cool_button:": {
        "src": "/images/emojis/unicode/svg/1f192.svg",
        "alt": "🆒",
        "title": "COOL button"
    },
    ":free_button:": {
        "src": "/images/emojis/unicode/svg/1f193.svg",
        "alt": "🆓",
        "title": "FREE button"
    },
    ":information:": {
        "src": "/images/emojis/unicode/svg/2139.svg",
        "alt": "ℹ",
        "title": "information"
    },
    ":id_button:": {
        "src": "/images/emojis/unicode/svg/1f194.svg",
        "alt": "🆔",
        "title": "ID button"
    },
    ":circled_m:": {
        "src": "/images/emojis/unicode/svg/24c2.svg",
        "alt": "Ⓜ",
        "title": "circled M"
    },
    ":new_button:": {
        "src": "/images/emojis/unicode/svg/1f195.svg",
        "alt": "🆕",
        "title": "NEW button"
    },
    ":ng_button:": {
        "src": "/images/emojis/unicode/svg/1f196.svg",
        "alt": "🆖",
        "title": "NG button"
    },
    ":o_button_blood_type:": {
        "src": "/images/emojis/unicode/svg/1f17e.svg",
        "alt": "🅾",
        "title": "O button (blood type)"
    },
    ":ok_button:": {
        "src": "/images/emojis/unicode/svg/1f197.svg",
        "alt": "🆗",
        "title": "OK button"
    },
    ":p_button:": {
        "src": "/images/emojis/unicode/svg/1f17f.svg",
        "alt": "🅿",
        "title": "P button"
    },
    ":sos_button:": {
        "src": "/images/emojis/unicode/svg/1f198.svg",
        "alt": "🆘",
        "title": "SOS button"
    },
    ":up_button:": {
        "src": "/images/emojis/unicode/svg/1f199.svg",
        "alt": "🆙",
        "title": "UP! button"
    },
    ":vs_button:": {
        "src": "/images/emojis/unicode/svg/1f19a.svg",
        "alt": "🆚",
        "title": "VS button"
    },
    ":japanese_here_button:": {
        "src": "/images/emojis/unicode/svg/1f201.svg",
        "alt": "🈁",
        "title": "Japanese “here” button"
    },
    ":japanese_service_charge_button:": {
        "src": "/images/emojis/unicode/svg/1f202.svg",
        "alt": "🈂",
        "title": "Japanese “service charge” button"
    },
    ":japanese_monthly_amount_button:": {
        "src": "/images/emojis/unicode/svg/1f237.svg",
        "alt": "🈷",
        "title": "Japanese “monthly amount” button"
    },
    ":japanese_not_free_of_charge_button:": {
        "src": "/images/emojis/unicode/svg/1f236.svg",
        "alt": "🈶",
        "title": "Japanese “not free of charge” button"
    },
    ":japanese_reserved_button:": {
        "src": "/images/emojis/unicode/svg/1f22f.svg",
        "alt": "🈯",
        "title": "Japanese “reserved” button"
    },
    ":japanese_bargain_button:": {
        "src": "/images/emojis/unicode/svg/1f250.svg",
        "alt": "🉐",
        "title": "Japanese “bargain” button"
    },
    ":japanese_discount_button:": {
        "src": "/images/emojis/unicode/svg/1f239.svg",
        "alt": "🈹",
        "title": "Japanese “discount” button"
    },
    ":japanese_free_of_charge_button:": {
        "src": "/images/emojis/unicode/svg/1f21a.svg",
        "alt": "🈚",
        "title": "Japanese “free of charge” button"
    },
    ":japanese_prohibited_button:": {
        "src": "/images/emojis/unicode/svg/1f232.svg",
        "alt": "🈲",
        "title": "Japanese “prohibited” button"
    },
    ":japanese_acceptable_button:": {
        "src": "/images/emojis/unicode/svg/1f251.svg",
        "alt": "🉑",
        "title": "Japanese “acceptable” button"
    },
    ":japanese_application_button:": {
        "src": "/images/emojis/unicode/svg/1f238.svg",
        "alt": "🈸",
        "title": "Japanese “application” button"
    },
    ":japanese_passing_grade_button:": {
        "src": "/images/emojis/unicode/svg/1f234.svg",
        "alt": "🈴",
        "title": "Japanese “passing grade” button"
    },
    ":japanese_vacancy_button:": {
        "src": "/images/emojis/unicode/svg/1f233.svg",
        "alt": "🈳",
        "title": "Japanese “vacancy” button"
    },
    ":japanese_congratulations_button:": {
        "src": "/images/emojis/unicode/svg/3297.svg",
        "alt": "㊗",
        "title": "Japanese “congratulations” button"
    },
    ":japanese_secret_button:": {
        "src": "/images/emojis/unicode/svg/3299.svg",
        "alt": "㊙",
        "title": "Japanese “secret” button"
    },
    ":japanese_open_for_business_button:": {
        "src": "/images/emojis/unicode/svg/1f23a.svg",
        "alt": "🈺",
        "title": "Japanese “open for business” button"
    },
    ":japanese_no_vacancy_button:": {
        "src": "/images/emojis/unicode/svg/1f235.svg",
        "alt": "🈵",
        "title": "Japanese “no vacancy” button"
    },
    ":red_circle:": {
        "src": "/images/emojis/unicode/svg/1f534.svg",
        "alt": "🔴",
        "title": "red circle"
    },
    ":orange_circle:": {
        "src": "/images/emojis/unicode/svg/1f7e0.svg",
        "alt": "🟠",
        "title": "orange circle"
    },
    ":yellow_circle:": {
        "src": "/images/emojis/unicode/svg/1f7e1.svg",
        "alt": "🟡",
        "title": "yellow circle"
    },
    ":green_circle:": {
        "src": "/images/emojis/unicode/svg/1f7e2.svg",
        "alt": "🟢",
        "title": "green circle"
    },
    ":blue_circle:": {
        "src": "/images/emojis/unicode/svg/1f535.svg",
        "alt": "🔵",
        "title": "blue circle"
    },
    ":purple_circle:": {
        "src": "/images/emojis/unicode/svg/1f7e3.svg",
        "alt": "🟣",
        "title": "purple circle"
    },
    ":brown_circle:": {
        "src": "/images/emojis/unicode/svg/1f7e4.svg",
        "alt": "🟤",
        "title": "brown circle"
    },
    ":black_circle:": {
        "src": "/images/emojis/unicode/svg/26ab.svg",
        "alt": "⚫",
        "title": "black circle"
    },
    ":white_circle:": {
        "src": "/images/emojis/unicode/svg/26aa.svg",
        "alt": "⚪",
        "title": "white circle"
    },
    ":red_square:": {
        "src": "/images/emojis/unicode/svg/1f7e5.svg",
        "alt": "🟥",
        "title": "red square"
    },
    ":orange_square:": {
        "src": "/images/emojis/unicode/svg/1f7e7.svg",
        "alt": "🟧",
        "title": "orange square"
    },
    ":yellow_square:": {
        "src": "/images/emojis/unicode/svg/1f7e8.svg",
        "alt": "🟨",
        "title": "yellow square"
    },
    ":green_square:": {
        "src": "/images/emojis/unicode/svg/1f7e9.svg",
        "alt": "🟩",
        "title": "green square"
    },
    ":blue_square:": {
        "src": "/images/emojis/unicode/svg/1f7e6.svg",
        "alt": "🟦",
        "title": "blue square"
    },
    ":purple_square:": {
        "src": "/images/emojis/unicode/svg/1f7ea.svg",
        "alt": "🟪",
        "title": "purple square"
    },
    ":brown_square:": {
        "src": "/images/emojis/unicode/svg/1f7eb.svg",
        "alt": "🟫",
        "title": "brown square"
    },
    ":black_large_square:": {
        "src": "/images/emojis/unicode/svg/2b1b.svg",
        "alt": "⬛",
        "title": "black large square"
    },
    ":white_large_square:": {
        "src": "/images/emojis/unicode/svg/2b1c.svg",
        "alt": "⬜",
        "title": "white large square"
    },
    ":black_medium_square:": {
        "src": "/images/emojis/unicode/svg/25fc.svg",
        "alt": "◼",
        "title": "black medium square"
    },
    ":white_medium_square:": {
        "src": "/images/emojis/unicode/svg/25fb.svg",
        "alt": "◻",
        "title": "white medium square"
    },
    ":black_medium_small_square:": {
        "src": "/images/emojis/unicode/svg/25fe.svg",
        "alt": "◾",
        "title": "black medium-small square"
    },
    ":white_medium_small_square:": {
        "src": "/images/emojis/unicode/svg/25fd.svg",
        "alt": "◽",
        "title": "white medium-small square"
    },
    ":black_small_square:": {
        "src": "/images/emojis/unicode/svg/25aa.svg",
        "alt": "▪",
        "title": "black small square"
    },
    ":white_small_square:": {
        "src": "/images/emojis/unicode/svg/25ab.svg",
        "alt": "▫",
        "title": "white small square"
    },
    ":large_orange_diamond:": {
        "src": "/images/emojis/unicode/svg/1f536.svg",
        "alt": "🔶",
        "title": "large orange diamond"
    },
    ":large_blue_diamond:": {
        "src": "/images/emojis/unicode/svg/1f537.svg",
        "alt": "🔷",
        "title": "large blue diamond"
    },
    ":small_orange_diamond:": {
        "src": "/images/emojis/unicode/svg/1f538.svg",
        "alt": "🔸",
        "title": "small orange diamond"
    },
    ":small_blue_diamond:": {
        "src": "/images/emojis/unicode/svg/1f539.svg",
        "alt": "🔹",
        "title": "small blue diamond"
    },
    ":red_triangle_pointed_up:": {
        "src": "/images/emojis/unicode/svg/1f53a.svg",
        "alt": "🔺",
        "title": "red triangle pointed up"
    },
    ":red_triangle_pointed_down:": {
        "src": "/images/emojis/unicode/svg/1f53b.svg",
        "alt": "🔻",
        "title": "red triangle pointed down"
    },
    ":diamond_with_a_dot:": {
        "src": "/images/emojis/unicode/svg/1f4a0.svg",
        "alt": "💠",
        "title": "diamond with a dot"
    },
    ":radio_button:": {
        "src": "/images/emojis/unicode/svg/1f518.svg",
        "alt": "🔘",
        "title": "radio button"
    },
    ":white_square_button:": {
        "src": "/images/emojis/unicode/svg/1f533.svg",
        "alt": "🔳",
        "title": "white square button"
    },
    ":black_square_button:": {
        "src": "/images/emojis/unicode/svg/1f532.svg",
        "alt": "🔲",
        "title": "black square button"
    },
    ":chequered_flag:": {
        "src": "/images/emojis/unicode/svg/1f3c1.svg",
        "alt": "🏁",
        "title": "chequered flag"
    },
    ":triangular_flag:": {
        "src": "/images/emojis/unicode/svg/1f6a9.svg",
        "alt": "🚩",
        "title": "triangular flag"
    },
    ":crossed_flags:": {
        "src": "/images/emojis/unicode/svg/1f38c.svg",
        "alt": "🎌",
        "title": "crossed flags"
    },
    ":black_flag:": {
        "src": "/images/emojis/unicode/svg/1f3f4.svg",
        "alt": "🏴",
        "title": "black flag"
    },
    ":white_flag:": {
        "src": "/images/emojis/unicode/svg/1f3f3.svg",
        "alt": "🏳",
        "title": "white flag"
    },
    ":transgender_flag:": {
        "src": "/images/emojis/unicode/svg/1f3f3-fe0f-200d-26a7-fe0f.svg",
        "alt": "🏳️‍⚧️",
        "title": "transgender flag"
    },
    ":pirate_flag:": {
        "src": "/images/emojis/unicode/svg/1f3f4-200d-2620-fe0f.svg",
        "alt": "🏴‍☠️",
        "title": "pirate flag"
    },
    ":flag_ascension_island:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1e8.svg",
        "alt": "🇦🇨",
        "title": "flag"
    },
    ":flag_andorra:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1e9.svg",
        "alt": "🇦🇩",
        "title": "flag"
    },
    ":flag_united_arab_emirates:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1ea.svg",
        "alt": "🇦🇪",
        "title": "flag"
    },
    ":flag_afghanistan:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1eb.svg",
        "alt": "🇦🇫",
        "title": "flag"
    },
    ":flag_antigua__barbuda:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1ec.svg",
        "alt": "🇦🇬",
        "title": "flag"
    },
    ":flag_anguilla:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1ee.svg",
        "alt": "🇦🇮",
        "title": "flag"
    },
    ":flag_albania:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1f1.svg",
        "alt": "🇦🇱",
        "title": "flag"
    },
    ":flag_armenia:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1f2.svg",
        "alt": "🇦🇲",
        "title": "flag"
    },
    ":flag_angola:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1f4.svg",
        "alt": "🇦🇴",
        "title": "flag"
    },
    ":flag_antarctica:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1f6.svg",
        "alt": "🇦🇶",
        "title": "flag"
    },
    ":flag_argentina:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1f7.svg",
        "alt": "🇦🇷",
        "title": "flag"
    },
    ":flag_american_samoa:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1f8.svg",
        "alt": "🇦🇸",
        "title": "flag"
    },
    ":flag_austria:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1f9.svg",
        "alt": "🇦🇹",
        "title": "flag"
    },
    ":flag_australia:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1fa.svg",
        "alt": "🇦🇺",
        "title": "flag"
    },
    ":flag_aruba:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1fc.svg",
        "alt": "🇦🇼",
        "title": "flag"
    },
    ":flag_aland_islands:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1fd.svg",
        "alt": "🇦🇽",
        "title": "flag"
    },
    ":flag_azerbaijan:": {
        "src": "/images/emojis/unicode/svg/1f1e6-1f1ff.svg",
        "alt": "🇦🇿",
        "title": "flag"
    },
    ":flag_bosnia__herzegovina:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1e6.svg",
        "alt": "🇧🇦",
        "title": "flag"
    },
    ":flag_barbados:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1e7.svg",
        "alt": "🇧🇧",
        "title": "flag"
    },
    ":flag_bangladesh:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1e9.svg",
        "alt": "🇧🇩",
        "title": "flag"
    },
    ":flag_belgium:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1ea.svg",
        "alt": "🇧🇪",
        "title": "flag"
    },
    ":flag_burkina_faso:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1eb.svg",
        "alt": "🇧🇫",
        "title": "flag"
    },
    ":flag_bulgaria:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1ec.svg",
        "alt": "🇧🇬",
        "title": "flag"
    },
    ":flag_bahrain:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1ed.svg",
        "alt": "🇧🇭",
        "title": "flag"
    },
    ":flag_burundi:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1ee.svg",
        "alt": "🇧🇮",
        "title": "flag"
    },
    ":flag_benin:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1ef.svg",
        "alt": "🇧🇯",
        "title": "flag"
    },
    ":flag_st_barthelemy:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1f1.svg",
        "alt": "🇧🇱",
        "title": "flag"
    },
    ":flag_bermuda:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1f2.svg",
        "alt": "🇧🇲",
        "title": "flag"
    },
    ":flag_brunei:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1f3.svg",
        "alt": "🇧🇳",
        "title": "flag"
    },
    ":flag_bolivia:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1f4.svg",
        "alt": "🇧🇴",
        "title": "flag"
    },
    ":flag_caribbean_netherlands:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1f6.svg",
        "alt": "🇧🇶",
        "title": "flag"
    },
    ":flag_brazil:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1f7.svg",
        "alt": "🇧🇷",
        "title": "flag"
    },
    ":flag_bahamas:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1f8.svg",
        "alt": "🇧🇸",
        "title": "flag"
    },
    ":flag_bhutan:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1f9.svg",
        "alt": "🇧🇹",
        "title": "flag"
    },
    ":flag_bouvet_island:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1fb.svg",
        "alt": "🇧🇻",
        "title": "flag"
    },
    ":flag_botswana:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1fc.svg",
        "alt": "🇧🇼",
        "title": "flag"
    },
    ":flag_belarus:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1fe.svg",
        "alt": "🇧🇾",
        "title": "flag"
    },
    ":flag_belize:": {
        "src": "/images/emojis/unicode/svg/1f1e7-1f1ff.svg",
        "alt": "🇧🇿",
        "title": "flag"
    },
    ":flag_canada:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1e6.svg",
        "alt": "🇨🇦",
        "title": "flag"
    },
    ":flag_cocos_keeling_islands:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1e8.svg",
        "alt": "🇨🇨",
        "title": "flag"
    },
    ":flag_congo___kinshasa:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1e9.svg",
        "alt": "🇨🇩",
        "title": "flag"
    },
    ":flag_central_african_republic:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1eb.svg",
        "alt": "🇨🇫",
        "title": "flag"
    },
    ":flag_congo___brazzaville:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1ec.svg",
        "alt": "🇨🇬",
        "title": "flag"
    },
    ":flag_switzerland:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1ed.svg",
        "alt": "🇨🇭",
        "title": "flag"
    },
    ":flag_cote_divoire:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1ee.svg",
        "alt": "🇨🇮",
        "title": "flag"
    },
    ":flag_cook_islands:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1f0.svg",
        "alt": "🇨🇰",
        "title": "flag"
    },
    ":flag_chile:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1f1.svg",
        "alt": "🇨🇱",
        "title": "flag"
    },
    ":flag_cameroon:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1f2.svg",
        "alt": "🇨🇲",
        "title": "flag"
    },
    ":flag_china:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1f3.svg",
        "alt": "🇨🇳",
        "title": "flag"
    },
    ":flag_colombia:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1f4.svg",
        "alt": "🇨🇴",
        "title": "flag"
    },
    ":flag_clipperton_island:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1f5.svg",
        "alt": "🇨🇵",
        "title": "flag"
    },
    ":flag_costa_rica:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1f7.svg",
        "alt": "🇨🇷",
        "title": "flag"
    },
    ":flag_cuba:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1fa.svg",
        "alt": "🇨🇺",
        "title": "flag"
    },
    ":flag_cape_verde:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1fb.svg",
        "alt": "🇨🇻",
        "title": "flag"
    },
    ":flag_curacao:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1fc.svg",
        "alt": "🇨🇼",
        "title": "flag"
    },
    ":flag_christmas_island:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1fd.svg",
        "alt": "🇨🇽",
        "title": "flag"
    },
    ":flag_cyprus:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1fe.svg",
        "alt": "🇨🇾",
        "title": "flag"
    },
    ":flag_czechia:": {
        "src": "/images/emojis/unicode/svg/1f1e8-1f1ff.svg",
        "alt": "🇨🇿",
        "title": "flag"
    },
    ":flag_germany:": {
        "src": "/images/emojis/unicode/svg/1f1e9-1f1ea.svg",
        "alt": "🇩🇪",
        "title": "flag"
    },
    ":flag_diego_garcia:": {
        "src": "/images/emojis/unicode/svg/1f1e9-1f1ec.svg",
        "alt": "🇩🇬",
        "title": "flag"
    },
    ":flag_djibouti:": {
        "src": "/images/emojis/unicode/svg/1f1e9-1f1ef.svg",
        "alt": "🇩🇯",
        "title": "flag"
    },
    ":flag_denmark:": {
        "src": "/images/emojis/unicode/svg/1f1e9-1f1f0.svg",
        "alt": "🇩🇰",
        "title": "flag"
    },
    ":flag_dominica:": {
        "src": "/images/emojis/unicode/svg/1f1e9-1f1f2.svg",
        "alt": "🇩🇲",
        "title": "flag"
    },
    ":flag_dominican_republic:": {
        "src": "/images/emojis/unicode/svg/1f1e9-1f1f4.svg",
        "alt": "🇩🇴",
        "title": "flag"
    },
    ":flag_algeria:": {
        "src": "/images/emojis/unicode/svg/1f1e9-1f1ff.svg",
        "alt": "🇩🇿",
        "title": "flag"
    },
    ":flag_ceuta__melilla:": {
        "src": "/images/emojis/unicode/svg/1f1ea-1f1e6.svg",
        "alt": "🇪🇦",
        "title": "flag"
    },
    ":flag_ecuador:": {
        "src": "/images/emojis/unicode/svg/1f1ea-1f1e8.svg",
        "alt": "🇪🇨",
        "title": "flag"
    },
    ":flag_estonia:": {
        "src": "/images/emojis/unicode/svg/1f1ea-1f1ea.svg",
        "alt": "🇪🇪",
        "title": "flag"
    },
    ":flag_egypt:": {
        "src": "/images/emojis/unicode/svg/1f1ea-1f1ec.svg",
        "alt": "🇪🇬",
        "title": "flag"
    },
    ":flag_western_sahara:": {
        "src": "/images/emojis/unicode/svg/1f1ea-1f1ed.svg",
        "alt": "🇪🇭",
        "title": "flag"
    },
    ":flag_eritrea:": {
        "src": "/images/emojis/unicode/svg/1f1ea-1f1f7.svg",
        "alt": "🇪🇷",
        "title": "flag"
    },
    ":flag_spain:": {
        "src": "/images/emojis/unicode/svg/1f1ea-1f1f8.svg",
        "alt": "🇪🇸",
        "title": "flag"
    },
    ":flag_ethiopia:": {
        "src": "/images/emojis/unicode/svg/1f1ea-1f1f9.svg",
        "alt": "🇪🇹",
        "title": "flag"
    },
    ":flag_european_union:": {
        "src": "/images/emojis/unicode/svg/1f1ea-1f1fa.svg",
        "alt": "🇪🇺",
        "title": "flag"
    },
    ":flag_finland:": {
        "src": "/images/emojis/unicode/svg/1f1eb-1f1ee.svg",
        "alt": "🇫🇮",
        "title": "flag"
    },
    ":flag_fiji:": {
        "src": "/images/emojis/unicode/svg/1f1eb-1f1ef.svg",
        "alt": "🇫🇯",
        "title": "flag"
    },
    ":flag_falkland_islands:": {
        "src": "/images/emojis/unicode/svg/1f1eb-1f1f0.svg",
        "alt": "🇫🇰",
        "title": "flag"
    },
    ":flag_micronesia:": {
        "src": "/images/emojis/unicode/svg/1f1eb-1f1f2.svg",
        "alt": "🇫🇲",
        "title": "flag"
    },
    ":flag_faroe_islands:": {
        "src": "/images/emojis/unicode/svg/1f1eb-1f1f4.svg",
        "alt": "🇫🇴",
        "title": "flag"
    },
    ":flag_france:": {
        "src": "/images/emojis/unicode/svg/1f1eb-1f1f7.svg",
        "alt": "🇫🇷",
        "title": "flag"
    },
    ":flag_gabon:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1e6.svg",
        "alt": "🇬🇦",
        "title": "flag"
    },
    ":flag_united_kingdom:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1e7.svg",
        "alt": "🇬🇧",
        "title": "flag"
    },
    ":flag_grenada:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1e9.svg",
        "alt": "🇬🇩",
        "title": "flag"
    },
    ":flag_georgia:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1ea.svg",
        "alt": "🇬🇪",
        "title": "flag"
    },
    ":flag_french_guiana:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1eb.svg",
        "alt": "🇬🇫",
        "title": "flag"
    },
    ":flag_guernsey:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1ec.svg",
        "alt": "🇬🇬",
        "title": "flag"
    },
    ":flag_ghana:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1ed.svg",
        "alt": "🇬🇭",
        "title": "flag"
    },
    ":flag_gibraltar:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1ee.svg",
        "alt": "🇬🇮",
        "title": "flag"
    },
    ":flag_greenland:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1f1.svg",
        "alt": "🇬🇱",
        "title": "flag"
    },
    ":flag_gambia:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1f2.svg",
        "alt": "🇬🇲",
        "title": "flag"
    },
    ":flag_guinea:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1f3.svg",
        "alt": "🇬🇳",
        "title": "flag"
    },
    ":flag_guadeloupe:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1f5.svg",
        "alt": "🇬🇵",
        "title": "flag"
    },
    ":flag_equatorial_guinea:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1f6.svg",
        "alt": "🇬🇶",
        "title": "flag"
    },
    ":flag_greece:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1f7.svg",
        "alt": "🇬🇷",
        "title": "flag"
    },
    ":flag_south_georgia__south_sandwich_islands:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1f8.svg",
        "alt": "🇬🇸",
        "title": "flag"
    },
    ":flag_guatemala:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1f9.svg",
        "alt": "🇬🇹",
        "title": "flag"
    },
    ":flag_guam:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1fa.svg",
        "alt": "🇬🇺",
        "title": "flag"
    },
    ":flag_guinea_bissau:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1fc.svg",
        "alt": "🇬🇼",
        "title": "flag"
    },
    ":flag_guyana:": {
        "src": "/images/emojis/unicode/svg/1f1ec-1f1fe.svg",
        "alt": "🇬🇾",
        "title": "flag"
    },
    ":flag_hong_kong_sar_china:": {
        "src": "/images/emojis/unicode/svg/1f1ed-1f1f0.svg",
        "alt": "🇭🇰",
        "title": "flag"
    },
    ":flag_heard__mcdonald_islands:": {
        "src": "/images/emojis/unicode/svg/1f1ed-1f1f2.svg",
        "alt": "🇭🇲",
        "title": "flag"
    },
    ":flag_honduras:": {
        "src": "/images/emojis/unicode/svg/1f1ed-1f1f3.svg",
        "alt": "🇭🇳",
        "title": "flag"
    },
    ":flag_croatia:": {
        "src": "/images/emojis/unicode/svg/1f1ed-1f1f7.svg",
        "alt": "🇭🇷",
        "title": "flag"
    },
    ":flag_haiti:": {
        "src": "/images/emojis/unicode/svg/1f1ed-1f1f9.svg",
        "alt": "🇭🇹",
        "title": "flag"
    },
    ":flag_hungary:": {
        "src": "/images/emojis/unicode/svg/1f1ed-1f1fa.svg",
        "alt": "🇭🇺",
        "title": "flag"
    },
    ":flag_canary_islands:": {
        "src": "/images/emojis/unicode/svg/1f1ee-1f1e8.svg",
        "alt": "🇮🇨",
        "title": "flag"
    },
    ":flag_indonesia:": {
        "src": "/images/emojis/unicode/svg/1f1ee-1f1e9.svg",
        "alt": "🇮🇩",
        "title": "flag"
    },
    ":flag_ireland:": {
        "src": "/images/emojis/unicode/svg/1f1ee-1f1ea.svg",
        "alt": "🇮🇪",
        "title": "flag"
    },
    ":flag_israel:": {
        "src": "/images/emojis/unicode/svg/1f1ee-1f1f1.svg",
        "alt": "🇮🇱",
        "title": "flag"
    },
    ":flag_isle_of_man:": {
        "src": "/images/emojis/unicode/svg/1f1ee-1f1f2.svg",
        "alt": "🇮🇲",
        "title": "flag"
    },
    ":flag_india:": {
        "src": "/images/emojis/unicode/svg/1f1ee-1f1f3.svg",
        "alt": "🇮🇳",
        "title": "flag"
    },
    ":flag_british_indian_ocean_territory:": {
        "src": "/images/emojis/unicode/svg/1f1ee-1f1f4.svg",
        "alt": "🇮🇴",
        "title": "flag"
    },
    ":flag_iraq:": {
        "src": "/images/emojis/unicode/svg/1f1ee-1f1f6.svg",
        "alt": "🇮🇶",
        "title": "flag"
    },
    ":flag_iran:": {
        "src": "/images/emojis/unicode/svg/1f1ee-1f1f7.svg",
        "alt": "🇮🇷",
        "title": "flag"
    },
    ":flag_iceland:": {
        "src": "/images/emojis/unicode/svg/1f1ee-1f1f8.svg",
        "alt": "🇮🇸",
        "title": "flag"
    },
    ":flag_italy:": {
        "src": "/images/emojis/unicode/svg/1f1ee-1f1f9.svg",
        "alt": "🇮🇹",
        "title": "flag"
    },
    ":flag_jersey:": {
        "src": "/images/emojis/unicode/svg/1f1ef-1f1ea.svg",
        "alt": "🇯🇪",
        "title": "flag"
    },
    ":flag_jamaica:": {
        "src": "/images/emojis/unicode/svg/1f1ef-1f1f2.svg",
        "alt": "🇯🇲",
        "title": "flag"
    },
    ":flag_jordan:": {
        "src": "/images/emojis/unicode/svg/1f1ef-1f1f4.svg",
        "alt": "🇯🇴",
        "title": "flag"
    },
    ":flag_japan:": {
        "src": "/images/emojis/unicode/svg/1f1ef-1f1f5.svg",
        "alt": "🇯🇵",
        "title": "flag"
    },
    ":flag_kenya:": {
        "src": "/images/emojis/unicode/svg/1f1f0-1f1ea.svg",
        "alt": "🇰🇪",
        "title": "flag"
    },
    ":flag_kyrgyzstan:": {
        "src": "/images/emojis/unicode/svg/1f1f0-1f1ec.svg",
        "alt": "🇰🇬",
        "title": "flag"
    },
    ":flag_cambodia:": {
        "src": "/images/emojis/unicode/svg/1f1f0-1f1ed.svg",
        "alt": "🇰🇭",
        "title": "flag"
    },
    ":flag_kiribati:": {
        "src": "/images/emojis/unicode/svg/1f1f0-1f1ee.svg",
        "alt": "🇰🇮",
        "title": "flag"
    },
    ":flag_comoros:": {
        "src": "/images/emojis/unicode/svg/1f1f0-1f1f2.svg",
        "alt": "🇰🇲",
        "title": "flag"
    },
    ":flag_st_kitts__nevis:": {
        "src": "/images/emojis/unicode/svg/1f1f0-1f1f3.svg",
        "alt": "🇰🇳",
        "title": "flag"
    },
    ":flag_north_korea:": {
        "src": "/images/emojis/unicode/svg/1f1f0-1f1f5.svg",
        "alt": "🇰🇵",
        "title": "flag"
    },
    ":flag_south_korea:": {
        "src": "/images/emojis/unicode/svg/1f1f0-1f1f7.svg",
        "alt": "🇰🇷",
        "title": "flag"
    },
    ":flag_kuwait:": {
        "src": "/images/emojis/unicode/svg/1f1f0-1f1fc.svg",
        "alt": "🇰🇼",
        "title": "flag"
    },
    ":flag_cayman_islands:": {
        "src": "/images/emojis/unicode/svg/1f1f0-1f1fe.svg",
        "alt": "🇰🇾",
        "title": "flag"
    },
    ":flag_kazakhstan:": {
        "src": "/images/emojis/unicode/svg/1f1f0-1f1ff.svg",
        "alt": "🇰🇿",
        "title": "flag"
    },
    ":flag_laos:": {
        "src": "/images/emojis/unicode/svg/1f1f1-1f1e6.svg",
        "alt": "🇱🇦",
        "title": "flag"
    },
    ":flag_lebanon:": {
        "src": "/images/emojis/unicode/svg/1f1f1-1f1e7.svg",
        "alt": "🇱🇧",
        "title": "flag"
    },
    ":flag_st_lucia:": {
        "src": "/images/emojis/unicode/svg/1f1f1-1f1e8.svg",
        "alt": "🇱🇨",
        "title": "flag"
    },
    ":flag_liechtenstein:": {
        "src": "/images/emojis/unicode/svg/1f1f1-1f1ee.svg",
        "alt": "🇱🇮",
        "title": "flag"
    },
    ":flag_sri_lanka:": {
        "src": "/images/emojis/unicode/svg/1f1f1-1f1f0.svg",
        "alt": "🇱🇰",
        "title": "flag"
    },
    ":flag_liberia:": {
        "src": "/images/emojis/unicode/svg/1f1f1-1f1f7.svg",
        "alt": "🇱🇷",
        "title": "flag"
    },
    ":flag_lesotho:": {
        "src": "/images/emojis/unicode/svg/1f1f1-1f1f8.svg",
        "alt": "🇱🇸",
        "title": "flag"
    },
    ":flag_lithuania:": {
        "src": "/images/emojis/unicode/svg/1f1f1-1f1f9.svg",
        "alt": "🇱🇹",
        "title": "flag"
    },
    ":flag_luxembourg:": {
        "src": "/images/emojis/unicode/svg/1f1f1-1f1fa.svg",
        "alt": "🇱🇺",
        "title": "flag"
    },
    ":flag_latvia:": {
        "src": "/images/emojis/unicode/svg/1f1f1-1f1fb.svg",
        "alt": "🇱🇻",
        "title": "flag"
    },
    ":flag_libya:": {
        "src": "/images/emojis/unicode/svg/1f1f1-1f1fe.svg",
        "alt": "🇱🇾",
        "title": "flag"
    },
    ":flag_morocco:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1e6.svg",
        "alt": "🇲🇦",
        "title": "flag"
    },
    ":flag_monaco:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1e8.svg",
        "alt": "🇲🇨",
        "title": "flag"
    },
    ":flag_moldova:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1e9.svg",
        "alt": "🇲🇩",
        "title": "flag"
    },
    ":flag_montenegro:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1ea.svg",
        "alt": "🇲🇪",
        "title": "flag"
    },
    ":flag_st_martin:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1eb.svg",
        "alt": "🇲🇫",
        "title": "flag"
    },
    ":flag_madagascar:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1ec.svg",
        "alt": "🇲🇬",
        "title": "flag"
    },
    ":flag_marshall_islands:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1ed.svg",
        "alt": "🇲🇭",
        "title": "flag"
    },
    ":flag_north_macedonia:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1f0.svg",
        "alt": "🇲🇰",
        "title": "flag"
    },
    ":flag_mali:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1f1.svg",
        "alt": "🇲🇱",
        "title": "flag"
    },
    ":flag_myanmar_burma:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1f2.svg",
        "alt": "🇲🇲",
        "title": "flag"
    },
    ":flag_mongolia:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1f3.svg",
        "alt": "🇲🇳",
        "title": "flag"
    },
    ":flag_macao_sar_china:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1f4.svg",
        "alt": "🇲🇴",
        "title": "flag"
    },
    ":flag_northern_mariana_islands:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1f5.svg",
        "alt": "🇲🇵",
        "title": "flag"
    },
    ":flag_martinique:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1f6.svg",
        "alt": "🇲🇶",
        "title": "flag"
    },
    ":flag_mauritania:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1f7.svg",
        "alt": "🇲🇷",
        "title": "flag"
    },
    ":flag_montserrat:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1f8.svg",
        "alt": "🇲🇸",
        "title": "flag"
    },
    ":flag_malta:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1f9.svg",
        "alt": "🇲🇹",
        "title": "flag"
    },
    ":flag_mauritius:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1fa.svg",
        "alt": "🇲🇺",
        "title": "flag"
    },
    ":flag_maldives:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1fb.svg",
        "alt": "🇲🇻",
        "title": "flag"
    },
    ":flag_malawi:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1fc.svg",
        "alt": "🇲🇼",
        "title": "flag"
    },
    ":flag_mexico:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1fd.svg",
        "alt": "🇲🇽",
        "title": "flag"
    },
    ":flag_malaysia:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1fe.svg",
        "alt": "🇲🇾",
        "title": "flag"
    },
    ":flag_mozambique:": {
        "src": "/images/emojis/unicode/svg/1f1f2-1f1ff.svg",
        "alt": "🇲🇿",
        "title": "flag"
    },
    ":flag_namibia:": {
        "src": "/images/emojis/unicode/svg/1f1f3-1f1e6.svg",
        "alt": "🇳🇦",
        "title": "flag"
    },
    ":flag_new_caledonia:": {
        "src": "/images/emojis/unicode/svg/1f1f3-1f1e8.svg",
        "alt": "🇳🇨",
        "title": "flag"
    },
    ":flag_niger:": {
        "src": "/images/emojis/unicode/svg/1f1f3-1f1ea.svg",
        "alt": "🇳🇪",
        "title": "flag"
    },
    ":flag_norfolk_island:": {
        "src": "/images/emojis/unicode/svg/1f1f3-1f1eb.svg",
        "alt": "🇳🇫",
        "title": "flag"
    },
    ":flag_nigeria:": {
        "src": "/images/emojis/unicode/svg/1f1f3-1f1ec.svg",
        "alt": "🇳🇬",
        "title": "flag"
    },
    ":flag_nicaragua:": {
        "src": "/images/emojis/unicode/svg/1f1f3-1f1ee.svg",
        "alt": "🇳🇮",
        "title": "flag"
    },
    ":flag_netherlands:": {
        "src": "/images/emojis/unicode/svg/1f1f3-1f1f1.svg",
        "alt": "🇳🇱",
        "title": "flag"
    },
    ":flag_norway:": {
        "src": "/images/emojis/unicode/svg/1f1f3-1f1f4.svg",
        "alt": "🇳🇴",
        "title": "flag"
    },
    ":flag_nepal:": {
        "src": "/images/emojis/unicode/svg/1f1f3-1f1f5.svg",
        "alt": "🇳🇵",
        "title": "flag"
    },
    ":flag_nauru:": {
        "src": "/images/emojis/unicode/svg/1f1f3-1f1f7.svg",
        "alt": "🇳🇷",
        "title": "flag"
    },
    ":flag_niue:": {
        "src": "/images/emojis/unicode/svg/1f1f3-1f1fa.svg",
        "alt": "🇳🇺",
        "title": "flag"
    },
    ":flag_new_zealand:": {
        "src": "/images/emojis/unicode/svg/1f1f3-1f1ff.svg",
        "alt": "🇳🇿",
        "title": "flag"
    },
    ":flag_oman:": {
        "src": "/images/emojis/unicode/svg/1f1f4-1f1f2.svg",
        "alt": "🇴🇲",
        "title": "flag"
    },
    ":flag_panama:": {
        "src": "/images/emojis/unicode/svg/1f1f5-1f1e6.svg",
        "alt": "🇵🇦",
        "title": "flag"
    },
    ":flag_peru:": {
        "src": "/images/emojis/unicode/svg/1f1f5-1f1ea.svg",
        "alt": "🇵🇪",
        "title": "flag"
    },
    ":flag_french_polynesia:": {
        "src": "/images/emojis/unicode/svg/1f1f5-1f1eb.svg",
        "alt": "🇵🇫",
        "title": "flag"
    },
    ":flag_papua_new_guinea:": {
        "src": "/images/emojis/unicode/svg/1f1f5-1f1ec.svg",
        "alt": "🇵🇬",
        "title": "flag"
    },
    ":flag_philippines:": {
        "src": "/images/emojis/unicode/svg/1f1f5-1f1ed.svg",
        "alt": "🇵🇭",
        "title": "flag"
    },
    ":flag_pakistan:": {
        "src": "/images/emojis/unicode/svg/1f1f5-1f1f0.svg",
        "alt": "🇵🇰",
        "title": "flag"
    },
    ":flag_poland:": {
        "src": "/images/emojis/unicode/svg/1f1f5-1f1f1.svg",
        "alt": "🇵🇱",
        "title": "flag"
    },
    ":flag_st_pierre__miquelon:": {
        "src": "/images/emojis/unicode/svg/1f1f5-1f1f2.svg",
        "alt": "🇵🇲",
        "title": "flag"
    },
    ":flag_pitcairn_islands:": {
        "src": "/images/emojis/unicode/svg/1f1f5-1f1f3.svg",
        "alt": "🇵🇳",
        "title": "flag"
    },
    ":flag_puerto_rico:": {
        "src": "/images/emojis/unicode/svg/1f1f5-1f1f7.svg",
        "alt": "🇵🇷",
        "title": "flag"
    },
    ":flag_palestinian_territories:": {
        "src": "/images/emojis/unicode/svg/1f1f5-1f1f8.svg",
        "alt": "🇵🇸",
        "title": "flag"
    },
    ":flag_portugal:": {
        "src": "/images/emojis/unicode/svg/1f1f5-1f1f9.svg",
        "alt": "🇵🇹",
        "title": "flag"
    },
    ":flag_palau:": {
        "src": "/images/emojis/unicode/svg/1f1f5-1f1fc.svg",
        "alt": "🇵🇼",
        "title": "flag"
    },
    ":flag_paraguay:": {
        "src": "/images/emojis/unicode/svg/1f1f5-1f1fe.svg",
        "alt": "🇵🇾",
        "title": "flag"
    },
    ":flag_qatar:": {
        "src": "/images/emojis/unicode/svg/1f1f6-1f1e6.svg",
        "alt": "🇶🇦",
        "title": "flag"
    },
    ":flag_reunion:": {
        "src": "/images/emojis/unicode/svg/1f1f7-1f1ea.svg",
        "alt": "🇷🇪",
        "title": "flag"
    },
    ":flag_romania:": {
        "src": "/images/emojis/unicode/svg/1f1f7-1f1f4.svg",
        "alt": "🇷🇴",
        "title": "flag"
    },
    ":flag_serbia:": {
        "src": "/images/emojis/unicode/svg/1f1f7-1f1f8.svg",
        "alt": "🇷🇸",
        "title": "flag"
    },
    ":flag_russia:": {
        "src": "/images/emojis/unicode/svg/1f1f7-1f1fa.svg",
        "alt": "🇷🇺",
        "title": "flag"
    },
    ":flag_rwanda:": {
        "src": "/images/emojis/unicode/svg/1f1f7-1f1fc.svg",
        "alt": "🇷🇼",
        "title": "flag"
    },
    ":flag_saudi_arabia:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1e6.svg",
        "alt": "🇸🇦",
        "title": "flag"
    },
    ":flag_solomon_islands:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1e7.svg",
        "alt": "🇸🇧",
        "title": "flag"
    },
    ":flag_seychelles:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1e8.svg",
        "alt": "🇸🇨",
        "title": "flag"
    },
    ":flag_sudan:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1e9.svg",
        "alt": "🇸🇩",
        "title": "flag"
    },
    ":flag_sweden:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1ea.svg",
        "alt": "🇸🇪",
        "title": "flag"
    },
    ":flag_singapore:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1ec.svg",
        "alt": "🇸🇬",
        "title": "flag"
    },
    ":flag_st_helena:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1ed.svg",
        "alt": "🇸🇭",
        "title": "flag"
    },
    ":flag_slovenia:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1ee.svg",
        "alt": "🇸🇮",
        "title": "flag"
    },
    ":flag_svalbard__jan_mayen:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1ef.svg",
        "alt": "🇸🇯",
        "title": "flag"
    },
    ":flag_slovakia:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1f0.svg",
        "alt": "🇸🇰",
        "title": "flag"
    },
    ":flag_sierra_leone:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1f1.svg",
        "alt": "🇸🇱",
        "title": "flag"
    },
    ":flag_san_marino:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1f2.svg",
        "alt": "🇸🇲",
        "title": "flag"
    },
    ":flag_senegal:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1f3.svg",
        "alt": "🇸🇳",
        "title": "flag"
    },
    ":flag_somalia:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1f4.svg",
        "alt": "🇸🇴",
        "title": "flag"
    },
    ":flag_suriname:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1f7.svg",
        "alt": "🇸🇷",
        "title": "flag"
    },
    ":flag_south_sudan:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1f8.svg",
        "alt": "🇸🇸",
        "title": "flag"
    },
    ":flag_sao_tome__principe:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1f9.svg",
        "alt": "🇸🇹",
        "title": "flag"
    },
    ":flag_el_salvador:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1fb.svg",
        "alt": "🇸🇻",
        "title": "flag"
    },
    ":flag_sint_maarten:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1fd.svg",
        "alt": "🇸🇽",
        "title": "flag"
    },
    ":flag_syria:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1fe.svg",
        "alt": "🇸🇾",
        "title": "flag"
    },
    ":flag_eswatini:": {
        "src": "/images/emojis/unicode/svg/1f1f8-1f1ff.svg",
        "alt": "🇸🇿",
        "title": "flag"
    },
    ":flag_tristan_da_cunha:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1e6.svg",
        "alt": "🇹🇦",
        "title": "flag"
    },
    ":flag_turks__caicos_islands:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1e8.svg",
        "alt": "🇹🇨",
        "title": "flag"
    },
    ":flag_chad:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1e9.svg",
        "alt": "🇹🇩",
        "title": "flag"
    },
    ":flag_french_southern_territories:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1eb.svg",
        "alt": "🇹🇫",
        "title": "flag"
    },
    ":flag_togo:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1ec.svg",
        "alt": "🇹🇬",
        "title": "flag"
    },
    ":flag_thailand:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1ed.svg",
        "alt": "🇹🇭",
        "title": "flag"
    },
    ":flag_tajikistan:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1ef.svg",
        "alt": "🇹🇯",
        "title": "flag"
    },
    ":flag_tokelau:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1f0.svg",
        "alt": "🇹🇰",
        "title": "flag"
    },
    ":flag_timor_leste:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1f1.svg",
        "alt": "🇹🇱",
        "title": "flag"
    },
    ":flag_turkmenistan:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1f2.svg",
        "alt": "🇹🇲",
        "title": "flag"
    },
    ":flag_tunisia:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1f3.svg",
        "alt": "🇹🇳",
        "title": "flag"
    },
    ":flag_tonga:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1f4.svg",
        "alt": "🇹🇴",
        "title": "flag"
    },
    ":flag_turkey:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1f7.svg",
        "alt": "🇹🇷",
        "title": "flag"
    },
    ":flag_trinidad__tobago:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1f9.svg",
        "alt": "🇹🇹",
        "title": "flag"
    },
    ":flag_tuvalu:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1fb.svg",
        "alt": "🇹🇻",
        "title": "flag"
    },
    ":flag_taiwan:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1fc.svg",
        "alt": "🇹🇼",
        "title": "flag"
    },
    ":flag_tanzania:": {
        "src": "/images/emojis/unicode/svg/1f1f9-1f1ff.svg",
        "alt": "🇹🇿",
        "title": "flag"
    },
    ":flag_ukraine:": {
        "src": "/images/emojis/unicode/svg/1f1fa-1f1e6.svg",
        "alt": "🇺🇦",
        "title": "flag"
    },
    ":flag_uganda:": {
        "src": "/images/emojis/unicode/svg/1f1fa-1f1ec.svg",
        "alt": "🇺🇬",
        "title": "flag"
    },
    ":flag_us_outlying_islands:": {
        "src": "/images/emojis/unicode/svg/1f1fa-1f1f2.svg",
        "alt": "🇺🇲",
        "title": "flag"
    },
    ":flag_united_nations:": {
        "src": "/images/emojis/unicode/svg/1f1fa-1f1f3.svg",
        "alt": "🇺🇳",
        "title": "flag"
    },
    ":flag_united_states:": {
        "src": "/images/emojis/unicode/svg/1f1fa-1f1f8.svg",
        "alt": "🇺🇸",
        "title": "flag"
    },
    ":flag_uruguay:": {
        "src": "/images/emojis/unicode/svg/1f1fa-1f1fe.svg",
        "alt": "🇺🇾",
        "title": "flag"
    },
    ":flag_uzbekistan:": {
        "src": "/images/emojis/unicode/svg/1f1fa-1f1ff.svg",
        "alt": "🇺🇿",
        "title": "flag"
    },
    ":flag_vatican_city:": {
        "src": "/images/emojis/unicode/svg/1f1fb-1f1e6.svg",
        "alt": "🇻🇦",
        "title": "flag"
    },
    ":flag_st_vincent__grenadines:": {
        "src": "/images/emojis/unicode/svg/1f1fb-1f1e8.svg",
        "alt": "🇻🇨",
        "title": "flag"
    },
    ":flag_venezuela:": {
        "src": "/images/emojis/unicode/svg/1f1fb-1f1ea.svg",
        "alt": "🇻🇪",
        "title": "flag"
    },
    ":flag_british_virgin_islands:": {
        "src": "/images/emojis/unicode/svg/1f1fb-1f1ec.svg",
        "alt": "🇻🇬",
        "title": "flag"
    },
    ":flag_us_virgin_islands:": {
        "src": "/images/emojis/unicode/svg/1f1fb-1f1ee.svg",
        "alt": "🇻🇮",
        "title": "flag"
    },
    ":flag_vietnam:": {
        "src": "/images/emojis/unicode/svg/1f1fb-1f1f3.svg",
        "alt": "🇻🇳",
        "title": "flag"
    },
    ":flag_vanuatu:": {
        "src": "/images/emojis/unicode/svg/1f1fb-1f1fa.svg",
        "alt": "🇻🇺",
        "title": "flag"
    },
    ":flag_wallis__futuna:": {
        "src": "/images/emojis/unicode/svg/1f1fc-1f1eb.svg",
        "alt": "🇼🇫",
        "title": "flag"
    },
    ":flag_samoa:": {
        "src": "/images/emojis/unicode/svg/1f1fc-1f1f8.svg",
        "alt": "🇼🇸",
        "title": "flag"
    },
    ":flag_kosovo:": {
        "src": "/images/emojis/unicode/svg/1f1fd-1f1f0.svg",
        "alt": "🇽🇰",
        "title": "flag"
    },
    ":flag_yemen:": {
        "src": "/images/emojis/unicode/svg/1f1fe-1f1ea.svg",
        "alt": "🇾🇪",
        "title": "flag"
    },
    ":flag_mayotte:": {
        "src": "/images/emojis/unicode/svg/1f1fe-1f1f9.svg",
        "alt": "🇾🇹",
        "title": "flag"
    },
    ":flag_south_africa:": {
        "src": "/images/emojis/unicode/svg/1f1ff-1f1e6.svg",
        "alt": "🇿🇦",
        "title": "flag"
    },
    ":flag_zambia:": {
        "src": "/images/emojis/unicode/svg/1f1ff-1f1f2.svg",
        "alt": "🇿🇲",
        "title": "flag"
    },
    ":flag_zimbabwe:": {
        "src": "/images/emojis/unicode/svg/1f1ff-1f1fc.svg",
        "alt": "🇿🇼",
        "title": "flag"
    },
    ":flag_england:": {
        "src": "/images/emojis/unicode/svg/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.svg",
        "alt": "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
        "title": "flag"
    },
    ":flag_scotland:": {
        "src": "/images/emojis/unicode/svg/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.svg",
        "alt": "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
        "title": "flag"
    },
    ":flag_wales:": {
        "src": "/images/emojis/unicode/svg/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.svg",
        "alt": "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
        "title": "flag"
    },
    "#)": {
        "src": "/images/emojis/ascii/u0023u0029.png",
        "alt": "😵",
        "title": "#)"
    },
    "#-)": {
        "src": "/images/emojis/ascii/u0023-u002Du0029.png",
        "alt": "😵",
        "title": "#-)"
    },
    "%)": {
        "src": "/images/emojis/ascii/u0025u0029.png",
        "alt": "😵",
        "title": "%)"
    },
    "%-)": {
        "src": "/images/emojis/ascii/u0025-u002Du0029.png",
        "alt": "😵",
        "title": "%-)"
    },
    "(y)": {
        "src": "/images/emojis/ascii/u0028yu0079u0029.png",
        "alt": "👍",
        "title": "(y)"
    },
    "*)": {
        "src": "/images/emojis/ascii/u002Au0029.png",
        "alt": "😉",
        "title": "*)"
    },
    "*-)": {
        "src": "/images/emojis/ascii/u002A-u002Du0029.png",
        "alt": "😉",
        "title": "*-)"
    },
    "*\\0/*": {
        "src": "/images/emojis/ascii/u002Au005C0u0030u002Fu002A.png",
        "alt": "🙆",
        "title": "*\\0/*"
    },
    "*\\O/*": {
        "src": "/images/emojis/ascii/u002Au005COu004Fu002Fu002A.png",
        "alt": "🙆",
        "title": "*\\O/*"
    },
    ":#": {
        "src": "/images/emojis/ascii/u0023.png",
        "alt": "😶",
        "title": ":#"
    },
    ":-#": {
        "src": "/images/emojis/ascii/-u002Du0023.png",
        "alt": "😶",
        "title": ":-#"
    },
    ":$": {
        "src": "/images/emojis/ascii/u0024.png",
        "alt": "😳",
        "title": ":$"
    },
    ":(": {
        "src": "/images/emojis/ascii/u0028.png",
        "alt": "😞",
        "title": ":("
    },
    ":'(": {
        "src": "/images/emojis/ascii/u0027u0028.png",
        "alt": "😢",
        "title": ":'("
    },
    ":'-(": {
        "src": "/images/emojis/ascii/u0027-u002Du0028.png",
        "alt": "😢",
        "title": ":'-("
    },
    ":-(": {
        "src": "/images/emojis/ascii/-u002Du0028.png",
        "alt": "😞",
        "title": ":-("
    },
    "':(": {
        "src": "/images/emojis/ascii/u0027u0028.png",
        "alt": "😓",
        "title": "':("
    },
    "':-(": {
        "src": "/images/emojis/ascii/u0027-u002Du0028.png",
        "alt": "😓",
        "title": "':-("
    },
    ":)": {
        "src": "/images/emojis/ascii/u0029.png",
        "alt": "🙂",
        "title": ":)"
    },
    ":')": {
        "src": "/images/emojis/ascii/u0027u0029.png",
        "alt": "😂",
        "title": ":')"
    },
    ":'-)": {
        "src": "/images/emojis/ascii/u0027-u002Du0029.png",
        "alt": "😂",
        "title": ":'-)"
    },
    ":-)": {
        "src": "/images/emojis/ascii/-u002Du0029.png",
        "alt": "🙂",
        "title": ":-)"
    },
    "':)": {
        "src": "/images/emojis/ascii/u0027u0029.png",
        "alt": "😅",
        "title": "':)"
    },
    "':-)": {
        "src": "/images/emojis/ascii/u0027-u002Du0029.png",
        "alt": "😅",
        "title": "':-)"
    },
    ":*": {
        "src": "/images/emojis/ascii/u002A.png",
        "alt": "😘",
        "title": ":*"
    },
    ":-*": {
        "src": "/images/emojis/ascii/-u002Du002A.png",
        "alt": "😘",
        "title": ":-*"
    },
    ":-.": {
        "src": "/images/emojis/ascii/-u002Du002E.png",
        "alt": "😕",
        "title": ":-."
    },
    ":/": {
        "src": "/images/emojis/ascii/u002F.png",
        "alt": "😕",
        "title": ":/"
    },
    ":-/": {
        "src": "/images/emojis/ascii/-u002Du002F.png",
        "alt": "😕",
        "title": ":-/"
    },
    ":@": {
        "src": "/images/emojis/ascii/u0040.png",
        "alt": "😠",
        "title": ":@"
    },
    ":[": {
        "src": "/images/emojis/ascii/u005B.png",
        "alt": "😞",
        "title": ":["
    },
    ":-[": {
        "src": "/images/emojis/ascii/-u002Du005B.png",
        "alt": "😞",
        "title": ":-["
    },
    ":\\": {
        "src": "/images/emojis/ascii/u005C.png",
        "alt": "😕",
        "title": ":\\"
    },
    ":]": {
        "src": "/images/emojis/ascii/u005D.png",
        "alt": "🙂",
        "title": ":]"
    },
    ":^*": {
        "src": "/images/emojis/ascii/u005Eu002A.png",
        "alt": "😘",
        "title": ":^*"
    },
    ":b": {
        "src": "/images/emojis/ascii/bu0062.png",
        "alt": "😛",
        "title": ":b"
    },
    ":-b": {
        "src": "/images/emojis/ascii/-u002Dbu0062.png",
        "alt": "😛",
        "title": ":-b"
    },
    ":D": {
        "src": "/images/emojis/ascii/Du0044.png",
        "alt": "😃",
        "title": ":D"
    },
    ":-D": {
        "src": "/images/emojis/ascii/-u002DDu0044.png",
        "alt": "😃",
        "title": ":-D"
    },
    "':D": {
        "src": "/images/emojis/ascii/u0027Du0044.png",
        "alt": "😅",
        "title": "':D"
    },
    "':-D": {
        "src": "/images/emojis/ascii/u0027-u002DDu0044.png",
        "alt": "😅",
        "title": "':-D"
    },
    ":L": {
        "src": "/images/emojis/ascii/Lu004C.png",
        "alt": "😕",
        "title": ":L"
    },
    ":O": {
        "src": "/images/emojis/ascii/Ou004F.png",
        "alt": "😮",
        "title": ":O"
    },
    ":-O": {
        "src": "/images/emojis/ascii/-u002DOu004F.png",
        "alt": "😮",
        "title": ":-O"
    },
    ":P": {
        "src": "/images/emojis/ascii/Pu0050.png",
        "alt": "😛",
        "title": ":P"
    },
    ":-P": {
        "src": "/images/emojis/ascii/-u002DPu0050.png",
        "alt": "😛",
        "title": ":-P"
    },
    ":Þ": {
        "src": "/images/emojis/ascii/Þu00DE.png",
        "alt": "😛",
        "title": ":Þ"
    },
    ":-Þ": {
        "src": "/images/emojis/ascii/-u002DÞu00DE.png",
        "alt": "😛",
        "title": ":-Þ"
    },
    ":X": {
        "src": "/images/emojis/ascii/Xu0058.png",
        "alt": "😶",
        "title": ":X"
    },
    ":-X": {
        "src": "/images/emojis/ascii/-u002DXu0058.png",
        "alt": "😶",
        "title": ":-X"
    },
    ";(": {
        "src": "/images/emojis/ascii/u003Bu0028.png",
        "alt": "😢",
        "title": ";("
    },
    ";-(": {
        "src": "/images/emojis/ascii/u003B-u002Du0028.png",
        "alt": "😢",
        "title": ";-("
    },
    ";)": {
        "src": "/images/emojis/ascii/u003Bu0029.png",
        "alt": "😉",
        "title": ";)"
    },
    ";-)": {
        "src": "/images/emojis/ascii/u003B-u002Du0029.png",
        "alt": "😉",
        "title": ";-)"
    },
    ";]": {
        "src": "/images/emojis/ascii/u003Bu005D.png",
        "alt": "😉",
        "title": ";]"
    },
    ";-]": {
        "src": "/images/emojis/ascii/u003B-u002Du005D.png",
        "alt": "😉",
        "title": ";-]"
    },
    ";^)": {
        "src": "/images/emojis/ascii/u003Bu005Eu0029.png",
        "alt": "😉",
        "title": ";^)"
    },
    ";D": {
        "src": "/images/emojis/ascii/u003BDu0044.png",
        "alt": "😉",
        "title": ";D"
    },
    "\\0/": {
        "src": "/images/emojis/ascii/u005C0u0030u002F.png",
        "alt": "🙆",
        "title": "\\0/"
    },
    "\\O/": {
        "src": "/images/emojis/ascii/u005COu004Fu002F.png",
        "alt": "🙆",
        "title": "\\O/"
    },
    "-_-": {
        "src": "/images/emojis/ascii/-u002D_u005F-u002D.png",
        "alt": "😑",
        "title": "-_-"
    },
    "-__-": {
        "src": "/images/emojis/ascii/-u002D_u005F_u005F-u002D.png",
        "alt": "😑",
        "title": "-__-"
    },
    "-___-": {
        "src": "/images/emojis/ascii/-u002D_u005F_u005F_u005F-u002D.png",
        "alt": "😑",
        "title": "-___-"
    },
    "</3": {
        "src": "/images/emojis/ascii/u003Cu002F3u0033.png",
        "alt": "💔",
        "title": "</3"
    },
    "<3": {
        "src": "/images/emojis/ascii/u003C3u0033.png",
        "alt": "❤",
        "title": "<3"
    },
    "=#": {
        "src": "/images/emojis/ascii/u003Du0023.png",
        "alt": "😶",
        "title": "=#"
    },
    "=$": {
        "src": "/images/emojis/ascii/u003Du0024.png",
        "alt": "😳",
        "title": "=$"
    },
    "=(": {
        "src": "/images/emojis/ascii/u003Du0028.png",
        "alt": "😞",
        "title": "=("
    },
    "'=(": {
        "src": "/images/emojis/ascii/u0027u003Du0028.png",
        "alt": "😓",
        "title": "'=("
    },
    "=)": {
        "src": "/images/emojis/ascii/u003Du0029.png",
        "alt": "🙂",
        "title": "=)"
    },
    "'=)": {
        "src": "/images/emojis/ascii/u0027u003Du0029.png",
        "alt": "😅",
        "title": "'=)"
    },
    "=*": {
        "src": "/images/emojis/ascii/u003Du002A.png",
        "alt": "😘",
        "title": "=*"
    },
    "=/": {
        "src": "/images/emojis/ascii/u003Du002F.png",
        "alt": "😕",
        "title": "=/"
    },
    "=\\": {
        "src": "/images/emojis/ascii/u003Du005C.png",
        "alt": "😕",
        "title": "=\\"
    },
    "=]": {
        "src": "/images/emojis/ascii/u003Du005D.png",
        "alt": "🙂",
        "title": "=]"
    },
    "=D": {
        "src": "/images/emojis/ascii/u003DDu0044.png",
        "alt": "😃",
        "title": "=D"
    },
    "'=D": {
        "src": "/images/emojis/ascii/u0027u003DDu0044.png",
        "alt": "😅",
        "title": "'=D"
    },
    "=L": {
        "src": "/images/emojis/ascii/u003DLu004C.png",
        "alt": "😕",
        "title": "=L"
    },
    "=P": {
        "src": "/images/emojis/ascii/u003DPu0050.png",
        "alt": "😛",
        "title": "=P"
    },
    "=X": {
        "src": "/images/emojis/ascii/u003DXu0058.png",
        "alt": "😶",
        "title": "=X"
    },
    ">.<": {
        "src": "/images/emojis/ascii/u003Eu002Eu003C.png",
        "alt": "😣",
        "title": ">.<"
    },
    ">:(": {
        "src": "/images/emojis/ascii/u003Eu0028.png",
        "alt": "😠",
        "title": ">:("
    },
    ">:-(": {
        "src": "/images/emojis/ascii/u003E-u002Du0028.png",
        "alt": "😠",
        "title": ">:-("
    },
    ">:)": {
        "src": "/images/emojis/ascii/u003Eu0029.png",
        "alt": "😆",
        "title": ">:)"
    },
    ">:-)": {
        "src": "/images/emojis/ascii/u003E-u002Du0029.png",
        "alt": "😆",
        "title": ">:-)"
    },
    ">:/": {
        "src": "/images/emojis/ascii/u003Eu002F.png",
        "alt": "😕",
        "title": ">:/"
    },
    ">:[": {
        "src": "/images/emojis/ascii/u003Eu005B.png",
        "alt": "😞",
        "title": ">:["
    },
    ">:\\": {
        "src": "/images/emojis/ascii/u003Eu005C.png",
        "alt": "😕",
        "title": ">:\\"
    },
    ">:O": {
        "src": "/images/emojis/ascii/u003EOu004F.png",
        "alt": "😮",
        "title": ">:O"
    },
    ">:P": {
        "src": "/images/emojis/ascii/u003EPu0050.png",
        "alt": "😜",
        "title": ">:P"
    },
    ">;)": {
        "src": "/images/emojis/ascii/u003Eu003Bu0029.png",
        "alt": "😆",
        "title": ">;)"
    },
    ">=)": {
        "src": "/images/emojis/ascii/u003Eu003Du0029.png",
        "alt": "😆",
        "title": ">=)"
    },
    "0:)": {
        "src": "/images/emojis/ascii/0u0030u0029.png",
        "alt": "😇",
        "title": "0:)"
    },
    "0:-)": {
        "src": "/images/emojis/ascii/0u0030-u002Du0029.png",
        "alt": "😇",
        "title": "0:-)"
    },
    "0:3": {
        "src": "/images/emojis/ascii/0u00303u0033.png",
        "alt": "😇",
        "title": "0:3"
    },
    "0:-3": {
        "src": "/images/emojis/ascii/0u0030-u002D3u0033.png",
        "alt": "😇",
        "title": "0:-3"
    },
    "0;-)": {
        "src": "/images/emojis/ascii/0u0030u003B-u002Du0029.png",
        "alt": "😇",
        "title": "0;-)"
    },
    "0;^)": {
        "src": "/images/emojis/ascii/0u0030u003Bu005Eu0029.png",
        "alt": "😇",
        "title": "0;^)"
    },
    "8)": {
        "src": "/images/emojis/ascii/8u0038u0029.png",
        "alt": "😎",
        "title": "8)"
    },
    "8-)": {
        "src": "/images/emojis/ascii/8u0038-u002Du0029.png",
        "alt": "😎",
        "title": "8-)"
    },
    "8-D": {
        "src": "/images/emojis/ascii/8u0038-u002DDu0044.png",
        "alt": "😎",
        "title": "8-D"
    },
    "B)": {
        "src": "/images/emojis/ascii/Bu0042u0029.png",
        "alt": "😎",
        "title": "B)"
    },
    "B-)": {
        "src": "/images/emojis/ascii/Bu0042-u002Du0029.png",
        "alt": "😎",
        "title": "B-)"
    },
    "B-D": {
        "src": "/images/emojis/ascii/Bu0042-u002DDu0044.png",
        "alt": "😎",
        "title": "B-D"
    },
    "D:": {
        "src": "/images/emojis/ascii/Du0044.png",
        "alt": "😨",
        "title": "D:"
    },
    "O:)": {
        "src": "/images/emojis/ascii/Ou004Fu0029.png",
        "alt": "😇",
        "title": "O:)"
    },
    "O:-)": {
        "src": "/images/emojis/ascii/Ou004F-u002Du0029.png",
        "alt": "😇",
        "title": "O:-)"
    },
    "O:3": {
        "src": "/images/emojis/ascii/Ou004F3u0033.png",
        "alt": "😇",
        "title": "O:3"
    },
    "O:-3": {
        "src": "/images/emojis/ascii/Ou004F-u002D3u0033.png",
        "alt": "😇",
        "title": "O:-3"
    },
    "O;-)": {
        "src": "/images/emojis/ascii/Ou004Fu003B-u002Du0029.png",
        "alt": "😇",
        "title": "O;-)"
    },
    "O_O": {
        "src": "/images/emojis/ascii/Ou004F_u005FOu004F.png",
        "alt": "😮",
        "title": "O_O"
    },
    "O=)": {
        "src": "/images/emojis/ascii/Ou004Fu003Du0029.png",
        "alt": "😇",
        "title": "O=)"
    },
    "X)": {
        "src": "/images/emojis/ascii/Xu0058u0029.png",
        "alt": "😵",
        "title": "X)"
    },
    "X-)": {
        "src": "/images/emojis/ascii/Xu0058-u002Du0029.png",
        "alt": "😵",
        "title": "X-)"
    },
    "X-P": {
        "src": "/images/emojis/ascii/Xu0058-u002DPu0050.png",
        "alt": "😜",
        "title": "X-P"
    },
    ":1102dailyparrot:": {
        "src": "/images/emojis/named/1102dailyparrot.gif",
        "alt": "1102 dailyparrot",
        "title": "1102 dailyparrot"
    },
    ":3dparrot:": {
        "src": "/images/emojis/named/3dparrot.png",
        "alt": "3d parrot",
        "title": "3d parrot"
    },
    ":404errorparrot:": {
        "src": "/images/emojis/named/404errorparrot.gif",
        "alt": "404error parrot",
        "title": "404error parrot"
    },
    ":60fpsparrot:": {
        "src": "/images/emojis/named/60fpsparrot.gif",
        "alt": "60fps parrot",
        "title": "60fps parrot"
    },
    ":accessibleparrot:": {
        "src": "/images/emojis/named/accessibleparrot.gif",
        "alt": "Accessible parrot",
        "title": "Accessible parrot"
    },
    ":akak:": {
        "src": "/images/emojis/named/akak.gif",
        "alt": "Akak",
        "title": "Akak"
    },
    ":angelparrot:": {
        "src": "/images/emojis/named/angelparrot.gif",
        "alt": "Angel parrot",
        "title": "Angel parrot"
    },
    ":angryparrot:": {
        "src": "/images/emojis/named/angryparrot.gif",
        "alt": "Angry parrot",
        "title": "Angry parrot"
    },
    ":antarcticaparrot:": {
        "src": "/images/emojis/named/antarcticaparrot.gif",
        "alt": "Antarctica parrot",
        "title": "Antarctica parrot"
    },
    ":a_parrot:": {
        "src": "/images/emojis/named/a_parrot.gif",
        "alt": "A-parrot",
        "title": "A-parrot"
    },
    ":asyncparrot:": {
        "src": "/images/emojis/named/asyncparrot.gif",
        "alt": "Async parrot",
        "title": "Async parrot"
    },
    ":aussiecongaparrot:": {
        "src": "/images/emojis/named/aussiecongaparrot.gif",
        "alt": "Aussie conga parrot",
        "title": "Aussie conga parrot"
    },
    ":aussieparrot:": {
        "src": "/images/emojis/named/aussieparrot.gif",
        "alt": "Aussie parrot",
        "title": "Aussie parrot"
    },
    ":aussiereversecongaparrot:": {
        "src": "/images/emojis/named/aussiereversecongaparrot.gif",
        "alt": "Aussiereversecongaparrot",
        "title": "Aussiereversecongaparrot"
    },
    ":backwardsparrot:": {
        "src": "/images/emojis/named/backwardsparrot.gif",
        "alt": "Backwards parrot",
        "title": "Backwards parrot"
    },
    ":bananaparrot:": {
        "src": "/images/emojis/named/bananaparrot.gif",
        "alt": "Banana parrot",
        "title": "Banana parrot"
    },
    ":beerparrot:": {
        "src": "/images/emojis/named/beerparrot.gif",
        "alt": "Beer parrot",
        "title": "Beer parrot"
    },
    ":beretparrot:": {
        "src": "/images/emojis/named/beretparrot.gif",
        "alt": "Beret parrot",
        "title": "Beret parrot"
    },
    ":bhutanparrot:": {
        "src": "/images/emojis/named/bhutanparrot.gif",
        "alt": "Bhutan parrot",
        "title": "Bhutan parrot"
    },
    ":bikeparrot:": {
        "src": "/images/emojis/named/bikeparrot.gif",
        "alt": "Bikeparrot",
        "title": "Bikeparrot"
    },
    ":bikerparrot:": {
        "src": "/images/emojis/named/bikerparrot.gif",
        "alt": "Biker parrot",
        "title": "Biker parrot"
    },
    ":birthdaypartyparrot:": {
        "src": "/images/emojis/named/birthdaypartyparrot.gif",
        "alt": "Birthday party parrot",
        "title": "Birthday party parrot"
    },
    ":blochparrot:": {
        "src": "/images/emojis/named/blochparrot.gif",
        "alt": "Blochparrot",
        "title": "Blochparrot"
    },
    ":blondesassyparrot:": {
        "src": "/images/emojis/named/blondesassyparrot.gif",
        "alt": "Blonde sassy parrot",
        "title": "Blonde sassy parrot"
    },
    ":blueparrot:": {
        "src": "/images/emojis/named/blueparrot.gif",
        "alt": "Blue parrot",
        "title": "Blue parrot"
    },
    ":bluescluesparrot:": {
        "src": "/images/emojis/named/bluescluesparrot.gif",
        "alt": "Blues clues parrot",
        "title": "Blues clues parrot"
    },
    ":bluntparrot:": {
        "src": "/images/emojis/named/bluntparrot.gif",
        "alt": "Blunt parrot",
        "title": "Blunt parrot"
    },
    ":bobrossparrot:": {
        "src": "/images/emojis/named/bobrossparrot.gif",
        "alt": "Bob ross parrot",
        "title": "Bob ross parrot"
    },
    ":bobrosspartyparrot:": {
        "src": "/images/emojis/named/bobrosspartyparrot.jpg",
        "alt": "Bob ross party parrot",
        "title": "Bob ross party parrot"
    },
    ":bobaparrot:": {
        "src": "/images/emojis/named/bobaparrot.gif",
        "alt": "Boba parrot",
        "title": "Boba parrot"
    },
    ":boba_parrot:": {
        "src": "/images/emojis/named/boba_parrot.gif",
        "alt": "Boba-parrot",
        "title": "Boba-parrot"
    },
    ":bootlegparrot:": {
        "src": "/images/emojis/named/bootlegparrot.gif",
        "alt": "Bootleg parrot",
        "title": "Bootleg parrot"
    },
    ":boredparrot:": {
        "src": "/images/emojis/named/boredparrot.gif",
        "alt": "Bored parrot",
        "title": "Bored parrot"
    },
    ":borjaparrot:": {
        "src": "/images/emojis/named/borjaparrot.png",
        "alt": "Borja parrot",
        "title": "Borja parrot"
    },
    ":bouncingparrot:": {
        "src": "/images/emojis/named/bouncingparrot.gif",
        "alt": "Bouncing parrot",
        "title": "Bouncing parrot"
    },
    ":braveheartparrot:": {
        "src": "/images/emojis/named/braveheartparrot.gif",
        "alt": "Braveheart parrot",
        "title": "Braveheart parrot"
    },
    ":brazilianfanparrot:": {
        "src": "/images/emojis/named/brazilianfanparrot.gif",
        "alt": "Brazilian fan parrot",
        "title": "Brazilian fan parrot"
    },
    ":brazilianplayerparrot:": {
        "src": "/images/emojis/named/brazilianplayerparrot.gif",
        "alt": "Brazilian player parrot",
        "title": "Brazilian player parrot"
    },
    ":bunnyparrot:": {
        "src": "/images/emojis/named/bunnyparrot.gif",
        "alt": "Bunny parrot",
        "title": "Bunny parrot"
    },
    ":businessparrot:": {
        "src": "/images/emojis/named/businessparrot.gif",
        "alt": "Businessparrot",
        "title": "Businessparrot"
    },
    ":cakeparrot:": {
        "src": "/images/emojis/named/cakeparrot.gif",
        "alt": "Cake parrot",
        "title": "Cake parrot"
    },
    ":calvinistparrot:": {
        "src": "/images/emojis/named/calvinistparrot.gif",
        "alt": "Calvinist parrot",
        "title": "Calvinist parrot"
    },
    ":canadaparrot:": {
        "src": "/images/emojis/named/canadaparrot.gif",
        "alt": "Canada parrot",
        "title": "Canada parrot"
    },
    ":capsparrot:": {
        "src": "/images/emojis/named/capsparrot.gif",
        "alt": "Caps parrot",
        "title": "Caps parrot"
    },
    ":catparrot:": {
        "src": "/images/emojis/named/catparrot.gif",
        "alt": "Cat parrot",
        "title": "Cat parrot"
    },
    ":ceilingparrot:": {
        "src": "/images/emojis/named/ceilingparrot.gif",
        "alt": "Ceiling parrot",
        "title": "Ceiling parrot"
    },
    ":checkeredflagparrot:": {
        "src": "/images/emojis/named/checkeredflagparrot.gif",
        "alt": "Checkeredflag parrot",
        "title": "Checkeredflag parrot"
    },
    ":chef_parrot:": {
        "src": "/images/emojis/named/chef_parrot.gif",
        "alt": "Chef-parrot",
        "title": "Chef-parrot"
    },
    ":chicoparrot:": {
        "src": "/images/emojis/named/chicoparrot.gif",
        "alt": "Chico parrot",
        "title": "Chico parrot"
    },
    ":chillparrot:": {
        "src": "/images/emojis/named/chillparrot.gif",
        "alt": "Chill parrot",
        "title": "Chill parrot"
    },
    ":christmasparrot:": {
        "src": "/images/emojis/named/christmasparrot.gif",
        "alt": "Christmas parrot",
        "title": "Christmas parrot"
    },
    ":coffeeparrot:": {
        "src": "/images/emojis/named/coffeeparrot.gif",
        "alt": "Coffee parrot",
        "title": "Coffee parrot"
    },
    ":communistparrot:": {
        "src": "/images/emojis/named/communistparrot.gif",
        "alt": "Communist parrot",
        "title": "Communist parrot"
    },
    ":confusedparrot:": {
        "src": "/images/emojis/named/confusedparrot.gif",
        "alt": "Confused parrot",
        "title": "Confused parrot"
    },
    ":congapartyparrot:": {
        "src": "/images/emojis/named/congapartyparrot.gif",
        "alt": "Conga party parrot",
        "title": "Conga party parrot"
    },
    ":congaparrot:": {
        "src": "/images/emojis/named/congaparrot.gif",
        "alt": "Congaparrot",
        "title": "Congaparrot"
    },
    ":copparrot:": {
        "src": "/images/emojis/named/copparrot.gif",
        "alt": "Cop parrot",
        "title": "Cop parrot"
    },
    ":corona_parrot:": {
        "src": "/images/emojis/named/corona_parrot.gif",
        "alt": "Corona-parrot",
        "title": "Corona-parrot"
    },
    ":covid19parrot:": {
        "src": "/images/emojis/named/covid19parrot.gif",
        "alt": "Covid19 parrot",
        "title": "Covid19 parrot"
    },
    ":cowboy_parrot:": {
        "src": "/images/emojis/named/cowboy_parrot.gif",
        "alt": "Cowboy-parrot",
        "title": "Cowboy-parrot"
    },
    ":cozyparrot:": {
        "src": "/images/emojis/named/cozyparrot.gif",
        "alt": "Cozy parrot",
        "title": "Cozy parrot"
    },
    ":crazilyangryparrot:": {
        "src": "/images/emojis/named/crazilyangryparrot.gif",
        "alt": "Crazilyangryparrot",
        "title": "Crazilyangryparrot"
    },
    ":croissantparrot:": {
        "src": "/images/emojis/named/croissantparrot.gif",
        "alt": "Croissantparrot",
        "title": "Croissantparrot"
    },
    ":cryptoparrot:": {
        "src": "/images/emojis/named/cryptoparrot.gif",
        "alt": "Crypto parrot",
        "title": "Crypto parrot"
    },
    ":cursedparrot:": {
        "src": "/images/emojis/named/cursedparrot.gif",
        "alt": "Cursed parrot",
        "title": "Cursed parrot"
    },
    ":dabparrot:": {
        "src": "/images/emojis/named/dabparrot.gif",
        "alt": "Dab parrot",
        "title": "Dab parrot"
    },
    ":dadparrot:": {
        "src": "/images/emojis/named/dadparrot.gif",
        "alt": "Dad parrot",
        "title": "Dad parrot"
    },
    ":daftpunkparrot:": {
        "src": "/images/emojis/named/daftpunkparrot.gif",
        "alt": "Daft punk parrot",
        "title": "Daft punk parrot"
    },
    ":darkbeerparrot:": {
        "src": "/images/emojis/named/darkbeerparrot.gif",
        "alt": "Dark beer parrot",
        "title": "Dark beer parrot"
    },
    ":darkmodeparrot:": {
        "src": "/images/emojis/named/darkmodeparrot.gif",
        "alt": "Dark mode parrot",
        "title": "Dark mode parrot"
    },
    ":databaseparrot:": {
        "src": "/images/emojis/named/databaseparrot.gif",
        "alt": "Database parrot",
        "title": "Database parrot"
    },
    ":deadparrot:": {
        "src": "/images/emojis/named/deadparrot.jpg",
        "alt": "Deadparrot",
        "title": "Deadparrot"
    },
    ":dealwithitnowparrot:": {
        "src": "/images/emojis/named/dealwithitnowparrot.gif",
        "alt": "Deal with it now parrot",
        "title": "Deal with it now parrot"
    },
    ":dealwithitparrot:": {
        "src": "/images/emojis/named/dealwithitparrot.gif",
        "alt": "Deal with it parrot",
        "title": "Deal with it parrot"
    },
    ":deployparrot:": {
        "src": "/images/emojis/named/deployparrot.gif",
        "alt": "Deployparrot",
        "title": "Deployparrot"
    },
    ":devilparrot:": {
        "src": "/images/emojis/named/devilparrot.gif",
        "alt": "Devil parrot",
        "title": "Devil parrot"
    },
    ":discoparrot:": {
        "src": "/images/emojis/named/discoparrot.gif",
        "alt": "Disco parrot",
        "title": "Disco parrot"
    },
    ":docparrot:": {
        "src": "/images/emojis/named/docparrot.gif",
        "alt": "Doc parrot",
        "title": "Doc parrot"
    },
    ":donutparrot:": {
        "src": "/images/emojis/named/donutparrot.gif",
        "alt": "Donut parrot",
        "title": "Donut parrot"
    },
    ":downvotepartyparrot:": {
        "src": "/images/emojis/named/downvotepartyparrot.gif",
        "alt": "Downvote party parrot",
        "title": "Downvote party parrot"
    },
    ":dreidelparrot:": {
        "src": "/images/emojis/named/dreidelparrot.gif",
        "alt": "Dreidel parrot",
        "title": "Dreidel parrot"
    },
    ":drupalparrot:": {
        "src": "/images/emojis/named/drupalparrot.gif",
        "alt": "Drupal parrot",
        "title": "Drupal parrot"
    },
    ":euparrot:": {
        "src": "/images/emojis/named/euparrot.gif",
        "alt": "Eu parrot",
        "title": "Eu parrot"
    },
    ":everythingsfineparrot:": {
        "src": "/images/emojis/named/everythingsfineparrot.gif",
        "alt": "Everythings fine parrot",
        "title": "Everythings fine parrot"
    },
    ":evilparrot:": {
        "src": "/images/emojis/named/evilparrot.gif",
        "alt": "Evil parrot",
        "title": "Evil parrot"
    },
    ":exceptionallyfastparrot:": {
        "src": "/images/emojis/named/exceptionallyfastparrot.gif",
        "alt": "Exceptionally fast parrot",
        "title": "Exceptionally fast parrot"
    },
    ":exceptionally_fast_parrot:": {
        "src": "/images/emojis/named/exceptionally_fast_parrot.gif",
        "alt": "Exceptionally-fast-parrot",
        "title": "Exceptionally-fast-parrot"
    },
    ":explodyparrot:": {
        "src": "/images/emojis/named/explodyparrot.gif",
        "alt": "Explody parrot",
        "title": "Explody parrot"
    },
    ":fadingparrot:": {
        "src": "/images/emojis/named/fadingparrot.gif",
        "alt": "Fadingparrot",
        "title": "Fadingparrot"
    },
    ":fanoos_parrot:": {
        "src": "/images/emojis/named/fanoos_parrot.gif",
        "alt": "Fanoos-parrot",
        "title": "Fanoos-parrot"
    },
    ":fastparrot:": {
        "src": "/images/emojis/named/fastparrot.gif",
        "alt": "Fast parrot",
        "title": "Fast parrot"
    },
    ":fedoraparrot:": {
        "src": "/images/emojis/named/fedoraparrot.gif",
        "alt": "Fedora parrot",
        "title": "Fedora parrot"
    },
    ":feministparrot:": {
        "src": "/images/emojis/named/feministparrot.png",
        "alt": "Feminist parrot",
        "title": "Feminist parrot"
    },
    ":fidgetparrot:": {
        "src": "/images/emojis/named/fidgetparrot.gif",
        "alt": "Fidget parrot",
        "title": "Fidget parrot"
    },
    ":fieriparrot:": {
        "src": "/images/emojis/named/fieriparrot.gif",
        "alt": "Fieri parrot",
        "title": "Fieri parrot"
    },
    ":fiestaparrot:": {
        "src": "/images/emojis/named/fiestaparrot.gif",
        "alt": "Fiesta parrot",
        "title": "Fiesta parrot"
    },
    ":fireparrot:": {
        "src": "/images/emojis/named/fireparrot.gif",
        "alt": "Fireparrot",
        "title": "Fireparrot"
    },
    ":fixparrot:": {
        "src": "/images/emojis/named/fixparrot.gif",
        "alt": "Fixparrot",
        "title": "Fixparrot"
    },
    ":flamingparrot:": {
        "src": "/images/emojis/named/flamingparrot.gif",
        "alt": "Flamingparrot",
        "title": "Flamingparrot"
    },
    ":flowerparrot:": {
        "src": "/images/emojis/named/flowerparrot.gif",
        "alt": "Flower parrot",
        "title": "Flower parrot"
    },
    ":flyingmoneyparrot:": {
        "src": "/images/emojis/named/flyingmoneyparrot.gif",
        "alt": "Flying money parrot",
        "title": "Flying money parrot"
    },
    ":footballparrot:": {
        "src": "/images/emojis/named/footballparrot.gif",
        "alt": "Football parrot",
        "title": "Football parrot"
    },
    ":france_parrot:": {
        "src": "/images/emojis/named/france_parrot.gif",
        "alt": "France-parrot",
        "title": "France-parrot"
    },
    ":frenchparrot:": {
        "src": "/images/emojis/named/frenchparrot.gif",
        "alt": "French parrot",
        "title": "French parrot"
    },
    ":gandalfparrot:": {
        "src": "/images/emojis/named/gandalfparrot.gif",
        "alt": "Gandalf parrot",
        "title": "Gandalf parrot"
    },
    ":gentlemanparrot:": {
        "src": "/images/emojis/named/gentlemanparrot.gif",
        "alt": "Gentleman parrot",
        "title": "Gentleman parrot"
    },
    ":githubparrot:": {
        "src": "/images/emojis/named/githubparrot.gif",
        "alt": "Github parrot",
        "title": "Github parrot"
    },
    ":glimpseparrot:": {
        "src": "/images/emojis/named/glimpseparrot.gif",
        "alt": "Glimpse parrot",
        "title": "Glimpse parrot"
    },
    ":gothparrot:": {
        "src": "/images/emojis/named/gothparrot.gif",
        "alt": "Goth parrot",
        "title": "Goth parrot"
    },
    ":gotta_parrot_fast:": {
        "src": "/images/emojis/named/gotta_parrot_fast.gif",
        "alt": "Gotta-parrot-fast",
        "title": "Gotta-parrot-fast"
    },
    ":grokeparrot:": {
        "src": "/images/emojis/named/grokeparrot.gif",
        "alt": "Grokeparrot",
        "title": "Grokeparrot"
    },
    ":grouchoparrot:": {
        "src": "/images/emojis/named/grouchoparrot.gif",
        "alt": "Groucho parrot",
        "title": "Groucho parrot"
    },
    ":guitarparrot:": {
        "src": "/images/emojis/named/guitarparrot.gif",
        "alt": "Guitarparrot",
        "title": "Guitarparrot"
    },
    ":halalparrot:": {
        "src": "/images/emojis/named/halalparrot.gif",
        "alt": "Halal parrot",
        "title": "Halal parrot"
    },
    ":hamburgerparrot:": {
        "src": "/images/emojis/named/hamburgerparrot.gif",
        "alt": "Hamburger parrot",
        "title": "Hamburger parrot"
    },
    ":hanamiparrot:": {
        "src": "/images/emojis/named/hanamiparrot.gif",
        "alt": "Hanami parrot",
        "title": "Hanami parrot"
    },
    ":hardhatparrot:": {
        "src": "/images/emojis/named/hardhatparrot.gif",
        "alt": "Hard hat parrot",
        "title": "Hard hat parrot"
    },
    ":haroldparrot:": {
        "src": "/images/emojis/named/haroldparrot.jpeg",
        "alt": "Haroldparrot",
        "title": "Haroldparrot"
    },
    ":harpoparrot:": {
        "src": "/images/emojis/named/harpoparrot.gif",
        "alt": "Harpo parrot",
        "title": "Harpo parrot"
    },
    ":harrypotterparrot:": {
        "src": "/images/emojis/named/harrypotterparrot.gif",
        "alt": "Harry potter parrot",
        "title": "Harry potter parrot"
    },
    ":harvey_parrot:": {
        "src": "/images/emojis/named/harvey_parrot.gif",
        "alt": "Harvey-parrot",
        "title": "Harvey-parrot"
    },
    ":headbangingparrot:": {
        "src": "/images/emojis/named/headbangingparrot.gif",
        "alt": "Headbanging parrot",
        "title": "Headbanging parrot"
    },
    ":headingparrot:": {
        "src": "/images/emojis/named/headingparrot.gif",
        "alt": "Heading parrot",
        "title": "Heading parrot"
    },
    ":headlessparrot:": {
        "src": "/images/emojis/named/headlessparrot.gif",
        "alt": "Headless parrot",
        "title": "Headless parrot"
    },
    ":headsetparrot:": {
        "src": "/images/emojis/named/headsetparrot.gif",
        "alt": "Headset parrot",
        "title": "Headset parrot"
    },
    ":hmmparrot:": {
        "src": "/images/emojis/named/hmmparrot.gif",
        "alt": "Hmm parrot",
        "title": "Hmm parrot"
    },
    ":horizontalparrot:": {
        "src": "/images/emojis/named/horizontalparrot.gif",
        "alt": "Horizontal parrot",
        "title": "Horizontal parrot"
    },
    ":hued_parrot:": {
        "src": "/images/emojis/named/hued_parrot.gif",
        "alt": "Hued-parrot",
        "title": "Hued-parrot"
    },
    ":hyperfastparrot:": {
        "src": "/images/emojis/named/hyperfastparrot.gif",
        "alt": "Hyperfastparrot",
        "title": "Hyperfastparrot"
    },
    ":hypnoparrot:": {
        "src": "/images/emojis/named/hypnoparrot.gif",
        "alt": "Hypno parrot",
        "title": "Hypno parrot"
    },
    ":hypnoparrotdark:": {
        "src": "/images/emojis/named/hypnoparrotdark.gif",
        "alt": "Hypno parrot dark",
        "title": "Hypno parrot dark"
    },
    ":hypnoparrotlight:": {
        "src": "/images/emojis/named/hypnoparrotlight.gif",
        "alt": "Hypno parrot light",
        "title": "Hypno parrot light"
    },
    ":icecreamparrot:": {
        "src": "/images/emojis/named/icecreamparrot.gif",
        "alt": "Ice cream parrot",
        "title": "Ice cream parrot"
    },
    ":illuminatiparrot:": {
        "src": "/images/emojis/named/illuminatiparrot.gif",
        "alt": "Illuminati parrot",
        "title": "Illuminati parrot"
    },
    ":innersourceparrot:": {
        "src": "/images/emojis/named/innersourceparrot.gif",
        "alt": "Inner source parrot",
        "title": "Inner source parrot"
    },
    ":inverseparrot:": {
        "src": "/images/emojis/named/inverseparrot.gif",
        "alt": "Inverse parrot",
        "title": "Inverse parrot"
    },
    ":invisibleparrot:": {
        "src": "/images/emojis/named/invisibleparrot.gif",
        "alt": "Invisible parrot",
        "title": "Invisible parrot"
    },
    ":japanparrot:": {
        "src": "/images/emojis/named/japanparrot.gif",
        "alt": "Japan parrot",
        "title": "Japan parrot"
    },
    ":jediparrot:": {
        "src": "/images/emojis/named/jediparrot.gif",
        "alt": "Jedi parrot",
        "title": "Jedi parrot"
    },
    ":jewishparrot:": {
        "src": "/images/emojis/named/jewishparrot.gif",
        "alt": "Jewish parrot",
        "title": "Jewish parrot"
    },
    ":johnfrancisparrot:": {
        "src": "/images/emojis/named/johnfrancisparrot.png",
        "alt": "John francis parrot",
        "title": "John francis parrot"
    },
    ":jumpingparrot:": {
        "src": "/images/emojis/named/jumpingparrot.gif",
        "alt": "Jumping parrot",
        "title": "Jumping parrot"
    },
    ":jumpingparrotjr:": {
        "src": "/images/emojis/named/jumpingparrotjr.gif",
        "alt": "Jumping parrot jr",
        "title": "Jumping parrot jr"
    },
    ":kaka:": {
        "src": "/images/emojis/named/kaka.gif",
        "alt": "Kaka",
        "title": "Kaka"
    },
    ":kindasusparrot:": {
        "src": "/images/emojis/named/kindasusparrot.gif",
        "alt": "Kindasusparrot",
        "title": "Kindasusparrot"
    },
    ":kiwiparrot:": {
        "src": "/images/emojis/named/kiwiparrot.gif",
        "alt": "Kiwi parrot",
        "title": "Kiwi parrot"
    },
    ":laptopparrot:": {
        "src": "/images/emojis/named/laptopparrot.png",
        "alt": "Laptop parrot",
        "title": "Laptop parrot"
    },
    ":lepreparty_parrot:": {
        "src": "/images/emojis/named/lepreparty_parrot.gif",
        "alt": "Lepreparty-parrot",
        "title": "Lepreparty-parrot"
    },
    ":levitationparrot:": {
        "src": "/images/emojis/named/levitationparrot.gif",
        "alt": "Levitation parrot",
        "title": "Levitation parrot"
    },
    ":loveparrot:": {
        "src": "/images/emojis/named/loveparrot.gif",
        "alt": "Love parrot",
        "title": "Love parrot"
    },
    ":lovey_parrot:": {
        "src": "/images/emojis/named/lovey_parrot.png",
        "alt": "Lovey-parrot",
        "title": "Lovey-parrot"
    },
    ":luckyparrot:": {
        "src": "/images/emojis/named/luckyparrot.gif",
        "alt": "Lucky parrot",
        "title": "Lucky parrot"
    },
    ":maidparrot:": {
        "src": "/images/emojis/named/maidparrot.gif",
        "alt": "Maid parrot",
        "title": "Maid parrot"
    },
    ":maracasparrot:": {
        "src": "/images/emojis/named/maracasparrot.gif",
        "alt": "Maracas parrot",
        "title": "Maracas parrot"
    },
    ":marc_rebillet_parrot:": {
        "src": "/images/emojis/named/marc_rebillet_parrot.jpg",
        "alt": "Marc-rebillet-parrot",
        "title": "Marc-rebillet-parrot"
    },
    ":mardigrasparrot:": {
        "src": "/images/emojis/named/mardigrasparrot.gif",
        "alt": "Mardigras parrot",
        "title": "Mardigras parrot"
    },
    ":margaritaparrot:": {
        "src": "/images/emojis/named/margaritaparrot.gif",
        "alt": "Margarita parrot",
        "title": "Margarita parrot"
    },
    ":marshmallowparrot:": {
        "src": "/images/emojis/named/marshmallowparrot.gif",
        "alt": "Marshmallow parrot",
        "title": "Marshmallow parrot"
    },
    ":mask_parrot:": {
        "src": "/images/emojis/named/mask_parrot.gif",
        "alt": "Mask-parrot",
        "title": "Mask-parrot"
    },
    ":matrixparrot:": {
        "src": "/images/emojis/named/matrixparrot.gif",
        "alt": "Matrix parrot",
        "title": "Matrix parrot"
    },
    ":meldparrot:": {
        "src": "/images/emojis/named/meldparrot.gif",
        "alt": "Meld parrot",
        "title": "Meld parrot"
    },
    ":mergedparrot:": {
        "src": "/images/emojis/named/mergedparrot.gif",
        "alt": "Merged parrot",
        "title": "Merged parrot"
    },
    ":metalparrot:": {
        "src": "/images/emojis/named/metalparrot.gif",
        "alt": "Metal parrot",
        "title": "Metal parrot"
    },
    ":mexaparrot:": {
        "src": "/images/emojis/named/mexaparrot.gif",
        "alt": "Mexa parrot",
        "title": "Mexa parrot"
    },
    ":michaeljacksonparrot:": {
        "src": "/images/emojis/named/michaeljacksonparrot.gif",
        "alt": "Michael jackson parrot",
        "title": "Michael jackson parrot"
    },
    ":middleparrot:": {
        "src": "/images/emojis/named/middleparrot.gif",
        "alt": "Middle parrot",
        "title": "Middle parrot"
    },
    ":minecraft_parrot:": {
        "src": "/images/emojis/named/minecraft_parrot.gif",
        "alt": "Minecraft-parrot",
        "title": "Minecraft-parrot"
    },
    ":moneyparrot:": {
        "src": "/images/emojis/named/moneyparrot.gif",
        "alt": "Moneyparrot",
        "title": "Moneyparrot"
    },
    ":moonparrot:": {
        "src": "/images/emojis/named/moonparrot.gif",
        "alt": "Moon parrot",
        "title": "Moon parrot"
    },
    ":moonwalkingparrot:": {
        "src": "/images/emojis/named/moonwalkingparrot.gif",
        "alt": "Moon walking parrot",
        "title": "Moon walking parrot"
    },
    ":musicparrot:": {
        "src": "/images/emojis/named/musicparrot.gif",
        "alt": "Music parrot",
        "title": "Music parrot"
    },
    ":mustacheparrot:": {
        "src": "/images/emojis/named/mustacheparrot.gif",
        "alt": "Mustache parrot",
        "title": "Mustache parrot"
    },
    ":nekoparrot:": {
        "src": "/images/emojis/named/nekoparrot.gif",
        "alt": "Neko parrot",
        "title": "Neko parrot"
    },
    ":nodeparrot:": {
        "src": "/images/emojis/named/nodeparrot.gif",
        "alt": "Node parrot",
        "title": "Node parrot"
    },
    ":norwegianblueparrot:": {
        "src": "/images/emojis/named/norwegianblueparrot.gif",
        "alt": "Norwegian blue parrot",
        "title": "Norwegian blue parrot"
    },
    ":nyanparrot:": {
        "src": "/images/emojis/named/nyanparrot.gif",
        "alt": "Nyan parrot",
        "title": "Nyan parrot"
    },
    ":oh_heyparrot:": {
        "src": "/images/emojis/named/oh_heyparrot.gif",
        "alt": "Oh-hey parrot",
        "title": "Oh-hey parrot"
    },
    ":oldtimeyparrot:": {
        "src": "/images/emojis/named/oldtimeyparrot.gif",
        "alt": "Old timey parrot",
        "title": "Old timey parrot"
    },
    ":opensourceparrot:": {
        "src": "/images/emojis/named/opensourceparrot.gif",
        "alt": "Open source parrot",
        "title": "Open source parrot"
    },
    ":originalparrot:": {
        "src": "/images/emojis/named/originalparrot.gif",
        "alt": "Original parrot",
        "title": "Original parrot"
    },
    ":oriolesparrot:": {
        "src": "/images/emojis/named/oriolesparrot.gif",
        "alt": "Orioles parrot",
        "title": "Orioles parrot"
    },
    ":papalparrot:": {
        "src": "/images/emojis/named/papalparrot.gif",
        "alt": "Papal parrot",
        "title": "Papal parrot"
    },
    ":paperparrot:": {
        "src": "/images/emojis/named/paperparrot.png",
        "alt": "Paper parrot",
        "title": "Paper parrot"
    },
    ":parrotarrowup:": {
        "src": "/images/emojis/named/parrotarrowup.gif",
        "alt": "Parrot arrow up",
        "title": "Parrot arrow up"
    },
    ":parrotbean:": {
        "src": "/images/emojis/named/parrotbean.gif",
        "alt": "Parrot bean",
        "title": "Parrot bean"
    },
    ":parrotbeer:": {
        "src": "/images/emojis/named/parrotbeer.gif",
        "alt": "Parrot beer",
        "title": "Parrot beer"
    },
    ":parrotbeers:": {
        "src": "/images/emojis/named/parrotbeers.gif",
        "alt": "Parrot beers",
        "title": "Parrot beers"
    },
    ":parrotcop:": {
        "src": "/images/emojis/named/parrotcop.gif",
        "alt": "Parrot cop",
        "title": "Parrot cop"
    },
    ":parrotdad:": {
        "src": "/images/emojis/named/parrotdad.gif",
        "alt": "Parrot dad",
        "title": "Parrot dad"
    },
    ":parrotdada:": {
        "src": "/images/emojis/named/parrotdada.png",
        "alt": "Parrot dada",
        "title": "Parrot dada"
    },
    ":parrotdoge:": {
        "src": "/images/emojis/named/parrotdoge.gif",
        "alt": "Parrot doge",
        "title": "Parrot doge"
    },
    ":parrotfly:": {
        "src": "/images/emojis/named/parrotfly.gif",
        "alt": "Parrot fly",
        "title": "Parrot fly"
    },
    ":parrotgreen:": {
        "src": "/images/emojis/named/parrotgreen.gif",
        "alt": "Parrot green",
        "title": "Parrot green"
    },
    ":parrotkorrot:": {
        "src": "/images/emojis/named/parrotkorrot.png",
        "alt": "Parrot korrot",
        "title": "Parrot korrot"
    },
    ":parrotmargarita:": {
        "src": "/images/emojis/named/parrotmargarita.gif",
        "alt": "Parrot margarita",
        "title": "Parrot margarita"
    },
    ":parrotmustache:": {
        "src": "/images/emojis/named/parrotmustache.gif",
        "alt": "Parrot mustache",
        "title": "Parrot mustache"
    },
    ":parrotnotfound:": {
        "src": "/images/emojis/named/parrotnotfound.gif",
        "alt": "Parrot not found",
        "title": "Parrot not found"
    },
    ":parrotok:": {
        "src": "/images/emojis/named/parrotok.png",
        "alt": "Parrot ok",
        "title": "Parrot ok"
    },
    ":parrotpanther:": {
        "src": "/images/emojis/named/parrotpanther.gif",
        "alt": "Parrot panther",
        "title": "Parrot panther"
    },
    ":parrotsleep:": {
        "src": "/images/emojis/named/parrotsleep.gif",
        "alt": "Parrot sleep",
        "title": "Parrot sleep"
    },
    ":parrotsmiley:": {
        "src": "/images/emojis/named/parrotsmiley.gif",
        "alt": "Parrot smiley",
        "title": "Parrot smiley"
    },
    ":parrottrump:": {
        "src": "/images/emojis/named/parrottrump.gif",
        "alt": "Parrot trump",
        "title": "Parrot trump"
    },
    ":parrotturban:": {
        "src": "/images/emojis/named/parrotturban.png",
        "alt": "Parrot turban",
        "title": "Parrot turban"
    },
    ":parrotwine:": {
        "src": "/images/emojis/named/parrotwine.gif",
        "alt": "Parrot wine",
        "title": "Parrot wine"
    },
    ":parrotyankee:": {
        "src": "/images/emojis/named/parrotyankee.gif",
        "alt": "Parrot yankee",
        "title": "Parrot yankee"
    },
    ":parrotbeer_left:": {
        "src": "/images/emojis/named/parrotbeer_left.gif",
        "alt": "Parrotbeer-left",
        "title": "Parrotbeer-left"
    },
    ":parrotbeer_right:": {
        "src": "/images/emojis/named/parrotbeer_right.gif",
        "alt": "Parrotbeer-right",
        "title": "Parrotbeer-right"
    },
    ":parrotception:": {
        "src": "/images/emojis/named/parrotception.gif",
        "alt": "Parrotception",
        "title": "Parrotception"
    },
    ":parrothd:": {
        "src": "/images/emojis/named/parrothd.gif",
        "alt": "Parrothd",
        "title": "Parrothd"
    },
    ":parrotliftoff:": {
        "src": "/images/emojis/named/parrotliftoff.gif",
        "alt": "Parrotliftoff",
        "title": "Parrotliftoff"
    },
    ":parrot_newzealand:": {
        "src": "/images/emojis/named/parrot_newzealand.gif",
        "alt": "Parrot-newzealand",
        "title": "Parrot-newzealand"
    },
    ":parrot_puertorico:": {
        "src": "/images/emojis/named/parrot_puertorico.gif",
        "alt": "Parrot-puertorico",
        "title": "Parrot-puertorico"
    },
    ":parrot_unity:": {
        "src": "/images/emojis/named/parrot_unity.gif",
        "alt": "Parrot-unity",
        "title": "Parrot-unity"
    },
    ":parrotwave1:": {
        "src": "/images/emojis/named/parrotwave1.gif",
        "alt": "Parrotwave1",
        "title": "Parrotwave1"
    },
    ":parrotwave2:": {
        "src": "/images/emojis/named/parrotwave2.gif",
        "alt": "Parrotwave2",
        "title": "Parrotwave2"
    },
    ":parrotwave3:": {
        "src": "/images/emojis/named/parrotwave3.gif",
        "alt": "Parrotwave3",
        "title": "Parrotwave3"
    },
    ":parrotwave4:": {
        "src": "/images/emojis/named/parrotwave4.gif",
        "alt": "Parrotwave4",
        "title": "Parrotwave4"
    },
    ":parrotwave5:": {
        "src": "/images/emojis/named/parrotwave5.gif",
        "alt": "Parrotwave5",
        "title": "Parrotwave5"
    },
    ":parrotwave6:": {
        "src": "/images/emojis/named/parrotwave6.gif",
        "alt": "Parrotwave6",
        "title": "Parrotwave6"
    },
    ":parrotwave7:": {
        "src": "/images/emojis/named/parrotwave7.gif",
        "alt": "Parrotwave7",
        "title": "Parrotwave7"
    },
    ":partitionparrot:": {
        "src": "/images/emojis/named/partitionparrot.jpg",
        "alt": "Partitionparrot",
        "title": "Partitionparrot"
    },
    ":partyparrotwallstreetbets:": {
        "src": "/images/emojis/named/partyparrotwallstreetbets.gif",
        "alt": "Party parrot wallstreetbets",
        "title": "Party parrot wallstreetbets"
    },
    ":partyparrot:": {
        "src": "/images/emojis/named/partyparrot.gif",
        "alt": "Partyparrot",
        "title": "Partyparrot"
    },
    ":peruparrot:": {
        "src": "/images/emojis/named/peruparrot.gif",
        "alt": "Peru parrot",
        "title": "Peru parrot"
    },
    ":phparrot:": {
        "src": "/images/emojis/named/phparrot.gif",
        "alt": "Ph parrot",
        "title": "Ph parrot"
    },
    ":picassoparrot:": {
        "src": "/images/emojis/named/picassoparrot.gif",
        "alt": "Picasso parrot",
        "title": "Picasso parrot"
    },
    ":pingpongparrot:": {
        "src": "/images/emojis/named/pingpongparrot.gif",
        "alt": "Pingpong parrot",
        "title": "Pingpong parrot"
    },
    ":pirateparrot:": {
        "src": "/images/emojis/named/pirateparrot.gif",
        "alt": "Pirate parrot",
        "title": "Pirate parrot"
    },
    ":pizzaparrot:": {
        "src": "/images/emojis/named/pizzaparrot.gif",
        "alt": "Pizza parrot",
        "title": "Pizza parrot"
    },
    ":plusultrafastparrot:": {
        "src": "/images/emojis/named/plusultrafastparrot.gif",
        "alt": "Plusultrafastparrot",
        "title": "Plusultrafastparrot"
    },
    ":pokeparrot:": {
        "src": "/images/emojis/named/pokeparrot.gif",
        "alt": "Poke parrot",
        "title": "Poke parrot"
    },
    ":popcornparrot:": {
        "src": "/images/emojis/named/popcornparrot.gif",
        "alt": "Popcorn parrot",
        "title": "Popcorn parrot"
    },
    ":portalblueparrot:": {
        "src": "/images/emojis/named/portalblueparrot.gif",
        "alt": "Portal blue parrot",
        "title": "Portal blue parrot"
    },
    ":portalorangeparrot:": {
        "src": "/images/emojis/named/portalorangeparrot.gif",
        "alt": "Portal orange parrot",
        "title": "Portal orange parrot"
    },
    ":portalparrot:": {
        "src": "/images/emojis/named/portalparrot.gif",
        "alt": "Portal parrot",
        "title": "Portal parrot"
    },
    ":pumpkinparrot:": {
        "src": "/images/emojis/named/pumpkinparrot.gif",
        "alt": "Pumpkin parrot",
        "title": "Pumpkin parrot"
    },
    ":quadparrot:": {
        "src": "/images/emojis/named/quadparrot.gif",
        "alt": "Quad parrot",
        "title": "Quad parrot"
    },
    ":rainbowparrot:": {
        "src": "/images/emojis/named/rainbowparrot.gif",
        "alt": "Rainbow parrot",
        "title": "Rainbow parrot"
    },
    ":ramadan_parrot:": {
        "src": "/images/emojis/named/ramadan_parrot.gif",
        "alt": "Ramadan-parrot",
        "title": "Ramadan-parrot"
    },
    ":redenvelopeparrot:": {
        "src": "/images/emojis/named/redenvelopeparrot.gif",
        "alt": "Red envelope parrot",
        "title": "Red envelope parrot"
    },
    ":redhatparrot:": {
        "src": "/images/emojis/named/redhatparrot.gif",
        "alt": "Red hat parrot",
        "title": "Red hat parrot"
    },
    ":reversecongaparrot:": {
        "src": "/images/emojis/named/reversecongaparrot.gif",
        "alt": "Reverse conga parrot",
        "title": "Reverse conga parrot"
    },
    ":reverseparrot:": {
        "src": "/images/emojis/named/reverseparrot.gif",
        "alt": "Reverse parrot",
        "title": "Reverse parrot"
    },
    ":revertitparrot:": {
        "src": "/images/emojis/named/revertitparrot.gif",
        "alt": "Revertit parrot",
        "title": "Revertit parrot"
    },
    ":revolutionparrot:": {
        "src": "/images/emojis/named/revolutionparrot.gif",
        "alt": "Revolution parrot",
        "title": "Revolution parrot"
    },
    ":rightparrot:": {
        "src": "/images/emojis/named/rightparrot.gif",
        "alt": "Rightparrot",
        "title": "Rightparrot"
    },
    ":ripparrot:": {
        "src": "/images/emojis/named/ripparrot.gif",
        "alt": "Rip parrot",
        "title": "Rip parrot"
    },
    ":rotatingparrot:": {
        "src": "/images/emojis/named/rotatingparrot.gif",
        "alt": "Rotating parrot",
        "title": "Rotating parrot"
    },
    ":rubyparrot:": {
        "src": "/images/emojis/named/rubyparrot.gif",
        "alt": "Ruby parrot",
        "title": "Ruby parrot"
    },
    ":ryangoslingparrot:": {
        "src": "/images/emojis/named/ryangoslingparrot.gif",
        "alt": "Ryan gosling parrot",
        "title": "Ryan gosling parrot"
    },
    ":rythmicalparrot:": {
        "src": "/images/emojis/named/rythmicalparrot.gif",
        "alt": "Rythmical parrot",
        "title": "Rythmical parrot"
    },
    ":sadparrot:": {
        "src": "/images/emojis/named/sadparrot.gif",
        "alt": "Sad parrot",
        "title": "Sad parrot"
    },
    ":sadparrotcanada:": {
        "src": "/images/emojis/named/sadparrotcanada.gif",
        "alt": "Sadparrotcanada",
        "title": "Sadparrotcanada"
    },
    ":sambosa_parrot:": {
        "src": "/images/emojis/named/sambosa_parrot.gif",
        "alt": "Sambosa-parrot",
        "title": "Sambosa-parrot"
    },
    ":sassyparrot:": {
        "src": "/images/emojis/named/sassyparrot.gif",
        "alt": "Sassy parrot",
        "title": "Sassy parrot"
    },
    ":satanparrot:": {
        "src": "/images/emojis/named/satanparrot.gif",
        "alt": "Satanparrot",
        "title": "Satanparrot"
    },
    ":schiapparrot:": {
        "src": "/images/emojis/named/schiapparrot.gif",
        "alt": "Schiapparrot",
        "title": "Schiapparrot"
    },
    ":schnitzelparrot:": {
        "src": "/images/emojis/named/schnitzelparrot.gif",
        "alt": "Schnitzel parrot",
        "title": "Schnitzel parrot"
    },
    ":scienceparrot:": {
        "src": "/images/emojis/named/scienceparrot.gif",
        "alt": "Science parrot",
        "title": "Science parrot"
    },
    ":sean_parrot:": {
        "src": "/images/emojis/named/sean_parrot.gif",
        "alt": "Sean-parrot",
        "title": "Sean-parrot"
    },
    ":sherlockholmesparrot:": {
        "src": "/images/emojis/named/sherlockholmesparrot.gif",
        "alt": "Sherlock holmes parrot",
        "title": "Sherlock holmes parrot"
    },
    ":shipitfurtherparrot:": {
        "src": "/images/emojis/named/shipitfurtherparrot.gif",
        "alt": "Ship it further parrot",
        "title": "Ship it further parrot"
    },
    ":shipitparrot:": {
        "src": "/images/emojis/named/shipitparrot.gif",
        "alt": "Ship it parrot",
        "title": "Ship it parrot"
    },
    ":shortparrot:": {
        "src": "/images/emojis/named/shortparrot.gif",
        "alt": "Short parrot",
        "title": "Short parrot"
    },
    ":shufflefurtherparrot:": {
        "src": "/images/emojis/named/shufflefurtherparrot.gif",
        "alt": "Shuffle further parrot",
        "title": "Shuffle further parrot"
    },
    ":shuffleparrot:": {
        "src": "/images/emojis/named/shuffleparrot.gif",
        "alt": "Shuffle parrot",
        "title": "Shuffle parrot"
    },
    ":shufflepartyparrot:": {
        "src": "/images/emojis/named/shufflepartyparrot.gif",
        "alt": "Shuffle party parrot",
        "title": "Shuffle party parrot"
    },
    ":sintparrot:": {
        "src": "/images/emojis/named/sintparrot.gif",
        "alt": "Sintparrot",
        "title": "Sintparrot"
    },
    ":sithparrot:": {
        "src": "/images/emojis/named/sithparrot.gif",
        "alt": "Sith parrot",
        "title": "Sith parrot"
    },
    ":skiparrot:": {
        "src": "/images/emojis/named/skiparrot.gif",
        "alt": "Ski parrot",
        "title": "Ski parrot"
    },
    ":sleepingparrot:": {
        "src": "/images/emojis/named/sleepingparrot.gif",
        "alt": "Sleeping parrot",
        "title": "Sleeping parrot"
    },
    ":slomoparrot:": {
        "src": "/images/emojis/named/slomoparrot.gif",
        "alt": "Slomo parrot",
        "title": "Slomo parrot"
    },
    ":slowparrot:": {
        "src": "/images/emojis/named/slowparrot.gif",
        "alt": "Slow parrot",
        "title": "Slow parrot"
    },
    ":sneezyparrot:": {
        "src": "/images/emojis/named/sneezyparrot.gif",
        "alt": "Sneezyparrot",
        "title": "Sneezyparrot"
    },
    ":sombreroparrot:": {
        "src": "/images/emojis/named/sombreroparrot.gif",
        "alt": "Sombrero parrot",
        "title": "Sombrero parrot"
    },
    ":sovietparrot:": {
        "src": "/images/emojis/named/sovietparrot.gif",
        "alt": "Soviet parrot",
        "title": "Soviet parrot"
    },
    ":sovjetparrot:": {
        "src": "/images/emojis/named/sovjetparrot.gif",
        "alt": "Sovjet parrot",
        "title": "Sovjet parrot"
    },
    ":spinningparrot:": {
        "src": "/images/emojis/named/spinningparrot.gif",
        "alt": "Spinning parrot",
        "title": "Spinning parrot"
    },
    ":spookparrot:": {
        "src": "/images/emojis/named/spookparrot.gif",
        "alt": "Spookparrot",
        "title": "Spookparrot"
    },
    ":spookyparrot:": {
        "src": "/images/emojis/named/spookyparrot.png",
        "alt": "Spookyparrot",
        "title": "Spookyparrot"
    },
    ":spyparrot:": {
        "src": "/images/emojis/named/spyparrot.gif",
        "alt": "Spy parrot",
        "title": "Spy parrot"
    },
    ":stableparrot:": {
        "src": "/images/emojis/named/stableparrot.gif",
        "alt": "Stable parrot",
        "title": "Stable parrot"
    },
    ":stalkerparrot:": {
        "src": "/images/emojis/named/stalkerparrot.gif",
        "alt": "Stalker parrot",
        "title": "Stalker parrot"
    },
    ":staticparrot:": {
        "src": "/images/emojis/named/staticparrot.png",
        "alt": "Static parrot",
        "title": "Static parrot"
    },
    ":stayhomeparrot:": {
        "src": "/images/emojis/named/stayhomeparrot.gif",
        "alt": "Stay home parrot",
        "title": "Stay home parrot"
    },
    ":stayhomeparrotcloser:": {
        "src": "/images/emojis/named/stayhomeparrotcloser.gif",
        "alt": "Stay home parrot closer",
        "title": "Stay home parrot closer"
    },
    ":stayhomeparrotwindow:": {
        "src": "/images/emojis/named/stayhomeparrotwindow.gif",
        "alt": "Stay home parrot window",
        "title": "Stay home parrot window"
    },
    ":staytfhomeparrot:": {
        "src": "/images/emojis/named/staytfhomeparrot.gif",
        "alt": "Staytf home parrot",
        "title": "Staytf home parrot"
    },
    ":stubparrot:": {
        "src": "/images/emojis/named/stubparrot.gif",
        "alt": "Stub parrot",
        "title": "Stub parrot"
    },
    ":sunglassesparrot:": {
        "src": "/images/emojis/named/sunglassesparrot.gif",
        "alt": "Sunglasses parrot",
        "title": "Sunglasses parrot"
    },
    ":susparrot:": {
        "src": "/images/emojis/named/susparrot.gif",
        "alt": "Sus parrot",
        "title": "Sus parrot"
    },
    ":sushiparrot:": {
        "src": "/images/emojis/named/sushiparrot.gif",
        "alt": "Sushi parrot",
        "title": "Sushi parrot"
    },
    ":syncparrot:": {
        "src": "/images/emojis/named/syncparrot.gif",
        "alt": "Sync parrot",
        "title": "Sync parrot"
    },
    ":tacoparrot:": {
        "src": "/images/emojis/named/tacoparrot.gif",
        "alt": "Taco parrot",
        "title": "Taco parrot"
    },
    ":tea_parrot:": {
        "src": "/images/emojis/named/tea_parrot.gif",
        "alt": "Tea-parrot",
        "title": "Tea-parrot"
    },
    ":tenetparrot:": {
        "src": "/images/emojis/named/tenetparrot.gif",
        "alt": "Tenetparrot",
        "title": "Tenetparrot"
    },
    ":tennisparrot:": {
        "src": "/images/emojis/named/tennisparrot.gif",
        "alt": "Tennis parrot",
        "title": "Tennis parrot"
    },
    ":thailandparrot:": {
        "src": "/images/emojis/named/thailandparrot.gif",
        "alt": "Thailand parrot",
        "title": "Thailand parrot"
    },
    ":thegrokeparrot:": {
        "src": "/images/emojis/named/thegrokeparrot.gif",
        "alt": "Thegrokeparrot",
        "title": "Thegrokeparrot"
    },
    ":thisisfineparrot:": {
        "src": "/images/emojis/named/thisisfineparrot.gif",
        "alt": "Thisisfineparrot",
        "title": "Thisisfineparrot"
    },
    ":thumbsdownpartyparrot:": {
        "src": "/images/emojis/named/thumbsdownpartyparrot.gif",
        "alt": "Thumbs down party parrot",
        "title": "Thumbs down party parrot"
    },
    ":thumbsupparrot:": {
        "src": "/images/emojis/named/thumbsupparrot.gif",
        "alt": "Thumbs up parrot",
        "title": "Thumbs up parrot"
    },
    ":tiedyeparrot:": {
        "src": "/images/emojis/named/tiedyeparrot.gif",
        "alt": "Tie dye parrot",
        "title": "Tie dye parrot"
    },
    ":tinfoilhatparrot:": {
        "src": "/images/emojis/named/tinfoilhatparrot.gif",
        "alt": "Tinfoilhatparrot",
        "title": "Tinfoilhatparrot"
    },
    ":tpparrot:": {
        "src": "/images/emojis/named/tpparrot.gif",
        "alt": "Tp parrot",
        "title": "Tp parrot"
    },
    ":transparront:": {
        "src": "/images/emojis/named/transparront.gif",
        "alt": "Transparront",
        "title": "Transparront"
    },
    ":trans_parrot:": {
        "src": "/images/emojis/named/trans_parrot.gif",
        "alt": "Trans-parrot",
        "title": "Trans-parrot"
    },
    ":triggeredparrot:": {
        "src": "/images/emojis/named/triggeredparrot.gif",
        "alt": "Triggered parrot",
        "title": "Triggered parrot"
    },
    ":tripletsparrot:": {
        "src": "/images/emojis/named/tripletsparrot.gif",
        "alt": "Triplets parrot",
        "title": "Triplets parrot"
    },
    ":troll_parrot:": {
        "src": "/images/emojis/named/troll_parrot.gif",
        "alt": "Troll-parrot",
        "title": "Troll-parrot"
    },
    ":twinsparrot:": {
        "src": "/images/emojis/named/twinsparrot.gif",
        "alt": "Twins parrot",
        "title": "Twins parrot"
    },
    ":ultrafastparrot:": {
        "src": "/images/emojis/named/ultrafastparrot.gif",
        "alt": "Ultra fast parrot",
        "title": "Ultra fast parrot"
    },
    ":ultraslowparrot:": {
        "src": "/images/emojis/named/ultraslowparrot.gif",
        "alt": "Ultra slow parrot",
        "title": "Ultra slow parrot"
    },
    ":unicornparrot:": {
        "src": "/images/emojis/named/unicornparrot.gif",
        "alt": "Unicorn parrot",
        "title": "Unicorn parrot"
    },
    ":upvoteparrot:": {
        "src": "/images/emojis/named/upvoteparrot.gif",
        "alt": "Upvote parrot",
        "title": "Upvote parrot"
    },
    ":upvotepartyparrot:": {
        "src": "/images/emojis/named/upvotepartyparrot.gif",
        "alt": "Upvote party parrot",
        "title": "Upvote party parrot"
    },
    ":usaparrot:": {
        "src": "/images/emojis/named/usaparrot.gif",
        "alt": "Usa parrot",
        "title": "Usa parrot"
    },
    ":vaticanparrot:": {
        "src": "/images/emojis/named/vaticanparrot.gif",
        "alt": "Vatican parrot",
        "title": "Vatican parrot"
    },
    ":verticalparrot:": {
        "src": "/images/emojis/named/verticalparrot.gif",
        "alt": "Vertical parrot",
        "title": "Vertical parrot"
    },
    ":vikingparrot:": {
        "src": "/images/emojis/named/vikingparrot.gif",
        "alt": "Viking parrot",
        "title": "Viking parrot"
    },
    ":vimto_parrot:": {
        "src": "/images/emojis/named/vimto_parrot.gif",
        "alt": "Vimto-parrot",
        "title": "Vimto-parrot"
    },
    ":virusparrot:": {
        "src": "/images/emojis/named/virusparrot.png",
        "alt": "Virusparrot",
        "title": "Virusparrot"
    },
    ":vote_partyparrot:": {
        "src": "/images/emojis/named/vote_partyparrot.gif",
        "alt": "Vote-partyparrot",
        "title": "Vote-partyparrot"
    },
    ":voting_parrot:": {
        "src": "/images/emojis/named/voting_parrot.gif",
        "alt": "Voting-parrot",
        "title": "Voting-parrot"
    },
    ":wave1parrot:": {
        "src": "/images/emojis/named/wave1parrot.gif",
        "alt": "Wave 1 parrot",
        "title": "Wave 1 parrot"
    },
    ":wave2parrot:": {
        "src": "/images/emojis/named/wave2parrot.gif",
        "alt": "Wave 2 parrot",
        "title": "Wave 2 parrot"
    },
    ":wave3parrot:": {
        "src": "/images/emojis/named/wave3parrot.gif",
        "alt": "Wave 3 parrot",
        "title": "Wave 3 parrot"
    },
    ":wave4parrot:": {
        "src": "/images/emojis/named/wave4parrot.gif",
        "alt": "Wave 4 parrot",
        "title": "Wave 4 parrot"
    },
    ":wave5parrot:": {
        "src": "/images/emojis/named/wave5parrot.gif",
        "alt": "Wave 5 parrot",
        "title": "Wave 5 parrot"
    },
    ":wave6parrot:": {
        "src": "/images/emojis/named/wave6parrot.gif",
        "alt": "Wave 6 parrot",
        "title": "Wave 6 parrot"
    },
    ":wave7parrot:": {
        "src": "/images/emojis/named/wave7parrot.gif",
        "alt": "Wave 7 parrot",
        "title": "Wave 7 parrot"
    },
    ":wave8parrot:": {
        "src": "/images/emojis/named/wave8parrot.gif",
        "alt": "Wave 8 parrot",
        "title": "Wave 8 parrot"
    },
    ":wave9parrot:": {
        "src": "/images/emojis/named/wave9parrot.gif",
        "alt": "Wave 9 parrot",
        "title": "Wave 9 parrot"
    },
    ":wendyparrot:": {
        "src": "/images/emojis/named/wendyparrot.gif",
        "alt": "Wendy parrot",
        "title": "Wendy parrot"
    },
    ":wfhparrot:": {
        "src": "/images/emojis/named/wfhparrot.gif",
        "alt": "Wfh parrot",
        "title": "Wfh parrot"
    },
    ":whitewalkerparrot:": {
        "src": "/images/emojis/named/whitewalkerparrot.gif",
        "alt": "White walker parrot",
        "title": "White walker parrot"
    },
    ":wildparrot:": {
        "src": "/images/emojis/named/wildparrot.gif",
        "alt": "Wildparrot",
        "title": "Wildparrot"
    },
    ":wineparrot:": {
        "src": "/images/emojis/named/wineparrot.gif",
        "alt": "Wine parrot",
        "title": "Wine parrot"
    },
    ":witnessprotectionparrot:": {
        "src": "/images/emojis/named/witnessprotectionparrot.gif",
        "alt": "Witness protection parrot",
        "title": "Witness protection parrot"
    },
    ":yosemitesamparrot:": {
        "src": "/images/emojis/named/yosemitesamparrot.gif",
        "alt": "Yosemite sam parrot",
        "title": "Yosemite sam parrot"
    },
    ":zakarum_parrot:": {
        "src": "/images/emojis/named/zakarum_parrot.gif",
        "alt": "Zakarum-parrot",
        "title": "Zakarum-parrot"
    },
    ":zoukparrot:": {
        "src": "/images/emojis/named/zoukparrot.gif",
        "alt": "Zouk parrot",
        "title": "Zouk parrot"
    }
};

// Regex that is used to get the exact index of emoji keys in a string
export const indexingRegex = /:[\w-]*?:|#\)|#-\)|%\)|%-\)|\(y\)|\*\)|\*-\)|\*\\0\/\*|\*\\O\/\*|:#|:-#|:\$|:\(|:'\(|:'-\(|:-\(|':\(|':-\(|:\)|:'\)|:'-\)|:-\)|':\)|':-\)|:\*|:-\*|:-\.|:\/|:-\/|:@|:\[|:-\[|:\\|:\]|:\^\*|:b|:-b|:D|:-D|':D|':-D|:L|:O|:-O|:P|:-P|:Þ|:-Þ|:X|:-X|;\(|;-\(|;\)|;-\)|;\]|;-\]|;\^\)|;D|\\0\/|\\O\/|-_-|-__-|-___-|<\/3|<3|=#|=\$|=\(|'=\(|=\)|'=\)|=\*|=\/|=\\|=\]|=D|'=D|=L|=P|=X|>\.<|>:\(|>:-\(|>:\)|>:-\)|>:\/|>:\[|>:\\|>:O|>:P|>;\)|>=\)|0:\)|0:-\)|0:3|0:-3|0;-\)|0;\^\)|8\)|8-\)|8-D|B\)|B-\)|B-D|D:|O:\)|O:-\)|O:3|O:-3|O;-\)|O_O|O=\)|X\)|X-\)|X-P/i;

export const emojiRegex = /<object[^>]*>.*?<\/object>|<span[^>]*>.*?<\/span>|<(?:object|embed|svg|img|div|span|p|a)[^>]*>|((\s|^)(:[\w-]*?:|#\)|#-\)|%\)|%-\)|\(y\)|\*\)|\*-\)|\*\\0\/\*|\*\\O\/\*|:#|:-#|:\$|:\(|:'\(|:'-\(|:-\(|':\(|':-\(|:\)|:'\)|:'-\)|:-\)|':\)|':-\)|:\*|:-\*|:-\.|:\/|:-\/|:@|:\[|:-\[|:\\|:\]|:\^\*|:b|:-b|:D|:-D|':D|':-D|:L|:O|:-O|:P|:-P|:Þ|:-Þ|:X|:-X|;\(|;-\(|;\)|;-\)|;\]|;-\]|;\^\)|;D|\\0\/|\\O\/|-_-|-__-|-___-|<\/3|<3|=#|=\$|=\(|'=\(|=\)|'=\)|=\*|=\/|=\\|=\]|=D|'=D|=L|=P|=X|>\.<|>:\(|>:-\(|>:\)|>:-\)|>:\/|>:\[|>:\\|>:O|>:P|>;\)|>=\)|0:\)|0:-\)|0:3|0:-3|0;-\)|0;\^\)|8\)|8-\)|8-D|B\)|B-\)|B-D|D:|O:\)|O:-\)|O:3|O:-3|O;-\)|O_O|O=\)|X\)|X-\)|X-P)(?=\s|$|[!,.?]))/i;