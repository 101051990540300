import React, {useState} from 'react';
import {Alert, Button} from 'reactstrap';
import {PickedFileInfo} from './useGoogleDrivePicker.ts';
import {useMutation} from 'react-query';
import {FileDataModel} from '../../../api/types.ts';
import unknownErrorToString from '../../../lib/unknownErrorToString.ts';
import useUploadGoogleDriveFile, {
    GoogleDriveFileUploadModel
} from '../../../api/google_drive/useUploadGoogleDriveFile.ts';
import ButtonAsync from '../../shared/ButtonAsync.tsx';

interface ConfirmGoogleDriveUploadProps {
    file: PickedFileInfo;
    onClose: () => void;
    onFilesUploaded: (files: FileDataModel[]) => void;
    cancel: () => void;
    specificContainerName?: string | null;
    folderPath?: string | null;
}

const ConfirmGoogleDriveUpload: React.FC<ConfirmGoogleDriveUploadProps> = ({
    file,
    onClose,
    onFilesUploaded,
    cancel,
    specificContainerName,
    folderPath
}) => {
    const uploadGoogleDriveFile = useUploadGoogleDriveFile();
    const [backendError, setBackendError] = useState<string | null>(null);

    const mutationGoogleDrive = useMutation(uploadGoogleDriveFile, {
        onSuccess: files => {
            files && onFilesUploaded(files);
            onClose();
        },
        onError: (error: unknown) => {
            setBackendError(unknownErrorToString(error));
        }
    });

    const onConfirm = async () => {
        const googleDriveFileUpload: GoogleDriveFileUploadModel = {
            accessToken: file.accessToken!,
            containerName: specificContainerName,
            folderPath,
            id: file.id,
            name: file.name
        };

        await mutationGoogleDrive.mutateAsync(googleDriveFileUpload);
    };

    return (
        <>
            <p>{file.name}</p>
            {backendError && <Alert color="danger mt-3">{backendError}</Alert>}

            <ButtonAsync
                color="primary"
                type="submit"
                block
                className="mt-3"
                onClick={onConfirm}
            >
                Confirm file upload
            </ButtonAsync>
            <Button
                color="secondary"
                type="submit"
                block
                className="mt-3"
                onClick={cancel}
            >
                Cancel
            </Button>
        </>
    );
};

export default ConfirmGoogleDriveUpload;
