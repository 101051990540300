import React from 'react';
import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import useMixById from '../../api/mixes/useMixById.ts';
import {useQuery} from 'react-query';
import emptyMix from '../projects/emptyMix.ts';
import ProjectTabPane from '../projects/tabs/ProjectTabPane.tsx';
import {ProjectDataIndex} from '../../api/types.ts';

interface GroupHomeProjectTabProps {
    homeMixId: string;
}

const GroupHomeProjectTab: React.FC<GroupHomeProjectTabProps> = ({
    homeMixId
}) => {
    const {loggedInUserId} = useAuthStateManager();

    const fetchProject = useMixById({
        mixId: homeMixId
    });
    const {data: mixData} = useQuery(
        ['project', homeMixId, loggedInUserId],
        fetchProject
    );

    const mix = mixData ?? emptyMix;

    return (
        <div className="container-fluid custom-container mt-5">
            <div className="row">
                <div className="col-12">
                    <div className="content-card">
                        <div className="card-content p-0 m-0">
                            {mix.tabs && (
                                <ProjectTabPane
                                    mix={mix}
                                    tab={mix.tabs.tab1}
                                    dataIndex={ProjectDataIndex.Tab1}
                                    readOnly={true}
                                    reactionsEnabled={false}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GroupHomeProjectTab;
