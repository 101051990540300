import {PagedResult} from '../../api/types.ts';
import {
    Pagination as ReactstrapPagination,
    PaginationItem,
    PaginationLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import {useState} from 'react';

interface PagerProps {
    pagedResult: PagedResult<unknown>;
    onPageSelected: (page: number) => void;
    onPerPageSelected?: (perPage: number) => void;
}

const Pager: React.FC<PagerProps> = ({
    pagedResult,
    onPageSelected,
    onPerPageSelected
}) => {
    const count = pagedResult.count;
    const perPage = pagedResult.perPage;
    const page = pagedResult.page;

    const setPage = onPageSelected;

    const totalPages = Math.ceil(count / perPage);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const perPageOptions = [10, 20, 50, 100];

    let startPage = Math.max(page - 2, 1);
    const endPage = Math.min(startPage + 4, totalPages);
    if (endPage - startPage < 4) {
        startPage = Math.max(endPage - 4, 1);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <ReactstrapPagination>
                <PaginationItem disabled={page === 1}>
                    <PaginationLink first onClick={() => handlePageChange(1)} />
                </PaginationItem>
                <PaginationItem disabled={page === 1}>
                    <PaginationLink
                        previous
                        onClick={() => handlePageChange(page - 1)}
                    />
                </PaginationItem>
                {pageNumbers.map(num => (
                    <PaginationItem key={num} active={num === page}>
                        <PaginationLink onClick={() => handlePageChange(num)}>
                            {num}
                        </PaginationLink>
                    </PaginationItem>
                ))}
                <PaginationItem disabled={page === totalPages}>
                    <PaginationLink
                        next
                        onClick={() => handlePageChange(page + 1)}
                    />
                </PaginationItem>
                <PaginationItem disabled={page === totalPages}>
                    <PaginationLink
                        last
                        onClick={() => handlePageChange(totalPages)}
                    />
                </PaginationItem>
            </ReactstrapPagination>
            {onPerPageSelected && (
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle caret>
                        Records per page: {perPage}
                    </DropdownToggle>
                    <DropdownMenu>
                        {perPageOptions.map(option => (
                            <DropdownItem
                                key={option}
                                onClick={() => {
                                    onPerPageSelected(option);
                                    setPage(1); // Reset page to 1 when perPage changes
                                }}
                            >
                                {option}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            )}
        </div>
    );
};

export default Pager;
