import {ProjectDataIndex} from '../types';
import {usePost} from '../useHttpHooks';

export type ImageUrlResult = {
    imageUrl: string;
};

export type CreateImageFromPromptCommand = {
    id: string;
    dataIndex: ProjectDataIndex;
    useAsThumbnail: boolean;
    prompt: string;
    timezoneOffset: number;
};

const useCreateImageFromPrompt = () =>
    usePost<CreateImageFromPromptCommand, ImageUrlResult>(
        `/api/assistant/create-image-from-prompt`,
        {
            errorMessage: 'POST api/assistant/create-image-from-prompt failed'
        }
    );

export default useCreateImageFromPrompt;
