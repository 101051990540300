// NOTE: From open-source gist https://gist.github.com/jimtaylor1974/29c9e70bb102680a6cc4bb4ecfa38628
import {useCallback} from 'react';
import {useAuthStateManager} from '../hooks/useAuthStateManager';
import ApiError from './ApiError';
import {UnknownObject} from '../types.ts';
import useClientConfig from '../hooks/useClientConfig.tsx';
import joinPath from '../lib/joinPath.ts';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface RequestOptions {
    errorMessage?: string;
    additionalHeaders?: HeadersInit;
    convertToFormData?: boolean;
}

const createFormDataFromObject = <T>(data: T | FormData): FormData => {
    // If the data is already FormData, return it as is
    if (data instanceof FormData) {
        return data;
    }

    // Create a new FormData object
    const formData = new FormData();

    // If the data is not of type FormData, but is an object, convert it to FormData
    if (typeof data === 'object' && data !== null) {
        Object.entries(data as UnknownObject).forEach(([key, value]) => {
            if (value instanceof File) {
                // Append File objects directly
                formData.append(key, value);
            } else if (value !== null) {
                // Append stringified version of the value
                formData.append(key, JSON.stringify(value));
            }
            // Null values are ignored
        });
    }

    return formData;
};

const prepareHeadersAndBody = <T>(
    data: T | FormData,
    method: HttpMethod,
    options: RequestOptions,
    accessToken: string
) => {
    const headers = new Headers(options.additionalHeaders);

    let body;
    let convertedData = data;

    if (options.convertToFormData) {
        convertedData = createFormDataFromObject(data);
    }

    if (!(convertedData instanceof FormData)) {
        headers.set('Content-Type', 'application/json');
        body = JSON.stringify(convertedData);
    } else {
        body = convertedData;
    }

    if (accessToken && accessToken != '') {
        headers.set('Authorization', `bearer ${accessToken}`);
    }

    return {
        headers,
        body: method === 'GET' ? undefined : body
    };
};

export const handleHttpError = async (
    method: HttpMethod,
    response: Response,
    options: RequestOptions
) => {
    let responseData;

    if (response.status === 401) {
        const authHeader = response.headers.get('Www-Authenticate');

        if (authHeader) {
            const authHeaderParts = authHeader.split(',');
            const error = authHeaderParts.find((part: string | string[]) =>
                part.includes('error=')
            );
            const errorDescription = authHeaderParts.find(
                (part: string | string[]) => part.includes('error_description=')
            );

            responseData = `${error?.split('=')[1].trim()}, ${errorDescription
                ?.split('=')[1]
                .trim()}`;
        }
    } else {
        // Handle other response statuses
        const responseClone = response.clone();
        try {
            responseData = await response.json();
        } catch (error) {
            responseData = await responseClone.text();
        }
    }

    throw new ApiError(
        responseData?.message ||
            options.errorMessage ||
            `Error during ${method} request`,
        response.status,
        responseData
    );
};

const useHttpMethod = <T = unknown, R = unknown>(
    method: HttpMethod,
    endpoint: string,
    options: RequestOptions
) => {
    const {apiServiceBaseUri} = useClientConfig();
    const {getAccessToken} = useAuthStateManager();

    const request = useCallback(
        async (data: T | FormData = {} as T): Promise<R | null> => {
            const accessToken = await getAccessToken();
            const {headers, body} = prepareHeadersAndBody(
                data,
                method,
                options,
                accessToken
            );

            const requestOptions: RequestInit = {method, headers, body};
            const response = await fetch(
                joinPath(apiServiceBaseUri, endpoint),
                requestOptions
            );

            if (!response.ok) {
                await handleHttpError(method, response, options);
            }

            return response.json() as Promise<R>;
        },
        [method, endpoint, options, getAccessToken]
    );

    return request;
};

const useHttpMethodReturnLocationOnly = <T = unknown>(
    method: HttpMethod,
    endpoint: string,
    options: RequestOptions
) => {
    const {apiServiceBaseUri} = useClientConfig();
    const {getAccessToken} = useAuthStateManager();

    const request = useCallback(
        async (data: T | FormData = {} as T): Promise<string | null> => {
            const accessToken = await getAccessToken();
            const {headers, body} = prepareHeadersAndBody(
                data,
                method,
                options,
                accessToken
            );

            const requestOptions: RequestInit = {method, headers, body};
            const response = await fetch(
                joinPath(apiServiceBaseUri, endpoint),
                requestOptions
            );

            if (!response.ok) {
                await handleHttpError(method, response, options);
            }

            return response.headers.get('Location');
        },
        [method, endpoint, options, getAccessToken]
    );

    return request;
};

export const useGet = <R = unknown>(
    endpoint: string,
    options: RequestOptions = {}
) => useHttpMethod<unknown, R>('GET', endpoint, options);

export const usePost = <T = unknown, R = unknown>(
    endpoint: string,
    options: RequestOptions = {}
) => useHttpMethod<T, R>('POST', endpoint, options);

export const usePut = <T = unknown, R = unknown>(
    endpoint: string,
    options: RequestOptions = {}
) => useHttpMethod<T, R>('PUT', endpoint, options);

export const usePatch = <T = unknown, R = unknown>(
    endpoint: string,
    options: RequestOptions = {}
) => useHttpMethod<T, R>('PATCH', endpoint, options);

export const useDelete = <R = unknown>(
    endpoint: string,
    options: RequestOptions = {}
) => useHttpMethod<unknown, R>('DELETE', endpoint, options);

export const useHttpPostReturnLocationOnly = <T = unknown>(
    endpoint: string,
    options: RequestOptions = {}
) => useHttpMethodReturnLocationOnly<T>('POST', endpoint, options);

export const useHttpPutReturnLocationOnly = <T = unknown>(
    endpoint: string,
    options: RequestOptions = {}
) => useHttpMethodReturnLocationOnly<T>('PUT', endpoint, options);
