import {UpdateThumbnailResult} from '../types';
import {usePost} from './../useHttpHooks';

export type UpdateCollectionThumbnailCommand = {
    collectionId: string;
    src: string;
};

const useUpdateCollectionThumbnail = () =>
    usePost<UpdateCollectionThumbnailCommand, UpdateThumbnailResult>(
        `/api/thumbnails/update-collection-thumbnail`,
        {errorMessage: 'POST api/thumbnails/update-collection-thumbnail failed'}
    );

export default useUpdateCollectionThumbnail;
