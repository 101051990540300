import {format, parseISO} from 'date-fns';

export default function formatDateTime(
    dateTime: Date | string,
    formatString?: string | null
) {
    const date = typeof dateTime === 'string' ? parseISO(dateTime) : dateTime;

    if (!formatString) {
        return format(date, 'Pp');
    }

    return format(date, formatString);
}
