export function isNotNullOrWhiteSpace(
    str: string | null | undefined
): str is string {
    if (str == null) {
        return false;
    }

    return str.trim().length > 0;
}

export default function isNullOrWhiteSpace(
    str: string | null | undefined
): str is string {
    if (str == null) {
        return true;
    }

    return str.trim().length === 0;
}
