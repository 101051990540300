import React, {useEffect, useState} from 'react';
import emojisByEmojiCode from './emojisByEmojiCode.ts';
import {Emoji} from './types.ts';

interface EmojiImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    emojiCode: string;
    title?: string;
}

const EmojiImage: React.FC<EmojiImageProps> = ({
    emojiCode,
    title,
    ...imgProps
}) => {
    const [emoji, setEmoji] = useState<Emoji | null>(null);

    useEffect(() => {
        if (emojiCode in emojisByEmojiCode) {
            setEmoji(emojisByEmojiCode[emojiCode]);
        }
    }, [emojiCode]);

    const getTitle = () => {
        return title || emoji?.name;
    };

    return emoji ? (
        <img
            className="emoji"
            alt={emoji.alt}
            title={getTitle()}
            src={emoji.source}
            {...imgProps}
        />
    ) : null;
};

export default EmojiImage;
