import {MixModel, ProjectDataIndex, ProjectTab} from '../../api/types.ts';
import {Button, Input} from 'reactstrap';
import React, {useState} from 'react';
import CustomContainer from '../shared/CustomContainer.tsx';
import MxCard from '../shared/MxCard.tsx';
import EmbeddingInstructions from '../shared/EmbeddingInstructions.tsx';
import useEmbeddedWebPageCheck from '../../hooks/useEmbeddedWebPageCheck.ts';

interface EditWebsiteTabSrcProps {
    project: MixModel;
    saveProject?: (project: MixModel) => Promise<void>;
    tab: ProjectTab;
    dataIndex: ProjectDataIndex;
}

const EditWebsiteTabSrc: React.FC<EditWebsiteTabSrcProps> = ({
    project,
    saveProject,
    tab,
    dataIndex
}) => {
    const [src, setSrc] = useState(tab.src);

    const onApply = () => {
        if (!saveProject || !project.tabs) {
            return;
        }

        switch (dataIndex) {
            case ProjectDataIndex.Tab1:
                saveProject({
                    ...project,
                    tabs: {...project.tabs, tab1: {...tab, src: src}}
                });
                break;
            case ProjectDataIndex.Tab2:
                saveProject({
                    ...project,
                    tabs: {...project.tabs, tab2: {...tab, src: src}}
                });
                break;
            case ProjectDataIndex.Tab3:
                saveProject({
                    ...project,
                    tabs: {...project.tabs, tab3: {...tab, src: src}}
                });
                break;
            case ProjectDataIndex.Tab4:
                saveProject({
                    ...project,
                    tabs: {...project.tabs, tab4: {...tab, src: src}}
                });
                break;
            case ProjectDataIndex.Tab5:
                saveProject({
                    ...project,
                    tabs: {...project.tabs, tab5: {...tab, src: src}}
                });
                break;
        }
    };

    const {isValid, message} = useEmbeddedWebPageCheck(src);

    return (
        <>
            <CustomContainer className="my-5">
                <MxCard>
                    <EmbeddingInstructions />
                    <label htmlFor="Src" className="form-label">
                        URL
                    </label>
                    <Input
                        id="Src"
                        value={src ?? ''}
                        onChange={event => setSrc(event.target.value)}
                    />

                    {!isValid && message && (
                        <div
                            className="text-danger small"
                            style={{color: 'red'}}
                        >
                            {message}
                        </div>
                    )}

                    <div className="d-flex justify-content-end mt-3">
                        <Button
                            color="primary"
                            onClick={onApply}
                            disabled={!isValid}
                        >
                            Apply
                        </Button>
                    </div>
                </MxCard>
            </CustomContainer>
        </>
    );
};

export default EditWebsiteTabSrc;
