import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {$generateHtmlFromNodes} from '@lexical/html';

interface Props {
    htmlChanged: (html: string) => void;
}

const HtmlOnChangePlugin = ({htmlChanged}: Props) => {
    const [editor] = useLexicalComposerContext();

    return (
        <OnChangePlugin
            onChange={editorState => {
                editorState.read(() => {
                    htmlChanged($generateHtmlFromNodes(editor));
                });
            }}
            ignoreSelectionChange
        />
    );
};

export default HtmlOnChangePlugin;
