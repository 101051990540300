import React, {KeyboardEvent, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import {ReactStateSetter} from '../../types.ts';

interface SearchProps {
    searchText: string;
    setSearchText: ReactStateSetter<string>;
    cssClass?: string;
}

const Search: React.FC<SearchProps> = ({
    cssClass,
    searchText,
    setSearchText
}) => {
    const [internalSearch, setInternalSearch] = useState<string>(searchText);

    const searchIfEnter = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            setSearchText(internalSearch);
        }
    };

    const searchAsync = () => {
        setSearchText(internalSearch);
    };

    const clearAsync = () => {
        setInternalSearch('');
        setSearchText('');
    };

    return (
        <div className={`search-holder ${cssClass}`}>
            <div className="basic-search-holder">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    maxLength={512}
                    aria-label="Search"
                    aria-describedby="SearchButton"
                    value={internalSearch}
                    onChange={e => setInternalSearch(e.target.value)}
                    onKeyDown={searchIfEnter}
                />

                <button
                    className="btn btn-primary search-icon"
                    type="button"
                    id="SearchButton"
                    onClick={searchAsync}
                >
                    <FontAwesomeIcon icon={faSearch} aria-hidden="true" />
                    <span className="visually-hidden">Search</span>
                </button>
                <button
                    className="btn action-link search-clear"
                    type="button"
                    id="ClearSearch"
                    onClick={clearAsync}
                >
                    <FontAwesomeIcon icon={faTimes} aria-hidden="true" />
                    <span className="visually-hidden">Clear</span>
                </button>
            </div>
        </div>
    );
};

export default Search;
