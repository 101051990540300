import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRedo} from '@fortawesome/free-solid-svg-icons';
import ImageUpload from './ImageUpload';
import createThumbnail from '../../lib/createThumbnail.js';
import {Button, FormGroup, Input, Label, Spinner} from 'reactstrap';
import {ProjectDataIndex} from '../../api/types.ts';
import useImageCreator from '../assistant/useImageCreator.ts';

interface ThumbnailEditorProps {
    src?: string;
    readOnly?: boolean;
    onThumbnailChanged?: (thumbnail: string) => void;
    id: string | null;
}

const ThumbnailEditor: React.FC<ThumbnailEditorProps> = ({
    src,
    readOnly,
    onThumbnailChanged,
    id
}) => {
    const [thumbnail, setThumbnail] = useState<string | undefined>(src);
    const [prompt, setPrompt] = useState('');
    const {imageCount, generateImage, dailyLimit, isGeneratingImage} =
        useImageCreator();

    const refreshThumbnail = () => {
        const newThumbnail = createThumbnail();
        setThumbnail(newThumbnail);
        if (onThumbnailChanged) {
            onThumbnailChanged(newThumbnail);
        }
    };

    useEffect(() => {
        setThumbnail(src);
    }, [src]);

    const handleImageCreator = async () => {
        if (id === null) return;

        const imageUrl = await generateImage(
            prompt,
            id,
            ProjectDataIndex.Zero,
            true
        );
        if (imageUrl) {
            setThumbnail(imageUrl);
            if (onThumbnailChanged) {
                onThumbnailChanged(imageUrl);
            }
        }
        setPrompt('');
    };

    const canUseAiGenerator = id !== null && !readOnly;

    return (
        <div className="thumbnail-editor">
            {thumbnail && (
                <div>
                    <img
                        src={thumbnail}
                        crossOrigin="anonymous"
                        alt="Thumbnail"
                    />
                </div>
            )}
            {!readOnly && (
                <>
                    <div className="mt-3">
                        <ImageUpload onThumbnailChanged={onThumbnailChanged} />
                    </div>
                    <div className="mt-3">
                        <button
                            className="btn btn-icon btn-outline-primary"
                            onClick={refreshThumbnail}
                        >
                            <FontAwesomeIcon icon={faRedo} /> Make thumbnail
                        </button>
                    </div>
                    {canUseAiGenerator && (
                        <div className="mt-3">
                            <FormGroup>
                                <Label for="imagePrompt">
                                    AI Image Generator
                                </Label>
                                <Input
                                    type="textarea"
                                    id="imagePrompt"
                                    value={prompt}
                                    onChange={e => setPrompt(e.target.value)}
                                    placeholder="Describe the image you want to generate..."
                                />
                            </FormGroup>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <div>
                                    <strong>Images generated today:</strong>{' '}
                                    {imageCount}/
                                    {dailyLimit === 2147483647
                                        ? 'unlimited'
                                        : dailyLimit}
                                </div>
                                {isGeneratingImage && (
                                    <div className="d-flex align-items-center">
                                        <Spinner
                                            type="grow"
                                            size="sm"
                                            color="primary"
                                            className="me-2"
                                        />
                                        <span>Generating image...</span>
                                    </div>
                                )}
                                <Button
                                    type="button"
                                    color="primary"
                                    onClick={handleImageCreator}
                                    disabled={
                                        imageCount >= dailyLimit ||
                                        prompt.trim() === '' ||
                                        isGeneratingImage
                                    }
                                >
                                    Generate Thumbnail
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ThumbnailEditor;
