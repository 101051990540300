import {EmptyResult, GroupMemberInfoAnswerModel} from '../types';
import {usePost} from './../useHttpHooks';

export type UpdateGroupMemberInfoCommand = {
    collectionId: string;
    userId: string;
    answers: GroupMemberInfoAnswerModel[];
};

const useUpdateGroupMemberInfo = () =>
    usePost<UpdateGroupMemberInfoCommand, EmptyResult>(
        `/api/groups/update-group-member-info`,
        {errorMessage: 'POST api/groups/update-group-member-info failed'}
    );

export default useUpdateGroupMemberInfo;
