import {EmptyResult, IOrdinalPosition} from '../types';
import {usePost} from './../useHttpHooks';

export type UpdateOrdinalPositionsCommand = {
    tableName: string;
    orderedEntities: IOrdinalPosition[];
};

const useUpdateOrdinalPositions = () =>
    usePost<UpdateOrdinalPositionsCommand, EmptyResult>(
        `/api/shared/update-ordinal-positions`,
        {errorMessage: 'POST api/shared/update-ordinal-positions failed'}
    );

export default useUpdateOrdinalPositions;
