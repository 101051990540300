import React, {useState} from 'react';
import {Vector3} from './types.ts';
import {FormGroup, Label, Input, Button} from 'reactstrap';
import SpatialCoordinateEditor from './SpatialCoordinateEditor.tsx';

interface LatheEditorProps {
    shape: Vector3[];
    segments?: number;
    cap?: number;
    onShapeChange: (shape: Vector3[]) => void;
    onSegmentsChange: (segments: number | undefined) => void;
    onCapChange: (cap: number | undefined) => void;
}

const LatheEditor: React.FC<LatheEditorProps> = ({
    shape,
    segments,
    cap,
    onShapeChange,
    onSegmentsChange,
    onCapChange
}) => {
    const [currentShape, setCurrentShape] = useState<Vector3[]>(shape);

    const handleSegmentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        onSegmentsChange(isNaN(value) ? undefined : value);
    };

    const handleCapChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        onCapChange(isNaN(value) ? undefined : value);
    };

    const handleShapeChange = (index: number, coordinate: Vector3) => {
        const updatedShape = [...currentShape];
        updatedShape[index] = coordinate;
        setCurrentShape(updatedShape);
    };

    const addShapePoint = () => {
        const newPoint: Vector3 = {x: 0, y: 0, z: 0};
        setCurrentShape([...currentShape, newPoint]);
    };

    const removeShapePoint = (index: number) => {
        const updatedShape = currentShape.filter((_, i) => i !== index);
        setCurrentShape(updatedShape);
    };

    const applyShapeChanges = () => {
        onShapeChange(currentShape);
    };

    return (
        <>
            <FormGroup>
                <Label>Segments:</Label>
                <Input
                    type="number"
                    step="1"
                    value={segments ?? ''}
                    onChange={handleSegmentsChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Cap:</Label>
                <Input
                    type="number"
                    step="1"
                    value={cap ?? ''}
                    onChange={handleCapChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Shape:</Label>
                {currentShape.map((point, index) => (
                    <div key={index}>
                        <SpatialCoordinateEditor
                            label={`Point ${index + 1}`}
                            coordinate={point}
                            onChange={coordinate =>
                                handleShapeChange(index, coordinate)
                            }
                        />
                        <Button
                            color="danger"
                            onClick={() => removeShapePoint(index)}
                        >
                            Remove Point
                        </Button>
                    </div>
                ))}
                <Button color="primary" onClick={addShapePoint}>
                    Add Point
                </Button>
                <Button color="success" onClick={applyShapeChanges}>
                    Apply Shape Changes
                </Button>
            </FormGroup>
        </>
    );
};

export default LatheEditor;
