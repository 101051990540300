import {Editor, useMonaco} from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import {useCallback, useEffect} from 'react';
import baseUri from '../../api/baseUri.ts';

export class TypeDefinitionsUrls {
    static readonly ArVr: string = `${baseUri}/types/api.txt`;
    static readonly Scene: string = `${baseUri}/types/scene/api.txt`;
}

interface CodeEditorProps {
    language?: string;
    value: string;
    onChange: (value: string) => void;
    onSave?: () => void;
    typeDefinitionsUrl?: string;
}

const CodeEditor: React.FC<CodeEditorProps> = ({
    language = 'javascript',
    value,
    onChange,
    onSave,
    typeDefinitionsUrl
}) => {
    const monacoInstance = useMonaco();

    useEffect(() => {
        if (monacoInstance) {
            // eslint-disable-next-line no-inner-declarations
            function initializeMonaco(monacoInstance: typeof monaco) {
                monacoInstance.languages.typescript.javascriptDefaults.setDiagnosticsOptions(
                    {
                        noSemanticValidation: true,
                        noSyntaxValidation: false
                    }
                );

                monacoInstance.languages.typescript.javascriptDefaults.setCompilerOptions(
                    {
                        target: monacoInstance.languages.typescript.ScriptTarget
                            .ES2020,
                        allowNonTsExtensions: true
                    }
                );

                if (typeDefinitionsUrl) {
                    fetch(typeDefinitionsUrl)
                        .then(response => response.text())
                        .then(libSource => {
                            try {
                                const libUri = 'ts:filename/api.d.ts';
                                monacoInstance.languages.typescript.javascriptDefaults.addExtraLib(
                                    libSource,
                                    libUri
                                );
                                monacoInstance.editor.createModel(
                                    libSource,
                                    'typescript',
                                    monacoInstance.Uri.parse(libUri)
                                );
                            } catch (e) {
                                console.warn('Caught error ...');
                                console.error(e);
                            }
                        });
                }
            }

            initializeMonaco(monacoInstance);
        }
    }, [monacoInstance]);

    function handleEditorChange(value: string | undefined) {
        onChange(value ?? '');
    }

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (
                onSave &&
                (event.ctrlKey || event.metaKey) &&
                event.key === 's'
            ) {
                event.preventDefault();
                onSave();
            }
        },
        [onSave]
    );

    const options: monaco.editor.IStandaloneEditorConstructionOptions = {
        selectOnLineNumbers: true,
        automaticLayout: true,
        theme: 'vs-dark',
        dimension: {
            height: 500,
            width: 500
        }
    };

    return (
        <div onKeyDown={handleKeyDown}>
            <Editor
                height="70vh"
                defaultLanguage={language}
                value={value}
                onChange={handleEditorChange}
                theme="vs-dark"
                options={options}
            />
        </div>
    );
};

export default CodeEditor;
