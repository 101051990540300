import React, {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {CollectionModel, FileDataModel, Selection} from '../../api/types.ts';
import FileFormats, {FileFormat} from '../../lib/FileFormats.ts';
import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import useGetUserCollections from '../../api/collections/useGetUserCollections.ts';
import useGetFiles from '../../api/uploads/useGetFiles.ts';
import SelectCollection from './SelectCollection.tsx';
import AssetSearch from './AssetSearch.tsx';
import SpinnerIfLoading from '../shared/SpinnerIfLoading.tsx';
import AssetSelectorDirectory from './AssetSelectorDirectory.tsx';
import {Spinner} from 'reactstrap';
import RootDirectoryNames from './RootDirectoryNames.ts';
import filterDirectory from './filterDirectory.ts';
import isNullOrUndefined from '../../lib/isNullOrUndefined.ts';

interface AssetSelectorProps {
    formats?: FileFormat;
    selection: Selection;
    fileSelectionChanged: (file: FileDataModel) => void;
    fileIsSelected: (fileId: string) => boolean;
    specificContainerName?: string | null;
}

const AssetSelector: React.FC<AssetSelectorProps> = ({
    formats,
    selection,
    fileSelectionChanged,
    fileIsSelected,
    specificContainerName
}) => {
    const {loggedInUserId, authData} = useAuthStateManager();
    const [containerName, setContainerName] = useState(
        specificContainerName ?? authData.container_name ?? ''
    );
    const fetchFiles = useGetFiles({containerName, folderPath: null});
    const {
        data: rootDirectory,
        isLoading,
        isError
    } = useQuery(['files', containerName], fetchFiles);

    const [searchText, setSearchText] = useState('');

    const [userCollections, setUserCollections] = useState<CollectionModel[]>(
        []
    );

    const fetchUserCollections = useGetUserCollections();

    const {data: userCollectionsData} = useQuery(
        ['userCollections', loggedInUserId],
        fetchUserCollections
    );

    useEffect(() => {
        if (isNullOrUndefined(specificContainerName) && userCollectionsData) {
            setUserCollections(userCollectionsData);
        }
    }, [specificContainerName, userCollectionsData]);

    const onCollectionSelected = (containerName: string) => {
        setContainerName(containerName);
    };

    if (isLoading) {
        return (
            <div className="spinner-full">
                <Spinner color="primary" />
            </div>
        );
    }

    if (isError || !rootDirectory) {
        return <p>Error fetching assets.</p>;
    }

    if (rootDirectory.name == RootDirectoryNames.StorageError) {
        return (
            <p className="text-danger">
                Storage error - container name: {containerName}
            </p>
        );
    }

    const extensions =
        formats === undefined ? ['*'] : FileFormats.getExtensions(formats);
    const filteredDirectory = filterDirectory(
        rootDirectory,
        extensions,
        searchText
    ) ?? {
        ...rootDirectory,
        files: [],
        directories: []
    };

    return (
        <div>
            <div className="d-flex position-relative mb-2 search-container">
                {isNullOrUndefined(specificContainerName) && (
                    <SelectCollection
                        collections={userCollections}
                        containerName={containerName ?? ''}
                        onCollectionSelected={onCollectionSelected}
                    />
                )}
                <AssetSearch
                    searchText={searchText}
                    setSearchText={setSearchText}
                />
            </div>
            <SpinnerIfLoading loading={!rootDirectory}>
                <AssetSelectorDirectory
                    directory={filteredDirectory}
                    containerName={containerName}
                    selection={selection}
                    fileSelectionChanged={fileSelectionChanged}
                    formats={formats}
                    fileIsSelected={fileIsSelected}
                />
            </SpinnerIfLoading>
        </div>
    );
};

export default AssetSelector;
