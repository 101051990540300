import React from 'react';
import {MixType} from '../../api/types.ts';

interface MixTypeButtonsProps {
    filter: MixType;
    onFilterSelected: (filter: MixType) => void;
}

const MixTypeButtons: React.FC<MixTypeButtonsProps> = ({
    filter,
    onFilterSelected
}) => {
    const setFilter = (filter: MixType) => {
        onFilterSelected(filter);
    };

    return (
        <div
            className="btn-group flex-fill mt-4 mt-sm-0"
            role="group"
            aria-label="Filter by type"
        >
            <button
                type="button"
                className={`btn btn-start ${
                    filter === MixType.Projects
                        ? 'btn-primary'
                        : 'btn-secondary'
                }`}
                onClick={() => setFilter(MixType.Projects)}
            >
                Projects
            </button>
            <button
                type="button"
                className={`btn ${
                    filter === MixType.Templates
                        ? 'btn-primary'
                        : 'btn-secondary'
                }`}
                onClick={() => setFilter(MixType.Templates)}
            >
                Templates
            </button>
            <button
                type="button"
                className={`btn btn-end ${
                    filter === MixType.All ? 'btn-primary' : 'btn-secondary'
                }`}
                onClick={() => setFilter(MixType.All)}
            >
                All
            </button>
        </div>
    );
};

export default MixTypeButtons;
