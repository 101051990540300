import React, {useEffect, useState} from 'react';
import {
    CollectionInfoModel,
    GroupMemberInfoFieldModel,
    GroupMembersModel
} from '../../api/types.ts';
import SpinnerIfLoading from '../shared/SpinnerIfLoading.tsx';
import useGroupMembersById from '../../api/groups/useGroupMembersById.ts';
import useGetGroupMemberInfoFieldsByCollectionId from '../../api/group_member_info/useGetGroupMemberInfoFieldsByCollectionId.ts';
import GroupMemberCard from './GroupMemberCard.tsx';
import AddGroupMemberCard from './AddGroupMemberCard.tsx';
import {useMutation, useQuery} from 'react-query';
import {Button, FormText, Input} from 'reactstrap';
import {toast} from 'react-toastify';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import useUpdateAutoJoinEmailAddresses, {
    UpdateAutoJoinEmailAddressesCommand
} from '../../api/groups/useUpdateAutoJoinEmailAddresses.ts';

interface GroupMembersTabProps {
    collection: CollectionInfoModel;
    canEdit: boolean;
}

const GroupMembersTab: React.FC<GroupMembersTabProps> = ({
    collection,
    canEdit
}) => {
    const [groupMembers, setGroupMembers] = useState<GroupMembersModel | null>(
        null
    );
    const [groupMemberInfoFields, setGroupMemberInfoFields] = useState<
        GroupMemberInfoFieldModel[]
    >([]);

    const groupMembersById = useGroupMembersById({
        collectionId: collection.id!
    });
    const {data: fetchedGroupMembers, refetch: refetchGroupMembers} = useQuery(
        ['groupMembersById', collection.id],
        groupMembersById
    );

    const getGroupMemberInfoFieldsByCollectionId =
        useGetGroupMemberInfoFieldsByCollectionId({
            collectionId: collection.id!
        });

    const {data: fetchedGroupMemberInfoFields} = useQuery(
        ['groupMemberInfoFieldsByCollectionId', collection.id],
        getGroupMemberInfoFieldsByCollectionId
    );

    useEffect(() => {
        if (fetchedGroupMembers) {
            setGroupMembers(fetchedGroupMembers);
        }

        if (fetchedGroupMemberInfoFields) {
            setGroupMemberInfoFields(fetchedGroupMemberInfoFields);
        }
    }, [collection.id, fetchedGroupMembers, fetchedGroupMemberInfoFields]);

    const onMemberUpdated = async () => {
        await refetchGroupMembers();
    };

    const updateAutoJoinEmailAddresses = useUpdateAutoJoinEmailAddresses();
    const updateAutoJoinEmailAddressesMutation = useMutation(
        updateAutoJoinEmailAddresses,
        {
            onSuccess: () => {
                toast.success('Email addresses updated');
                refetchGroupMembers();
            },
            onError: (error: unknown) => {
                toast.error(
                    'Failed to accept update auto join email addresses' +
                        unknownErrorToString(error)
                );
            }
        }
    );

    const onSaveAutoJoinEmailAddresses = async () => {
        const command: UpdateAutoJoinEmailAddressesCommand = {
            collectionId: collection.id!,
            autoJoinEmailAddresses: groupMembers!.autoJoinEmailAddresses
        };

        await updateAutoJoinEmailAddressesMutation.mutateAsync(command);
    };

    return (
        <SpinnerIfLoading loading={!groupMembers}>
            <div className="container-fluid custom-container mt-5 mb-5">
                <div className="row">
                    <div className="col-12">
                        {canEdit && groupMembers && (
                            <div className="mb-3">
                                <h3 className="fw-bold mb-3">
                                    Auto join email addresses
                                </h3>
                                <FormText>
                                    Anyone with the specified email addresses
                                    can join this group as a contributor without
                                    needing to wait for approval.
                                </FormText>
                                <Input
                                    type="textarea"
                                    value={groupMembers.autoJoinEmailAddresses}
                                    onChange={e =>
                                        setGroupMembers(prevState => ({
                                            ...prevState!,
                                            autoJoinEmailAddresses:
                                                e.target.value
                                        }))
                                    }
                                />
                                <div className="d-flex justify-content-end">
                                    <Button
                                        color="primary"
                                        onClick={onSaveAutoJoinEmailAddresses}
                                        className="mt-2"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        )}
                        {canEdit &&
                            groupMembers &&
                            groupMembers.pending.length > 0 && (
                                <>
                                    <h3 className="fw-bold mb-3">
                                        Pending members
                                    </h3>
                                    <div className="mixi-card-deck member-card-deck mb-3">
                                        {groupMembers.pending.map(member => (
                                            <div
                                                className="mixi-card-holder"
                                                key={member.userId}
                                            >
                                                <GroupMemberCard
                                                    member={member}
                                                    collection={collection}
                                                    onMemberUpdated={
                                                        onMemberUpdated
                                                    }
                                                    canEdit={canEdit}
                                                    fields={[]}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        <h3 className="fw-bold mb-3">Members</h3>
                        <div className="mixi-card-deck member-card-deck">
                            {canEdit && (
                                <div className="mixi-card-holder">
                                    <AddGroupMemberCard
                                        collectionId={collection.id!}
                                        onMemberUpdated={onMemberUpdated}
                                    />
                                </div>
                            )}
                            {groupMembers?.members.map(member => (
                                <div
                                    className="mixi-card-holder"
                                    key={member.userId}
                                >
                                    <GroupMemberCard
                                        member={member}
                                        fields={groupMemberInfoFields}
                                        collection={collection}
                                        onMemberUpdated={onMemberUpdated}
                                        canEdit={canEdit}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </SpinnerIfLoading>
    );
};

export default GroupMembersTab;
