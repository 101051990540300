import React, {createContext, ReactNode, useContext} from 'react';
import {useQuery} from 'react-query';
import baseUri from '../api/baseUri.ts';
import {Spinner} from 'reactstrap';

const fetchClientConfig = async (): Promise<ClientConfigData> => {
    const response = await fetch(`${baseUri}/api/client-config`);

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};

type ClientConfigData = {
    apiServiceBaseUri: string;
    storageUri: string;
    googleDriveClientId?: string | null;
    googleDriveDeveloperKey?: string | null;
    googleClientId: string;
    microsoftClientId: string;
    appleClientId: string;
    dropBoxAppKey: string;
    gaTrackingCode: string | null;
    assistantEnabled: boolean;
};

const ClientConfigContext = createContext<ClientConfigData>({
    apiServiceBaseUri: '',
    storageUri: '',
    googleClientId: '',
    microsoftClientId: '',
    appleClientId: '',
    dropBoxAppKey: '',
    gaTrackingCode: null,
    assistantEnabled: false
});

type ClientConfigProviderProps = {
    children: ReactNode;
};

export const ClientConfigProvider: React.FC<ClientConfigProviderProps> = ({
    children
}) => {
    const {data, isLoading, error} = useQuery(
        'clientConfig',
        fetchClientConfig,
        {
            staleTime: Infinity // Prevents refetching
        }
    );

    if (isLoading) {
        return (
            <div className="spinner-full">
                <Spinner color="primary" />
            </div>
        );
    }
    if (error) {
        return (
            <div>
                Error loading client configuration. Please refresh the browser.
            </div>
        );
    }
    if (!data) {
        return (
            <div className="spinner-full">
                <Spinner color="primary" />
            </div>
        );
    }

    return (
        <ClientConfigContext.Provider value={data}>
            {children}
        </ClientConfigContext.Provider>
    );
};

const useClientConfig = () => {
    const context = useContext(ClientConfigContext);
    if (context === undefined) {
        throw new Error(
            'useClientConfig must be used within a ClientConfigProvider'
        );
    }
    return context;
};

export default useClientConfig;
