import {DirectoryModel} from '../../api/types.ts';

export default function filterDirectory(
    directory: DirectoryModel,
    extensions: string[],
    searchText: string
): DirectoryModel | null {
    const filteredFiles = directory.files.filter(
        file =>
            (extensions.includes('*') || extensions.includes(file.extension)) &&
            (!searchText ||
                file.name.toLowerCase().includes(searchText.toLowerCase()))
    );

    const filteredDirectories = directory.directories
        .map(subDir => filterDirectory(subDir, extensions, searchText))
        .filter((subDir): subDir is DirectoryModel => subDir !== null)
        .filter(
            subDir =>
                subDir.files.length > 0 ||
                subDir.directories.length > 0 ||
                subDir.isNew
        );

    if (
        filteredFiles.length === 0 &&
        filteredDirectories.length === 0 &&
        !directory.isNew
    ) {
        return null;
    }

    return {
        ...directory,
        files: filteredFiles,
        directories: filteredDirectories
    };
}
