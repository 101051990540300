import React, {useEffect, useState} from 'react';
import {ReactStateSetter} from '../../../types.ts';
import Cropper from 'react-cropper';
import {Button} from 'reactstrap';
import 'cropperjs/dist/cropper.css';
import createFileFromImage from './createFileFromImage.ts';
import {toast} from 'react-toastify';

interface ImageDimensions {
    width: number;
    height: number;
    aspectRatio: number;
}

interface ImageEditProps {
    editImage: File;
    setEditImage: ReactStateSetter<File>;
}

const ImageCrop: React.FC<ImageEditProps> = ({editImage, setEditImage}) => {
    const [editedImageString, setEditedImageString] = useState<string>();
    const [cropper, setCropper] = useState<Cropper | null>(null);
    const [dimensions, setDimensions] = useState<ImageDimensions>({
        width: 0,
        height: 0,
        aspectRatio: 0
    });

    useEffect(() => {
        if (editImage) {
            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result as string;
                img.onload = () => {
                    setDimensions({
                        width: img.width,
                        height: img.height,
                        aspectRatio: img.width / img.height
                    });
                    setCropper(null);
                    setEditedImageString(reader.result as string);
                };
            };
            reader.readAsDataURL(editImage);
        }
    }, [editImage]);

    const handleCropperInitialized = (instance: Cropper) => {
        setCropper(instance);
        if (editImage && cropper) {
            cropper.setAspectRatio(dimensions.aspectRatio); // Reset aspect ratio
        }
    };

    const handleApply = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (cropper) {
            const croppedCanvas = cropper.getCroppedCanvas();

            // Use crop box dimensions for width and height
            const width = cropper.getCropBoxData().width;
            const height = cropper.getCropBoxData().height;

            // Create a new canvas with the updated dimensions
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const context = canvas.getContext('2d');

            // Draw the cropped image onto the new canvas with the updated dimensions
            context?.drawImage(
                croppedCanvas,
                0,
                0,
                croppedCanvas.width,
                croppedCanvas.height,
                0,
                0,
                width,
                height
            );

            // Convert the canvas to a base64 string
            const croppedImageBase64 = canvas.toDataURL('image/png', 1);

            setEditImage(
                createFileFromImage(croppedImageBase64, editImage?.name ?? '')
            );
            toast.success('Image changed.');
        }
    };
    return (
        <>
            <div className="imageEdit-container">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        minHeight: '100px'
                    }}
                >
                    <Cropper
                        key={cropper ? dimensions?.width : 'cropper'} // Use cropper width as key if available, otherwise use a stable key
                        src={editedImageString}
                        style={{
                            height: dimensions.height,
                            width: dimensions.width
                        }}
                        initialAspectRatio={dimensions.aspectRatio}
                        guides={true}
                        dragMode="move"
                        cropBoxResizable={true}
                        cropBoxMovable={true}
                        viewMode={2}
                        background={false}
                        onInitialized={handleCropperInitialized}
                        autoCropArea={1}
                    />
                </div>

                <Button
                    color="primary"
                    style={{
                        marginTop: 20
                    }}
                    onClick={handleApply}
                >
                    Apply
                </Button>
            </div>
        </>
    );
};

export default ImageCrop;
