import React, {useState} from 'react';
import {Material, NormalizedColor, HTMLColor} from './types.ts';
import {Input, Label, FormGroup, Button, InputGroup} from 'reactstrap';
import {FileDataModel, Selection} from '../../api/types.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBan, faImage} from '@fortawesome/free-solid-svg-icons';
import AssetSelectAndUploadModal from '../assets/AssetSelectAndUploadModal.tsx';
import {FileFormat} from '../../lib/FileFormats.ts';
import ColorPickerComponent from '../shared/ColorPicker.tsx';
import getColorHexValue from './getColorHexValue.ts';

interface MaterialEditorProps {
    material: Material;
    onChange: (material: Material) => void;
}

const MaterialEditor: React.FC<MaterialEditorProps> = ({
    material,
    onChange
}) => {
    const [color, setColor] = useState<NormalizedColor | HTMLColor | undefined>(
        material.color
    );
    const [texture, setTexture] = useState<string | undefined>(
        material.texture
    );
    const [alpha, setAlpha] = useState<number | undefined>(material.alpha);

    const handleColorChange = (newColor: string) => {
        setColor(newColor);
        onChange({color: newColor, texture, alpha});
    };

    const handleAlphaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        const newAlpha = isNaN(value) ? undefined : value;
        setAlpha(newAlpha);
        onChange({color, texture, alpha: newAlpha});
    };

    const [isImageSelectModalOpen, setIsImageSelectModalOpen] = useState(false);

    const onImageSelectionChanged = (files: FileDataModel[] | undefined) => {
        if (!files) {
            return;
        }

        if (files.length > 0) {
            setTexture(files[0].uri);
            onChange({color, texture: files[0].uri, alpha});
        }

        setIsImageSelectModalOpen(false);
    };

    return (
        <div>
            <FormGroup>
                <Label>Color:</Label>
                <ColorPickerComponent
                    color={getColorHexValue(color)}
                    onChange={handleColorChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Texture:</Label>
                <InputGroup>
                    <Input
                        type="text"
                        value={texture ?? ''}
                        onChange={() => {}}
                        disabled
                    />

                    <Button
                        color="secondary"
                        onClick={() => setIsImageSelectModalOpen(true)}
                        aria-label="Select texture image"
                        title="Select texture image"
                    >
                        <FontAwesomeIcon icon={faImage} />
                    </Button>
                    {isImageSelectModalOpen && (
                        <AssetSelectAndUploadModal
                            options={{
                                selection: Selection.Single,
                                explanation: 'Please select an image file',
                                formats: FileFormat.Image,
                                fileSelectionChanged: onImageSelectionChanged
                            }}
                            onClose={() => setIsImageSelectModalOpen(false)}
                        />
                    )}

                    <Button
                        color="secondary"
                        onClick={() => {
                            setTexture('');
                            onChange({color, texture: '', alpha});
                        }}
                        title="Remove texture"
                    >
                        <FontAwesomeIcon icon={faBan} />
                    </Button>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <Label>Alpha:</Label>
                <Input
                    type="number"
                    step="0.1"
                    min="0"
                    max="1"
                    value={alpha ?? ''}
                    onChange={handleAlphaChange}
                />
            </FormGroup>
        </div>
    );
};

export default MaterialEditor;
