import React from 'react';
import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import useMixById from '../../api/mixes/useMixById.ts';
import {useQuery} from 'react-query';
import emptyMix from './emptyMix.ts';
import GalleryCard from './GalleryCard.tsx';
import {Button} from 'reactstrap';

interface MixCardProps {
    mixId: string;
    onRemove?: () => void;
}

const MixCard: React.FC<MixCardProps> = ({mixId, onRemove}) => {
    const {loggedInUserId} = useAuthStateManager();

    const fetchProject = useMixById({
        mixId
    });
    const {data: mixData} = useQuery(
        ['project', mixId, loggedInUserId],
        fetchProject
    );

    const mix = mixData ?? emptyMix;

    return (
        <div key={mixId} className="mixi-card-holder">
            <GalleryCard project={mix} />
            {onRemove && (
                <div className="d-flex justify-content-end">
                    <Button size="sm" color="danger" onClick={() => onRemove()}>
                        Remove
                    </Button>
                </div>
            )}
        </div>
    );
};

export default MixCard;
