import React, {useEffect, useRef, useState} from 'react';
import {useMutation} from 'react-query';
import {toast} from 'react-toastify';
import {
    ChannelInfoModel,
    CollectionInfoModel,
    DisplayPostModel,
    Period
} from '../../api/types.ts';
import useDeletePost from '../../api/posts/useDeletePost.ts';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import useGetPosts, {GetPostsQuery} from '../../api/posts/useGetPosts.ts';
import SpinnerIfLoading from '../shared/SpinnerIfLoading.tsx';
import PostSeparator from './PostSeparator.tsx';
import GroupPost from './GroupPost.tsx';
import MxGroupPostsScroller from './MxGroupPostsScroller.tsx';
import {useEffectOnce} from '../../hooks/useEffectOnce.ts';
import {PostUpdateMessage, PostUpdateType} from '../../types.ts';
import useGetPostReplies, {
    GetPostRepliesQuery
} from '../../api/posts/useGetPostReplies.ts';
import useGetPostReactionsSummary, {
    GetPostReactionsSummaryQuery
} from '../../api/posts/useGetPostReactionsSummary.ts';
import {onPostAdded} from './group_posts.ts';

interface GroupPostsProps {
    collection: CollectionInfoModel;
    channel: ChannelInfoModel;
}

const GroupPosts: React.FC<GroupPostsProps> = ({collection, channel}) => {
    const [posts, setPosts] = useState<DisplayPostModel[]>([]);
    const [renderedPosts, setRenderedPosts] = useState<Record<string, boolean>>(
        {}
    );

    const onGroupPostAdded = (postId: string, element: HTMLDivElement) => {
        if (!(postId in renderedPosts)) {
            onPostAdded('Posts', element);
            setRenderedPosts(prevState => ({...prevState, [postId]: true}));
        }
    };

    const postsRef = useRef(posts);
    useEffect(() => {
        postsRef.current = posts;
    }, [posts]);

    const [isLoading, setIsLoading] = useState(false);

    const searchParams = new URLSearchParams(location.search);
    const requestedPostId = searchParams.get('pid');
    const requestedParentPostId = searchParams.get('ppid');

    const [queryParams, setQueryParams] = useState<GetPostsQuery>({
        collectionId: collection.id!,
        channelId: channel.id!,
        period: Period.MostRecent,
        dateTimeFromUtc: null,
        postId: requestedPostId,
        parentPostId: requestedParentPostId,
        take: 20
    });

    const deletePost = useDeletePost();
    const deletePostMutation = useMutation(deletePost, {
        onSuccess: () => {
            toast.success('Post deleted.');
        },
        onError: error => {
            toast.error(
                `Unable to delete post: ${unknownErrorToString(error)}`
            );
        }
    });

    const handlePostDelete = async (post: DisplayPostModel) => {
        const confirmed = window.confirm(
            'Are you sure you want to delete this post? This cannot be undone'
        );
        if (confirmed) {
            await deletePostMutation.mutateAsync({post});
        }
    };

    const getPosts = useGetPosts(queryParams);

    const fetchPosts = async () => {
        setIsLoading(true);
        try {
            const result = await getPosts();
            result && addPosts(result.posts);
        } catch (error) {
            console.error('Error fetching posts:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPosts();
    }, [queryParams]);

    const addPosts = (newPosts: DisplayPostModel[]) => {
        setPosts(prevPosts => {
            const uniquePosts = [...prevPosts, ...newPosts].filter(
                (post, index, self) =>
                    index === self.findIndex(p => p.id === post.id)
            );
            uniquePosts.sort((a, b) =>
                a.createdOnUtc.localeCompare(b.createdOnUtc)
            );
            updatePostDateSeparators(uniquePosts);
            return uniquePosts;
        });
    };

    const updatePostDateSeparators = (posts: DisplayPostModel[]) => {
        const firstPostsPerDay = new Set(
            posts
                .reduce((acc, post) => {
                    const date = new Date(post.createdOnUtc).toDateString();
                    if (!acc.has(date)) {
                        acc.set(date, post);
                    }
                    return acc;
                }, new Map<string, DisplayPostModel>())
                .values()
        );

        posts.forEach(post => {
            post.showDateSeparator = firstPostsPerDay.has(post);
        });
    };

    const fetchMostRecentPostsAsync = async () => {
        setQueryParams(prevParams => ({
            ...prevParams,
            period: Period.MostRecent,
            dateTimeFromUtc: null
        }));
    };

    const fetchOlderPostsAsync = async () => {
        const dateTimeFromUtc =
            (postsRef.current
                ? postsRef.current[0]?.createdOnUtc
                : undefined) ?? '';

        setQueryParams(prevParams => ({
            ...prevParams,
            period: Period.Older,
            dateTimeFromUtc
        }));
    };

    const fetchNewerPostsAsync = async () => {
        const dateTimeFromUtc = posts[posts.length - 1]?.createdOnUtc || '';
        setQueryParams(prevParams => ({
            ...prevParams,
            period: Period.Newer,
            dateTimeFromUtc
        }));
    };

    const [postRepliesQueryParams, setPostRepliesQueryParams] =
        useState<GetPostRepliesQuery | null>(null);

    const getPostReplies = useGetPostReplies(
        postRepliesQueryParams ?? {parentPostId: '', countOnly: true}
    );

    const fetchPostReplies = async () => {
        try {
            const repliesResult = await getPostReplies();
            if (repliesResult) {
                setPosts(prevState =>
                    prevState.map(post =>
                        post.id === postRepliesQueryParams?.parentPostId
                            ? {
                                  ...post,
                                  countReplies: repliesResult.countReplies,
                                  replies: postRepliesQueryParams?.countOnly
                                      ? post.replies
                                      : repliesResult.replies ?? []
                              }
                            : post
                    )
                );
            }
        } catch (error) {
            console.error('Error fetching post replies:', error);
        }
    };

    useEffect(() => {
        fetchPostReplies();
    }, [postRepliesQueryParams]);

    const refreshRepliesAsync = async (parentPostId: string | null) => {
        const parentPost = postsRef.current.find(
            post => post.id === parentPostId
        );

        if (parentPost && parentPostId) {
            const countOnly = !parentPost.replies;

            setPostRepliesQueryParams(prevParams => ({
                ...prevParams!,
                parentPostId: parentPostId!,
                countOnly: countOnly
            }));
        }
    };

    const [refreshReactionsQueryParams, setRefreshReactionsQueryParams] =
        useState<GetPostReactionsSummaryQuery | null>(null);

    const getPostReactionsSummary = useGetPostReactionsSummary(
        refreshReactionsQueryParams ?? {postId: ''}
    );

    const fetchPostReactionsSummary = async () => {
        try {
            const postReactionSummary = await getPostReactionsSummary();
            if (postReactionSummary) {
                setPosts(prevState =>
                    prevState.map(post => {
                        if (post.id === postReactionSummary.postId) {
                            return {
                                ...post,
                                reactionSummary:
                                    postReactionSummary.reactionSummary,
                                reactionsCount:
                                    postReactionSummary.reactionsCount
                            };
                        }

                        if (post.id === postReactionSummary.parentPostId) {
                            return {
                                ...post,
                                replies:
                                    post.replies?.map(reply =>
                                        reply.id === postReactionSummary.postId
                                            ? {
                                                  ...reply,
                                                  reactionSummary:
                                                      postReactionSummary.reactionSummary,
                                                  reactionsCount:
                                                      postReactionSummary.reactionsCount
                                              }
                                            : reply
                                    ) ?? null
                            };
                        }

                        return post;
                    })
                );
            }
        } catch (error) {
            console.error('Error fetching post reactions:', error);
        }
    };

    useEffect(() => {
        fetchPostReactionsSummary();
    }, [refreshReactionsQueryParams]);

    const refreshReactionsAsync = async (
        postId: string,
        parentPostId: string | null
    ) => {
        let postToRefresh: DisplayPostModel | undefined;
        if (parentPostId) {
            const parentPost = postsRef.current.find(p => p.id == parentPostId);
            postToRefresh = parentPost?.replies?.find(r => r.id == postId);
        } else {
            postToRefresh = postsRef.current.find(p => p.id == postId);
        }

        if (postToRefresh) {
            setRefreshReactionsQueryParams(prevParams => ({
                ...prevParams!,
                postId: postToRefresh!.id
            }));
        }
    };

    const handleDeletePostMessage = (
        postId: string,
        parentPostId: string | null
    ) => {
        if (parentPostId === null) {
            setPosts(prevPosts => {
                const updatedPosts = prevPosts.filter(
                    post => post.id !== postId
                );
                updatePostDateSeparators(updatedPosts);
                return updatedPosts;
            });
        } else {
            setPosts(prevPosts => {
                const updatedPosts: DisplayPostModel[] = prevPosts.map(post => {
                    if (post.id === parentPostId) {
                        setPostRepliesQueryParams({
                            parentPostId,
                            countOnly: true
                        });

                        return {
                            ...post,
                            replies: post.replies
                                ? post.replies.filter(
                                      reply => reply.id !== postId
                                  )
                                : post.replies
                        };
                    }

                    return post;
                });
                updatePostDateSeparators(updatedPosts);
                return updatedPosts;
            });
        }
    };

    const postsUpdated = async (message: PostUpdateMessage) => {
        switch (message.postUpdateType) {
            case PostUpdateType.NewPost:
                if (posts.length === 0) {
                    await fetchMostRecentPostsAsync();
                } else {
                    await fetchNewerPostsAsync();
                }
                break;
            case PostUpdateType.NewReply:
                await refreshRepliesAsync(message.parentPostId);
                break;
            case PostUpdateType.Deleted:
                handleDeletePostMessage(message.postId, message.parentPostId);
                break;
            case PostUpdateType.Reaction:
                await refreshReactionsAsync(
                    message.postId,
                    message.parentPostId
                );
                break;
            default:
                break;
        }
    };

    useEffectOnce(() => {
        const handlePostsUpdated = (event: Event) => {
            postsUpdated((event as CustomEvent).detail as PostUpdateMessage);
        };

        window.addEventListener('PostsUpdated', handlePostsUpdated);

        return () => {
            window.removeEventListener('PostsUpdated', handlePostsUpdated);
        };
    });

    const nextMessage = async () => {
        await fetchOlderPostsAsync();
    };

    const setPost = (post: DisplayPostModel) => {
        setPosts(prevState => prevState.map(p => (p.id == post.id ? post : p)));
    };

    return (
        <MxGroupPostsScroller nextMessage={nextMessage}>
            <SpinnerIfLoading loading={isLoading}>
                {posts.map(post => (
                    <React.Fragment key={post.id}>
                        {post.showDateSeparator && (
                            <PostSeparator createdOnUtc={post.createdOnUtc} />
                        )}
                        <GroupPost
                            key={post.id}
                            collection={collection}
                            post={post}
                            setPost={setPost}
                            onDelete={() => handlePostDelete(post)}
                            onPostAdded={onGroupPostAdded}
                        />
                    </React.Fragment>
                ))}
            </SpinnerIfLoading>
        </MxGroupPostsScroller>
    );
};

export default GroupPosts;
