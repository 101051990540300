import {PostsResult, Period} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetPostsQuery = {
    collectionId: string;
    channelId: string;
    dateTimeFromUtc: string | null;
    postId: string | null;
    parentPostId: string | null;
    period: Period;
    take: number;
};

const useGetPosts = (request: GetPostsQuery) =>
    useGet<PostsResult>(`/api/posts/get-posts?${toQuery(request)}`, {
        errorMessage: 'GET api/posts/get-posts failed'
    });

export default useGetPosts;
