import {usePost} from './../useHttpHooks';

export type MarkNotificationsAsReadCommand = NonNullable<unknown>;

const useMarkNotificationsAsRead = () =>
    usePost<MarkNotificationsAsReadCommand>(
        `/api/notifications/mark-notifications-as-read`,
        {
            errorMessage:
                'POST api/notifications/mark-notifications-as-read failed'
        }
    );

export default useMarkNotificationsAsRead;
