import React from 'react';
import {HubConnectionBuilder} from '@microsoft/signalr';
import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import useClientConfig from '../../hooks/useClientConfig.tsx';
import {useEffectOnce} from '../../hooks/useEffectOnce.ts';
import {PostUpdateMessage} from '../../types.ts';
import joinPath from '../../lib/joinPath.ts';

interface UserMessagesHubProps {
    apiServiceBaseUri: string;
    userId: string;
}

const UserMessagesHub: React.FC<
    React.PropsWithChildren<UserMessagesHubProps>
> = ({children, apiServiceBaseUri, userId}) => {
    useEffectOnce(() => {
        const hubUrl = joinPath(apiServiceBaseUri, 'messagesHub');

        const hubConnection = new HubConnectionBuilder()
            .withUrl(hubUrl)
            .build();

        hubConnection.on('PostsUpdated', onPostsUpdated);
        hubConnection.on('NewNotifications', onNewNotifications);

        hubConnection.start().then(() => {
            hubConnection.invoke('JoinGroup', userId);
        });

        return () => {
            if (hubConnection) {
                hubConnection.invoke('LeaveGroup', userId);
                hubConnection.stop();
            }
        };
    });

    const onPostsUpdated = (message: PostUpdateMessage) => {
        window.dispatchEvent(
            new CustomEvent('PostsUpdated', {detail: message})
        );
    };

    const onNewNotifications = () => {
        window.dispatchEvent(new CustomEvent('NewNotifications'));
    };

    return <>{children}</>;
};

export const MxMessagesHub: React.FC<React.PropsWithChildren> = ({
    children
}) => {
    const {apiServiceBaseUri} = useClientConfig();
    const {authData} = useAuthStateManager();

    if (authData.isAuthenticated && authData.id && apiServiceBaseUri !== '') {
        return (
            <UserMessagesHub
                userId={authData.id!}
                apiServiceBaseUri={apiServiceBaseUri}
                key={`${authData.id}_${apiServiceBaseUri}`}
            >
                {children}
            </UserMessagesHub>
        );
    }

    return <>{children}</>;
};
