import React, {useState} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import {FileDataModel, Selection} from '../../api/types.ts';
import AssetSelector from './AssetSelector.tsx';
import {FileFormat} from '../../lib/FileFormats.ts';

interface AssetSelectionModalProps {
    selection: Selection;
    formats?: FileFormat;
    onClose: () => void;
    onAssetsSelected: (assets: FileDataModel[]) => void;
    specificContainerName?: string;
}

const AssetSelectionModal: React.FC<AssetSelectionModalProps> = ({
    selection,
    formats,
    onClose,
    onAssetsSelected,
    specificContainerName
}) => {
    const [selectedFiles, setSelectedFiles] = useState<
        Record<string, FileDataModel>
    >({});

    const fileIsSelected = (path: string) => !!selectedFiles[path];

    const onFileSelectionChanged = (file: FileDataModel) => {
        let newSelectedFiles = {...selectedFiles};
        if (newSelectedFiles[file.path]) {
            delete newSelectedFiles[file.path];
        } else {
            if (selection === Selection.Single) {
                newSelectedFiles = {[file.path]: file};
            } else {
                newSelectedFiles[file.path] = file;
            }
        }

        setSelectedFiles(newSelectedFiles);
    };

    const assetsSelectedAsync = () => {
        onAssetsSelected(Object.values(selectedFiles));
    };

    return (
        <Modal
            isOpen={true}
            toggle={onClose}
            className="modal-dialog modal-dialog-centered modal-body-scroll modal-lg"
        >
            <ModalHeader>
                {selection === Selection.Single
                    ? 'Select file'
                    : 'Select files'}
            </ModalHeader>
            <ModalBody>
                <AssetSelector
                    specificContainerName={specificContainerName}
                    formats={formats}
                    selection={selection}
                    fileSelectionChanged={onFileSelectionChanged}
                    fileIsSelected={fileIsSelected}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={assetsSelectedAsync}>
                    OK
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AssetSelectionModal;
