import AssetSelectAndUpload from '../assets/AssetSelectAndUpload.tsx';
import {MergeData} from '../../types.ts';
import isNullOrWhiteSpace from '../../lib/isNullOrWhiteSpace.ts';
import CopyButton from '../shared/CopyButton.tsx';
import {
    ContentSectionModel,
    FileDataModel,
    Selection,
    ViewMode
} from '../../api/types.ts';
import useSessionState from '../../lib/useSessionState.ts';

interface DownloadSectionProps {
    elementId: string;
    section: ContentSectionModel;
    readOnly: boolean;
    mergeData: MergeData;
    packageContainerName?: string;
}

const DownloadSection: React.FC<DownloadSectionProps> = ({
    elementId,
    section,
    readOnly,
    mergeData,
    packageContainerName
}) => {
    const [viewMode] = useSessionState(`ViewMode_${elementId}`, ViewMode.View);

    const onFileSelectionChanged = (files: FileDataModel[]) => {
        if (files.length > 0) {
            mergeData(elementId, {fileName: files[0].uri});
        }
    };

    if (!readOnly && viewMode == ViewMode.Edit) {
        return (
            <>
                <div className="mb-3 form-group">
                    <label>Title</label>
                    <span>&nbsp;</span>
                    <input
                        className="form-control"
                        value={section.altText ?? ''}
                        onChange={event =>
                            mergeData(elementId, {altText: event.target.value})
                        }
                        maxLength={2048}
                    />
                </div>
                <div className="mb-3 form-group">
                    <label>Description</label>
                    <span>&nbsp;</span>
                    <textarea
                        className="form-control"
                        rows={3}
                        value={section.content ?? ''}
                        onChange={event =>
                            mergeData(elementId, {content: event.target.value})
                        }
                    />
                </div>
                <div className="mb-3 form-group">
                    <label>Download source</label>
                    <span>&nbsp;</span>
                    <input
                        className="form-control"
                        defaultValue={section.fileName ?? ''}
                        readOnly
                        disabled
                    />
                </div>
                <AssetSelectAndUpload
                    selection={Selection.Single}
                    explanation="Please select any file"
                    formats={undefined}
                    fileSelectionChanged={files =>
                        onFileSelectionChanged(files)
                    }
                    specificContainerName={packageContainerName}
                />
            </>
        );
    }

    if (!isNullOrWhiteSpace(section.fileName)) {
        return (
            <>
                {!isNullOrWhiteSpace(section.altText) && (
                    <h4>{section.altText}</h4>
                )}
                {!isNullOrWhiteSpace(section.content) && (
                    <p>{section.content}</p>
                )}
                <p>
                    <small>
                        {section.fileName!.split(/[/\\]/).pop() || ''}
                    </small>
                </p>
                <div className="copy-link-container mb-3">
                    <a
                        href={section.fileName!}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={section.fileName!}
                    >
                        {section.fileName}
                    </a>
                    <CopyButton
                        cssClass="border-none mx-3"
                        copyText={section.fileName!}
                        title="Copy path"
                    />
                </div>
            </>
        );
    }

    return null;
};

export default DownloadSection;
