export function newProjectUrl(project: {
    id: string | null;
    customUrl?: string | null;
}) {
    if (project.customUrl && project.customUrl != '') {
        return `/new/${project.customUrl}`;
    }

    return `/new/${project.id ?? '_'}`;
}

export default function projectUrl(project: {
    id: string | null;
    customUrl?: string | null;
}) {
    if (project.customUrl && project.customUrl != '') {
        return `/project/${project.customUrl}`;
    }

    return `/project/${project.id ?? '_'}`;
}
