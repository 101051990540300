import {EmptyResult, PostModel} from '../types';
import {usePost} from './../useHttpHooks';

export type AuthorPostCommand = {
    post: PostModel;
};

const useAuthorPost = () =>
    usePost<AuthorPostCommand, EmptyResult>(`/api/posts/author-post`, {errorMessage: 'POST api/posts/author-post failed'
});

export default useAuthorPost;
