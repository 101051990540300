import {isUrl} from '../../lib/urls.ts';
import tryParseInt from '../../lib/tryParseInt.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {MergeData} from '../../types.ts';
import {ContentSectionModel, ViewMode} from '../../api/types.ts';
import useSessionState from '../../lib/useSessionState.ts';
import EmbeddedWebPageCheck from '../shared/EmbeddedWebPageCheck.tsx';
import {EmbeddingInstructionsModal} from '../shared/EmbeddingInstructions.tsx';
import {useState} from 'react';

interface EmbedSectionProps {
    elementId: string;
    section: ContentSectionModel;
    readOnly: boolean;
    mergeData: MergeData;
}

const EmbedSection: React.FC<EmbedSectionProps> = ({
    elementId,
    section,
    readOnly,
    mergeData
}) => {
    const [showEmbeddingInstructions, setShowEmbeddingInstructions] =
        useState(false);

    const [viewMode] = useSessionState(`ViewMode_${elementId}`, ViewMode.View);

    const embedHeight = tryParseInt(section.altText, 600);

    const shrink = () => {
        mergeData(elementId, {altText: String(embedHeight - 100)});
    };
    const grow = () => {
        mergeData(elementId, {altText: String(embedHeight + 100)});
    };

    return (
        <>
            {showEmbeddingInstructions && (
                <EmbeddingInstructionsModal
                    onClose={() => setShowEmbeddingInstructions(false)}
                />
            )}
            {!readOnly && viewMode == ViewMode.Edit && (
                <>
                    <div className="mb-3 form-group">
                        <label>
                            Web page URL to embed{' '}
                            <a
                                href="#"
                                onClick={event => (
                                    event.preventDefault(),
                                    setShowEmbeddingInstructions(true)
                                )}
                            >
                                help?
                            </a>
                        </label>
                        <span>&nbsp;</span>
                        <input
                            className="form-control"
                            value={section.fileName ?? ''}
                            onChange={event =>
                                mergeData(elementId, {
                                    fileName: event.target.value
                                })
                            }
                        />
                    </div>
                    <div className="d-flex align-items-center mb-3">
                        <div>
                            <button
                                className="btn btn-sm btn-outline-primary"
                                onClick={shrink}
                                disabled={embedHeight <= 100}
                            >
                                <FontAwesomeIcon icon={faMinus} />
                            </button>
                        </div>
                        <div className="mx-2">
                            <small className="d-block">Change height</small>
                        </div>
                        <div>
                            <button
                                className="btn btn-sm btn-outline-primary"
                                onClick={grow}
                                disabled={embedHeight >= 1200}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </div>
                    </div>
                    <div className="mt-2 mb-2 form-group">
                        <label>Preview:</label>
                        <span>&nbsp;</span>
                    </div>
                </>
            )}

            {isUrl(section.fileName) && (
                <div>
                    <EmbeddedWebPageCheck url={section.fileName ?? ''}>
                        <iframe
                            src={section.fileName ?? ''}
                            style={{
                                width: '100%',
                                height: `${embedHeight}px`
                            }}
                        ></iframe>
                    </EmbeddedWebPageCheck>
                </div>
            )}
        </>
    );
};

export default EmbedSection;
