import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import {
    ChannelInfoModel,
    CollectionInfoModel,
    CollectionJoinCodeModel,
    CollectionType,
    GroupMemberInfoFieldEditableBy,
    GroupMemberInfoFieldModel,
    GroupMemberInfoFieldType
} from '../../api/types.ts';
import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import useGetCollectionChannels from '../../api/collections/useGetCollectionChannels.ts';
import useGetCollectionJoinCode from '../../api/collections/useGetCollectionJoinCode.ts';
import useUpdateCollection from '../../api/collections/useUpdateCollection.ts';
import useUpdateCollectionThumbnail from '../../api/thumbnails/useUpdateCollectionThumbnail.ts';
import useCreateChannel from '../../api/posts/useCreateChannel.ts';
import useUpdateChannel from '../../api/posts/useUpdateChannel.ts';
import useUpdateOrdinalPositions from '../../api/shared/useUpdateOrdinalPositions.ts';
import useUpsertGroupMemberInfoField from '../../api/group_member_info/useUpsertGroupMemberInfoField.ts';
import useDeleteGroupMemberInfoField from '../../api/group_member_info/useDeleteGroupMemberInfoField.ts';
import useGetGroupMemberInfoFieldsByCollectionId from '../../api/group_member_info/useGetGroupMemberInfoFieldsByCollectionId.ts';
import {Move} from '../content/lib.ts';
import SpinnerIfLoading from '../shared/SpinnerIfLoading.tsx';
import ThumbnailEditor from '../shared/ThumbnailEditor.tsx';
import {useMutation, useQuery} from 'react-query';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import MxTable from '../shared/MxTable.tsx';
import ChannelSettingsModal from './ChannelSettingsModal.tsx';
import EditGroupMemberInfoFieldModal from './EditGroupMemberInfoFieldModal.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faLongArrowAltDown,
    faLongArrowAltUp
} from '@fortawesome/free-solid-svg-icons';
import dateTimeUtcNow from '../../lib/dateTimeUtcNow.ts';
import breakToWords from '../../lib/breakToWords.ts';
import assertNonNullStateSetter from '../../lib/assertNonNullStateSetter.ts';
import getEnumFlagsText from '../../lib/getEnumFlagsText.ts';
import RoleNames from '../../lib/RoleNames.ts';
import EnumSelect from '../shared/EnumSelect.tsx';
import CustomContainer from '../shared/CustomContainer.tsx';
import MxCard from '../shared/MxCard.tsx';
import GroupHomeProject from './GroupHomeProject.tsx';
import OverlayWithSpinner from '../shared/OverlayWithSpinner.tsx';
import ConfirmButton from '../shared/ConfirmButton.tsx';
import useArchiveCollection from '../../api/collections/useArchiveCollection.ts';
import isValidURL from '../../lib/isValidURL.ts';
import InviteCodes from './InviteCodes.tsx';

interface GroupSettingsTabProps {
    collection: CollectionInfoModel;
    onCollectionUpdated: () => Promise<void>;
    onChannelUpdated: () => void;
}

const GroupSettingsTab: React.FC<GroupSettingsTabProps> = ({
    collection,
    onCollectionUpdated,
    onChannelUpdated
}) => {
    const {authData} = useAuthStateManager();
    const [editedCollection, setEditedCollection] = useState(collection);
    const [collectionJoinCode, setCollectionJoinCode] =
        useState<CollectionJoinCodeModel | null>(null);
    const [channels, setChannels] = useState<ChannelInfoModel[]>([]);
    const [groupMemberInfoFields, setGroupMemberInfoFields] = useState<
        GroupMemberInfoFieldModel[]
    >([]);
    const [showChannelSettingsModal, setShowChannelSettingsModal] =
        useState(false);
    const [showEditFieldModal, setShowEditFieldModal] = useState(false);
    const [channelInfo, setChannelInfo] = useState<ChannelInfoModel | null>(
        null
    );
    const [fieldBeingEdited, setFieldBeingEdited] =
        useState<GroupMemberInfoFieldModel | null>(null);

    const getCollectionChannels = useGetCollectionChannels({
        collectionId: collection.id!
    });
    const {data: fetchedChannels, refetch: refetchChannels} = useQuery(
        ['collectionChannels', collection.id],
        getCollectionChannels
    );

    const getCollectionJoinCode = useGetCollectionJoinCode({
        collectionId: collection.id!
    });
    const {data: fetchedCollectionJoinCode} = useQuery(
        ['collectionJoinCode', collection.id],
        getCollectionJoinCode
    );

    const getGroupMemberInfoFieldsByCollectionId =
        useGetGroupMemberInfoFieldsByCollectionId({
            collectionId: collection.id!
        });
    const {
        data: fetchedGroupMemberInfoFields,
        refetch: refetchGroupMemberInfoFields
    } = useQuery(
        ['groupMemberInfoFieldsByCollectionId', collection.id],
        getGroupMemberInfoFieldsByCollectionId
    );

    const updateCollectionMutation = useMutation(useUpdateCollection(), {
        onSuccess: () => {
            toast.success('Collection updated.');
            onCollectionUpdated();
        },
        onError: (error: unknown) => {
            toast.error(
                'Unable to update collection. Please see validation errors. ' +
                    unknownErrorToString(error)
            );
        }
    });

    const updateCollectionThumbnailMutation = useMutation(
        useUpdateCollectionThumbnail(),
        {
            onSuccess: result => {
                if (!result || !result.thumbnail) {
                    toast.error('Unable to update thumbnail.');
                } else {
                    setEditedCollection(prev => ({
                        ...prev,
                        thumbnail: result.thumbnail
                    }));
                    onCollectionUpdated();
                }
            },
            onError: (error: unknown) => {
                toast.error(
                    'Unable to update thumbnail. ' + unknownErrorToString(error)
                );
            }
        }
    );

    const createChannelMutation = useMutation(useCreateChannel(), {
        onSuccess: () => {
            onChannelUpdated();
            setShowChannelSettingsModal(false);
            refetchChannels();
        },
        onError: (error: unknown) => {
            toast.error(unknownErrorToString(error));
        }
    });

    const updateChannelMutation = useMutation(useUpdateChannel(), {
        onSuccess: () => {
            onChannelUpdated();
            setShowChannelSettingsModal(false);
            refetchChannels();
        },
        onError: (error: unknown) => {
            toast.error(unknownErrorToString(error));
        }
    });

    const updateOrdinalPositionsMutation = useMutation(
        useUpdateOrdinalPositions(),
        {
            onSuccess: () => {},
            onError: (error: unknown) => {
                toast.error(unknownErrorToString(error));
            }
        }
    );

    const upsertGroupMemberInfoFieldMutation = useMutation(
        useUpsertGroupMemberInfoField(),
        {
            onSuccess: async () => {
                await refetchGroupMemberInfoFields();
                setShowEditFieldModal(false);
            },
            onError: (error: unknown) => {
                toast.error(
                    'Unable to save field. ' + unknownErrorToString(error)
                );
            }
        }
    );

    const deleteGroupMemberInfoFieldMutation = useMutation(
        useDeleteGroupMemberInfoField(),
        {
            onSuccess: async () => {
                await refetchGroupMemberInfoFields();
                setShowEditFieldModal(false);
            },
            onError: (error: unknown) => {
                toast.error(
                    'Unable to delete field. ' + unknownErrorToString(error)
                );
            }
        }
    );

    const archiveCollectionMutation = useMutation(useArchiveCollection(), {
        onSuccess: async () => {
            await onCollectionUpdated();
        },
        onError: (error: unknown) => {
            toast.error(
                'Unable to archive group. ' + unknownErrorToString(error)
            );
        }
    });

    useEffect(() => {
        if (fetchedChannels) {
            setChannels(fetchedChannels);
        }
    }, [fetchedChannels]);

    useEffect(() => {
        if (fetchedCollectionJoinCode) {
            setCollectionJoinCode(fetchedCollectionJoinCode);
        }
    }, [fetchedCollectionJoinCode]);

    useEffect(() => {
        if (fetchedGroupMemberInfoFields) {
            setGroupMemberInfoFields(fetchedGroupMemberInfoFields);
        }
    }, [fetchedGroupMemberInfoFields]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        await updateCollectionMutation.mutateAsync({
            collection: editedCollection,
            collectionJoinCode
        });
    };

    const handleThumbnailChanged = async (src: string) => {
        if (isValidURL(src)) {
            // NOTE: If it's a valid URL it has been updated on server
            return;
        }

        await updateCollectionThumbnailMutation.mutateAsync({
            collectionId: collection.id!,
            src
        });
    };

    const handleMoveSection = async (move: Move, index: number) => {
        const indexA = index;
        const indexB = move === Move.Up ? index - 1 : index + 1;
        const updatedFields = [...groupMemberInfoFields];
        [updatedFields[indexA], updatedFields[indexB]] = [
            updatedFields[indexB],
            updatedFields[indexA]
        ];

        for (let i = 0; i < updatedFields.length; i++) {
            updatedFields[i].ordinalPosition = i;
        }

        setGroupMemberInfoFields(updatedFields);

        try {
            await updateOrdinalPositionsMutation.mutateAsync({
                tableName: 'GroupMemberInfoField',
                orderedEntities: updatedFields
            });
        } catch (error) {
            toast.error('Unable to update field positions.');
        }
    };

    const handleSaveChannel = async (updatedChannelInfo: ChannelInfoModel) => {
        setChannelInfo(updatedChannelInfo);
        if (!updatedChannelInfo.id) {
            await createChannelMutation.mutateAsync({
                collectionId: collection.id!,
                name: updatedChannelInfo.name,
                topic: updatedChannelInfo.topic,
                isDefault: updatedChannelInfo.isDefault
            });
            setChannels(prev => [...prev, updatedChannelInfo]);
        } else {
            await updateChannelMutation.mutateAsync({
                id: updatedChannelInfo.id,
                collectionId: updatedChannelInfo.collectionId,
                name: updatedChannelInfo.name,
                topic: updatedChannelInfo.topic,
                isDefault: updatedChannelInfo.isDefault,
                isDeleted: updatedChannelInfo.isDeleted
            });
            setChannels(prev =>
                prev.map(channel =>
                    channel.id === updatedChannelInfo.id
                        ? updatedChannelInfo
                        : channel
                )
            );
        }
    };

    const handleArchiveChannel = async (
        updatedChannelInfo: ChannelInfoModel
    ) => {
        updatedChannelInfo.isDeleted = true;
        await handleSaveChannel(updatedChannelInfo);
    };

    const handleArchiveCollection = async () => {
        await archiveCollectionMutation.mutateAsync({
            collectionId: collection.id!,
            setAsArchived: !collection.archived
        });
    };

    const handleUpsertField = async (field: GroupMemberInfoFieldModel) => {
        await upsertGroupMemberInfoFieldMutation.mutateAsync({
            collectionId: collection.id!,
            model: field
        });
    };

    const handleDeleteField = async (fieldId: string) => {
        await deleteGroupMemberInfoFieldMutation.mutateAsync({
            collectionId: collection.id,
            id: fieldId
        });
    };

    const handleAddNewField = () => {
        const ordinalPosition = groupMemberInfoFields.length
            ? Math.max(
                  ...groupMemberInfoFields.map(field => field.ordinalPosition)
              ) + 1
            : 0;

        const field: GroupMemberInfoFieldModel = {
            id: null,
            collectionId: collection.id!,
            introContent: '',
            maxLength: null,
            options: [],
            label: '',
            fieldType: GroupMemberInfoFieldType.Text,
            editableBy:
                GroupMemberInfoFieldEditableBy.Admin |
                GroupMemberInfoFieldEditableBy.Member,
            ordinalPosition
        };

        setFieldBeingEdited(field);
        setShowEditFieldModal(true);
    };

    const handleAddNewChannel = () => {
        const channelInfo: ChannelInfoModel = {
            id: null,
            collectionId: collection.id!,
            createdOnUtc: dateTimeUtcNow(),
            name: '',
            topic: '',
            isDefault: false,
            isDeleted: false
        };
        setChannelInfo(channelInfo);
        setShowChannelSettingsModal(true);
    };

    const handleReactivateChannel = (channelInfo: ChannelInfoModel) => {
        channelInfo.isDeleted = false;
        setChannelInfo(channelInfo);
        setShowChannelSettingsModal(true);
    };

    const onUserCollectionHomeProjectChanged = async (mixId: string | null) => {
        setEditedCollection(prev => ({
            ...prev!,
            homeMixId: mixId
        }));

        await updateCollectionMutation.mutateAsync({
            collection: {...editedCollection, homeMixId: mixId},
            collectionJoinCode
        });
    };

    const membershipUrl = `${location.protocol}//${location.hostname}${
        location.port ? ':' + location.port : ''
    }/explore/groups/${collection.containerName}?tab=Membership`;

    if (editedCollection.collectionType === CollectionType.User) {
        return (
            <div className="container-fluid custom-container mt-5 mb-5">
                <div className="row">
                    <div className="col-12">
                        <div className="content-card">
                            <div className="card-content">
                                <GroupHomeProject
                                    collection={editedCollection}
                                    onHomeProjectChanged={
                                        onUserCollectionHomeProjectChanged
                                    }
                                    myProjects={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <SpinnerIfLoading loading={!editedCollection}>
            {(updateCollectionMutation.isLoading ||
                updateCollectionThumbnailMutation.isLoading ||
                createChannelMutation.isLoading ||
                updateChannelMutation.isLoading ||
                updateOrdinalPositionsMutation.isLoading ||
                upsertGroupMemberInfoFieldMutation.isLoading ||
                deleteGroupMemberInfoFieldMutation.isLoading) && (
                <OverlayWithSpinner />
            )}
            <CustomContainer className="my-5">
                <MxCard contentCard>
                    <div>
                        <div className="row align-items-top">
                            <div className="col-12 col-lg-6 col-xxl-4">
                                <ThumbnailEditor
                                    src={editedCollection.thumbnail}
                                    readOnly={false}
                                    onThumbnailChanged={handleThumbnailChanged}
                                    id={editedCollection.id}
                                />
                            </div>
                            <div className="col-12 col-lg mt-4 mt-lg-0">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="name"
                                            className="form-label"
                                        >
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            className="form-control"
                                            maxLength={64}
                                            value={editedCollection.name}
                                            onChange={e =>
                                                setEditedCollection(prev => ({
                                                    ...prev,
                                                    name: e.target.value
                                                }))
                                            }
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="description"
                                            className="form-label"
                                        >
                                            Description
                                        </label>
                                        <input
                                            type="text"
                                            id="description"
                                            className="form-control"
                                            maxLength={1024}
                                            value={editedCollection.description}
                                            onChange={e =>
                                                setEditedCollection(prev => ({
                                                    ...prev,
                                                    description: e.target.value
                                                }))
                                            }
                                        />
                                    </div>
                                    <GroupHomeProject
                                        collection={editedCollection}
                                        onHomeProjectChanged={mixId =>
                                            setEditedCollection(prev => ({
                                                ...prev!,
                                                homeMixId: mixId
                                            }))
                                        }
                                    />
                                    <hr />
                                    {collectionJoinCode && (
                                        <InviteCodes
                                            collectionJoinCode={
                                                collectionJoinCode
                                            }
                                            setCollectionJoinCode={
                                                setCollectionJoinCode
                                            }
                                            membershipUrl={membershipUrl}
                                            groupName={collection.name}
                                        />
                                    )}
                                    <hr />
                                    {authData.roles.includes(
                                        RoleNames.Admin
                                    ) && (
                                        <>
                                            <div className="mb-3">
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        id="public"
                                                        className="form-check-input"
                                                        checked={
                                                            editedCollection.public
                                                        }
                                                        onChange={e =>
                                                            setEditedCollection(
                                                                prev => ({
                                                                    ...prev,
                                                                    public: e
                                                                        .target
                                                                        .checked
                                                                })
                                                            )
                                                        }
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="public"
                                                    >
                                                        Public - other users can
                                                        browse this groups'
                                                        shared projects
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="collectionType"
                                                    className="form-label"
                                                >
                                                    Collection type
                                                </label>
                                                <EnumSelect
                                                    value={
                                                        editedCollection.collectionType
                                                    }
                                                    onValueChange={collectionType =>
                                                        setEditedCollection(
                                                            prev => ({
                                                                ...prev,
                                                                collectionType:
                                                                    Number(
                                                                        collectionType
                                                                    )
                                                            })
                                                        )
                                                    }
                                                    enumType={CollectionType}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <ConfirmButton
                                            cssClass="btn btn-danger"
                                            onClick={handleArchiveCollection}
                                            message={`Are you sure you want to ${
                                                collection.archived
                                                    ? 'un-archive group'
                                                    : 'archive group'
                                            } this group?`}
                                        >
                                            {collection.archived
                                                ? 'Un-archive group'
                                                : 'Archive group'}
                                        </ConfirmButton>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </MxCard>
            </CustomContainer>

            <CustomContainer className="my-5">
                <MxCard contentCard>
                    {collection.collectionType === CollectionType.Standard &&
                        channels !== null && (
                            <div className="row align-items-center">
                                <div className="mt-3">
                                    <h5 className="fw-bold mb-3">
                                        Manage channels
                                    </h5>
                                    <MxTable
                                        mxThead={
                                            <tr>
                                                <th>Name</th>
                                                <th>Topic</th>
                                                <th>Default</th>
                                                <th>Archived</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        }
                                    >
                                        {channels.map((channel, index) => (
                                            <tr key={index}>
                                                <td>#{channel.name}</td>
                                                <td>{channel.topic}</td>
                                                <td>
                                                    {channel.isDefault ? (
                                                        <span>Yes</span>
                                                    ) : (
                                                        <span>No</span>
                                                    )}
                                                </td>
                                                {channel.isDeleted ? (
                                                    <>
                                                        <td>
                                                            <span>Yes</span>
                                                        </td>
                                                        <td>
                                                            <a
                                                                href=""
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    handleReactivateChannel(
                                                                        channel
                                                                    );
                                                                }}
                                                            >
                                                                Reactivate
                                                            </a>
                                                        </td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td>
                                                            <span>No</span>
                                                        </td>
                                                        <td>
                                                            <a
                                                                href=""
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    setChannelInfo(
                                                                        channel
                                                                    );
                                                                    setShowChannelSettingsModal(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                Edit
                                                            </a>
                                                        </td>
                                                    </>
                                                )}
                                            </tr>
                                        ))}
                                    </MxTable>
                                </div>
                                <div className="mb-3">
                                    <button
                                        type="button"
                                        className="btn btn-primary float-end"
                                        onClick={() => handleAddNewChannel()}
                                    >
                                        Add new channel
                                    </button>
                                    <div className="clearfix"></div>
                                </div>
                                {showChannelSettingsModal && (
                                    <ChannelSettingsModal
                                        key={`${channelInfo!.name}_channel`}
                                        channelInfo={channelInfo!}
                                        onDismiss={() => {
                                            setChannelInfo(null);
                                            setShowChannelSettingsModal(false);
                                        }}
                                        onSave={handleSaveChannel}
                                        onArchive={handleArchiveChannel}
                                    />
                                )}
                            </div>
                        )}

                    <div>
                        <h5 className="fw-bold mt-3">
                            Group member info fields
                        </h5>
                        <MxTable
                            mxThead={
                                <tr>
                                    <th>Label</th>
                                    <th>Field type</th>
                                    <th>Editable by</th>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                </tr>
                            }
                        >
                            {groupMemberInfoFields.map((field, index) => (
                                <tr key={index}>
                                    <td>{field.label}</td>
                                    <td>
                                        {breakToWords(
                                            GroupMemberInfoFieldType[
                                                field.fieldType
                                            ]
                                        )}
                                    </td>
                                    <td>
                                        {getEnumFlagsText(
                                            field.editableBy,
                                            GroupMemberInfoFieldEditableBy
                                        )}
                                    </td>
                                    <td>
                                        <a
                                            href="#"
                                            onClick={e => {
                                                e.preventDefault();
                                                setFieldBeingEdited(field);
                                                setShowEditFieldModal(true);
                                            }}
                                        >
                                            Edit
                                        </a>
                                    </td>
                                    <td className="col-sm-1">
                                        <div className="d-flex float-end">
                                            {groupMemberInfoFields.length >
                                                1 && (
                                                <div className="post-toolbar">
                                                    {index !==
                                                        groupMemberInfoFields.length -
                                                            1 && (
                                                        <a
                                                            className="action-link mx-1"
                                                            role="button"
                                                            title="Move field DOWN"
                                                            onClick={() =>
                                                                handleMoveSection(
                                                                    Move.Down,
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faLongArrowAltDown
                                                                }
                                                            />
                                                        </a>
                                                    )}
                                                    {index !== 0 && (
                                                        <a
                                                            className="action-link mx-1"
                                                            role="button"
                                                            title="Move field UP"
                                                            onClick={() =>
                                                                handleMoveSection(
                                                                    Move.Up,
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faLongArrowAltUp
                                                                }
                                                            />
                                                        </a>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </MxTable>
                        <div className="mt-3">
                            <button
                                type="button"
                                className="btn btn-primary float-end"
                                onClick={() => handleAddNewField()}
                            >
                                Add new field
                            </button>
                            <div className="clearfix"></div>
                        </div>
                        {showEditFieldModal && (
                            <EditGroupMemberInfoFieldModal
                                field={fieldBeingEdited!}
                                setField={assertNonNullStateSetter(
                                    setFieldBeingEdited
                                )}
                                onDismiss={() => {
                                    setFieldBeingEdited(null);
                                    setShowEditFieldModal(false);
                                }}
                                onSave={() =>
                                    handleUpsertField(fieldBeingEdited!)
                                }
                                onArchive={() =>
                                    handleDeleteField(fieldBeingEdited!.id!)
                                }
                            />
                        )}
                    </div>
                </MxCard>
            </CustomContainer>
        </SpinnerIfLoading>
    );
};

export default GroupSettingsTab;
