export default function getEnumFlagsText<T extends object>(
    value: number,
    enumObj: T
): string {
    const flags: string[] = [];
    for (const key in enumObj) {
        if (Object.prototype.hasOwnProperty.call(enumObj, key)) {
            const enumValue = enumObj[key as keyof T] as number;
            if ((value & enumValue) !== 0) {
                flags.push(key);
            }
        }
    }
    return flags.length > 0 ? flags.join(', ') : 'None';
}
