export default function mergeDataImpl(
    sourceChanges: Record<string, unknown>,
    target: Record<string, unknown>
): void {
    const targetObject = target;

    if (targetObject instanceof Object && !(targetObject instanceof Array)) {
        const targetDict = targetObject as Record<string, unknown>;

        for (const [key, value] of Object.entries(sourceChanges)) {
            if (
                value instanceof Object &&
                !(value instanceof Array) &&
                key in targetDict
            ) {
                const existingValue = targetDict[key];
                if (
                    existingValue instanceof Object &&
                    !(existingValue instanceof Array)
                ) {
                    mergeDataImpl(
                        value as Record<string, unknown>,
                        existingValue as Record<string, unknown>
                    ); // Recursive call for nested dictionaries
                } else {
                    targetDict[key] = value;
                }
            } else if (Array.isArray(value)) {
                const sourceArray = value as unknown[];
                if (key in targetDict) {
                    const existingArray = targetDict[key];
                    if (Array.isArray(existingArray)) {
                        mergeArrays(sourceArray, existingArray as unknown[]); // Merge the arrays
                    } else {
                        targetDict[key] = [...sourceArray]; // Create a new array in the target
                    }
                } else {
                    targetDict[key] = [...sourceArray]; // Create a new array in the target
                }
            } else {
                targetDict[key] = value;
            }
        }
    } else {
        throw new Error('Final target for merge is not a dictionary.');
    }
}

function mergeArrays(source: unknown[], target: unknown[]): void {
    const maxLength = Math.max(source.length, target.length);
    for (let i = 0; i < maxLength; i++) {
        if (i >= source.length) {
            target.splice(i, target.length - i); // Remove extra elements from the target array
            break;
        }
        if (i >= target.length) {
            target.push(source[i]); // Add new elements from the source array to the target array
        } else if (
            source[i] instanceof Object &&
            !(source[i] instanceof Array) &&
            target[i] instanceof Object &&
            !(target[i] instanceof Array)
        ) {
            mergeDataImpl(
                source[i] as Record<string, unknown>,
                target[i] as Record<string, unknown>
            ); // Recursive call for nested dictionaries
        } else if (Array.isArray(source[i]) && Array.isArray(target[i])) {
            mergeArrays(source[i] as unknown[], target[i] as unknown[]); // Recursive call for nested arrays
        } else if (source[i] !== undefined && source[i] !== null) {
            target[i] = source[i]; // Update the target element with the source element
        }
    }
}
