import React, {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import DOMPurify from 'dompurify';

interface HtmlComponentProps {
    html?: string | null;
}

const HtmlComponent: React.FC<HtmlComponentProps> = ({html}) => {
    const contentRef = useRef<HTMLSpanElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!contentRef.current) {
            return;
        }

        const links = contentRef.current.querySelectorAll('a');
        const handleClick = (event: MouseEvent) => {
            const target = event.target as HTMLAnchorElement;
            const href = target.getAttribute('href');
            if (!href) {
                return;
            }

            // Check if the href starts with 'http:' or 'https:'
            if (href.startsWith('http://') || href.startsWith('https://')) {
                // It's a fully qualified link, force such to open in a new tab/window:
                event.preventDefault();
                window.open(href, '_blank');
            } else {
                // It's a relative link, intercept and use navigate
                event.preventDefault();
                navigate(href);
            }
        };

        links.forEach(link => {
            link.addEventListener('click', handleClick as EventListener);
        });

        return () => {
            links.forEach(link => {
                link.removeEventListener('click', handleClick as EventListener);
            });
        };
    }, [navigate]);

    if (!html) {
        return null;
    }

    const createMarkup = (htmlString: string) => {
        DOMPurify.addHook('uponSanitizeElement', function (node, data) {
            if (data.tagName === 'iframe') {
                // Check if the iframe's `src` attribute matches the allowed pattern
                const srcAttr = node.getAttribute('src');
                if (!srcAttr) {
                    //  Remove iframe if no src attribute
                    return node.parentNode?.removeChild(node);
                }

                const youtubeRegExp =
                    /^https:\/\/www\.youtube-nocookie\.com\/embed\/[a-zA-Z0-9_-]+(?:\?.*)?$/;

                if (!youtubeRegExp.test(srcAttr)) {
                    // Remove iframe if src attribute doesn't match explkicit url pattern from the lexical youtube plugin
                    return node.parentNode?.removeChild(node);
                }
            }
        });

        return {
            __html: DOMPurify.sanitize(htmlString, {
                ADD_TAGS: ['iframe']
            })
        };
    };

    return (
        <span ref={contentRef} dangerouslySetInnerHTML={createMarkup(html)} />
    );
};

export default HtmlComponent;
