import React from 'react';
import {FormGroup, Label, Input} from 'reactstrap';

interface CylinderEditorProps {
    height: number;
    diameter: number;
    onHeightChange: (height: number) => void;
    onDiameterChange: (diameter: number) => void;
}

const CylinderEditor: React.FC<CylinderEditorProps> = ({
    height,
    diameter,
    onHeightChange,
    onDiameterChange
}) => {
    const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onHeightChange(isNaN(value) ? 0 : value);
    };

    const handleDiameterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onDiameterChange(isNaN(value) ? 0 : value);
    };

    return (
        <>
            <FormGroup>
                <Label>Height:</Label>
                <Input
                    type="number"
                    step="0.1"
                    value={height}
                    onChange={handleHeightChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Diameter:</Label>
                <Input
                    type="number"
                    step="0.1"
                    value={diameter}
                    onChange={handleDiameterChange}
                />
            </FormGroup>
        </>
    );
};

export default CylinderEditor;
