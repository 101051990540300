import {EmptyResult, ProjectDataIndex} from '../types';
import {usePost} from '../useHttpHooks';

export type TranslateCommand = {
    outputId: string;
    language: string;
    text: string | null;
    mixId: string | null;
    dataIndex: ProjectDataIndex | null;
    contentPackageId: string | null;
};

const useTranslate = () =>
    usePost<TranslateCommand, EmptyResult>(`/api/assistant/translate`, {
        errorMessage: 'POST api/assistant/translate failed'
    });

export default useTranslate;
