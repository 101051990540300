import React, {useState} from 'react';
import {Button, ButtonGroup, ButtonToolbar} from 'reactstrap';
import {BsPencil} from 'react-icons/bs';
import {FaRegSquareFull} from 'react-icons/fa6';
import {GrCursor} from 'react-icons/gr';
import {FaRegCircle} from 'react-icons/fa6';
import {TbTextResize} from 'react-icons/tb';
import {GoImage} from 'react-icons/go';
import {FiVideo} from 'react-icons/fi';
import {FaRegEye} from 'react-icons/fa';
import {MdOutlineAddReaction} from 'react-icons/md';
import {IoCamera, IoHomeOutline, IoMove} from 'react-icons/io5';
import CanvasController from './CanvasController.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChalkboard} from '@fortawesome/free-solid-svg-icons';
import SelectProjectModal from '../projects/SelectProjectModal.tsx';
import {MixModel} from '../../api/types.ts';
import {AiOutlineBgColors} from 'react-icons/ai';
import AddToJournalModal from './AddToJournalModal.tsx';

interface MainToolbarProps {
    isReaction: boolean;
    controlsDisabled: boolean;
    setIsReaction: () => void;
    controller: CanvasController;
    readOnly: boolean;
    reactionsEnabled: boolean;
    renderToken: number;
    mixId: string;
}

const MainToolbar: React.FC<MainToolbarProps> = ({
    isReaction,
    controlsDisabled,
    setIsReaction,
    controller,
    readOnly,
    reactionsEnabled,
    mixId
}) => {
    const [showSelectProjectModel, setShowSelectProjectModel] = useState(false);

    const [dataUri, setDataUri] = useState<string | null>(null);

    const onProjectSelected = (mix: MixModel) => {
        controller.addProjectLink(mix);
        setShowSelectProjectModel(false);
    };

    const handleCapture = () => {
        const dataUri = controller.captureCanvasImage();
        if (dataUri) {
            setDataUri(dataUri);
        }
    };

    return (
        <>
            {dataUri && (
                <AddToJournalModal
                    captureDataUri={dataUri}
                    onClose={() => setDataUri(null)}
                    mixId={mixId}
                />
            )}
            {showSelectProjectModel && (
                <SelectProjectModal
                    onClose={() => setShowSelectProjectModel(false)}
                    onProjectSelected={onProjectSelected}
                />
            )}
            <ButtonToolbar>
                <ButtonGroup className="me-2" size="sm">
                    <Button
                        title="Re-center Canvas"
                        onClick={() => controller.handleRecenterCanvas()}
                    >
                        <IoHomeOutline size={19} />
                    </Button>
                    {!readOnly && (
                        <>
                            <Button
                                title="Select"
                                className={`buttons-that-select ${
                                    controller.isSelectActive ? 'active' : ''
                                }`}
                                onClick={() => controller.selectClicked()}
                                disabled={controlsDisabled}
                            >
                                <GrCursor size={17} />
                            </Button>
                            <Button
                                title="Move canvas"
                                className={`buttons-that-select ${
                                    controller.movingCanvas ? 'active' : ''
                                }`}
                                onClick={() => controller.toggleMovingCanvas()}
                                disabled={controlsDisabled}
                            >
                                <IoMove size={20} />
                            </Button>
                            <Button
                                title="Read-Only Mode"
                                className={`buttons-that-select ${
                                    controller.isReadOnly ? 'active' : ''
                                }`}
                                onClick={() => controller.toggleReadOnlyMode()}
                            >
                                <FaRegEye size={20} />
                            </Button>

                            <Button
                                title="Screenshot to journal"
                                className={`buttons-that-select ${
                                    controller.isReadOnly ? 'active' : ''
                                }`}
                                onClick={handleCapture}
                            >
                                <IoCamera size={20} />
                            </Button>

                            <Button className="toolbar-buttons">
                                <input
                                    type="color"
                                    title="Change Color"
                                    value={controller.currentColor}
                                    style={{
                                        height: '15px',
                                        width: '20px',
                                        alignSelf: 'center'
                                    }}
                                    onChange={e => {
                                        controller.handleColorChange(
                                            e.target.value
                                        );
                                    }}
                                />
                            </Button>

                            <Button
                                title="Draw"
                                className={`buttons-that-select ${
                                    controller.isDrawing ? 'active' : ''
                                }`}
                                onClick={() => controller.toggleIsDrawing()}
                                disabled={controlsDisabled}
                            >
                                <BsPencil size={17} />
                            </Button>
                            <Button
                                title="Add Rectangle"
                                className="toolbar-buttons"
                                onClick={() => controller.addRectangle()}
                                disabled={controlsDisabled}
                            >
                                <FaRegSquareFull size={15} />
                            </Button>
                            <Button
                                title="Add Circle"
                                className="toolbar-buttons"
                                onClick={() => controller.addCircle()}
                                disabled={controlsDisabled}
                            >
                                <FaRegCircle size={17} />
                            </Button>
                            <Button
                                title="Add Text"
                                className="toolbar-buttons"
                                onClick={() => controller.addText()}
                                disabled={controlsDisabled}
                            >
                                <TbTextResize size={20} />
                            </Button>
                            <Button
                                title="Add Image"
                                className="toolbar-buttons"
                                onClick={() => controller.addImage()}
                                disabled={controlsDisabled}
                            >
                                <GoImage size={20} />
                            </Button>
                            <Button
                                title="Add Video"
                                className="toolbar-buttons"
                                onClick={() => controller.addVideo()}
                                disabled={controlsDisabled}
                            >
                                <FiVideo size={20} />
                            </Button>
                            <Button
                                title="Link to project"
                                className="toolbar-buttons"
                                onClick={() => setShowSelectProjectModel(true)}
                                disabled={controlsDisabled}
                            >
                                <FontAwesomeIcon
                                    icon={faChalkboard}
                                    size="lg"
                                />
                            </Button>
                            <Button
                                title="Change canvas background color"
                                className="toolbar-buttons"
                                onClick={() =>
                                    controller.handleBackgroundColorChange()
                                }
                                disabled={controlsDisabled}
                            >
                                <AiOutlineBgColors size={22} />
                            </Button>
                        </>
                    )}
                    {reactionsEnabled && (
                        <Button
                            title="Reactions"
                            className={`buttons-that-select ${
                                isReaction ? 'active' : ''
                            }`}
                            onClick={setIsReaction}
                        >
                            <MdOutlineAddReaction size={20} />
                        </Button>
                    )}
                </ButtonGroup>
            </ButtonToolbar>
        </>
    );
};

export default MainToolbar;
