import {UserInfo} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetPotentialMentionsQuery = {
    collectionId: string | null;
};

const useGetPotentialMentions = (request: GetPotentialMentionsQuery) =>
    useGet<UserInfo[]>(
        `/api/groups/get-potential-mentions?${toQuery(request)}`,
        {errorMessage: 'GET api/groups/get-potential-mentions failed'}
    );

export default useGetPotentialMentions;
