import {RenameFileResult} from '../types';
import {usePost} from './../useHttpHooks';

export type RenameFileCommand = {
    containerName: string;
    sourceFilePath: string;
    destinationFilePath: string;
};

const useRenameFile = () =>
    usePost<RenameFileCommand, RenameFileResult>(`/api/uploads/rename-file`, {errorMessage: 'POST api/uploads/rename-file failed'
});

export default useRenameFile;
