import React from 'react';
import {FileDataModel, Selection} from '../../api/types.ts';
import FileFormats, {FileFormat} from '../../lib/FileFormats.ts';
import CopyButton from '../shared/CopyButton.tsx';
import baseUri from '../../api/baseUri.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faCircle,
    faDotCircle,
    faSquare,
    faSquareFull,
    faVrCardboard
} from '@fortawesome/free-solid-svg-icons';

interface AssetSelectorFileProps {
    file: FileDataModel;
    selection: Selection;
    fileSelectionChanged: (file: FileDataModel) => void;
    formats?: FileFormat;
    fileIsSelected: (file: string) => boolean;
}

const AssetSelectorFile: React.FC<AssetSelectorFileProps> = ({
    file,
    selection,
    fileSelectionChanged,
    formats,
    fileIsSelected
}) => {
    const isSelectable = (() => {
        if (selection == Selection.None) {
            return false;
        }

        const extensions = formats ? FileFormats.getExtensions(formats) : ['*'];
        if (!formats || extensions[0] == '*') {
            return true;
        }

        return extensions.includes(file.extension.toLowerCase());
    })();

    const selected = fileIsSelected(file.path);

    const rowStyle = isSelectable ? {cursor: 'pointer'} : undefined;

    const onRowClicked = () => {
        if (isSelectable) {
            fileSelectionChanged(file);
        }
    };

    return (
        <div
            className="uploads-list-row justify-content-start overflow-hidden"
            style={rowStyle}
            onClick={onRowClicked}
        >
            {isSelectable && (
                <div className="me-2">
                    {selection === Selection.Single && (
                        <FontAwesomeIcon
                            icon={selected ? faDotCircle : faCircle}
                        />
                    )}
                    {selection === Selection.Multiple && (
                        <FontAwesomeIcon
                            icon={selected ? faSquareFull : faSquare}
                        />
                    )}
                </div>
            )}
            <div
                className={`d-flex character-limit-100 ${
                    selected ? 'fw-bold' : ''
                }`}
                title={file.fileName}
            >
                {file.fileName}
            </div>
            <div className="folder-action-icons">
                {selection === Selection.None && (
                    <>
                        <CopyButton
                            cssClass="action-link"
                            copyText={file.uri}
                            title="Copy file path"
                        />
                        {file.canViewInVR && (
                            <a
                                className="action-link"
                                href={`${baseUri}/vr?url=${encodeURIComponent(
                                    file.uri
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faVrCardboard} />
                            </a>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default AssetSelectorFile;
