import React, {useState} from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner
} from 'reactstrap';
import FileFormats, {FileFormat} from '../../lib/FileFormats.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faAsterisk,
    faCube,
    faHeadphones,
    faImage,
    faPlay
} from '@fortawesome/free-solid-svg-icons';
import {FileDataModel} from '../../api/types.ts';
import GoogleDriveUploadPicker from './google_drive/GoogleDriveUploadPicker.tsx';
import DropboxUploadPicker from './drop_box/DropboxUploadPicker.tsx';
import ImageEditor from './image_editor/ImageEditor.tsx';
import UploadFromComputer from './UploadFromComputer.tsx';
import {MdOutlineUploadFile} from 'react-icons/md';
import useGoogleDrivePicker, {
    PickedFileInfo
} from './google_drive/useGoogleDrivePicker.ts';
import ConfirmGoogleDriveUpload from './google_drive/ConfirmGoogleDriveUpload.tsx';

interface UploadModalProps {
    folderPath?: string | null;
    specificContainerName?: string | null;
    onClose: () => void;
    onFilesUploaded: (files: FileDataModel[]) => void;
    formats?: FileFormat;
}

const UploadModal: React.FC<UploadModalProps> = ({
    folderPath,
    specificContainerName,
    onClose,
    onFilesUploaded,
    formats
}) => {
    const [backendError, setBackendError] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFormat, setSelectedFormat] = useState<FileFormat | null>(
        formats === undefined ? null : formats
    );
    const [showUploadFromComputer, setShowUploadFromComputer] = useState(false);
    const [editedImage, setEditedImage] = useState<File | null>(null);

    const handleFileTypeSelect = (fileType: FileFormat) => {
        setSelectedFormat(fileType);
    };

    const showImageEditor =
        selectedFormat === FileFormat.Image &&
        selectedFile &&
        selectedFile.name.split('.').pop()?.toLowerCase() !== 'zip';

    const dropboxExtensions =
        selectedFormat === null
            ? undefined
            : FileFormats.getExtensions(selectedFormat, true).map(f => `.${f}`);

    const [googleDriveFile, setGoogleDriveFile] =
        useState<PickedFileInfo | null>(null);

    const [downloadingImage, setDownloadingImage] = useState(false);

    const downloadGoogleDriveImage = async (file: PickedFileInfo) => {
        try {
            setDownloadingImage(true);
            if (file.accessToken) {
                const response = await window.fetch(
                    `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`,
                    {
                        headers: {
                            Authorization: `Bearer ${file.accessToken}`
                        }
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const blob = await response.blob();
                const downloadedFile = new File([blob], file.name, {
                    type: blob.type
                });

                setSelectedFile(downloadedFile);
            } else {
                console.error('no authResponse');
            }
        } catch (error) {
            console.error('download error', error);
        } finally {
            setDownloadingImage(false);
        }
    };

    async function onPicked(file: PickedFileInfo) {
        if (file.type === 'photo' && selectedFormat === FileFormat.Image) {
            await downloadGoogleDriveImage(file);
            setShowUploadFromComputer(true);
        } else {
            setGoogleDriveFile(file);
        }
    }

    const {openPicker} = useGoogleDrivePicker(onPicked, formats, true);

    const onDropboxImageSelected = (file: File) => {
        setSelectedFile(file);
        setShowUploadFromComputer(true);
    };

    const modalBodyContent = () => {
        if (selectedFormat !== null) {
            if (googleDriveFile != null) {
                return (
                    <ConfirmGoogleDriveUpload
                        cancel={() => setGoogleDriveFile(null)}
                        onClose={onClose}
                        file={googleDriveFile}
                        onFilesUploaded={onFilesUploaded}
                        specificContainerName={specificContainerName}
                    />
                );
            }

            if (downloadingImage) {
                return (
                    <div className="spinner-full">
                        <Spinner color="primary" />
                    </div>
                );
            }

            return (
                <>
                    {formats === undefined && (
                        <div className="mb-3">
                            <a
                                href="#"
                                onClick={event => {
                                    event.preventDefault();
                                    setSelectedFormat(null);
                                    setShowUploadFromComputer(false);
                                }}
                            >
                                Choose a different type of asset
                            </a>
                        </div>
                    )}

                    {showImageEditor && (
                        <div>
                            <ImageEditor
                                selectedImage={selectedFile}
                                onImageEdited={file =>
                                    typeof file === 'function'
                                        ? setSelectedFile(file(selectedFile))
                                        : setSelectedFile(file)
                                }
                                editedImage={editedImage}
                                setEditedImage={setEditedImage}
                            />
                        </div>
                    )}

                    {showUploadFromComputer && (
                        <UploadFromComputer
                            folderPath={folderPath}
                            specificContainerName={specificContainerName}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            onClose={onClose}
                            onFilesUploaded={onFilesUploaded}
                            backendError={backendError}
                            setBackendError={setBackendError}
                            selectedFormat={selectedFormat}
                            setShowUploadFromComputer={
                                setShowUploadFromComputer
                            }
                            getEditedImage={() => editedImage}
                        />
                    )}
                    {!showUploadFromComputer && (
                        <div className="file-type-buttons">
                            <div style={{textAlign: 'center'}}>
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        setShowUploadFromComputer(true)
                                    }
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <MdOutlineUploadFile size={55} />
                                    <span
                                        style={{
                                            marginTop: 10
                                        }}
                                    >
                                        Upload from this PC
                                    </span>
                                </Button>
                            </div>

                            <GoogleDriveUploadPicker openPicker={openPicker} />

                            <DropboxUploadPicker
                                extensions={dropboxExtensions}
                                onFilesUploaded={onFilesUploaded}
                                setBackendError={setBackendError}
                                specificContainerName={specificContainerName}
                                folderPath={folderPath}
                                onClose={onClose}
                                onDropboxImageSelected={onDropboxImageSelected}
                                setDownloadingImage={setDownloadingImage}
                            />
                        </div>
                    )}
                </>
            );
        }

        return (
            <>
                <p>Select type of asset to upload</p>
                <div className="file-type-buttons">
                    <Button
                        onClick={() => handleFileTypeSelect(FileFormat.Image)}
                    >
                        <FontAwesomeIcon icon={faImage} className="me-2" />
                        Image
                    </Button>
                    <Button
                        onClick={() => handleFileTypeSelect(FileFormat.Audio)}
                    >
                        <FontAwesomeIcon icon={faHeadphones} className="me-2" />
                        Audio
                    </Button>
                    <Button
                        onClick={() => handleFileTypeSelect(FileFormat.Model)}
                    >
                        <FontAwesomeIcon icon={faCube} className="me-2" />
                        Model
                    </Button>
                    <Button
                        onClick={() => handleFileTypeSelect(FileFormat.Video)}
                    >
                        <FontAwesomeIcon icon={faPlay} className="me-2" />
                        Video
                    </Button>
                    <Button
                        onClick={() => handleFileTypeSelect(FileFormat.Any)}
                    >
                        <FontAwesomeIcon icon={faAsterisk} className="me-2" />
                        Other
                    </Button>
                </div>
            </>
        );
    };

    return (
        <Modal isOpen toggle={onClose} fullscreen zIndex={1000}>
            <ModalHeader toggle={onClose}>Upload asset</ModalHeader>
            <ModalBody>{modalBodyContent()}</ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onClose}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default UploadModal;
