import React from 'react';
import {FormGroup, Label, Input} from 'reactstrap';

interface TorusKnotEditorProps {
    radius: number;
    tube: number;
    radialSegments: number;
    tubularSegments: number;
    p: number;
    q: number;
    onRadiusChange: (radius: number) => void;
    onTubeChange: (tube: number) => void;
    onRadialSegmentsChange: (radialSegments: number) => void;
    onTubularSegmentsChange: (tubularSegments: number) => void;
    onPChange: (p: number) => void;
    onQChange: (q: number) => void;
}

const TorusKnotEditor: React.FC<TorusKnotEditorProps> = ({
    radius,
    tube,
    radialSegments,
    tubularSegments,
    p,
    q,
    onRadiusChange,
    onTubeChange,
    onRadialSegmentsChange,
    onTubularSegmentsChange,
    onPChange,
    onQChange
}) => {
    const handleRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onRadiusChange(isNaN(value) ? 0 : value);
    };

    const handleTubeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onTubeChange(isNaN(value) ? 0 : value);
    };

    const handleRadialSegmentsChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const value = parseInt(e.target.value, 10);
        onRadialSegmentsChange(isNaN(value) ? 0 : value);
    };

    const handleTubularSegmentsChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const value = parseInt(e.target.value, 10);
        onTubularSegmentsChange(isNaN(value) ? 0 : value);
    };

    const handlePChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        onPChange(isNaN(value) ? 0 : value);
    };

    const handleQChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        onQChange(isNaN(value) ? 0 : value);
    };

    return (
        <>
            <FormGroup>
                <Label>Radius:</Label>
                <Input
                    type="number"
                    step="0.1"
                    value={radius}
                    onChange={handleRadiusChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Tube:</Label>
                <Input
                    type="number"
                    step="0.1"
                    value={tube}
                    onChange={handleTubeChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Radial Segments:</Label>
                <Input
                    type="number"
                    step="1"
                    value={radialSegments}
                    onChange={handleRadialSegmentsChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Tubular Segments:</Label>
                <Input
                    type="number"
                    step="1"
                    value={tubularSegments}
                    onChange={handleTubularSegmentsChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>P:</Label>
                <Input
                    type="number"
                    step="1"
                    value={p}
                    onChange={handlePChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Q:</Label>
                <Input
                    type="number"
                    step="1"
                    value={q}
                    onChange={handleQChange}
                />
            </FormGroup>
        </>
    );
};

export default TorusKnotEditor;
