import {Move} from './lib.ts';
import {
    ContentSectionModel,
    ContentSectionType,
    ElementIdAndContentSections,
    TemplateContentConfig,
    ViewMode
} from '../../api/types.ts';
import {ReactNode, useState} from 'react';
import SectionIcon from './SectionIcon.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faEdit,
    faLongArrowAltDown,
    faLongArrowAltUp,
    faPlus,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import ContentSectionPicker from './ContentSectionPicker.tsx';
import {MergeData, RemoveElement} from '../../types.ts';
import {Link} from 'react-router-dom';
import atify from '../../lib/atify.ts';
import displayName from '../../lib/displayName.ts';
import useSessionState from '../../lib/useSessionState.ts';
import emptyGuid from '../../lib/emptyGuid.ts';
import {isNotNullOrWhiteSpace} from '../../lib/isNullOrWhiteSpace.ts';

interface InsertSectionModalProps {
    sections: ElementIdAndContentSections;
    mergeData: MergeData;
    sectionToInsertAbove: ContentSectionModel;
    onClose: () => void;
}

const InsertSectionModal: React.FC<InsertSectionModalProps> = ({
    sections,
    mergeData,
    sectionToInsertAbove,
    onClose
}) => {
    const ordinalPosition = sectionToInsertAbove.ordinalPosition;

    const onAdd = (section: ContentSectionModel) => {
        const insertIndex = sections.findIndex(
            s => s.section.id === sectionToInsertAbove.id
        );

        if (insertIndex !== -1) {
            const batchedData: {
                [elementId: string]: Partial<ContentSectionModel>;
            } = {};

            sections.forEach((s, index) => {
                if (index < insertIndex) {
                    batchedData[s.elementId] = {ordinalPosition: index};
                } else if (index === insertIndex) {
                    batchedData[s.elementId] = {ordinalPosition: index + 1};
                } else {
                    batchedData[s.elementId] = {ordinalPosition: index + 1};
                }
            });

            batchedData[section.id!] = {
                ...section,
                ordinalPosition: insertIndex
            };

            mergeData(emptyGuid, batchedData);
        }

        onClose();
    };

    return (
        <Modal toggle={onClose} isOpen={true}>
            <ModalHeader>Insert section</ModalHeader>
            <ModalBody>
                <ContentSectionPicker
                    mergeData={mergeData}
                    ordinalPosition={ordinalPosition}
                    onAdd={onAdd}
                />
            </ModalBody>
        </Modal>
    );
};

interface MoveControlsProps {
    sectionsCount: number;
    sectionIndex: number;
    moveSection: (sectionIndex: number, move: Move) => void;
}

const MoveControls: React.FC<MoveControlsProps> = ({
    sectionsCount,
    sectionIndex,
    moveSection
}) => {
    if (sectionsCount < 2) {
        return null;
    }

    return (
        <div className="post-toolbar ms-2 z-index-6">
            {sectionIndex != 0 && (
                <a
                    className="action-link"
                    role="button"
                    title="Move section UP"
                    onClick={() => moveSection(sectionIndex, Move.Up)}
                >
                    <FontAwesomeIcon icon={faLongArrowAltUp} />
                </a>
            )}
            {sectionIndex != sectionsCount - 1 && (
                <a
                    className="action-link"
                    role="button"
                    title="Move section DOWN"
                    onClick={() => moveSection(sectionIndex, Move.Down)}
                >
                    <FontAwesomeIcon icon={faLongArrowAltDown} />
                </a>
            )}
        </div>
    );
};

interface SectionContainerProps {
    removeElement: RemoveElement;
    mergeData: MergeData;
    elementId: string;
    section: ContentSectionModel;
    sections: ElementIdAndContentSections;
    readOnly: boolean;
    sectionsCount: number;
    sectionIndex: number;
    moveSection: (sectionIndex: number, move: Move) => void;
    templateConfig?: TemplateContentConfig | null;
    isTemplateContentPackage: boolean;
    children: ReactNode;
}

const SectionContainer: React.FC<SectionContainerProps> = ({
    removeElement,
    mergeData,
    elementId,
    section,
    sections,
    readOnly,
    sectionsCount,
    sectionIndex,
    moveSection,
    templateConfig,
    isTemplateContentPackage,
    children
}) => {
    const [viewMode, setViewMode] = useSessionState(
        `ViewMode_${elementId}`,
        ViewMode.View
    );

    const [insertSection, setInsertSection] = useState(false);
    const toggleModal = () => {
        setInsertSection(!insertSection);
    };

    const changeViewMode = () => {
        setViewMode(prevMode =>
            prevMode == ViewMode.View ? ViewMode.Edit : ViewMode.View
        );
    };

    const deleteSection = () => {
        const deleteIndex = sections.findIndex(s => s.elementId === elementId);

        if (deleteIndex == -1) {
            removeElement(elementId);
        } else {
            const updatedPositions: {
                [elementId: string]: Partial<ContentSectionModel>;
            } = {};

            sections.forEach((s, index) => {
                if (index < deleteIndex) {
                    updatedPositions[s.elementId] = {ordinalPosition: index};
                } else if (index > deleteIndex) {
                    updatedPositions[s.elementId] = {
                        ordinalPosition: index - 1
                    };
                }
            });

            removeElement(elementId);
            mergeData(emptyGuid, updatedPositions);
        }
    };

    const sectionTitle = () => {
        switch (section.type) {
            case ContentSectionType.RichText:
                return 'Rich text editor';
            default:
                return ContentSectionType[section.type];
        }
    };

    const canToggleViewMode = (() => {
        if (templateConfig) {
            return true;
        }

        if (isTemplateContentPackage) {
            return isNotNullOrWhiteSpace(section.templateKey);
        }

        return true;
    })();

    const setTemplateKey = (templateKey: string) => {
        const data =
            templateKey == ''
                ? {
                      templateKey: null
                  }
                : {
                      templateKey,
                      content: `<p>$__${templateKey}__</p>`
                  };

        mergeData(elementId, data);

        if (templateKey != '') {
            setViewMode(ViewMode.View);
        }
    };

    return (
        <>
            {insertSection && (
                <InsertSectionModal
                    sections={sections}
                    sectionToInsertAbove={section}
                    mergeData={mergeData}
                    onClose={() => setInsertSection(false)}
                />
            )}

            <div
                className={
                    !readOnly && viewMode === ViewMode.Edit
                        ? 'content-editor-card'
                        : 'content-view-card'
                }
            >
                {!readOnly && (
                    <>
                        {viewMode == ViewMode.Edit && (
                            <>
                                <div className="content-editor-card-head">
                                    <h5 className="m-0">
                                        <SectionIcon
                                            sectionType={section.type}
                                        />
                                        <span className="ms-2">
                                            {sectionTitle()}
                                        </span>
                                    </h5>
                                    <div className="content-editor-card-actions">
                                        <div className="post-toolbar ms-2 z-index-6">
                                            {templateConfig && (
                                                <>
                                                    <label className="ms-2">
                                                        Template field
                                                    </label>
                                                    <select
                                                        className="form-control ms-2"
                                                        value={
                                                            section.templateKey ??
                                                            ''
                                                        }
                                                        onChange={event =>
                                                            setTemplateKey(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <option value="">
                                                            None
                                                        </option>
                                                        {templateConfig.templateFields.map(
                                                            field => (
                                                                <option
                                                                    key={
                                                                        field.key
                                                                    }
                                                                    value={
                                                                        field.key
                                                                    }
                                                                >
                                                                    {field.name}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </>
                                            )}
                                            {!isTemplateContentPackage && (
                                                <a
                                                    role="button"
                                                    title="Delete Section"
                                                    className="action-link"
                                                    onClick={deleteSection}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrashAlt}
                                                    />
                                                </a>
                                            )}
                                        </div>
                                        {!isTemplateContentPackage && (
                                            <MoveControls
                                                moveSection={moveSection}
                                                sectionIndex={sectionIndex}
                                                sectionsCount={sectionsCount}
                                            />
                                        )}

                                        {canToggleViewMode && (
                                            <div className="post-toolbar ms-2 z-index-6">
                                                <a
                                                    role="button"
                                                    title="Done"
                                                    className="action-link"
                                                    onClick={changeViewMode}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                    />
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="content-editor-card-body">
                                    {children}
                                </div>
                            </>
                        )}
                        {viewMode == ViewMode.View && (
                            <div className="content-view-card-head text-break">
                                <div className="content-view-card-actions d-flex float-end ms-2">
                                    {!isTemplateContentPackage && (
                                        <div className="post-toolbar ms-2 z-index-6">
                                            <a
                                                role="button"
                                                title="Insert above"
                                                className="action-link"
                                                onClick={toggleModal}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                />
                                            </a>
                                        </div>
                                    )}
                                    {!isTemplateContentPackage && (
                                        <MoveControls
                                            moveSection={moveSection}
                                            sectionIndex={sectionIndex}
                                            sectionsCount={sectionsCount}
                                        />
                                    )}
                                    {canToggleViewMode && (
                                        <div className="post-toolbar ms-2 z-index-6">
                                            <a
                                                role="button"
                                                title="Edit"
                                                className="action-link"
                                                onClick={changeViewMode}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                />
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <div className="clearfix"></div>
                                {children}
                            </div>
                        )}
                    </>
                )}

                {readOnly && (
                    <div className="content-view-card-body text-break">
                        {children}

                        {section.attribution && (
                            <div className="mt-2 text-end small">
                                Author:{' '}
                                <Link
                                    to={`/explore/groups/${atify(
                                        section.attribution.nickname
                                    )}`}
                                >
                                    <strong>{section.attribution.name}</strong>{' '}
                                    {displayName(section.attribution)}
                                </Link>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default SectionContainer;
