// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function debounce<F extends (...args: any[]) => any>(
    func: F,
    wait: number
): F {
    // Debounces a function, delaying its execution until after wait milliseconds
    // have elapsed since the last call, preventing rapid invocations.
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => func.apply(this, args), wait);
    } as F;
}
