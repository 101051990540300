import {MixModel, ProjectDataIndex, ProjectTab} from '../../../api/types.ts';
import SceneComponent from '../../scene/SceneComponent.tsx';

interface ProjectTabSceneProps {
    mix: MixModel;
    tab: ProjectTab;
    dataIndex: ProjectDataIndex;
    readOnly: boolean;
}

const ProjectTabScene: React.FC<ProjectTabSceneProps> = ({
    mix,
    dataIndex,
    readOnly
}) => {
    return (
        <SceneComponent
            project={mix}
            dataIndex={dataIndex}
            readOnly={readOnly}
        />
    );
};

export default ProjectTabScene;
