import React, {ReactNode} from 'react';
import {Vector3} from './types.ts';
import {FormGroup, Input, Label} from 'reactstrap';

interface SceneCoordinateEditorProps {
    label: ReactNode;
    coordinate: Vector3;
    onChange: (coordinate: Vector3) => void;
}

const SceneCoordinateEditor: React.FC<SceneCoordinateEditorProps> = ({
    label,
    coordinate,
    onChange
}) => {
    const handleEntityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;

        const updatedCoordinate = {
            ...coordinate,
            [name as keyof Vector3]: Number(value)
        };

        onChange(updatedCoordinate);
    };

    return (
        <div className="p-2">
            <div>{label}</div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <FormGroup style={{flex: 1}} className="me-2">
                    <Label for="x-coordinate">X</Label>
                    <Input
                        type="number"
                        name="x"
                        id="x-coordinate"
                        value={coordinate.x}
                        onChange={handleEntityChange}
                        step={0.1}
                    />
                </FormGroup>
                <FormGroup style={{flex: 1}} className="me-2">
                    <Label for="y-coordinate">Y</Label>
                    <Input
                        type="number"
                        name="y"
                        id="y-coordinate"
                        value={coordinate.y}
                        onChange={handleEntityChange}
                        step={0.1}
                    />
                </FormGroup>
                <FormGroup style={{flex: 1}}>
                    <Label for="z-coordinate">Z</Label>
                    <Input
                        type="number"
                        name="z"
                        id="z-coordinate"
                        value={coordinate.z}
                        onChange={handleEntityChange}
                        step={0.1}
                    />
                </FormGroup>
            </div>
        </div>
    );
};

export default SceneCoordinateEditor;
