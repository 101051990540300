import {ProjectDataIndex} from '../types';
import {EntityDataModel} from '../../types.ts';
import {handleHttpError} from '../useHttpHooks.ts';
import joinPath from '../../lib/joinPath.ts';

const getLargeObject = async (
    projectId: string,
    dataIndex: ProjectDataIndex,
    elementId: string,
    propNames: string[],
    apiServiceBaseUri: string,
    getAccessToken: () => Promise<string>
): Promise<EntityDataModel> => {
    const query =
        propNames.length == 0 ? '' : `?propNames=${propNames.join(',')}`;

    const url = joinPath(
        apiServiceBaseUri,
        `/api/project-data/${projectId}/${dataIndex}/get-large-object/${elementId}${query}`
    );

    try {
        const accessToken = await getAccessToken();
        const response = await window.fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            await handleHttpError('GET', response, {
                errorMessage: 'Error fetching large object'
            });
        }

        const data: EntityDataModel = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching large object:', error);
        throw error;
    }
};

export default getLargeObject;
