import {MergeData} from '../../types.ts';
import AssetSelectAndUpload from '../assets/AssetSelectAndUpload.tsx';
import {FileFormat} from '../../lib/FileFormats.ts';
import isNullOrWhiteSpace from '../../lib/isNullOrWhiteSpace.ts';
import AudioPlayer from './AudioPlayer.tsx';
import {
    ContentSectionModel,
    FileDataModel,
    Selection,
    ViewMode
} from '../../api/types.ts';
import useSessionState from '../../lib/useSessionState.ts';

interface AudioSectionProps {
    elementId: string;
    section: ContentSectionModel;
    readOnly: boolean;
    mergeData: MergeData;
    packageContainerName?: string;
}

const AudioSection: React.FC<AudioSectionProps> = ({
    elementId,
    section,
    readOnly,
    mergeData,
    packageContainerName
}) => {
    const [viewMode] = useSessionState(`ViewMode_${elementId}`, ViewMode.View);

    const onFileSelectionChanged = (files: FileDataModel[]) => {
        if (files.length > 0) {
            mergeData(elementId, {fileName: files[0].uri});
        }
    };

    if (!readOnly && viewMode == ViewMode.Edit) {
        return (
            <>
                <div className="mb-3 form-group">
                    <label>Title</label>
                    <span>&nbsp;</span>
                    <input
                        className="form-control"
                        value={section.altText ?? ''}
                        onChange={event =>
                            mergeData(elementId, {altText: event.target.value})
                        }
                        maxLength={2048}
                    />
                </div>
                <div className="mb-3 form-group">
                    <label>Audio source</label>
                    <span>&nbsp;</span>
                    <input
                        className="form-control"
                        defaultValue={section.fileName ?? ''}
                        readOnly
                        disabled
                    />
                </div>
                <AssetSelectAndUpload
                    selection={Selection.Single}
                    explanation="Please select an audio file"
                    formats={FileFormat.Audio}
                    fileSelectionChanged={files =>
                        onFileSelectionChanged(files)
                    }
                    specificContainerName={packageContainerName}
                />
            </>
        );
    }

    if (!isNullOrWhiteSpace(section.fileName)) {
        return (
            <div className="row mb-3 align-items-center text-md-start text-center">
                <div className="col-md-auto order-md-1 order-2">
                    <AudioPlayer audioSrc={section.fileName!} />
                </div>
                {!isNullOrWhiteSpace(section.altText) && (
                    <div className="col-md-auto order-md-2 order-1">
                        <h5>{section.altText}</h5>
                    </div>
                )}
                <div className="col-md-auto order-last">
                    <span>
                        <small>
                            {section.fileName!.split(/[/\\]/).pop() || ''}
                        </small>
                    </span>
                </div>
            </div>
        );
    }

    return null;
};

export default AudioSection;
