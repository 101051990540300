import {forwardRef, useState} from 'react';
import {AutoFocusPlugin} from '@lexical/react/LexicalAutoFocusPlugin';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import {AutoLinkNode, LinkNode} from '@lexical/link';
import {ListItemNode, ListNode} from '@lexical/list';
import {CodeNode} from '@lexical/code';
import {HeadingNode} from '@lexical/rich-text';
import ImagePlugin from './plugins/ImagePlugin';
import {ImageNode} from './nodes/ImageNode';
import classNames from 'classnames';
import {$insertNodes, EditorThemeClasses, LexicalEditor} from 'lexical';
import HtmlOnChangePlugin from './plugins/HtmlOnChangePlugin';
import {VideoNode} from './nodes/VideoNode';
import VideoPlugin from './plugins/VideoPlugin';
import YouTubePlugin from './plugins/YouTubePlugin';
import {YouTubeNode} from './nodes/YouTubeNode';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import {useSharedHistoryContext} from './context/SharedHistoryContext';
import LinkPlugin from './plugins/LinkPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import KeyboardNavigationPlugin from './plugins/KeyboardNavigationPlugin';
import AddParagraphAtEditorEndPlugin from './plugins/AddParagraphAtEditorEndPlugin';
import {$generateNodesFromDOM} from '@lexical/html';
import LexicalClearEditorPlugin, {
    ClearEditorRef
} from './plugins/ClearEditorPlugin';
import {EmojiNode} from './nodes/EmojiNode';
import EmojiPlugin from './plugins/EmojiPlugin';
import AutoEmojiPlugin from './plugins/AutoEmojiPlugin';
import PastePlugin from './plugins/PastePlugin.tsx';

// Uncomment the line below to display the lexical treeview of nodes. This is useful when debugging.
// import TreeViewPlugin from './plugins/TreeViewPlugin';

interface RichTextEditorProps {
    id?: string;
    htmlChanged: (value: string) => void;
    html: string;
    imageSelector?: boolean;
    videoSelector?: boolean;
    youTubeSelector?: boolean;
    fileSelector?: boolean;
    emojiSelector?: boolean;
    mentions?: boolean;
    className?: string;
    placeholder?: string;
    readOnly?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    specificContainerName?: string | null;
    mentionsCollectionId?: string;
    onPasteImage?: (
        dataURL: string,
        onUploadedCallback: (imageURL: string) => void
    ) => void;
}

const theme: EditorThemeClasses = {
    paragraph: 'mb-1',
    text: {
        bold: 'editor-bold',
        italic: 'editor-italic',
        strikethrough: 'editor-strikethrough',
        underline: 'editor-underline',
        underlineStrikethrough: 'editor-underline-strikethrough'
    },
    image: 'editor-image',
    emoji: 'editor-emoji'
};

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: Error) {
    console.error(error);
}

const RichTextEditor = forwardRef<ClearEditorRef, RichTextEditorProps>(
    (
        {
            id,
            htmlChanged,
            html,
            imageSelector = false,
            videoSelector = false,
            youTubeSelector = false,
            fileSelector = false,
            emojiSelector = false,
            mentions = false,
            autoFocus,
            disabled,
            readOnly,
            placeholder = 'Enter text...',
            className,
            specificContainerName,
            mentionsCollectionId,
            onPasteImage
        },
        ref
    ) => {
        const {historyState} = useSharedHistoryContext();

        const [floatingAnchorElem, setFloatingAnchorElem] =
            useState<HTMLDivElement | null>(null);

        const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

        const onRef = (_floatingAnchorElem: HTMLDivElement) => {
            if (_floatingAnchorElem !== null) {
                setFloatingAnchorElem(_floatingAnchorElem);
            }
        };

        const initialConfig = {
            namespace: 'MarkdownEditor',
            theme,
            onError,
            editorState: (editor: LexicalEditor) => {
                editor.update(() => {
                    const parser = new DOMParser();
                    const dom = parser.parseFromString(html, 'text/html');
                    const nodes = $generateNodesFromDOM(editor, dom);
                    $insertNodes(nodes);
                });
            },
            nodes: [
                LinkNode,
                AutoLinkNode,
                ListNode,
                ListItemNode,
                CodeNode,
                HeadingNode,
                ImageNode,
                VideoNode,
                YouTubeNode,
                EmojiNode
            ],
            editable: !disabled || readOnly
        };

        return (
            <div className={'position-relative'} style={{zIndex: 0}} id={id}>
                <LexicalComposer initialConfig={initialConfig}>
                    <div
                        className={classNames('editor-shell', className)}
                        style={{
                            border: '1px solid #ddd',
                            borderRadius: '4px'
                        }}
                    >
                        <ToolbarPlugin
                            imageSelector={imageSelector}
                            videoSelector={videoSelector}
                            youTubeSelector={youTubeSelector}
                            fileSelector={fileSelector}
                            emojiSelector={emojiSelector}
                            mentions={mentions}
                            setIsLinkEditMode={setIsLinkEditMode}
                            specificContainerName={specificContainerName}
                            mentionsCollectionId={mentionsCollectionId}
                        />
                        <div className="editor-container">
                            <RichTextPlugin
                                contentEditable={
                                    <div className="editor-scroller d-flex flex-column ">
                                        <div className="editor" ref={onRef}>
                                            <ContentEditable
                                                id={`content_editable_${id}`}
                                                className="py-2 content-editable"
                                                style={{
                                                    backgroundColor:
                                                        'rgba(255, 255, 255, 0)',
                                                    border: '1px solid transparent',
                                                    position: 'relative',
                                                    minHeight: '150px',
                                                    paddingLeft: '28px',
                                                    paddingRight: '28px'
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                                placeholder={
                                    <div className="editor-placeholder">
                                        {placeholder}
                                    </div>
                                }
                                ErrorBoundary={LexicalErrorBoundary}
                            />
                            <LinkPlugin />
                            <AutoLinkPlugin />
                            <LexicalClearEditorPlugin ref={ref} />
                            <LexicalClickableLinkPlugin
                                disabled={true}
                                newTab={true}
                            />
                            <ListPlugin />
                            <HistoryPlugin
                                externalHistoryState={historyState}
                            />
                            {autoFocus && <AutoFocusPlugin />}
                            <ImagePlugin />
                            <PastePlugin onPasteImage={onPasteImage} />
                            <VideoPlugin />
                            <YouTubePlugin />
                            <EmojiPlugin />
                            <AutoEmojiPlugin />
                            <KeyboardNavigationPlugin />
                            <AddParagraphAtEditorEndPlugin />
                            {floatingAnchorElem && (
                                <>
                                    <DraggableBlockPlugin
                                        anchorElem={floatingAnchorElem}
                                    />
                                    <FloatingLinkEditorPlugin
                                        anchorElem={floatingAnchorElem}
                                        isLinkEditMode={isLinkEditMode}
                                        setIsLinkEditMode={setIsLinkEditMode}
                                    />
                                </>
                            )}
                            <HtmlOnChangePlugin htmlChanged={htmlChanged} />
                            {/* Uncomment the line below to display the lexical treeview of nodes. This is useful when debugging. */}
                            {/*<TreeViewPlugin />*/}
                        </div>
                    </div>
                </LexicalComposer>
            </div>
        );
    }
);

export default RichTextEditor;
