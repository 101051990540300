import {FormGroup, Input, Label} from 'reactstrap';
import AssetSelectAndUpload from '../assets/AssetSelectAndUpload.tsx';
import {MergeData} from '../../types.ts';
import {FileFormat} from '../../lib/FileFormats.ts';
import isNullOrWhiteSpace from '../../lib/isNullOrWhiteSpace.ts';
import mime from 'mime';
import VideoPlayer from './VideoPlayer.tsx';
import YouTubeVideoPlayer from './YouTubeVideoPlayer.tsx';
import {
    ContentSectionModel,
    VideoHostingService,
    Selection,
    FileDataModel,
    ViewMode
} from '../../api/types.ts';
import useSessionState from '../../lib/useSessionState.ts';

interface VideoSectionProps {
    elementId: string;
    section: ContentSectionModel;
    readOnly: boolean;
    mergeData: MergeData;
    packageContainerName?: string;
}

const VideoSection: React.FC<VideoSectionProps> = ({
    elementId,
    section,
    readOnly,
    mergeData,
    packageContainerName
}) => {
    const [viewMode] = useSessionState(`ViewMode_${elementId}`, ViewMode.View);

    const serviceEntries = Object.entries(VideoHostingService).filter(
        ([, value]) => !isNaN(Number(value))
    );

    const handleServiceChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        mergeData(elementId, {
            service: Number(event.target.value) as VideoHostingService
        });
    };

    const onFileSelectionChanged = (files: FileDataModel[]) => {
        if (files.length > 0) {
            mergeData(elementId, {fileName: files[0].uri});
        }
    };

    if (!readOnly && viewMode == ViewMode.Edit) {
        return (
            <>
                <div className="mb-3 form-group">
                    <label>Title</label>
                    <span>&nbsp;</span>
                    <input
                        className="form-control"
                        value={section.altText ?? ''}
                        onChange={event =>
                            mergeData(elementId, {altText: event.target.value})
                        }
                        maxLength={2048}
                    />
                </div>
                <FormGroup tag="fieldset">
                    <legend>Video hosting service</legend>
                    {serviceEntries.map(([key, value]) => (
                        <FormGroup check key={key}>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="videoService"
                                    value={value}
                                    checked={section.service === value}
                                    onChange={handleServiceChange}
                                />
                                {key}
                            </Label>
                        </FormGroup>
                    ))}
                </FormGroup>

                {section.service == VideoHostingService.YouTube && (
                    <div className="mb-3 form-group">
                        <label>YouTube video id</label>
                        <span>&nbsp;</span>
                        <input
                            className="form-control"
                            value={section.videoId ?? ''}
                            onChange={event =>
                                mergeData(elementId, {
                                    videoId: event.target.value
                                })
                            }
                        />
                    </div>
                )}
                {section.service == VideoHostingService.HostedByMixiply && (
                    <>
                        <div className="mb-3 form-group">
                            <label>Video source</label>
                            <span>&nbsp;</span>
                            <input
                                className="form-control"
                                defaultValue={section.fileName ?? ''}
                                readOnly
                                disabled
                            />
                        </div>
                        <AssetSelectAndUpload
                            selection={Selection.Single}
                            explanation="Please select a video file"
                            formats={FileFormat.Video}
                            fileSelectionChanged={files =>
                                onFileSelectionChanged(files)
                            }
                            specificContainerName={packageContainerName}
                        />
                    </>
                )}
            </>
        );
    }

    const fileName = section.fileName!.split(/[/\\]/).pop() || '';

    return (
        <>
            {!isNullOrWhiteSpace(section.altText) && <h5>{section.altText}</h5>}

            {section.service == VideoHostingService.HostedByMixiply && (
                <>
                    {!isNullOrWhiteSpace(section.fileName) && (
                        <>
                            <VideoPlayer
                                videoSrc={section.fileName ?? ''}
                                videoType={mime.getType(fileName) ?? undefined}
                            />
                            <div className="form-group mb-3 ">
                                <small>{fileName}</small>
                            </div>
                        </>
                    )}
                </>
            )}
            {section.service == VideoHostingService.YouTube && (
                <>
                    {!isNullOrWhiteSpace(section.videoId) && (
                        <YouTubeVideoPlayer videoId={section.videoId ?? ''} />
                    )}
                </>
            )}
        </>
    );
};

export default VideoSection;
