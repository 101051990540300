import {FileDataModel, UploadResult} from '../../api/types.ts';
import FileFormats, {FileFormat} from '../../lib/FileFormats.ts';

export default function getFilesFromUploadResults(
    results: UploadResult[],
    formats: FileFormat | null | undefined
): FileDataModel[] {
    return results.filter(
        file =>
            formats == null ||
            FileFormats.getExtensions(formats, true).includes(
                file.extension ?? ''
            ) ||
            FileFormats.getExtensions(formats, true).includes('*')
    );
}
