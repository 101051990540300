﻿export default class ApiError extends Error {
    status: number;
    data: unknown;

    constructor(message: string, status: number, data: unknown = null) {
        super(message);
        this.status = status;
        this.data = data;
        // Restore prototype chain
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
