import {useDelete} from './../useHttpHooks';

export type DeleteGroupMemberInfoFieldCommand = {
    collectionId: string;
    id: string;
};

const useDeleteGroupMemberInfoField = () =>
    useDelete<DeleteGroupMemberInfoFieldCommand>(
        `/api/group-member-info/delete-group-member-info-field`,
        {
            errorMessage:
                'DELETE api/group-member-info/delete-group-member-info-field failed'
        }
    );

export default useDeleteGroupMemberInfoField;
