import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faTrash,
    faPlus,
    faCheck,
    faTimes
} from '@fortawesome/free-solid-svg-icons';

interface JsonStringCollectionInputProps {
    value: string[];
    onValueChanged: (value: string[]) => void;
}

const JsonStringCollectionInput: React.FC<JsonStringCollectionInputProps> = ({
    value,
    onValueChanged
}) => {
    const [values, setValues] = useState<string[]>(value);
    const [adding, setAdding] = useState<boolean>(false);
    const [valueToAdd, setValueToAdd] = useState<string>('');

    const onAdding = () => {
        setAdding(true);
    };

    const onCancel = () => {
        setAdding(false);
        setValueToAdd('');
    };

    const onAdd = () => {
        setValues([...values, valueToAdd]);
        onValueChanged([...values, valueToAdd]);
        onCancel();
    };

    const onRemove = (index: number) => {
        const newValues = values.filter((_, i) => i !== index);
        setValues(newValues);
        onValueChanged(newValues);
    };

    return (
        <div>
            {values.map((value, index) => (
                <button
                    key={index}
                    className={`btn btn-primary cursor-normal${
                        index !== 0 ? ' ms-2' : ''
                    }`}
                >
                    {value}
                    <span
                        className="badge bg-danger cursor-pointer ms-3"
                        onClick={event => (
                            event.preventDefault(), onRemove(index)
                        )}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </span>
                </button>
            ))}
            {!adding ? (
                <div className="mt-2">
                    <button
                        className="btn btn-primary"
                        onClick={event => (event.preventDefault(), onAdding())}
                    >
                        Add <FontAwesomeIcon icon={faPlus} className="ms-3" />
                    </button>
                </div>
            ) : (
                <div className="input-group mt-2">
                    <input
                        type="text"
                        className="form-control"
                        value={valueToAdd}
                        onChange={e => setValueToAdd(e.target.value)}
                    />
                    <button
                        className="btn btn-primary btn-icon"
                        onClick={event => (event.preventDefault(), onAdd())}
                    >
                        <FontAwesomeIcon icon={faCheck} />
                    </button>
                    <button
                        className="btn btn-primary btn-icon"
                        onClick={event => (event.preventDefault(), onCancel())}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
            )}
        </div>
    );
};

export default JsonStringCollectionInput;
