import emojis from './emojis.ts';
import {TabState} from '../components/shared/tabs/types.ts';

const tabStates: TabState[] = emojis.groups
    .filter(g => g.name !== 'Component')
    .map(g => ({
        name: g.name,
        text: g.name,
        available: true
    }));

tabStates.unshift({name: 'Emojis', text: 'Emojis', available: true});

export default tabStates;
