import {ChannelInfoModel} from '../types';
import {usePost} from './../useHttpHooks';

export type CreateChannelCommand = {
    collectionId: string;
    name: string;
    topic: string;
    isDefault: boolean;
};

const useCreateChannel = () =>
    usePost<CreateChannelCommand, ChannelInfoModel>(`/api/posts/create-channel`, {errorMessage: 'POST api/posts/create-channel failed'
});

export default useCreateChannel;
