import React, {useState} from 'react';
import {Vector3, NormalizedColor, HTMLColor} from './types.ts';
import {FormGroup, Label, Button} from 'reactstrap';
import SpatialCoordinateEditor from './SpatialCoordinateEditor.tsx';
import ColorPickerComponent from '../shared/ColorPicker.tsx';

interface LinesEditorProps {
    points: Vector3[];
    colors?: (NormalizedColor | HTMLColor)[];
    onPointsChange: (points: Vector3[]) => void;
    onColorsChange: (
        colors: (NormalizedColor | HTMLColor)[] | undefined
    ) => void;
}

const LinesEditor: React.FC<LinesEditorProps> = ({
    points,
    colors,
    onPointsChange,
    onColorsChange
}) => {
    const [currentPoints, setCurrentPoints] = useState<Vector3[]>(points);
    const [currentColors, setCurrentColors] = useState<
        (NormalizedColor | HTMLColor)[] | undefined
    >(colors);

    const handlePointChange = (index: number, coordinate: Vector3) => {
        const updatedPoints = [...currentPoints];
        updatedPoints[index] = coordinate;
        setCurrentPoints(updatedPoints);
    };

    const handleColorChange = (
        index: number,
        color: NormalizedColor | HTMLColor | undefined
    ) => {
        if (currentColors) {
            const updatedColors = [...currentColors];
            updatedColors[index] = color || '#000000';
            setCurrentColors(updatedColors);
        }
    };

    const addPoint = () => {
        const newPoint: Vector3 = {x: 0, y: 0, z: 0};
        setCurrentPoints([...currentPoints, newPoint]);
        if (currentColors) {
            const newColor: HTMLColor = '#000000';
            setCurrentColors([...currentColors, newColor]);
        }
    };

    const removePoint = (index: number) => {
        const updatedPoints = currentPoints.filter((_, i) => i !== index);
        setCurrentPoints(updatedPoints);
        if (currentColors) {
            const updatedColors = currentColors.filter((_, i) => i !== index);
            setCurrentColors(updatedColors);
        }
    };

    const applyChanges = () => {
        onPointsChange(currentPoints);
        onColorsChange(currentColors);
    };

    return (
        <>
            <FormGroup>
                <Label>Points:</Label>
                {currentPoints.map((point, index) => (
                    <div key={index}>
                        <SpatialCoordinateEditor
                            label={`Point ${index + 1}`}
                            coordinate={point}
                            onChange={coordinate =>
                                handlePointChange(index, coordinate)
                            }
                        />
                        {currentColors && (
                            <ColorPickerComponent
                                color={currentColors[index] as string}
                                onChange={color =>
                                    handleColorChange(index, color)
                                }
                            />
                        )}
                        <Button
                            color="danger"
                            onClick={() => removePoint(index)}
                        >
                            Remove Point
                        </Button>
                    </div>
                ))}
                <Button color="primary" onClick={addPoint}>
                    Add Point
                </Button>
                <Button color="success" onClick={applyChanges}>
                    Apply Changes
                </Button>
            </FormGroup>
        </>
    );
};

export default LinesEditor;
