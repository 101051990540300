import {PreviewFormat} from '../types.ts';
import {AssetType} from '../api/types.ts';

export enum FileFormat {
    Image,
    Audio,
    Model,
    Video,
    Any
}

class FileFormats {
    static ImageFormats: string[] = ['jpg', 'jpeg', 'png', 'gif'];
    static AudioFormats: string[] = ['mp3', 'ogg', 'wav'];
    static get ModelFormats(): string[] {
        return ['glb', 'gltf', 'obj'];
    }
    static VideoFormats: string[] = ['mp4'];
    static MarkdownFormats: string[] = ['md'];
    static get UploadModelFormats(): string[] {
        return ['glb', 'gltf', 'obj', 'zip'];
    }
    static get UploadAudioFormats(): string[] {
        return ['mp3', 'ogg', 'wav', 'zip'];
    }
    static get UploadVideoFormats(): string[] {
        return ['mp4', 'zip'];
    }
    static get UploadImageFormats(): string[] {
        return ['jpg', 'jpeg', 'png', 'zip'];
    }
    static Any: string[] = ['*'];
    static get CreateMixExtensions(): string[] {
        return 'gltf glb jpg jpeg png mp4 mp3 ogg wav'.split(' ');
    }
    static get ViewInVRExtensions(): string[] {
        return 'gltf glb'.split(' ');
    }

    static assetTypeFormats(assetType: AssetType) {
        switch (assetType) {
            case AssetType.Image:
                return FileFormat.Image;
            case AssetType.Model:
                return FileFormat.Model;
            case AssetType.Audio:
                return FileFormat.Audio;
            case AssetType.Video:
                return FileFormat.Video;
            default:
                return FileFormat.Any;
        }
    }

    static getExtensions(format: FileFormat, forUpload?: boolean) {
        switch (format) {
            case FileFormat.Image:
                return forUpload
                    ? FileFormats.UploadImageFormats
                    : FileFormats.ImageFormats;

            case FileFormat.Audio:
                return forUpload
                    ? FileFormats.UploadAudioFormats
                    : FileFormats.AudioFormats;

            case FileFormat.Model:
                return forUpload
                    ? FileFormats.UploadModelFormats
                    : FileFormats.ModelFormats;

            case FileFormat.Video:
                return forUpload
                    ? FileFormats.UploadVideoFormats
                    : FileFormats.VideoFormats;

            case FileFormat.Any:
                return FileFormats.Any;
        }
    }

    static getViewMimeTypes(
        format?: FileFormat,
        forUpload?: boolean
    ): string | undefined {
        if (format === undefined) {
            return undefined;
        }

        const extensions = FileFormats.getExtensions(format, forUpload);

        const mimeTypesSet = new Set<string>();

        for (const extension of extensions) {
            const mimeType = FileFormats.getMimeType(extension);
            if (mimeType !== null) {
                mimeTypesSet.add(mimeType);
            }
        }

        return mimeTypesSet.size > 0
            ? Array.from(mimeTypesSet).join(',')
            : undefined;
    }

    static getAcceptFileTypes(format: FileFormat, forUpload?: boolean) {
        const mimeTypes: {[key: string]: string[]} = {};
        const extensions = this.getExtensions(format, forUpload);

        extensions.forEach(ext => {
            const mimeType = this.getMimeType(ext);
            if (mimeType) {
                if (!mimeTypes[mimeType]) {
                    mimeTypes[mimeType] = [];
                }
                mimeTypes[mimeType].push('.' + ext);
            }
        });

        return mimeTypes;
    }

    static getPreviewFormat(extension: string) {
        if (FileFormats.ImageFormats.includes(extension)) {
            return PreviewFormat.Image;
        }

        if (FileFormats.AudioFormats.includes(extension)) {
            return PreviewFormat.Audio;
        }

        if (FileFormats.ModelFormats.includes(extension)) {
            return PreviewFormat.Model;
        }

        if (FileFormats.VideoFormats.includes(extension)) {
            return PreviewFormat.Video;
        }

        if (FileFormats.MarkdownFormats.includes(extension)) {
            return PreviewFormat.Markdown;
        }

        return PreviewFormat.NoPreview;
    }

    private static getMimeType(extension: string): string | null {
        const mapping: {[key: string]: string} = {
            jpg: 'image/jpeg',
            jpeg: 'image/jpeg',
            png: 'image/png',
            gif: 'image/gif',
            mp3: 'audio/mpeg',
            ogg: 'audio/ogg',
            wav: 'audio/wav',
            glb: 'model/gltf-binary',
            gltf: 'model/gltf+json',
            obj: 'text/plain', // OBJ doesn't have a standard MIME type
            mp4: 'video/mp4',
            zip: 'application/zip'
            // add other mappings as necessary
        };

        return mapping[extension] || null;
    }
}

export default FileFormats;
