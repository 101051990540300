import HtmlComponent from '../shared/HtmlComponent.tsx';
import {MergeData} from '../../types.ts';
import {ContentSectionModel, ViewMode} from '../../api/types.ts';
import {isNotNullOrWhiteSpace} from '../../lib/isNullOrWhiteSpace.ts';
import useSessionState from '../../lib/useSessionState.ts';
import RichTextEditor from '../content_editor/RichTextEditor.tsx';

interface RichTextSectionProps {
    elementId: string;
    section: ContentSectionModel;
    readOnly: boolean;
    mergeData: MergeData;
    packageContainerName?: string;
    onPasteImage?: (
        dataURL: string,
        onUploadedCallback: (imageURL: string) => void
    ) => void;
}

const RichTextSection: React.FC<RichTextSectionProps> = ({
    elementId,
    section,
    readOnly,
    mergeData,
    packageContainerName,
    onPasteImage
}) => {
    const [viewMode] = useSessionState(`ViewMode_${elementId}`, ViewMode.View);

    return (
        <>
            {!readOnly && (
                <div hidden={viewMode === ViewMode.View}>
                    <RichTextEditor
                        id={`Section_Edit_${elementId}`}
                        htmlChanged={html => {
                            mergeData(elementId, {content: html});
                        }}
                        html={section.content ?? ''}
                        imageSelector
                        videoSelector
                        youTubeSelector
                        fileSelector
                        emojiSelector
                        onPasteImage={onPasteImage}
                        specificContainerName={packageContainerName}
                    />
                </div>
            )}
            {(readOnly || viewMode === ViewMode.View) &&
                isNotNullOrWhiteSpace(section.content) && (
                    <HtmlComponent html={section.content} />
                )}
        </>
    );
};

export default RichTextSection;
