import {FileDataModel} from '../types.ts';
import {usePost} from '../useHttpHooks.ts';

export type GoogleDriveFileUploadModel = {
    accessToken: string;
    containerName?: string | null;
    folderPath?: string | null;
    id: string;
    name: string;
};

const useUploadGoogleDriveFile = () =>
    usePost<GoogleDriveFileUploadModel, FileDataModel[]>(
        '/api/google-drive/upload',
        {
            errorMessage: 'Failed to upload google drive file'
        }
    );

export default useUploadGoogleDriveFile;
