import React, {useState, useEffect} from 'react';
import {useAuthStateManager} from '../../hooks/useAuthStateManager';
import useReactToPost from '../../api/posts/useReactToPost';
import {
    CollectionInfoModel,
    DisplayPostModel,
    UserCollectionType
} from '../../api/types.ts';
import RoleNames from '../../lib/RoleNames.ts';
import {Emoji} from '../../emojis/types.ts';
import DateTimeLocalDisplay from '../shared/DateTimeLocalDisplay.tsx';
import PostToolbar from './PostToolbar.tsx';
import HtmlComponent from '../shared/HtmlComponent.tsx';
import MxReactionSummary from './MxReactionSummary.tsx';
import EmojiPickerModal from '../../emojis/EmojiPickerModal.tsx';
import displayName from '../../lib/displayName.ts';
import {useMutation} from 'react-query';

interface GroupPostReplyProps {
    reply: DisplayPostModel;
    collection: CollectionInfoModel;
    onDelete: () => void;
}

const GroupPostReply: React.FC<GroupPostReplyProps> = ({
    reply,
    collection,
    onDelete
}) => {
    const {loggedInUserId, authData} = useAuthStateManager();
    const [showReactions, setShowReactions] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const reactToPost = useReactToPost();
    const reactToPostMutation = useMutation(reactToPost);

    useEffect(() => {
        setCanDelete(
            loggedInUserId === reply.userId ||
                collection.loggedInUserUserCollectionType ===
                    UserCollectionType.Admin ||
                authData.roles.includes(RoleNames.Admin)
        );
    }, [
        loggedInUserId,
        reply.userId,
        collection.loggedInUserUserCollectionType,
        authData.roles
    ]);

    const onReact = () => {
        setShowReactions(true);
    };

    const onReactAsync = async (emoji: Emoji) => {
        const reactToPostCommand = {
            postId: reply.id,
            parentPostId: reply.parentId,
            userId: loggedInUserId,
            emojiCode: emoji.emojiCode
        };

        await reactToPostMutation.mutateAsync(reactToPostCommand);

        setShowReactions(false);
    };

    return (
        <div className="mixi-card no-hover post-card reply-card" id={reply.id}>
            <div className="card-content">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div className="member mini">
                        <img
                            src={reply.user.thumbnail}
                            crossOrigin="anonymous"
                            className="member-avatar"
                            alt={displayName(reply.user)}
                        />
                        <div className="member-info">
                            <div className="d-flex mb-1 flex-column">
                                <div className="d-flex align-items-center mb-0">
                                    <h6 className="member-name mb-0 fw-bold">
                                        {displayName(reply.user)}
                                    </h6>
                                    <h6 className="post-time ps-2 mb-0">
                                        <DateTimeLocalDisplay
                                            dateTimeUtc={reply.createdOnUtc}
                                            format="hh:mm a"
                                        />
                                    </h6>
                                </div>
                                <div className="d-flex align-items-center">
                                    <small>
                                        {displayName(reply.user)}&nbsp;
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PostToolbar
                        canDelete={canDelete}
                        postType={reply.postType}
                        onDelete={onDelete}
                        onReply={() => {}}
                        onReact={onReact}
                        postId={reply.id}
                        parentPostId={reply.parentId}
                    />
                </div>
                <div className="card-body">
                    <HtmlComponent html={reply.content} />
                </div>
                {reply.reactionsCount > 0 && (
                    <div className="card-footer align-items-center">
                        <MxReactionSummary
                            reactionSummary={reply.reactionSummary}
                            reactionsCount={reply.reactionsCount}
                            postId={reply.id}
                        />
                    </div>
                )}
            </div>
            {showReactions && (
                <EmojiPickerModal
                    onClose={() => setShowReactions(false)}
                    onSelect={onReactAsync}
                />
            )}
        </div>
    );
};

export default GroupPostReply;
