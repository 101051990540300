export default function createFileFromImage(image: string, fileName: string) {
    const byteString = atob(image.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], {type: 'image/png'});
    return new File([blob], fileName, {type: 'image/png'});
}
