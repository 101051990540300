import {ReactionEvent} from '../../project_data/useProjectData.ts';
import emojisByEmojiCode from '../../emojis/emojisByEmojiCode.ts';

export function handleReaction(
    reactionEvent: ReactionEvent,
    emojiContainer: HTMLDivElement
) {
    const {animationType} = reactionEvent;

    switch (animationType) {
        case 'drop':
            dropReaction(reactionEvent, emojiContainer);
            break;
        case 'float':
            floatReaction(reactionEvent, emojiContainer);
            break;
        case 'fly':
            flyReaction(reactionEvent, emojiContainer);
            break;
    }
}

const floatReaction = (
    reactionEvent: ReactionEvent,
    emojiContainer: HTMLDivElement
) => {
    const canvasHeight = emojiContainer.offsetHeight;

    const emoji = document.createElement('div');
    emoji.textContent = '❤';
    emoji.style.position = 'absolute';
    emoji.style.left = `${reactionEvent.left}px`;
    emoji.style.bottom = '0';
    emoji.style.fontSize = '40px';
    emoji.style.color = 'red';
    emoji.style.opacity = '1';

    emojiContainer.appendChild(emoji);

    const animate = () => {
        requestAnimationFrame(animate);

        const emojiTop = parseInt(emoji.style.bottom, 10);
        const newTop = emojiTop + 4;
        if (newTop >= canvasHeight) {
            if (emojiContainer.contains(emoji)) {
                emojiContainer.removeChild(emoji);
            }
        } else {
            emoji.style.bottom = `${newTop}px`;
            emoji.style.opacity = `${1 - newTop / canvasHeight}`;
        }
    };

    animate();
};

const dropReaction = (
    reactionEvent: ReactionEvent,
    emojiContainer: HTMLDivElement
) => {
    if (reactionEvent.emoji == null) {
        return;
    }

    const canvasHeight = emojiContainer.offsetHeight;

    const emojiElement = document.createElement('img');
    emojiElement.src = emojisByEmojiCode[reactionEvent.emoji].source;
    emojiElement.style.position = 'absolute';
    emojiElement.style.left = `${reactionEvent.left}px`;
    emojiElement.style.top = '-30px';
    emojiElement.style.width = '30px';
    emojiElement.style.height = '30px';
    emojiElement.style.opacity = '1';

    emojiContainer.appendChild(emojiElement);

    let emojiTop = -30;
    let emojiVelocity = 2;
    let bouncecounts = 0;
    const maxBounces = 4;

    const animate = () => {
        requestAnimationFrame(animate);

        emojiTop += emojiVelocity;

        if (emojiTop - 30 >= canvasHeight) {
            emojiVelocity = -emojiVelocity * 0.6;
            bouncecounts++;
        } else {
            emojiVelocity += 0.5;
        }

        emojiElement.style.top = `${emojiTop}px`;
        emojiElement.style.opacity = `${1 - emojiTop / (canvasHeight * 1.5)}`;

        if (bouncecounts > maxBounces) {
            if (emojiContainer.contains(emojiElement)) {
                emojiContainer.removeChild(emojiElement);
            }
        }
    };

    animate();
};

const flyReaction = (
    reactionEvent: ReactionEvent,
    emojiContainer: HTMLDivElement
) => {
    const canvasWidth = emojiContainer.offsetWidth;

    const emoji = document.createElement('div');
    emoji.textContent = reactionEvent.message;
    emoji.style.position = 'absolute';
    emoji.style.left = '0';
    emoji.style.top = `${reactionEvent.top}px`;
    emoji.style.fontSize = '25px';
    emoji.style.fontFamily = 'Poppins, sans-serif';
    emoji.style.color = reactionEvent.color ?? 'black';
    emoji.style.opacity = '1';

    emojiContainer.appendChild(emoji);

    const animate = () => {
        requestAnimationFrame(animate);
        const emojiLeft = parseInt(emoji.style.left, 10);
        const newLeft = emojiLeft + 4;

        if (newLeft >= canvasWidth) {
            if (emojiContainer.contains(emoji)) {
                emojiContainer.removeChild(emoji);
            }
        } else {
            emoji.style.left = `${newLeft}px`;
            emoji.style.opacity = `${1 - newLeft / canvasWidth}`;
        }
    };

    animate();
};
