import baseUri from '../../api/baseUri.ts';

interface EmbeddedModelProps {
    src: string;
}

const EmbeddedModel: React.FC<EmbeddedModelProps> = ({src}) => {
    const isObj = Boolean(src.endsWith('.obj'));

    return (
        <div className="ratio ratio-16x9">
            <iframe
                className="lazyload"
                src={`${baseUri}/vr?url=${encodeURIComponent(
                    isObj ? src.replace('.obj', '.gltf') : src
                )}`}
            ></iframe>
        </div>
    );
};

export default EmbeddedModel;
