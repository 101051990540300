import {useState} from 'react';
import {ProjectDataIndex} from '../../api/types.ts';
import useCreateImageFromPrompt from '../../api/assistant/useCreateImageFromPrompt.ts';
import {useMutation, useQuery} from 'react-query';
import {toast} from 'react-toastify';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import getTimezoneOffset from '../../lib/getTimezoneOffset.ts';
import useGetDailyAiImageCreationUsage from '../../api/assistant/useGetDailyAiImageCreationUsage.ts';

const useImageCreator = () => {
    const timezoneOffset = getTimezoneOffset();
    const getDailyAiImageCreationUsage = useGetDailyAiImageCreationUsage({
        timezoneOffset
    });

    const {
        data: dailyAiImageCreationUsage,
        refetch: refetchDailyAiImageCreationUsage
    } = useQuery(
        ['daily-ai-image-creation-usage', timezoneOffset],
        getDailyAiImageCreationUsage
    );

    const dailyLimit =
        dailyAiImageCreationUsage?.dailyAiImageCreationLimit ?? 0;
    const imageCount =
        dailyAiImageCreationUsage?.dailyAiImageCreationCount ?? 0;
    const [isGeneratingImage, setIsGeneratingImage] = useState(false);

    const createImageFromPrompt = useCreateImageFromPrompt();

    const createImageFromPromptMutation = useMutation(createImageFromPrompt, {
        onMutate: () => {
            setIsGeneratingImage(true);
        },
        onSuccess: () => {
            toast.success('Image generated');
            setIsGeneratingImage(false);
            refetchDailyAiImageCreationUsage();
        },
        onError: (error: unknown) => {
            toast.error(unknownErrorToString(error));
            setIsGeneratingImage(false);
        }
    });

    const generateImage = async (
        prompt: string,
        mixId: string,
        dataIndex: ProjectDataIndex,
        useAsThumbnail: boolean
    ): Promise<string | null> => {
        if (imageCount < dailyLimit) {
            try {
                const result = await createImageFromPromptMutation.mutateAsync({
                    id: mixId,
                    dataIndex,
                    useAsThumbnail,
                    prompt,
                    timezoneOffset: getTimezoneOffset()
                });

                toast.success(
                    'Image request submitted - it may take a while for the image to show in your project.'
                );

                return result?.imageUrl ?? null;
            } catch (error) {
                console.error('Error generating image:', error);
                return null;
            }
        }
        return null;
    };

    return {imageCount, generateImage, dailyLimit, isGeneratingImage};
};

export default useImageCreator;
