import {FileDataModel} from '../types';
import {usePost} from './../useHttpHooks';

export type UploadFileFromUrlCommand = {
    containerName: string | null | undefined;
    folderPath: string | null | undefined;
    url: string;
};

const useUploadFileFromUrl = () => {
    return usePost<UploadFileFromUrlCommand, FileDataModel[]>(
        `/api/uploads/upload-from-url`,
        {
            errorMessage: 'POST api/uploads/upload-from-url'
        }
    );
};
export default useUploadFileFromUrl;
