import {useState, useEffect, useCallback} from 'react';

export default function useSessionState<T>(
    key: string,
    defaultValue: T
): [T, (value: T | ((prevValue: T) => T)) => void] {
    const [state, setState] = useState<T>(() => {
        const storedValue = sessionStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : defaultValue;
    });

    const setValue = useCallback(
        (value: T | ((prevValue: T) => T)) => {
            setState(prevState => {
                const newValue =
                    typeof value === 'function'
                        ? (value as (prevValue: T) => T)(prevState)
                        : value;
                sessionStorage.setItem(key, JSON.stringify(newValue));
                return newValue;
            });
        },
        [key]
    );

    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);

    return [state, setValue];
}
