import toQuery from '../../lib/toQuery.ts';
import {UnknownObject} from '../../types.ts';

export async function authRequest(
    params: UnknownObject,
    url = 'tokens'
): Promise<
    | {access_token: string; refresh_token: string}
    | {error: string; error_description: string}
> {
    const data = toQuery(params);
    const headers: {[headerKey: string]: string} = {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    };
    const response = await window.fetch(url, {
        cache: 'no-cache',
        headers,
        method: 'POST',
        body: data
    });

    const responseClone = response.clone();
    let responseData;
    try {
        responseData = await response.json();
    } catch (error) {
        responseData = await responseClone.text();
    }

    if (response.ok) {
        return responseData as {access_token: string; refresh_token: string};
    } else {
        if (typeof responseData === 'string') {
            return {
                error: 'internal_server_error',
                error_description: responseData
            };
        }

        return responseData as {error: string; error_description: string};
    }
}
