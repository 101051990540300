import {UploadResult} from '../types';
import {usePost} from './../useHttpHooks';
import joinPath from '../../lib/joinPath.ts';

const useUploadFiles = (
    containerName?: string | null,
    folderPath?: string[] | string | null
) => {
    const joinedPath = Array.isArray(folderPath)
        ? joinPath(folderPath)
        : folderPath;

    const queryParams = new URLSearchParams({
        ...(containerName && {containerName}),
        ...(joinedPath && {folderPath: joinedPath})
    });

    return usePost<FormData, UploadResult[]>(
        `/api/uploads/upload-files?${queryParams}`,
        {
            errorMessage: 'POST api/uploads/upload-files failed'
        }
    );
};
export default useUploadFiles;
