import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type NicknameExistsQuery = {
    nickname: string;
};

const useNicknameExists = (request: NicknameExistsQuery) =>
    useGet<boolean>(`/api/groups/nickname-exists?${toQuery(request)}`, {errorMessage: 'GET api/groups/nickname-exists failed'
});

export default useNicknameExists;
