import React, {useState} from 'react';
import EmojiImage from '../../emojis/EmojiImage.tsx';
import ReactionsModal from './ReactionsModal.tsx';

interface MxReactionSummaryProps {
    reactionSummary: string;
    reactionsCount: number;
    postId: string;
}

const MxReactionSummary: React.FC<MxReactionSummaryProps> = ({
    reactionSummary,
    reactionsCount,
    postId
}) => {
    const [showReactionsModal, setShowReactionsModal] = useState(false);
    const emojiCodes = reactionSummary
        ? reactionSummary.split(',').map(r => r.trim())
        : [];

    return (
        <>
            {emojiCodes.length > 0 && (
                <a
                    onClick={() => setShowReactionsModal(true)}
                    className="reactions-cta action-link ms-auto"
                    title="Reactions"
                >
                    <div className="reactions-emojis">
                        {emojiCodes.map(emojiCode => (
                            <EmojiImage emojiCode={emojiCode} key={emojiCode} />
                        ))}
                    </div>
                    <span className="reactions-counter">{reactionsCount}</span>
                </a>
            )}
            {showReactionsModal && (
                <ReactionsModal
                    onHide={() => setShowReactionsModal(false)}
                    postId={postId}
                />
            )}
        </>
    );
};

export default MxReactionSummary;
