class EmojiUtility {
    static sanitizeEmojiCode(text: string): string {
        const sanitizedCharacters = text
            .split('')
            .map(ch => {
                if (/\s|-/.test(ch)) {
                    return '_';
                }
                if (/[a-zA-Z0-9]/.test(ch)) {
                    return ch;
                }
                return '';
            })
            .join('');

        return this.removeDiacritics(sanitizedCharacters).toLowerCase();
    }

    private static removeDiacritics(text: string): string {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
}

export default EmojiUtility;
