import {UpsertJournalEntryResult, JournalEntryModel} from '../types';
import {usePost} from './../useHttpHooks';

export type UpsertJournalEntryCommand = {
    journalEntryModel: JournalEntryModel;
};

const useUpsertJournalEntry = () =>
    usePost<UpsertJournalEntryCommand, UpsertJournalEntryResult>(
        `/api/journal/upsert-journal-entry`,
        {errorMessage: 'POST api/journal/upsert-journal-entry failed'}
    );

export default useUpsertJournalEntry;
