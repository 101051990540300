import React from 'react';
import {MixModel} from '../../api/types.ts';
import {useNavigate} from 'react-router-dom';
import projectUrl from '../../lib/projectUrl.ts';
import {isNotNullOrWhiteSpace} from '../../lib/isNullOrWhiteSpace.ts';

interface GalleryCardProps {
    project: MixModel;
    indicateIsTemplate?: boolean;
    onClick?: (project: MixModel) => void;
}

const GalleryCard: React.FC<GalleryCardProps> = ({
    project,
    indicateIsTemplate = false,
    onClick
}) => {
    const navigate = useNavigate();

    const onProjectClick = () => {
        if (onClick) {
            onClick(project);
            return;
        }

        navigateToProject();
    };

    const navigateToProject = () => {
        let url = projectUrl(project);

        if (project.isTemplate) {
            url += '?tab=template';
        }

        navigate(url);
    };

    const navigateTo = (url: string) => {
        navigate(url);
    };

    const userHasName = () => {
        return isNotNullOrWhiteSpace(project.userName);
    };

    return (
        <div className="mixi-card mix-card hover-lift">
            <div className="card-content">
                <div className="mix-card-head">
                    <button
                        className="btn credit d-inline-flex p-0"
                        type="button"
                        onClick={() =>
                            navigateTo(
                                `/explore/groups/${project.userContainerName}`
                            )
                        }
                    >
                        <img
                            src={project.userThumbnail}
                            crossOrigin="anonymous"
                            className="credit-avatar"
                            alt={project.nickname}
                        />
                        <h6 className="credit-name fw-bold">
                            {userHasName()
                                ? project.userName
                                : project.nickname}
                            <br />
                            <small className="fw-normal">
                                {project.nickname}
                            </small>
                        </h6>
                    </button>
                </div>
                <div onClick={onProjectClick} className="card-body-holder">
                    {isNotNullOrWhiteSpace(project.thumbnail) && (
                        <div className="thumbnail-holder">
                            <img
                                src={project.thumbnail}
                                crossOrigin="anonymous"
                                className="mix-thumbnail"
                                alt={project.name}
                            />
                        </div>
                    )}
                    <div className="card-body">
                        <h4 className="card-title mt-0 mb-2">{project.name}</h4>
                        <p className="card-text">
                            {project.description?.slice(0, 120)}
                        </p>
                    </div>
                </div>
                {indicateIsTemplate && project.isTemplate && (
                    <div className="card-footer justify-content-between">
                        <div className="stats static"></div>
                        <span
                            className="btn-category btn btn-outline-dark btn-sm"
                            onClick={onProjectClick}
                        >
                            Template
                        </span>
                    </div>
                )}

                {project.tags.length > 0 && (
                    <div className="card-footer justify-content-start">
                        <h6 className="text-primary m-0 me-2">Tags: </h6>
                        <div className="d-flex gap-2">
                            {project.tags.map((tag, i) => (
                                <h6 className="m-0" key={i}>
                                    <span className="fw-normal badge bg-secondary">
                                        {tag}
                                    </span>
                                </h6>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GalleryCard;
