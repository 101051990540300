import ContainerFile from './ContainerFile.tsx';
import {useState} from 'react';
import FormConfirmEdit from './FormConfirmEdit.tsx';
import {toast} from 'react-toastify';
import CopyButton from '../shared/CopyButton.tsx';
import ConfirmButton from '../shared/ConfirmButton.tsx';
import UploadModal from './UploadModal.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faFolderOpen,
    faFolderPlus,
    faTrashAlt,
    faUpload
} from '@fortawesome/free-solid-svg-icons';
import baseUri from '../../api/baseUri.ts';
import {DirectoryModel, FileDataModel} from '../../api/types.ts';
import {useMutation} from 'react-query';
import useDeleteFolder from '../../api/uploads/useDeleteFolder.ts';
import useRenameFolder from '../../api/uploads/useRenameFolder.ts';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import joinPath from '../../lib/joinPath.ts';
import {ReactStateSetter} from '../../types.ts';
import AssetPermissions from './AssetPermissions.ts';

interface ContainerDirectoryProps {
    parentDirectory?: DirectoryModel | null;
    directory: DirectoryModel;
    setDirectory: (directory: Partial<DirectoryModel>) => void;
    setParentDirectory?: (directory: Partial<DirectoryModel>) => void;
    containerName: string;
    onFilesUploaded: (files: FileDataModel[]) => void;
    getPermissions: (
        directory: DirectoryModel,
        parentDirectory?: DirectoryModel | null
    ) => AssetPermissions;
    refetch: () => void;
    editingPath: string | null;
    setEditingPath: ReactStateSetter<string | null>;
}

const ContainerDirectory: React.FC<ContainerDirectoryProps> = ({
    parentDirectory = null,
    directory,
    setDirectory,
    setParentDirectory,
    containerName,
    onFilesUploaded,
    getPermissions,
    refetch,
    editingPath,
    setEditingPath
}) => {
    const permissions = getPermissions(directory, parentDirectory);

    const [showUploadModal, setShowUploadModal] = useState(false);

    const isPosts =
        directory.path === 'posts' || directory.path.startsWith('posts/');

    const isEditing = editingPath === directory.path;

    const deleteFolder = useDeleteFolder();
    const deleteFolderMutation = useMutation(deleteFolder, {
        onSuccess: () => {
            toast.success('Folder deleted');
            refetch();
        },
        onError: (error: unknown) => {
            toast.error(unknownErrorToString(error));
        }
    });

    const renameFolder = useRenameFolder();
    const renameFolderMutation = useMutation(renameFolder, {
        onSuccess: () => {
            toast.success('Folder renamed');
            refetch();
        },
        onError: (error: unknown) => {
            toast.error(unknownErrorToString(error));
        }
    });

    const folderApiUrl = () =>
        `${baseUri}/api/files/${containerName}?folderPath=${encodeURIComponent(
            directory.path
        )}`;

    const onApplyDirectoryRename = async (newValue: string) => {
        if (
            parentDirectory &&
            parentDirectory.directories.some(
                d =>
                    d.name.toLowerCase() == newValue.toLowerCase() &&
                    d.path != directory.path
            )
        ) {
            toast.error('A folder with that name already exists.');
            return;
        }

        if (
            parentDirectory &&
            parentDirectory.isRoot &&
            newValue.toLowerCase() === 'posts'
        ) {
            toast.error('Cannot name a folder "posts".');
            return;
        }

        let segments: string[] = directory.path.split('/');
        segments = segments.slice(0, segments.length - 1);
        const destinationFolderPath: string = (
            '/' +
            segments.join('/') +
            '/' +
            newValue
        ).replace(/^\/+/, '');

        if (directory.isNew) {
            setDirectory({
                path: joinPath('', destinationFolderPath),
                name: newValue
            });

            setEditingPath(null);
            return;
        }

        await renameFolderMutation.mutateAsync({
            containerName,
            sourceFolderPath: directory.path,
            destinationFolderPath
        });

        setEditingPath(null);
    };

    const onAddDirectory = () => {
        let newDirectoryName = 'New Folder';
        const existingFolderNames = directory.directories.map(d => d.name);
        const hasNewFolders = existingFolderNames.some(d =>
            d.toLowerCase().startsWith(newDirectoryName.toLowerCase())
        );

        let count = 1;
        if (hasNewFolders) {
            let potentialFolderName: string;
            do {
                potentialFolderName = `New Folder (${count})`;
                count++;
            } while (
                existingFolderNames.some(
                    n => n.toLowerCase() === potentialFolderName.toLowerCase()
                )
            );

            newDirectoryName = potentialFolderName;
        }

        const newDirectoryPath = joinPath(directory.path, newDirectoryName);

        const newDirectory: DirectoryModel = {
            name: newDirectoryName,
            path: newDirectoryPath,
            isNew: true,
            directories: [],
            files: [],
            isRoot: false,
            length: 0
        };

        setDirectory({
            directories: [...directory.directories, newDirectory]
        });

        setEditingPath(newDirectoryPath);
    };

    const onDeleteDirectory = async () => {
        if (directory.isNew) {
            if (setParentDirectory && parentDirectory) {
                setParentDirectory({
                    ...parentDirectory,
                    directories: parentDirectory.directories.filter(
                        dir => dir != directory
                    )
                });
            }

            return;
        }

        await deleteFolderMutation.mutateAsync({
            containerName,
            folderPath: directory.path
        });
    };

    const upload = () => {
        setShowUploadModal(true);
    };

    return (
        <>
            {showUploadModal && (
                <UploadModal
                    folderPath={directory.path}
                    specificContainerName={containerName}
                    onClose={() => setShowUploadModal(false)}
                    onFilesUploaded={onFilesUploaded}
                />
            )}
            <ul className="uploads-list">
                <li>
                    <div className="uploads-list-row">
                        <span className="uploads-list-row-name">
                            <div className="list-row-icon">
                                <FontAwesomeIcon icon={faFolderOpen} />
                            </div>
                            {isEditing && (
                                <FormConfirmEdit
                                    value={directory.name}
                                    onApply={onApplyDirectoryRename}
                                    onCancel={() => setEditingPath(null)}
                                />
                            )}
                            {!isEditing && (
                                <span
                                    className="folder-name font-weight-bold"
                                    onClick={
                                        directory.isRoot ||
                                        isPosts ||
                                        !permissions.canRenameOrDeleteDirectory
                                            ? undefined
                                            : () =>
                                                  setEditingPath(directory.path)
                                    }
                                >
                                    {directory.name}
                                </span>
                            )}
                        </span>
                        <span className="folder-action-icons">
                            <CopyButton
                                cssClass="action-link"
                                copyText={folderApiUrl()}
                                title="Copy folder API url"
                                iconCssClass="fas fa-link"
                            />

                            {!isPosts && (
                                <>
                                    {permissions.canAddDirectory && (
                                        <button
                                            className="action-link"
                                            onClick={() => onAddDirectory()}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFolderPlus}
                                                title="New folder"
                                            />
                                        </button>
                                    )}

                                    {permissions.canUpload && (
                                        <button
                                            className="action-link"
                                            onClick={() => upload()}
                                        >
                                            <FontAwesomeIcon
                                                icon={faUpload}
                                                title="Upload new file"
                                            />{' '}
                                            Upload
                                        </button>
                                    )}
                                </>
                            )}

                            {!directory.isRoot &&
                                permissions.canRenameOrDeleteDirectory &&
                                !isPosts && (
                                    <ConfirmButton
                                        cssClass="action-link"
                                        onClick={onDeleteDirectory}
                                        message="Deleting this folder will mean that it is not available in any content that depends on it."
                                    >
                                        <FontAwesomeIcon
                                            icon={faTrashAlt}
                                            title="Delete folder"
                                        />
                                    </ConfirmButton>
                                )}
                        </span>
                    </div>
                </li>
                <li>
                    <ul>
                        {directory.directories.map((dir, index) => (
                            <li key={dir.path}>
                                <ContainerDirectory
                                    parentDirectory={directory}
                                    directory={dir}
                                    setDirectory={updatedDirectory =>
                                        setDirectory({
                                            ...directory,
                                            directories:
                                                directory.directories.map(
                                                    (d, i) =>
                                                        i === index
                                                            ? {
                                                                  ...d,
                                                                  ...updatedDirectory
                                                              }
                                                            : d
                                                )
                                        })
                                    }
                                    setParentDirectory={parentDirectoryPartial =>
                                        setDirectory({
                                            ...directory,
                                            ...parentDirectoryPartial
                                        })
                                    }
                                    containerName={containerName}
                                    onFilesUploaded={onFilesUploaded}
                                    getPermissions={getPermissions}
                                    refetch={refetch}
                                    editingPath={editingPath}
                                    setEditingPath={setEditingPath}
                                />
                            </li>
                        ))}
                    </ul>
                </li>
                <li>
                    <ul>
                        {directory.files.map(fileData => (
                            <li key={fileData.path}>
                                <ContainerFile
                                    directory={directory}
                                    fileData={fileData}
                                    permissions={permissions}
                                    refetch={refetch}
                                />
                            </li>
                        ))}
                    </ul>
                </li>
            </ul>
        </>
    );
};

export default ContainerDirectory;
