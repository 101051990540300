﻿import {fabric} from 'fabric';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
fabric.Sprite = fabric.util.createClass(fabric.Image, {
    type: 'sprite',
    selectable: false, // Set selectable to false
    evented: false,
    spriteWidth: 40,
    spriteHeight: 40,
    spriteIndex: 0,
    frameTime: 100,

    initialize(element: HTMLImageElement, options?: any): void {
        options = options || {};
        options.width = this.spriteWidth;
        options.height = this.spriteHeight;
        this.callSuper('initialize', element, options);
        this.createTmpCanvas();
        this.createSpriteImages();
    },

    createTmpCanvas(): void {
        this.tmpCanvasEl = fabric.util.createCanvasElement();
        this.tmpCanvasEl.width = this.spriteWidth || this.width;
        this.tmpCanvasEl.height = this.spriteHeight || this.height;
    },

    createSpriteImages(): void {
        this.spriteImages = [];
        const steps = this._element.width / this.spriteWidth;
        for (let i = 0; i < steps; i++) {
            this.createSpriteImage(i);
        }
    },

    createSpriteImage(i: number): void {
        const tmpCtx = this.tmpCanvasEl.getContext('2d');
        tmpCtx.clearRect(0, 0, this.tmpCanvasEl.width, this.tmpCanvasEl.height);
        tmpCtx.drawImage(this._element, -i * this.spriteWidth, 0);
        const dataURL = this.tmpCanvasEl.toDataURL('image/png');
        const tmpImg = fabric.util.createImage();
        tmpImg.src = dataURL;
        this.spriteImages.push(tmpImg);
    },

    _render(ctx: CanvasRenderingContext2D): void {
        ctx.drawImage(
            this.spriteImages[this.spriteIndex],
            -this.width / 2,
            -this.height / 2
        );
    },

    play(): void {
        this.animInterval = setInterval(() => {
            if (this.onPlay) {
                this.onPlay();
            }
            this.dirty = true;
            this.spriteIndex++;
            if (this.spriteIndex === this.spriteImages.length) {
                this.spriteIndex = 0;
            }
        }, this.frameTime);
    },

    stop(): void {
        clearInterval(this.animInterval);
    }
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
fabric.Sprite.fromURL = function (
    url: string,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    callback: (sprite: fabric.Sprite) => void,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    imgOptions?: any
): void {
    // Create a new Image object
    const img = new Image();
    img.crossOrigin = 'anonymous'; // Set crossOrigin attribute to anonymous
    img.onload = function () {
        // Once the image has loaded, use it to create a Sprite
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        callback(new fabric.Sprite(img, imgOptions));
    };
    img.src = url; // Set the source URL of the image
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
fabric.Sprite.async = true;
