import {CollectionJoinCodeModel} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetCollectionJoinCodeQuery = {
    collectionId: string;
};

const useGetCollectionJoinCode = (request: GetCollectionJoinCodeQuery) =>
    useGet<CollectionJoinCodeModel>(`/api/collections/get-collection-join-code?${toQuery(request)}`, {errorMessage: 'GET api/collections/get-collection-join-code failed'
});

export default useGetCollectionJoinCode;
