import {
    Button,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import ButtonAsync from '../shared/ButtonAsync.tsx';
import React, {useState} from 'react';
import useUpsertJournalEntry from '../../api/journal/useUpsertJournalEntry.ts';
import {useMutation} from 'react-query';
import {toast} from 'react-toastify';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import {
    ContentPackageModel,
    ContentSectionType,
    JournalEntryModel
} from '../../api/types.ts';
import htmlEncode from '../../lib/htmlEncode.ts';
import emptyGuid from '../../lib/emptyGuid.ts';
import {isNotNullOrWhiteSpace} from '../../lib/isNullOrWhiteSpace.ts';

interface AddToJournalModalProps {
    mixId: string;
    captureDataUri: string;
    onClose: () => void;
}

const AddToJournalModal: React.FC<AddToJournalModalProps> = ({
    mixId,
    captureDataUri,
    onClose
}) => {
    const [comment, setComment] = useState('');

    const upsertJournalEntry = useUpsertJournalEntry();
    const upsertJournalEntryMutation = useMutation(upsertJournalEntry, {
        onSuccess: result => {
            if (result) {
                toast.success('Journal entry added.');
                onClose();
            }
        },
        onError: (error: unknown) => {
            toast.error(
                'Unable to complete save. ' + unknownErrorToString(error)
            );
        }
    });

    const onAdd = async () => {
        const sections = isNotNullOrWhiteSpace(comment)
            ? [
                  {
                      ordinalPosition: 0,
                      type: ContentSectionType.RichText,
                      content: `<p>${htmlEncode(comment)}</p>`
                  },
                  {
                      ordinalPosition: 1,
                      type: ContentSectionType.Picture,
                      fileName: captureDataUri
                  }
              ]
            : [
                  {
                      ordinalPosition: 0,
                      type: ContentSectionType.Picture,
                      fileName: captureDataUri
                  }
              ];

        const content: ContentPackageModel = {
            id: null,
            sections
        };

        const journalEntry: JournalEntryModel = {
            id: null,
            content,
            mixId,
            userId: emptyGuid,
            createdOnUtc: null,
            isDeleted: false,
            autoGenerated: false
        };

        await upsertJournalEntryMutation.mutateAsync({
            journalEntryModel: journalEntry
        });
    };

    return (
        <>
            <Modal isOpen size="lg" toggle={onClose}>
                <ModalHeader>Add to Journal</ModalHeader>
                <ModalBody>
                    <img className="img-fluid" src={captureDataUri} />
                    <FormGroup>
                        <Label for="EntryText">Comment</Label>
                        <Input
                            id="EntryText"
                            type="textarea"
                            value={comment}
                            onChange={event => setComment(event.target.value)}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between align-items-center">
                    <Button color="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <ButtonAsync color="primary" onClick={onAdd}>
                        Add
                    </ButtonAsync>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default AddToJournalModal;
