import {GroupMembersModel} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GroupMembersByIdQuery = {
    collectionId: string;
};

const useGroupMembersById = (request: GroupMembersByIdQuery) =>
    useGet<GroupMembersModel>(`/api/groups/group-members-by-id?${toQuery(request)}`, {errorMessage: 'GET api/groups/group-members-by-id failed'
});

export default useGroupMembersById;
