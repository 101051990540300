import React, {useState, useEffect, useRef} from 'react';
import {useAuthStateManager} from '../../hooks/useAuthStateManager';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import {PostModel, PostType} from '../../api/types.ts';
import {useMutation} from 'react-query';
import useAuthorPost from '../../api/posts/useAuthorPost.ts';
import {toast} from 'react-toastify';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import RichTextEditor from '../content_editor/RichTextEditor.tsx';
import {ClearEditorRef} from '../content_editor/plugins/ClearEditorPlugin.tsx';

interface MxAuthorPostProps {
    id: string;
    collection: {id: string | null};
    channel: {id: string | null};
    type: PostType;
    parentPostId?: string;
}

const MxAuthorPost: React.FC<MxAuthorPostProps> = ({
    id,
    collection,
    channel,
    type,
    parentPostId
}) => {
    const {loggedInUserId} = useAuthStateManager();
    const [post, setPost] = useState<PostModel | null>(null);

    const authorPost = useAuthorPost();
    const authorPostMutation = useMutation(authorPost, {
        onSuccess: () => {
            toast.success('Post was sent');
            setPost({
                ...post!,
                content: ''
            });

            clearEditor();
        },
        onError: error => {
            toast.error('Unable to post ' + unknownErrorToString(error));
        }
    });

    useEffect(() => {
        setPost({
            userId: loggedInUserId!,
            collectionId: collection.id!,
            channelId: channel.id!,
            content: '',
            postType: type,
            replyToId: parentPostId ?? null
        });
    }, [loggedInUserId, collection, channel, type, parentPostId]);

    const handlePost = async () => {
        if (post) {
            await authorPostMutation.mutateAsync({
                post: post!
            });
        }
    };

    const clearEditorRef = useRef<ClearEditorRef>(null);

    const clearEditor = () => {
        if (clearEditorRef.current) {
            clearEditorRef.current.callClearEditor();
        }
    };

    return (
        <div className="make-post mixi-card no-hover">
            <div className="card-content author-post">
                {post && (
                    <RichTextEditor
                        id={id}
                        html={post?.content ?? ''}
                        htmlChanged={html =>
                            setPost(prevState => ({
                                ...prevState!,
                                content: html
                            }))
                        }
                        imageSelector
                        videoSelector
                        youTubeSelector
                        fileSelector
                        emojiSelector
                        mentions
                        mentionsCollectionId={collection.id!}
                        ref={clearEditorRef}
                    />
                )}
                <div className="make-post-actions">
                    <button
                        className="btn btn-primary btn-icon"
                        disabled={!post || !post.content}
                        onClick={handlePost}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} /> Post
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MxAuthorPost;
