import {NormalizedColor, HTMLColor} from './types';

const isValidHexColor = (color: string): boolean => {
    return /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);
};

const isValidRGBColor = (color: NormalizedColor): boolean => {
    return (
        typeof color.r === 'number' &&
        typeof color.g === 'number' &&
        typeof color.b === 'number' &&
        color.r >= 0 &&
        color.r <= 1 &&
        color.g >= 0 &&
        color.g <= 1 &&
        color.b >= 0 &&
        color.b <= 1 &&
        (color.a === undefined ||
            (typeof color.a === 'number' && color.a >= 0 && color.a <= 1))
    );
};

const rgbToHex = (r: number, g: number, b: number, a?: number): string => {
    const toHex = (value: number) =>
        Math.round(value * 255)
            .toString(16)
            .padStart(2, '0');
    const hex = [r, g, b].map(toHex).join('');
    if (a !== undefined) {
        const alpha = toHex(a);
        return `#${hex}${alpha}`;
    }
    return `#${hex}`;
};

const getColorHexValue = (
    color: NormalizedColor | HTMLColor | undefined | null
): string => {
    if (color === undefined || color === null) {
        console.warn(
            'getColorHexValue: Color is undefined or null. Returning default color #000000.'
        );
        return '#000000';
    }

    if (typeof color === 'string') {
        if (isValidHexColor(color)) {
            return color.length === 4
                ? `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`.toUpperCase()
                : color.toUpperCase();
        } else {
            console.warn(
                `getColorHexValue: Invalid hex color string "${color}". Returning default color #000000.`
            );
            return '#000000';
        }
    }

    if (isValidRGBColor(color)) {
        return rgbToHex(color.r, color.g, color.b, color.a);
    }

    console.warn(
        'getColorHexValue: Invalid color format. Returning default color #000000.'
    );
    return '#000000';
};

export default getColorHexValue;
