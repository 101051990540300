import React from 'react';
import classNames from 'classnames';

interface MxCardProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    bodyClass?: string;
    header?: React.ReactNode;
    headerClass?: string;
    footer?: React.ReactNode;
    footerClass?: string;
    contentClass?: string;
    mixiCard?: boolean;
    contentCard?: boolean;
}

const MxCard: React.FC<MxCardProps> = ({
    children,
    bodyClass,
    header,
    headerClass,
    footer,
    footerClass,
    contentClass,
    mixiCard,
    contentCard,
    className, // This now comes from the extended HTMLAttributes
    ...props // Capture all other div attributes
}) => {
    const mainClassName = classNames(
        className, // Use className from the props directly
        {'mixi-card': mixiCard},
        {'content-card': contentCard},
        contentClass
    );

    const headerClassName = classNames('card-header', headerClass);
    const bodyClassName = classNames('card-body', bodyClass);
    const footerClassName = classNames('card-footer', footerClass);

    return (
        <div className={mainClassName} {...props}>
            {header && <div className={headerClassName}>{header}</div>}
            <div className={bodyClassName}>{children}</div>
            {footer && <div className={footerClassName}>{footer}</div>}
        </div>
    );
};

export default MxCard;
