import {ProjectDataIndex, ProjectSceneScriptAndHtmlModel} from '../types';
import {useGet} from '../useHttpHooks.ts';

const useFetchProjectSceneScriptAndHtml = (
    projectId: string,
    dataIndex: ProjectDataIndex
) =>
    useGet<ProjectSceneScriptAndHtmlModel>(
        `/api/scene/${projectId}/${dataIndex}/scene-script-and-html`,
        {
            errorMessage: 'Failed to fetch project scene script and html'
        }
    );

export default useFetchProjectSceneScriptAndHtml;
