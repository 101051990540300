import {Fragment, useState} from 'react';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import LabeledFormField from '../../shared/LabeledFormField.tsx';

interface YouTubeVideoInsertModalProps {
    onClose: () => void;
    onSelectYouTubeVideo: (videoId: string) => void;
}

const YouTubeVideoInsertModal = ({
    onClose,
    onSelectYouTubeVideo
}: YouTubeVideoInsertModalProps) => {
    const closeModal = () => {
        onClose();
        setYouTubeURL('');
    };

    const [youTubeURL, setYouTubeURL] = useState<string>('');

    const insertYouTubeVideo = () => {
        if (youTubeURL == '') {
            return;
        }

        const regex =
            /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\\/\n\s]+\/\S+\/|(?:v|e)(?:\S+)?\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = youTubeURL.match(regex);

        if (match == null) {
            return;
        }

        const [, videoID] = match;
        onSelectYouTubeVideo(videoID);
    };

    return (
        <Modal isOpen={true} toggle={onClose} fullscreen zIndex={999}>
            <ModalHeader>Insert YouTube Video</ModalHeader>
            <ModalBody>
                <Fragment>
                    <LabeledFormField label="URL" htmlFor="YouTubeVideoUrl">
                        <Input
                            type="text"
                            id="YouTubeVideoUrl"
                            maxLength={1200}
                            placeholder="Enter the URL for a youtube video"
                            value={youTubeURL}
                            onChange={event => {
                                setYouTubeURL(event.target.value);
                            }}
                        />
                    </LabeledFormField>
                </Fragment>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => {
                        insertYouTubeVideo();
                        closeModal();
                    }}
                >
                    Select
                </Button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClose}
                >
                    Cancel
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default YouTubeVideoInsertModal;
