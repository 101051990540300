export default function humanFileSize(
    size?: number | null,
    nullStatement: string = ''
): string {
    if (size === undefined || size === null) {
        return nullStatement;
    }

    return bytesToString(size);
}

function bytesToString(byteCount: number): string {
    const suf = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB']; // Longs run out around EB
    if (byteCount === 0) {
        return '0' + suf[0];
    }

    const bytes = Math.abs(byteCount);
    const place = Math.floor(Math.log(bytes) / Math.log(1024));
    const num = Math.round((bytes / Math.pow(1024, place)) * 10) / 10;
    return (Math.sign(byteCount) * num).toString() + suf[place];
}
