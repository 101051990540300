﻿import {ReactNode, Suspense} from 'react';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    useNavigate
} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import pages from './pages';
import MainLayout from './components/layout/MainLayout.tsx';
import {
    AuthStateManagerProvider,
    useAuthStateManager
} from './hooks/useAuthStateManager.tsx';
import baseUri from './api/baseUri.ts';
import {useMutation} from 'react-query';
import {Authorization} from './types.ts';
import unknownErrorToString from './lib/unknownErrorToString.ts';
import useAuthMicrosoft from './api/auth/useAuthMicrosoft.ts';
import {UserStatus} from './api/types.ts';
import {MxMessagesHub} from './components/shared/MxMessagesHub.tsx';
import {GalleryStateProvider} from './components/projects/GalleryStateContext.tsx';
import {useEffectOnce} from './hooks/useEffectOnce.ts';
import {initializeAnalytics, useAnalytics} from './analytics/analytics.ts';
import useClientConfig from './hooks/useClientConfig.tsx';
import {TranslationProvider} from './components/translation/TranslationContext.tsx';

interface ComponentWithChildrenProps {
    children: ReactNode;
}

const WithMsCallback: React.FC<ComponentWithChildrenProps> = ({children}) => {
    const {onAuthenticated, removeSignInRedirect} = useAuthStateManager();

    const navigate = useNavigate();
    const authenticateMicrosoftAuth = useAuthMicrosoft();
    const mutation = useMutation(authenticateMicrosoftAuth, {
        onSuccess: authResponse => {
            if (authResponse) {
                toast.success('Login successful!');

                const authData = onAuthenticated(authResponse);
                if (authData.user_status == UserStatus.NotRegistered) {
                    navigate('/registration');
                } else {
                    const redir = removeSignInRedirect();
                    navigate(redir ?? '/');
                }
            }
        },
        onError: (error: unknown) => {
            toast.error(unknownErrorToString(error));
        }
    });

    window.msCallback = async (accessToken: string) => {
        mutation.mutate({token: accessToken});
    };

    return children;
};

const WithAnalytics: React.FC<ComponentWithChildrenProps> = ({children}) => {
    const {gaTrackingCode} = useClientConfig();

    useEffectOnce(() => {
        initializeAnalytics(gaTrackingCode ?? 'UA-129458804-1');
    });

    useAnalytics();

    return children;
};

const AuthenticatedRoutes: React.FC = () => {
    const authStateManager = useAuthStateManager();

    return (
        <Router>
            <WithAnalytics>
                <WithMsCallback>
                    <TranslationProvider>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Routes>
                                {Object.entries(pages).flatMap(([key, page]) =>
                                    page.paths.map(path => (
                                        <Route
                                            key={`${key}-${path}`}
                                            path={path}
                                            element={
                                                authStateManager.isAuthorizedForResource(
                                                    page as Authorization
                                                ) ? (
                                                    <MainLayout page={page}>
                                                        <page.component />
                                                    </MainLayout>
                                                ) : (
                                                    <Navigate to="/" replace />
                                                )
                                            }
                                        />
                                    ))
                                )}
                            </Routes>
                        </Suspense>
                    </TranslationProvider>
                </WithMsCallback>
            </WithAnalytics>
        </Router>
    );
};

const App: React.FC = () => {
    return (
        <AuthStateManagerProvider baseUri={baseUri}>
            <MxMessagesHub>
                <ToastContainer pauseOnFocusLoss={false} />
                <GalleryStateProvider>
                    <AuthenticatedRoutes />
                </GalleryStateProvider>
            </MxMessagesHub>
        </AuthStateManagerProvider>
    );
};

export default App;
