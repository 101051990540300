import {GroupPostsScroller} from './GroupPostsScroller';
declare global {
    interface Window {
        __groupPostScrollerInstances: {[key: string]: GroupPostsScroller};
    }
}

function getGroupPostsScroller(id: string) {
    return (
        window.__groupPostScrollerInstances &&
        window.__groupPostScrollerInstances[id]
    );
}

export function loadGroupPostsScroller(
    id: string,
    nextMessage: () => Promise<void>
) {
    const currentInstance = getGroupPostsScroller(id);
    if (currentInstance) {
        currentInstance.dispose();
    }

    const scrollerInstance = new GroupPostsScroller(nextMessage, id);

    if (!window.__groupPostScrollerInstances) {
        window.__groupPostScrollerInstances = {};
    }

    window.__groupPostScrollerInstances[id] = scrollerInstance;
}

export function unloadGroupPostsScroller(id: string) {
    if (getGroupPostsScroller(id)) {
        window.__groupPostScrollerInstances[id].dispose();
    }
}

export function onPostAdded(id: string, groupPost: HTMLElement) {
    const currentInstance = getGroupPostsScroller(id);

    if (currentInstance) {
        currentInstance.onPostAdded(groupPost);
    }
}
