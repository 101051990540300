import {
    ContentPackageModel,
    ContentSectionModel,
    ContentSectionType,
    ViewMode
} from '../../api/types.ts';
import {v4 as uuidv4} from 'uuid';

export enum Move {
    Up = 0,
    Down = 1
}

export function contentIsEmpty(content?: ContentPackageModel | null) {
    if (!content) {
        return true;
    }

    return content.sections.length > 0;
}

export function getNewSection(
    type: ContentSectionType,
    ordinalPosition: number,
    additionalData: Partial<ContentSectionModel> = {}
) {
    const section: ContentSectionModel = {
        id: uuidv4(),
        ordinalPosition,
        type,
        content: '',
        ...additionalData
    };

    sessionStorage.setItem(
        `ViewMode_${section.id}`,
        JSON.stringify(ViewMode.Edit)
    );

    return section;
}
