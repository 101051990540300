import {UnknownObject} from '../types.ts';

const getChanges = (
    prevState: UnknownObject,
    currentState: UnknownObject
): UnknownObject => {
    const changes: UnknownObject = {};

    for (const key in currentState) {
        if (Object.prototype.hasOwnProperty.call(currentState, key)) {
            const currentValue = currentState[key];
            const prevValue = prevState[key];

            if (Array.isArray(currentValue) && Array.isArray(prevValue)) {
                const arrayChanges = getArrayChanges(prevValue, currentValue);
                if (arrayChanges !== null) {
                    changes[key] = arrayChanges;
                }
            } else if (
                typeof currentValue === 'object' &&
                currentValue !== null &&
                typeof prevValue === 'object' &&
                prevValue !== null
            ) {
                const nestedChanges = getChanges(
                    prevValue as UnknownObject,
                    currentValue as UnknownObject
                );
                if (Object.keys(nestedChanges).length !== 0) {
                    changes[key] = nestedChanges;
                }
            } else if (currentValue !== prevValue) {
                changes[key] = currentValue;
            }
        }
    }

    return changes;
};

const getArrayChanges = (
    prevArray: unknown[],
    currentArray: unknown[]
): unknown[] | null => {
    const changes: unknown[] = [];
    let hasChanges = false;

    for (let i = 0; i < currentArray.length; i++) {
        if (i >= prevArray.length) {
            changes.push(currentArray[i]);
            hasChanges = true;
        } else if (!objectEquals(prevArray[i], currentArray[i])) {
            hasChanges = true;
            if (Array.isArray(currentArray[i]) && Array.isArray(prevArray[i])) {
                const arrayChanges = getArrayChanges(
                    prevArray[i] as unknown[],
                    currentArray[i] as unknown[]
                );
                changes.push(arrayChanges !== null ? arrayChanges : null);
            } else if (
                typeof currentArray[i] === 'object' &&
                currentArray[i] !== null &&
                typeof prevArray[i] === 'object' &&
                prevArray[i] !== null
            ) {
                const nestedChanges = getChanges(
                    prevArray[i] as UnknownObject,
                    currentArray[i] as UnknownObject
                );
                changes.push(
                    Object.keys(nestedChanges).length !== 0
                        ? nestedChanges
                        : null
                );
            } else {
                changes.push(currentArray[i]);
            }
        } else {
            changes.push(null);
        }
    }

    return hasChanges ? changes : null;
};

const objectEquals = (obj1: unknown, obj2: unknown): boolean => {
    if (obj1 === null && obj2 === null) {
        return true;
    }

    if (obj1 === null || obj2 === null) {
        return false;
    }

    if (typeof obj1 === 'object' && typeof obj2 === 'object') {
        const keys1 = Object.keys(obj1 as UnknownObject);
        const keys2 = Object.keys(obj2 as UnknownObject);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            if (
                !objectEquals(
                    (obj1 as UnknownObject)[key],
                    (obj2 as UnknownObject)[key]
                )
            ) {
                return false;
            }
        }

        return true;
    }

    return obj1 === obj2;
};

export default getChanges;
