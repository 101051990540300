import {FC} from 'react';
import {Button} from 'reactstrap';
import {FaDropbox} from 'react-icons/fa6';
import 'regenerator-runtime/runtime';
import {FileDataModel} from '../../../api/types.ts';
import {ReactStateSetter} from '../../../types.ts';
import useClientConfig from '../../../hooks/useClientConfig.tsx';
import useUploadFileFromUrl from '../../../api/uploads/useUploadFileFromUrl.ts';
import {useMutation} from 'react-query';
import unknownErrorToString from '../../../lib/unknownErrorToString.ts';
import useDropboxChooser from 'use-dropbox-chooser';
import FileFormats from '../../../lib/FileFormats.ts';
import OverlayWithSpinner from '../../shared/OverlayWithSpinner.tsx';

interface DropboxUploadPickerProps {
    extensions: string[] | undefined;
    setBackendError: ReactStateSetter<string | null>;
    onFilesUploaded: (files: FileDataModel[]) => void;
    folderPath?: string | null;
    specificContainerName?: string | null;
    onClose: () => void;
    onDropboxImageSelected: (file: File) => void;
    setDownloadingImage: ReactStateSetter<boolean>;
}

const DropboxUploadPicker: FC<DropboxUploadPickerProps> = ({
    extensions,
    setBackendError,
    onFilesUploaded,
    folderPath,
    specificContainerName,
    onClose,
    onDropboxImageSelected,
    setDownloadingImage
}) => {
    const {dropBoxAppKey} = useClientConfig();
    const uploadFileFromUrl = useUploadFileFromUrl();

    const mutation = useMutation(uploadFileFromUrl, {
        onSuccess: files => {
            files && onFilesUploaded(files);
            onClose();
        },
        onError: (error: unknown) => {
            setBackendError(unknownErrorToString(error));
        }
    });

    const {open, isOpen} = useDropboxChooser({
        appKey: dropBoxAppKey,
        chooserOptions: {extensions, linkType: 'direct'},
        onSelected: async files => {
            const fileName = files[0].name;
            const fileUrl = files[0].link;
            if (
                FileFormats.ImageFormats.some(ext =>
                    fileName.toLowerCase().endsWith(`.${ext}`)
                )
            ) {
                setDownloadingImage(true);
                fetch(fileUrl)
                    .then(response => response.blob())
                    .then(blob => {
                        const file = new File([blob], fileName, {
                            type: blob.type
                        });

                        onDropboxImageSelected(file);
                    })
                    .catch(error => {
                        console.error('Error downloading file:', error);
                    });
                setDownloadingImage(false);
            } else {
                await mutation.mutateAsync({
                    folderPath,
                    containerName: specificContainerName,
                    url: fileUrl
                });
            }
        }
    });

    return (
        <div style={{textAlign: 'center'}}>
            <Button
                color="primary"
                onClick={open}
                disabled={isOpen}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <FaDropbox size={55} />
                <span
                    style={{
                        marginTop: 10
                    }}
                >
                    Upload from Dropbox
                </span>
            </Button>

            {mutation.isLoading && <OverlayWithSpinner />}
        </div>
    );
};

export default DropboxUploadPicker;
