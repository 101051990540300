import MxCode from '../shared/MxCode.tsx';
import isNullOrWhiteSpace from '../../lib/isNullOrWhiteSpace.ts';
import CopyButton from '../shared/CopyButton.tsx';
import {MergeData} from '../../types.ts';
import {ContentSectionModel, ViewMode} from '../../api/types.ts';
import useSessionState from '../../lib/useSessionState.ts';

interface CodeSectionProps {
    elementId: string;
    section: ContentSectionModel;
    readOnly: boolean;
    mergeData: MergeData;
}

const CodeSection: React.FC<CodeSectionProps> = ({
    elementId,
    section,
    readOnly,
    mergeData
}) => {
    const [viewMode] = useSessionState(`ViewMode_${elementId}`, ViewMode.View);

    if (!readOnly && viewMode == ViewMode.Edit) {
        return (
            <>
                <fieldset className="form-group mb-3">
                    <label>File name</label>
                    <input
                        className="form-control"
                        value={section.fileName ?? ''}
                        onChange={event =>
                            mergeData(elementId, {fileName: event.target.value})
                        }
                    />
                </fieldset>

                <MxCode
                    id={`Section_Edit_${elementId}`}
                    code={section.content ?? ''}
                    onChange={code => mergeData(elementId, {content: code})}
                    readOnly={false}
                />
            </>
        );
    }

    return (
        <>
            {!isNullOrWhiteSpace(section.fileName) && (
                <div>
                    <b>{section.fileName}</b>
                </div>
            )}

            <MxCode
                id={`Section_ReadOnly_${elementId}`}
                code={section.content ?? ''}
                onChange={code => console.log(code)}
                readOnly
            />

            <div className="text-right mt-3">
                <CopyButton
                    cssClass="btn btn-outline-secondary"
                    copyText={section.content ?? ''}
                    title="Copy code"
                />
            </div>
        </>
    );
};

export default CodeSection;
