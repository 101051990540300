import {EmptyResult} from '../types';
import {usePost} from './../useHttpHooks';

export type RemoveGroupMemberCommand = {
    collectionId: string;
    userId: string;
};

const useRemoveGroupMember = () =>
    usePost<RemoveGroupMemberCommand, EmptyResult>(`/api/groups/remove-group-member`, {errorMessage: 'POST api/groups/remove-group-member failed'
});

export default useRemoveGroupMember;
