import {MixModel} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type MixByIdQuery = {
    mixId?: string | null;
    includes?: string | null;
    customUrl?: string | null;
};

const useMixById = (request: MixByIdQuery) =>
    useGet<MixModel>(`/api/mixes/mix-by-id?${toQuery(request)}`, {
        errorMessage: 'GET api/mixes/mix-by-id failed'
    });

export default useMixById;
