import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$insertNodes, COMMAND_PRIORITY_CRITICAL, PASTE_COMMAND} from 'lexical';
import {mergeRegister} from '@lexical/utils';
import {useEffect} from 'react';
import {$createImageNode, ImageNode, ImagePayload} from '../nodes/ImageNode';
import {
    INSERT_IMAGE_COMMAND,
    SET_IMAGE_SRC_BY_KEY_COMMAND
} from './ImagePlugin.tsx';

export default function PastePlugin({
    onPasteImage
}: {
    onPasteImage?: (
        dataURL: string,
        onUploadedCallback: (imageURL: string) => void
    ) => void;
}): JSX.Element | null {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand<ClipboardEvent>(
                PASTE_COMMAND,
                event => {
                    const items = event.clipboardData?.items;
                    if (items) {
                        for (let i = 0; i < items.length; i++) {
                            const item = items[i];
                            if (item.type.indexOf('image') !== -1) {
                                const blob = item.getAsFile();
                                if (blob) {
                                    let imageNode: ImageNode | null = null;
                                    if (onPasteImage) {
                                        const placeholderImage =
                                            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

                                        const imagePayload: ImagePayload = {
                                            src: placeholderImage,
                                            altText: ''
                                        };

                                        imageNode =
                                            $createImageNode(imagePayload);

                                        $insertNodes([imageNode]);
                                    }

                                    const reader = new FileReader();
                                    reader.onload = () => {
                                        const dataURL = reader.result as string;

                                        if (onPasteImage && imageNode) {
                                            onPasteImage(dataURL, imageURL => {
                                                editor.dispatchCommand(
                                                    SET_IMAGE_SRC_BY_KEY_COMMAND,
                                                    {
                                                        key: imageNode!.getKey(),
                                                        imageSrc: imageURL
                                                    }
                                                );
                                            });
                                        } else {
                                            const payload: Readonly<ImagePayload> =
                                                {
                                                    src: dataURL,
                                                    altText: ''
                                                };
                                            editor.dispatchCommand(
                                                INSERT_IMAGE_COMMAND,
                                                payload
                                            );
                                        }
                                    };
                                    reader.readAsDataURL(blob);
                                    return true;
                                }
                            }
                        }
                    }
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL
            )
        );
    }, [editor]);

    return null;
}
