import {FC} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFolderOpen} from '@fortawesome/free-solid-svg-icons';
import {DirectoryModel, FileDataModel, Selection} from '../../api/types.ts';
import {FileFormat} from '../../lib/FileFormats.ts';
import useClientConfig from '../../hooks/useClientConfig.tsx';
import CopyButton from '../shared/CopyButton.tsx';
import AssetSelectorFile from './AssetSelectorFile.tsx';

interface AssetSelectorDirectoryProps {
    directory: DirectoryModel;
    containerName: string;
    selection: Selection;
    fileSelectionChanged: (file: FileDataModel) => void;
    formats?: FileFormat;
    fileIsSelected: (file: string) => boolean;
}

const AssetSelectorDirectory: FC<AssetSelectorDirectoryProps> = ({
    directory,
    containerName,
    selection,
    fileSelectionChanged,
    formats,
    fileIsSelected
}) => {
    const {apiServiceBaseUri} = useClientConfig();

    const folderApiUrl = `${apiServiceBaseUri}api/file/${containerName}?folderPath=${encodeURIComponent(
        directory.path
    )}`;

    return (
        <div className="uploads">
            <ul className="uploads-list">
                <li>
                    <div className="uploads-list-row">
                        <span className="uploads-list-row-name">
                            <div className="list-row-icon">
                                <FontAwesomeIcon icon={faFolderOpen} />
                            </div>
                            <span className="folder-name font-weight-bold">
                                {directory.name}
                            </span>
                            {selection === Selection.None && (
                                <div className="folder-action-icons">
                                    <CopyButton
                                        cssClass="action-link"
                                        copyText={folderApiUrl}
                                        title="Copy folder API url"
                                        iconCssClass="fas fa-link"
                                    />
                                </div>
                            )}
                        </span>
                    </div>
                </li>
                <li>
                    <ul>
                        {directory.directories.map(dir => (
                            <li key={dir.path}>
                                <AssetSelectorDirectory
                                    directory={dir}
                                    containerName={containerName}
                                    selection={selection}
                                    fileSelectionChanged={fileSelectionChanged}
                                    formats={formats}
                                    fileIsSelected={fileIsSelected}
                                />
                            </li>
                        ))}
                    </ul>
                </li>
                <li>
                    <ul>
                        {directory.files.map(file => (
                            <li key={file.path}>
                                <AssetSelectorFile
                                    file={file}
                                    selection={selection}
                                    fileSelectionChanged={fileSelectionChanged}
                                    formats={formats}
                                    fileIsSelected={fileIsSelected}
                                />
                            </li>
                        ))}
                    </ul>
                </li>
            </ul>
        </div>
    );
};

export default AssetSelectorDirectory;
