export function isRelativeUrl(url: string): boolean {
    return !isUrl(url) && /^\/|\.\/|\.\.\//.test(url);
}

export function isUrl(str: string | null | undefined): boolean {
    if (!str) {
        return false;
    }

    try {
        new URL(str);
        return true;
    } catch {
        return false;
    }
}
