import {EmptyResult, UserCollectionType, MembershipStatus} from '../types';
import {usePost} from './../useHttpHooks';

export type UpdateGroupMemberCommand = {
    collectionId: string;
    userId: string;
    userCollectionType: UserCollectionType;
    membershipStatus: MembershipStatus;
};

const useUpdateGroupMember = () =>
    usePost<UpdateGroupMemberCommand, EmptyResult>(`/api/groups/update-group-member`, {errorMessage: 'POST api/groups/update-group-member failed'
});

export default useUpdateGroupMember;
