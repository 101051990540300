import React from 'react';
import {AiFeedbackItems} from '../../api/types.ts';

interface AssistantFeedbackContainerProps {
    feedbacks: AiFeedbackItems;
    templateKey: string;
}

const AssistantFeedbackContainer: React.FC<AssistantFeedbackContainerProps> = ({
    feedbacks,
    templateKey
}) => {
    if (!feedbacks[templateKey]) {
        return null;
    }

    return (
        <div className="feedback-container">
            <p style={{color: '#e619ce'}}>{feedbacks[templateKey]}</p>
        </div>
    );
};

export default AssistantFeedbackContainer;
