class Containers {
    public static readonly Apps = 'apps';
    public static readonly Binaries = 'binaries';
    public static readonly Thumbnails = 'thumbnails';
    public static readonly SiteContentMedia = 'sitecontentmedia';
    public static readonly UsefulStuff = 'usefulstuff';
    public static readonly Featured = 'featured';
    public static readonly Public = 'public';
    public static readonly UserContainerPrefix = 'u-';
    public static readonly TemplatesContainerName = 'c-templates';
    public static readonly ReservedNames: string[] = [
        Containers.Thumbnails,
        Containers.SiteContentMedia,
        Containers.UsefulStuff,
        Containers.Featured,
        Containers.Public,
        Containers.Binaries
    ];
}

export default Containers;
