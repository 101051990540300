import React, {ReactNode} from 'react';
import {
    ContentSectionModel,
    ElementIdAndContentSections,
    ContentSectionType,
    TemplateContentConfig,
    ContentPackageData,
    AiFeedbackItems
} from '../../api/types.ts';
import CodeSection from './CodeSection.tsx';
import ContentSectionPicker from './ContentSectionPicker.tsx';
import SectionContainer from './SectionContainer.tsx';
import HtmlSection from './HtmlSection.tsx';
import MarkdownSection from './MarkdownSection.tsx';
import VideoSection from './VideoSection.tsx';
import PictureSection from './PictureSection.tsx';
import AudioSection from './AudioSection.tsx';
import HeaderSection from './HeaderSection.tsx';
import ModelSection from './ModelSection.tsx';
import RichTextSection from './RichTextSection.tsx';
import DownloadSection from './DownloadSection.tsx';
import CopySection from './CopySection.tsx';
import EmbedSection from './EmbedSection.tsx';
import {EntityDataComponentProps} from '../../types.ts';
import {Move} from './lib.ts';
import classNames from 'classnames';
import emptyGuid from '../../lib/emptyGuid.ts';
import ProjectsSection from './ProjectsSection.tsx';
import AssistantFeedbackContainer from '../assistant/AiFeedbackContainer.tsx';

interface MxContentPackageProps extends EntityDataComponentProps {
    contentPackageId?: string | null;
    cssClass?: string | null;
    readOnly: boolean;
    contentTop?: ReactNode;
    actions?: ReactNode;
    controls?: ReactNode;
    intro?: ReactNode;
    emptyContent?: ReactNode;
    controlsBottom?: ReactNode;
    packageContainerName?: string;
    onPasteImage?: (
        dataURL: string,
        onUploadedCallback: (imageURL: string) => void
    ) => void;
    templateConfig?: TemplateContentConfig | null;
    isTemplateContentPackage?: boolean;
    feedbacks?: AiFeedbackItems;
}

const MxContentPackage: React.FC<MxContentPackageProps> = ({
    contentPackageId,
    entityData,
    manipulation,
    actions,
    controls,
    contentTop,
    controlsBottom,
    emptyContent,
    intro,
    packageContainerName,
    readOnly,
    cssClass,
    onPasteImage,
    templateConfig,
    isTemplateContentPackage,
    feedbacks
}) => {
    const {mergeData, removeElement} = manipulation;
    const contentPackageData = entityData as unknown as ContentPackageData;

    const sections: ElementIdAndContentSections = Object.entries(
        contentPackageData
    )
        .sort(([, a], [, b]) => a.ordinalPosition - b.ordinalPosition)
        .map(([elementId, section]) => ({elementId, section}));

    const getSectionControl = (
        elementId: string,
        section: ContentSectionModel
    ) => {
        switch (section.type) {
            case ContentSectionType.Code:
                return (
                    <CodeSection
                        elementId={elementId}
                        section={section}
                        readOnly={readOnly}
                        mergeData={mergeData}
                    />
                );
            case ContentSectionType.Html:
                return (
                    <HtmlSection
                        elementId={elementId}
                        section={section}
                        readOnly={readOnly}
                        mergeData={mergeData}
                    />
                );
            case ContentSectionType.Markdown:
                return (
                    <MarkdownSection
                        elementId={elementId}
                        section={section}
                        readOnly={readOnly}
                        mergeData={mergeData}
                    />
                );
            case ContentSectionType.Video:
                return (
                    <VideoSection
                        elementId={elementId}
                        section={section}
                        readOnly={readOnly}
                        mergeData={mergeData}
                        packageContainerName={packageContainerName}
                    />
                );
            case ContentSectionType.Picture:
                return (
                    <PictureSection
                        elementId={elementId}
                        section={section}
                        readOnly={readOnly}
                        mergeData={mergeData}
                        packageContainerName={packageContainerName}
                    />
                );
            case ContentSectionType.Audio:
                return (
                    <AudioSection
                        elementId={elementId}
                        section={section}
                        readOnly={readOnly}
                        mergeData={mergeData}
                        packageContainerName={packageContainerName}
                    />
                );
            case ContentSectionType.Header:
                return (
                    <HeaderSection
                        elementId={elementId}
                        section={section}
                        readOnly={readOnly}
                        mergeData={mergeData}
                    />
                );
            case ContentSectionType.Model:
                return (
                    <ModelSection
                        elementId={elementId}
                        section={section}
                        readOnly={readOnly}
                        mergeData={mergeData}
                        packageContainerName={packageContainerName}
                    />
                );
            case ContentSectionType.RichText:
                return (
                    <RichTextSection
                        elementId={elementId}
                        section={section}
                        readOnly={readOnly}
                        mergeData={mergeData}
                        onPasteImage={onPasteImage}
                        packageContainerName={packageContainerName}
                    />
                );
            case ContentSectionType.Download:
                return (
                    <DownloadSection
                        elementId={elementId}
                        section={section}
                        readOnly={readOnly}
                        mergeData={mergeData}
                        packageContainerName={packageContainerName}
                    />
                );
            case ContentSectionType.Copy:
                return (
                    <CopySection
                        elementId={elementId}
                        section={section}
                        readOnly={readOnly}
                        mergeData={mergeData}
                    />
                );
            case ContentSectionType.Embed:
                return (
                    <EmbedSection
                        elementId={elementId}
                        section={section}
                        readOnly={readOnly}
                        mergeData={mergeData}
                    />
                );
            case ContentSectionType.Projects:
                return (
                    <ProjectsSection
                        elementId={elementId}
                        section={section}
                        readOnly={readOnly}
                        mergeData={mergeData}
                    />
                );
        }
    };

    const ordinalPositions = sections.map(
        ({section}) => section.ordinalPosition
    );
    const nextOrdinalPosition =
        sections.length == 0 ? 0 : Math.max(...ordinalPositions) + 1;

    const sectionsCount = sections.length;

    const moveSection = (sectionIndex: number, move: Move) => {
        const elementAndSection = sections[sectionIndex];
        const elementAndSectionToSwap =
            sections[move == Move.Up ? sectionIndex - 1 : sectionIndex + 1];

        mergeData(emptyGuid, {
            [elementAndSection.elementId]: {
                ordinalPosition: elementAndSectionToSwap.section.ordinalPosition
            },
            [elementAndSectionToSwap.elementId]: {
                ordinalPosition: elementAndSection.section.ordinalPosition
            }
        });
    };

    return (
        <div
            className={classNames('content-editor-holder p-3', cssClass)}
            {...(contentPackageId
                ? {'data-contentpackageid': contentPackageId}
                : {})}
        >
            {contentTop}
            {!readOnly && (
                <div>
                    {actions}
                    {controls}
                </div>
            )}
            <div className="content-editor-body">
                {intro}
                {Object.entries(contentPackageData).length == 0 && (
                    <>{emptyContent}</>
                )}

                {sections.map(({elementId, section}, sectionIndex) => (
                    <SectionContainer
                        key={elementId}
                        sections={sections}
                        elementId={elementId}
                        section={section}
                        readOnly={readOnly}
                        removeElement={removeElement}
                        mergeData={mergeData}
                        sectionsCount={sectionsCount}
                        sectionIndex={sectionIndex}
                        moveSection={moveSection}
                        templateConfig={templateConfig}
                        isTemplateContentPackage={
                            isTemplateContentPackage ?? false
                        }
                    >
                        {getSectionControl(elementId, section)}
                        {section.templateKey && feedbacks && (
                            <AssistantFeedbackContainer
                                feedbacks={feedbacks}
                                templateKey={section.templateKey}
                            />
                        )}
                    </SectionContainer>
                ))}

                {!readOnly && !isTemplateContentPackage && (
                    <ContentSectionPicker
                        mergeData={mergeData}
                        ordinalPosition={nextOrdinalPosition}
                    />
                )}
            </div>
            {controlsBottom}
        </div>
    );
};

export default MxContentPackage;
