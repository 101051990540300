interface AudioPlayerProps {
    audioSrc: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({audioSrc}) => {
    return (
        <audio controls>
            <source src={audioSrc} />
        </audio>
    );
};

export default AudioPlayer;
