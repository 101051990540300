import React from 'react';
import {CollectionInfoModel, UserActivitiesFor} from '../../api/types.ts';
import CustomContainer from '../shared/CustomContainer.tsx';
import MxCard from '../shared/MxCard.tsx';
import MxUserActivities from '../profile/MxUserActivities.tsx';

interface GroupActivityTabProps {
    collection: CollectionInfoModel;
}

const GroupActivityTab: React.FC<GroupActivityTabProps> = ({collection}) => {
    return (
        <CustomContainer className="mt-5">
            <MxCard contentCard={true}>
                <MxUserActivities
                    userActivitiesFor={UserActivitiesFor.Group}
                    collectionId={collection.id!}
                />
            </MxCard>
        </CustomContainer>
    );
};

export default GroupActivityTab;
