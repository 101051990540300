﻿export interface EntityDataModel extends Record<string, UnknownObject> {}

export type MergeData = (
    elementId: string,
    data: UnknownObject,
    meta?: string | null
) => void;

export type RemoveElement = (elementId: string, meta?: string | null) => void;

export type EntityManipulationMethods = {
    mergeData: MergeData;
    removeElement: RemoveElement;
};

export interface EntityDataComponentProps {
    entityData: EntityDataModel;
    manipulation: EntityManipulationMethods;
    onRemoteManipulation?: EntityManipulationMethods;
    onInitialDataFetched?: (initialData: EntityDataModel) => void;
}

export interface EntityDataPayload {
    projectId: string;
    dataIndex: ProjectDataIndex;
    elementId: string;
    data?: UnknownObject | null;
    op: EntityDataOperation;
    meta?: string | null;
}

export enum EntityDataOperation {
    MergeData,
    RemoveElement
}

import {ProjectDataIndex, UserStatus} from './api/types.ts';

export type ReactStateSetter<T> = React.Dispatch<React.SetStateAction<T>>;

export interface UnknownObject extends Record<string, unknown> {}

export interface AuthData extends Record<string, unknown> {
    isAuthenticated: boolean;
    roles: string[];
    id?: string;
    name?: string;
    nickname?: string;
    email?: string;
    container_name?: string;
    user_status?: UserStatus;
    upload_limit?: number;
    has_collections?: boolean;
    signalr_token?: string;
}

export type Authorization = {
    authorize?: boolean;
    roles?: string[];
};

export type AuthTokens = {
    accessToken: string;
    refreshToken: string;
};

export interface SocialMediaToken {
    provider?: string;
    token: string;
    oauth_verifier?: string;
}

export type ServiceError = {
    key?: string;
    rawValue?: unknown;
    message?: string;
    detail?: string;
    stackTrace?: string;
    exceptionType?: string;
    innerException?: ServiceError;
};

export type ServiceErrors = {
    errors: ServiceError[];
    count: number;
};

export enum PreviewFormat {
    NoPreview,
    Image,
    Audio,
    Model,
    Video,
    Markdown
}

export interface IUserName {
    name?: string | null;
    nickname?: string | null;
}

export enum PostUpdateType {
    NewPost,
    NewReply,
    Deleted,
    Reaction
}

export interface PostUpdateMessage {
    postUpdateType: PostUpdateType;
    collectionId: string;
    postId: string;
    parentPostId: string | null;
    mentions: string[] | null;
}
