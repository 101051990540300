export function loadCodeEditor(id, onChange, textarea, mode, readOnly, code) {
    const options = {
        theme: 'material-darker',
        lineNumbers: true,
        mode,
        readOnly
    };

    const editorOptions = Object.assign({}, options, {
        extraKeys: {
            F11: function (cm) {
                cm.setOption('fullScreen', !cm.getOption('fullScreen'));
            },
            Esc: function (cm) {
                if (cm.getOption('fullScreen'))
                    cm.setOption('fullScreen', false);
            }
        }
    });

    const editor = window.CodeMirror.fromTextArea(textarea, editorOptions);

    editor.setValue(code);

    editor.on('change', editor => {
        onChange(editor.getValue());
    });

    if (!window.__codeMirrorEditorInstances) {
        window.__codeMirrorEditorInstances = {};
    }

    window.__codeMirrorEditorInstances[id] = editor;
}

export function unloadCodeEditor(id) {
    if (
        window.__codeMirrorEditorInstances &&
        window.__codeMirrorEditorInstances[id] &&
        window.__codeMirrorEditorInstances[id].toTextArea
    ) {
        window.__codeMirrorEditorInstances[id].toTextArea();
    }
}
