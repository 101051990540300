class SkinTones {
    public static readonly lightSkinTone = 'light skin tone';
    public static readonly mediumLightSkinTone = 'medium-light skin tone';
    public static readonly mediumSkinTone = 'medium skin tone';
    public static readonly mediumDarkSkinTone = 'medium-dark skin tone';
    public static readonly darkSkinTone = 'dark skin tone';

    public static readonly all = [
        SkinTones.lightSkinTone,
        SkinTones.mediumLightSkinTone,
        SkinTones.mediumSkinTone,
        SkinTones.mediumDarkSkinTone,
        SkinTones.darkSkinTone
    ];
}

export default SkinTones;
