import React, {useState} from 'react';
import {CollectionInfoModel, MembershipStatus} from '../../api/types.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faSignIn,
    faUserCheck,
    faUserTimes
} from '@fortawesome/free-solid-svg-icons';
import useUpdateGroupMember, {
    UpdateGroupMemberCommand
} from '../../api/groups/useUpdateGroupMember.ts';
import {useMutation} from 'react-query';
import {toast} from 'react-toastify';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import {Alert, Button} from 'reactstrap';
import RequestToJoinModal from './RequestToJoinModal.tsx';
import {Link} from 'react-router-dom';

interface GroupMembershipTabProps {
    collection: CollectionInfoModel;
    onMembershipChanged: () => Promise<void>;
}

const GroupMembershipTab: React.FC<GroupMembershipTabProps> = ({
    collection,
    onMembershipChanged
}) => {
    const {loggedInUserId, authData, isAuthenticated} = useAuthStateManager();
    const updateGroupMember = useUpdateGroupMember();
    const ignoreInviteMutation = useMutation(updateGroupMember, {
        onSuccess: () => {
            toast.success('Invite ignored');
            onMembershipChanged();
        },
        onError: (error: unknown) => {
            toast.error(
                'Failed to ignore invite ' + unknownErrorToString(error)
            );
        }
    });

    const acceptInviteMutation = useMutation(updateGroupMember, {
        onSuccess: () => {
            toast.success(`Accepted @${collection.name}'s invite to join`);
            onMembershipChanged();
        },
        onError: (error: unknown) => {
            toast.error(
                'Failed to accept invite ' + unknownErrorToString(error)
            );
        }
    });

    const ignoreInviteAsync = async () => {
        const command: UpdateGroupMemberCommand = {
            collectionId: collection.id!,
            userId: loggedInUserId!,
            userCollectionType: collection.loggedInUserUserCollectionType!,
            membershipStatus: MembershipStatus.InviteIgnored
        };
        await ignoreInviteMutation.mutateAsync(command);
    };

    const acceptInviteAsync = async () => {
        const command: UpdateGroupMemberCommand = {
            collectionId: collection.id!,
            userId: loggedInUserId!,
            userCollectionType: collection.loggedInUserUserCollectionType!,
            membershipStatus: MembershipStatus.Member
        };
        await acceptInviteMutation.mutateAsync(command);
    };

    const [showRequestToJoinModal, setShowRequestToJoinModal] = useState(false);

    const onRequested = async (membershipStatus: MembershipStatus) => {
        collection.loggedInUserMembershipStatus = membershipStatus;
        await onMembershipChanged();
    };

    const dismissRequestToJoinModal = () => {
        setShowRequestToJoinModal(false);
    };

    const requestToJoin = () => {
        setShowRequestToJoinModal(true);
    };

    if (!isAuthenticated) {
        const pageUrl = `/explore/groups/${collection.containerName}`;

        return (
            <>
                <div className="page-header wave-bg-2">
                    <div className="container-fluid custom-container position-relative">
                        <div className="row page-header-content align-items-end">
                            <div className="col-12 col-12 col-lg-7">
                                <h2>{collection.name}</h2>
                            </div>
                            <div className="header-description">
                                <p className="mb-0">{collection.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid custom-container mt-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="content-card">
                                <div className="card-content">
                                    <Alert color="warning">
                                        Please login or sign-up to request
                                        membership.
                                    </Alert>
                                    <div className="d-flex justify-content-end">
                                        {' '}
                                        <Link
                                            to={`/login?redir=${encodeURIComponent(
                                                pageUrl
                                            )}`}
                                            className="btn btn-primary"
                                        >
                                            <FontAwesomeIcon icon={faSignIn} />{' '}
                                            Login / sign-up
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="container-fluid custom-container mt-5 mb-5">
            <div className="row">
                <div className="col-12">
                    {collection.loggedInUserMembershipStatus === null && (
                        <>
                            <p>
                                Click below to request to join {collection.name}
                            </p>
                            <div className="file-type-buttons">
                                <div style={{textAlign: 'center'}}>
                                    <Button
                                        color="success"
                                        onClick={requestToJoin}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faUserCheck} />
                                        <span
                                            style={{
                                                marginTop: 10
                                            }}
                                        >
                                            Join
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}

                    {collection.loggedInUserMembershipStatus ===
                        MembershipStatus.Invited && (
                        <>
                            <p>
                                You have been invited to join {collection.name}
                            </p>
                            <div className="file-type-buttons">
                                <div style={{textAlign: 'center'}}>
                                    <Button
                                        color="primary"
                                        onClick={ignoreInviteAsync}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faUserTimes} />
                                        <span
                                            style={{
                                                marginTop: 10
                                            }}
                                        >
                                            Ignore invite
                                        </span>
                                    </Button>
                                </div>
                                <div style={{textAlign: 'center'}}>
                                    <Button
                                        color="success"
                                        onClick={acceptInviteAsync}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faUserCheck} />
                                        <span
                                            style={{
                                                marginTop: 10
                                            }}
                                        >
                                            Accept invite
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {showRequestToJoinModal && (
                <RequestToJoinModal
                    dismiss={dismissRequestToJoinModal}
                    requested={onRequested}
                    collection={collection}
                    nicknameOrEmailOrUserId={authData.nickname!}
                />
            )}
        </div>
    );
};

export default GroupMembershipTab;
