import {useDelete} from './../useHttpHooks';

export type DeleteFileCommand = {
    containerName: string;
    blobName: string;
};

const useDeleteFile = () =>
    useDelete<DeleteFileCommand>(`/api/uploads/delete-file`, {
        errorMessage: 'DELETE api/uploads/delete-file failed'
    });

export default useDeleteFile;
