import {usePost} from '../useHttpHooks.ts';
import {
    IdResult,
    ProjectDataIndex,
    ProjectSceneScriptAndHtmlModel
} from '../types.ts';

const useUpdateSceneScriptAndHtml = (
    projectId: string,
    dataIndex: ProjectDataIndex
) =>
    usePost<ProjectSceneScriptAndHtmlModel, IdResult>(
        `/api/scene/${projectId}/${dataIndex}/scene-script-and-html`,
        {
            errorMessage: 'Failed to update project scene script and html'
        }
    );

export default useUpdateSceneScriptAndHtml;
