import React from 'react';
import breakToWords from '../../lib/breakToWords.ts';

interface EnumSelectProps<TEnum extends Record<string, string | number>> {
    value: TEnum[keyof TEnum];
    onValueChange: (value: TEnum[keyof TEnum]) => void;
    id?: string;
    enumType: TEnum;
}

function EnumSelect<TEnum extends Record<string, string | number>>({
    value,
    onValueChange,
    id,
    enumType
}: EnumSelectProps<TEnum>) {
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = event.target.value as unknown as TEnum[keyof TEnum];
        onValueChange(newValue);
    };

    return (
        <select
            className="form-select"
            value={value.toString()}
            onChange={handleChange}
            id={id}
        >
            {Object.entries(enumType)
                .filter(([, enumValue]) => typeof enumValue === 'number')
                .map(([enumKey, enumValue]) => (
                    <option key={enumKey} value={enumValue.toString()}>
                        {breakToWords(enumKey)}
                    </option>
                ))}
        </select>
    );
}

export default EnumSelect;
