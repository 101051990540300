import {
    AddPendingGroupMemberResponse,
    UserCollectionType,
    MembershipStatus
} from '../types';
import {usePost} from './../useHttpHooks';

export type AddPendingGroupMemberCommand = {
    collectionId: string;
    groupAdminId: string | null;
    nicknameOrEmailOrUserId: string;
    userCollectionType: UserCollectionType;
    membershipStatus: MembershipStatus;
    collectionJoinCode?: string | null;
};

const useAddPendingGroupMember = () =>
    usePost<AddPendingGroupMemberCommand, AddPendingGroupMemberResponse>(
        `/api/groups/add-pending-group-member`,
        {errorMessage: 'POST api/groups/add-pending-group-member failed'}
    );

export default useAddPendingGroupMember;
