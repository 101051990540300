import React, {useState} from 'react';
import {Button, ButtonProps, Spinner} from 'reactstrap';
import OverlayWithSpinner from './OverlayWithSpinner.tsx';

interface ButtonAsyncProps extends ButtonProps {
    onClick: (
        event: React.MouseEvent<HTMLButtonElement>
    ) => Promise<void> | void;
    children: React.ReactNode;
    submittingText?: string;
    cssClass?: string;
}

const ButtonAsync: React.FC<ButtonAsyncProps> = ({
    onClick,
    children,
    disabled,
    submittingText = 'Submitting...',
    cssClass,
    ...buttonProps
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const result = onClick(event);
        if (result instanceof Promise) {
            setIsSubmitting(true);
            try {
                await result;
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const isActionLink = cssClass?.includes('action-link');

    return (
        <>
            <Button
                {...buttonProps}
                type="button"
                disabled={disabled || isSubmitting}
                onClick={handleClick}
                color={
                    isActionLink ? undefined : buttonProps.color ?? 'secondary'
                }
                className={cssClass}
            >
                {isSubmitting ? (
                    <>
                        <Spinner size="sm" color="light" />
                        {submittingText}
                    </>
                ) : (
                    children
                )}
            </Button>
            {isSubmitting && <OverlayWithSpinner />}
        </>
    );
};

export default ButtonAsync;
