import {MixModel, ProjectDataIndex} from '../../api/types.ts';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {applySplit, disposeSplit} from '../../lib/apply_split.js';
import emptyGuid from '../../lib/emptyGuid.ts';
import SceneTools from './SceneTools.tsx';
import baseUri from '../../api/baseUri.ts';

interface SceneComponentProps {
    project: MixModel;
    dataIndex: ProjectDataIndex;
    readOnly: boolean;
}

const SceneComponent: React.FC<SceneComponentProps> = ({
    project,
    dataIndex,
    readOnly
}) => {
    const {id} = project;
    const projectId = id!;
    const [syncToken, setSyncToken] = useState<string>(emptyGuid);
    const [dynamicHeight, setDynamicHeight] = useState<number>(0);
    const splitInstanceId = 'MixSceneSplit';

    useEffect(() => {
        !readOnly &&
            applySplit(splitInstanceId, ['#MixSceneTools', '#MixScene'], {
                sizes: [25, 75]
            });

        return () => {
            !readOnly && disposeSplit(splitInstanceId);
        };
    }, []);

    useLayoutEffect(() => {
        const projectBar = document.querySelector(
            '.project-bar'
        ) as HTMLElement;
        const projectActions = document.querySelector(
            '.project-actions'
        ) as HTMLElement;
        const body = document.body;

        if (projectBar && projectActions) {
            const projectBarRect = projectBar.getBoundingClientRect();
            const projectActionsRect = projectActions.getBoundingClientRect();
            const projectBarStyle = getComputedStyle(projectBar);
            const projectActionsStyle = getComputedStyle(projectActions);
            const bodyStyle = getComputedStyle(body);

            const projectBarMarginTop = parseFloat(projectBarStyle.marginTop);
            const projectBarMarginBottom = parseFloat(
                projectBarStyle.marginBottom
            );
            const projectActionsMarginTop = parseFloat(
                projectActionsStyle.marginTop
            );
            const projectActionsMarginBottom = parseFloat(
                projectActionsStyle.marginBottom
            );
            const bodyPaddingBottom = parseFloat(bodyStyle.paddingBottom);

            const totalHeight =
                projectBarRect.height +
                projectActionsRect.height +
                projectBarMarginTop +
                projectBarMarginBottom +
                projectActionsMarginTop +
                projectActionsMarginBottom +
                bodyPaddingBottom;

            setDynamicHeight(totalHeight);
        }
    }, []);

    if (readOnly) {
        return (
            <div className="mixcode-code" id="MixScene">
                <iframe
                    id="MixSceneIframe"
                    style={{width: '100%', height: '100%', minHeight: '800px'}}
                    src={`${baseUri}/api/scene/${projectId}/${dataIndex}/${syncToken}/scene.html`}
                    allow="keyboard"
                    tabIndex={0}
                />
            </div>
        );
    }

    return (
        <div
            className="split overflow-hidden"
            style={{
                height: `calc(100vh - ${dynamicHeight}px - 3px)`
            }}
        >
            <div className="mixcode-tools" id="MixSceneTools">
                <SceneTools
                    project={project}
                    dataIndex={dataIndex}
                    setSyncToken={setSyncToken}
                />
            </div>
            <div className="mixcode-code" id="MixScene">
                <iframe
                    id="MixSceneIframe"
                    style={{width: '100%', height: '100%', minHeight: '800px'}}
                    src={`${baseUri}/api/scene/${projectId}/${dataIndex}/${syncToken}/scene.html`}
                />
            </div>
        </div>
    );
};

export default SceneComponent;
