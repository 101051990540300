import {IUserName} from '../types.ts';
import {isNotNullOrWhiteSpace} from './isNullOrWhiteSpace.ts';
import atify from './atify.ts';

export default function displayName(userName: IUserName) {
    if (
        isNotNullOrWhiteSpace(userName.name) &&
        isNotNullOrWhiteSpace(userName.nickname)
    ) {
        return `${userName.name} ${atify(userName.nickname)}`;
    }

    return atify(userName.nickname ?? '?');
}
