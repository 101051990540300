import {ChannelInfoModel} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetCollectionChannelsQuery = {
    collectionId: string;
};

const useGetCollectionChannels = (request: GetCollectionChannelsQuery) =>
    useGet<ChannelInfoModel[]>(`/api/collections/get-collection-channels?${toQuery(request)}`, {errorMessage: 'GET api/collections/get-collection-channels failed'
});

export default useGetCollectionChannels;
