import CanvasController from './CanvasController';

interface PencilToolbarProps {
    controller: CanvasController;
}

const PencilToolbar: React.FC<PencilToolbarProps> = ({controller}) => {
    return (
        <>
            {/*
            <div className="m-1">
                <label>Drawing Mode</label>
                <select
                    value={controller.drawingMode}
                    style={{
                        background: '#f0f0f0',
                        marginLeft: 15
                    }}
                    onChange={e => controller.onDrawingModeChange(e)}
                >
                    <option value="Pencil">Pencil</option>
                    <option value="Diamond">Diamond</option>
                    <option value="VLine">V-Line</option>
                    <option value="HLine">H-Line</option>
                    <option value="Square">Square</option>
                    <option value="Spray">Spray</option>
                    <option value="Circle">Circle</option>
                </select>
            </div>
            */}
            <div className="m-1">
                <label>Line Width</label>
                <input
                    style={{
                        marginLeft: 15,
                        marginRight: 15
                    }}
                    type="range"
                    min={0}
                    max={150}
                    step={1}
                    value={controller.pencilWidth}
                    onChange={e => controller.handleLineWidthChange(e)}
                />
                <span>{controller.pencilWidth}</span>
            </div>
            <div className="m-1">
                <label>Shadow Width</label>
                <input
                    style={{
                        marginLeft: 15,
                        marginRight: 15
                    }}
                    type="range"
                    min={0}
                    max={50}
                    step={1}
                    value={controller.shadowWidth}
                    onChange={e => controller.handleShadowWidthChange(e)}
                />
                <span>{controller.shadowWidth}</span>
            </div>
            <div className="m-1">
                <label>Shadow Offset</label>
                <input
                    style={{
                        marginLeft: 15,
                        marginRight: 15
                    }}
                    type="range"
                    min={0}
                    max={50}
                    step={1}
                    value={controller.shadowOffset}
                    onChange={e => controller.handleShadowOffsetChange(e)}
                />
                <span>{controller.shadowOffset}</span>
            </div>
        </>
    );
};

export default PencilToolbar;
