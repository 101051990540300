import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {
    fa0,
    fa1,
    fa2,
    fa3,
    fa4,
    fa5,
    fa6,
    fa7,
    fa8,
    fa9,
    faFillDrip,
    faArrowsToCircle,
    faCircleChevronRight,
    faChevronCircleRight,
    faAt,
    faTrashCan,
    faTrashAlt,
    faTextHeight,
    faUserXmark,
    faUserTimes,
    faStethoscope,
    faMessage,
    faCommentAlt,
    faInfo,
    faDownLeftAndUpRightToCenter,
    faCompressAlt,
    faExplosion,
    faFileLines,
    faFileAlt,
    faFileText,
    faWaveSquare,
    faRing,
    faBuildingUn,
    faDiceThree,
    faCalendarDays,
    faCalendarAlt,
    faAnchorCircleCheck,
    faBuildingCircleArrowRight,
    faVolleyball,
    faVolleyballBall,
    faArrowsUpToLine,
    faSortDown,
    faSortDesc,
    faCircleMinus,
    faMinusCircle,
    faDoorOpen,
    faRightFromBracket,
    faSignOutAlt,
    faAtom,
    faSoap,
    faIcons,
    faHeartMusicCameraBolt,
    faMicrophoneLinesSlash,
    faMicrophoneAltSlash,
    faBridgeCircleCheck,
    faPumpMedical,
    faFingerprint,
    faHandPointRight,
    faMagnifyingGlassLocation,
    faSearchLocation,
    faForwardStep,
    faStepForward,
    faFaceSmileBeam,
    faSmileBeam,
    faFlagCheckered,
    faFootball,
    faFootballBall,
    faSchoolCircleExclamation,
    faCrop,
    faAnglesDown,
    faAngleDoubleDown,
    faUsersRectangle,
    faPeopleRoof,
    faPeopleLine,
    faBeerMugEmpty,
    faBeer,
    faDiagramPredecessor,
    faArrowUpLong,
    faLongArrowUp,
    faFireFlameSimple,
    faBurn,
    faPerson,
    faMale,
    faLaptop,
    faFileCsv,
    faMenorah,
    faTruckPlane,
    faRecordVinyl,
    faFaceGrinStars,
    faGrinStars,
    faBong,
    faSpaghettiMonsterFlying,
    faPastafarianism,
    faArrowDownUpAcrossLine,
    faSpoon,
    faUtensilSpoon,
    faJarWheat,
    faEnvelopesBulk,
    faMailBulk,
    faFileCircleExclamation,
    faCircleH,
    faHospitalSymbol,
    faPager,
    faAddressBook,
    faContactBook,
    faStrikethrough,
    faK,
    faLandmarkFlag,
    faPencil,
    faPencilAlt,
    faBackward,
    faCaretRight,
    faComments,
    faPaste,
    faFileClipboard,
    faCodePullRequest,
    faClipboardList,
    faTruckRampBox,
    faTruckLoading,
    faUserCheck,
    faVialVirus,
    faSheetPlastic,
    faBlog,
    faUserNinja,
    faPersonArrowUpFromLine,
    faScrollTorah,
    faTorah,
    faBroomBall,
    faQuidditch,
    faQuidditchBroomBall,
    faToggleOff,
    faBoxArchive,
    faArchive,
    faPersonDrowning,
    faArrowDown91,
    faSortNumericDesc,
    faSortNumericDownAlt,
    faFaceGrinTongueSquint,
    faGrinTongueSquint,
    faSprayCan,
    faTruckMonster,
    faW,
    faEarthAfrica,
    faGlobeAfrica,
    faRainbow,
    faCircleNotch,
    faTabletScreenButton,
    faTabletAlt,
    faPaw,
    faCloud,
    faTrowelBricks,
    faFaceFlushed,
    faFlushed,
    faHospitalUser,
    faTentArrowLeftRight,
    faGavel,
    faLegal,
    faBinoculars,
    faMicrophoneSlash,
    faBoxTissue,
    faMotorcycle,
    faBellConcierge,
    faConciergeBell,
    faPenRuler,
    faPencilRuler,
    faPeopleArrows,
    faPeopleArrowsLeftRight,
    faMarsAndVenusBurst,
    faSquareCaretRight,
    faCaretSquareRight,
    faScissors,
    faCut,
    faSunPlantWilt,
    faToiletsPortable,
    faHockeyPuck,
    faTable,
    faMagnifyingGlassArrowRight,
    faTachographDigital,
    faDigitalTachograph,
    faUsersSlash,
    faClover,
    faReply,
    faMailReply,
    faStarAndCrescent,
    faHouseFire,
    faSquareMinus,
    faMinusSquare,
    faHelicopter,
    faCompass,
    faSquareCaretDown,
    faCaretSquareDown,
    faFileCircleQuestion,
    faLaptopCode,
    faSwatchbook,
    faPrescriptionBottle,
    faBars,
    faNavicon,
    faPeopleGroup,
    faHourglassEnd,
    faHourglass3,
    faHeartCrack,
    faHeartBroken,
    faSquareUpRight,
    faExternalLinkSquareAlt,
    faFaceKissBeam,
    faKissBeam,
    faFilm,
    faRulerHorizontal,
    faPeopleRobbery,
    faLightbulb,
    faCaretLeft,
    faCircleExclamation,
    faExclamationCircle,
    faSchoolCircleXmark,
    faArrowRightFromBracket,
    faSignOut,
    faCircleChevronDown,
    faChevronCircleDown,
    faUnlockKeyhole,
    faUnlockAlt,
    faCloudShowersHeavy,
    faHeadphonesSimple,
    faHeadphonesAlt,
    faSitemap,
    faCircleDollarToSlot,
    faDonate,
    faMemory,
    faRoadSpikes,
    faFireBurner,
    faFlag,
    faHanukiah,
    faFeather,
    faVolumeLow,
    faVolumeDown,
    faCommentSlash,
    faCloudSunRain,
    faCompress,
    faWheatAwn,
    faWheatAlt,
    faAnkh,
    faHandsHoldingChild,
    faAsterisk,
    faSquareCheck,
    faCheckSquare,
    faPesetaSign,
    faHeading,
    faHeader,
    faGhost,
    faList,
    faListSquares,
    faSquarePhoneFlip,
    faPhoneSquareAlt,
    faCartPlus,
    faGamepad,
    faCircleDot,
    faDotCircle,
    faFaceDizzy,
    faDizzy,
    faEgg,
    faHouseMedicalCircleXmark,
    faCampground,
    faFolderPlus,
    faFutbol,
    faFutbolBall,
    faSoccerBall,
    faPaintbrush,
    faPaintBrush,
    faLock,
    faGasPump,
    faHotTubPerson,
    faHotTub,
    faMapLocation,
    faMapMarked,
    faHouseFloodWater,
    faTree,
    faBridgeLock,
    faSackDollar,
    faPenToSquare,
    faEdit,
    faCarSide,
    faShareNodes,
    faShareAlt,
    faHeartCircleMinus,
    faHourglassHalf,
    faHourglass2,
    faMicroscope,
    faSink,
    faBagShopping,
    faShoppingBag,
    faArrowDownZA,
    faSortAlphaDesc,
    faSortAlphaDownAlt,
    faMitten,
    faPersonRays,
    faUsers,
    faEyeSlash,
    faFlaskVial,
    faHand,
    faHandPaper,
    faOm,
    faWorm,
    faHouseCircleXmark,
    faPlug,
    faChevronUp,
    faHandSpock,
    faStopwatch,
    faFaceKiss,
    faKiss,
    faBridgeCircleXmark,
    faFaceGrinTongue,
    faGrinTongue,
    faChessBishop,
    faFaceGrinWink,
    faGrinWink,
    faEarDeaf,
    faDeaf,
    faDeafness,
    faHardOfHearing,
    faRoadCircleCheck,
    faDiceFive,
    faSquareRss,
    faRssSquare,
    faLandMineOn,
    faICursor,
    faStamp,
    faStairs,
    faI,
    faHryvniaSign,
    faHryvnia,
    faPills,
    faFaceGrinWide,
    faGrinAlt,
    faTooth,
    faV,
    faBangladeshiTakaSign,
    faBicycle,
    faStaffSnake,
    faRodAsclepius,
    faRodSnake,
    faStaffAesculapius,
    faHeadSideCoughSlash,
    faTruckMedical,
    faAmbulance,
    faWheatAwnCircleExclamation,
    faSnowman,
    faMortarPestle,
    faRoadBarrier,
    faSchool,
    faIgloo,
    faJoint,
    faAngleRight,
    faHorse,
    faQ,
    faG,
    faNotesMedical,
    faTemperatureHalf,
    faTemperature2,
    faThermometer2,
    faThermometerHalf,
    faDongSign,
    faCapsules,
    faPooStorm,
    faPooBolt,
    faFaceFrownOpen,
    faFrownOpen,
    faHandPointUp,
    faMoneyBill,
    faBookmark,
    faAlignJustify,
    faUmbrellaBeach,
    faHelmetUn,
    faBullseye,
    faBacon,
    faHandPointDown,
    faArrowUpFromBracket,
    faFolder,
    faFolderBlank,
    faFileWaveform,
    faFileMedicalAlt,
    faRadiation,
    faChartSimple,
    faMarsStroke,
    faVial,
    faGauge,
    faDashboard,
    faGaugeMed,
    faTachometerAltAverage,
    faWandMagicSparkles,
    faMagicWandSparkles,
    faE,
    faPenClip,
    faPenAlt,
    faBridgeCircleExclamation,
    faUser,
    faSchoolCircleCheck,
    faDumpster,
    faVanShuttle,
    faShuttleVan,
    faBuildingUser,
    faSquareCaretLeft,
    faCaretSquareLeft,
    faHighlighter,
    faKey,
    faBullhorn,
    faGlobe,
    faSynagogue,
    faPersonHalfDress,
    faRoadBridge,
    faLocationArrow,
    faC,
    faTabletButton,
    faBuildingLock,
    faPizzaSlice,
    faMoneyBillWave,
    faChartArea,
    faAreaChart,
    faHouseFlag,
    faPersonCircleMinus,
    faBan,
    faCancel,
    faCameraRotate,
    faSprayCanSparkles,
    faAirFreshener,
    faStar,
    faRepeat,
    faCross,
    faBox,
    faVenusMars,
    faArrowPointer,
    faMousePointer,
    faMaximize,
    faExpandArrowsAlt,
    faChargingStation,
    faShapes,
    faTriangleCircleSquare,
    faShuffle,
    faRandom,
    faPersonRunning,
    faRunning,
    faMobileRetro,
    faGripLinesVertical,
    faSpider,
    faHandsBound,
    faFileInvoiceDollar,
    faPlaneCircleExclamation,
    faXRay,
    faSpellCheck,
    faSlash,
    faComputerMouse,
    faMouse,
    faArrowRightToBracket,
    faSignIn,
    faShopSlash,
    faStoreAltSlash,
    faServer,
    faVirusCovidSlash,
    faShopLock,
    faHourglassStart,
    faHourglass1,
    faBlenderPhone,
    faBuildingWheat,
    faPersonBreastfeeding,
    faRightToBracket,
    faSignInAlt,
    faVenus,
    faPassport,
    faHeartPulse,
    faHeartbeat,
    faPeopleCarryBox,
    faPeopleCarry,
    faTemperatureHigh,
    faMicrochip,
    faCrown,
    faWeightHanging,
    faXmarksLines,
    faFilePrescription,
    faWeightScale,
    faWeight,
    faUserGroup,
    faUserFriends,
    faArrowUpAZ,
    faSortAlphaUp,
    faChessKnight,
    faFaceLaughSquint,
    faLaughSquint,
    faWheelchair,
    faCircleArrowUp,
    faArrowCircleUp,
    faToggleOn,
    faPersonWalking,
    faWalking,
    faL,
    faFire,
    faBedPulse,
    faProcedures,
    faShuttleSpace,
    faSpaceShuttle,
    faFaceLaugh,
    faLaugh,
    faFolderOpen,
    faHeartCirclePlus,
    faCodeFork,
    faCity,
    faMicrophoneLines,
    faMicrophoneAlt,
    faPepperHot,
    faUnlock,
    faColonSign,
    faHeadset,
    faStoreSlash,
    faRoadCircleXmark,
    faUserMinus,
    faMarsStrokeUp,
    faMarsStrokeV,
    faChampagneGlasses,
    faGlassCheers,
    faClipboard,
    faHouseCircleExclamation,
    faFileArrowUp,
    faFileUpload,
    faWifi,
    faWifi3,
    faWifiStrong,
    faBath,
    faBathtub,
    faUnderline,
    faUserPen,
    faUserEdit,
    faSignature,
    faStroopwafel,
    faBold,
    faAnchorLock,
    faBuildingNgo,
    faManatSign,
    faNotEqual,
    faBorderTopLeft,
    faBorderStyle,
    faMapLocationDot,
    faMapMarkedAlt,
    faJedi,
    faSquarePollVertical,
    faPoll,
    faMugHot,
    faCarBattery,
    faBatteryCar,
    faGift,
    faDiceTwo,
    faChessQueen,
    faGlasses,
    faChessBoard,
    faBuildingCircleCheck,
    faPersonChalkboard,
    faMarsStrokeRight,
    faMarsStrokeH,
    faHandBackFist,
    faHandRock,
    faSquareCaretUp,
    faCaretSquareUp,
    faCloudShowersWater,
    faChartBar,
    faBarChart,
    faHandsBubbles,
    faHandsWash,
    faLessThanEqual,
    faTrain,
    faEyeLowVision,
    faLowVision,
    faCrow,
    faSailboat,
    faWindowRestore,
    faSquarePlus,
    faPlusSquare,
    faToriiGate,
    faFrog,
    faBucket,
    faImage,
    faMicrophone,
    faCow,
    faCaretUp,
    faScrewdriver,
    faFolderClosed,
    faHouseTsunami,
    faSquareNfi,
    faArrowUpFromGroundWater,
    faMartiniGlass,
    faGlassMartiniAlt,
    faRotateLeft,
    faRotateBack,
    faRotateBackward,
    faUndoAlt,
    faTableColumns,
    faColumns,
    faLemon,
    faHeadSideMask,
    faHandshake,
    faGem,
    faDolly,
    faDollyBox,
    faSmoking,
    faMinimize,
    faCompressArrowsAlt,
    faMonument,
    faSnowplow,
    faAnglesRight,
    faAngleDoubleRight,
    faCannabis,
    faCirclePlay,
    faPlayCircle,
    faTablets,
    faEthernet,
    faEuroSign,
    faEur,
    faEuro,
    faChair,
    faCircleCheck,
    faCheckCircle,
    faCircleStop,
    faStopCircle,
    faCompassDrafting,
    faDraftingCompass,
    faPlateWheat,
    faIcicles,
    faPersonShelter,
    faNeuter,
    faIdBadge,
    faMarker,
    faFaceLaughBeam,
    faLaughBeam,
    faHelicopterSymbol,
    faUniversalAccess,
    faCircleChevronUp,
    faChevronCircleUp,
    faLariSign,
    faVolcano,
    faPersonWalkingDashedLineArrowRight,
    faSterlingSign,
    faGbp,
    faPoundSign,
    faViruses,
    faSquarePersonConfined,
    faUserTie,
    faArrowDownLong,
    faLongArrowDown,
    faTentArrowDownToLine,
    faCertificate,
    faReplyAll,
    faMailReplyAll,
    faSuitcase,
    faPersonSkating,
    faSkating,
    faFilterCircleDollar,
    faFunnelDollar,
    faCameraRetro,
    faCircleArrowDown,
    faArrowCircleDown,
    faFileImport,
    faArrowRightToFile,
    faSquareArrowUpRight,
    faExternalLinkSquare,
    faBoxOpen,
    faScroll,
    faSpa,
    faLocationPinLock,
    faPause,
    faHillAvalanche,
    faTemperatureEmpty,
    faTemperature0,
    faThermometer0,
    faThermometerEmpty,
    faBomb,
    faRegistered,
    faAddressCard,
    faContactCard,
    faVcard,
    faScaleUnbalancedFlip,
    faBalanceScaleRight,
    faSubscript,
    faDiamondTurnRight,
    faDirections,
    faBurst,
    faHouseLaptop,
    faLaptopHouse,
    faFaceTired,
    faTired,
    faMoneyBills,
    faSmog,
    faCrutch,
    faFontAwesome,
    faFontAwesomeFlag,
    faFontAwesomeLogoFull,
    faCloudArrowUp,
    faCloudUpload,
    faCloudUploadAlt,
    faPalette,
    faArrowsTurnRight,
    faVest,
    faFerry,
    faArrowsDownToPeople,
    faSeedling,
    faSprout,
    faLeftRight,
    faArrowsAltH,
    faBoxesPacking,
    faCircleArrowLeft,
    faArrowCircleLeft,
    faGroupArrowsRotate,
    faBowlFood,
    faCandyCane,
    faArrowDownWideShort,
    faSortAmountAsc,
    faSortAmountDown,
    faCloudBolt,
    faThunderstorm,
    faTextSlash,
    faRemoveFormat,
    faFaceSmileWink,
    faSmileWink,
    faFileWord,
    faFilePowerpoint,
    faArrowsLeftRight,
    faArrowsH,
    faHouseLock,
    faCloudArrowDown,
    faCloudDownload,
    faCloudDownloadAlt,
    faChildren,
    faChalkboard,
    faBlackboard,
    faUserLargeSlash,
    faUserAltSlash,
    faEnvelopeOpen,
    faHandshakeSimpleSlash,
    faHandshakeAltSlash,
    faMattressPillow,
    faGuaraniSign,
    faArrowsRotate,
    faRefresh,
    faSync,
    faFireExtinguisher,
    faCruzeiroSign,
    faGreaterThanEqual,
    faShieldHalved,
    faShieldAlt,
    faBookAtlas,
    faAtlas,
    faVirus,
    faEnvelopeCircleCheck,
    faLayerGroup,
    faArrowsToDot,
    faArchway,
    faHeartCircleCheck,
    faHouseChimneyCrack,
    faHouseDamage,
    faFileZipper,
    faFileArchive,
    faSquare,
    faMartiniGlassEmpty,
    faGlassMartini,
    faCouch,
    faCediSign,
    faItalic,
    faChurch,
    faCommentsDollar,
    faDemocrat,
    faZ,
    faPersonSkiing,
    faSkiing,
    faRoadLock,
    faA,
    faTemperatureArrowDown,
    faTemperatureDown,
    faFeatherPointed,
    faFeatherAlt,
    faP,
    faSnowflake,
    faNewspaper,
    faRectangleAd,
    faAd,
    faCircleArrowRight,
    faArrowCircleRight,
    faFilterCircleXmark,
    faLocust,
    faSort,
    faUnsorted,
    faListOl,
    faList12,
    faListNumeric,
    faPersonDressBurst,
    faMoneyCheckDollar,
    faMoneyCheckAlt,
    faVectorSquare,
    faBreadSlice,
    faLanguage,
    faFaceKissWinkHeart,
    faKissWinkHeart,
    faFilter,
    faQuestion,
    faFileSignature,
    faUpDownLeftRight,
    faArrowsAlt,
    faHouseChimneyUser,
    faHandHoldingHeart,
    faPuzzlePiece,
    faMoneyCheck,
    faStarHalfStroke,
    faStarHalfAlt,
    faCode,
    faWhiskeyGlass,
    faGlassWhiskey,
    faBuildingCircleExclamation,
    faMagnifyingGlassChart,
    faArrowUpRightFromSquare,
    faExternalLink,
    faCubesStacked,
    faWonSign,
    faKrw,
    faWon,
    faVirusCovid,
    faAustralSign,
    faF,
    faLeaf,
    faRoad,
    faTaxi,
    faCab,
    faPersonCirclePlus,
    faChartPie,
    faPieChart,
    faBoltLightning,
    faSackXmark,
    faFileExcel,
    faFileContract,
    faFishFins,
    faBuildingFlag,
    faFaceGrinBeam,
    faGrinBeam,
    faObjectUngroup,
    faPoop,
    faLocationPin,
    faMapMarker,
    faKaaba,
    faToiletPaper,
    faHelmetSafety,
    faHardHat,
    faHatHard,
    faEject,
    faCircleRight,
    faArrowAltCircleRight,
    faPlaneCircleCheck,
    faFaceRollingEyes,
    faMehRollingEyes,
    faObjectGroup,
    faChartLine,
    faLineChart,
    faMaskVentilator,
    faArrowRight,
    faSignsPost,
    faMapSigns,
    faCashRegister,
    faPersonCircleQuestion,
    faH,
    faTarp,
    faScrewdriverWrench,
    faTools,
    faArrowsToEye,
    faPlugCircleBolt,
    faHeart,
    faMarsAndVenus,
    faHouseUser,
    faHomeUser,
    faDumpsterFire,
    faHouseCrack,
    faMartiniGlassCitrus,
    faCocktail,
    faFaceSurprise,
    faSurprise,
    faBottleWater,
    faCirclePause,
    faPauseCircle,
    faToiletPaperSlash,
    faAppleWhole,
    faAppleAlt,
    faKitchenSet,
    faR,
    faTemperatureQuarter,
    faTemperature1,
    faThermometer1,
    faThermometerQuarter,
    faCube,
    faBitcoinSign,
    faShieldDog,
    faSolarPanel,
    faLockOpen,
    faElevator,
    faMoneyBillTransfer,
    faMoneyBillTrendUp,
    faHouseFloodWaterCircleArrowRight,
    faSquarePollHorizontal,
    faPollH,
    faCircle,
    faBackwardFast,
    faFastBackward,
    faRecycle,
    faUserAstronaut,
    faPlaneSlash,
    faTrademark,
    faBasketball,
    faBasketballBall,
    faSatelliteDish,
    faCircleUp,
    faArrowAltCircleUp,
    faMobileScreenButton,
    faMobileAlt,
    faVolumeHigh,
    faVolumeUp,
    faUsersRays,
    faWallet,
    faClipboardCheck,
    faFileAudio,
    faBurger,
    faHamburger,
    faWrench,
    faBugs,
    faRupeeSign,
    faRupee,
    faFileImage,
    faCircleQuestion,
    faQuestionCircle,
    faPlaneDeparture,
    faHandshakeSlash,
    faBookBookmark,
    faCodeBranch,
    faHatCowboy,
    faBridge,
    faPhoneFlip,
    faPhoneAlt,
    faTruckFront,
    faCat,
    faAnchorCircleExclamation,
    faTruckField,
    faRoute,
    faClipboardQuestion,
    faPanorama,
    faCommentMedical,
    faTeethOpen,
    faFileCircleMinus,
    faTags,
    faWineGlass,
    faForwardFast,
    faFastForward,
    faFaceMehBlank,
    faMehBlank,
    faSquareParking,
    faParking,
    faHouseSignal,
    faBarsProgress,
    faTasksAlt,
    faFaucetDrip,
    faCartFlatbed,
    faDollyFlatbed,
    faBanSmoking,
    faSmokingBan,
    faTerminal,
    faMobileButton,
    faHouseMedicalFlag,
    faBasketShopping,
    faShoppingBasket,
    faTape,
    faBusSimple,
    faBusAlt,
    faEye,
    faFaceSadCry,
    faSadCry,
    faAudioDescription,
    faPersonMilitaryToPerson,
    faFileShield,
    faUserSlash,
    faPen,
    faTowerObservation,
    faFileCode,
    faSignal,
    faSignal5,
    faSignalPerfect,
    faBus,
    faHeartCircleXmark,
    faHouseChimney,
    faHomeLg,
    faWindowMaximize,
    faFaceFrown,
    faFrown,
    faPrescription,
    faShop,
    faStoreAlt,
    faFloppyDisk,
    faSave,
    faVihara,
    faScaleUnbalanced,
    faBalanceScaleLeft,
    faSortUp,
    faSortAsc,
    faCommentDots,
    faCommenting,
    faPlantWilt,
    faDiamond,
    faFaceGrinSquint,
    faGrinSquint,
    faHandHoldingDollar,
    faHandHoldingUsd,
    faBacterium,
    faHandPointer,
    faDrumSteelpan,
    faHandScissors,
    faHandsPraying,
    faPrayingHands,
    faArrowRotateRight,
    faArrowRightRotate,
    faArrowRotateForward,
    faRedo,
    faBiohazard,
    faLocationCrosshairs,
    faLocation,
    faMarsDouble,
    faChildDress,
    faUsersBetweenLines,
    faLungsVirus,
    faFaceGrinTears,
    faGrinTears,
    faPhone,
    faCalendarXmark,
    faCalendarTimes,
    faChildReaching,
    faHeadSideVirus,
    faUserGear,
    faUserCog,
    faArrowUp19,
    faSortNumericUp,
    faDoorClosed,
    faShieldVirus,
    faDiceSix,
    faMosquitoNet,
    faBridgeWater,
    faPersonBooth,
    faTextWidth,
    faHatWizard,
    faPenFancy,
    faPersonDigging,
    faDigging,
    faTrash,
    faGaugeSimple,
    faGaugeSimpleMed,
    faTachometerAverage,
    faBookMedical,
    faPoo,
    faQuoteRight,
    faQuoteRightAlt,
    faShirt,
    faTShirt,
    faTshirt,
    faCubes,
    faDivide,
    faTengeSign,
    faTenge,
    faHeadphones,
    faHandsHolding,
    faHandsClapping,
    faRepublican,
    faArrowLeft,
    faPersonCircleXmark,
    faRuler,
    faAlignLeft,
    faDiceD6,
    faRestroom,
    faJ,
    faUsersViewfinder,
    faFileVideo,
    faUpRightFromSquare,
    faExternalLinkAlt,
    faTableCells,
    faTh,
    faFilePdf,
    faBookBible,
    faBible,
    faO,
    faSuitcaseMedical,
    faMedkit,
    faUserSecret,
    faOtter,
    faPersonDress,
    faFemale,
    faCommentDollar,
    faBusinessTime,
    faBriefcaseClock,
    faTableCellsLarge,
    faThLarge,
    faBookTanakh,
    faTanakh,
    faPhoneVolume,
    faVolumeControlPhone,
    faHatCowboySide,
    faClipboardUser,
    faChild,
    faLiraSign,
    faSatellite,
    faPlaneLock,
    faTag,
    faComment,
    faCakeCandles,
    faBirthdayCake,
    faCake,
    faEnvelope,
    faAnglesUp,
    faAngleDoubleUp,
    faPaperclip,
    faArrowRightToCity,
    faRibbon,
    faLungs,
    faArrowUp91,
    faSortNumericUpAlt,
    faLitecoinSign,
    faBorderNone,
    faCircleNodes,
    faParachuteBox,
    faIndent,
    faTruckFieldUn,
    faHourglass,
    faHourglassEmpty,
    faMountain,
    faUserDoctor,
    faUserMd,
    faCircleInfo,
    faInfoCircle,
    faCloudMeatball,
    faCamera,
    faCameraAlt,
    faSquareVirus,
    faMeteor,
    faCarOn,
    faSleigh,
    faArrowDown19,
    faSortNumericAsc,
    faSortNumericDown,
    faHandHoldingDroplet,
    faHandHoldingWater,
    faWater,
    faCalendarCheck,
    faBraille,
    faPrescriptionBottleMedical,
    faPrescriptionBottleAlt,
    faLandmark,
    faTruck,
    faCrosshairs,
    faPersonCane,
    faTent,
    faVestPatches,
    faCheckDouble,
    faArrowDownAZ,
    faSortAlphaAsc,
    faSortAlphaDown,
    faMoneyBillWheat,
    faCookie,
    faArrowRotateLeft,
    faArrowLeftRotate,
    faArrowRotateBack,
    faArrowRotateBackward,
    faUndo,
    faHardDrive,
    faHdd,
    faFaceGrinSquintTears,
    faGrinSquintTears,
    faDumbbell,
    faRectangleList,
    faListAlt,
    faTarpDroplet,
    faHouseMedicalCircleCheck,
    faPersonSkiingNordic,
    faSkiingNordic,
    faCalendarPlus,
    faPlaneArrival,
    faCircleLeft,
    faArrowAltCircleLeft,
    faTrainSubway,
    faSubway,
    faChartGantt,
    faIndianRupeeSign,
    faIndianRupee,
    faInr,
    faCropSimple,
    faCropAlt,
    faMoneyBill1,
    faMoneyBillAlt,
    faLeftLong,
    faLongArrowAltLeft,
    faDna,
    faVirusSlash,
    faMinus,
    faSubtract,
    faChess,
    faArrowLeftLong,
    faLongArrowLeft,
    faPlugCircleCheck,
    faStreetView,
    faFrancSign,
    faVolumeOff,
    faHandsAslInterpreting,
    faAmericanSignLanguageInterpreting,
    faAslInterpreting,
    faHandsAmericanSignLanguageInterpreting,
    faGear,
    faCog,
    faDropletSlash,
    faTintSlash,
    faMosque,
    faMosquito,
    faStarOfDavid,
    faPersonMilitaryRifle,
    faCartShopping,
    faShoppingCart,
    faVials,
    faPlugCirclePlus,
    faPlaceOfWorship,
    faGripVertical,
    faArrowTurnUp,
    faLevelUp,
    faU,
    faSquareRootVariable,
    faSquareRootAlt,
    faClock,
    faClockFour,
    faBackwardStep,
    faStepBackward,
    faPallet,
    faFaucet,
    faBaseballBatBall,
    faS,
    faTimeline,
    faKeyboard,
    faCaretDown,
    faHouseChimneyMedical,
    faClinicMedical,
    faTemperatureThreeQuarters,
    faTemperature3,
    faThermometer3,
    faThermometerThreeQuarters,
    faMobileScreen,
    faMobileAndroidAlt,
    faPlaneUp,
    faPiggyBank,
    faBatteryHalf,
    faBattery3,
    faMountainCity,
    faCoins,
    faKhanda,
    faSliders,
    faSlidersH,
    faFolderTree,
    faNetworkWired,
    faMapPin,
    faHamsa,
    faCentSign,
    faFlask,
    faPersonPregnant,
    faWandSparkles,
    faEllipsisVertical,
    faEllipsisV,
    faTicket,
    faPowerOff,
    faRightLong,
    faLongArrowAltRight,
    faFlagUsa,
    faLaptopFile,
    faTty,
    faTeletype,
    faDiagramNext,
    faPersonRifle,
    faHouseMedicalCircleExclamation,
    faClosedCaptioning,
    faPersonHiking,
    faHiking,
    faVenusDouble,
    faImages,
    faCalculator,
    faPeoplePulling,
    faN,
    faCableCar,
    faTram,
    faCloudRain,
    faBuildingCircleXmark,
    faShip,
    faArrowsDownToLine,
    faDownload,
    faFaceGrin,
    faGrin,
    faDeleteLeft,
    faBackspace,
    faEyeDropper,
    faEyeDropperEmpty,
    faEyedropper,
    faFileCircleCheck,
    faForward,
    faMobile,
    faMobileAndroid,
    faMobilePhone,
    faFaceMeh,
    faMeh,
    faAlignCenter,
    faBookSkull,
    faBookDead,
    faIdCard,
    faDriversLicense,
    faOutdent,
    faDedent,
    faHeartCircleExclamation,
    faHouse,
    faHome,
    faHomeAlt,
    faHomeLgAlt,
    faCalendarWeek,
    faLaptopMedical,
    faB,
    faFileMedical,
    faDiceOne,
    faKiwiBird,
    faArrowRightArrowLeft,
    faExchange,
    faRotateRight,
    faRedoAlt,
    faRotateForward,
    faUtensils,
    faCutlery,
    faArrowUpWideShort,
    faSortAmountUp,
    faMillSign,
    faBowlRice,
    faSkull,
    faTowerBroadcast,
    faBroadcastTower,
    faTruckPickup,
    faUpLong,
    faLongArrowAltUp,
    faStop,
    faCodeMerge,
    faUpload,
    faHurricane,
    faMound,
    faToiletPortable,
    faCompactDisc,
    faFileArrowDown,
    faFileDownload,
    faCaravan,
    faShieldCat,
    faBolt,
    faZap,
    faGlassWater,
    faOilWell,
    faVault,
    faMars,
    faToilet,
    faPlaneCircleXmark,
    faYenSign,
    faCny,
    faJpy,
    faRmb,
    faYen,
    faRubleSign,
    faRouble,
    faRub,
    faRuble,
    faSun,
    faGuitar,
    faFaceLaughWink,
    faLaughWink,
    faHorseHead,
    faBoreHole,
    faIndustry,
    faCircleDown,
    faArrowAltCircleDown,
    faArrowsTurnToDots,
    faFlorinSign,
    faArrowDownShortWide,
    faSortAmountDesc,
    faSortAmountDownAlt,
    faLessThan,
    faAngleDown,
    faCarTunnel,
    faHeadSideCough,
    faGripLines,
    faThumbsDown,
    faUserLock,
    faArrowRightLong,
    faLongArrowRight,
    faAnchorCircleXmark,
    faEllipsis,
    faEllipsisH,
    faChessPawn,
    faKitMedical,
    faFirstAid,
    faPersonThroughWindow,
    faToolbox,
    faHandsHoldingCircle,
    faBug,
    faCreditCard,
    faCreditCardAlt,
    faCar,
    faAutomobile,
    faHandHoldingHand,
    faBookOpenReader,
    faBookReader,
    faMountainSun,
    faArrowsLeftRightToLine,
    faDiceD20,
    faTruckDroplet,
    faFileCircleXmark,
    faTemperatureArrowUp,
    faTemperatureUp,
    faMedal,
    faBed,
    faSquareH,
    faHSquare,
    faPodcast,
    faTemperatureFull,
    faTemperature4,
    faThermometer4,
    faThermometerFull,
    faBell,
    faSuperscript,
    faPlugCircleXmark,
    faStarOfLife,
    faPhoneSlash,
    faPaintRoller,
    faHandshakeAngle,
    faHandsHelping,
    faLocationDot,
    faMapMarkerAlt,
    faFile,
    faGreaterThan,
    faPersonSwimming,
    faSwimmer,
    faArrowDown,
    faDroplet,
    faTint,
    faEraser,
    faEarthAmericas,
    faEarth,
    faEarthAmerica,
    faGlobeAmericas,
    faPersonBurst,
    faDove,
    faBatteryEmpty,
    faBattery0,
    faSocks,
    faInbox,
    faSection,
    faGaugeHigh,
    faTachometerAlt,
    faTachometerAltFast,
    faEnvelopeOpenText,
    faHospital,
    faHospitalAlt,
    faHospitalWide,
    faWineBottle,
    faChessRook,
    faBarsStaggered,
    faReorder,
    faStream,
    faDharmachakra,
    faHotdog,
    faPersonWalkingWithCane,
    faBlind,
    faDrum,
    faIceCream,
    faHeartCircleBolt,
    faFax,
    faParagraph,
    faCheckToSlot,
    faVoteYea,
    faStarHalf,
    faBoxesStacked,
    faBoxes,
    faBoxesAlt,
    faLink,
    faChain,
    faEarListen,
    faAssistiveListeningSystems,
    faTreeCity,
    faPlay,
    faFont,
    faRupiahSign,
    faMagnifyingGlass,
    faSearch,
    faTableTennisPaddleBall,
    faPingPongPaddleBall,
    faTableTennis,
    faPersonDotsFromLine,
    faDiagnoses,
    faTrashCanArrowUp,
    faTrashRestoreAlt,
    faNairaSign,
    faCartArrowDown,
    faWalkieTalkie,
    faFilePen,
    faFileEdit,
    faReceipt,
    faSquarePen,
    faPenSquare,
    faPencilSquare,
    faSuitcaseRolling,
    faPersonCircleExclamation,
    faChevronDown,
    faBatteryFull,
    faBattery,
    faBattery5,
    faSkullCrossbones,
    faCodeCompare,
    faListUl,
    faListDots,
    faSchoolLock,
    faTowerCell,
    faDownLong,
    faLongArrowAltDown,
    faRankingStar,
    faChessKing,
    faPersonHarassing,
    faBrazilianRealSign,
    faLandmarkDome,
    faLandmarkAlt,
    faArrowUp,
    faTv,
    faTelevision,
    faTvAlt,
    faShrimp,
    faListCheck,
    faTasks,
    faJugDetergent,
    faCircleUser,
    faUserCircle,
    faUserShield,
    faWind,
    faCarBurst,
    faCarCrash,
    faY,
    faPersonSnowboarding,
    faSnowboarding,
    faTruckFast,
    faShippingFast,
    faFish,
    faUserGraduate,
    faCircleHalfStroke,
    faAdjust,
    faClapperboard,
    faCircleRadiation,
    faRadiationAlt,
    faBaseball,
    faBaseballBall,
    faJetFighterUp,
    faDiagramProject,
    faProjectDiagram,
    faCopy,
    faVolumeXmark,
    faVolumeMute,
    faVolumeTimes,
    faHandSparkles,
    faGrip,
    faGripHorizontal,
    faShareFromSquare,
    faShareSquare,
    faChildCombatant,
    faChildRifle,
    faGun,
    faSquarePhone,
    faPhoneSquare,
    faPlus,
    faAdd,
    faExpand,
    faComputer,
    faXmark,
    faClose,
    faMultiply,
    faRemove,
    faTimes,
    faArrowsUpDownLeftRight,
    faArrows,
    faChalkboardUser,
    faChalkboardTeacher,
    faPesoSign,
    faBuildingShield,
    faBaby,
    faUsersLine,
    faQuoteLeft,
    faQuoteLeftAlt,
    faTractor,
    faTrashArrowUp,
    faTrashRestore,
    faArrowDownUpLock,
    faLinesLeaning,
    faRulerCombined,
    faCopyright,
    faEquals,
    faBlender,
    faTeeth,
    faShekelSign,
    faIls,
    faShekel,
    faSheqel,
    faSheqelSign,
    faMap,
    faRocket,
    faPhotoFilm,
    faPhotoVideo,
    faFolderMinus,
    faStore,
    faArrowTrendUp,
    faPlugCircleMinus,
    faSignHanging,
    faSign,
    faBezierCurve,
    faBellSlash,
    faTablet,
    faTabletAndroid,
    faSchoolFlag,
    faFill,
    faAngleUp,
    faDrumstickBite,
    faHollyBerry,
    faChevronLeft,
    faBacteria,
    faHandLizard,
    faNotdef,
    faDisease,
    faBriefcaseMedical,
    faGenderless,
    faChevronRight,
    faRetweet,
    faCarRear,
    faCarAlt,
    faPumpSoap,
    faVideoSlash,
    faBatteryQuarter,
    faBattery2,
    faRadio,
    faBabyCarriage,
    faCarriageBaby,
    faTrafficLight,
    faThermometer,
    faVrCardboard,
    faHandMiddleFinger,
    faPercent,
    faPercentage,
    faTruckMoving,
    faGlassWaterDroplet,
    faDisplay,
    faFaceSmile,
    faSmile,
    faThumbtack,
    faThumbTack,
    faTrophy,
    faPersonPraying,
    faPray,
    faHammer,
    faHandPeace,
    faRotate,
    faSyncAlt,
    faSpinner,
    faRobot,
    faPeace,
    faGears,
    faCogs,
    faWarehouse,
    faArrowUpRightDots,
    faSplotch,
    faFaceGrinHearts,
    faGrinHearts,
    faDiceFour,
    faSimCard,
    faTransgender,
    faTransgenderAlt,
    faMercury,
    faArrowTurnDown,
    faLevelDown,
    faPersonFallingBurst,
    faAward,
    faTicketSimple,
    faTicketAlt,
    faBuilding,
    faAnglesLeft,
    faAngleDoubleLeft,
    faQrcode,
    faClockRotateLeft,
    faHistory,
    faFaceGrinBeamSweat,
    faGrinBeamSweat,
    faFileExport,
    faArrowRightFromFile,
    faShield,
    faShieldBlank,
    faArrowUpShortWide,
    faSortAmountUpAlt,
    faHouseMedical,
    faGolfBallTee,
    faGolfBall,
    faCircleChevronLeft,
    faChevronCircleLeft,
    faHouseChimneyWindow,
    faPenNib,
    faTentArrowTurnLeft,
    faTents,
    faWandMagic,
    faMagic,
    faDog,
    faCarrot,
    faMoon,
    faWineGlassEmpty,
    faWineGlassAlt,
    faCheese,
    faYinYang,
    faMusic,
    faCodeCommit,
    faTemperatureLow,
    faPersonBiking,
    faBiking,
    faBroom,
    faShieldHeart,
    faGopuram,
    faEarthOceania,
    faGlobeOceania,
    faSquareXmark,
    faTimesSquare,
    faXmarkSquare,
    faHashtag,
    faUpRightAndDownLeftFromCenter,
    faExpandAlt,
    faOilCan,
    faT,
    faHippo,
    faChartColumn,
    faInfinity,
    faVialCircleCheck,
    faPersonArrowDownToLine,
    faVoicemail,
    faFan,
    faPersonWalkingLuggage,
    faUpDown,
    faArrowsAltV,
    faCloudMoonRain,
    faCalendar,
    faTrailer,
    faBahai,
    faHaykal,
    faSdCard,
    faDragon,
    faShoePrints,
    faCirclePlus,
    faPlusCircle,
    faFaceGrinTongueWink,
    faGrinTongueWink,
    faHandHolding,
    faPlugCircleExclamation,
    faLinkSlash,
    faChainBroken,
    faChainSlash,
    faUnlink,
    faClone,
    faPersonWalkingArrowLoopLeft,
    faArrowUpZA,
    faSortAlphaUpAlt,
    faFireFlameCurved,
    faFireAlt,
    faTornado,
    faFileCirclePlus,
    faBookQuran,
    faQuran,
    faAnchor,
    faBorderAll,
    faFaceAngry,
    faAngry,
    faCookieBite,
    faArrowTrendDown,
    faRss,
    faFeed,
    faDrawPolygon,
    faScaleBalanced,
    faBalanceScale,
    faGaugeSimpleHigh,
    faTachometer,
    faTachometerFast,
    faShower,
    faDesktop,
    faDesktopAlt,
    faM,
    faTableList,
    faThList,
    faCommentSms,
    faSms,
    faBook,
    faUserPlus,
    faCheck,
    faBatteryThreeQuarters,
    faBattery4,
    faHouseCircleCheck,
    faAngleLeft,
    faDiagramSuccessor,
    faTruckArrowRight,
    faArrowsSplitUpAndLeft,
    faHandFist,
    faFistRaised,
    faCloudMoon,
    faBriefcase,
    faPersonFalling,
    faImagePortrait,
    faPortrait,
    faUserTag,
    faRug,
    faEarthEurope,
    faGlobeEurope,
    faCartFlatbedSuitcase,
    faLuggageCart,
    faRectangleXmark,
    faRectangleTimes,
    faTimesRectangle,
    faWindowClose,
    faBahtSign,
    faBookOpen,
    faBookJournalWhills,
    faJournalWhills,
    faHandcuffs,
    faTriangleExclamation,
    faExclamationTriangle,
    faWarning,
    faDatabase,
    faShare,
    faMailForward,
    faBottleDroplet,
    faMaskFace,
    faHillRockslide,
    faRightLeft,
    faExchangeAlt,
    faPaperPlane,
    faRoadCircleExclamation,
    faDungeon,
    faAlignRight,
    faMoneyBill1Wave,
    faMoneyBillWaveAlt,
    faLifeRing,
    faHands,
    faSignLanguage,
    faSigning,
    faCalendarDay,
    faWaterLadder,
    faLadderWater,
    faSwimmingPool,
    faArrowsUpDown,
    faArrowsV,
    faFaceGrimace,
    faGrimace,
    faWheelchairMove,
    faWheelchairAlt,
    faTurnDown,
    faLevelDownAlt,
    faPersonWalkingArrowRight,
    faSquareEnvelope,
    faEnvelopeSquare,
    faDice,
    faBowlingBall,
    faBrain,
    faBandage,
    faBandAid,
    faCalendarMinus,
    faCircleXmark,
    faTimesCircle,
    faXmarkCircle,
    faGifts,
    faHotel,
    faEarthAsia,
    faGlobeAsia,
    faIdCardClip,
    faIdCardAlt,
    faMagnifyingGlassPlus,
    faSearchPlus,
    faThumbsUp,
    faUserClock,
    faHandDots,
    faAllergies,
    faFileInvoice,
    faWindowMinimize,
    faMugSaucer,
    faCoffee,
    faBrush,
    faMask,
    faMagnifyingGlassMinus,
    faSearchMinus,
    faRulerVertical,
    faUserLarge,
    faUserAlt,
    faTrainTram,
    faUserNurse,
    faSyringe,
    faCloudSun,
    faStopwatch20,
    faSquareFull,
    faMagnet,
    faJar,
    faNoteSticky,
    faStickyNote,
    faBugSlash,
    faArrowUpFromWaterPump,
    faBone,
    faUserInjured,
    faFaceSadTear,
    faSadTear,
    faPlane,
    faTentArrowsDown,
    faExclamation,
    faArrowsSpin,
    faPrint,
    faTurkishLiraSign,
    faTry,
    faTurkishLira,
    faDollarSign,
    faDollar,
    faUsd,
    faX,
    faMagnifyingGlassDollar,
    faSearchDollar,
    faUsersGear,
    faUsersCog,
    faPersonMilitaryPointing,
    faBuildingColumns,
    faBank,
    faInstitution,
    faMuseum,
    faUniversity,
    faUmbrella,
    faTrowel,
    faD,
    faStapler,
    faMasksTheater,
    faTheaterMasks,
    faKipSign,
    faHandPointLeft,
    faHandshakeSimple,
    faHandshakeAlt,
    faJetFighter,
    faFighterJet,
    faSquareShareNodes,
    faShareAltSquare,
    faBarcode,
    faPlusMinus,
    faVideo,
    faVideoCamera,
    faGraduationCap,
    faMortarBoard,
    faHandHoldingMedical,
    faPersonCircleCheck,
    faTurnUp,
    faLevelUpAlt
} from '@fortawesome/free-solid-svg-icons';

export const iconMap: Record<string, IconDefinition> = {
    'fas fa0': fa0,
    'fas fa1': fa1,
    'fas fa2': fa2,
    'fas fa3': fa3,
    'fas fa4': fa4,
    'fas fa5': fa5,
    'fas fa6': fa6,
    'fas fa7': fa7,
    'fas fa8': fa8,
    'fas fa9': fa9,
    'fas fa-fill-drip': faFillDrip,
    'fas fa-arrows-to-circle': faArrowsToCircle,
    'fas fa-circle-chevron-right': faCircleChevronRight,
    'fas fa-chevron-circle-right': faChevronCircleRight,
    'fas fa-at': faAt,
    'fas fa-trash-can': faTrashCan,
    'fas fa-trash-alt': faTrashAlt,
    'fas fa-text-height': faTextHeight,
    'fas fa-user-xmark': faUserXmark,
    'fas fa-user-times': faUserTimes,
    'fas fa-stethoscope': faStethoscope,
    'fas fa-message': faMessage,
    'fas fa-comment-alt': faCommentAlt,
    'fas fa-info': faInfo,
    'fas fa-down-left-and-up-right-to-center': faDownLeftAndUpRightToCenter,
    'fas fa-compress-alt': faCompressAlt,
    'fas fa-explosion': faExplosion,
    'fas fa-file-lines': faFileLines,
    'fas fa-file-alt': faFileAlt,
    'fas fa-file-text': faFileText,
    'fas fa-wave-square': faWaveSquare,
    'fas fa-ring': faRing,
    'fas fa-building-un': faBuildingUn,
    'fas fa-dice-three': faDiceThree,
    'fas fa-calendar-days': faCalendarDays,
    'fas fa-calendar-alt': faCalendarAlt,
    'fas fa-anchor-circle-check': faAnchorCircleCheck,
    'fas fa-building-circle-arrow-right': faBuildingCircleArrowRight,
    'fas fa-volleyball': faVolleyball,
    'fas fa-volleyball-ball': faVolleyballBall,
    'fas fa-arrows-up-to-line': faArrowsUpToLine,
    'fas fa-sort-down': faSortDown,
    'fas fa-sort-desc': faSortDesc,
    'fas fa-circle-minus': faCircleMinus,
    'fas fa-minus-circle': faMinusCircle,
    'fas fa-door-open': faDoorOpen,
    'fas fa-right-from-bracket': faRightFromBracket,
    'fas fa-sign-out-alt': faSignOutAlt,
    'fas fa-atom': faAtom,
    'fas fa-soap': faSoap,
    'fas fa-icons': faIcons,
    'fas fa-heart-music-camera-bolt': faHeartMusicCameraBolt,
    'fas fa-microphone-lines-slash': faMicrophoneLinesSlash,
    'fas fa-microphone-alt-slash': faMicrophoneAltSlash,
    'fas fa-bridge-circle-check': faBridgeCircleCheck,
    'fas fa-pump-medical': faPumpMedical,
    'fas fa-fingerprint': faFingerprint,
    'fas fa-hand-point-right': faHandPointRight,
    'fas fa-magnifying-glass-location': faMagnifyingGlassLocation,
    'fas fa-search-location': faSearchLocation,
    'fas fa-forward-step': faForwardStep,
    'fas fa-step-forward': faStepForward,
    'fas fa-face-smile-beam': faFaceSmileBeam,
    'fas fa-smile-beam': faSmileBeam,
    'fas fa-flag-checkered': faFlagCheckered,
    'fas fa-football': faFootball,
    'fas fa-football-ball': faFootballBall,
    'fas fa-school-circle-exclamation': faSchoolCircleExclamation,
    'fas fa-crop': faCrop,
    'fas fa-angles-down': faAnglesDown,
    'fas fa-angle-double-down': faAngleDoubleDown,
    'fas fa-users-rectangle': faUsersRectangle,
    'fas fa-people-roof': faPeopleRoof,
    'fas fa-people-line': faPeopleLine,
    'fas fa-beer-mug-empty': faBeerMugEmpty,
    'fas fa-beer': faBeer,
    'fas fa-diagram-predecessor': faDiagramPredecessor,
    'fas fa-arrow-up-long': faArrowUpLong,
    'fas fa-long-arrow-up': faLongArrowUp,
    'fas fa-fire-flame-simple': faFireFlameSimple,
    'fas fa-burn': faBurn,
    'fas fa-person': faPerson,
    'fas fa-male': faMale,
    'fas fa-laptop': faLaptop,
    'fas fa-file-csv': faFileCsv,
    'fas fa-menorah': faMenorah,
    'fas fa-truck-plane': faTruckPlane,
    'fas fa-record-vinyl': faRecordVinyl,
    'fas fa-face-grin-stars': faFaceGrinStars,
    'fas fa-grin-stars': faGrinStars,
    'fas fa-bong': faBong,
    'fas fa-spaghetti-monster-flying': faSpaghettiMonsterFlying,
    'fas fa-pastafarianism': faPastafarianism,
    'fas fa-arrow-down-up-across-line': faArrowDownUpAcrossLine,
    'fas fa-spoon': faSpoon,
    'fas fa-utensil-spoon': faUtensilSpoon,
    'fas fa-jar-wheat': faJarWheat,
    'fas fa-envelopes-bulk': faEnvelopesBulk,
    'fas fa-mail-bulk': faMailBulk,
    'fas fa-file-circle-exclamation': faFileCircleExclamation,
    'fas fa-circle-h': faCircleH,
    'fas fa-hospital-symbol': faHospitalSymbol,
    'fas fa-pager': faPager,
    'fas fa-address-book': faAddressBook,
    'fas fa-contact-book': faContactBook,
    'fas fa-strikethrough': faStrikethrough,
    'fas fa-k': faK,
    'fas fa-landmark-flag': faLandmarkFlag,
    'fas fa-pencil': faPencil,
    'fas fa-pencil-alt': faPencilAlt,
    'fas fa-backward': faBackward,
    'fas fa-caret-right': faCaretRight,
    'fas fa-comments': faComments,
    'fas fa-paste': faPaste,
    'fas fa-file-clipboard': faFileClipboard,
    'fas fa-code-pull-request': faCodePullRequest,
    'fas fa-clipboard-list': faClipboardList,
    'fas fa-truck-ramp-box': faTruckRampBox,
    'fas fa-truck-loading': faTruckLoading,
    'fas fa-user-check': faUserCheck,
    'fas fa-vial-virus': faVialVirus,
    'fas fa-sheet-plastic': faSheetPlastic,
    'fas fa-blog': faBlog,
    'fas fa-user-ninja': faUserNinja,
    'fas fa-person-arrow-up-from-line': faPersonArrowUpFromLine,
    'fas fa-scroll-torah': faScrollTorah,
    'fas fa-torah': faTorah,
    'fas fa-broom-ball': faBroomBall,
    'fas fa-quidditch': faQuidditch,
    'fas fa-quidditch-broom-ball': faQuidditchBroomBall,
    'fas fa-toggle-off': faToggleOff,
    'fas fa-box-archive': faBoxArchive,
    'fas fa-archive': faArchive,
    'fas fa-person-drowning': faPersonDrowning,
    'fas fa-arrow-down91': faArrowDown91,
    'fas fa-sort-numeric-desc': faSortNumericDesc,
    'fas fa-sort-numeric-down-alt': faSortNumericDownAlt,
    'fas fa-face-grin-tongue-squint': faFaceGrinTongueSquint,
    'fas fa-grin-tongue-squint': faGrinTongueSquint,
    'fas fa-spray-can': faSprayCan,
    'fas fa-truck-monster': faTruckMonster,
    'fas fa-w': faW,
    'fas fa-earth-africa': faEarthAfrica,
    'fas fa-globe-africa': faGlobeAfrica,
    'fas fa-rainbow': faRainbow,
    'fas fa-circle-notch': faCircleNotch,
    'fas fa-tablet-screen-button': faTabletScreenButton,
    'fas fa-tablet-alt': faTabletAlt,
    'fas fa-paw': faPaw,
    'fas fa-cloud': faCloud,
    'fas fa-trowel-bricks': faTrowelBricks,
    'fas fa-face-flushed': faFaceFlushed,
    'fas fa-flushed': faFlushed,
    'fas fa-hospital-user': faHospitalUser,
    'fas fa-tent-arrow-left-right': faTentArrowLeftRight,
    'fas fa-gavel': faGavel,
    'fas fa-legal': faLegal,
    'fas fa-binoculars': faBinoculars,
    'fas fa-microphone-slash': faMicrophoneSlash,
    'fas fa-box-tissue': faBoxTissue,
    'fas fa-motorcycle': faMotorcycle,
    'fas fa-bell-concierge': faBellConcierge,
    'fas fa-concierge-bell': faConciergeBell,
    'fas fa-pen-ruler': faPenRuler,
    'fas fa-pencil-ruler': faPencilRuler,
    'fas fa-people-arrows': faPeopleArrows,
    'fas fa-people-arrows-left-right': faPeopleArrowsLeftRight,
    'fas fa-mars-and-venus-burst': faMarsAndVenusBurst,
    'fas fa-square-caret-right': faSquareCaretRight,
    'fas fa-caret-square-right': faCaretSquareRight,
    'fas fa-scissors': faScissors,
    'fas fa-cut': faCut,
    'fas fa-sun-plant-wilt': faSunPlantWilt,
    'fas fa-toilets-portable': faToiletsPortable,
    'fas fa-hockey-puck': faHockeyPuck,
    'fas fa-table': faTable,
    'fas fa-magnifying-glass-arrow-right': faMagnifyingGlassArrowRight,
    'fas fa-tachograph-digital': faTachographDigital,
    'fas fa-digital-tachograph': faDigitalTachograph,
    'fas fa-users-slash': faUsersSlash,
    'fas fa-clover': faClover,
    'fas fa-reply': faReply,
    'fas fa-mail-reply': faMailReply,
    'fas fa-star-and-crescent': faStarAndCrescent,
    'fas fa-house-fire': faHouseFire,
    'fas fa-square-minus': faSquareMinus,
    'fas fa-minus-square': faMinusSquare,
    'fas fa-helicopter': faHelicopter,
    'fas fa-compass': faCompass,
    'fas fa-square-caret-down': faSquareCaretDown,
    'fas fa-caret-square-down': faCaretSquareDown,
    'fas fa-file-circle-question': faFileCircleQuestion,
    'fas fa-laptop-code': faLaptopCode,
    'fas fa-swatchbook': faSwatchbook,
    'fas fa-prescription-bottle': faPrescriptionBottle,
    'fas fa-bars': faBars,
    'fas fa-navicon': faNavicon,
    'fas fa-people-group': faPeopleGroup,
    'fas fa-hourglass-end': faHourglassEnd,
    'fas fa-hourglass3': faHourglass3,
    'fas fa-heart-crack': faHeartCrack,
    'fas fa-heart-broken': faHeartBroken,
    'fas fa-square-up-right': faSquareUpRight,
    'fas fa-external-link-square-alt': faExternalLinkSquareAlt,
    'fas fa-face-kiss-beam': faFaceKissBeam,
    'fas fa-kiss-beam': faKissBeam,
    'fas fa-film': faFilm,
    'fas fa-ruler-horizontal': faRulerHorizontal,
    'fas fa-people-robbery': faPeopleRobbery,
    'fas fa-lightbulb': faLightbulb,
    'fas fa-caret-left': faCaretLeft,
    'fas fa-circle-exclamation': faCircleExclamation,
    'fas fa-exclamation-circle': faExclamationCircle,
    'fas fa-school-circle-xmark': faSchoolCircleXmark,
    'fas fa-arrow-right-from-bracket': faArrowRightFromBracket,
    'fas fa-sign-out': faSignOut,
    'fas fa-circle-chevron-down': faCircleChevronDown,
    'fas fa-chevron-circle-down': faChevronCircleDown,
    'fas fa-unlock-keyhole': faUnlockKeyhole,
    'fas fa-unlock-alt': faUnlockAlt,
    'fas fa-cloud-showers-heavy': faCloudShowersHeavy,
    'fas fa-headphones-simple': faHeadphonesSimple,
    'fas fa-headphones-alt': faHeadphonesAlt,
    'fas fa-sitemap': faSitemap,
    'fas fa-circle-dollar-to-slot': faCircleDollarToSlot,
    'fas fa-donate': faDonate,
    'fas fa-memory': faMemory,
    'fas fa-road-spikes': faRoadSpikes,
    'fas fa-fire-burner': faFireBurner,
    'fas fa-flag': faFlag,
    'fas fa-hanukiah': faHanukiah,
    'fas fa-feather': faFeather,
    'fas fa-volume-low': faVolumeLow,
    'fas fa-volume-down': faVolumeDown,
    'fas fa-comment-slash': faCommentSlash,
    'fas fa-cloud-sun-rain': faCloudSunRain,
    'fas fa-compress': faCompress,
    'fas fa-wheat-awn': faWheatAwn,
    'fas fa-wheat-alt': faWheatAlt,
    'fas fa-ankh': faAnkh,
    'fas fa-hands-holding-child': faHandsHoldingChild,
    'fas fa-asterisk': faAsterisk,
    'fas fa-square-check': faSquareCheck,
    'fas fa-check-square': faCheckSquare,
    'fas fa-peseta-sign': faPesetaSign,
    'fas fa-heading': faHeading,
    'fas fa-header': faHeader,
    'fas fa-ghost': faGhost,
    'fas fa-list': faList,
    'fas fa-list-squares': faListSquares,
    'fas fa-square-phone-flip': faSquarePhoneFlip,
    'fas fa-phone-square-alt': faPhoneSquareAlt,
    'fas fa-cart-plus': faCartPlus,
    'fas fa-gamepad': faGamepad,
    'fas fa-circle-dot': faCircleDot,
    'fas fa-dot-circle': faDotCircle,
    'fas fa-face-dizzy': faFaceDizzy,
    'fas fa-dizzy': faDizzy,
    'fas fa-egg': faEgg,
    'fas fa-house-medical-circle-xmark': faHouseMedicalCircleXmark,
    'fas fa-campground': faCampground,
    'fas fa-folder-plus': faFolderPlus,
    'fas fa-futbol': faFutbol,
    'fas fa-futbol-ball': faFutbolBall,
    'fas fa-soccer-ball': faSoccerBall,
    'fas fa-paintbrush': faPaintbrush,
    'fas fa-paint-brush': faPaintBrush,
    'fas fa-lock': faLock,
    'fas fa-gas-pump': faGasPump,
    'fas fa-hot-tub-person': faHotTubPerson,
    'fas fa-hot-tub': faHotTub,
    'fas fa-map-location': faMapLocation,
    'fas fa-map-marked': faMapMarked,
    'fas fa-house-flood-water': faHouseFloodWater,
    'fas fa-tree': faTree,
    'fas fa-bridge-lock': faBridgeLock,
    'fas fa-sack-dollar': faSackDollar,
    'fas fa-pen-to-square': faPenToSquare,
    'fas fa-edit': faEdit,
    'fas fa-car-side': faCarSide,
    'fas fa-share-nodes': faShareNodes,
    'fas fa-share-alt': faShareAlt,
    'fas fa-heart-circle-minus': faHeartCircleMinus,
    'fas fa-hourglass-half': faHourglassHalf,
    'fas fa-hourglass2': faHourglass2,
    'fas fa-microscope': faMicroscope,
    'fas fa-sink': faSink,
    'fas fa-bag-shopping': faBagShopping,
    'fas fa-shopping-bag': faShoppingBag,
    'fas fa-arrow-down-z-a': faArrowDownZA,
    'fas fa-sort-alpha-desc': faSortAlphaDesc,
    'fas fa-sort-alpha-down-alt': faSortAlphaDownAlt,
    'fas fa-mitten': faMitten,
    'fas fa-person-rays': faPersonRays,
    'fas fa-users': faUsers,
    'fas fa-eye-slash': faEyeSlash,
    'fas fa-flask-vial': faFlaskVial,
    'fas fa-hand': faHand,
    'fas fa-hand-paper': faHandPaper,
    'fas fa-om': faOm,
    'fas fa-worm': faWorm,
    'fas fa-house-circle-xmark': faHouseCircleXmark,
    'fas fa-plug': faPlug,
    'fas fa-chevron-up': faChevronUp,
    'fas fa-hand-spock': faHandSpock,
    'fas fa-stopwatch': faStopwatch,
    'fas fa-face-kiss': faFaceKiss,
    'fas fa-kiss': faKiss,
    'fas fa-bridge-circle-xmark': faBridgeCircleXmark,
    'fas fa-face-grin-tongue': faFaceGrinTongue,
    'fas fa-grin-tongue': faGrinTongue,
    'fas fa-chess-bishop': faChessBishop,
    'fas fa-face-grin-wink': faFaceGrinWink,
    'fas fa-grin-wink': faGrinWink,
    'fas fa-ear-deaf': faEarDeaf,
    'fas fa-deaf': faDeaf,
    'fas fa-deafness': faDeafness,
    'fas fa-hard-of-hearing': faHardOfHearing,
    'fas fa-road-circle-check': faRoadCircleCheck,
    'fas fa-dice-five': faDiceFive,
    'fas fa-square-rss': faSquareRss,
    'fas fa-rss-square': faRssSquare,
    'fas fa-land-mine-on': faLandMineOn,
    'fas fa-i-cursor': faICursor,
    'fas fa-stamp': faStamp,
    'fas fa-stairs': faStairs,
    'fas fa-i': faI,
    'fas fa-hryvnia-sign': faHryvniaSign,
    'fas fa-hryvnia': faHryvnia,
    'fas fa-pills': faPills,
    'fas fa-face-grin-wide': faFaceGrinWide,
    'fas fa-grin-alt': faGrinAlt,
    'fas fa-tooth': faTooth,
    'fas fa-v': faV,
    'fas fa-bangladeshi-taka-sign': faBangladeshiTakaSign,
    'fas fa-bicycle': faBicycle,
    'fas fa-staff-snake': faStaffSnake,
    'fas fa-rod-asclepius': faRodAsclepius,
    'fas fa-rod-snake': faRodSnake,
    'fas fa-staff-aesculapius': faStaffAesculapius,
    'fas fa-head-side-cough-slash': faHeadSideCoughSlash,
    'fas fa-truck-medical': faTruckMedical,
    'fas fa-ambulance': faAmbulance,
    'fas fa-wheat-awn-circle-exclamation': faWheatAwnCircleExclamation,
    'fas fa-snowman': faSnowman,
    'fas fa-mortar-pestle': faMortarPestle,
    'fas fa-road-barrier': faRoadBarrier,
    'fas fa-school': faSchool,
    'fas fa-igloo': faIgloo,
    'fas fa-joint': faJoint,
    'fas fa-angle-right': faAngleRight,
    'fas fa-horse': faHorse,
    'fas fa-q': faQ,
    'fas fa-g': faG,
    'fas fa-notes-medical': faNotesMedical,
    'fas fa-temperature-half': faTemperatureHalf,
    'fas fa-temperature2': faTemperature2,
    'fas fa-thermometer2': faThermometer2,
    'fas fa-thermometer-half': faThermometerHalf,
    'fas fa-dong-sign': faDongSign,
    'fas fa-capsules': faCapsules,
    'fas fa-poo-storm': faPooStorm,
    'fas fa-poo-bolt': faPooBolt,
    'fas fa-face-frown-open': faFaceFrownOpen,
    'fas fa-frown-open': faFrownOpen,
    'fas fa-hand-point-up': faHandPointUp,
    'fas fa-money-bill': faMoneyBill,
    'fas fa-bookmark': faBookmark,
    'fas fa-align-justify': faAlignJustify,
    'fas fa-umbrella-beach': faUmbrellaBeach,
    'fas fa-helmet-un': faHelmetUn,
    'fas fa-bullseye': faBullseye,
    'fas fa-bacon': faBacon,
    'fas fa-hand-point-down': faHandPointDown,
    'fas fa-arrow-up-from-bracket': faArrowUpFromBracket,
    'fas fa-folder': faFolder,
    'fas fa-folder-blank': faFolderBlank,
    'fas fa-file-waveform': faFileWaveform,
    'fas fa-file-medical-alt': faFileMedicalAlt,
    'fas fa-radiation': faRadiation,
    'fas fa-chart-simple': faChartSimple,
    'fas fa-mars-stroke': faMarsStroke,
    'fas fa-vial': faVial,
    'fas fa-gauge': faGauge,
    'fas fa-dashboard': faDashboard,
    'fas fa-gauge-med': faGaugeMed,
    'fas fa-tachometer-alt-average': faTachometerAltAverage,
    'fas fa-wand-magic-sparkles': faWandMagicSparkles,
    'fas fa-magic-wand-sparkles': faMagicWandSparkles,
    'fas fa-e': faE,
    'fas fa-pen-clip': faPenClip,
    'fas fa-pen-alt': faPenAlt,
    'fas fa-bridge-circle-exclamation': faBridgeCircleExclamation,
    'fas fa-user': faUser,
    'fas fa-school-circle-check': faSchoolCircleCheck,
    'fas fa-dumpster': faDumpster,
    'fas fa-van-shuttle': faVanShuttle,
    'fas fa-shuttle-van': faShuttleVan,
    'fas fa-building-user': faBuildingUser,
    'fas fa-square-caret-left': faSquareCaretLeft,
    'fas fa-caret-square-left': faCaretSquareLeft,
    'fas fa-highlighter': faHighlighter,
    'fas fa-key': faKey,
    'fas fa-bullhorn': faBullhorn,
    'fas fa-globe': faGlobe,
    'fas fa-synagogue': faSynagogue,
    'fas fa-person-half-dress': faPersonHalfDress,
    'fas fa-road-bridge': faRoadBridge,
    'fas fa-location-arrow': faLocationArrow,
    'fas fa-c': faC,
    'fas fa-tablet-button': faTabletButton,
    'fas fa-building-lock': faBuildingLock,
    'fas fa-pizza-slice': faPizzaSlice,
    'fas fa-money-bill-wave': faMoneyBillWave,
    'fas fa-chart-area': faChartArea,
    'fas fa-area-chart': faAreaChart,
    'fas fa-house-flag': faHouseFlag,
    'fas fa-person-circle-minus': faPersonCircleMinus,
    'fas fa-ban': faBan,
    'fas fa-cancel': faCancel,
    'fas fa-camera-rotate': faCameraRotate,
    'fas fa-spray-can-sparkles': faSprayCanSparkles,
    'fas fa-air-freshener': faAirFreshener,
    'fas fa-star': faStar,
    'fas fa-repeat': faRepeat,
    'fas fa-cross': faCross,
    'fas fa-box': faBox,
    'fas fa-venus-mars': faVenusMars,
    'fas fa-arrow-pointer': faArrowPointer,
    'fas fa-mouse-pointer': faMousePointer,
    'fas fa-maximize': faMaximize,
    'fas fa-expand-arrows-alt': faExpandArrowsAlt,
    'fas fa-charging-station': faChargingStation,
    'fas fa-shapes': faShapes,
    'fas fa-triangle-circle-square': faTriangleCircleSquare,
    'fas fa-shuffle': faShuffle,
    'fas fa-random': faRandom,
    'fas fa-person-running': faPersonRunning,
    'fas fa-running': faRunning,
    'fas fa-mobile-retro': faMobileRetro,
    'fas fa-grip-lines-vertical': faGripLinesVertical,
    'fas fa-spider': faSpider,
    'fas fa-hands-bound': faHandsBound,
    'fas fa-file-invoice-dollar': faFileInvoiceDollar,
    'fas fa-plane-circle-exclamation': faPlaneCircleExclamation,
    'fas fa-x-ray': faXRay,
    'fas fa-spell-check': faSpellCheck,
    'fas fa-slash': faSlash,
    'fas fa-computer-mouse': faComputerMouse,
    'fas fa-mouse': faMouse,
    'fas fa-arrow-right-to-bracket': faArrowRightToBracket,
    'fas fa-sign-in': faSignIn,
    'fas fa-shop-slash': faShopSlash,
    'fas fa-store-alt-slash': faStoreAltSlash,
    'fas fa-server': faServer,
    'fas fa-virus-covid-slash': faVirusCovidSlash,
    'fas fa-shop-lock': faShopLock,
    'fas fa-hourglass-start': faHourglassStart,
    'fas fa-hourglass1': faHourglass1,
    'fas fa-blender-phone': faBlenderPhone,
    'fas fa-building-wheat': faBuildingWheat,
    'fas fa-person-breastfeeding': faPersonBreastfeeding,
    'fas fa-right-to-bracket': faRightToBracket,
    'fas fa-sign-in-alt': faSignInAlt,
    'fas fa-venus': faVenus,
    'fas fa-passport': faPassport,
    'fas fa-heart-pulse': faHeartPulse,
    'fas fa-heartbeat': faHeartbeat,
    'fas fa-people-carry-box': faPeopleCarryBox,
    'fas fa-people-carry': faPeopleCarry,
    'fas fa-temperature-high': faTemperatureHigh,
    'fas fa-microchip': faMicrochip,
    'fas fa-crown': faCrown,
    'fas fa-weight-hanging': faWeightHanging,
    'fas fa-xmarks-lines': faXmarksLines,
    'fas fa-file-prescription': faFilePrescription,
    'fas fa-weight-scale': faWeightScale,
    'fas fa-weight': faWeight,
    'fas fa-user-group': faUserGroup,
    'fas fa-user-friends': faUserFriends,
    'fas fa-arrow-up-a-z': faArrowUpAZ,
    'fas fa-sort-alpha-up': faSortAlphaUp,
    'fas fa-chess-knight': faChessKnight,
    'fas fa-face-laugh-squint': faFaceLaughSquint,
    'fas fa-laugh-squint': faLaughSquint,
    'fas fa-wheelchair': faWheelchair,
    'fas fa-circle-arrow-up': faCircleArrowUp,
    'fas fa-arrow-circle-up': faArrowCircleUp,
    'fas fa-toggle-on': faToggleOn,
    'fas fa-person-walking': faPersonWalking,
    'fas fa-walking': faWalking,
    'fas fa-l': faL,
    'fas fa-fire': faFire,
    'fas fa-bed-pulse': faBedPulse,
    'fas fa-procedures': faProcedures,
    'fas fa-shuttle-space': faShuttleSpace,
    'fas fa-space-shuttle': faSpaceShuttle,
    'fas fa-face-laugh': faFaceLaugh,
    'fas fa-laugh': faLaugh,
    'fas fa-folder-open': faFolderOpen,
    'fas fa-heart-circle-plus': faHeartCirclePlus,
    'fas fa-code-fork': faCodeFork,
    'fas fa-city': faCity,
    'fas fa-microphone-lines': faMicrophoneLines,
    'fas fa-microphone-alt': faMicrophoneAlt,
    'fas fa-pepper-hot': faPepperHot,
    'fas fa-unlock': faUnlock,
    'fas fa-colon-sign': faColonSign,
    'fas fa-headset': faHeadset,
    'fas fa-store-slash': faStoreSlash,
    'fas fa-road-circle-xmark': faRoadCircleXmark,
    'fas fa-user-minus': faUserMinus,
    'fas fa-mars-stroke-up': faMarsStrokeUp,
    'fas fa-mars-stroke-v': faMarsStrokeV,
    'fas fa-champagne-glasses': faChampagneGlasses,
    'fas fa-glass-cheers': faGlassCheers,
    'fas fa-clipboard': faClipboard,
    'fas fa-house-circle-exclamation': faHouseCircleExclamation,
    'fas fa-file-arrow-up': faFileArrowUp,
    'fas fa-file-upload': faFileUpload,
    'fas fa-wifi': faWifi,
    'fas fa-wifi3': faWifi3,
    'fas fa-wifi-strong': faWifiStrong,
    'fas fa-bath': faBath,
    'fas fa-bathtub': faBathtub,
    'fas fa-underline': faUnderline,
    'fas fa-user-pen': faUserPen,
    'fas fa-user-edit': faUserEdit,
    'fas fa-signature': faSignature,
    'fas fa-stroopwafel': faStroopwafel,
    'fas fa-bold': faBold,
    'fas fa-anchor-lock': faAnchorLock,
    'fas fa-building-ngo': faBuildingNgo,
    'fas fa-manat-sign': faManatSign,
    'fas fa-not-equal': faNotEqual,
    'fas fa-border-top-left': faBorderTopLeft,
    'fas fa-border-style': faBorderStyle,
    'fas fa-map-location-dot': faMapLocationDot,
    'fas fa-map-marked-alt': faMapMarkedAlt,
    'fas fa-jedi': faJedi,
    'fas fa-square-poll-vertical': faSquarePollVertical,
    'fas fa-poll': faPoll,
    'fas fa-mug-hot': faMugHot,
    'fas fa-car-battery': faCarBattery,
    'fas fa-battery-car': faBatteryCar,
    'fas fa-gift': faGift,
    'fas fa-dice-two': faDiceTwo,
    'fas fa-chess-queen': faChessQueen,
    'fas fa-glasses': faGlasses,
    'fas fa-chess-board': faChessBoard,
    'fas fa-building-circle-check': faBuildingCircleCheck,
    'fas fa-person-chalkboard': faPersonChalkboard,
    'fas fa-mars-stroke-right': faMarsStrokeRight,
    'fas fa-mars-stroke-h': faMarsStrokeH,
    'fas fa-hand-back-fist': faHandBackFist,
    'fas fa-hand-rock': faHandRock,
    'fas fa-square-caret-up': faSquareCaretUp,
    'fas fa-caret-square-up': faCaretSquareUp,
    'fas fa-cloud-showers-water': faCloudShowersWater,
    'fas fa-chart-bar': faChartBar,
    'fas fa-bar-chart': faBarChart,
    'fas fa-hands-bubbles': faHandsBubbles,
    'fas fa-hands-wash': faHandsWash,
    'fas fa-less-than-equal': faLessThanEqual,
    'fas fa-train': faTrain,
    'fas fa-eye-low-vision': faEyeLowVision,
    'fas fa-low-vision': faLowVision,
    'fas fa-crow': faCrow,
    'fas fa-sailboat': faSailboat,
    'fas fa-window-restore': faWindowRestore,
    'fas fa-square-plus': faSquarePlus,
    'fas fa-plus-square': faPlusSquare,
    'fas fa-torii-gate': faToriiGate,
    'fas fa-frog': faFrog,
    'fas fa-bucket': faBucket,
    'fas fa-image': faImage,
    'fas fa-microphone': faMicrophone,
    'fas fa-cow': faCow,
    'fas fa-caret-up': faCaretUp,
    'fas fa-screwdriver': faScrewdriver,
    'fas fa-folder-closed': faFolderClosed,
    'fas fa-house-tsunami': faHouseTsunami,
    'fas fa-square-nfi': faSquareNfi,
    'fas fa-arrow-up-from-ground-water': faArrowUpFromGroundWater,
    'fas fa-martini-glass': faMartiniGlass,
    'fas fa-glass-martini-alt': faGlassMartiniAlt,
    'fas fa-rotate-left': faRotateLeft,
    'fas fa-rotate-back': faRotateBack,
    'fas fa-rotate-backward': faRotateBackward,
    'fas fa-undo-alt': faUndoAlt,
    'fas fa-table-columns': faTableColumns,
    'fas fa-columns': faColumns,
    'fas fa-lemon': faLemon,
    'fas fa-head-side-mask': faHeadSideMask,
    'fas fa-handshake': faHandshake,
    'fas fa-gem': faGem,
    'fas fa-dolly': faDolly,
    'fas fa-dolly-box': faDollyBox,
    'fas fa-smoking': faSmoking,
    'fas fa-minimize': faMinimize,
    'fas fa-compress-arrows-alt': faCompressArrowsAlt,
    'fas fa-monument': faMonument,
    'fas fa-snowplow': faSnowplow,
    'fas fa-angles-right': faAnglesRight,
    'fas fa-angle-double-right': faAngleDoubleRight,
    'fas fa-cannabis': faCannabis,
    'fas fa-circle-play': faCirclePlay,
    'fas fa-play-circle': faPlayCircle,
    'fas fa-tablets': faTablets,
    'fas fa-ethernet': faEthernet,
    'fas fa-euro-sign': faEuroSign,
    'fas fa-eur': faEur,
    'fas fa-euro': faEuro,
    'fas fa-chair': faChair,
    'fas fa-circle-check': faCircleCheck,
    'fas fa-check-circle': faCheckCircle,
    'fas fa-circle-stop': faCircleStop,
    'fas fa-stop-circle': faStopCircle,
    'fas fa-compass-drafting': faCompassDrafting,
    'fas fa-drafting-compass': faDraftingCompass,
    'fas fa-plate-wheat': faPlateWheat,
    'fas fa-icicles': faIcicles,
    'fas fa-person-shelter': faPersonShelter,
    'fas fa-neuter': faNeuter,
    'fas fa-id-badge': faIdBadge,
    'fas fa-marker': faMarker,
    'fas fa-face-laugh-beam': faFaceLaughBeam,
    'fas fa-laugh-beam': faLaughBeam,
    'fas fa-helicopter-symbol': faHelicopterSymbol,
    'fas fa-universal-access': faUniversalAccess,
    'fas fa-circle-chevron-up': faCircleChevronUp,
    'fas fa-chevron-circle-up': faChevronCircleUp,
    'fas fa-lari-sign': faLariSign,
    'fas fa-volcano': faVolcano,
    'fas fa-person-walking-dashed-line-arrow-right':
        faPersonWalkingDashedLineArrowRight,
    'fas fa-sterling-sign': faSterlingSign,
    'fas fa-gbp': faGbp,
    'fas fa-pound-sign': faPoundSign,
    'fas fa-viruses': faViruses,
    'fas fa-square-person-confined': faSquarePersonConfined,
    'fas fa-user-tie': faUserTie,
    'fas fa-arrow-down-long': faArrowDownLong,
    'fas fa-long-arrow-down': faLongArrowDown,
    'fas fa-tent-arrow-down-to-line': faTentArrowDownToLine,
    'fas fa-certificate': faCertificate,
    'fas fa-reply-all': faReplyAll,
    'fas fa-mail-reply-all': faMailReplyAll,
    'fas fa-suitcase': faSuitcase,
    'fas fa-person-skating': faPersonSkating,
    'fas fa-skating': faSkating,
    'fas fa-filter-circle-dollar': faFilterCircleDollar,
    'fas fa-funnel-dollar': faFunnelDollar,
    'fas fa-camera-retro': faCameraRetro,
    'fas fa-circle-arrow-down': faCircleArrowDown,
    'fas fa-arrow-circle-down': faArrowCircleDown,
    'fas fa-file-import': faFileImport,
    'fas fa-arrow-right-to-file': faArrowRightToFile,
    'fas fa-square-arrow-up-right': faSquareArrowUpRight,
    'fas fa-external-link-square': faExternalLinkSquare,
    'fas fa-box-open': faBoxOpen,
    'fas fa-scroll': faScroll,
    'fas fa-spa': faSpa,
    'fas fa-location-pin-lock': faLocationPinLock,
    'fas fa-pause': faPause,
    'fas fa-hill-avalanche': faHillAvalanche,
    'fas fa-temperature-empty': faTemperatureEmpty,
    'fas fa-temperature0': faTemperature0,
    'fas fa-thermometer0': faThermometer0,
    'fas fa-thermometer-empty': faThermometerEmpty,
    'fas fa-bomb': faBomb,
    'fas fa-registered': faRegistered,
    'fas fa-address-card': faAddressCard,
    'fas fa-contact-card': faContactCard,
    'fas fa-vcard': faVcard,
    'fas fa-scale-unbalanced-flip': faScaleUnbalancedFlip,
    'fas fa-balance-scale-right': faBalanceScaleRight,
    'fas fa-subscript': faSubscript,
    'fas fa-diamond-turn-right': faDiamondTurnRight,
    'fas fa-directions': faDirections,
    'fas fa-burst': faBurst,
    'fas fa-house-laptop': faHouseLaptop,
    'fas fa-laptop-house': faLaptopHouse,
    'fas fa-face-tired': faFaceTired,
    'fas fa-tired': faTired,
    'fas fa-money-bills': faMoneyBills,
    'fas fa-smog': faSmog,
    'fas fa-crutch': faCrutch,
    'fas fa-font-awesome': faFontAwesome,
    'fas fa-font-awesome-flag': faFontAwesomeFlag,
    'fas fa-font-awesome-logo-full': faFontAwesomeLogoFull,
    'fas fa-cloud-arrow-up': faCloudArrowUp,
    'fas fa-cloud-upload': faCloudUpload,
    'fas fa-cloud-upload-alt': faCloudUploadAlt,
    'fas fa-palette': faPalette,
    'fas fa-arrows-turn-right': faArrowsTurnRight,
    'fas fa-vest': faVest,
    'fas fa-ferry': faFerry,
    'fas fa-arrows-down-to-people': faArrowsDownToPeople,
    'fas fa-seedling': faSeedling,
    'fas fa-sprout': faSprout,
    'fas fa-left-right': faLeftRight,
    'fas fa-arrows-alt-h': faArrowsAltH,
    'fas fa-boxes-packing': faBoxesPacking,
    'fas fa-circle-arrow-left': faCircleArrowLeft,
    'fas fa-arrow-circle-left': faArrowCircleLeft,
    'fas fa-group-arrows-rotate': faGroupArrowsRotate,
    'fas fa-bowl-food': faBowlFood,
    'fas fa-candy-cane': faCandyCane,
    'fas fa-arrow-down-wide-short': faArrowDownWideShort,
    'fas fa-sort-amount-asc': faSortAmountAsc,
    'fas fa-sort-amount-down': faSortAmountDown,
    'fas fa-cloud-bolt': faCloudBolt,
    'fas fa-thunderstorm': faThunderstorm,
    'fas fa-text-slash': faTextSlash,
    'fas fa-remove-format': faRemoveFormat,
    'fas fa-face-smile-wink': faFaceSmileWink,
    'fas fa-smile-wink': faSmileWink,
    'fas fa-file-word': faFileWord,
    'fas fa-file-powerpoint': faFilePowerpoint,
    'fas fa-arrows-left-right': faArrowsLeftRight,
    'fas fa-arrows-h': faArrowsH,
    'fas fa-house-lock': faHouseLock,
    'fas fa-cloud-arrow-down': faCloudArrowDown,
    'fas fa-cloud-download': faCloudDownload,
    'fas fa-cloud-download-alt': faCloudDownloadAlt,
    'fas fa-children': faChildren,
    'fas fa-chalkboard': faChalkboard,
    'fas fa-blackboard': faBlackboard,
    'fas fa-user-large-slash': faUserLargeSlash,
    'fas fa-user-alt-slash': faUserAltSlash,
    'fas fa-envelope-open': faEnvelopeOpen,
    'fas fa-handshake-simple-slash': faHandshakeSimpleSlash,
    'fas fa-handshake-alt-slash': faHandshakeAltSlash,
    'fas fa-mattress-pillow': faMattressPillow,
    'fas fa-guarani-sign': faGuaraniSign,
    'fas fa-arrows-rotate': faArrowsRotate,
    'fas fa-refresh': faRefresh,
    'fas fa-sync': faSync,
    'fas fa-fire-extinguisher': faFireExtinguisher,
    'fas fa-cruzeiro-sign': faCruzeiroSign,
    'fas fa-greater-than-equal': faGreaterThanEqual,
    'fas fa-shield-halved': faShieldHalved,
    'fas fa-shield-alt': faShieldAlt,
    'fas fa-book-atlas': faBookAtlas,
    'fas fa-atlas': faAtlas,
    'fas fa-virus': faVirus,
    'fas fa-envelope-circle-check': faEnvelopeCircleCheck,
    'fas fa-layer-group': faLayerGroup,
    'fas fa-arrows-to-dot': faArrowsToDot,
    'fas fa-archway': faArchway,
    'fas fa-heart-circle-check': faHeartCircleCheck,
    'fas fa-house-chimney-crack': faHouseChimneyCrack,
    'fas fa-house-damage': faHouseDamage,
    'fas fa-file-zipper': faFileZipper,
    'fas fa-file-archive': faFileArchive,
    'fas fa-square': faSquare,
    'fas fa-martini-glass-empty': faMartiniGlassEmpty,
    'fas fa-glass-martini': faGlassMartini,
    'fas fa-couch': faCouch,
    'fas fa-cedi-sign': faCediSign,
    'fas fa-italic': faItalic,
    'fas fa-church': faChurch,
    'fas fa-comments-dollar': faCommentsDollar,
    'fas fa-democrat': faDemocrat,
    'fas fa-z': faZ,
    'fas fa-person-skiing': faPersonSkiing,
    'fas fa-skiing': faSkiing,
    'fas fa-road-lock': faRoadLock,
    'fas fa-a': faA,
    'fas fa-temperature-arrow-down': faTemperatureArrowDown,
    'fas fa-temperature-down': faTemperatureDown,
    'fas fa-feather-pointed': faFeatherPointed,
    'fas fa-feather-alt': faFeatherAlt,
    'fas fa-p': faP,
    'fas fa-snowflake': faSnowflake,
    'fas fa-newspaper': faNewspaper,
    'fas fa-rectangle-ad': faRectangleAd,
    'fas fa-ad': faAd,
    'fas fa-circle-arrow-right': faCircleArrowRight,
    'fas fa-arrow-circle-right': faArrowCircleRight,
    'fas fa-filter-circle-xmark': faFilterCircleXmark,
    'fas fa-locust': faLocust,
    'fas fa-sort': faSort,
    'fas fa-unsorted': faUnsorted,
    'fas fa-list-ol': faListOl,
    'fas fa-list12': faList12,
    'fas fa-list-numeric': faListNumeric,
    'fas fa-person-dress-burst': faPersonDressBurst,
    'fas fa-money-check-dollar': faMoneyCheckDollar,
    'fas fa-money-check-alt': faMoneyCheckAlt,
    'fas fa-vector-square': faVectorSquare,
    'fas fa-bread-slice': faBreadSlice,
    'fas fa-language': faLanguage,
    'fas fa-face-kiss-wink-heart': faFaceKissWinkHeart,
    'fas fa-kiss-wink-heart': faKissWinkHeart,
    'fas fa-filter': faFilter,
    'fas fa-question': faQuestion,
    'fas fa-file-signature': faFileSignature,
    'fas fa-up-down-left-right': faUpDownLeftRight,
    'fas fa-arrows-alt': faArrowsAlt,
    'fas fa-house-chimney-user': faHouseChimneyUser,
    'fas fa-hand-holding-heart': faHandHoldingHeart,
    'fas fa-puzzle-piece': faPuzzlePiece,
    'fas fa-money-check': faMoneyCheck,
    'fas fa-star-half-stroke': faStarHalfStroke,
    'fas fa-star-half-alt': faStarHalfAlt,
    'fas fa-code': faCode,
    'fas fa-whiskey-glass': faWhiskeyGlass,
    'fas fa-glass-whiskey': faGlassWhiskey,
    'fas fa-building-circle-exclamation': faBuildingCircleExclamation,
    'fas fa-magnifying-glass-chart': faMagnifyingGlassChart,
    'fas fa-arrow-up-right-from-square': faArrowUpRightFromSquare,
    'fas fa-external-link': faExternalLink,
    'fas fa-cubes-stacked': faCubesStacked,
    'fas fa-won-sign': faWonSign,
    'fas fa-krw': faKrw,
    'fas fa-won': faWon,
    'fas fa-virus-covid': faVirusCovid,
    'fas fa-austral-sign': faAustralSign,
    'fas fa-f': faF,
    'fas fa-leaf': faLeaf,
    'fas fa-road': faRoad,
    'fas fa-taxi': faTaxi,
    'fas fa-cab': faCab,
    'fas fa-person-circle-plus': faPersonCirclePlus,
    'fas fa-chart-pie': faChartPie,
    'fas fa-pie-chart': faPieChart,
    'fas fa-bolt-lightning': faBoltLightning,
    'fas fa-sack-xmark': faSackXmark,
    'fas fa-file-excel': faFileExcel,
    'fas fa-file-contract': faFileContract,
    'fas fa-fish-fins': faFishFins,
    'fas fa-building-flag': faBuildingFlag,
    'fas fa-face-grin-beam': faFaceGrinBeam,
    'fas fa-grin-beam': faGrinBeam,
    'fas fa-object-ungroup': faObjectUngroup,
    'fas fa-poop': faPoop,
    'fas fa-location-pin': faLocationPin,
    'fas fa-map-marker': faMapMarker,
    'fas fa-kaaba': faKaaba,
    'fas fa-toilet-paper': faToiletPaper,
    'fas fa-helmet-safety': faHelmetSafety,
    'fas fa-hard-hat': faHardHat,
    'fas fa-hat-hard': faHatHard,
    'fas fa-eject': faEject,
    'fas fa-circle-right': faCircleRight,
    'fas fa-arrow-alt-circle-right': faArrowAltCircleRight,
    'fas fa-plane-circle-check': faPlaneCircleCheck,
    'fas fa-face-rolling-eyes': faFaceRollingEyes,
    'fas fa-meh-rolling-eyes': faMehRollingEyes,
    'fas fa-object-group': faObjectGroup,
    'fas fa-chart-line': faChartLine,
    'fas fa-line-chart': faLineChart,
    'fas fa-mask-ventilator': faMaskVentilator,
    'fas fa-arrow-right': faArrowRight,
    'fas fa-signs-post': faSignsPost,
    'fas fa-map-signs': faMapSigns,
    'fas fa-cash-register': faCashRegister,
    'fas fa-person-circle-question': faPersonCircleQuestion,
    'fas fa-h': faH,
    'fas fa-tarp': faTarp,
    'fas fa-screwdriver-wrench': faScrewdriverWrench,
    'fas fa-tools': faTools,
    'fas fa-arrows-to-eye': faArrowsToEye,
    'fas fa-plug-circle-bolt': faPlugCircleBolt,
    'fas fa-heart': faHeart,
    'fas fa-mars-and-venus': faMarsAndVenus,
    'fas fa-house-user': faHouseUser,
    'fas fa-home-user': faHomeUser,
    'fas fa-dumpster-fire': faDumpsterFire,
    'fas fa-house-crack': faHouseCrack,
    'fas fa-martini-glass-citrus': faMartiniGlassCitrus,
    'fas fa-cocktail': faCocktail,
    'fas fa-face-surprise': faFaceSurprise,
    'fas fa-surprise': faSurprise,
    'fas fa-bottle-water': faBottleWater,
    'fas fa-circle-pause': faCirclePause,
    'fas fa-pause-circle': faPauseCircle,
    'fas fa-toilet-paper-slash': faToiletPaperSlash,
    'fas fa-apple-whole': faAppleWhole,
    'fas fa-apple-alt': faAppleAlt,
    'fas fa-kitchen-set': faKitchenSet,
    'fas fa-r': faR,
    'fas fa-temperature-quarter': faTemperatureQuarter,
    'fas fa-temperature1': faTemperature1,
    'fas fa-thermometer1': faThermometer1,
    'fas fa-thermometer-quarter': faThermometerQuarter,
    'fas fa-cube': faCube,
    'fas fa-bitcoin-sign': faBitcoinSign,
    'fas fa-shield-dog': faShieldDog,
    'fas fa-solar-panel': faSolarPanel,
    'fas fa-lock-open': faLockOpen,
    'fas fa-elevator': faElevator,
    'fas fa-money-bill-transfer': faMoneyBillTransfer,
    'fas fa-money-bill-trend-up': faMoneyBillTrendUp,
    'fas fa-house-flood-water-circle-arrow-right':
        faHouseFloodWaterCircleArrowRight,
    'fas fa-square-poll-horizontal': faSquarePollHorizontal,
    'fas fa-poll-h': faPollH,
    'fas fa-circle': faCircle,
    'fas fa-backward-fast': faBackwardFast,
    'fas fa-fast-backward': faFastBackward,
    'fas fa-recycle': faRecycle,
    'fas fa-user-astronaut': faUserAstronaut,
    'fas fa-plane-slash': faPlaneSlash,
    'fas fa-trademark': faTrademark,
    'fas fa-basketball': faBasketball,
    'fas fa-basketball-ball': faBasketballBall,
    'fas fa-satellite-dish': faSatelliteDish,
    'fas fa-circle-up': faCircleUp,
    'fas fa-arrow-alt-circle-up': faArrowAltCircleUp,
    'fas fa-mobile-screen-button': faMobileScreenButton,
    'fas fa-mobile-alt': faMobileAlt,
    'fas fa-volume-high': faVolumeHigh,
    'fas fa-volume-up': faVolumeUp,
    'fas fa-users-rays': faUsersRays,
    'fas fa-wallet': faWallet,
    'fas fa-clipboard-check': faClipboardCheck,
    'fas fa-file-audio': faFileAudio,
    'fas fa-burger': faBurger,
    'fas fa-hamburger': faHamburger,
    'fas fa-wrench': faWrench,
    'fas fa-bugs': faBugs,
    'fas fa-rupee-sign': faRupeeSign,
    'fas fa-rupee': faRupee,
    'fas fa-file-image': faFileImage,
    'fas fa-circle-question': faCircleQuestion,
    'fas fa-question-circle': faQuestionCircle,
    'fas fa-plane-departure': faPlaneDeparture,
    'fas fa-handshake-slash': faHandshakeSlash,
    'fas fa-book-bookmark': faBookBookmark,
    'fas fa-code-branch': faCodeBranch,
    'fas fa-hat-cowboy': faHatCowboy,
    'fas fa-bridge': faBridge,
    'fas fa-phone-flip': faPhoneFlip,
    'fas fa-phone-alt': faPhoneAlt,
    'fas fa-truck-front': faTruckFront,
    'fas fa-cat': faCat,
    'fas fa-anchor-circle-exclamation': faAnchorCircleExclamation,
    'fas fa-truck-field': faTruckField,
    'fas fa-route': faRoute,
    'fas fa-clipboard-question': faClipboardQuestion,
    'fas fa-panorama': faPanorama,
    'fas fa-comment-medical': faCommentMedical,
    'fas fa-teeth-open': faTeethOpen,
    'fas fa-file-circle-minus': faFileCircleMinus,
    'fas fa-tags': faTags,
    'fas fa-wine-glass': faWineGlass,
    'fas fa-forward-fast': faForwardFast,
    'fas fa-fast-forward': faFastForward,
    'fas fa-face-meh-blank': faFaceMehBlank,
    'fas fa-meh-blank': faMehBlank,
    'fas fa-square-parking': faSquareParking,
    'fas fa-parking': faParking,
    'fas fa-house-signal': faHouseSignal,
    'fas fa-bars-progress': faBarsProgress,
    'fas fa-tasks-alt': faTasksAlt,
    'fas fa-faucet-drip': faFaucetDrip,
    'fas fa-cart-flatbed': faCartFlatbed,
    'fas fa-dolly-flatbed': faDollyFlatbed,
    'fas fa-ban-smoking': faBanSmoking,
    'fas fa-smoking-ban': faSmokingBan,
    'fas fa-terminal': faTerminal,
    'fas fa-mobile-button': faMobileButton,
    'fas fa-house-medical-flag': faHouseMedicalFlag,
    'fas fa-basket-shopping': faBasketShopping,
    'fas fa-shopping-basket': faShoppingBasket,
    'fas fa-tape': faTape,
    'fas fa-bus-simple': faBusSimple,
    'fas fa-bus-alt': faBusAlt,
    'fas fa-eye': faEye,
    'fas fa-face-sad-cry': faFaceSadCry,
    'fas fa-sad-cry': faSadCry,
    'fas fa-audio-description': faAudioDescription,
    'fas fa-person-military-to-person': faPersonMilitaryToPerson,
    'fas fa-file-shield': faFileShield,
    'fas fa-user-slash': faUserSlash,
    'fas fa-pen': faPen,
    'fas fa-tower-observation': faTowerObservation,
    'fas fa-file-code': faFileCode,
    'fas fa-signal': faSignal,
    'fas fa-signal5': faSignal5,
    'fas fa-signal-perfect': faSignalPerfect,
    'fas fa-bus': faBus,
    'fas fa-heart-circle-xmark': faHeartCircleXmark,
    'fas fa-house-chimney': faHouseChimney,
    'fas fa-home-lg': faHomeLg,
    'fas fa-window-maximize': faWindowMaximize,
    'fas fa-face-frown': faFaceFrown,
    'fas fa-frown': faFrown,
    'fas fa-prescription': faPrescription,
    'fas fa-shop': faShop,
    'fas fa-store-alt': faStoreAlt,
    'fas fa-floppy-disk': faFloppyDisk,
    'fas fa-save': faSave,
    'fas fa-vihara': faVihara,
    'fas fa-scale-unbalanced': faScaleUnbalanced,
    'fas fa-balance-scale-left': faBalanceScaleLeft,
    'fas fa-sort-up': faSortUp,
    'fas fa-sort-asc': faSortAsc,
    'fas fa-comment-dots': faCommentDots,
    'fas fa-commenting': faCommenting,
    'fas fa-plant-wilt': faPlantWilt,
    'fas fa-diamond': faDiamond,
    'fas fa-face-grin-squint': faFaceGrinSquint,
    'fas fa-grin-squint': faGrinSquint,
    'fas fa-hand-holding-dollar': faHandHoldingDollar,
    'fas fa-hand-holding-usd': faHandHoldingUsd,
    'fas fa-bacterium': faBacterium,
    'fas fa-hand-pointer': faHandPointer,
    'fas fa-drum-steelpan': faDrumSteelpan,
    'fas fa-hand-scissors': faHandScissors,
    'fas fa-hands-praying': faHandsPraying,
    'fas fa-praying-hands': faPrayingHands,
    'fas fa-arrow-rotate-right': faArrowRotateRight,
    'fas fa-arrow-right-rotate': faArrowRightRotate,
    'fas fa-arrow-rotate-forward': faArrowRotateForward,
    'fas fa-redo': faRedo,
    'fas fa-biohazard': faBiohazard,
    'fas fa-location-crosshairs': faLocationCrosshairs,
    'fas fa-location': faLocation,
    'fas fa-mars-double': faMarsDouble,
    'fas fa-child-dress': faChildDress,
    'fas fa-users-between-lines': faUsersBetweenLines,
    'fas fa-lungs-virus': faLungsVirus,
    'fas fa-face-grin-tears': faFaceGrinTears,
    'fas fa-grin-tears': faGrinTears,
    'fas fa-phone': faPhone,
    'fas fa-calendar-xmark': faCalendarXmark,
    'fas fa-calendar-times': faCalendarTimes,
    'fas fa-child-reaching': faChildReaching,
    'fas fa-head-side-virus': faHeadSideVirus,
    'fas fa-user-gear': faUserGear,
    'fas fa-user-cog': faUserCog,
    'fas fa-arrow-up19': faArrowUp19,
    'fas fa-sort-numeric-up': faSortNumericUp,
    'fas fa-door-closed': faDoorClosed,
    'fas fa-shield-virus': faShieldVirus,
    'fas fa-dice-six': faDiceSix,
    'fas fa-mosquito-net': faMosquitoNet,
    'fas fa-bridge-water': faBridgeWater,
    'fas fa-person-booth': faPersonBooth,
    'fas fa-text-width': faTextWidth,
    'fas fa-hat-wizard': faHatWizard,
    'fas fa-pen-fancy': faPenFancy,
    'fas fa-person-digging': faPersonDigging,
    'fas fa-digging': faDigging,
    'fas fa-trash': faTrash,
    'fas fa-gauge-simple': faGaugeSimple,
    'fas fa-gauge-simple-med': faGaugeSimpleMed,
    'fas fa-tachometer-average': faTachometerAverage,
    'fas fa-book-medical': faBookMedical,
    'fas fa-poo': faPoo,
    'fas fa-quote-right': faQuoteRight,
    'fas fa-quote-right-alt': faQuoteRightAlt,
    'fas fa-shirt': faShirt,
    'fas fa-t-shirt': faTShirt,
    'fas fa-tshirt': faTshirt,
    'fas fa-cubes': faCubes,
    'fas fa-divide': faDivide,
    'fas fa-tenge-sign': faTengeSign,
    'fas fa-tenge': faTenge,
    'fas fa-headphones': faHeadphones,
    'fas fa-hands-holding': faHandsHolding,
    'fas fa-hands-clapping': faHandsClapping,
    'fas fa-republican': faRepublican,
    'fas fa-arrow-left': faArrowLeft,
    'fas fa-person-circle-xmark': faPersonCircleXmark,
    'fas fa-ruler': faRuler,
    'fas fa-align-left': faAlignLeft,
    'fas fa-dice-d6': faDiceD6,
    'fas fa-restroom': faRestroom,
    'fas fa-j': faJ,
    'fas fa-users-viewfinder': faUsersViewfinder,
    'fas fa-file-video': faFileVideo,
    'fas fa-up-right-from-square': faUpRightFromSquare,
    'fas fa-external-link-alt': faExternalLinkAlt,
    'fas fa-table-cells': faTableCells,
    'fas fa-th': faTh,
    'fas fa-file-pdf': faFilePdf,
    'fas fa-book-bible': faBookBible,
    'fas fa-bible': faBible,
    'fas fa-o': faO,
    'fas fa-suitcase-medical': faSuitcaseMedical,
    'fas fa-medkit': faMedkit,
    'fas fa-user-secret': faUserSecret,
    'fas fa-otter': faOtter,
    'fas fa-person-dress': faPersonDress,
    'fas fa-female': faFemale,
    'fas fa-comment-dollar': faCommentDollar,
    'fas fa-business-time': faBusinessTime,
    'fas fa-briefcase-clock': faBriefcaseClock,
    'fas fa-table-cells-large': faTableCellsLarge,
    'fas fa-th-large': faThLarge,
    'fas fa-book-tanakh': faBookTanakh,
    'fas fa-tanakh': faTanakh,
    'fas fa-phone-volume': faPhoneVolume,
    'fas fa-volume-control-phone': faVolumeControlPhone,
    'fas fa-hat-cowboy-side': faHatCowboySide,
    'fas fa-clipboard-user': faClipboardUser,
    'fas fa-child': faChild,
    'fas fa-lira-sign': faLiraSign,
    'fas fa-satellite': faSatellite,
    'fas fa-plane-lock': faPlaneLock,
    'fas fa-tag': faTag,
    'fas fa-comment': faComment,
    'fas fa-cake-candles': faCakeCandles,
    'fas fa-birthday-cake': faBirthdayCake,
    'fas fa-cake': faCake,
    'fas fa-envelope': faEnvelope,
    'fas fa-angles-up': faAnglesUp,
    'fas fa-angle-double-up': faAngleDoubleUp,
    'fas fa-paperclip': faPaperclip,
    'fas fa-arrow-right-to-city': faArrowRightToCity,
    'fas fa-ribbon': faRibbon,
    'fas fa-lungs': faLungs,
    'fas fa-arrow-up91': faArrowUp91,
    'fas fa-sort-numeric-up-alt': faSortNumericUpAlt,
    'fas fa-litecoin-sign': faLitecoinSign,
    'fas fa-border-none': faBorderNone,
    'fas fa-circle-nodes': faCircleNodes,
    'fas fa-parachute-box': faParachuteBox,
    'fas fa-indent': faIndent,
    'fas fa-truck-field-un': faTruckFieldUn,
    'fas fa-hourglass': faHourglass,
    'fas fa-hourglass-empty': faHourglassEmpty,
    'fas fa-mountain': faMountain,
    'fas fa-user-doctor': faUserDoctor,
    'fas fa-user-md': faUserMd,
    'fas fa-circle-info': faCircleInfo,
    'fas fa-info-circle': faInfoCircle,
    'fas fa-cloud-meatball': faCloudMeatball,
    'fas fa-camera': faCamera,
    'fas fa-camera-alt': faCameraAlt,
    'fas fa-square-virus': faSquareVirus,
    'fas fa-meteor': faMeteor,
    'fas fa-car-on': faCarOn,
    'fas fa-sleigh': faSleigh,
    'fas fa-arrow-down19': faArrowDown19,
    'fas fa-sort-numeric-asc': faSortNumericAsc,
    'fas fa-sort-numeric-down': faSortNumericDown,
    'fas fa-hand-holding-droplet': faHandHoldingDroplet,
    'fas fa-hand-holding-water': faHandHoldingWater,
    'fas fa-water': faWater,
    'fas fa-calendar-check': faCalendarCheck,
    'fas fa-braille': faBraille,
    'fas fa-prescription-bottle-medical': faPrescriptionBottleMedical,
    'fas fa-prescription-bottle-alt': faPrescriptionBottleAlt,
    'fas fa-landmark': faLandmark,
    'fas fa-truck': faTruck,
    'fas fa-crosshairs': faCrosshairs,
    'fas fa-person-cane': faPersonCane,
    'fas fa-tent': faTent,
    'fas fa-vest-patches': faVestPatches,
    'fas fa-check-double': faCheckDouble,
    'fas fa-arrow-down-a-z': faArrowDownAZ,
    'fas fa-sort-alpha-asc': faSortAlphaAsc,
    'fas fa-sort-alpha-down': faSortAlphaDown,
    'fas fa-money-bill-wheat': faMoneyBillWheat,
    'fas fa-cookie': faCookie,
    'fas fa-arrow-rotate-left': faArrowRotateLeft,
    'fas fa-arrow-left-rotate': faArrowLeftRotate,
    'fas fa-arrow-rotate-back': faArrowRotateBack,
    'fas fa-arrow-rotate-backward': faArrowRotateBackward,
    'fas fa-undo': faUndo,
    'fas fa-hard-drive': faHardDrive,
    'fas fa-hdd': faHdd,
    'fas fa-face-grin-squint-tears': faFaceGrinSquintTears,
    'fas fa-grin-squint-tears': faGrinSquintTears,
    'fas fa-dumbbell': faDumbbell,
    'fas fa-rectangle-list': faRectangleList,
    'fas fa-list-alt': faListAlt,
    'fas fa-tarp-droplet': faTarpDroplet,
    'fas fa-house-medical-circle-check': faHouseMedicalCircleCheck,
    'fas fa-person-skiing-nordic': faPersonSkiingNordic,
    'fas fa-skiing-nordic': faSkiingNordic,
    'fas fa-calendar-plus': faCalendarPlus,
    'fas fa-plane-arrival': faPlaneArrival,
    'fas fa-circle-left': faCircleLeft,
    'fas fa-arrow-alt-circle-left': faArrowAltCircleLeft,
    'fas fa-train-subway': faTrainSubway,
    'fas fa-subway': faSubway,
    'fas fa-chart-gantt': faChartGantt,
    'fas fa-indian-rupee-sign': faIndianRupeeSign,
    'fas fa-indian-rupee': faIndianRupee,
    'fas fa-inr': faInr,
    'fas fa-crop-simple': faCropSimple,
    'fas fa-crop-alt': faCropAlt,
    'fas fa-money-bill1': faMoneyBill1,
    'fas fa-money-bill-alt': faMoneyBillAlt,
    'fas fa-left-long': faLeftLong,
    'fas fa-long-arrow-alt-left': faLongArrowAltLeft,
    'fas fa-dna': faDna,
    'fas fa-virus-slash': faVirusSlash,
    'fas fa-minus': faMinus,
    'fas fa-subtract': faSubtract,
    'fas fa-chess': faChess,
    'fas fa-arrow-left-long': faArrowLeftLong,
    'fas fa-long-arrow-left': faLongArrowLeft,
    'fas fa-plug-circle-check': faPlugCircleCheck,
    'fas fa-street-view': faStreetView,
    'fas fa-franc-sign': faFrancSign,
    'fas fa-volume-off': faVolumeOff,
    'fas fa-hands-asl-interpreting': faHandsAslInterpreting,
    'fas fa-american-sign-language-interpreting':
        faAmericanSignLanguageInterpreting,
    'fas fa-asl-interpreting': faAslInterpreting,
    'fas fa-hands-american-sign-language-interpreting':
        faHandsAmericanSignLanguageInterpreting,
    'fas fa-gear': faGear,
    'fas fa-cog': faCog,
    'fas fa-droplet-slash': faDropletSlash,
    'fas fa-tint-slash': faTintSlash,
    'fas fa-mosque': faMosque,
    'fas fa-mosquito': faMosquito,
    'fas fa-star-of-david': faStarOfDavid,
    'fas fa-person-military-rifle': faPersonMilitaryRifle,
    'fas fa-cart-shopping': faCartShopping,
    'fas fa-shopping-cart': faShoppingCart,
    'fas fa-vials': faVials,
    'fas fa-plug-circle-plus': faPlugCirclePlus,
    'fas fa-place-of-worship': faPlaceOfWorship,
    'fas fa-grip-vertical': faGripVertical,
    'fas fa-arrow-turn-up': faArrowTurnUp,
    'fas fa-level-up': faLevelUp,
    'fas fa-u': faU,
    'fas fa-square-root-variable': faSquareRootVariable,
    'fas fa-square-root-alt': faSquareRootAlt,
    'fas fa-clock': faClock,
    'fas fa-clock-four': faClockFour,
    'fas fa-backward-step': faBackwardStep,
    'fas fa-step-backward': faStepBackward,
    'fas fa-pallet': faPallet,
    'fas fa-faucet': faFaucet,
    'fas fa-baseball-bat-ball': faBaseballBatBall,
    'fas fa-s': faS,
    'fas fa-timeline': faTimeline,
    'fas fa-keyboard': faKeyboard,
    'fas fa-caret-down': faCaretDown,
    'fas fa-house-chimney-medical': faHouseChimneyMedical,
    'fas fa-clinic-medical': faClinicMedical,
    'fas fa-temperature-three-quarters': faTemperatureThreeQuarters,
    'fas fa-temperature3': faTemperature3,
    'fas fa-thermometer3': faThermometer3,
    'fas fa-thermometer-three-quarters': faThermometerThreeQuarters,
    'fas fa-mobile-screen': faMobileScreen,
    'fas fa-mobile-android-alt': faMobileAndroidAlt,
    'fas fa-plane-up': faPlaneUp,
    'fas fa-piggy-bank': faPiggyBank,
    'fas fa-battery-half': faBatteryHalf,
    'fas fa-battery3': faBattery3,
    'fas fa-mountain-city': faMountainCity,
    'fas fa-coins': faCoins,
    'fas fa-khanda': faKhanda,
    'fas fa-sliders': faSliders,
    'fas fa-sliders-h': faSlidersH,
    'fas fa-folder-tree': faFolderTree,
    'fas fa-network-wired': faNetworkWired,
    'fas fa-map-pin': faMapPin,
    'fas fa-hamsa': faHamsa,
    'fas fa-cent-sign': faCentSign,
    'fas fa-flask': faFlask,
    'fas fa-person-pregnant': faPersonPregnant,
    'fas fa-wand-sparkles': faWandSparkles,
    'fas fa-ellipsis-vertical': faEllipsisVertical,
    'fas fa-ellipsis-v': faEllipsisV,
    'fas fa-ticket': faTicket,
    'fas fa-power-off': faPowerOff,
    'fas fa-right-long': faRightLong,
    'fas fa-long-arrow-alt-right': faLongArrowAltRight,
    'fas fa-flag-usa': faFlagUsa,
    'fas fa-laptop-file': faLaptopFile,
    'fas fa-tty': faTty,
    'fas fa-teletype': faTeletype,
    'fas fa-diagram-next': faDiagramNext,
    'fas fa-person-rifle': faPersonRifle,
    'fas fa-house-medical-circle-exclamation': faHouseMedicalCircleExclamation,
    'fas fa-closed-captioning': faClosedCaptioning,
    'fas fa-person-hiking': faPersonHiking,
    'fas fa-hiking': faHiking,
    'fas fa-venus-double': faVenusDouble,
    'fas fa-images': faImages,
    'fas fa-calculator': faCalculator,
    'fas fa-people-pulling': faPeoplePulling,
    'fas fa-n': faN,
    'fas fa-cable-car': faCableCar,
    'fas fa-tram': faTram,
    'fas fa-cloud-rain': faCloudRain,
    'fas fa-building-circle-xmark': faBuildingCircleXmark,
    'fas fa-ship': faShip,
    'fas fa-arrows-down-to-line': faArrowsDownToLine,
    'fas fa-download': faDownload,
    'fas fa-face-grin': faFaceGrin,
    'fas fa-grin': faGrin,
    'fas fa-delete-left': faDeleteLeft,
    'fas fa-backspace': faBackspace,
    'fas fa-eye-dropper': faEyeDropper,
    'fas fa-eye-dropper-empty': faEyeDropperEmpty,
    'fas fa-eyedropper': faEyedropper,
    'fas fa-file-circle-check': faFileCircleCheck,
    'fas fa-forward': faForward,
    'fas fa-mobile': faMobile,
    'fas fa-mobile-android': faMobileAndroid,
    'fas fa-mobile-phone': faMobilePhone,
    'fas fa-face-meh': faFaceMeh,
    'fas fa-meh': faMeh,
    'fas fa-align-center': faAlignCenter,
    'fas fa-book-skull': faBookSkull,
    'fas fa-book-dead': faBookDead,
    'fas fa-id-card': faIdCard,
    'fas fa-drivers-license': faDriversLicense,
    'fas fa-outdent': faOutdent,
    'fas fa-dedent': faDedent,
    'fas fa-heart-circle-exclamation': faHeartCircleExclamation,
    'fas fa-house': faHouse,
    'fas fa-home': faHome,
    'fas fa-home-alt': faHomeAlt,
    'fas fa-home-lg-alt': faHomeLgAlt,
    'fas fa-calendar-week': faCalendarWeek,
    'fas fa-laptop-medical': faLaptopMedical,
    'fas fa-b': faB,
    'fas fa-file-medical': faFileMedical,
    'fas fa-dice-one': faDiceOne,
    'fas fa-kiwi-bird': faKiwiBird,
    'fas fa-arrow-right-arrow-left': faArrowRightArrowLeft,
    'fas fa-exchange': faExchange,
    'fas fa-rotate-right': faRotateRight,
    'fas fa-redo-alt': faRedoAlt,
    'fas fa-rotate-forward': faRotateForward,
    'fas fa-utensils': faUtensils,
    'fas fa-cutlery': faCutlery,
    'fas fa-arrow-up-wide-short': faArrowUpWideShort,
    'fas fa-sort-amount-up': faSortAmountUp,
    'fas fa-mill-sign': faMillSign,
    'fas fa-bowl-rice': faBowlRice,
    'fas fa-skull': faSkull,
    'fas fa-tower-broadcast': faTowerBroadcast,
    'fas fa-broadcast-tower': faBroadcastTower,
    'fas fa-truck-pickup': faTruckPickup,
    'fas fa-up-long': faUpLong,
    'fas fa-long-arrow-alt-up': faLongArrowAltUp,
    'fas fa-stop': faStop,
    'fas fa-code-merge': faCodeMerge,
    'fas fa-upload': faUpload,
    'fas fa-hurricane': faHurricane,
    'fas fa-mound': faMound,
    'fas fa-toilet-portable': faToiletPortable,
    'fas fa-compact-disc': faCompactDisc,
    'fas fa-file-arrow-down': faFileArrowDown,
    'fas fa-file-download': faFileDownload,
    'fas fa-caravan': faCaravan,
    'fas fa-shield-cat': faShieldCat,
    'fas fa-bolt': faBolt,
    'fas fa-zap': faZap,
    'fas fa-glass-water': faGlassWater,
    'fas fa-oil-well': faOilWell,
    'fas fa-vault': faVault,
    'fas fa-mars': faMars,
    'fas fa-toilet': faToilet,
    'fas fa-plane-circle-xmark': faPlaneCircleXmark,
    'fas fa-yen-sign': faYenSign,
    'fas fa-cny': faCny,
    'fas fa-jpy': faJpy,
    'fas fa-rmb': faRmb,
    'fas fa-yen': faYen,
    'fas fa-ruble-sign': faRubleSign,
    'fas fa-rouble': faRouble,
    'fas fa-rub': faRub,
    'fas fa-ruble': faRuble,
    'fas fa-sun': faSun,
    'fas fa-guitar': faGuitar,
    'fas fa-face-laugh-wink': faFaceLaughWink,
    'fas fa-laugh-wink': faLaughWink,
    'fas fa-horse-head': faHorseHead,
    'fas fa-bore-hole': faBoreHole,
    'fas fa-industry': faIndustry,
    'fas fa-circle-down': faCircleDown,
    'fas fa-arrow-alt-circle-down': faArrowAltCircleDown,
    'fas fa-arrows-turn-to-dots': faArrowsTurnToDots,
    'fas fa-florin-sign': faFlorinSign,
    'fas fa-arrow-down-short-wide': faArrowDownShortWide,
    'fas fa-sort-amount-desc': faSortAmountDesc,
    'fas fa-sort-amount-down-alt': faSortAmountDownAlt,
    'fas fa-less-than': faLessThan,
    'fas fa-angle-down': faAngleDown,
    'fas fa-car-tunnel': faCarTunnel,
    'fas fa-head-side-cough': faHeadSideCough,
    'fas fa-grip-lines': faGripLines,
    'fas fa-thumbs-down': faThumbsDown,
    'fas fa-user-lock': faUserLock,
    'fas fa-arrow-right-long': faArrowRightLong,
    'fas fa-long-arrow-right': faLongArrowRight,
    'fas fa-anchor-circle-xmark': faAnchorCircleXmark,
    'fas fa-ellipsis': faEllipsis,
    'fas fa-ellipsis-h': faEllipsisH,
    'fas fa-chess-pawn': faChessPawn,
    'fas fa-kit-medical': faKitMedical,
    'fas fa-first-aid': faFirstAid,
    'fas fa-person-through-window': faPersonThroughWindow,
    'fas fa-toolbox': faToolbox,
    'fas fa-hands-holding-circle': faHandsHoldingCircle,
    'fas fa-bug': faBug,
    'fas fa-credit-card': faCreditCard,
    'fas fa-credit-card-alt': faCreditCardAlt,
    'fas fa-car': faCar,
    'fas fa-automobile': faAutomobile,
    'fas fa-hand-holding-hand': faHandHoldingHand,
    'fas fa-book-open-reader': faBookOpenReader,
    'fas fa-book-reader': faBookReader,
    'fas fa-mountain-sun': faMountainSun,
    'fas fa-arrows-left-right-to-line': faArrowsLeftRightToLine,
    'fas fa-dice-d20': faDiceD20,
    'fas fa-truck-droplet': faTruckDroplet,
    'fas fa-file-circle-xmark': faFileCircleXmark,
    'fas fa-temperature-arrow-up': faTemperatureArrowUp,
    'fas fa-temperature-up': faTemperatureUp,
    'fas fa-medal': faMedal,
    'fas fa-bed': faBed,
    'fas fa-square-h': faSquareH,
    'fas fa-h-square': faHSquare,
    'fas fa-podcast': faPodcast,
    'fas fa-temperature-full': faTemperatureFull,
    'fas fa-temperature4': faTemperature4,
    'fas fa-thermometer4': faThermometer4,
    'fas fa-thermometer-full': faThermometerFull,
    'fas fa-bell': faBell,
    'fas fa-superscript': faSuperscript,
    'fas fa-plug-circle-xmark': faPlugCircleXmark,
    'fas fa-star-of-life': faStarOfLife,
    'fas fa-phone-slash': faPhoneSlash,
    'fas fa-paint-roller': faPaintRoller,
    'fas fa-handshake-angle': faHandshakeAngle,
    'fas fa-hands-helping': faHandsHelping,
    'fas fa-location-dot': faLocationDot,
    'fas fa-map-marker-alt': faMapMarkerAlt,
    'fas fa-file': faFile,
    'fas fa-greater-than': faGreaterThan,
    'fas fa-person-swimming': faPersonSwimming,
    'fas fa-swimmer': faSwimmer,
    'fas fa-arrow-down': faArrowDown,
    'fas fa-droplet': faDroplet,
    'fas fa-tint': faTint,
    'fas fa-eraser': faEraser,
    'fas fa-earth-americas': faEarthAmericas,
    'fas fa-earth': faEarth,
    'fas fa-earth-america': faEarthAmerica,
    'fas fa-globe-americas': faGlobeAmericas,
    'fas fa-person-burst': faPersonBurst,
    'fas fa-dove': faDove,
    'fas fa-battery-empty': faBatteryEmpty,
    'fas fa-battery0': faBattery0,
    'fas fa-socks': faSocks,
    'fas fa-inbox': faInbox,
    'fas fa-section': faSection,
    'fas fa-gauge-high': faGaugeHigh,
    'fas fa-tachometer-alt': faTachometerAlt,
    'fas fa-tachometer-alt-fast': faTachometerAltFast,
    'fas fa-envelope-open-text': faEnvelopeOpenText,
    'fas fa-hospital': faHospital,
    'fas fa-hospital-alt': faHospitalAlt,
    'fas fa-hospital-wide': faHospitalWide,
    'fas fa-wine-bottle': faWineBottle,
    'fas fa-chess-rook': faChessRook,
    'fas fa-bars-staggered': faBarsStaggered,
    'fas fa-reorder': faReorder,
    'fas fa-stream': faStream,
    'fas fa-dharmachakra': faDharmachakra,
    'fas fa-hotdog': faHotdog,
    'fas fa-person-walking-with-cane': faPersonWalkingWithCane,
    'fas fa-blind': faBlind,
    'fas fa-drum': faDrum,
    'fas fa-ice-cream': faIceCream,
    'fas fa-heart-circle-bolt': faHeartCircleBolt,
    'fas fa-fax': faFax,
    'fas fa-paragraph': faParagraph,
    'fas fa-check-to-slot': faCheckToSlot,
    'fas fa-vote-yea': faVoteYea,
    'fas fa-star-half': faStarHalf,
    'fas fa-boxes-stacked': faBoxesStacked,
    'fas fa-boxes': faBoxes,
    'fas fa-boxes-alt': faBoxesAlt,
    'fas fa-link': faLink,
    'fas fa-chain': faChain,
    'fas fa-ear-listen': faEarListen,
    'fas fa-assistive-listening-systems': faAssistiveListeningSystems,
    'fas fa-tree-city': faTreeCity,
    'fas fa-play': faPlay,
    'fas fa-font': faFont,
    'fas fa-rupiah-sign': faRupiahSign,
    'fas fa-magnifying-glass': faMagnifyingGlass,
    'fas fa-search': faSearch,
    'fas fa-table-tennis-paddle-ball': faTableTennisPaddleBall,
    'fas fa-ping-pong-paddle-ball': faPingPongPaddleBall,
    'fas fa-table-tennis': faTableTennis,
    'fas fa-person-dots-from-line': faPersonDotsFromLine,
    'fas fa-diagnoses': faDiagnoses,
    'fas fa-trash-can-arrow-up': faTrashCanArrowUp,
    'fas fa-trash-restore-alt': faTrashRestoreAlt,
    'fas fa-naira-sign': faNairaSign,
    'fas fa-cart-arrow-down': faCartArrowDown,
    'fas fa-walkie-talkie': faWalkieTalkie,
    'fas fa-file-pen': faFilePen,
    'fas fa-file-edit': faFileEdit,
    'fas fa-receipt': faReceipt,
    'fas fa-square-pen': faSquarePen,
    'fas fa-pen-square': faPenSquare,
    'fas fa-pencil-square': faPencilSquare,
    'fas fa-suitcase-rolling': faSuitcaseRolling,
    'fas fa-person-circle-exclamation': faPersonCircleExclamation,
    'fas fa-chevron-down': faChevronDown,
    'fas fa-battery-full': faBatteryFull,
    'fas fa-battery': faBattery,
    'fas fa-battery5': faBattery5,
    'fas fa-skull-crossbones': faSkullCrossbones,
    'fas fa-code-compare': faCodeCompare,
    'fas fa-list-ul': faListUl,
    'fas fa-list-dots': faListDots,
    'fas fa-school-lock': faSchoolLock,
    'fas fa-tower-cell': faTowerCell,
    'fas fa-down-long': faDownLong,
    'fas fa-long-arrow-alt-down': faLongArrowAltDown,
    'fas fa-ranking-star': faRankingStar,
    'fas fa-chess-king': faChessKing,
    'fas fa-person-harassing': faPersonHarassing,
    'fas fa-brazilian-real-sign': faBrazilianRealSign,
    'fas fa-landmark-dome': faLandmarkDome,
    'fas fa-landmark-alt': faLandmarkAlt,
    'fas fa-arrow-up': faArrowUp,
    'fas fa-tv': faTv,
    'fas fa-television': faTelevision,
    'fas fa-tv-alt': faTvAlt,
    'fas fa-shrimp': faShrimp,
    'fas fa-list-check': faListCheck,
    'fas fa-tasks': faTasks,
    'fas fa-jug-detergent': faJugDetergent,
    'fas fa-circle-user': faCircleUser,
    'fas fa-user-circle': faUserCircle,
    'fas fa-user-shield': faUserShield,
    'fas fa-wind': faWind,
    'fas fa-car-burst': faCarBurst,
    'fas fa-car-crash': faCarCrash,
    'fas fa-y': faY,
    'fas fa-person-snowboarding': faPersonSnowboarding,
    'fas fa-snowboarding': faSnowboarding,
    'fas fa-truck-fast': faTruckFast,
    'fas fa-shipping-fast': faShippingFast,
    'fas fa-fish': faFish,
    'fas fa-user-graduate': faUserGraduate,
    'fas fa-circle-half-stroke': faCircleHalfStroke,
    'fas fa-adjust': faAdjust,
    'fas fa-clapperboard': faClapperboard,
    'fas fa-circle-radiation': faCircleRadiation,
    'fas fa-radiation-alt': faRadiationAlt,
    'fas fa-baseball': faBaseball,
    'fas fa-baseball-ball': faBaseballBall,
    'fas fa-jet-fighter-up': faJetFighterUp,
    'fas fa-diagram-project': faDiagramProject,
    'fas fa-project-diagram': faProjectDiagram,
    'fas fa-copy': faCopy,
    'fas fa-volume-xmark': faVolumeXmark,
    'fas fa-volume-mute': faVolumeMute,
    'fas fa-volume-times': faVolumeTimes,
    'fas fa-hand-sparkles': faHandSparkles,
    'fas fa-grip': faGrip,
    'fas fa-grip-horizontal': faGripHorizontal,
    'fas fa-share-from-square': faShareFromSquare,
    'fas fa-share-square': faShareSquare,
    'fas fa-child-combatant': faChildCombatant,
    'fas fa-child-rifle': faChildRifle,
    'fas fa-gun': faGun,
    'fas fa-square-phone': faSquarePhone,
    'fas fa-phone-square': faPhoneSquare,
    'fas fa-plus': faPlus,
    'fas fa-add': faAdd,
    'fas fa-expand': faExpand,
    'fas fa-computer': faComputer,
    'fas fa-xmark': faXmark,
    'fas fa-close': faClose,
    'fas fa-multiply': faMultiply,
    'fas fa-remove': faRemove,
    'fas fa-times': faTimes,
    'fas fa-arrows-up-down-left-right': faArrowsUpDownLeftRight,
    'fas fa-arrows': faArrows,
    'fas fa-chalkboard-user': faChalkboardUser,
    'fas fa-chalkboard-teacher': faChalkboardTeacher,
    'fas fa-peso-sign': faPesoSign,
    'fas fa-building-shield': faBuildingShield,
    'fas fa-baby': faBaby,
    'fas fa-users-line': faUsersLine,
    'fas fa-quote-left': faQuoteLeft,
    'fas fa-quote-left-alt': faQuoteLeftAlt,
    'fas fa-tractor': faTractor,
    'fas fa-trash-arrow-up': faTrashArrowUp,
    'fas fa-trash-restore': faTrashRestore,
    'fas fa-arrow-down-up-lock': faArrowDownUpLock,
    'fas fa-lines-leaning': faLinesLeaning,
    'fas fa-ruler-combined': faRulerCombined,
    'fas fa-copyright': faCopyright,
    'fas fa-equals': faEquals,
    'fas fa-blender': faBlender,
    'fas fa-teeth': faTeeth,
    'fas fa-shekel-sign': faShekelSign,
    'fas fa-ils': faIls,
    'fas fa-shekel': faShekel,
    'fas fa-sheqel': faSheqel,
    'fas fa-sheqel-sign': faSheqelSign,
    'fas fa-map': faMap,
    'fas fa-rocket': faRocket,
    'fas fa-photo-film': faPhotoFilm,
    'fas fa-photo-video': faPhotoVideo,
    'fas fa-folder-minus': faFolderMinus,
    'fas fa-store': faStore,
    'fas fa-arrow-trend-up': faArrowTrendUp,
    'fas fa-plug-circle-minus': faPlugCircleMinus,
    'fas fa-sign-hanging': faSignHanging,
    'fas fa-sign': faSign,
    'fas fa-bezier-curve': faBezierCurve,
    'fas fa-bell-slash': faBellSlash,
    'fas fa-tablet': faTablet,
    'fas fa-tablet-android': faTabletAndroid,
    'fas fa-school-flag': faSchoolFlag,
    'fas fa-fill': faFill,
    'fas fa-angle-up': faAngleUp,
    'fas fa-drumstick-bite': faDrumstickBite,
    'fas fa-holly-berry': faHollyBerry,
    'fas fa-chevron-left': faChevronLeft,
    'fas fa-bacteria': faBacteria,
    'fas fa-hand-lizard': faHandLizard,
    'fas fa-notdef': faNotdef,
    'fas fa-disease': faDisease,
    'fas fa-briefcase-medical': faBriefcaseMedical,
    'fas fa-genderless': faGenderless,
    'fas fa-chevron-right': faChevronRight,
    'fas fa-retweet': faRetweet,
    'fas fa-car-rear': faCarRear,
    'fas fa-car-alt': faCarAlt,
    'fas fa-pump-soap': faPumpSoap,
    'fas fa-video-slash': faVideoSlash,
    'fas fa-battery-quarter': faBatteryQuarter,
    'fas fa-battery2': faBattery2,
    'fas fa-radio': faRadio,
    'fas fa-baby-carriage': faBabyCarriage,
    'fas fa-carriage-baby': faCarriageBaby,
    'fas fa-traffic-light': faTrafficLight,
    'fas fa-thermometer': faThermometer,
    'fas fa-vr-cardboard': faVrCardboard,
    'fas fa-hand-middle-finger': faHandMiddleFinger,
    'fas fa-percent': faPercent,
    'fas fa-percentage': faPercentage,
    'fas fa-truck-moving': faTruckMoving,
    'fas fa-glass-water-droplet': faGlassWaterDroplet,
    'fas fa-display': faDisplay,
    'fas fa-face-smile': faFaceSmile,
    'fas fa-smile': faSmile,
    'fas fa-thumbtack': faThumbtack,
    'fas fa-thumb-tack': faThumbTack,
    'fas fa-trophy': faTrophy,
    'fas fa-person-praying': faPersonPraying,
    'fas fa-pray': faPray,
    'fas fa-hammer': faHammer,
    'fas fa-hand-peace': faHandPeace,
    'fas fa-rotate': faRotate,
    'fas fa-sync-alt': faSyncAlt,
    'fas fa-spinner': faSpinner,
    'fas fa-robot': faRobot,
    'fas fa-peace': faPeace,
    'fas fa-gears': faGears,
    'fas fa-cogs': faCogs,
    'fas fa-warehouse': faWarehouse,
    'fas fa-arrow-up-right-dots': faArrowUpRightDots,
    'fas fa-splotch': faSplotch,
    'fas fa-face-grin-hearts': faFaceGrinHearts,
    'fas fa-grin-hearts': faGrinHearts,
    'fas fa-dice-four': faDiceFour,
    'fas fa-sim-card': faSimCard,
    'fas fa-transgender': faTransgender,
    'fas fa-transgender-alt': faTransgenderAlt,
    'fas fa-mercury': faMercury,
    'fas fa-arrow-turn-down': faArrowTurnDown,
    'fas fa-level-down': faLevelDown,
    'fas fa-person-falling-burst': faPersonFallingBurst,
    'fas fa-award': faAward,
    'fas fa-ticket-simple': faTicketSimple,
    'fas fa-ticket-alt': faTicketAlt,
    'fas fa-building': faBuilding,
    'fas fa-angles-left': faAnglesLeft,
    'fas fa-angle-double-left': faAngleDoubleLeft,
    'fas fa-qrcode': faQrcode,
    'fas fa-clock-rotate-left': faClockRotateLeft,
    'fas fa-history': faHistory,
    'fas fa-face-grin-beam-sweat': faFaceGrinBeamSweat,
    'fas fa-grin-beam-sweat': faGrinBeamSweat,
    'fas fa-file-export': faFileExport,
    'fas fa-arrow-right-from-file': faArrowRightFromFile,
    'fas fa-shield': faShield,
    'fas fa-shield-blank': faShieldBlank,
    'fas fa-arrow-up-short-wide': faArrowUpShortWide,
    'fas fa-sort-amount-up-alt': faSortAmountUpAlt,
    'fas fa-house-medical': faHouseMedical,
    'fas fa-golf-ball-tee': faGolfBallTee,
    'fas fa-golf-ball': faGolfBall,
    'fas fa-circle-chevron-left': faCircleChevronLeft,
    'fas fa-chevron-circle-left': faChevronCircleLeft,
    'fas fa-house-chimney-window': faHouseChimneyWindow,
    'fas fa-pen-nib': faPenNib,
    'fas fa-tent-arrow-turn-left': faTentArrowTurnLeft,
    'fas fa-tents': faTents,
    'fas fa-wand-magic': faWandMagic,
    'fas fa-magic': faMagic,
    'fas fa-dog': faDog,
    'fas fa-carrot': faCarrot,
    'fas fa-moon': faMoon,
    'fas fa-wine-glass-empty': faWineGlassEmpty,
    'fas fa-wine-glass-alt': faWineGlassAlt,
    'fas fa-cheese': faCheese,
    'fas fa-yin-yang': faYinYang,
    'fas fa-music': faMusic,
    'fas fa-code-commit': faCodeCommit,
    'fas fa-temperature-low': faTemperatureLow,
    'fas fa-person-biking': faPersonBiking,
    'fas fa-biking': faBiking,
    'fas fa-broom': faBroom,
    'fas fa-shield-heart': faShieldHeart,
    'fas fa-gopuram': faGopuram,
    'fas fa-earth-oceania': faEarthOceania,
    'fas fa-globe-oceania': faGlobeOceania,
    'fas fa-square-xmark': faSquareXmark,
    'fas fa-times-square': faTimesSquare,
    'fas fa-xmark-square': faXmarkSquare,
    'fas fa-hashtag': faHashtag,
    'fas fa-up-right-and-down-left-from-center': faUpRightAndDownLeftFromCenter,
    'fas fa-expand-alt': faExpandAlt,
    'fas fa-oil-can': faOilCan,
    'fas fa-t': faT,
    'fas fa-hippo': faHippo,
    'fas fa-chart-column': faChartColumn,
    'fas fa-infinity': faInfinity,
    'fas fa-vial-circle-check': faVialCircleCheck,
    'fas fa-person-arrow-down-to-line': faPersonArrowDownToLine,
    'fas fa-voicemail': faVoicemail,
    'fas fa-fan': faFan,
    'fas fa-person-walking-luggage': faPersonWalkingLuggage,
    'fas fa-up-down': faUpDown,
    'fas fa-arrows-alt-v': faArrowsAltV,
    'fas fa-cloud-moon-rain': faCloudMoonRain,
    'fas fa-calendar': faCalendar,
    'fas fa-trailer': faTrailer,
    'fas fa-bahai': faBahai,
    'fas fa-haykal': faHaykal,
    'fas fa-sd-card': faSdCard,
    'fas fa-dragon': faDragon,
    'fas fa-shoe-prints': faShoePrints,
    'fas fa-circle-plus': faCirclePlus,
    'fas fa-plus-circle': faPlusCircle,
    'fas fa-face-grin-tongue-wink': faFaceGrinTongueWink,
    'fas fa-grin-tongue-wink': faGrinTongueWink,
    'fas fa-hand-holding': faHandHolding,
    'fas fa-plug-circle-exclamation': faPlugCircleExclamation,
    'fas fa-link-slash': faLinkSlash,
    'fas fa-chain-broken': faChainBroken,
    'fas fa-chain-slash': faChainSlash,
    'fas fa-unlink': faUnlink,
    'fas fa-clone': faClone,
    'fas fa-person-walking-arrow-loop-left': faPersonWalkingArrowLoopLeft,
    'fas fa-arrow-up-z-a': faArrowUpZA,
    'fas fa-sort-alpha-up-alt': faSortAlphaUpAlt,
    'fas fa-fire-flame-curved': faFireFlameCurved,
    'fas fa-fire-alt': faFireAlt,
    'fas fa-tornado': faTornado,
    'fas fa-file-circle-plus': faFileCirclePlus,
    'fas fa-book-quran': faBookQuran,
    'fas fa-quran': faQuran,
    'fas fa-anchor': faAnchor,
    'fas fa-border-all': faBorderAll,
    'fas fa-face-angry': faFaceAngry,
    'fas fa-angry': faAngry,
    'fas fa-cookie-bite': faCookieBite,
    'fas fa-arrow-trend-down': faArrowTrendDown,
    'fas fa-rss': faRss,
    'fas fa-feed': faFeed,
    'fas fa-draw-polygon': faDrawPolygon,
    'fas fa-scale-balanced': faScaleBalanced,
    'fas fa-balance-scale': faBalanceScale,
    'fas fa-gauge-simple-high': faGaugeSimpleHigh,
    'fas fa-tachometer': faTachometer,
    'fas fa-tachometer-fast': faTachometerFast,
    'fas fa-shower': faShower,
    'fas fa-desktop': faDesktop,
    'fas fa-desktop-alt': faDesktopAlt,
    'fas fa-m': faM,
    'fas fa-table-list': faTableList,
    'fas fa-th-list': faThList,
    'fas fa-comment-sms': faCommentSms,
    'fas fa-sms': faSms,
    'fas fa-book': faBook,
    'fas fa-user-plus': faUserPlus,
    'fas fa-check': faCheck,
    'fas fa-battery-three-quarters': faBatteryThreeQuarters,
    'fas fa-battery4': faBattery4,
    'fas fa-house-circle-check': faHouseCircleCheck,
    'fas fa-angle-left': faAngleLeft,
    'fas fa-diagram-successor': faDiagramSuccessor,
    'fas fa-truck-arrow-right': faTruckArrowRight,
    'fas fa-arrows-split-up-and-left': faArrowsSplitUpAndLeft,
    'fas fa-hand-fist': faHandFist,
    'fas fa-fist-raised': faFistRaised,
    'fas fa-cloud-moon': faCloudMoon,
    'fas fa-briefcase': faBriefcase,
    'fas fa-person-falling': faPersonFalling,
    'fas fa-image-portrait': faImagePortrait,
    'fas fa-portrait': faPortrait,
    'fas fa-user-tag': faUserTag,
    'fas fa-rug': faRug,
    'fas fa-earth-europe': faEarthEurope,
    'fas fa-globe-europe': faGlobeEurope,
    'fas fa-cart-flatbed-suitcase': faCartFlatbedSuitcase,
    'fas fa-luggage-cart': faLuggageCart,
    'fas fa-rectangle-xmark': faRectangleXmark,
    'fas fa-rectangle-times': faRectangleTimes,
    'fas fa-times-rectangle': faTimesRectangle,
    'fas fa-window-close': faWindowClose,
    'fas fa-baht-sign': faBahtSign,
    'fas fa-book-open': faBookOpen,
    'fas fa-book-journal-whills': faBookJournalWhills,
    'fas fa-journal-whills': faJournalWhills,
    'fas fa-handcuffs': faHandcuffs,
    'fas fa-triangle-exclamation': faTriangleExclamation,
    'fas fa-exclamation-triangle': faExclamationTriangle,
    'fas fa-warning': faWarning,
    'fas fa-database': faDatabase,
    'fas fa-share': faShare,
    'fas fa-mail-forward': faMailForward,
    'fas fa-bottle-droplet': faBottleDroplet,
    'fas fa-mask-face': faMaskFace,
    'fas fa-hill-rockslide': faHillRockslide,
    'fas fa-right-left': faRightLeft,
    'fas fa-exchange-alt': faExchangeAlt,
    'fas fa-paper-plane': faPaperPlane,
    'fas fa-road-circle-exclamation': faRoadCircleExclamation,
    'fas fa-dungeon': faDungeon,
    'fas fa-align-right': faAlignRight,
    'fas fa-money-bill1-wave': faMoneyBill1Wave,
    'fas fa-money-bill-wave-alt': faMoneyBillWaveAlt,
    'fas fa-life-ring': faLifeRing,
    'fas fa-hands': faHands,
    'fas fa-sign-language': faSignLanguage,
    'fas fa-signing': faSigning,
    'fas fa-calendar-day': faCalendarDay,
    'fas fa-water-ladder': faWaterLadder,
    'fas fa-ladder-water': faLadderWater,
    'fas fa-swimming-pool': faSwimmingPool,
    'fas fa-arrows-up-down': faArrowsUpDown,
    'fas fa-arrows-v': faArrowsV,
    'fas fa-face-grimace': faFaceGrimace,
    'fas fa-grimace': faGrimace,
    'fas fa-wheelchair-move': faWheelchairMove,
    'fas fa-wheelchair-alt': faWheelchairAlt,
    'fas fa-turn-down': faTurnDown,
    'fas fa-level-down-alt': faLevelDownAlt,
    'fas fa-person-walking-arrow-right': faPersonWalkingArrowRight,
    'fas fa-square-envelope': faSquareEnvelope,
    'fas fa-envelope-square': faEnvelopeSquare,
    'fas fa-dice': faDice,
    'fas fa-bowling-ball': faBowlingBall,
    'fas fa-brain': faBrain,
    'fas fa-bandage': faBandage,
    'fas fa-band-aid': faBandAid,
    'fas fa-calendar-minus': faCalendarMinus,
    'fas fa-circle-xmark': faCircleXmark,
    'fas fa-times-circle': faTimesCircle,
    'fas fa-xmark-circle': faXmarkCircle,
    'fas fa-gifts': faGifts,
    'fas fa-hotel': faHotel,
    'fas fa-earth-asia': faEarthAsia,
    'fas fa-globe-asia': faGlobeAsia,
    'fas fa-id-card-clip': faIdCardClip,
    'fas fa-id-card-alt': faIdCardAlt,
    'fas fa-magnifying-glass-plus': faMagnifyingGlassPlus,
    'fas fa-search-plus': faSearchPlus,
    'fas fa-thumbs-up': faThumbsUp,
    'fas fa-user-clock': faUserClock,
    'fas fa-hand-dots': faHandDots,
    'fas fa-allergies': faAllergies,
    'fas fa-file-invoice': faFileInvoice,
    'fas fa-window-minimize': faWindowMinimize,
    'fas fa-mug-saucer': faMugSaucer,
    'fas fa-coffee': faCoffee,
    'fas fa-brush': faBrush,
    'fas fa-mask': faMask,
    'fas fa-magnifying-glass-minus': faMagnifyingGlassMinus,
    'fas fa-search-minus': faSearchMinus,
    'fas fa-ruler-vertical': faRulerVertical,
    'fas fa-user-large': faUserLarge,
    'fas fa-user-alt': faUserAlt,
    'fas fa-train-tram': faTrainTram,
    'fas fa-user-nurse': faUserNurse,
    'fas fa-syringe': faSyringe,
    'fas fa-cloud-sun': faCloudSun,
    'fas fa-stopwatch20': faStopwatch20,
    'fas fa-square-full': faSquareFull,
    'fas fa-magnet': faMagnet,
    'fas fa-jar': faJar,
    'fas fa-note-sticky': faNoteSticky,
    'fas fa-sticky-note': faStickyNote,
    'fas fa-bug-slash': faBugSlash,
    'fas fa-arrow-up-from-water-pump': faArrowUpFromWaterPump,
    'fas fa-bone': faBone,
    'fas fa-user-injured': faUserInjured,
    'fas fa-face-sad-tear': faFaceSadTear,
    'fas fa-sad-tear': faSadTear,
    'fas fa-plane': faPlane,
    'fas fa-tent-arrows-down': faTentArrowsDown,
    'fas fa-exclamation': faExclamation,
    'fas fa-arrows-spin': faArrowsSpin,
    'fas fa-print': faPrint,
    'fas fa-turkish-lira-sign': faTurkishLiraSign,
    'fas fa-try': faTry,
    'fas fa-turkish-lira': faTurkishLira,
    'fas fa-dollar-sign': faDollarSign,
    'fas fa-dollar': faDollar,
    'fas fa-usd': faUsd,
    'fas fa-x': faX,
    'fas fa-magnifying-glass-dollar': faMagnifyingGlassDollar,
    'fas fa-search-dollar': faSearchDollar,
    'fas fa-users-gear': faUsersGear,
    'fas fa-users-cog': faUsersCog,
    'fas fa-person-military-pointing': faPersonMilitaryPointing,
    'fas fa-building-columns': faBuildingColumns,
    'fas fa-bank': faBank,
    'fas fa-institution': faInstitution,
    'fas fa-museum': faMuseum,
    'fas fa-university': faUniversity,
    'fas fa-umbrella': faUmbrella,
    'fas fa-trowel': faTrowel,
    'fas fa-d': faD,
    'fas fa-stapler': faStapler,
    'fas fa-masks-theater': faMasksTheater,
    'fas fa-theater-masks': faTheaterMasks,
    'fas fa-kip-sign': faKipSign,
    'fas fa-hand-point-left': faHandPointLeft,
    'fas fa-handshake-simple': faHandshakeSimple,
    'fas fa-handshake-alt': faHandshakeAlt,
    'fas fa-jet-fighter': faJetFighter,
    'fas fa-fighter-jet': faFighterJet,
    'fas fa-square-share-nodes': faSquareShareNodes,
    'fas fa-share-alt-square': faShareAltSquare,
    'fas fa-barcode': faBarcode,
    'fas fa-plus-minus': faPlusMinus,
    'fas fa-video': faVideo,
    'fas fa-video-camera': faVideoCamera,
    'fas fa-graduation-cap': faGraduationCap,
    'fas fa-mortar-board': faMortarBoard,
    'fas fa-hand-holding-medical': faHandHoldingMedical,
    'fas fa-person-circle-check': faPersonCircleCheck,
    'fas fa-turn-up': faTurnUp,
    'fas fa-level-up-alt': faLevelUpAlt
};

export const tabIcons = [
    'fas fa-angle-double-right',
    'fas fa-award',
    'fas fa-bolt',
    'fas fa-brain',
    'fas fa-briefcase',
    'fas fa-brush',
    'fas fa-bug',
    'fas fa-bullseye',
    'fas fa-calendar-check',
    'fas fa-chart-line',
    'fas fa-clipboard-list',
    'fas fa-code',
    'fas fa-code-branch',
    'fas fa-cogs',
    'fas fa-coins',
    'fas fa-compass',
    'fas fa-crown',
    'fas fa-cube',
    'fas fa-database',
    'fas fa-diagram-project',
    'fas fa-dollar-sign',
    'fas fa-eye',
    'fas fa-file-code',
    'fas fa-fire',
    'fas fa-flask',
    'fas fa-gem',
    'fas fa-globe',
    'fas fa-graduation-cap',
    'fas fa-hand-holding-usd',
    'fas fa-hands-helping',
    'fas fa-heart',
    'fas fa-info-circle',
    'fas fa-keyboard',
    'fas fa-laptop-code',
    'fas fa-lightbulb',
    'fas fa-magic',
    'fas fa-microchip',
    'fas fa-mobile',
    'fas fa-network-wired',
    'fas fa-paint-brush',
    'fas fa-palette',
    'fas fa-paper-plane',
    'fas fa-pen-ruler',
    'fas fa-puzzle-piece',
    'fas fa-question-circle',
    'fas fa-robot',
    'fas fa-rocket',
    'fas fa-search',
    'fas fa-shield',
    'fas fa-sitemap',
    'fas fa-smile',
    'fas fa-star',
    'fas fa-tasks',
    'fas fa-terminal',
    'fas fa-tools',
    'fas fa-trophy',
    'fas fa-user-graduate',
    'fas fa-users',
    'fas fa-wallet',
    'fas fa-wrench'
];

export default function getIconDefinition(className: string) {
    return iconMap[className] || faMehBlank;
}
