import React, {useEffect, useState} from 'react';
import {ReactStateSetter} from '../../../types.ts';
import Resizer from 'react-image-file-resizer';
import {Button, Col, Input, InputGroup, InputGroupText, Row} from 'reactstrap';
import {readAndCompressImage} from 'browser-image-resizer';
import {AiOutlineRotateLeft} from 'react-icons/ai';
import {fabric} from 'fabric';
import humanFileSize from '../../../lib/humanFileSize.ts';

interface EditInfo {
    initialDimensions: ImageDimensions;
    dimensions: ImageDimensions;
    quality: number;
    numberOfRotates: number;
}

interface ImageDimensions {
    width: number;
    height: number;
    aspectRatio: number;
}
interface ImageResizerProps {
    editImage: File;
    setEditedImage: ReactStateSetter<File | null>;
    editedImage: File;
}

const ImageResizer: React.FC<ImageResizerProps> = ({
    editImage,
    setEditedImage,
    editedImage
}) => {
    const fileName = editImage.name;
    const fileExtension = fileName.toLowerCase().split('.').pop() ?? '';
    const fabricIDataURLOptionsFormat = fileExtension == 'png' ? 'png' : 'jpeg';
    const fileBlobType = `image/${fabricIDataURLOptionsFormat}`;

    const [editedImageUrl, setEditedImageUrl] = useState(
        URL.createObjectURL(editedImage)
    );

    const [editInfo, setEditInfo] = useState<EditInfo>({
        dimensions: {
            width: 0,
            height: 0,
            aspectRatio: 0
        },
        initialDimensions: {
            width: 0,
            height: 0,
            aspectRatio: 0
        },
        quality: 0.6,
        numberOfRotates: 0
    });

    useEffect(() => {
        const initImageDimensions = () => {
            const img = new Image();
            img.src = URL.createObjectURL(editImage);
            img.onload = () => {
                // noinspection JSSuspiciousNameCombination
                setEditInfo(prevState => ({
                    ...prevState,
                    dimensions: {
                        width: img.width,
                        height: img.height,
                        aspectRatio: img.width / img.height
                    },
                    initialDimensions: {
                        width: img.width,
                        height: img.height,
                        aspectRatio: img.width / img.height
                    },
                    initialDimensionsRotated: {
                        width: img.height,
                        height: img.width,
                        aspectRatio: img.height / img.width
                    }
                }));
            };
        };

        initImageDimensions();
    }, []);

    useEffect(() => {
        (async () => {
            if (
                editInfo.dimensions.width > 0 &&
                editInfo.dimensions.height > 0
            ) {
                const editedImage = await applyImageEdits();
                setEditedImage(editedImage as File);
            }
        })();
    }, [
        editInfo.dimensions.width,
        editInfo.dimensions.height,
        editInfo.numberOfRotates,
        editInfo.quality
    ]);

    useEffect(() => {
        setEditedImageUrl(URL.createObjectURL(editedImage));

        return () => {
            URL.revokeObjectURL(editedImageUrl);
        };
    }, [editedImage]);

    const resizeFile = async (
        file: File,
        width: number,
        height: number
    ): Promise<File> => {
        return new Promise<File>(resolve => {
            Resizer.imageFileResizer(
                file,
                width,
                height,
                fileExtension,
                100,
                0,
                uri => {
                    resolve(uri as File);
                },
                'file'
            );
        });
    };

    const rotateImage = (imageToRotate: File): Promise<File> => {
        return new Promise((resolve, reject) => {
            fabric.Image.fromURL(
                URL.createObjectURL(imageToRotate),
                function (oImg) {
                    oImg.rotate(-90 * editInfo.numberOfRotates);
                    const dataUrl = oImg.toDataURL({
                        format: fabricIDataURLOptionsFormat,
                        quality: 1
                    });

                    fetch(dataUrl)
                        .then(res => res.blob())
                        .then(async blob => {
                            const imageFile = new File([blob], fileName, {
                                type: fileBlobType
                            });

                            resolve(imageFile);
                        })
                        .catch(error => {
                            reject(error);
                        });
                }
            );
        });
    };

    const adjustImageQuality = async (imageFile: File) => {
        return await readAndCompressImage(imageFile, {
            quality: editInfo.quality,
            maxWidth: getWidthAccountingForRotation(),
            maxHeight: getHeightAccountingForRotation()
        });
    };

    const applyImageEdits = async () => {
        const resizedFile =
            editInfo.dimensions.width === editInfo.initialDimensions.width &&
            editInfo.dimensions.height === editInfo.initialDimensions.height
                ? editImage
                : await resizeFile(
                      editImage,
                      editInfo.dimensions.width,
                      editInfo.dimensions.height
                  );

        const rotatedImage =
            editInfo.numberOfRotates === 0
                ? resizedFile
                : await rotateImage(resizedFile);

        const qualityAdjustedFile =
            editInfo.quality === 1
                ? rotatedImage
                : await adjustImageQuality(rotatedImage);

        return new File([qualityAdjustedFile], fileName, {
            type: 'image/jpeg'
        });
    };

    const handleRotate = () => {
        setEditInfo(prevState => ({
            ...prevState,
            numberOfRotates:
                prevState.numberOfRotates == 3
                    ? 0
                    : prevState.numberOfRotates + 1
        }));
    };

    const isRotated =
        editInfo.numberOfRotates == 1 || editInfo.numberOfRotates == 3;

    const getWidthAccountingForRotation = () => {
        return isRotated
            ? editInfo.dimensions.height
            : editInfo.dimensions.width;
    };
    const getHeightAccountingForRotation = () => {
        return isRotated
            ? editInfo.dimensions.width
            : editInfo.dimensions.height;
    };

    const handleWidthChange = (newWidth: number) => {
        if (isRotated) {
            setHeight(newWidth);
        } else {
            setWidth(newWidth);
        }
    };

    const handleHeightChange = (newHeight: number) => {
        if (isRotated) {
            setWidth(newHeight);
        } else {
            setHeight(newHeight);
        }
    };

    const setWidth = (newWidth: number) => {
        if (newWidth >= editInfo.initialDimensions.width) {
            return;
        }

        setEditInfo(prevState => ({
            ...prevState,
            dimensions: {
                ...prevState.dimensions,
                width: newWidth,
                height: Math.round(newWidth / editInfo.dimensions.aspectRatio)
            }
        }));
    };

    const setHeight = (newHeight: number) => {
        if (newHeight >= editInfo.initialDimensions.height) {
            return;
        }

        setEditInfo(prevState => ({
            ...prevState,
            dimensions: {
                ...prevState.dimensions,
                height: newHeight,
                width: Math.round(newHeight * editInfo.dimensions.aspectRatio)
            }
        }));
    };

    const handleQualityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newQuality = parseFloat(e.target.value);
        setEditInfo(prevState => ({
            ...prevState,
            quality: newQuality
        }));
    };

    const getFileSizeColour = (size: number) => {
        const sizeInKB = size / 1024;

        if (sizeInKB < 100) {
            return 'green';
        } else if (sizeInKB >= 100 && sizeInKB <= 500) {
            return 'orange';
        } else {
            return 'red';
        }
    };

    const circleStyle = {
        width: '15px',
        height: '15px',
        borderRadius: '50%',
        display: 'inline-block',
        marginLeft: '6px',
        backgroundColor: getFileSizeColour(editedImage.size)
    };

    return (
        <>
            <Row className="mb-3">
                <Col md="auto">
                    <Button
                        color="primary"
                        onClick={handleRotate}
                        style={{
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <AiOutlineRotateLeft size={23} />
                    </Button>
                </Col>
                <Col md={3}>
                    <InputGroup size="sm">
                        <InputGroupText>Width</InputGroupText>
                        <Input
                            type="number"
                            step={10}
                            value={getWidthAccountingForRotation()}
                            onChange={event =>
                                handleWidthChange(
                                    parseInt(event.target.value, 10)
                                )
                            }
                        />
                    </InputGroup>
                </Col>
                <Col md={3}>
                    <InputGroup size="sm">
                        <InputGroupText>Height</InputGroupText>
                        <Input
                            type="number"
                            step={10}
                            value={getHeightAccountingForRotation()}
                            onChange={event =>
                                handleHeightChange(
                                    parseInt(event.target.value, 10)
                                )
                            }
                        />
                    </InputGroup>
                </Col>
            </Row>
            <div>
                <label>Quality</label>
                <input
                    style={{
                        marginLeft: 15
                    }}
                    type="range"
                    min={0}
                    max={1}
                    step={0.1}
                    value={editInfo.quality}
                    onChange={e => handleQualityChange(e)}
                />
                <span className="ms-2">{editInfo.quality.toFixed(1)}</span>
            </div>

            {editedImage && (
                <>
                    <div>
                        <b>File Size: </b>
                        {humanFileSize(editedImage.size)}
                        <div style={circleStyle}></div>
                    </div>

                    <div
                        style={{maxWidth: '100%', overflowX: 'auto'}}
                        className="mb-3"
                    >
                        <img
                            src={editedImageUrl}
                            alt="Resized"
                            style={{maxWidth: 'none', height: 'auto'}}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default ImageResizer;
