import MxCode from '../shared/MxCode.tsx';
import HtmlComponent from '../shared/HtmlComponent.tsx';
import {marked} from 'marked';
import {MergeData} from '../../types.ts';
import {ContentSectionModel, ViewMode} from '../../api/types.ts';
import useSessionState from '../../lib/useSessionState.ts';

interface MarkdownSectionProps {
    elementId: string;
    section: ContentSectionModel;
    readOnly: boolean;
    mergeData: MergeData;
}

const MarkdownSection: React.FC<MarkdownSectionProps> = ({
    elementId,
    section,
    readOnly,
    mergeData
}) => {
    const [viewMode] = useSessionState(`ViewMode_${elementId}`, ViewMode.View);

    if (!readOnly && viewMode == ViewMode.Edit) {
        return (
            <>
                <small>
                    <a
                        href="https://www.markdownguide.org/basic-syntax"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Markdown guide
                    </a>
                </small>
                <MxCode
                    id={`Section_Edit_${elementId}`}
                    code={section.content ?? ''}
                    onChange={code => mergeData(elementId, {content: code})}
                    readOnly={false}
                    mode="text/x-markdown"
                />
            </>
        );
    }

    const html = marked(section.content ?? '') as string;
    return <HtmlComponent html={html} />;
};

export default MarkdownSection;
