import useFetchProjectData from '../api/project_data/useFetchProjectData.ts';
import {useQuery, useMutation} from 'react-query';
import {useEffect, useMemo, useState} from 'react';
import {
    EntityDataModel,
    EntityDataOperation,
    EntityDataPayload,
    EntityManipulationMethods,
    UnknownObject
} from '../types.ts';
import useProjectDataHub, {
    ProjectDataHubMessages,
    useHubMessageListener
} from './useProjectDataHub.ts';
import createEntityManipulationMethods from './createEntityManipulationMethods.ts';
import {ProjectDataIndex} from '../api/types.ts';
import {HubConnectionState} from '@microsoft/signalr';
import isLargeObject from '../lib/isLargeObject.ts';
import useMergeLargeObject from '../api/project_data/useMergeLargeObject.ts';
import {useAuthStateManager} from '../hooks/useAuthStateManager.tsx';
import useClientConfig from '../hooks/useClientConfig.tsx';
import getLargeObject from '../api/project_data/getLargeObject.ts';

export type UseProjectDataResponse = {
    projectData: EntityDataModel;
    manipulation: EntityManipulationMethods;
    isLoading: boolean;
    isError: boolean;
    showReconnectionModal: boolean;
    closeReconnectionModal: () => void;
    reinitialize: () => void;
    playerEvent: (player: PlayerEvent) => void;

    // TODO: Rename to sendReactionEvent
    reactionEvent: (reaction: ReactionEvent) => void;
};

export interface Player {
    eventType: string;
    userId: string;
    nickname: string;
    containerName: string;
}

export interface PlayerEvent extends Player {
    x?: number;
    y?: number;
}

export interface ReactionEvent {
    animationType: 'float' | 'drop' | 'fly';
    message: string | null;
    color: string | null;
    emoji: string | null;
    left: number | null;
    top: number | null;
}

type UseProjectDataParams = {
    projectId: string;
    dataIndex: ProjectDataIndex;
    onRemoteManipulation?: EntityManipulationMethods;
    onInitialDataFetched?: (initialData: EntityDataModel) => void;
    onRemotePlayerEvent?: (player: PlayerEvent) => void;
    onRemoteReactionEvent?: (reaction: ReactionEvent) => void;
};

export default function useProjectData({
    projectId,
    dataIndex,
    onRemoteManipulation,
    onInitialDataFetched,
    onRemotePlayerEvent,
    onRemoteReactionEvent
}: UseProjectDataParams): UseProjectDataResponse {
    const {getAccessToken} = useAuthStateManager();
    const {apiServiceBaseUri} = useClientConfig();

    const mergeLargeObject = useMergeLargeObject();
    const mergeLargeObjectMutation = useMutation(mergeLargeObject);

    const onRemoteMergeData = onRemoteManipulation?.mergeData ?? undefined;
    const onRemoteRemoveElement =
        onRemoteManipulation?.removeElement ?? undefined;

    const fetchProductData = useFetchProjectData(projectId, dataIndex);
    const {
        data: initialData,
        isError,
        isLoading
    } = useQuery(['projectData', projectId, dataIndex], fetchProductData);

    const [entityData, setEntityData] = useState<EntityDataModel>({});

    const manipulation = useMemo(
        () => createEntityManipulationMethods(setEntityData),
        [setEntityData]
    );
    const {mergeData, removeElement} = manipulation;

    useEffect(() => {
        if (initialData) {
            setEntityData(initialData);
            if (onInitialDataFetched) {
                onInitialDataFetched(initialData);
            }
        }
    }, [initialData, onInitialDataFetched]);

    const groupName = `${projectId}_${dataIndex}`;
    const [hubConnection, isHubDisconnected, reinitializeHubConnection] =
        useProjectDataHub(groupName);

    const [showReconnectionModal, setShowReconnectionModal] = useState(false);

    useEffect(() => {
        setShowReconnectionModal(isHubDisconnected);
    }, [isHubDisconnected]);

    useHubMessageListener(
        hubConnection,
        ProjectDataHubMessages.UpdatePlayerOnClient,
        (args: unknown) => {
            const playerEvent = args as PlayerEvent;

            if (onRemotePlayerEvent) {
                onRemotePlayerEvent(playerEvent);
            }
        },
        [groupName, hubConnection.connectionId, onRemotePlayerEvent]
    );

    useHubMessageListener(
        hubConnection,
        ProjectDataHubMessages.UpdateReactionOnClient,
        (args: unknown) => {
            const reactionEvent = args as ReactionEvent;

            if (onRemoteReactionEvent) {
                onRemoteReactionEvent(reactionEvent);
            }
        },
        [groupName, hubConnection.connectionId, onRemoteReactionEvent]
    );

    useHubMessageListener(
        hubConnection,
        ProjectDataHubMessages.UpdateProjectDataOnClient,
        (args: unknown) => {
            const payload = args as EntityDataPayload;

            switch (payload.op) {
                case EntityDataOperation.MergeData:
                    {
                        const data = payload.data!;

                        const {
                            largeObject,
                            projectId,
                            dataIndex,
                            elementId,
                            propNames
                        } = data;

                        if (typeof largeObject === 'boolean' && largeObject) {
                            console.log(
                                'UpdateProjectDataOnClient isLargeObject'
                            );
                            getLargeObject(
                                projectId as string,
                                dataIndex as number,
                                elementId as string,
                                propNames as string[],
                                apiServiceBaseUri,
                                getAccessToken
                            )
                                .then(fetchedElementData => {
                                    handleMergeData(
                                        payload.elementId,
                                        fetchedElementData,
                                        payload.meta
                                    );
                                })
                                .catch(error => {
                                    console.error(
                                        'Error fetching large object:',
                                        error
                                    );
                                });
                        } else {
                            handleMergeData(
                                payload.elementId,
                                data,
                                payload.meta
                            );
                        }
                    }
                    break;
                case EntityDataOperation.RemoveElement:
                    onRemoteRemoveElement &&
                        onRemoteRemoveElement(payload.elementId, payload.meta);
                    removeElement(payload.elementId);
                    break;
            }
        },
        [
            groupName,
            hubConnection.connectionId,
            onRemoteMergeData,
            onRemoteRemoveElement
        ]
    );

    function handleMergeData(
        elementId: string,
        data: UnknownObject,
        meta?: string | null | undefined
    ) {
        onRemoteMergeData && onRemoteMergeData(elementId, data, meta);
        mergeData(elementId, data);
    }

    const mergeDataAndBroadcast = (
        elementId: string,
        data: UnknownObject,
        meta?: string | null
    ) => {
        mergeData(elementId, data);

        const payload: EntityDataPayload = {
            projectId,
            dataIndex,
            elementId,
            data,
            op: EntityDataOperation.MergeData,
            meta
        };

        if (isLargeObject(payload, 16 * 1024 /* 16KB */)) {
            console.log('isLargeObject');
            mergeLargeObjectMutation.mutate(payload);
        } else {
            if (hubConnection.state === HubConnectionState.Connected) {
                hubConnection.invoke(
                    ProjectDataHubMessages.UpdateProjectDataOnServer,
                    groupName,
                    payload
                );
            } else {
                console.warn(
                    "SignalR connection is not in the 'Connected' state. Cannot send data."
                );
            }
        }
    };

    const removeElementAndBroadcast = (
        elementId: string,
        meta?: string | null
    ) => {
        removeElement(elementId);

        const payload: EntityDataPayload = {
            projectId,
            dataIndex,
            elementId,
            op: EntityDataOperation.RemoveElement,
            meta
        };

        if (hubConnection.state === HubConnectionState.Connected) {
            hubConnection.invoke(
                ProjectDataHubMessages.UpdateProjectDataOnServer,
                groupName,
                payload
            );
        } else {
            console.warn(
                "SignalR connection is not in the 'Connected' state. Cannot send data."
            );
        }
    };

    const playerEvent = (playerEvent: PlayerEvent) => {
        if (hubConnection.state === HubConnectionState.Connected) {
            hubConnection.invoke(
                ProjectDataHubMessages.UpdatePlayerOnServer,
                groupName,
                playerEvent
            );
        } else {
            console.warn(
                "SignalR connection is not in the 'Connected' state. Cannot send data."
            );
        }
    };

    const reactionEvent = (reactionEvent: ReactionEvent) => {
        if (hubConnection.state === HubConnectionState.Connected) {
            hubConnection.invoke(
                ProjectDataHubMessages.UpdateReactionOnServer,
                groupName,
                reactionEvent
            );
        } else {
            console.warn(
                "SignalR connection is not in the 'Connected' state. Cannot send data."
            );
        }
    };

    return {
        projectData: entityData,
        manipulation: {
            mergeData: mergeDataAndBroadcast,
            removeElement: removeElementAndBroadcast
        },
        playerEvent,
        reactionEvent,
        isLoading,
        isError,
        showReconnectionModal,
        reinitialize: reinitializeHubConnection,
        closeReconnectionModal: () => setShowReconnectionModal(false)
    };
}
