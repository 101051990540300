import React from 'react';
import {SceneEntity, SceneEvents} from './types.ts';
import {EntityManipulationMethods} from '../../types.ts';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import SpatialCoordinateEditor from './SpatialCoordinateEditor.tsx';
import MaterialEditor from './MaterialEditor.tsx';
import SphereEditor from './SphereEditor.tsx';
import BoxEditor from './BoxEditor.tsx';
import TorusEditor from './TorusEditor.tsx';
import CylinderEditor from './CylinderEditor.tsx';
import PlaneEditor from './PlaneEditor.tsx';
import DiscEditor from './DiscEditor.tsx';
import IcosphereEditor from './IcosphereEditor.tsx';
import LightEditor from './LightEditor.tsx';
import TorusKnotEditor from './TorusKnotEditor.tsx';
import GroundEditor from './GroundEditor.tsx';
import LinesEditor from './LinesEditor.tsx';
import DashedLinesEditor from './DashedLinesEditor.tsx';
import ExtrudeShapeEditor from './ExtrudeShapeEditor.tsx';
import LatheEditor from './LatheEditor.tsx';
// import TextEditor from './TextEditor.tsx';

interface SceneEntityControlProps {
    entity: SceneEntity;
    elementId: string;
    manipulation: EntityManipulationMethods;
    refreshIframe: () => void;
}

const SceneEntityControl: React.FC<SceneEntityControlProps> = ({
    entity,
    elementId,
    manipulation,
    refreshIframe
}) => {
    const {mergeData, removeElement} = manipulation;

    const handleChange = (property: string, value: unknown) => {
        mergeData(elementId, {[property]: value}, SceneEvents.EntityModify);
    };

    return (
        <div className="p-2">
            <div>
                <b>Type:</b> {entity.type}
            </div>

            {entity.type == 'model' && (
                <div>
                    <b>Model:</b> {entity.url}
                </div>
            )}

            {entity.position && (
                <SpatialCoordinateEditor
                    label={<b>Position</b>}
                    coordinate={entity.position}
                    onChange={coordinate =>
                        handleChange('position', coordinate)
                    }
                />
            )}

            {entity.rotation && (
                <SpatialCoordinateEditor
                    label={<b>Rotation</b>}
                    coordinate={entity.rotation}
                    onChange={coordinate =>
                        handleChange('rotation', coordinate)
                    }
                />
            )}

            {entity.scaling && (
                <SpatialCoordinateEditor
                    label={<b>Scaling</b>}
                    coordinate={entity.scaling}
                    onChange={coordinate => handleChange('scaling', coordinate)}
                />
            )}

            {entity.material && (
                <MaterialEditor
                    material={entity.material}
                    onChange={value => handleChange('material', value)}
                />
            )}

            {entity.type === 'sphere' && (
                <SphereEditor
                    diameter={entity.diameter}
                    onChange={value => handleChange('diameter', value)}
                />
            )}

            {entity.type === 'box' && (
                <BoxEditor
                    size={entity.size}
                    onChange={value => handleChange('size', value)}
                />
            )}

            {entity.type === 'torus' && (
                <TorusEditor
                    diameter={entity.diameter}
                    thickness={entity.thickness}
                    onDiameterChange={value => handleChange('diameter', value)}
                    onThicknessChange={value =>
                        handleChange('thickness', value)
                    }
                />
            )}

            {entity.type === 'cylinder' && (
                <CylinderEditor
                    height={entity.height}
                    diameter={entity.diameter}
                    onHeightChange={value => handleChange('height', value)}
                    onDiameterChange={value => handleChange('diameter', value)}
                />
            )}

            {entity.type === 'plane' && (
                <PlaneEditor
                    width={entity.width}
                    height={entity.height}
                    onWidthChange={value => handleChange('width', value)}
                    onHeightChange={value => handleChange('height', value)}
                />
            )}

            {entity.type === 'disc' && (
                <DiscEditor
                    radius={entity.radius}
                    onChange={value => handleChange('radius', value)}
                />
            )}

            {entity.type === 'icosphere' && (
                <IcosphereEditor
                    radius={entity.radius}
                    subdivisions={entity.subdivisions}
                    onRadiusChange={value => handleChange('radius', value)}
                    onSubdivisionsChange={value =>
                        handleChange('subdivisions', value)
                    }
                />
            )}

            {(entity.type === 'pointLight' ||
                entity.type === 'directionalLight' ||
                entity.type === 'spotLight' ||
                entity.type === 'hemisphericLight') && (
                <LightEditor
                    light={entity}
                    onIntensityChange={value =>
                        handleChange('intensity', value)
                    }
                    onDirectionChange={value =>
                        handleChange('direction', value)
                    }
                    onAngleChange={value => handleChange('angle', value)}
                    onExponentChange={value => handleChange('exponent', value)}
                />
            )}

            {entity.type === 'torusKnot' && (
                <TorusKnotEditor
                    radius={entity.radius}
                    tube={entity.tube}
                    radialSegments={entity.radialSegments}
                    tubularSegments={entity.tubularSegments}
                    p={entity.p}
                    q={entity.q}
                    onRadiusChange={value => handleChange('radius', value)}
                    onTubeChange={value => handleChange('tube', value)}
                    onRadialSegmentsChange={value =>
                        handleChange('radialSegments', value)
                    }
                    onTubularSegmentsChange={value =>
                        handleChange('tubularSegments', value)
                    }
                    onPChange={value => handleChange('p', value)}
                    onQChange={value => handleChange('q', value)}
                />
            )}

            {entity.type === 'ground' && (
                <GroundEditor
                    width={entity.width}
                    height={entity.height}
                    subdivisions={entity.subdivisions}
                    heightMap={entity.heightMap}
                    onWidthChange={value => handleChange('width', value)}
                    onHeightChange={value => handleChange('height', value)}
                    onSubdivisionsChange={value =>
                        handleChange('subdivisions', value)
                    }
                    onHeightMapChange={value =>
                        handleChange('heightMap', value)
                    }
                />
            )}

            {entity.type === 'lines' && (
                <LinesEditor
                    points={entity.points}
                    colors={entity.colors}
                    onPointsChange={value => handleChange('points', value)}
                    onColorsChange={value => handleChange('colors', value)}
                />
            )}

            {entity.type === 'dashedLines' && (
                <DashedLinesEditor
                    points={entity.points}
                    dashSize={entity.dashSize}
                    gapSize={entity.gapSize}
                    dashNb={entity.dashNb}
                    colors={entity.colors}
                    onPointsChange={value => handleChange('points', value)}
                    onDashSizeChange={value => handleChange('dashSize', value)}
                    onGapSizeChange={value => handleChange('gapSize', value)}
                    onDashNbChange={value => handleChange('dashNb', value)}
                    onColorsChange={value => handleChange('colors', value)}
                />
            )}

            {entity.type === 'extrudeShape' && (
                <ExtrudeShapeEditor
                    shape={entity.shape}
                    path={entity.path}
                    scale={entity.scale}
                    rotation={entity.rotation}
                    cap={entity.cap}
                    onShapeChange={value => handleChange('shape', value)}
                    onPathChange={value => handleChange('path', value)}
                    onScaleChange={value => handleChange('scale', value)}
                    onRotationChange={value => handleChange('rotation', value)}
                    onCapChange={value => handleChange('cap', value)}
                />
            )}

            {entity.type === 'lathe' && (
                <LatheEditor
                    shape={entity.shape}
                    segments={entity.segments}
                    cap={entity.cap}
                    onShapeChange={value => handleChange('shape', value)}
                    onSegmentsChange={value => handleChange('segments', value)}
                    onCapChange={value => handleChange('cap', value)}
                />
            )}

            {/*entity.type === 'text' && (
                <TextEditor
                    text={entity.text}
                    color={entity.color}
                    fontSize={entity.fontSize}
                    onTextChange={value => handleChange('text', value)}
                    onColorChange={value => handleChange('color', value)}
                    onFontSizeChange={value => handleChange('fontSize', value)}
                />
            )*/}

            <div className="form-text text-white">
                NOTE: Some properties will require a{' '}
                <a
                    href="#"
                    onClick={event => (event.preventDefault(), refreshIframe())}
                >
                    scene refresh
                </a>{' '}
                to take effect.
            </div>

            <div className="d-flex justify-content-end">
                <Button
                    color="danger"
                    size="sm"
                    onClick={() =>
                        removeElement(elementId, SceneEvents.EntityDelete)
                    }
                >
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </div>
        </div>
    );
};

export default SceneEntityControl;
