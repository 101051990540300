import React, {useCallback, useState, useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {AiChunk} from '../../api/types';
import {Button, Spinner} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload, faCopy} from '@fortawesome/free-solid-svg-icons';
import useAiHub from './useAiHub';
import CodeBlock from '../shared/CodeBlock';
import ReactDOMServer from 'react-dom/server';

interface AssistantOutputProps {
    id: string;
    title: string;
    onRemove: () => void;
}

const AssistantOutput: React.FC<AssistantOutputProps> = ({
    id,
    title,
    onRemove
}) => {
    const [rawOutput, setRawOutput] = useState('');
    const [output, setOutput] = useState('');
    const [copyState, setCopyState] = useState<{
        copied: boolean;
        text: string;
        className: string;
    }>({
        copied: false,
        text: 'Copy',
        className: ''
    });

    useEffect(() => {
        const processOutput = (text: string) => {
            // Remove standalone ``` or ```markdown lines
            let processed = text.replace(/^```(markdown)?\n/gm, '');
            processed = processed.replace(/\n```\n/g, '\n');

            // Remove any remaining ``` at the start or end of the text
            processed = processed.replace(/^```/, '').replace(/```$/, '');

            return processed.trim();
        };

        setOutput(processOutput(rawOutput));
    }, [rawOutput]);

    const groupName = `Assistant_${id}`;

    const onChunkGenerated = useCallback((aiChunk: AiChunk) => {
        setRawOutput(prevState => prevState + aiChunk.chunk);
    }, []);

    useAiHub({
        groupName,
        onChunkGenerated
    });

    const renderMarkdown = useCallback(
        (
            markdownContent: string,
            context: 'display' | 'clipboard' = 'display'
        ) => (
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    code({node, className, children, ...props}) {
                        const match = /language-(\w+)/.exec(className || '');
                        const language = match ? match[1] : null;
                        if (context === 'clipboard') {
                            return <code>{children}</code>;
                        }
                        return language ? (
                            <CodeBlock
                                language={language}
                                value={String(children).replace(/\n$/, '')}
                                {...props}
                            />
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        );
                    },
                    a({href, children, ...props}) {
                        const isExternal =
                            href &&
                            !href.startsWith('/') &&
                            !href.startsWith('#');
                        if (context === 'clipboard') {
                            return `${children} (${href})`;
                        }
                        return (
                            <a
                                href={href}
                                target={isExternal ? '_blank' : undefined}
                                rel={
                                    isExternal
                                        ? 'noopener noreferrer'
                                        : undefined
                                }
                                {...props}
                            >
                                {children}
                            </a>
                        );
                    }
                }}
            >
                {markdownContent}
            </ReactMarkdown>
        ),
        []
    );

    const copyToClipboard = useCallback(() => {
        const markdownContent = output;
        const htmlContent = ReactDOMServer.renderToString(
            renderMarkdown(markdownContent, 'clipboard')
        );

        if (navigator.clipboard && window.ClipboardItem) {
            const clipboardItem = new ClipboardItem({
                'text/plain': new Blob([markdownContent], {type: 'text/plain'}),
                'text/html': new Blob([htmlContent], {type: 'text/html'})
            });

            navigator.clipboard
                .write([clipboardItem])
                .then(() => {
                    setCopyState({
                        copied: true,
                        text: 'Copied!',
                        className: 'text-success'
                    });
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                    setCopyState({
                        copied: false,
                        text: 'Failed to copy',
                        className: 'text-danger'
                    });
                });
        } else {
            // Fallback for browsers that don't support the Clipboard API
            const textArea = document.createElement('textarea');
            textArea.value = markdownContent;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                setCopyState({
                    copied: true,
                    text: 'Copied!',
                    className: 'text-success'
                });
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
                setCopyState({
                    copied: false,
                    text: 'Failed to copy',
                    className: 'text-danger'
                });
            }
            document.body.removeChild(textArea);
        }
    }, [output, renderMarkdown]);

    useEffect(() => {
        let timer: number;
        if (copyState.copied) {
            timer = setTimeout(() => {
                setCopyState({copied: false, text: 'Copy', className: ''});
            }, 2000);
        }
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [copyState.copied]);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <h4>{title}</h4>
                <Button close onClick={onRemove} aria-label="Close" />
            </div>
            {output === '' && <Spinner type="grow" size="sm" color="primary" />}
            <div style={{whiteSpace: 'pre-wrap'}}>
                {renderMarkdown(output, 'display')}
            </div>
            {output !== '' && (
                <div className="folder-action-icons">
                    <button
                        onClick={copyToClipboard}
                        className={`btn btn-link p-0 mr-2 action-link ${copyState.className}`}
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            textDecoration: 'none'
                        }}
                    >
                        {copyState.text} <FontAwesomeIcon icon={faCopy} />
                    </button>
                    <a
                        href={`data:text/plain;charset=utf-8,${encodeURIComponent(
                            output
                        )}`}
                        download="translation.txt"
                        className="action-link"
                    >
                        <FontAwesomeIcon icon={faDownload} />
                    </a>
                </div>
            )}
        </>
    );
};

export default AssistantOutput;
