import React, {useState} from 'react';
import Draggable from 'react-draggable';
import {
    FormGroup,
    Input,
    Label,
    Button,
    Card,
    CardHeader,
    CardBody,
    Collapse
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faChevronUp,
    faLanguage
} from '@fortawesome/free-solid-svg-icons';
import {useMutation} from 'react-query';
import {toast} from 'react-toastify';
import {v4 as uuidv4} from 'uuid';
import useLocalState from '../../lib/useLocalState';
import languageOptions from '../../lib/languageOptions';
import isNullOrWhiteSpace from '../../lib/isNullOrWhiteSpace';
import unknownErrorToString from '../../lib/unknownErrorToString';
import useTranslate from '../../api/assistant/useTranslate';
import AssistantOutput from '../assistant/AssistantOutput';
import {AssistantOutputIdAndLabel} from '../assistant/types';
import ButtonAsync from '../shared/ButtonAsync';
import {TranslationProjectInfo} from './TranslationContext';
import {ProjectDataIndex, ProjectTabType} from '../../api/types';
import {Link} from 'react-router-dom';

const extractTextFromDOM = (): string => {
    const tabElement = document.querySelector('div.tab-content');
    const mainElement = document.querySelector('main');
    const fullscreenElement = document.getElementById('fullscreen-content');
    const targetElement = tabElement || mainElement || fullscreenElement;

    if (!targetElement) {
        console.error(
            'No tab-content, main, or fullscreen-content element found'
        );
        return '';
    }

    const isElementVisible = (element: Element): boolean => {
        const style = window.getComputedStyle(element);
        return (
            style.display !== 'none' &&
            style.visibility !== 'hidden' &&
            style.opacity !== '0'
        );
    };

    const textNodes: string[] = [];
    const walker = document.createTreeWalker(
        targetElement,
        NodeFilter.SHOW_TEXT,
        {
            acceptNode: node => {
                // Check if the node is a descendant of DraggableTranslationPanel
                let parent = node.parentElement;
                while (parent) {
                    if (parent.id === 'DraggableTranslationPanel') {
                        return NodeFilter.FILTER_REJECT;
                    }
                    // Check if the parent element is visible
                    if (!isElementVisible(parent)) {
                        return NodeFilter.FILTER_REJECT;
                    }
                    parent = parent.parentElement;
                }
                return NodeFilter.FILTER_ACCEPT;
            }
        }
    );

    let node;
    while ((node = walker.nextNode())) {
        const trimmedText = node.textContent?.trim();
        if (trimmedText) {
            textNodes.push(trimmedText);
        }
    }

    return textNodes.join('\n');
};

interface TranslationAdviceProps {
    isOpen: boolean;
    toggle: () => void;
    onAccept: () => void;
    isAccepted: boolean;
}

const TranslationAdvice: React.FC<TranslationAdviceProps> = ({
    isOpen,
    toggle,
    onAccept,
    isAccepted
}) => (
    <div className="mb-3">
        <Button color="link" onClick={toggle} className="p-0">
            <FontAwesomeIcon
                icon={isOpen ? faChevronUp : faChevronDown}
                className="me-2"
            />
            Translation Advice
        </Button>
        <Collapse isOpen={isOpen}>
            <div className="mt-2 p-3 border rounded">
                <p>
                    This translation is powered by AI technology. While we
                    strive for accuracy, please be aware of the following
                    limitations:
                </p>
                <ul>
                    <li>
                        AI translations may contain errors or
                        misinterpretations.
                    </li>
                    <li>
                        The AI model does not account for regional language
                        variations or dialects.
                    </li>
                    <li>
                        Cultural nuances and context-specific meanings may be
                        lost in translation.
                    </li>
                    <li>
                        For languages with multiple variants (e.g., Te Reo
                        Māori), the AI may not accurately represent local or
                        indigenous forms.
                    </li>
                </ul>
                <p>
                    We recognize that language is deeply connected to culture
                    and identity. This AI translation tool is not intended to
                    replace or diminish the importance of indigenous languages
                    or regional variations.
                </p>
                <p>
                    The translated content should be used as a general guide
                    only and not relied upon for critical information. For
                    important communications or to capture the full cultural
                    context, we recommend consulting with fluent speakers or
                    professional translators.
                </p>
                <p>
                    <Link to="/project/language-translation?tab=project">
                        Learn more ...
                    </Link>
                </p>
                {!isAccepted && (
                    <div className="d-flex justify-content-end">
                        <Button
                            color="primary"
                            onClick={onAccept}
                            className="mt-2"
                        >
                            I Understand
                        </Button>
                    </div>
                )}
            </div>
        </Collapse>
    </div>
);

interface DraggableTranslationPanelProps {
    onClose: () => void;
    projectInfo: TranslationProjectInfo | null;
}

const DraggableTranslationPanel: React.FC<DraggableTranslationPanelProps> = ({
    onClose,
    projectInfo
}) => {
    const [adviceAccepted, setAdviceAccepted] = useLocalState<boolean>(
        'translationAdviceAccepted',
        false
    );
    const [showAdvice, setShowAdvice] = useState<boolean>(!adviceAccepted);

    const handleAdviceAccept = (): void => {
        setAdviceAccepted(true);
        setShowAdvice(false);
    };

    const toggleAdvice = (): void => setShowAdvice(!showAdvice);

    const translate = useTranslate();
    const translateMutation = useMutation(translate, {
        onError: (error: unknown) => {
            toast.error('Error. ' + unknownErrorToString(error));
        }
    });

    const [selectedLanguage, setSelectedLanguage] = useLocalState(
        'selectedLanguage',
        ''
    );
    const [otherLanguage, setOtherLanguage] = useLocalState(
        'otherLanguage',
        ''
    );

    const [assistantOutputs, setAssistantOutputs] = useState<
        AssistantOutputIdAndLabel[]
    >([]);

    const handleLanguageChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSelectedLanguage(event.target.value);
    };

    const handleOtherLanguageChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setOtherLanguage(event.target.value);
    };

    const removeOutput = (outputToRemove: AssistantOutputIdAndLabel) => {
        setAssistantOutputs(prevState =>
            prevState.filter(output => output != outputToRemove)
        );
    };

    const handleTranslate = async () => {
        if (selectedLanguage === 'Other' && isNullOrWhiteSpace(otherLanguage)) {
            return;
        }

        const language =
            selectedLanguage === 'Other' ? otherLanguage : selectedLanguage;

        const outputId = uuidv4();
        setAssistantOutputs(prevState => [
            ...prevState,
            {outputId, title: language}
        ]);

        let text = null;
        let mixId = projectInfo?.mixId ?? null;
        let dataIndex = projectInfo?.dataIndex ?? null;
        let contentPackageId = null;

        const isCanvasOrContent =
            projectInfo?.projectTabType == ProjectTabType.Whiteboard ||
            projectInfo?.projectTabType == ProjectTabType.Content;

        const contentPackageElement = document.querySelector(
            'div[data-contentpackageid]'
        );

        if (contentPackageElement) {
            contentPackageId = contentPackageElement.getAttribute(
                'data-contentpackageid'
            );
            dataIndex = null;
            mixId = null;
        } else {
            if (dataIndex == ProjectDataIndex.Zero || !isCanvasOrContent) {
                dataIndex = null;
                mixId = null;
            }

            if (dataIndex === null && mixId === null) {
                text = extractTextFromDOM();
            }
        }

        await translateMutation.mutateAsync({
            outputId,
            language,
            text,
            mixId,
            dataIndex,
            contentPackageId
        });
    };

    const translateDisabled =
        !adviceAccepted ||
        isNullOrWhiteSpace(selectedLanguage) ||
        (selectedLanguage == 'Other' && isNullOrWhiteSpace(otherLanguage));

    return (
        <Draggable handle=".handle">
            <Card
                id="DraggableTranslationPanel"
                style={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    width: '600px',
                    zIndex: 1000
                }}
            >
                <CardHeader
                    className="handle"
                    style={{
                        cursor: 'move',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <div>
                        <FontAwesomeIcon icon={faLanguage} className="me-2" />
                        Translate
                    </div>
                    <Button close onClick={onClose} />
                </CardHeader>
                <CardBody>
                    <TranslationAdvice
                        isOpen={showAdvice}
                        toggle={toggleAdvice}
                        onAccept={handleAdviceAccept}
                        isAccepted={adviceAccepted}
                    />

                    {adviceAccepted && (
                        <>
                            <FormGroup>
                                <Label for="languageSelect">
                                    Select Language
                                </Label>
                                <Input
                                    type="select"
                                    id="languageSelect"
                                    value={selectedLanguage}
                                    onChange={handleLanguageChange}
                                >
                                    <option value="">Select a language</option>
                                    {languageOptions.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                    <option value="Other">Other</option>
                                </Input>
                                {selectedLanguage === 'Other' && (
                                    <Input
                                        type="text"
                                        id="otherLanguage"
                                        placeholder="Enter another language"
                                        value={otherLanguage}
                                        onChange={handleOtherLanguageChange}
                                        className="mt-2"
                                    />
                                )}
                                <div className="d-flex justify-content-end mt-3">
                                    <ButtonAsync
                                        type="button"
                                        color="primary"
                                        onClick={handleTranslate}
                                        disabled={translateDisabled}
                                    >
                                        Translate
                                    </ButtonAsync>
                                </div>
                            </FormGroup>
                            <div className="mt-4">
                                {assistantOutputs.map(output => (
                                    <AssistantOutput
                                        key={output.outputId}
                                        id={output.outputId}
                                        title={output.title}
                                        onRemove={() => removeOutput(output)}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </CardBody>
            </Card>
        </Draggable>
    );
};

export default DraggableTranslationPanel;
