import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {mergeRegister} from '@lexical/utils';
import {
    $insertNodes,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand
} from 'lexical';
import {useEffect} from 'react';

import {$createVideoNode, VideoNode, VideoPayload} from '../nodes/VideoNode';

export type InsertVideoPayload = Readonly<VideoPayload>;

export const INSERT_VIDEO_COMMAND: LexicalCommand<InsertVideoPayload> =
    createCommand('INSERT_VIDEO_COMMAND');

export default function VideoPlugin(): JSX.Element | null {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!editor.hasNodes([VideoNode])) {
            throw new Error('VideoPlugin: VideoNode not registered on editor');
        }

        return mergeRegister(
            editor.registerCommand<InsertVideoPayload>(
                INSERT_VIDEO_COMMAND,
                payload => {
                    const videoNode = $createVideoNode(payload);
                    $insertNodes([videoNode]);

                    return true;
                },
                COMMAND_PRIORITY_EDITOR
            )
        );
    }, [editor]);

    return null;
}
