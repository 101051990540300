import React from 'react';
import {FormGroup, Label, Input} from 'reactstrap';

interface SphereEditorProps {
    diameter: number;
    onChange: (diameter: number) => void;
}

const SphereEditor: React.FC<SphereEditorProps> = ({diameter, onChange}) => {
    const handleDiameterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onChange(isNaN(value) ? 0 : value);
    };

    return (
        <FormGroup>
            <Label>Diameter:</Label>
            <Input
                type="number"
                step="0.1"
                value={diameter}
                onChange={handleDiameterChange}
            />
        </FormGroup>
    );
};

export default SphereEditor;
