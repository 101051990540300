import React, {useState} from 'react';
import useGetTagsForProjects, {
    GetTagsForProjectsQuery
} from '../../api/mixes/useGetTagsForProjects';
import {useQuery} from 'react-query';
import {MatchOperator, Pagination} from '../../api/types';
import Select, {MultiValue} from 'react-select';
import useDebounce from '../../hooks/useDebounce.ts';
import {ButtonGroup} from 'reactstrap';

interface Tag {
    label: string;
    value: string;
}

interface SearchProjectTagsProps {
    selectedTags: string[];
    setSelectedTags: (tags: string[]) => void;
    matchTags: MatchOperator;
    onMatchOperatorSelected: (matchOperator: MatchOperator) => void;
}

const SearchProjectTags: React.FC<SearchProjectTagsProps> = ({
    selectedTags,
    setSelectedTags,
    matchTags,
    onMatchOperatorSelected
}) => {
    const [inputValue, setInputValue] = useState('');
    const pagination: Pagination = {page: 1, perPage: 100};
    const getTagsForProjectsQuery: GetTagsForProjectsQuery = {
        pagination,
        filter: inputValue
    };
    const fetchTags = useGetTagsForProjects(getTagsForProjectsQuery);
    const debouncedInputValue = useDebounce([inputValue], 1000);
    const {data: pagedTags} = useQuery(
        ['tags', debouncedInputValue],
        fetchTags
    );

    const onMatchTagsClick = async (matchOperator: MatchOperator) => {
        onMatchOperatorSelected(matchOperator);
    };

    const handleInputChange = (newValue: string) => {
        setInputValue(newValue);
    };

    const handleChange = (selectedOptions: MultiValue<Tag>) => {
        setSelectedTags(selectedOptions.map(option => option.value));
    };

    const options = pagedTags?.items.map(tag => ({label: tag, value: tag}));

    return (
        <div className="mb-4 d-flex flex-sm-row flex-column align-items-center">
            <div className="search-holder flex-grow-1">
                <div className="basic-search-holder">
                    <Select
                        isMulti
                        options={options}
                        value={selectedTags.map(tag => ({
                            label: tag,
                            value: tag
                        }))}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        placeholder="Search using tags..."
                        className="w-100"
                        classNamePrefix="basic-search"
                    />
                </div>
            </div>
            <ButtonGroup className="mt-4 mt-sm-0 ms-sm-3">
                <button
                    type="button"
                    onClick={() => onMatchTagsClick(MatchOperator.Any)}
                    className={`btn ${
                        matchTags === MatchOperator.Any
                            ? 'btn-primary'
                            : 'btn-secondary'
                    }`}
                >
                    Match any
                </button>
                <button
                    type="button"
                    onClick={() => onMatchTagsClick(MatchOperator.All)}
                    className={`btn ${
                        matchTags === MatchOperator.All
                            ? 'btn-primary'
                            : 'btn-secondary'
                    }`}
                >
                    Match all
                </button>
            </ButtonGroup>
        </div>
    );
};

export default SearchProjectTags;
