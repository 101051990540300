import {ReactStateSetter} from '../../types.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

interface AssetSearchProps {
    searchText: string;
    setSearchText: ReactStateSetter<string>;
}

const AssetSearch: React.FC<AssetSearchProps> = ({
    searchText,
    setSearchText
}) => {
    return (
        <div className="search-holder w-100">
            <div className="basic-search-holder">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    aria-label="Search"
                    value={searchText}
                    onChange={event => setSearchText(event.target.value)}
                />

                <button
                    className="btn action-link asset-search-clear"
                    type="button"
                    id="ClearSearch"
                    onClick={() => setSearchText('')}
                >
                    <FontAwesomeIcon icon={faTimes} aria-hidden="true" />
                    <span className="visually-hidden">Clear</span>
                </button>
            </div>
        </div>
    );
};

export default AssetSearch;
