import React from 'react';
import {FormGroup, Label, Input} from 'reactstrap';

interface PlaneEditorProps {
    width: number;
    height: number;
    onWidthChange: (width: number) => void;
    onHeightChange: (height: number) => void;
}

const PlaneEditor: React.FC<PlaneEditorProps> = ({
    width,
    height,
    onWidthChange,
    onHeightChange
}) => {
    const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onWidthChange(isNaN(value) ? 0 : value);
    };

    const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onHeightChange(isNaN(value) ? 0 : value);
    };

    return (
        <>
            <FormGroup>
                <Label>Width:</Label>
                <Input
                    type="number"
                    step="0.1"
                    value={width}
                    onChange={handleWidthChange}
                />
            </FormGroup>
            <FormGroup>
                <Label>Height:</Label>
                <Input
                    type="number"
                    step="0.1"
                    value={height}
                    onChange={handleHeightChange}
                />
            </FormGroup>
        </>
    );
};

export default PlaneEditor;
