import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type UnreadNotificationsCountQuery = NonNullable<unknown>;

const useUnreadNotificationsCount = (request: UnreadNotificationsCountQuery) =>
    useGet<number>(
        `/api/notifications/unread-notifications-count?${toQuery(request)}`,
        {
            errorMessage:
                'GET api/notifications/unread-notifications-count failed'
        }
    );

export default useUnreadNotificationsCount;
