import AssetSelectAndUpload from '../assets/AssetSelectAndUpload.tsx';
import {MergeData} from '../../types.ts';
import isNullOrWhiteSpace from '../../lib/isNullOrWhiteSpace.ts';
import {FileFormat} from '../../lib/FileFormats.ts';
import {
    ContentSectionModel,
    FileDataModel,
    Selection,
    ViewMode
} from '../../api/types.ts';
import useSessionState from '../../lib/useSessionState.ts';

interface PictureSectionProps {
    elementId: string;
    section: ContentSectionModel;
    readOnly: boolean;
    mergeData: MergeData;
    packageContainerName?: string;
}

const PictureSection: React.FC<PictureSectionProps> = ({
    elementId,
    section,
    readOnly,
    mergeData,
    packageContainerName
}) => {
    const [viewMode] = useSessionState(`ViewMode_${elementId}`, ViewMode.View);

    const onFileSelectionChanged = (files: FileDataModel[]) => {
        if (files.length > 0) {
            mergeData(elementId, {fileName: files[0].uri});
        }
    };

    if (!readOnly && viewMode == ViewMode.Edit) {
        return (
            <>
                <div className="mb-3 form-group">
                    <label>Title</label>
                    <span>&nbsp;</span>
                    <input
                        className="form-control"
                        value={section.altText ?? ''}
                        onChange={event =>
                            mergeData(elementId, {altText: event.target.value})
                        }
                        maxLength={2048}
                    />
                </div>
                <div className="mb-3 form-group">
                    <label>Picture source</label>
                    <span>&nbsp;</span>
                    <input
                        className="form-control"
                        defaultValue={section.fileName ?? ''}
                        readOnly
                        disabled
                    />
                </div>
                <AssetSelectAndUpload
                    selection={Selection.Single}
                    explanation="Please select a picture file"
                    formats={FileFormat.Image}
                    fileSelectionChanged={files =>
                        onFileSelectionChanged(files)
                    }
                    specificContainerName={packageContainerName}
                />
            </>
        );
    }

    if (!isNullOrWhiteSpace(section.fileName)) {
        return (
            <img
                src={section.fileName ?? ''}
                alt={section.altText ?? undefined}
                className="img-fluid"
            />
        );
    }

    return null;
};

export default PictureSection;
