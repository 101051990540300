import React, {useEffect, useState} from 'react';
import atify from '../../lib/atify.ts';
import {isNotNullOrWhiteSpace} from '../../lib/isNullOrWhiteSpace.ts';
import emojisByEmojiCode from '../../emojis/emojisByEmojiCode.ts';
import {PostReactionModel} from '../../api/types.ts';
import Constants from '../../lib/Constants.ts';

interface ReactionCardProps {
    reaction: PostReactionModel;
}

const ReactionCard: React.FC<ReactionCardProps> = ({reaction}) => {
    const [emojiSource, setEmojiSource] = useState<string>(
        Constants.EmptyThumbnailDataURL
    );

    useEffect(() => {
        if (reaction.reaction in emojisByEmojiCode) {
            setEmojiSource(emojisByEmojiCode[reaction.reaction].source);
        }
    }, [reaction.reaction]);

    const userHasName = () => {
        return isNotNullOrWhiteSpace(reaction.userName);
    };

    return (
        <a className="notification-card reaction-card">
            <div className="notification-img-holder">
                <img src={reaction.userThumbnail} crossOrigin="anonymous" />
                <span className="notification-type">
                    <img className="emoji" src={emojiSource} />
                </span>
            </div>
            <div className="notification-info fw-bold">
                {userHasName() ? reaction.userName : reaction.userNickname}
                <br />
                <small className="fw-normal">
                    {atify(reaction.userNickname)}
                </small>
            </div>
        </a>
    );
};

export default ReactionCard;
