import isNullOrWhiteSpace from '../../lib/isNullOrWhiteSpace.ts';
import {isUrl} from '../../lib/urls.ts';
import CopyButton from '../shared/CopyButton.tsx';
import {MergeData} from '../../types.ts';
import {ContentSectionModel, ViewMode} from '../../api/types.ts';
import useSessionState from '../../lib/useSessionState.ts';

interface CopySectionProps {
    elementId: string;
    section: ContentSectionModel;
    readOnly: boolean;
    mergeData: MergeData;
}

const CopySection: React.FC<CopySectionProps> = ({
    elementId,
    section,
    readOnly,
    mergeData
}) => {
    const [viewMode] = useSessionState(`ViewMode_${elementId}`, ViewMode.View);

    if (!readOnly && viewMode == ViewMode.Edit) {
        return (
            <div className="mb-3 form-group">
                <label>Text to copy</label>
                <span>&nbsp;</span>
                <input
                    className="form-control"
                    value={section.content ?? ''}
                    onChange={event =>
                        mergeData(elementId, {content: event.target.value})
                    }
                    maxLength={2048}
                />
                <div className="form-text text-muted">
                    If the text is a URL it will be linkified.
                </div>
            </div>
        );
    }

    if (section.content && !isNullOrWhiteSpace(section.content)) {
        if (isUrl(section.content)) {
            return (
                <div className="copy-link-container cropped-link mb-2 mt-2">
                    <a
                        href={section.content}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {section.content}
                    </a>
                    <CopyButton
                        id={`Copy_${elementId}`}
                        cssClass="action-link"
                        copyText={section.content}
                        title="Copy"
                    />
                </div>
            );
        }

        return (
            <div className="copy-link-container mb-2 mt-2">
                <input
                    type="text"
                    className="form-control"
                    defaultValue={section.content}
                    readOnly
                />
                <CopyButton
                    id={`Copy_${elementId}`}
                    cssClass="action-link"
                    copyText={section.content}
                    title="Copy"
                />
            </div>
        );
    }

    return null;
};

export default CopySection;
