import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

interface EmbeddingInstructionsModalProps {
    onClose: () => void;
}

export const EmbeddingInstructionsModal: React.FC<
    EmbeddingInstructionsModalProps
> = ({onClose}) => {
    return (
        <Modal isOpen toggle={onClose} fullscreen="md">
            <ModalBody>
                <EmbeddingInstructions />
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClose}
                >
                    Close
                </button>
            </ModalFooter>
        </Modal>
    );
};

const EmbeddingInstructions: React.FC = () => {
    return (
        <div className="instructions">
            <h3>Embedding a Website:</h3>
            <ol>
                <li>
                    Copy the full URL (web address) of the website you want to
                    embed.
                </li>
                <li>Paste the URL into the provided field.</li>
            </ol>

            <div className="note">
                <p>Please note:</p>
                <ul>
                    <li>
                        Make sure to include "https://" or "http://" at the
                        beginning of the URL.
                    </li>
                    <li>
                        Some websites may not allow embedding due to their
                        security settings or policies. If embedding fails, it
                        usually means the website has restricted embedding.
                    </li>
                    <li>
                        If embedding is not allowed for a particular website,
                        you may consider providing a direct link to the website
                        instead.
                    </li>
                </ul>
            </div>

            <p>Examples of websites that allow embedding:</p>
            <ul>
                <li>
                    <a
                        href="https://www.youtube.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        YouTube videos
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.google.com/maps"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Google Maps
                    </a>
                </li>
                <li>
                    <a
                        href="https://en.wikipedia.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Wikipedia articles
                    </a>
                </li>
                <li>
                    <a
                        href="https://make.gamefroot.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Gamefroot games
                    </a>
                </li>
                <li>
                    <a
                        href="https://scratch.mit.edu/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Scratch projects
                    </a>
                </li>
                <li>
                    <a
                        href="https://replit.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Replit projects
                    </a>
                </li>
                <li>
                    <a
                        href="https://codepen.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        CodePen projects
                    </a>
                </li>
                <li>
                    <a
                        href="https://jsfiddle.net/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        JSFiddle code snippets
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.khanacademy.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Khan Academy coding projects
                    </a>
                </li>
                <li>
                    Some{' '}
                    <a
                        href="https://code.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Code.org projects and tutorials
                    </a>
                </li>
                <li>
                    <a
                        href="https://glitch.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Glitch projects
                    </a>
                </li>
                <li>... and many news articles and blog posts</li>
            </ul>
        </div>
    );
};

export default EmbeddingInstructions;
