export type EmojisByEmojiCode = Record<string, Emoji>;

export interface Emojis {
    groups: EmojiGroup[];
}

export interface EmojiGroup {
    name: string;
    subGroups: EmojiSubGroup[];
}

export interface EmojiSubGroup {
    name: string;
    emojis: Emoji[];
}

export interface Emoji {
    emojiCode: string;
    alt: string;
    name: string;
    source: string;
    components: string[];
    variants: Emoji[];
    type: EmojiType;
}

export enum EmojiType {
    Ascii,
    ShortNames,
    Unicode
}
