﻿import React, {useEffect, useMemo, useRef, useState} from 'react';
import {fabric} from 'fabric';
import AssetSelectAndUploadModal, {
    AssetSelectAndUploadModalOptions
} from '../assets/AssetSelectAndUploadModal.tsx';
import {FileFormat} from '../../lib/FileFormats.ts';
import useProjectData, {
    ReactionEvent
} from '../../project_data/useProjectData.ts';
import ReconnectionModal from '../../project_data/ReconnectionModal.tsx';
import {ObjectTypes, CustomCanvas} from './types.ts';
import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import MainToolbar from './MainToolbar.tsx';
import TextToolbar from './TextToolbar.tsx';
import ReactionToolbar from './ReactionToolbar.tsx';
import EditToolbar from './EditToolbar.tsx';
import VideoToolbar from './VideoToolbar.tsx';
import CanvasController from './CanvasController.ts';
import createObservableClass from '../../lib/createObservableClass.ts';
import useClientConfig from '../../hooks/useClientConfig.tsx';
import {
    FileDataModel,
    MixModel,
    Selection,
    ProjectDataIndex
} from '../../api/types.ts';
import {useEffectOnce} from '../../hooks/useEffectOnce.ts';
import {useNavigate} from 'react-router-dom';
import PencilToolbar from './PencilToolbar.tsx';
import EmojiPickerModal from '../../emojis/EmojiPickerModal.tsx';
import {Emoji} from '../../emojis/types.ts';
import emojisByEmojiCode from '../../emojis/emojisByEmojiCode.ts';
import {handleReaction} from './reactions.ts';

interface CanvasComponentProps {
    project: MixModel;
    dataIndex: ProjectDataIndex;
    readOnly: boolean;
    reactionsEnabled: boolean;
}

const CanvasComponent: React.FC<CanvasComponentProps> = ({
    project,
    dataIndex,
    readOnly,
    reactionsEnabled
}) => {
    const navigate = useNavigate();
    const {id} = project;
    const projectId = id!;

    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const emojiContainerRef = useRef<HTMLDivElement | null>(null);

    const {authData} = useAuthStateManager();
    const {storageUri} = useClientConfig();

    const playerTimersRef = useRef<{
        [playerId: string]: ReturnType<typeof setTimeout>;
    }>({});

    const openAssetSelectAndUploadModal = (
        fileFormat: FileFormat,
        assetExplanation: string,
        onFileSelected: (file: FileDataModel | null) => void
    ) => {
        const onFileSelectionChanged = (files: FileDataModel[] | undefined) => {
            if (!files) {
                return;
            }

            if (files.length > 0) {
                onFileSelected(files[0]);
            } else {
                onFileSelected(null);
            }

            closeAssetSelectAndUploadModal();
        };

        setAssetSelectAndUploadModalOptions({
            selection: Selection.Single,
            explanation: assetExplanation,
            formats: fileFormat,
            fileSelectionChanged: onFileSelectionChanged
        });
    };

    const [renderToken, setRenderToken] = useState(0);
    const [showReactions, setShowReactions] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState(
        emojisByEmojiCode['grinning_face']
    );

    const stateHasChanged = () =>
        setRenderToken(prevDummy => (prevDummy === 1000 ? 0 : prevDummy + 1));

    const controller = useMemo(
        () =>
            createObservableClass(
                new CanvasController(
                    readOnly,
                    authData,
                    playerTimersRef,
                    storageUri,
                    openAssetSelectAndUploadModal,
                    () => stateHasChanged(),
                    navigate
                ),
                () => {
                    // Force a re-render by updating the dummy state
                    stateHasChanged();
                },
                [
                    'videoElements',
                    'activeObjectType',
                    'currentColor',
                    'fontSettings',
                    'players',
                    'movingCanvas',
                    'canvasTransform',
                    'isDrawing',
                    'isSelectActive',
                    'isReadOnly'
                ]
            ),
        []
    );

    useEffect(() => {
        controller.setIsReadOnly(readOnly);
    }, [readOnly]);

    const {
        projectData,
        manipulation,
        playerEvent,
        reactionEvent,
        isLoading,
        isError,
        showReconnectionModal,
        closeReconnectionModal,
        reinitialize
    } = useProjectData({
        projectId,
        dataIndex,
        onRemoteManipulation: {
            mergeData: (elementId, data, meta) =>
                controller.onRemoteMergeData(elementId, data, meta),
            removeElement: (elementId, meta) =>
                controller.onRemoteRemoveElement(elementId, meta)
        },
        onInitialDataFetched: initialData =>
            controller.onInitialDataFetched(initialData),
        onRemotePlayerEvent: playerEvent =>
            controller.onRemotePlayerEvent(playerEvent),
        onRemoteReactionEvent: reactionEvent =>
            onRemoteReactionEvent(reactionEvent)
    });

    const projectDataRef = useRef(projectData);

    useEffect(() => {
        projectDataRef.current = projectData;
    }, [projectData]);

    useEffect(() => {
        if (!controller.initialized && canvasRef.current) {
            const canvas = new fabric.Canvas(canvasRef.current) as CustomCanvas;

            controller.initialize(
                canvas,
                () => projectDataRef.current,
                manipulation,
                playerEvent
            );
        }
    }, [canvasRef.current, controller.initialized]);

    useEffectOnce(() => {
        return () => {
            controller.dispose();
        };
    });

    useEffect(() => {
        const handleDeleteOrBackspace = (event: KeyboardEvent) => {
            if (event.key === 'Delete' || event.key === 'Backspace') {
                controller.onDelete();
            }
        };

        document.addEventListener('keydown', handleDeleteOrBackspace);

        return () => {
            document.removeEventListener('keydown', handleDeleteOrBackspace);
        };
    }, []);

    const [
        assetSelectAndUploadModalOptions,
        setAssetSelectAndUploadModalOptions
    ] = useState<AssetSelectAndUploadModalOptions | null>(null);

    const [isReaction, setIsReaction] = useState<boolean>(false);

    const closeAssetSelectAndUploadModal = () => {
        setAssetSelectAndUploadModalOptions(null);
    };

    const controlsDisabled = isLoading || controller.isReadOnly;

    if (isError) {
        return <>Error loading project data</>;
    }

    const enableReaction = () => {
        if (isReaction) {
            setIsReaction(false);
        } else {
            setIsReaction(true);
        }
    };

    const onRemoteReactionEvent = (reactionEvent: ReactionEvent) => {
        if (!canvasRef.current || !emojiContainerRef.current) {
            return;
        }

        const emojiContainer = emojiContainerRef.current;

        handleReaction(reactionEvent, emojiContainer);
    };

    const triggerReactionEvent = ({
        animationType,
        message,
        emoji
    }: {
        animationType: 'float' | 'drop' | 'fly';
        message: string | null;
        emoji: string | null;
    }) => {
        if (!canvasRef.current || !emojiContainerRef.current) {
            return;
        }

        const emojiContainer = emojiContainerRef.current;
        const canvasWidth = emojiContainer.offsetWidth;
        const canvasHeight = emojiContainer.offsetHeight;

        switch (animationType) {
            case 'drop':
                {
                    const reaction: ReactionEvent = {
                        animationType,
                        message,
                        emoji,
                        color: message == null ? null : controller.currentColor,
                        top: null,
                        left: Math.random() * (canvasWidth - 30)
                    };

                    handleReaction(reaction, emojiContainer);

                    reactionEvent(reaction);
                }

                break;
            case 'float':
                {
                    const reaction: ReactionEvent = {
                        animationType,
                        message,
                        emoji,
                        color: message == null ? null : controller.currentColor,
                        top: null,
                        left: Math.random() * canvasWidth
                    };

                    handleReaction(reaction, emojiContainer);

                    reactionEvent(reaction);
                }

                break;
            case 'fly':
                {
                    const reaction: ReactionEvent = {
                        animationType,
                        message,
                        emoji,
                        color: message == null ? null : controller.currentColor,
                        top: Math.random() * (canvasHeight - 100),
                        left: null
                    };

                    handleReaction(reaction, emojiContainer);

                    reactionEvent(reaction);
                }

                break;
        }
    };

    const onReactAsync = async (emoji: Emoji) => {
        setSelectedEmoji(emoji);
        setShowReactions(false);
    };

    return (
        <>
            {showReconnectionModal && !readOnly && (
                <ReconnectionModal
                    reinitialize={reinitialize}
                    onClose={closeReconnectionModal}
                />
            )}

            {assetSelectAndUploadModalOptions && (
                <AssetSelectAndUploadModal
                    options={assetSelectAndUploadModalOptions}
                    onClose={closeAssetSelectAndUploadModal}
                />
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <div
                    id="CanvasContainer"
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%'
                    }}
                >
                    {showReactions && (
                        <EmojiPickerModal
                            onClose={() => setShowReactions(false)}
                            onSelect={onReactAsync}
                        />
                    )}

                    <div>
                        <canvas
                            ref={canvasRef}
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                        />

                        <div
                            ref={emojiContainerRef}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '93%',
                                pointerEvents: 'none'
                            }}
                        />
                    </div>
                    <div
                        id="mainToolbar"
                        style={{
                            position: 'absolute',
                            bottom: '20px',
                            left: '50%',
                            transform: 'translateX(-50%)'
                        }}
                    >
                        <MainToolbar
                            isReaction={isReaction}
                            controlsDisabled={controlsDisabled}
                            setIsReaction={enableReaction}
                            controller={controller}
                            readOnly={readOnly}
                            renderToken={renderToken}
                            reactionsEnabled={reactionsEnabled}
                            mixId={projectId}
                        />
                    </div>
                    {controller.showPencilToolbar && (
                        <div
                            id="penciltoolbar"
                            style={{
                                position: 'absolute',
                                bottom: '50px',
                                margin: '10px',
                                padding: '10px',
                                left: '57%',
                                transform: 'translateX(-50%)',
                                height: '100px',
                                width: '320px',
                                backgroundColor: '#F0F0F0',
                                color: '#da8495',
                                borderRadius: '10px'
                            }}
                        >
                            <PencilToolbar controller={controller} />
                        </div>
                    )}

                    {isReaction && (
                        <div
                            id="mainToolbar"
                            style={{
                                position: 'absolute',
                                bottom: '60px',
                                left: '50%',
                                transform: 'translateX(-50%)'
                            }}
                        >
                            <ReactionToolbar
                                openReactionModal={() => setShowReactions(true)}
                                triggerReactionEvent={triggerReactionEvent}
                                emoji={selectedEmoji}
                            />
                        </div>
                    )}

                    {controller.activeObjectType !== null &&
                        controller.activeObjectType !== ObjectTypes.Text &&
                        !controller.isReadOnly &&
                        !controller.movingCanvas &&
                        !controller.isDrawing && (
                            <div
                                id="editToolbar"
                                style={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '50%',
                                    transform: 'translateX(-50%)'
                                }}
                            >
                                <EditToolbar
                                    onRemoveSelected={controller.removeSelected}
                                    onDuplicate={controller.duplicateObject}
                                    onBringForward={controller.bringForward}
                                    onBringBackwards={controller.bringBackwards}
                                    onToFront={controller.bringToFront}
                                    onSendToBack={controller.sendToBack}
                                    onGroupItems={controller.groupItems}
                                    onUngroupItems={controller.ungroupItems}
                                    controlsDisabled={controlsDisabled}
                                    activeSelection={
                                        controller.activeObjectType
                                    }
                                    activeObjectMeta={
                                        controller.activeObjectMeta
                                    }
                                />
                            </div>
                        )}

                    {controller.activeObjectType == ObjectTypes.Text &&
                        !controller.isReadOnly &&
                        !controller.movingCanvas &&
                        !controller.isDrawing && (
                            <>
                                <div
                                    id="editToolbar"
                                    style={{
                                        position: 'absolute',
                                        top: '20px',
                                        left: '50%',
                                        transform: 'translateX(-115%)'
                                    }}
                                >
                                    <EditToolbar
                                        onRemoveSelected={
                                            controller.removeSelected
                                        }
                                        onDuplicate={controller.duplicateObject}
                                        onBringForward={controller.bringForward}
                                        onBringBackwards={
                                            controller.bringBackwards
                                        }
                                        onToFront={controller.bringToFront}
                                        onSendToBack={controller.sendToBack}
                                        onGroupItems={controller.groupItems}
                                        onUngroupItems={controller.ungroupItems}
                                        controlsDisabled={controlsDisabled}
                                        activeSelection={
                                            controller.activeObjectType
                                        }
                                        activeObjectMeta={
                                            controller.activeObjectMeta
                                        }
                                    />
                                </div>
                                <div
                                    id="TextToolbar"
                                    style={{
                                        position: 'absolute',
                                        top: '20px',
                                        left: '50%',
                                        transform: 'translateX(5%)'
                                    }}
                                >
                                    <TextToolbar
                                        controlsDisabled={controlsDisabled}
                                        controller={controller}
                                    />
                                </div>
                            </>
                        )}

                    {controller.activeObjectType == ObjectTypes.Video &&
                        !controller.isReadOnly &&
                        !controller.movingCanvas && (
                            <>
                                <div
                                    id="TextToolbar"
                                    style={{
                                        position: 'absolute',
                                        top: '20px',
                                        left: '20px',
                                        transform: 'translateX(5%)'
                                    }}
                                >
                                    <VideoToolbar
                                        controlsDisabled={controlsDisabled}
                                        pauseSelectedVideo={
                                            controller.pauseSelectedVideo
                                        }
                                        playSelectedVideo={
                                            controller.playSelectedVideo
                                        }
                                    />
                                </div>
                            </>
                        )}
                </div>

                {/*
                {controller.getSelections().map((selection, index) => (
                    <div
                        key={index}
                        style={{border: '1px solid purple'}}
                        className="mt-1 p-2"
                    >
                        SELECTION{' '}
                        <pre>{JSON.stringify(selection, null, 2)}</pre>
                    </div>
                ))}

                <br />

                {controller
                    .getObjects()
                    .filter(obj => obj.type != 'sprite')
                    .map((obj, index) => {
                        const object = obj as CustomFabricObject;
                        return (
                            <div
                                key={index}
                                style={{border: '1px solid #DDD'}}
                                className="mt-1 p-2"
                            >
                                <pre>
                                    {JSON.stringify(
                                        {
                                            ...obj.toObject(),
                                            elementId: object.elementId,
                                            ordinalPosition:
                                                object.ordinalPosition,
                                            meta: object.meta,
                                            nav: object.nav
                                        },
                                        null,
                                        2
                                    )}
                                </pre>
                            </div>
                        );
                    })}
                */}
            </div>
        </>
    );
};

export default CanvasComponent;
