import React, {useState, useEffect} from 'react';
import {useParams, useLocation, useNavigate} from 'react-router-dom';
import {useQuery} from 'react-query';
import useGetChannelNames, {
    GetChannelNamesQuery
} from '../../api/collections/useGetChannelNames';
import useGetCollectionInfo, {
    GetCollectionInfoQuery
} from '../../api/collections/useGetCollectionInfo';
import {useAuthStateManager} from '../../hooks/useAuthStateManager';
import {
    CollectionInfoModel,
    CollectionType,
    UserCollectionType,
    MembershipStatus
} from '../../api/types';
import GroupProjectsTab from './GroupProjectsTab';
import GroupActivityTab from './GroupActivityTab';
import GroupAssetsTab from './GroupAssetsTab';
import GroupMembersTab from './GroupMembersTab';
import GroupSettingsTab from './GroupSettingsTab';
import GroupPostsTab from './GroupPostsTab';
import {ActiveTabState, TabState} from '../shared/tabs/types.ts';
import {getNavTabsState} from '../shared/tabs/tabs.ts';
import RoleNames from '../../lib/RoleNames.ts';
import SpinnerIfLoading from '../shared/SpinnerIfLoading.tsx';
import NavTabs from '../shared/tabs/NavTabs.tsx';
import Tab from '../shared/tabs/Tab.tsx';
import NavTabsLinks from '../shared/tabs/NavTabsLinks.tsx';
import GroupHomeProjectTab from './GroupHomeProjectTab.tsx';
import TopBar from '../shared/top_bar/TopBar.tsx';
import GroupMembershipTab from './GroupMembershipTab.tsx';

export enum GroupPageTabNames {
    Projects = 'Projects',
    Membership = 'Membership',
    Activity = 'Activity',
    Assets = 'Assets',
    Members = 'Members',
    Settings = 'Settings',
    Posts = 'Posts'
}

const GroupPage: React.FC = () => {
    const navigate = useNavigate();
    const {containerName = ''} = useParams<{containerName: string}>();
    const location = useLocation();
    const {loggedInUserId, isInRole} = useAuthStateManager();
    const [collectionInfo, setCollectionInfo] =
        useState<CollectionInfoModel | null>(null);
    const [channelNames, setChannelNames] = useState<string[] | null>(null);

    const userIsAdmin = isInRole(RoleNames.Admin);

    const thumbnailUrl =
        collectionInfo?.thumbnail === ''
            ? '/images/empty-thumbnail.png'
            : collectionInfo?.thumbnail ?? '';

    const getChannelNamesQuery: GetChannelNamesQuery = {
        collectionId: null,
        containerName
    };

    const fetchChannelNames = useGetChannelNames(getChannelNamesQuery);
    const {data: fetchedChannelNames, refetch: refetchChannelNames} = useQuery(
        ['channel-names', containerName],
        fetchChannelNames,
        {
            enabled: !!loggedInUserId
        }
    );

    useEffect(() => {
        if (fetchedChannelNames) {
            setChannelNames(fetchedChannelNames);
        }
    }, [fetchedChannelNames]);

    const getCollectionInfoQuery: GetCollectionInfoQuery = {
        collectionId: null,
        containerName
    };

    const fetchCollectionInfo = useGetCollectionInfo(getCollectionInfoQuery);
    const {data: fetchedCollectionInfo, refetch: refetchCollectionInfo} =
        useQuery(['collection-info', containerName], fetchCollectionInfo);

    const [selectedChannelName, setSelectedChannelName] = useState(
        new URLSearchParams(location.search).get('ch') ?? ''
    );

    const homeMixTab: TabState = {
        name: 'home',
        text: 'Home',
        available: !!collectionInfo?.homeMixId,
        iconClass: 'fas fa-home'
    };

    const initialTabName = new URLSearchParams(location.search).get('tab');

    const [activeTabState, setActiveTabState] = useState<ActiveTabState>({
        activeTabName: initialTabName ?? GroupPageTabNames.Projects,
        selectedOption:
            initialTabName === GroupPageTabNames.Posts
                ? selectedChannelName
                : null
    });

    useEffect(() => {
        if (fetchedCollectionInfo) {
            setCollectionInfo(fetchedCollectionInfo);
            if (!initialTabName && !!fetchedCollectionInfo?.homeMixId) {
                setActiveTabState({activeTabName: homeMixTab.name});
            }
        }
    }, [fetchedCollectionInfo]);

    const canViewActivity = () => {
        return (
            userIsAdmin ||
            collectionInfo?.collectionType === CollectionType.User
        );
    };

    const canViewUploadsTab = () => {
        if (!collectionInfo) {
            return false;
        }

        if (collectionInfo.collectionType === CollectionType.User) {
            return false;
        }

        return (
            userIsAdmin ||
            (collectionInfo.loggedInUserUserCollectionType !==
                UserCollectionType.Viewer &&
                collectionInfo.loggedInUserMembershipStatus ===
                    MembershipStatus.Member)
        );
    };

    const canViewMembersTab = () => {
        if (!collectionInfo) {
            return false;
        }

        if (collectionInfo.collectionType === CollectionType.User) {
            return false;
        }

        return (
            userIsAdmin ||
            (collectionInfo.loggedInUserUserCollectionType !==
                UserCollectionType.Viewer &&
                collectionInfo.loggedInUserMembershipStatus ===
                    MembershipStatus.Member)
        );
    };

    const canEditMembers = () => {
        if (!collectionInfo) {
            return false;
        }

        if (collectionInfo.collectionType === CollectionType.User) {
            return false;
        }

        return (
            userIsAdmin ||
            (collectionInfo.loggedInUserUserCollectionType ===
                UserCollectionType.Admin &&
                collectionInfo.loggedInUserMembershipStatus ===
                    MembershipStatus.Member)
        );
    };

    const canViewSettings = () => {
        if (!collectionInfo) {
            return false;
        }

        if (
            collectionInfo.collectionType === CollectionType.User &&
            collectionInfo.loggedInUserUserCollectionType ===
                UserCollectionType.Admin &&
            collectionInfo.loggedInUserMembershipStatus ===
                MembershipStatus.Member
        ) {
            return true;
        }

        return (
            userIsAdmin ||
            (collectionInfo.loggedInUserUserCollectionType ===
                UserCollectionType.Admin &&
                collectionInfo.loggedInUserMembershipStatus ===
                    MembershipStatus.Member)
        );
    };

    const canViewPosts = () => {
        if (!collectionInfo || !loggedInUserId) {
            return false;
        }

        if (collectionInfo.collectionType !== CollectionType.Standard) {
            return false;
        }

        return (
            userIsAdmin ||
            (collectionInfo.loggedInUserUserCollectionType !==
                UserCollectionType.Viewer &&
                collectionInfo.loggedInUserMembershipStatus ===
                    MembershipStatus.Member)
        );
    };

    const canViewMembership =
        loggedInUserId === null ||
        (!!collectionInfo &&
            !!loggedInUserId &&
            (collectionInfo.loggedInUserMembershipStatus ===
                MembershipStatus.Invited ||
                collectionInfo.loggedInUserMembershipStatus === null));

    const handleChannelSelected = (selectedChannel: string) => {
        const channelNameWithoutHash = selectedChannel.replace('#', '');
        setSelectedChannelName(channelNameWithoutHash);
        const url = `/explore/groups/${containerName}?tab=${GroupPageTabNames.Posts}&ch=${channelNameWithoutHash}`;
        navigate(url);
    };

    useEffect(() => {
        if (channelNames && selectedChannelName === '') {
            const ch =
                (channelNames.includes('#' + channelName)
                    ? '#' + channelName
                    : channelNames[0]) ?? '';

            setSelectedChannelName(ch);
        }
    }, [channelNames]);

    const postsTab: TabState = {
        name: GroupPageTabNames.Posts,
        text: 'Posts',
        available: canViewPosts(),
        options: channelNames ?? [],
        onOptionSelected: handleChannelSelected,
        iconClass: 'fas fa-comment-dots',
        msAuto: true,
        selectedOption: selectedChannelName
    };

    const tabs: TabState[] = [
        homeMixTab,
        {
            name: GroupPageTabNames.Projects,
            text: 'Projects',
            iconClass: 'fas fa-paint-brush',
            available: true
        },
        {
            name: GroupPageTabNames.Membership,
            text: 'Membership',
            iconClass: 'fas fa-user',
            available: canViewMembership
        },
        {
            name: GroupPageTabNames.Activity,
            text: 'Activity',
            available: canViewActivity(),
            iconClass: 'fas fa-calendar'
        },
        {
            name: GroupPageTabNames.Assets,
            text: 'Assets',
            available: canViewUploadsTab(),
            iconClass: 'fas fa-cloud'
        },
        {
            name: GroupPageTabNames.Members,
            text: 'Members',
            available: canViewMembersTab(),
            iconClass: 'fas fa-user-friends'
        },
        {
            name: GroupPageTabNames.Settings,
            text: 'Settings',
            available: canViewSettings(),
            iconClass: 'fas fa-cog'
        },
        postsTab
    ];

    const navTabsState = getNavTabsState(tabs, activeTabState);
    const channelName = postsTab.selectedOption;

    const handleCollectionUpdated = async () => {
        await refetchCollectionInfo();
        await refetchChannelNames();
    };

    const handleChannelUpdated = async () => {
        await refetchChannelNames();
    };

    const onMembershipChanged = async () => {
        await refetchCollectionInfo();
        setActiveTabState({activeTabName: GroupPageTabNames.Projects});
    };

    const getGroupType = () => {
        switch (collectionInfo?.collectionType) {
            case CollectionType.Public:
                return 'Public';
            case CollectionType.Curated:
                return 'Curated';
            case CollectionType.User:
                return 'User';
            default:
                return 'Members only group';
        }
    };

    const name = (
        <>
            <div className="user-image-holder">
                <img
                    src={thumbnailUrl}
                    crossOrigin="anonymous"
                    alt={collectionInfo?.name}
                    className="group-card-avatar"
                />
            </div>
            <h6 className="credit-name m-0 ms-2">
                <strong>{collectionInfo?.name}</strong>
            </h6>
            <span className="vertical-separator mx-2"></span>
            {getGroupType()}
            <span className="vertical-separator mx-2"></span> Projects{' '}
            <span className="counter-value mx-1 badge bg-secondary">
                {collectionInfo?.mixesCount}
            </span>
            <span className="vertical-separator mx-2"></span> Members{' '}
            <span className="counter-value mx-1 badge bg-secondary">
                {collectionInfo?.memberCount}
            </span>
        </>
    );

    return (
        <>
            <TopBar to="/explore/groups" name={name} />

            <SpinnerIfLoading loading={!collectionInfo}>
                {navTabsState && collectionInfo && (
                    <>
                        <div className="project-actions">
                            <NavTabsLinks
                                navTabsState={navTabsState}
                                setActiveTabState={setActiveTabState}
                            />
                        </div>
                        <NavTabs navTabsState={navTabsState}>
                            <Tab name={'home'} navTabsState={navTabsState}>
                                {collectionInfo.homeMixId && (
                                    <GroupHomeProjectTab
                                        homeMixId={collectionInfo.homeMixId}
                                    />
                                )}
                            </Tab>
                            <Tab
                                name={GroupPageTabNames.Projects}
                                navTabsState={navTabsState}
                            >
                                <GroupProjectsTab collection={collectionInfo} />
                            </Tab>
                            <Tab
                                name={GroupPageTabNames.Membership}
                                navTabsState={navTabsState}
                            >
                                <GroupMembershipTab
                                    collection={collectionInfo}
                                    onMembershipChanged={onMembershipChanged}
                                />
                            </Tab>
                            <Tab
                                name={GroupPageTabNames.Activity}
                                navTabsState={navTabsState}
                            >
                                <GroupActivityTab collection={collectionInfo} />
                            </Tab>
                            <Tab
                                name={GroupPageTabNames.Assets}
                                navTabsState={navTabsState}
                            >
                                <GroupAssetsTab collection={collectionInfo} />
                            </Tab>
                            <Tab
                                name={GroupPageTabNames.Members}
                                navTabsState={navTabsState}
                            >
                                <GroupMembersTab
                                    collection={collectionInfo}
                                    canEdit={canEditMembers()}
                                />
                            </Tab>
                            <Tab
                                name={GroupPageTabNames.Settings}
                                navTabsState={navTabsState}
                            >
                                <GroupSettingsTab
                                    collection={collectionInfo}
                                    onCollectionUpdated={
                                        handleCollectionUpdated
                                    }
                                    onChannelUpdated={handleChannelUpdated}
                                />
                            </Tab>
                            <Tab
                                name={GroupPageTabNames.Posts}
                                navTabsState={navTabsState}
                            >
                                <GroupPostsTab
                                    key={`${collectionInfo?.id}_${selectedChannelName}`}
                                    collection={collectionInfo}
                                    channelName={selectedChannelName}
                                />
                            </Tab>
                        </NavTabs>
                    </>
                )}
            </SpinnerIfLoading>
        </>
    );
};

export default GroupPage;
