import {useQuery} from 'react-query';
import {isNotNullOrWhiteSpace} from '../lib/isNullOrWhiteSpace.ts';
import isValidURL from '../lib/isValidURL.ts';
import useIframeCheck from '../api/web_content/useIframeCheck.ts';
import isSuccessStatusCode from '../lib/isSuccessStatusCode.ts';

export interface EmbeddedWebPageCheckResult {
    isLoading: boolean;
    isError: boolean;
    isValid: boolean;
    message: string | null;
}

const useEmbeddedWebPageCheck = (
    url: string | null | undefined
): EmbeddedWebPageCheckResult => {
    const urlProvided = isNotNullOrWhiteSpace(url);
    const isValidUrl = urlProvided && isValidURL(url ?? '');
    const iframeCheck = useIframeCheck({url: url ?? ''});

    const {
        data: contentCheckResult,
        isLoading,
        isError
    } = useQuery(['iframeCheck', url], iframeCheck, {
        enabled: isValidUrl
    });

    const isValid = Boolean(
        !isLoading &&
            !isError &&
            isValidUrl &&
            contentCheckResult &&
            isSuccessStatusCode(contentCheckResult.status) &&
            !contentCheckResult.blocked
    );

    const message = (() => {
        if (!urlProvided) {
            return null;
        }

        if (!isValidUrl) {
            return 'Invalid URL';
        }

        if (contentCheckResult) {
            if (contentCheckResult.blocked) {
                return (
                    contentCheckResult.reason ||
                    'Sorry, the website does not permit the embedding of web pages in frames.'
                );
            }

            switch (contentCheckResult.status) {
                case 200:
                case 201:
                case 204:
                    return null;
                case 400:
                    return 'Bad Request: The request was invalid or cannot be served.';
                case 401:
                    return 'Unauthorized: Authentication is required to access the resource.';
                case 403:
                    return 'Forbidden: Access to the resource is denied.';
                case 404:
                    return 'Not Found: The requested resource could not be found.';
                case 500:
                    return 'Internal Server Error: An unexpected condition was encountered.';
                case 503:
                    return 'Service Unavailable: The server is currently unable to handle the request.';
                default:
                    return 'Sorry, an error occurred while loading the page.';
            }
        }

        if (isLoading) {
            return null;
        }

        return 'Sorry, page could not be loaded or the website does not permit the embedding of web pages in frames.';
    })();

    return {
        isLoading,
        isError,
        isValid,
        message
    };
};

export default useEmbeddedWebPageCheck;
