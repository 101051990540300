export default function truncate(
    str: string | null | undefined,
    limit: number
): string {
    if (!str) {
        return '';
    }

    if (str.length <= limit) {
        return str;
    }

    return str.substring(0, limit) + '…';
}
