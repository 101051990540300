import {EmptyResult, GroupMemberInfoFieldModel} from '../types';
import {usePost} from './../useHttpHooks';

export type UpsertGroupMemberInfoFieldCommand = {
    collectionId: string;
    model: GroupMemberInfoFieldModel;
};

const useUpsertGroupMemberInfoField = () =>
    usePost<UpsertGroupMemberInfoFieldCommand, EmptyResult>(`/api/group-member-info/upsert-group-member-info-field`, {errorMessage: 'POST api/group-member-info/upsert-group-member-info-field failed'
});

export default useUpsertGroupMemberInfoField;
