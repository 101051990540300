export default function toQuery(params: Record<string, unknown>): string {
    const valueToString = (value: unknown) => {
        if (
            value === null ||
            value === undefined ||
            (typeof value === 'number' && isNaN(value))
        ) {
            return '';
        }
        return String(value);
    };

    const flatten = (obj: Record<string, unknown>, prefix = ''): string[] => {
        return Object.entries(obj).reduce<string[]>((acc, [key, value]) => {
            const newKey = prefix ? `${prefix}.${key}` : key;
            if (Array.isArray(value)) {
                value.forEach(item => {
                    if (typeof item === 'object' && item !== null) {
                        acc.push(
                            ...flatten(item as Record<string, unknown>, newKey)
                        );
                    } else {
                        acc.push(
                            `${encodeURIComponent(newKey)}=${encodeURIComponent(
                                valueToString(item)
                            )}`
                        );
                    }
                });
            } else if (typeof value === 'object' && value !== null) {
                acc.push(...flatten(value as Record<string, unknown>, newKey));
            } else {
                acc.push(
                    `${encodeURIComponent(newKey)}=${encodeURIComponent(
                        valueToString(value)
                    )}`
                );
            }
            return acc;
        }, []);
    };

    return flatten(params).join('&');
}
