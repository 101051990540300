import {
    AutoLinkPlugin,
    createLinkMatcherWithRegExp
} from '@lexical/react/LexicalAutoLinkPlugin';

const URL_REGEX =
    /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const EMAIL_REGEX =
    /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

const MENTION_REGEX = /(@\w+)/i;

const MATCHERS = [
    createLinkMatcherWithRegExp(URL_REGEX, text => {
        return text.startsWith('http') ? text : `https://${text}`;
    }),
    createLinkMatcherWithRegExp(EMAIL_REGEX, text => {
        return `mailto:${text}`;
    }),
    createLinkMatcherWithRegExp(MENTION_REGEX, text => {
        return `/explore/groups/${text}`;
    })
];

export default function LexicalAutoLinkPlugin(): JSX.Element {
    return <AutoLinkPlugin matchers={MATCHERS} />;
}
