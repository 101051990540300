import {EmptyResult} from '../types';
import {usePost} from './../useHttpHooks';

export type ArchiveCollectionCommand = {
    collectionId: string;
    setAsArchived: boolean;
};

const useArchiveCollection = () =>
    usePost<ArchiveCollectionCommand, EmptyResult>(
        `/api/collections/archive-collection`,
        {errorMessage: 'POST api/collections/archive-collection failed'}
    );

export default useArchiveCollection;
