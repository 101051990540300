import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {PreviewFormat} from '../../types.ts';
import FileFormats from '../../lib/FileFormats.ts';
import AudioPlayer from '../content/AudioPlayer.tsx';
import VideoPlayer from '../content/VideoPlayer.tsx';
import EmbeddedModel from '../content/EmbeddedModel.tsx';
import {IAsset} from '../../api/types.ts';
import MarkdownViewer from './MarkdownViewer.tsx';

interface AssetPreviewModalProps {
    asset: IAsset;
    onClose: () => void;
}

const AssetPreviewModal: React.FC<AssetPreviewModalProps> = ({
    asset,
    onClose
}) => {
    const getbody = () => {
        switch (FileFormats.getPreviewFormat(asset.extension)) {
            case PreviewFormat.Audio:
                return <AudioPlayer audioSrc={asset.uri} />;
            case PreviewFormat.Image:
                return (
                    <img
                        src={asset.uri}
                        alt={asset.fileName}
                        typeof={asset.contentType}
                        className="img-fluid"
                    />
                );
            case PreviewFormat.Model:
                return <EmbeddedModel src={asset.uri} />;
            case PreviewFormat.Video:
                return (
                    <VideoPlayer
                        videoSrc={asset.uri}
                        videoType={asset.contentType}
                    />
                );
            case PreviewFormat.Markdown:
                return <MarkdownViewer src={asset.uri} />;
            default:
                return null;
        }
    };

    return (
        <Modal isOpen={true} toggle={onClose} fullscreen>
            <ModalHeader>{asset.fileName}</ModalHeader>
            <ModalBody>{getbody()}</ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClose}
                >
                    Close
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default AssetPreviewModal;
