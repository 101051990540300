import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import tryParseInt from '../../lib/tryParseInt.ts';
import {MergeData} from '../../types.ts';
import {ContentSectionModel, ViewMode} from '../../api/types.ts';
import useSessionState from '../../lib/useSessionState.ts';

interface HeaderSectionProps {
    elementId: string;
    section: ContentSectionModel;
    readOnly: boolean;
    mergeData: MergeData;
}

const HeaderSection: React.FC<HeaderSectionProps> = ({
    elementId,
    section,
    readOnly,
    mergeData
}) => {
    const [viewMode] = useSessionState(`ViewMode_${elementId}`, ViewMode.View);

    const headerSize = tryParseInt(section.altText, 1);

    const shrink = () =>
        mergeData(elementId, {altText: String(headerSize - 1)});
    const grow = () => mergeData(elementId, {altText: String(headerSize + 1)});

    const getHeader = () => {
        const cssClass = section.centered ? 'text-center' : '';

        switch (headerSize) {
            case 1:
                return <h1 className={cssClass}>{section.content}</h1>;
            case 2:
                return <h2 className={cssClass}>{section.content}</h2>;
            case 3:
                return <h3 className={cssClass}>{section.content}</h3>;
            case 4:
                return <h4 className={cssClass}>{section.content}</h4>;
            case 5:
                return <h5 className={cssClass}>{section.content}</h5>;
            case 6:
                return <h6 className={cssClass}>{section.content}</h6>;
        }

        return null;
    };

    if (!readOnly && viewMode == ViewMode.Edit) {
        return (
            <>
                <div className="mb-3 form-group">
                    <label>Enter header</label>
                    <span>&nbsp;</span>
                    <input
                        className="form-control"
                        value={section.content ?? ''}
                        onChange={event =>
                            mergeData(elementId, {content: event.target.value})
                        }
                        maxLength={2048}
                    />
                </div>

                <div className="d-flex align-items-center mb-3">
                    <div>
                        <button
                            className="btn btn-sm btn-outline-primary"
                            onClick={grow}
                            disabled={headerSize >= 6}
                        >
                            <FontAwesomeIcon icon={faMinus} />
                        </button>
                    </div>
                    <div className="mx-2">
                        <small className="d-block">Change header size</small>
                    </div>
                    <div>
                        <button
                            className="btn btn-sm btn-outline-primary"
                            onClick={shrink}
                            disabled={headerSize <= 1}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                </div>

                <div className="mb-3 form-group">
                    <div className="form-check">
                        <label className="form-check-label">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={section.centered}
                                onChange={event =>
                                    mergeData(elementId, {
                                        centered: event.target.checked
                                    })
                                }
                            />
                            Centered
                        </label>
                    </div>
                </div>

                <div className="mt-2 mb-2 form-group">
                    <label>Preview:</label>
                    <span>&nbsp;</span>
                </div>

                {getHeader()}
            </>
        );
    }

    return <>{getHeader()}</>;
};

export default HeaderSection;
