import React, {ReactNode} from 'react';
import useEmbeddedWebPageCheck from '../../hooks/useEmbeddedWebPageCheck.ts';

interface EmbeddedWebPageCheckProps {
    url: string | null | undefined;
    children?: ReactNode;
}

const EmbeddedWebPageCheck: React.FC<EmbeddedWebPageCheckProps> = ({
    url,
    children
}) => {
    const {isLoading, isValid, message} = useEmbeddedWebPageCheck(url);

    if (isLoading) {
        return null;
    }

    return (
        <>
            {!isValid && message && (
                <div className="text-danger small" style={{color: 'red'}}>
                    {message}
                </div>
            )}
            {isValid && children}
        </>
    );
};

export default EmbeddedWebPageCheck;
