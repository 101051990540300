import React, {useState} from 'react';
import {FaRegHeart} from 'react-icons/fa';
import {Button, ButtonGroup, ButtonToolbar, Input} from 'reactstrap';
import {IoMdSend} from 'react-icons/io';
import {Emoji} from '../../emojis/types.ts';
import EmojiImage from '../../emojis/EmojiImage.tsx';

interface ReactionToolbarProps {
    openReactionModal: () => void;
    triggerReactionEvent: ({
        animationType,
        message,
        emoji
    }: {
        animationType: 'float' | 'drop' | 'fly';
        message: string | null;
        emoji: string | null;
    }) => void;
    emoji: Emoji;
}

const ReactionToolbar: React.FC<ReactionToolbarProps> = ({
    openReactionModal,
    triggerReactionEvent,
    emoji
}) => {
    const [message, setMessage] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            triggerReactionEvent({
                animationType: 'fly',
                message: message,
                emoji: null
            });
        }
    };

    return (
        <>
            <ButtonToolbar>
                <ButtonGroup size="sm">
                    <Button
                        title="Select Emoji"
                        className="toolbar-buttons"
                        onClick={() => openReactionModal()}
                    >
                        Select Emoji
                    </Button>
                    <Button
                        title="Send Emoji"
                        className="toolbar-buttons"
                        onClick={() =>
                            triggerReactionEvent({
                                animationType: 'drop',
                                message: null,
                                emoji: emoji.emojiCode
                            })
                        }
                    >
                        <EmojiImage
                            emojiCode={emoji.emojiCode}
                            style={{
                                width: '20px',
                                height: '20px'
                            }}
                        />
                    </Button>
                    <Button
                        title="Heart"
                        className="toolbar-buttons"
                        onClick={() =>
                            triggerReactionEvent({
                                animationType: 'float',
                                message: null,
                                emoji: ':red_heart:'
                            })
                        }
                    >
                        <FaRegHeart size={17} />
                    </Button>
                    <Button
                        style={{
                            padding: '5px',
                            background: '#f0f0f0',
                            border: 'none'
                        }}
                    >
                        <Input
                            type="text"
                            title="Message"
                            value={message}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            style={{height: '20px'}}
                        />
                    </Button>
                    <Button
                        title="Send Message"
                        onClick={() =>
                            triggerReactionEvent({
                                animationType: 'fly',
                                message: message,
                                emoji: null
                            })
                        }
                        className="toolbar-buttons"
                    >
                        <IoMdSend />
                    </Button>
                </ButtonGroup>
            </ButtonToolbar>
        </>
    );
};

export default ReactionToolbar;
