import {RenameFileResult} from '../types';
import {usePost} from './../useHttpHooks';

export type RenameFolderCommand = {
    containerName: string;
    sourceFolderPath: string;
    destinationFolderPath: string;
};

const useRenameFolder = () =>
    usePost<RenameFolderCommand, RenameFileResult[]>(`/api/uploads/rename-folder`, {errorMessage: 'POST api/uploads/rename-folder failed'
});

export default useRenameFolder;
