import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faEdit,
    faUserTimes,
    faUserCheck,
    faUserClock,
    faTimesCircle,
    faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import {MembershipStatus} from '../../api/types.ts';

interface GroupMemberActionIconsProps {
    membership?: MembershipStatus | null;
    edit?: () => void;
    ignore?: () => void;
    accept?: () => void;
    cancel?: () => void;
    join?: () => void;
    isGroupAdmin?: boolean;
    cssClass?: string;
    showText: boolean;
}

const GroupMemberActionIcons: React.FC<GroupMemberActionIconsProps> = ({
    membership,
    edit,
    ignore,
    accept,
    cancel,
    join,
    isGroupAdmin,
    cssClass,
    showText
}) => {
    const getText = (s: string) => (showText ? s : '');

    const handleJoin = async () => {
        if (join) {
            await join();
        }
    };

    return (
        <div className={cssClass}>
            {isGroupAdmin && membership === MembershipStatus.Member && (
                <a
                    href="#"
                    title="Edit member"
                    className="action-link"
                    onClick={event => (
                        event.preventDefault(),
                        event.stopPropagation(),
                        edit && edit()
                    )}
                >
                    <FontAwesomeIcon icon={faEdit} />
                    {getText(' Edit')}
                </a>
            )}
            {(isGroupAdmin && membership === MembershipStatus.Requested) ||
            (!isGroupAdmin && membership === MembershipStatus.Invited) ? (
                <>
                    <a
                        href="#"
                        title="Ignore membership"
                        className="action-link"
                        style={{color: 'darkred'}}
                        onClick={event => (
                            event.preventDefault(),
                            event.stopPropagation(),
                            ignore && ignore()
                        )}
                    >
                        <FontAwesomeIcon icon={faUserTimes} />
                        {getText(' Ignore')}
                    </a>
                    <a
                        href="#"
                        title="Accept membership"
                        className="action-link"
                        style={{color: 'seagreen'}}
                        onClick={event => (
                            event.preventDefault(),
                            event.stopPropagation(),
                            accept && accept()
                        )}
                    >
                        <FontAwesomeIcon icon={faUserCheck} />
                        {getText(' Accept')}
                    </a>
                    <FontAwesomeIcon
                        icon={faUserClock}
                        style={{padding: '5px'}}
                        title="Pending membership"
                    />
                    {getText(' Pending')}
                </>
            ) : null}
            {(isGroupAdmin && membership === MembershipStatus.Invited) ||
            (!isGroupAdmin && membership === MembershipStatus.Requested) ? (
                <>
                    <a
                        href="#"
                        title="Cancel"
                        className="action-link"
                        style={{color: 'darkred'}}
                        onClick={event => (
                            event.preventDefault(),
                            event.stopPropagation(),
                            cancel && cancel()
                        )}
                    >
                        <FontAwesomeIcon icon={faTimesCircle} />
                        {getText(' Cancel')}
                    </a>
                    <FontAwesomeIcon
                        icon={faUserClock}
                        style={{padding: '5px'}}
                        title="Pending membership"
                    />
                    {getText(' Pending')}
                </>
            ) : null}
            {!isGroupAdmin && membership === null && (
                <a
                    href="#"
                    title="Request to join"
                    className="action-link"
                    onClick={event => (
                        event.preventDefault(),
                        event.stopPropagation(),
                        handleJoin && handleJoin()
                    )}
                >
                    <FontAwesomeIcon icon={faUserPlus} />
                    {getText(' Join')}
                </a>
            )}
        </div>
    );
};

export default GroupMemberActionIcons;
