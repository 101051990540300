import React from 'react';
import {ActiveTabState, NavTabsState, TabState} from './types.ts';
import {activateTab, isActive} from './tabs.ts';
import {ReactStateSetter} from '../../../types.ts';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import getIconDefinition from '../../../lib/getIconDefinition.ts';
import {useSearchParams} from 'react-router-dom';

interface NavTabLinkProps {
    tab: TabState;
    navTabsState: NavTabsState;
    setActiveTabState: ReactStateSetter<ActiveTabState>;
}

const NavTabLink: React.FC<NavTabLinkProps> = ({
    tab,
    navTabsState,
    setActiveTabState
}) => {
    const [, setSearchParams] = useSearchParams();

    const onSelectedOptionChange = (selectedOption: string) => {
        const currentActiveTabName = navTabsState.activeTabName;
        setActiveTabState({activeTabName: tab.name, selectedOption});
        tab.onOptionSelected && tab.onOptionSelected(selectedOption);

        if (currentActiveTabName != tab.name) {
            setSearchParams({tab: tab.name});
        }
    };

    const getLabelClass = (tab: TabState) => {
        return isActive(navTabsState, tab.name) ? 'active' : '';
    };

    return (
        <li
            className={`nav-item ${tab.msAuto ? ' ms-auto' : ''}`}
            style={tab.style}
        >
            <a
                className={`nav-link ${getLabelClass(tab)}`}
                href="#"
                onClick={e => {
                    e.preventDefault();
                    activateTab(navTabsState, setActiveTabState, tab.name);
                    setSearchParams({tab: tab.name});
                }}
            >
                {tab.iconClass && (
                    <FontAwesomeIcon
                        icon={getIconDefinition(tab.iconClass)}
                    ></FontAwesomeIcon>
                )}
                <span className="tab-text ps-2">{tab.text}</span>
            </a>
            {tab.options && (
                <div className="channel-select-holder">
                    <select
                        value={'#' + tab.selectedOption}
                        onChange={e => onSelectedOptionChange(e.target.value)}
                    >
                        {tab.options.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            )}
        </li>
    );
};

export default NavTabLink;
