import {EmptyResult, ProjectDataIndex} from '../types';
import {usePost} from '../useHttpHooks';

export type ProvideProjectContentFeedbackCommand = {
    groupName: string;
    projectId: string;
    dataIndex: ProjectDataIndex;
    requestId: string;
};

const useProvideProjectContentFeedback = () =>
    usePost<ProvideProjectContentFeedbackCommand, EmptyResult>(
        `/api/assistant/provide-project-content-feedback`,
        {
            errorMessage:
                'POST api/assistant/provide-project-content-feedback failed'
        }
    );

export default useProvideProjectContentFeedback;
