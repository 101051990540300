import {RepliesResult} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetPostRepliesQuery = {
    parentPostId: string;
    countOnly: boolean;
};

const useGetPostReplies = (request: GetPostRepliesQuery) =>
    useGet<RepliesResult>(`/api/posts/get-post-replies?${toQuery(request)}`, {errorMessage: 'GET api/posts/get-post-replies failed'
});

export default useGetPostReplies;
