import type {
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    LexicalNode,
    NodeKey,
    SerializedLexicalNode,
    Spread
} from 'lexical';

import {DecoratorNode} from 'lexical';
import {Suspense} from 'react';
import ImageComponent from './ImageComponent';
import isNullOrWhiteSpace from '../../../lib/isNullOrWhiteSpace.ts';

export interface ImagePayload {
    altText: string;
    height?: number | 'inherit';
    key?: NodeKey;
    maxWidth?: number;
    src: string;
    width?: number | 'inherit';
}

function convertImageElement(domNode: Node): null | DOMConversionOutput {
    if (domNode instanceof HTMLImageElement) {
        const {src, alt: altText} = domNode;
        const widthAttributeValue = domNode.getAttribute('width');
        const heightAttributeValue = domNode.getAttribute('height');

        const width = isNullOrWhiteSpace(widthAttributeValue)
            ? undefined
            : +widthAttributeValue!;
        const height = isNullOrWhiteSpace(heightAttributeValue)
            ? undefined
            : +heightAttributeValue!;

        // console.log('convert image element', domNode);
        const node = $createImageNode({altText, src, width, height});
        return {node};
    }
    return null;
}

export type SerializedImageNode = Spread<
    {
        altText: string;
        height?: number | 'inherit';
        maxWidth: number;
        src: string;
        width?: number | 'inherit';
        type: 'image';
        version: 1;
    },
    SerializedLexicalNode
>;

export class ImageNode extends DecoratorNode<JSX.Element> {
    __src: string;
    __altText: string;
    __width: 'inherit' | number;
    __height: 'inherit' | number;
    __maxWidth: number;

    static getType(): string {
        return 'image';
    }

    static clone(node: ImageNode): ImageNode {
        return new ImageNode(
            node.__src,
            node.__altText,
            node.__maxWidth,
            node.__width,
            node.__height,
            node.__key
        );
    }

    exportJSON(): SerializedImageNode {
        return {
            altText: this.__altText,
            height: this.__height,
            maxWidth: this.__maxWidth,
            src: this.__src,
            type: 'image',
            version: 1,
            width: this.__width
        };
    }

    static importJSON(serializedNode: SerializedImageNode): ImageNode {
        // console.log(serializedNode);
        const {altText, height, width, maxWidth, src} = serializedNode;
        const node = $createImageNode({
            altText,
            height,
            maxWidth,
            src,
            width
        });
        return node;
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement('img');
        element.setAttribute('src', this.__src);
        element.setAttribute('alt', this.__altText);
        element.setAttribute('width', this.__width.toString());
        element.setAttribute('height', this.__height.toString());
        element.setAttribute('class', 'created-by-lexical');
        return {element};
    }

    static importDOM(): DOMConversionMap | null {
        return {
            img: (domNode: HTMLElement) => {
                if (domNode.hasAttribute('data-emoji-code')) {
                    return null;
                }
                return {
                    conversion: domNode => {
                        return convertImageElement(domNode);
                    },
                    priority: 0
                };
            }
        };
    }

    constructor(
        src: string,
        altText: string,
        maxWidth: number,
        width?: 'inherit' | number,
        height?: 'inherit' | number,
        key?: NodeKey
    ) {
        super(key);
        this.__src = src;
        this.__altText = altText;
        this.__maxWidth = maxWidth;
        this.__width = width ?? 'inherit';
        this.__height = height ?? 'inherit';
    }

    setWidthAndHeight(
        width: 'inherit' | number,
        height: 'inherit' | number
    ): void {
        const writable = this.getWritable();
        writable.__width = width;
        writable.__height = height;
    }

    // View

    createDOM(config: EditorConfig): HTMLElement {
        const span = document.createElement('span');
        const theme = config.theme;
        const className = theme.image;
        if (className !== undefined) {
            span.className = className;
        }
        return span;
    }

    updateDOM(): false {
        return false;
    }

    getSrc(): string {
        return this.__src;
    }

    getWidth(): 'inherit' | number {
        return this.__width;
    }

    getHeight(): 'inherit' | number {
        return this.__height;
    }

    getAltText(): string {
        return this.__altText;
    }

    decorate(): JSX.Element {
        return (
            <Suspense fallback={null}>
                <ImageComponent
                    src={this.__src}
                    altText={this.__altText}
                    width={this.__width}
                    height={this.__height}
                    maxWidth={this.__maxWidth}
                    nodeKey={this.getKey()}
                    resizable={true}
                />
            </Suspense>
        );
    }

    isInline(): false {
        return false;
    }
}

export function $createImageNode({
    altText,
    height,
    maxWidth = 1000,
    src,
    width,
    key
}: ImagePayload): ImageNode {
    return new ImageNode(src, altText, maxWidth, width, height, key);
}

export function $isImageNode(
    node: LexicalNode | null | undefined
): node is ImageNode {
    return node instanceof ImageNode;
}
