import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faReply,
    faTrashAlt,
    faEllipsisH
} from '@fortawesome/free-solid-svg-icons';
import useReactToPost from '../../api/posts/useReactToPost';
import {PostType} from '../../api/types.ts';
import {useMutation} from 'react-query';
import EmojiImage from '../../emojis/EmojiImage.tsx';

enum PostTool {
    Delete,
    Reply
}

interface PostToolbarProps {
    canDelete: boolean;
    onDelete: () => void;
    onReply: () => void;
    onReact: () => void;
    postType: PostType;
    postId: string;
    parentPostId: string | null;
}

const PostToolbar: React.FC<PostToolbarProps> = ({
    canDelete,
    onDelete,
    onReply,
    onReact,
    postType,
    postId,
    parentPostId
}) => {
    const [tools, setTools] = useState<PostTool[]>([]);
    const reactToPost = useReactToPost();
    const reactToPostMutaion = useMutation(reactToPost);

    useEffect(() => {
        setTools(getPostTools());
    }, [canDelete, postType]);

    const getPostTools = () => {
        const toolValues = Object.values(PostTool).filter(
            value => typeof value === 'number'
        ) as PostTool[];

        return toolValues.filter(value => {
            if (!canDelete && value === PostTool.Delete) {
                return false;
            }

            if (postType === PostType.Reply && value === PostTool.Reply) {
                return false;
            }

            return true;
        });
    };

    const reactAsync = async (emojiCode: string) => {
        const reactToPostCommand = {
            postId,
            parentPostId,
            emojiCode
        };

        await reactToPostMutaion.mutateAsync(reactToPostCommand);
    };

    return (
        <div className="toolbar-holder">
            <div className="post-toolbar emoji-toolbar d-flex me-3">
                <a
                    className="emoji-link"
                    href="#"
                    onClick={e => {
                        e.preventDefault();
                        reactAsync('thumbs_up');
                    }}
                >
                    <EmojiImage emojiCode="thumbs_up" />
                </a>
                <a
                    className="emoji-link"
                    href="#"
                    onClick={e => {
                        e.preventDefault();
                        reactAsync('sparkling_heart');
                    }}
                >
                    <EmojiImage emojiCode="sparkling_heart" />
                </a>
                <a
                    className="emoji-link"
                    href="#"
                    onClick={e => {
                        e.preventDefault();
                        reactAsync('slightly_smiling_face');
                    }}
                >
                    <EmojiImage emojiCode="slightly_smiling_face" />
                </a>
                <a
                    className="emoji-link"
                    href="#"
                    onClick={e => {
                        e.preventDefault();
                        reactAsync('star_struck');
                    }}
                >
                    <EmojiImage emojiCode="star_struck" />
                </a>
                <a
                    className="emoji-link"
                    href="#"
                    onClick={e => {
                        e.preventDefault();
                        reactAsync('face_with_open_mouth');
                    }}
                >
                    <EmojiImage emojiCode="face_with_open_mouth" />
                </a>

                <a className="action-link" href="#" onClick={onReact}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                </a>
            </div>
            <div className="post-toolbar d-flex">
                {tools.map((tool, index) => {
                    let className = 'action-link';

                    if (tool !== tools[tools.length - 1]) {
                        className += ' me-2';
                    }

                    return (
                        <a
                            key={index}
                            className={className}
                            title={PostTool[tool]}
                        >
                            {tool === PostTool.Reply && (
                                <FontAwesomeIcon
                                    title={PostTool[tool]}
                                    icon={faReply}
                                    onClick={onReply}
                                />
                            )}
                            {tool === PostTool.Delete && (
                                <FontAwesomeIcon
                                    title={PostTool[tool]}
                                    icon={faTrashAlt}
                                    onClick={onDelete}
                                />
                            )}
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export default PostToolbar;
