import {useEffect, useRef, useState, useCallback} from 'react';
import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState
} from '@microsoft/signalr';
import joinPath from '../../lib/joinPath';
import useClientConfig from '../../hooks/useClientConfig';
import {AiChunk} from '../../api/types';

interface UseAiHubProps {
    groupName: string | null;
    onChunkGenerated: (aiChunk: AiChunk) => void;
    onComplete?: () => void;
}

const useAiHub = ({groupName, onChunkGenerated, onComplete}: UseAiHubProps) => {
    const {apiServiceBaseUri} = useClientConfig();
    const [connectionError, setConnectionError] = useState<string | null>(null);
    const hubConnectionRef = useRef<HubConnection | null>(null);

    const connectToHub = useCallback(async () => {
        if (hubConnectionRef.current?.state === HubConnectionState.Connected) {
            return;
        }

        const hubUrl = joinPath(apiServiceBaseUri, 'aiHub');
        hubConnectionRef.current = new HubConnectionBuilder()
            .withUrl(hubUrl)
            .withAutomaticReconnect([300, 500, 1000, 3000, 5000])
            .build();

        if (groupName) {
            try {
                hubConnectionRef.current.on(
                    'OnChunkGenerated',
                    onChunkGenerated
                );

                if (onComplete) {
                    hubConnectionRef.current.on('OnComplete', onComplete);
                }

                await hubConnectionRef.current.start();
                console.log('Connected to AI Hub');
                setConnectionError(null);
                await hubConnectionRef.current.invoke('JoinGroup', groupName);
            } catch (error) {
                console.error('Error connecting to AI Hub:', error);
                setConnectionError(
                    'Failed to connect to AI Hub. Please try again later.'
                );
            }
        }
    }, [apiServiceBaseUri, groupName, onChunkGenerated, onComplete]);

    const disconnectFromHub = useCallback(async () => {
        if (
            hubConnectionRef.current?.state === HubConnectionState.Connected &&
            groupName
        ) {
            try {
                await hubConnectionRef.current.invoke('LeaveGroup', groupName);
                hubConnectionRef.current.off(
                    'OnChunkGenerated',
                    onChunkGenerated
                );

                if (onComplete) {
                    hubConnectionRef.current.off('OnComplete', onComplete);
                }

                await hubConnectionRef.current.stop();
                console.log('Disconnected from AI Hub');
            } catch (error) {
                console.error('Error disconnecting from AI Hub:', error);
            }
        }
    }, [groupName, onChunkGenerated, onComplete]);

    useEffect(() => {
        connectToHub();
        return () => {
            disconnectFromHub();
        };
    }, [connectToHub, disconnectFromHub]);

    return {connectionError};
};

export default useAiHub;
