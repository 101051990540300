export default function getBlobInfoFromUri(uri: string): {
    containerName: string;
    blobName: string;
} {
    const url = new URL(uri);
    const pathSegments = url.pathname
        .split('/')
        .filter(segment => segment.length > 0);
    const containerName = pathSegments[0];
    const blobName = decodeURIComponent(pathSegments.slice(1).join('/'));
    return {containerName, blobName};
}
