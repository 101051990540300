import React from 'react';
import {CollectionInfoModel} from '../../api/types.ts';
import Assets from '../assets/Assets.tsx';

interface GroupAssetsTabProps {
    collection: CollectionInfoModel;
}

const GroupAssetsTab: React.FC<GroupAssetsTabProps> = ({collection}) => {
    return (
        <div className="container-fluid custom-container mt-5">
            <div className="row">
                <div className="col-12">
                    <div className="uploads-card p-3 mb-4">
                        <Assets
                            containerName={collection.containerName}
                            showSelectCollection={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GroupAssetsTab;
