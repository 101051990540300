import React, {useEffect, useState} from 'react';
import useGetPostReactions from '../../api/posts/useGetPostReactions';
import {PostReactionModel} from '../../api/types';
import {useQuery} from 'react-query';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {getNavTabsState} from '../shared/tabs/tabs.ts';
import {ActiveTabState, TabState} from '../shared/tabs/types.ts';
import ReactionCard from '../shared/ReactionCard.tsx';
import Tab from '../shared/tabs/Tab.tsx';
import emojisByEmojiCode from '../../emojis/emojisByEmojiCode.ts';

interface ReactionsGroup {
    emojiCode: string;
    count: number;
    reactions: PostReactionModel[];
}

interface ReactionsModalProps {
    postId: string;
    onHide: () => void;
}

const ReactionsModal: React.FC<ReactionsModalProps> = ({postId, onHide}) => {
    const [reactions, setReactions] = useState<PostReactionModel[]>([]);
    const [reactionsGroups, setReactionsGroups] = useState<ReactionsGroup[]>(
        []
    );

    const getPostReactions = useGetPostReactions({postId});
    const {data: reactionsData} = useQuery(
        ['postReactions', postId],
        getPostReactions
    );

    useEffect(() => {
        if (reactionsData) {
            setReactions(reactionsData);
            const groups = reactionsData
                .reduce((acc: ReactionsGroup[], curr: PostReactionModel) => {
                    const found = acc.find(x => x.emojiCode === curr.reaction);
                    if (found) {
                        found.count++;
                        found.reactions.push(curr);
                    } else {
                        acc.push({
                            emojiCode: curr.reaction,
                            count: 1,
                            reactions: [curr]
                        });
                    }
                    return acc;
                }, [] as ReactionsGroup[])
                .sort((a, b) => b.count - a.count);
            setReactionsGroups(groups);
        }
    }, [reactionsData]);

    const [activeTabState, setActiveTabState] = useState<ActiveTabState>({
        activeTabName: 'All'
    });

    const reactionGroupsTabs: TabState[] =
        reactionsGroups.map(g => ({
            name: g.emojiCode,
            text: String(g.count),
            available: true
        })) ?? [];

    const tabs: TabState[] = [
        {
            name: 'All',
            text: 'All',
            available: true
        },
        ...reactionGroupsTabs
    ];

    const navTabsState = getNavTabsState(tabs, activeTabState);

    return (
        <Modal isOpen size="lg" toggle={onHide}>
            <ModalHeader>
                <ul className="nav nav-tabs">
                    {reactionsGroups.map((group, index) => (
                        <li key={index} className="nav-item">
                            <button
                                type="button"
                                className={`d-flex align-items-center nav-link ${
                                    activeTabState.activeTabName ===
                                    group.emojiCode
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={() =>
                                    setActiveTabState(prevState => ({
                                        ...prevState,
                                        activeTabName: group.emojiCode
                                    }))
                                }
                            >
                                <img
                                    className="emoji me-2"
                                    src={
                                        emojisByEmojiCode[group.emojiCode]
                                            .source
                                    }
                                />
                                {group.count}
                            </button>
                        </li>
                    ))}
                </ul>
                <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={onHide}
                ></button>
            </ModalHeader>
            <ModalBody>
                <Tab name="All" navTabsState={navTabsState}>
                    <div className="reactions">
                        <div className="reaction-card-holder">
                            {reactions.map((reaction, index) => (
                                <ReactionCard key={index} reaction={reaction} />
                            ))}
                        </div>
                    </div>
                </Tab>
                {reactionsGroups.map((group, index) => (
                    <Tab
                        key={index}
                        name={group.emojiCode}
                        navTabsState={navTabsState}
                    >
                        <div className="reactions">
                            <div className="reaction-card-holder">
                                {group.reactions.map(
                                    (
                                        reaction: PostReactionModel,
                                        index: number
                                    ) => (
                                        <ReactionCard
                                            key={index}
                                            reaction={reaction}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    </Tab>
                ))}
            </ModalBody>
        </Modal>
    );
};

export default ReactionsModal;
