import React from 'react';
import {SceneEntity, Vector3} from './types.ts';
import {FormGroup, Label, Input} from 'reactstrap';
import SpatialCoordinateEditor from './SpatialCoordinateEditor.tsx';

interface LightEditorProps {
    light: SceneEntity;
    onIntensityChange: (intensity: number | undefined) => void;
    onDirectionChange: (direction: Vector3) => void;
    onAngleChange: (angle: number | undefined) => void;
    onExponentChange: (exponent: number | undefined) => void;
}

const LightEditor: React.FC<LightEditorProps> = ({
    light,
    onIntensityChange,
    onDirectionChange,
    onAngleChange,
    onExponentChange
}) => {
    const handleIntensityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onIntensityChange(isNaN(value) ? undefined : value);
    };

    const handleAngleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onAngleChange(isNaN(value) ? undefined : value);
    };

    const handleExponentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value);
        onExponentChange(isNaN(value) ? undefined : value);
    };

    if (
        light.type === 'directionalLight' ||
        light.type === 'spotLight' ||
        light.type === 'hemisphericLight'
    ) {
        const lightWithDirection = light as SceneEntity & {
            direction: Vector3;
            intensity?: number;
        };
        return (
            <>
                <SpatialCoordinateEditor
                    label={<b>Direction</b>}
                    coordinate={lightWithDirection.direction}
                    onChange={onDirectionChange}
                />
                {lightWithDirection.intensity !== undefined && (
                    <FormGroup>
                        <Label>Intensity:</Label>
                        <Input
                            type="number"
                            step="0.1"
                            value={lightWithDirection.intensity}
                            onChange={handleIntensityChange}
                        />
                    </FormGroup>
                )}
                {light.type === 'spotLight' && (
                    <>
                        {(light as SceneEntity & {angle?: number}).angle !==
                            undefined && (
                            <FormGroup>
                                <Label>Angle:</Label>
                                <Input
                                    type="number"
                                    step="0.1"
                                    value={
                                        (
                                            light as SceneEntity & {
                                                angle?: number;
                                            }
                                        ).angle
                                    }
                                    onChange={handleAngleChange}
                                />
                            </FormGroup>
                        )}
                        {(light as SceneEntity & {exponent?: number})
                            .exponent !== undefined && (
                            <FormGroup>
                                <Label>Exponent:</Label>
                                <Input
                                    type="number"
                                    step="0.1"
                                    value={
                                        (
                                            light as SceneEntity & {
                                                exponent?: number;
                                            }
                                        ).exponent
                                    }
                                    onChange={handleExponentChange}
                                />
                            </FormGroup>
                        )}
                    </>
                )}
            </>
        );
    }

    if (light.type === 'pointLight') {
        const lightWithIntensity = light as SceneEntity & {intensity?: number};
        return (
            <>
                {lightWithIntensity.intensity !== undefined && (
                    <FormGroup>
                        <Label>Intensity:</Label>
                        <Input
                            type="number"
                            step="0.1"
                            value={lightWithIntensity.intensity}
                            onChange={handleIntensityChange}
                        />
                    </FormGroup>
                )}
            </>
        );
    }

    return null;
};

export default LightEditor;
