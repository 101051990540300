import {useEffect, useMemo, useState} from 'react';
import {
    HubConnectionBuilder,
    HttpTransportType,
    HubConnection,
    HubConnectionState
} from '@microsoft/signalr';
import {useAuthStateManager} from '../hooks/useAuthStateManager.tsx';
import baseUri from '../api/baseUri.ts';
import toQuery from '../lib/toQuery.ts';

enum ExceptionReason {
    General = 'General exception',
    Start = 'Could not start connection',
    Invoke = 'Could not invoke hub method'
}

const logException = (
    exceptionReason: ExceptionReason,
    exception: unknown,
    groupName: string
) => {
    console.error(`${groupName} ${exceptionReason}`);
    console.error(exception);
};

export default function useProjectDataHub(
    groupName: string
): [HubConnection, boolean, () => void] {
    const {getAccessToken, authData} = useAuthStateManager();
    const [hubState, setHubState] = useState(HubConnectionState.Connecting);
    const [reinitialize, setReinitialize] = useState(false);

    const hubConnection = useMemo(() => {
        const hubConnector = new HubConnectionBuilder()
            .withUrl(
                `${baseUri}/projectDataHub?${toQuery({
                    signalr_token: authData.signalr_token
                })}`,
                {
                    transport: HttpTransportType.WebSockets,
                    accessTokenFactory: () => getAccessToken()
                }
            )
            .withAutomaticReconnect([300, 500, 1000, 3000, 5000])
            .build();

        hubConnector.onclose(error => {
            setHubState(HubConnectionState.Disconnected);
            if (error) {
                console.error(error);
            }
        });

        return hubConnector;
    }, [getAccessToken, reinitialize]);

    useEffect(() => {
        if (
            hubConnection.state === HubConnectionState.Connected ||
            hubConnection.state === HubConnectionState.Connecting
        ) {
            return; // Skip if already connected or connecting
        }

        setHubState(HubConnectionState.Connecting);

        let attempts = 0;
        let tryConnection: ReturnType<typeof setTimeout>;
        let isMounted = true;

        const tryCreateHubConnection = async () => {
            let exceptionReason = ExceptionReason.General;

            try {
                if (
                    hubConnection.state !== HubConnectionState.Connecting &&
                    hubConnection.state !== HubConnectionState.Connected
                ) {
                    await hubConnection.start().catch(ex => {
                        exceptionReason = ExceptionReason.Start;
                        throw ex;
                    });

                    await hubConnection.invoke('join', groupName).catch(ex => {
                        exceptionReason = ExceptionReason.Invoke;
                        throw ex;
                    });

                    if (isMounted) {
                        setHubState(HubConnectionState.Connected);
                    }
                }
            } catch (ex) {
                if (attempts < 4) {
                    tryConnection = setTimeout(
                        () => tryCreateHubConnection(),
                        1000 * Math.pow(2, attempts)
                    );
                    attempts++;
                    return;
                }

                logException(exceptionReason, ex, groupName);
                if (isMounted) {
                    setHubState(HubConnectionState.Disconnected);
                }
            }
        };

        tryCreateHubConnection();

        return () => {
            isMounted = false;
            if (hubConnection.state !== HubConnectionState.Disconnected) {
                hubConnection.stop().then(() => {
                    if (isMounted) {
                        setHubState(HubConnectionState.Disconnected);
                        console.log('Connection stopped');
                    }
                });
            }
            clearTimeout(tryConnection);
        };
    }, [groupName, hubConnection, reinitialize]);

    return [
        hubConnection,
        hubState === HubConnectionState.Disconnected,
        () => setReinitialize(!reinitialize)
    ];
}

export const ProjectDataHubMessages = {
    UpdateProjectDataOnClient: 'updateProjectDataOnClient',
    UpdateProjectDataOnServer: 'updateProjectDataOnServer',
    UpdatePlayerOnServer: 'updatePlayerOnServer',
    UpdatePlayerOnClient: 'updatePlayerOnClient',
    UpdateReactionOnServer: 'updateReactionOnServer',
    UpdateReactionOnClient: 'updateReactionOnClient'
};

export const useHubMessageListener = (
    hubConnection: HubConnection,
    message: string,
    handler: (...args: unknown[]) => void = () => {
        return;
    },
    dependencies: unknown[] = []
) => {
    useEffect(() => {
        hubConnection.on(message, (...args: unknown[]) => {
            try {
                handler(...args);
            } catch (ex) {
                // Not notifying the user here as it may not actually be an issue.
                console.error(ex);
            }
        });
        return () => {
            hubConnection.off(message);
        };
    }, dependencies);
};
