import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {mergeRegister} from '@lexical/utils';
import {
    $insertNodes,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand
} from 'lexical';
import {useEffect} from 'react';

import {
    $createYouTubeNode,
    YouTubeNode,
    YouTubePayload
} from '../nodes/YouTubeNode';

export type InsertYouTubePayload = Readonly<YouTubePayload>;

export const INSERT_YOUTUBE_COMMAND: LexicalCommand<InsertYouTubePayload> =
    createCommand('INSERT_YOUTUBE_COMMAND');

export default function YouTubePlugin(): JSX.Element | null {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!editor.hasNodes([YouTubeNode])) {
            throw new Error(
                'YouTubePlugin: YouTubeNode not registered on editor'
            );
        }

        return mergeRegister(
            editor.registerCommand<InsertYouTubePayload>(
                INSERT_YOUTUBE_COMMAND,
                payload => {
                    const youTubeNode = $createYouTubeNode(payload);
                    $insertNodes([youTubeNode]);

                    return true;
                },
                COMMAND_PRIORITY_EDITOR
            )
        );
    }, [editor]);

    return null;
}
