import {PagedResult, Pagination} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetTagsForProjectsQuery = {
    pagination: Pagination;
    filter: string;
};

const useGetTagsForProjects = (request: GetTagsForProjectsQuery) =>
    useGet<PagedResult<string>>(
        `/api/mixes/get-tags-for-projects?${toQuery(request)}`,
        {errorMessage: 'GET api/mixes/get-tags-for-projects failed'}
    );

export default useGetTagsForProjects;
