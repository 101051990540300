import {Emoji} from './types.ts';
import EmojiUtility from './EmojiUtility.ts';

class SimilarEmojiComparer {
    private readonly textToEmojiCode: string;

    constructor(text: string) {
        this.textToEmojiCode = EmojiUtility.sanitizeEmojiCode(text);
    }

    compare(x: Emoji, y: Emoji): number {
        if (x === y) {
            return 0;
        }
        if (y === null) {
            return 1;
        }
        if (x === null) {
            return -1;
        }

        const indexOfTextX = x.emojiCode.indexOf(this.textToEmojiCode);
        const indexOfTextY = y.emojiCode.indexOf(this.textToEmojiCode);

        if (indexOfTextX === indexOfTextY) {
            return x.emojiCode.localeCompare(y.emojiCode);
        }

        return indexOfTextX - indexOfTextY;
    }
}

export default SimilarEmojiComparer;
