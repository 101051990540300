import React, {FC} from 'react';
import {ButtonProps} from 'reactstrap';
import ButtonAsync from './ButtonAsync';

interface ConfirmButtonProps extends ButtonProps {
    message: string;
    onClick: (
        event: React.MouseEvent<HTMLButtonElement>
    ) => Promise<void> | void;
}

const ConfirmButton: FC<ConfirmButtonProps> = ({
    message,
    onClick,
    children,
    ...buttonProps
}) => {
    const onConfirm = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const confirmed = window.confirm(message);
        if (confirmed) {
            const result = onClick(event);
            if (result instanceof Promise) {
                await result;
            }
        }
    };

    return (
        <ButtonAsync {...buttonProps} onClick={onConfirm}>
            {children}
        </ButtonAsync>
    );
};

export default ConfirmButton;
