import {GroupMemberInfoFieldModel} from '../types';
import {useGet} from './../useHttpHooks';
import toQuery from '../../lib/toQuery.ts';

export type GetGroupMemberInfoFieldsByCollectionIdQuery = {
    collectionId: string;
};

const useGetGroupMemberInfoFieldsByCollectionId = (request: GetGroupMemberInfoFieldsByCollectionIdQuery) =>
    useGet<GroupMemberInfoFieldModel[]>(`/api/group-member-info/get-group-member-info-fields-by-collection-id?${toQuery(request)}`, {errorMessage: 'GET api/group-member-info/get-group-member-info-fields-by-collection-id failed'
});

export default useGetGroupMemberInfoFieldsByCollectionId;
