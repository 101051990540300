import React, {useState, useEffect, useCallback} from 'react';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {vscDarkPlus} from 'react-syntax-highlighter/dist/esm/styles/prism';
import mermaid from 'mermaid';
import {Button} from 'reactstrap';
import {FaCopy} from 'react-icons/fa';
import ReactDOMServer from 'react-dom/server';

interface CodeBlockProps {
    language: string;
    value: string;
}

const CodeBlock: React.FC<CodeBlockProps> = ({language, value}) => {
    const [showDiagram, setShowDiagram] = useState(true);
    const [mermaidSvg, setMermaidSvg] = useState<string | null>(null);
    const [copied, setCopied] = useState(false);

    const svgToImageDataUrl = (svgString: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(svgString, 'image/svg+xml');
            const svg = doc.documentElement;

            // Get the viewBox values
            const viewBox = svg
                .getAttribute('viewBox')
                ?.split(' ')
                .map(Number) || [0, 0, 800, 600];
            const [, , vbWidth, vbHeight] = viewBox;

            // Set a maximum width for the image
            const maxWidth = 1200;
            const scale = maxWidth / vbWidth;

            // Calculate height while maintaining aspect ratio
            const width = vbWidth * scale;
            const height = vbHeight * scale;

            // Set new dimensions
            svg.setAttribute('width', width.toString());
            svg.setAttribute('height', height.toString());

            const svgData = new XMLSerializer().serializeToString(svg);

            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL('image/png'));
            };
            img.onerror = reject;
            img.src =
                'data:image/svg+xml;base64,' +
                btoa(unescape(encodeURIComponent(svgData)));
        });
    };

    const copyToClipboard = async () => {
        const plainText = value;
        let htmlContent = '';

        if (language === 'mermaid' && showDiagram && mermaidSvg) {
            try {
                const imageDataUrl = await svgToImageDataUrl(mermaidSvg);

                htmlContent = `
<html>
<body>
    <img src="${imageDataUrl}" alt="Mermaid Diagram" style="max-width: 100%;">
</body>
</html>`;
            } catch (error) {
                console.error('Error converting SVG to image:', error);
                htmlContent =
                    '<p>Error: Could not convert diagram to image.</p>';
            }
        } else {
            htmlContent = ReactDOMServer.renderToString(
                <SyntaxHighlighter language={language} style={vscDarkPlus}>
                    {value}
                </SyntaxHighlighter>
            );
        }

        const clipboardItem = new ClipboardItem({
            'text/plain': new Blob([plainText], {type: 'text/plain'}),
            'text/html': new Blob([htmlContent], {type: 'text/html'})
        });

        navigator.clipboard
            .write([clipboardItem])
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    const renderMermaidDiagram = useCallback(async () => {
        if (language === 'mermaid') {
            try {
                // Add the white background configuration
                const mermaidConfig =
                    '%%{init: {"theme": "base", "themeVariables": { "background": "#ffffff"}}}%%\n';
                const mermaidCode = mermaidConfig + value;

                const {svg} = await mermaid.render(
                    'mermaid-diagram',
                    mermaidCode
                );
                setMermaidSvg(svg);
            } catch (error) {
                console.error('Error rendering Mermaid diagram:', error);
                setMermaidSvg('<div>Error rendering diagram</div>');
            }
        }
    }, [language, value]);

    useEffect(() => {
        renderMermaidDiagram();
    }, [renderMermaidDiagram]);

    return (
        <>
            <div className="d-flex justify-content-end">
                <button
                    onClick={copyToClipboard}
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    <FaCopy size={20} color={copied ? 'green' : 'gray'} />
                </button>
                {language === 'mermaid' && (
                    <Button
                        color="secondary"
                        size="sm"
                        onClick={() => setShowDiagram(!showDiagram)}
                    >
                        {showDiagram ? 'Show Code' : 'Show Diagram'}
                    </Button>
                )}
            </div>
            {language === 'mermaid' ? (
                showDiagram ? (
                    <div dangerouslySetInnerHTML={{__html: mermaidSvg || ''}} />
                ) : (
                    <SyntaxHighlighter language="mermaid" style={vscDarkPlus}>
                        {value}
                    </SyntaxHighlighter>
                )
            ) : (
                <SyntaxHighlighter language={language} style={vscDarkPlus}>
                    {value}
                </SyntaxHighlighter>
            )}
        </>
    );
};

export default CodeBlock;
