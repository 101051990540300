import {
    EntityDataModel,
    EntityManipulationMethods,
    MergeData,
    ReactStateSetter,
    RemoveElement,
    UnknownObject
} from '../types.ts';
import emptyGuid from '../lib/emptyGuid.ts';
import mergeDataImpl from './mergeDataImpl.ts';

export const emptyManipulation: EntityManipulationMethods = {
    mergeData: () => {},
    removeElement: () => {}
};

export default function createEntityManipulationMethods(
    setEntityData: ReactStateSetter<EntityDataModel>
): EntityManipulationMethods {
    const mergeData: MergeData = (elementId: string, data: UnknownObject) => {
        setEntityData(prevData => {
            const targetData = {...prevData};

            if (elementId === emptyGuid) {
                for (const [batchedElementId, batchedData] of Object.entries(
                    data
                )) {
                    if (!targetData[batchedElementId]) {
                        targetData[batchedElementId] = {};
                    }

                    mergeDataImpl(
                        batchedData as UnknownObject,
                        targetData[batchedElementId] as UnknownObject
                    );
                }
            } else {
                if (!targetData[elementId]) {
                    targetData[elementId] = {};
                }

                mergeDataImpl(data, targetData[elementId] as UnknownObject);
            }

            // console.log(`mergeData -> after merged ${elementId}`, targetData);

            return targetData;
        });
    };

    const removeElement: RemoveElement = elementId => {
        setEntityData(prevData => {
            const targetData = {...prevData};
            delete targetData[elementId];

            /*
            console.log(
                `removeElement -> after removed ${elementId}`,
                targetData
            );
            */

            return targetData;
        });
    };

    return {mergeData, removeElement};
}
