import React, {useState} from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    FormText,
    Spinner
} from 'reactstrap';
import {useMutation} from 'react-query';
import useAddPendingGroupMember, {
    AddPendingGroupMemberCommand
} from '../../api/groups/useAddPendingGroupMember';
import {toast} from 'react-toastify';
import {MembershipStatus, UserCollectionType} from '../../api/types.ts';
import unknownErrorToString from '../../lib/unknownErrorToString.ts';
import ButtonAsync from '../shared/ButtonAsync.tsx';
import {useEffectOnce} from '../../hooks/useEffectOnce.ts';

interface JoinCollectionInfoModel {
    id: string | null;
    hasInviteCode: boolean | null;
    loggedInUserCanAutoJoin: boolean | null;
}

interface RequestToJoinModalProps {
    dismiss: () => void;
    requested: (membershipStatus: MembershipStatus) => void;
    collection: JoinCollectionInfoModel;
    nicknameOrEmailOrUserId: string;
}

const RequestToJoinModal: React.FC<RequestToJoinModalProps> = ({
    dismiss,
    requested,
    collection,
    nicknameOrEmailOrUserId
}) => {
    const [collectionJoinCode, setCollectionJoinCode] = useState('');
    const addPendingGroupMember = useAddPendingGroupMember();
    const mutation = useMutation(addPendingGroupMember, {
        onSuccess: data => {
            data && toast.success(data.message);
            data && requested(data.membershipStatus);
            dismiss();
        },
        onError: (error: unknown) => {
            toast.error(unknownErrorToString(error));
        }
    });

    const onRequestedAsync = async () => {
        const command: AddPendingGroupMemberCommand = {
            collectionId: collection.id!,
            groupAdminId: null,
            nicknameOrEmailOrUserId,
            userCollectionType: UserCollectionType.Contributor,
            membershipStatus: MembershipStatus.Requested,
            collectionJoinCode
        };

        await mutation.mutateAsync(command);
    };

    useEffectOnce(() => {
        if (collection.loggedInUserCanAutoJoin) {
            onRequestedAsync();
        }
    });

    return (
        <Modal isOpen={true} toggle={dismiss}>
            <ModalHeader toggle={dismiss}>Request group membership</ModalHeader>
            <ModalBody>
                {collection.hasInviteCode && (
                    <div className="row mb-3">
                        <label>Invite code</label>
                        <Input
                            value={collectionJoinCode}
                            onChange={e =>
                                setCollectionJoinCode(e.target.value)
                            }
                        />
                        <FormText color="muted">
                            Enter an invite code if you've been given one or
                            leave empty.
                        </FormText>
                    </div>
                )}
                {mutation.isLoading && <Spinner />}
            </ModalBody>
            <ModalFooter>
                <div className="mb-3">
                    <ButtonAsync
                        color="primary"
                        className="float-end ms-3"
                        onClick={onRequestedAsync}
                        disabled={
                            collection.loggedInUserCanAutoJoin ||
                            mutation.isLoading
                        }
                    >
                        Join
                    </ButtonAsync>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default RequestToJoinModal;
