import React, {useState} from 'react';
import {ChromePicker, ColorChangeHandler} from 'react-color';
import {Button} from 'reactstrap';
import {HTMLColor} from '../scene/types.ts';

interface ColorPickerComponentProps {
    color: string;
    onChange: (color: HTMLColor) => void;
}

const ColorPickerComponent: React.FC<ColorPickerComponentProps> = ({
    color,
    onChange
}) => {
    const [displayColorPicker, setDisplayColorPicker] =
        useState<boolean>(false);

    const toggleColorPicker = (): void => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleChangeComplete: ColorChangeHandler = color => {
        onChange(color.hex);
    };

    return (
        <div>
            <Button
                onClick={toggleColorPicker}
                style={{
                    backgroundColor: color,
                    width: '36px',
                    height: '36px',
                    border: '1px solid #ccc'
                }}
            />
            {displayColorPicker && (
                <div className="mt-2">
                    <ChromePicker
                        color={color}
                        onChangeComplete={handleChangeComplete}
                    />
                </div>
            )}
        </div>
    );
};

export default ColorPickerComponent;
