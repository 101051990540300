import {CollectionInfoModel} from '../../api/types.ts';
import React, {useState} from 'react';
import MixCard from '../projects/MixCard.tsx';
import SelectProjectModal from '../projects/SelectProjectModal.tsx';
import {Button} from 'reactstrap';

interface GroupHomeProjectProps {
    collection: CollectionInfoModel;
    onHomeProjectChanged: (mixId: string | null) => void;
    myProjects?: boolean;
}

const GroupHomeProject: React.FC<GroupHomeProjectProps> = ({
    collection,
    onHomeProjectChanged,
    myProjects
}) => {
    const [showSelectProjectModel, setShowSelectProjectModel] = useState(false);

    return (
        <div className="mb-3">
            <label htmlFor="HomeMixId" className="form-label">
                Group home project
            </label>

            {collection.homeMixId && (
                <div className="mixi-card-deck">
                    <MixCard
                        mixId={collection.homeMixId}
                        onRemove={() => onHomeProjectChanged(null)}
                    />
                </div>
            )}

            {showSelectProjectModel && (
                <SelectProjectModal
                    onClose={() => setShowSelectProjectModel(false)}
                    onProjectSelected={mix => {
                        onHomeProjectChanged(mix.id!);
                        setShowSelectProjectModel(false);
                    }}
                    myProjects={myProjects}
                />
            )}
            <div className="d-flex justify-content-end">
                <Button
                    color="primary"
                    onClick={() => setShowSelectProjectModel(true)}
                >
                    Set group home project
                </Button>
            </div>
        </div>
    );
};

export default GroupHomeProject;
