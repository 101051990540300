import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useAuthStateManager} from '../../hooks/useAuthStateManager.tsx';
import MxNotifications from '../notifications/MxNotifications.tsx';
import Menu from './Menu.tsx';
import {faPlus, faNavicon} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {UserStatus} from '../../api/types.ts';
import {Page} from '../../pages.ts';
import PageLayout from './PageLayout.ts';
import DraggableTranslationPanel from '../translation/DraggableTranslationPanel.tsx';
import {useTranslation} from '../translation/TranslationContext.tsx';
import FloatingTranslationButton from '../translation/FloatingTranslationButton.tsx';

interface MainLayoutProps {
    children: React.ReactNode;
    page: Page;
}

const MainLayout: React.FC<MainLayoutProps> = ({children, page}) => {
    const {authData, isAuthenticated} = useAuthStateManager();

    const [isOpen, setIsOpen] = useState(false);

    const {showTranslationPanel, setShowTranslationPanel, projectInfo} =
        useTranslation();

    const handleTranslationToggle = () => {
        setShowTranslationPanel(!showTranslationPanel);
    };

    const handleMobileNavToggle = () => {
        setIsOpen(!isOpen);
    };

    const getTranslation = () => {
        if (!page.translation) {
            return null;
        }

        if (!isAuthenticated) {
            return null;
        }

        return (
            <>
                <FloatingTranslationButton onClick={handleTranslationToggle} />
                {showTranslationPanel && (
                    <DraggableTranslationPanel
                        onClose={() => setShowTranslationPanel(false)}
                        projectInfo={projectInfo}
                    />
                )}
            </>
        );
    };

    if (page.pageLayout === PageLayout.None) {
        return <>{children}</>;
    }

    if (page.pageLayout === PageLayout.FullScreen) {
        return (
            <div id="fullscreen-content">
                {getTranslation()}
                {children}
            </div>
        );
    }

    if (
        page.pageLayout === PageLayout.Project ||
        page.pageLayout === PageLayout.Group
    ) {
        return (
            <>
                <div className={`layout-holder`}>
                    <main>
                        {getTranslation()}
                        {children}
                    </main>
                </div>
            </>
        );
    }

    return (
        <div>
            {getTranslation()}
            <div className="topnav-wrapper">
                <nav className="topnav rounded-3">
                    <div className="topnav-logo-holder">
                        <NavLink className="navbar-brand" to="/">
                            <img
                                src="/images/logos/logo-default.svg"
                                alt="Mixiply Logo"
                            />
                        </NavLink>
                    </div>
                    <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
                    <div className="topnav-actions">
                        {authData.isAuthenticated ? (
                            authData.user_status === UserStatus.Registered ? (
                                <React.Fragment>
                                    <MxNotifications />
                                    <NavLink
                                        title="Create new project"
                                        className="btn btn-primary btn-icon d-none d-lg-inline-block ms-3 text-nowrap"
                                        to="/new-project"
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                        Create
                                    </NavLink>
                                </React.Fragment>
                            ) : null
                        ) : (
                            <NavLink
                                className="btn btn-primary me-2"
                                to={`/login?redir=${encodeURIComponent(
                                    '/new-project'
                                )}`}
                                role="button"
                                title="Login or Register"
                            >
                                Start creating
                            </NavLink>
                        )}
                        <button
                            className="btn btn-primary topnav-menu-toggler ms-3 "
                            onClick={handleMobileNavToggle}
                        >
                            <FontAwesomeIcon icon={faNavicon} />
                        </button>
                    </div>
                </nav>
            </div>
            <div className={`layout-holder`}>
                <main>{children}</main>
            </div>
        </div>
    );
};

export default MainLayout;
