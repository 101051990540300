import React, {useState, useCallback} from 'react';
import classNames from 'classnames';
import {Emoji, EmojiType} from './types.ts';
import SkinTones from './SkinTones.ts';

interface EmojiPickerEmojiProps {
    emoji: Emoji;
    onSelect: (emoji: Emoji) => void;
    defaultSkinTone?: string;
}

const EmojiPickerEmoji: React.FC<EmojiPickerEmojiProps> = ({
    emoji,
    onSelect,
    defaultSkinTone = null
}) => {
    const [showVariants, setShowVariants] = useState(false);
    const [primaryEmoji, setPrimaryEmoji] = useState<Emoji | null>(null);
    const [variants, setVariants] = useState<Emoji[]>([]);

    const hasSkinToneComponent = useCallback(() => {
        return (
            defaultSkinTone !== null &&
            emoji.variants.some(
                v =>
                    v.components &&
                    v.components.some(c => SkinTones.all.includes(c))
            )
        );
    }, [defaultSkinTone, emoji]);

    const getTitle = useCallback((emoji: Emoji) => {
        if (emoji.type === EmojiType.Ascii) {
            return emoji.name;
        }
        return `${emoji.name} :${emoji.emojiCode}:`;
    }, []);

    const handleSelect = useCallback(
        (emoji: Emoji) => {
            onSelect(emoji);
        },
        [onSelect]
    );

    const toggleVariants = useCallback(() => {
        setShowVariants(prev => !prev);
    }, []);

    React.useEffect(() => {
        if (hasSkinToneComponent()) {
            const primary = emoji.variants.find(
                v => v.components?.includes(defaultSkinTone ?? '')
            );
            if (primary) {
                setPrimaryEmoji(primary);
                setVariants([
                    emoji,
                    ...emoji.variants.filter(v => v !== primary)
                ]);
                return;
            }
        }
        setPrimaryEmoji(emoji);
        setVariants(emoji.variants);
    }, [emoji, defaultSkinTone, hasSkinToneComponent]);

    if (!primaryEmoji) {
        return null;
    }

    return (
        <span
            className={classNames('emoji-link', {
                border: showVariants,
                'border-dotted': showVariants,
                'border-grey': showVariants,
                rounded: showVariants
            })}
        >
            <img
                className="emoji"
                alt={primaryEmoji.alt}
                title={getTitle(primaryEmoji)}
                src={primaryEmoji.source}
                onClick={() => handleSelect(primaryEmoji)}
                data-type={primaryEmoji.type}
            />
            {variants.length > 0 && (
                <span
                    className="action-link d-flex-align-items-center justify-content-center"
                    onClick={toggleVariants}
                >
                    <i className="fas fa-ellipsis-h"></i>
                </span>
            )}
            {showVariants &&
                variants.map(variant => (
                    <img
                        key={variant.emojiCode}
                        className="emoji"
                        alt={variant.alt}
                        title={getTitle(variant)}
                        src={variant.source}
                        onClick={() => handleSelect(variant)}
                        data-type={variant.type}
                    />
                ))}
        </span>
    );
};

export default EmojiPickerEmoji;
